import { createSelector } from 'reselect';
import {
  path, identity, pathOr, memoizeWith, propOr,
} from 'ramda';

const getClientBillingInfo = createSelector(
  path(['billingInfo', 'client']),
  identity,
);

const getClientActionBillingInfoPending = createSelector(
  pathOr(false, ['pending']),
  clientBillingInfo => memoizeWith(identity, pending => propOr(false, pending, clientBillingInfo)),
);

export default {
  getClientBillingInfo,
  getClientActionBillingInfoPending,
};
