import React from 'react';
import { ButtonsCollection, Favorite } from '../../../../ui-kit';
import './style.sass';

const ButtonsControlGroup = () => (
  <div className="control-group__wrapper">
    <ul className="control-group__list">
      <li className="control-group-item">
        <ButtonsCollection.ButtonIcons
          title="Zoom-in"
          className="control-group-button"
        >
          <span className="icon-zoom-in" />
        </ButtonsCollection.ButtonIcons>
      </li>
      <li className="control-group-item">
        <ButtonsCollection.ButtonIcons
          title="Zoom-out"
          className="control-group-button"
        >
          <span className="icon-zoom-out" />
        </ButtonsCollection.ButtonIcons>
      </li>
      <li className="control-group-item">
        <ButtonsCollection.ButtonIcons
          title="Turn"
          className="control-group-button"
        >
          <span className="icon-turn" />
        </ButtonsCollection.ButtonIcons>
      </li>
    </ul>
    <ul className="control-group__list">
      <li className="control-group-item">
        <ButtonsCollection.ButtonIcons
          title="Download"
          className="control-group-button"
        >
          <span className="icon-download" />
        </ButtonsCollection.ButtonIcons>
      </li>
      <li className="control-group-item">
        <Favorite className="control-group-button" />
      </li>
      <li className="control-group-item">
        <ButtonsCollection.ButtonIcons
          title="Reply"
          className="control-group-button"
        >
          <span className="icon-reply" />
        </ButtonsCollection.ButtonIcons>
      </li>
      <li className="control-group-item">
        <ButtonsCollection.ButtonIcons
          title="Delete"
          className="control-group-button"
        >
          <span className="icon-trash" />
        </ButtonsCollection.ButtonIcons>
      </li>
    </ul>
  </div>
);

export default ButtonsControlGroup;
