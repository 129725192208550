import React from 'react';
import PropTypes from 'prop-types';

import { InvoiceDetails } from './components';
import './style.sass';
import { ButtonsCollection } from '../../ui-kit';
import { CRM_ROUTES } from '../../constants/crm';
import RedirectToProjectsInvoiceModal from './components/modals/RedirectModal/index';

const NewInvoiceControls = ({
  previewInvoiceUrl, saveAndSend, isPending, saveAsDraft, history, t, downloadInvoiceUrl,
  generateInvoiceNumber,
}) => (
  <>
    <ButtonsCollection.ButtonBrill
      type="button"
      className="button--xs new-invoice__container--controls-button save-and-send button-brill--fill"
      onClick={generateInvoiceNumber}
      pending={isPending}
    >
      {t('Generate Invoice Number')}
    </ButtonsCollection.ButtonBrill>
    <a
      href={previewInvoiceUrl}
      className="new-invoice__container--controls-button preview"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ButtonsCollection.ButtonBrill
        type="button"
        className="button--xs new-invoice__container--controls-button save-and-send button-brill--fill"
      >

        Preview
      </ButtonsCollection.ButtonBrill>
    </a>
    <a
      href={downloadInvoiceUrl}
      className="new-invoice__container--controls-button download"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ButtonsCollection.ButtonBrill
        type="button"
        className="button--xs new-invoice__container--controls-button save-and-send button-brill--fill"
      >

        Download
      </ButtonsCollection.ButtonBrill>
    </a>

    <ButtonsCollection.ButtonBrill
      type="button"
      className="button--xs new-invoice__container--controls-button save-and-send button-brill--fill"
      onClick={saveAndSend}
      pending={isPending}
    >

      Save and send
    </ButtonsCollection.ButtonBrill>
    <ButtonsCollection.ButtonBrill
      type="button"
      className="button--xs new-invoice__container--controls-button save-as-draft"
      onClick={saveAsDraft}
      pending={isPending}
    >

      Save as draft
    </ButtonsCollection.ButtonBrill>
    <button
      type="button"
      className="button--cancel new-invoice__container--controls-button cancel"
      onClick={() => history.push(CRM_ROUTES.INVOICES)}
    >
      {t('Cancel')}
    </button>
  </>
);

const EditInvoiceControls = ({
  previewInvoiceUrl, updateAndSend, isPending, updateInvoice, history, t, downloadInvoiceUrl,
}) => (
  <>
    <a
      href={previewInvoiceUrl}
      className="new-invoice__container--controls-button preview"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ButtonsCollection.ButtonBrill
        type="button"
        className="button--xs new-invoice__container--controls-button save-and-send button-brill--fill"
      >

        Preview
      </ButtonsCollection.ButtonBrill>
    </a>
    <a
      href={downloadInvoiceUrl}
      className="new-invoice__container--controls-button download"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ButtonsCollection.ButtonBrill
        type="button"
        className="button--xs new-invoice__container--controls-button save-and-send button-brill--fill"
      >

        Download
      </ButtonsCollection.ButtonBrill>
    </a>

    <ButtonsCollection.ButtonBrill
      type="button"
      className="button--xs new-invoice__container--controls-button save-and-send button-brill--fill"
      onClick={updateAndSend}
      pending={isPending}
    >

      Save and send
    </ButtonsCollection.ButtonBrill>
    <ButtonsCollection.ButtonBrill
      type="button"
      className="button--xs new-invoice__container--controls-button save-as-draft"
      onClick={updateInvoice}
      pending={isPending}
    >

      Save
    </ButtonsCollection.ButtonBrill>
    <button
      type="button"
      className="button--cancel new-invoice__container--controls-button cancel"
      onClick={() => history.push(CRM_ROUTES.INVOICES)}
    >
      {t('Cancel')}
    </button>
  </>
);

const NewInvoicePage = ({
  total, subtotal, handleChange, values, previewInvoiceUrl, isPendingCreate,
  isEditInvoicePage, saveAsDraft, history, t, errors, setFieldValue, touched,
  currencySign, saveAndSend, updateAndSend, updateInvoice, isPendingUpdate, currentInvoice,
  downloadInvoiceUrl, generatedInvoiceNumber, generateInvoiceNumber, onClose, checkNoticeModal,
  noticeModal,
}) => (
  <div className="new-invoice__container">
    {/* <InvoiceForm */}
    {/*  handleChange={handleChange} */}
    {/*  values={values} */}
    {/*  errors={errors} */}
    {/*  setFieldValue={setFieldValue} */}
    {/*  touched={touched} */}
    {/*  currentInvoice={currentInvoice} */}
    {/*  isEditInvoicePage={isEditInvoicePage} */}
    {/* /> */}
    <RedirectToProjectsInvoiceModal isOpen={noticeModal} onClose={onClose} />
    <InvoiceDetails
      checkNoticeModal={checkNoticeModal}
      generatedInvoiceNumber={generatedInvoiceNumber}
      isEditInvoicePage={isEditInvoicePage}
      total={total}
      subtotal={subtotal}
      currencySign={currencySign}
      handleChange={handleChange}
      values={values}
      errors={errors}
      setFieldValue={setFieldValue}
      touched={touched}
      currentInvoice={currentInvoice}
    />
    <div className="new-invoice__container--controls">
      {
        !isEditInvoicePage ? (
          <NewInvoiceControls
            downloadInvoiceUrl={downloadInvoiceUrl}
            previewInvoiceUrl={previewInvoiceUrl}
            saveAndSend={saveAndSend}
            isPending={isPendingCreate}
            saveAsDraft={saveAsDraft}
            history={history}
            generateInvoiceNumber={generateInvoiceNumber}
            t={t}
          />
        ) : (
          <EditInvoiceControls
            downloadInvoiceUrl={downloadInvoiceUrl}
            previewInvoiceUrl={previewInvoiceUrl}
            updateAndSend={updateAndSend}
            isPending={isPendingUpdate}
            updateInvoice={updateInvoice}
            history={history}
            t={t}
          />
        )
      }
    </div>
  </div>
);

NewInvoicePage.propTypes = {
  total: PropTypes.number.isRequired,
  subtotal: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  saveAsDraft: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  touched: PropTypes.instanceOf(Object).isRequired,
  currencySign: PropTypes.string.isRequired,
  previewInvoiceUrl: PropTypes.string.isRequired,
  saveAndSend: PropTypes.func.isRequired,
  isEditInvoicePage: PropTypes.bool.isRequired,
  updateAndSend: PropTypes.func.isRequired,
  updateInvoice: PropTypes.func.isRequired,
  isPendingCreate: PropTypes.bool.isRequired,
  isPendingUpdate: PropTypes.bool.isRequired,
  currentInvoice: PropTypes.instanceOf(Object).isRequired,
  downloadInvoiceUrl: PropTypes.string.isRequired,
  generatedInvoiceNumber: PropTypes.number,
  noticeModal: PropTypes.bool.isRequired,
  checkNoticeModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  generateInvoiceNumber: PropTypes.func.isRequired,
};

NewInvoicePage.defaultProps = {
  generatedInvoiceNumber: null,
};

export default NewInvoicePage;
