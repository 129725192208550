import React from 'react';
import PropTypes from 'prop-types';
import ModalView from '../modalView';

import '../style.sass';

const EditTimeEntry = ({ t, ...props }) => (
  <ModalView
    title={t('Edit time entry')}
    titleAction={t('Update')}
    {...{ ...props, t }}
  />
);

EditTimeEntry.propTypes = {
  t: PropTypes.func.isRequired,
};

export default EditTimeEntry;
