import {
  compose, hoistStatics, getContext, withHandlers, branch, renderNothing,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import DeleteTaskModal from './deleteTaskModal';
import { getModal } from '../../../../state/ui/selectors';
import { closeModal } from '../../../../state/ui/actions';
import { tasksActions } from '../../../../state/tasks';
import { clientsActions, clientsSelectors } from '../../../../state/clients';

const mapStateToProps = (state, { selectedClientTask }) => ({
  task: clientsSelectors.getClientTaskSelector(state)(selectedClientTask),
  isOpen: getModal(state)('deleteClientTaskModal'),
});

const mapDispatchToProps = {
  deleteTaskRequest: tasksActions.deleteTaskRequest,
  onCloseModal: () => closeModal('deleteClientTaskModal'),
  getClientTasks: clientsActions.getClientTasksRequest,
  getClientLogs: clientsActions.getClientLogsRequest,
  deleteClientTaskSuccess: clientsActions.deleteClientTaskSuccess,
};

const onDeleteTaskHandler = ({
  deleteTaskRequest,
  onCloseModal,
  onSuccess,
  selectedClientTask,
  selectedClientId,
  getClientTasks,
  getClientLogs,
  task,
  deleteClientTaskSuccess,
}) => () => deleteTaskRequest({
  taskId: selectedClientTask,
  clientId: selectedClientId,
  project_id: task.project_id ? task.project_id : null,
}, {
  callbacks: {
    success: () => {
      onCloseModal();
      deleteClientTaskSuccess({ task, selectedClientId });
      getClientTasks({ id: selectedClientId });
      getClientLogs({ id: selectedClientId });
      if (onSuccess) {
        onSuccess();
      }
    },
  },
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    selectedClientId: PropTypes.any,
  }),
  branch(
    ({ task }) => !task,
    renderNothing,
  ),
  withTranslation(['tasks', 'common']),
  withRouter,
  withHandlers({
    onDeleteTask: onDeleteTaskHandler,
  }),
  hoistStatics,
);

export default enhance(DeleteTaskModal);
