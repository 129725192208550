import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ButtonsCollection } from '../index';
import BgImage from '../../assets/otherAssets/comming_soon_blur.jpg';
import './style.sass';

/**
 *
 * @param title{string}
 * @param history{Object}
 * @returns {JSX.Element}
 * @constructor
 */

export const ComingSoon = ({ title }) => {
  const { t } = useTranslation('common');

  const history = useHistory();
  const goToPreviousPath = () => {
    history.goBack();
  };
  return (
    <>
      <div className="main-container page-contacts__main-container ComingSoon">
        <img className="ComingSoon_bg" src={BgImage} alt="comingSoonImage" />
        <div className="ComingSoon_content">
          <h2 className="ComingSoon_content_title">
            <span>{title}</span>
            <span>{t('Coming soon')}</span>
          </h2>
          <ButtonsCollection.ButtonBrill
            onClick={goToPreviousPath}
            className="button--md"
          >

            {t('Go back')}
          </ButtonsCollection.ButtonBrill>
        </div>
      </div>
    </>
  );
};
