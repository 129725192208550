import React from 'react';
import PropTypes from 'prop-types';
import ContactsItem from '../contactsItem';
import { CreateContactModal, EditContactModal, DeleteContactModal } from '../../../contactsContainer/modals';
import './style.sass';
import { ButtonsCollection, ShowMoreItems } from '../../../../ui-kit';

const ContactsInfo = ({
  isOpen,
  clientContacts,
  t,
  setCreateContactModalOpen,
  editClientContactsSuccess,
  client,
  selectedContactId,
  onDeleteContact,
  createClientContactsSuccess,
  clientContactsCount,
  getMoreContacts,
}) => (
  <div className="client-contacts">
    <ButtonsCollection.ButtonBrill
      onClick={setCreateContactModalOpen}
      className="button--sm header__control-button--project add-contacts-button"
    >
      {t('New contact')}
    </ButtonsCollection.ButtonBrill>
    {clientContacts && clientContacts.length ? (
      <ShowMoreItems
        className="client-tasks__wrapper"
        count={4}
        onShowMoreCustomClick={getMoreContacts}
        totalItemsCount={clientContactsCount}
        items={
          clientContacts.map(contact => (
            <ContactsItem contact={contact} isOpen={isOpen} key={contact.id} />
          ))
        }
      />
    ) : <div className="client-tasks__empty">There are no contacts yet</div>}
    <CreateContactModal relatedTo={client} onSuccess={createClientContactsSuccess} />
    <EditContactModal selectedContactId={selectedContactId} onSuccess={editClientContactsSuccess} />
    <DeleteContactModal onDelete={onDeleteContact} selectedContactId={selectedContactId} />
  </div>
);

ContactsInfo.propTypes = {
  t: PropTypes.func.isRequired,
  setCreateContactModalOpen: PropTypes.func.isRequired,
  onDeleteContact: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  clientContacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  client: PropTypes.shape({}).isRequired,
  selectedContactId: PropTypes.number,
  editClientContactsSuccess: PropTypes.func.isRequired,
  createClientContactsSuccess: PropTypes.func.isRequired,
  getMoreContacts: PropTypes.func.isRequired,
  clientContactsCount: PropTypes.number.isRequired,
};

ContactsInfo.defaultProps = {
  selectedContactId: null,
  isOpen: false,
};

export default ContactsInfo;
