import React from 'react';

import ModalView from '../modalView';

const CreateContactModal = props => (
  <>
    <ModalView
      title="Create new contact"
      titleAction="Create"
      {...props}
    />
  </>

);

export default CreateContactModal;
