import React from 'react';
import { FormsCollection } from '@/ui-kit';
import './style.sass';

export const RadioButtons = ({
  config,
  handleChange,
  handleBlur,
  name,
  defaultValue,
  selectedValue,
}) => (
  <div className="radio-buttons">
    {config.options.map(({ label, value }) => (
      <FormsCollection.Radio
        key={`${name}${value}`}
        label={label}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        checked={(selectedValue || defaultValue) === value}
        id={`${name}${value}`}
      />
    ))}
  </div>
);
