import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { withFormik } from 'formik';
import { isEmpty } from 'ramda';
import CreateClientModal from './createClientModal';
import { uiActions, uiSelectors } from '../../../../state/ui';
import { clientsActions } from '../../../../state/clients';
import rules from '../rules';
import { callNotification } from '../../../../utils/helpers/notifies';
import { getTimezoneAutocompleteHelper } from '../../../../utils/helpers/dateHelpers';

const mapStateToProps = state => ({
  isOpen: uiSelectors.getModal(state)('createClientModal'),
});

const mapDispatchToProps = {
  closeModal: () => uiActions.closeModal('createClientModal'),
  createClient: clientsActions.createClientRequest,
};

const onCloseModalHandler = ({
  closeModal,
  resetForm,
}) => () => {
  closeModal();
  resetForm();
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => ({
      contact: {
        first_name: '',
        last_name: '',
        company_name: '',
        company_url: '',
        service: '',
        position: '',
        email: '',
        phone: '',
        avatar: '',
        timezone: '',
      },
    }),
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: rules,
    handleSubmit: (formData, {
      resetForm,
      props: {
        closeModal,
        createClient,
        onSuccess,
      },
    }) => {
      if (isEmpty(formData.contact.avatar)) {
        // eslint-disable-next-line no-param-reassign
        formData.contact.avatar = null;
      }
      if (isEmpty(formData.contact.email)) {
        // eslint-disable-next-line no-param-reassign
        formData.contact.email = null;
      }
      createClient({
        ...formData,
      }, {
        callbacks: {
          success: () => {
            resetForm({
            });
            closeModal();
            if (onSuccess) {
              onSuccess();
            }
          },
          error: () => callNotification.error('Email should be unique'),
        },
      });
    },
  }),
  withHandlers({
    onCloseModal: onCloseModalHandler,
    getAutocompleteHandler: getTimezoneAutocompleteHelper,
  }),
);
export default enhance(CreateClientModal);
