import {
  compose, lifecycle, pure, withHandlers, withState,
} from 'recompose';

import {
  always,
  and,
  cond,
  curry,
  equals,
  flip,
  identity,
  ifElse,
  isEmpty,
  isNil,
  not,
  or,
  pick,
  prop, replace,
  T,
  tap, when,
  allPass, difference, omit, values, filter,
} from 'ramda';
import { Either, Maybe } from 'ramda-fantasy';
import { isNotEmpty, notEqual } from 'ramda-extension';
import { connect } from 'react-redux';
import TypeMessage from './sendMessage';

import withMentions from '../../utils/enchancers/withMentions';
import {
  appendMention, appendOrFilterMention, filterMentions, replaceOrAppendMention,
} from '../../utils/helpers/mentionHelpers/events';
import {
  isMentionEdit,
  isMentionEditedRight,
  isNextMentionExist,
} from '../../utils/helpers/mentionHelpers/lookup';
import withMessageContent from '../../utils/enchancers/withMessageContent';
import withWindowWidth from '../../utils/enchancers/withWindowWidth';
import withEmoji from '../../utils/enchancers/withEmoji';
import withRefs from '../../utils/enchancers/withRefs';
import {
  setBreakToField,
  setFocusAfterNodeForMention,
} from '../../utils/helpers/uiComponentHelpers/caretHelpers';
import { setCaretToTheEndPosition } from '../../utils/helpers/uiHelpers';
import {
  debounceFunc, isMobile,
  isNotNil,
  propIdOrNull,
} from '../../utils/helpers/commonHelpers';
import { KEYS, WINDOW_WIDTH } from '../../constants/ui';
import { callWithPreventEvent } from '../../utils/helpers/uiComponentHelpers/DOMhelpers';
import { uiActions, uiSelectors } from '../../state/ui';
import { withUserProfile, withFilesUploading } from '../../utils/enchancers';
import { uploadTempFileRequest } from '../../state/ui/actions';
import { AVAILABLE_FILE_FORMATS, AVAILABLE_FILE_SIZES } from '../../constants/files';

const onClickOutsideTypeMessageWrapperHandler = () => () => {};

const onTextareaKeyDownHandler = ({
  onKeyDownSaveContent,
  onMentionKeyDown,
  isDropdownMentionsOpen,
  isEmojiOpen,
  onKeyDown,
  emojisAutocomplete,
  onKeyDownPureTextArea,
  messageContent,
}) => (event) => {
  onMentionKeyDown(event);

  const isEmojiAutoComplete = compose(not, isEmpty)(emojisAutocomplete);
  const isPreventSpecialEnter = or(isEmojiOpen, isEmojiAutoComplete);
  if (!isDropdownMentionsOpen && !isPreventSpecialEnter) {
    onKeyDownSaveContent(event);
  }
  if (onKeyDown) {
    onKeyDown(event);
  }
  if (onKeyDownPureTextArea && !isEmojiOpen && !isDropdownMentionsOpen && !messageContent) {
    onKeyDownPureTextArea(event);
  }
};

const onSpecialKeysHandler = ({
  emojisAutocomplete,
  onClickSaveContent,
  isEmojiOpen,
  isDropdownMentionsOpen,
}) => (e) => {
  const { keyCode, type } = e;
  const isEmojiAutoComplete = compose(not, isEmpty)(emojisAutocomplete);
  const isPreventSpecialEnter = or(isEmojiOpen, isEmojiAutoComplete);
  const isNotMention = !isDropdownMentionsOpen;
  const isEnterForSubmitForm = () => isNotMention && and(not(e.shiftKey),
    not(isPreventSpecialEnter));
  const onPreventByEmojis = () => when(
    equals(true),
    () => callWithPreventEvent(identity, e),
  )(isPreventSpecialEnter);

  const listenerKeyDown = code => cond([
    [equals(KEYS.LEFT_ARROW), onPreventByEmojis],
    [equals(KEYS.RIGHT_ARROW), onPreventByEmojis],
    [equals(KEYS.UP_ARROW), onPreventByEmojis],
    [equals(KEYS.DOWN_ARROW), onPreventByEmojis],
    [equals(KEYS.ENTER),
      cond([
        [isMobile, () => callWithPreventEvent(setBreakToField, e)],
        [isEnterForSubmitForm, () => callWithPreventEvent(onClickSaveContent, e)],
        [T, cond([
          [() => equals(true, isEmojiAutoComplete), () => callWithPreventEvent(identity, e)],
          [T, () => callWithPreventEvent(setBreakToField, e)],
        ])],
      ])],
  ])(code);

  const listenerKeyUp = code => cond([
    [equals(KEYS.ENTER),
      ifElse(
        isEnterForSubmitForm,
        () => callWithPreventEvent(identity, e),
        ifElse(
          () => equals(true, isEmojiAutoComplete),
          () => callWithPreventEvent(identity, e),
          T,
        ),
      ),
    ],
  ])(code);

  const listenerShortKeys = cond([
    [equals('keydown'), () => when(equals(true), () => listenerKeyDown(keyCode))(isNotMention)],
    [equals('keyup'), () => when(equals(true), () => listenerKeyUp(keyCode))(isNotMention)],
  ]);

  return listenerShortKeys(type);
};

const onResetAllHtmlHandler = ({
  getField, onPasteTextCheckEmoji,
}) => (e) => {
  const field = getField();
  const selection = window.getSelection();
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = clipboardData.getData('Text');
  e.preventDefault();
  if (selection.focusNode) {
    requestAnimationFrame(() => {
      onPasteTextCheckEmoji(field, pastedData);
    });
  }
};

const mapStateToProps = (state, { keyField }) => ({
  contentFieldInStore: uiSelectors.getFieldValue(state)(keyField),
  isWsError: uiSelectors.getIsWsError(state),
});

const mapDispatchToProps = ({
  saveFieldValue: uiActions.saveFieldValue,
  resetFieldValue: uiActions.resetFieldValue,
  uploadTempFile: uploadTempFileRequest,
});

const onChangeContentHandler = ({
  onSetMessageContent,
  getRef,
  setMentions,
  setIsDropdownMentionsOpen,
  onCloseMentionDropdown,
  isDropdownMentionsOpen,
  mentionTag,
  members,
  saveFieldValue,
  keyField,
  isEditing,
}) => ({ target: { value } }) => {
  // todo: set clear value without html
  const saveContentInStore = () => !isEditing && saveFieldValue({
    key: keyField,
    content: value,
  });
  debounceFunc(saveContentInStore, 1000, false, 'saveContentInStore');
  const clearValue = value.replace(/&nbsp;/g, ' ');
  const typeMessage = getRef('typeMessage');

  const range = document.createRange();
  const selection = window.getSelection();
  const { focusNode, anchorOffset } = selection;

  const isMentionEdited = isMentionEdit(anchorOffset)(focusNode);

  if (clearValue.length === 0) {
    if (typeMessage.firstChild) {
      setFocusAfterNodeForMention(selection, range, 0, typeMessage.firstChild);
      typeMessage.firstChild.remove();
    }
  }

  const cratedMention = mentionTag(range, selection);
  Either.either(identity, cratedMention.replaceTagToText)(isMentionEdited);
  if (focusNode && anchorOffset) {
    appendOrFilterMention(
      focusNode.data,
      anchorOffset,
      appendMention(members, compose(cratedMention.appendNode, pick(['username', 'id']))),
      Maybe.maybe(
        isDropdownMentionsOpen ? onCloseMentionDropdown() : identity,
        compose(cond([
          [isNotEmpty, () => setIsDropdownMentionsOpen(true)],
          [T, () => setIsDropdownMentionsOpen(false)],
        ]), tap(setMentions), filterMentions(members)),
      ),
    );
  }

  const { innerHTML } = typeMessage;
  onSetMessageContent(innerHTML);
};

const onSetFocusFieldHandler = ({ getRef }) => () => {
  const isNotInputFocus = getRef('typeMessage') !== document.activeElement;
  if (isNotInputFocus) {
    setCaretToTheEndPosition(getRef('typeMessage'));
  }
};

const onSetRefWrapTextAreaHandler = ({ setRef }) => e => setRef('typeMessageWrapper', e);

const onClickMentionHandler = ({ onSetUserProfile }) => (user) => {
  onSetUserProfile(user);
};

const onCloseReplyMessageHandler = ({ setReplyMessage }) => () => setReplyMessage(null);

const onSubmitFilesHandler = ({ setAppendedFilesList }) => list => setAppendedFilesList(list);

const onSetFileFromBufferHandler = ({
  uploadTempFile, setFilesIdList, filesListIds, appendedFilesList, setAppendedFilesList,
}) => (e) => {
  const { items } = e.clipboardData || e.originalEvent.clipboardData;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < items.length; i++) {
    const item = items[i].getAsFile();
    if (item) {
      const formData = new FormData();
      formData.append('file', item);
      uploadTempFile(formData, {
        callbacks: {
          success: ({ model }) => {
            setFilesIdList(filesListIds.concat(model));
            setAppendedFilesList(appendedFilesList.concat(model.id));
          },
        },
      });
    }
  }
};

const onPasteHandler = ({ onResetAllHtml, onSetFileFromBuffer }) => (e) => {
  onResetAllHtml(e);
  onSetFileFromBuffer(e);
};

const onResetFileByIdHandler = ({
  setAppendedFilesList,
  appendedFilesList, resetFilesIdList,
}) => (fileId) => {
  setAppendedFilesList(filter(notEqual(fileId), appendedFilesList));
  resetFilesIdList([fileId]);
};

const enhance = compose(
  withWindowWidth(),
  connect(mapStateToProps, mapDispatchToProps),
  withRefs(),
  pure,
  withState('appendedFilesList', 'setAppendedFilesList', []),
  withFilesUploading({
    availableSize: AVAILABLE_FILE_SIZES.MESSENGER,
    availableFormats: AVAILABLE_FILE_FORMATS.MESSENGER,
    onSubmitFiles: onSubmitFilesHandler,
  }),
  withHandlers({
    onResetFileById: onResetFileByIdHandler,
  }),
  withState('isBeginMention', 'setIsBeginMention', true),
  withWindowWidth(),
  withUserProfile,
  withMessageContent({
    value: ({ value }) => value,
    onSaveMessageContent: ({
      resetFieldValue, keyField,
      onSubmit, messageContent, onClearMessageContent, isEditing, isWsError, appendedFilesList,
      resetFilesIdList, setAppendedFilesList,
    }) => {
      if (!isWsError) {
        const onResetFieldValue = () => !isEditing && resetFieldValue({
          key: keyField,
          content: '',
        });
        setTimeout(onResetFieldValue, 1000);
        onResetFieldValue();
        onClearMessageContent();
        resetFilesIdList([]);
        setAppendedFilesList([]);
        onSubmit(compose(replace(/&gt;/g, '>'), replace(/&lt;/g, '<'))(messageContent), appendedFilesList);
      }
    },
  }),
  withEmoji({
    parentElement: ({ getRef }) => getRef('typeMessage'),
    onUpdateContent: ({ onSetMessageContent }) => onSetMessageContent,
  }),
  withMentions({
    mentions: ({ members }) => members,
    parentElement: ({ getRef }) => (!isNil(getRef('typeMessage')) ? getRef('typeMessage') : null),
    onUpdateContent: ({ onSetMessageContent }) => onSetMessageContent,
    onSelectMention: ({
      mentionTag, setSelectedMention, parentElement,
      onUpdateContent, isMentionEdited, setIsMentionEdited, mentions,
      isBeginMention, setIsBeginMention,
    }) => (mentionIndex) => {
      const selection = window.getSelection();
      const range = document.createRange();
      if (isBeginMention) setIsBeginMention(false);
      const tag = mentionTag(range, selection, () => ({
        start: selection.anchorOffset - 1,
        end: selection.anchorOffset,
      }));
      const appendAndSetActiveMention = curry(action => compose(
        compose(action, pick(['username', 'id']), flip(prop)(mentions)),
        tap(setSelectedMention),
      ));
      Either.either(identity, index => replaceOrAppendMention(
        appendAndSetActiveMention(tag.replaceTag),
        appendAndSetActiveMention(compose(() => setIsMentionEdited(true), tag.appendNode)),
      )(isMentionEditedRight(index)(isMentionEdited)))(
        isNextMentionExist(mentions.length)(mentionIndex),
      );

      onUpdateContent(parentElement.innerHTML);
    },
  }),
  withHandlers({
    onChangeContent: onChangeContentHandler,
    onSetRefWrapTextArea: onSetRefWrapTextAreaHandler,
  }),
  withHandlers({
    onSpecialKeys: onSpecialKeysHandler,
    onResetAllHtml: onResetAllHtmlHandler,
    onSetFocusField: onSetFocusFieldHandler,
    onTextareaKeyDown: onTextareaKeyDownHandler,
    onClickOutsideTypeMessageWrapper: onClickOutsideTypeMessageWrapperHandler,
    onClickMention: onClickMentionHandler,
    onCloseReplyMessage: onCloseReplyMessageHandler,
    onSetFileFromBuffer: onSetFileFromBufferHandler,
  }),
  withHandlers({
    onPaste: onPasteHandler,
  }),
  lifecycle({
    shouldComponentUpdate(prevProps) {
      return difference(values(omit(['children', this.props])), values(omit(['children', prevProps])));
    },
    componentDidMount() {
      const {
        getRef, onSpecialKeys, onPaste, onClickOutsideTypeMessageWrapper, windowWidth,
        contentFieldInStore, onSetMessageContent, isEditing,
      } = this.props;
      const isDesktopTextArea = cond([
        [allPass([isNotNil, always(windowWidth > WINDOW_WIDTH.MEDIUM)]), Maybe.Just],
        [T, Maybe.Nothing],
      ]);
      when(notEqual(true))(() => tap(onSetMessageContent, contentFieldInStore))(isEditing);
      document.addEventListener('click', onClickOutsideTypeMessageWrapper);
      requestAnimationFrame(() => {
        Maybe.maybe(always('Empty'), compose(
          setCaretToTheEndPosition,
          identity,
        ))(isDesktopTextArea(getRef('typeMessage')));
      });
      setTimeout(() => {
        const typeMessageRef = getRef('typeMessage');
        if (typeMessageRef) {
          typeMessageRef.addEventListener('keydown', onSpecialKeys);
          typeMessageRef.addEventListener('keyup', onSpecialKeys);
          typeMessageRef.addEventListener('paste', onPaste);
        }
      });
    },
    componentDidUpdate(prevProps) {
      const { replyMessage, getRef } = this.props;
      if (notEqual(
        propIdOrNull(replyMessage),
        propIdOrNull(prevProps.replyMessage),
      )) {
        const typeMessageRef = getRef('typeMessage');
        if (typeMessageRef) {
          typeMessageRef.focus();
        }
      }
    },
    componentWillUnmount() {
      const {
        getRef, onSpecialKeys, onPaste, onClickOutsideTypeMessageWrapper,
      } = this.props;
      document.removeEventListener('click', onClickOutsideTypeMessageWrapper);
      const typeMessageRef = getRef('typeMessage');
      if (typeMessageRef) {
        typeMessageRef.removeEventListener('keyup', onSpecialKeys);
        typeMessageRef.removeEventListener('keydown', onSpecialKeys);
        typeMessageRef.removeEventListener('paste', onPaste);
      }
    },
  }),
);

export default enhance(TypeMessage);
