import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ScrollWrapper, FormsCollection, EmptyResult } from '../../../../../ui-kit';
import { UsersList, DeleteUserModal } from './components';
import { settingUsersSelectors } from '../../../../../state/settingUsers';
import { permissionsActions } from '../../../../../state/permissions';
import './style.sass';

/**
 * @param gridName {string}
 * @param pagination {object}
 * @param onSortBy {function}
 * @param sort {string}
 * @param order {string}
 * @param searchValue {string}
 * @param isPending {boolean}
 * @param onSearch {function}
 * @param setSearchValue {function}
 * @returns {JSX.Element}
 * @constructor
 */

const SettingUsersContainer = ({
  gridName,
  pagination,
  onSortBy,
  sort = '',
  order = '',
  searchValue,
  onSearch,
  setSearchValue,
}) => {
  const [selectedUser, setSelectedUser] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const usersList = useSelector(settingUsersSelectors.getSettingUsersList);
  const usersEntities = useSelector(settingUsersSelectors.getSettingUsersEntities);
  const usersHasMore = useSelector(settingUsersSelectors.getSettingUsersHasMore);
  const usersCount = useSelector(settingUsersSelectors.getSettingUsersCount);
  const isPending = useSelector(settingUsersSelectors.getSettingUsersPending);

  const getRolesRequest = useCallback((value) => {
    dispatch(permissionsActions.getRolesRequest(value));
  });

  const onDeleteUser = useCallback((user) => {
    setIsOpenModal(true);
    setSelectedUser(user);
  });

  useEffect(() => {
    getRolesRequest();
  }, []);
  return (
    <ScrollWrapper>
      <div className="settingUsers">
        <div className="row settingUsers__search-row">
          <FormsCollection.Search
            className="search-field--fill"
            placeholder="Search by name, username, e-mail"
            name="search-users"
            value={searchValue}
            onChange={setSearchValue}
            onClick={onSearch}
            onSearchByEnter={onSearch}
          />
        </div>
        {usersList.length !== 0 ? (
          <UsersList
            gridName={gridName}
            pagination={pagination}
            onSortBy={onSortBy}
            sort={sort}
            order={order}
            isLoading={isPending}
            usersList={usersList}
            usersHasMore={usersHasMore}
            usersCount={usersCount}
            usersEntities={usersEntities}
            searchName={searchValue}
            onDeleteUser={onDeleteUser}
          />
        ) : <EmptyResult text="No search results found" /> }

      </div>
      <DeleteUserModal
        selectedUser={selectedUser}
        pagination={pagination}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
      />
    </ScrollWrapper>
  );
};

export default SettingUsersContainer;
