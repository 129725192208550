/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect/lib/index';
import {
  pathOr, identity, memoizeWith, propOr,
} from 'ramda';

const getActiveTask = createSelector(
  state => pathOr({}, ['task', 'entities', 'data'], state),
  identity,
);

const getActiveTaskPending = createSelector(
  pathOr(false, ['pending', 'getActiveTaskRequest']), identity,
);

const getTaskComments = createSelector(
  pathOr({}, ['task', 'entities', 'comments']), identity,
);

const getTaskCommentsList = createSelector(
  pathOr([], ['task', 'result', 'comments']), identity,
);

const getComment = createSelector(
  getTaskComments,
  comments => memoizeWith(identity, id => pathOr({}, [id], comments)),
);

const getHasMoreTaskComments = createSelector(pathOr(false, ['task', 'entities', 'comments', 'hasMore']), identity);
const getTimeEntries = createSelector(pathOr({}, ['task', 'entities', 'time_entries']), identity);
const getTimeEntriesList = createSelector(pathOr([], ['task', 'result', 'time_entries']), identity);

const getAddTaskCommentRequestPending = createSelector(pathOr(false, ['pending', 'addTaskCommentRequest']), identity);
const getShowMoreTaskCommentsRequestPending = createSelector(pathOr(false, ['pending', 'getShowMoreTaskCommentsRequest']), identity);
const getTaskCommentsRequestPending = createSelector(pathOr(false, ['pending', 'getTaskCommentsRequest']), identity);
const getTimeEntriesRequestPending = createSelector(pathOr(false, ['pending', 'getTimeEntriesRequest']), identity);
const getAddTimeEntryRequestPending = createSelector(pathOr(false, ['pending', 'addTimeEntryRequest']), identity);
const getGetTaskFilesRequestPending = createSelector(pathOr(false, ['pending', 'getTaskFilesRequest']), identity);


const getTimeEntry = createSelector(
  getTimeEntries,
  timeEntries => memoizeWith(identity, id => pathOr({}, [id], timeEntries)),
);

const getTaskFilesList = createSelector(
  pathOr([], ['task', 'result', 'files']), identity,
);

const getTaskFilesEntities = createSelector(
  pathOr({}, ['task', 'entities', 'files']), identity,
);

const getTaskFilesHasMore = createSelector(
  pathOr(false, ['task', 'entities', 'files', 'hasMore']), identity,
);

const getTaskFilesCount = createSelector(
  pathOr(0, ['task', 'entities', 'files', 'count']), identity,
);

const getTaskLogsByTaskId = createSelector(
  pathOr([], ['task', 'taskLogs']),
  taskLogs => memoizeWith(identity, taskId => propOr({}, taskId, taskLogs)),
);


export {
  getTaskFilesList,
  getTaskFilesEntities,
  getTaskFilesHasMore,
  getTaskFilesCount,
  getGetTaskFilesRequestPending,
  getActiveTask,
  getActiveTaskPending,
  getTaskComments,
  getTaskCommentsList,
  getAddTaskCommentRequestPending,
  getShowMoreTaskCommentsRequestPending,
  getTaskCommentsRequestPending,
  getHasMoreTaskComments,
  getTimeEntries,
  getTimeEntriesList,
  getTimeEntriesRequestPending,
  getAddTimeEntryRequestPending,
  getTimeEntry,
  getComment,
  getTaskLogsByTaskId,
};
