import {
  compose, getContext, lifecycle, pure, withHandlers, withProps, withState,
} from 'recompose';
import {
  pathOr, propOr, startsWith,
} from 'ramda';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ClientsItem from './clientsItem';
import { clientsSelectors } from '../../../../state/clients';
import { uiActions } from '../../../../state/ui';
import { withRefs, withWindowWidth } from '../../../../utils/enchancers';
import { getFullName } from '../../../../utils/helpers/userHelpers';

const fixUrl = str => (startsWith('http', str) ? str : `http://${str}`);

const mapStateToProps = (state, { clientId }) => ({
  client: clientsSelectors.getClientById(state)(clientId),
  pinnedClient: clientsSelectors.getPinnedClientById(state)(clientId),
});

const mapDispatchToProps = {
  openModal: uiActions.openModal,
};

const onToggleClientHandler = ({ toggleActiveTab, client }) => () => toggleActiveTab(client.id);

const onSetRefHandler = ({ setRef, isInitialOpen }) => refName => (e) => {
  if (isInitialOpen) {
    setRef(refName, e);
  }
};

const onActionHandler = modalName => ({
  openModal, setSelectedClientId, client,
}) => () => {
  setSelectedClientId(client.id);
  openModal(modalName);
};


const enhance = compose(
  getContext({
    setSelectedClientId: PropTypes.func.isRequired,
    scrollWrapperRef: PropTypes.any,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['tasks', 'common']),
  pure,
  withRefs(),
  withWindowWidth(),
  withState('isInitialOpen', 'setIsInitialOpen', ({ isOpen }) => isOpen),
  withProps(({ client, pinnedClient }) => {
    const getContactField = property => pathOr('', ['contact', property], client);
    const companyUrl = getContactField('company_url');
    const fixedUrl = companyUrl ? fixUrl(companyUrl) : null;
    return {
      clientName: getFullName(client),
      isPinned: !!pinnedClient,
      getContactField,
      companyUrl: fixedUrl,
    };
  }),
  withHandlers({
    onCreateTaskModal: onActionHandler('createClientTaskModal'),
    onEditTaskModal: onActionHandler('editPartOfTaskClientModal'),
    onEditFullTaskModal: onActionHandler('editTaskModal'),
    onDeleteTaskModal: onActionHandler('deleteClientTaskModal'),
    onToggleClient: onToggleClientHandler,
    onSetClientRowRef: onSetRefHandler('active-client'),
    onSetClientContentRef: onSetRefHandler('active-client-content'),
  }),
  lifecycle({
    componentDidUpdate() {
      const {
        scrollWrapperRef, getRef, setIsInitialOpen, isInitialOpen,
      } = this.props;
      const activeClientRef = getRef('active-client');
      const activeClientContentRef = getRef('active-client-content');
      if (isInitialOpen && scrollWrapperRef && activeClientRef && activeClientContentRef) {
        requestAnimationFrame(() => {
          scrollWrapperRef.scrollTop(propOr(0, 'offsetTop', activeClientRef), propOr(0, 'offsetTop', activeClientRef));
        });
        setIsInitialOpen(false);
      }
    },
  }),
);

export default enhance(ClientsItem);
