import React from 'react';
import PropTypes from 'prop-types';
import { ButtonsCollection } from '../../../../ui-kit';
import { t } from '../../../../utils/helpers/translateHelpers';
import { PIPELINE_TYPES } from '../../../../constants/crm';
import './style.sass';

const getLeadPipelineStatusClassName = (
  index,
  currentPipelineStatusIndex,
  currentStatusType,
  type,
) => {
  const baseClassName = 'statusOfLeadsControl__status-btn';

  const getClassNameByCurrentStatus = () => {
    const isWonOrPreviousStatus = (
      currentStatusType === PIPELINE_TYPES.WON || index < currentPipelineStatusIndex
    );
    if (currentStatusType === PIPELINE_TYPES.LOST) {
      return `${baseClassName}--lost`;
    }
    if (isWonOrPreviousStatus && type !== PIPELINE_TYPES.LOST) {
      return `${baseClassName}--done`;
    }
    if (index === currentPipelineStatusIndex) {
      return `${baseClassName}--current`;
    }
    return '';
  };

  const getTypeClassName = () => {
    switch (type) {
      case PIPELINE_TYPES.IN_PROGRESS:
        return 'type-in-progress';
      case PIPELINE_TYPES.WON:
        return 'type-won';
      case PIPELINE_TYPES.LOST:
        return 'type-lost';
      default:
        return '';
    }
  };
  return `${baseClassName} ${getClassNameByCurrentStatus()} ${baseClassName}--${getTypeClassName()}`;
};

const getStatusTitle = (type) => {
  const commonTranslates = key => t('common', key);
  switch (type) {
    case PIPELINE_TYPES.IN_PROGRESS:
      return commonTranslates('Mark as In progress');
    case PIPELINE_TYPES.LOST:
      return commonTranslates('Mark as Lost');
    case PIPELINE_TYPES.WON:
      return commonTranslates('Mark as Won');
    default:
      return null;
  }
};

const StatusOfLeadsControl = ({
  pipelineStatuses, onChangeStatus, currentPipelineStatusIndex,
  currentPipelineStatusType,
}) => (
  <div className="statusOfLeadsContainer">
    <div className="statusOfLeadsControl">
      {
        pipelineStatuses.map((status, index) => (
          <ButtonsCollection.ButtonBrill
            key={status.id}
            title={getStatusTitle(status.type)}
            className={
                getLeadPipelineStatusClassName(
                  index,
                  currentPipelineStatusIndex,
                  currentPipelineStatusType,
                  status.type,
                )
              }
            onClick={onChangeStatus(status.id, status.type)}
            style={{
              zIndex: pipelineStatuses.length - index,
            }}
          >
            {status.title}
          </ButtonsCollection.ButtonBrill>
        ))
      }
    </div>
  </div>
);

StatusOfLeadsControl.propTypes = {
  currentPipelineStatusIndex: PropTypes.number.isRequired,
  currentPipelineStatusType: PropTypes.number.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  pipelineStatuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default StatusOfLeadsControl;
