import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ButtonsCollection, Header } from '@/ui-kit';
import { ArticleForm } from '@/views/knowledgeBase/components/articleForm';
import api from '../../../../api/knowledgeBase.service';
import './style.sass';
import { callNotification } from '@/utils/helpers/notifies';

export const CreateArticle = () => {
  const { t } = useTranslation();

  const { push } = useHistory();
  const { subcategoryId } = useParams();

  const form = useForm({
    defaultValues: {
      title: '',
      content: '',
      categoryIds: [Number(subcategoryId)],
    },
  });

  const onClose = useCallback(() => {
    push(`/knowledge-base/subcategory/${subcategoryId}/`)
  }, [subcategoryId]);

  const onSubmit = useCallback(async (values) => {
    try {
      await api.createKnowledgeBaseArticle({ files: [], ...values });

      callNotification.success('Article has been created.');

      onClose();
    } catch (e) {
      callNotification.error('Error with creating article.');
      console.error(e);
    }
  }, []);

  return (
    <>
      <Header hideToggleRightSideBar className="create-article__header">
        <h1 className="weight--medium title--sm title-page">
          <ButtonsCollection.ButtonIcons
            title={t('Go back to subcategory')}
            renderMobileButton
            className="create-article__header__head-back"
            onClick={onClose}
          >
            <span className="icon-expand-arrow-left" />
          </ButtonsCollection.ButtonIcons>
          Create new article
        </h1>
      </Header>
      <div className="main-container__content create-article__container">
        <FormProvider {...form}>
          <ArticleForm
            onAction={onSubmit}
            submitButtonText={t('Create article')}
          />
        </FormProvider>
      </div>
    </>
  )
}
