import React from 'react';
import PropTypes from 'prop-types';
import { identity, prop } from 'ramda';

import {
  Avatar, BrillMask, OnlineStatusLabel,
} from '../../../../../../ui-kit';
import './style.sass';
import { getFullName } from '../../../../../../utils/helpers/userHelpers';
import { formatDate } from '../../../../../../utils/helpers/commonHelpers';
import ControlsCommentCard from './controlsCommentCard/controlsCommentCard';
import EditableComment from './editableComment';

const CommentItem = ({
  content, author, createdAt, authorAvatarSrc, onRenderContent, comment, isShowActions,
  onToggleShowActions, onHideActions, onDeleteComment, isEditing, onEditingThisComment,
  setRefComment, getRef, onCloseEditingThisComment, onSaveComment, isCommentOwner,
}) => (
  <>
    <div onMouseLeave={onHideActions} ref={setRefComment} className="row-flex comment__item">
      <div className="comment-card">
        <div className="comment-card__head">
          <BrillMask
            outsideChildren={<OnlineStatusLabel status={author.is_online} />}
          >
            <Avatar src={authorAvatarSrc} alt={getFullName(author)} />
          </BrillMask>
          <div className="comment-card__info">
            <div className="comment-card__name">
              {getFullName(author)}
            </div>
            <span className="comment-card__date">
              <span className="comment-card__date__date">{formatDate(createdAt)}</span>
              <span className="comment-card__date__time">{formatDate(createdAt, 'h:m:s A')}</span>
            </span>
          </div>
          {
            isCommentOwner && (
              <ControlsCommentCard
                isShowActions={isShowActions}
                onClickShowActions={onToggleShowActions}
                vectorDropDown={false}
                chooseVector={identity}
                onEditMessage={onEditingThisComment}
                onClickDelete={onDeleteComment}
              />
            )
          }
        </div>
        <div className="comment-card__body">
          {
            isEditing ? (
              <EditableComment
                onKeyDownSaveContent={() => {}}
                onChangeContent={identity}
                onClickSaveContent={onSaveComment}
                setMessageAsEditable={identity}
                onCloseEditMessage={onCloseEditingThisComment}
                onMount={identity}
                onWillUnMount={identity}
                onKeyDown={identity}
                content={onRenderContent(content,
                  identity,
                  comment.id,
                  { isEditing: true })
                }
                id={comment.id}
                messageRef={getRef('commentElement')}
              />
            ) : onRenderContent(content, identity, prop('id', comment), {})
        }
        </div>
      </div>
    </div>
  </>
);

CommentItem.propTypes = {
  author: PropTypes.instanceOf(Object).isRequired,
  createdAt: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  authorAvatarSrc: PropTypes.string.isRequired,
  onRenderContent: PropTypes.func.isRequired,
  comment: PropTypes.instanceOf(Object).isRequired,
  isShowActions: PropTypes.bool.isRequired,
  onToggleShowActions: PropTypes.func.isRequired,
  onHideActions: PropTypes.func.isRequired,
  onDeleteComment: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onEditingThisComment: PropTypes.func.isRequired,
  setRefComment: PropTypes.func.isRequired,
  getRef: PropTypes.func.isRequired,
  onCloseEditingThisComment: PropTypes.func.isRequired,
  onSaveComment: PropTypes.func.isRequired,
  isCommentOwner: PropTypes.bool.isRequired,
};

export default CommentItem;
