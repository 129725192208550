import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection, ModalWindow, FormsCollection } from '../../../ui-kit';
import { FilesField, FilesItem } from './components/index';
import './style.sass';

const AttachFilesModal = ({ isOpen, closeModal }) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={() => closeModal('attachFilesModalMessenger')}
    modalName="attachFiles"
    title="Upload file"
    className="modal-window__attach-files"
  >
    <FormsCollection.FormWrapper
      handleSubmit={() => {}}
      className="attach-files"
    >
      <FilesField pending={false} />
      <div className="attach-files__uploaded-files">
        <FilesItem type="image">

          File screenshot 2018-12-24 at 18.05.26.jpeg
        </FilesItem>
        <FilesItem type="other">

          File screenshot 2018-12-24 at 18.05.26.jpeg
        </FilesItem>
        <FilesItem type="other">

          File screenshot 2018-12-24 at 18.05.26.jpeg
        </FilesItem>
      </div>
      <div className="button-group">
        <ButtonsCollection.ButtonBrill
          className="button--md attach-files__button-save button-brill--fill"
        >

          UPLOAD
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);
AttachFilesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default AttachFilesModal;
