import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { uiActions, uiSelectors } from '../../../../state/ui';
import { pipelinesSelectors } from '../../../../state/pipelines';
import DeletePipelineModal from './deletePipelineModal';

const mapStateToProps = (state, { selectedPipelineId }) => ({
  isOpen: uiSelectors.getModal(state)('deletePipelineModal'),
  pipeline: pipelinesSelectors.getPipelineById(state)(selectedPipelineId),
  isPending: pipelinesSelectors.deletePipelinePendingRequest(state),
});

const mapDispatchToProps = {
  closeModal: () => uiActions.closeModal('deletePipelineModal'),
};

const onCloseHandler = ({
  closeModal,
}) => () => {
  closeModal();
};


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onClose: onCloseHandler,
  }),
);

export default enhance(DeletePipelineModal);
