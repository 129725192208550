import PROJECT_TABS from '../../constants/tabNavigation';

const BUTTON_LABELS = {
  [PROJECT_TABS.NOTES]: 'New note',
  [PROJECT_TABS.REPOSITORY]: 'New repository',
  [PROJECT_TABS.TASKS]: 'New task',
  [PROJECT_TABS.TASK]: 'New task',
};

const MODALS_NAME = {
  [PROJECT_TABS.NOTES]: 'createNoteModal',
  [PROJECT_TABS.REPOSITORY]: 'createRepositoryModal',
  [PROJECT_TABS.TASKS]: 'createTaskModal',
  [PROJECT_TABS.TASK]: 'createTaskModal',
};

export {
  BUTTON_LABELS,
  MODALS_NAME,
};
