import React, { useState } from 'react';
import { MobileFiltersContainer } from '../../../../../ui-kit';
import { WINDOW_WIDTH } from '../../../../../constants/ui';
import './style.sass';
import { FilterFields, FiltersNav } from './components';

/**
 *
 * @param activeView{string}
 * @param onLoadStatistics{function}
 * @param onExportButtonClick{function}
 * @param setUsersId{boolean}
 * @returns {JSX.Element}
 * @constructor
 */
export const Filters = ({
  activeView = '',
  onLoadStatistics,
  onExportButtonClick,
  setUsersId = false,
}) => {
  const [mobileFiltersOpen, setMobileFilterOpen] = useState(null);
  return (
    <div className="activity-filters">
      <div className="activity-filters__row">
        <FiltersNav
          onExportButtonClick={onExportButtonClick}
          activeView={activeView}
        />
        <div className="activity-filters__group-fields">
          <MobileFiltersContainer
            title="Filter"
            onWidth={WINDOW_WIDTH.X_SMALL}
            isOpenDefault={mobileFiltersOpen}
            onToggle={setMobileFilterOpen}
          >
            <FilterFields
              onClose={() => setMobileFilterOpen(false)}
              activeView={activeView}
              setUsersId={setUsersId}
              onLoadStatistics={onLoadStatistics}
            />
          </MobileFiltersContainer>
        </div>
      </div>
    </div>
  );
};
