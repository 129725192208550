import React from 'react';

import './style.sass';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { uiSelectors } from '../../state/ui';
import { Copyright, ButtonsCollection, Header } from '../../ui-kit';
import { userSelectors } from '../../state/user';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const PageError = () => {
  const errorPage = useSelector(uiSelectors.getIsPageNotFoundStatus);
  const user = useSelector(userSelectors.getUserData);

  const history = useHistory();
  const deletePage404Handler = () => history.push(`/${errorPage?.href}`) || history.push('/');
  const { t } = useTranslation('common');

  const LABEL = user?.id ? errorPage?.label || 'All projects' : 'Go back';
  const CODE = errorPage?.code || 404;
  const TITLE = errorPage?.title || 'Oops, seems like you got lost';
  const SUBTITLE = errorPage?.subTitle || '';

  return (
    <div className="page-wrapper page-error">
      <Header empty />
      <div className="page-error__wrapper">
        <div className="page-error__row">
          <h1 className="page-error__code">{t(CODE)}</h1>
          <h2 className="page-error__title">{t(TITLE)}</h2>
          <b className="page-error__help-text">{t(SUBTITLE)}</b>
          <div className="button-group">
            <ButtonsCollection.ButtonBrill
              className="button--md page-error__back-button"
              onClick={deletePage404Handler}
            >
              {t(LABEL)}
            </ButtonsCollection.ButtonBrill>
          </div>
        </div>
      </div>
      <Copyright />
    </div>
  );
};
