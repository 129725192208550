/* eslint-disable prefer-const */
import {
  compose, lifecycle, withContext, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import {
  curry,
  path,
  pathOr,
  without,
  append,
  objOf,
  prop,
  not,
  pipe,
  pick,
} from 'ramda';
import PropTypes from 'prop-types';

import { isNotEmpty } from 'ramda-extension';
import { PRELOADER_DIMENSION } from '../../constants/ui';
import { SPRINTS_LIMIT } from './constants';
import {
  withRefs, preloaderWhileLoading, withUrlParams, withFilters, withAgendaColumnSwipe,
} from '../../utils/enchancers';
import { getArrayFromEntities } from '../../utils/helpers/uiHelpers';
import {
  setFilterForSingleParam,
  checkIfValueIncluded,
  filterAndTransformCondition,
  handlerFilter,
  makeNewFilterValue,
  filterParamKey,
  updateFilterAndUrlParams,
} from '../../utils/helpers/uiComponentHelpers/filters';

import { projectActions, projectSelectors } from '../../state/project';
import AgendaBoard from './agendaBoard';

const mapStateToProps = state => ({
  agendaStatusesList: projectSelectors.getAgendaStatusesList(state),
  isAgendaRequestPending: projectSelectors.getAgendaTasksRequestPending(state),
  tasks: projectSelectors.getProjectSprintTasks(state),
  sprints: getArrayFromEntities(projectSelectors.getProjectSprintsTasks(state)),
});

const mapDispatchToProps = ({
  getTasksRequest: projectActions.getAgendaTasksRequest,
  getTasksBySprint: projectActions.getAgendaTasksBySprintRequest,
  getUsersWithTasks: projectActions.getUsersWithTasksRequest,
  getSprintsRequest: projectActions.getSprintsRequest,
});

const changeParamKey = (e) => {
  let [key, value] = e;
  if (key === 'sprint') {
    key = 'sprint_id';
  }
  if (value === null) {
    value = 'null';
  }
  return ({ field: key, value });
};

const onSelectSprintHandler = ({
  setFilters, onSetUrlParam, mergeFilters,
}) => (item) => {
  const sprint = pathOr(null, ['val', 'id'], item);
  return setFilterForSingleParam(mergeFilters, setFilters, onSetUrlParam, 'sprint', sprint);
};

const onResetSprintHandler = ({ setFilters, onResetUrlParam, mergeFilters }) => () => {
  setFilterForSingleParam(mergeFilters, setFilters, onResetUrlParam, 'sprint', null);
};

const onSelectUserHandler = ({
  onSetUrlParam, onResetUrlParam, setFilters, getFilter, mergeFilters,
}) => (userId) => {
  const selectedAssignee = getFilter(null, 'assignee');

  const makeAssigneeFilter = makeNewFilterValue(mergeFilters, 'assignee');
  const addAssigneeFilterAndUrl = updateFilterAndUrlParams(setFilters, onSetUrlParam);
  const resetAssigneeFilterAndUrl = updateFilterAndUrlParams(setFilters, onResetUrlParam);
  const objOfAssignee = objOf('assignee');

  const addAssignee = curry((assignees, assignee) => addAssigneeFilterAndUrl(
    makeAssigneeFilter(append(assignee))(assignees),
    objOfAssignee([assignee]),
  ));

  const resetAssignee = curry((assignees, assignee) => resetAssigneeFilterAndUrl(
    makeAssigneeFilter(without([assignee]))(assignees),
    objOfAssignee([assignee]),
  ));

  return handlerFilter(
    checkIfValueIncluded(selectedAssignee),
    resetAssignee(selectedAssignee),
    addAssignee(selectedAssignee),
    userId,
  );
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRefs(),
  withUrlParams({
    params: { concat: ['assignee'], arrayFields: ['assignee'] },
    saveFilterByName: ({ match: { params: { projectId } } }) => `agenda-of-project${projectId}`,
  }),
  withFilters({
    initial: ({ getUrlParam }) => ({
      sprint: getUrlParam(['sprint']),
      assignee: getUrlParam(['assignee'], []),
      category_id: getUrlParam(['category']),
    }),
  }),
  withProps(({ match, getTasksRequest }) => ({
    projectId: path(['params', 'projectId'], match),
    getTasksWithFilters: ({ filters }) => {
      const projectId = path(['params', 'projectId'], match);
      const where = filterAndTransformCondition(filterParamKey, changeParamKey, filters);
      getTasksRequest({ projectId, where });
    },
  })),
  withContext({
    isAgendaRequestPending: PropTypes.bool.isRequired,
  }, pick(['isAgendaRequestPending'])),
  withHandlers({
    onSelectSprint: onSelectSprintHandler,
    onSelectUser: onSelectUserHandler,
    onResetSprint: onResetSprintHandler,
  }),
  lifecycle({
    componentDidMount() {
      const { projectId, getUsersWithTasks, getSprintsRequest } = this.props;
      getUsersWithTasks({ projectId });
      getSprintsRequest({ projectId, limit: SPRINTS_LIMIT });
    },
    componentDidUpdate(prevProps) {
      const { getTasksWithFilters, filters } = this.props;
      // todo: need solution for saving in storage more that one assignee
      if (filters !== prevProps.filters && isNotEmpty(filters)) {
        getTasksWithFilters({ filters });
      }
    },
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.MIDDLE,
    alignContainerCenter: true,
    isLoading: pipe(
      prop('isAgendaRequestPending'),
      not,
    ),
  }),
  withAgendaColumnSwipe({ range: 50 }),
);

export default enhance(AgendaBoard);
