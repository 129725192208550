import React from 'react';
import PropTypes from 'prop-types';

import {
  ConfirmModal,
} from '../../../../ui-kit';
import { getFullName } from '../../../../utils/helpers/userHelpers';

const UnpinContactModal = ({
  isOpen,
  onClose,
  pinnedContact,
  onUnpin,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    actionTitle="Unpin"
    onAction={onUnpin}
    onClose={onClose}
    title="Unpin contact"
  >
    Are you sure you want to unpin
    <b>{getFullName(pinnedContact)}</b>
    contact?
  </ConfirmModal>
);

UnpinContactModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUnpin: PropTypes.func.isRequired,
  pinnedContact: PropTypes.shape({}).isRequired,
};

export default UnpinContactModal;
