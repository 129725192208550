import React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonsCollection, Layouts, FormsCollection } from '../../../ui-kit';
import './style.sass';

/**
 * @returns {JSX.Element}
 * @constructor
 */

export const ForgotPasswordSuccess = () => {
  const { t } = useTranslation('validation');
  return (
    <Layouts.Auth>
      <div className="auth-wrapper__wrap">
        <FormsCollection.FormWrapper>
          <div className="auth-wrapper__form-contain">
            <h2 className="title weight--bold title--md text-align--center">
              {t('Email has been sent successfully!')}
            </h2>
            <div className="auth-wrapper__helper-text--to-field">{t('Check your inbox for further instructions')}</div>
            <div className="button-group">
              <ButtonsCollection.ButtonBrill
                className="auth-wrapper__button--with-border button--fill"
                borderColor="#26b5ed"
                asLink
                href="/"
                borderWidth="2"
              >
                {t('close')}
              </ButtonsCollection.ButtonBrill>
            </div>
          </div>
        </FormsCollection.FormWrapper>
      </div>
    </Layouts.Auth>
  );
};
