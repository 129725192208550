import React from 'react';

/**
 *
 * @param innerProps {object}
 * @returns {JSX.Element}
 * @constructor
 */

const ClearIndicator = ({ innerProps }) => (
  <button className="close-button" type="button" onClick={innerProps.onMouseDown}>
    <span className="icon-close" />
  </button>
);

export default ClearIndicator;
