import {
  compose, withHandlers, getContext, branch, renderNothing,
} from 'recompose';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import PinnedClientItem from './pinnedClientItem';
import { clientsSelectors } from '../../../../../../state/clients';
import { uiActions } from '../../../../../../state/ui';

const mapStateToProps = (state, { clientId }) => ({
  pinnedClient: clientsSelectors.getPinnedClientById(state)(clientId),
});

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
};
const onUnpinHandler = ({
  setSelectedClientId, setOpenModal, clientId,
}) => () => {
  setSelectedClientId(clientId);
  setOpenModal('unpinClientModal');
};
const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ pinnedClient }) => !pinnedClient || isEmpty(pinnedClient), renderNothing),
  getContext({
    setSelectedClientId: PropTypes.func,
    setSelectedPinnedClientId: PropTypes.func,
  }),
  withHandlers({
    onUnpin: onUnpinHandler,
  }),
);
export default enhance(PinnedClientItem);
