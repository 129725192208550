import {
  branch,
  compose, renderNothing, withHandlers,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isNil } from 'ramda';

import PinTaskModal from './unpinLeadModal';
import { uiActions, uiSelectors } from '../../../../state/ui';
import { leadsSelectors, leadsActions } from '../../../../state/leads';

const mapDispatchToProps = {
  onCloseModal: uiActions.closeModal,
  unpinLeadRequest: leadsActions.unpinLeadRequest,
  getPinnedLeads: leadsActions.getPinnedLeadsRequest,
};

const mapStateToProps = (state, { selectedLeadId }) => ({
  isOpen: uiSelectors.getModal(state)('unpinLeadModal'),
  pinnedLead: leadsSelectors.getPinnedLeadById(state)(selectedLeadId),
});


const onUnpinHandler = ({
  unpinLeadRequest, onClose, selectedLeadId, getPinnedLeads,
}) => () => unpinLeadRequest({
  leadId: selectedLeadId,
}, {
  callbacks: {
    success: () => {
      onClose();
      getPinnedLeads();
    },
  },
});

const onCloseHandler = ({ onCloseModal }) => () => onCloseModal('unpinLeadModal');

const enhance = compose(
  branch(
    ({ selectedLeadId }) => isNil(selectedLeadId),
    renderNothing,
  ),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withHandlers({
    onClose: onCloseHandler,
  }),
  withHandlers({
    onUnpin: onUnpinHandler,
  }),
);


export default enhance(PinTaskModal);
