import {
  take,
  fork,
  put,
  select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { sagasManager } from '../../utils';
import { uiTypes } from '.';
import { getAppTitle, getAppUnreadMessages } from './selectors';

function* displayErrorPage() {
  while (true) {
    yield take(uiTypes.DISPLAY_ERROR_PAGE);
    yield put(push('/page-not-found'));
  }
}

function* goBackFromErrorPage() {
  while (true) {
    const { payload: href } = yield take(uiTypes.GO_BACK_FROM_ERROR_PAGE);
    yield put(push(href));
  }
}

const changeAppTitleAndIcon = (title, unread) => {
  document.title = unread ? `${unread} unread ${unread === 1 ? 'message' : 'messages'} - ${title}` : title;
  const faviconElement = document.querySelector('link[rel="shortcut icon"]');
  faviconElement.setAttribute('href', !unread ? '/favicon.ico' : '/favicon_newmessage.ico');
};

function* watchAppTitle() {
  while (true) {
    const { payload: title } = yield take(uiTypes.SET_APP_TITLE);
    const unread = yield select(getAppUnreadMessages);
    changeAppTitleAndIcon(title, unread);
  }
}

function* watchAppUnreadMessages() {
  while (true) {
    const { payload: unread } = yield take(uiTypes.SET_APP_UNREAD_MESSAGES);
    const title = yield select(getAppTitle);
    changeAppTitleAndIcon(title, unread);
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(displayErrorPage);
  yield fork(goBackFromErrorPage);
  yield fork(watchAppTitle);
  yield fork(watchAppUnreadMessages);
});
