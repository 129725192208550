import { identity } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { APP_ROUTES } from '@/constants/appRoutes';
import { ScrollWrapper, Avatar, BrillMask } from '@/ui-kit';
import { getApiUrl } from '@/utils/helpers/requestHelpers';
import { getVersionFromStorage } from '@/utils/helpers/uiHelpers';
import { ToggleButtonLeftSideNav, MainNav } from './components';
import ErrorImage from '../../assets/otherAssets/socketErrorIcon.svg';
import { logo } from '../../constants/assets';
import { uiSelectors } from '../../state/ui';
import './style.sass';
import classNames from "classnames";

/**
 * @param children {array | string | element}
 * @param onScroll {function}
 * @returns {JSX.Element}
 * @constructor
 */

const imageLogoUrl = `${getApiUrl(window.location)}/images/wetelo.png`;

export const LeftSideBar = ({
  children = '',
  onScroll = identity,
}) => {
  const isSocketError = useSelector(uiSelectors.getIsWsError);
  const version = useSelector(uiSelectors.getVersionSelector);
  const isDisplayLeftSideBar = useSelector(uiSelectors.getLeftSidebar);
  const getIsNotificationBannerSelector = useSelector(uiSelectors.getIsNotificationBannerSelector);
  const versionFromStorage = getVersionFromStorage() || null;
  const isCurrentVersion = version === versionFromStorage;
  return (
    <aside className={classNames( { 'left-side-bar__is-notification-banner': getIsNotificationBannerSelector }, 'left-side-bar')}>
      <div className="left-side-bar__logo">
        <Link to="/">
          <img src={logo} alt="Avanga" title="Avanga" />
        </Link>
        <>
          {isSocketError && (
            <img src={ErrorImage} className="left-side-bar__logo__offline" alt="Error" />
          )}
        </>
        <ToggleButtonLeftSideNav />
        <div className="left-side-bar__version hide-element--flex">
          <span className="left-side-bar__version-icon icon-hexagon-stroke" />
          Beta version
        </div>
      </div>
      <ScrollWrapper
        className="scroll-wrapper--dark left-side-bar__scroll-wrapper"
        autoHide
        onUpdate={onScroll}
      >
        <div className="short-project-info">
          <BrillMask className="short-project-info__icon">
            <Avatar src={imageLogoUrl} alt="Wetelo, Inc." />
          </BrillMask>
          <div className="short-project-info__title  hide-element--flex">
            Wetelo, Inc.
          </div>
        </div>
        <MainNav />
        <div className="left-side-bar__container">
          <div className="row hide-element--block">
            {children}
          </div>
        </div>
      </ScrollWrapper>
      <div className="left-side-bar__version--wrapper">
        <Link className="left-side-bar__version--link" to={APP_ROUTES.CHANGELOG}>
          {isDisplayLeftSideBar && `Version ${versionFromStorage || version}`}
          {isDisplayLeftSideBar && isCurrentVersion ? ''
            : <div className="left-side-bar__version--circle" />}
        </Link>
      </div>
    </aside>
  );
};
