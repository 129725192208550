import { DragDropContext } from 'react-beautiful-dnd';
import React from 'react';
import PropTypes from 'prop-types';

import { ColumnBoard } from '..';
import { WINDOW_WIDTH } from '../../../../constants/ui';


const StatusesBoard = ({
  statuses, onDragEnd, onDragStart, onDragUpdate, isDragging, updateDroppable, columnRowHeight,
  windowWidth,
}) => (
  <div
    className="agenda-board__column-row"
    style={windowWidth < WINDOW_WIDTH.SMALL ? { height: columnRowHeight } : {}}
  >
    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate} onDragStart={onDragStart}>
      {statuses.map(status => (
        <ColumnBoard
          isDragging={isDragging}
          updateDroppable={updateDroppable}
          statusId={status}
          key={`agenda - ${status}`}
        />
      ))}
    </DragDropContext>
  </div>
);

StatusesBoard.propTypes = {
  statuses: PropTypes.arrayOf(PropTypes.number).isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onDragStart: PropTypes.func.isRequired,
  onDragUpdate: PropTypes.func.isRequired,
  updateDroppable: PropTypes.number,
  isDragging: PropTypes.bool.isRequired,
  columnRowHeight: PropTypes.string.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

StatusesBoard.defaultProps = {
  updateDroppable: null,
};

export default StatusesBoard;
