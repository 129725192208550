import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import moment from 'moment';
import { ButtonsCollection, FormsCollection } from '../../../../../ui-kit';
import { DeleteTimeEntryModal, EditTimeEntryModal } from '../timeEntryCRUD';

const TimeEntryForm = ({
  t, handleChange, handleSubmit, values, errors, isPending,
}) => (
  <div className="time-entry__form-group">
    <FormsCollection.FormWrapper handleSubmit={handleSubmit} className="time-entry__form">
      <div className="time-entry__field-date">
        <label className="time-entry__label" htmlFor="time-entry__field_date">Date</label>
        <FormsCollection.DatePicker
          name="date"
          className="time-entry__field"
          onChange={handleChange}
          value={values.date}
          error={errors.date}
          touched
          maxDate={moment().toDate()}
        />
      </div>
      <div className="time-entry__field-time">
        <label className="time-entry__label" htmlFor="time-entry__field_time">Time</label>
        <FormsCollection.Input
          name="time"
          id="create-task__estimated-time"
          placeholder={t('1w 2d 21h 30m')}
          onChange={handleChange}
          className="time-entry__field time-entry__field--last"
          value={values.time}
          error={errors.time}
          touched
        />
      </div>
      <ButtonsCollection.ButtonBrill
        type="submit"
        pending={isPending}
        className="button-brill--fill time-entry__button  button--sm"
      >

        Submit
      </ButtonsCollection.ButtonBrill>
    </FormsCollection.FormWrapper>
    <DeleteTimeEntryModal />
    <EditTimeEntryModal />
  </div>
);

TimeEntryForm.propTypes = {
  t: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
};

export default TimeEntryForm;
