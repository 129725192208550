import * as permissionsActions from './actions';
import * as permissionsSelectors from './selectors';
import * as permissionsOperations from './operations';
import permissionsTypes from './types';
import reducer from './reducers';

export {
  permissionsActions,
  permissionsTypes,
  permissionsSelectors,
  permissionsOperations,
};

export default reducer;
