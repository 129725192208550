import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { prop } from 'ramda';
import AddProductForm from './addProductForm';
import withAutocomplete from '../../../../../../utils/enchancers/withAutocomplete';
import { projectsActions } from '../../../../../../state/projects';
import { clientsActions } from '../../../../../../state/clients';

const mapDispatchToProps = {
  editProject: projectsActions.editProjectRequest,
  getProductsForClient: clientsActions.getClientProductsRequest,
};


const submitForm = (formValues, {
  props: {
    clientId, editProject, getProductsForClient,
  },
}) => {
  editProject({
    id: formValues.projects.value,
    title: formValues.projects.label,
    type: formValues.projects.type,
    client_id: clientId || null,
    forClient: true,
  }, {
    callbacks: {
      success: () => getProductsForClient({ id: clientId }),
    },
  });
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => ({
      projects: {
        label: '',
        value: null,
      },
    }),
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    // validationSchema: rules,
    handleSubmit: submitForm,
  }),
  withAutocomplete({
    name: 'getProjectsAutocomplete',
    action: projectsActions.getProjectsAutocomplete,
    dataPath: prop('projects'),
    searchField: 'q',
  }),
);

export default enhance(AddProductForm);
