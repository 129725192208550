import {
  compose, lifecycle, withProps,
} from 'recompose';
import { identity } from 'ramda';
import { connect } from 'react-redux';

import { uiSelectors } from '../../state/ui';

const mapStateToProps = state => ({
  isWsError: uiSelectors.getIsWsError(state),
});

const withWsReconnection = (onRetrievedWsConnectionCb = identity,
  onDroppedWsConnectionCb = identity) => compose(
  connect(mapStateToProps),
  withProps(() => ({
    onRetrievedWsConnection: onRetrievedWsConnectionCb,
    onDroppedWsConnection: onDroppedWsConnectionCb,

  })),
  lifecycle(
    {
      componentDidUpdate(prevProps) {
        const { onDroppedWsConnection, onRetrievedWsConnection, isWsError } = this.props;
        if (isWsError !== prevProps.isWsError) {
          if (isWsError) onDroppedWsConnection(this.props);
          else onRetrievedWsConnection(this.props);
        }
      },
    },
  ),
);

export default withWsReconnection;
