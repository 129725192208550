import {
  compose, withHandlers, getContext, branch, renderNothing,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';
import PinnedContactItem from './pinnedContactItem';
import { contactsSelectors } from '../../../../../../state/contacts';
import { uiActions } from '../../../../../../state/ui';

const mapStateToProps = (state, { contactId }) => ({
  pinnedContact: contactsSelectors.getPinnedContactById(state)(contactId),
});

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
};
const onUnpinHandler = ({
  setSelectedContactId, setOpenModal, contactId,
}) => () => {
  setSelectedContactId(contactId);
  setOpenModal('unpinContactModal');
};
const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ pinnedContact }) => !pinnedContact || isEmpty(pinnedContact), renderNothing),
  getContext({
    setSelectedContactId: PropTypes.func,
    setSelectedPinnedContactId: PropTypes.func,
  }),
  withHandlers({
    onUnpin: onUnpinHandler,
  }),
);
export default enhance(PinnedContactItem);
