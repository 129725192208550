import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { ClientTasksList, ClientTab } from '..';
import { Header } from '../../../index';

const ClientItemMobile = ({
  t,
  setRef,
  client,
  onCreateTaskModal,
  onEditTaskModal,
  onEditFullTaskModal,
  onDeleteTaskModal,
}) => (
  <div className="clients-item__detail-info">
    <Header withoutControls hideToggleRightSideBar className="user-header">
      <>
        <h1 className="weight--medium title--xl title-page user-nav__title">
          <NavLink
            title="Go back to clients"
            renderMobileButton
            className="user-head-back"
            to="/crm/clients"
          >
            <span className="icon-expand-arrow-left" />
          </NavLink>
          {getFullName(client.contact)}
        </h1>
      </>
    </Header>
    <div className="main-container__content main-container__content--profile">
      <div className="clients-item__content" ref={e => setRef('active-client-content', e)}>
        <ClientTasksList
          clientId={client.id}
          t={t}
          onCreateTaskModal={onCreateTaskModal}
          onDeleteTaskModal={onDeleteTaskModal}
          onEditTaskModal={onEditTaskModal}
          onEditFullTaskModal={onEditFullTaskModal}
        />
        <ClientTab client={client} />
      </div>
    </div>
  </div>
);

ClientItemMobile.propTypes = {
  setRef: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  onCreateTaskModal: PropTypes.func.isRequired,
  onEditTaskModal: PropTypes.func.isRequired,
  onDeleteTaskModal: PropTypes.func.isRequired,
  onEditFullTaskModal: PropTypes.func.isRequired,
};

export default ClientItemMobile;
