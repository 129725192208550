import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import {
  ConfirmModal,
} from '../../../../ui-kit';

const DeleteInvoiceModal = ({
  isOpen,
  isPending,
  onClose,
  invoice,
  onDelete,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Delete"
    onAction={onDelete}
    onClose={onClose}
    title="Delete invoice"
  >
    Are you sure you want to delete
    <b>{propOr('', 'number', invoice)}</b>
    invoice?
  </ConfirmModal>
);

DeleteInvoiceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  invoice: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteInvoiceModal;
