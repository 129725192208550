import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { debounceFunc } from '@/utils/helpers/commonHelpers';

import { getBusinessProcessesRequest } from '@bp/store/bpModule/actions';

export const useFiltersRow = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const [searchValue, setSearchValue] = useState('');
  const [processesFilter, setProcessesFilter] = useState({
    order: 'desc',
    sort: 'created_at',
    sortId: 0,
  });

  const onChangeSearch = ({ target: { value } }) => {
    setSearchValue(value);
    debounceFunc(
      () => setProcessesFilter({ ...processesFilter, title: value }),
      800,
      false,
      'searchBusinessProcess',
    );
  };

  const onChangeSorting = useCallback(({ val: { order, sortBy, id } }) => {
    setProcessesFilter({ order, sort: sortBy, sortId: id });
  }, []);

  useEffect(() => {
    dispatch(getBusinessProcessesRequest(processesFilter));
  }, [processesFilter]);

  return {
    t,
    processesFilter,
    searchValue,
    onChangeSearch,
    onChangeSorting,
  };
};
