import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';

import {
  ConfirmModal,
} from '../../../ui-kit';

const PinTaskModal = ({
  isOpen,
  isPending,
  onClose,
  task,
  onPin,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Pin"
    onAction={onPin}
    onClose={onClose}
    title="Pin task"
  >

    Are you sure you want to pin
    <b>{prop('title', task)}</b>

    task?
  </ConfirmModal>
);

PinTaskModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPin: PropTypes.func.isRequired,
  task: PropTypes.shape({}).isRequired,
};

export default PinTaskModal;
