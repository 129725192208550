import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ButtonsCollection } from '../../../../ui-kit';
import { ChannelItem, CreateChannel } from './components';
import './style.sass';
import AttachFilesModal from '../attachFilesModal';

const GroupChannels = ({
  groupChannelsList,
  openModal,
  t,
}) => (
  <div className="chanel-nav-group">
    <div className="chanel-nav-group__header">
      <NavLink
        to="/messenger/channels/"
        className="chanel-nav-group__title"
      >
        {t('Channels')}
      </NavLink>
      <ButtonsCollection.ButtonPlus
        title="Create Channel"
        onClick={() => { openModal('createChannelModal'); }}
        className="chanel-nav-group__plus-button"
      />
    </div>
    <nav className="chanel-nav-group__nav">
      { groupChannelsList.map(channel => (
        <ChannelItem
          key={channel}
          channelId={channel}
        />
      ))}
    </nav>
    <CreateChannel />
    <AttachFilesModal />
  </div>
);

GroupChannels.propTypes = {
  groupChannelsList: PropTypes.arrayOf(PropTypes.number).isRequired,
  openModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default GroupChannels;
