/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { isEmpty } from 'ramda';

import { Grid } from '../../../../ui-kit';
import TaskItem from '../taskItem';
import DraggableOverlay from '../draggableOverlay';

const TasksGrid = ({
  name, isLoading, items, sort, order, onSortBy, isTaskHasSprint, id, projectId, isDisableDnd,
}) => (
  <Grid.Wrapper name={name} isLoading={isLoading} className="table-tasks__sprint-body">
    <Grid.Row className="project-task project-task__controls">
      <Grid.Col
        sort={sort}
        order={order}
        filter="id"
        onClick={() => onSortBy({ sortBy: 'id' })}
        name="#"
        className="project-task__col--id"
      />
      <Grid.Col
        filter="title"
        sort={sort}
        order={order}
        onClick={() => onSortBy({ sortBy: 'title' })}
        name="Subject"
        className="project-task__col--subject"
      />
      <Grid.Col
        sort={sort}
        order={order}
        filter="priority"
        onClick={() => onSortBy({ sortBy: 'priority' })}
        name="Priority"
        className="project-task__col--priority"
      />
      <Grid.Col
        sort={sort}
        order={order}
        filter="category_id"
        onClick={() => onSortBy({ sortBy: 'category_id' })}
        name="Category"
        className="project-task__col--category"
      />
      <Grid.Col
        sort={sort}
        order={order}
        filter="status_id"
        onClick={() => onSortBy({ sortBy: 'status_id' })}
        name="Status"
        className="project-task__col--status"
      />
      <Grid.Col
        sort={sort}
        order={order}
        filter="author"
        onClick={() => onSortBy({ sortBy: 'created_by' })}
        name="Author"
        className="project-task__col--author"
      />
      <Grid.Col
        sort={sort}
        order={order}
        filter="assignee"
        name="Assignee"
        onClick={() => onSortBy({ sortBy: 'assignee' })}
        className="project-task__col--assignee"
      />
      <Grid.Col
        sort={sort}
        order={order}
        filter="estimated"
        onClick={() => onSortBy({ sortBy: 'estimated_time' })}
        name="Estimated"
        className="project-task__col--estimation"
      />
      <Grid.Col
        name="Spent"
        className="project-task__col--spent"
      />
      <Grid.Col
        name="Done %"
        className="project-task__col--estimation"
      />
    </Grid.Row>
    <Droppable droppableId={id}>
      {provided => (
        <div
          ref={provided.innerRef}
          className="table-tasks__draggable-area"
        >
          {items.map((task, index) => (
            <Draggable
              key={`${name}-project-task-key ${task}`}
              index={index}
              isDragDisabled={isDisableDnd}
              draggableId={task}
            >
              {(providedDrag, snapshotDrag) => (
                <div
                  className="project-task__drag-wrapper"
                  ref={providedDrag.innerRef}
                  {...providedDrag.draggableProps}
                  {...providedDrag.dragHandleProps}
                >
                  <TaskItem
                    projectId={projectId}
                    isTaskHasSprint={isTaskHasSprint}
                    isItemDrag={snapshotDrag.isDragging}
                    id={task}
                  />
                </div>
              )}
            </Draggable>
          ))}
          {isEmpty(items) && (<DraggableOverlay />)}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </Grid.Wrapper>
);

TasksGrid.propTypes = {
  name: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.number),
};

TasksGrid.defaultProps = {
  isLoading: false,
  items: [],
};

export default TasksGrid;
