import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import api from '@/api/knowledgeBase.service';
import { ButtonsCollection, Header, ScrollWrapper } from '@/ui-kit';
import './style.sass';

export const Article = () => {
  const { t } = useTranslation();

  const { push } = useHistory();
  const { subcategoryId, articleId } = useParams();

  const [article, setArticle] = useState(null);

  const fetchArticle = async () => {
    const result = await api.getKnowledgeBaseArticleById(articleId);

    setArticle(result)
  };

  useEffect( () => {
    fetchArticle();
  }, []);

  useEffect(() => {
    const articleContainer = document.querySelector('.article-container__content');
    const anchorElements = articleContainer.querySelectorAll('a');
    anchorElements.forEach((anchor) => {
      anchor.setAttribute('target', '_blank');
    });
  }, [article]);

  const onClose = useCallback(() => {
    push(`/knowledge-base/subcategory/${subcategoryId}`)
  }, [subcategoryId]);

  return (
    <>
      <Header hideToggleRightSideBar className="article-header">
        <h1 className="weight--medium title--sm title-page">
          <ButtonsCollection.ButtonIcons
            title={t('Go back to subcategory')}
            renderMobileButton
            className="subcategory-header__head-back"
            onClick={onClose}
          >
            <span className="icon-expand-arrow-left" />
          </ButtonsCollection.ButtonIcons>
          {article?.title}
        </h1>
      </Header>
      <div className="main-container__content article-container">
        <ScrollWrapper>
          <div className="article-container__content ck-content">
            <div dangerouslySetInnerHTML={{ __html: article?.content }} />
          </div>
        </ScrollWrapper>
      </div>
    </>
  )
}
