import React, {
  useEffect, useState, useCallback
} from 'react';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { CardActivity, TotalTimeList } from './cardsActivity/components';
import api from '../../../../api/activity.service';
import { PieChart } from './PieChart';
import { useActivityContext } from '../context/useActivityContext';
import { ButtonsCollection } from '../../../../ui-kit'
import { userSelectors } from '../../../../state/user';
import { TYPES } from '../../../../constants/users';
import './style.sass';
import { getActivityDownloadUrl } from '../../../../utils/helpers/requestHelpers'
import { API_ROUTES } from '../../../../constants/apiRoutes';
import { callNotification } from '../../../../utils/helpers/notifies';
import { LineChart } from './LineChart';


const UsersActivity = () => {
  const { selectedUser, startDate, endDate } = useActivityContext();
  const { t } = useTranslation();
  const currentUser = useSelector(userSelectors.getUserData);
  const userId = selectedUser.value;
  

  const datesTest = {
    startDate: `${startDate}`,
    endDate: `${endDate}`,
    user_id: userId,
  };
  
  const downloadUrl = getActivityDownloadUrl(API_ROUTES.ACTIVITY.USER_REPORT,datesTest);

  const [userTotalTimeHours, setUserTotalTimeHours] = useState([]);
  const [userTotalTimeDates, setUserTotalTimeDates] = useState([]);
  const [usersTotalTimePerProjects, setUsersTotalTimePerProjects] = useState([]);
  const [usersTotalTime, setUsersTotalTime] = useState([]);

  const [requestResult, setRequestResult] = useState(null);

  const exportable = currentUser?.type !== TYPES.USER && userId && userTotalTimeHours?.length > 0;
  
  const getActivity = async () => {
    try {
      const data = await api.getUsersTotalTimeRequest(datesTest);
      setRequestResult(data);
      setUsersTotalTimePerProjects(data.totalTimePerProjects);
      setUsersTotalTime(data.totalUsersTime);
      if (userId) {
        setUserTotalTimeDates(data.userTotalTimePerDays?.labels);
        setUserTotalTimeHours(data.userTotalTimePerDays?.datasets);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveFileFromBuffer = useCallback((buffer, fileName, type) => {
    const url = window.URL.createObjectURL(new Blob([new Int8Array(buffer)], {type}));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
  })

  const handleDownload = useCallback(async () => {
    try {
      const data = await api.getStatsReport(datesTest);
      try {
        const { fileName, buffer, mimeType } = data
        saveFileFromBuffer(buffer.data, fileName, mimeType);
      }
      catch(e) {
        callNotification.error(e);
      }
    }
    catch(e) {
      console.log(e);
    }
  })

  useEffect(() => {
    getActivity();
  }, [startDate, endDate, userId]);
  return (
    <div className="cards-activity-container__row">
      <div className="cards-activity-container__col">
        {userId
        && (
          <CardActivity title={t('User total time')}>
            {exportable
            && (
            <ButtonsCollection.ButtonIcons className="user-activity__button" title="Download" onClick={handleDownload}>
              <span className="icon-download" />
            </ButtonsCollection.ButtonIcons>
          )}
          <LineChart labels={userTotalTimeDates} datasets={userTotalTimeHours} />
        </CardActivity>
        )}
        <CardActivity
          title={t('Total time per projects')}
        >
          {
            usersTotalTimePerProjects
            && (
            <TotalTimeList
              data={usersTotalTimePerProjects}
              showSublist
              fields={{
                project: true, spent: true, estimate: true, more: true,
              }}
            />
            )
          }
        </CardActivity>
      </div>
      <div className="cards-activity-container__col">
        <PieChart id={userId} dateValue={datesTest} requestResult={requestResult} />
        {
          usersTotalTime
          && (
          <CardActivity
            title={t('Total time for this users')}
          >
            <TotalTimeList
              data={usersTotalTime}
              showSublist={false}
              userId={userId}
              fields={{
                user: true, spent: true, estimate: true, more: true,
              }}
            />
          </CardActivity>
          )
        }
      </div>
    </div>
  );
};

UsersActivity.defaultProps = {
};

export default UsersActivity;
