import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import './style.sass';


const cssPropertyValueToNumber = propertyValue => parseInt(
  propertyValue
    .substr(0, propertyValue.length - 2),
  10,
);

/**
 *
 * @param children {element | string | number}
 * @param className {string}
 * @param countLines {number}
 * @returns {JSX.Element}
 * @constructor
 */

export const ShowMore = ({
  children,
  className = '',
  countLines = 3,
}) => {
  const { t } = useTranslation('common');

  const [isOpen, setIsOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const [minHeight, setMinHeight] = useState(0);

  const bodyRef = useRef({});

  const handleToggleStatus = () => setIsOpen(prevVal => !prevVal);
  const setMaxHeightHandler = value => setMaxHeight(value);
  const setMinHeightHandler = value => setMinHeight(value);

  const getHeightByIsOpen = isOpen ? maxHeight : minHeight;
  const height = maxHeight !== 0 ? getHeightByIsOpen : 'initial';
  const isMore = maxHeight > minHeight;

  const getLineHeight = element => cssPropertyValueToNumber(getComputedStyle(element).lineHeight);

  const makeShowMore = useCallback(() => {
    const bodyElement = bodyRef.current;
    const lineHeight = getLineHeight(bodyElement);
    const textHeight = bodyElement.offsetHeight;
    setMinHeightHandler(lineHeight * countLines);
    if (textHeight !== minHeight) setMaxHeightHandler(textHeight);
  }, [bodyRef, countLines, minHeight]);

  useEffect(() => {
    makeShowMore();
  }, [children]);

  return (
    <div className={`show-more ${className}`}>
      <div
        style={{ height }}
        className={classNames('show-more__body', { open: isOpen })}
        ref={bodyRef}
      >
        {children}
      </div>
      {
      isMore && (
        <div className="show-more__row">
          <button type="button" className="show-more__button" onClick={handleToggleStatus}>
            <span className="show-more__button-text">{isOpen ? t('Show less') : t('Show more')}</span>
            <span
              className="show-more__button-icon icon-expand-arrow-dn"
            />
          </button>
        </div>
      )
    }
    </div>
  );
};
