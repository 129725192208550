import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';

import { ButtonsCollection, ModalWindow, FormsCollection } from '../../../../ui-kit';
import './style.sass';

const ModalView = ({
  isOpen,
  onCloseModal,
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  errors,
  isPending,
  title,
  titleAction,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    className="crud-notes__modal"
    title={title}
  >
    <FormsCollection.FormWrapper
      handleSubmit={handleSubmit}
      className="crud-notes"
    >
      <div className="crud-notes__row">
        <FormsCollection.Input
          placeholderJump="Note title"
          className="crud-notes__title-field"
          id="crud-notes__title"
          name="title"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.title}
          touched
          error={propOr('', 'message', errors.title)}
        />
      </div>
      <div className="crud-notes__row crud-notes__row--description">
        <FormsCollection.TextArea
          name="content"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.content}
          touched
          error={propOr('', 'message', errors.content)}
          className="crud-notes__textarea"
          placeholderJump="Description"
          autoHeight
          maxHeight={190}
        />
      </div>
      <div className="button-group">
        <ButtonsCollection.ButtonBrill
          pending={isPending}
          type="submit"
          className="button--md crud-notes__button-save button-brill--fill"
        >
          {titleAction}
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);

ModalView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  isPending: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titleAction: PropTypes.string.isRequired,
};

export default ModalView;
