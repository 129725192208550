import {
  compose, getContext,
} from 'recompose';
import { connect } from 'react-redux';
import {
  pipe, pick, mergeRight,
} from 'ramda';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CreateTaskModal from './createTaskModal';
import rules from '../modalViewTasks/rules';
import withCRUDTask from '../../../../utils/enchancers/withCRUDTask';
import { setTaskData } from '../../../../utils/helpers/taskHelpers/crudHelper';
import { tasksActions } from '../../../../state/tasks';
import { clientsActions } from '../../../../state/clients';
import { STATUS, TASKS_WITHOUT_PROJECT } from '../../../../constants/tasks';

const mapDispatchToProps = ({
  addTask: tasksActions.addTaskRequest,
  getClientTasks: clientsActions.getClientTasksRequest,
  getClientLogs: clientsActions.getClientLogsRequest,
});

const enhance = compose(
  connect(null, mapDispatchToProps),
  withTranslation(['common', 'tasks']),
  getContext({
    setSelectedClientId: PropTypes.func.isRequired,
    selectedClientId: PropTypes.any,
  }),
  withCRUDTask({
    rules,
    data: () => null,
    modalName: 'createClientTaskModal',
    onSubmit: (formValues, {
      resetForm,
      props: {
        addTask, onCloseModal, initialValues, onSuccess, selectedClientId, getClientTasks,
        getClientLogs,
      },
    }) => {
      const {
        submitButton,
      } = formValues;
      addTask(
        {
          ...setTaskData({
            ...formValues,
            projectId: TASKS_WITHOUT_PROJECT,
            project_id: null,
          }),
          status_id: STATUS.TO_DO.id,
          client_id: selectedClientId,
        }, {
          section: 'project',
          callbacks: {
            success: () => {
              resetForm(initialValues);
              if (submitButton !== 'saveAndCreate') {
                onCloseModal('createClientTaskModal');
              } else {
                const leftoverData = pipe(
                  pick(['assignee']),
                  mergeRight(initialValues),
                )(formValues);
                resetForm(leftoverData);
              }
              getClientTasks({ id: selectedClientId });
              getClientLogs({ id: selectedClientId });
              if (onSuccess) {
                onSuccess();
              }
            },
          },
        },
      );
    },
  }),
);

export default enhance(CreateTaskModal);
