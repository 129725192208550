const PROJECT = 'PROJECT';
const SPRINT = 'SPRINT';
const TASK = 'TASK';
const AUTHOR = 'AUTHOR';
const ASSIGNEE = 'ASSIGNEE';
const CATEGORIES = 'CATEGORIES';
const CATEGORY = 'CATEGORY';

export default {
  GET_PROJECT_REQUEST: `GET_${PROJECT}_REQUEST`,
  GET_PROJECT_TASKS_REQUEST: `GET_${PROJECT}_TASKS_REQUEST`,
  GET_SPRINTS_REQUEST: 'GET_SPRINTS_REQUEST',
  GET_CATEGORIES_TASK_REQUEST: `GET_${CATEGORIES}_TASK_REQUEST`,
  GET_AGENDA_TASKS_REQUEST: 'GET_AGENDA_TASKS_REQUEST',
  GET_AGENDA_TASKS_BY_SPRINT_REQUEST: 'GET_AGENDA_TASKS_BY_SPRINT_REQUEST',
  GET_USERS_WITH_TASKS_REQUEST: 'GET_USERS_WITH_TASKS_REQUEST',
  GET_IMPORTANT_TASKS_REQUEST: 'GET_IMPORTANT_TASKS_REQUEST',

  SORT_PROJECT_TASKS_REQUEST: 'SORT_PROJECT_TASKS_REQUEST',
  DELETE_PROJECT_TASK_REQUEST: `DELETE_${PROJECT}_${TASK}_REQUEST`,
  DELETE_PROJECT_TASK: `DELETE_PROJECT_${TASK}`,
  DELETE_PROJECT_TASK_SUCCESS: 'DELETE_PROJECT_TASK_SUCCESS',

  ADD_SPRINT_REQUEST: `ADD_${SPRINT}_REQUEST`,
  ADD_CATEGORY_TASK_REQUEST: `ADD_${CATEGORY}_TASK_REQUEST`,
  ADD_PROJECT_MEMBERS_REQUEST: 'ADD_PROJECT_MEMBERS_REQUEST',

  DELETE_CATEGORY_TASK_REQUEST: `DELETE_${CATEGORY}_TASK_REQUEST`,
  DELETE_CATEGORY_TASK: `DELETE_${CATEGORY}_TASK`,
  DELETE_CATEGORY_TASK_ENTITY: `DELETE_${CATEGORY}_TASK_ENTITY`,
  DELETE_PROJECT_MEMBER_REQUEST: 'DELETE_PROJECT_MEMBER_REQUEST',
  DELETE_PROJECT_MEMBER: 'DELETE_PROJECT_MEMBER',
  DELETE_PROJECT_MEMBER_ENTITY: 'DELETE_PROJECT_MEMBER_ENTITY',

  UPDATE_CATEGORY_TASK_REQUEST: `UPDATE_${CATEGORY}_TASK_REQUEST`,
  SET_CATEGORY_TASK: `SET_${CATEGORY}_TASK`,
  REORDER_CATEGORY_TASK: `REORDER_${CATEGORY}_TASK`,
  REORDER_PROJECT_MEMBERS: 'REORDER_PROJECT_MEMBERS',


  SORT_SPRINT_TASKS: 'SORT_SPRINT_TASKS',
  SORT_BACKLOG_TASKS: 'SORT_BACKLOG_TASKS',
  EDIT_PROJECT: `EDIT_${PROJECT}`,
  SET_AUTHOR: `SET_${AUTHOR}`,
  SET_ASSIGNEE: `SET_${ASSIGNEE}`,
  SET_PROJECT_TASKS: `SET_${PROJECT}_TASKS`,
  SET_SPRINT_TASK: `SET_${SPRINT}_TASK`,
  SET_IMPORTANT_TASKS: 'SET_IMPORTANT_TASKS',
  SET_SPRINT: `SET_${SPRINT}`,
  SET_SPRINTS: 'SET_SPRINTS',
  REMOVE_BACKLOG_TASK: 'REMOVE_BACKLOG_TASK',
  REMOVE_SPRINT_TASK: 'REMOVE_SPRINT_TASK',
  REORDER_SPRINT_TASKS: 'REORDER_SPRINT_TASKS',
  REORDER_BACKLOG_TASKS: 'REORDER_BACKLOG_TASKS',
  REORDER_AGENDA_TASKS: 'REORDER_AGENDA_TASKS',
  SET_BACKLOG_TASK: 'SET_BACKLOG_TASK',
  SET_BACKLOG_TASK_TO_LIST: 'SET_BACKLOG_TASK_TO_LIST',
  SET_SPRINT_TASK_TO_LIST: 'SET_SPRINT_TASK_TO_LIST',
  SET_CATEGORIES_TASK: `SET_${CATEGORIES}_TASK`,
  SET_AGENDA_TASKS: 'SET_AGENDA_TASKS',
  SET_USERS_WITH_TASKS: 'SET_USERS_WITH_TASKS',
  CLEAR_PROJECT_DATA: 'CLEAR_PROJECT_DATA',
  SET_PROJECT_MEMBERS: 'SET_PROJECT_MEMBERS',

  ON_DRAG_AGENDA_END: 'ON_DRAG_AGENDA_END',

  DELETE_SPRINT_REQUEST: 'DELETE_SPRINT_REQUEST',
  DELETE_SPRINT: 'DELETE_SPRINT',
  DELETE_SPRINT_ENTITY: 'DELETE_SPRINT_ENTITY',
  REORDER_SPRINT: 'REORDER_SPRINT',
  UPDATE_SPRINT_REQUEST: 'UPDATE_SPRINT_REQUEST',

  SET_SUMMARY: 'SET_SUMMARY',

  GET_PINNED_TASKS_REQUEST: 'GET_PINNED_TASKS_REQUEST',
  SET_PINNED_TASKS: 'SET_PINNED_TASKS',

  SET_PIN_TASK: 'SET_PIN_TASK',
  RESET_PIN_TASK: 'RESET_PIN_TASK',

  GET_PROJECT_ERROR: 'GET_PROJECT_ERROR',

  GET_STATUSES_OF_TASKS_REQUEST: 'GET_STATUSES_OF_TASKS_REQUEST',
  SET_STATUSES_OF_TASKS: 'SET_STATUSES_OF_TASKS',

  ADD_STATUS_OF_TASKS_REQUEST: 'ADD_STATUS_OF_TASKS_REQUEST',
  SET_STATUS_OF_TASKS: 'SET_STATUS_OF_TASKS',

  UPDATE_STATUS_OF_TASKS_REQUEST: 'UPDATE_STATUS_OF_TASKS_REQUEST',

  DELETE_STATUS_OF_TASKS_REQUEST: 'DELETE_STATUS_OF_TASKS_REQUEST',
  DELETE_STATUS_OF_TASKS: 'DELETE_STATUS_OF_TASKS',

  UPDATE_STATUSES_PRIORITY_LIST_REQUEST: 'UPDATE_STATUSES_PRIORITY_LIST_REQUEST',
  UPDATE_STATUSES_PRIORITY_LIST: 'UPDATE_STATUSES_PRIORITY_LIST',

  ON_DRAG_TASKS_STATUS: 'ON_DRAG_TASKS_STATUS',

  SET_TASK_TO_AGENDA: 'SET_TASK_TO_AGENDA',
  DELETE_TASK_OF_AGENDA: 'DELETE_TASK_OF_AGENDA',
};

export {
  CATEGORIES,
};
