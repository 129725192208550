import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import {LeftSideBar} from "@/newContainers";
import { changeRightSidebarStatus } from '@/state/ui/actions';
import { getRightSidebar } from '@/state/ui/selectors';
import {
  Layouts, NoInternetConnection,
} from '@/ui-kit';
import { KnowledgeBase, Subcategory, CreateArticle, EditArticle, AskQuestion } from '@/views/knowledgeBase/components';
import { Article } from '@/views/knowledgeBase/components/article';
import { NavCategories } from '@/views/knowledgeBase/components/navCategories';
import { Provider } from '@/views/knowledgeBase/context/Provider';

import './style.sass';

export const KnowledgeBasePage = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const status = useSelector(getRightSidebar);
  const dispatch = useDispatch();

  useEffect(() => {
    if (status) dispatch(changeRightSidebarStatus(!status));
  }, []);

  return (
    <Layouts.Main rightSideBarStatus={false}>
      <Provider>
        <LeftSideBar>
          <NavCategories />
          <div className='question-button' onClick={() => push('/knowledge-base/ask-question')}>{t('Ask a question')}</div>
        </LeftSideBar>
        <div className='main-container'>
          <NoInternetConnection>
            <Switch>
              <Route exact path='/knowledge-base/' component={KnowledgeBase} />
              <Route exact path='/knowledge-base/subcategory/:subcategoryId/' component={Subcategory} />
              <Route exact path='/knowledge-base/subcategory/:subcategoryId/article/create' component={CreateArticle} />
              <Route exact path='/knowledge-base/subcategory/:subcategoryId/article/:articleId/edit'
                     component={EditArticle} />
              <Route exact path='/knowledge-base/subcategory/:subcategoryId/article/:articleId' component={Article} />
              <Route exact path='/knowledge-base/ask-question' component={AskQuestion} />
            </Switch>
          </NoInternetConnection>
        </div>
      </Provider>
    </Layouts.Main>
  );
}
