import React, { useCallback, useEffect, useState } from 'react';
import { path } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { push } from 'connected-react-router';


import { ButtonsCollection, Layouts, FormsCollection } from '../../../ui-kit';
import { ErrorNotification } from '../errorNotification';
import { PageError } from '../..';
import { uiActions, uiSelectors } from '../../../state/ui';
import { userActions, userSelectors } from '../../../state/user';
import { rules } from './rules';
import { WINDOW_TITLE } from '../../../constants/window-titles';
import { ROUTE_TYPES } from '../../../constants/ui';
import './style.sass';
import api from '../../../api/auth.service';
import { APP_ROUTES } from '../../../constants/appRoutes';
import { ERROR_CODES_API } from '../../../constants/api';

/**
 * @returns {JSX.Element}
 * @constructor
 */

export const JoinYourTeam = () => {
  const { t } = useTranslation('validation');
  const dispatch = useDispatch();
  const authNotification = useSelector(uiSelectors.getAuthNotification);
  const email = useSelector(userSelectors.getEmailFromCodeCheck);
  const { userId, code, setErrors } = useParams();
  const [codeError, setCodeError] = useState(null);
  const {
    control, setValue, handleSubmit,
  } = useForm({
    resolver: yupResolver(rules),
  });

  const continueSignUpRequest = async (params) => {
    const data = await api.continueSignUpRequest(params);
    if (data.success) {
      localStorage.setItem('authToken', data.token.token);
      dispatch(push(APP_ROUTES.PROFILE));
      dispatch(userActions.setAuthenticated(true));
      dispatch(uiActions.setRoutesType(ROUTE_TYPES.AUTHENTICATED));
    } else {
      dispatch(uiActions.displayNotification({ data }));
    }
    return data;
  };

  const checkCodeRequest = useCallback(async (params) => {
    const data = await api.checkCodeRequest(params);
    setCodeError(data);
  }, []);

  useEffect(() => {
    checkCodeRequest({ id: userId, code });
    dispatch(uiActions.setAppTitle(WINDOW_TITLE.JOIN_YOUR_TEAM));
    dispatch(uiActions.displayNotification(null));
    return () => {
      dispatch(uiActions.displayNotification(null));
    };
  }, []);

  const onChange = (name, value) => {
    setValue(name, value);
  };

  const onSubmit = useCallback((formValues) => {
    const {
      username,
      password,
      passwordConfirm,
      firstName,
      lastName,
    } = formValues;
    const values = {
      first_name: firstName,
      last_name: lastName,
      email,
      username,
      password,
      repeat: passwordConfirm,
      code,
      id: userId,
    };
    return continueSignUpRequest(values, setErrors);
  });

  const onError = (errors) => {
    dispatch(uiActions.displayNotification(null));
    if (errors) {
      const performedErrors = Object.values(errors).reverse()[0];
      dispatch(uiActions.displayNotification({
        [performedErrors.message.field]: {
          message: performedErrors.message.message,
          params: performedErrors.message.params,
        },
      }));
    }
  };

  return (
    codeError !== ERROR_CODES_API.BAD_REQUEST ? (
      <Layouts.Auth>
        <ErrorNotification />
        <div className="auth-wrapper__wrap">
          <FormsCollection.FormWrapper handleSubmit={handleSubmit(onSubmit, onError)}>
            <div className="auth-wrapper__form-contain">
              <h2 className="title weight--bold title--md text-align--center">{t('Join your team!')}</h2>
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                render={({
                  onBlur, value, touched, field,
                }) => (
                  <FormsCollection.Input
                    type="text"
                    onChange={e => onChange('firstName', e.target.value)}
                    onBlur={onBlur}
                    value={value}
                    error={!!path(['firstName'], authNotification)}
                    touched={touched}
                    name="firstName"
                    id="join-your-team__first-name"
                    placeholderJump={t('First name')}
                    {...field}
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                defaultValue=""
                render={({
                  onBlur, value, touched, field,
                }) => (
                  <FormsCollection.Input
                    type="text"
                    onChange={e => onChange('lastName', e.target.value)}
                    onBlur={onBlur}
                    value={value}
                    error={!!path(['lastName'], authNotification)}
                    touched={touched}
                    name="lastName"
                    id="join-your-team__last-name"
                    placeholderJump={t('Last name')}
                    {...field}
                  />
                )}
              />
              <Controller
                name="username"
                control={control}
                defaultValue=""
                render={({
                  onBlur, value, touched, field,
                }) => (
                  <FormsCollection.Input
                    type="text"
                    onChange={e => onChange('username', e.target.value)}
                    onBlur={onBlur}
                    value={value}
                    error={!!path(['username'], authNotification)}
                    touched={touched}
                    name="username"
                    id="join-your-team__username"
                    placeholderJump={t('Username')}
                    {...field}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({
                  onBlur, value, touched, field,
                }) => (
                  <FormsCollection.Input
                    type="password"
                    onChange={e => onChange('password', e.target.value)}
                    onBlur={onBlur}
                    value={value}
                    error={!!path(['password'], authNotification)}
                    touched={touched}
                    name="password"
                    id="join-your-team__password"
                    placeholderJump={t('Password')}
                    hiddenEye
                    {...field}
                  />
                )}
              />
              <Controller
                name="passwordConfirm"
                control={control}
                defaultValue=""
                render={({
                  onBlur, value, touched, field,
                }) => (
                  <FormsCollection.Input
                    type="password"
                    onChange={e => onChange('passwordConfirm', e.target.value)}
                    onBlur={onBlur}
                    value={value}
                    error={!!path(['passwordConfirm'], authNotification)}
                    touched={touched}
                    name="passwordConfirm"
                    id="join-your-team__confirm-password"
                    placeholderJump={t('Confirm new password')}
                    required
                    hiddenEye
                    {...field}
                  />
                )}
              />
              <div className="button-group">
                <ButtonsCollection.ButtonBrill
                  type="submit"
                  className="auth-wrapper__button--with-border button--fill"
                  borderColor="#26b5ed"
                  borderWidth="2"
                >
                  {t('Join')}
                </ButtonsCollection.ButtonBrill>
              </div>
            </div>
          </FormsCollection.FormWrapper>
        </div>
      </Layouts.Auth>
    ) : (
      <PageError />
    )
  );
};
