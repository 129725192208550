import { compose, getContext } from 'recompose';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import ProductsItem from './productsItem';

const mapDispatchToProps = {
  push,
};


const enhance = compose(
  getContext({
    setProductInfo: PropTypes.func,
  }),
  connect(null, mapDispatchToProps),
);

export default enhance(ProductsItem);
