import * as yup from 'yup';
import { isValidLogTime } from '../../../../../utils/helpers/dateHelpers';
import * as translateHelpers from '../../../../../utils/helpers/translateHelpers';


const rules = yup.object().shape({
  date: yup
    .date()
    .nullable('Is required')
    .required('Is required'),
  time: yup
    .string()
    .required('Is required')
    .test({
      test: isValidLogTime,
      message: 'Should look like 1w 1d 1h 1m',
      params: {
        key: translateHelpers.t('common', 'logTimeIsNotValid'),
      },
    }),
});

export default rules;
