import { createSelector } from 'reselect/lib/index';
import {
  pathOr, identity, memoizeWith,
} from 'ramda';

const userActivityPerDayEntities = createSelector(
  identity,
  state => memoizeWith(identity, userId => pathOr({}, ['statistics', [userId], 'entities', 'activityPerDay'], state)),
);

const userActivityPerDayResult = createSelector(
  identity,
  state => memoizeWith(identity, userId => pathOr([], ['statistics', [userId], 'result', 'activityPerDay'], state)),
);

const userActivityTotal = createSelector(
  identity,
  state => memoizeWith(identity, userId => pathOr({}, ['statistics', [userId], 'result', 'total'], state)),
);

const activityPerTasksResult = createSelector(
  identity,
  state => memoizeWith(identity, projectId => pathOr([], ['statistics', 'tasks', [projectId], 'result'], state)),
);

const activityPerTasksEntities = createSelector(
  identity,
  state => memoizeWith(identity, projectId => pathOr({}, ['statistics', 'tasks', [projectId], 'entities'], state)),
);

const activityPerUsersTasks = createSelector(
  identity,
  state => memoizeWith(identity, userId => pathOr([], ['statistics', [userId], 'result', 'total', 'tasks'], state)),
);

const activityPerUsersEntities = createSelector(
  identity,
  pathOr({}, ['statistics', 'users', 'entities']),
);

const activityPerUsersResult = createSelector(
  identity,
  pathOr([], ['statistics', 'users', 'result']),
);

const pendingGetActivityPerTasks = createSelector(
  identity,
  pathOr(false, ['pending', 'getStatisticsByTasksRequest']),
);

const pendingGetActivityPerUsers = createSelector(
  identity,
  pathOr(false, ['pending', 'getMyStatisticsRequest']),
);


const myActivityPerDayEntities = createSelector(
  identity,
  pathOr({}, ['statistics', 'myStatistics', 'entities', 'activityPerDay']),
);

const myActivityPerDayResult = createSelector(
  identity,
  pathOr([], ['statistics', 'myStatistics', 'result', 'activityPerDay']),
);

const myActivityTotal = createSelector(
  identity,
  pathOr({}, ['statistics', 'myStatistics', 'total']),
);
const getStatistics = createSelector(
  identity,
  pathOr({}, ['statistics']),
);

export {
  userActivityPerDayEntities,
  userActivityPerDayResult,
  userActivityTotal,
  activityPerTasksResult,
  activityPerTasksEntities,
  activityPerUsersEntities,
  activityPerUsersResult,
  pendingGetActivityPerTasks,
  pendingGetActivityPerUsers,
  myActivityPerDayEntities,
  myActivityPerDayResult,
  myActivityTotal,
  activityPerUsersTasks,
  getStatistics,
};
