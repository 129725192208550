import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { bpSelectors, bpActions } from '@bp/store/bpModule';

const { getSelectedEvent, getSelectedNode, getEventsOptionsForEditing } = bpSelectors;

export const useEditNode = () => {
  const dispatch = useDispatch();
  const {
    params: { processId },
  } = useRouteMatch();
  const selectedEvent = useSelector(getSelectedEvent);
  const selectedNode = useSelector(getSelectedNode);
  const options = useSelector(getEventsOptionsForEditing);

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        bpActions.editBusinessProcessNodeRequest({
          nodeId: selectedNode.id,
          event_id: selectedEvent.id,
          values: JSON.stringify(values),
          processId,
        }),
      );
    },
    [dispatch, processId, selectedEvent, selectedNode, bpActions],
  );

  return { initialValues: selectedNode.params, options, onSubmit };
};
