import React, { memo } from 'react';

/**
 *
 * @param className {string}
 * @param children {string}
 * @returns {JSX.Element}
 * @constructor
 */


const ColData = ({ className = '', children = '' }) => (
  <div className={className}>
    {children}
  </div>
);

export default memo(ColData);
