import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ButtonsCollection, DropDown, FormsCollection } from '../../../../ui-kit';
import { INVOICES_VIEW } from '../../../../constants/crm';
import './style.sass';
import { DROPDOWN_SORT_INVOICES_LIST } from './constants';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import FiltersMobile from './filtersMobile';

const Filters = ({
  onSetView, isPipeView, t, invoiceStatusesOptions, setFilter, initialDate,
  getStatusById, queryParams, getOrderByValue, onSearch, windowWidth, areMobileFiltersOpen,
  toggleMobileFiltersHandler,
}) => (
  windowWidth > WINDOW_WIDTH.SMALL ? (
    <div className="invoices__filters">
      <div className={`invoices__filters-row ${isPipeView ? 'invoices__filters-row--pipeline-view' : ''}`}>
        <div className="invoices__filters-col">
          <ButtonsCollection.ButtonBrill
            className={`button--fill-grey invoices__filters-btn-tab${!isPipeView ? ' active' : ''}`}
            onClick={onSetView(INVOICES_VIEW.LIST)}
          >
            <span className="invoices__filters-icon-list icon-list-stroke" />
            {t('List view')}
          </ButtonsCollection.ButtonBrill>
          <ButtonsCollection.ButtonBrill
            className={`invoices__filters-btn-tab${isPipeView ? ' active' : ''}`}
            onClick={onSetView(INVOICES_VIEW.PIPE)}
          >
            <span className="invoices__filters-icon-pipe icon-list-fill" />
            {t('Lifecycle view')}
          </ButtonsCollection.ButtonBrill>
          {
            !isPipeView && (
              <>
                <DropDown
                  label={`${t('Status')} : `}
                  name="status"
                  placeholder={getStatusById(queryParams.status_id).label}
                  currentItem={getStatusById(queryParams.status_id)}
                  onChange={status => setFilter(status.val.value, 'status_id')}
                  list={invoiceStatusesOptions}
                />
                <DropDown
                  label={`${t('Sort')} : `}
                  name="sorting-filter"
                  placeholder={getOrderByValue(queryParams.order).label}
                  currentItem={getOrderByValue(queryParams.order)}
                  onChange={order => setFilter(order.val.value, 'order')}
                  list={DROPDOWN_SORT_INVOICES_LIST}
                />
              </>
            )
          }
          <div className="invoices-filter-fields__date-picker">
            <div className="invoices-filter-fields__date-picker-label">

            From
            </div>
            <FormsCollection.DatePicker
              isNotUseTimeZone
              onChange={({ target: { value } }) => {
                setFilter(moment(value).format('YYYY-MM-DD'), 'startDate');
              }}
              value={queryParams.startDate || initialDate.startDate}
            />
          </div>
          <div className="invoices-filter-fields__date-picker">
            <div className="invoices-filter-fields__date-picker-label">

            To
            </div>
            <FormsCollection.DatePicker
              isNotUseTimeZone
              onChange={({ target: { value } }) => {
                setFilter(moment(value).format('YYYY-MM-DD'), 'endDate');
              }}
              value={queryParams.endDate || initialDate.endDate}
              minDate={moment(queryParams.startDate).toDate()}
            />
          </div>
        </div>
        <div className="invoices__filters-col">
          <FormsCollection.Search
            className="search-field--circle"
            placeholder={t('Search by client\'s name or project')}
            name="search-lead"
            value={queryParams.search}
            onChange={({ target: { value } }) => setFilter(value, 'search')}
            onSearch={onSearch}
            withoutControl
          />
        </div>
      </div>
    </div>
  ) : (
    <FiltersMobile
      setFilter={setFilter}
      t={t}
      isPipeView={isPipeView}
      toggleMobileFiltersHandler={toggleMobileFiltersHandler}
      queryParams={queryParams}
      getOrderByValue={getOrderByValue}
      initialDate={initialDate}
      areMobileFiltersOpen={areMobileFiltersOpen}
      getStatusById={getStatusById}
      onSearch={onSearch}
      onSetView={onSetView}
      invoiceStatusesOptions={invoiceStatusesOptions}
    />
  )
);

Filters.propTypes = {
  t: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  getStatusById: PropTypes.func.isRequired,
  queryParams: PropTypes.instanceOf(Object).isRequired,
  onSetView: PropTypes.func.isRequired,
  invoiceStatusesOptions: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  isPipeView: PropTypes.bool.isRequired,
  getOrderByValue: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  initialDate: PropTypes.instanceOf(Object).isRequired,
  areMobileFiltersOpen: PropTypes.bool.isRequired,
  toggleMobileFiltersHandler: PropTypes.func.isRequired,
  windowWidth: PropTypes.bool.isRequired,
};

Filters.defaultProps = {
};

export default Filters;
