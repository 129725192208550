export default {
  // Business processes
  GET_BUSINESS_PROCESSES_REQUEST: 'GET_BUSINESS_PROCESSES_REQUEST',
  GET_BUSINESS_PROCESS_REQUEST: 'GET_BUSINESS_PROCESS_REQUEST',
  ADD_BUSINESS_PROCESS_REQUEST: 'ADD_BUSINESS_PROCESS_REQUEST',
  EDIT_BUSINESS_PROCESS_REQUEST: 'EDIT_BUSINESS_PROCESS_REQUEST',
  EDIT_BUSINESS_PROCESS: 'EDIT_BUSINESS_PROCESS',
  DELETE_BUSINESS_PROCESS_REQUEST: 'DELETE_BUSINESS_PROCESS_REQUEST',

  SET_BUSINESS_PROCESSES: 'SET_BUSINESS_PROCESSES',
  SET_BUSINESS_PROCESS: 'SET_BUSINESS_PROCESS',
  DELETE_BUSINESS_PROCESS: 'DELETE_BUSINESS_PROCESS',
  SET_CRUD_NODES_CONFIG: 'SET_CRUD_NODES_CONFIG',
  SELECT_EVENT_BY_ID: 'SELECT_EVENT_BY_ID',

  // Nodes of Business processes
  ADD_BUSINESS_PROCESS_NODE_REQUEST: 'ADD_BUSINESS_PROCESS_NODE_REQUEST',
  EDIT_BUSINESS_PROCESS_NODE_REQUEST: 'EDIT_BUSINESS_PROCESS_NODE_REQUEST',
  DELETE_BUSINESS_PROCESS_NODE_REQUEST: 'DELETE_BUSINESS_PROCESS_NODE_REQUEST',

  SET_BUSINESS_PROCESS_NODE: 'SET_BUSINESS_PROCESS_NODE',
  SELECT_NODE_BY_ID: 'SELECT_NODE_BY_ID',
  SELECT_VARIABLE_KEY: 'SELECT_VARIABLE_KEY',
  SET_NEW_VARIABLE: 'SET_NEW_VARIABLE',
  CLEAN_FORM_AFTER_SUBMIT: 'CLEAN_FORM_AFTER_SUBMIT',

  DELETE_BUSINESS_PROCESS_NODE: 'DELETE_BUSINESS_PROCESS_NODE',
  SET_BUSINESS_PROCESS_NODES: 'SET_BUSINESS_PROCESS_NODES',
  EDIT_BUSINESS_PROCESS_NODE: 'EDIT_BUSINESS_PROCESS_NODE',
  CLEAR_DATA_BEFORE_CLOSE_PAGE: 'CLEAR_DATA_BEFORE_CLOSE_PAGE',

  // Events
  GET_BUSINESS_PROCESS_EVENTS_REQUEST: 'GET_BUSINESS_PROCESS_EVENTS_REQUEST',
  SET_BUSINESS_PROCESS_EVENTS: 'SET_BUSINESS_PROCESS_EVENTS',

  SELECT_BUSINESS_PROCESS: 'SELECT_BUSINESS_PROCESS',

  // Logs
  GET_BUSINESS_PROCESS_LOGS_REQUEST: 'GET_BUSINESS_PROCESS_LOGS_REQUEST',
  SET_BUSINESS_PROCESS_LOGS: 'SET_BUSINESS_PROCESS_LOGS',
};
