import React, { memo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  Projects,
  Project,
  Messenger,
  MyTasks,
  CRM,
} from '../../../pages';
import Settings from '../../../views/settings';
import {
  Changelog, PageError, Profile, ActivityPage,
} from '../../../views';
import { preloaderWhileLoading } from '../../../utils/enchancers';
import checkIsUserValid from '../../../utils/enchancers/checkIsUserValid';
import { userActions, userSelectors } from '../../../state/user';
import { uiActions } from '../../../state/ui';
import withDocumentVisibleChange from '../../../utils/enchancers/withDocumentVisibleChange';
import { CHANNELS_ROUTES } from '../../../constants/ui';
import ModulesRouts from '../../../modules/routsOfModules'; // Modules routs will be automatic generated when you run modules-build


const mapDispatchToProps = ({
  checkCurrentUserRequest: userActions.checkCurrentUserRequest,
  setIsFocus: uiActions.setIsFocus,
});

const masStateToProps = state => ({
  bootData: userSelectors.getUserData(state),
});

const Routes = ({ location }) => (
  <Switch>
    <Route
      exact
      strict
      path="/:url*"
      render={() => location.pathname.slice(-1) !== '/' && <Redirect to={`${location.pathname}/`} />}
    />
    <Route exact path="/" component={Projects} />
    <Route exact path="/projects/" component={Projects} />
    <Route path="/tasks/" component={MyTasks} />
    <Route exact path="/activity/:view?/" component={ActivityPage} />
    <Route exact path="/profile/" component={Profile} />
    <Route exact path="/messenger/:id?/" component={Messenger} />
    <Route exact path={CHANNELS_ROUTES.CHANNELS} component={Messenger} />
    <Route exact path={CHANNELS_ROUTES.DIRECTS} component={Messenger} />
    <Route path="/projects/:id/" component={Project} />
    <Route path="/preferences/" component={Settings} />
    <Route path="/crm/" component={CRM} />
    <Route path="/changelog/" component={Changelog} />
    <ModulesRouts>
      <PageError />
    </ModulesRouts>
  </Switch>
);

Routes.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const enhance = compose(
  withRouter,
  connect(masStateToProps, mapDispatchToProps),
  checkIsUserValid('checkCurrentUserRequest'),
  preloaderWhileLoading({
    delay: 600,
    fullScreen: true,
    isLoading: props => !props.bootData,
  }),
  withDocumentVisibleChange,
  memo,
);

export default enhance(Routes);
