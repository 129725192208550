import React from 'react';
import { useTranslation } from 'react-i18next';
import { useProjectNotesContext } from '../context/useProjectNotesContext';
import { DropDown } from '../../../ui-kit';
import './style.sass';

const SORTBY_PARAMS = {
  resent: {
    id: 0,
    order: 'desc',
    sortBy: 'created_at',
    label: 'Recent',
  },
  old: {
    id: 1,
    order: 'asc',
    sortBy: 'created_at',
    label: 'Old',
  },
  alphabetAsc: {
    id: 2,
    order: 'asc',
    sortBy: 'title',
    label: 'A-Z',
  },
  alphabetDesc: {
    id: 3,
    order: 'desc',
    sortBy: 'title',
    label: 'Z-A',
  },
};

export const FilterRow = () => {
  const { t } = useTranslation(['common', 'project']);
  const getFilterItem = ({
    id, order, sortBy, label,
  }) => ({
    id,
    order,
    sortBy,
    label: t(label),
  });
  const { onChangeSorting, sortBy: { id } } = useProjectNotesContext();
  return (
    <div className="notes-filters">
      <div className="notes-filters__sort-group">
        <DropDown
          label={`${t('Sort')} : `}
          placeholder="Custom"
          name="filter-row"
          onChange={onChangeSorting}
          currentItem={id}
          list={
            [getFilterItem(SORTBY_PARAMS.resent),
              getFilterItem(SORTBY_PARAMS.old),
              getFilterItem(SORTBY_PARAMS.alphabetAsc),
              getFilterItem(SORTBY_PARAMS.alphabetDesc),
            ]
          }
        />
      </div>
    </div>
  );
};
