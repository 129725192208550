import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { ButtonsCollection, FormsCollection } from '../../../ui-kit';
import CustomFieldsList from '../CustomFieldsList/CustomFieldsList';
import { insertInto } from '../../../utils/helpers/crmHelpers/customFieldsHelper';
import { callNotification } from '../../../utils/helpers/notifies';
import ControlledTitleField from '../ControlledTitleField';
import SubmitAndCancel from '../SubmitAndCancel';
import api from '../../../api/custom.service';

import rules from './rules';
import './style.sass';

const DEFAULT_FIELD_VALUE = '';

/**
 *
 * @param setIdToDelete {function}
 * @param customFieldsList {array}
 * @param customFieldModel {string}
 * @param setCustomFieldsList {function}
 * @returns {JSX.Element}
 *
 */

const CustomFieldsForm = ({
  setIdToDelete, customFieldsList,
  setCustomFieldsList, customFieldModel,
}) => {
  const { t } = useTranslation('common');
  const [isUiAddMoreFields, setIsUiAddMoreFields] = useState(null);
  const [isPending, setIsPending] = useState(false);

  const { control, setValue, handleSubmit } = useForm({ resolver: yupResolver(rules) });

  const resetTitleValue = useCallback(() => setValue('title', ''), []);

  const onSubmit = useCallback(({ title }) => {
    const makeRequest = async () => {
      try {
        const newField = await api.createField({ model_name: customFieldModel, title });
        setCustomFieldsList(insertInto(customFieldsList, newField));
        resetTitleValue();
        setIsPending(false);
        setIsUiAddMoreFields(false);
        callNotification.success(t('Custom field has been added!'));
      } catch (e) {
        setIsPending(false);
        callNotification.error(t('Custom field has not been added!'));
      }
    };
    setIsPending(true);
    makeRequest();
  }, [customFieldModel, customFieldsList]);

  const toggleUiState = useCallback(() => setIsUiAddMoreFields((prevState => !prevState)), []);

  return (<>
    <CustomFieldsList
      setCustomFieldsList={setCustomFieldsList}
      isUiAddMoreFields={isUiAddMoreFields}
      customFieldsList={customFieldsList}
      setIdToDelete={setIdToDelete}
    />
    {
      isUiAddMoreFields ? (
        <FormsCollection.FormWrapper
          handleSubmit={handleSubmit(onSubmit)}
          className="custom-fields__row custom-fields__row--form-add status-item"
        >
          <div className="custom-fields__col">
            <ControlledTitleField control={control} defaultValue={DEFAULT_FIELD_VALUE} />
          </div>
          <SubmitAndCancel onCancel={toggleUiState} isPending={isPending} isColm />
        </FormsCollection.FormWrapper>
      ) : (
        <div className="custom-fields__row">
          <ButtonsCollection.ButtonPlus
            onClick={toggleUiState}
            className="add-field__button"
          />
          <button
            type="button"
            onClick={toggleUiState}
            className="add-field__label"
          >
            {'Add field'}
          </button>
        </div>
      )
    }
  </>);
};

CustomFieldsForm.defaultProps = {
  setIdToDelete: () => {},
  setCustomFieldsList: () => {},
  customFieldsList: [],
};

export default CustomFieldsForm;
