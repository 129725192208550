import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { prop } from 'ramda';

import { ButtonsCollection, PermissionElement } from '../../../../ui-kit';
import { SprintControls } from '..';
import './style.sass';
import TasksGrid from '../tasksGrid';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import { getHoursFromTimeString } from '../../../../utils/helpers/dateHelpers';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';
import { getSprintStatus } from '../../../../constants/sprints';

const Sprint = ({
  t, onOpenTabSprint,
  isOpenSprintTab,
  windowWidth,
  item, id, onEdit, onDelete, onComplete, onStart, totalSpent, totalEstimated,
}) => {
  const { isSprintNotActive, isSprintActive, isSprintCompleted } = getSprintStatus(item.status);
  return (
    <div
      className={`table-tasks__sprint
     ${isOpenSprintTab ? 'table-tasks__sprint--open' : ''}
     ${isSprintCompleted ? 'table-tasks__sprint--completed' : ''}
     `}
    >
      <div className="table-tasks__about-sprint">
        <ButtonsCollection.ButtonIcons
          className="table-tasks__sprint-toggle-button"
          title={`${!isOpenSprintTab ? t('Show more') : t('Show less')}`}
          onClick={() => onOpenTabSprint()}
        >
          <span className="icon-expand-arrow-dn" />
        </ButtonsCollection.ButtonIcons>
        <Link to={`/projects/${prop('project_id', item)}/agenda-board/?sprint=${prop('id', item)}`} className="table-tasks__name-sprint">{ item.title }</Link>
        <SprintControls
          status={item.status}
          onComplete={onComplete}
          onEdit={onEdit}
          onDelete={onDelete}
          onStart={onStart}
        />
        <div className="table-tasks__sprint-info">
          <div className="table-tasks__sprint-info__field">
            <div className="table-tasks__sprint-info__field-label">
              {`${t('Estimation')}:`}
            </div>
            <div
              className="table-tasks__sprint-info__field-value help-cursor"
              title={`Equality - ${getHoursFromTimeString(totalEstimated)}`}
            >
              {totalEstimated}
            </div>
          </div>
          <div className="table-tasks__sprint-info__field">
            <div className="table-tasks__sprint-info__field-label">
              {`${t('Spent')}:`}
            </div>
            <div
              className="table-tasks__sprint-info__field-value help-cursor"
              title={`Equality - ${getHoursFromTimeString(totalSpent)}`}
            >
              {totalSpent}
            </div>
          </div>
        </div>
        <PermissionElement
          module={MODULES.PROJECTS}
          action={ACTIONS.UPDATE}
        >
          {
            windowWidth > WINDOW_WIDTH.SMALL && (
              <>
                {isSprintNotActive && (
                  <ButtonsCollection.ButtonBrill
                    onClick={onStart}
                    className="button--sm button-brill--fill table-tasks__complete-sprint"
                  >
                    Start
                  </ButtonsCollection.ButtonBrill>
                )}
                {isSprintActive && (
                  <ButtonsCollection.ButtonBrill
                    onClick={onComplete}
                    className="button--sm button-brill--fill table-tasks__complete-sprint"
                  >
                    Complete
                  </ButtonsCollection.ButtonBrill>
                )}
                {isSprintCompleted && (
                  <ButtonsCollection.ButtonBrill
                    className="button--sm button-brill--fill table-tasks__complete-sprint"
                    onClick={onStart}
                  >
                    Completed
                  </ButtonsCollection.ButtonBrill>
                )}
              </>
            )
          }
        </PermissionElement>
      </div>
      <TasksGrid
        isTaskHasSprint
        isDisableDnd={isSprintCompleted}
        name={`sprint-grid-${id}`}
        items={item.tasks}
        isSprint
        id={id}
      />
    </div>
  );
};

Sprint.propTypes = {
  onOpenTabSprint: PropTypes.func.isRequired,
  isOpenSprintTab: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  totalSpent: PropTypes.string.isRequired,
  totalEstimated: PropTypes.string.isRequired,
  item: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

export default Sprint;
