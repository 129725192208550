import { compose, withStateHandlers } from 'recompose';
import RightSideBarContainer from './rightSideBarContainer';

const setActiveTabTitle = () => activeTabTitle => ({ activeTabTitle });

const enhancer = compose(
  withStateHandlers(
    () => ({ activeTabTitle: '' }), {
      setActiveTabTitleStateHandler: setActiveTabTitle,
    },
  ),
);

export default enhancer(RightSideBarContainer);
