import React, { memo } from 'react';
import classNames from 'classnames';
import './style.sass';
import {
  SORT_BY_LETTERS_DOWN,
  SORT_BY_LETTERS_UP,
} from '../../containers/pipeLinesContainer/constants';

const addClassForSortingArrow = (sort, value, order) => {
  if (sort === value && order === SORT_BY_LETTERS_UP) {
    return 'filter-button--active--down';
  } if (sort === value && order === SORT_BY_LETTERS_DOWN) {
    return 'filter-button--active';
  }
  if (!value) {
    return 'filter-button--inactive';
  }
  return '';
};

/**
 *
 * @param onClick {function}
 * @param value {string}
 * @param sort {string}
 * @param order {string}
 * @param children {node}
 * @param title {string}
 * @param className {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const ButtonFilter = memo(({
  onClick = () => {},
  children,
  title = '',
  className = '',
  sort,
  value,
  order,
}) => (
  <div className="filter-button__container">
    <button
      type="button"
      title={title}
      onClick={onClick}
      className={classNames(className, 'filter-button', addClassForSortingArrow(sort, value, order))}
    >
      <span className="filter-button__body">
        {children}
      </span>
      <span className="icon icon-argon-arrow" />
    </button>
  </div>
));
