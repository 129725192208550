import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { customFieldsActions, customFieldsSelectors } from '../../../../../state/customFields';
import { ControlledField } from '..';

const splitArrEveryTwo = (arr) => {
  const completeArr = [];
  for (let i = 0; i < arr.length; i += 2) {
    completeArr.push(arr.slice(i, i + 2));
  }
  return completeArr;
};

/**
 *
 * @param fields {array}
 * @param setValue {function}
 * @param control {object}
 * @returns {JSX.Element}
 * @constructor
 */

const PeopleCustomFields = ({ fields = [], setValue = () => {}, control = {} }) => {
  const dispatch = useDispatch();
  const customFieldEntities = useSelector(customFieldsSelectors.getCustomFieldsEntities);

  fields.forEach(({ id = '', value = '' }) => {
    setValue(String(id), value);
  });

  const customFieldsData = splitArrEveryTwo(fields);

  useEffect(() => {
    dispatch(customFieldsActions.getCustomFieldsRequest());
  }, []);

  return customFieldsData.map(array => (
    <div
      className="userForm__row"
      key={`people-fields-row-${array[0].id}`}
    >
      {array.map(({ id, field_id: fieldId }) => (
        <ControlledField
          title={customFieldEntities[fieldId].title}
          id={String(id)}
          control={control}
          key={id}
        />
      ))
          }
    </div>
  ));
};

export default PeopleCustomFields;
