import {
  branch, compose, renderNothing, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { pathOr } from 'ramda';
import BillingInfo from './billingInfo';
import { billingInfoActions, billingInfoSelectors } from '../../../../state/billingInfo';
import { callNotification } from '../../../../utils/helpers/notifies';
import { preloaderWhileLoading } from '../../../../utils/enchancers';

const mapDispatchToProps = {
  editBillingInfo: billingInfoActions.editClientBillingInfoRequest,
};

const mapStateToProps = state => ({
  clientBillingInfo: billingInfoSelectors.getClientBillingInfo(state),
  isPending: billingInfoSelectors.getClientActionBillingInfoPending(state)('editClientBillingInfoRequest'),
});

const submitForm = (formValues, {
  props: {
    editBillingInfo, setErrorFromBack, clientId,
  },
}) => {
  editBillingInfo({
    clientId,
    bank_details: {
      ...formValues,
    },
  },
  {
    callbacks: {
      error: compose(
        (e) => {
          callNotification.error(e);
          setErrorFromBack(e);
        },
        pathOr({}, ['response', 'data', 'result', 'error']),
      ),
    },
  },
  preloaderWhileLoading({
    dimension: 75,
    isLoading: props => props.isPending,
  }));
};

const getInitialValues = ({ clientBillingInfo: { bank_details: bankDetails } }) => ({
  ...bankDetails,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ clientBillingInfo }) => !clientBillingInfo, renderNothing),
  withFormik({
    mapPropsToValues: getInitialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    handleSubmit: submitForm,
  }),
  withProps(({ isEdit = true }) => ({
    isEdit,
  })),
);

export default enhance(BillingInfo);
