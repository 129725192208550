import React from 'react';
import { WINDOW_WIDTH } from '@/constants/ui';
import { FormsCollection, MobileFiltersContainer, DropDown } from '@/ui-kit';
import { useFiltersRow } from './useFiltersRow';
import './style.sass';

export const FiltersRow = ({ toggleMobileFiltersHandler, areMobileFiltersOpen }) => {
  const { t, processesFilter, searchValue, onChangeSearch, onChangeSorting } = useFiltersRow();
  return (
    <div className="processes-filter">
      <div className="processes-filter__sort-group">
        <FormsCollection.Search
          className="search-field--circle"
          placeholder="Search by title"
          name="search"
          value={searchValue}
          onChange={onChangeSearch}
          withoutControl
        />
        <MobileFiltersContainer
          title="Filter"
          onWidth={WINDOW_WIDTH.SMALL}
          isOpen={areMobileFiltersOpen}
          onToggle={toggleMobileFiltersHandler}
        >
          <div className="processes-filter__sort-group">
            <DropDown
              label={`${t('Sort')} : `}
              placeholder="Custom"
              onChange={onChangeSorting}
              currentItem={processesFilter.sortId}
              list={[
                {
                  id: 0,
                  order: 'desc',
                  sortBy: 'created_at',
                  label: t('Recent'),
                },
                {
                  id: 1,
                  order: 'asc',
                  sortBy: 'created_at',
                  label: t('Old'),
                },
              ]}
            />
          </div>
        </MobileFiltersContainer>
      </div>
    </div>
  );
};
