import api from '../services/api.service';
import { API_ROUTES } from '../constants/apiRoutes';
import { extractData } from '../utils/helpers/extractDataHelper';

const schema = {
  updateUserProfileRequest: async (params = {}) => {
    try {
      const response = await api.put(API_ROUTES.UPDATE_USER_PROFILE, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  updateProfileSettingsRequest: async (params = {}) => {
    try {
      const response = await api.put(API_ROUTES.UPDATE_PROFILE_SETTINGS, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
};

export default schema;
