import React from 'react';
import PropTypes from 'prop-types';

import DroppableArea from '../droppableArea';
import './style.sass';

const ColumnBoard = ({
  pipelineStatus, isDragging, updateDroppable, leads,
}) => (
  <div className="column-board">
    <div className="column-board__header">
      <h2 className="column-board__title">
        {pipelineStatus.title}
      </h2>
    </div>
    <DroppableArea
      leads={leads}
      pipelineStatusId={pipelineStatus.id}
      isDragging={isDragging}
      updateDroppable={updateDroppable}
    />
  </div>
);

ColumnBoard.propTypes = {
  pipelineStatus: PropTypes.shape({}).isRequired,
  leads: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDragging: PropTypes.bool.isRequired,
  updateDroppable: PropTypes.number,
};

ColumnBoard.defaultProps = {
  updateDroppable: null,
};

export default ColumnBoard;
