import { handleActions } from 'redux-actions';
import types from './types';
import { mergeDeep } from '../../utils/helpers/ramdaStateHelpers';

const reducer = handleActions({
  [types.SET_SETTING_USERS]: mergeDeep(({
    payload: {
      data: { entities, result },
      count, hasMore,
    },
  }) => ({
    entities: entities.settingUsers,
    result,
    hasMore,
    count,
  })),
  [types.SET_SETTING_USER]: mergeDeep(({ payload: { user } }) => ({
    entities: {
      [user.id]: user,
    },
  })),
}, {});

export default reducer;
