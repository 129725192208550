import React, { useCallback, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ScrollWrapper, FormsCollection } from '../../../ui-kit';
import { FormProfile, AvatarProfile, EmailNotification } from './components';
import './style.sass';
import { getFullName } from '../../../utils/helpers/userHelpers';
import { userActions, userSelectors } from '../../../state/user';
import api from '../../../api/profile.service';
import rules from './rules';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const ProfileContainer = () => {
  const [pending, setPending] = useState(null);

  const user = useSelector(userSelectors.getUserData);
  const dispatch = useDispatch();

  const {
    first_name, last_name, username, avatar, email_notification,
  } = user;

  const getInitialValues = () => {
    let avatarUserId = avatar;
    if (typeof avatarUserId === 'object') {
      avatarUserId = avatarUserId?.id;
    }
    return {
      first_name,
      last_name,
      username,
      avatar: avatarUserId,
      email_notification,
    };
  };

  const avatarResetHandler = async () => {
    const val = getInitialValues();
    const res = await api.updateUserProfileRequest({ ...val, avatar: null });
    dispatch(userActions.setUser({ user: res.user }));
  };

  const emailNotificationHandler = async ({ target: { checked } }) => {
    const res = await api.updateProfileSettingsRequest({ email_notification: checked });
    dispatch(userActions.setUser({ user: res.user }));
  };

  const avatarUploadHandler = async (id) => {
    const val = getInitialValues();
    const res = await api.updateUserProfileRequest({ ...val, avatar: id });
    dispatch(userActions.setUser({ user: res.user }));
  };

  const {
    control, handleSubmit,
  } = useForm({
    resolver: yupResolver(rules),
  });

  const methods = useForm();

  const onSubmit = useCallback(async (value) => {
    setPending(true);
    await api.updateUserProfileRequest(value);
    dispatch(userActions.setUser({ user: value }));
    setPending(false);
  });

  return (
    <ScrollWrapper>
      <div className="profile">
        <div className="profile__container">
          <FormsCollection.FormWrapper
            handleSubmit={handleSubmit(onSubmit)}
            className="profile__form"
          >
            <FormProvider {...methods}>
              <FormProfile
                pending={pending}
                user={user}
                control={control}
                renderEmailNotificationBlock={() => (
                  <EmailNotification
                    emailNotification={email_notification}
                    emailNotificationHandler={emailNotificationHandler}
                  />
                )}
              />
            </FormProvider>
            <AvatarProfile
              alt={getFullName({ first_name, last_name })}
              value={avatar?.id}
              name="avatar"
              userAvatar={avatar?.id ?? 0}
              onChange={() => {}}
              onUploadCallback={avatarUploadHandler}
              onResetCallback={avatarResetHandler}
            />
          </FormsCollection.FormWrapper>
        </div>
      </div>
    </ScrollWrapper>
  );
};
