import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import SummaryRow from './summaryRow';

const ProjectSummary = ({ groupedSummary }) => (
  <div className="project-summary__cards">
    {groupedSummary.map((summary) => (
      <SummaryRow key={new Date().getTime() * Math.random()} summary={summary} />
    ))}
  </div>
);

ProjectSummary.propTypes = {
  groupedSummary: PropTypes.instanceOf(Array).isRequired,
};

export default ProjectSummary;
