export default {
  GET_INVOICES_LIST_REQUEST: 'GET_INVOICES_LIST_REQUEST',
  GET_CURRENT_INVOICE: 'GET_CURRENT_INVOICE',
  ADD_INVOICE_REQUEST: 'ADD_INVOICE_REQUEST',
  UPDATE_INVOICE_REQUEST: 'UPDATE_INVOICE_REQUEST',
  SET_INVOICES: 'SET_INVOICES',
  SET_CURRENT_INVOICE: 'SET_CURRENT_INVOICE',
  CHANGE_VIEW: 'CHANGE_VIEW',
  ON_DRAG_INVOICE_END: 'ON_DRAG_INVOICE_END',
  REORDER_COLUMN_VIEW: 'REORDER_COLUMN_VIEW',
  DELETE_INVOICE_REQUEST: 'DELETE_INVOICE_REQUEST',

  ADD_INVOICE_ITEM: 'ADD_INVOICE_ITEM',
  DELETE_INVOICE_ITEM: 'DELETE_INVOICE_ITEM',
  CHANGE_INVOICE_ITEM_FIELD_VALUE: 'CHANGE_INVOICE_ITEM_FIELD_VALUE',
  SET_INVOICE_ITEMS: 'SET_INVOICE_ITEMS',

  SET_TAX: 'SET_TAX',
  SET_DISCOUNT: 'SET_DISCOUNT',
  SET_TAX_PAYMENT_TYPE: 'SET_TAX_PAYMENT_TYPE',
  SET_DISCOUNT_PAYMENT_TYPE: 'SET_DISCOUNT_PAYMENT_TYPE',
  SET_CURRENCY_SIGN: 'SET_CURRENCY_SIGN',

  CLEAR_STORE: 'CLEAR_STORE',
  OPEN_SEND_MODAL: 'OPEN_SEND_MODAL',
};
