import {
  branch,
  compose, renderNothing, withHandlers,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isNil } from 'ramda';

import PinContactModal from './pinContactModal';
import { getModal } from '../../../../state/ui/selectors';
import { uiActions } from '../../../../state/ui';
import { contactsSelectors, contactsActions } from '../../../../state/contacts';

const mapDispatchToProps = {
  onCloseModal: uiActions.closeModal,
  pinContact: contactsActions.pinContactRequest,
  getPinnedContacts: contactsActions.getPinnedContactsRequest,
};

const mapStateToProps = (state, { selectedContactId }) => ({
  isOpen: getModal(state)('pinContactModal'),
  contact: contactsSelectors.getContactById(state)(selectedContactId),
});

const onCloseHandler = ({ onCloseModal }) => () => onCloseModal('pinContactModal');

const onPinHandler = ({
  pinContact, onClose, contact, getPinnedContacts,
}) => () => pinContact({
  contactId: contact.id,
}, {
  callbacks: {
    success: () => {
      onClose();
      getPinnedContacts();
    },
  },
});


const enhance = compose(
  branch(
    ({ selectedContactId }) => isNil(selectedContactId),
    renderNothing,
  ),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withHandlers({
    onClose: onCloseHandler,
  }),
  withHandlers({
    onPin: onPinHandler,
  }),
);


export default enhance(PinContactModal);
