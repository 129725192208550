import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import {
  ConfirmModal,
} from '../../../../ui-kit';
import { getFullName } from '../../../../utils/helpers/userHelpers';

const DeleteLeadModal = ({
  isOpen,
  isPending,
  onClose,
  lead,
  onDelete,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Delete"
    onAction={onDelete}
    onClose={onClose}
    title="Delete lead"
  >
    Are you sure you want to delete
    <b>{getFullName(propOr({}, 'contact', lead))}</b>
    lead?
  </ConfirmModal>
);

DeleteLeadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  lead: PropTypes.instanceOf(Object),
  onDelete: PropTypes.func.isRequired,
};

DeleteLeadModal.defaultProps = {
  lead: null,
  isPending: null,
};

export default DeleteLeadModal;
