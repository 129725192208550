import { handleActions } from 'redux-actions';
import {
  compose, prop, uniq, uniqBy,
} from 'ramda';
import { mergeIn, mergeDeep } from '../../utils/helpers/ramdaStateHelpers';
import types from './types';

const normalizeDataArrToObj = data => data.reduce((acc, item) => ({
  ...acc,
  [item.id]: item,
}), {});

const clientSubDataReducer = key => mergeDeep(({
  payload: {
    data, count, meta: { clientId },
  },
},
{ [key]: { result } }) => {
  const entities = normalizeDataArrToObj(data);
  const dateStateByClientId = result[clientId];
  const uniqData = compose(uniqBy(prop('id')));
  return {
    [key]: {
      entities,
      result: {
        [clientId]: dateStateByClientId ? uniqData([...data, ...dateStateByClientId])
          : uniqData([...data]),
      },
      count,
    },
  };
});


const reducer = handleActions({
  [types.SET_CLIENTS]: mergeIn(({
    payload:
      {
        data: { entities: { clients }, result },
        count,
        hasMore,
        offset,
      },
  }) => ({
    summary: {
      entities: clients,
      result,
      count,
      hasMore,
      offset,
    },
  })),
  [types.SET_PINNED_CLIENTS]: mergeDeep(({
    payload:
      {
        data: { entities: { pinnedClients }, result },
        count,
        hasMore,
      },
  }) => ({
    pinnedClients: {
      entities: pinnedClients,
      result,
      count,
      hasMore,
    },
  })),
  [types.SET_CLIENT_LOGS]: mergeIn(({ payload: { data, meta: { clientId } } }) => ({
    clientLogs: {
      [clientId]: data,
    },
  })),
  [types.SET_CLIENTS_AUTOCOMPLETE]: mergeDeep(({ payload: { clients, count } }) => ({
    clientsAutocomplete: {
      clients,
      count,
    },
  })),
  [types.SET_CLIENT]: mergeIn(({ payload: { client } }) => ({
    client,
  })),
  [types.DELETE_CLIENT_NOTE_SUCCESS]: mergeDeep(({ payload: { id, noteId } }, result) => ({
    clientsNotes:
      {
        result: {
          [id]: result.clientsNotes.result[id].filter(n => n.id !== noteId),
        },
      },
  })),
  [types.EDIT_CLIENT_NOTE_SUCCESS]: mergeDeep(({ payload: { id } }, result) => ({
    clientsNotes:
      {
        result: {
          [id]: uniq(...result.clientsNotes.result[id]),
        },
      },
  })),
  [types.DELETE_CLIENT_TASK_SUCCESS]: mergeDeep(({ payload: { task, selectedClientId } },
    result) => ({
    clientsTasks:
      {
        result: {
          [selectedClientId]: result.clientsTasks.result[selectedClientId]
            .filter(t => t.id !== task.id),
        },
      },
  })),
  [types.EDIT_CLIENT_TASK_SUCCESS]: mergeDeep(({ payload: { selectedClientId } }, result) => ({
    clientsTasks:
      {
        result: {
          [selectedClientId]: uniq(...result.clientsTasks.result[selectedClientId]),
        },
      },
  })),
  [types.FULL_EDIT_CLIENT_TASK_SUCCESS]: mergeDeep(({ payload: { clientId } }, result) => ({
    clientsTasks:
      {
        result: {
          [clientId]: uniq(...result.clientsTasks.result[clientId]),
        },
      },
  })),
  [types.CHANGE_CLIENT_TASK_STATUS_SUCCESS]: mergeDeep(({ payload: { id } }, result) => ({
    clientsTasks:
      {
        result: {
          [id]: uniq(...result.clientsTasks.result[id]),
        },
      },
  })),
  [types.DELETE_CLIENT_CONTACT_SUCCESS]: mergeDeep(({ payload: { id, selectedContactId } },
    result) => ({
    clientsContacts:
      {
        result: {
          [id]: result.clientsContacts.result[id].filter(c => c.id !== selectedContactId),
        },
      },
  })),
  [types.EDIT_CLIENT_CONTACT_SUCCESS]: mergeDeep(({ payload: clientId }, result) => ({
    clientsContacts:
      {
        result: {
          [clientId]: uniq(...result.clientsContacts.result[clientId]),
        },
      },
  })),
  [types.SET_ADDED_CLIENT_CONTACT]: mergeDeep(({ payload: { contact } }, result) => ({
    clientsContacts:
      {
        result: {
          [contact.client_id]: uniq(...result.clientsContacts.result[contact.client_id], contact),
        },
      },
  }
  )),
  [types.SET_CLIENT_TASKS]: clientSubDataReducer('clientsTasks'),
  [types.SET_CLIENT_CONTACTS]: clientSubDataReducer('clientsContacts'),
  [types.SET_CLIENT_NOTES]: clientSubDataReducer('clientsNotes'),
  [types.SET_CLIENT_PRODUCTS]: clientSubDataReducer('clientsProducts'),
}, {
  clientsTasks: {
    entities: {},
    result: {},
  },
  clientsContacts: {
    entities: {},
    result: {},
  },
  clientsNotes: {
    entities: {},
    result: {},
  },
  clientLogs: {},
  client: {},
  clientsProducts: {
    entities: {},
    result: {},
  },
});

export default reducer;
