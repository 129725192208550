import React from 'react';
import classNames from 'classnames';
import './style.sass';
import { useTranslation } from 'react-i18next';


/**
 *
 * @param status {boolean}
 * @param className {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const OnlineStatusLabel = ({
  status = false,
  className = '',
}) => {
  const { t } = useTranslation('common');
  return (
    <span
      title={status
        ? t('Online')
        : t('Offline')}
      className={classNames('status-online-label',
        className, status && 'status-online-label--online')}
    />
  );
};
