import React from 'react';
import { ButtonsCollection } from '..';

/**
 *
 * @param onClick {function}
 * @param title {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const ActiveTabTitle = ({ onClick, title }) => (
  <div className="flex-row active-tab-title">
    <ButtonsCollection.ButtonIcons
      onClick={onClick}
      title="Hide tab"
      className="active-tab-title__close-button"
    >
      <span className="icon-expand-arrow-left" />
    </ButtonsCollection.ButtonIcons>
    <h3 className="active-tab-title__title">{title}</h3>
  </div>
);
