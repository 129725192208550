import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { uiActions } from '../../state/ui';

const mapDispatchToProps = ({
  setUserProfile: uiActions.getUserProfileRequest,
  changeRightSidebarStatus: uiActions.changeRightSidebarStatus,
});

const onSetUserProfileHandler = ({ setUserProfile, changeRightSidebarStatus }) => (userId) => {
  if (userId) {
    setUserProfile({ userId });
    changeRightSidebarStatus(true);
  }
};

const withUsersProfile = compose(
  connect(null, mapDispatchToProps),
  withHandlers({
    onSetUserProfile: onSetUserProfileHandler,
  }),
);

export default withUsersProfile;
