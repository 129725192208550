import React from 'react';

/**
 *
 * @param emptyText {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const EmptyText = ({ emptyText }) => <div className="show-more-lazy__is-empty">{emptyText}</div>;
