import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { openModal } from '../../../../state/ui/actions';
import Backlog from './backlog';
import { projectSelectors } from '../../../../state/project';

const mapDispatchToProps = {
  openModal,
};

const mapStateToProps = state => ({
  tasks: projectSelectors.getBacklogTasksList(state),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('tasks'),
  withProps(() => ({ gridName: 'backlog-grid' })),
);

export default enhance(Backlog);
