import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import ModalView from '../modalView';

const EditTaskModal = (props) => {
  const { t } = props;
  return (
    <ModalView
      dateFormat="dd MM yyyy"
      modalTitle={t('Edit task')}
      action={t('Save')}
      name="editTaskModal"
      {...props}
    />
  );
};

EditTaskModal.propTypes = {
  t: PropTypes.func.isRequired,
};

export default EditTaskModal;
