import {
  compose, lifecycle,
  withContext,
  withHandlers,
  withProps,
  withState,
} from 'recompose';
import {
  always,
  and, equals, not, prop,
} from 'ramda';
import PropTypes from 'prop-types';

import {
  withFilters,
  withOffset,
  withPagination,
  withRefs,
  withSorting,
  withUrlParams,
} from './index';
import { calculateOffset } from '../helpers/uiComponentHelpers/pagination';
import { getOppositeValue } from '../helpers/commonHelpers';
import withLocationSearchChanged from './withLocationChanged';


const onGetEntitiesRequestHandler = ({
  getEntitiesAction, pagination, sort, order, searchVal, sortKey, searchKey, params,
}) => () => {
  const { limit, offset } = pagination;
  getEntitiesAction({
    limit,
    offset: Number(offset),
    [sortKey || 'sort']: sort,
    order,
    [searchKey]: searchVal,
    ...params,
  });
};

const withGrid = ({
  limit, gridName, action, searchValue = always(''), sortKey: sortKeyCustom,
  hasMore: hasMoreEntities, count: countEntities, searchByKey, customParams = always({}),
}) => compose(
  withRefs(),
  withProps(props => ({
    gridName,
    limit,
    count: countEntities(props),
    getEntitiesAction: action(props),
    hasMore: hasMoreEntities(props),
    searchVal: searchValue(props),
    sortKey: sortKeyCustom,
    searchKey: searchByKey,
    params: customParams(props),
  })),
  withUrlParams({}),
  withFilters({ initial: ({ getUrlParam }) => ({ title: getUrlParam(['title']) }) }),
  withPagination({
    limit: always(limit),
    offset: ({ getUrlParam }) => calculateOffset(getUrlParam(['page'], 1), limit),
    page: ({ getUrlParam }) => getUrlParam(['page'], 1),
    selector: always(gridName),
  }),
  withState('isNeedRefreshProjects', 'setIsNeedRefreshProjects', false),
  withOffset,
  withSorting({
    selector: props => props.getUrlParams({}),
    values: ({
      order: ({ getUrlParam }) => getUrlParam(['order'], 'desc'),
      sort: ({ getUrlParam }) => getUrlParam(['sort'], 'created_at'),
    }),
    onSortBy: ({ order, onSetUrlParam }) => (dataSorting) => {
      const sort = prop('sort', dataSorting);
      const newOrder = sort !== 'created_at' ? getOppositeValue('asc', 'desc', order) : prop('order', dataSorting);
      onSetUrlParam({ sort, order: newOrder });
    },
  }),
  withContext({
    filterValue: PropTypes.any,
    setIsNeedRefreshProjects: PropTypes.func,
    onToggleFavoriteStatus: PropTypes.func,
  }, props => ({
    filterValue: props.filterValue,
    setIsNeedRefreshProjects: props.setIsNeedRefreshProjects,
    onToggleFavoriteStatus: props.onToggleFavoriteStatus,
  })),
  withHandlers({
    onGetEntitiesRequest: onGetEntitiesRequestHandler,
  }),
  withLocationSearchChanged({
    onChange: ({ onGetEntitiesRequest }) => onGetEntitiesRequest(),
  }),
  lifecycle({
    componentDidMount() {
      this.props.onGetEntitiesRequest();
    },
    componentDidUpdate(prevProps) {
      const {
        isNeedRefreshProjects, setIsNeedRefreshProjects, onGetEntitiesRequest, searchVal, params,
      } = this.props;

      if (prevProps.searchVal !== searchVal) {
        onGetEntitiesRequest();
      }
      if (!equals(prevProps.params, params)) {
        onGetEntitiesRequest();
      }
      if (and(
        isNeedRefreshProjects,
        not(equals(prevProps.isNeedRefreshProjects, isNeedRefreshProjects)),
      )) {
        onGetEntitiesRequest();
        setIsNeedRefreshProjects(false);
      }
    },
  }),
);

export default withGrid;
