import {
  compose, lifecycle, withHandlers, withStateHandlers, defaultProps, withProps,
} from 'recompose';
import { connect } from 'react-redux';

import { withRefs } from '.';
import { uiActions, uiSelectors } from '../../state/ui';
import { checkSafariWithCb } from '../helpers/commonHelpers';
import { scrollToCustom } from '../helpers/DOMHelper/node';

const VECTOR_RIGHT = 'vectorRight';
const VECTOR_LEFT = 'vectorLeft';


const mapStateToProps = state => ({
  isSwipeSideBars: uiSelectors.getIsSwipeSideBars(state),
});

const mapDispatchToProps = {
  changeSwipeSidebarsStatus: uiActions.changeSwipeSidebarsStatus,
};

const getVectorSwipe = ({
  coordinatesStart,
  currentCoordinates,
}) => (coordinatesStart < currentCoordinates ? VECTOR_RIGHT : VECTOR_LEFT);


const scrollToBoard = ({ indexBoard, getRef, boardList }) => {
  const rowElement = getRef('agendaRow').querySelector('.scroll-wrapper > div');
  const boardElement = boardList[indexBoard];
  scrollToCustom(rowElement, {
    left: boardElement.offsetLeft - 16,
    behavior: 'smooth',
  });
};

const onTouchEndHandler = ({
  toggleIsTouched, coordinatesStart, setCurrentBoard, currentBoard, getRef, range, boardList,
}) => (e) => {
  const currentCoordinates = e.changedTouches[0].clientX;
  if (Math.abs(coordinatesStart - currentCoordinates) < range) {
    scrollToBoard({ indexBoard: currentBoard, getRef, boardList });
    return currentCoordinates;
  }

  const swipeVector = getVectorSwipe({
    coordinatesStart,
    currentCoordinates,
  });
  const newBoardIndex = swipeVector === VECTOR_RIGHT ? currentBoard - 1 : currentBoard + 1;

  if (newBoardIndex > -1 && newBoardIndex < boardList.length) {
    scrollToBoard({ indexBoard: newBoardIndex, getRef, boardList });
    setCurrentBoard(newBoardIndex);
  }
  toggleIsTouched(false);
  return coordinatesStart;
};


const onTouchMoveHandler = ({
  coordinatesStart,
  getRef,
  startScrollPosition,
  currentBoard,
  changeSwipeSidebarsStatus,
  isSwipeSideBars,
  boardList,
}) => (e) => {
  const currentCoordinates = e.changedTouches[0].clientX;
  const currentRangeSwipe = coordinatesStart - currentCoordinates;
  const rowElement = getRef('agendaRow');
  const swipeVector = getVectorSwipe({
    coordinatesStart,
    currentCoordinates,
  });
  if ((swipeVector === VECTOR_RIGHT && currentBoard === 0)
    || (swipeVector === VECTOR_LEFT && currentBoard === boardList.length - 1)) {
    if (!isSwipeSideBars) changeSwipeSidebarsStatus(true);
  } else if (isSwipeSideBars) changeSwipeSidebarsStatus(false);
  scrollToCustom(
    rowElement,
    {
      x: startScrollPosition + currentRangeSwipe,
      y: 0,
    },
  );
};

const onTouchStartHandler = ({
  toggleIsTouched, setStartScrollPosition, getRef, setStartCoordinates,
}) => (e) => {
  const rowElement = getRef('agendaRow');
  setStartCoordinates(e.changedTouches[0].clientX);
  toggleIsTouched(true);
  setStartScrollPosition(rowElement.scrollLeft);
};

const toggleIsTouchedStateHandler = () => value => ({ isTouched: value });
const setStartCoordinatesStateHandler = () => value => ({ coordinatesStart: value });
const setCurrentBoardStateHandler = () => value => ({ currentBoard: value });
const setStartScrollPositionStateHandler = () => value => ({ startScrollPosition: value });

const enhance = ({ range = 50 }) => compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRefs(),
  defaultProps({
    range,
  }),
  withStateHandlers(() => ({
    isTouched: false, coordinatesStart: 0, startScrollPosition: 0, currentBoard: 0,
  }),
  {
    toggleIsTouched: toggleIsTouchedStateHandler,
    setStartCoordinates: setStartCoordinatesStateHandler,
    setCurrentBoard: setCurrentBoardStateHandler,
    setStartScrollPosition: setStartScrollPositionStateHandler,
  }),
  withProps(({ getRef }) => ({
    boardList: getRef('agendaRow') && getRef('agendaRow').querySelectorAll('.column-board'),
  })),
  withHandlers({
    onTouchEnd: onTouchEndHandler,
    onTouchMove: onTouchMoveHandler,
    onTouchStart: onTouchStartHandler,
  }),
  lifecycle({
    componentDidMount() {
      const {
        getRef,
        onTouchEnd,
        onTouchStart,
        onTouchMove,
        changeSwipeSidebarsStatus,
      } = this.props;
      const agendaRowElement = getRef('agendaRow');
      checkSafariWithCb((isSafari) => {
        if (isSafari) agendaRowElement.querySelector('.scroll-wrapper > div').style.overflow = 'hidden';
      });
      agendaRowElement.addEventListener('touchstart', onTouchStart);
      agendaRowElement.addEventListener('touchmove', onTouchMove);
      agendaRowElement.addEventListener('touchend', onTouchEnd);
      changeSwipeSidebarsStatus(false);
    },
    componentWillUnmount() {
      const {
        getRef,
        onTouchEnd,
        onTouchStart,
        onTouchMove,
      } = this.props;
      const agendaRowElement = getRef('agendaRow');
      agendaRowElement.removeEventListener('touchstart', onTouchStart);
      agendaRowElement.removeEventListener('touchmove', onTouchMove);
      agendaRowElement.removeEventListener('touchend', onTouchEnd);
    },
    shouldComponentUpdate({ currentBoard, isTouched, coordinatesStart }) {
      return !(currentBoard !== this.props.currentBoard
        || isTouched !== this.props.isTouched
        || coordinatesStart !== this.props.coordinatesStart);
    },
  }),
);
export default enhance;
