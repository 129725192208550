import {
  always, compose, concat, cond, curry, isNil, not, prop, T,
} from 'ramda';
import { Either, Maybe } from 'ramda-fantasy';
import { curryRegexTest, regexRules } from './common';

const getHTMLElementByString = (string, type = 'text/html') => new DOMParser().parseFromString(string, type).body.childNodes[0];

const removeNode = curry(node => node.remove());

const { Just, Nothing } = Maybe;
const { Left, Right } = Either;

const concatWithPreviousSibling = curry((text, siblingText) => concat(siblingText, text));
const concatWithNextSibling = curry((siblingText, text) => concat(siblingText, text));

const isBeginningWord = cond([
  [curryRegexTest(regexRules.regCharacterAfterSpace), value => Right(prop('textContent', value))],
  [T, Left],
]);

const concatText = curry((text, value) => Either.either(
  always(text),
  concatWithNextSibling(text),
  value,
));

const checkIsWordValidAndConcat = curry(text => compose(
  concatText(text),
  isBeginningWord,
));

const checkIfSiblingExist = curry(sibling => compose(
  isNil,
  prop(sibling),
));

const isNodeHasSibling = curry((sibling, node) => cond([
  [checkIfSiblingExist(sibling), Nothing],
  [T, Just],
])(node));

const isNodeHasProp = cond([
  [compose(not, checkIfSiblingExist), Nothing],
  [T, Just],
]);

const getNodeAttribute = curry((type, node) => node.getAttribute(type));

const callWithPreventEvent = curry((func, e) => {
  func(e);
  e.preventDefault();
  return false;
});

export {
  removeNode,
  isNodeHasSibling,
  checkIsWordValidAndConcat,
  concatWithPreviousSibling,
  getNodeAttribute,
  isNodeHasProp,
  callWithPreventEvent,
  getHTMLElementByString,
};
