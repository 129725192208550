import {
  cond, curry, identity, isEmpty, isNil, replace, T,
} from 'ramda';
import { Either } from 'ramda-fantasy';
import PropTypes from 'prop-types';
import { compose, withHandlers, withStateHandlers } from 'recompose';

import { isNotEmpty } from 'ramda-extension';
import { KEYS } from '../../constants/ui';
import { isMobile } from '../helpers/commonHelpers';

const { Left, Right } = Either;

const onChangeContentStateHandler = () => messageContent => ({ messageContent });
const onClearContentStateHandler = () => () => ({ messageContent: '' });

const isOnlySpaces = compose(isEmpty, replace(/(\s|&nbsp;)/g, ''));

const isContentIsNotEmpty = (content, files) => cond([
  [() => isNotEmpty(files), Right],
  [isEmpty, Left],
  [isNil, Left],
  [isOnlySpaces, Left],
  [T, Right],
])(content);

const submitMessage = Either.either(identity);

const onClickSaveContentHandler = curry((onSaveAction, props) => (element) => {
  if (element) {
    element.preventDefault();
  }
  submitMessage(() => onSaveAction(props), isContentIsNotEmpty(props.messageContent,
    props.filesListIds));
});

const onKeyDownSaveContentHandler = curry((onSaveAction, props) => (event) => {
  if (event.keyCode === KEYS.ENTER && !event.shiftKey && !isMobile()) {
    event.preventDefault();
    submitMessage(() => onSaveAction(props), isContentIsNotEmpty(props.messageContent,
      props.filesListIds));
  }
});

const WithMessageContent = ({ value, onSaveMessageContent }) => compose(
  withStateHandlers(props => ({ messageContent: value(props) }), ({
    onSetMessageContent: onChangeContentStateHandler,
    onClearMessageContent: onClearContentStateHandler,
  })),
  withHandlers({
    onKeyDownSaveContent: onKeyDownSaveContentHandler(onSaveMessageContent),
    onClickSaveContent: onClickSaveContentHandler(onSaveMessageContent),
  }),
);

WithMessageContent.propTypes = {
  sortBy: PropTypes.func.isRequired,
};

export default WithMessageContent;
