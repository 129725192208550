/* eslint-disable camelcase */
import { createActions } from 'redux-actions';
import { identity, pathOr } from 'ramda';

import types from './types';
import { statisticsSchemas } from './index';
import { activityPerTasksSchema } from './schema';

export const {
  getStatisticsRequest,
  getStatisticsByTasksRequest,
  getMyStatisticsRequest,
  setUserStatistic,
  setStatisticsByTasks,
  setMyStatistics,
  exportDataRequest,
} = createActions(
  {
    [types.EXPORT_DATA_REQUEST]: [
      identity,
      (params, { callbacks = {}, isCurrentUser = true, ...rest }) => ({
        async: true,
        route: isCurrentUser ? '/stats/me/' : '/stats/',
        selectorName: 'exportDataRequest',
        method: 'GET',
        params: {
          ...params,
          is_export: 1,
        },
        schema: {
          rules: {
            activityPerDay: statisticsSchemas.activityPerDaySchema,
            activityPerMonth: statisticsSchemas.activityPerMonthSchema,
            total: { users: statisticsSchemas.activityPerUsers },
          },
          pathData: [],
        },
        // actions: {
        //   success: (action) => console.log(action),
        // },
        callbacks: {
          success: pathOr(null, ['success'], callbacks),
        },
        ...rest,
      }),
    ],
    [types.GET_STATISTICS_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/stats',
        selectorName: params.user_id ? 'getUserStatisticRequest' : 'getStatisticsRequest',
        method: 'GET',
        params,
        schema: {
          rules: {
            activityPerDay: statisticsSchemas.activityPerDaySchema,
            activityPerMonth: statisticsSchemas.activityPerMonthSchema,
            total: { users: statisticsSchemas.activityPerUsers },
          },
          pathData: [],
        },
        actions: {
          success: action => setUserStatistic({
            ...action,
            meta: { userId: params.user_id || meta.userId },
          }),
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.GET_STATISTICS_BY_TASKS_REQUEST]: [
      identity,
      ({ project_id, ...params }, meta) => ({
        async: true,
        route: `/projects/${project_id}/tasks`,
        selectorName: 'getStatisticsByTasksRequest',
        method: 'GET',
        params,
        schema: {
          rules: activityPerTasksSchema,
          pathData: ['data'],
        },
        actions: {
          success: action => setStatisticsByTasks({ ...action, meta: { projectId: project_id } }),
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.GET_MY_STATISTICS_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/stats/me',
        selectorName: 'getMyStatisticsRequest',
        method: 'GET',
        params,
        schema: {
          rules: {
            activityPerDay: statisticsSchemas.activityPerDaySchema,
            activityPerMonth: statisticsSchemas.activityPerMonthSchema,
            total: { users: statisticsSchemas.activityPerUsers },
          },
          pathData: [],
        },
        actions: {
          success: action => setMyStatistics({ ...action, meta: { projectId: params.project_id } }),
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
  },
  types.SET_USER_STATISTIC,
  types.SET_STATISTICS_BY_TASKS,
  types.SET_MY_STATISTICS,
);
