import {
  getContext, withProps, compose, branch, renderNothing,
} from 'recompose';
import { connect } from 'react-redux';
import { pathOr, __ } from 'ramda';
import PropTypes from 'prop-types';
import { leadsActions } from '../../state/leads';
import { getLeadById } from '../../state/leads/selectors';

const mapStateToProps = (state, { selectedLeadId }) => ({
  lead: getLeadById(state)(selectedLeadId),
});

const mapDispatchToProps = {
  editLead: leadsActions.editLeadRequest,
};

const withEditLead = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ lead }) => !lead, renderNothing),
  withProps(({ lead }) => {
    const getPathFromLead = pathOr('', __, lead);
    const getPropFromLeadContact = field => pathOr('', ['contact', field], lead);
    return {
      initialValues: ({
        contact: {
          avatar: getPropFromLeadContact('avatar'),
          first_name: getPropFromLeadContact('first_name'),
          last_name: getPropFromLeadContact('last_name'),
          email: getPropFromLeadContact('email'),
          phone: getPropFromLeadContact('phone'),
          company_name: getPropFromLeadContact('company_name'),
          company_url: getPropFromLeadContact('company_url'),
          position: getPropFromLeadContact('position'),
          service: getPropFromLeadContact('service'),
          timezone: {
            label: getPropFromLeadContact('timezone'),
            value: getPropFromLeadContact('timezone'),
          },
        },
        pipeline_id: {
          label: getPathFromLead(['pipeline', 'title']),
          value: getPathFromLead(['pipeline', 'id']),
        },
        pipeline_status_id: {
          label: getPathFromLead(['pipelineStatus', 'title']),
          value: getPathFromLead(['pipelineStatus', 'id']),
        },
      }),
    };
  }),
  getContext({
    setSelectedLeadId: PropTypes.func.isRequired,
    setIsNeedRefresh: PropTypes.func.isRequired,
  }),
);

export default withEditLead;
