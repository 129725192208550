/*eslint-disable*/
import {
  assoc,
  curry, insert, lensProp, remove, set,
} from 'ramda';
import { select, put } from 'redux-saga/effects';
import { getPipelineStatusByIdSelector } from '../../../state/pipelines/selectors';
import { getLeadsByPipelineStatusId, getLeadById } from '../../../state/leads/selectors';
import {
  editLeadRequest, reorderPipeViewLead, updateLeadSummary, convertLeadToClientRequest,
} from '../../../state/leads/actions';
import { move } from '../commonHelpers';
import { PIPELINE_TYPES } from '../../../constants/crm';

const getDataInStoreWithParams = curry((selector, params) => select(
  state => selector(state)(params),
));

const makePayload = curry((key, data, leads) => assoc(key, data, { leads }));

const addStatusParam = makePayload('pipeline_status_id');

const getLeadsByStatusIdSelector = getDataInStoreWithParams(getLeadsByPipelineStatusId);
const getLeadSelector = getDataInStoreWithParams(getLeadById);
const getPipelineStatusSelector = getDataInStoreWithParams(getPipelineStatusByIdSelector);


const updateTask = curry(function* (status_id, data) {
  const statusLens = lensProp('pipeline_status_id');
  const updatedData = set(statusLens, status_id, data);
  yield put(editLeadRequest({ ...updatedData }, { isAfterDrop: true }));
});

const updateBoard = curry(function* (status_id, action) {
  const selectedTasks = yield getLeadsByStatusIdSelector(status_id);
  const updatedTasks = action(selectedTasks);
  return addStatusParam(status_id, updatedTasks);
});

const dragLeadBetweenBoard = function* (data) {
  const { source, destination, itemId } = data;
  if (source.droppableId === destination.droppableId) {
    const itemsInSource = yield updateBoard(
      source.droppableId,
      move(source.index, destination.index),
    );
    yield put(reorderPipeViewLead(itemsInSource));
  } else {
    const lead = yield getLeadSelector(itemId);
    const pipelineStatus = yield getPipelineStatusSelector(destination.droppableId);
    const itemsInSource = yield updateBoard(source.droppableId, remove(source.index, 1));
    const itemsInDestination = yield updateBoard(
      destination.droppableId, insert(destination.index, lead),
    );
    const updatedLead = {
      ...lead,
      pipeline_status_id: destination.droppableId,
      pipelineStatus,
    };
    const { type } = pipelineStatus;
    yield put(reorderPipeViewLead(itemsInSource));
    yield put(reorderPipeViewLead(itemsInDestination));
    yield put(updateLeadSummary(updatedLead));
    if (type === PIPELINE_TYPES.WON) yield put(convertLeadToClientRequest({ ...lead }));
    yield updateTask(destination.droppableId, lead);
  }
};

export default dragLeadBetweenBoard;
