import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import {
  prop, values, sortWith, ascend, splitEvery,
} from 'ramda';
import ProjectSummary from './projectSummary';
import { projectSelectors } from '../../../../state/project';

const mapStateToProps = state => ({
  summary: projectSelectors.getProjectSummary(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withProps(({ summary }) => {
    const SIZE = 3;
    const summaryData = values(summary);
    const sortedSummary = sortWith([ascend(prop('priority'))], summaryData);
    const groupedSummary = splitEvery(SIZE, [...sortedSummary]);

    return {
      groupedSummary,
    };
  }),
);
export default enhance(ProjectSummary);
