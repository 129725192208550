export default {
  DISPLAY_NOTIFICATION: 'DISPLAY_NOTIFICATION',
  DISPLAY_ERROR_PAGE: 'DISPLAY_ERROR_PAGE',
  GO_BACK_FROM_ERROR_PAGE: 'GO_BACK_FROM_ERROR_PAGE',
  SET_PRELOADER: 'SET_PRELOADER',
  CHANGE_RIGHT_SIDEBAR_STATUS: 'CHANGE_RIGHT_SIDEBAR_STATUS',
  CHANGE_LEFT_SIDEBAR_STATUS: 'CHANGE_LEFT_SIDEBAR_STATUS',
  CHANGE_SWIPE_SIDEBARS_STATUS: 'CHANGE_SWIPE_SIDEBARS_STATUS',
  CHANGE_NAME_ACTIVE_FILTER: 'CHANGE_NAME_ACTIVE_FILTER',
  SET_PAGINATION: 'SET_PAGINATION',
  RESET_PAGINATION: 'RESET_PAGINATION',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  RESET_MODALS: 'RESET_MODALS',
  UPLOAD_TEMP_FILE_REQUEST: 'UPLOAD_TEMP_FILE_REQUEST',
  GET_TEMP_FILE_REQUEST: 'GET_TEMP_FILE_REQUEST',
  GET_TEMP_FILE_SUCCESS: 'GET_TEMP_FILE_SUCCESS',
  UPDATE_TEMP_FILE_REQUEST: 'UPDATE_TEMP_FILE_REQUEST',
  SET_SORT: 'SET_SORT',
  SET_ORDER: 'SET_ORDER',
  SET_GRID_OPTIONS: 'SET_GRID_OPTIONS',
  SET_ROUTES_TYPE: 'SET_ROUTES_TYPE',
  RESET_UI: 'RESET_UI',
  ON_DRAG_END: 'ON_DRAG_END',
  CLEAR_GRID_OPTIONS: 'CLEAR_GRID_OPTIONS',
  SET_FILTER: 'SET_FILTER',
  SET_ERROR_PAGE: 'SET_ERROR_PAGE',
  CHANGE_PROJECTS_TAB_STATUS: 'CHANGE_PROJECTS_TAB_STATUS',
  CHANGE_CO_WORKERS_TAB_STATUS: 'CHANGE_CO_WORKERS_TAB_STATUS',
  CHANGE_MODULES_TAB_STATUS: 'CHANGE_MODULES_TAB_STATUS',
  CHANGE_IMPORTANT_TASKS_TAB_STATUS: 'CHANGE_IMPORTANT_TASKS_TAB_STATUS',
  CHANGE_PROJECT_MEMBERS_TAB_STATUS: 'CHANGE_PROJECT_MEMBERS_TAB_STATUS',
  CHANGE_MESSENGER_DETAILS_TAB_STATUS: 'CHANGE_MESSENGER_DETAILS_TAB_STATUS',
  CHANGE_MESSENGER_MEDIA_TAB_STATUS: 'CHANGE_MESSENGER_MEDIA_TAB_STATUS',
  CHANGE_MESSENGER_PINNED_ITEMS_TAB_STATUS: 'CHANGE_MESSENGER_PINNED_ITEMS_TAB_STATUS',
  CHANGE_MESSENGER_NOTIFICATION_TAB_STATUS: 'CHANGE_MESSENGER_NOTIFICATION_TAB_STATUS',
  CHANGE_MESSENGER_MEMBERS_TAB_STATUS: 'CHANGE_MESSENGER_MEMBERS_TAB_STATUS',
  GET_USER_PROFILE_REQUEST: 'GET_USER_PROFILE_REQUEST',
  SET_USER_PROFILE_ID: 'SET_USER_PROFILE_ID',
  SET_USER_PROFILE: 'SET_USER_PROFILE',
  LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
  SET_IS_FOCUS: 'SET_IS_FOCUS',
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
  SET_TEXTAREA_MESSENGER_WAS_CHANGED: 'SET_TEXTAREA_MESSENGER_WAS_CHANGED',
  SET_URL_BEFORE_CHANNELS: 'SET_URL_BEFORE_CHANNELS',
  SET_WS_ERROR: 'SET_WS_ERROR',
  SAVE_FIELD_VALUE: 'SAVE_FIELD_VALUE',
  RESET_FIELD_VALUE: 'RESET_FIELD_VALUE',
  SAVE_URL_FILTER: 'SAVE_URL_FILTER',
  RESET_URL_FILTER: 'RESET_URL_FILTER',
  SET_APP_TITLE: 'SET_APP_TITLE',
  SET_APP_UNREAD_MESSAGES: 'SET_APP_UNREAD_MESSAGES',
  SET_WINDOW_CONTENT_LOADED: 'SET_WINDOW_CONTENT_LOADED',
  IS_NOTIFICATION_BANNER: 'IS_NOTIFICATION_BANNER',
};
