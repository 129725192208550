import {
  branch, compose, renderNothing,
} from 'recompose';
import { connect } from 'react-redux';

import Grid from './grid';

import withRefs from '../../../../../../utils/enchancers/withRefs';
import { projectsSelectors } from '../../../../../../state/projects';
import { openModal } from '../../../../../../state/ui/actions';

const mapStateTopProps = state => ({
  getProjectsViewStyle: projectsSelectors.getProjectsViewStyle(state),
});

const mapDispatchToProps = {
  openModal,
};

const enhance = compose(
  connect(mapStateTopProps, mapDispatchToProps),
  withRefs(),
  branch(
    ({ data }) => !data,
    renderNothing,
  ),
);

export default enhance(Grid);
