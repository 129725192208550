import api from '../services/api.service';
import { API_ROUTES } from '../constants/apiRoutes';
import { extractData } from './helpers';

const schema = {
  loginRequest: async (params = {}) => {
    try {
      const response = await api.post(API_ROUTES.LOGIN, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  forgotPasswordRequest: async (params = {}) => {
    try {
      const response = await api.post(API_ROUTES.FORGOT_PASSWORD, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  resetPasswordRequest: async (params = {}) => {
    try {
      const response = await api.post(API_ROUTES.RESET_PASSWORD, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  checkForgotPasswordTokenRequest: async (params = {}) => {
    try {
      const response = await api.post(API_ROUTES.FORGOT_PASSWORD_CODE, params);
      return extractData(response);
    } catch (error) {
      if (error.response.status) return error.response.status;
      return error;
    }
  },
  continueSignUpRequest: async (params = {}) => {
    try {
      const response = await api.post(API_ROUTES.CONTINUE_SIGN_UP, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  loginGoogleRequest: async (params = {}) => {
    try {
      const response = await api.get(API_ROUTES.GOOGLE_LOGIN, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  checkCodeRequest: async (params = {}) => {
    try {
      const response = await api.get(API_ROUTES.CHECK_CODE_REQUEST, params);
      return extractData(response);
    } catch (error) {
      if (error.response.status) return error.response.status;
      return error;
    }
  },
  // checkCurrentUserRequest: async (route, params) => {
  //   const data = await api.post('/auth/current-user', {});
  //   return data;
  // },
};

export default schema;
