import { withStateHandlers } from 'recompose';
import { recalculateOffset } from '../helpers/commonHelpers';

const setOffsetStateHandler = ({ offset }) => (val) => {
  const recalculatedOffset = recalculateOffset(offset)(val);
  return ({ offset: recalculatedOffset });
};

const setResetOffsetStateHandler = () => val => ({ offset: val });

const withOffset = withStateHandlers(() => ({ offset: 0 }), {
  setOffset: setOffsetStateHandler,
  resetOffsetTo: setResetOffsetStateHandler,
});

export default withOffset;
