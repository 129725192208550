import {
  compose, getContext, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { propOr, replace } from 'ramda';
import moment from 'moment';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router';
import InvoiceItem from './invoiceItem';
import { invoicesActions, invoicesSelectors } from '../../../../state/invoices';
import { CRM_ROUTES, INVOICES_STATUSES } from '../../../../constants/crm';
import { uiActions } from '../../../../state/ui';
import { getApiUrl } from '../../../../utils/helpers/requestHelpers';

const onChangeInvoiceStatus = ({
  invoiceId, updateInvoiceRequest, queryParams,
}) => ({ val: { value } }) => updateInvoiceRequest({
  status_id: value,
  invoiceId,
}, { queryParams });

const onDeleteHandler = ({ setSelectedInvoiceId, invoiceId, openModal }) => () => {
  setSelectedInvoiceId(invoiceId);
  openModal('deleteInvoiceModal');
};

const mapDispatchToProps = {
  updateInvoiceRequest: invoicesActions.updateInvoiceRequest,
  openModal: uiActions.openModal,
};

const getInvoiceDownloadUrl = ({ invoiceId }) => isPreview => `${getApiUrl(window.location)}/invoices/${invoiceId}/download?is_preview=${isPreview || ''}`;

const onLinkClick = (href, newTab) => () => {
  const link = document.createElement('a');
  link.setAttribute('href', href);
  if (newTab) {
    link.setAttribute('target', '_blank');
  }
  document.body.appendChild(link);

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  document.body.removeChild(link);
};

const openEditPageHandler = ({ history }) => invoiceId => () => history.push(
  replace(':invoiceId', invoiceId, CRM_ROUTES.EDIT_INVOICE),
);

const mapStateToProps = (state, { invoiceId }) => {
  const invoice = invoicesSelectors.getInvoiceById(state)(invoiceId);
  const project = propOr(null, 'project', invoice);

  return {
    clientContact: propOr(null, 'client_contact', invoice),
    invoice,
    project,
  };
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withProps(({ clientContact, project, invoice }) => ({
    leadName: clientContact
      ? `${clientContact.first_name} ${clientContact.last_name}`
      : 'N/A',
    projectTitle: propOr('N/A', 'title', project),
    invoiceStatus: INVOICES_STATUSES[invoice.status_id],
    date: moment(invoice.date).format('DD MMM YYYY'),
    total: parseFloat(propOr('', 'total', invoice)).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
    currencySign: propOr('', 'value', JSON.parse(invoice.currency)),
    onLinkClick,
  })),
  getContext({
    invoiceStatusesOptions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    queryParams: PropTypes.instanceOf(Object),
    setSelectedInvoiceId: PropTypes.func,
  }),
  withHandlers({
    onChangeInvoiceStatus,
    onDelete: onDeleteHandler,
    getInvoiceDownloadUrl,
    openEditPage: openEditPageHandler,
  }),
);

export default enhance(InvoiceItem);
