import React from 'react';
import LeadForm from '../../modals/leadForm';
import './style.sass';

const LeadInfo = props => (
  <div className="leadInfo">
    <LeadForm titleAction="Save" isEdit {...props} />
  </div>
);

export default LeadInfo;
