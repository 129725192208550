import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ModalWindow } from '../../../../../ui-kit';
import './style.sass';

const redirectToProjectsInvoiceModal = ({
  isOpen,
  onClose,
  t,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onClose}
    modalName="redirectModal"
    title="Please create project"
    className="modal-window__redirect"
  >
    <div className="modal-window__redirect__text">This client does not have any projects yet. Go to
      <NavLink to="/projects" className="modal-window__redirect__link">
        <span className="modal-window__redirect__link-text"> {t('Projects')} </span>
      </NavLink>
      to create the first one.
    </div>
  </ModalWindow>
);

redirectToProjectsInvoiceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default redirectToProjectsInvoiceModal;
