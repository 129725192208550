/* eslint-disable import/prefer-default-export */
import { assoc, curry } from 'ramda';
import moment from 'moment';

const makePayload = curry((key, data, tasks) => assoc(key, data, { tasks }));
const formatSprintTime = curry(date => moment(date).format('MMM DD, h:mm'));

export {
  formatSprintTime,
  makePayload,
};
