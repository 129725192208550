import React from 'react';
import PropTypes from 'prop-types';

import { ConfirmModal } from '../../../../ui-kit';
import './style.sass';

const DeleteTaskStatusModal = ({
  isOpen, onClose, isPending, onDelete,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Delete"
    zIndex="100"
    onAction={onDelete}
    onClose={onClose}
    title="Delete task status"
  >

    Are you sure you want to delete this task status?
  </ConfirmModal>
);

DeleteTaskStatusModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isPending: PropTypes.bool,
};

DeleteTaskStatusModal.defaultProps = {
  isPending: false,
  isOpen: false,
};

export default DeleteTaskStatusModal;
