import { handleActions } from 'redux-actions';

import types from './types';
import { mergeIn } from '../../utils/helpers/ramdaStateHelpers';

const reducer = handleActions({
  [types.SET_SPRINTS_PROJECT]: mergeIn(({ payload: { data } }) => data),
}, {});

export default reducer;
