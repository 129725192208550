/* eslint-disable import/prefer-default-export */
import {
  isEmpty, compose, filter, values, reject, prop, equals, includes, flip,
} from 'ramda';

import { SPRINT_STATUS } from '../../../constants/sprints';

const hasActiveSprint = compose(isEmpty, filter(compose(equals(SPRINT_STATUS.ACTIVE),
  prop('status'))), values);

const getOnlyActiveSprints = reject(compose(
  flip(includes)([SPRINT_STATUS.NOT_ACTIVE, SPRINT_STATUS.COMPLETE]),
  prop('status'),
));

export {
  hasActiveSprint,
  getOnlyActiveSprints,
};
