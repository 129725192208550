import React from 'react';
import { ControlledDateField, ControlledField } from '..';

const splitArrEveryTwo = (arr) => {
  const completeArr = [];
  for (let i = 0; i < arr.length; i += 2) {
    completeArr.push(arr.slice(i, i + 2));
  }
  return completeArr;
};

/**
 *
 * @param fields {array}
 * @param control {object}
 * @returns {JSX.Element}
 * @constructor
 */

const PeopleFields = ({ fields = [], control = {} }) => {
  const customFieldsData = splitArrEveryTwo(fields);
  return customFieldsData.map((array, index) => (
    <div
      className="userForm__row"
      key={`people-fields-row-${fields[index].name}`}
    >
      {array.map(({
        name, title, placeholer, disabled, dateError, dateLimits, isDate,
      }) => (isDate ? (
        <ControlledDateField
          id={name}
          title={title}
          dateError={dateError}
          dateLimits={dateLimits}
          placeholer={placeholer}
          disabled={disabled}
          control={control}
          key={name}
        />
      ) : (
        <ControlledField
          id={name}
          title={title}
          placeholer={placeholer}
          disabled={disabled}
          control={control}
          key={name}
        />
      )
      ))
          }
    </div>
  ));
};

export default PeopleFields;
