import {
  compose, lifecycle, pure, withContext, withHandlers, withProps, withStateHandlers,
} from 'recompose';
import { prop } from 'ramda';
import PropTypes from 'prop-types';

import CustomNode from '../customNode';
import { curryRegexTest, regexRules } from '../helpers/uiComponentHelpers/common';
import { appendNodeAndSetFocus, replaceNodeTagByOffset } from '../helpers/uiComponentHelpers/caretHelpers';
import { KEYS } from '../../constants/ui';
import {
  createMentionNode, editMention,
  getCoordinatesForReplacedWord,
  replaceMentionToText,
} from '../helpers/mentionHelpers/events';

const setSelectedMentionStateHandler = () => value => ({ activeDropdownMember: value });
const setIsMentionEditedStateHandler = () => value => ({ isMentionEdited: value });
const setMentionsStateHandler = () => value => ({ mentions: value });
const setIsDropdownMentionsOpenStateHandler = () => value => ({ isDropdownMentionsOpen: value });
const setIsMouseOverMentionStateHandler = ({ isEventFirst }) => (value) => {
  if (value && isEventFirst) {
    return {
      isEventFirst: false,
    };
  }
  return { isMouseOverMention: value, isEventFirst: !value };
};
const onCloseMentionDropdownStateHandler = () => () => ({
  isMentionEdited: false,
  isDropdownMentionsOpen: false,
  isMouseOverMention: false,
  activeDropdownMember: 0,
});

const onClickMentionHandler = ({
  onSelectMention,
  onCloseMentionDropdown,
}) => compose(onCloseMentionDropdown, onSelectMention);

const onMentionKeyDownHandler = ({
  activeDropdownMember,
  onSelectMention,
  onCloseMentionDropdown,
  isDropdownMentionsOpen,
  onMouseOverMention,
}) => (event) => {
  const { keyCode } = event;
  if (isDropdownMentionsOpen) {
    onMouseOverMention(false);
    if (keyCode === KEYS.DOWN_ARROW || keyCode === KEYS.UP_ARROW) {
      event.preventDefault();
      const nextSelectedMention = keyCode === KEYS.UP_ARROW ? activeDropdownMember - 1
        : activeDropdownMember + 1;
      if (nextSelectedMention >= 0) {
        onSelectMention(nextSelectedMention);
      }
    }

    if (keyCode === KEYS.ENTER) {
      event.preventDefault();
      event.stopPropagation();
      onSelectMention(activeDropdownMember);
    }

    if (keyCode === KEYS.ESCAPE || keyCode === KEYS.LEFT_ARROW || keyCode === KEYS.RIGHT_ARROW
      || keyCode === KEYS.ENTER) {
      event.preventDefault();
      event.stopPropagation();
      onCloseMentionDropdown();
    }
  }
};

const onClickOutsideHandler = ({ onCloseMentionDropdown, isDropdownMentionsOpen }) => (e) => {
  if (!e.target.getAttribute('data-mentionId') && !e.target.closest('[data-mentionId]')) {
    if (isDropdownMentionsOpen) {
      onCloseMentionDropdown();
    }
  }
};

const withMentions = ({
  mentions, parentElement, onUpdateContent, onSelectMention,
}) => compose(
  withProps((props) => {
    const getElement = parentElement(props);
    return ({
      parentElement: getElement,
      onUpdateContent: onUpdateContent(props),
      mentionTag: (range, selection) => {
        const { focusNode, anchorOffset } = selection;
        return CustomNode(
          focusNode,
          getElement,
          compose(createMentionNode),
          compose(curryRegexTest(regexRules.regIsMentionCompleted), prop('data')),
          getCoordinatesForReplacedWord(anchorOffset),
          replaceNodeTagByOffset(range, selection, getElement, focusNode),
          appendNodeAndSetFocus(range, selection, getElement),
          replaceMentionToText(range, selection),
          compose(editMention(range, selection, getElement, focusNode)),
        );
      },
    });
  }),
  withStateHandlers(props => ({
    mentions: mentions(props),
    isDropdownMentionsOpen: false,
    activeDropdownMember: -1,
    isMentionEdited: false,
    isMouseOverMention: false,
    isEventFirst: true,
  }), {
    setIsMentionEdited: setIsMentionEditedStateHandler,
    setMentions: setMentionsStateHandler,
    setIsDropdownMentionsOpen: setIsDropdownMentionsOpenStateHandler,
    setSelectedMention: setSelectedMentionStateHandler,
    onMouseOverMention: setIsMouseOverMentionStateHandler,
    onCloseMentionDropdown: onCloseMentionDropdownStateHandler,
  }),
  withHandlers({
    onSelectMention: props => data => onSelectMention(props)(data),
  }),
  withHandlers({
    onClickMention: onClickMentionHandler,
    onMentionKeyDown: onMentionKeyDownHandler,
    onClickOutside: onClickOutsideHandler,
  }),
  withContext(
    {
      onClickMention: PropTypes.func,
      onMouseOverMention: PropTypes.func,
      activeDropdownMember: PropTypes.number,
      isDropdownMentionsOpen: PropTypes.bool,
      isMouseOverMention: PropTypes.bool,
    },
    ({
      activeDropdownMember, isDropdownMentionsOpen, onClickMention,
      onMouseOverMention, isMouseOverMention,
    }) => ({
      onClickMention,
      onMouseOverMention,
      activeDropdownMember,
      isDropdownMentionsOpen,
      isMouseOverMention,
    }),
  ),
  pure,
  lifecycle({
    componentDidMount() {
      document.addEventListener('click', this.props.onClickOutside, true);
    },
    componentDidUpdate({ isDropdownMentionsOpen }) {
      if (isDropdownMentionsOpen !== this.props.isDropdownMentionsOpen) {
        if (isDropdownMentionsOpen) {
          this.props.setIsBeginMention(true);
        } else {
          this.props.setSelectedMention(-1);
        }
      }
    },
    componentWillUnmount() {
      document.removeEventListener('click', this.props.onClickOutside, true);
    },
  }),
);

export default withMentions;
