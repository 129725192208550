import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';

import './style.sass';
import { ButtonsCollection, Grid, FormsCollection } from '../../../../../../ui-kit';

const AddItemForm = ({
  values, errors, handleChange, setIsFormOpen,
}) => (
  <div className="add-invoice-item__form">
    <Grid.Row
      key="invoice-item-grid-addItemForm"
      className="add-invoice-item__row invoice-item"
    >
      <Grid.ColData className="add-invoice-item__col add-invoice-item__col--details">
        <FormsCollection.Input
          name="invoice_details"
          value={values.invoice_details}
          error={propOr('', 'message', errors.invoice_details)}
          touched
          onChange={handleChange}
          id="add-invoice-item__field add-invoice-item__field--invoice_details"
          placeholderJump="Invoice details"
        />
      </Grid.ColData>
      <Grid.ColData className="add-invoice-item__col add-invoice-item__col--quantity">
        <FormsCollection.Input
          name="quantity"
          value={values.quantity}
          error={propOr('', 'message', errors.quantity)}
          touched
          onChange={handleChange}
          id="add-invoice-item__field add-invoice-item__field--quantity"
          placeholderJump="Quantity"
        />
      </Grid.ColData>
      <Grid.ColData className="add-invoice-item__col add-invoice-item__col--rate">
        <FormsCollection.Input
          name="rate"
          value={values.rate}
          error={propOr('', 'message', errors.rate)}
          touched
          onChange={handleChange}
          id="add-invoice-item__field add-invoice-item__field--rate"
          placeholderJump="Rate"
        />
      </Grid.ColData>
      <Grid.ColData className="add-invoice-item__col add-invoice-item__col--amount">
        <FormsCollection.Input
          name="amount"
          value={values.amount}
          error={propOr('', 'message', errors.amount)}
          touched
          onChange={handleChange}
          id="add-invoice-item__field add-invoice-item__field--amount"
          placeholderJump="Amount"
        />
      </Grid.ColData>
      <Grid.ColData className="add-invoice-item__col add-invoice-item__col--submit-button">
        <ButtonsCollection.ButtonBrill
          type="submit"
          className="button-brill--fill button--md add-invoice-item--submit-button"
        >
          Add item
        </ButtonsCollection.ButtonBrill>
      </Grid.ColData>
      <Grid.ColData className="add-invoice-item__col add-invoice-item__col--cancel-button">
        <button
          type="button"
          className="button--cancel disposable-form__cancel-button"
          onClick={() => setIsFormOpen(false)}
        >
          Cancel
        </button>
      </Grid.ColData>
    </Grid.Row>
  </div>
);

AddItemForm.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  setIsFormOpen: PropTypes.func.isRequired,
};

export default AddItemForm;
