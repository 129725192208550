/* eslint-disable jsx-a11y/interactive-supports-focus,jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { identity } from 'ramda';
import { MessageElements } from '../../../../../../components';

import './style.sass';

const PinnedItems = ({
  pinnedMessages, pinnedIds, t, onUnpinMessage, onShowUserProfile,
  setScrollToMessage, closeRightSideBar, windowWidth,
}) => (
  <div className="pinned-items">
    {
      pinnedIds.length
        ? (
          <ul className="pinned-items__list">
            {pinnedIds.map((id) => {
              const message = pinnedMessages[id];
              return (
                <li key={id} className="pinned-items__item">
                  <div
                    className="pinned-items__message pinned-message"
                    role="button"
                    onClick={() => {
                      // eslint-disable-next-line no-unused-expressions
                      windowWidth < 768 && closeRightSideBar();
                      setScrollToMessage(message);
                    }}
                  >
                    <div className="pinned-message__header">
                      <div
                        tabIndex={0}
                        role="button"
                        onFocus={identity}
                        className="pinned-message__author text--cut"
                        onClick={(e) => {
                          e.stopPropagation();
                          onShowUserProfile(message.created_by);
                        }}
                      >
                        {`${message.first_name} ${message.last_name}`}
                      </div>
                      <div className="pinned-message__date">
                        {moment(message.created_at).format('DD MMM YYYY')}
                      </div>
                    </div>
                    <div className="pinned-message__body">
                      <span>
                        <MessageElements messageId={message.id}>{message.content}</MessageElements>
                      </span>
                    </div>
                    <button
                      type="button"
                      className="pinned-message__remove"
                      onClick={(e) => {
                        e.stopPropagation();
                        onUnpinMessage(message);
                      }}
                    >
                      <span className="icon-close" />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        )
        : (
          <div className="pinned-items__empty">
            {t('There are no pinned items yet')}
          </div>
        )
    }
  </div>
);

PinnedItems.propTypes = {
  pinnedMessages: PropTypes.objectOf(PropTypes.object),
  pinnedIds: PropTypes.arrayOf(PropTypes.number),
  onShowUserProfile: PropTypes.func.isRequired,
  onUnpinMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setScrollToMessage: PropTypes.func.isRequired,
  closeRightSideBar: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
};
PinnedItems.defaultProps = {
  pinnedMessages: [],
  pinnedIds: [],
};
export default PinnedItems;
