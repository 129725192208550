import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import RightSideBarInvoicesContainer from './rightSideBarInvoicesContainer';
import { invoicesSelectors } from '../../../../../../state/invoices';

const mapStateToProps = state => ({
  summary: invoicesSelectors.getInvoiceSummary(state),
});

const enhance = compose(
  connect(mapStateToProps, null),
  withTranslation(['common']),
);

export default enhance(RightSideBarInvoicesContainer);
