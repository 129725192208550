import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';

import {
  ConfirmModal,
} from '../../../ui-kit';

const UnpinTaskModal = ({
  isOpen,
  isPending,
  onClose,
  task,
  onUnpin,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Unpin"
    onAction={onUnpin}
    onClose={onClose}
    title="Unpin task"
  >

    Are you sure you want to unpin
    <b>{prop('title', task)}</b>

    task?
  </ConfirmModal>
);

UnpinTaskModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUnpin: PropTypes.func.isRequired,
  task: PropTypes.shape({}).isRequired,
};

export default UnpinTaskModal;
