import React from 'react';
import PropTypes from 'prop-types';
import { isNil, prop } from 'ramda';
import { ButtonsCollection, FormsCollection } from '../../../../../ui-kit';
import { POSITIONS, STATUSES } from '../../constants';
import HrCreateComment from '../hrCreateComment';
import HrFilesList from '../hrFilesList';
import './style.scss';

const HrCreateForm = ({
  values, touched, handleSubmit, handleChange,
  errors, resetFilesIdList, handleBlur,
  candidate, onOpenUploadFilesModal, setScrollRef,
  filesListIds, filesList, filesEntities, setChangeComments,
}) => (
  <FormsCollection.FormWrapper
    handleSubmit={handleSubmit}
  >
    <div
      ref={setScrollRef}
      className="HrCreateForm"
    >
      <div className="HrCreateForm_col">
        <p className="HrCreateForm_col__title">Main information</p>
        <div className="HrCreateForm_col__field">
          <label className="userFormInvite__label" htmlFor="candidate_first_name">First name</label>
          <FormsCollection.Input
            id="candidate_first_name"
            error={errors.first_name}
            onBlur={handleBlur}
            value={values.first_name}
            touched
            name="first_name"
            onChange={handleChange}
            placeholder="Jack"
          />
        </div>
        <div className="HrCreateForm_col__field">
          <label className="userFormInvite__label" htmlFor="candidate_last_name">Last name</label>
          <FormsCollection.Input
            id="candidate_last_name"
            value={values.last_name}
            error={errors.last_name}
            touched
            name="last_name"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Parrow"
          />
        </div>
        <div className="HrCreateForm_col__field">
          <label className="userFormInvite__label" htmlFor="candidate_position">Position</label>
          <FormsCollection.Select
            isRequired
            touched
            id="candidate_position"
            value={values.position}
            error={errors.position}
            name="position"
            onBlur={handleBlur}
            onChange={handleChange}
            data={POSITIONS}
          />
        </div>
        <div className="HrCreateForm_col__field">
          <span className="userFormInvite__label">Status</span>
          <FormsCollection.Select
            name="status"
            value={values.status}
            error={errors.status}
            onBlur={handleBlur}
            onChange={handleChange}
            data={STATUSES}
            isRequired
          />
        </div>
        <div className="HrCreateForm_col__field">
          <label className="userFormInvite__label" htmlFor="candidate_first_contact">First contact</label>
          <FormsCollection.DatePicker
            id="candidate_first_contact"
            name="first_contact"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.first_contact}
            touched={touched.first_contact}
            error={errors.first_contact}
          />
        </div>
        <div className="HrCreateForm_col__field">
          <label className="userFormInvite__label" htmlFor="candidate_date_of_birthday">Date of birthday</label>
          <FormsCollection.DatePicker
            id="candidate_date_of_birthday"
            name="date_of_birthday"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.date_of_birthday}
            touched={touched.date_of_birthday}
            error={errors.date_of_birthday}
            maxDate={new Date()}
          />
        </div>
        <div className="HrCreateForm_col__field">
          <label className="userFormInvite__label" htmlFor="candidate_salary">Salary</label>
          <FormsCollection.Input
            id="candidate_salary"
            error={errors.salary}
            onBlur={handleBlur}
            value={values.salary}
            touched
            name="salary"
            onChange={handleChange}
            placeholder="Enter salary"
          />
        </div>
      </div>
      <div className="HrCreateForm_col">
        <p className="HrCreateForm_col__title">Contact information</p>
        <div className="HrCreateForm_col__field">
          <label className="userFormInvite__label" htmlFor="candidate_phone">Phone number</label>
          <FormsCollection.Input
            id="candidate_phone"
            value={values.phone}
            touched
            name="phone"
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.phone}
            placeholder="+380999999999"
          />
        </div>
        <div className="HrCreateForm_col__field">
          <label className="userFormInvite__label" htmlFor="candidate_email">Email</label>
          <FormsCollection.Input
            id="candidate_email"
            value={values.email}
            touched
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.email}
            placeholder="example@gmail.com"
          />
        </div>
        <div className="HrCreateForm_col__field">
          <label className="userFormInvite__label" htmlFor="candidate_skype">Skype</label>
          <FormsCollection.Input
            id="candidate_skype"
            value={values.skype}
            touched
            name="skype"
            error={errors.skype}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Skype account"
          />
        </div>
        <div className="HrCreateForm_col__field">
          <label className="userFormInvite__label" htmlFor="candidate_linkedin_url">LinkedIn</label>
          <FormsCollection.Input
            id="candidate_linkedin_url"
            value={values.linkedin_url}
            touched
            name="linkedin_url"
            error={errors.linkedin_url}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="http://example.com"
          />
        </div>
        <div className="HrCreateForm_col__field">
          <label className="userFormInvite__label" htmlFor="candidate_facebook_url">Facebook</label>
          <FormsCollection.Input
            id="candidate_facebook_url"
            value={values.facebook_url}
            error={errors.facebook_url}
            touched
            name="facebook_url"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="http://example.com"
          />
        </div>
        <div className="HrCreateForm_col__field">
          <label className="userFormInvite__label" htmlFor="hrCandidate_city">City</label>
          <FormsCollection.Input
            value={values.city || ''}
            error={errors.city}
            id="hrCandidate_city"
            touched
            name="city"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="City"
          />
        </div>
      </div>
      <div className="HrCreateForm_col HrCreateForm_col--align-top">
        <div className="flex-row-between">
          <label className="HrCreateForm_col__title" htmlFor="comments">Comments</label>
        </div>
        <HrCreateComment
          name="hrComments"
          comments={values.hrComments}
          handleChange={handleChange}
          onBlur={handleBlur}
          onToggleFields={setChangeComments}
        />
      </div>
      <div className="HrCreateForm_col">
        <div className="flex-row-between">
          <p className="HrCreateForm_col__title">Files</p>
          <ButtonsCollection.ButtonBrill
            className="HrCreateForm_col__field-btn"
            onClick={onOpenUploadFilesModal}
            title="Upload files"
            type="button"
          >
            <span>Attach files</span>
          </ButtonsCollection.ButtonBrill>
        </div>
        <div className="HrCreateForm_col__field HrCreateForm_col__field-autoHeight mt-0">
          <FormsCollection.Input
            id="hrCandidate_file_link"
            touched
            name="file_link"
            value={values.file_link}
            error={errors.file_link}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="http://example.com"
          />
        </div>
        <HrFilesList
          files={filesList}
          entities={filesEntities}
          tempFiles={filesListIds}
          deleteTempFile={resetFilesIdList}
        />
      </div>
      <div className="HrCreateForm_row">
        <label className="HrCreateForm_col__title" htmlFor="conclusion">Conclusion</label>
        <div className="HrCreateForm_col__field HrCreateForm_col__field-autoHeight">
          <FormsCollection.TextArea
            value={values.conclusion}
            name="conclusion"
            placeholder="Type a message"
            error={prop('message', errors.conclusion)}
            onChange={handleChange}
            onBlur={handleBlur}
            maxHeight={210}
            autoHeight
            isFocus
            isNotValidationPasteText
            touched
          />
        </div>
      </div>
      <div className="HrCreateForm_row HrCreateForm_row-end">
        <ButtonsCollection.ButtonBrill
          type="submit"
          isPending={false}
          className="userFormInvite__submit-button button-brill--fill"
        >
          {isNil(candidate) ? 'Create candidate' : 'Save'}
        </ButtonsCollection.ButtonBrill>
      </div>
    </div>
  </FormsCollection.FormWrapper>
);

HrCreateForm.propTypes = {
  filesList: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(Object),
  ]),
  filesEntities: PropTypes.instanceOf(Object),
  filesListIds: PropTypes.instanceOf(Array).isRequired,
  resetFilesIdList: PropTypes.func.isRequired,
  setScrollRef: PropTypes.func.isRequired,
  setChangeComments: PropTypes.func.isRequired,
  candidate: PropTypes.instanceOf(Object),
  values: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  onOpenUploadFilesModal: PropTypes.func.isRequired,
  errors: PropTypes.shape().isRequired,
  touched: PropTypes.oneOfType(
    [
      PropTypes.shape(),
      PropTypes.bool,
    ],
  ),
};

HrCreateForm.defaultProps = {
  touched: false,
  candidate: null,
  filesList: [],
  filesEntities: {},
};

export default HrCreateForm;
