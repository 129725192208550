import {
  includes, pickBy, test, filter, isEmpty,
} from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { NavLink, useParams } from 'react-router-dom';

import { APP_ROUTES } from '../../../../constants/appRoutes';
import { LAST_CHANNEL_MESSENGER_STORAGE } from '../../../../constants/messenger';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';
import modulesParams from '../../../../modules/modules-nav.json';
import * as messengerSelectors from '../../../../state/messenger/selectors';
import { uiActions, uiSelectors } from '../../../../state/ui';
import { openModal } from '../../../../state/ui/actions';
import { ButtonsCollection, Label, PermissionElement } from '../../../../ui-kit';
import { useWindowWidth } from '../../../../utils/hooks';

import './style.sass';

/**
 * @returns {JSX.Element}
 * @constructor
 */

const TABLET_SIZE = 768;
const ADMIN_ID = 1;
const PERMITTED_LINKS = ['next.avanga.org', 'localhost', 'dev.avanga.org'];

const filterModule = ({ navigationName }) => (
  !includes(navigationName, ['HR management'])
  || !isEmpty(filter(item => test(new RegExp(item, 'g'), window.location.href), PERMITTED_LINKS))
);
const modulesMenuList = Object.keys(pickBy(filterModule, modulesParams));

export const MainNav = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const params = useParams();
  const { pathname } = useLocation();

  const channelIdFromStorage = window.localStorage
    .getItem(LAST_CHANNEL_MESSENGER_STORAGE) || ADMIN_ID;

  const channelIdFromUrl = params?.id || channelIdFromStorage;

  const [lastChannelId, setLastChannelId] = useState(channelIdFromUrl);

  const totalUnreadCount = useSelector(messengerSelectors.getTotalUnreadCount);
  const leftSideBarStatus = useSelector(uiSelectors.getLeftSidebar);

  const setOpenModal = useCallback(modalName => dispatch(openModal(modalName)));

  const changeLeftSidebarStatus = useCallback((value) => {
    dispatch(uiActions.changeLeftSidebarStatus(value));
  });

  const onChangeStorage = useCallback(() => {
    setLastChannelId(channelIdFromStorage);
  });

  const onCreateProjectModal = useCallback((e) => {
    e.preventDefault();
    setOpenModal('createProjectModal');
  });

  const onToggle = useCallback(() => {
    if (windowWidth < TABLET_SIZE) {
      changeLeftSidebarStatus(!leftSideBarStatus);
    }
  });

  useEffect(() => {
    window.addEventListener('storageChange', onChangeStorage, false);
    window.addEventListener('storage', onChangeStorage, false);
  }, []);

  useEffect(() => {
    if (channelIdFromUrl) {
      setLastChannelId(channelIdFromUrl);
    }
  }, [channelIdFromUrl]);

  useEffect(() => {
    window.removeEventListener('storageChange', onChangeStorage, false);
    window.removeEventListener('storage', onChangeStorage, false);
  });

  return (
    <nav className="main-nav">
      <NavLink
        title={!leftSideBarStatus ? t('Projects') : ''}
        to={APP_ROUTES.PROJECTS}
        activeClassName="main-nav__item--active"
        className="main-nav__item"
        onClick={onToggle}
      >
        <span className="icon-box-icon-2 main-nav__icon" />
        <span className="hide-element--flex main-nav__text">Projects</span>
        <PermissionElement module={MODULES.PROJECTS} action={ACTIONS.CREATE}>
          <ButtonsCollection.ButtonPlus
            onClick={onCreateProjectModal}
            title="Create project"
            className="hide-element--flex"
          />
        </PermissionElement>
      </NavLink>
      <NavLink
        title={!leftSideBarStatus && t('Tasks')}
        to={APP_ROUTES.TASKS}
        exact
        activeClassName="main-nav__item--active"
        className="main-nav__item"
        onClick={onToggle}
      >
        <span className="icon-ok-icon-2 main-nav__icon" />
        <span className="hide-element--flex main-nav__text">Tasks</span>
      </NavLink>
      <NavLink
        title={!leftSideBarStatus && t('Chats')}
        to={`${APP_ROUTES.MESSENGER}${lastChannelId}`}
        activeClassName="main-nav__item--active"
        className="main-nav__item"
        onClick={onToggle}
      >
        <span className="icon-talk-cloud-icon main-nav__icon" />
        <span className="hide-element--flex main-nav__text">Chats</span>
        {
          totalUnreadCount > 0 && (
            <Label
              className="label--xs label--fill main-nav__total-unread-count"
            >
              {totalUnreadCount}
            </Label>
          )
        }
      </NavLink>
      <NavLink
        title={!leftSideBarStatus && t('Activity')}
        to={APP_ROUTES.MY_ACTIVITY}
        isActive={() => pathname.includes('activity')}
        activeClassName="main-nav__item--active"
        className="main-nav__item"
        onClick={onToggle}
      >
        <span className="icon-timer-2-icon main-nav__icon" />
        <span className="hide-element--flex main-nav__text">Activity</span>
      </NavLink>
      <NavLink
        title={!leftSideBarStatus && t('Knowledge base')}
        to={APP_ROUTES.KNOWLEDGE_BASE}
        isActive={() => pathname.includes('knowledge-base')}
        activeClassName="main-nav__item--active"
        className="main-nav__item"
        onClick={onToggle}
      >
        <span className="icon-knowledge-base main-nav__icon" />
        <span className="hide-element--flex main-nav__text">Knowledge base</span>
      </NavLink>
      <PermissionElement module={MODULES.SETTINGS} action={ACTIONS.READ}>
        <NavLink
          title={!leftSideBarStatus && t('Preferences')}
          to={APP_ROUTES.PREFERENCES}
          isActive={() => pathname.includes('preferences')}
          activeClassName="main-nav__item--active"
          className="main-nav__item"
          onClick={onToggle}
        >
          <span className="icon-settings main-nav__icon" />
          <span className="hide-element--flex main-nav__text">Preferences</span>
        </NavLink>
      </PermissionElement>
      <PermissionElement module={MODULES.LEADS} action={ACTIONS.READ}>
        <NavLink
          title={!leftSideBarStatus && t('CRM')}
          to={APP_ROUTES.CRM}
          activeClassName="main-nav__item--active"
          className="main-nav__item"
          onClick={onToggle}
        >
          <span className="icon-crm main-nav__icon" />
          <span className="hide-element--flex main-nav__text">CRM</span>
        </NavLink>
      </PermissionElement>
      {
        // hiden Business Processes
        modulesMenuList.map(key => modulesParams[key].navigationName !== 'Business Processes' && (
          <PermissionElement
            key={`main-nav-${key}`}
            module={modulesParams[key].permissionModuleName}
            action={modulesParams[key].permissionModuleAction}
          >
            <NavLink
              title={!leftSideBarStatus && t(modulesParams[key].navigationName)}
              to={modulesParams[key].navigationLink}
              activeClassName="main-nav__item--active"
              className="main-nav__item"
              onClick={onToggle}
            >
              <span className={` main-nav__icon ${modulesParams[key].navigationIconClass}`} />
              <span className="hide-element--flex main-nav__text">
                {modulesParams[key].navigationName}
              </span>
            </NavLink>
          </PermissionElement>
        ))
      }
    </nav>
  );
};
