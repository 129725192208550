import {
  compose, getContext,
} from 'recompose';
import PropTypes from 'prop-types';
import { withRefs } from '../../utils/enchancers';

import MentionsDropdown from './MentionsDropdown';

const enhance = compose(
  withRefs(),
  getContext({
    activeDropdownMember: PropTypes.number,
    onMouseOverMention: PropTypes.func.isRequired,
  }),
);

export default enhance(MentionsDropdown);
