import * as yup from 'yup';

const rules = yup.object().shape({
  title: yup
    .string()
    .required('Title is required'),
  content: yup
    .string()
    .required('Description is required'),
});

export default rules;
