import { createSelector } from 'reselect';
import {
  identity, memoizeWith, path, pathOr, propOr,
} from 'ramda';
import { LEADS_VIEW } from '../../constants/crm';

const getLeadByIdFormList = leads => memoizeWith(
  identity, leadId => propOr(null, leadId, leads),
);

const getLeadByStatusIdFormList = leadsBoard => memoizeWith(
  identity, statusId => propOr([], statusId, leadsBoard),
);

const createLeadSubDataSelector = key => createSelector(
  path(['leads', key, 'result']),
  entities => memoizeWith(identity, leadId => propOr([], leadId, entities)),
);

const createLeadSubDataEntitySelector = key => createSelector(
  path(['leads', key, 'entities']),
  entities => memoizeWith(identity, id => propOr(null, id, entities)),
);

const getLeadsEntitySelector = createSelector(
  path(['leads', 'summary', 'entities', 'leads']),
  identity,
);

const getLeadsBoardSelector = createSelector(
  path(['leads', 'board']),
  identity,
);

const getLeadsIDsSelector = createSelector(
  path(['leads', 'summary', 'result']),
  identity,
);

const getLeadById = createSelector(
  getLeadsEntitySelector,
  getLeadByIdFormList,
);

const getLeadsByPipelineStatusId = createSelector(
  getLeadsBoardSelector,
  getLeadByStatusIdFormList,
);

const getLeadTasksSelector = createLeadSubDataSelector('leadsTasks');
const getLeadContactsSelector = createLeadSubDataSelector('leadsContacts');
const getLeadNotesSelector = createLeadSubDataSelector('leadsNotes');

const getLeadTaskSelector = createLeadSubDataEntitySelector('leadsTasks');
const getLeadContactSelector = createLeadSubDataEntitySelector('leadsContacts');
const getLeadNoteSelector = createLeadSubDataEntitySelector('leadsNotes');

const getLeadsNoteCountSelector = createSelector(pathOr(0, ['leads', 'leadsNotes', 'count']), identity);
const getLeadsContactCountSelector = createSelector(pathOr(0, ['leads', 'leadsContacts', 'count']), identity);

const getLeadLogsSelector = createSelector(
  path(['leads', 'leadLogs']),
  leadLogs => memoizeWith(identity, leadId => propOr({}, leadId, leadLogs)),
);

const getPinnedLeadsId = createSelector(
  path(['leads', 'pinnedLeads', 'result']),
  identity,
);

const getPinnedLeadsEntitySelector = createSelector(
  path(['leads', 'pinnedLeads', 'entities', 'pinnedLeads']),
  identity,
);

const getPinnedLeadById = createSelector(
  getPinnedLeadsEntitySelector,
  getLeadByIdFormList,
);

const getLeadActionPending = createSelector(
  pathOr(false, ['pending']),
  pendingInfo => memoizeWith(identity, pending => propOr(false, pending, pendingInfo)),
);


const getLeadsPendingRequest = createSelector(pathOr(false, ['pending', 'getLeadsRequest']), identity);
const getPinnedLeadsPendingRequest = createSelector(pathOr(false, ['pending', 'getPinnedLeadsRequest']), identity);
const getLeadTasksPendingRequest = createSelector(pathOr(false, ['pending', 'getLeadTasksRequest']), identity);

const getLeadsCount = createSelector(pathOr(0, ['leads', 'summary', 'count']), identity);
const getLeadsOffset = createSelector(pathOr(0, ['leads', 'summary', 'offset']), identity);
const getLeadsSummaryCount = createSelector(pathOr({}, ['leads', 'summary', 'summaryCount']), identity);

const getActiveRelatedLeadSelector = createSelector(pathOr(null, ['leads', 'activeRelatedLead']), identity);

const getCheckedPipelineSelector = createSelector(pathOr(null, ['leads', 'checkedPipeline']), identity);
const getLeadsViewSelector = createSelector(pathOr(LEADS_VIEW.LIST, ['leads', 'leadsView']), identity);

export {
  getLeadsEntitySelector,
  getLeadsIDsSelector,
  getLeadById,
  getLeadsPendingRequest,
  getLeadsCount,
  getPinnedLeadsId,
  getPinnedLeadsEntitySelector,
  getPinnedLeadById,
  getPinnedLeadsPendingRequest,
  getLeadTasksSelector,
  getLeadTasksPendingRequest,
  getLeadTaskSelector,
  getLeadActionPending,
  getLeadContactsSelector,
  getActiveRelatedLeadSelector,
  getLeadContactSelector,
  getLeadLogsSelector,
  getLeadNotesSelector,
  getLeadNoteSelector,
  getLeadsNoteCountSelector,
  getLeadsByPipelineStatusId,
  getCheckedPipelineSelector,
  getLeadsViewSelector,
  getLeadsSummaryCount,
  getLeadsContactCountSelector,
  getLeadsOffset,
};
