/* eslint-disable no-unused-expressions */
import { Emoji } from 'emoji-mart';

import {
  cond,
  curry,
  dec,
  flip,
  gt,
  gte,
  inc,
  length,
  lte,
  not,
  path,
  pathOr,
  prop,
  T,
  tap,
  replace,
  compose as ramdaCompose,
} from 'ramda';
import { Either, Tuple } from 'ramda-fantasy';
import { compose } from 'recompose';
import { getHTMLElementByString } from './DOMhelpers';
import { regexRules } from './common';

const { Left, Right } = Either;

const countItemsPerRow = 10;
const moveToPrevEmojiRow = activeItem => activeItem - countItemsPerRow;
const moveToPrevEmojiItem = activeItem => activeItem - 1;
const tupleCategory = Tuple.snd;
const tupleEmoji = Tuple.fst;
const moveToNextEmojiRow = inc;
const moveToPrevEmoji = dec;
const moveToPrevCategory = dec;

const getActiveChildren = curry(pathToChildren => path(pathToChildren));
const getOffsetTop = prop('offsetTop');

const getEmojiHtmlStringById = (id = ':smile:', size = 16) => Emoji({
  html: true,
  emoji: id,
  tooltip: id,
  set: 'messenger',
  size,
});

// TODO should be refactored


const convertSpanEmojiToImg = (element, dataCode) => {
  const style = pathOr(false, ['style', 'cssText'], element);
  if (!style) return '';
  const code = element.getAttribute('data-code');
  const ariaLabel = element.getAttribute('aria-label');
  const imageElement = document.createElement('img');

  imageElement.setAttribute('data-code', dataCode || code);
  imageElement.setAttribute('aria-label', ariaLabel);
  imageElement.setAttribute('style', style);
  imageElement.setAttribute('src', 'data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='); // Set to src image  transparent pixel
  imageElement.className = 'emoji__item';
  // eslint-disable-next-line no-useless-escape
  return imageElement;
};

const insertEmojiNode = (element) => {
  const selection = window.getSelection();
  if (selection) {
    if (selection.focusNode.parentNode.getAttribute('data-type') === 'mention') {
      const { nextSibling } = selection.focusNode.parentNode;
      nextSibling.parentNode.insertBefore(element, nextSibling.nextSibling);
    } else {
      const range = selection.getRangeAt(0);
      range.collapse(true);
      range.insertNode(element);
      selection.removeAllRanges();
    }
  }
  return element;
};

const convertDirtyEmojiInPure = (value) => {
  const regAllHtmlEmoji = /<img\s+[^>]*src="([^"]*)"[^>]*>/gi;
  const emojiElement = string => getHTMLElementByString(string);
  const getCodeFromElement = element => element.getAttribute('data-code');
  return value.replace(regAllHtmlEmoji,
    dirtyHTML => `${getCodeFromElement(emojiElement(dirtyHTML))}`)
    .replace('&nbsp;', '');
};

const convertEmojiForCopy = (value) => {
  const regAllHtmlEmoji = /<img\s+[^>]*src="([^"]*)"[^>]*>/gi;
  const emojiElement = string => getHTMLElementByString(string);
  const getCodeFromElement = element => element.getAttribute('data-code');
  return value.replace(regAllHtmlEmoji,
    dirtyHTML => ` :${ramdaCompose(
      replace(/:/g, ''),
      () => getCodeFromElement(emojiElement(dirtyHTML)),
    )()}: `)
    .replace('&nbsp;', '');
};

const setEmojiInText = (value) => {
  const regEmojis = regexRules.regFullEmojiString;
  const regEmojiCode = regexRules.regEmojiForConvert;
  const convertCodeEmoji = code => code.match(regEmojiCode)[1];
  const getEmojiElement = (code) => {
    const cleanCode = convertCodeEmoji(code);
    const element = compose(convertSpanEmojiToImg,
      getHTMLElementByString,
      getEmojiHtmlStringById)(cleanCode);
    element.setAttribut && element.setAttribute('data-code', `:${cleanCode}:`);
    element.setAttribut && element.setAttribute('title', element.getAttribute('aria-label'));
    return element.outerHTML;
  };

  return value.replace(regEmojis, getEmojiElement, 21);
};

const isStart = type => compose(
  gte(0),
  type,
);

const checkIfAbleToMove = cond([
  [isStart(Tuple.snd), Left],
  [T, Right],
]);

const isStartEmoji = cond([
  [isStart(Tuple.fst), checkIfAbleToMove],
  [T, Right],
]);

const getCategoryEmojiLength = compose(
  length,
  prop('emojis'),
);

const getSelectedElement = (element, math) => compose(
  math,
  element,
);

const getSelectedEmojiAndIncrement = getSelectedElement(tupleEmoji, inc);
const getSelectedCategoryAndIncrement = getSelectedElement(tupleCategory, inc);
const getSelectedCategoryAndDec = getSelectedElement(tupleCategory, dec);

const changeCategory = curry((
  setSelectedCategory,
  setSelectedEmoji,
  data,
  selectedCategory,
) => compose(
  setSelectedEmoji,
  dec,
  getCategoryEmojiLength,
  flip(prop)(data),
  tap(setSelectedCategory),
  selectedCategory,
));

const checkIfEndCategory = lengthEmoji => compose(
  gt(dec(lengthEmoji)),
  Tuple.snd,
);

const setPrevActiveEmoji = curry((setActiveItem, setActiveCategory, data) => compose(
  cond([
    [compose(lte(0), moveToPrevEmoji, tupleEmoji), compose(
      setActiveItem,
      moveToPrevEmojiItem,
      tupleEmoji,
    )],
    [compose(
      not,
      isStart(tupleCategory),
    ), compose(
      changeCategory(setActiveCategory, setActiveItem, data, moveToPrevCategory),
      tupleCategory,
    )],
  ]),
));

const setNextEmojiActive = curry((
  setActiveItem,
  setActiveCategory,
  emoji,
  countCategory,
) => compose(
  cond([
    [compose(
      gte(getCategoryEmojiLength(emoji) - 1),
      getSelectedEmojiAndIncrement,
    ), compose(
      setActiveItem,
      getSelectedEmojiAndIncrement,
    )],
    [checkIfEndCategory(countCategory), compose(
      () => setActiveItem(0),
      setActiveCategory,
      getSelectedCategoryAndIncrement,
    )],
  ]),
));

const getRefActiveEmoji = curry((category, siblingElement) => compose(
  getActiveChildren(['children', category, 'children', siblingElement]),
));
export {
  convertDirtyEmojiInPure,
  setNextEmojiActive,
  getOffsetTop,
  tupleEmoji,
  tupleCategory,
  insertEmojiNode,
  changeCategory,
  moveToPrevEmojiRow,
  moveToNextEmojiRow,
  isStartEmoji,
  getSelectedCategoryAndDec,
  setEmojiInText,
  getRefActiveEmoji,
  setPrevActiveEmoji,
  getEmojiHtmlStringById,
  convertSpanEmojiToImg,
  convertEmojiForCopy,
};
