import { lifecycle, compose } from 'recompose';

const withLocationSearchChanged = ({ onChange }) => compose(
  lifecycle({
    componentDidUpdate(prevProps) {
      const { location: { search } } = this.props;
      const { location: { search: prevSearch } } = prevProps;
      if (search !== prevSearch) {
        onChange(this.props);
      }
    },
  }),
);
export default withLocationSearchChanged;
