import {
  branch, compose, lifecycle, renderNothing, defaultProps, withHandlers, getContext, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import {
  pathOr, propOr, isEmpty, path, prop, find, propEq, filter, dec,
} from 'ramda';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { notEqual, propNotEq } from 'ramda-extension';
import PropTypes from 'prop-types';
import EditTaskModal from './editTaskModal';
import rules from '../rules';
import { getActiveTask, getActiveTaskPending } from '../../../state/task/selectors';
import { setTaskData } from '../../../utils/helpers/taskHelpers/crudHelper';
import withCRUDTask from '../../../utils/enchancers/withCRUDTask';
import { tasksActions } from '../../../state/tasks';
import { newTaskActions } from '../../../state/newTask';
import { taskActions } from '../../../state/task';
import { withFilters, withUrlParams } from '../../../utils/enchancers';
import { filterAndTransformCondition, filterParamKey } from '../../../utils/helpers/uiComponentHelpers/filters';
import { projectActions } from '../../../state/project';
import { leadsActions } from '../../../state/leads';
import { clientsActions } from '../../../state/clients';

const mapStateToProps = (state, { getTaskSelector }) => {
  const task = getActiveTask(state);
  const getTaskFromPropsSelector = () => (getTaskSelector ? (getTaskSelector(state) || {}) : {});
  return {
    task: !isEmpty(task) ? task : getTaskFromPropsSelector(),
    isActiveTaskPending: getActiveTaskPending(state),
  };
};

const mapDispatchToProps = ({
  updateTask: tasksActions.updateTaskRequest,
  getTaskLogs: taskActions.getTaskLogsRequest,
  setSelectedProjectIdCrud: newTaskActions.setSelectedProjectCrud,
  getCategoriesTaskCrud: newTaskActions.getCategoriesTaskCrudRequest,
  getSprintsTaskCrud: newTaskActions.getSprintsTaskCrudRequest,
  getStatusesTasksCrud: newTaskActions.getStatusesTaskCrudRequest,
  getSprintsTaskCrudAutocomplete: newTaskActions.getSprintsTaskCrudAutocomplete,
  getTasks: projectActions.getProjectTasksRequest,
  fullEditLeadTaskSuccess: leadsActions.fullEditLeadTaskSuccess,
  fullEditClientTaskSuccess: clientsActions.fullEditClientTaskSuccess,
  getProjectStatuses: projectActions.getStatusesOfTasksRequest,
});

const loadSprintsListHandler = ({
  getSprintsTaskCrudAutocomplete,
  projectId,
}) => defaultValue => (fieldValue, callback) => {
  getSprintsTaskCrudAutocomplete([{
    projectId, title: fieldValue,
  }, {
    callbacks: {
      success: ({ data: { entities: { sprints: sprintsEntities } } }) => {
        const sprints = sprintsEntities ? Object.values(sprintsEntities) : [];
        const sprintOptions = sprints.map(({ id, title }) => ({
          label: title,
          value: id,
        }));

        callback([defaultValue, ...sprintOptions]);
      },
    },
  }]);
};

const changeParamKey = (e) => {
  const [key, value] = e;
  return ({ field: key, value });
};

const fetchDataToProjectHandler = ({
  getSprintsTaskCrud,
  getCategoriesTaskCrud,
  getStatusesTasksCrud,
}) => (projectId) => {
  if (projectId) {
    getStatusesTasksCrud({ projectId });
    getCategoriesTaskCrud({ projectId });
    getSprintsTaskCrud({ projectId, isTaskCRUD: true });
  }
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  branch(
    ({ isActiveTaskPending }) => isActiveTaskPending,
    renderNothing,
  ),
  defaultProps({
    isDisabledProjectField: false,
  }),
  getContext({
    setSelectedTaskClientId: PropTypes.func,
    setSelectedTaskLeadId: PropTypes.func,
  }),
  withUrlParams({}),
  withFilters({
    initial: ({ getUrlParam }) => ({
      sprintsStatus: getUrlParam(['sprintsStatus']),
      assignee: getUrlParam(['assignee']),
      created_by: getUrlParam(['assigner']),
      category_id: getUrlParam(['category']),
    }),
  }),
  withProps(({
    match, getTasks,
  }) => ({
    getTasksWithFilters: ({ filters }) => {
      const projectId = path(['params', 'id'], match);
      const where = filterAndTransformCondition(filterParamKey, changeParamKey, filters);
      const sprintStatusId = compose(prop('value'), find(propEq('field', 'sprintsStatus')))(where);
      const whereForTasks = compose(filter(propNotEq('field', 'sprintsStatus')))(where);
      const status = sprintStatusId ? dec(sprintStatusId) : null;
      if (projectId) {
        getTasks({
          projectId,
          where: whereForTasks,
          whereSprint: { status },
          withRelated: 'tasks',
          limit: 100,
          offset: 0,
        });
      }
    },
  })),
  withTranslation(['tasks', 'common']),
  withCRUDTask({
    rules,
    data: ({ task }) => task,
    modalName: 'editTaskModal',
    onSubmit: (formValues, {
      props: {
        updateTask,
        onCloseModal,
        selectedTaskId,
        selectedMeta,
        match,
        onSuccess,
        getUrlParam,
        task,
        taskClientId,
        setSelectedTaskClientId,
        taskLeadId,
        setSelectedTaskLeadId,
        getTasksWithFilters,
        filters,
        getTaskLogs,
        fullEditLeadTaskSuccess,
        fullEditClientTaskSuccess,
        getProjectStatuses,
      },
    }) => {
      const {
        submitButton,
      } = formValues;
      const clientId = getUrlParam(['client_id']) || taskClientId;
      const leadId = getUrlParam(['lead_id']) || taskLeadId;
      const { project_id: taskProjectId, status, status_id: statusId } = task;
      // eslint-disable-next-line no-nested-ternary
      const data = leadId ? { leadId, status, statusId }
        : (clientId ? { clientId, status, statusId } : {});

      const getNewProjectId = () => {
        const newProjectValue = path(['project_id', 'value'], formValues);
        return newProjectValue !== undefined ? newProjectValue : formValues.project_id;
      };
      updateTask({
        ...setTaskData(formValues),
        id: selectedTaskId,
        project_id: taskProjectId,
        projectId: pathOr(taskProjectId, ['params', 'id'], match),
        new_project_id: getNewProjectId(),
        ...data,
        status,
      }, {
        callbacks: {
          success: () => {
            if (submitButton !== 'saveAndCreate') {
              onCloseModal('editTaskModal');
            }
            if (setSelectedTaskClientId) {
              setSelectedTaskClientId(null);
            }
            if (setSelectedTaskLeadId) {
              setSelectedTaskLeadId(null);
            }
            if (onSuccess) {
              if (leadId) { fullEditLeadTaskSuccess({ leadId }); }
              if (clientId) { fullEditClientTaskSuccess({ clientId }); }
              onSuccess({ projectId: taskProjectId });
            }
            getTasksWithFilters({ filters });
            getTaskLogs({ taskId: selectedTaskId });
            if (taskProjectId) {
              getProjectStatuses({ projectId: taskProjectId });
            }
          },
        },
        ...selectedMeta,
      });
    },
  }),
  withHandlers({
    fetchDataToProject: fetchDataToProjectHandler,
    loadSprintsList: loadSprintsListHandler,
  }),
  lifecycle({
    componentDidMount() {
      const {
        projectId, values: { project_id }, fetchDataToProject,
      } = this.props;
      this.props.setSelectedProjectIdCrud(this.props.projectId);
      fetchDataToProject(propOr(projectId, 'value', project_id));
    },
    componentDidUpdate(prevProps) {
      const {
        values: { project_id }, fetchDataToProject, projectId,
      } = this.props;
      if (notEqual(project_id, prevProps.values.project_id)) {
        fetchDataToProject(propOr(projectId, 'value', project_id));
      }
    },
  }),
);

export default enhance(EditTaskModal);
