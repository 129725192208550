import {
  compose, renderNothing, branch, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withFormik } from 'formik';
import {
  dec, inc, map, values,
} from 'ramda';

import StatusItem from './statusItem';
import { projectActions, projectSelectors } from '../../../../../state/project';
import rules from '../rules';
import { STATUS } from '../../../../../constants/tasks';
import { renameKeys } from '../../../../../utils/helpers/commonHelpers';

const mapDispatchToProps = ({
  updateStatusOfTasksRequest: projectActions.updateStatusOfTasksRequest,
});

const mapStateToProps = (state, { id }) => ({
  item: projectSelectors.getStatusOfTasks(state)(id),
});

const initialValues = ({ item: { state, title } }) => ({
  title,
  state: dec(state),
});

const stateOfTasksStatusesOptions = compose(map(renameKeys({ title: 'label', id: 'value' })), values)(STATUS);

const onSubmitForm = ({ state, title },
  {
    props: {
      id: statusId,
      match: { params: { id } },
      updateStatusOfTasksRequest,
      setEditingStatusId,
    },
  }) => updateStatusOfTasksRequest({
  projectId: id, statusId, state: inc(state), type: state, title,
}, {
  callbacks: {
    success: () => setEditingStatusId(null),
  },
});

const enhance = compose(
  branch(({ id }) => !id, renderNothing),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: onSubmitForm,
  }),
  withProps(() => ({
    stateOfStatusesOptions: stateOfTasksStatusesOptions,
  })),
);
export default enhance(StatusItem);
