import React from 'react';
import { Preload } from '../..';
/**
 *
 * @param childern {element | string}
 * @param pending {boolean}
 * @returns {JSX.Element}
 * @constructor
 */

export const WithPreload = React.memo(
  ({ pending, children }) => <>{pending ? <Preload /> : children}</>,
);
