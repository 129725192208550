import React from 'react';
import PropTypes from 'prop-types';
import { equals } from 'ramda';

import NotesItem from './components';
import { ShowMoreItems } from '../../../../../../ui-kit';

const NotesList = ({
  activeTab,
  changeActiveTabStateHandler,
  notes,
  getMoreNotesHandler,
  clientNotesCount,
}) => (
  <div className="notes-list">
    <div className="notes-list__table">
      {notes && notes.length ? (
        <>
          <div className="notes-list__table-head">
            <div className="notes-list__table-row">
              <div className="notes-list__table-col"><b>Title</b></div>
              <div className="notes-list__table-col notes-list__table-col--description"><b>Description</b></div>
              <div className="notes-list__table-col" />
            </div>
          </div>
          <div className="notes-list__table-body">
            <ShowMoreItems
              className="client-tasks__wrapper"
              count={4}
              totalItemsCount={clientNotesCount}
              onShowMoreCustomClick={getMoreNotesHandler}
              items={
              notes.map(note => (
                <NotesItem
                  key={note.id}
                  isOpen={equals(note.id, activeTab)}
                  note={note}
                  toggleIsOpen={changeActiveTabStateHandler}
                />
              ))}
            />
          </div>
        </>
      ) : <div className="client-tasks__empty">There are no notes yet</div>}
    </div>
  </div>
);

NotesList.propTypes = {
  activeTab: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  notes: PropTypes.objectOf(PropTypes.number).isRequired,
  changeActiveTabStateHandler: PropTypes.func,
  getMoreNotesHandler: PropTypes.func.isRequired,
  clientNotesCount: PropTypes.func.isRequired,
};

NotesList.defaultProps = {
  activeTab: '',
  changeActiveTabStateHandler: () => {},
};

export default NotesList;
