import {
  compose,
} from 'recompose';
import { prop } from 'ramda';

import AttachImage from './AttachImage';
import { withUploadFile } from '../../../../utils/enchancers';

const enhance = compose(
  withUploadFile(({
    availableFormats: prop('formats'),
    availableSize: prop('maxSize'),
  })),
);
export default (enhance)(AttachImage);
