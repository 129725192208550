import React from 'react';
import PropTypes from 'prop-types';
import {
  Filters,
  ContactItem,
} from './components';
import {
  CreateContactModal,
  EditContactModal,
  DeleteContactModal,
  PinContactModal,
  UnpinContactModal,
} from './modals';
import {
  Grid, Pagination,
  ScrollWrapper,
} from '../../ui-kit';
import './style.sass';
import CustomFieldsModal from '../customFields';
import { CUSTOM_FIELDS_MODELS } from '../../constants/customFields';

const ContactsContainer = ({
  contacts,
  onSortBy,
  order,
  sort,
  setSelectedSort,
  pagination,
  contactsCount,
  onPageChange,
  onSortByName,
  onSearch,
  searchName,
  setSearchContactName,
  onSuccessCreateContact,
  onDeleteContact,
  setSelectedContactId,
  selectedContactId,
  setSelectedPinnedContactId,
  selectedPinnedContactId,
  getContacts,
  isOpen,
  setIsOpen,
}) => (
  <ScrollWrapper>
    <div className="contacts">
      <Filters
        setSelectedSort={setSelectedSort}
        onSortBy={onSortBy}
        order={order}
        sort={sort}
        onSearch={onSearch}
        searchName={searchName}
        setSearchContactName={setSearchContactName}
      />
      <Grid.Wrapper
        name="Contacts"
        className="contacts__table"
        pagination={(
          <Pagination
            count={contactsCount}
            component="contacts"
            pagination={pagination}
            onPageChange={onPageChange}
          />
)}
      >
        <Grid.Row className="contacts__table-row contacts__table-header">
          <Grid.Col
            sort={sort}
            order={order}
            onClick={onSortByName}
            filter="name"
            name="Contact"
            className="contacts__table-col"
          />
          <Grid.Col
            name="Related to"
            className="contacts__table-col"
          />
        </Grid.Row>
        {
          contacts.map(contactId => (
            <ContactItem
              id={contactId}
              key={contactId}
              setSelectedContact={setSelectedContactId}
              setSelectedPinnedContactId={setSelectedPinnedContactId}
              selectedPinnedContactId={selectedPinnedContactId}
            />
          ))
        }
      </Grid.Wrapper>
    </div>
    <CreateContactModal onSuccess={onSuccessCreateContact} />
    <EditContactModal selectedContactId={selectedContactId} />
    <DeleteContactModal onDelete={onDeleteContact} selectedContactId={selectedContactId} />
    <PinContactModal selectedContactId={selectedContactId} />
    <UnpinContactModal selectedContactId={selectedContactId} />
    <CustomFieldsModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      customFieldModel={CUSTOM_FIELDS_MODELS.CONTACT}
      customCallback={getContacts}
    />
  </ScrollWrapper>
);

ContactsContainer.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.number).isRequired,
  contactsCount: PropTypes.number.isRequired,
  onSortByName: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  setSelectedSort: PropTypes.func.isRequired,
  onSortBy: PropTypes.func.isRequired,
  onSuccessCreateContact: PropTypes.func.isRequired,
  onDeleteContact: PropTypes.func.isRequired,
  setSearchContactName: PropTypes.func.isRequired,
  setSelectedContactId: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  selectedContactId: PropTypes.number.isRequired,
  searchName: PropTypes.string.isRequired,
  order: PropTypes.instanceOf(Object).isRequired,
  sort: PropTypes.instanceOf(Object).isRequired,
  pagination: PropTypes.instanceOf(Object).isRequired,
  // eslint-disable-next-line react/require-default-props
  selectedPinnedContactId: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  setSelectedPinnedContactId: PropTypes.func,
  getContacts: PropTypes.func.isRequired,
};

export default ContactsContainer;
