import React from 'react';

import './style.sass';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ButtonsCollection, ModalWindow } from '..';

/**
 *
 * @param onClose {function}
 * @param onAction {function | [null]}
 * @param isPending {boolean}
 * @param title {string}
 * @param isOpen {boolean}
 * @param actionTitle {string}
 * @param zIndex {number | string}
 * @param className {string}
 * @param children {element | string | array}
 * @param disabledAction {boolean}
 * @returns {JSX.Element}
 * @constructor
 */

export const ConfirmModal = React.memo(({
  onClose, onAction = null, isPending = false, title, isOpen, actionTitle = 'Yes', className = '', children = 'Are you sure?', zIndex = '99',
  disabledAction = false,
}) => {
  const { t } = useTranslation('common');
  return (
    <ModalWindow
      isOpen={isOpen}
      onClose={onClose}
      className={`confirm-modal ${className}`}
      modalName={title}
      title={title}
      zIndex={zIndex}
    >
      <div className="row  text-align--left">
        <span className="confirm-modal__title title-md">
          {children}
        </span>
      </div>
      <div className="confirm-modal__button-row">
        {
          onAction && (
            <ButtonsCollection.ButtonBrill
              type="button"
              onClick={onAction}
              pending={isPending}
              disabled={disabledAction}
              className={classNames('button--md confirm-modal__button-control button-brill--fill ss', { 'button-brill--grey': disabledAction })}
            >
              {t(actionTitle)}
            </ButtonsCollection.ButtonBrill>
          )
        }
        <button
          type="button"
          className="button--cancel"
          onClick={onClose}
        >
          {t('Cancel')}
        </button>
      </div>
    </ModalWindow>
  );
});
