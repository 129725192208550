import React, { memo } from 'react';

import './style.sass';
import { Link } from 'react-router-dom';


/**
 *
 * @param className {string}
 * @param onClick {function}
 * @param onMouseLeave {function}
 * @param onMouseOver {function}
 * @param title {string}
 * @param asLink {boolean}
 * @param href {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const ButtonPlus = memo(({
  onClick = () => { },
  onMouseLeave = () => { },
  onMouseOver = () => { },
  className = '',
  href = '',
  asLink = false,
  title = '',
}) => (asLink ? (
  <Link
    onMouseLeave={onMouseLeave}
    onMouseOver={onMouseOver}
    title={title}
    to={href}
    onClick={onClick}
    onFocus={() => { }}
    className={`plus-button ${className}`}
  >
    <span className="plus-button__hexagon icon-hexagon-shape" />
    <span className="plus-button__plus icon-plus-icon-2" />
  </Link>
) : (
  <button
    onMouseLeave={onMouseLeave}
    onMouseOver={onMouseOver}
    type="button"
    title={title}
    onClick={onClick}
    onFocus={() => { }}
    className={`plus-button ${className}`}
  >
    <span className="plus-button__hexagon icon-hexagon-shape" />
    <span className="plus-button__plus icon-plus-icon-2" />
  </button>
)
));
