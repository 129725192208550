import React, { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonsCollection } from '..';


/**
 *
 * @param onClickSaveContent {function}
 * @param onClickDiscardButton {function}
 * @param discardButton {boolean}
 * @param sendLabel {string}
 * @param isDisabled {boolean}
 * @param title {string}
 * @returns {JSX.Element}
 * @constructor
 */

const SPECIFIC_SCREEN_SIZE = 768;

export const MessageButtons = memo(({
  onClickSaveContent, discardButton, onClickDiscardButton, sendLabel = 'Send', isDisabled,
  title,
}) => {
  const { t } = useTranslation('common');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
    return (
      window.removeEventListener('resize', () => setWindowWidth(window.innerWidth))
    );
  }, []);

  return (
    <div className="message-textArea__col">
      {
        windowWidth < SPECIFIC_SCREEN_SIZE && !discardButton ? (
          <button
            type="submit"
            disabled={isDisabled}
            title={t(title)}
            onClick={onClickSaveContent}
            onMouseDown={e => e.preventDefault()}
            className="button--xs message-textArea__button-send-short button-brill--fill"
          >
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 535.5 535.5"
              style={{ enableBackground: 'new 0 0 535.5 535.5' }}
              xmlSpace="preserve"
            >
              <g>
                <g id="send">
                  <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75" />
                </g>
              </g>
            </svg>
          </button>
        ) : (
          <ButtonsCollection.ButtonBrill
            type="submit"
            disabled={isDisabled}
            title={t(title)}
            onClick={onClickSaveContent}
            onMouseDown={e => e.preventDefault()}
            className="button--xs message-textArea__button-send button-brill--fill"
          >
            { sendLabel }
          </ButtonsCollection.ButtonBrill>
        )
      }
      { discardButton && (
        <ButtonsCollection.ButtonBrill
          type="button"
          onClick={onClickDiscardButton}
          className="button--xs message-textArea__button-discard button-brill"
        >
          {t('Cancel')}
        </ButtonsCollection.ButtonBrill>
      )}
    </div>
  );
});
