import React from 'react';
import { pathOr } from 'ramda';

import { useTranslation } from 'react-i18next';
import { ToggleButton, FormsCollection } from '../../../../../../../../ui-kit';
import { MODULES } from '../../../../../../../../constants/pemissions';

const getAllPermissionsStatus = ({
  update, create, read, delete: deleteProp,
}) => (update && create && read && deleteProp);

const hiddenReadToggles = [
  MODULES.PROJECTS_DOCUMENTS,
  MODULES.TASKS,
  MODULES.LEAD_DOCUMENTS,
  MODULES.CLIENT_DOCUMENTS,
];


/**
 *
 * @param moduleName{string}
 * @param permissions{Object}
 * @param permissionByGroups{Object}
 * @param onChangePermission{function}
 * @returns {JSX.Element}
 * @constructor
 */

export const PermissionsToggle = ({
  moduleName, item, permissions, onChangePermission,
}) => {
  const { t } = useTranslation('permissions');
  return (
    <div className="modulePermissions__row" key={`${moduleName}${item}`}>
      <div className="modulePermissions__col modulePermissions__col--name">
        {t(item)}
      </div>
      <div className="modulePermissions__col">
        {!hiddenReadToggles.includes(item)
        && (
          <ToggleButton
            onChange={onChangePermission}
            name={`${moduleName}-${item}-read`}
            status={pathOr(0, [item, 'read'], permissions)}
          />
        )}
      </div>
      <div className="modulePermissions__col">
        <ToggleButton
          onChange={onChangePermission}
          name={`${moduleName}-${item}-create`}
          status={pathOr(0, [item, 'create'], permissions)}
        />
      </div>
      <div className="modulePermissions__col">
        <ToggleButton
          onChange={onChangePermission}
          name={`${moduleName}-${item}-update`}
          status={pathOr(0, [item, 'update'], permissions)}
        />
      </div>
      <div className="modulePermissions__col">
        <ToggleButton
          onChange={onChangePermission}
          name={`${moduleName}-${item}-delete`}
          status={pathOr(0, [item, 'delete'], permissions)}
        />
      </div>
      <div className="modulePermissions__col">
        <ToggleButton
          onChange={onChangePermission}
          name={`${moduleName}-${item}-all`}
          status={getAllPermissionsStatus(permissions[item] || {})}
        />
      </div>
      <div className="modulePermissions__col">
        <FormsCollection.Checkbox
          className="checkbox-field--fill"
          id={`${moduleName}-${item}-owner`}
          name={`${moduleName}-${item}-owner`}
          disabled
          status={pathOr(0, [item, 'owner'], permissions)}
        />
      </div>
    </div>
  );
};
