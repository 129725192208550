const OFFSET_LEFT = 'data-offset-left';
const OFFSET_RIGHT = 'data-offset-right';
const DELIMITER_LEFT = 'delimiterLeft';
const DELIMITER_RIGHT = 'delimiterRight';
const BRILL_NAME = 'coWorker';

export {
  OFFSET_LEFT,
  OFFSET_RIGHT,
  DELIMITER_LEFT,
  DELIMITER_RIGHT,
  BRILL_NAME,
};
