import { compose, withHandlers, getContext } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import HeaderDropDown from './headerDropDown';
import { uiActions } from '../../../../../../state/ui';

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
};

const onDeleteChannelHandler = ({
  setOpenModal,
  setSelectedChannelId, match: { params: { id } },
}) => () => {
  setOpenModal('deleteChannelModal');
  setSelectedChannelId(id);
};

const onEditChannelHandler = ({
  setOpenModal,
  setSelectedChannelId, match: { params: { id } },
}) => () => {
  setOpenModal('editChannelModal');
  setSelectedChannelId(id);
};

const onLeaveChannelHandler = ({
  setOpenModal,
  setSelectedChannelId, match: { params: { id } },
}) => () => {
  setOpenModal('leaveChannelModal');
  setSelectedChannelId(id);
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  withRouter,
  getContext({
    setSelectedChannelId: PropTypes.func,
  }),
  withHandlers({
    onDeleteChannel: onDeleteChannelHandler,
    onEditChannel: onEditChannelHandler,
    onLeaveChannel: onLeaveChannelHandler,
  }),
);
export default enhance(HeaderDropDown);
