import {
  branch,
  compose, renderNothing, withHandlers,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isNil } from 'ramda';

import PinClientModal from './pinClientModal';
import { getModal } from '../../../../state/ui/selectors';
import { uiActions } from '../../../../state/ui';
import { clientsSelectors, clientsActions } from '../../../../state/clients';

const mapDispatchToProps = {
  onCloseModal: uiActions.closeModal,
  pinClient: clientsActions.pinClientRequest,
  getPinnedClients: clientsActions.getPinnedClientsRequest,
};

const mapStateToProps = (state, { selectedClientId }) => ({
  isOpen: getModal(state)('pinClientModal'),
  client: clientsSelectors.getClientById(state)(selectedClientId),
});

const onCloseHandler = ({ onCloseModal }) => () => onCloseModal('pinClientModal');

const onPinHandler = ({
  pinClient, onClose, client, getPinnedClients,
}) => () => pinClient({
  id: client.id,
}, {
  callbacks: {
    success: () => {
      onClose();
      getPinnedClients();
    },
  },
});


const enhance = compose(
  branch(
    ({ selectedClientId }) => isNil(selectedClientId),
    renderNothing,
  ),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withHandlers({
    onClose: onCloseHandler,
  }),
  withHandlers({
    onPin: onPinHandler,
  }),
);


export default enhance(PinClientModal);
