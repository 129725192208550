export const PEOPLE_API = {
  MAIN: '/people/people/',
  AVATAR: '/people/avatar/',
  RESEND_INVITE: '/resend-invite',
  USERS_MAIN: '/users/',
};

export const PEOPLE_PAGES = {
  PERSON: {
    BASE: '/people/chosen/',
    MAIN: '/people/chosen/:userId',
    EDIT: '/people/chosen/:userId',
    INVITE: '/people/chosen/invite-user',
  },
  PEOPLE: {
    MAIN: '/people/',
  },
};
