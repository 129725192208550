import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { leadsSelectors } from '../../../../../../state/leads';
import LeadSummary from './leadsSummary';

const mapStateToProps = state => ({
  leadsCount: leadsSelectors.getLeadsSummaryCount(state),
});

const enhance = compose(
  connect(mapStateToProps, null),
  withTranslation(['tasks']),
);
export default enhance(LeadSummary);
