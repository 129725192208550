import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import DeleteInvoiceModal from './deleteInvoiceModal';
import { uiSelectors, uiActions } from '../../../../state/ui';
import { invoicesSelectors } from '../../../../state/invoices';

const mapStateToProps = (state, { selectedInvoiceId }) => ({
  isOpen: uiSelectors.getModal(state)('deleteInvoiceModal'),
  invoice: invoicesSelectors.getInvoiceById(state)(selectedInvoiceId),
});

const mapDispatchToProps = {
  closeModal: () => uiActions.closeModal('deleteInvoiceModal'),
};

const onCloseHandler = ({
  closeModal,
}) => () => {
  closeModal();
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onClose: onCloseHandler,
  }),
);
export default enhance(DeleteInvoiceModal);
