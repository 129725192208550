import {
  compose,
  withProps,
} from 'recompose';

import Activity from './Activity';
import { VIEWS } from '../../../constants/ui';

const enhancer = compose(
  withProps(({ view }) => ({ view: VIEWS[view] })),
);
export default enhancer(Activity);


// import {
//   compose,
//   lifecycle,
//   withContext,
//   withHandlers,
//   withState,
//   withProps,
// } from 'recompose';
// import { connect } from 'react-redux';
// import {
//   propOr, equals, pathOr, prop,
// } from 'ramda';
// import PropTypes from 'prop-types';
// import moment from 'moment';
//
// import Activity from './Activity';
// import withWindowWidth from '../../../utils/enchancers/withWindowWidth';
// import { statisticsActions } from '../../../state/statistics';
// import * as userSelectors from '../../../state/user/selectors';
// import { VIEWS } from '../../../constants/ui';
// import { withFilters, withUrlParams } from '../../../utils/enchancers';
// import { newTaskSelectors } from '../../../state/newTask';
// import { usersSelectors } from '../../../state/users';
// import { renameKeysTitleIntoValue } from '../../../utils/helpers/uiHelpers';
// import { VIEW_TYPE } from '../../../constants/activity';
//
// const dateFormat = 'YYYY-MM-DD';
// const formatDate = date => moment(date).format(dateFormat);
//
// const DEFAULT_USER_VALUE = { value: null, label: 'All users' };
//
// const mapStateToProps = state => ({
//   user: userSelectors.getUserData(state),
//   projects: newTaskSelectors.getProjectsEntities(state),
//   users: usersSelectors.getUsersForSelect(state),
// });
//
// const mapDispatchToProps = ({
//   getStatistics: statisticsActions.getStatisticsRequest,
//   getStatisticsByTasks: statisticsActions.getStatisticsByTasksRequest,
//   getMyStatistics: statisticsActions.getMyStatisticsRequest,
//   exportDataRequest: statisticsActions.exportDataRequest,
// });
//
// const downloadBlob = (blob, name) => {
//   const blobUrl = URL.createObjectURL(blob);
//
//   const link = document.createElement('a');
//
//   link.href = blobUrl;
//   link.download = name;
//
//   document.body.appendChild(link);
//
//   link.dispatchEvent(
//     new MouseEvent('click', {
//       bubbles: true,
//       cancelable: true,
//       view: window,
//     }),
//   );
//
//   document.body.removeChild(link);
// };
//
// const onLoadStatisticsHandler = ({
//   getStatistics, selectedStartDate, selectedEndDate, selectedProjectLabel, selectedUserId, user,
// }) => (queryParams) => {
//   getStatistics({
//     startDate: formatDate(queryParams.selectedStartDate || selectedStartDate),
//     endDate: formatDate(queryParams.selectedEndDate || selectedEndDate),
//     user_id: propOr(null, 'value', queryParams.selectedUserId || selectedUserId),
//     project_id: propOr(null, 'value', queryParams.selectedProjectLabel || selectedProjectLabel),
//   }, { userId: user.id });
// };
//
// const onLoadMyStatisticsHandler = ({
//   getMyStatistics, selectedStartDate, selectedEndDate, selectedProjectLabel,
// }) => (queryParams) => {
//   getMyStatistics({
//     startDate: formatDate(queryParams.selectedStartDate || selectedStartDate),
//     endDate: formatDate(queryParams.selectedEndDate || selectedEndDate),
//     project_id: propOr(null, 'value', queryParams.selectedProjectLabel || selectedProjectLabel),
//   });
// };
//
// const onExportButtonClickHandler = ({
//   exportDataParams,
//   exportDataRequest,
// }) => (isCurrentUser, exportKeys = []) => () => exportDataRequest(
//   {
//     ...exportDataParams,
//     export_keys: exportKeys.join(','),
//     user_id: isCurrentUser ? null : exportDataParams.user_id,
//   }, {
//     isCurrentUser,
//     callbacks: {
//       success: (action) => {
//         const { zip: { data }, filename } = pathOr(null, ['data', 'result'], action);
//         const blob = new Blob([new Uint8Array(data)], { type: 'application/zip' });
//         downloadBlob(blob, filename);
//       },
//     },
//   },
// );
//
// const whereAssignee = userId => (userId ? ({
//   'where[0][field]': 'assignee',
//   'where[0][value]': userId,
// }) : ({}));
//
// const onLoadStatisticsPerTasksHandler = ({
//   getStatisticsByTasks, selectedStartDate,
//   selectedEndDate, selectedProjectLabel, selectedUserId, user,
// }) => (queryParams) => {
//   if (prop('value', queryParams.selectedProjectLabel || selectedProjectLabel)) {
//     getStatisticsByTasks({
//       entry_time_from: formatDate(queryParams.selectedStartDate || selectedStartDate),
//       entry_time_to: formatDate(queryParams.selectedEndDate || selectedEndDate),
//       project_id: propOr(null, 'value', queryParams.selectedProjectLabel || selectedProjectLabel),
//       ...whereAssignee(propOr(null, 'value', queryParams.selectedUserId || selectedUserId)),
//     }, { userId: user.id });
//   }
// };
//
// const enhancer = compose(
//   connect(mapStateToProps, mapDispatchToProps),
//   withWindowWidth(),
//   withState('selectedUserId', 'setUserId', DEFAULT_USER_VALUE),
//   withState('selectedUsers', 'setUsers', [DEFAULT_USER_VALUE]),
//   withState('selectedProjectLabel', 'setProjectLabel', {}),
//   withState('selectedStartDate', 'setStartDate', moment().startOf('month').toDate()),
//   withState('selectedEndDate', 'setEndDate', moment().endOf('month').toDate()),
//   withProps(({ view }) => ({ view: VIEWS[view] })),
//   withHandlers({
//     onLoadStatistics: onLoadStatisticsHandler,
//     onLoadMyStatistics: onLoadMyStatisticsHandler,
//     onLoadStatisticsPerTasks: onLoadStatisticsPerTasksHandler,
//   }),
//   withUrlParams({}),
//   withFilters({
//     initial: ({ getUrlParam }) => ({
//       user_id: getUrlParam(['user_id']),
//       project_id: getUrlParam(['project_id']),
//       startDate: getUrlParam(['startDate']),
//       endDate: getUrlParam(['endDate']),
//     }),
//   }),
//   withProps(({ getFilter }) => ({
//     userId: getFilter(null, 'user_id'),
//     projectId: getFilter(null, 'project_id'),
//     startDate: getFilter(null, 'startDate'),
//     endDate: getFilter(null, 'endDate'),
//   })),
//   withContext({
//     setUserId: PropTypes.func,
//     setProjectLabel: PropTypes.func,
//     setStartDate: PropTypes.func,
//     setEndDate: PropTypes.func,
//     selectedProjectLabel: PropTypes.instanceOf(Object),
//     selectedUserId: PropTypes.instanceOf(Object),
//     selectedStartDate: PropTypes.instanceOf(Date),
//     selectedEndDate: PropTypes.instanceOf(Date),
//     setFilters: PropTypes.func,
//     onSetUrlParam: PropTypes.func,
//     mergeFilters: PropTypes.func,
//     onResetUrlParam: PropTypes.func,
//     setUsersId: PropTypes.func,
//     selectedUsers: PropTypes.array,
//     setUsers: PropTypes.func,
//     queryParams: PropTypes.object,
//     onLoadStatistics: PropTypes.func,
//     getUrlParam: PropTypes.func,
//   }, props => ({
//     onLoadStatistics: props.onLoadStatistics,
//     setUsers: props.setUsers,
//     getUrlParam: props.getUrlParam,
//     queryParams: props.queryParams,
//     selectedUsers: props.selectedUsers,
//     setUserId: props.setUserId,
//     setUsersId: props.setUsersId,
//     setProjectLabel: props.setProjectLabel,
//     setStartDate: props.setStartDate,
//     setEndDate: props.setEndDate,
//     selectedProjectLabel: props.projectId
//       ? renameKeysTitleIntoValue(props.projects[props.projectId])
//       : props.selectedProjectLabel,
//     selectedUserId: Object.values(props.users).find(u => u.value === parseInt(props.userId, 10))
//       || props.selectedUserId,
//     selectedStartDate: props.startDate
//       ? new Date(decodeURIComponent(props.startDate))
//       : props.selectedStartDate,
//     selectedEndDate: props.endDate
//       ? new Date(decodeURIComponent(props.endDate))
//       : props.selectedEndDate,
//     setFilters: props.setFilters,
//     onSetUrlParam: props.onSetUrlParam,
//     mergeFilters: props.mergeFilters,
//     onResetUrlParam: props.onResetUrlParam,
//   })),
//   withProps(props => ({
//     queryParams: {
//       selectedProjectLabel: props.projectId
//         ? renameKeysTitleIntoValue(props.projects[props.projectId])
//         : null,
//       selectedUserId: Object.values(props.users).find(u => u.value === parseInt(props.userId, 10))
//         || null,
//       selectedStartDate: props.startDate
//         ? new Date(props.startDate)
//         : null,
//       selectedEndDate: props.endDate
//         ? new Date(decodeURIComponent(props.endDate))
//         : null,
//     },
//     userIsSelected: props.selectedUsers.some(({ value }) => value !== null),
//   })),
//   withProps(({
//     queryParams, selectedProjectLabel, selectedUserId, selectedStartDate, selectedEndDate,
//   }) => ({
//     exportDataParams: {
//       project_id: propOr(null, 'value', queryParams.selectedProjectLabel || selectedProjectLabel),
//       user_id: propOr(null, 'value', queryParams.selectedUserId || selectedUserId),
//       startDate: formatDate(queryParams.selectedStartDate || selectedStartDate),
//       endDate: formatDate(queryParams.selectedEndDate || selectedEndDate),
//       entry_time_from: formatDate(queryParams.selectedStartDate || selectedStartDate),
//       entry_time_to: formatDate(queryParams.selectedEndDate || selectedEndDate),
//       ...whereAssignee(propOr(null, 'value', queryParams.selectedUserId || selectedUserId)),
//     },
//   })),
//   withHandlers({
//     onExportButtonClick: onExportButtonClickHandler,
//   }),
//   withContext({
//     onExportButtonClick: PropTypes.func,
//   }, ({ onExportButtonClick }) => ({
//     onExportButtonClick,
//   })),
//   lifecycle({
//     componentDidMount() {
//       if (this.props.view === VIEW_TYPE.MY_STATISTICS) {
//         this.props.setProjectLabel({ value: null, label: 'All projects' });
//       }
//     },
//     componentDidUpdate({
//       selectedEndDate, selectedStartDate, selectedProjectLabel, selectedUserId, view,
//       queryParams, selectedUsers,
//     }) {
//       if (this.props.view !== view) {
//         const urlData = {
//           startDate: moment(this.props.selectedStartDate).format('YYYY-MM-DD'),
//           endDate: moment(this.props.selectedEndDate).format('YYYY-MM-DD'),
//           project_id: this.props.view === VIEW_TYPE.USERS ? '' : this.props.selectedProjectLabel.value,
//           user_id: this.props.view === VIEW_TYPE.MY_STATISTICS ? '' : this.props.selectedUserId.value,
//         };
//         this.props.onSetUrlParam(urlData);
//       }
//       if (!equals(queryParams, this.props.queryParams)) {
//         this.props.onLoadMyStatistics(this.props.queryParams);
//         this.props.onLoadStatisticsPerTasks(this.props.queryParams);
//         this.props.onLoadStatistics(this.props.queryParams);
//       }
//       if (((selectedStartDate !== this.props.selectedStartDate
//           || selectedEndDate !== this.props.selectedEndDate
//       ) && this.props.selectedEndDate && this.props.selectedStartDate)
//         || (selectedProjectLabel !== this.props.selectedProjectLabel)
//         || (selectedUserId !== this.props.selectedUserId)
//       ) {
//         if (this.props.view === VIEW_TYPE.PROJECT) {
//           if (this.props.userIsSelected) {
//             this.props.onSetUrlParam({ user_id: this.props.selectedUserId.value });
//           }
//           if (equals(selectedUsers, this.props.selectedUsers)
//             && !equals(queryParams, this.props.queryParams)) {
//             for (const user of this.props.selectedUsers) {
//               this.props.onLoadStatistics({ ...this.props.queryParams, selectedUserId: user });
//             }
//           }
//           if (!equals(selectedProjectLabel, this.props.selectedProjectLabel)) {
//             this.props.setUsers([DEFAULT_USER_VALUE]);
//             this.props.onSetUrlParam({ user_id: null });
//             this.props.setUserId(DEFAULT_USER_VALUE);
//           }
//           if (selectedProjectLabel.value) {
//             this.props.onLoadStatisticsPerTasks(this.props.queryParams);
//           }
//         }
//         if (this.props.view === VIEW_TYPE.USERS) {
//           this.props.onLoadStatistics(this.props.queryParams);
//         }
//         if (this.props.view === VIEW_TYPE.MY_STATISTICS) {
//           this.props.onLoadMyStatistics(this.props.queryParams);
//         }
//       }
//     },
//   }),
// );
// export default enhancer(Activity);
