import { createActions } from 'redux-actions';
import {
  identity, path, pathOr, mergeLeft, compose,
} from 'ramda';

import types from './types';
import { pipelinesListSchema, pipelinesStatusesListSchema } from './schema';
import { setErrorPage } from '../ui/actions';

import { getNormalizeErrorObject } from '../../utils/helpers/requestHelpers';

const errorPageDataWhenGetPipelines = compose(mergeLeft({ href: '/' }),
  getNormalizeErrorObject);

export const {
  getPipelinesRequest,
  getPipelinesAutocompleteRequest,
  getPipelineStatusesAutocompleteRequest,
  addPipelineRequest,
  deletePipelineRequest,
  editPipelineRequest,
  setPipeline,
  setPipelines,
  setPipelinesAutocomplete,
  setPipelineStatusesAutocomplete,
  deletePipelines,
  getPipelineStatusesRequest,
  setPipelineStatuses,
} = createActions(
  {
    [types.GET_PIPELINES_REQUEST]: [
      identity,
      ({ ...params }, meta) => ({
        async: true,
        route: '/pipelines',
        selectorName: 'getPipelinesRequest',
        method: 'GET',
        params,
        schema: {
          rules: pipelinesListSchema,
          pathData: ['data'],
        },
        actions: {
          success: setPipelines,
          error: compose(setErrorPage, errorPageDataWhenGetPipelines),
        },
        ...meta,
      }),
    ],
    [types.GET_PIPELINES_AUTOCOMPLETE_REQUEST]: [
      identity,
      ({ ...params }, meta) => ({
        async: true,
        route: '/pipelines/autocomplete',
        selectorName: 'getPipelinesAutocomplete',
        method: 'GET',
        params,
        actions: {
          success: action => setPipelinesAutocomplete({ ...action, meta }),
        },
        ...meta,
      }),
    ],
    [types.GET_PIPELINE_STATUSES_AUTOCOMPLETE_REQUEST]: [
      identity,
      ({ ...params }, meta) => ({
        async: true,
        route: '/pipeline-statuses/autocomplete',
        selectorName: 'getPipelineStatusesAutocomplete',
        method: 'GET',
        params,
        actions: {
          success: action => setPipelineStatusesAutocomplete({ ...action, meta }),
        },
        ...meta,
      }),
    ],
    [types.ADD_PIPELINE_REQUEST]: [
      identity,
      ({ ...params }, meta) => ({
        async: true,
        route: '/pipelines',
        selectorName: 'addPipelineRequest',
        method: 'POST',
        params,
        callbacks: {
          success: path(['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.EDIT_PIPELINE_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/pipelines/${id}`,
        selectorName: 'editPipelineRequest',
        method: 'PUT',
        params,
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.DELETE_PIPELINE_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/pipelines/${id}`,
        selectorName: 'deletePipelineRequest',
        method: 'DELETE',
        params,
        callbacks: {
          success: path(['callbacks', 'success'], meta),
        },
        actions: {
          success: () => getPipelinesRequest(meta),
        },
        ...meta,
      }),
    ],
    [types.GET_PIPELINE_STATUSES_REQUEST]: [
      identity,
      ({ pipelineId }, meta) => ({
        async: true,
        route: `/pipeline-statuses/?pipeline_id=${pipelineId}`,
        selectorName: 'getPipelineStatusesRequest',
        method: 'GET',
        schema: {
          rules: pipelinesStatusesListSchema,
          pathData: ['data'],
        },
        actions: {
          success: setPipelineStatuses,
        },
        ...meta,
      }),
    ],
  },
  types.SET_PIPELINES,
  types.SET_PIPELINES_AUTOCOMPLETE,
  types.SET_PIPELINE_STATUSES_AUTOCOMPLETE,
  types.SET_PIPELINE,
  types.DELETE_PIPELINE,
  types.SET_PIPELINE_STATUSES,
);
