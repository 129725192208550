import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateTaskRequest } from '../../../../state/tasks/actions';
import { taskSelectors } from '../../../../state/task';
import { changeTaskValues } from '../../../../state/task/actions';
import { callNotification } from '../../../../utils/helpers/notifies';
import { renderDescriptionWithLinks } from '../../../../utils/helpers/stringHelpers/common';
import { EDIT_TASK_MODE } from './constants';


const useTaskAbout = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const task = useSelector(taskSelectors.getActiveTask);
  const [activeEditMode, setActiveEditMode] = useState(null);
  const [title, setTitle] = useState(task.title);
  const [description, setDescription] = useState(task.description);

  const activeValue = useMemo(() => (
    {
      [EDIT_TASK_MODE.TITLE]: title,
      [EDIT_TASK_MODE.DESCRIPTION]: description,
    }[activeEditMode]
  ), [title, description]);

  const changeActiveEditorStatus = useCallback((value = null) => () => {
    setActiveEditMode(value);
  }, []);

  const onTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const onDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const saveEditedValues = useCallback(() => {
    const newTask = {
      ...task,
      [activeEditMode]: activeValue,
    };
    dispatch(updateTaskRequest(newTask));
    dispatch(changeTaskValues(newTask));
    callNotification.success(t(`Task ${activeEditMode} was successfully updated!`));
    changeActiveEditorStatus()();
  }, [task, activeValue, activeEditMode, changeActiveEditorStatus]);

  const descriptionWithLinks = useMemo(() => renderDescriptionWithLinks(description), [description]);

  return {
    activeEditMode,
    task,
    title,
    description,
    descriptionWithLinks,
    saveEditedValues,
    onTitleChange,
    onDescriptionChange,
    changeActiveEditorStatus,
  };
};

export default useTaskAbout;
