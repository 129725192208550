/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { PermissionElement } from '../../../../ui-kit';
import TasksGrid from '../tasksGrid';
import './style.sass';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';

const Backlog = ({
  tasks, onSortBy, sort, order, gridName, t,
}) => (
  <div className="table-tasks__sprint table-tasks__sprint--backlog">
    <div className="table-tasks__about-sprint">
      <h2 className="table-tasks__name-sprint">{t('Backlog')}</h2>
      <PermissionElement module={MODULES.PROJECTS} action={ACTIONS.UPDATE} />
    </div>
    <TasksGrid
      sort={sort}
      order={order}
      isSprint={false}
      onSortBy={onSortBy}
      name="backlog-grid"
      id={gridName}
      items={tasks}
    />
  </div>
);

Backlog.propTypes = {
  openModal: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.number),
};

Backlog.defaultProps = {
  tasks: [],
};

export default Backlog;
