import { useState, useEffect } from 'react';
import { useUrlParams } from '.';
import { calculateOffset } from '../helpers/uiComponentHelpers/pagination';

const DEFAULT_OFFSET = 0;
const DEFAULT_PAGE = 1;
const PAGE = 'page';

export const usePagination = (limit) => {
  const [offset, setOffset] = useState(DEFAULT_OFFSET);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const { getUrlParam } = useUrlParams({});

  useEffect(() => {
    const newOffset = calculateOffset(getUrlParam(PAGE, page), limit);
    if (newOffset >= 0 && newOffset !== offset) setOffset(Number(newOffset));
  }, [getUrlParam, limit, page]);

  useEffect(() => {
    const newPage = getUrlParam(PAGE, page);
    if (newPage && newPage > 1 && newPage !== page) setPage(Number(newPage));
  }, [getUrlParam, limit, page]);

  return {
    limit,
    offset,
    page,
  };
};
