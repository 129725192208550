import {
  compose,
  getContext,
  lifecycle,
  withContext,
  withHandlers,
  withProps,
  withState,
} from 'recompose';
import {
  curry,
  assoc,
  anyPass,
  unapply,
  map,
  equals,
  mergeAll,
  isEmpty,
  prop, find,
  identity, all,
  propEq,
} from 'ramda';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { notEqual } from 'ramda-extension';
import LeadsContainer from './leadsContainer';
import { leadsActions, leadsSelectors } from '../../state/leads';
import { userSelectors } from '../../state/user';
import { pipelinesActions, pipelinesSelectors } from '../../state/pipelines';
import { LEADS_VIEW, PIPELINE_TYPES, START_PAGE_NUMBER } from '../../constants/crm';
import { uiActions } from '../../state/ui';
import { setFilterForSingleParam } from '../../utils/helpers/uiComponentHelpers/filters';
import {
  withFilters,
  withPagination, withRefs,
  withSorting,
  withUrlParams,
} from '../../utils/enchancers';
import { calculateOffset } from '../../utils/helpers/uiComponentHelpers/pagination';
import { getOppositeValue } from '../../utils/helpers/commonHelpers';

const PERMISSION_ALLOWED = 1;

const mapStateToProps = state => ({
  leads: leadsSelectors.getLeadsIDsSelector(state),
  leadsView: leadsSelectors.getLeadsViewSelector(state),
  checkedPipeline: leadsSelectors.getCheckedPipelineSelector(state),
  pipelines: pipelinesSelectors.getPipelinesSelector(state),
  leadsCount: leadsSelectors.getLeadsCount(state),
  leadsOffset: leadsSelectors.getLeadsOffset(state),
  myPermissions: userSelectors.getMyPermissions(state),
});

const mapDispatchToProps = ({
  setLeadsView: leadsActions.setLeadsView,
  setCheckedPipeline: leadsActions.setCheckedPipeline,
  getLeadsRequest: leadsActions.getLeadsRequest,
  getLeadTasksRequest: leadsActions.getLeadTasksRequest,
  getPinnedLeadsRequest: leadsActions.getPinnedLeadsRequest,
  getPipelinesRequest: pipelinesActions.getPipelinesRequest,
  deleteLead: leadsActions.deleteLeadRequest,
  closeModal: uiActions.closeModal,
  deleteLeadNoteSuccess: leadsActions.deleteLeadNoteSuccess,
});

const LIMIT = 10;

const defaultSorting = {
  order: 'desc',
  sortBy: 'created_at',
};


const makeSortingParams = curry((params, sort, order) => {
  const assocIfTruthy = curry((property, value, obj) => (
    value ? assoc(property, value, obj) : obj));
  return compose(
    assocIfTruthy('order', order),
    assocIfTruthy('sort', sort),
  )(params);
});

const getSortingParamsForRequest = (sort, order) => {
  const isSortEqualTo = compose(
    anyPass,
    unapply(map(equals(sort))),
  );
  return isSortEqualTo('created_at', 'name') ? makeSortingParams({}, sort, order) : defaultSorting;
};

const getLeads = ({
  getLeadsRequest, sort, order, pagination, filters,
}, meta = {}) => compose(
  getLeadsRequest,
  unapply(mergeAll),
)(getSortingParamsForRequest(sort, order), pagination, filters, meta);

const onPageChangeHandler = ({
  getLeadsRequest, pagination, sort, order, filters,
}) => meta => getLeads({
  getLeadsRequest, pagination, sort, order, filters,
}, meta);

const setSearchLeadNameHandler = ({ setFilters, mergeFilters }) => ({ target: { value } }) => {
  setFilters(mergeFilters({
    name: value,
  }));
};

const onChangeFilterStatusHandler = ({
  setFilters,
  mergeFilters,
  setIsNeedRefresh,
  onSetUrlParam,
}) => ({ val: { value } }) => {
  setFilters(mergeFilters({ status: value }));
  onSetUrlParam({ status: value, page: 1 });
  return setIsNeedRefresh(true);
};

const refreshPageHandler = ({ setIsNeedRefresh }) => () => setIsNeedRefresh(true);

const onSearchHandler = ({
  onSetUrlParam, onResetUrlParam, searchName, mergeFilters, setFilters, setIsNeedRefresh,
}) => () => {
  const setSearchNameFilterAndUrl = setFilterForSingleParam(mergeFilters, setFilters);
  if (isEmpty(searchName)) {
    setSearchNameFilterAndUrl(onResetUrlParam, 'name', null);
  } else {
    setSearchNameFilterAndUrl(onSetUrlParam, 'name', searchName);
  }
  return setIsNeedRefresh(true);
};

const onSuccessCreateLeadHandler = ({
  setIsNeedRefresh,
}) => () => setIsNeedRefresh(true);

const onDeleteLeadHandler = ({
  deleteLead,
  getPinnedLeadsRequest,
  selectedLeadId,
  closeModal,
  setSelectedLeadId,
  sort, order, pagination, filters,
}) => () => {
  deleteLead({ id: selectedLeadId }, {
    ...getSortingParamsForRequest(sort, order),
    ...pagination,
    ...filters,
  });
  setSelectedLeadId(null);
  closeModal('deleteLeadModal');
  getPinnedLeadsRequest();
};

const getActiveTaskSelector = ({ selectedLeadTask }) => state => (
  leadsSelectors.getLeadTaskSelector(state)(selectedLeadTask)
);

const onSuccessEditFullTaskHandler = ({ selectedLeadId: id, getLeadTasksRequest }) => () => {
  getLeadTasksRequest({ id });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('currentPipeline', 'setCurrentPipeline', null),
  withState('isNeedRefresh', 'setIsNeedRefresh', false),
  withState('selectedSort', 'setSelectedSort', {}),
  withState('selectedLeadTask', 'setSelectedLeadTask', null),
  withUrlParams({}),
  getContext({
    setSelectedLeadId: PropTypes.func.isRequired,
    selectedLeadId: PropTypes.any,
    setSelectedPinnedLeadId: PropTypes.func.isRequired,
    selectedPinnedLeadId: PropTypes.any,
  }),
  withFilters({
    initial: ({ getUrlParam }) => ({
      name: getUrlParam(['name']),
      status: getUrlParam(['status']) || 1,
    }),
  }),
  withProps(({ getFilter }) => ({
    searchName: getFilter('', 'name'),
    status: getFilter('1', 'status'),
  })),
  withPagination({
    limit: () => LIMIT,
    offset: ({ getUrlParam }) => calculateOffset(getUrlParam(['page'], 1), LIMIT),
    page: ({ getUrlParam }) => getUrlParam(['page'], 1),
  }),
  withSorting({
    selector: props => props.getUrlParams({}),
    values: ({
      order: ({ getUrlParam }) => getUrlParam(['order'], 'desc'),
      sort: ({ getUrlParam }) => getUrlParam(['sort'], 'created_at'),
    }),
    onSortBy: ({ order, onSetUrlParam, setIsNeedRefresh }) => (dataSorting) => {
      const sort = prop('sort', dataSorting);
      const newOrder = notEqual(sort, 'created_at') ? getOppositeValue('asc', 'desc', order) : prop('order', dataSorting);
      onSetUrlParam({ sort, order: newOrder });
      setIsNeedRefresh(true);
    },
  }),
  withRefs(),
  withHandlers({
    onPageChange: onPageChangeHandler,
    setSearchLeadName: setSearchLeadNameHandler,
    onSearch: onSearchHandler,
    onSuccessCreateLead: onSuccessCreateLeadHandler,
    onDeleteLead: onDeleteLeadHandler,
    getActiveTaskSelector,
    onSuccessEditFullTask: onSuccessEditFullTaskHandler,
    onChangeFilterStatus: onChangeFilterStatusHandler,
    refreshPage: refreshPageHandler,
  }),
  withProps(({ getRef }) => ({
    scrollWrapperRef: getRef('scroll-wrapper'),
  })),
  withContext({
    setIsNeedRefresh: PropTypes.func.isRequired,
    setSelectedLeadTask: PropTypes.func.isRequired,
    onSetActiveLeadRef: PropTypes.func,
    setLeadsView: PropTypes.func.isRequired,
    selectedLeadTask: PropTypes.any,
    scrollWrapperRef: PropTypes.any,
  }, ({
    setIsNeedRefresh, setSelectedLeadTask, selectedLeadTask, scrollWrapperRef,
    setLeadsView,
  }) => ({
    setIsNeedRefresh,
    setSelectedLeadTask,
    selectedLeadTask,
    scrollWrapperRef,
    setLeadsView,
  })),
  lifecycle({
    componentDidMount() {
      getLeads(this.props);
      this.props.getPinnedLeadsRequest();
      const { getPipelinesRequest, myPermissions: { pipelines: { read } } } = this.props;
      if (read === PERMISSION_ALLOWED) getPipelinesRequest();
    },
    componentDidUpdate(prevProps) {
      const { selectedPinnedLeadId, leads, setSelectedLeadId } = this.props;
      if (selectedPinnedLeadId && !leads.includes(selectedPinnedLeadId)) {
        getLeads(this.props, { selectedPinnedLeadId });
        setSelectedLeadId(selectedPinnedLeadId);
      }
      const {
        isNeedRefresh: prevIsNeedRefresh, filters: prevFilters,
        pipelines: prevPipelines, leadsView: prevLeadsView,
        leadsOffset: prevLeadsOffset,
      } = prevProps;
      const {
        isNeedRefresh, setIsNeedRefresh, filters, pipelines,
        leadsView, getLeadsRequest, setCheckedPipeline, checkedPipeline,
        leadsOffset,
      } = this.props;

      if (leadsOffset !== prevLeadsOffset) {
        this.props.onSetUrlParam({ page: START_PAGE_NUMBER + leadsOffset / LIMIT });
      }

      const leadRequestParamsForPipeView = {
        offset: 0,
        limit: 100,
        status: PIPELINE_TYPES.ALL,
        name: filters.name,
      };

      // Refresh the data if filters was changed
      const isRefresh = (prevIsNeedRefresh !== isNeedRefresh) && isNeedRefresh;
      const isFiltersChanged = all(identity, Object.keys(prevFilters).map(filter => (
        (prevFilters[filter] !== filters[filter]))));
      if (isRefresh || (isEmpty(prevFilters) && isFiltersChanged)) {
        setIsNeedRefresh(false);
        if (leadsView === LEADS_VIEW.PIPE) {
          getLeadsRequest(leadRequestParamsForPipeView);
        } else {
          getLeads(this.props);
        }
      }

      // Check last checked pipeline
      // If there is no available pipeline then set the first pipeline from list
      if (pipelines.length && !prevPipelines.length) {
        const isExistsLastCheckedPipeline = checkedPipeline && find(propEq('id', checkedPipeline), pipelines);
        if (!isExistsLastCheckedPipeline || !checkedPipeline) {
          setCheckedPipeline(pipelines[0].id);
        }
      }

      // Refresh the data if leads view was changed
      if (leadsView !== prevLeadsView && prevLeadsView) {
        if (leadsView === LEADS_VIEW.PIPE) {
          getLeadsRequest(leadRequestParamsForPipeView);
        }
        if (leadsView === LEADS_VIEW.LIST) {
          getLeads(this.props);
        }
      }
    },
  }),
);

export default enhance(LeadsContainer);
