import {
  compose, lifecycle, withContext, withHandlers, withProps, withState,
} from 'recompose';
import { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { equals, propOr, pathOr } from 'ramda';
import { withRouter } from 'react-router';

import Project from './project';
import { projectActions, projectSelectors } from '../../state/project';
import { clientsActions } from '../../state/clients';
import { projectsActions } from '../../state/projects';
import { closeModal, openModal, setAppTitle } from '../../state/ui/actions';
import { withWindowWidth } from '../../utils/enchancers';
import PROJECT_TABS from '../../constants/tabNavigation';
import { getIsTaskHasSprint } from '../../utils/helpers/taskHelpers/crudHelper';
import { TASKS_WITHOUT_PROJECT } from '../../constants/tasks';

const mapStateToProps = (state, ownProps) => ({
  project: projectSelectors.default(state)(ownProps.match.params.id),
});

const mapDispatchToProps = ({
  getProject: projectActions.getProjectRequest,
  setCloseModal: closeModal,
  setOpenModal: openModal,
  getSprintsRequest: projectActions.getSprintsRequest,
  clearProjectData: projectActions.clearProjectData,
  setAppTitle,
  getProjectsListRequest: projectsActions.getProjectsListRequest,
  getClientsRequest: clientsActions.getClientsRequest,
  getPinnedTasks: projectActions.getPinnedTasksRequest,
  agendaTasks: projectActions.getAgendaTasksRequest,
  getCategoriesTask: projectActions.getCategoriesTaskRequest,
  getProjectStatuses: projectActions.getStatusesOfTasksRequest,
  getUsersWithTasks: projectActions.getUsersWithTasksRequest,
});


const onDeleteTaskModalHandler = ({
  setOpenModal,
  setSelectedSort, setSelectedTask, setIsRedirectToProject, setSelectedMeta,
}) => (id, sort, meta) => {
  setSelectedTask(({
    id,
    isHasSprint: getIsTaskHasSprint(meta),
  }), () => setOpenModal('deleteTaskModal'));
  setSelectedSort(sort);
  setSelectedMeta(meta);
  setIsRedirectToProject(propOr(false, 'isRedirectToProject', meta));
};

const onEditTaskModalHandler = ({
  setOpenModal,
  setSelectedSort, setSelectedTask, setSelectedMeta,
}) => (id, sort, meta) => {
  setSelectedTask(id);
  setOpenModal('editTaskModal');
  setSelectedSort(sort);
  setSelectedMeta(meta);
};

const onPinTaskModalHandler = ({
  setOpenModal, setSelectedTask,
}) => (id) => {
  setSelectedTask(id);
  setOpenModal('pinTaskModal');
};

const onUnpinTaskModalHandler = ({
  setOpenModal, setSelectedTask,
}) => (id) => {
  setSelectedTask(id);
  setOpenModal('unpinTaskModal');
};


const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common', 'tasks', 'projects']),
  withRouter,
  withWindowWidth(),
  withState('activeTab', 'setActiveTab', PROJECT_TABS.TASKS),
  withState('selectedTask', 'setSelectedTask', null),
  withState('selectedSprintId', 'setSelectedSprintId', 0),
  withState('sprintCRUDMeta', 'setSprintCRUDMeta', {}),
  withState('selectedCategoryId', 'setSelectedCategoryId', 0),
  withState('selectedSort', 'setSelectedSort', {}),
  withState('selectedMeta', 'setSelectedMeta', {}),
  withState('isRedirectToProject', 'setIsRedirectToProject', false),
  withProps(({ activeTab, match: { params: { id } } }) => {
    const isTabIsEqual = equals(activeTab);
    return {
      isTabNotesActive: isTabIsEqual(PROJECT_TABS.NOTES),
      isTabTasksActive: isTabIsEqual(PROJECT_TABS.TASKS),
      isTabTaskActive: isTabIsEqual(PROJECT_TABS.TASK),
      isTabRepositoryActive: isTabIsEqual(PROJECT_TABS.REPOSITORY),
      id: Number(id),
    };
  }),
  withHandlers({
    onDeleteTaskModal: onDeleteTaskModalHandler,
    onEditTaskModal: onEditTaskModalHandler,
    onPinTaskModal: onPinTaskModalHandler,
    onUnpinTaskModal: onUnpinTaskModalHandler,
  }),
  memo,
  withContext({
    onDeleteTaskModal: PropTypes.func,
    onEditTaskModal: PropTypes.func,
    onPinTaskModal: PropTypes.func,
    onUnpinTaskModal: PropTypes.func,
    projectId: PropTypes.number,
    selectedSort: PropTypes.instanceOf(Object),
    setActiveTab: PropTypes.func.isRequired,
    isRedirectToProject: PropTypes.bool.isRequired,
    setIsRedirectToProject: PropTypes.func.isRequired,
    setSelectedSprintId: PropTypes.func.isRequired,
    selectedSprintId: PropTypes.number.isRequired,
    setSelectedCategoryId: PropTypes.func.isRequired,
    selectedCategoryId: PropTypes.number.isRequired,
    selectedMeta: PropTypes.instanceOf(Object).isRequired,
    sprintCRUDMeta: PropTypes.instanceOf(Object).isRequired,
    setSprintCRUDMeta: PropTypes.func.isRequired,
    setSelectedTask: PropTypes.func,
  }, props => ({
    selectedSort: props.selectedSort,
    onDeleteTaskModal: props.onDeleteTaskModal,
    onEditTaskModal: props.onEditTaskModal,
    projectId: Number(props.match.params.id),
    setActiveTab: props.setActiveTab,
    setIsRedirectToProject: props.setIsRedirectToProject,
    isRedirectToProject: props.isRedirectToProject,
    selectedSprintId: props.selectedSprintId,
    setSelectedSprintId: props.setSelectedSprintId,
    selectedCategoryId: props.selectedCategoryId,
    setSelectedCategoryId: props.setSelectedCategoryId,
    selectedMeta: props.selectedMeta,
    sprintCRUDMeta: props.sprintCRUDMeta,
    setSprintCRUDMeta: props.setSprintCRUDMeta,
    onPinTaskModal: props.onPinTaskModal,
    onUnpinTaskModal: props.onUnpinTaskModal,
    setSelectedTask: props.setSelectedTask,
  })),
  lifecycle({
    componentDidMount() {
      const {
        getProject, getProjectsListRequest, getClientsRequest, getPinnedTasks,
        agendaTasks, getCategoriesTask, getProjectStatuses, getUsersWithTasks,
        // history: { location: { pathname } },
        match: { params: { id } },
        setAppTitle: setTitle,
      } = this.props;

      getProjectsListRequest();
      getClientsRequest();

      // const isAgendaBoard = /\/projects\/\d*\/agenda-board\//gi.test(pathname);

      if (id !== TASKS_WITHOUT_PROJECT) {
        getProject({ projectId: id }, {
          callbacks: {
            success: () => {
              compose(
                name => setTitle(`${name} - Avanga 2.0`),
                pathOr('Project', ['project', 'title']),
              );
              if (id !== TASKS_WITHOUT_PROJECT) {
                getPinnedTasks({
                  projectId: id,
                });
                agendaTasks({ projectId: id });
                getCategoriesTask({ id });
                getProjectStatuses({ projectId: id });
                getUsersWithTasks({ projectId: id });
              }
            },
          },
        });
      }
    },
    componentWillUnmount() {
      this.props.clearProjectData();
    },
  }),
);

export default enhancer(Project);
