import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';

import { ButtonsCollection, ModalWindow } from '../../../../../ui-kit';
import './style.sass';

const DeleteProjectModal = ({
  isOpen,
  onCloseModal,
  project,
  onDeleteProject,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    className="delete-project__modal"
    modalName="Delete project"
    title="Delete project"
  >
    <>
      <div className="row  text-align--left">
        <span className="delete-project__title title-md">

        Are you sure you want to delete
          <b className="delete-project__name">{prop('title', project)}</b>

          project?
        </span>
      </div>
      <div className="delete-project__button-row">
        <ButtonsCollection.ButtonBrill
          type="button"
          onClick={onDeleteProject}
          className="button--md delete-project__button-control button-brill--fill"
        >

        Delete
        </ButtonsCollection.ButtonBrill>
        <button
          type="button"
          className="button--cancel"
          onClick={onCloseModal}
        >

        Cancel
        </button>
      </div>
    </>
  </ModalWindow>
);

DeleteProjectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  project: PropTypes.objectOf(PropTypes.object).isRequired,
  onDeleteProject: PropTypes.func.isRequired,
};

export default DeleteProjectModal;
