import { Line } from 'react-chartjs-2';
import React from 'react';
import { getTimeStringBySeconds } from 'utils/helpers/dateHelpers';

const SECONDS_IN_ONE_HOUR = 3600;

const convertTooltipTime = ({ value }) => {
  const seconds = new Number(value) * SECONDS_IN_ONE_HOUR;
  const convertedTime = getTimeStringBySeconds(seconds);
  return convertedTime
}

const defaultOptions = {
  scales: {
    xAxes: [{
      stacked: false,
      barThickness: 8,
      min: 0,
      offset: true,
    }],
    yAxes: [{
      ticks: {
        beginAtZero: true,
        min: 0,
        max: 24,
      },
    }],
  },
  tooltips: {
    enabled: true,
    callbacks: {
      label: convertTooltipTime
    }
  }
}

/**
 *
 * @param id{number}
 * @param dateValue{Object}
 * @param requestResult{Object}
 * @returns {number|JSX.Element}
 * @constructor
 */
export const LineChart = ({ labels, datasets }) => {

  return (
    <Line
      data={{
        labels,
        datasets,
      }}
      options={defaultOptions}
    />
  );
};
