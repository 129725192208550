import React from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { MobileFiltersContainer, DropDown, FormsCollection } from '../../../../ui-kit';
import './style.sass';
import {
  SORT_BY_LETTERS_DOWN,
  SORT_BY_LETTERS_UP,
} from '../../constants';
import { CHANNELS_YOU_BELONG_TO, CHANNELS_YOU_CAN_JOIN } from '../../../channels/constants';
import { WINDOW_WIDTH } from '../../../../constants/ui';

const FiltersRow = ({
  setFieldValue,
  values,
  t,
  onChangeSearchHandler,
  areMobileFiltersOpen,
  toggleMobileFiltersHandler,
  windowWidth,
  setShowFilter,
}) => {
  const onChangeShowHandler = ({ val }) => {
    setShowFilter();
    setFieldValue('type', val);
  };
  const onChangeSortHandler = ({ val }) => setFieldValue('orderByTitle', val);


  return (
    <div className="directs-filter">
      <div className="directs-filter__row">
        <FormsCollection.Search
          className="search-field--circle directs-filter__search-field"
          name="search"
          withoutControl
          value={values.search}
          onChange={onChangeSearchHandler}
        />
        {/* set delay for search `debounceFunc` for it */}
        <MobileFiltersContainer
          windowWidth={windowWidth}
          title="Filter"
          onWidth={WINDOW_WIDTH.SMALL}
          isOpen={areMobileFiltersOpen}
          changeContainerStatusStateHandler={toggleMobileFiltersHandler}
        >
          <DropDown
            className="directs-filter__drop-down"
            label="Show: "
            currentItem={path(['type', 'id'], values)}
            list={[
              {
                id: 1,
                label: 'New chats',
                value: CHANNELS_YOU_CAN_JOIN,
              },
              {
                id: 2,
                label: 'Recent chats',
                value: CHANNELS_YOU_BELONG_TO,
              },
            ]}
            onChange={onChangeShowHandler}
          />
          <DropDown
            label="Sort: "
            currentItem={path(['orderByTitle', 'id'], values)}
            list={[
              {
                id: 1,
                label: t('All'),
                value: null,
              },
              {
                id: 2,
                label: t('A-Z'),
                value: SORT_BY_LETTERS_DOWN,
              },
              {
                id: 3,
                label: t('Z-A'),
                value: SORT_BY_LETTERS_UP,
              },
            ]}
            onChange={onChangeSortHandler}
          />
        </MobileFiltersContainer>
      </div>
    </div>
  );
};

FiltersRow.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  onChangeSearchHandler: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  areMobileFiltersOpen: PropTypes.bool.isRequired,
  toggleMobileFiltersHandler: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  setShowFilter: PropTypes.func.isRequired,
};

export default FiltersRow;
