import React, { useEffect } from 'react';
import { ScrollWrapper, PermissionElement } from '../..';
import PROJECT_TABS from '../../../constants/tabNavigation';
import { ACTIONS, MODULES } from '../../../constants/pemissions';
import { Provider } from './context/Provider';

import { GitlabProjectsList } from './GitlabProjectsList';
import { AddGitlabProject } from './GtilabProjectsCRUD/AddGitlabProject';

import './style.sass';

export const GitlabProjects = ({ setActiveTab }) => {
  useEffect(() => {
    setActiveTab(PROJECT_TABS.REPOSITORY);
    return () => {
      setActiveTab(PROJECT_TABS.TASK);
    };
  }, []);
  return (
    <Provider>
      <ScrollWrapper>
        <PermissionElement
          module={MODULES.PROJECTS}
          action={ACTIONS.READ}
        >
          <GitlabProjectsList />
          <AddGitlabProject />
        </PermissionElement>
      </ScrollWrapper>
    </Provider>
  );
};
