/* eslint-disable jsx-a11y/no-static-element-interactions,react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import LeadItem from '../leadItem';
import './style.sass';
import { EmptyResult } from '../../../../ui-kit';

const ListOfLeads = ({
  t,
  leads,
  activeTabLeadId,
  toggleActiveTab,
  onEditLeadModal,
  onDeleteLeadModal,
  onPinLeadModal,
  onUnpinLeadModal,
  isPinned,
}) => (
  <div
    className={`listOfLeads ${activeTabLeadId && 'listOfLeads__has-selected-lead'}`}
  >
    <div
      className="leadItem leadItem--header"
    >
      <div className="leadItem__row">
        <div className="leadItem__col" />
        <div className="leadItem__col leadItem__username">
          Username
        </div>
        <div className="leadItem__col leadItem__position">
          Position
        </div>
        <div className="leadItem__col leadItem__company">
          Company
        </div>
        <div className="leadItem__col leadItem__email">
          Email
        </div>
        <div className="leadItem__col leadItem__email">
          Service
        </div>
        <div className="leadItem__col leadItem__pipeline">
          Pipeline
        </div>
        <div className="leadItem__col leadItem__progress">
          Status
        </div>
        <div className="leadItem__col leadItem__control" />
      </div>
    </div>
    { leads.length !== 0
      ? leads.map(lead => (
        <LeadItem
          key={lead}
          t={t}
          onEditLeadModal={onEditLeadModal}
          onDeleteLeadModal={onDeleteLeadModal}
          onPinLeadModal={onPinLeadModal}
          onUnpinLeadModal={onUnpinLeadModal}
          leadId={lead}
          isPinned={isPinned}
          isOpen={activeTabLeadId === lead}
          toggleActiveTab={toggleActiveTab}
        />
      )) : <EmptyResult text="No search results found" />
    }
  </div>
);

ListOfLeads.propTypes = {
  toggleActiveTab: PropTypes.func.isRequired,
  activeTabLeadId: PropTypes.number,
  t: PropTypes.func.isRequired,
  onEditLeadModal: PropTypes.func.isRequired,
  onDeleteLeadModal: PropTypes.func.isRequired,
  onPinLeadModal: PropTypes.func.isRequired,
  onUnpinLeadModal: PropTypes.func.isRequired,
  leads: PropTypes.oneOfType([PropTypes.arrayOf(Object), PropTypes.number]).isRequired,
  isPinned: PropTypes.bool,
};
ListOfLeads.defaultProps = {
  isPinned: false,
  activeTabLeadId: null,

};

export default ListOfLeads;
