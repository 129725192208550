import {
  compose,
  forEachObjIndexed,
  mapObjIndexed,
  minBy,
  reduce,
  groupBy,
  omit,
  last,

} from 'ramda';
import moment from 'moment';
import * as messenger from '../../constants/messenger';

const dayStartTime = time => time - (time % 86400);

const getOldestMessageTimestamps = (messages) => {
  const dates = {};
  const getSmallestDate = message => message.created_at;
  const objectByDate = (message) => {
    dates[message.created_at] = message.created_at;
    return dates;
  };
  const composeC = compose(
    forEachObjIndexed(objectByDate),
    mapObjIndexed(reduce(minBy(getSmallestDate), last(messages))),
    groupBy(message => message.createdDay),
  );
  composeC(messages);
  return dates;
};

const checkIfDayIsSame = (firstTimestamp, secondTimestamp) => {
  const startOfFirstDay = dayStartTime(firstTimestamp);
  const startOfSecondDay = dayStartTime(secondTimestamp);
  return startOfFirstDay === startOfSecondDay;
};

const getUniqueOldestTimestamp = (newMessageTimestamps, loadedMessageDates) => {
  const checkMoreOlderTimestamp = (messageMore) => {
    forEachObjIndexed((loadedMessage) => {
      if (dayStartTime(messageMore) === dayStartTime(loadedMessage)) {
        const timestampsWithoutPrevTime = omit([loadedMessage], loadedMessageDates);
        // eslint-disable-next-line no-param-reassign
        loadedMessageDates = ({
          ...timestampsWithoutPrevTime,
          [messageMore]: messageMore,
        });
      }
    }, loadedMessageDates);
  };
  forEachObjIndexed(checkMoreOlderTimestamp, newMessageTimestamps);
  return loadedMessageDates;
};

const recomputeItemsIndex = ({ lastCountMessages, allItemsHeight, currentItemsHeight }) => {
  const itemsWithNewIndex = {};
  const recomputePrevSize = (value, key) => {
    itemsWithNewIndex[+key + lastCountMessages] = value;
  };
  forEachObjIndexed(recomputePrevSize, allItemsHeight);
  return ({
    ...itemsWithNewIndex,
    ...currentItemsHeight,
  });
};

const convertTimestamp = (timestamp, formatDate) => moment.unix(timestamp).format(formatDate);

const checkAreMembersVisible = type => type === messenger.GROUP_CHANNEL
    || type === messenger.PRIVATE_CHANNEL;

export {
  getUniqueOldestTimestamp,
  checkIfDayIsSame,
  dayStartTime,
  convertTimestamp,
  recomputeItemsIndex,
  getOldestMessageTimestamps,
  checkAreMembersVisible,
};
