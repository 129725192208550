import { createActions } from 'redux-actions';
import { identity } from 'ramda';
import types from './types';
import { customFieldsSchema } from './schema';

export const {
  addCustomFieldRequest,
  addCustomFieldSuccess,
  getCustomFieldsRequest,
  getCustomFieldsSuccess,
  editCustomFieldRequest,
  editCustomFieldSuccess,
  deleteCustomFieldRequest,
  editCustomFieldValueRequest,
  editCustomFieldValueSuccess,
  onDragCustomField,
  updateCustomFieldsPriorityRequest,
  updateCustomFieldsPriorityError,
} = createActions({
  [types.GET_CUSTOM_FIELDS_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/custom/get',
      selectorName: 'getCustomFieldsRequest',
      method: 'GET',
      params,
      schema: {
        rules: customFieldsSchema,
        pathData: ['data'],
      },
      actions: {
        success: getCustomFieldsSuccess,
      },
      ...meta,
    }),
  ],
  [types.ADD_CUSTOM_FIELD_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/custom/create',
      selectorName: 'addCustomFieldRequest',
      method: 'POST',
      params,
      actions: {
        success: addCustomFieldSuccess,
      },
      ...meta,
    }),
  ],
  [types.EDIT_CUSTOM_FIELD_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/custom/update',
      selectorName: 'editCustomFieldRequest',
      method: 'PUT',
      params,
      actions: {
        success: editCustomFieldSuccess,
      },
      ...meta,
    }),
  ],
  [types.EDIT_CUSTOM_FIELD_VALUE_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/custom/update-value',
      selectorName: 'editCustomFieldValueRequest',
      method: 'PUT',
      params,
      ...meta,
    }),
  ],
  [types.DELETE_CUSTOM_FIELD_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/custom/delete',
      selectorName: 'deleteCustomFieldRequest',
      method: 'DELETE',
      params,
      actions: {
        success: () => getCustomFieldsRequest(),
      },
      ...meta,
    }),
  ],
  [types.UPDATE_CUSTOM_FIELDS_PRIORITY_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/custom/update/priority',
      selectorName: 'updateCustomFieldsPriorityRequest',
      params,
      method: 'PUT',
      ...meta,
    }),
  ],
  [types.UPDATE_CUSTOM_FIELDS_PRIORITY_ERROR]: [
    identity,
    (params, meta) => meta,
  ],
},
types.GET_CUSTOM_FIELDS_SUCCESS,
types.ADD_CUSTOM_FIELD_SUCCESS,
types.EDIT_CUSTOM_FIELD_SUCCESS,
types.EDIT_CUSTOM_FIELD_VALUE_SUCCESS,
types.ON_DRAG_CUSTOM_FIELD);
