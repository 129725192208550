import {
  compose, pure, withHandlers, withProps, withStateHandlers,
} from 'recompose';
import { withTranslation } from 'react-i18next';
import { equals } from 'ramda';

import FiltersRow from './filtersRow';
import { withUrlParams, withWindowWidth } from '../../../../utils/enchancers';
import { MY_PROJECTS_FILTER } from '../../../../constants/projects';

const sortParams = {
  recent: {
    order: 'desc',
    sortBy: 'created_at',
  },
  old: {
    order: 'asc',
    sortBy: 'created_at',
  },
};

const SORTBY_PARAMS = {
  favorite: 0,
  recent: 1,
  old: 2,
  alphabetAsc: 3,
  alphabetDesc: 4,
}

const setAllFilter = ({ setActiveFilter, onSetUrlParam }) => () => {
  onSetUrlParam({ role: MY_PROJECTS_FILTER.ALL });
  setActiveFilter(MY_PROJECTS_FILTER.ALL);
};
const setCreatedByMeFilter = ({ setActiveFilter, onSetUrlParam }) => () => {
  onSetUrlParam({ role: MY_PROJECTS_FILTER.CREATED_BY_ME });
  setActiveFilter(MY_PROJECTS_FILTER.CREATED_BY_ME);
};
const setFavoritesFilter = ({ setActiveFilter, onSetUrlParam }) => () => {
  onSetUrlParam({ role: MY_PROJECTS_FILTER.FAVORITES });
  setActiveFilter(MY_PROJECTS_FILTER.FAVORITES);
};
const setActiveStatusFilter = ({ setActiveFilter, onSetUrlParam }) => () => {
  onSetUrlParam({ role: MY_PROJECTS_FILTER.ACTIVE });
  setActiveFilter(MY_PROJECTS_FILTER.ACTIVE);
};
const setInactiveStatusFilter = ({ setActiveFilter, onSetUrlParam }) => () => {
  onSetUrlParam({ role: MY_PROJECTS_FILTER.INACTIVE });
  setActiveFilter(MY_PROJECTS_FILTER.INACTIVE);
};

const onChangeSortingHandler = ({ onSortBy }) => ({ val: { order, sortBy } }) => {
  onSortBy({ order, sort: sortBy });
};

const onChangeSortingOnButtonHandler = ({ onSortBy, toggleMobileFiltersHandler }) => (e) => {
  const { target: { name } } = e;
  const { order, sortBy } = sortParams[name];
  onSortBy({ order, sort: sortBy });
  toggleMobileFiltersHandler();
};

const getSortValue = (order, sort) => {
  if (equals('created_at', sort)) {
    return equals('desc', order) ? SORTBY_PARAMS.recent : SORTBY_PARAMS.old;
  }
  if (equals('title', sort)) {
    return equals('asc', order) ? SORTBY_PARAMS.alphabetAsc : SORTBY_PARAMS.alphabetDesc;
  }
  if (equals('favorite', sort)) {
    return SORTBY_PARAMS.favorite;
  }
  return null;
};

const onSetProjectsFilterHandler = ({
  setProjectsFilter,
  onSetUrlParam,
}) => ({ val: { id } }) => {
  onSetUrlParam({ role: id, page: 1 });
  setProjectsFilter(id);
};

const enhance = compose(
  withTranslation(['projects']),
  pure,
  withWindowWidth(),
  withProps(({ order, sort }) => ({
    sortingByDate: getSortValue(order, sort),
  })),
  withUrlParams({}),
  withStateHandlers(
    () => ({ statusVisible: 'brill', areMobileFiltersOpen: false }), {
      toggleMobileFiltersHandler:
        ({ areMobileFiltersOpen }) => () => ({ areMobileFiltersOpen: !areMobileFiltersOpen }),
    },
  ),
  withHandlers({
    onChangeSorting: onChangeSortingHandler,
    onChangeSortingOnButton: onChangeSortingOnButtonHandler,
    onSetProjectsFilter: onSetProjectsFilterHandler,
    setAllFilter,
    setCreatedByMeFilter,
    setFavoritesFilter,
    setActiveStatusFilter,
    setInactiveStatusFilter,
  }),
);
export default enhance(FiltersRow);
