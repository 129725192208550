import React from 'react';
import PropTypes from 'prop-types';

import DroppableArea from '../droppableArea';
import './style.sass';

const ColumnBoard = ({
  invoiceStatus, isDragging, updateDroppable, invoices,
}) => (
  <div className="column-board">
    <div className="column-board__header">
      <h2 className="column-board__title">
        {invoiceStatus.title}
      </h2>
    </div>
    <DroppableArea
      invoices={invoices}
      invoiceStatusId={invoiceStatus.id}
      isDragging={isDragging}
      updateDroppable={updateDroppable}
    />
  </div>
);

ColumnBoard.propTypes = {
  invoiceStatus: PropTypes.shape({}).isRequired,
  invoices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDragging: PropTypes.bool.isRequired,
  updateDroppable: PropTypes.number,
};

ColumnBoard.defaultProps = {
  updateDroppable: null,
};

export default ColumnBoard;
