export default {
  GET_HR_CANDIDATES_REQUEST: 'GET_HR_CANDIDATES_REQUEST',
  GET_HR_CANDIDATE_REQUEST: 'GET_HR_CANDIDATE_REQUEST',
  DELETE_HR_CANDIDATE_REQUEST: 'DELETE_HR_CANDIDATE_REQUEST',
  CREATE_HR_CANDIDATE_REQUEST: 'CREATE_HR_CANDIDATE_REQUEST',
  UPDATE_HR_CANDIDATE_REQUEST: 'UPDATE_HR_CANDIDATE_REQUEST',
  UPDATE_HR_CANDIDATE_SUCCESS: 'UPDATE_HR_CANDIDATE_SUCCESS',
  SET_HR_CANDIDATE: 'SET_HR_CANDIDATE',
  SET_HR_CANDIDATES: 'SET_HR_CANDIDATES',
  GET_HR_COMMENTS_REQUEST: 'GET_HR_COMMENTS_REQUEST',
  SET_HR_COMMENTS: 'SET_HR_COMMENTS',

  GET_HR_FILES_REQUEST: 'GET_HR_FILES_REQUEST',
  DELETE_HR_FILE_REQUEST: 'DELETE_HR_FILE_REQUEST',
  DELETE_HR_FILE_SUCCESS: 'DELETE_HR_FILE_SUCCESS',
  SET_HR_FILES: 'SET_HR_FILES',
};
