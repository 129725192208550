import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { Emoji } from 'emoji-mart';

const EmojiAutoComplete = ({
  emojisAutocomplete,
  onSetEmojiByAutocomplete, emojiTyping, emojiSelected, onSetRefEmojiAutocomplete,
}) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <div
    ref={onSetRefEmojiAutocomplete}
    onMouseDown={e => e.preventDefault()}
    className={`emoji_auto-complete ${emojisAutocomplete.length ? 'emoji_auto-complete--open' : ''}`}
  >
    <div className="emoji-autoComplete__header">
      <div className="emoji-autoComplete__current">

        Emoji matching
        {' '}
        <b>
          {` '${emojiTyping}' `}
        </b>
      </div>
      <div className="emoji-autoComplete__help">
        {' '}
        <i>Enter</i>
        {' '}

- to set,
        {' '}
        <i>Left</i>
        {' '}

or
        {' '}
        <i>Right</i>
        {' '}

- to navigate
        {' '}
        <i>Esc</i>
        {' '}

- to dismiss
      </div>
    </div>
    {emojisAutocomplete.map((emoji, index) => (
      <div
        key={`emoji_autocomplete-item${emoji.id}`}
        className={`emoji-autoComplete__emoji ${index === emojiSelected ? 'emoji-autoComplete__emoji--active' : ''}`}
      >
        <Emoji
          emoji={emoji}
          set="messenger"
          onClick={() => onSetEmojiByAutocomplete(emoji)}
          size={21}
        />
      </div>
    ))}
  </div>
);

EmojiAutoComplete.propTypes = {
  emojisAutocomplete: PropTypes.instanceOf(Array).isRequired,
  onSetEmojiByAutocomplete: PropTypes.func.isRequired,
  onSetRefEmojiAutocomplete: PropTypes.func,
  emojiTyping: PropTypes.string.isRequired,
  emojiSelected: PropTypes.number.isRequired,
};

EmojiAutoComplete.defaultProps = {
  onSetRefEmojiAutocomplete: () => {},
};

export default EmojiAutoComplete;
