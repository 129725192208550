import React from 'react';
import PropTypes from 'prop-types';
import {
  path, identity, compose, propOr, test,
} from 'ramda';

import {
  Avatar,
  BrillMask,
  OnlineStatusLabel, ButtonsCollection,
} from '../../../../../../../../ui-kit';
import './style.sass';
import {
  ControlsMessageCard, DateLabel, LoadingMoreLabel, EditableMessage,
} from '../index';
import LabelUnreadMessage from '../../../../../labelUnreadMessage';
import { WINDOW_WIDTH } from '../../../../../../../../constants/ui';
import { ReplayedMessage } from '../../../../../index';
import { formatDate } from '../../../../../../../../utils/helpers/commonHelpers';
import { getImageUrl } from '../../../../../../../../utils/helpers/requestHelpers';


const isImageType = str => test(/image/, str);

const MessageInnerContent = ({
  message, content, setMessageAsEditable, isEditable, onEditMessage, onClickSaveContent,
  onShowUserProfile, isShowActions, onClickShowActions, isEdited,
  onChangeContent, channelId, onKeyDownSaveContent, isOwner, id, onClickMention,
  onMountEditable, userAvatar, userName, onRenderContent, onWillUnMountEditable,
  onClickDelete, isUnread, isNewDay, isOwnerChannel,
  userOnlineStatus, onCloseEditMessage, getRef, onPinMessage, onUnpinMessage, onSetReplyMessage,
  parentMessage, windowWidth, isPending, onKeyDown,
}) => (
  <>
    {
      (isUnread && isNewDay) ? (
        <div className="message-item__double-label">
          { isNewDay && <DateLabel date={message.created_at} /> }
          { isUnread && <LabelUnreadMessage className="label--md" /> }
        </div>
      ) : (
        <>
          { isNewDay && <DateLabel date={message.created_at} /> }
          { isUnread && <LabelUnreadMessage /> }
        </>
      )
    }
    {
      isPending && (<LoadingMoreLabel content="Loading..." />)
    }
    <div
      className={`messenger__message message-card ${isEditable ? 'message-card--edit' : ''}`}
    >
      <div className="message-card__avatar">
        <BrillMask
          onClick={onShowUserProfile}
          asLink
          outsideChildren={<OnlineStatusLabel status={userOnlineStatus} />}
        >
          <Avatar src={userAvatar} alt={userName} />
        </BrillMask>
      </div>
      <div className="message-card__body">
        <div className="message-card__head">
          {
            windowWidth > WINDOW_WIDTH.X_SMALL && (
              <ButtonsCollection.ButtonIcons
                title="Reply"
                className="icon-reply message-card__head-icon-reply"
                onClick={onSetReplyMessage}
              />
            )
          }
          {!isEditable && (
            <ControlsMessageCard
              isShowActions={isShowActions}
              channelId={channelId}
              isOwner={isOwner}
              isOwnerChannel={isOwnerChannel}
              messageId={message.id}
              message={message}
              onClickShowActions={onClickShowActions}
              onEditMessage={onEditMessage}
              onClickDelete={onClickDelete}
              onClickPin={onPinMessage}
              onClickUnpin={onUnpinMessage}
              onSetReplyMessage={onSetReplyMessage}
            />
          )}
          <div className="message-card__user">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div tabIndex="0" role="button" className="message-card__name" onClick={onShowUserProfile}>
              { userName }
            </div>
          </div>
          <span className="message-card__date">
            <span className="message-card__date-only">{formatDate(message.created_at, 'MMM DD, YYYY')}</span>
            {' '}
            <span className="message-card__time-only">{formatDate(message.created_at, 'h:mm:ss A')}</span>
            {isEdited && (<span className="message-card__edited-info" title={`Edited at ${formatDate(message.updated_at, 'MMM DD, YYYY h:mm:ss A')}`}> (edited)</span>)}
          </span>
        </div>
        <div className={`message-card__content ${(message.content && propOr([], 'files', message).length)
          ? '' : 'message-card__content--empty'}`}
        >
          {
            parentMessage && (
              <ReplayedMessage
                authorId={parentMessage.created_by}
                message={parentMessage}
                content={
                  onRenderContent(parentMessage.content, onClickMention, parentMessage.id)
                }
              />
            )
          }
          {
            isEditable ? (
              <EditableMessage
                onKeyDownSaveContent={onKeyDownSaveContent}
                onChangeContent={onChangeContent}
                onClickSaveContent={onClickSaveContent}
                setMessageAsEditable={setMessageAsEditable}
                onCloseEditMessage={onCloseEditMessage}
                onMount={onMountEditable}
                onWillUnMount={onWillUnMountEditable}
                onKeyDown={onKeyDown}
                content={onRenderContent(content,
                  onClickMention,
                  message.id,
                  { isEditing: true })
                }
                id={id}
                messageRef={getRef('message')}
              />
            ) : onRenderContent(content, onClickMention, message.id, { metadata: message.data }) }
        </div>
        {
          propOr([], 'files', message).length ? (
            <>
              {
                propOr([], 'files', message).length === 1
                && compose(isImageType, path(['files', 0, 'type']))(message) ? (
                  <div className="message-card__solo-image">
                    <a
                      href={getImageUrl(path(['files', 0, 'id'], message))}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="message-card__solo-image"
                    >
                      <img
                        alt={path(['files', 0, 'original'], message)}
                        src={getImageUrl(path(['files', 0, 'id'], message), '?height=300')}
                      />
                    </a>
                  </div>
                  ) : (
                    <ul className="message-card__files-list">
                      {
                  propOr([], 'files', message).map(file => (
                    <li
                      className="message-card__files-item"
                      key={`f-${file.id}-m-${id}`}
                    >
                      <a
                        href={getImageUrl(file.id)}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="message-card__files-name link"
                      >
                        <span className="icon-attach-file message-card__files-icon" />
                        {file.original}
                      </a>
                    </li>
                  ))
                }
                    </ul>
                  )
              }
            </>
          ) : ''
        }
      </div>
    </div>
  </>
);

MessageInnerContent.propTypes = {
  onEditMessage: PropTypes.func.isRequired,
  onClickSaveContent: PropTypes.func.isRequired,
  onClickShowActions: PropTypes.func.isRequired,
  onMountEditable: PropTypes.func.isRequired,
  onWillUnMountEditable: PropTypes.func.isRequired,
  onKeyDownSaveContent: PropTypes.func,
  onChangeContent: PropTypes.func.isRequired,
  onClickMention: PropTypes.func.isRequired,
  onRenderContent: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  setMessageAsEditable: PropTypes.func.isRequired,
  onShowUserProfile: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  channelId: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isShowActions: PropTypes.bool.isRequired,
  isEdited: PropTypes.bool.isRequired,
  isUnread: PropTypes.bool.isRequired,
  isNewDay: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  userAvatar: PropTypes.string.isRequired,
  message: PropTypes.instanceOf(Object).isRequired,
  parentMessage: PropTypes.oneOfType([PropTypes.instanceOf(Object), () => null]),
  isOwnerChannel: PropTypes.bool.isRequired,
  userOnlineStatus: PropTypes.number.isRequired,
  onCloseEditMessage: PropTypes.func.isRequired,
  getRef: PropTypes.func.isRequired,
  onPinMessage: PropTypes.func.isRequired,
  onUnpinMessage: PropTypes.func.isRequired,
  onSetReplyMessage: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  isPending: PropTypes.bool.isRequired,
  onKeyDown: PropTypes.func.isRequired,
};

MessageInnerContent.defaultProps = {
  parentMessage: null,
  onKeyDownSaveContent: identity,
};

export default MessageInnerContent;
