import React from 'react';
import PropTypes from 'prop-types';
import { ModulesList } from '../../../../newContainers/leftSideBar/components';
import { CRM_ROUTES } from '../../../../constants/crm';

const CRMModules = ({
  t,
}) => (
  <ModulesList
    data={[
      {
        title: t('Leads'), id: 1, asLink: true, href: CRM_ROUTES.LEADS,
      },
      {
        title: t('Contacts'), id: 3, asLink: true, href: CRM_ROUTES.CONTACTS,
      },
      {
        title: t('Clients'), id: 2, asLink: true, href: CRM_ROUTES.CLIENTS,
      },
      {
        title: t('Invoices'), id: 2, asLink: true, href: CRM_ROUTES.INVOICES,
      },
    ]}
  />
);

CRMModules.propTypes = {
  t: PropTypes.func.isRequired,
};

export default CRMModules;
