import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Pagination, ScrollWrapper,
} from '../../ui-kit';
import { PipelineItem } from './components';
import { EditPipelineModal, DeletePipelineModal, CreatePipelineModal } from './modals';
import './style.sass';

const PipeLinesContainer = ({
  pipelines,
  onDeletePipeline,
  selectedPipelineId,
  pipelinesCount,
  pagination,
  onPageChange,
  onPipelineAction,
}) => (
  <>
    <ScrollWrapper>
      <div className="pipelines">
        <Grid.Wrapper
          name="Pipelines"
          className="pipelines__table"
          pagination={(
            <Pagination
              count={pipelinesCount}
              component="contacts"
              pagination={pagination}
              onPageChange={onPageChange}
            />)
          }
        >
          <Grid.Row className="pipelines__table-row pipelines__table-header">
            <Grid.Col
              name="#"
              className="pipelines__table-col"
            />
            <Grid.Col
              name="Name"
              className="pipelines__table-col"
            />
          </Grid.Row>
          {
            pipelines.map(id => (
              <PipelineItem
                key={id}
                id={id}
              />
            ))
          }
        </Grid.Wrapper>
      </div>
    </ScrollWrapper>
    <EditPipelineModal onSuccess={onPipelineAction} selectedPipelineId={selectedPipelineId} />
    <CreatePipelineModal onSuccess={onPipelineAction} />
    <DeletePipelineModal selectedPipelineId={selectedPipelineId} onDelete={onDeletePipeline} />
  </>
);

PipeLinesContainer.propTypes = {
  pipelinesCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPipelineAction: PropTypes.func.isRequired,
  pagination: PropTypes.shape({}).isRequired,
  selectedPipelineId: PropTypes.number.isRequired,
};

export default PipeLinesContainer;
