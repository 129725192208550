import React, { useEffect } from 'react';
import { ScrollWrapper, PermissionElement } from '..';
import { Provider } from './context/Provider';

import { FilterRow } from './FilterRow';
import { NotesList } from './NotesList';
import { EditNote, CreateNote, DeleteNote } from './NotesCRUD';

import { withPermissionHandlers } from '../../utils/helpers/permissionHelpers';
import { ACTIONS, MODULES } from '../../constants/pemissions';
import PROJECT_TABS from '../../constants/tabNavigation';

import './style.sass';

const ProjectsNotes = ({ setActiveTab }) => {
  useEffect(() => {
    setActiveTab(PROJECT_TABS.NOTES);
    return () => {
      setActiveTab(PROJECT_TABS.TASKS);
    };
  }, []);

  return (
    <Provider>
      <ScrollWrapper>
        <PermissionElement
          module={MODULES.PROJECTS}
          action={ACTIONS.READ}
        >
          <FilterRow />
          <NotesList />
          <CreateNote />
          <EditNote />
          <DeleteNote />
        </PermissionElement>
      </ScrollWrapper>
    </Provider>
  );
};

export default withPermissionHandlers(ProjectsNotes);
