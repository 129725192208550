import React from 'react';
import PropTypes from 'prop-types';
import { FormsCollection } from '../../../../../ui-kit';

const HrSearch = ({
  onClick, value, onChange, onSearchByEnter,
}) => (
  <div className="row settingUsers__search-row">
    <FormsCollection.Search
      className="search-field--fill"
      placeholder="Search by name, e-mail"
      name="search-candidates"
      value={value}
      onChange={onChange}
      onClick={onClick}
      onSearchByEnter={onSearchByEnter}
    />
  </div>
);

HrSearch.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onSearchByEnter: PropTypes.func.isRequired,
};
HrSearch.defaultProps = {};

export default HrSearch;
