import React from 'react';
import { GitlabCommitItem } from '../GitlabCommitItem';
import { GitlabCommitsPagination } from '../GitlabCommitsPagination';
import './style.sass';

/**
 * @param commits {array}
 * @param currentPage {number}
 * @param onGoPreviousPage {function}
 * @param onGoNextPage {function}
 * @param totalPages {number}
 * @param totalCommits {number}
 * @returns {JSX.Element}
 * @constructor
 */


export const GitlabCommitsList = ({
  commits, currentPage, onGoPreviousPage, onGoNextPage, totalPages, totalCommits,
}) => (
  <>
    <ul>
      {commits?.map(({
        id, title, commited_date: date, author_name: author, web_url: url,
      }) => (
        <GitlabCommitItem
          key={id}
          title={title}
          url={url}
          date={date}
          author={author}
        />
      ))}
    </ul>
    <GitlabCommitsPagination
      currentPage={currentPage}
      onGoPreviousPage={onGoPreviousPage}
      onGoNextPage={onGoNextPage}
      totalPages={totalPages}
      totalCommits={totalCommits}
    />
  </>
);
