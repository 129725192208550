import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { SPRINT_STATUS } from '../../../../constants/sprints';
import { projectSelectors } from '../../../../state/project';
import { APP_QUERY_PARAMS } from '../../../../constants/appQueryParams';

const useDropArea = () => {
  const { search } = useLocation();
  const sprints = useSelector(projectSelectors.getProjectSprintsTasks);
  const backlog = useSelector(projectSelectors.getBacklogTasksList);
  const isCategorySelected = search.indexOf(APP_QUERY_PARAMS.CATEGORY) !== -1;
  const showBacklog = !!backlog.length || !isCategorySelected;

  const [firstSprint, ...nextSprints] = useMemo(() => {
    let sortedSprints = Object.values(sprints)
      .reverse()
      .sort((item) => (item.status === SPRINT_STATUS.ACTIVE ? -1 : 1));

    if (isCategorySelected) {
      sortedSprints = sortedSprints.filter(({ tasks }) => tasks.length);
    }

    return sortedSprints;
  }, [sprints]);

  return { firstSprint, nextSprints, showBacklog };
};

export default useDropArea;
