import * as yup from 'yup';
import { translateHelpers } from '../../utils/helpers';

const rules = yup.object().shape({
  search: yup
    .string()
    .required({
      field: 'search',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Search'),
      },
    }),
  sortBy: yup.number(),
});

export default rules;
