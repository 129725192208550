import React, { memo } from 'react';
import classNames from 'classnames';
import { Avatar, BrillMask } from '../..';


/**
 * @param onClick {function}
 * @param alt {string}
 * @param id {string | number}
 * @param select {boolean}
 * @param asLink {boolean}
 * @param src {string}
 * @param importantBackground {boolean}
 * @returns {JSX.Element}
 * @constructor
 */


export const AvatarItem = memo(({
  alt,
  id,
  select,
  // asLink = false,
  src = '',
  importantBackground,
  onClick = () => { },
}) => (
  <li
    className={classNames('users-list__item', { 'users-list__item--active': select })}
  >
    <BrillMask asLink>
      <Avatar
        id={id}
        src={src}
        alt={alt}
        onClick={onClick}
        importantBackground={importantBackground || ''}
        className="users-list__avatar"
      />
    </BrillMask>
  </li>
));
