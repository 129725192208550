import { compose } from 'recompose';
import { connect } from 'react-redux';

import ColumnBoard from './columnBoard';
import { projectSelectors } from '../../../../state/project';

const mapStateToProps = (state, { statusId }) => ({
  agenda: projectSelectors.getAgenda(state)(statusId),
});

const enhance = compose(
  connect(mapStateToProps),
);
export default enhance(ColumnBoard);
