import { takeLatest, fork, put } from 'redux-saga/effects';

import { sagasManager } from '../../utils';
import { newTaskTypes, newTaskActions } from '.';

function* getSprintsTaskCrudAutocompleteFlow({ payload }) {
  yield put(newTaskActions.getSprintsTaskCrudRequest(...payload));
}

function* getSprintsTaskCrudAutocompleteFlowWatcher() {
  yield takeLatest(newTaskTypes.GET_SPRINTS_TASK_CRUD_AUTOCOMPLETE,
    getSprintsTaskCrudAutocompleteFlow);
}


sagasManager.addSagaToRoot(function* watcher() {
  yield fork(getSprintsTaskCrudAutocompleteFlowWatcher);
});
