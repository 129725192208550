import React from 'react';
import PropTypes from 'prop-types';
import { ShowMoreItems } from '../../../../../../ui-kit';
import PinnedContactItem from '../pinnedContactItem';
import './style.sass';

const PinnedContacts = ({
  pinnedContacts,
}) => (
  <>
    {
      pinnedContacts && pinnedContacts.length ? (
        <ul className="pinned-contacts">
          <ShowMoreItems
            count={4}
            items={pinnedContacts.map(id => id && <PinnedContactItem key={id} contactId={id} />)}
          />
        </ul>
      ) : (
        <div className="pinned-contacts--empty">
          There are no pinned contacts yet
        </div>
      )
    }
  </>

);

PinnedContacts.propTypes = {
  pinnedContacts: PropTypes.instanceOf(Object).isRequired,
};

export default PinnedContacts;
