import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { memo } from 'react';

import Routes from './routes';
import { uiSelectors } from '../../../state/ui';

const mapStateToProps = state => ({
  routeTypes: uiSelectors.getRouteTypes(state),
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  memo,
);
export default enhance(Routes);
