import React from 'react';
import PropTypes from 'prop-types';
import ContactsItem from '../contactsItem';
import { ButtonsCollection, ShowMoreItems } from '../../../../ui-kit';
import { CreateContactModal, EditContactModal, DeleteContactModal } from '../../../contactsContainer/modals';
import './style.sass';

const ContactsInfo = ({
  isOpen,
  leadContacts,
  t,
  setCreateContactModalOpen,
  lead,
  selectedContactId,
  onDeleteContact,
  leadContactsCount,
  getMoreContacts,
  createLeadContactsSuccess,
  editLeadContactsSuccess,
}) => (
  <div className="lead-contacts">
    <ButtonsCollection.ButtonBrill
      onClick={setCreateContactModalOpen}
      className="button--sm header__control-button--project add-contacts-button"
    >
      {t('New contact')}
    </ButtonsCollection.ButtonBrill>
    {leadContacts && leadContacts.length ? (
      <ShowMoreItems
        className="client-tasks__wrapper"
        onShowMoreCustomClick={getMoreContacts}
        totalItemsCount={leadContactsCount}
        count={4}
        items={
          leadContacts.map(contact => (
            <ContactsItem contact={contact} isOpen={isOpen} />
          ))}
      />
    ) : <div className="client-tasks__empty">There are no contacts yet</div> }
    <CreateContactModal
      relatedTo={lead}
      onSuccess={createLeadContactsSuccess}
    />
    <EditContactModal
      selectedContactId={selectedContactId}
      onSuccess={editLeadContactsSuccess}
    />
    <DeleteContactModal onDelete={onDeleteContact} selectedContactId={selectedContactId} />
  </div>
);

ContactsInfo.propTypes = {
  t: PropTypes.func.isRequired,
  setCreateContactModalOpen: PropTypes.func.isRequired,
  onDeleteContact: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  leadContacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  lead: PropTypes.shape({}).isRequired,
  selectedContactId: PropTypes.number,
  leadContactsCount: PropTypes.number,
  getMoreContacts: PropTypes.func,
  createLeadContactsSuccess: PropTypes.func.isRequired,
  editLeadContactsSuccess: PropTypes.func.isRequired,
};

ContactsInfo.defaultProps = {
  leadContactsCount: null,
  selectedContactId: null,
  isOpen: null,
  getMoreContacts: () => {},
};

export default ContactsInfo;
