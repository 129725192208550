import React from 'react';

import { Header } from '../../Header';
import { Copyright } from '../..';

import './style.sass';

/**
 *
 * @param children {string | JSX.Element}
 * @returns {JSX.Element}
 * @constructor
 */

export const Auth = ({ children = '' }) => (
  <div className="page-wrapper page-auth">
    <Header empty />
    <div className="page-auth__container">
      <div className="auth-wrapper">
        {children}
      </div>
    </div>
    <Copyright />
  </div>
);
