import {
  compose, lifecycle, getContext, withState, withContext, withHandlers, withStateHandlers,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProjectsNotes from './projectsNotes';
import PROJECT_TABS from '../../constants/tabNavigation';
import { uiActions } from '../../state/ui';
import { withPermissionHandlers } from '../../utils/helpers/permissionHelpers';

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
};


const onEditNoteHandler = ({ setSelectedNoteId, setOpenModal }) => (noteId) => {
  setSelectedNoteId(noteId);
  setOpenModal('editNoteModal');
};

const onDeleteNoteHandler = ({ setSelectedNoteId, setOpenModal }) => (noteId) => {
  setSelectedNoteId(noteId);
  setOpenModal('deleteNoteModal');
};

const onChangeSortingStateHandler = () => val => ({
  sortBy: val,
});

const enhance = compose(
  connect(null, mapDispatchToProps),
  withState('selectedNoteId', 'setSelectedNoteId', 0),
  getContext({
    setActiveTab: PropTypes.func,
  }),
  lifecycle({
    componentDidMount() {
      const { setActiveTab } = this.props;
      setActiveTab(PROJECT_TABS.NOTES);
    },
    componentWillUnmount() {
      const { setActiveTab } = this.props;
      setActiveTab(PROJECT_TABS.TASKS);
    },
  }),
  withHandlers({
    onEditNote: onEditNoteHandler,
    onDeleteNote: onDeleteNoteHandler,
  }),
  withStateHandlers(() => ({ sortBy: { val: { id: 0 } } }), {
    onChangeSorting: onChangeSortingStateHandler,
  }),
  withContext({
    selectedNoteId: PropTypes.func.isRequired,
    onEditNote: PropTypes.func.isRequired,
    onDeleteNote: PropTypes.func.isRequired,
  }, props => ({
    selectedNoteId: props.selectedNoteId,
    onEditNote: props.onEditNote,
    onDeleteNote: props.onDeleteNote,
  })),
  withPermissionHandlers,
);
export default enhance(ProjectsNotes);
