export default {
  GET_ACTIVE_TASK_REQUEST: 'GET_ACTIVE_TASK_REQUEST',
  GET_TASK_COMMENTS_REQUEST: 'GET_TASK_COMMENTS_REQUEST',
  GET_TIME_ENTRIES_REQUEST: 'GET_TIME_ENTRIES_REQUEST',

  ADD_TASK_COMMENT_REQUEST: 'ADD_TASK_COMMENT_REQUEST',
  ADD_TIME_ENTRY_REQUEST: 'ADD_TIME_ENTRY_REQUEST',

  UPDATE_TIME_ENTRY_REQUEST: 'UPDATE_TIME_ENTRY_REQUEST',

  DELETE_TIME_ENTRY_REQUEST: 'DELETE_TIME_ENTRY_REQUEST',

  SET_ACTIVE_TASK: 'SET_ACTIVE_TASK',
  SET_TASK_COMMENT: 'SET_TASK_COMMENT',
  SET_TASK_COMMENTS: 'SET_TASK_COMMENTS',
  SET_TIME_ENTRIES: 'SET_TIME_ENTRIES',
  SET_TIME_ENTRY: 'SET_TIME_ENTRY',
  SET_TIME_ENTRY_ENTITY: 'SET_TIME_ENTRY_ENTITY',

  DELETE_TIME_ENTRY: 'DELETE_TIME_ENTRY',
  DELETE_TIME_ENTRY_ENTITY: 'DELETE_TIME_ENTRY_ENTITY',

  REORDER_TIME_ENTRIES: 'REORDER_TIME_ENTRIES',
  REORDER_SPENT_TIME_TASK: 'REORDER_SPENT_TIME_TASK',
  CHANGE_TASK_VALUES: 'CHANGE_TASK_VALUES',

  UPDATE_ACTIVE_TASK: 'UPDATE_ACTIVE_TASK',
  REDIRECT_TASK_NOT_FOUND: 'REDIRECT_TASK_NOT_FOUND',
  CLEAR_DATA_TASK: 'CLEAR_DATA_TASK',

  GET_TASK_FILES_REQUEST: 'GET_TASK_FILES_REQUEST',
  SET_TASK_FILES: 'SET_TASK_FILES',

  DELETE_TASK_FILE_REQUEST: 'DELETE_TASK_FILE_REQUEST',
  DELETE_TASK_FILE: 'DELETE_TASK_FILE',

  DELETE_TASK_COMMENT_REQUEST: 'DELETE_TASK_COMMENT_REQUEST',
  DELETE_TASK_COMMENT: 'DELETE_TASK_COMMENT',

  EDIT_TASK_COMMENT_REQUEST: 'EDIT_TASK_COMMENT_REQUEST',
  EDIT_TASK_COMMENT: 'EDIT_TASK_COMMENT',

  GET_TASK_LOGS_REQUEST: 'GET_TASK_LOGS_REQUEST',
  SET_TASK_LOGS: 'SET_TASK_LOGS',
};
