import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { push } from 'connected-react-router';

import { OnlineStatusLabel, ButtonsCollection, PreLoaderWhileLoading } from '../../../../ui-kit';
import { usersSelectors } from '../../../../state/users';
import { uiSelectors } from '../../../../state/ui';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import { getShortName, stringToColor } from '../../../../ui-kit/avatar/utils';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { PRELOADER_DIMENSION } from '../../../../constants/ui';
import './style.sass';
import api from '../../../../api/messenger.service';

const PRELOADER_DELAY = 600;

export const UserProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector(uiSelectors.getUserProfileId);
  const users = useSelector(usersSelectors.getUsersEntities);
  const isPending = useSelector(uiSelectors.getUserProfileRequestPending);
  const user = users[userId];
  const userAvatar = getImageUrl(user.avatar);
  const backgroundShortName = stringToColor(getFullName(user));
  const shortName = getShortName(getFullName(user));

  const openChannelByUserId = useCallback(async (value) => {
    const data = await api.openDirectChannelByUserId(value);
    if (data) {
      dispatch(push(`/messenger/${data.channel.id}`));
    }
  });

  const onGoToSendMessage = () => openChannelByUserId(userId);

  return (
    <PreLoaderWhileLoading
      dimension={PRELOADER_DIMENSION.SMALL}
      alignContainerCenter
      delay={PRELOADER_DELAY}
      isLoading={isPending}
      onAction={isPending}
    >
      <div className="user-profile">
        <div className="user-profile__profile-image">
          {
            userAvatar ? (<img src={userAvatar} alt={user.username} />) : (
              <div
                style={{ backgroundColor: backgroundShortName }}
                className="user-profile__empty-image"
              >
                {shortName}
              </div>
            )
          }
        </div>
        <div className="user-profile__user-info">
          <div className="top-block-info">
            <div className="user-status">
              <div className={classNames(
                'user-status-online',
                { 'user-status-online--online': user.is_online },
                { 'user-status-online--offline': !user.is_online },
              )}
              >
                <OnlineStatusLabel status={user.is_online} />
                {user.is_online ? t('Online') : t('Offline')}
              </div>
            </div>
            <div className="user-name">
              <h4 className="user-name__title">{`${user.first_name} ${user.last_name}`}</h4>
            </div>
            <div className="user-nickname">
              {`@${user.username}`}
            </div>
            <ButtonsCollection.ButtonBrill
              className="user-send-message-button"
              onClick={onGoToSendMessage}
            >
              {t('Send a message')}
            </ButtonsCollection.ButtonBrill>
          </div>
          <div className="bottom-block-info">
            <ul className="user-info-list">
              {
                user.position && (
                  <li className="user-info-item">
                    <div className="user-info-label">{t('Position')}</div>
                    <div className="position-name">{user.position}</div>
                  </li>
                )
              }
              {
                user.phone && (
                  <li className="user-info-item">
                    <div className="user-info-label">{t('Phone number')}</div>
                    <div className="phone-number">{user.phone}</div>
                  </li>
                )
              }
              {
                user.email && (
                <li className="user-info-item">
                  <div className="user-info-label">{t('Email address')}</div>
                  <div className="email-address">{user.email}</div>
                </li>
                )
              }
              {
                user.timezone && (
                <li className="user-info-item">
                  <div className="user-info-label">{t('Time zone')}</div>
                  <div className="timezone">{user.timezone}</div>
                </li>
                )
              }
            </ul>
          </div>
        </div>
      </div>
    </PreLoaderWhileLoading>
  );
};
