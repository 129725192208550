import { compose, lifecycle, getContext } from 'recompose';
import PropTypes from 'prop-types';
import ProjectsRepository from './projectsRepository';
import PROJECT_TABS from '../../constants/tabNavigation';

const enhance = compose(
  getContext({
    setActiveTab: PropTypes.func,
  }),
  lifecycle({
    componentDidMount() {
      const { setActiveTab } = this.props;
      setActiveTab(PROJECT_TABS.REPOSITORY);
    },
    componentWillUnmount() {
      const { setActiveTab } = this.props;
      setActiveTab(PROJECT_TABS.TASKS);
    },
  }),
);
export default enhance(ProjectsRepository);
