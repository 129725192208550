import React from 'react';
import PropTypes from 'prop-types';

import { Label } from '../../../../../../../../ui-kit';

import './style.sass';

const LabelTimeMessage = ({ children, className }) => (
  <Label className={`label--time-message label--grey className ${className}`}>
    {children}
  </Label>
);

LabelTimeMessage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  className: PropTypes.string,
};

LabelTimeMessage.defaultProps = {
  children: '',
  className: '',
};

export default LabelTimeMessage;
