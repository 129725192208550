import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { prop } from 'ramda';

import './style.sass';
import { Favorite, BrillMask, Avatar } from '../../../../../../ui-kit';
import { getImageUrl } from '../../../../../../utils/helpers/requestHelpers';
import { IMAGES_SIZE } from '../../../../../../constants/ui';

const getImageUrlById = id => getImageUrl(prop('image', id), IMAGES_SIZE.sm);
const getTitleById = id => prop('title', id);

const ProjectItem = ({
  projects, id, customRef, onToggleFavoriteStatus,
}) => (
  <Link
    to={`${projects[id].id}/`}
    title={getTitleById(projects[id])}
    ref={e => customRef(e)}
    className="brill-projects__card project-brill"
  >
    <div className={`project-brill__icon-wrapper ${getImageUrlById(projects[id]) ? '' : 'project-brill--without-image'} `}>
      <BrillMask>
        <Avatar src={getImageUrlById(projects[id])} alt={getTitleById(projects[id])} />
      </BrillMask>
    </div>
    <h4 className="project-brill__title">
      <div className="text--cut">{getTitleById(projects[id])}</div>
    </h4>
    <div className="project-brill__deadline-alert">Deadline alert</div>
    <Favorite
      className="project-brill__favorite-button"
      status={prop('favorite', projects[id])}
      onClick={e => onToggleFavoriteStatus(e,
        prop('favorite', projects[id]),
        prop('id', projects[id]))}
    />
  </Link>
);
ProjectItem.propTypes = {
  projects: PropTypes.objectOf(PropTypes.object).isRequired,
  customRef: PropTypes.func.isRequired,
  onToggleFavoriteStatus: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};
export default ProjectItem;
