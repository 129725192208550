import { createActions } from 'redux-actions';

import { identity } from 'ramda';
import types from './types';

export const {
  setCompanyBillingInfo,
  getClientBillingInfoRequest,
  setClientBillingInfo,
  editClientBillingInfoRequest,
} = createActions(
  {
    [types.GET_CLIENT_BILLING_INFO_REQUEST]: [
      identity,
      ({ clientId, ...params }, meta) => ({
        async: true,
        route: `/billing-info/${clientId}`,
        selectorName: 'getClientBillingInfoRequest',
        method: 'GET',
        params,
        actions: {
          success: setClientBillingInfo,
        },
        ...meta,
      }),
    ],
    [types.EDIT_CLIENT_BILLING_INFO_REQUEST]: [
      identity,
      ({ clientId, ...params }, meta) => ({
        async: true,
        route: `/billing-info/${clientId}`,
        selectorName: 'editClientBillingInfoRequest',
        method: 'PUT',
        params,
        actions: {
          success: setClientBillingInfo,
        },
        ...meta,
      }),
    ],
  },
  types.SET_CLIENT_BILLING_INFO,
);
