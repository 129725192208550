import { compose, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { uiSelectors, uiActions } from '../../../../state/ui';
import ChannelsContainer from './channelsContainer';

const mapStateToProps = state => ({
  urlBeforeChannels: uiSelectors.getLastUrlBeforeChannels(state),
});

const mapDispatchToProps = {
  setAppTitle: uiActions.setAppTitle,
};

const onGoBackHandler = ({
  urlBeforeChannels, history,
}) => () => history.push(urlBeforeChannels);

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common']),
  withRouter,
  withHandlers({
    onGoBack: onGoBackHandler,
  }),
  lifecycle({
    componentDidMount() {
      this.props.setAppTitle('All channels - Messenger - Avanga 2.0');
    },
  }),
);
export default enhancer(ChannelsContainer);
