import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ErrorBoundary from '../../components/errorBoundary';

import { setGridOptions, clearGridOptions } from '../../state/ui/actions';
import { preloaderWhileLoading } from '../../utils/enchancers';


const DEFAULT_LODER_DIMETION = 100;

/**
 *
 * @param children {string | element}
 * @param className {string}
 * @param name {string}
 * @param pagination {element}
 * @param isKeepOptions {boolean}
 * @returns {JSX.Element}
 * @constructor
 */

const Wrapper = ({
  children, className, name, pagination, isKeepOptions,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const ErrorNotification = () => {
    throw t('Wrapper must have name');
  };

  useEffect(() => {
    dispatch(setGridOptions({
      grid: name,
    }));
    return () => {
      if (!isKeepOptions) {
        dispatch(clearGridOptions({ grid: name }));
      }
    };
  }, [name, isKeepOptions]);

  return <>
    <ErrorBoundary render={e => <div>{e}</div>}>
      <div className={className}>
        {!name && <ErrorNotification />}
        {children}
      </div>
    </ErrorBoundary>
    {pagination}
  </>;
};

Wrapper.defaultProps = {
  pagination: <> </>,
};

const enhance = compose(
  preloaderWhileLoading({
    dimension: DEFAULT_LODER_DIMETION,
    fullScreen: true,
    isLoading: props => props.isLoading,
  }),
);

export default enhance(Wrapper);
