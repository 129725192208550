import React from 'react';
import PropTypes from 'prop-types';
import { DropDown } from '../../../../ui-kit';
import './style.sass';

const Filters = ({
  assigners, assignees, onSelectAssignee, onSelectAssigner, selectedAssignee, selectedAssigner, t,
  sprintsStatuses, onSelectSprintsStatus, selectedSprintsStatus,
}) => (
  <div className="tasks-filter__sort-group__drop-downs">
    <DropDown
      label={`${t('Author')} : `}
      name="assigner"
      placeholder={t('All')}
      list={assigners}
      onReset={onSelectAssigner}
      currentItem={Number(selectedAssigner)}
      onChange={onSelectAssigner}
    />
    <DropDown
      label={`${t('Assignee')} : `}
      name="assignee"
      placeholder={t('All')}
      onReset={onSelectAssignee}
      currentItem={Number(selectedAssignee)}
      onChange={onSelectAssignee}
      list={assignees}
    />
    <DropDown
      label={`${t('Status of sprints')} : `}
      name="sprintsStatus"
      placeholder={t('All')}
      onReset={onSelectSprintsStatus}
      currentItem={Number(selectedSprintsStatus)}
      onChange={onSelectSprintsStatus}
      list={sprintsStatuses}
    />
  </div>
);

Filters.propTypes = {
  assigners: PropTypes.arrayOf(PropTypes.object),
  assignees: PropTypes.arrayOf(PropTypes.object),
  selectedAssignee: PropTypes.string,
  selectedAssigner: PropTypes.string,
  selectedSprintsStatus: PropTypes.string,
  sprintsStatuses: PropTypes.arrayOf(PropTypes.object),
  onSelectAssignee: PropTypes.func.isRequired,
  onSelectAssigner: PropTypes.func.isRequired,
  onSelectSprintsStatus: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Filters.defaultProps = {
  assigners: [],
  selectedAssignee: null,
  selectedAssigner: null,
  selectedSprintsStatus: null,
  sprintsStatuses: [],
  assignees: [],
};

export default Filters;
