import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { helpers } from '../../../../utils';
import { closeModal } from '../../../../state/ui/actions';
import CreateRepositoryModal from './createRepositoryModal';
import { getModal } from '../../../../state/ui/selectors';

const { translateHelpers } = helpers;

const mapStateToProps = state => ({
  isOpen: getModal(state),
});

const mapDispatchToProps = ({
  closeModal,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: () => ({
      title: '',
      url: '',
    }),
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: yup.object().shape({
      title: yup
        .string()
        .required({
          field: 'title',
          message: 'required',
          params: {
            key: translateHelpers.t('common', 'Title'),
          },
        }),
      url: yup
        .string(),
    }),
    handleSubmit: () => {},
  }),
);
export default enhance(CreateRepositoryModal);
