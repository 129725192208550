import React from 'react';
import PropTypes from 'prop-types';
import { identity } from 'ramda';

import './style.sass';
import { SendMessage } from '../../../../../chat/components';

const CommentsTextArea = ({
  isLeftSideBar,
  isRightSideBar,
  isAllSideBar,
  members,
  task,
  onSend,
}) => (
  <div
    className={
      `task-comments__message-text-area
        ${isLeftSideBar ? 'task-comments__message-text-area--leftSideBar-active ' : ''}
        ${isRightSideBar ? 'task-comments__message-text-area--rightSideBar-active ' : ''}
        ${isAllSideBar ? 'task-comments__message-text-area--allSideBar-active ' : ''}
        `
    }
  >
    <SendMessage
      onSubmit={onSend}
      members={members}
      className="message-textArea"
      keyField={`task-comments-${task.id}`}
      options={{ isFilesUploadingFunctionality: false }}
      onRenderContent={identity}
    />
  </div>
);

CommentsTextArea.propTypes = {
  members: PropTypes.instanceOf(Array).isRequired,
  task: PropTypes.instanceOf(Object).isRequired,
  onSend: PropTypes.func.isRequired,
  isLeftSideBar: PropTypes.bool.isRequired,
  isRightSideBar: PropTypes.bool.isRequired,
  isAllSideBar: PropTypes.bool.isRequired,
};

export default CommentsTextArea;
