import React from 'react';
import './style.sass';
import { Filters, CardsActivity } from './components';
import { ScrollWrapper } from '../../../ui-kit';
import { Provider } from './context/Provider';

/**
 *
 * @param onLoadStatistics{function}
 * @param onExportButtonClick{function}
 * @param view{string}
 * @returns {JSX.Element}
 * @constructor
 */

const Activity = ({
  onLoadStatistics, onExportButtonClick, view,
}) => (
  <div className="activity-container">
    <ScrollWrapper>
      <Provider>
        <Filters
          activeView={view}
          onLoadStatistics={onLoadStatistics}
          onExportButtonClick={onExportButtonClick}
        />
        <CardsActivity />
      </Provider>
    </ScrollWrapper>
  </div>
);

export default Activity;
