import {
  compose, getContext, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { withRouter } from 'react-router';
import { closeModal } from '../../../../../../state/ui/actions';
import { getModal } from '../../../../../../state/ui/selectors';
import { taskActions, taskSelectors } from '../../../../../../state/task';
import DeleteTimeEntry from './deleteTimeEntry';
import { usersSelectors } from '../../../../../../state/users';
import { getFullName } from '../../../../../../utils/helpers/userHelpers';

const mapStateToProps = (state, { selectedTimeEntryId }) => ({
  isOpen: getModal(state)('deleteTimeEntry'),
  users: usersSelectors.getUsersEntities(state),
  timeEntry: taskSelectors.getTimeEntry(state)(selectedTimeEntryId),
  // category: taskSelectors.getCategory(state)(selectedCategoryId),
  // isPending: projectSelectors.getCategoryDeleteRequestPending(state),
});

const mapDispatchToProps = ({
  onCloseModal: () => closeModal('deleteTimeEntry'),
  deleteTimeEntry: taskActions.deleteTimeEntryRequest,
});

const onDeleteHandler = ({
  deleteTimeEntry, selectedTimeEntryId, onCloseModal, clientId, match: { params: { id, taskId } },
}) => () => {
  deleteTimeEntry({
    timeEntryId: selectedTimeEntryId, taskId, clientId, projectId: id,
  }, {
    callbacks: {
      success: onCloseModal,
    },
  });
};

const enhance = compose(
  getContext({
    selectedTimeEntryId: PropTypes.number.isRequired,
    clientId: PropTypes.string,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withProps(({ users, timeEntry }) => ({
    userName: getFullName(users[timeEntry.user_id]),
  })),
  withTranslation(['common', 'tasks']),
  withHandlers({
    onDelete: onDeleteHandler,
  }),
);

export default enhance(DeleteTimeEntry);
