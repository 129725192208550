import {
  branch,
  compose, getContext, renderNothing, withHandlers,
} from 'recompose';
import { prop } from 'ramda';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import rules from '../rules';
import { uiActions, uiSelectors } from '../../../../../../../state/ui';
import { clientsActions, clientsSelectors } from '../../../../../../../state/clients';
import EditNote from '../../../../../../projectsNotes/components/notesCRUD/editNote/editNote';


const mapStateToProps = (state, { selectedNoteId }) => ({
  isOpen: uiSelectors.getModal(state)('editNoteModal'),
  note: clientsSelectors.getClientNoteSelector(state)(selectedNoteId),
  isPending: clientsSelectors.getClientActionPending(state)('editClientNotesRequest'),
});

const mapDispatchToProps = ({
  closeModal: () => uiActions.closeModal('editNoteModal'),
  editClientNoteRequest: clientsActions.editClientNoteRequest,
  getClientNotesRequest: clientsActions.getClientNotesRequest,
});

const formSubmit = (formValues, {
  resetForm,
  props: {
    closeModal,
    editClientNoteRequest,
    getClientNotesRequest,
    selectedNoteId: noteId,
    clientId: id,
    sortBy, order,
    setOffset,
  },
}) => {
  const data = { ...formValues, id, noteId };
  editClientNoteRequest(data, {
    callbacks: {
      success: () => {
        setOffset(10);
        getClientNotesRequest({ id, sortBy, order });
        closeModal();
        resetForm();
      },
    },
  });
};

const onCloseModalHandler = ({ resetForm, closeModal, note }) => () => {
  resetForm({
    title: note.title,
    content: note.content,
  });
  closeModal();
};

const enhance = compose(
  getContext({
    selectedNoteId: PropTypes.number.isRequired,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common', 'tasks']),
  branch(({ note }) => !prop('title', note), renderNothing),
  withFormik({
    mapPropsToValues: ({ note }) => ({
      title: note.title,
      content: note.content,
    }),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: formSubmit,
  }),
  withHandlers({
    onCloseModal: onCloseModalHandler,
  }),
);

export default enhance(EditNote);
