import { compose, withContext, withState } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pipelines from './pipelines';
import { uiActions } from '../../../../state/ui';

const mapDispatchToProps = {
  onCreatePipelineModal: () => uiActions.openModal('createPipelineModal'),
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  withState('selectedPipelineId', 'setSelectedPipelineId', null),
  withContext({
    setSelectedPipelineId: PropTypes.func,
    selectedPipelineId: PropTypes.any,
  }, ({ setSelectedPipelineId, selectedPipelineId }) => ({
    setSelectedPipelineId,
    selectedPipelineId,
  })),
  withTranslation(['common', 'chat']),
);
export default enhance(Pipelines);
