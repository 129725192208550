import React from 'react';
import PropTypes from 'prop-types';

import { ScrollWrapper } from '../../ui-kit';
import { InvoiceList, Filters, PipeView } from './components';
import { DeleteInvoiceModal } from './modals';

import './style.sass';
import { INVOICES_VIEW } from '../../constants/crm';
import SendInvoiceModal from './modals/sendInvoiceModal/sendInvoiceModal';

const InvoicesContainer = ({
  invoicesView, setInvoicesView, invoicesList, getUrlParam, openModals, invoiceToSend,
  invoicesCount, onDelete, onSend, selectedInvoiceId, queryParams, closeModal, isSending,
}) => (
  <ScrollWrapper className={invoicesView === INVOICES_VIEW.LIST ? 'invoices-list__scroll-wrapper' : ''}>
    <div className="invoices">
      <Filters
        invoicesView={invoicesView}
        setInvoicesView={setInvoicesView}
      />
      {
        invoicesView === INVOICES_VIEW.LIST ? (
          <InvoiceList
            invoicesList={invoicesList}
            invoicesCount={invoicesCount}
            getUrlParam={getUrlParam}
            queryParams={queryParams}
          />
        ) : (
          <PipeView />
        )
      }
    </div>
    <DeleteInvoiceModal onDelete={onDelete} selectedInvoiceId={selectedInvoiceId} />
    <SendInvoiceModal
      isOpen={openModals.sendInvoiceModal}
      onSend={onSend}
      onClose={() => closeModal('sendInvoiceModal')}
      invoice={invoiceToSend}
      isPending={isSending}
    />
  </ScrollWrapper>
);

InvoicesContainer.propTypes = {
  invoicesView: PropTypes.oneOf([INVOICES_VIEW.LIST, INVOICES_VIEW.PIPE]).isRequired,
  setInvoicesView: PropTypes.func.isRequired,
  invoicesList: PropTypes.arrayOf(PropTypes.number).isRequired,
  invoicesCount: PropTypes.number.isRequired,
  selectedInvoiceId: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  getUrlParam: PropTypes.func.isRequired,
  queryParams: PropTypes.instanceOf(Object).isRequired,
  openModals: PropTypes.instanceOf(Object).isRequired,
  closeModal: PropTypes.func.isRequired,
  invoiceToSend: PropTypes.instanceOf(Object).isRequired,
  isSending: PropTypes.bool.isRequired,
};

export default InvoicesContainer;
