import {
  compose, getContext, lifecycle, withHandlers, withProps, withState,
} from 'recompose';
import { connect } from 'react-redux';
import {
  replace, map, identity, ifElse, gt,
} from 'ramda';

import PropTypes from 'prop-types';
import CommentsTextArea from './commentsTextArea';
import { taskActions, taskSelectors } from '../../../../../../state/task';
import { uiSelectors } from '../../../../../../state/ui';
import { projectSelectors } from '../../../../../../state/project';
import { usersActions, usersSelectors } from '../../../../../../state/users';
import { convertMessageBeforeSubmit } from '../../../../../../utils/helpers/messengerHelpers/messages';

const mapDispatchToProps = {
  addCommentRequest: taskActions.addTaskCommentRequest,
  getUsersRequest: usersActions.getUsersListFetch,
};

const mapStateToProps = state => ({
  project: projectSelectors.default(state),
  task: taskSelectors.getActiveTask(state),
  isPending: taskSelectors.getAddTaskCommentRequestPending(state),
  isLeftSideBarActive: uiSelectors.getLeftSidebar(state),
  isRightSideBarActive: uiSelectors.getRightSidebar(state),
  projectMembers: projectSelectors.getProjectMembersList(state),
  members: map(usersSelectors.getUser(state), ifElse(gt(0), identity,
    () => usersSelectors.getUsersResult(state))(projectSelectors.getProjectMembersList(state))),
});

const onChangeHandler = ({ setFieldValue }) => ({ target: { value } }) => setFieldValue(value);

const onSendHandler = ({
  addCommentRequest, setFieldValue, task: { project_id, id }, onSendComment, clientId,
}) => (value) => {
  addCommentRequest({
    projectId: project_id,
    taskId: id,
    clientId,
    content: convertMessageBeforeSubmit(replace(/&gt;/g, '>', value)),
  }, {
    callbacks: {
      success: () => {
        setFieldValue('');
        onSendComment();
      },
    },
  });
};

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('value', 'setFieldValue', ''),
  withProps(({
    isLeftSideBarActive, isRightSideBarActive, members, projectMembers,
  }) => ({
    isLeftSideBar: isLeftSideBarActive,
    isRightSideBar: isRightSideBarActive,
    isAllSideBar: isLeftSideBarActive && isRightSideBarActive,
    members: projectMembers.length
      ? members.filter(item => projectMembers.includes(item.id))
      : members,
  })),
  getContext({
    clientId: PropTypes.string,
  }),
  withHandlers({
    onChange: onChangeHandler,
    onSend: onSendHandler,
  }),
  lifecycle({
    componentDidMount() {
      if (!this.props.projectMembers.length) {
        this.props.getUsersRequest();
        // todo: For get list of all users, Only fist 100 user in members dropdown
      }
    },
  }),
);
export default enhancer(CommentsTextArea);
