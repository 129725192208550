import {
  branch,
  compose, getContext, renderNothing,
} from 'recompose';
import { prop } from 'ramda';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { withRouter } from 'react-router';
import moment from 'moment';
import rules from '../rules';
import { uiActions, uiSelectors } from '../../../../../../state/ui';
import { taskActions, taskSelectors } from '../../../../../../state/task';
import EditTimeEntry from './editTimeEntry';
import {
  getSecondsFromTimeString,
  getTimeStringBySeconds,
} from '../../../../../../utils/helpers/dateHelpers';

const mapStateToProps = (state, { selectedTimeEntryId }) => ({
  isOpen: uiSelectors.getModal(state)('editTimeEntry'),
  timeEntry: taskSelectors.getTimeEntry(state)(selectedTimeEntryId),
});

const mapDispatchToProps = ({
  onCloseModal: () => uiActions.closeModal('editTimeEntry'),
  updateTimeEntry: taskActions.updateTimeEntryRequest,
});

const formSubmit = ({ date, time }, { resetForm, onCloseModal, ...props }) => {
  const {
    updateTimeEntry, selectedTimeEntryId, clientId, match: { params: { id, taskId } },
  } = props.props;
  const data = {
    date: moment(date).format('YYYY-MM-DD'),
    time: getSecondsFromTimeString(time),
    projectId: id,
    taskId,
    clientId,
    timeEntryId: selectedTimeEntryId,
  };
  updateTimeEntry(data, {
    callbacks: {
      success: props.props.onCloseModal,
    },
  });
};

const enhance = compose(
  getContext({
    selectedTimeEntryId: PropTypes.number.isRequired,
    clientId: PropTypes.string,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withTranslation(['common', 'tasks']),
  branch(({ timeEntry }) => !prop('time', timeEntry), renderNothing),
  withFormik({
    mapPropsToValues: ({ timeEntry }) => ({
      time: getTimeStringBySeconds(timeEntry.time),
      date: new Date(timeEntry.date),
    }),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: formSubmit,
  }),
);

export default enhance(EditTimeEntry);
