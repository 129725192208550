import React from 'react';
import { prop } from 'ramda';
import PropTypes from 'prop-types';

import { ScrollWrapper, TabWrapper } from '../../../../ui-kit';
import {
  AboutConversation,
  DescriptionChannel,
  NotificationPreferences,
  // SharedMedia,
  Members,
} from '..';
import { PinnedItems } from './components';
import { RightSideBar } from '../../../../newContainers';
import { checkAreMembersVisible } from '../../../../utils/helpers/messengerHelpers';
import SearchMessages from './components/searchMessages';

const InformationRightSideBar = ({
  channel,
  title,
  createdBy,
  isMessengerDetailsTabOpen,
  // isMessengerMediaTabOpen,
  // isMessengerPinnedItemsTabOpen,
  isMessengerNotificationTabOpen,
  isMessengerMembersTabOpen,
  changeMessengerDetailsTabStatus,
  // changeMessengerMediaTabStatus,
  // changeMessengerPinnedItemsTabStatus,
  changeMessengerNotificationTabStatus,
  changeMessengerMembersTabStatus,
  t,
}) => (
  <RightSideBar
    className="right-side-bar--messenger messenger__right-side-bar"
    title={title}
  >
    <ScrollWrapper className="right-side-bar__scroll-wrapper">
      <AboutConversation
        userId={prop('user_id', channel)}
      />
      <TabWrapper
        title={<h3 className="title">{t('Details')}</h3>}
        onChangeStatusTab={changeMessengerDetailsTabStatus}
        defaultStatusTab={isMessengerDetailsTabOpen}
      >
        <DescriptionChannel
          createdAt={prop('created_at', channel)}
          description={prop('description', channel)}
          createdBy={createdBy}
        />
      </TabWrapper>
      <TabWrapper
        title={(
          <h3 className="title">
            {t('Members')}
          </h3>
        )}
        onChangeStatusTab={changeMessengerMembersTabStatus}
        defaultStatusTab={isMessengerMembersTabOpen}
        visible={checkAreMembersVisible(prop('type', channel))}
      >
        <Members />
      </TabWrapper>
      <TabWrapper
        title={(
          <h3 className="title">

            Search messages
          </h3>
        )}
        defaultStatusTab={false}
      >
        <SearchMessages />
      </TabWrapper>
      <TabWrapper
        defaultStatusTab
        title={(
          <h3 className="title">
            {t('Pinned Items')}
          </h3>
        )}
      >
        <PinnedItems />
      </TabWrapper>
      <TabWrapper
        title={(
          <h3 className="title">
            {t('Notification preferences')}
          </h3>
        )}
        onChangeStatusTab={changeMessengerNotificationTabStatus}
        defaultStatusTab={isMessengerNotificationTabOpen}
      >
        <NotificationPreferences channel={channel} />
      </TabWrapper>
    </ScrollWrapper>
  </RightSideBar>
);

InformationRightSideBar.propTypes = {
  channel: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.number,
  }).isRequired,
  createdBy: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isMessengerDetailsTabOpen: PropTypes.bool.isRequired,
  // isMessengerMediaTabOpen: PropTypes.bool.isRequired,
  // isMessengerPinnedItemsTabOpen: PropTypes.bool.isRequired,
  isMessengerNotificationTabOpen: PropTypes.bool.isRequired,
  isMessengerMembersTabOpen: PropTypes.bool.isRequired,
  changeMessengerDetailsTabStatus: PropTypes.func.isRequired,
  // changeMessengerMediaTabStatus: PropTypes.func.isRequired,
  // changeMessengerPinnedItemsTabStatus: PropTypes.func.isRequired,
  changeMessengerNotificationTabStatus: PropTypes.func.isRequired,
  changeMessengerMembersTabStatus: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default InformationRightSideBar;
