import { compose } from 'recompose';
import { connect } from 'react-redux';
import { invoicesSelectors } from '../../../../../../state/invoices';
import ColumnBoard from './columnBoard';

const mapStateToProps = (state, { invoiceStatus }) => ({
  invoices: invoicesSelectors.getInvoicesByStatusId(state)(invoiceStatus.id),
});

const enhance = compose(
  connect(mapStateToProps),
);
export default enhance(ColumnBoard);
