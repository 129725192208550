import { handleActions } from 'redux-actions';

import {
  mergeIn,
} from '../../utils/helpers/ramdaStateHelpers';

import types from './types';

const reducer = handleActions({
  [types.SET_CATEGORIES_TASK_CRUD]: mergeIn(({
    payload:
    { data: { entities, result } },
  }, state) => ({
    entities: {
      ...state.entities,
      categories: { ...entities.categories },
    },
    result: {
      ...state.result,
      categories: result,
    },
  })),
  [types.SET_PROJECTS_TASK_CRUD]: mergeIn(({ payload: { data: { entities, result } } }, state) => ({
    entities: {
      ...state.entities,
      projects: { ...entities.projects },
    },
    result: {
      ...state.result,
      projects: result,
    },
  })),
  [types.SET_SPRINTS_TASK_CRUD]: mergeIn(({ payload: { data: { entities, result } } }, state) => ({
    entities: {
      ...state.entities,
      sprints: { ...entities.sprints },
    },
    result: {
      ...state.result,
      ...result,
    },
  })),
  [types.SET_STATUSES_TASK_CRUD]: mergeIn(({ payload: { data: { entities, result } } }, state) => ({
    entities: {
      ...state.entities,
      statuses: { ...entities.statuses },
    },
    result: {
      ...state.result,
      ...result,
    },
  })),
  [types.SET_PROJECT_TASK_CRUD]: mergeIn(
    ({ payload: { data } }) => ({ selectedProject: data }),
  ),
  [types.SET_SELECTED_PROJECT_TASK_CRUD]: mergeIn(
    ({ payload }) => ({ selectedProjectId: payload }),
  ),
}, {});

export default reducer;
