/* eslint-disable prefer-arrow-callback */
import {
  compose,
  cond,
  equals,
  head, identity,
  omit,
  path,
  prop,
  replace,
} from 'ramda';
import { push } from 'connected-react-router';
import { notEqual } from 'ramda-extension';
import { Either } from 'ramda-fantasy';

import { NimbleEmojiIndex } from 'emoji-mart';
import emojiData from 'emoji-mart/data/messenger';
import { uiActions, uiSelectors, uiTypes } from '../../../state/ui';
import { getGridsSelector } from '../../../state/ui/selectors';
import { userSelectors } from '../../../state/user';

import { TYPES_NOTIFICATION } from '../../../constants/ui';

import {
  debounceFunc, isConditionRight, isMobile, map,
} from '../../helpers/commonHelpers';
import {
  isUserFocusOnChat,
  pathToActiveChannelId,
} from '../../helpers/messengerHelpers/channelHelpers';
import { showMessageWhenUserNotAuthor } from '../../helpers/messengerHelpers/notifications';
import { getAvatarInBase64 } from '../../helpers/canvasHelpers';
import { notificationSound, regexRules } from '../../helpers/uiComponentHelpers/common';
import { truncate } from '../../helpers/stringHelpers/common';
import { reconnectSocketToServer } from '../../helpers/webSocket/state/actions';
import { messengerSelectors } from '../../../state/messenger';
import { subscribeOnEvent, unSubscribeFromEvent } from '../../helpers/DOMHelper/listeners';

const POP = 'POP';


const getNotificationType = path(['meta', 'type']);
const checkNotificationType = notificationType => compose(
  equals(notificationType),
  getNotificationType,
);

const getEmojiDataByString = (string) => {
  const emojiCustomIndex = new NimbleEmojiIndex(emojiData);
  return emojiCustomIndex.search(replace(/:/g, '', string)) || [];
};

const checkEmojiHandler = (value) => {
  const replaceEmojiStringToEmoji = compose(
    prop('native'),
    head,
    getEmojiDataByString,
  );
  return replace(
    regexRules.regFullEmojiString,
    replaceEmojiStringToEmoji,
    value,
  );
};

const callDefaultNotification = (title, options, onClick) => {
  const notification = new Notification(title, options);
  notification.addEventListener('show', () => {
    if (notification.permission !== 'denied'
      && !localStorage.getItem('isNotification')) notificationSound.play();
    localStorage.setItem('isNotification', true);
  });
  notification.addEventListener('close', () => {
    localStorage.removeItem('isNotification');
  });
  notification.addEventListener('click', onClick);
  setTimeout(notification.close.bind(notification), 9000);
};

const showNotification = ({ onClick = identity }) => (data) => {
  const defaultUserAvatar = getAvatarInBase64(data);
  const options = {
    ...data,
    body: compose(truncate(134), replace(regexRules.regAllHtml, ''), checkEmojiHandler)(data.body),
    icon: data.icon || defaultUserAvatar,
    tag: 'newMessage',
    silent: true,
    renotify: false,
  };
  const title = prop('title', data);
  if (isMobile() && navigator.serviceWorker.ready) {
    navigator.serviceWorker.ready.then((registration) => {
      if (registration.showNotification) {
        registration.showNotification(title, {
          ...options,
          silent: false,
          vibrate: [69, 120, 69, 120, 50, 50, 200],
          click_action: onClick,
        });
      } else {
        callDefaultNotification(title, options, onClick);
      }
    });
  } else {
    callDefaultNotification(title, options, onClick);
  }
};

const clearGridsMiddleware = ({ getState }) => next => (action) => {
  if (action.type === uiTypes.CLEAR_GRID_OPTIONS) {
    const gridsState = getGridsSelector(getState());
    const { payload: { grid } } = action;
    const grids = omit([grid], gridsState);
    next(uiActions.clearGridOptions({ grids }));
  } else {
    next(action);
  }
};

const showNotificationMiddleware = ({ getState, dispatch }) => next => (action) => {
  if (action.type === uiTypes.SHOW_NOTIFICATION) {
    const state = getState();
    const { id } = userSelectors.getUserData(state);
    const isWindowFocus = uiSelectors.getIsWindowFocus(state);
    const channel = pathToActiveChannelId(state);
    const redirectToChannel = (event) => {
      event.preventDefault();
      const isTargetChannelNotActive = isConditionRight(notEqual(channel));
      Either.either(() => window.focus(), (channelId) => {
        dispatch(push(`/messenger/${channelId}/`));
        window.focus();
      })(isTargetChannelNotActive(path(['payload', 'channel', 'id'], action)));
    };
    cond([
      [checkNotificationType(TYPES_NOTIFICATION.MESSAGE), compose(
        map(compose(
          showMessageWhenUserNotAuthor(id, showNotification({ onClick: redirectToChannel })),
          prop('payload'),
        )),
        isUserFocusOnChat(isWindowFocus, channel, path(['payload', 'message', 'channel_id'])),
      )],
    ])(action);
  }
  next(action);
};

const tryWSReconnection = (getState, dispatch, totalUnreadCount) => {
  const STEP_DURATION_TO_RECONNECT = 3000;
  const socketReconnectInterval = setInterval(() => {
    if (!uiSelectors.getIsWsError(getState())) {
      clearInterval(socketReconnectInterval);
    } else if (window.navigator.onLine) {
      dispatch(reconnectSocketToServer({
        isReconnect: true,
        totalUnreadCount, // todo: don`t use it now
      }));
    }
  }, STEP_DURATION_TO_RECONNECT);
};

const setWsErrorMiddleware = ({ getState, dispatch }) => next => (action) => {
  if (action.type === uiTypes.SET_WS_ERROR) {
    const totalUnreadCount = messengerSelectors.getTotalUnreadCount(getState());
    const isWsError = action.payload;
    const oldWsErrorValue = uiSelectors.getIsWsError(getState());
    const reconnectWatcher = debounceFunc(function reconnectToWs() {
      dispatch(reconnectSocketToServer({
        isReconnect: true,
        totalUnreadCount, // todo: don`t use it now
      }));
    }, 500);

    if (isWsError !== oldWsErrorValue && isWsError) {
      if (isWsError) {
        tryWSReconnection(getState, dispatch,
          totalUnreadCount);
        subscribeOnEvent(window, 'online', reconnectWatcher);
      } else {
        unSubscribeFromEvent(window, 'online', reconnectWatcher);
      }
    }
    next(action);
  }
  next(action);
};

const backButtonMiddleware = history => ({ getState, dispatch }) => next => (action) => {
  const { type, payload } = action;

  window.onload = () => dispatch(uiActions.setWindowContentLoaded());

  if (type === uiTypes.LOCATION_CHANGE) {
    const { router, ui: { isWindowContentLoaded } } = getState();
    if (payload.action === POP) {
      const isLocationWasChanged = router.location.pathname === payload.location.pathname;
      const isAllowedToGoBack = router.location.key && isWindowContentLoaded;
      if (isLocationWasChanged && isAllowedToGoBack) {
        history.goBack();
      }
    }
    next(action);
  } else {
    next(action);
  }
};

export default clearGridsMiddleware;
export {
  showNotificationMiddleware,
  setWsErrorMiddleware,
  showNotification,
  backButtonMiddleware,
};
