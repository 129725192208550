import React from 'react';
import PropTypes from 'prop-types';
import { ButtonsCollection, Avatar, BrillMask } from '../../../../../../ui-kit';
import { getFullName } from '../../../../../../utils/helpers/userHelpers';
import './style.sass';
import { getImageUrl } from '../../../../../../utils/helpers/requestHelpers';

const PinnedContactItem = ({
  pinnedContact, onUnpin, setSelectedPinnedContactId,
}) => (
  // eslint-disable-next-line max-len
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
  <li
    className="pinned-contacts__item"
    onClick={() => setSelectedPinnedContactId(pinnedContact.id)}
  >
    <BrillMask className="brill-mask">
      <Avatar src={getImageUrl(pinnedContact.avatar)} alt={getFullName(pinnedContact)} />
    </BrillMask>
    <div className="pinned-contacts__item-name">
      <div className="text--cut">
        {getFullName(pinnedContact)}
      </div>
    </div>
    {
      onUnpin && (
        <ButtonsCollection.ButtonIcons
          className="pinned-contacts__item-unpin"
          onClick={onUnpin}
          title="Unpin contact"
        >
          <span className="icon-close" />
        </ButtonsCollection.ButtonIcons>
      )
    }
  </li>
);

PinnedContactItem.propTypes = {
  pinnedContact: PropTypes.instanceOf(Object).isRequired,
  onUnpin: PropTypes.func.isRequired,
  setSelectedPinnedContactId: PropTypes.func.isRequired,
};

export default PinnedContactItem;
