import React, { memo } from 'react';

/**
 *
 * @param isActive {boolean}
 * @param innerText {string}
 * @param value {string}
 * @param onSelectOption {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const Option = memo(({
  innerText,
  value,
  isActive = false,
  onSelectOption,
}) => (
  <button
    type="button"
    className={`select-field__option
    ${isActive ? 'select-field__option--active' : ''}
  `}
    onClick={() => onSelectOption(value)}
  >
    <div className="select-field__option-inner-text text--cut">
      {innerText}
    </div>
  </button>
));
