import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { ButtonsCollection, PermissionElement } from '../../../../../../../ui-kit';
import { WINDOW_WIDTH } from '../../../../../../../constants/ui';
import { ACTIONS, MODULES } from '../../../../../../../constants/pemissions';
import { VIEW_TYPE } from '../../../../../../../constants/activity';
import { useWindowWidth } from '../../../../../../../utils/hooks';

/**
 *
 * @param activeView{string}
 * @param push{function}
 * @param onExportButtonClick{function}
 * @returns {JSX.Element}
 * @constructor
 */

export const FiltersNav = ({
  activeView = '',
  onExportButtonClick,
}) => {
  const history = useHistory();
  const pushNavHandler = (elem) => {
    history.push(elem);
  };
  const windowWidth = useWindowWidth();

  return (
    <PermissionElement module={MODULES.STATS} action={ACTIONS.READ}>
      <div className="activity-filters__group-change-view__row flex-row flex-wrap">
        {
          windowWidth > WINDOW_WIDTH.X_SMALL ? (
            <ButtonsCollection.ButtonBrill
              className={classNames('activity-filters__group-change-view__button', activeView === VIEW_TYPE.MY_STATISTICS && 'activity-filters__group-change-view__button--active')}
              onClick={() => pushNavHandler('/activity/me/')}
            >
              <span className="button-change-view__button-icon icon-author-user" />
              My statistics
            </ButtonsCollection.ButtonBrill>
          ) : (
            <ButtonsCollection.ButtonBrill
              className={classNames('activity-filters__group-change-view__button', activeView === VIEW_TYPE.MY_STATISTICS && 'activity-filters__group-change-view__button--active')}
              onClick={() => pushNavHandler('/activity/me/')}
            >
              <span className="button-change-view__button-icon icon-author-user" />
            </ButtonsCollection.ButtonBrill>
          )
        }
        {
          windowWidth > WINDOW_WIDTH.X_SMALL ? (
            <ButtonsCollection.ButtonBrill
              className={classNames('activity-filters__group-change-view__button', activeView === VIEW_TYPE.USERS && 'activity-filters__group-change-view__button--active')}
              onClick={() => pushNavHandler('/activity/users/')}
            >
              <span className="button-change-view__button-icon icon-user-icon-nav2">
                <span className="path1" />
                <span className="path2" />
              </span>
              Users
            </ButtonsCollection.ButtonBrill>
          ) : (
            <ButtonsCollection.ButtonBrill
              className={classNames('activity-filters__group-change-view__button', activeView === VIEW_TYPE.USERS && 'activity-filters__group-change-view__button--active')}
              onClick={() => pushNavHandler('/activity/users/')}
            >
              <span className="button-change-view__button-icon icon-user-icon-nav2">
                <span className="path1" />
                <span className="path2" />
              </span>
            </ButtonsCollection.ButtonBrill>
          )
        }
        {
          windowWidth > WINDOW_WIDTH.X_SMALL ? (
            <ButtonsCollection.ButtonBrill
              className={classNames('activity-filters__group-change-view__button', activeView === VIEW_TYPE.PROJECT && 'activity-filters__group-change-view__button--active')}
              onClick={() => pushNavHandler('/activity/projects/')}
            >
              <span className="button-change-view__button-icon icon-box-icon-2" />
              Project
            </ButtonsCollection.ButtonBrill>
          ) : (
            <ButtonsCollection.ButtonBrill
              className={classNames('activity-filters__group-change-view__button', activeView === VIEW_TYPE.PROJECT && 'activity-filters__group-change-view__button--active')}
              onClick={() => pushNavHandler('/activity/projects/')}
            >
              <span className="button-change-view__button-icon icon-box-icon-2" />
            </ButtonsCollection.ButtonBrill>
          )
        }
        {/* <button */}
        {/*  type="button" */}
        {/*  className="activity-filters__export-button__link" */}
        {/*  onClick={onExportButtonClick(activeView === VIEW_TYPE.MY_STATISTICS)} */}
        {/* > */}
        {/*  Export all data */}
        {/* </button> */}
      </div>
    </PermissionElement>
  );
};
