import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ACTIONS, MODULES } from '@/constants/pemissions';
import { ButtonsCollection, PermissionElement } from '@/ui-kit';
import { useKnowledgeBaseContext } from '@/views/knowledgeBase/context/useKnowledgeBaseContext';
import './style.sass'

export const CategoryItem = ({
 category,
 onOpenEditCategoryModal,
 onOpenDeleteCategoryModal,
 onOpenCreateSubcategoryModal,
 onOpenEditSubcategoryModal,
 onOpenDeleteSubcategoryModal,
}) => {
  const { t } = useTranslation('common');

  const { push } = useHistory();

  const { setCategoryId, setParentId } = useKnowledgeBaseContext();

  const [isOpenList, setIsOpenList] = useState(true);

  const onOpenCategoryItem = useCallback(() => {
    setIsOpenList(prevState => !prevState);
  }, []);

  const onCreateSubcategory = useCallback((e) => {
    e.stopPropagation();

    setParentId(category?.id);

    onOpenCreateSubcategoryModal();
  }, []);

  const onEditSubcategory = useCallback((e, subcategoryId) => {
    e.stopPropagation();

    setCategoryId(subcategoryId);

    onOpenEditSubcategoryModal();
  }, [onOpenEditSubcategoryModal, setCategoryId]);

  const onEditCategory = useCallback((e) => {
    e.stopPropagation();

    setCategoryId(category?.id);

    onOpenEditCategoryModal();
  }, [onOpenEditCategoryModal, setCategoryId]);

  const onDeleteCategory = useCallback((e) => {
    e.stopPropagation();

    setCategoryId(category?.id);

    onOpenDeleteCategoryModal();
  }, [onOpenDeleteCategoryModal, setCategoryId]);

  const onDeleteSubcategory = useCallback((e, id) => {
    e.stopPropagation();

    setCategoryId(id);

    onOpenDeleteSubcategoryModal();
  }, [onOpenDeleteSubcategoryModal, setCategoryId]);

  const onSubcategoryClick = useCallback((id) => {
    push(`/knowledge-base/subcategory/${id}`);
  }, [])

  return (
    <div
      className={classNames('category-item', isOpenList && 'category-item--open')}
    >
      <div className="category-item__info-header" onClick={() => onOpenCategoryItem()}>
        <ButtonsCollection.ButtonIcons
          className="category-item__info-header__toggle-button"
          title={`${!isOpenList ? t('Show more') : t('Show less')}`}
        >
          <span className="icon-expand-arrow-dn" />
        </ButtonsCollection.ButtonIcons>
        <h4 className="category-item__info-header__title">{category.description}</h4>
          <div className='category-item__info-header__controls'>
            <PermissionElement
              module={MODULES.KNOWLEDGE_BASE}
              action={ACTIONS.CREATE}
            >
              <div className='category-item__info-header__controls__create-button'>
                <ButtonsCollection.ButtonPlus
                  onClick={onCreateSubcategory}
                  title='Create subcategory'
                  className='category-item__info-header__controls__create-button__button-plus'
                />
              </div>
            </PermissionElement>
            <PermissionElement
              module={MODULES.KNOWLEDGE_BASE}
              action={ACTIONS.UPDATE}
            >
              <ButtonsCollection.ButtonIcons
                onClick={onEditCategory}
                title='Edit category'
                className='category-item__info-header__controls__edit-button'
              >
                <span className='icon-edit-icon' />
              </ButtonsCollection.ButtonIcons>
            </PermissionElement>
            <PermissionElement
              module={MODULES.KNOWLEDGE_BASE}
              action={ACTIONS.DELETE}
            >
              <ButtonsCollection.ButtonIcons
                onClick={onDeleteCategory}
                title='Delete category'
                className='category-item__info-header__controls__delete-button'
              >
                <span className='icon-trash' />
              </ButtonsCollection.ButtonIcons>
            </PermissionElement>
          </div>
      </div>
      <div className="category-item__subcategories-list">
        {category?.subCategories?.map((subcategory) => (
          <div
            key={`subcategory-item-${subcategory?.id}`}
            className="subcategory-item"
            onClick={() => onSubcategoryClick(subcategory?.id)}
          >
            <h4 className="subcategory-item__title">{subcategory?.description}</h4>
              <div className='subcategory-item__controls'>
                <PermissionElement
                  module={MODULES.KNOWLEDGE_BASE}
                  action={ACTIONS.CREATE}
                >
                  <ButtonsCollection.ButtonIcons
                    onClick={(e) => onEditSubcategory(e, subcategory.id)}
                    title='Edit subcategory'
                    className='subcategory-item__controls__edit-button'
                  >
                    <span className='icon-edit-icon' />
                  </ButtonsCollection.ButtonIcons>
                </PermissionElement>
                <PermissionElement
                  module={MODULES.KNOWLEDGE_BASE}
                  action={ACTIONS.DELETE}
                >
                  <ButtonsCollection.ButtonIcons
                    onClick={(e) => onDeleteSubcategory(e, subcategory.id)}
                    title='Delete subcategory'
                    className='subcategory-item__controls__delete-button'
                  >
                    <span className='icon-trash' />
                  </ButtonsCollection.ButtonIcons>
                </PermissionElement>
              </div>
          </div>
        ))}
      </div>
    </div>
  )
}
