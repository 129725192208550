import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, BrillMask, OnlineStatusLabel } from '../../../../../ui-kit';
import { BRILL_NAME } from '../constants';
import { getImageUrl } from '../../../../../utils/helpers/requestHelpers';
import { IMAGES_SIZE } from '../../../../../constants/ui';
import { getFullName } from '../../../../../utils/helpers/userHelpers';
import { usersSelectors } from '../../../../../state/users';
import { uiActions } from '../../../../../state/ui';

/**
 * @param id {number}
 * @param customRef {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const CoWorkerItem = ({
  id,
  customRef,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(state => usersSelectors.getUser(state)(id));
  const userAvatar = user.avatar ? getImageUrl(user.avatar, IMAGES_SIZE.sm) : '';
  const userName = getFullName(user);
  const changeRightSidebarStatus = useCallback((value) => {
    dispatch(uiActions.changeRightSidebarStatus(value));
  });
  const setUserProfile = useCallback((userId) => {
    dispatch(uiActions.getUserProfileRequest({ userId }));
  });

  const onOpenProfile = useCallback(() => {
    if (user.id) {
      changeRightSidebarStatus(true);
      setUserProfile(user.id);
    }
  });

  return (
    <li
      ref={e => customRef(`${BRILL_NAME}${id}`, e)}
      className="co-workers__item"
    >
      <BrillMask
        asLink
        onClick={onOpenProfile}
        outsideChildren={
          user?.is_online ? (
            <OnlineStatusLabel
              className="co-workers__online-label"
              status={user.is_online}
            />
          ) : <></>
        }
      >
        <Avatar
          src={userAvatar}
          classNameImg="co-workers__img"
          alt={userName}
        />
      </BrillMask>
    </li>
  );
};
