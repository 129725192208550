import {
  compose, cond, curry, path, pathEq, prop,
} from 'ramda';

import { Tuple } from 'ramda-fantasy';
import { map, renameKeys } from '../commonHelpers';
import {
  checkCurrentUserNotAuthor,
  contentNotificationForGroup,
  checkIfShowMentionNotification,
  getMentionIds,
  setContent,
  tupleWithMessageAndContent, contentNotificationForDirect,
} from './messages';
import { getAvatar, setAvatar } from '../userHelpers';
import {
  isChannelCommon,
  isChannelDirect,
  pathToChannelType,
} from './channelHelpers';

const createMessageNotification = compose(
  renameKeys({ avatar: 'icon', content: 'body' }),
  setContent(prop('content')),
  setAvatar(getAvatar),
);

const setupMessageNotification = curry(showNotification => compose(
  showNotification,
  createMessageNotification,
));

const mentionWhenReply = curry((message, userId, idList) => {
  const isReplyToMe = compose(pathEq(['message', 'parent', 'created_by'], userId))(message);

  return isReplyToMe ? idList.map((val = []) => val.concat(Tuple(userId, ''))) : idList;
});

const channelNotification = (userId, notification) => message => cond([
  [isChannelCommon(pathToChannelType), compose(
    map(compose(
      setupMessageNotification(notification),
      contentNotificationForGroup,
    )),
    checkIfShowMentionNotification(userId),
    mentionWhenReply(message, userId),
    getMentionIds,
    tupleWithMessageAndContent,
  )],
  [isChannelDirect(pathToChannelType), compose(
    setupMessageNotification(notification),
    contentNotificationForDirect,
  )],
])(message);

const showMessageWhenUserNotAuthor = (userId, notification) => compose(
  map(channelNotification(userId, notification)),
  checkCurrentUserNotAuthor(userId, path(['message', 'created_by'])),
);

export {
  createMessageNotification, setupMessageNotification, showMessageWhenUserNotAuthor,
};
