import * as R from 'ramda';

const toUpperCase = R.compose(
  R.join(''),
  R.juxt([R.compose(R.toUpper, R.head), R.tail]),
);

const transformErrorResponse = (errors) => {
  const transformMessage = (values, key) => ({
    message: values.message,
    params: {
      ...values.params,
      key: toUpperCase(key),
    },
  });
  return R.mapObjIndexed(transformMessage, errors);
};

const getErrors = (errors) => {
  const getDataErrors = R.pathOr(false, ['response', 'data', 'result', 'errors'], errors);
  return getDataErrors ? transformErrorResponse(getDataErrors) : R.path(['response', 'data', 'result'], errors);
};


export {
  // eslint-disable-next-line import/prefer-default-export
  getErrors,
};
