import reducers from './reducers';

import * as pendingOperations from './operations';
import pendingTypes from './types';
import * as pendingActions from './actions';
import * as pendingSelectors from './selectors';

export {
  pendingOperations,
  pendingTypes,
  pendingActions,
  pendingSelectors,
};

export default reducers;
