import { compose } from 'recompose';

import Pagination from './Pagination';
import { withUrlParams } from '../../utils/enchancers';


const enhance = compose(
  withUrlParams({}),
);

export default enhance(Pagination);
