import { Draggable, Droppable } from 'react-beautiful-dnd';
import React from 'react';
import PropTypes from 'prop-types';

import { TaskCard } from '..';
import { ScrollWrapper } from '../../../../ui-kit';

const getStyleForDroppable = (isDragging, updateDroppable, droppableId, snapshot) => {
  if (isDragging && updateDroppable === droppableId) {
    return 'column-board__body--update';
  }
  if (isDragging && !snapshot.draggingFromThisWith) {
    return 'column-board__body--active';
  }
  return '';
};

const DroppableArea = ({
  statusId, isDragging, updateDroppable, tasks,
}) => (
  <Droppable droppableId={statusId}>
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        className="column-board__drag"
        style={{
          backgroundColor: provided.isDragging ? 'green' : 'lightblue',
        }}
        {...provided.droppableProps}
      >
        <ScrollWrapper className="agenda-board__scroll-wrapper-column">
          <div className={`column-board__body ${getStyleForDroppable(isDragging, updateDroppable, statusId, snapshot)}`}>
            {tasks.map((task, index) => (
              <Draggable
                key={`project-task-key ${task}`}
                index={index}
                draggableId={task}
              >
                {(providedDrag, draggableSnapshot) => (
                  <div
                    className="project-task__drag-wrapper"
                    ref={providedDrag.innerRef}
                    {...providedDrag.draggableProps}
                    {...providedDrag.dragHandleProps}
                  >
                    <TaskCard
                      id={task}
                      statusId={statusId}
                      isDragging={draggableSnapshot.isDragging}
                      key={`task ${task}`}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        </ScrollWrapper>
      </div>
    )}
  </Droppable>
);

DroppableArea.propTypes = {
  statusId: PropTypes.number.isRequired,
  isDragging: PropTypes.bool.isRequired,
  updateDroppable: PropTypes.number,
  tasks: PropTypes.arrayOf(PropTypes.number),
};

DroppableArea.defaultProps = {
  updateDroppable: null,
  tasks: null,
};

export default DroppableArea;
