import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import { ButtonsCollection, ModalWindow, FormsCollection } from '../../../../ui-kit';
import './style.sass';

const CreateRepositoryModal = ({
  isOpen, closeModal, handleChange, handleBlur, values, errors, touched, handleSubmit,
}) => (
  <ModalWindow
    isOpen={isOpen('createRepositoryModal')}
    onClose={() => closeModal('createRepositoryModal')}
    modalName="createRepository"
    title="Creating new repository"
    className="modal-window__create-repository"
  >
    <FormsCollection.FormWrapper
      className="create-repository"
      handleSubmit={handleSubmit}
    >
      <div className="create-repository__row">
        <FormsCollection.Input
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.title}
          error={propOr('', 'message', errors.title)}
          touched={touched.title}
          name="title"
          placeholderJump="Repository title"
          className="create-repository__note-title"
          id="create-repository__title"
        />
      </div>
      <div className="create-repository__row create-repository__row-url">
        <FormsCollection.TextArea
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.url}
          error={propOr('', 'message', errors.url)}
          touched={touched.description}
          placeholderJump="Repository URL"
          name="url"
          autoHeight
          maxHeight={190}
          tabIndex={0}
        >
        </FormsCollection.TextArea>
      </div>
      <div className="create-repository__button-group">
        <ButtonsCollection.ButtonBrill
          className="button--md create-repository__button-save button-brill--fill"
          type="submit"
        >

          Create
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);

CreateRepositoryModal.propTypes = {
  isOpen: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

CreateRepositoryModal.defaultProps = {
  handleChange: () => {},
  handleBlur: () => {},
};

export default CreateRepositoryModal;
