import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  Grid, ButtonsCollection, Avatar, BrillMask,
} from '../../../../../ui-kit';
import { getImageUrl } from '../../../../../utils/helpers/requestHelpers';
import { PEOPLE_PAGES } from '../../api/routes';

/**
 *
 * @param id {number}
 * @param username {string}
 * @param email {string}
 * @param vacation {number}
 * @param onDeleteUser {function}
 * @param avatar {string}
 * @param first_name {string}
 * @param last_name {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const PeopleBodyRow = ({
  id, username, email, vacation, onDeleteUser = () => {}, avatar, firstName, lastName,
}) => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const fullName = useMemo(() => `${firstName} ${lastName}`, [firstName, lastName]);
  const onDeleteUserHandler = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onDeleteUser({
        id, username, email, first_name: firstName, last_name: lastName,
      });
    },
    [id, username, firstName, lastName],
  );
  return (
    <Grid.Row
      key={`project-grid  - ${id}`}
      className="table-users__rowUsr"
      onClick={() => history.push(PEOPLE_PAGES.PERSON.BASE + id)}
    >
      <Grid.ColData className="table-users__col">{id}</Grid.ColData>
      <Grid.ColData className="table-users__col--avatar">
        <BrillMask>
          <Avatar
            src={getImageUrl(avatar)}
            alt={fullName}
          />
        </BrillMask>
      </Grid.ColData>
      <Grid.ColData className="table-users__col table-users__col--name">
        <div className="text--cut">{fullName}</div>
      </Grid.ColData>
      <Grid.ColData className="table-users__col table-users__col--username">
        <div className="text--cut">{username}</div>
      </Grid.ColData>
      <Grid.ColData className="table-users__col table-users__col--email">
        <div className="text--cut p-xs text--breaker">
          {email}
        </div>
      </Grid.ColData>
      <Grid.ColData className="table-users__col table-users__col--name">
        <div className="text--cut">{vacation}</div>
      </Grid.ColData>
      <Grid.ColData className="table-users__col table-users__col--controls">
        <ButtonsCollection.ButtonIcons
          onClick={() => history.push(PEOPLE_PAGES.PERSON.BASE + id)}
          title={t('Edit user')}
        >
          <span className="icon-edit-icon" />
        </ButtonsCollection.ButtonIcons>
        <ButtonsCollection.ButtonIcons
          onClick={onDeleteUserHandler}
          title={t('Delete user')}
        >
          <span className="icon-trash" />
        </ButtonsCollection.ButtonIcons>
      </Grid.ColData>
    </Grid.Row>
  );
};
