import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { compose } from 'recompose';
import { useLocation, withRouter } from 'react-router';
import { PageError } from '../../../views';
import {
  ForgotPassword,
  ForgotPasswordSuccess,
  ChangePassword,
  JoinYourTeam,
  SignIn,
} from '../../../views/auth';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

const Routes = () => {
  const location = useLocation();
  return (
    <Switch>
      <Route
        exact
        strict
        path="/:url*"
        render={() => location.pathname.slice(-1) !== '/' && <Redirect to={`${location.pathname}/`} />}
      />
      <Route path="/forgot-password/" component={ForgotPassword} />
      <Route path="/forgot-password-success" component={ForgotPasswordSuccess} />
      <Route path="/reset-password/:userId/:code" component={ChangePassword} />
      <Route path="/join-your-team/:userId/:code" component={JoinYourTeam} />
      <Route exact path="/" component={SignIn} />
      <Route component={PageError} />
    </Switch>
  );
};

const enhance = compose(
  withRouter,
);

export default enhance(Routes);
