import {
  branch, compose, renderNothing, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { propOr } from 'ramda';

import { getUser } from '../../../../state/users/selectors';
import AboutConversation from './aboutConversation';
import { userHelpers, requestHelpers } from '../../../../utils/helpers';
import { IMAGES_SIZE } from '../../../../constants/ui';

const mapStateToProps = (state, { userId }) => ({
  user: getUser(state)(userId),
});

const enhance = compose(
  branch(({ userId }) => !userId,
    renderNothing),
  connect(mapStateToProps),
  withProps(({ user }) => ({
    avatar: requestHelpers.getImageUrl(user.avatar, IMAGES_SIZE.sm),
    fullName: userHelpers.getFullName(user),
    nickName: user.username,
    onlineStatus: Boolean(propOr(0, 'is_online', user)),
  })),
);
export default enhance(AboutConversation);
