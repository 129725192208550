import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection } from '../../../../../ui-kit';

import './style.sass';

const CategoriesItem = ({
  category, onEdit, onDelete, t, onClick, isSelected,
}) => (
  <button
    type="button"
    onClick={onClick}
    key={category.id}
    className={`
  category-nav__item
  ${isSelected ? 'category-nav__item--active' : ''}
  `}
  >
    <span className="category-nav__item-title">{category.title}</span>
    <ButtonsCollection.ButtonIcons
      className="category-nav__item-button"
      onClick={onEdit}
      title={`${t('Edit category')} ${category.title}`}
    >
      <span className="icon-edit-icon" />
    </ButtonsCollection.ButtonIcons>
    <ButtonsCollection.ButtonIcons
      className="category-nav__item-button category-nav__item-button-delete"
      onClick={onDelete}
      title={`${t('Delete category')} ${category.title}`}
    >
      <span className="icon-trash" />
    </ButtonsCollection.ButtonIcons>
  </button>
);

CategoriesItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  category: PropTypes.instanceOf(Object).isRequired,
  onEdit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default CategoriesItem;
