import React, { memo } from 'react';
import classNames from 'classnames';
import { BrillWithIndex } from '..';
import { BLACK } from '../../constants/colors';


/**
 *
 * @type {React.NamedExoticComponent<{readonly count?: number, readonly colorBorder?: string, readonly title?: string, readonly className?: string}>}
 */

export const SummaryCard = memo(({
  count, colorBorder = BLACK, title, className = '',
}) => (
  <div className={classNames('summary-card__item', className)}>
    <BrillWithIndex colorBorder={colorBorder}>{count}</BrillWithIndex>
    <h4 className="summary-card__title">{title}</h4>
  </div>
));
