import {
  compose, withContext, withHandlers, withState, withStateHandlers,
} from 'recompose';
import PropTypes from 'prop-types';
import Comments from './comments';

const setContentMessage = () => e => ({ contentMessage: e.target.value });

const onSendCommentHandler = ({ scrollElement }) => () => {
  setTimeout(() => scrollElement.scrollToBottom(scrollElement.getValues().scrollHeight));
};

const onScrollSetRefHandler = ({ setRef }) => e => setRef('commentsScroll', e);

const enhancer = compose(
  withStateHandlers(() => ({
    contentMessage: '',
  }), {
    setContentValueStateHandler: setContentMessage,
  }),
  withState('selectedCommentId', 'setSelectedCommentId', null),
  withContext({
    setSelectedCommentId: PropTypes.func.isRequired,
  }, ({ setSelectedCommentId }) => ({
    setSelectedCommentId,
  })),
  withHandlers({
    onScrollSetRef: onScrollSetRefHandler,
    onSendComment: onSendCommentHandler,
  }),
);
export default enhancer(Comments);
