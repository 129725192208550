import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Header } from '../../../../ui-kit';
import { SettingsBillingInfoContainer } from './settingsBillingInfoContainer';
import './style.sass';
import { APP_ROUTES } from '../../../../constants/appRoutes';

/**
 * @returns {JSX.Element}
 * @constructor
 */

export const BillingInfoWrapper = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header hideToggleRightSideBar className="role-header">
        <>
          <h1 className="weight--medium title--xl title-page user-nav__title">
            {t('Settings - Billing info')}
          </h1>
          <nav className="header-nav header-nav--user">
            <NavLink
              activeClassName="header-nav__link"
              className="header-nav__link"
              to={APP_ROUTES.PREFERENCES_USERS}
            >
              {t('Users')}
            </NavLink>
            <NavLink
              activeClassName="header-nav__link"
              className="header-nav__link"
              to={APP_ROUTES.PREFERENCES_ROLES}
            >
              {t('Roles')}
            </NavLink>
            <NavLink
              activeClassName="header-nav__link--active"
              className="header-nav__link"
              to="/preferences/billing-info"
            >
              {t('Billing info')}
            </NavLink>
          </nav>
        </>
      </Header>
      <div className="main-container__content main-container__content--roles">
        <SettingsBillingInfoContainer />
      </div>
    </>
  );
};
