import React from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import {
  Avatar, BrillMask, ButtonsCollection, ShowMoreItems, DropDown,
} from '../../../../ui-kit';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import { IMAGES_SIZE, WINDOW_WIDTH } from '../../../../constants/ui';
import { STATUS } from '../../../../constants/tasks';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { formatDate } from '../../../../utils/helpers/commonHelpers';
import LeadTasksMobile from './leadTasksMobile';


const LeadTasks = ({
  t,
  onCreateTaskModal,
  onDeleteTaskModal,
  onEditTaskModal,
  onEditFullTaskModal,
  leadTasks,
  onSetActiveTaskId,
  onClickTask,
  onChangeTaskStatus,
  onEditFullTask,
  windowWidth,
}) => (
  <div className="leadToDoList">
    <div className="leadToDoList__filter">
      <h6 className="leadToDoList__filter-title">To Do List</h6>
      <ButtonsCollection.ButtonPlus className="leadToDoList__add-item" onClick={onCreateTaskModal} />
    </div>
    {leadTasks && leadTasks.length ? (
      <ul className="leadToDoList__list">
        { windowWidth > WINDOW_WIDTH.SMALL ? (
          <ShowMoreItems
            className="client-tasks__wrapper"
            count={4}
            items={
              leadTasks.map(task => (
                <li
                  className="leadToDoList__item"
                >
                  <div
                    role="button"
                    tabIndex="-1"
                    className={
                      `leadToDoList__complete-status ${task.status_id === STATUS.DONE.id ? 'leadToDoList__complete-status--done' : ''}`
                    }
                    onKeyDown={onChangeTaskStatus(task)}
                    onClick={onChangeTaskStatus(task)}
                    title={task.status_id === STATUS.DONE.id ? t('Mark as To do') : t('Mark as Done')}
                  />
                  <div
                    className="leadToDoList__title text--cut"
                    role="button"
                    tabIndex="-1"
                    onKeyDown={onClickTask(task)}
                    onClick={onClickTask(task)}
                  >
                    {task.title}
                  </div>
                  {
                    task.assigneeUser && (
                      <div className="leadToDoList__author">
                        <BrillMask>
                          <Avatar src={getImageUrl(pathOr(null, ['assigneeUser', 'avatar'], task), IMAGES_SIZE.xs)} alt={getFullName(task.assigneeUser)} />
                        </BrillMask>
                        <span className="leadToDoList__name">{getFullName(task.assigneeUser)}</span>
                      </div>
                    )
                  }
                  {
                    task.deadline && (
                      <div className="leadToDoList__deadline">
                        {formatDate(task.deadline)}
                      </div>
                    )
                  }
                  <DropDown
                    className="controls-group-task"
                    name="controls-group-task"
                    label={(
                      <ButtonsCollection.ButtonIcons
                        className="project-task__controls-button-task"
                        title="Controls for sprint"
                      >
                        <span className="project-task__control-icon icon-settings-vertical">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                        </span>
                      </ButtonsCollection.ButtonIcons>
                    )}
                    list={
                      [
                        {
                          id: 0,
                          onClick: onSetActiveTaskId(task.id, onEditTaskModal),
                          label: (
                            <>
                              <span className="controls-group-task__icon icon-pencil-2" />
                              <span className="controls-group-task__text">{t('Edit')}</span>
                            </>
                          ),
                        },
                        {
                          id: 0,
                          onClick: onEditFullTask(task, onEditFullTaskModal),
                          label: (
                            <>
                              <span className="controls-group-task__icon icon-pencil-2" />
                              <span className="controls-group-task__text">{t('Edit full task')}</span>
                            </>
                          ),
                        },
                        {
                          id: 1,
                          onClick: onSetActiveTaskId(task.id, onDeleteTaskModal),
                          label: (
                            <>
                              <span className="controls-group-task__icon icon-delete-icon" />
                              <span className="controls-group-task__text">{t('Delete')}</span>
                            </>
                          ),
                        },
                      ]
                    }
                  />
                </li>
              ))
            }
          />
        ) : (
          <LeadTasksMobile
            onSetActiveTaskId={onSetActiveTaskId}
            onEditFullTaskModal={onEditFullTaskModal}
            t={t}
            onClickTask={onClickTask}
            onDeleteTaskModal={onDeleteTaskModal}
            leadTasks={leadTasks}
            onEditFullTask={onEditFullTask}
            onChangeTaskStatus={onChangeTaskStatus}
            onEditTaskModal={onEditTaskModal}
            onCreateTaskModal={onCreateTaskModal}
          />
        )}
      </ul>
    ) : <div className="client-tasks__empty">There are no tasks yet</div> }
  </div>
);

LeadTasks.propTypes = {
  t: PropTypes.func.isRequired,
  leadTasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCreateTaskModal: PropTypes.func.isRequired,
  onDeleteTaskModal: PropTypes.func.isRequired,
  onEditTaskModal: PropTypes.func.isRequired,
  onEditFullTaskModal: PropTypes.func.isRequired,
  onSetActiveTaskId: PropTypes.func.isRequired,
  onClickTask: PropTypes.func.isRequired,
  onChangeTaskStatus: PropTypes.func.isRequired,
  onEditFullTask: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

export default LeadTasks;
