import { bpAutocompleteActions } from '@bp/store/bpAutoComplete';

const { getBpProjectsRequest, getBpUsersRequest, getBpChannelsRequest, getBpRolesRequest } =
  bpAutocompleteActions;

export const FIELD_TYPES = {
  TEXT: 'TEXT',
  TEXTAREA: 'TEXTAREA',
  ESTIMATE: 'ESTIMATE',
  RADIO: 'RADIO',
  AUTOCOMPLETE: 'AUTOCOMPLETE',
  MULTI_AUTOCOMPLETE: 'MULTI_AUTOCOMPLETE',
  DATE_PICKER: 'DATE_PICKER',
  TIME_PICKER: 'TIME_PICKER',
  SELECT: 'SELECT',
};

export const FIELD_TYPES_KEYS = {
  [FIELD_TYPES.TEXT]: 1,
  [FIELD_TYPES.SELECT]: 2,
  [FIELD_TYPES.TEXTAREA]: 6,
  [FIELD_TYPES.RADIO]: 5,
  [FIELD_TYPES.AUTOCOMPLETE]: 7,
  [FIELD_TYPES.MULTI_AUTOCOMPLETE]: 8,
  [FIELD_TYPES.DATE_PICKER]: 9,
  [FIELD_TYPES.TIME_PICKER]: 10,
  [FIELD_TYPES.ESTIMATE]: 11,
};

const getUserAction = {
  action: getBpUsersRequest,
  callback: (data) =>
    data.users.map(({ value, label }) => ({
      value,
      label,
    })),
};

const defaultCallback = ({ data }) =>
  data.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

export const AUTO_COMPLETE_ACTIONS = {
  projectName: {
    action: getBpProjectsRequest,
    callback: defaultCallback,
  },
  roleId: {
    action: getBpRolesRequest,
    callback: defaultCallback,
  },
  projectId: {
    action: getBpProjectsRequest,
    callback: defaultCallback,
  },
  channelId: {
    action: getBpChannelsRequest,
    callback: defaultCallback,
  },
  watchers: getUserAction,
  watchersByRole: getUserAction,
  assignee: getUserAction,
  assigneeByRole: {
    action: getBpRolesRequest,
    callback: defaultCallback,
  },
  members: getUserAction,
};

export const getAutoCompleteAction = (name) => AUTO_COMPLETE_ACTIONS[name];
