import React from 'react';
import PropTypes from 'prop-types';
import { RightSideBar } from '../../../../../../newContainers';
import { ScrollWrapper, TabWrapper } from '../../../../../../ui-kit';
import LeadSummary from '../leadSummary';
import PinnedLeads from '../pinnedLeads';
import { TabWrapperMobile } from '../../../../../../newContainers/rightSideBar/components';
import './style.sass';

const RightSideBarLeadsContainer = ({
  t, setActiveTabTitle, activeTabTitle,
}) => (
  <>
    <RightSideBar className="right-side-bar--projects" title={t('Leads summary')}>
      <ScrollWrapper className="right-side-bar__scroll-wrapper">
        <LeadSummary />
        <TabWrapper
          title={<h2 className="title">Pinned leads</h2>}
        >
          <PinnedLeads />
        </TabWrapper>
      </ScrollWrapper>
      <ScrollWrapper className="right-side-bar__scroll-wrapper">
        <LeadSummary />
        <TabWrapperMobile
          setActiveTitle={setActiveTabTitle}
          title="Pinned leads"
          active={activeTabTitle}
        >
          <PinnedLeads />
        </TabWrapperMobile>
      </ScrollWrapper>
    </RightSideBar>
  </>
);

RightSideBarLeadsContainer.propTypes = {
  windowWidth: PropTypes.number.isRequired,
  activeTabTitle: PropTypes.string,
  t: PropTypes.func.isRequired,
  setActiveTabTitle: PropTypes.func.isRequired,
};
RightSideBarLeadsContainer.defaultProps = {
  activeTabTitle: '',
};

export default RightSideBarLeadsContainer;
