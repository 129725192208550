import { createSelector } from 'reselect';
import {
  pathOr, identity, memoizeWith,
} from 'ramda';

export const getCustomFieldsList = createSelector(pathOr([], ['customFields', 'result']), identity);
export const getCustomFieldsEntities = createSelector(pathOr({}, ['customFields', 'entities']), identity);

export const getCustomField = createSelector(
  state => memoizeWith(identity, fieldId => pathOr({}, ['customFields', 'entities', fieldId], state)), identity,
);
export const getCustomFieldsByModel = createSelector(
  state => memoizeWith(identity, (customFieldsList, modelName) => customFieldsList.filter(id => pathOr(null, ['customFields', 'entities', id, 'model_name'], state) === modelName)), identity,
);
