import {
  compose, pure, withState, lifecycle, withHandlers,
} from 'recompose';

import {
  cond, identity, tap, length, lt,
} from 'ramda';

import { Tuple, Either } from 'ramda-fantasy';

import { emojiIndex } from 'emoji-mart';
import withRefs from '../../../utils/enchancers/withRefs';
import { EmojiPicker } from './EmojiPicker';
import { KEYS } from '../../../constants/ui';
import {
  changeCategory,
  getOffsetTop, getRefActiveEmoji, getSelectedCategoryAndDec,
  isStartEmoji, moveToNextEmojiRow, moveToPrevEmojiRow, setNextEmojiActive,
  setPrevActiveEmoji, tupleCategory, tupleEmoji,
} from '../../../utils/helpers/uiComponentHelpers/emojiHelpers';
import { callWithPreventEvent } from '../../../utils/helpers/uiComponentHelpers/DOMhelpers';

const onClickOutsideHandler = ({ onClose, getRef }) => (e) => {
  const pickerElement = getRef('emojiPicker');
  if (pickerElement && !pickerElement.contains(e.target)) {
    onClose(e);
  }
};

const onShortKeysHandler = ({
  setSelectedEmoji, selectedEmoji, data, setSelectedCategory, selectedCategory,
  isOpen, getRef, setScrollOffset,
}) => (e) => {
  if (isOpen) {
    const emojiLength = data[selectedCategory].emojis.length;
    if (e.keyCode === KEYS.LEFT_ARROW) {
      const tupleEmojiCategory = Tuple(selectedEmoji, selectedCategory);
      Either.either(identity, setPrevActiveEmoji(
        setSelectedEmoji,
        setSelectedCategory,
        data,
      ))(isStartEmoji(tupleEmojiCategory));
      e.preventDefault();
      return false;
    }
    if (e.keyCode === KEYS.RIGHT_ARROW) {
      const tupleEmojiCategory = Tuple(selectedEmoji, selectedCategory);
      setNextEmojiActive(
        setSelectedEmoji,
        setSelectedCategory,
        data[selectedCategory],
        length(data),
      )(tupleEmojiCategory);
      e.preventDefault();
      return false;
    }
    if (e.keyCode === KEYS.UP_ARROW) {
      cond([
        [compose(lt(-1), tupleEmoji), compose(
          compose(setScrollOffset, getOffsetTop),
          emojiRow => getRefActiveEmoji(selectedCategory, emojiRow)(getRef('emojiList')),
          tap(setSelectedEmoji),
          tupleEmoji,
        )],
        [compose(lt(0), tupleCategory), changeCategory(
          setSelectedCategory,
          setSelectedEmoji,
          data,
          getSelectedCategoryAndDec,
        )],
      ])(Tuple(moveToPrevEmojiRow(selectedEmoji), selectedCategory));
      e.preventDefault();
      return false;
    }
    if (e.keyCode === KEYS.DOWN_ARROW) {
      const activeItem = getRefActiveEmoji(
        selectedCategory,
        moveToNextEmojiRow(selectedEmoji),
      )(getRef('emojiList'));
      setScrollOffset(getOffsetTop(activeItem));
      if (emojiLength > selectedEmoji + 10) {
        setSelectedEmoji(selectedEmoji + 10);
      } else if (selectedCategory < data.length - 1) {
        setSelectedCategory(selectedCategory + 1);
        setSelectedEmoji(0);
      }
      e.preventDefault();
      return false;
    }
    if (e.keyCode === KEYS.TAB) {
      if (emojiLength > selectedEmoji + 1) {
        setSelectedEmoji(selectedEmoji + 1);
      }
      e.preventDefault();
      return false;
    }
    if (e.keyCode === KEYS.ENTER) {
      e.preventDefault();
      return false;
    }
  }
  return e;
};

const onKeysHandler = ({
  onSetEmoji, data, selectedCategory, selectedEmoji, isOpen, onClose,
}) => (e) => {
  if (isOpen) {
    if (e.keyCode === KEYS.ENTER && isOpen) {
      e.preventDefault();
      onSetEmoji(emojiIndex.search(data[selectedCategory].emojis[selectedEmoji], 10)[0]);
      onClose(e);
      return false;
    }
  }
  return e;
};

const onSetRefEmojiHandler = ({ setRef }) => (e) => {
  setRef('containerEmojis', e);
};
const onSetRefScrollWrapperHandler = ({ setRef }) => (e) => {
  setRef('scrollWrapper', e);
};

const scrollToCategoryByElementHandler = ({ getRef }) => (element) => {
  const scrollElement = getRef('scrollWrapper');
  if (element && scrollElement) {
    const offsetTopElement = element.offsetTop;
    scrollElement.scrollTop(offsetTopElement - 10);
  }
};


const enhance = compose(
  withRefs(),
  withState('searchValue', 'onChange', ''),
  withState('selectedEmoji', 'setSelectedEmoji', 0),
  withState('scrollOffset', 'setScrollOffset', 0),
  withState('selectedCategory', 'setSelectedCategory', 0),

  withHandlers({
    onClickOutside: onClickOutsideHandler,
    onKeys: onKeysHandler,
    onShortKeys: onShortKeysHandler,
    onSetRefEmoji: onSetRefEmojiHandler,
    onSetRefScrollWrapper: onSetRefScrollWrapperHandler,
    scrollToCategoryByElement: scrollToCategoryByElementHandler,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        onClickOutside, isOpen, onKeys,
        onShortKeys, getRef, setSelectedEmoji, scrollOffset,
        onSetFocusField,
      } = this.props;

      if (isOpen !== prevProps.isOpen) {
        if (isOpen) {
          onSetFocusField(true);
          document.addEventListener('click', onClickOutside);
        } else {
          setSelectedEmoji(0);
          document.removeEventListener('click', onClickOutside);
        }
      }
      if (scrollOffset !== prevProps.scrollOffset) {
        getRef('scrollWrapper').scrollTop(scrollOffset);
      }
      window.addEventListener('keyup', onKeys);
      window.addEventListener('keydown', onShortKeys);
      if (getRef('emojiPicker')) {
        getRef('emojiPicker').addEventListener('mousedown', callWithPreventEvent(identity));
      }
    },
    componentWillUnmount() {
      const { onClickOutside } = this.props;
      document.removeEventListener('click', onClickOutside);
    },
  }),
  pure,
);

export default enhance(EmojiPicker);
