import React from 'react';
import { Link } from 'react-router-dom';

import { Favorite } from '../../../../../ui-kit';
import { APP_ROUTES } from '../../../../../constants/appRoutes';
import './style.sass';

/**
 * @param item {object}
 * @param handlerFavorite {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const ProjectNavItem = ({
  item,
  handlerFavorite = () => {},
}) => (
  <div className="nav-project__item">
    <Favorite
      status={item.favorite}
      onClick={() => handlerFavorite(item.favorite, item.id)}
    />
    <Link
      to={`${APP_ROUTES.PROJECTS}${item.id}`}
      key={item.id}
      className="nav-project__link"
    >
      {item.title}
    </Link>
  </div>
);
