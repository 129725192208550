import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.sass';

/**
 * @param title {string}
 * @param onClick {function}
 * @param asLink {boolean}
 * @param href {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const ModulesItem = ({
  title,
  onClick = () => {},
  asLink = false,
  href = '',
}) => (
  asLink ? (
    <NavLink
      to={href}
      activeClassName="modules-item__link--active"
      className="modules-item__link"
    >
      {title}
    </NavLink>
  ) : (
    <button
      className="modules-item__button"
      type="button"
      onClick={onClick}
    >
      {title}
    </button>
  )
);
