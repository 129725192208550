import { createActions } from 'redux-actions';
import {
  compose,
  identity, mergeLeft, pathOr, prop,
} from 'ramda';

import types from './types';
import {
  authorSchema, timeEntriesSchema, taskCommentsSchema, taskSchema, taskFilesSchema,
} from './schema';
import { setUsersUpdateMiddleware } from '../../utils/helpers/stateHelpers';
import { getTaskActionParams } from '../../utils/helpers/taskHelpers/crudHelper';
import { setErrorPage } from '../ui/actions';
import { callNotification } from '../../utils/helpers/notifies';
import { getNormalizeErrorObject, getErrorMessage } from '../../utils/helpers/requestHelpers';

const errorPageDataWhenGetTasks = projectId => compose(mergeLeft({ href: `/projects/${projectId}`, label: 'Go back' }),
  getNormalizeErrorObject);

export const {
  getActiveTaskRequest,
  addTaskCommentRequest,
  getTaskCommentsRequest,
  getTimeEntriesRequest,
  addTimeEntryRequest,
  deleteTimeEntryRequest,
  updateTimeEntryRequest,
  getTaskFilesRequest,
  deleteTaskFileRequest,
  editTaskCommentRequest,
  deleteTaskCommentRequest,
  setActiveTask,
  redirectTaskNotFound,
  setTaskComment,
  setTaskComments,
  updateActiveTask,
  setTimeEntries,
  setTimeEntry,
  deleteTimeEntry,
  reorderTimeEntries,
  deleteTimeEntryEntity,
  setTimeEntryEntity,
  reorderSpentTimeTask,
  clearDataTask,
  setTaskFiles,
  deleteTaskFile,
  editTaskComment,
  deleteTaskComment,
  getTaskLogsRequest,
  setTaskLogs,
  changeTaskValues,
} = createActions(
  {
    [types.GET_ACTIVE_TASK_REQUEST]: [
      identity,
      ({
        taskId, ...params
      }, meta) => ({
        async: true,
        route: `/tasks/${taskId}`,
        selectorName: 'getActiveTaskRequest',
        method: 'GET',
        params: getTaskActionParams(params),
        schema: {
          rules: taskSchema,
          pathData: [],
        },
        actions: {
          success: setUsersUpdateMiddleware(setActiveTask,
            {
              multiPathEntities: [
                ['data', 'entities', 'author'],
                ['data', 'entities', 'assigneeUser'],
                ['data', 'entities', 'watchers'],
              ],
            }),
          error: compose(setErrorPage, errorPageDataWhenGetTasks(params.projectId)),
        },
        ...meta,
      }),
    ],
    [types.ADD_TASK_COMMENT_REQUEST]: [
      identity,
      ({
        taskId, ...params
      }, meta) => ({
        async: true,
        route: `/tasks/${taskId}/comments`,
        selectorName: 'addTaskCommentRequest',
        method: 'POST',
        params: getTaskActionParams(params),
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        schema: {
          rules: { comment: authorSchema },
          pathData: [],
        },
        actions: {
          success: setUsersUpdateMiddleware(setTaskComment,
            {
              pathEntities: ['entities', 'author'],
            }),
        },
        ...meta,
      }),
    ],
    [types.GET_TASK_COMMENTS_REQUEST]: [
      identity,
      ({
        taskId, ...params
      }, meta) => ({
        async: true,
        route: `/tasks/${taskId}/comments`,
        selectorName: meta.isShowMoreRequest ? 'getShowMoreTaskCommentsRequest' : 'getTaskCommentsRequest',
        method: 'GET',
        params: getTaskActionParams(params),
        schema: {
          rules: taskCommentsSchema,
          pathData: ['data'],
        },
        actions: {
          success: setUsersUpdateMiddleware(setTaskComments,
            {
              pathEntities: ['data', 'entities', 'author'],
            }),
        },
        ...meta,
      }),
    ],
    [types.GET_TIME_ENTRIES_REQUEST]: [
      identity,
      ({
        taskId, ...params
      }, meta) => ({
        async: true,
        route: `/tasks/${taskId}/time-entries`,
        selectorName: !prop('isNotPending', meta)
          ? 'getTimeEntriesRequest'
          : 'getTimeEntriesIsNotPendingRequest',
        method: 'GET',
        params: {
          sortBy: 'date',
          order: 'asc',
          ...getTaskActionParams(params),
        },
        schema: {
          rules: timeEntriesSchema,
          pathData: ['data'],
        },
        actions: {
          success: setUsersUpdateMiddleware(setTimeEntries,
            {
              pathEntities: ['data', 'entities', 'user'],
            }),
        },
        ...meta,
      }),
    ],
    [types.ADD_TIME_ENTRY_REQUEST]: [
      identity,
      ({
        taskId, ...params
      }, meta) => ({
        async: true,
        route: `/tasks/${taskId}/time-entries`,
        selectorName: 'addTimeEntryRequest',
        method: 'POST',
        params: getTaskActionParams(params),
        actions: {
          success: action => setTimeEntry({
            ...action,
            meta: { taskId, projectId: prop('projectId', params), clientId: prop('clientId', params) },
          }),
          error: error => callNotification.error(getErrorMessage(error)),
        },
        ...meta,
      }),
    ],
    [types.DELETE_TIME_ENTRY_REQUEST]: [
      identity,
      ({
        taskId, timeEntryId, ...params
      }, meta) => ({
        async: true,
        route: `/tasks/${taskId}/time-entries/${timeEntryId}`,
        selectorName: 'deleteTimeEntryRequest',
        method: 'DELETE',
        params: getTaskActionParams(params),
        actions: {
          success: deleteTimeEntry,
          error: error => callNotification.error(getErrorMessage(error)),
        },
        ...meta,
      }),
    ],
    [types.UPDATE_TIME_ENTRY_REQUEST]: [
      identity,
      ({
        taskId, timeEntryId, ...params
      }, meta) => ({
        async: true,
        route: `/tasks/${taskId}/time-entries/${timeEntryId}`,
        selectorName: 'updateTimeEntryRequest',
        method: 'PUT',
        params: getTaskActionParams(params),
        actions: {
          success: action => setTimeEntry({
            ...action,
            meta: { taskId, projectId: prop('projectId', params), clientId: prop('clientId', params) },
          }),
          error: error => callNotification.error(getErrorMessage(error)),
        },
        ...meta,
      }),
    ],
    [types.GET_TASK_FILES_REQUEST]: [
      identity,
      ({
        taskId, ...params
      }, meta) => ({
        async: true,
        route: `/tasks/${taskId}/files`,
        selectorName: 'getTaskFilesRequest',
        method: 'GET',
        params: getTaskActionParams(params),
        schema: {
          rules: taskFilesSchema,
          pathData: ['data'],
        },
        actions: {
          success: action => setTaskFiles({ ...action, meta }),
        },
        ...meta,
      }),
    ],
    [types.DELETE_TASK_FILE_REQUEST]: [
      identity,
      ({
        taskId, fileId, ...params
      }, meta) => ({
        async: true,
        route: `/tasks/${taskId}/files/${fileId}`,
        selectorName: 'deleteTaskFileRequest',
        method: 'DELETE',
        params: getTaskActionParams(params),
        actions: {
          success: deleteTaskFile,
        },
        ...meta,
      }),
    ],
    [types.EDIT_TASK_COMMENT_REQUEST]: [
      identity,
      ({
        taskId, commentId, ...params
      }, meta) => ({
        async: true,
        route: `/tasks/${taskId}/comments/${commentId}`,
        selectorName: 'editTaskCommentRequest',
        method: 'PUT',
        params: getTaskActionParams(params),
        actions: {
          success: editTaskComment,
        },
        ...meta,
      }),
    ],
    [types.DELETE_TASK_COMMENT_REQUEST]: [
      identity,
      ({
        taskId, commentId, ...params
      }, meta) => ({
        async: true,
        route: `/tasks/${taskId}/comments/${commentId}`,
        selectorName: 'deleteTaskCommentRequest',
        method: 'DELETE',
        params: getTaskActionParams(params),
        actions: {
          success: deleteTaskComment,
        },
        ...meta,
      }),
    ],
    [types.GET_TASK_LOGS_REQUEST]: [
      identity,
      ({
        taskId, ...params
      }, meta) => ({
        async: true,
        route: `/tasks/${taskId}/logs`,
        selectorName: 'getTaskLogsRequest',
        method: 'GET',
        params,
        actions: {
          success: setTaskLogs,
          meta: {
            taskId,
          },
        },
        ...meta,
      }),
    ],
  },
  types.SET_ACTIVE_TASK,
  types.REDIRECT_TASK_NOT_FOUND,
  types.SET_TASK_COMMENT,
  types.SET_TASK_COMMENTS,
  types.UPDATE_ACTIVE_TASK,
  types.SET_TIME_ENTRIES,
  types.SET_TIME_ENTRY,
  types.DELETE_TIME_ENTRY,
  types.REORDER_TIME_ENTRIES,
  types.DELETE_TIME_ENTRY_ENTITY,
  types.SET_TIME_ENTRY_ENTITY,
  types.REORDER_SPENT_TIME_TASK,
  types.CLEAR_DATA_TASK,
  types.SET_TASK_FILES,
  types.DELETE_TASK_FILE,
  types.EDIT_TASK_COMMENT,
  types.DELETE_TASK_COMMENT,
  types.SET_TASK_LOGS,
  types.CHANGE_TASK_VALUES,
);
