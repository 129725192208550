import React from 'react';
import PropTypes from 'prop-types';
import {
  ModalWindow,
} from '../../../ui-kit';
import LeadForm from './leadForm';
import './style.sass';

const ModalView = ({
  isOpen,
  onCloseModal,
  title,
  ...props
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    modalName="Lead"
    title={title}
    className="lead__modal"
  >
    <LeadForm {...props} />
  </ModalWindow>
);

ModalView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  pipelineStatusesPending: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  touched: PropTypes.shape({}).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  titleAction: PropTypes.string.isRequired,
  pipelineStatusesAutocomplete: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};


export default ModalView;
