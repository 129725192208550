import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const DraggableOverlay = ({ t }) => (
  <div className="drag-overlay-task">
    <b className="drag-overlay-task__title">
      {t('Plan a sprint by dragging a task')}
    </b>
  </div>
);

DraggableOverlay.propTypes = {
  t: PropTypes.func.isRequired,
};


export default DraggableOverlay;
