import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../..';
import './style.sass';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { usersSelectors } from '../../../../state/users';

/**
 *
 * @param isPending {boolean}
 * @param onDelete {function}
 * @param titleItem {string}
 * @param titleMain {string}
 * @param memberId {number}
 * @param isOpen {boolean}
 * @param setIsOpenedDeleteModal {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const DeleteMemberModal = ({
  isPending,
  onDelete,
  titleItem,
  titleMain,
  memberId = 0,
  isOpen,
  setIsOpenedDeleteModal,
}) => {
  const member = useSelector(state => usersSelectors.getUser(state)(memberId));
  const { t } = useTranslation(['common', 'chat']);

  const onCloseModal = useCallback(() => setIsOpenedDeleteModal(false), []);
  const memberName = getFullName(member);

  return (
    <ConfirmModal
      isOpen={isOpen}
      isPending={isPending}
      actionTitle="Delete"
      onAction={onDelete}
      onClose={onCloseModal}
      title={`Delete ${titleItem}`}
    >
      <span className="delete-member__title title-md">
        {t('Are you sure you want to delete')}
        <b className="delete-member__name">{memberName}</b>
        {' '}

      from this
        {' '}
        {titleMain.toLowerCase()}

      ?
      </span>
    </ConfirmModal>
  );
};
