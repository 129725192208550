import {
  branch,
  compose,
  renderNothing,
  getContext,
} from 'recompose';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import EditProjectModal from './editProjectModal';
import { withCRUDProject } from '../../../../../utils/enchancers';
import rules from '../rules';

import { projectsActions } from '../../../../../state/projects';
import { projectSelectors } from '../../../../../state/project';

const mapStateToProps = (state, props) => ({
  project: projectSelectors.default(state)(props.selectedProjectId),
  isProjectRequestEnded: projectSelectors.getProjectRequestPending(state),
});

const mapDispatchToProps = ({
  editProject: projectsActions.editProjectRequest,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(
    ({ isProjectRequestEnded }) => isProjectRequestEnded,
    renderNothing,
  ),
  getContext({
    setIsNeedRefreshProjects: PropTypes.func,
  }),
  withCRUDProject({
    rules,
    data: ({ project }) => project,
    modalName: 'editProjectModal',
    onSubmit: (formValues, {
      resetForm, props: {
        editProject, setCloseModal, selectedProjectId, initialValues, setIsNeedRefreshProjects,
      },
    }) => {
      editProject({
        ...formValues,
        image: formValues.image || null,
        id: selectedProjectId,
        type: Number(formValues.type),
        members: formValues.members.map(member => member.value),
        client_id: formValues.client_id.value || null,
      }, {
        callbacks: {
          success: () => {
            setCloseModal('editProjectModal');
            if (setIsNeedRefreshProjects) setIsNeedRefreshProjects(true);
            resetForm(initialValues);
          },
        },
      });
    },
  }),
);
export default enhance(EditProjectModal);
