import reducers from './reducers';

import * as socketOperations from './operations';
import socketTypes from './types';
import * as socketActions from './actions';
import * as socketSelectors from './selectors';

export {
  socketOperations,
  socketTypes,
  socketActions,
  socketSelectors,
};

export default reducers;
