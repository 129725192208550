import {
  take,
  fork,
  put,
} from 'redux-saga/effects';

import { sagasManager } from '../../utils';
import { projectsActions, projectsTypes } from '.';
import { projectActions } from '../project';

function* editProjectSuccessFlow() {
  while (true) {
    const { payload } = yield take(projectsTypes.EDIT_PROJECT_SUCCESS);
    const { id: projectId } = payload.project;

    yield put(projectsActions.setProject(payload));
    yield put(projectActions.editProject({
      data: {
        entities: {
          data: {
            [projectId]: payload.project,
          },
        },
        result: { summary: {} },
      },
      project: { id: projectId },
    }));
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(editProjectSuccessFlow);
});
