import React from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { ButtonsCollection } from '../../../../../ui-kit';
import './styles.scss';
import { getImageUrl } from '../../../../../utils/helpers/requestHelpers';

const HrFilesList = ({
  tempFiles, deleteTempFile, files, entities, deleteFile,
}) => (
  <ul className="HrFilesList">
    {
      files.length > 0 && files.map(fileId => (
        <li className="HrFilesList_file" key={`file-for-send-${fileId}`}>
          <a
            className="HrFilesList_file__link"
            rel="noopener noreferrer"
            target="_blank"
            href={getImageUrl(path(['id'], entities[fileId]))}
          >
            <span className="icon-attach-file" />
            <span>{entities[fileId].original}</span>
          </a>
          <ButtonsCollection.ButtonIcons title="Remove file" onClick={() => deleteFile(fileId)}>
            <span className="icon-trash" />
          </ButtonsCollection.ButtonIcons>
        </li>
      ))
    }
    {
      tempFiles.map(file => (
        <li className="HrFilesList_file" key={`file-for-send-${file.id}`}>
          <span className="icon-attach-file" />
          <span>{file.original}</span>
          <ButtonsCollection.ButtonIcons title="Remove file" onClick={() => deleteTempFile([file.id])}>
            <span className="icon-trash" />
          </ButtonsCollection.ButtonIcons>
        </li>
      ))
    }
  </ul>
);

HrFilesList.propTypes = {
  tempFiles: PropTypes.instanceOf(Array).isRequired,
  files: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(Object),
  ]),
  entities: PropTypes.instanceOf(Object),
  deleteTempFile: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
};
HrFilesList.defaultProps = {
  files: [],
  entities: {},
};

export default HrFilesList;
