import * as yup from 'yup';
import { translateHelpers } from '../../../utils/helpers';

export const rules = yup.object().shape({
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password'), null], {
      message: 'match',
      field: 'passwordConfirm',
      params: {
        key: translateHelpers.t('common', 'Passwords'),
      },
    })
    .required({
      message: 'required',
      field: 'passwordConfirm',
      params: {
        key: translateHelpers.t('common', 'Password confirm'),
      },
    }),
  password: yup
    .string()
    .required({
      field: 'password',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Password'),
      },
    }),
  username: yup
    .string()
    .required({
      field: 'username',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Username'),
      },
    }),
  lastName: yup
    .string()
    .required({
      field: 'lastName',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Last name'),
      },
    }),
  firstName: yup
    .string()
    .required({
      field: 'firstName',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'First name'),
      },
    }),
});
