import React from 'react';
import { useTranslation } from 'react-i18next';

import './style.sass';

/**
 *
 * @param percent {string}
 * @returns {JSX.Element}
 * @constructor
 */

const PERCENT = '25%';

export const ProgressBar = ({ percent = PERCENT }) => {
  const { t } = useTranslation('common');
  return (
    <div className="custom-progress-bar__progress-bar-container">
      <div className="custom-progress-bar__wrapper">
        <div className="custom-progress-bar__percent">{percent}</div>
        <div className="custom-progress-bar__progress-bar">
          <div className="line-percent" style={{ width: percent }} />
        </div>
        <button type="button" className="custom-progress-bar__cancel-button">{t('Cancel')}</button>
      </div>
    </div>
  );
};
