import React from 'react';
import { propOr } from 'ramda';
import PropTypes from 'prop-types';

import { Grid, FormsCollection } from '../../../../../../ui-kit';
import './style.sass';

const InvoiceItem = ({
  values, errors, handleChange, invoiceItemId, onDelete,
  onChangeFieldValue, amount, canBeDeleted, currencySign,
}) => (
  <div className="invoice-item">
    <Grid.Row
      key={`invoice-item-grid  - ${invoiceItemId}`}
      className="table-invoice-items__row"
    >
      <Grid.ColData className="table-invoice-items__col table-invoice-items__col--details">
        <FormsCollection.Input
          name="details"
          value={values.details}
          error={propOr('', 'message', errors.details)}
          touched
          onChange={(e) => {
            handleChange(e);
            onChangeFieldValue('details', e.target.value);
          }}
          id="add-invoice-item__field add-invoice-item__field--invoice_details"
          placeholderJump="Details"
        />
      </Grid.ColData>
      <Grid.ColData className="table-invoice-items__col table-invoice-items__col--quantity">
        <FormsCollection.Input
          name="quantity"
          value={values.quantity}
          error={propOr('', 'message', errors.quantity)}
          touched
          onChange={(e) => {
            handleChange(e);
            onChangeFieldValue('quantity', parseFloat(e.target.value));
            onChangeFieldValue('amount', parseFloat(e.target.value) * parseFloat(values.rate));
          }}
          id="add-invoice-item__field add-invoice-item__field--quantity"
          placeholderJump="Quantity"
        />
      </Grid.ColData>
      <Grid.ColData className="table-invoice-items__col table-invoice-items__col--rate">
        <FormsCollection.Input
          name="rate"
          value={values.rate}
          error={propOr('', 'message', errors.rate)}
          touched
          onChange={(e) => {
            handleChange(e);
            onChangeFieldValue('rate', parseFloat(e.target.value));
            onChangeFieldValue('amount', parseFloat(e.target.value) * parseFloat(values.quantity));
          }}
          id="add-invoice-item__field add-invoice-item__field--rate"
          placeholderJump="Rate"
        />
      </Grid.ColData>
      <Grid.ColData className="table-invoice-items__col table-invoice-items__col--amount">
        <span className="text--cut">{`${currencySign} ${amount}`}</span>
      </Grid.ColData>
      {
        canBeDeleted && (
          <Grid.ColData className="table-invoice-items__col table-invoice-items__col--delete-button">
            <span tabIndex="0" role="button" className="icon-delete-icon" onClick={onDelete} onKeyDown={onDelete} />
          </Grid.ColData>
        )
      }
    </Grid.Row>
  </div>
);

InvoiceItem.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  invoiceItemId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeFieldValue: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  canBeDeleted: PropTypes.bool.isRequired,
  currencySign: PropTypes.string.isRequired,
};

export default InvoiceItem;
