export const MODULES = {
  WHR_CANDIDATES: 'whr_candidates',
  WHR_COMMENTS: 'whr_comments',
};

export const ACTIONS = {
  READ: 'read',
  DELETE: 'delete',
  UPDATE: 'update',
  CREATE: 'create',

};
