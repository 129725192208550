import { compose, hoistStatics } from 'recompose';
import { connect } from 'react-redux';

import DeleteProjectModal from './deleteProjectModal';
import { getModal } from '../../../../../state/ui/selectors';
import { projectsSelectors } from '../../../../../state/projects';
import { uiActions } from '../../../../../state/ui';

const mapStateToProps = (state, props) => ({
  isOpen: getModal(state)('deleteProjectModal'),
  project: projectsSelectors.getProject(state)(props.selectedProjectId),
});

const mapDispatchToProps = ({
  onCloseModal: () => uiActions.closeModal('deleteProjectModal'),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  hoistStatics,
);

export default enhance(DeleteProjectModal);
