import React, { useState, useCallback, useEffect } from 'react';
import './style.sass';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Header, ButtonsCollection, ScrollWrapper } from '../../../../../ui-kit';
import PersonContainer from '../personContainer';
import { getFullName } from '../../../../../utils/helpers/userHelpers';
import { PEOPLE_PAGES } from '../../api/routes';
import { usePeopleContext } from '../../context/usePeopleContext';
import api from '../../api/people.service';

const DEFAULT_TITLE = 'Create User Profile';

/**
 * @returns {JSX.Element}
 * @constructor
 */

const PersonWrapper = () => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const { userId } = useParams();
  const { peopleData, selectedUser, setSelectedUser } = usePeopleContext();
  const [title, setTitle] = useState(DEFAULT_TITLE);

  const getPerson = useCallback(async (id) => {
    const { user } = await api.getPerson({ id });
    setSelectedUser(user);
  }, [api]);

  const onClose = useCallback(() => {
    history.push(PEOPLE_PAGES.PEOPLE.MAIN);
    setSelectedUser({});
  }, []);

  useEffect(() => {
    const user = peopleData.find(item => item.id === +userId);
    if (user) {
      setSelectedUser(user);
    } else {
      getPerson(userId);
    }
  }, [peopleData, userId]);

  useEffect(() => {
    const newTitle = selectedUser ? `${t('User Profile')} - ${getFullName(selectedUser)}` : DEFAULT_TITLE;
    setTitle(newTitle);
  }, [selectedUser]);

  return (<>
    <Header hideToggleRightSideBar className="user-header">
      <>
        <h1 className="weight--medium title--sm user-nav__title profile-title">
          <ButtonsCollection.ButtonIcons
            title={t('Go back to users')}
            renderMobileButton
            className="user-head-back"
            onClick={onClose}
          >
            <span className="icon-expand-arrow-left" />
          </ButtonsCollection.ButtonIcons>
          {title}
        </h1>
      </>
    </Header>
    <div className="main-container__content main-container__content--profile">
      <ScrollWrapper>
        <div className="userEdit">
          <PersonContainer />
        </div>
      </ScrollWrapper>
    </div>
  </>);
};

export default PersonWrapper;
