import React from 'react';
import PropTypes from 'prop-types';
import { MyTasksList } from '../../..';

const ProjectTasksList = ({
  tasks,
  gridName,
  pagination,
  onPageChange,
  data,
}) => (
  <MyTasksList
    projectView
    data={data}
    tasks={tasks}
    gridName={gridName}
    pagination={pagination}
    onPageChange={onPageChange}
  />
);

ProjectTasksList.propTypes = {
  data: PropTypes.shape({}).isRequired,
  tasks: PropTypes.arrayOf(PropTypes.number).isRequired,
  gridName: PropTypes.string.isRequired,
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
  }).isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default ProjectTasksList;
