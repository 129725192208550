import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { LeadTab, StatusOfLeadsControl, LeadTasks } from '..';
import { Header } from '../../../index';

const LeadItemMobile = ({
  t,
  setRef,
  lead,
  onCreateTaskModal,
  onEditTaskModal,
  onEditFullTaskModal,
  onDeleteTaskModal,
}) => (
  <div className="leadItem__detail-info">
    <Header withoutControls hideToggleRightSideBar className="user-header">
      <>
        <h1 className="weight--medium title--xl title-page user-nav__title">
          <NavLink
            title="Go back to users"
            renderMobileButton
            className="user-head-back"
            to="/crm/leads"
          >
            <span className="icon-expand-arrow-left" />
          </NavLink>
          {getFullName(lead.contact)}
        </h1>
      </>
    </Header>
    <div className="main-container__content main-container__content--profile">
      <div className="leadItem__content" ref={e => setRef('active-lead-content', e)}>
        <StatusOfLeadsControl lead={lead} t={t} />
        <LeadTasks
          leadId={lead.id}
          t={t}
          onCreateTaskModal={onCreateTaskModal}
          onDeleteTaskModal={onDeleteTaskModal}
          onEditTaskModal={onEditTaskModal}
          onEditFullTaskModal={onEditFullTaskModal}
        />
        <LeadTab lead={lead} />
      </div>
    </div>
  </div>
);

LeadItemMobile.propTypes = {
  t: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  lead: PropTypes.shape({}).isRequired,
  onCreateTaskModal: PropTypes.func.isRequired,
  onEditTaskModal: PropTypes.func.isRequired,
  onEditFullTaskModal: PropTypes.func.isRequired,
  onDeleteTaskModal: PropTypes.func.isRequired,
};

export default LeadItemMobile;
