import { compose, pure } from 'recompose';
import { connect } from 'react-redux';

import { openModal } from '../../../../../../state/ui/actions';
import RepositoryItem from './repositoryItem';

const mapDispatchToProps = ({
  openModal,
});

const enhance = compose(
  connect(null, mapDispatchToProps),
  pure,
);
export default enhance(RepositoryItem);
