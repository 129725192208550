import {
  compose, withHandlers, lifecycle, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import {
  prop, pathOr,
} from 'ramda';
import { uiActions, uiSelectors } from '../../state/ui';
import { pipelinesActions, pipelinesSelectors } from '../../state/pipelines';
import { leadsSelectors } from '../../state/leads';
import withAutocomplete from './withAutocomplete';

const mapStateToProps = (state, { pendingSelector }) => ({
  getModalIsOpen: uiSelectors.getModal(state),
  isPending: leadsSelectors.getLeadActionPending(state)(pendingSelector),
  pipelineStatusesAutocomplete: pipelinesSelectors.getPipelineStatusesAutocompleteSelector(state),
  pipelineStatusesPending: pipelinesSelectors.getPipelineStatusesAutocompletePendingRequest(state),
});

const mapDispatchToProps = {
  closeModal: uiActions.closeModal,
  getPipelineStatusesAutocomplete: pipelinesActions.getPipelineStatusesAutocompleteRequest,
};

const onCloseModalHandler = modalName => ({
  closeModal,
  resetForm,
}) => () => {
  closeModal(modalName);
  resetForm();
};

const enhance = ({
  rules,
  onSubmit,
  modalName,
  pendingSelector,
}) => compose(
  withProps(() => ({
    pendingSelector,
  })),
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: ({ initialValues = {} }) => initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: rules,
    handleSubmit: onSubmit,
  }),
  lifecycle({
    componentDidMount() {
      const pipelineId = pathOr(null, ['values', 'pipeline_id', 'value'], this.props);
      if (pipelineId) {
        this.props.getPipelineStatusesAutocomplete({ pipeline_id: pipelineId });
      }
    },
    componentDidUpdate(prevProps) {
      const prevPipelineId = pathOr(null, ['values', 'pipeline_id', 'value'], prevProps);
      const pipelineId = pathOr(null, ['values', 'pipeline_id', 'value'], this.props);
      if (prevPipelineId !== pipelineId && pipelineId) {
        this.props.getPipelineStatusesAutocomplete({ pipeline_id: pipelineId });
      }
    },
  }),
  withProps(({ getModalIsOpen }) => ({
    isOpen: getModalIsOpen(modalName),
  })),
  withHandlers({
    onCloseModal: onCloseModalHandler(modalName),
  }),
  withAutocomplete({
    name: 'getPipelinesAutocomplete',
    action: pipelinesActions.getPipelinesAutocompleteRequest,
    dataPath: prop('pipelines'),
    searchField: 'q',
  }),
);
export default enhance;
