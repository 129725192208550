import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ScrollWrapper, FormsCollection, PreLoaderWhileLoading } from '../../../../../ui-kit';
import './style.sass';
import { PermissionsList } from './components';
import { permissionsActions, permissionsSelectors } from '../../../../../state/permissions';


const PRELOADER_DIMENSION = 100;

/**
 *
 * @param onSubmit{function}
 * @returns {JSX.Element}
 * @constructor
 */
export const PermissionsContainer = ({
  onSubmit,
}) => {
  const { t } = useTranslation('common');
  const { roleId } = useParams();
  const dispatch = useDispatch();

  const [listOfPermissions, setListOfPermissions] = useState({});

  const role = useSelector(state => permissionsSelectors.getRoleById(state)(roleId));

  const [name, setName] = useState(role.name);
  const onChangeRoleField = e => setName(e.target.value);

  const handleSubmit = () => onSubmit({ name, listOfPermissions });

  useEffect(() => {
    if (roleId) {
      dispatch(permissionsActions.getRoleRequest({ roleId }));
    }
    setName(role.name);
  }, [roleId, role.name]);

  return (roleId && !role.id ? ''
    : (
      <PreLoaderWhileLoading
        dimension={PRELOADER_DIMENSION}
        alignContainerCenter
        isLoading={!role}
      >
        <ScrollWrapper>
          <div className="permissions">
            <div className="role-field">
              <FormsCollection.Input
                type="text"
                id="permission-input"
                className="role-field__input"
                name="role-name"
                value={name || ''}
                onChange={onChangeRoleField}
                error={!name && 'Name is required'}
                placeholderJump={t('Name of role')}
              />
            </div>
            <PermissionsList
              listOfPermissions={listOfPermissions}
              setListOfPermissions={setListOfPermissions}
              onSubmit={handleSubmit}
            />
          </div>
        </ScrollWrapper>
      </PreLoaderWhileLoading>
    )
  );
};
