import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { ToggleButton, ButtonsCollection, FormsCollection } from '../../../../../../../ui-kit';

const UserPermissionForm = ({ isOpen, onToggle }) => (
  <FormsCollection.FormWrapper classNameForm="userPermission">
    <div className="userPermission__title">

        Custom permission for this user ?
      <ButtonsCollection.ButtonIcons onClick={onToggle}><span className="icon-expand-arrow-dn" /></ButtonsCollection.ButtonIcons>
    </div>
    <div className={`userPermission___wrapper ${isOpen ? 'userPermission___wrapper--open' : ''}`}>
      <div className="userPermission__row userPermission__row--header">
        <div className="userPermission__col">

          Module name
        </div>
        <div className="userPermission__col">
          <div className="row userPermission__title-row">Permissions</div>
          <div className="userPermission__row-child userPermission__title-permission-row">
            <div className="userPermission__col-child">

              Read
            </div>
            <div className="userPermission__col-child">

              Create
            </div>
            <div className="userPermission__col-child">

              Update
            </div>
            <div className="userPermission__col-child">

              Delete
            </div>
          </div>
        </div>
      </div>
      <div className="userPermission__row">
        <div className="userPermission__col">

          Projects
        </div>
        <div className="userPermission__col">
          <div className="userPermission__row-child">
            <div className="userPermission__col-child">
              <ToggleButton />
            </div>
            <div className="userPermission__col-child">
              <ToggleButton />
            </div>
            <div className="userPermission__col-child">
              <ToggleButton />
            </div>
            <div className="userPermission__col-child">
              <ToggleButton status />
            </div>
          </div>
        </div>
      </div>
      <div className="userPermission__row">
        <div className="userPermission__col">

          Chats
        </div>
        <div className="userPermission__col">
          <div className="userPermission__row-child">
            <div className="userPermission__col-child">
              <ToggleButton status />
            </div>
            <div className="userPermission__col-child">
              <ToggleButton status />
            </div>
            <div className="userPermission__col-child">
              <ToggleButton status />
            </div>
            <div className="userPermission__col-child">
              <ToggleButton />
            </div>
          </div>
        </div>
      </div>
      <div className="userPermission__row">
        <div className="userPermission__col">

          Channels
        </div>
        <div className="userPermission__col">
          <div className="userPermission__row-child">
            <div className="userPermission__col-child">
              <ToggleButton status />
            </div>
            <div className="userPermission__col-child">
              <ToggleButton />
            </div>
            <div className="userPermission__col-child">
              <ToggleButton />
            </div>
            <div className="userPermission__col-child">
              <ToggleButton />
            </div>
          </div>
        </div>
      </div>
      <div className="userPermission__row">
        <div className="userPermission__col">

          Projects
        </div>
        <div className="userPermission__col">
          <div className="userPermission__row-child">
            <div className="userPermission__col-child">
              <ToggleButton />
            </div>
            <div className="userPermission__col-child">
              <ToggleButton />
            </div>
            <div className="userPermission__col-child">
              <ToggleButton />
            </div>
            <div className="userPermission__col-child">
              <ToggleButton status />
            </div>
          </div>
        </div>
      </div>
      <div className="userPermission__row userPermission__row--buttons">
        <ButtonsCollection.ButtonBrill className="userPermission__submit-button button-brill--fill">Confirm permissions</ButtonsCollection.ButtonBrill>
      </div>
    </div>
  </FormsCollection.FormWrapper>
);

UserPermissionForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default UserPermissionForm;
