export const RIGHT_SIDEBAR_VIEWS = {
  CREATE_ACTION: 'CREATE_ACTION',
  EDIT_ACTION: 'EDIT_ACTION',
  NOTHING: 'NOTHING',
};

export const NODE_EVENTS = {
  ACTION: 'ACTION',
  TRIGGER: 'TRIGGER',
};
