import { identity, pathOr, propOr, memoizeWith } from 'ramda';
import { createSelector } from 'reselect';
import { getEventsOptions } from '@bp//utils';
import { NODE_EVENTS } from '@bp/constants';
import { getOutputVariablesToEventHelper } from './constants';

const rootSelector = createSelector(propOr({}, 'businessProcess'), identity);

export const getBusinessProcessSelected = createSelector(
  rootSelector,
  pathOr({}, ['businessProcess', 'selected']),
);

export const getBusinessProcessData = createSelector(
  rootSelector,
  pathOr([], ['businessProcess', 'data']),
);

export const getProcessNodeResult = createSelector(rootSelector, pathOr([], ['nodes', 'data']));

export const getSelectedNode = createSelector(rootSelector, pathOr([], ['nodes', 'selected']));

export const getBusinessProcessRequestPending = createSelector(
  pathOr(false, ['pending', 'getBusinessProcessRequest']),
  identity,
);

export const getBusinessProcessesPending = createSelector(
  pathOr(false, ['pending', 'getBusinessProcessesRequest']),
  identity,
);

export const getCrudNodesConfig = createSelector(rootSelector, pathOr({}, ['crudNodesConfig']));

export const getAllEvents = createSelector(rootSelector, pathOr([], ['events', 'data']));

export const getAllNodes = createSelector(rootSelector, pathOr([], ['nodes', 'data']));

export const getSelectedEvent = createSelector(rootSelector, pathOr([], ['events', 'selected']));

export const getVariables = createSelector(rootSelector, pathOr([], ['nodes', 'variables']));

export const getLogs = createSelector(rootSelector, pathOr([], ['logs', 'data']));

export const getLogsRequestPending = createSelector(
  pathOr(false, ['pending', 'getBusinessProcessLogsRequest']),
  identity,
);

export const getEventsOptionsForCreation = createSelector(rootSelector, (state) => {
  const { event } = state.crudNodesConfig;
  const { data } = state.events;
  const isAction = event === NODE_EVENTS.ACTION;
  return getEventsOptions(isAction, data);
});

export const getEventsOptionsForEditing = createSelector(rootSelector, (state) => {
  const { selected, data } = state.events;
  const isAction = !!selected.is_action;
  return getEventsOptions(isAction, data);
});

export const getActiveEventById = createSelector(getAllEvents, (events) =>
  memoizeWith(identity, (id) => events.find((event) => event.id === id)),
);

export const getOutputVariablesToEvent = createSelector(
  getAllEvents,
  getAllNodes,
  getSelectedNode,
  getCrudNodesConfig,
  getOutputVariablesToEventHelper,
);
