import React from 'react';
import PropTypes from 'prop-types';
import { ButtonsCollection, Avatar, BrillMask } from '../../../../../../ui-kit';
import './style.sass';
import { getFullName } from '../../../../../../utils/helpers/userHelpers';
import { getImageUrl } from '../../../../../../utils/helpers/requestHelpers';

const PinnedLeadItem = ({ pinnedLead, onUnpin, setSelectedPinnedLeadId }) => (
  // eslint-disable-next-line max-len
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
  <li
    className="pinned-leads__item"
    onClick={() => setSelectedPinnedLeadId(pinnedLead.id)}
  >
    <BrillMask className="brill-mask">
      <Avatar src={getImageUrl(pinnedLead.contact.avatar)} alt={getFullName(pinnedLead.contact)} />
    </BrillMask>
    <div className="pinned-contacts__item-name">
      <div className="text--cut">
        {getFullName(pinnedLead.contact)}
      </div>
    </div>
    {
      onUnpin && (
        <ButtonsCollection.ButtonIcons
          className="pinned-leads__item-unpin"
          onClick={onUnpin}
          title="Unpin lead"
        >
          <span className="icon-close" />
        </ButtonsCollection.ButtonIcons>
      )
    }
  </li>
);

PinnedLeadItem.propTypes = {
  pinnedLead: PropTypes.instanceOf(Object).isRequired,
  onUnpin: PropTypes.func.isRequired,
  setSelectedPinnedLeadId: PropTypes.func.isRequired,
};

export default PinnedLeadItem;
