import { compose } from 'recompose';
import AvatarProfile from './AvatarProfile';
import { withUploadFile } from '../../../../../utils/enchancers';
import { AVAILABLE_FILE_FORMATS, AVAILABLE_FILE_SIZES } from '../../../../../constants/files';

const enhance = compose(
  withUploadFile({
    availableFormats: AVAILABLE_FILE_FORMATS.LOGO_PROFILE,
    availableSize: AVAILABLE_FILE_SIZES.LOGO_PROFILE,
  }),
);

export default enhance(AvatarProfile);
