import { compose, withHandlers, withStateHandlers } from 'recompose';

import AttachedFilesSlider from './attachedFilesSlider';

const toggleSlider = () => val => ({
  isOpen: val,
});

const changeSliderState = () => index => ({
  currentSlide: index,
});

const changeSlider = ({ changeSliderStateHandler }) => (index, data) => {
  let newIndex = index;
  const countSlides = data.images.length - 1;
  if (index > countSlides) {
    newIndex = 0;
  } else if (index < 0) {
    newIndex = countSlides;
  }
  changeSliderStateHandler(newIndex);
};

const enhance = compose(
  withStateHandlers(() => ({ isOpen: true, currentSlide: 0 }), {
    toggleSliderStateHandler: toggleSlider,
    changeSliderStateHandler: changeSliderState,
  }),
  withHandlers({
    changeSliderHandler: changeSlider,
  }),
);
export default enhance(AttachedFilesSlider);
