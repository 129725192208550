import { compose } from 'recompose';
import {
  map, values, path,
} from 'ramda';
import UserInvite from './UserInvite';
import { permissionsActions } from '../../../../state/permissions';
import { renameKeys } from '../../../../utils/helpers/commonHelpers';
import { withAutocomplete } from '../../../../utils/enchancers';

const enhance = compose(
  withAutocomplete({
    name: 'getRolesAutocomplete',
    action: (params, meta) => permissionsActions.getRolesRequest(params,
      { ...meta, isAutocomplete: true }),
    dataPath: compose(
      map(renameKeys({ name: 'label', id: 'value' })),
      values,
      path(['data', 'entities', 'roles']),
    ),
    searchField: 'name',
  }),
);

export default enhance(UserInvite);
