import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, ScrollWrapper } from '../../ui-kit';
import { EditTaskModal as EditFullTaskModal } from '../taskCRUD';
import { LEADS_VIEW } from '../../constants/crm';
import { CUSTOM_FIELDS_MODELS } from '../../constants/customFields';
import CustomFieldsModal from '../customFields';
import { Filters, ListOfLeads, PipeView } from './components';
import {
  CreateLeadModal,
  EditLeadModal,
  DeleteLeadModal,
  PinLeadModal,
  UnpinLeadModal,
} from './modals';
import {
  CreateTaskModal,
  EditTaskModal,
  DeleteTaskModal,
} from './leadTasksModals';
import './style.sass';

const LeadsContainer = ({
  onSuccessCreateLead,
  getActiveTaskSelector,
  onSuccessEditFullTask,
  setRef,
  selectedLeadId,
  onDeleteLead,
  leads,
  selectedLeadTask,
  setSelectedSort,
  onSortBy,
  order,
  sort,
  status,
  onSearch,
  searchName,
  setSearchLeadName,
  onChangeFilterStatus,
  leadsView,
  setLeadsView,
  pipelines,
  checkedPipeline,
  pagination,
  onPageChange,
  leadsCount,
  selectedPinnedLeadId,
  refreshPage,
  isOpen,
  setIsOpen,
}) => {
  const isListView = leadsView === LEADS_VIEW.LIST;

  return (
    <ScrollWrapper refCustom={e => setRef('scroll-wrapper', e)}>
      <div className={`leads leads--${isListView ? 'list' : 'pipe'}`}>
        <Filters
          setSelectedSort={setSelectedSort}
          onSortBy={onSortBy}
          order={order}
          sort={sort}
          status={status}
          onSearch={onSearch}
          searchName={searchName}
          setSearchLeadName={setSearchLeadName}
          onChangeFilterStatus={onChangeFilterStatus}
          leadsView={leadsView}
          setLeadsView={setLeadsView}
          pipelines={pipelines}
        />
        {
          isListView ? (
            <>
              <ListOfLeads
                leads={leads}
                selectedLeadId={selectedLeadId}
                selectedPinnedLeadId={selectedPinnedLeadId}
              />
              <div className="pagination-container">
                <Pagination
                  count={leadsCount}
                  component="clients"
                  pagination={pagination}
                  onPageChange={onPageChange}
                />
              </div>
            </>
          ) : (
            <PipeView pipeline={checkedPipeline} />
          )
        }
      </div>
      <CreateTaskModal />
      <EditFullTaskModal
        getTaskSelector={getActiveTaskSelector}
        onSuccess={onSuccessEditFullTask}
        selectedTaskId={selectedLeadTask}
        taskLeadId={selectedLeadId}
      />
      <EditTaskModal selectedLeadTask={selectedLeadTask} />
      <DeleteTaskModal selectedLeadTask={selectedLeadTask} />
      <CreateLeadModal onSuccess={onSuccessCreateLead} />
      <EditLeadModal selectedLeadId={selectedLeadId} />
      <DeleteLeadModal onDelete={onDeleteLead} selectedLeadId={selectedLeadId} />
      <PinLeadModal selectedLeadId={selectedLeadId} />
      <UnpinLeadModal selectedLeadId={selectedLeadId} />
      <CustomFieldsModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        customFieldModel={CUSTOM_FIELDS_MODELS.LEAD_CLIENT}
        customCallback={refreshPage}
      />
    </ScrollWrapper>
  );
};

LeadsContainer.propTypes = {
  onSuccessCreateLead: PropTypes.func.isRequired,
  getActiveTaskSelector: PropTypes.func.isRequired,
  onSuccessEditFullTask: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  selectedLeadTask: PropTypes.string,
  selectedLeadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedPinnedLeadId: PropTypes.number,
  leadsView: PropTypes.string.isRequired,
  onDeleteLead: PropTypes.func.isRequired,
  setSelectedSort: PropTypes.func.isRequired,
  onSortBy: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  setLeadsView: PropTypes.func.isRequired,
  setSearchLeadName: PropTypes.func.isRequired,
  onChangeFilterStatus: PropTypes.func.isRequired,
  leads: PropTypes.oneOfType([PropTypes.arrayOf(Object), PropTypes.number]).isRequired,
  pipelines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  order: PropTypes.string.isRequired,
  sort: PropTypes.string.isRequired,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  checkedPipeline: PropTypes.oneOfType([
    PropTypes.number,
    () => null,
  ]).isRequired,
  searchName: PropTypes.string.isRequired,
  leadsCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  refreshPage: PropTypes.func.isRequired,
  pagination: PropTypes.instanceOf(Object).isRequired,

};

LeadsContainer.defaultProps = {
  selectedLeadTask: null,
  selectedLeadId: null,
  selectedPinnedLeadId: null,
};

export default LeadsContainer;
