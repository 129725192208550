/* eslint-disable arrow-body-style */
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose';
import { connect } from 'react-redux';

import StatusesBoard from './statusBoard';
import { projectActions, projectSelectors } from '../../../../state/project';
import { debounce } from '../../../../utils/helpers/commonHelpers';
import { withWindowWidth } from '../../../../utils/enchancers';

const FIX_COLUMN_HEIGHT = 175;

const mapDispatchToProps = ({
  onDragAgendaEnd: projectActions.onDragAgendaEnd,
});

const mapStateToProps = state => ({
  assigneeUsers: projectSelectors.getProjectTasksAssignees(state),
});

const isDestinationAndSourceEqual = (destination, source) => (
  destination.droppableId === source.droppableId && destination.index === source.index);

const onDragEndHandler = ({
  onDragAgendaEnd,
  setIsDragging,
}) => (data) => {
  const { destination, source } = data;
  if (!destination) {
    setIsDragging(false);
    return false;
  }
  if (isDestinationAndSourceEqual(destination, source)) {
    setIsDragging(false);
    return false;
  }
  onDragAgendaEnd(data);
  setIsDragging(false);
  return data;
};

const onDragUpdateHandler = ({
  setUpdateDroppable,
}) => (data) => {
  const { destination, source } = data;
  if (!destination) {
    return false;
  }
  if (isDestinationAndSourceEqual(destination, source)) {
    return false;
  }
  setUpdateDroppable(destination.droppableId);
  return data;
};

const onDragStartHandler = ({
  setIsDragging,
}) => (data) => {
  setIsDragging(true);
  return data;
};

const onResizeWindowHandler = ({ setColumnRowHeight }) => () => {
  debounce(() => setColumnRowHeight(`${window.innerHeight - FIX_COLUMN_HEIGHT}px`), 300);
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withWindowWidth(),
  withState('isDragging', 'setIsDragging', false),
  withState('updateDroppable', 'setUpdateDroppable', null),
  withState('columnRowHeight', 'setColumnRowHeight', `${window.innerHeight - FIX_COLUMN_HEIGHT}px`),
  withHandlers({
    onDragEnd: onDragEndHandler,
    onDragUpdate: onDragUpdateHandler,
    onDragStart: onDragStartHandler,
    onResizeWindow: onResizeWindowHandler,
  }),
  lifecycle({
    componentDidMount() {
      window.addEventListener('resize', this.props.onResizeWindow);
    },
    componentWillUnmount() {
      window.removeEventListener('resize', this.props.onResizeWindow);
    },
  }),
);

export default enhance(StatusesBoard);
