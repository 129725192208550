import {
  compose, withContext, withHandlers, withState, lifecycle,
} from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Leads from './leads';
import { uiActions } from '../../../../state/ui';
import { withWindowWidth } from '../../../../utils/enchancers';

const mapDispatchToProps = {
  openModal: uiActions.openModal,
  setAppTitle: uiActions.setAppTitle,
};

const onCreateLeadModalHandler = ({
  openModal,
}) => () => {
  openModal('createLeadModal');
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  withWindowWidth(),
  withTranslation(['common', 'chat']),
  withState('selectedLeadId', 'setSelectedLeadId', null),
  withState('selectedPinnedLeadId', 'setSelectedPinnedLeadId', null),
  withContext({
    setSelectedLeadId: PropTypes.func,
    selectedLeadId: PropTypes.any,
    setSelectedPinnedLeadId: PropTypes.func,
    selectedPinnedLeadId: PropTypes.any,
  }, ({
    setSelectedLeadId,
    selectedLeadId,
    setSelectedPinnedLeadId,
    selectedPinnedLeadId,
  }) => ({
    setSelectedLeadId,
    selectedLeadId,
    setSelectedPinnedLeadId,
    selectedPinnedLeadId,
  })),
  withHandlers({
    onCreateLeadModal: onCreateLeadModalHandler,
  }),
  lifecycle({
    componentDidMount() {
      this.props.setAppTitle('Leads - CRM - Avanga 2.0');
    },
  }),
);
export default enhance(Leads);
