import React from 'react';
import { useSelector } from 'react-redux';
import { prop, propOr } from 'ramda';
import classNames from 'classnames';

import './style.sass';
import { Avatar, BrillMask, OnlineStatusLabel } from '../../..';
import { GLOBAL_MENTION_ID } from '../../../../constants/messenger';
import { IMAGES_SIZE } from '../../../../constants/ui';
import { setGlobalVariableForMention } from '../../../../utils/helpers/mentionHelpers/lookup';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { getUsersList } from '../../../../state/users/selectors';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';

/**
 * @param index {number}
 * @param onClickMention {function}
 * @param isSelected {boolean}
 * @param itemId {number}
 * @returns {JSX.Element}
 * @constructor
 */

const MentionItem = ({
  itemId, isSelected, onClickMention, index,
}) => {
  const users = useSelector(getUsersList);
  const userCondition = itemId === GLOBAL_MENTION_ID
    ? setGlobalVariableForMention([])[0] : users.entities[itemId];

  const user = { ...userCondition, fullName: getFullName(userCondition), userAvatar: getImageUrl(prop('avatar', userCondition), IMAGES_SIZE.sm) };

  let avatarUserId = prop('avatar', user);
  if (typeof avatarUserId === 'object' && avatarUserId !== null) {
    avatarUserId = avatarUserId.id;
  }
  const userAvatar = avatarUserId ? getImageUrl(avatarUserId, IMAGES_SIZE.sm) : '';

  const onClick = () => onClickMention(index);

  return (
    <li
      role="none"
      data-mentionid={user.id}
      onClick={onClick}
      className={classNames('drop-down-mentions__member', { 'drop-down-mentions__member--selected': isSelected })}
    >
      <BrillMask outsideChildren={itemId !== GLOBAL_MENTION_ID
        && <OnlineStatusLabel status={user.is_online} />}
      >
        <Avatar src={userAvatar} alt={user.fullName} />
      </BrillMask>
      <button type="button" className="drop-down-mentions__link">
        <span className="drop-down-mentions__fullName">
          { propOr(getFullName(user), 'helpTitle', user) }
        </span>
        <span className="drop-down-mentions__username">( <span className="text--cut">{` ${user.username} `}</span> )</span>
      </button>
    </li>
  );
};

export default MentionItem;
