import { memo } from 'react';
import PropTypes from 'prop-types';
import {
  branch,
  compose,
  getContext,
  lifecycle,
  renderNothing,
  withContext,
  withHandlers,
  withState,
  withStateHandlers,
} from 'recompose';

import { connect } from 'react-redux';
import {
  path, equals,
  isNil, isEmpty, when, F, T, cond, prop,
} from 'ramda';

import moment from 'moment';
import { isNotNil, notEqual } from 'ramda-extension';
import MessageHistory from './messageHistory';

import { messengerActions, messengerSelectors } from '../../../../state/messenger';
import { uiActions, uiSelectors } from '../../../../state/ui';
import { userSelectors } from '../../../../state/user';

import { withRefs } from '../../../../utils/enchancers';
import { debounceFunc, propIdOrNull } from '../../../../utils/helpers/commonHelpers';

import {
  subscribeOnEvent,
  unSubscribeFromEvent,
} from '../../../../utils/helpers/DOMHelper/listeners';
import { getDate } from '../../../../utils/helpers/dateHelpers';
import { convertMessageBeforeSubmit } from '../../../../utils/helpers/messengerHelpers/messages';

const isNilOrZero = cond([[isNil, T], [equals(0), T], [T, F]]);

const mapStateToProps = (state, { channelId }) => {
  const activeChannel = path(['messenger', 'activeChannel', 'id'], state);
  return {
    countMessages: messengerSelectors.getCountLoadedMessages(state)(channelId),
    hasMore: messengerSelectors.getHasMoreMessages(state)(channelId),
    messages: messengerSelectors.getMessageList(state)(channelId),
    getMessageById: messengerSelectors.getMessage(state),
    lastMessageId: messengerSelectors.getLastMessageId(state)(activeChannel),
    messageTimestamps: messengerSelectors.getMessageDays(state)(activeChannel),
    submitMessageRequest: messengerSelectors.submitMessageRequest(state),
    isDeleteMessagePending: messengerSelectors.getIsDeleteMessagePending(state),
    allRenderedMessages: messengerSelectors.getAllRenderedMessages(state)(activeChannel),
    messagesCount: messengerSelectors.getCountLoadedMessages(state)(channelId),
    channel: messengerSelectors.getChannelById(state)(channelId),
    isPendingMore: messengerSelectors.getMoreMessagesPending(state),
    textAreaWasChanged: uiSelectors.getTextareaMessengerWasChanged(state),
    user: userSelectors.getUserData(state),
    scrollToMessage: messengerSelectors.getScrollToMessage(state),
  };
};

const mapDispatchToProps = ({
  getMessages: messengerActions.getLatestMessagesRequest,
  redMessagesRequest: messengerActions.readMessagesRequest,
  updateMessagesRequest: messengerActions.updateMessageRequest,
  setLastMessageId: messengerActions.setLastMessageId,
  onUpdateMessage: messengerActions.updateMessage,
  deleteMessageRequest: messengerActions.deleteMessageRequest,
  setClosedModal: uiActions.closeModal,
  setScrollToMessage: messengerActions.setScrollToMessage,
});

const whenIsThenTap = (element, func) => (element ? func(element) : element);

const setLoadingMoreMessageStateHandler = () => value => ({ loadingMoreMessage: value });

const intersectionObserver = new IntersectionObserver((entries) => {
  const [entry] = entries;
  if (entry.isIntersecting) {
    setTimeout(() => {
      entry.target.dispatchEvent(new CustomEvent('scrollIntoViewEnd'));
    }, 100);
  }
});

let isObserve = false; // todo: bad solution

const onScrollHandler = ({
  hasMore, getMessages, messagesCount, channelId,
  setLoadingMoreMessage,
  setIsShowScrollToBottom, isShowScrollToBottom, setLastScrollPosition, lastScrollPosition,
  isPendingMore, setIsScrollBottom, isScrollBottom, getRef, setLastScrollHeight, lastScrollHeight,
  isPuck, scrollToMessage, loadingMoreMessage,
}) => ({ clientHeight, scrollTop, scrollHeight }) => {
  const isMakeGetMessagesRequest = scrollTop < 200 && hasMore && !isPendingMore && !isPuck
    && !loadingMoreMessage;
  if (scrollTop <= 0) {
    getRef('scroll').scrollTop(4);
    // User can`not scroll to end messenger ( need for save position when load new messages )
  }
  if (lastScrollHeight !== scrollHeight) {
    setLastScrollHeight(scrollHeight);
    if ((scrollHeight - clientHeight) - lastScrollPosition === 0) {
      requestAnimationFrame(() => {
        // eslint-disable-next-line no-unused-expressions
        getRef('scroll') && getRef('scroll').scrollToBottom();
      });
    }
  }
  if (scrollToMessage && !isObserve) {
    requestAnimationFrame(() => {
      const el = document.getElementById(`message-item-${scrollToMessage.id}`);
      if (el) {
        el.scrollIntoView({ block: 'center' });
        el.setAttribute('data-scrolling-to-this', true);
        isObserve = true;
        intersectionObserver.observe(el);
        el.addEventListener('scrollIntoViewEnd', () => {
          setTimeout(() => el.removeAttribute('data-scrolling-to-this'),
            800);
          intersectionObserver.unobserve(el);
        });
      }
    });
  }
  if (isMakeGetMessagesRequest) {
    setLoadingMoreMessage(true);
    getMessages({
      offset: messagesCount,
      limit: 30,
      channelId,
    }, {
      showMore: true,
      callbacks: {
        success: () => setTimeout(() => setLoadingMoreMessage(false)),
      },
    });
  }
  const isScrollOnBottom = scrollHeight - (scrollTop + clientHeight) === 0;
  if (isScrollBottom !== isScrollOnBottom && lastScrollPosition !== scrollTop) {
    setIsScrollBottom(isScrollOnBottom);
  }
  if ((scrollHeight - (scrollTop + clientHeight) > 200 || isPuck)
    && !isShowScrollToBottom) {
    setIsShowScrollToBottom(true);
  }
  if (isShowScrollToBottom
    && (scrollHeight - (scrollTop + clientHeight) < 200 && !isPuck)) {
    setIsShowScrollToBottom(false);
  }
  setLastScrollPosition(scrollTop);
};

const onMountEditableHandler = ({ setEditableMessage }) => (id) => {
  setEditableMessage(id);
};

const onWillUnMountEditableHandler = ({ setEditableMessage }) => () => setEditableMessage(null);


const setSelectedMessageStateHandler = () => messageId => ({ selectedMessage: messageId });

const onDeleteMessageHandler = ({
  selectedMessage, deleteMessageRequest, channelId,
  replyMessage, setReplyMessage, setClosedModal,
}) => (event) => {
  if (event) {
    event.preventDefault();
  }
  const { id } = selectedMessage;
  deleteMessageRequest({ channelId, messageId: id }, ({
    channelId,
    callbacks: {
      success: () => {
        setClosedModal('deleteMessageModal');
      },
    },
  }));
  if (equals(
    id,
    propIdOrNull(replyMessage),
  )) {
    setReplyMessage(null);
  }
};

const onScrollToBottomHandler = ({ getRef, setIsPuck, isPuck }) => () => {
  if (isPuck) {
    setIsPuck(false);
  } else {
    requestAnimationFrame(() => {
      if (getRef('scroll')) getRef('scroll').scrollToBottom();
    });
  }
};

const onScrollToMessageHandler = ({
  getMessages, channelId,
  setIsPuck, setScrollToMessage,
}) => ({ id, created_at }) => {
  const messageElement = document.getElementById(`message-item-${id}`);
  if (messageElement) {
    messageElement.scrollIntoView({ block: 'center' });
    intersectionObserver.observe(messageElement);
    messageElement.addEventListener('scrollIntoViewEnd', () => setScrollToMessage(false));
  } else {
    const dateFrom = moment(created_at).subtract(1, 'd').format('YYYY-MM-DD');
    const dateTo = moment(created_at).add(1, 'd').format('YYYY-MM-DD');
    setIsPuck(true);
    getMessages({
      date_from: dateFrom, date_to: dateTo, channelId, limit: null, offset: 0,
    }, {
      setAsClean: true,
    });
  }
};

const onSetCorrectScrollPositionByResizeHandler = ({ isScrollBottom, getRef }) => () => {
  const whenResizeMessengerSaveScrollWatcher = () => {
    if (isScrollBottom) {
      getRef('scroll').scrollToBottom();
    }
  };
  debounceFunc(whenResizeMessengerSaveScrollWatcher, 100, false, 'whenResizeMessengerSaveScrollWatcher');
};

const onUpdateMessageContentHandler = ({ onUpdateMessage }) => (
  messageTs,
  messageId,
  channelId,
  content,
) => {
  onUpdateMessage({
    ts: messageTs,
    id: messageId,
    isPending: true,
    updated_at: getDate(),
    channel_id: channelId,
    content: convertMessageBeforeSubmit(content),
  });
};

const enhance = compose(
  getContext({
    channelId: PropTypes.number,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withRefs(),
  branch(
    ({
      isChannelLoaded, activeChannel, height, messages,
    }) => {
      const isChannelReady = isNil(activeChannel) || !isChannelLoaded
        || !isChannelLoaded.loading || !height;
      if (isEmpty(messages) || isNil(messages)) {
        return true;
      }
      if (isChannelReady) {
        return true;
      }
      if (isChannelLoaded.loading) {
        return false;
      }
      return false;
    },
    renderNothing,
  ),
  withState('idMessageWithUnreadLabel', 'setIdMessageWithUnreadLabel', null),
  withState('lastScrollPosition', 'setLastScrollPosition', false),
  withState('isAbleToGetMoreMessage', 'setIsAbleToGetMoreMessage', false),
  withState('isScrollBottom', 'setIsScrollBottom', true),
  withState('lastScrollOffset', 'setLastScrollOffset', null),
  withState('isItemsDimensionUpdated', 'setIsItemsDimensionUpdated', null),
  withState('isShowScrollToBottom', 'setIsShowScrollToBottom', false),
  withState('lastScrollHeight', 'setLastScrollHeight', 0),
  withState('isCustomPending', 'setIsCustomPending', false),
  withStateHandlers(() => ({
    loadingMoreMessage: false,
    itemsHeight: {},
    isUpdateOffsetImmediately: true,
    selectedMessage: null,
  }), {
    setLoadingMoreMessage: setLoadingMoreMessageStateHandler,
    setSelectedMessage: setSelectedMessageStateHandler,
  }),
  withHandlers({
    onSetScrollRef: ({ setRef }) => scrollRef => setRef('scroll', scrollRef),
  }),
  withHandlers({
    onMountEditable: onMountEditableHandler,
    onWillUnMountEditable: onWillUnMountEditableHandler,
  }),
  withHandlers({
    onScroll: onScrollHandler,
    onDeleteMessage: onDeleteMessageHandler,
    onScrollToBottom: onScrollToBottomHandler,
  }),
  withHandlers({
    onScrollToMessage: onScrollToMessageHandler,
    onUpdateMessageContent: onUpdateMessageContentHandler,
    onSetCorrectScrollPositionByResize: onSetCorrectScrollPositionByResizeHandler,
  }),
  memo,
  withContext({
    setSelectedMessage: PropTypes.func.isRequired,
  }, ({ setSelectedMessage }) => ({
    setSelectedMessage,
  })),
  lifecycle({
    getSnapshotBeforeUpdate(prevProps) {
      const { messages, getRef } = this.props;
      if (prevProps.messages.length !== messages.length) {
        const scrollTop = getRef('scroll').getScrollTop();
        const scrollHeight = getRef('scroll').getScrollHeight();
        const clientHeight = getRef('scroll').getClientHeight();
        return {
          scrollToBottom: (scrollHeight - clientHeight) - scrollTop,
        };
      }
      return {

      };
    },
    componentDidUpdate({
      isPuck, ...prevProps
    }, prevState, { scrollToBottom }) {
      const {
        isScrollBottom, isScrollOnBottom, getRef, scrollToIndex, messages, getMessageById,
        onScrollToBottom, user, channel, setIdMessageWithUnreadLabel, textAreaWasChanged, setIsPuck,
        scrollToMessage, onScrollToMessage, setLoadingMoreMessage, channelId,
      } = this.props;
      const scrollElement = getRef('scroll');

      if (channelId !== prevProps.channelId) {
        setIsPuck(false);
      }
      if (scrollToBottom) {
        requestAnimationFrame(() => {
          const scrollTo = scrollElement.getScrollHeight()
              - scrollElement.getClientHeight() - scrollToBottom;
          scrollElement.scrollTop(scrollTo);
          setTimeout(() => setLoadingMoreMessage(false), 1000);
        });
      }
      if (!isPuck && !isScrollOnBottom && isScrollBottom) {
        requestAnimationFrame(() => scrollElement && scrollElement.scrollToBottom());
      }
      if (prevProps.scrollToIndex !== scrollToIndex) {
        whenIsThenTap(
          scrollElement.refs.viewWrapper,
          el => el.children[this.props.scrollToIndex].scrollIntoView({ block: 'bottom', behavior: 'smooth' }),
        );
      }
      if (messages.length - prevProps.messages.length === 1) {
        if (getMessageById(
          messages[messages.length - 1],
        ).created_by === user.id) {
          onScrollToBottom();
        }
      }
      const isUpdateCountMessage = messages.length !== prevProps.messages.length;
      const idUnreadMessageNew = channel.unread_count
        ? messages[messages.length
        - channel.unread_count] : 0;
      if (idUnreadMessageNew) {
        setIdMessageWithUnreadLabel(idUnreadMessageNew);
      } else if (textAreaWasChanged || isUpdateCountMessage) {
        setIdMessageWithUnreadLabel(idUnreadMessageNew);
      }
      if (path(['scrollToMessage', 'id'], prevProps) !== prop('id', scrollToMessage) && !!prop('id', scrollToMessage)) {
        onScrollToMessage(scrollToMessage);
        isObserve = false;
      }
      if (isPuck && isPuck !== this.props.isPuck) {
        this.props.getMessages({
          offset: 0,
          limit: 30,
          channelId: this.props.channelId,
        }, {
          setAsClean: true,
          callbacks: {
            success: () => {
              requestAnimationFrame(() => {
                onScrollToBottom();
              });
            },
          },
        });
      }
    },
    shouldComponentUpdate({
      messages, height, activeChannel, isChannelLoaded, editableMessage, isShowScrollToBottom,
      isPendingMore, textAreaWasChanged, channel, idMessageWithUnreadLabel, scrollToMessage,
      isPuck, lastScrollHeight, selectedMessage,
    }) {
      return this.props.messages.length !== messages.length
        || height !== this.props.height
        || isChannelLoaded !== this.props.isChannelLoaded
      || activeChannel !== this.props.activeChannel
        || height !== this.props.height
      || this.props.messages.length !== messages.length
        || this.props.editableMessage !== editableMessage
        || isShowScrollToBottom !== this.props.isShowScrollToBottom
        || isPendingMore !== this.props.isPendingMore
        || channel.id !== this.props.channel.id
        || idMessageWithUnreadLabel !== this.props.idMessageWithUnreadLabel
        || textAreaWasChanged !== this.props.textAreaWasChanged
        || notEqual(scrollToMessage, scrollToMessage)
        || isPuck !== this.props.isPuck
        || lastScrollHeight !== this.props.lastScrollHeight
        || selectedMessage !== this.props.selectedMessage
        || (channel.unread_count !== this.props.unread_count && isNilOrZero(channel.unread_count));
    },
    componentDidMount() {
      const {
        scrollToMessage,
        getRef, setIdMessageWithUnreadLabel, textAreaWasChanged, messages,
        onSetCorrectScrollPositionByResize, idMessageWithUnreadLabel, channel, onDidMount,
      } = this.props;
      requestAnimationFrame(() => {
        const scrollElement = getRef('scroll');
        if (scrollElement) {
          scrollElement.view.addEventListener('touchmove', (e) => {
            if (getRef('scroll').getScrollTop() <= 4) {
              getRef('scroll').scrollTop(8);
              e.stopPropagation();
              e.preventDefault();
              return false;
            }
            return true;
          });
        }
        when(isNil,
          compose(
            () => when(isNotNil, () => scrollElement.scrollToBottom())(scrollElement),
            () => subscribeOnEvent(window, 'resize', onSetCorrectScrollPositionByResize),
          ))(scrollToMessage);
      });
      if (idMessageWithUnreadLabel !== channel.unread_count) {
        const idUnreadMessageNew = channel.unread_count
          ? messages[messages.length - channel.unread_count] : 0;
        if (idUnreadMessageNew) {
          setIdMessageWithUnreadLabel(idUnreadMessageNew);
        } else if (textAreaWasChanged) {
          setIdMessageWithUnreadLabel(idUnreadMessageNew);
        }
      }
      onDidMount();
    },
    componentWillUnmount() {
      const {
        onSetCorrectScrollPositionByResize,
      } = this.props;
      unSubscribeFromEvent(window, 'resize', onSetCorrectScrollPositionByResize);
    },
  }),
  memo,
);

export default enhance(MessageHistory);
