import { push } from 'connected-react-router';
import { hrActions, hrTypes } from '../store/candidate';

// eslint-disable-next-line import/prefer-default-export
export const setCandidateMiddleware = ({ dispatch }) => next => (action) => {
  if (action.type === hrTypes.UPDATE_HR_CANDIDATE_SUCCESS) {
    dispatch(push(`/hr-management/candidate/${action.payload.whrCandidate.id}`));
    next(hrActions.setHrCandidate({
      candidate: action.payload.whrCandidate,
    }));
  } else {
    next(action);
  }
};
