import {
  compose, getContext, withHandlers, lifecycle, withState, withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { pipelinesSelectors, pipelinesActions } from '../../../../state/pipelines';
import { uiActions } from '../../../../state/ui';
import PipelineItem from './pipelineItem';

const mapStateToProps = (state, props) => ({
  pipeline: pipelinesSelectors.getPipelineById(state)(props.id),
  pipelineStatuses: pipelinesSelectors.getPipelineStatusesSelector(state)(props.id),
});

const mapDispatchToProps = ({
  openModal: uiActions.openModal,
  getPipelineStatusesRequest: pipelinesActions.getPipelineStatusesRequest,
});

const onSelectPipelineHandler = modalName => ({
  setSelectedPipelineId,
  pipeline, openModal,
}) => () => {
  setSelectedPipelineId(pipeline.id);
  openModal(modalName);
};

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    setSelectedPipelineId: PropTypes.func.isRequired,
  }),
  withStateHandlers(() => ({
    isOpen: false,
  }), {
    onToggleStatuses: ({ isOpen }) => () => ({
      isOpen: !isOpen,
    }),
  }),
  withState('isStatusesMounted', 'setIsStatusesMounted', false),
  withHandlers({
    onEditPipeline: onSelectPipelineHandler('editPipelineModal'),
    onDeletePipeline: onSelectPipelineHandler('deletePipelineModal'),
  }),
  lifecycle({
    componentDidUpdate(prevState) {
      const {
        isOpen,
        isStatusesMounted,
        setIsStatusesMounted,
        getPipelineStatusesRequest,
        pipeline: { id: pipelineId },
      } = this.props;
      if (isOpen && !prevState.isOpen && !isStatusesMounted) {
        setIsStatusesMounted(true);
        getPipelineStatusesRequest({ pipelineId });
      }
    },
  }),
);

export default enhancer(PipelineItem);
