import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { messengerActions } from '../../state/messenger';

const mapDispatchToProps = ({
  getGroupChannelsRequest: messengerActions.getGroupChannelsRequest,
});

const withGroupChannels = () => compose(
  connect(null, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.getGroupChannelsRequest(null);
    },
  }),
);

export default withGroupChannels;
