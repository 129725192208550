import React from 'react';
import PropTypes from 'prop-types';
import { TaskList } from '../../../../newContainers/rightSideBar/components/taskList';

import './style.sass';

const PinnedTasks = ({
  entities, result, isPending, onUnpin,
}) => (
  <TaskList
    onDelete={onUnpin}
    pending={isPending}
    entities={entities}
    result={result}
  />
);

PinnedTasks.propTypes = {
  entities: PropTypes.instanceOf(Object).isRequired,
  result: PropTypes.arrayOf(PropTypes.number).isRequired,
  isPending: PropTypes.bool.isRequired,
  onUnpin: PropTypes.func.isRequired,
};

export default PinnedTasks;
