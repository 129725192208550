import { useCallback, useEffect, useState } from 'react';
import api from '../../api/tasks.service';

/**
 *
 * @returns {{importantTasks: []}}
 */

export const useImportantTasks = () => {
  const [importantTasks, setImportantTasks] = useState([]);

  const getImportantTasks = useCallback(async () => {
    const data = await api.getImportantTasksRequest();
    if (data) {
      setImportantTasks(data);
    }
  });

  useEffect(() => {
    getImportantTasks();
  }, []);
  return {
    importantTasks,
  };
};
