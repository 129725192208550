import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getModal } from '../../../../state/ui/selectors';
import { closeModal } from '../../../../state/ui/actions';
import DeleteTaskStatusModal from './deleteTaskStatusModal';
import { projectActions } from '../../../../state/project';

const mapStateToProps = state => ({
  isOpen: getModal(state)('deleteTasksStatus'),
});

const mapDispatchToProps = ({
  onClose: () => closeModal('deleteTasksStatus'),
  deleteStatusTaskRequest: projectActions.deleteStatusOfTasksRequest,
});

const onDeleteHandler = ({
  match: { params: { id } }, deleteStatusTaskRequest,
  statusId, onClose,
}) => () => {
  deleteStatusTaskRequest({ statusId, projectId: id }, {
    callbacks: {
      success: onClose,
    },
  });
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onDelete: onDeleteHandler,
  }),
);
export default enhance(DeleteTaskStatusModal);
