import React from 'react';

import './style.sass';

export const Preload = () => (
  <div className="preload">
    <div className="preload__element">
      <span className="preload__elements-item icon-hexagon-shape" />
      <span className="preload__elements-item icon-hexagon-shape" />
      <span className="preload__elements-item icon-hexagon-shape" />
    </div>
  </div>
);
