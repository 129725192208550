import { Draggable, Droppable } from 'react-beautiful-dnd';
import React from 'react';
import PropTypes from 'prop-types';
import { ScrollWrapper } from '../../../../../../ui-kit';
import InvoiceCard from '../invoiceCard';

const getStyleForDroppable = (isDragging, updateDroppable, droppableId, snapshot) => {
  if (isDragging && updateDroppable === droppableId) {
    return 'column-board__body--update';
  }
  if (isDragging && !snapshot.draggingFromThisWith) {
    return 'column-board__body--active';
  }
  return '';
};

const DroppableArea = ({
  statusId, isDragging, updateDroppable, invoices, invoiceStatusId,
}) => (
  <Droppable droppableId={invoiceStatusId}>
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        className="column-board__drag"
        style={{
          backgroundColor: provided.isDragging ? 'green' : 'lightblue',
        }}
        {...provided.droppableProps}
      >
        <ScrollWrapper className="pipe-view__scroll-wrapper-column">
          <div className={`column-board__body ${getStyleForDroppable(isDragging, updateDroppable, statusId, snapshot)}`}>
            {invoices.map((invoice, index) => (
              <Draggable
                key={`lead-key ${invoice.id}`}
                index={index}
                draggableId={invoice.id}
              >
                {(providedDrag, draggableSnapshot) => (
                  <div
                    className="project-task__drag-wrapper"
                    ref={providedDrag.innerRef}
                    {...providedDrag.draggableProps}
                    {...providedDrag.dragHandleProps}
                  >
                    <InvoiceCard
                      key={`lead ${invoice.id}`}
                      isDragging={draggableSnapshot.isDragging}
                      invoice={invoice}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        </ScrollWrapper>
      </div>
    )}
  </Droppable>
);

DroppableArea.propTypes = {
  statusId: PropTypes.number.isRequired,
  isDragging: PropTypes.bool.isRequired,
  updateDroppable: PropTypes.number,
  invoices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

DroppableArea.defaultProps = {
  updateDroppable: null,
};

export default DroppableArea;
