import {
  curry, compose, map, filter, isEmpty, isNil, objOf, cond, identity, toPairs, T, is, ifElse,
} from 'ramda';
import { isNotNil } from 'ramda-extension';
import { checkIfValueIncluded } from '../commonHelpers';

const filterAndTransformCondition = curry((filterAcc, acc, data) => compose(
  map(acc),
  filter(filterAcc),
  toPairs,
)(data));

const filterParamKey = (item) => {
  const value = ifElse(is(Array), filter(isNotNil), identity)(item[1]);
  return !isEmpty(value) && !isNil(value);
};

const makeNewFilterValue = curry((merger, name, addNewValue, value) => compose(
  merger,
  objOf(name),
  addNewValue,
)(value));

const handlerFilter = (condition, errorHandler, successHandler, value) => cond([
  [condition, errorHandler],
  [T, successHandler],
])(value);

const updateFilterAndUrlParams = curry((setFilters, actionUrl, filters, urlParam) => {
  setFilters(filters);
  actionUrl(urlParam);
  return filters;
});

const setFilterForSingleParam = curry((merger, setFilters, onSetUrlParam, name, value) => {
  const updateSprintFilter = makeNewFilterValue(merger, name, identity);
  const filters = handlerFilter(isNil, updateSprintFilter, updateSprintFilter, value);
  updateFilterAndUrlParams(setFilters, onSetUrlParam, filters, ({ [name]: value }));
  return value;
});

export {
  setFilterForSingleParam,
  checkIfValueIncluded,
  filterAndTransformCondition,
  filterParamKey,
  makeNewFilterValue,
  updateFilterAndUrlParams,
  handlerFilter,
};
