import React from 'react';
import PropTypes from 'prop-types';
import { pathOr, prop } from 'ramda';
import { Draggable } from 'react-beautiful-dnd';

import { ButtonsCollection, FormsCollection } from '../../../../../ui-kit';
import { STATUS, STATUSES_BY_ID } from '../../../../../constants/tasks';

const StatusItem = ({
  setEditingStatusId, editingStatusId, onDeleteTaskStatus, item, stateOfStatusesOptions,
  handleSubmit, handleChange, values, errors, index,
}) => (
  <form
    key={`status-item-drop-${index}`}
    onSubmit={handleSubmit}
    className="status-item__droppable"
  >
    <Draggable index={index} key={`status-item-drag-${index}`} draggableId={item.id}>
      {
              providedDrag => (
                <div
                  key={`status${item.id}`}
                  className={`statuses-list__row status-item
                                ${editingStatusId === item.id ? 'status-item--editing' : ''}`}
                  ref={providedDrag.innerRef}
                  {...providedDrag.draggableProps}
                  {...providedDrag.dragHandleProps}
                >
                  <div className="statuses-list__col">
                    <div className="status-item burger-dnd">
                      <span />
                      <span />
                      <span />
                    </div>
                    {item.id === editingStatusId ? (
                      <FormsCollection.Input
                        className="statuses-list__input-add"
                        name="title"
                        value={prop('title', values)}
                        error={pathOr('', ['title', 'message'], errors)}
                        onChange={handleChange}
                      />
                    ) : (
                      <h4 className="status-item__title">{item.title}</h4>
                    )}
                  </div>
                  <div className="statuses-list__col">
                    {
                      editingStatusId === item.id ? (
                        <FormsCollection.Select
                          name="state"
                          value={prop('state', values)}
                          onChange={handleChange}
                          data={stateOfStatusesOptions}
                        />
                      ) : (
                        <span>{pathOr('-', [prop([item.state], STATUSES_BY_ID), 'title'], STATUS)}</span>
                      )
                    }
                  </div>
                  <div className="statuses-list__col">
                    {
                      editingStatusId === item.id ? (
                        <>
                          <ButtonsCollection.ButtonBrill
                            className="button-brill--fill statuses-list__add-button"
                            type="submit"
                          >

                          Save
                          </ButtonsCollection.ButtonBrill>
                          <button
                            type="button"
                            className="button--cancel statuses-list__add-button-cancel"
                            onClick={() => setEditingStatusId(null)}
                          >

                          Cancel
                          </button>
                        </>
                      ) : (
                        <>
                          <ButtonsCollection.ButtonIcons
                            onClick={() => setEditingStatusId(item.id)}
                            title="Edit status"
                          >
                            <span className="icon-edit-icon" />
                          </ButtonsCollection.ButtonIcons>
                          {
                          !item.is_system ? (
                            <ButtonsCollection.ButtonIcons
                              onClick={() => onDeleteTaskStatus(item.id)}
                              title="Delete status"
                            >
                              <span className="icon-trash" />
                            </ButtonsCollection.ButtonIcons>
                          ) : (
                            <div className="statuses-list__empty-space" />
                          )
                        }
                        </>
                      )
                    }
                  </div>
                </div>
              )
            }
    </Draggable>
  </form>
);

StatusItem.propTypes = {
  setEditingStatusId: PropTypes.func.isRequired,
  editingStatusId: PropTypes.number.isRequired,
  onDeleteTaskStatus: PropTypes.func.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  stateOfStatusesOptions: PropTypes.instanceOf(Array).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
};

export default StatusItem;
