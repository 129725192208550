import React from 'react';
import PropTypes from 'prop-types';
import ProductsItem from '../productsItem';
import './style.sass';

const ProductsList = ({ productsForClient, onDeleteProductModal }) => (
  <div className="products-list">
    {productsForClient && productsForClient.length ? (
      productsForClient.map(product => (
        <ProductsItem
          key={product.id}
          product={product}
          onDeleteProductModal={onDeleteProductModal}
        />
      ))
    ) : <div className="client-tasks__empty">There are no products yet</div>}
  </div>
);

ProductsList.propTypes = {
  productsForClient: PropTypes.instanceOf(Array).isRequired,
  onDeleteProductModal: PropTypes.func.isRequired,
};

export default ProductsList;
