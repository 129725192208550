import {
  compose, lifecycle, withHandlers, withProps,
} from 'recompose';
import {
  pathOr, __, isEmpty,
} from 'ramda';
import { connect } from 'react-redux';
import LeadInfo from './leadInfo';
import rules from '../../modals/rules';
import { withEditLead, withLeadsCRUD, preloaderWhileLoading } from '../../../../utils/enchancers';
import { leadsActions } from '../../../../state/leads';
import { PIPELINE_TYPES } from '../../../../constants/crm';
import { callNotification } from '../../../../utils/helpers/notifies';
import { getTimezoneAutocompleteHelper } from '../../../../utils/helpers/dateHelpers';
import { customFieldsActions, customFieldsSelectors } from '../../../../state/customFields';
import { customFields } from '../../../../constants';
import {
  getCustomFieldValues,
  getCustomValuesList,
} from '../../../../utils/helpers/crmHelpers';

const mapStateToProps = state => ({
  customFieldEntities: customFieldsSelectors.getCustomFieldsEntities(state),
  // eslint-disable-next-line max-len
  customFieldsList: customFieldsSelectors.getCustomFieldsByModel(state)(customFieldsSelectors.getCustomFieldsList(state), customFields.CUSTOM_FIELDS_MODELS.LEAD_CLIENT),
  editCustomFieldValue: customFieldsActions.editCustomFieldValueRequest,
});


const mapDispatchToProps = {
  convertLeadToClient: leadsActions.convertLeadToClientRequest,
  editCustomFieldValue: customFieldsActions.editCustomFieldValueRequest,
  getCustomFields: customFieldsActions.getCustomFieldsRequest,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withEditLead,
  withProps(({
    customFieldEntities, lead, initialValues,
  }) => {
    const [customFieldValues, initialCustomValues] = getCustomFieldValues(
      lead, customFieldEntities,
    );
    return {
      customFieldValues,
      initialCustomValues,
      initialValues: {
        ...initialValues,
        ...initialCustomValues,
      },
    };
  }),
  withLeadsCRUD({
    pendingSelector: 'editLeadRequest',
    rules,
    onSubmit: (formData, {
      // resetForm,
      props: {
        editLead, selectedLeadId, setIsNeedRefresh, convertLeadToClient, onSuccess,
        editCustomFieldValue, customFieldsList, customFieldEntities,
      },
    }) => {
      const getFormField = pathOr(null, __, formData);
      if (isEmpty(formData.contact.email)) {
        // eslint-disable-next-line no-param-reassign
        formData.contact.email = null;
      }
      if (isEmpty(formData.contact.avatar)) {
        // eslint-disable-next-line no-param-reassign
        formData.contact.avatar = null;
      }

      const customValuesList = getCustomValuesList({
        customFieldsList,
        selectedId: selectedLeadId,
        customFieldEntities,
        formData,
      });
      editCustomFieldValue({ customFieldsData: customValuesList });
      editLead({
        id: selectedLeadId,
        contact: {
          ...formData.contact,
          timezone: formData.contact.timezone.value,
        },
        pipeline_id: getFormField(['pipeline_id', 'value']),
        pipeline_status_id: getFormField(['pipeline_status_id', 'value']),
      }, {
        callbacks: {
          success: () => {
            if (getFormField(['pipeline_status_id', 'type']) === PIPELINE_TYPES.WON) convertLeadToClient({ id: selectedLeadId, ...formData });
            // resetForm({});
            if (setIsNeedRefresh) {
              setIsNeedRefresh(true);
            }
            if (onSuccess) {
              onSuccess();
            }
          },
          error: () => callNotification.error('Email should be unique'),
        },
      });
    },
  }),
  preloaderWhileLoading({
    dimension: 75,
    isLoading: props => props.isPending,
  }),
  withHandlers({
    getAutocompleteHandler: getTimezoneAutocompleteHelper,
  }),
  lifecycle({
    componentDidMount() {
      const { getCustomFields } = this.props;
      getCustomFields();
    },
  }),
);
export default enhance(LeadInfo);
