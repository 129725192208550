import React, { memo } from 'react';
import './style.sass';
import { Pagination, Grid, EmptyResult } from '../../../../../ui-kit';
import { PeopleHeaderRow } from './PeopleHeaderRow';
import { PeopleBodyRow } from './PeopleBodyRow';
import { usePeopleContext } from '../../context/usePeopleContext';

const GRID_NAME = 'peopleList';
/**
 * @param limit {number}
 * @param offset {number}
 * @param page {number}
 * @param order {string}
 * @param sort {string}
 * @param onSortBy {function}
 * @returns {JSX.Element}
 * @constructor
 */

const PeopleList = ({
  order, sort, onSortBy, limit, offset, page,
}) => {
  const {
    count, peopleData, setSelectedUser, isLoading,
  } = usePeopleContext();
  const onDeleteUser = (user) => {
    setSelectedUser({ ...user, toDelete: true });
  };
  return (
    <Grid.Wrapper
      isLoading={isLoading}
      isKeepOptions
      name={GRID_NAME}
      className="table-users"
      pagination={(
        <Pagination
          count={count}
          component={GRID_NAME}
          pagination={{ limit, offset, page }}
          className="users-list__pagination"
        />
        )}
    >
      <PeopleHeaderRow sort={sort} order={order} onSortBy={onSortBy} />
      {
          peopleData.length !== 0 ? peopleData.map(({
            id, username, email, vacation, avatar, first_name: firstName, last_name: lastName,
          }) => (
            <PeopleBodyRow
              id={id}
              key={id}
              username={username}
              email={email}
              vacation={vacation}
              avatar={avatar}
              firstName={firstName}
              lastName={lastName}
              onDeleteUser={onDeleteUser}
            />
          )) : <EmptyResult text="No search results found" />}
    </Grid.Wrapper>
  );
};

PeopleList.defaultProps = {
  order: '',
  sort: '',
  onSortBy: () => {},
};

export default memo(PeopleList);
