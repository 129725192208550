import * as newTaskActions from './actions';
import * as newTaskSelectors from './selectors';
import * as newTaskOperations from './operations';
import * as newTaskSchemas from './schema';
import newTaskTypes from './types';
import reducer from './reducers';

export {
  newTaskActions,
  newTaskSelectors,
  newTaskOperations,
  newTaskSchemas,
  newTaskTypes,
};

export default reducer;
