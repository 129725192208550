import React, { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ButtonsCollection } from '../../..';
import { GitlabCommitsGrid } from '../../GitlabCommits/GitlabCommitsGrid';

import api from '../../../../api/projects.service';
import { callNotification } from '../../../../utils/helpers/notifies';
import { useCommitsFilters } from '../../hooks';

import './style.sass';
import { debounce } from '../../../../utils/helpers/commonHelpers';

const DEBOUNCE_TIMEOUT = 800;
const FIRST_PAGE = 1;
const INITIAL_COMMITS_COUNT = 0;

export const GitlabProjectItem = ({
  gitlabProjectId, name, webUrl,
}) => {
  const { id: projectId } = useParams();
  const { t } = useTranslation(['common']);
  const [isOpen, setIsOpen] = useState(false);
  const [commits, setCommits] = useState([]);
  const [totalCommits, setTotalCommits] = useState(INITIAL_COMMITS_COUNT);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(FIRST_PAGE);

  const onGoPreviousPage = () => {
    setCurrentPage(prevState => prevState - 1);
  };

  const onGoNextPage = () => {
    if (currentPage < totalPages) { setCurrentPage(prevState => prevState + 1); }
  };

  const {
    titleValue,
    authorValue,
    onChangeTitleValue,
    onChangeAuthorValue,
  } = useCommitsFilters();


  const toggleOpen = () => {
    setIsOpen(prev => !prev);
  };


  const fetchRepositoryCommits = useCallback(debounce(async (params) => {
    try {
      const { data, total, lastPage } = await api.getRepositoryCommits(projectId, gitlabProjectId, params);
      setTotalCommits(total);
      setTotalPages(lastPage);
      setCommits(data);
    } catch (error) {
      callNotification.error(t('Can\'t get commits'));
    }
  }, DEBOUNCE_TIMEOUT), []);

  useEffect(() => {
    fetchRepositoryCommits({ page: currentPage, title: titleValue, author: authorValue });
  },
  [currentPage, titleValue, authorValue]);

  useEffect(() => {
    setCurrentPage(FIRST_PAGE);
  }, [titleValue, authorValue]);

  const onSyncGitlabProjectsHandler = useCallback(async () => {
    try {
      const { message } = await api.syncRepository(projectId, gitlabProjectId);
      callNotification.success(t(`${message}`));
    } catch (error) {
      callNotification.error(t(`Synchronization error. ${error.response.data.result.message}`));
    }
  }, [projectId, gitlabProjectId]);

  return (
    <li className={classNames('gitlab-project__item', { 'gitlab-project__item--active': isOpen })}>
      <div className="gitlab-project__item-header">
        <div className="gitlab-project__info">
          <ButtonsCollection.ButtonIcons
            className="gitlab-project__toggle-button"
            title={`${isOpen ? t('Show less') : t('Show more')}`}
            onClick={toggleOpen}
          >
            <span className="icon-expand-arrow-dn" />
          </ButtonsCollection.ButtonIcons>
          <a href={webUrl} className="gitlab-project__link">
            {name}
          </a>
        </div>
        <div className="gitlab-project__remote">
          <ButtonsCollection.ButtonBrill
            onClick={onSyncGitlabProjectsHandler}
            className="button--sm button-brill--fill gitlab-project__sync-btn"
          >
        Sync
          </ButtonsCollection.ButtonBrill>
        </div>
      </div>
      <GitlabCommitsGrid
        name={`gitlab-project-${gitlabProjectId}`}
        isOpen={isOpen}
        onGoPreviousPage={onGoPreviousPage}
        onGoNextPage={onGoNextPage}
        totalPages={totalPages}
        commits={commits}
        currentPage={currentPage}
        totalCommits={totalCommits}
        titleValue={titleValue}
        authorValue={authorValue}
        onChangeAuthorValue={onChangeAuthorValue}
        onChangeTitleValue={onChangeTitleValue}
      />
    </li>
  );
};
