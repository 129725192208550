import React from 'react';
import { CARNATION, SALMON, GRANNY_SMITH_APPLE } from '../../constants/colors';
import './style.sass';

/**
 *
 * @param percents {number}
 * @returns {JSX.Element}
 * @constructor
 */
const HALF = 50;
const MORE_THAN_HALF = 99;
const INITIAL_PERCENT = 0;
const getPercents = percents => `${percents}%`;
const getColor = (percents) => {
  if (percents < HALF) {
    return CARNATION;
  } if (percents >= HALF && percents <= MORE_THAN_HALF) {
    return SALMON;
  }
  return GRANNY_SMITH_APPLE;
};
export const StatusBar = ({ percents = INITIAL_PERCENT }) => (
  <div className="progress-bar" title={getPercents(percents)}>
    <div className="progress-bar__line" style={{ width: getPercents(percents), backgroundColor: getColor(percents) }} />
  </div>
);
