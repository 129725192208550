import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import {
  ButtonsCollection, AsyncAutoComplete, ModalWindow, FormsCollection,
} from '../../..';
import { getUsersListAutocompleteRequest } from '../../../../state/users/actions';
import rules from './rules';
import './style.sass';

const AUTOCOMPLETE_LIMIT = 10;

/**
 *
 * @param isPending {boolean}
 * @param titleMain {string}
 * @param titleItem {string}
 * @param membersList {array}
 * @param onInvite {function}
 * @param setIsOpenedInviteModal {function}
 * @param isOpen {boolean}
 * @returns {JSX.Element}
 */

export const InviteMembersModal = ({
  isPending,
  titleMain,
  titleItem,
  membersList,
  onInvite,
  setIsOpenedInviteModal,
  isOpen,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'validation', 'chat']);

  const closeModal = useCallback(() => setIsOpenedInviteModal(false), []);

  const getUsersAutocompleteAction = useCallback((object, callback) => {
    dispatch(getUsersListAutocompleteRequest(object, callback));
  }, []);

  const getAutocompleteHandler = (fieldValue, callback) => {
    getUsersAutocompleteAction({
      q: fieldValue,
      limit: AUTOCOMPLETE_LIMIT,
    }, {
      callbacks: {
        success: ({ users }) => {
          const usersResult = users.filter(({ value }) => !membersList.includes(value));
          callback(usersResult);
        },
      },
    });
  };

  const {
    control, setValue, reset, handleSubmit,
  } = useForm({
    resolver: yupResolver(rules),
  });

  const onSubmit = useCallback((formValues) => {
    const { members } = formValues;
    const memberIds = members.map(member => member.value);
    onInvite(memberIds, {
      success: () => { closeModal(); reset(); },
    });
  });

  const onChange = (name, value) => {
    setValue(name, value);
  };

  const onCloseModal = () => {
    reset();
    closeModal();
  };
  return (
    <ModalWindow
      isOpen={isOpen}
      onClose={onCloseModal}
      modalName="inviteChannelMembersModal"
      title={`Invite ${titleItem}s to ${titleMain.toLowerCase()}`}
      className="modal-window__create-chanel"
    >
      <FormsCollection.FormWrapper handleSubmit={handleSubmit(onSubmit)} className="invite-channel-members">
        <div className="invite-channel-members__row invite-channel-members__row--members">
          <div className="invite-channel-members__col-label">
            {t('Members')}
          </div>
          <div className="invite-channel-members__col-fields">
            <Controller
              name="members"
              control={control}
              defaultValue=""
              render={({ value, error, field }) => (
                <AsyncAutoComplete
                  isMulti
                  name="members"
                  loadOptions={getAutocompleteHandler}
                  placeholder={`${t('Choose members')}...`}
                  closeMenuOnSelect={false}
                  value={value}
                  placeholderJump={t('Members')}
                  error={error}
                  onChange={() => onChange('members', value)}
                  {...field}
                />)}
            />
          </div>
        </div>
        <div className="button-group">
          <ButtonsCollection.ButtonBrill
            pending={isPending}
            className="button--md invite-channel-members__button-save button-brill--fill"
            type="submit"
          >
            {t('Invite')}
          </ButtonsCollection.ButtonBrill>
        </div>
      </FormsCollection.FormWrapper>
    </ModalWindow>
  );
};
