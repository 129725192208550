import {
  branch, compose, renderNothing, withHandlers, withProps,
} from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';

import TimeEntryItem from './timeEntryItem';
import { taskSelectors } from '../../../../../state/task';
import { getFullName } from '../../../../../utils/helpers/userHelpers';
import { withUserProfile } from '../../../../../utils/enchancers';
import { getTimeStringBySeconds } from '../../../../../utils/helpers/dateHelpers';
import { formatDate } from '../../../../../utils/helpers/commonHelpers';
import { usersSelectors } from '../../../../../state/users';
import { getImageUrl } from '../../../../../utils/helpers/requestHelpers';
import { IMAGES_SIZE } from '../../../../../constants/ui';

const mapStateToProps = state => ({
  items: taskSelectors.getTimeEntries(state),
  users: usersSelectors.getUsersEntities(state),
});

const onEditTimeEntryHandler = ({ onEdit, id }) => () => onEdit(id);
const onDeleteTimeEntryHandler = ({ onDelete, id }) => () => onDelete(id);

const enhance = compose(
  connect(mapStateToProps),
  branch(({ items, id }) => !items[id], renderNothing),
  withUserProfile,
  withTranslation(['common', 'tasks']),
  withHandlers({
    onEditTimeEntry: onEditTimeEntryHandler,
    onDeleteTimeEntry: onDeleteTimeEntryHandler,
  }),
  withProps(({
    items, id, prevId, onSetUserProfile, users,
  }) => {
    const {
      user_id, time, created_at, date,
    } = items[id];
    const user = users[user_id];
    return {
      id,
      userName: user ? getFullName(user) : '',
      userAvatar: user ? getImageUrl(user.avatar, IMAGES_SIZE.beforeSmall) : '',
      onShowUserProfile: () => onSetUserProfile(user_id),
      estimatedTime: getTimeStringBySeconds(time),
      dateLog: moment(date).format('DD MMMM YYYY'),
      createdAt: formatDate(created_at, 'h:m:s A'),
      isNewDateFlag: prevId === null || moment(date).date() !== moment(items[prevId].date).date(),
    };
  }),
);
export default enhance(TimeEntryItem);
