/* eslint-disable max-len */
import {
  curry, pathOr, omit, filter,
} from 'ramda';
import {
  setBacklogTask,
  setBacklogTaskToList,
  setSprintTask,
  setSprintTaskToList,
  deleteSprintEntity,
  reorderSprint,
  reorderCategoryTask,
  deleteCategoryTaskEntity,
  reorderProjectMembers,
} from '../../../state/project/actions';
import { getSprintsList, getProjectSprintsTasks } from '../../../state/project/selectors';

const setTask = curry((setItemToEntities, setItemToList, author, assigneeUser, data, itemId, accum, { isNewTask }) => {
  accum(setItemToEntities(data));
  accum(setItemToList({ itemId, sprintId: data[itemId].sprint_id, isNewTask }));
});

const setTaskToSprint = setTask(
  setSprintTask,
  setSprintTaskToList,
);

const setTaskToBacklog = setTask(
  setBacklogTask,
  setBacklogTaskToList,
);

const deleteSprint = (sprintId, state, next) => {
  const result = getSprintsList(state);
  const entities = getProjectSprintsTasks(state);

  next(reorderSprint(filter(id => id !== sprintId, result)));
  next(deleteSprintEntity(omit([sprintId], entities)));
};

const deleteCategoryTask = (state, categoryId, next) => {
  const entities = pathOr({}, ['project', 'entities', 'categories'], state);
  const result = pathOr({}, ['project', 'result', 'categories'], state);
  next(reorderCategoryTask(filter(id => categoryId !== id, result)));
  next(deleteCategoryTaskEntity(omit([categoryId], entities)));
};

const deleteProjectMember = (state, memberId, next) => {
  const result = pathOr({}, ['project', 'result', 'members'], state);
  next(reorderProjectMembers(filter(id => memberId !== id, result)));
};

export {
  setTaskToSprint,
  setTaskToBacklog,
  deleteSprint,
  deleteCategoryTask,
  deleteProjectMember,
};
