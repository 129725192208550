export default {
  GET_SETTING_USERS_REQUEST: 'GET_SETTING_USERS_REQUEST',
  SET_SETTING_USERS: 'SET_SETTING_USERS',
  GET_SETTING_USER_REQUEST: 'GET_SETTING_USER_REQUEST',
  SET_SETTING_USER: 'SET_SETTING_USER',
  UPDATE_SETTING_USER_REQUEST: 'UPDATE_SETTING_USER_REQUEST',
  DELETE_SETTING_USER_REQUEST: 'DELETE_SETTING_USER_REQUEST',
  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  RESEND_USER_INVITE_REQUEST: 'RESEND_USER_INVITE_REQUEST',
};
