import * as yup from 'yup';

const rules = yup.object().shape({
  first_name: yup
    .string()
    .required('First name is required'),
  last_name: yup
    .string()
    .required('Last name is required'),
  username: yup
    .string()
    .required('Username is required'),
  email: yup
    .string()
    .email('Email is not valid')
    .required('Email is required'),
});

export default rules;
