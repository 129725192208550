import { createSelector } from 'reselect';
import {
  identity, memoizeWith, path, propOr,
} from 'ramda';

const getInvoicesEntities = createSelector(
  path(['invoices', 'invoicesList', 'entities']),
  identity,
);

const getInvoicesList = createSelector(
  path(['invoices', 'invoicesList', 'result']),
  identity,
);

const getInvoicesCount = createSelector(
  path(['invoices', 'summary', 'count']),
  identity,
);

const getInvoiceById = createSelector(
  getInvoicesEntities,
  entities => memoizeWith(identity, id => propOr(null, id, entities)),
);

const getInvoicesByStatusIdFormList = invoicesBoard => memoizeWith(
  identity, statusId => propOr([], statusId, invoicesBoard),
);

const getInvoicesBoard = createSelector(
  path(['invoices', 'board']),
  identity,
);

const getInvoicesByStatusId = createSelector(
  getInvoicesBoard,
  getInvoicesByStatusIdFormList,
);

const getListView = createSelector(
  path(['invoices', 'invoiceListView']),
  identity,
);

const getInvoiceItemsList = createSelector(
  path(['invoices', 'newInvoicePage', 'invoiceItems', 'result']),
  identity,
);

const getInvoiceItemsEntities = createSelector(
  path(['invoices', 'newInvoicePage', 'invoiceItems', 'entities']),
  identity,
);

const getInvoiceItemById = createSelector(
  getInvoiceItemsEntities,
  entities => memoizeWith(identity, id => propOr(null, id, entities)),
);

const getTax = createSelector(
  path(['invoices', 'newInvoicePage', 'tax', 'value']),
  identity,
);

const getDiscount = createSelector(
  path(['invoices', 'newInvoicePage', 'discount', 'value']),
  identity,
);

const getTaxState = createSelector(
  path(['invoices', 'newInvoicePage', 'tax']),
  identity,
);

const getDiscountState = createSelector(
  path(['invoices', 'newInvoicePage', 'discount']),
  identity,
);

const getTaxPaymentType = createSelector(
  path(['invoices', 'newInvoicePage', 'tax', 'paymentType']),
  identity,
);

const getDiscountPaymentType = createSelector(
  path(['invoices', 'newInvoicePage', 'discount', 'paymentType']),
  identity,
);

const getCurrencySign = createSelector(
  path(['invoices', 'newInvoicePage', 'currencySign']),
  identity,
);

const getCurrentInvoice = createSelector(
  path(['invoices', 'currentInvoice']),
  identity,
);

const getCurrentInvoiceTax = createSelector(
  getCurrentInvoice,
  currentInvoice => propOr(0, 'tax', currentInvoice),
);

const getCurrentInvoiceDiscount = createSelector(
  getCurrentInvoice,
  currentInvoice => propOr(0, 'discount', currentInvoice),
);

const getTotalCount = createSelector(
  path(['invoices', 'summary', 'totalCount']),
  identity,
);

const getInvoiceToSend = createSelector(
  path(['invoices', 'invoiceToSend']),
  identity,
);

const getInvoiceSummary = createSelector(
  path(['invoices', 'summary', 'statuses']),
  identity,
);

export default {
  getInvoicesEntities,
  getInvoicesList,
  getInvoiceById,
  getInvoicesCount,
  getInvoicesByStatusId,
  getListView,
  getInvoiceItemsEntities,
  getInvoiceItemsList,
  getInvoiceItemById,
  getTax,
  getDiscount,
  getTaxPaymentType,
  getDiscountPaymentType,
  getCurrencySign,
  getTaxState,
  getDiscountState,
  getCurrentInvoice,
  getCurrentInvoiceTax,
  getCurrentInvoiceDiscount,
  getTotalCount,
  getInvoiceToSend,
  getInvoiceSummary,
};
