import { compose, withProps } from 'recompose';
import ProjectTasksList from './projectTasksList';


const enhance = compose(
  withProps(({ data }) => ({
    tasks: data.tasks.map(t => t.id),
  })),
);

export default enhance(ProjectTasksList);
