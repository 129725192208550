import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CardActivity, TotalTimeList } from './cardsActivity/components';
import api from '../../../../api/activity.service';
import { useActivityContext } from '../context/useActivityContext';
import { LineChart } from './LineChart';

export const ProjectActivity = () => {
  const {
    startDate, endDate, projectValue, selectedUsers,
  } = useActivityContext();
  const { t } = useTranslation();
  const selectedUsersId = selectedUsers?.map(el => el.value);
  const checkUserId = selectedUsersId.includes(null) ? null : selectedUsersId;

  const filterData = {
    startDate: `${startDate}`,
    endDate: `${endDate}`,
    project_id: projectValue.value,
    users_id: checkUserId,
  };

  const [userTotalTimeHours, setUserTotalTimeHours] = useState([]);
  const [userTotalTimeDates, setUserTotalTimeDates] = useState([]);
  const [totalTimePerTasks, setTotalTimePerTasks] = useState([]);
  const [usersTotalTime, setUsersTotalTime] = useState([]);

  const getActivity = async () => {
    try {
      const dataUsers = await api.getProjectTotalTimeRequest(filterData);

      const spent = dataUsers?.userTotalTimePerDays?.datasets;
      const dates = dataUsers?.userTotalTimePerDays?.labels;
      setUserTotalTimeHours(spent);
      setUserTotalTimeDates(dates);
      setTotalTimePerTasks(dataUsers.totalTimePerTasks);
      setUsersTotalTime(dataUsers.totalUsersTime);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getActivity();
  }, [startDate, endDate, selectedUsers, projectValue]);

  return (
    <div className="cards-activity-container__row">
      <div className="cards-activity-container__col">
        {!selectedUsersId?.includes(null) && selectedUsersId?.length >= 1
        && userTotalTimeDates && (
        <CardActivity title={t('User total time')}>
          <LineChart labels={userTotalTimeDates} datasets={userTotalTimeHours} />
        </CardActivity>
        )
        }
        <CardActivity
          title={t('Total time per tasks')}
        >
          {
            totalTimePerTasks
            && (
              <TotalTimeList
                data={totalTimePerTasks}
                dataTest={totalTimePerTasks}
                fields={{
                  hash: true, user: true, task: true, spent: true, estimate: true, more: true,
                }}
                fieldsTask={{
                  hash: true, user: true, title: true, spent: true, estimate: true, more: true,
                }}
              />
            )
          }
        </CardActivity>
      </div>
      <div className="cards-activity-container__col">
        <CardActivity
          title={t('Total time for this users')}
        >
          <TotalTimeList
            data={usersTotalTime}
            showSublist={false}
            userId={selectedUsersId}
            fields={{
              user: true, spent: true, estimate: true, more: true,
            }}
          />
        </CardActivity>
      </div>
    </div>
  );
};
