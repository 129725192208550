import React from 'react';
import PropTypes from 'prop-types';

import { Pagination, ScrollWrapper } from '../../ui-kit';
import { FilterRow, ChannelsList } from './components';
import './style.sass';

const Channels = (props) => {
  const {
    onGoBack,
    countEntities,
    entities,
    title,
    result,
    onPageChange,
    pagination,
    t,
    onChangeSearchHandler,
  } = props;
  return (
    <ScrollWrapper>
      <div className="channels">
        <div className="channels__container">
          <button
            onClick={onGoBack}
            type="button"
            className="channels__button-close"
            title={t('Close page Channels')}
            aria-label={t('Close page Channels')}
          >
            <span className="icon-close" />
          </button>
          <FilterRow
            onChangeSearchHandler={onChangeSearchHandler}
            {...props}
          />
          <ChannelsList
            title={title}
            entities={entities}
            result={result}
          />
          <Pagination
            count={countEntities}
            component="channels"
            onPageChange={onPageChange}
            pagination={pagination}
          />
        </div>
      </div>
    </ScrollWrapper>
  );
};

Channels.propTypes = {
  title: PropTypes.string.isRequired,
  entities: PropTypes.instanceOf(Object).isRequired,
  result: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChangeSearchHandler: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  countEntities: PropTypes.number.isRequired,
  onGoBack: PropTypes.func.isRequired,
  pagination: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
};
export default Channels;
