import { useContext } from 'react';
import { PeopleContext } from './context';

export function usePeopleContext() {
  const context = useContext(PeopleContext);
  if (!context) {
    throw new Error('usePeopleContext must be used within aPeopleProvider.');
  }
  return context;
}
