import React from 'react';
import PropTypes from 'prop-types';

import { MessageElements } from '../../../../components';
import { ButtonsCollection, DropDown } from '../../../../ui-kit';
import './style.sass';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import { SPRINT_STATUS } from '../../../../constants/sprints';
import { formatSprintTime } from '../../../../utils/helpers/projectHelpers/utils';

const SprintTitle = ({ title }) => (
  <h2 className="agenda-header__title">
    { title }
  </h2>
);

SprintTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

const SprintInfo = ({
  isShowMore, description, startTime, endTime,
}) => (
  <div className={`agenda-header__row agenda-header__show-more ${isShowMore ? 'active' : ''}`}>
    <div className="agenda-header__date">
      { startTime && `${formatSprintTime(startTime)} - ${formatSprintTime(endTime)} ` }
    </div>
    <div className="agenda-header__helper selectable-text">
      <MessageElements
        options={{ parseToPlainText: true }}
      >
        {description}
      </MessageElements>
    </div>
  </div>
);

SprintInfo.propTypes = {
  isShowMore: PropTypes.bool.isRequired,
  description: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
};

SprintInfo.defaultProps = {
  description: null,
  endTime: null,
  startTime: null,
};

const SprintEstimation = ({ estimatedTime, spentTime }) => (
  <div className="agenda-header__row">
    <div className="agenda-header__log-time">
      <div className="agenda-header__log-item">

        Spent:
        <b>{estimatedTime}</b>
      </div>
      <div className="agenda-header__log-item">

        Estimation:
        <b>{spentTime}</b>
      </div>
    </div>
  </div>
);


const SelectedSprint = ({ sprint, onEdit, onDelete }) => (
  <div className="agenda-header__title-group">
    <SprintTitle title={sprint.title} />
    <DropDown
      label={(
        <ButtonsCollection.ButtonIcons
          className="agenda-task__controls-button"
          title="Controls for sprint"
        >
          <span className="table-tasks__control-icon icon-settings-vertical">
            <span
              className="path1"
            />
            <span className="path2" />
            <span
              className="path3"
            />
          </span>
        </ButtonsCollection.ButtonIcons>
        )
      }
      list={
        [
          {
            id: 0,
            onClick: onEdit,
            label: (
              <div className="flex-row">
                <span className="agenda-header__controls-icon icon-pencil-2" />

                Edit
              </div>
            ),
          },
          {
            id: 4,
            onClick: onDelete,
            label: (
              <div className="flex-row">
                <span className="icon-delete-icon agenda-header__controls-icon" />

                Delete
              </div>
            ),
          },
        ]
      }
    />
  </div>
);

SprintEstimation.propTypes = {
  estimatedTime: PropTypes.string.isRequired,
  spentTime: PropTypes.string.isRequired,
};

SelectedSprint.propTypes = {
  sprint: PropTypes.instanceOf(Object),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

SelectedSprint.defaultProps = {
  sprint: null,
};

const BoardHeader = ({
  windowWidth,
  estimatedTime,
  spentTime,
  setIsShowMore, isShowMore, sprint,
  sprintId, onComplete, onEdit, onDelete, onStart,
}) => (
  <>
    <div className="agenda-header">
      <div className="agenda-header__col--left">
        <div className="agenda-header__row">
          <div className="agenda-header__title-group">
            { sprintId ? (
              <SelectedSprint
                onEdit={onEdit}
                onDelete={onDelete}
                sprint={sprint}
              />
            ) : <SprintTitle title="All sprints" /> }
          </div>
        </div>
        { sprintId && (
          <SprintInfo
            startTime={sprint.start_time}
            endTime={sprint.end_time}
            isShowMore={isShowMore}
            description={sprint.description}
          />
        )
        }
      </div>
      { sprintId && (
      <div className="agenda-header__col--right">
        <SprintEstimation
          estimatedTime={estimatedTime}
          spentTime={spentTime}
        />
        <ButtonsCollection.ButtonBrill
          onClick={sprint.status === SPRINT_STATUS.COMPLETE ? onStart : onComplete}
          type="button"
          className="button--sm button-brill--fill table-tasks__complete-sprint"
        >
          {sprint.status === SPRINT_STATUS.COMPLETE ? ('Start') : ('Complete')}
        </ButtonsCollection.ButtonBrill>
      </div>
      )}
    </div>
    {
      windowWidth < WINDOW_WIDTH.SMALL && (
        <div className={`agenda-header__show-more-button ${isShowMore ? 'active' : ''}`}>
          <ButtonsCollection.ButtonIcons
            type="button"
            title="Show more"
            onClick={() => setIsShowMore(!isShowMore)}
          >
            <span className="icon-down" />
          </ButtonsCollection.ButtonIcons>
        </div>
      )
    }
  </>
);

BoardHeader.propTypes = {
  windowWidth: PropTypes.number.isRequired,
  setIsShowMore: PropTypes.func.isRequired,
  sprint: PropTypes.instanceOf(Object),
  sprintId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  isShowMore: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  estimatedTime: PropTypes.string.isRequired,
  spentTime: PropTypes.string.isRequired,
};

BoardHeader.defaultProps = {
  sprint: null,
  sprintId: null,
};

export default BoardHeader;
