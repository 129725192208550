import React from 'react';
import PropTypes from 'prop-types';
import { ClientsTasksItem } from '..';
import {
  ButtonsCollection, ShowMoreItems,
} from '../../../../ui-kit';
import './style.sass';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import ClientsTasksItemMobile from '../clientTasksItem/clientsTasksItemMobile';


const ClientTasksList = ({
  t,
  onCreateTaskModal,
  onDeleteTaskModal,
  onEditTaskModal,
  onEditFullTaskModal,
  clientTasks,
  onSetActiveTaskId,
  onClickTaskClient,
  onChangeTaskStatus,
  onEditFullTask,
  windowWidth,
}) => (
  <div className="client-tasks">
    <div className="client-tasks__filter">
      <h6 className="client-tasks__filter-title">To Do List</h6>
      <ButtonsCollection.ButtonPlus className="client-tasks__add-item" onClick={onCreateTaskModal} />
    </div>
    {clientTasks && clientTasks.length ? (
      <ul className="client-tasks__list">
        <ShowMoreItems
          className="client-tasks__wrapper"
          count={4}
          items={
            clientTasks.map(task => (windowWidth > WINDOW_WIDTH.SMALL ? (
              <ClientsTasksItem
                key={task.id}
                task={task}
                onEditFullTaskModal={onEditFullTaskModal}
                onChangeTaskStatus={onChangeTaskStatus}
                onClickTaskClient={onClickTaskClient}
                onDeleteTaskModal={onDeleteTaskModal}
                onEditFullTask={onEditFullTask}
                onEditTaskModal={onEditTaskModal}
                onSetActiveTaskId={onSetActiveTaskId}
                t={t}
              />
            ) : (
              <ClientsTasksItemMobile
                onClickTaskClient={onClickTaskClient}
                onEditFullTaskModal={onEditFullTaskModal}
                t={t}
                onDeleteTaskModal={onDeleteTaskModal}
                onChangeTaskStatus={onChangeTaskStatus}
                onEditFullTask={onEditFullTask}
                onSetActiveTaskId={onSetActiveTaskId}
                task={task}
                onEditTaskModal={onEditTaskModal}
              />
            )))
            }
        />
      </ul>
    ) : <div className="client-tasks__empty">There are no tasks yet</div> }
  </div>
);

ClientTasksList.propTypes = {
  t: PropTypes.func.isRequired,
  clientTasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCreateTaskModal: PropTypes.func.isRequired,
  onDeleteTaskModal: PropTypes.func.isRequired,
  onEditTaskModal: PropTypes.func.isRequired,
  onEditFullTaskModal: PropTypes.func.isRequired,
  onSetActiveTaskId: PropTypes.func.isRequired,
  onClickTaskClient: PropTypes.func.isRequired,
  onChangeTaskStatus: PropTypes.func.isRequired,
  onEditFullTask: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

export default ClientTasksList;
