import {
  compose, withHandlers,
} from 'recompose';
import withRefs from '../../../../utils/enchancers/withRefs';
import TextArea from './TextArea';

const onSetRefFieldHandler = ({ childrenRef, setRef }) => element => compose(
  () => { if (childrenRef) childrenRef(element); },
  setRef('input'),
)(element);

const enhance = compose(
  withRefs(),
  withHandlers({
    onSetRefField: onSetRefFieldHandler,
  }),
);
export default (enhance)(TextArea);
