import { fork, take } from 'redux-saga/effects';
import types from './types';
import { dragDropInvoiceHelper } from '../../utils/helpers/crmHelpers';
import { sagasManager } from '../../utils';

function* onDragAgendaFlow() {
  while (true) {
    const { payload } = yield take(types.ON_DRAG_INVOICE_END);
    const {
      destination, source, draggableId: itemId, queryParams,
    } = payload;
    yield dragDropInvoiceHelper({
      destination, source, itemId, queryParams,
    });
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(onDragAgendaFlow);
});
