import React from 'react';
import './style.sass';
import { useTranslation } from 'react-i18next';
import { PermissionsToggle } from './permissionsToggle';


/**
 *
 * @param moduleName{string}
 * @param permissions{Object}
 * @param permissionByGroups{Object}
 * @param onChangePermission{function}
 * @returns {JSX.Element}
 * @constructor
 */

export const ModulePermissions = ({
  moduleName, permissions, permissionByGroups, onChangePermission,
}) => {
  const { t } = useTranslation('permissions');
  return (
    <div className="modulePermissions">
      <div className="modulePermissions__head">
        <h3 className="modulePermissions__title">{t(moduleName)}</h3>
      </div>
      <div className="modulePermissions__row modulePermissions__row--head">

        <div className="modulePermissions__col modulePermissions__col--name">{t('Module name')}</div>
        <div className="modulePermissions__col">{t('Read')}</div>
        <div className="modulePermissions__col">{t('Create')}</div>
        <div className="modulePermissions__col">{t('Update')}</div>
        <div className="modulePermissions__col">{t('Delete')}</div>
        <div className="modulePermissions__col">{t('All')}</div>
        <div className="modulePermissions__col">{t('Only owner')}</div>
      </div>
      {
        permissionByGroups[moduleName].map(item => (
          <PermissionsToggle
            item={item}
            moduleName={moduleName}
            permissions={permissions}
            onChangePermission={onChangePermission}
          />
        ))
      }
    </div>
  );
};
