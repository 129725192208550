import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useProjectNotesContext } from '../context/useProjectNotesContext';

import { NoteItem } from '../NoteItem';
import { PreLoaderWhileLoading } from '../../preloaderWhileLoading';

import { PRELOADER_DIMENSION } from '../../../constants/ui';
import api from '../../../api/projects.service';

import './style.sass';

const LIMIT_OF_NOTES = 1000;
const PRELOADER_DELAY = 600;

export const NotesList = () => {
  const { sortBy, notes, setNotes } = useProjectNotesContext();
  const { id: projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const { t } = useTranslation(['common']);

  const fetchNotes = useCallback(async (params) => {
    try {
      const { data } = await api.getProjectNotes(projectId, params);
      setIsLoading(true);
      setIsPending(true);
      setNotes(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setIsPending(false);
    }
  }, [projectId]);

  useEffect(() => {
    fetchNotes({ ...sortBy, limit: LIMIT_OF_NOTES, offset: 0 });
  }, [sortBy]);

  return (
    <PreLoaderWhileLoading
      dimension={PRELOADER_DIMENSION.MIDDLE}
      alignContainerCenter
      delay={PRELOADER_DELAY}
      isLoading={isLoading}
      onAction={isPending}
    >
      <div className="notes-list">
        <div className="notes-list__table">
          <div className="notes-list__table-head">
            <div className="notes-list__table-row">
              <div className="notes-list__table-col"><b>{t('Title')}</b></div>
              <div className="notes-list__table-col notes-list__table-col--description"><b>{t('Description')}</b></div>
              <div className="notes-list__table-col" />
            </div>
          </div>
          <div className="notes-list__table-body">
            {
              notes?.length ? (
                notes.map(({ id, title, content }) => (
                  <NoteItem
                    key={id}
                    noteId={id}
                    content={content}
                    title={title}
                  />
                ))
              ) : <div className="notes-list__empty">{t('There are no notes yet')}</div>
            }
          </div>
        </div>
      </div>
    </PreLoaderWhileLoading>
  );
};
