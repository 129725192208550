import { prop } from 'ramda';
import { apiRequest } from '../sagaRequestApi';

// eslint-disable-next-line import/prefer-default-export
export const asyncActionsMiddleware = () => next => (action) => {
  next(action);
  if (prop('async', action.meta)) {
    const { meta } = action;
    next(apiRequest({
      ...meta,
    }));
  }
};
