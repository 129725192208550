import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { ScrollWrapper } from '../../../../../ui-kit';
import { RolesList, DeleteRoleModal } from './components';
import { permissionsSelectors } from '../../../../../state/permissions';
import './style.sass';

/**
 * @param gridName {string}
 * @param pagination {object}
 * @param onSortBy {function}
 * @param sort {string}
 * @param order {string}
 * @returns {JSX.Element}
 * @constructor
 */

const SettingRolesContainer = ({
  gridName,
  pagination,
  onSortBy,
  sort = '',
  order = '',
}) => {
  const [selectedRole, setSelectedRole] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);

  const rolesEntities = useSelector(permissionsSelectors.getRolesEntities);
  const rolesList = useSelector(permissionsSelectors.getRolesList);
  const rolesCount = useSelector(permissionsSelectors.getRolesCount);
  const rolesHasMore = useSelector(permissionsSelectors.getRolesHasMore);
  const isPending = useSelector(permissionsSelectors.getRolesIsPending);

  const onDeleteRole = useCallback((role) => {
    setIsOpenModal(true);
    setSelectedRole(role);
  });

  return (
    <ScrollWrapper>
      <div className="roles-list">
        <RolesList
          gridName={gridName}
          pagination={pagination}
          onSortBy={onSortBy}
          sort={sort}
          order={order}
          isLoading={isPending}
          rolesList={rolesList}
          rolesCount={rolesCount}
          rolesEntities={rolesEntities}
          rolesHasMore={rolesHasMore}
          onDeleteRole={onDeleteRole}
        />
      </div>
      <DeleteRoleModal
        selectedRole={selectedRole}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
      />
    </ScrollWrapper>
  );
};

export default SettingRolesContainer;
