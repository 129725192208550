import { compose, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { filter, uniq, values } from 'ramda';
import { withRouter } from 'react-router';

import TaskCard from './taskCard';
import { projectSelectors, projectActions } from '../../../../state/project';
import { tasksActions } from '../../../../state/tasks';
import { withTasksCRUDHandlers } from '../../../../utils/enchancers';
import withWindowWidth from '../../../../utils/enchancers/withWindowWidth';
import { withPermissionHandlers } from '../../../../utils/helpers/permissionHelpers';

const mapStateToProps = (state, props) => ({
  categories: projectSelectors.getProjectCategoriesTasks(state),
  authors: projectSelectors.getProjectTasksAuthors(state),
  taskStatuses: projectSelectors.getAgendaStatuses(state),
  assignees: projectSelectors.getProjectTasksAssignees(state),
  task: projectSelectors.getTask(state)(props.id),
  getAgendaTasksList: projectSelectors.getAgendaTasksList(state),
  statusOfTasksEntities: projectSelectors.getStatusesOfTasksEntities(state),
});

const mapDispatchToProps = {
  setTask: tasksActions.setTask,
  deleteTask: tasksActions.deleteTask,
  updateTaskRequest: tasksActions.updateTaskRequest,
  reorderAgendaTasks: projectActions.reorderAgendaTasks,
  getProjectStatuses: projectActions.getStatusesOfTasksRequest,
  setSummary: projectActions.setSummary,
};

const onEditHandler = ({ onEditTask, match: { params: { projectId } }, id }) => () => {
  onEditTask({ taskId: id, projectId });
};
const onDeleteHandler = ({
  onDeleteTask,
  match: { params: { projectId } }, id, statusId,
}) => () => {
  onDeleteTask({
    taskId: id,
    projectId,
    meta: { isAgendaBoard: true, statusId, isHasSprint: true },
  });
};

const onSetTaskStatusHandler = ({
  task, updateTaskRequest, reorderAgendaTasks, getAgendaTasksList, setSummary,
}) => (statusId) => {
  const itemsInSource = getAgendaTasksList(task.status_id);
  const itemsInSource2 = getAgendaTasksList(statusId);

  const prevTasksList = filter(item => item !== task.id, itemsInSource);
  const nextTasksList = uniq([task.id, ...itemsInSource2]);

  reorderAgendaTasks({ tasks: nextTasksList, status_id: statusId });
  reorderAgendaTasks({ tasks: prevTasksList, status_id: task.status_id });
  updateTaskRequest({ ...task, status_id: statusId }, { isUpdate: true });
  setSummary({ destination: { droppableId: statusId }, source: { droppableId: task.status_id } });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withWindowWidth(),
  withTranslation(['tasks', 'common']),
  withTasksCRUDHandlers,
  withProps(({ statusOfTasksEntities }) => ({
    statuses: values(statusOfTasksEntities),
  })),
  withHandlers({
    onEdit: onEditHandler,
    onDelete: onDeleteHandler,
    onSetTaskStatus: onSetTaskStatusHandler,
  }),
  withPermissionHandlers,
);

export default enhance(TaskCard);
