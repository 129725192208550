import React from 'react';
import PropTypes from 'prop-types';
import {
  propOr, pathOr,
} from 'ramda';

import {
  ButtonsCollection, NoInternetConnection, DefaultAutoComplete, AsyncAutoComplete, FormsCollection,
} from '../../../ui-kit';
import './style.sass';
import { FILE_FORMATS, FILE_SIZES } from '../../../constants/crm';

const LeadForm = ({
  isPending,
  handleChange,
  handleBlur,
  handleSubmit,
  values,
  errors,
  touched,
  titleAction,
  getPipelinesAutocomplete,
  setFieldValue,
  pipelineStatusesAutocomplete,
  pipelineStatusesPending,
  isEdit,
  getAutocompleteHandler,
  customFieldValues,
}) => {
  const getFieldProps = name => ({
    value: pathOr('', ['contact', name], values),
    error: pathOr('', ['contact', name, 'message'], errors),
    touched: isEdit ? true : pathOr(false, ['contact', name], touched),
  });

  const getAutocompleteFieldProps = (name, inContact = false) => {
    const usualFieldProps = inContact ? getFieldProps(name) : {
      value: values[name],
      error: pathOr('', [name, 'message'], errors),
      touched: isEdit ? true : propOr(false, name, touched),
    };
    return {
      ...usualFieldProps,
      defaultValue: inContact && values.contact ? values.contact[name] : values[name],
      closeMenuOnSelect: true,
    };
  };
  return (
    <NoInternetConnection>
      <FormsCollection.FormWrapper
        handleSubmit={handleSubmit}
      >
        <div className="lead-form-col">
          <FormsCollection.Input
            placeholderJump="First name"
            name="contact.first_name"
            onChange={handleChange}
            onBlur={handleBlur}
            className="lead__first-name-input"
            id="lead__first-name"
            {...getFieldProps('first_name')}
          />
          <FormsCollection.Input
            placeholderJump="Last name"
            name="contact.last_name"
            onChange={handleChange}
            onBlur={handleBlur}
            className="lead__last-name-input"
            id="lead__last-name"
            {...getFieldProps('last_name')}
          />
        <>
          <span className="auto-complete-label">Pipeline</span>
          <AsyncAutoComplete
            name="pipeline"
            placeholder="Pipeline"
            loadOptions={getPipelinesAutocomplete}
            onChange={(value) => {
              setFieldValue('pipeline_id', value);
              setFieldValue('pipeline_status_id', undefined);
            }}
            {...getAutocompleteFieldProps('pipeline_id')}
          />
        </>
        <>
          <span className="auto-complete-label">Status</span>
          <DefaultAutoComplete
            name="status"
            placeholder="Status"
            isDisabled={!values.pipeline_id || pipelineStatusesPending}
            options={pipelineStatusesAutocomplete}
            onChange={value => setFieldValue('pipeline_status_id', value)}
            {...getAutocompleteFieldProps('pipeline_status_id')}
          />
        </>
          <FormsCollection.Input
            placeholderJump="Email"
            name="contact.email"
            onChange={handleChange}
            onBlur={handleBlur}
            className="lead__email-input"
            id="lead__email"
            {...getFieldProps('email')}
          />
          <div className="lead__row lead__logo">
            <div className="lead__col-field">
              <FormsCollection.AttachImage
                onChange={handleChange}
                value={values.avatar}
                name="contact.avatar"
                id="lead__avatar"
                labelText="Add image"
                formats={FILE_FORMATS.CONTACT_LOGO}
                maxSize={FILE_SIZES.CONTACT_LOGO}
                minSize={FILE_SIZES.MINIMAL_SIZE_KB}
                {...getFieldProps('avatar')}
              />
            </div>
          </div>
        </div>
        <div className="lead-form-col">
          <FormsCollection.Input
            placeholderJump="Phone"
            name="contact.phone"
            onChange={handleChange}
            onBlur={handleBlur}
            className="lead__phone-input"
            id="lead__phone"
            {...getFieldProps('phone')}
          />
          <FormsCollection.Input
            placeholderJump="Company"
            name="contact.company_name"
            onChange={handleChange}
            onBlur={handleBlur}
            className="lead__company-input"
            id="lead__company"
            {...getFieldProps('company_name')}
          />
          <FormsCollection.Input
            placeholderJump="Company URL"
            name="contact.company_url"
            onChange={handleChange}
            onBlur={handleBlur}
            className="lead__company-url-input"
            id="lead__company-url"
            {...getFieldProps('company_url')}
          />
          <FormsCollection.Input
            placeholderJump="Position"
            name="contact.position"
            onChange={handleChange}
            onBlur={handleBlur}
            className="lead__position-input"
            id="lead__position"
            {...getFieldProps('position')}
          />
          <FormsCollection.Input
            placeholderJump="Service"
            name="contact.service"
            onChange={handleChange}
            onBlur={handleBlur}
            className="lead__service-input"
            id="lead__service"
            {...getFieldProps('service')}
          />
          <span className="auto-complete-label">Timezone</span>
          <AsyncAutoComplete
            name="contact.timezone"
            placeholder="Choose timezone..."
            closeMenuOnSelect
            onChange={({ label, value }) => {
              setFieldValue('contact.timezone.label', label);
              setFieldValue('contact.timezone.value', value);
            }}
            placeholderJump="Timezone"
            loadOptions={getAutocompleteHandler}
            {...getAutocompleteFieldProps('timezone', true)}
          />
          {customFieldValues?.map(customField => (
            <FormsCollection.Input
              placeholderJump={customField.name}
              name={customField.name}
              onChange={handleChange}
              onBlur={handleBlur}
              className="lead__custom-field-input"
              value={values[customField.name]}
            />
          ))}
        </div>
        <div className="button-group">
          <ButtonsCollection.ButtonBrill
            pending={isPending}
            className="lead__button-save button-brill--fill"
            type="submit"
          >
            {titleAction}
          </ButtonsCollection.ButtonBrill>
        </div>
      </FormsCollection.FormWrapper>
    </NoInternetConnection>
  );
};

LeadForm.propTypes = {
  isPending: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,
  pipelineStatusesPending: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  touched: PropTypes.shape({}).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  titleAction: PropTypes.string.isRequired,
  pipelineStatusesAutocomplete: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getAutocompleteHandler: PropTypes.func,
  customFieldValues: PropTypes.shape({}),
};

LeadForm.defaultProps = {
  customFieldValues: null,
  isEdit: false,
  getAutocompleteHandler: () => {},
};


export default LeadForm;
