import * as pipelinesActions from './actions';
import * as pipelinesSelectors from './selectors';
import * as pipelinesOperations from './operations';
import * as pipelinesSchemas from './schema';
import pipelinesTypes from './types';
import reducer from './reducers';

export {
  pipelinesActions,
  pipelinesSelectors,
  pipelinesOperations,
  pipelinesSchemas,
  pipelinesTypes,
};

export default reducer;
