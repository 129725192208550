import React, { useCallback, useEffect, useState } from 'react';

import { ScrollWrapper } from '../../../../../ui-kit';
import { BillingInfoForm, CompanyLogo } from './components';
import './style.sass';
import api from '../../../../../api/settings.service';

/**
 * @returns {JSX.Element}
 * @constructor
 */

export const SettingsBillingInfoContainer = () => {
  const [companyBillingInfo, setCompanyBillingInfo] = useState({});

  const getCompanyBillingInfoRequest = async (params) => {
    const data = await api.getCompanyBillingInfoRequest(params);
    if (data) {
      setCompanyBillingInfo(data);
    }
    return data;
  };

  const changeCompanyLogoRequest = useCallback(async (params) => {
    const data = await api.changeCompanyLogoRequest(params);
    if (data) {
      getCompanyBillingInfoRequest();
    }
    return data;
  });

  useEffect(() => {
    getCompanyBillingInfoRequest();
  }, []);

  return (
    <ScrollWrapper>
      <div className="billing-info__container">
        <CompanyLogo
          companyBillingInfo={companyBillingInfo}
          changeCompanyLogoRequest={changeCompanyLogoRequest}
        />
        <BillingInfoForm
          companyBillingInfo={companyBillingInfo}
          getCompanyBillingInfoRequest={getCompanyBillingInfoRequest}
        />
      </div>
    </ScrollWrapper>
  );
};
