import React from 'react';
import { propOr } from 'ramda';
import PropTypes from 'prop-types';
import { AsyncAutoComplete, ButtonsCollection, FormsCollection } from '../../../../../../ui-kit';
import './style.sass';

const AddProductForm = ({
  handleSubmit,
  values,
  errors,
  getProjectsAutocomplete,
  touched,
  setFieldValue,
}) => (
  <FormsCollection.FormWrapper
    handleSubmit={handleSubmit}
    className="client-products"
  >
    <div className="client-products__row">
      <div className="client-products__col">
        <AsyncAutoComplete
          name="projects"
          loadOptions={getProjectsAutocomplete}
          closeMenuOnSelect
          placeholder="Choose project"
          value={values.projects}
          placeholderJump="Projects"
          touched={touched.projects}
          error={propOr('', 'message', errors.projects)}
          onChange={value => setFieldValue('projects', value)}

        />
      </div>
      <div className="client-products__col">
        <div className="button-group">
          <ButtonsCollection.ButtonBrill
            type="submit"
            className="button--sm client-products__button-save button-brill--fill"
          >
            ADD PRODUCT
          </ButtonsCollection.ButtonBrill>
        </div>
      </div>
    </div>
  </FormsCollection.FormWrapper>
);

AddProductForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  getProjectsAutocomplete: PropTypes.func.isRequired,
  touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Object)]).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default AddProductForm;
