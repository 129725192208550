import {
  compose, withHandlers, withProps, withStateHandlers,
} from 'recompose';
import { identity, includes, is } from 'ramda';
import { connect } from 'react-redux';

import { uploadTempFileRequest } from '../../state/ui/actions';
import { getTempFileUploadPending } from '../../state/ui/selectors';
import { getImageUrl } from '../helpers/requestHelpers';
import { AVAILABLE_FILE_FORMATS, AVAILABLE_FILE_SIZES } from '../../constants/files';
import { callNotification } from '../helpers/notifies';
import { normalizeSize } from '../helpers/crmHelpers/sizeConvertHelper';


// todo: Make props explicitly
const onUploadFileHandler = ({
  uploadTempFile, setFileValue, onChange, name, onUploadCallback, onCbErrorFileUploading = identity,
  formats, maxSize, minSize,
}) => (e) => {
  const file = e.currentTarget.files[0];
  if (!file) return;
  const { size, type, name: fileName } = file;
  const previewData = URL.createObjectURL(file);
  const formData = new FormData();

  const fileSize = (size / 1024) / 1024;
  const fileSizeMin = Math.round(minSize * 1024);
  Math.round(fileSize);

  if (fileSize > maxSize) {
    callNotification.error(`File size of ${fileName} more than ${normalizeSize(size)}. \n
        File size should not be large than ${maxSize}MB`);
  } else if (fileSize <= minSize) {
    callNotification.error(`File size of ${fileName} less than ${normalizeSize(size)}. \n
          File size should be larger than ${fileSizeMin}KB`);
  } else if (!includes(type, formats)) {
    callNotification.error(`File - ${fileName}, has incorrect file type`);
  } else {
    formData.append('file', e.currentTarget.files[0]);
    uploadTempFile(formData, {
      callbacks: {
        error: onCbErrorFileUploading,
        success: ({ model }) => {
          onChange({
            target: {
              name,
              value: model.id,
            },
          });
          if (onUploadCallback) onUploadCallback(model.id);
          setFileValue(previewData);
        },
      },
    });
  }
};


const onDeleteFileHandler = ({ onChange, name }) => () => {
  onChange({
    target: {
      name,
      value: '',
    },
  });
};

const setFileValueStateHandler = () => value => ({ fileUrl: value });

const mapStateToProps = state => ({
  isPending: getTempFileUploadPending(state),
});
const mapDispatchToProps = {
  uploadTempFile: uploadTempFileRequest,
};

const withUploadFile = ({
  availableFormats = AVAILABLE_FILE_FORMATS.DEFAULT,
  availableSize = AVAILABLE_FILE_SIZES.DEFAULT,
}) => compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(props => ({
    formats: is(Function, availableFormats) ? availableFormats(props) : availableFormats,
    maxSize: is(Function, availableSize) ? availableSize(props) : availableSize,
  })),
  withStateHandlers(() => ({ fileUrl: '' }), {
    setFileValue: setFileValueStateHandler,
  }),
  withHandlers({
    onDeleteFile: onDeleteFileHandler,
    onUploadFile: onUploadFileHandler,
  }),
  withProps(({ value }) => ({ value: getImageUrl(value) })),
);

export default withUploadFile;
