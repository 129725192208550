import React from 'react';
import PropTypes from 'prop-types';
import PinnedClientItem from '../pinnedClientItem';
import './style.sass';
import { ShowMoreItems } from '../../../../../../ui-kit';

const PinnedClients = ({
  pinnedClients,
}) => (
  <>
    {
      pinnedClients && pinnedClients.length ? (
        <ul className="pinned-clients">
          <ShowMoreItems
            count={4}
            items={pinnedClients.map(id => <PinnedClientItem key={id} clientId={id} />)}
          />
        </ul>
      ) : (
        <div className="pinned-clients--empty">
          There are no pinned clients yet
        </div>
      )
    }
  </>

);

PinnedClients.propTypes = {
  pinnedClients: PropTypes.instanceOf(Array).isRequired,
};

export default PinnedClients;
