export default {
  CREATE_CLIENT_REQUEST: 'CREATE_CLIENT_REQUEST',
  ADD_CLIENT_NOTE_REQUEST: 'ADD_CLIENT_NOTE_REQUEST',
  EDIT_CLIENT_REQUEST: 'EDIT_CLIENT_REQUEST',
  DELETE_CLIENT_REQUEST: 'DELETE_CLIENT_REQUEST',
  EDIT_CLIENT_NOTE_REQUEST: 'EDIT_CLIENT_NOTE_REQUEST',
  DELETE_CLIENT_NOTE_REQUEST: 'DELETE_CLIENT_NOTE_REQUEST',
  GET_CLIENTS_REQUEST: 'GET_CLIENTS_REQUEST',
  GET_CLIENT_REQUEST: 'GET_CLIENT_REQUEST',
  GET_CLIENTS_AUTOCOMPLETE_REQUEST: 'GET_CLIENTS_AUTOCOMPLETE_REQUEST',
  GET_CLIENT_TASKS_REQUEST: 'GET_CLIENT_TASKS_REQUEST',
  GET_CLIENT_CONTACTS_REQUEST: 'GET_CLIENT_CONTACTS_REQUEST',
  GET_CLIENT_NOTES_REQUEST: 'GET_CLIENT_NOTES_REQUEST',
  GET_CLIENT_LOGS_REQUEST: 'GET_CLIENT_LOGS_REQUEST',
  GET_CLIENT_PRODUCTS_REQUEST: 'GET_CLIENT_PRODUCTS_REQUEST',

  SET_CLIENTS: 'SET_CLIENTS',
  SET_CLIENT: 'SET_CLIENT',
  SET_CLIENTS_AUTOCOMPLETE: 'SET_CLIENTS_AUTOCOMPLETE',
  SET_CLIENT_TASKS: 'SET_CLIENT_TASKS',
  SET_CLIENT_CONTACTS: 'SET_CLIENT_CONTACTS',
  SET_CLIENT_NOTES: 'SET_CLIENT_NOTES',
  SET_CLIENT_LOGS: 'SET_CLIENT_LOGS',
  SET_CLIENT_PRODUCTS: 'SET_CLIENT_PRODUCTS',
  SET_ADDED_CLIENT_CONTACT: 'SET_ADDED_CLIENT_CONTACT',

  DELETE_CLIENT_NOTE_SUCCESS: 'DELETE_CLIENT_NOTE_SUCCESS',
  EDIT_CLIENT_NOTE_SUCCESS: 'EDIT_CLIENT_NOTE_SUCCESS',
  DELETE_CLIENT_TASK_SUCCESS: 'DELETE_CLIENT_TASK_SUCCESS',
  EDIT_CLIENT_TASK_SUCCESS: 'EDIT_CLIENT_TASK_SUCCESS',
  FULL_EDIT_CLIENT_TASK_SUCCESS: 'FULL_EDIT_CLIENT_TASK_SUCCESS',
  CHANGE_CLIENT_TASK_STATUS_SUCCESS: 'CHANGE_CLIENT_TASK_STATUS_SUCCESS',
  DELETE_CLIENT_CONTACT_SUCCESS: 'DELETE_CLIENT_CONTACT_SUCCESS',
  EDIT_CLIENT_CONTACT_SUCCESS: 'EDIT_CLIENT_CONTACT_SUCCESS',

  GET_PINNED_CLIENTS_REQUEST: 'GET_PINNED_CLIENTS_REQUEST',
  PIN_CLIENT_REQUEST: 'PIN_CLIENT_REQUEST',
  UNPIN_CLIENT_REQUEST: 'UNPIN_CLIENT_REQUEST',
  SET_PINNED_CLIENTS: 'SET_PINNED_CLIENTS',
  SET_PINNED_CLIENT: 'SET_PINNED_CLIENT',
};
