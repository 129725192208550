import React, {
  useEffect, useState,
} from 'react';
import { FieldsName, TotalTimeItem } from './components';
import './style.sass';
import { getHoursFromTimeString, getTimeStringBySeconds } from '../../../../../../../utils/helpers/dateHelpers';

export const TotalTimeList = ({
  showSublist, data, fields, fieldsTask,
}) => {
  const [totalTime, setTotalTime] = useState(null);

  const convertToDay = dataTimeDay => getTimeStringBySeconds(dataTimeDay?.reduce((sum, current) => sum + current, 0));
  const convertToHour = dataTime => getHoursFromTimeString(dataTime);
  const dataTimeSpent = data?.map(el => (el.time));
  const dataTimeEst = data?.map(el => (el.est_time));

  const totalTimeHandler = () => {
    const timeDays = {
      time_c: convertToDay(dataTimeSpent),
      estimate_c: convertToDay(dataTimeEst),
    };
    const timeHours = {
      time_c_h: convertToHour(timeDays?.time_c),
      estimate_c_h: convertToHour(timeDays?.estimate_c),
    };
    setTotalTime({ ...timeDays, ...timeHours });
  };

  useEffect(() => {
    totalTimeHandler();
  }, [data]);

  return (
    <div className="total-time-list">
      <FieldsName fields={fields} />
      {data?.map(item => (
        item
        && (
        <TotalTimeItem
          row={item}
          fields={fieldsTask || {
            title: true, spent: true, estimate: true, more: true,
          }}
        >
          {showSublist
              && <>
                <FieldsName fields={{
                  hash: true, task: true, spent: true, estimate: true,
                }}
                />
                {item?.tasks?.map(task => (
                  <TotalTimeItem
                    row={task}
                    fields={{
                      hash: true, title: true, spent: true, estimate: true,
                    }}
                  />
                ))}
              </>
            }
        </TotalTimeItem>
        )
      ))}
      {data?.length > 1
      && totalTime
      && (
        <TotalTimeItem
          isShowMoreButton={false}
          row={totalTime}
          fields={{ ...fieldsTask, title_text: 'Total time' } || { title_text: 'Total time', spent: true, estimate: true }}
        />)
      }
    </div>
  );
};

export default TotalTimeList;
