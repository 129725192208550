import React from 'react';
import PropTypes from 'prop-types';
import { ButtonsCollection, ModalWindow, FormsCollection } from '../../../../ui-kit';
import { FilesField, FilesItem } from './components';
import './style.sass';

const AttachFilesModal = ({ isOpen, onClose, t }) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onClose}
    modalName="createChannel"
    title={t('Upload file')}
    className="modal-window__attach-files"
  >
    <FormsCollection.FormWrapper
      handleSubmit={() => {}}
      className="create-channel"
    >
      <FilesField />
      <FilesItem type="image">

        File screenshot 2018-12-24 at 18.05.26.jpeg
      </FilesItem>
      <FilesItem type="other">

        File screenshot 2018-12-24 at 18.05.26.jpeg
      </FilesItem>
      <FilesItem type="other">

        File screenshot 2018-12-24 at 18.05.26.jpeg
      </FilesItem>
      <div className="button-group">
        <ButtonsCollection.ButtonBrill
          className="button--md create-channel__button-save button-brill--fill"
        >
          {t('Upload')}
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);

AttachFilesModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  t: PropTypes.func.isRequired,
};

AttachFilesModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

export default AttachFilesModal;
