import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import DeleteContactModal from './deleteContactModal';
import { uiSelectors, uiActions } from '../../../../state/ui';
import { contactsSelectors } from '../../../../state/contacts';
import { leadsSelectors } from '../../../../state/leads';
import { clientsSelectors } from '../../../../state/clients';

const mapStateToProps = (state, { selectedContactId }) => ({
  isOpen: uiSelectors.getModal(state)('deleteContactModal'),
  contact: contactsSelectors.getContactById(state)(selectedContactId)
    || leadsSelectors.getLeadContactSelector(state)(selectedContactId)
    || clientsSelectors.getClientContactSelector(state)(selectedContactId),
});

const mapDispatchToProps = {
  closeModal: () => uiActions.closeModal('deleteContactModal'),
};

const onCloseHandler = ({
  closeModal,
}) => () => {
  closeModal();
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onClose: onCloseHandler,
  }),
);
export default enhance(DeleteContactModal);
