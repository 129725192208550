import React from 'react';
import { Preload, ScrollWrapper } from '@/ui-kit';
import { LogsItem } from './components';
import { useLogsContainer } from './useLogsContainer';
import './index.sass';

export const LogsContainer = () => {
  const { logs, isPending } = useLogsContainer();

  if (isPending) return <Preload />;

  return (
    <ScrollWrapper>
      <div className="processContainer">
        <div className="logs">
          {!logs.length ? (
            <div className="empty-logs-preview">No log has not been created!</div>
          ) : (
            <>
              {logs.map(([event, logsList]) => (
                <LogsItem key={event} event={event} logsList={logsList} />
              ))}
            </>
          )}
        </div>
      </div>
    </ScrollWrapper>
  );
};
