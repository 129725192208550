/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';

const contactsListSchema = [new schema.Entity('contacts')];

const pinnedContactsListSchema = [new schema.Entity('pinnedContacts')];

export {
  contactsListSchema,
  pinnedContactsListSchema,
};
