export default {
  GET_BP_ROLES_REQUEST: 'GET_BP_ROLES_REQUEST',
  SET_BP_ROLES: 'SET_BP_ROLES',

  GET_BP_PROJECTS_REQUEST: 'GET_BP_PROJECTS_REQUEST',
  SET_BP_PROJECTS: 'SET_BP_PROJECTS',

  GET_BP_CHANNELS_REQUEST: 'GET_BP_CHANNELS_REQUEST',
  SET_BP_CHANNELS: 'SET_BP_CHANNELS',

  GET_BP_USERS_REQUEST: 'GET_BP_USERS_REQUEST',
  SET_BP_USERS: 'SET_BP_USERS',

  GET_BP_CLIENTS_REQUEST: 'GET_BP_CLIENTS_REQUEST',
  SET_BP_CLIENTS: 'SET_BP_CLIENTS',
};
