import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const FilesItem = ({ type, children }) => (
  <div className="files-upload-entities">
    <span className="files-upload-entities__icon">
      {
        type === 'image' ? (
          <span className="icon-image-file" />
        ) : (
          <span className="icon-document-file" />
        )
      }
    </span>
    <h6 className="files-upload-entities__name">{children}</h6>
    <button type="button" className="files-upload-entities__remove-button">
      <span className="icon-close" />
    </button>
  </div>
);

FilesItem.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
};

FilesItem.defaultProps = {
  children: '',
};

export default FilesItem;
