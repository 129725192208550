import * as yup from 'yup';
import { isEmpty } from 'ramda';
import { helpers } from '../../../utils';

const { translateHelpers } = helpers;
const MAX_EMAIL_SYMBOLS = 40;
const MAX_PHONE_SYMBOLS = 16;
const REG_EXP = /^[0-9\-\\+]{0,16}$/;
const SPACES_REG_EXP = /^[-a-zA-Zа-яА-ЯЄєІіЇї0-9-()]+(\s+[-a-zA-Zа-яА-ЯЄєІіЇї0-9-()]+)*$/;
const checkTrimValue = value => (SPACES_REG_EXP).test(value);
const symbolsValidator = value => (REG_EXP).test(value);

const MIN_CHAR_LENGTH = 2;
const MAX_CHAR_LENGTH = 35;

const rules = yup.object({
  contact: yup.object().shape({
    first_name: yup
      .string()
      .test({
        test: checkTrimValue,
        message: {
          field: 'first_name',
          message: 'Space in the fields is not valid',
          params: {
            key: translateHelpers.t('common', 'Whitespaces'),
          },
        },
      })
      .required({
        field: 'first_name',
        message: 'required',
        params: {
          key: translateHelpers.t('common', 'First name'),
        },
      })
      .min(MIN_CHAR_LENGTH, {
        field: 'first_name',
        message: 'Minimum number of characters 2',
        params: {
          key: translateHelpers.t('common', 'First name'),
        },
      })
      .max(MAX_CHAR_LENGTH, {
        field: 'first_name',
        message: 'Maximum number of characters 35',
        params: {
          key: translateHelpers.t('common', 'First name'),
        },
      }),

    last_name: yup
      .string()
      .test({
        test: checkTrimValue,
        message: {
          field: 'last_name',
          message: 'Space in the fields is not valid',
          params: {
            key: translateHelpers.t('common', 'Whitespaces'),
          },
        },
      })
      .required({
        field: 'last_name',
        message: 'required',
        params: {
          key: translateHelpers.t('common', 'Last name'),
        },
      })
      .min(MIN_CHAR_LENGTH, {
        field: 'last_name',
        message: 'Minimum number of characters 2',
        params: {
          key: translateHelpers.t('common', 'Last name'),
        },
      })
      .max(MAX_CHAR_LENGTH, {
        field: 'last_name',
        message: 'Maximum number of characters 35',
        params: {
          key: translateHelpers.t('common', 'Last name'),
        },
      }),
    email: yup
      .string()
      .max(MAX_EMAIL_SYMBOLS, {
        field: 'email',
        message: 'Email is too long',
        params: {
          key: translateHelpers.t('common', 'Email'),
        },
      })
      .email({
        field: 'email',
        message: 'Email address entered incorrectly. Example name@mail.com',
        params: {
          key: translateHelpers.t('common', 'Email address entered incorrectly. Example name@mail.com'),
        },
      }).required({
        field: 'email',
        message: 'required',
        params: {
          key: translateHelpers.t('common', 'Email'),
        },
      }),
    phone: yup
      .string()
      .max(MAX_PHONE_SYMBOLS, {
        field: 'phone',
        message: 'Phone number cannot be too long',
        params: {
          key: translateHelpers.t('common', 'Phone number cannot be too long'),
        },
      })
      .test({
        test: symbolsValidator,
        message: {
          field: 'phone',
          message: 'Only numbers can be entered',
          params: {
            key: translateHelpers.t('common', 'Only numbers can be entered'),
          },
        },
      }),
    timezone: yup
      .string(),
    company_name: yup
      .string()
      .test({
        test: checkTrimValue,
        message: {
          field: 'company_name',
          message: 'Space in the fields is not valid',
          params: {
            key: translateHelpers.t('common', 'Whitespaces'),
          },
        },
      }),
    avatar: yup
      .string(),
    company_url: yup
      .string()
      .url({
        field: 'company_url',
        message: 'URL address entered incorrectly',
        params: {
          key: translateHelpers.t('common', 'URL address entered incorrectly'),
        },
      }),
    position: yup
      .string()
      .test({
        test: checkTrimValue,
        message: {
          field: 'position',
          message: 'Space in the fields is not valid',
          params: {
            key: translateHelpers.t('common', 'Whitespaces'),
          },
        },
      }),
    service: yup
      .string()
      .test({
        test: checkTrimValue,
        message: {
          field: 'service',
          message: 'Space in the fields is not valid',
          params: {
            key: translateHelpers.t('common', 'Whitespaces'),
          },
        },
      }),
  }),
  pipeline_id: yup.object()
    .test({
      test: val => val && !isEmpty(val),
      message: {
        field: 'pipeline',
        message: 'required',
        params: {
          key: translateHelpers.t('common', 'Pipeline'),
        },
      },
    }),
  pipeline_status_id: yup.object()
    .test({
      test: val => val && !isEmpty(val),
      message: {
        field: 'status',
        message: 'required',
        params: {
          key: translateHelpers.t('common', 'Status'),
        },
      },
    }),
});

export default rules;
