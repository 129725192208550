import {
  compose,
  getContext,
  withStateHandlers,
  withHandlers,
} from 'recompose';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import FiltersRow from './filtersRow';
import { withWindowWidth } from '../../../../utils/enchancers';

const setShowFilter = ({
  getUrlParam,
  onResetUrlParam,
}) => () => {
  const isPage = getUrlParam(['page']);
  if (isPage) {
    onResetUrlParam({ page: null });
  }
};

const enhance = compose(
  getContext({
    values: PropTypes.shape({}),
    setFieldValue: PropTypes.func
  }),
  withTranslation(["common", "chat"]),
  withWindowWidth(),
  withHandlers({ setShowFilter }),
  withStateHandlers(
    {
      areMobileFiltersOpen: false
    },
    {
      toggleMobileFiltersHandler: ({ areMobileFiltersOpen }) => () => ({
        areMobileFiltersOpen: !areMobileFiltersOpen
      })
    }
  )
);
export default enhance(FiltersRow);
