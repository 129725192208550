const CUSTOM_FIELDS_MODELS = {
  PEOPLE: 'PEOPLE',
  CONTACT: 'CONTACT',
  LEAD_CLIENT: 'LEAD_CLIENT',
};
const MODAL_NAMES = {
  CUSTOM_FIELDS: 'customFieldsModal',
  DELETE_CUSTOM_FIELD: 'deleteCustomField',
};

export {
  CUSTOM_FIELDS_MODELS,
  MODAL_NAMES,
};
