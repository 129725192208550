import api from '../services/api.service';
import { extractData } from '../utils/helpers/extractDataHelper';

const schema = {
  openDirectChannelByUserId: async (params = {}) => {
    try {
      const response = await api.get(`/channels/direct/${params}`);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
};

export default schema;
