import React, { useCallback } from 'react';
import { values } from 'ramda';
import classNames from 'classnames';
import * as emojiData from 'emoji-mart/data/messenger.json';

import { ScrollWrapper } from '../..';
import { EmojiItem, CategoryItem } from './components';
import './style.sass';

/**
 *
 * @param isOpen {bool}
 * @param onSetEmoji {function}
 * @param setRef {function}
 * @param data {array}
 * @param onOpen {function}
 * @param selectedEmoji {number}
 * @param selectedCategory {number}
 * @param scrollToCategoryByElement {function}
 * @param onSetRefEmoji {function}
 * @param onSetRefScrollWrapper {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const EmojiPicker = ({
  isOpen,
  onSetEmoji,
  setRef,
  data = values(emojiData.categories),
  onOpen,
  selectedEmoji,
  selectedCategory,
  scrollToCategoryByElement,
  onSetRefEmoji,
  onSetRefScrollWrapper,
}) => {
  const onEventPrevent = useCallback((e) => {
    e.preventDefault();
    return false;
  });

  return (
    <div className={classNames('emoji', (isOpen: 'emoji--active'))}>
      <button
        type="button"
        className="message-textArea__control-button emoji"
        onClick={onOpen}
        onMouseDown={onEventPrevent}
      >
        <span className="icon-add-smile emoji-icon" />
      </button>
      {
        isOpen && (
          <div className="emoji__picker" ref={e => setRef('emojiPicker', e)}>
            <div className="emoji__picker-header">
              <ul className="emoji__picker-categories">
                {
                  data.map(category => (
                    <CategoryItem
                      scrollToCategoryByElement={scrollToCategoryByElement}
                      key={`emoji__picker-item-cat${category.id}`}
                      category={category}
                    />
                  ))
                }
              </ul>
            </div>
            <div className="emoji__picker-body" ref={onSetRefEmoji}>
              <ScrollWrapper refCustom={onSetRefScrollWrapper} className="emoji__picker-scroll">
                <ul className="emoji__picker-list" ref={e => setRef('emojiList', e)}>
                  {
                    data.map((category, categoryIndex) => (
                      <div
                        className="emoji__picker-group"
                        key={`category-group-${category.id}`}
                      >
                        <h3
                          key={`emoji__picker-item-${category.name}title`}
                          className="emoji__picker-category-title"
                          id={`emoji__category-${category.id}`}
                        >
                          {category.name}
                        </h3>
                        {
                         values(category.emojis).map((item, index) => (
                           <div
                             key={`emoji__picker-item-${category.name}${item}`}
                             className={
                               classNames(
                                 'emoji__picker-emoji',
                                 (selectedEmoji === index && selectedCategory === categoryIndex: 'emoji__picker-emoji--active'),
                               )
                             }
                           >
                             <EmojiItem
                               item={item}
                               onClick={onSetEmoji}
                             />
                           </div>
                         ))
                       }
                      </div>
                    ))
                  }
                </ul>
              </ScrollWrapper>
            </div>
          </div>
        )
      }
    </div>
  );
};
