import {
  compose, getContext, withHandlers,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import DeleteSprintModal from './deleteSprintModal';
import { projectSelectors } from '../../../../../state/project';
import { withSprintsCRUDHandlers } from '../../../../../utils/enchancers';
import { getModal } from '../../../../../state/ui/selectors';

const mapStateToProps = (state, { selectedSprintId }) => ({
  isOpen: getModal(state)('deleteSprintModal'),
  isPending: projectSelectors.getDeleteSprintRequestPending(state),
  sprint: projectSelectors.getProjectSprint(state)(selectedSprintId),
});

const onDeleteHandler = ({
  selectedSprintId, projectId, sprintCRUDMeta, onDeleteSprint,
}) => () => onDeleteSprint({
  meta: sprintCRUDMeta, sprintId: selectedSprintId, projectId, modalName: 'deleteSprintModal',
});

const onCloseHandler = ({ onCloseModal }) => () => onCloseModal('deleteSprintModal');

const enhance = compose(
  getContext({
    selectedSprintId: PropTypes.number.isRequired,
    sprintCRUDMeta: PropTypes.instanceOf(Object).isRequired,
  }),
  connect(mapStateToProps),
  withRouter,
  withSprintsCRUDHandlers,
  withHandlers({
    onDelete: onDeleteHandler,
    onClose: onCloseHandler,
  }),
);


export default enhance(DeleteSprintModal);
