import React, { memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonsCollection } from '../index';
import { messengerActions } from '../../state/messenger';
import { getUser } from '../../state/users/selectors';
import { getFullName } from '../../utils/helpers/userHelpers';
import './style.sass';

/**
 *
 * @param content {JSX.Element}
 * @param allowClose {boolean}
 * @param onClose {function}
 * @param message {object}
 * @param authorId {number}
 * @returns {JSX.Element}
 * @constructor
 */

export const ReplayedMessage = memo(({
  content,
  allowClose = false,
  onClose = () => {},
  message,
  authorId,
}) => {
  const { t } = useTranslation('common');
  const user = useSelector(state => getUser(state)(authorId));
  const dispatch = useDispatch();

  const fullName = getFullName(user);
  const setScrollToMessage = useCallback(() => {
    if (message && message.id) {
      dispatch(messengerActions.setScrollToMessage(message));
    }
  }, [message]);

  return (
    <div
      className="row message__replayed-message-wrapper"
      title="Scroll to message"
      onClick={setScrollToMessage}
      aria-hidden="true"
    >
      <h4 className="message__replayed-message__user-name">{fullName}</h4>
      <div className="message__replayed-message">
        <div className="message__replayed-message-content">
          {content}
        </div>
        {
          allowClose && (
            <ButtonsCollection.ButtonIcons
              title={t('Cancel reply')}
              className="message__replayed-message-content-close icon-close"
              onClick={onClose}
            />
          )
        }
      </div>
    </div>
  );
});
