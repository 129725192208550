import { identity } from 'ramda';
import { createActions } from 'redux-actions';
import types from './types';

export const {
  getBpRolesRequest,
  getBpProjectsRequest,
  getBpChannelsRequest,
  getBpUsersRequest,
  getBpClientsRequest,
} = createActions({
  [types.GET_BP_ROLES_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/roles',
      selectorName: 'getBpRolesRequest',
      method: 'GET',
      params,
      ...meta,
    }),
  ],
  [types.GET_BP_PROJECTS_REQUEST]: [
    identity,
    ({ processId, ...params }, meta) => ({
      async: true,
      route: '/projects',
      selectorName: 'getBpProjectsRequest',
      method: 'GET',
      params,
      ...meta,
    }),
  ],
  [types.GET_BP_CHANNELS_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/channels',
      selectorName: 'getBpChannelsRequest',
      method: 'GET',
      params,
      ...meta,
    }),
  ],
  [types.GET_BP_USERS_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/users/autocomplete',
      selectorName: 'getBpUsersRequest',
      method: 'GET',
      params,
      ...meta,
    }),
  ],
  [types.GET_BP_CLIENTS_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/clients/autocomplete',
      selectorName: 'getBpClientsRequest',
      method: 'GET',
      params,
      ...meta,
    }),
  ],
});
