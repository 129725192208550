/* eslint-disable import/prefer-default-export */
const ROUTE_TYPES = {
  AUTHENTICATED: 'authenticated',
  GUEST: 'guest',
};

const CHANNELS_ROUTES = {
  CHANNELS: '/messenger/channels/',
  DIRECTS: '/messenger/directs/',
};

const LABELS = {
  TASKS: 'Tasks',
  PROJECT: 'Project',
  RECENT: 'Recent',
  OLD: 'Old',
};

const PRELOADER_DURATION = 1000;

const PRELOADER_DIMENSION = {
  EXTRA_SMALL: 50,
  SMALL: 75,
  MIDDLE: 100,
  DEFAULT: 125,
  LARGE: 200,
};

const KEYS = {
  ENTER: 13,
  TAB: 9,
  ESCAPE: 27,
  DOWN_ARROW: 40,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  UP_ARROW: 38,
  SHIFT: 16,
};

const STATUS = {
  ONLINE: 1,
  OFFLINE: 2,
};

const WINDOW_WIDTH = {
  MIN_WIDTH: 320,
  X_SMALL: 481,
  SMALL: 769,
  MEDIUM: 981,
  LARGE: 1241,
  X_LARGE: 1661,
  EXTRA_LARGE: 2101,
};

const TYPES_NOTIFICATION = {
  MESSAGE: 1,
};

const IMAGES_SIZE = {
  xs: '?width=72&height=72&crop=1',
  beforeSmall: '?width=96&height=96&crop=1',
  sm: '?width=126&height=126&crop=1',
  original: '',
};

const FILTERS_NAMES = {
  PROJECT_TASKS: 'PROJECT_TASKS',
  AGENDA: 'AGENDA',
};

const FILTERS_VALUES = {
  [FILTERS_NAMES.PROJECT_TASKS]: 'ProjectTasks',
  [FILTERS_NAMES.AGENDA]: 'Agenda',
};

const VIEWS = {
  me: 'myStatistics',
  users: 'users',
  projects: 'project',
};

export {
  ROUTE_TYPES,
  TYPES_NOTIFICATION,
  KEYS,
  STATUS,
  PRELOADER_DURATION,
  PRELOADER_DIMENSION,
  WINDOW_WIDTH,
  IMAGES_SIZE,
  CHANNELS_ROUTES,
  FILTERS_VALUES,
  FILTERS_NAMES,
  VIEWS,
  LABELS,
};
