import { schema } from 'normalizr';

const hrCandidatesSchema = [new schema.Entity('candidates')];
const hrCommentsSchema = [new schema.Entity('comments')];
const hrFilesSchema = [new schema.Entity('files')];

export {
  hrCandidatesSchema,
  hrCommentsSchema,
  hrFilesSchema,
};
