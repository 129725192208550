import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Label } from '../../../../ui-kit';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { isChannelPrivate } from '../../../../utils/helpers/messengerHelpers/channelHelpers';

const ChannelsItem = ({ channel, onChannelAuthorClick }) => (
  <li key={channel.id} className="channels-list__item">
    <Link to={`/messenger/${channel.id}`} className="channels-list__link">
      <div className="channels-list__title-group">
        {
          isChannelPrivate(channel.type) ? (
            <span className="channels-list__title-group-icon icon-chanel-lock-icon" />
          ) : (
            <span className="channels-list__title-group-icon icon-chanel-icon" />
          )
        }
        <h3 className="channels-list__title-group-title">{channel.name}</h3>
      </div>
      <div className="channels-list__created-by">

        Created by
        <button type="button" onClick={onChannelAuthorClick}>{`${getFullName(channel.author)} `}</button>
        {`at ${moment(channel.created_at).format('DD MMM YYYY')}`}
      </div>
      <Label className="channels-list__link-to  label--xs">
        <span
          className="channels-list__link-to-icon icon-argon-arrow"
        />
      </Label>
    </Link>
  </li>
);

ChannelsItem.propTypes = {
  channel: PropTypes.instanceOf(Object),
  onChannelAuthorClick: PropTypes.func.isRequired,
};

ChannelsItem.defaultProps = {
  channel: {},
};

export default ChannelsItem;
