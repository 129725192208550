import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { uiActions } from '@/state/ui';
import { useWindowWidth } from '@/utils/hooks';

export const useBusinessProcesses = () => {
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch();

  const onCreateProcessModal = useCallback(() => {
    dispatch(uiActions.openModal('createProcessModal'));
  }, [dispatch, uiActions]);

  return { windowWidth, onCreateProcessModal };
};
