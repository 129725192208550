import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ButtonsCollection, Layouts } from '../../../../ui-kit';
import {
  Header,
  PipeLinesContainer,
} from '../../../../containers';
import { LeftSideBar } from '../../../../newContainers';
import { CRM_ROUTES } from '../../../../constants/crm';
import './style.sass';
import CRMModules from '../CRMModules/index';

const Pipelines = ({ t, onCreatePipelineModal, selectedPipelineId }) => (
  <Layouts.Main rightSideBarStatus={false}>
    <LeftSideBar>
      <CRMModules />
    </LeftSideBar>
    <div className="main-container page-activity__main-container">
      <Header>
        <div className="pipelines__page-title">
          <NavLink className="pipelines__go-to-leads" to={CRM_ROUTES.LEADS}>
            <span className="icon-expand-arrow-left" title="Back to leads" />
          </NavLink>
          <h1 className="weight--medium title--xl title-page">
            {t('Pipelines')}
          </h1>
        </div>
        <ButtonsCollection.ButtonBrill
          onClick={onCreatePipelineModal}
          className="button--sm header__control-button--project"
        >
              Create pipeline
        </ButtonsCollection.ButtonBrill>
      </Header>
      <div className="main-container__content page-activity__activity-content">
        <PipeLinesContainer selectedPipelineId={selectedPipelineId} />
      </div>
    </div>
  </Layouts.Main>
);
Pipelines.propTypes = {
  onCreatePipelineModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  selectedPipelineId: PropTypes.number.isRequired,
};
export default Pipelines;
