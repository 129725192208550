import React from 'react';

import { ConfirmModal } from '@/ui-kit';
import { useDeleteNodeModal } from './useDeleteNodeModal';

export const DeleteNodeModal = ({ isPending }) => {
  const { isOpen, selectedEvent, onDelete, onClose } = useDeleteNodeModal();
  return (
    <ConfirmModal
      isOpen={isOpen}
      isPending={isPending}
      actionTitle="Delete"
      onAction={onDelete}
      onClose={onClose}
      title="Delete node"
    >
      Are you sure you want to delete
      <b>{selectedEvent?.title}</b>
      node?
    </ConfirmModal>
  );
};
