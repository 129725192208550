import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { organizeFields } from '../../../utils/helpers/crmHelpers/customFieldsHelper';
import { ModalWindow } from '../../../ui-kit';
import DeleteCustomFieldModal from '../deleteCustomFieldModal';
import CustomFieldsForm from '../customFieldsForm';
import api from '../../../api/custom.service';


/**
 *
 * @param customCallback {function}
 * @param customFieldModel {string}
 * @param isOpen {boolean}
 * @param setIsOpen {function}
 * @returns {JSX.Element}
 *
 */

const CustomFieldsModal = ({
  customCallback, customFieldModel, isOpen, setIsOpen,
}) => {
  const { t } = useTranslation('common');
  const [idToDelete, setIdToDelete] = useState(null);
  const [customFieldsList, setCustomFieldsList] = useState([]);

  useEffect(() => {
    const getFields = async () => {
      const { data } = await api.getFields();
      const orderedData = organizeFields(data, customFieldModel);
      setCustomFieldsList(orderedData);
    };
    getFields();
  },
  []);

  const onCloseModal = useCallback(() => {
    customCallback();
    setIsOpen(false);
  }, [customCallback]);

  return <>
    <ModalWindow
      isOpen={isOpen}
      onClose={onCloseModal}
      modalName={customFieldModel}
      title={t('Custom fields')}
      className="modal-window__custom-fields"
    >
      <CustomFieldsForm
        setIdToDelete={setIdToDelete}
        customFieldsList={customFieldsList}
        setCustomFieldsList={setCustomFieldsList}
        customFieldModel={customFieldModel}
      />
    </ModalWindow>
    <DeleteCustomFieldModal
      idToDelete={idToDelete}
      setIdToDelete={setIdToDelete}
      customFieldsList={customFieldsList}
      setCustomFieldsList={setCustomFieldsList}
    />
  </>;
};

CustomFieldsModal.defaultProps = {
  customCallback: () => {},
  setIsOpen: () => {},
};

export default CustomFieldsModal;
