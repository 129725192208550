import { compose, identity, mergeLeft, pathOr } from 'ramda';
import { createActions } from 'redux-actions';
import { uiActions } from '@/state/ui';
import { callNotification } from '@/utils/helpers/notifies';
import { getNormalizeErrorObject } from '@/utils/helpers/requestHelpers';
import types from './types';

const errorPageDataWhenGetBps = compose(mergeLeft({ href: '/' }), getNormalizeErrorObject);

export const {
  getBusinessProcessLogsRequest,
  deleteBusinessProcessNodeRequest,
  addBusinessProcessNodeRequest,
  editBusinessProcessNodeRequest,
  getBusinessProcessesRequest,
  getBusinessProcessRequest,
  editBusinessProcessRequest,
  getBusinessProcessEventsRequest,
  addBusinessProcessRequest,
  deleteBusinessProcessRequest,
  setBusinessProcessEvents,
  setBusinessProcess,
  setBusinessProcesses,
  selectBusinessProcess,
  deleteBusinessProcess,
  setCrudNodesConfig,
  selectEventById,
  setBusinessProcessNode,
  selectNodeById,
  editBusinessProcessNode,
  clearDataBeforeClosePage,
  deleteBusinessProcessNode,
  selectVariableKey,
  setNewVariable,
  cleanFormAfterSubmit,
  setBusinessProcessLogs,
  editBusinessProcess,
} = createActions(
  {
    [types.GET_BUSINESS_PROCESS_LOGS_REQUEST]: [
      identity,
      (processId, meta) => ({
        async: true,
        route: `/bp/${processId}/logs`,
        selectorName: 'getBusinessProcessLogsRequest',
        method: 'GET',
        params: {},
        actions: {
          success: setBusinessProcessLogs,
        },
        ...meta,
      }),
    ],
    [types.DELETE_BUSINESS_PROCESS_NODE_REQUEST]: [
      identity,
      ({ processId, nodeId, ...params }, meta) => ({
        async: true,
        route: `/bp/${processId}/nodes/${nodeId}/`,
        selectorName: 'deleteBusinessProcessNodeRequest',
        method: 'DELETE',
        params,
        actions: {
          success: () => deleteBusinessProcessNode(nodeId),
        },
        callbacks: { error: callNotification.error, success: callNotification.success },
        ...meta,
      }),
    ],
    [types.ADD_BUSINESS_PROCESS_NODE_REQUEST]: [
      identity,
      ({ processId, ...params }, meta) => ({
        async: true,
        route: `/bp/${processId}/nodes`,
        selectorName: 'addBusinessProcessNodeRequest',
        method: 'POST',
        params,
        actions: {
          success: setBusinessProcessNode,
        },
        callbacks: { error: callNotification.error, success: callNotification.success },
        ...meta,
      }),
    ],
    [types.EDIT_BUSINESS_PROCESS_NODE_REQUEST]: [
      identity,
      ({ nodeId, processId, ...params }, meta) => ({
        async: true,
        route: `/bp/${processId}/nodes/${nodeId}/`,
        selectorName: 'editBusinessProcessNodeRequest',
        method: 'PUT',
        params,
        actions: {
          success: editBusinessProcessNode,
        },
        callbacks: { error: callNotification.error, success: callNotification.success },
        ...meta,
      }),
    ],
    [types.GET_BUSINESS_PROCESSES_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/bp',
        selectorName: 'getBusinessProcessesRequest',
        method: 'GET',
        params,
        actions: {
          success: setBusinessProcesses,
          error: compose(uiActions.setErrorPage, errorPageDataWhenGetBps),
        },
        ...meta,
      }),
    ],
    [types.GET_BUSINESS_PROCESS_REQUEST]: [
      identity,
      ({ processId, ...params }, meta) => ({
        async: true,
        route: `/bp/${processId}`,
        selectorName: 'getBusinessProcessRequest',
        method: 'GET',
        params,
        actions: {
          success: setBusinessProcess,
          error: uiActions.setErrorPage,
        },
        ...meta,
      }),
    ],
    [types.EDIT_BUSINESS_PROCESS_REQUEST]: [
      identity,
      ({ processId, ...params }, meta) => ({
        async: true,
        route: `/bp/${processId}`,
        selectorName: 'editBusinessProcessRequest',
        method: 'PUT',
        params,
        actions: {
          success: editBusinessProcess,
          error: uiActions.setErrorPage,
        },
        callbacks: { error: callNotification.error, success: callNotification.success },
        ...meta,
      }),
    ],
    [types.GET_BUSINESS_PROCESS_EVENTS_REQUEST]: [
      identity,
      (params) => ({
        async: true,
        route: '/bp/events/',
        selectorName: 'getBusinessProcessEventsRequest',
        method: 'GET',
        params,
        actions: {
          success: setBusinessProcessEvents,
        },
      }),
    ],
    [types.ADD_BUSINESS_PROCESS_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/bp',
        selectorName: 'addBusinessProcessRequest',
        method: 'POST',
        params,
        actions: {
          success: setBusinessProcess,
        },
        ...meta,
        callbacks: {
          success: pathOr(identity, ['callbacks', 'success'], meta),
        },
      }),
    ],
    [types.DELETE_BUSINESS_PROCESS_REQUEST]: [
      identity,
      ({ processId, ...params }, meta) => ({
        async: true,
        route: `/bp/${processId}`,
        selectorName: 'deleteBusinessProcessRequest',
        method: 'DELETE',
        params,
        actions: {
          meta,
          success: () => deleteBusinessProcess(processId),
        },
        ...meta,
      }),
    ],
  },
  types.SET_BUSINESS_PROCESS_EVENTS,
  types.SET_BUSINESS_PROCESS,
  types.SET_BUSINESS_PROCESSES,
  types.SELECT_BUSINESS_PROCESS,
  types.DELETE_BUSINESS_PROCESS,
  types.SET_CRUD_NODES_CONFIG,
  types.SELECT_EVENT_BY_ID,
  types.SET_BUSINESS_PROCESS_NODE,
  types.SELECT_NODE_BY_ID,
  types.EDIT_BUSINESS_PROCESS_NODE,
  types.CLEAR_DATA_BEFORE_CLOSE_PAGE,
  types.DELETE_BUSINESS_PROCESS_NODE,
  types.SELECT_VARIABLE_KEY,
  types.SET_NEW_VARIABLE,
  types.CLEAN_FORM_AFTER_SUBMIT,
  types.SET_BUSINESS_PROCESS_LOGS,
  types.EDIT_BUSINESS_PROCESS,
);
