const COLUMNS_BOARD_BY_ID = {
  1: 'toDo',
  2: 'inProgress',
  3: 'done',
};

export {
  // eslint-disable-next-line import/prefer-default-export
  COLUMNS_BOARD_BY_ID,
};

export const SPRINTS_LIMIT = 25;
