import { compose } from 'recompose';

import { connect } from 'react-redux';
import FileItem from './fileItem';
import { usersSelectors } from '../../../../../../state/users';

const mapStateToProps = (state, { file }) => ({
  userName: usersSelectors.getUser(state)(file.created_by),
});

const enhance = compose(connect(mapStateToProps));
export default enhance(FileItem);
