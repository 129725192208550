import * as projectActions from './actions';
import * as projectSelectors from './selectors';
import * as projectOperations from './operations';
import * as projectSchemas from './schema';
import projectTypes from './types';
import reducer from './reducers';

export {
  projectActions,
  projectSelectors,
  projectOperations,
  projectSchemas,
  projectTypes,
};

export default reducer;
