import { createSelector } from 'reselect/lib/index';
import {
  pathOr, identity,
} from 'ramda';

const getSprintsList = createSelector(pathOr([], ['sprints', 'result']), identity);
const getSprintsEntities = createSelector(pathOr({}, ['sprints', 'entities', 'items']), identity);

const getSprintsRequestPending = createSelector(
  pathOr(false, ['pending', 'getSprintsRequest']),
  identity,
);

export {
  getSprintsList,
  getSprintsEntities,
  getSprintsRequestPending,
};
