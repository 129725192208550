import { createSelector } from 'reselect';
import { pathOr } from 'ramda';

const getPendingRequest = (state, request) => createSelector(
  pathOr(false, ['pending', request]),
  status => status,
)(state);

export {
  // eslint-disable-next-line import/prefer-default-export
  getPendingRequest,
};
