import {
  compose, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import {
  pathOr, __, isEmpty,
} from 'ramda';
import EditLeadModal from './editLeadModal';
import rules from '../rules';
import { uiActions } from '../../../../state/ui';
import { leadsActions } from '../../../../state/leads';
import { withLeadsCRUD, withEditLead } from '../../../../utils/enchancers';
import { PIPELINE_TYPES } from '../../../../constants/crm';
import { callNotification } from '../../../../utils/helpers/notifies';
import { getTimezoneAutocompleteHelper } from '../../../../utils/helpers/dateHelpers';

const mapDispatchToProps = {
  closeEditModal: () => uiActions.closeModal('editLeadModal'),
  convertLeadToClient: leadsActions.convertLeadToClientRequest,
};

const enhance = compose(
  withEditLead,
  connect(null, mapDispatchToProps),
  withLeadsCRUD({
    pendingSelector: 'editLeadRequest',
    modalName: 'editLeadModal',
    rules,
    onSubmit: (formData, {
      resetForm, props: {
        editLead, closeEditModal, selectedLeadId, setIsNeedRefresh, convertLeadToClient, onSuccess,
      },
    }) => {
      const getFormField = pathOr(null, __, formData);
      if (isEmpty(formData.contact.avatar)) {
        // eslint-disable-next-line no-param-reassign
        formData.contact.avatar = null;
      }
      if (isEmpty(formData.contact.email)) {
        // eslint-disable-next-line no-param-reassign
        formData.contact.email = null;
      }
      editLead({
        id: selectedLeadId,
        contact: {
          ...formData.contact,
          timezone: formData.contact.timezone.value,
        },
        pipeline_id: getFormField(['pipeline_id', 'value']),
        pipeline_status_id: getFormField(['pipeline_status_id', 'value']),
      }, {
        callbacks: {
          success: () => {
            closeEditModal();
            resetForm({});
            setIsNeedRefresh(true);
            if (getFormField(['pipeline_status_id', 'type']) === PIPELINE_TYPES.WON) convertLeadToClient({ id: selectedLeadId, ...formData });
            if (onSuccess) {
              onSuccess();
            }
          },
          error: () => callNotification.error('Email should be unique'),
        },
      });
    },
  }),
  withHandlers({
    getAutocompleteHandler: getTimezoneAutocompleteHelper,
  }),
);
export default enhance(EditLeadModal);
