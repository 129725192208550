import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';

import {
  ConfirmModal,
} from '../../../../../ui-kit';

const CompleteSprintModal = ({
  isOpen,
  isPending,
  onClose,
  sprint,
  onComplete,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Complete"
    onAction={onComplete}
    onClose={onClose}
    title="Complete Sprint"
  >

    Are you sure you want to complete
    <b>{prop('title', sprint)}</b>

    sprint?
  </ConfirmModal>
);

CompleteSprintModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sprint: PropTypes.instanceOf(Object).isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default CompleteSprintModal;
