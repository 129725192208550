import * as hrActions from './actions';
import * as hrSelectors from './selectors';
import * as hrOperations from './operations';
import hrTypes from './types';
import reducer from './reducers';

export {
  hrActions,
  hrTypes,
  hrSelectors,
  hrOperations,
};

export default reducer;
