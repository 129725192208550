import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ToggleButton,
} from '../../../../../ui-kit';
import './style.sass';


/**
 *
 * @param email_notification{boolean}
 * @param emailNotificationHandler{function}
 * @constructor
 */

export const EmailNotification = ({
  emailNotificationHandler,
  emailNotification,
}) => {
  const { t } = useTranslation();
  return (
    <div className="email_notification">
      <label htmlFor="email_notification">
        {t('Email notification')}
      </label>
      <ToggleButton
        id="email_notification"
        name="email_notification"
        onChange={emailNotificationHandler}
        status={emailNotification}
      />

    </div>
  );
};
