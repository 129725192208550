import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const ScrollToBottomButton = ({ onScrollToBottom, isShow }) => {
  const buttonHeight = document.getElementById('textarea')?.offsetHeight;
  const ADDITIONAL_HEIGHT = 50;
  const height = buttonHeight + ADDITIONAL_HEIGHT;

  return (
    <button
      type="button"
      id="scrollButton"
      className="messenger_scrollToBottom__button"
      title="Scroll to bottom"
      onClick={onScrollToBottom}
      style={{ bottom: isShow ? `${height}px` : '-200px', opacity: isShow ? 1 : 0, transition: '0.5s' }}
    >
      <span className="icon-expand-arrow-dn" style={{ marginRight: 0, '&:before': { color: '#fff' } }} />
    </button>
  );
};

ScrollToBottomButton.propTypes = {
  onScrollToBottom: PropTypes.func.isRequired,
  isShow: PropTypes.bool.isRequired,
};

export default ScrollToBottomButton;
