import {
  branch,
  compose, getContext, renderNothing,
} from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import EditTaskModal from './editTaskModal';
import rules from '../modalViewTasks/rules';
import withCRUDTask from '../../../../utils/enchancers/withCRUDTask';
import {
  setTaskData,
} from '../../../../utils/helpers/taskHelpers/crudHelper';
import { tasksActions } from '../../../../state/tasks';
import { clientsActions, clientsSelectors } from '../../../../state/clients';
import { TASKS_WITHOUT_PROJECT } from '../../../../constants/tasks';

const mapStateToProps = (state, { selectedClientTask }) => ({
  task: clientsSelectors.getClientTaskSelector(state)(selectedClientTask),
});

const mapDispatchToProps = ({
  editTask: tasksActions.updateTaskRequest,
  getClientTasks: clientsActions.getClientTasksRequest,
  getClientLogs: clientsActions.getClientLogsRequest,
  editClientTaskSuccess: clientsActions.editClientTaskSuccess,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common', 'tasks']),
  getContext({
    selectedClientId: PropTypes.any,
  }),
  branch(
    ({ task }) => !task,
    renderNothing,
  ),
  withCRUDTask({
    rules,
    data: ({ task }) => task,
    modalName: 'editPartOfTaskClientModal',
    onSubmit: (formValues, {
      resetForm,
      props: {
        editTask, onSuccess, selectedClientId, getClientTasks, onCloseModal, task,
        getClientLogs, editClientTaskSuccess,
      },
    }) => {
      editTask(
        {
          ...setTaskData(
            formValues,
          ),
          status: task.status,
          status_id: task.status_id,
          projectId: propOr(TASKS_WITHOUT_PROJECT, 'project_id', task),
          id: task.id,
          clientId: selectedClientId,
        }, {
          section: 'project',
          callbacks: {
            success: () => {
              resetForm({});
              onCloseModal('editPartOfTaskClientModal');
              getClientTasks({ id: selectedClientId });
              getClientLogs({ id: selectedClientId });
              editClientTaskSuccess({ selectedClientId });
              if (onSuccess) {
                onSuccess();
              }
            },
          },
        },
      );
    },
  }),
);

export default enhance(EditTaskModal);
