import React from 'react';

import { ModalWindow, ScrollWrapper, TabWrapper } from '@/ui-kit';
import { VariableItem } from './components';
import { useVariablesModal } from './useVariablesModal';
import './style.sass';

export const VariablesModal = () => {
  const { variablesOfNodes, isOpen, onCloseModal } = useVariablesModal();
  return (
    <ModalWindow
      title="Select variables"
      isOpen={isOpen}
      onClose={onCloseModal}
      isPreventTouch
      onCloseModal={onCloseModal}
      className="modal-window--big modal-window__bp-variables"
    >
      <div className="bpVariables__list">
        <ScrollWrapper heightIs={400} autoHide>
          {variablesOfNodes.map(({ id, title, variables }) => (
            <TabWrapper
              key={id}
              title={<div className="bpVariables__title-group">{`[${id}]   ${title} `}</div>}
            >
              {variables.map((variable) => (
                <VariableItem variable={variable} id={id} key={id} onCloseModal={onCloseModal} />
              ))}
            </TabWrapper>
          ))}
        </ScrollWrapper>
      </div>
    </ModalWindow>
  );
};
