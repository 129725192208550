import {
  branch, compose, getContext, renderNothing, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { getModal } from '../../../../../../state/ui/selectors';
import DeleteMessageModal from './deleteCommentModal';
import { taskActions, taskSelectors } from '../../../../../../state/task';
import { uiActions } from '../../../../../../state/ui';

const mapStateToProps = (state, { selectedCommentId }) => ({
  isOpen: getModal(state)('deleteCommentModal'),
  task: taskSelectors.getComment(state)(selectedCommentId),
});

const mapDispatchToProps = ({
  closeModal: uiActions.closeModal,
  deleteComment: taskActions.deleteTaskCommentRequest,
});

const onDeleteHandler = ({
  deleteComment, closeModal, clientId,
  match: { params: { id = null, taskId } },
  selectedCommentId: commentId,
}) => () => {
  deleteComment({
    projectId: id, taskId, clientId, commentId,
  }, {
    callbacks: {
      success: () => {
        closeModal('deleteCommentModal');
      },
    },
  });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ task: { id } }) => !id, renderNothing),
  withRouter,
  getContext({
    clientId: PropTypes.string,
  }),
  withTranslation(['common']),
  withHandlers({
    onDelete: onDeleteHandler,
  }),
);

export default enhance(DeleteMessageModal);
