import React from 'react';
import PropTypes from 'prop-types';
import { BrillMask, Avatar } from '../../../../ui-kit';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { formatDate } from '../../../../utils/helpers/commonHelpers';
import { renderLogType } from './historyLogRenders';
import './style.sass';
import { WINDOW_WIDTH } from '../../../../constants/ui';

const History = ({ logs, t, windowWidth }) => (
  <div className="history">
    <ul className="history__list">
      {
        Object.keys(logs).map((logData) => {
          const logItems = logs[logData];
          return (
            <li className="history__item">
              <span className="history__time-line">
                {logData}
              </span>
              <ul className="history__list">
                {
                  logItems.map(({
                    user,
                    created_at,
                    model_name: type,
                    logs: fieldLogs,
                  }) => fieldLogs.map((log) => {
                    const renderMethod = renderLogType[type];

                    return user ? (
                      <li className="history__item">
                        { windowWidth > WINDOW_WIDTH.SMALL ? (
                          <>
                            <div className="history__user">
                              <BrillMask className="history__user-icon" asLink to="/">
                                <Avatar src={getImageUrl(user.avatar)} alt={getFullName(user)} />
                              </BrillMask>
                              <b className="history__user-name">
                                {getFullName(user)}
                              </b>
                            </div>
                            {renderMethod && renderMethod(log, t)}
                            <span className="history__time">{formatDate(created_at, 'h:mm:ss A')}</span>
                          </>
                        ) : (
                          <>
                            <div className="history__mobile-block">
                              <div className="history__user">
                                <BrillMask className="history__user-icon" asLink to="/">
                                  <Avatar src={getImageUrl(user.avatar)} alt={getFullName(user)} />
                                </BrillMask>
                                <b className="history__user-name">
                                  {getFullName(user)}
                                </b>
                              </div>
                              <span className="history__time">{formatDate(created_at, 'h:mm:ss A')}</span>
                            </div>
                            <div className="history__title text--cut">
                              {renderMethod && renderMethod(log, t)}
                            </div>
                          </>
                        )}
                      </li>
                    ) : ('');
                  }))
                }
              </ul>
            </li>
          );
        })
      }
    </ul>
  </div>
);

History.propTypes = {
  logs: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

export default History;
