import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ButtonsCollection, Layouts } from '../../../../ui-kit';
import {
  Header, InvoicesContainer, NewInvoicePage,
} from '../../../../containers';
import { LeftSideBar } from '../../../../newContainers';
import CRMModules from '../CRMModules/index';
import { RightSideBarInvoicesContainer } from './components';
import './style.sass';
import { WINDOW_WIDTH } from '../../../../constants/ui';

const Invoices = ({
  t, windowWidth, onCreateInvoiceButtonClick, isNewInvoicePage, isEditInvoicePage,
}) => (
  <Layouts.Main noRightSidebar={isNewInvoicePage || isEditInvoicePage}>
    <LeftSideBar>
      <CRMModules />
    </LeftSideBar>
    <div className="main-container page-invoices__main-container">
      <Header hideToggleRightSideBar={isNewInvoicePage || isEditInvoicePage}>
        {isNewInvoicePage ? (
          <div className="invoices-header">
            <NavLink className="invoices-header__go-to-invoices" to="/crm/invoices/">
              <span className="icon-expand-arrow-left" title="Back to invoices" />
            </NavLink>
            <h1 className="weight--medium title--xl title-page">
              {
                  // eslint-disable-next-line no-nested-ternary
                  isNewInvoicePage
                    ? t('New invoice')
                    : isEditInvoicePage
                      ? t('Edit invoice')
                      : t('Invoices')
                }
            </h1>
          </div>
        ) : (
          <h1 className="weight--medium title--xl title-page">
            {
                // eslint-disable-next-line no-nested-ternary
                isNewInvoicePage
                  ? t('New invoice')
                  : isEditInvoicePage
                    ? t('Edit invoice')
                    : t('Invoices')
              }
          </h1>
        )}
        {
            !isEditInvoicePage && !isNewInvoicePage && (
              windowWidth > WINDOW_WIDTH.MEDIUM ? (
                <ButtonsCollection.ButtonBrill
                  onClick={onCreateInvoiceButtonClick}
                  className="button--sm header__control-button--project"
                >

                  New invoice
                </ButtonsCollection.ButtonBrill>
              ) : (
                <ButtonsCollection.ButtonPlus
                  onClick={onCreateInvoiceButtonClick}
                  className="header__control-button--project"
                />
              )
            )
          }
      </Header>
      <div className="main-container__content page-invoices__content">
        {
          !isNewInvoicePage && !isEditInvoicePage
            ? <InvoicesContainer />
            : <NewInvoicePage isEditInvoicePage={isEditInvoicePage} />
         }
        {
            !isNewInvoicePage && !isEditInvoicePage && (
              <RightSideBarInvoicesContainer windowWidth={windowWidth} />
            )
          }
      </div>
    </div>
  </Layouts.Main>
);

export default Invoices;

Invoices.propTypes = {
  t: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  onCreateInvoiceButtonClick: PropTypes.func.isRequired,
  isNewInvoicePage: PropTypes.bool.isRequired,
  isEditInvoicePage: PropTypes.bool.isRequired,
};
