import {
  compose, getContext, lifecycle, withHandlers, withState, withStateHandlers,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as routerPush } from 'connected-react-router';
import HrComponent from './hrComponent';
import withGrid from '../../../../../utils/enchancers/withGrid';
import { hrActions, hrSelectors } from '../../store/candidate';
import { withPagination, withUrlParams } from '../../../../../utils/enchancers';
import { calculateOffset } from '../../../../../utils/helpers/uiComponentHelpers/pagination';
import { CANDIDATES_LIMIT } from './constants';

const mapStateToProps = state => ({
  candidates: hrSelectors.getHrCandidatesList(state),
  candidatesEntities: hrSelectors.getHrCandidatesEntities(state),
  candidatesCount: hrSelectors.getHrCandidatesCount(state),
  candidatesHasMore: hrSelectors.getHrCandidatesHasMore(state),
  isPending: hrSelectors.getHrCandidatesPending(state),
});

const mapDispatchToProps = {
  push: routerPush,
  getHrCandidates: hrActions.getHrCandidatesRequest,
  deleteHrCandidateRequest: hrActions.deleteHrCandidateRequest,
};

const deleteCandidateHandler = ({
  selectedCandidate, setSelectedCandidate, deleteHrCandidateRequest, pagination,
}) => () => {
  if (selectedCandidate && selectedCandidate.id) {
    deleteHrCandidateRequest({ id: selectedCandidate.id }, {
      ...pagination,
    });
  }
  setSelectedCandidate(null);
};

const selectPositionHandler = ({ setSelectedFilterPosition }) => ({ val }) => {
  setSelectedFilterPosition(val);
};

const selectStatusHandler = ({ setSelectedFilterStatus }) => ({ val }) => {
  setSelectedFilterStatus(val);
};

const queryBuilder = (status, position) => {
  const where = [];
  if (status.value || status.value === 0) {
    where.push({ field: 'status', value: status.value });
  }
  if (position.value || position.value === 0) {
    where.push({ field: 'position', value: position.value });
  }
  return { where };
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('selectedCandidate', 'setSelectedCandidate', null),
  withState('selectedFilterStatus', 'setSelectedFilterStatus', { id: null }),
  withState('selectedFilterPosition', 'setSelectedFilterPosition', { id: null }),
  withUrlParams({}),
  withPagination({
    limit: () => CANDIDATES_LIMIT,
    offset: ({ getUrlParam }) => calculateOffset(getUrlParam(['page'], 1), CANDIDATES_LIMIT),
    page: ({ getUrlParam }) => getUrlParam(['page'], 1),
  }),
  withHandlers({
    deleteCandidate: deleteCandidateHandler,
    selectPosition: selectPositionHandler,
    selectStatus: selectStatusHandler,
  }),
  withStateHandlers(() => ({ searchValue: '', searchByValue: '' }), {
    setSearchValue: () => ({ target: { value } }) => ({ searchValue: value }),
    onSearch: ({ searchValue }) => () => ({ searchByValue: searchValue }),
  }),
  withGrid({
    limit: CANDIDATES_LIMIT,
    gridName: 'hrCandidatesList',
    searchValue: ({ searchByValue }) => searchByValue,
    hasMore: ({ candidatesHasMore }) => candidatesHasMore,
    action: ({ getHrCandidates }) => getHrCandidates,
    count: ({ candidatesCount }) => candidatesCount,
    sortKey: 'sortBy',
    searchByKey: 'name',
    customParams: ({
      selectedFilterStatus, selectedFilterPosition,
    }) => queryBuilder(selectedFilterStatus, selectedFilterPosition),
  }),
  getContext({ setHeadTitle: PropTypes.func }),
  lifecycle({
    componentDidMount() {
      const { setHeadTitle } = this.props;
      setHeadTitle(null);
    },
  }),
);
export default enhance(HrComponent);
