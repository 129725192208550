import {
  branch, compose, lifecycle, renderNothing,
} from 'recompose';

import { connect } from 'react-redux';
import {
  pathOr, equals, always, cond, includes, values,
} from 'ramda';

import { userSelectors } from '../../state/user';
import { uiActions } from '../../state/ui';
import { SERVER_ERRORS } from '../../constants/config';

const mapStateToProps = state => ({
  myRole: userSelectors.getUserData(state).role_id,
});

const mapDispatchToProps = {
  setPageError: uiActions.setErrorPage,
};

const withMyPermissions = compose(
  connect(state => ({
    myPermissions: userSelectors.getMyPermissions(state),
  })),
);

const withPermission = module => compose(
  withMyPermissions,
  branch(({ myPermissions }) => !myPermissions, renderNothing),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { myPermissions, setPageError } = this.props;
      const isForbidden = !pathOr(
        cond([
          [equals(0), always(false)],
          [includes(1, values(myPermissions)), always(true)],
          // todo: Sometimes backkend response not fully list permissions for module ,
          //  here but if user can UPDATE, DELETE, CREATE element I think he also can read it =)
        ]), [module, 'read'], myPermissions,
      );
      if (isForbidden) {
        setPageError(SERVER_ERRORS[403]);
      }
    },
  }),
);

export default withPermission;
