import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection, DropDown } from '../../../../../../ui-kit';

const HeaderDropDown = ({
  onEditChannel, onDeleteChannel, onLeaveChannel, isUserOwnsChannel, isRenderChannelControl,
}) => (
  <>
    {
    isRenderChannelControl && (
      <DropDown
        className="project-header-control"
        name="project-header"
        label={(
          <ButtonsCollection.ButtonIcons
            title="Controls for Channels"
            className="header-col__project-burger"
          >
            <span className="icon-burger-dots-gorizont" />
          </ButtonsCollection.ButtonIcons>
        )}
        list={
          [
            ...(isUserOwnsChannel ? [
              {
                id: 0,
                label: (
                  <>
                    <span className="project-header-control__icon icon-edit-icon" />
                    <span className="project-header-control__text">Edit channel</span>
                  </>
                ),
                onClick: onEditChannel,
              },
              {
                id: 1,
                label: (
                  <>
                    <span className="project-header-control__icon icon-delete-icon" />
                    <span className="project-header-control__text">Delete channel</span>
                  </>
                ),
                onClick: onDeleteChannel,
              },
            ] : [
              {
                id: 2,
                label: (
                  <>
                    <span className="project-header-control__icon icon-delete-icon" />
                    <span className="project-header-control__text">Leave channel</span>
                  </>
                ),
                onClick: onLeaveChannel,
              },
            ]),

          ]
        }
      />
    )
  }
  </>
);

HeaderDropDown.propTypes = {
  onEditChannel: PropTypes.func.isRequired,
  onDeleteChannel: PropTypes.func.isRequired,
  onLeaveChannel: PropTypes.func.isRequired,
  isRenderChannelControl: PropTypes.bool.isRequired,
  isUserOwnsChannel: PropTypes.bool.isRequired,
};

export default HeaderDropDown;
