import { compose, lifecycle, withState } from 'recompose';
import { connect } from 'react-redux';
import {
  propOr, pathOr, values, prop,
} from 'ramda';
import { push } from 'connected-react-router';

import { helpers } from '../../../../utils';
import MessengerContainer from './messengerContainer';
import { LAST_CHANNEL_MESSENGER_STORAGE } from '../../../../constants/messenger';
import {
  messengerActions,
  messengerSelectors,
} from '../../../../state/messenger';
import { userSelectors } from '../../../../state/user';

const { webSocketHelpers } = helpers;

const channelIdFromUrl = pathOr(false, ['params', 'id']);

const mapStateToProps = (state, props) => {
  const channelId = channelIdFromUrl(props.match);
  const userId = prop('id', userSelectors.getUserData(state));
  return {
    loadedChannels: messengerSelectors.getLoadedChannels(state),
    groupChannels: messengerSelectors.getGroupChannels(state),
    activeChannel: messengerSelectors.getActiveChannelEntity(state),
    isUserOwnsChannel: messengerSelectors.getIsUserOwnsChannel(state)(
      channelId,
    )(userId),
  };
};

const mapDispatchToProps = {
  push,
  getLatestMessagesRequest: messengerActions.getLatestMessagesRequest,
  getChannelRequest: messengerActions.getChannelRequest,
  getDirectChannelsRequest: messengerActions.getDirectChannelsRequest,
  getPinnedMessages: messengerActions.getPinnedMessagesRequest,
  connectUserToChannel: messengerActions.connectUserToChannel,
  setActiveChannel: messengerActions.setActiveChannel,
  unsubscribeChannel: webSocketHelpers.actions.unsubscribeChannel,
};

const setLastChannelIdToStorage = (channelId) => {
  const event = new CustomEvent('storageChange');
  window.localStorage.setItem(LAST_CHANNEL_MESSENGER_STORAGE, channelId);
  window.dispatchEvent(event);
};

const getChannelId = ({ groupChannels, match }) => {
  const channelId = pathOr(
    propOr(null, 'id', values(groupChannels)[0]),
    ['params', 'id'],
    match,
  );
  return channelId ? parseInt(channelId, 10) : channelId;
};

const redirectToChannel = (channelId, actionPush) => channelId && actionPush(`/messenger/${channelId}`);

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('loading', 'setLoading', true),
  withState('isRenderMessenger', 'setIsRenderMessenger', false),
  withState('channelId', 'setChannelId', null),
  lifecycle({
    componentDidMount() {
      const {
        match,
        setChannelId,
        connectUserToChannel,
        getPinnedMessages,
        groupChannels,
        getChannelRequest,
      } = this.props;
      const urlChannelId = channelIdFromUrl(match);
      const channelId = urlChannelId || prop('id', values(groupChannels)[0]);
      const channelIdLast = window.localStorage.getItem(
        LAST_CHANNEL_MESSENGER_STORAGE,
      );
      if (!urlChannelId) {
        redirectToChannel(
          channelIdLast || prop('id', values(groupChannels)[0]),
          this.props.push,
        );
      } else {
        setLastChannelIdToStorage(channelId);
        getChannelRequest(channelId);
        getPinnedMessages({ channelId });
        setChannelId(channelId);
        connectUserToChannel({ channelId });
      }
    },
    componentDidUpdate(prevProps) {
      const prevParamsChannelId = getChannelId(prevProps);
      const paramsChannelId = getChannelId(this.props);
      const {
        setChannelId,
        connectUserToChannel,
        unsubscribeChannel,
        getChannelRequest,
        channelId,
        getPinnedMessages,
      } = this.props;
      if (!prevParamsChannelId || prevParamsChannelId !== paramsChannelId) {
        setLastChannelIdToStorage(paramsChannelId);
        setChannelId(parseInt(paramsChannelId, 0));
        getPinnedMessages({ channelId });
        getChannelRequest(paramsChannelId);
        connectUserToChannel({ channelId: paramsChannelId });
        const unsubscribeChannelId = prevParamsChannelId;
        if (unsubscribeChannelId !== paramsChannelId) {
          unsubscribeChannel({ topic: `channel:${unsubscribeChannelId}` });
        }
      }
    },
  }),
);

export default enhancer(MessengerContainer);
