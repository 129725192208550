import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { persistStore } from 'redux-persist';

import { composeWithDevTools } from 'redux-devtools-extension';

import {
  map, reduce, keys, propOr, values,
} from 'ramda';
import { feature, core } from './utils/middlewares';
import { backButtonMiddleware } from './utils/middlewares/feature/ui';
import { sagasManager } from './utils';
import requestApi from './utils/middlewares/sagaRequestApi';
import reducers from './state';
import { getApiUrl } from './utils/helpers/requestHelpers';
import modulesConfig from './modules/modules-info';

const listOfModulesNames = keys(modulesConfig.modules);

const storeFetureModulesGrouped = map(moduleName => propOr({},
// eslint-disable-next-line global-require,import/no-dynamic-require
  'default', require(`./modules/${moduleName}/src/feture/index.js`)), listOfModulesNames);


const storeFetureModulesList = reduce((accum, value) => ([...accum, ...values(value)]),
  [], storeFetureModulesGrouped);
const sagaMiddleware = createSagaMiddleware();
const history = createBrowserHistory();

const store = createStore(
  reducers(history),
  composeWithDevTools(
    applyMiddleware(
      backButtonMiddleware(history),
      routerMiddleware(history),
      sagaMiddleware,
      ...feature,
      ...core,
      ...storeFetureModulesList,
      requestApi({
        baseUrl: getApiUrl(window.location),
      }),
    ),
  ),
);

const persistor = persistStore(store);
sagaMiddleware.run(sagasManager.getRootSaga());

export {
  history,
  persistor,
};

export default store;
