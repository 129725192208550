import * as yup from 'yup';
import { withFormik } from 'formik';
import { isNotEmpty } from 'ramda-extension';
import { compose, withHandlers, withState } from 'recompose';
import { candidateFields } from '../../helpers';
import { withFilesUploading, withRefs } from '../../../../../utils/enchancers';
import hrCreateForm from './hrCreateForm';

const validator = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  position: yup.number().default(0).required('required'),
  email: yup.string().email().nullable(null),
  status: yup.number().default(3),
  file_link: yup.string().url(),
  phone: yup.string().matches(/^\+?3?8?(0\d{9})$/, 'Phone number is not valid'),
});

const scrollToTopForm = (values, { getRef, changeComments, setChangeComments }) => {
  // TODO: Bad solution for scroll after submit
  const ref = getRef('scrollWrapper');
  if (!changeComments) {
    if (ref) ref.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }
  setChangeComments(false);
};

const initialValues = ({ candidate, commentsEntities, commentsList }) => {
  let newValues = {};
  // Insert values if update candidate
  if (isNotEmpty(candidate)) {
    newValues = candidateFields(candidate);
  }
  // Insert comments values if update candidate
  if (isNotEmpty(commentsEntities) && commentsList && commentsList.length > 0) {
    newValues = {
      ...newValues,
      hrComments: commentsList.map(commentId => commentsEntities[commentId]),
    };
  }
  return newValues;
};

const formSubmit = (formValues, { ...props }) => {
  const { onSubmitForm, appendedFilesList } = props.props;
  // Insert new candidate files
  onSubmitForm({ ...formValues, files: appendedFilesList });
};

const setScrollRefHandler = ({ setRef }) => (e) => {
  setRef('scrollWrapper', e);
};

const onSubmitFilesHandler = ({ setAppendedFilesList }) => list => setAppendedFilesList(list);

const enhance = compose(
  withRefs(),
  withState('appendedFilesList', 'setAppendedFilesList', []),
  withState('changeComments', 'setChangeComments', false),
  withHandlers({ setScrollRef: setScrollRefHandler }),
  withFormik({
    mapPropsToValues: initialValues,
    validationSchema: validator,
    validate: scrollToTopForm,
    enableReinitialize: false,
    validateOnChange: false,
    validateOnBlur: false,
    handleSubmit: formSubmit,
  }),
  withFilesUploading({
    onSubmitFiles: onSubmitFilesHandler,
  }),
);
export default enhance(hrCreateForm);
