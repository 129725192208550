import {
  compose, withContext, defaultProps, branch, renderNothing, lifecycle, withState, withProps,
} from 'recompose';
import {
  identity, T, equals, cond, prop,
} from 'ramda';
import PropTypes from 'prop-types';
import { memo } from 'react';

import MessageElements from './messageElements';
import { parseStringWrappers, parseTextElements, parseToPlainText } from './utils';
import { setGlobalVariableForMention } from '../../utils/helpers/mentionHelpers/lookup';

const enhance = compose(
  branch(({ children }) => !children, renderNothing),
  withState('content', 'setContent', ({ children }) => children),
  memo,
  withProps(({
    children, options = {},
  }) => ({
    content: cond([
      [prop('isEditing'), () => parseTextElements(children, options)],
      [prop('parseToPlainText'), () => parseToPlainText(children, options)],
      [T, () => parseStringWrappers(children, options)],
    ])(options),
  })),
  defaultProps({
    members: [],
    ownerId: 0,
    onHandler: identity,
  }),
  withContext({
    members: PropTypes.instanceOf(Object),
    ownerId: PropTypes.number,
    onHandler: PropTypes.func,
    options: PropTypes.instanceOf(Object),
  }, props => ({
    members: setGlobalVariableForMention(props.members),
    ownerId: props.ownerId,
    onHandler: props.onHandler,
    options: props.options,
  })),
  lifecycle({
    shouldComponentUpdate({ children, options }) {
      return children !== this.props.children
        || !equals(options, this.props.options);
    },
  }),
  memo,
);

export default enhance(MessageElements);
