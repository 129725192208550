import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from '@/state/ui';
import { getModalWrapperForHooks } from '@/state/ui/selectors';
import { successActionCallback } from '@/utils/helpers/actionCallbacks';
import { bpSelectors, bpActions } from '@bp/store/bpModule';

export const useDeleteProcessModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getModalWrapperForHooks('deleteProcessModal'));
  const selectedProcess = useSelector(bpSelectors.getBusinessProcessSelected);

  const onClose = useCallback(() => {
    dispatch(uiActions.closeModal('deleteProcessModal'));
  }, [dispatch]);

  const onDelete = useCallback(() => {
    dispatch(
      bpActions.deleteBusinessProcessRequest(
        {
          processId: selectedProcess.id,
        },
        successActionCallback(() => {
          onClose();
        }),
      ),
    );
  }, [selectedProcess, dispatch]);

  return {
    isOpen,
    selectedProcess,
    onDelete,
    onClose,
  };
};
