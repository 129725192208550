import { useContext } from 'react';
import { ProjectNotesContext } from './context';

export const useProjectNotesContext = () => {
  const context = useContext(ProjectNotesContext);
  if (!context) {
    throw new Error('useProjectNotesContext must be used within a ProjectNotesProvider.');
  }
  return context;
};
