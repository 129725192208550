import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import './style.sass';

const DEFAULT_BUTTON_NAME = 'Submit';

/**
 *
 * @param children {element}
 * @param className {string}
 * @param disabled {boolean}
 * @param onClick {function}
 * @param type {string}
 * @param asLink {boolean}
 * @param href {string}
 * @param name {string}
 * @returns {JSX.Element}
 * @constructor
 */


export const Button = memo(({
  children = DEFAULT_BUTTON_NAME,
  className = '',
  disabled = false,
  onClick = () => { },
  type = 'button',
  asLink = false,
  href = '',
  name = '',
}) => (asLink ? (
  <Link disabled={disabled} onClick={onClick} type={type} to={href} className={`button-default ${className}`}>
    {children}
  </Link>
) : (
  // eslint-disable-next-line react/button-has-type
  <button name={name} disabled={disabled} onClick={onClick} type={type} className={`button-default ${className}`}>
    {children}
  </button>
)));
