import {
  compose, getContext, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { closeModal } from '../../../../../../../state/ui/actions';
import { getModal } from '../../../../../../../state/ui/selectors';
import { clientsActions, clientsSelectors } from '../../../../../../../state/clients';
import DeleteNote from '../../../../../../projectsNotes/components/notesCRUD/deleteNote/deleteNote';

const mapStateToProps = (state, { selectedNoteId }) => ({
  isOpen: getModal(state)('deleteNoteModal'),
  note: clientsSelectors.getClientNoteSelector(state)(selectedNoteId),
  isPending: clientsSelectors.getClientActionPending(state)('deleteClientNoteRequest'),
});

const mapDispatchToProps = ({
  onCloseModal: () => closeModal('deleteNoteModal'),
  deleteClientNote: clientsActions.deleteClientNoteRequest,
});

const onDeleteHandler = ({
  deleteClientNote, onCloseModal, selectedNoteId: noteId, clientId: id, sortBy, order,
}) => () => {
  deleteClientNote({ id, noteId }, {
    callbacks: {
      success: onCloseModal,
    },
    sortBy,
    order,
  });
};

const enhance = compose(
  getContext({
    selectedNoteId: PropTypes.number.isRequired,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common']),
  withHandlers({
    onDelete: onDeleteHandler,
  }),
);

export default enhance(DeleteNote);
