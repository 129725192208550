import {
  compose, lifecycle, withHandlers, withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import NotesList from './notesList';
import { clientsActions, clientsSelectors } from '../../../../../../state/clients';
import { OFFSET } from '../../../../../../constants/crm';


const mapStateToProps = (state, { clientId }) => ({
  notes: clientsSelectors.getClientNotesSelector(state)(clientId),
  clientNotesCount: clientsSelectors.getClientNotesCountSelector(state),
});

const mapDispatchToProps = ({
  getClientNotesRequest: clientsActions.getClientNotesRequest,
});

const getMoreNotes = ({
  getClientNotesRequest, setOffset, offset, clientId, clientNotesCount, notes,
}) => () => {
  if (notes.length < clientNotesCount) {
    setOffset(offset + OFFSET);
    getClientNotesRequest({ id: clientId, offset });
  }
};
const changeActiveTab = ({ activeTab }) => (idTab) => {
  if (idTab === activeTab) {
    return {
      activeTab: 0,
    };
  }
  return {
    activeTab: idTab,
  };
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withHandlers({
    getMoreNotesHandler: getMoreNotes,
  }),
  withStateHandlers(
    () => ({ activeTab: 0 }), {
      changeActiveTabStateHandler: changeActiveTab,
    },
  ),
  lifecycle({
    componentDidMount() {
      const { clientId: id, getClientNotesRequest } = this.props;
      getClientNotesRequest({ id });
    },
    componentDidUpdate(prevProps) {
      const {
        clientId: id,
        getClientNotesRequest, sortBy, order,
      } = this.props;
      if ((this.props.sortBy !== prevProps.sortBy)
        || (this.props.order !== prevProps.order)) {
        getClientNotesRequest({ id, sortBy, order });
      }
    },
  }),
);
export default enhance(NotesList);
