import { createActions } from 'redux-actions';
import { identity, compose, mergeLeft } from 'ramda';

import { callNotification } from '../../utils/helpers/notifies';
import types from './types';
import invoicesSchemas from './schema';
import { setErrorPage } from '../ui/actions';
import { getNormalizeErrorObject } from '../../utils/helpers/requestHelpers';

const errorPageDataWhenGetInvoices = compose(mergeLeft({ href: '/' }),
  getNormalizeErrorObject);

const {
  getInvoicesListRequest,
  updateInvoiceRequest,
  setInvoices,
  changeView,
  onDragInvoiceEnd,
  reorderColumnView,
  deleteInvoiceRequest,
  addInvoiceItem,
  deleteInvoiceItem,
  changeInvoiceItemFieldValue,
  setTax,
  setDiscount,
  addInvoiceRequest,
  setTaxPaymentType,
  setDiscountPaymentType,
  setCurrencySign,
  getCurrentInvoice,
  setCurrentInvoice,
  setInvoiceItems,
  clearStore,
  openSendModal,
} = createActions(
  {
    [types.GET_INVOICES_LIST_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/invoices',
        selectorName: 'getInvoicesListRequest',
        method: 'GET',
        params,
        actions: {
          success: setInvoices,
          error: compose(setErrorPage, errorPageDataWhenGetInvoices),
        },
        schema: {
          rules: invoicesSchemas.invoicesListSchema,
          pathData: ['paginatedData', 'data'],
        },
        ...meta,
      }),
    ],
    [types.UPDATE_INVOICE_REQUEST]: [
      identity,
      ({ invoiceId, ...params }, meta) => ({
        async: true,
        route: `/invoices/${invoiceId}`,
        selectorName: 'updateInvoiceRequest',
        method: 'PUT',
        params,
        actions: {
          success: () => getInvoicesListRequest(meta && meta.queryParams ? meta.queryParams : {}),
          error: () => callNotification.error('Invoice cannot be edited'),
        },
        ...meta,
      }),
    ],
    [types.DELETE_INVOICE_REQUEST]: [
      identity,
      ({ invoiceId, ...params }, meta) => ({
        async: true,
        route: `/invoices/${invoiceId}`,
        selectorName: 'deleteInvoiceRequest',
        method: 'DELETE',
        params,
        actions: {
          success: () => getInvoicesListRequest(meta && meta.queryParams ? meta.queryParams : {}),
          error: () => callNotification.error('Invoice cannot be deleted'),
        },
        ...meta,
      }),
    ],
    [types.ADD_INVOICE_REQUEST]: [
      identity,
      ({ ...params }, meta) => ({
        async: true,
        route: '/invoices',
        selectorName: 'addInvoiceRequest',
        method: 'POST',
        params,
        actions: {
          success: () => getInvoicesListRequest(meta && meta.queryParams ? meta.queryParams : {}),
          error: () => callNotification.error('Invoice cannot be added'),
        },
        ...meta,
      }),
    ],
    [types.GET_CURRENT_INVOICE]: [
      identity,
      ({ invoiceId, ...params }, meta) => ({
        async: true,
        route: `/invoices/${invoiceId}`,
        selectorName: 'getCurrentInvoice',
        method: 'GET',
        params,
        actions: {
          success: setCurrentInvoice,
        },
        callbacks: {
          success: setInvoiceItems,
        },
        ...meta,
      }),
    ],
  },
  types.ON_DRAG_INVOICE_END,
  types.SET_INVOICES,
  types.SET_CURRENT_INVOICE,
  types.CHANGE_VIEW,
  types.REORDER_COLUMN_VIEW,
  types.ADD_INVOICE_ITEM,
  types.DELETE_INVOICE_ITEM,
  types.CHANGE_INVOICE_ITEM_FIELD_VALUE,
  types.SET_TAX,
  types.SET_DISCOUNT,
  types.SET_TAX_PAYMENT_TYPE,
  types.SET_DISCOUNT_PAYMENT_TYPE,
  types.SET_CURRENCY_SIGN,
  types.SET_INVOICE_ITEMS,
  types.CLEAR_STORE,
  types.OPEN_SEND_MODAL,
);

export default {
  getInvoicesListRequest,
  updateInvoiceRequest,
  changeView,
  onDragInvoiceEnd,
  reorderColumnView,
  deleteInvoiceRequest,
  addInvoiceItem,
  deleteInvoiceItem,
  changeInvoiceItemFieldValue,
  setTax,
  setDiscount,
  addInvoiceRequest,
  setTaxPaymentType,
  setDiscountPaymentType,
  setCurrencySign,
  getCurrentInvoice,
  clearStore,
  openSendModal,
};
