import {
  compose, lifecycle, withContext, withHandlers, withProps, withState,
} from 'recompose';
import PropTypes from 'prop-types';
import { map } from 'ramda';
import { connect } from 'react-redux';

import InvoicesContainer from './invoicesContainer';
import {
  INVOICES_STATUSES, INVOICES_LIMIT, INVOICES_VIEW, INVOICE_STATUS_IDS,
} from '../../constants/crm';
import { withFilters, withUrlParams } from '../../utils/enchancers';
import { projectsActions } from '../../state/projects';
import { invoicesActions, invoicesSelectors } from '../../state/invoices';
import { uiActions, uiSelectors } from '../../state/ui';
import { pendingSelectors } from '../../utils/middlewares/sagaRequestApi/state';

const mapDispatchToProps = {
  getProjectsRequest: projectsActions.getProjectsListRequest,
  getInvoicesListRequest: invoicesActions.getInvoicesListRequest,
  setInvoicesView: invoicesActions.changeView,
  deleteInvoiceRequest: invoicesActions.deleteInvoiceRequest,
  updateInvoiceRequest: invoicesActions.updateInvoiceRequest,
  closeModal: modalName => uiActions.closeModal(modalName),
};

const mapStateToProps = (state) => {
  const invoiceToSendId = invoicesSelectors.getInvoiceToSend(state);
  return {
    invoicesList: invoicesSelectors.getInvoicesList(state),
    invoicesCount: invoicesSelectors.getInvoicesCount(state),
    invoicesView: invoicesSelectors.getListView(state),
    invoiceToSendId,
    invoiceToSend: invoicesSelectors.getInvoiceById(state)(invoiceToSendId),
    openModals: {
      deleteInvoiceModal: uiSelectors.getModal(state)('deleteInvoiceModal'),
      sendInvoiceModal: uiSelectors.getModal(state)('sendInvoiceModal'),
    },
    isSending: pendingSelectors.getPendingRequest(state, 'updateInvoiceRequest'),
  };
};

const onDeleteHandler = ({
  closeModal, selectedInvoiceId, deleteInvoiceRequest, queryParams, invoicesView,
}) => () => {
  deleteInvoiceRequest(
    { invoiceId: selectedInvoiceId },
    {
      queryParams: {
        ...queryParams, ...(invoicesView === INVOICES_VIEW.PIPE ? { page: '', is_lifecycle: 1 } : {}),
      },
    },
  );
  closeModal('deleteInvoiceModal');
};

const onSendHandler = ({
  closeModal, invoiceToSendId, updateInvoiceRequest, queryParams,
}) => () => {
  updateInvoiceRequest({
    invoiceId: invoiceToSendId,
    status_id: INVOICE_STATUS_IDS.SENT,
    is_email: 1,
  }, { queryParams: { ...queryParams, page: '', is_lifecycle: 1 } });
  closeModal('sendInvoiceModal');
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('selectedInvoiceId', 'setSelectedInvoiceId'),
  withUrlParams({}),
  withFilters({
    initial: ({ getUrlParam }) => ({
      status_id: getUrlParam(['status_id']),
      order: getUrlParam(['order'], 'desc'),
      search: getUrlParam(['search']),
      startDate: getUrlParam(['startDate']),
      endDate: getUrlParam(['endDate']),
      page: getUrlParam(['page'], 1),
    }),
  }),
  withProps(({ getFilter }) => ({
    queryParams: {
      status_id: getFilter('', 'status_id'),
      order: getFilter('desc', 'order'),
      search: getFilter('', 'search'),
      startDate: getFilter('', 'startDate'),
      endDate: getFilter('', 'endDate'),
      page: getFilter(1, 'page'),
      limit: INVOICES_LIMIT,
    },
  })),
  withContext({
    invoiceStatusesOptions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    queryParams: PropTypes.instanceOf(Object),
    getUrlParam: PropTypes.func,
    getInvoicesListRequest: PropTypes.func,
    setSelectedInvoiceId: PropTypes.func,
  }, ({
    queryParams, getUrlParam, getInvoicesListRequest, setSelectedInvoiceId,
  }) => ({
    invoiceStatusesOptions: compose(
      map(arr => ({ id: arr[0], label: arr[1], value: arr[0] })),
      Object.entries,
    )(INVOICES_STATUSES),
    getUrlParam,
    queryParams,
    getInvoicesListRequest,
    setSelectedInvoiceId,
  })),
  withHandlers({
    onDelete: onDeleteHandler,
    onSend: onSendHandler,
  }),
  lifecycle({
    componentDidMount() {
      const { getProjectsRequest } = this.props;
      getProjectsRequest();
    },
  }),
);

export default enhance(InvoicesContainer);
