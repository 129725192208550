/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { Link } from 'react-router-dom';

import { ButtonsCollection, Avatar, BrillMask } from '../../ui-kit';
import testAvatar from '../../assets/testAssets/avatar.jpeg';
import ButtonsControlGroup from './components';

import './style.sass';

const dataPseudo = {
  images: [
    {
      id: 'some-img-1',
      src: testAvatar,
      alt: 'Test Avatar',
    },
    {
      id: 'some-img-2',
      src: 'http://wowslider.com/sliders/demo-81/data1/images/redkite50498.jpg',
      alt: 'Eagle',
    },
    {
      id: 'some-img-3',
      src: 'https://cdn.motor1.com/images/mgl/2yyBB/s1/zero-to-60-mustang-gtt-first-drive.jpg',
      alt: 'Mustang',
    },
  ],
  messageInfo: {
    userName: 'Isaac Hunt',
    date: 'Dec 4, 2018',
    time: '2:17:32 PM',
  },
};

const AttachedFilesSlider = ({
  isOpen,
  toggleSliderStateHandler,
  currentSlide,
  data,
  changeSliderHandler,
}) => (
  <>
    {isOpen ? (
      <Portal>
        <div className="attached-files-slider">
          <div className="attached-files-slider__header">
            <div className="attached-files-slider__row">
              <div className="attached-files-slider__col-user">
                <BrillMask className="user-avatar" asLink to="/">
                  <Avatar src={testAvatar} />
                </BrillMask>
                <Link to="/" className="user-name">
                  {data.messageInfo.userName}
                </Link>
              </div>
              <div className="attached-files-slider__col-date">
                <span className="date">
                  {data.messageInfo.date}
                </span>
                <span className="time">
                  {data.messageInfo.time}
                </span>
              </div>
            </div>
            <ButtonsCollection.ButtonIcons
              title="Close modal"
              type="button"
              className="slider-close-button"
              onClick={() => toggleSliderStateHandler(false)}
            >
              <span className="icon-close" />
            </ButtonsCollection.ButtonIcons>
          </div>
          <div className="attached-files-slider__body">
            <div className="slider-wrapper">
              <ButtonsCollection.ButtonIcons
                title="Previous slider"
                type="button"
                className="slider-button prev"
                onClick={() => changeSliderHandler(currentSlide - 1, data)}
              >
                <span className="icon-pagination-arrow" />
              </ButtonsCollection.ButtonIcons>
              <div className="images-wrapper">
                {
                  data.images.map((item, index) => (
                    <div key={item.id} className={`image ${(index === currentSlide) ? ('active') : ''}`}>
                      <img src={item.src} alt={item.src} />
                    </div>
                  ))
                }
              </div>
              <ButtonsCollection.ButtonIcons
                title="Next slider"
                type="button"
                className="slider-button next"
                onClick={() => changeSliderHandler(currentSlide + 1, data)}
              >
                <span className="icon-pagination-arrow" />
              </ButtonsCollection.ButtonIcons>
            </div>
          </div>
          <div className="attached-files-slider__footer">
            <ButtonsControlGroup />
          </div>
        </div>
      </Portal>
    ) : null}
  </>
);
AttachedFilesSlider.propTypes = {
  isOpen: PropTypes.bool,
  currentSlide: PropTypes.number.isRequired,
  toggleSliderStateHandler: PropTypes.func.isRequired,
  changeSliderHandler: PropTypes.func.isRequired,
  data: PropTypes.shape({}),
};
AttachedFilesSlider.defaultProps = {
  isOpen: true,
  data: dataPseudo,
};
export default AttachedFilesSlider;
