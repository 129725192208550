import { compose, withProps, withStateHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import RightSideBarContainer from './rightSideBarContainer';
import { TYPE } from '../../../../constants/projects';
import { projectSelectors } from '../../../../state/project';

const mapStateToProps = (state, { projectId }) => ({
  project: projectSelectors.default(state)(projectId),
});
const setActiveTabTitle = () => activeTabTitle => ({ activeTabTitle });

const enhancer = compose(
  withTranslation(['common', 'tasks', 'chat']),
  connect(mapStateToProps),
  withProps(({ project: { type } }) => ({
    isNotPublic: type !== TYPE.PUBLIC,
  })),
  withStateHandlers(
    () => ({ activeTabTitle: '' }), {
      setActiveTabTitleStateHandler: setActiveTabTitle,
    },
  ),
);

export default enhancer(RightSideBarContainer);
