import * as yup from 'yup';
import { helpers } from '../../utils';
import { isValidLogTime } from '../../utils/helpers/dateHelpers';

const { translateHelpers } = helpers;

const rules = yup.object().shape({
  title: yup
    .string()
    .min(2, {
      message: 'Title must be at least 2 characters long',
      params: {
        key: translateHelpers.t('common', 'Title must be at least 2 characters long'),
      },
    })
    .max(254, {
      message: 'Title must be at most 254 characters',
      params: {
        key: translateHelpers.t('common', 'Title must be at most 254 characters'),
      },
    })
    .required({
      field: 'title',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Title1'),
      },
    }),
  description: yup
    .string()
    .nullable(true),
  estimated_time: yup
    .string()
    .test('estimated_time', 'logTimeIsNotValid',
      isValidLogTime),
});

export default rules;
