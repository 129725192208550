import { useFormik } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { uiActions } from '@/state/ui';
import { getModalWrapperForHooks } from '@/state/ui/selectors';
import { bpActions, bpSelectors } from '@bp/store/bpModule';

export const useRenameProcessModal = () => {
  const {
    params: { processId },
  } = useRouteMatch();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOpen = useSelector(getModalWrapperForHooks('renameProcess'));
  const selectedProcess = useSelector(bpSelectors.getBusinessProcessSelected);

  const onClose = useCallback(() => {
    dispatch(uiActions.closeModal('renameProcess'));
  }, [dispatch, uiActions]);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } = useFormik({
    initialValues: {
      title: selectedProcess?.title,
    },
    enableReinitialize: true,
    onSubmit: (formValues) => {
      dispatch(bpActions.editBusinessProcessRequest({ ...formValues, processId }));
      onClose();
    },
  });

  return {
    t,
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    handleSubmit,
    isOpen,
    onClose,
  };
};
