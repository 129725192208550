import React from 'react';

import { AVAILABLE_FILE_FORMATS, AVAILABLE_FILE_SIZES } from '../../../../../../../constants/files';
import { FormsCollection } from '../../../../../../../ui-kit';

/**
 * @param changeCompanyLogoRequest {function}
 * @param companyBillingInfo {object}
 * @returns {JSX.Element}
 * @constructor
 */

export const CompanyLogo = ({ companyBillingInfo, changeCompanyLogoRequest }) => {
  const updateLogo = ({ target: { value } }) => {
    changeCompanyLogoRequest({ logo: value || null });
  };

  return (
    <div className="billing-info__company-logo">
      <div className="billing-info__company-logo--image-wrapper">
        <FormsCollection.AttachImage
          onChange={(e) => {
            updateLogo(e);
          }}
          value={companyBillingInfo.logo?.id}
          name="image"
          formats={AVAILABLE_FILE_FORMATS.LOGO_COMPANY}
          maxSize={AVAILABLE_FILE_SIZES.LOGO_COMPANY}
          id="attached-logo__avatar"
          labelText="Add logo"
          className="billing-info__company-logo--image"
        />
      </div>
    </div>
  );
};
