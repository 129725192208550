import {
  assoc, compose as RCompose, compose, curry, prop, propOr, filter,
  omit, pathOr, cond, equals, T, inc, head, values,
} from 'ramda';
import moment from 'moment-timezone';

import { isNotNil, notEqual } from 'ramda-extension';
import { getValueBy, isConditionRight, setNewPropertyData } from '../commonHelpers';
import { renameKeysTitleIntoValue } from '../uiHelpers';
import { getSecondsFromTimeString } from '../dateHelpers';
import { userHelpers } from '..';
import { reorderTimeEntries, deleteTimeEntryEntity } from '../../../state/task/actions';
import SERVER_TIMEZONE from '../../../constants/config';

const getValue = getValueBy('value');
const getIsTaskHasSprint = propOr(false, 'isHasSprint');
const getTaskId = prop('id');
const getCategoryTitle = propOr('', 'title');
const formatDate = curry(date => moment.utc(moment(date).tz(SERVER_TIMEZONE).format('YYYY-MM-DD HH:mm')).tz('Europe/Kiev').format('DD/MM/YY'));

const makePayloadTaskWithSprint = curry((sprint, result, entities) => ({
  sprints: ({
    [prop('id')(sprint)]: ({
      ...sprint,
      tasks: result,
    }),
  }),
  tasks: entities,
}));

const makePayloadDeleteBacklogTask = curry(result => ({
  data: ({
    result,
  }),
}));

// eslint-disable-next-line camelcase
const makePayloadDeleteAgendaTask = status_id => tasks => ({
  status_id,
  tasks,
});


const eitherTaskHasSprint = isConditionRight(compose(isNotNil, prop('sprint_id')));

const setTaskData = compose(
  setNewPropertyData('status', inc),
  setNewPropertyData('estimated_time', getSecondsFromTimeString),
  setNewPropertyData('category_id', getValue),
  setNewPropertyData('project_id', getValue),
  setNewPropertyData('sprint_id', getValue),
  setNewPropertyData('watchers', getValue),
  setNewPropertyData('assignee', getValue),
  setNewPropertyData('status_id', getValue),
);

const setTaskLeadData = compose(
  setNewPropertyData('estimated_time', getSecondsFromTimeString),
  setNewPropertyData('assignee', getValue),
);

const setCategoryLabel = curry((categories, task) => compose(
  renameKeysTitleIntoValue,
  category => prop(category, categories),
  propOr('', 'category_id'),
)(task));

const setStatusLabel = curry((statuses, task) => compose(
  renameKeysTitleIntoValue,
  status => prop(status, statuses),
  propOr(compose(prop('id'), head, values)(statuses), 'status_id'),
)(task));

const setProjectLabel = curry((projects, task, projectId) => compose(
  renameKeysTitleIntoValue,
  project => prop(project, projects),
  propOr(projectId, 'project_id'),
)(task));


const setAssigneeLabel = RCompose(
  renameKeysTitleIntoValue,
  taskData => assoc('label', userHelpers.getFullName(taskData), taskData),
);

const setSprintLabel = curry((sprints, task) => RCompose(
  renameKeysTitleIntoValue,
  sprint => prop(sprint, sprints),
  propOr(propOr(0, 'id', head(values(sprints))), 'sprint_id'),
)(task));


const filterTasksById = curry((tasks, id) => filter(notEqual(id), tasks));

const deleteTask = curry((action, makePayload, tasks, tasksEntities, task) => {
  const taskId = getTaskId(task);
  const tasksWithoutDeletedItem = filterTasksById(tasks, taskId);
  const taskEntitiesWithoutDeletedItem = omit([taskId], tasksEntities);
  const payload = makePayload(tasksWithoutDeletedItem, taskEntitiesWithoutDeletedItem);
  return action(null, payload);
});

const deleteTimeEntry = (state, timeEntryId, next) => {
  const entities = pathOr({}, ['task', 'entities', 'time_entries'], state);
  const result = pathOr({}, ['task', 'result', 'time_entries'], state);
  next(reorderTimeEntries(filter(id => timeEntryId !== id, result)));
  next(deleteTimeEntryEntity(omit([timeEntryId], entities)));
};

const deleteTaskWithSprint = curry((
  deleteFromAgenda,
  deleteFromSprint,
  isAgenda,
  task,
) => cond([
  [equals(true), () => deleteFromAgenda(task)],
  [T, () => deleteFromSprint(task)],
])(isAgenda));

const getTaskParam = curry((paramName, paramData) => (
  parseInt(paramData, 10) ? { [paramName]: paramData } : {}
));

const getProjectIdParam = getTaskParam('project_id');
const getClientIdParam = getTaskParam('client_id');
const getLeadIdParam = getTaskParam('client_id');

const getTaskActionParams = ({
  projectId, clientId, leadId, ...params
} = {}) => ({
  ...params,
  ...getProjectIdParam(projectId),
  ...getClientIdParam(clientId),
  ...getLeadIdParam(leadId),
});

export {
  makePayloadTaskWithSprint,
  makePayloadDeleteAgendaTask,
  makePayloadDeleteBacklogTask,
  setTaskData,
  deleteTaskWithSprint,
  eitherTaskHasSprint,
  formatDate,
  getTaskId,
  setAssigneeLabel,
  filterTasksById,
  setCategoryLabel,
  getCategoryTitle,
  setSprintLabel,
  setProjectLabel,
  getIsTaskHasSprint,
  deleteTask,
  deleteTimeEntry,
  setStatusLabel,
  getProjectIdParam,
  getClientIdParam,
  getTaskParam,
  getLeadIdParam,
  getTaskActionParams,
  setTaskLeadData,
};
