import React from 'react';
import PropTypes from 'prop-types';
import {
  propOr, path,
} from 'ramda';

import {
  ButtonsCollection, ModalWindow, AsyncAutoComplete, FormsCollection,
} from '../../../../ui-kit';
import './style.sass';

const ModalView = ({
  isOpen,
  isPending,
  onCloseModal,
  handleSubmit,
  handleChange,
  setFieldValue,
  handleBlur,
  touched,
  values,
  errors,
  action,
  name,
  modalTitle,
  t,
  getUsersAutocomplete,
  membersListOfSelectedProject,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={() => onCloseModal(name)}
    className="create-lead-task__modal"
    title={modalTitle}
  >
    <FormsCollection.FormWrapper
      handleSubmit={handleSubmit}
      className="create-task"
    >
      <div className="create-task__row">
        <FormsCollection.Input
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.title}
          error={propOr('', 'message', errors.title)}
          touched={touched.title}
          name="title"
          placeholderJump={t('Task name')}
          className="create-task__title-field"
          id="create-task__title"
        />
      </div>
      <div className="create-task__row">
        <div
          className="create-task__col create-task__col-select create-task__col--assignee"
        >
          <div className="create-task__col-label">
            {t('Assignee')}
          </div>
          <div className="create-task__col-fields">
            <AsyncAutoComplete
              key={`assignee-field-${membersListOfSelectedProject}`}
              name="assignee"
              placeholder={t('Choose assignee')}
              error={propOr('', 'message', errors.assignee)}
              closeMenuOnSelect
              touched={touched.assignee}
              value={values.assignee}
              defaultValue={values.assignee}
              loadOptions={getUsersAutocomplete}
              onChange={value => setFieldValue('assignee', value)}
            />
          </div>
        </div>
      </div>
      <div className="create-task__row">
        <div className="create-task__col create-task__col-select create-task__col--assignee">
          <div className="create-task__col-label">
            {t('Deadline')}
          </div>
          <div className="create-task__col-fields">
            <FormsCollection.DatePicker
              name="deadline"
              onChange={handleChange}
              value={values.deadline}
              error={
                values.deadline
                && values.deadline < new Date()
                  ? 'You have entered incorrect date' : errors.deadline
              }
              minDate={new Date()}
              touched={touched.deadline}
            />
          </div>
        </div>
      </div>
      <div className="button-group">
        <ButtonsCollection.ButtonBrill
          className="button--md create-task__button-save button-brill--fill"
          type="submit"
          pending={isPending && path(['submitButton'], values) === 'create'}
          onClick={() => setFieldValue('submitButton', 'create')}
        >
          {action}
        </ButtonsCollection.ButtonBrill>
        {
          (name === 'createTaskModal' || name === 'createClientTaskModal') && (
            <ButtonsCollection.ButtonBrill
              pending={isPending && path(['submitButton'], values) !== 'create'}
              className="button--md create-task__button-save-create"
              type="submit"
              onClick={() => setFieldValue('submitButton', 'saveAndCreate')}
            >
              {t('Save and create another')}
            </ButtonsCollection.ButtonBrill>
          )
        }
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);

ModalView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  getUsersAutocomplete: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  t: PropTypes.func.isRequired,
  membersListOfSelectedProject: PropTypes.instanceOf(Array).isRequired,
};

export default ModalView;
