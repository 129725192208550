import React, { useCallback,  useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ACTIONS, MODULES } from '@/constants/pemissions';
import { WINDOW_WIDTH } from '@/constants/ui';
import { ButtonsCollection, EmptyResult, Header, PermissionElement, ScrollWrapper } from '@/ui-kit';
import { useWindowWidth } from '@/utils/hooks';
import { CategoryItem } from '@/views/knowledgeBase/components/knowledgeBase/components/categoryItem';
import { useKnowledgeBaseContext } from '@/views/knowledgeBase/context/useKnowledgeBaseContext';
import {
  CreateCategoryModal,
  CreateSubcategoryModal,
  DeleteCategoryModal,
  DeleteSubcategoryModal,
  EditCategoryModal, EditSubcategoryModal,
} from './modals';
import './style.sass';

export const KnowledgeBase = () => {
  const { t } = useTranslation('common');

  const windowWidth = useWindowWidth();

  const { categoriesData } = useKnowledgeBaseContext();

  const [isOpenedCreateCategoryModal, setIsOpenedCreateCategoryModal] = useState(false);
  const [isOpenedEditCategoryModal, setIsOpenedEditCategoryModal] = useState(false);
  const [isOpenedDeleteCategoryModal, setIsOpenedDeleteCategoryModal] = useState(false);
  const [isOpenedCreateSubcategoryModal, setIsOpenedCreateSubcategoryModal] = useState(false);
  const [isOpenedEditSubcategoryModal, setIsOpenedEditSubcategoryModal] = useState(false);
  const [isOpenedDeleteSubcategoryModal, setIsOpenedDeleteSubcategoryModal] = useState(false);

  const onOpenCreateCategoryModal = useCallback(() => {
    setIsOpenedCreateCategoryModal(prevState => !prevState);
  }, [setIsOpenedCreateCategoryModal]);

  const onOpenEditCategoryModal = useCallback(() => {
    setIsOpenedEditCategoryModal(prevState => !prevState);
  }, [setIsOpenedEditCategoryModal]);

  const onOpenDeleteCategoryModal = useCallback(() => {
    setIsOpenedDeleteCategoryModal(prevState => !prevState);
  }, [setIsOpenedDeleteCategoryModal]);

  const onOpenCreateSubcategoryModal = useCallback(() => {
    setIsOpenedCreateSubcategoryModal(prevState => !prevState);
  }, [setIsOpenedCreateSubcategoryModal]);

  const onOpenEditSubcategoryModal = useCallback(() => {
    setIsOpenedEditSubcategoryModal(prevState => !prevState);
  }, [setIsOpenedEditSubcategoryModal]);

  const onOpenDeleteSubcategoryModal = useCallback(() => {
    setIsOpenedDeleteSubcategoryModal(prevState => !prevState);
  }, [setIsOpenedDeleteSubcategoryModal]);

  return (
    <>
      <Header hideToggleRightSideBar>
        <h1 className="weight--medium title--xl title-page">
          {t('Knowledge base')}
        </h1>
        <PermissionElement
          module={MODULES.KNOWLEDGE_BASE}
          action={ACTIONS.CREATE}
        >
          {windowWidth > WINDOW_WIDTH.MEDIUM ? (
            <ButtonsCollection.ButtonBrill
              onClick={onOpenCreateCategoryModal}
              type='button'
              className='knowledge-base-header__button-invite'
            >
              {t('Create category')}
            </ButtonsCollection.ButtonBrill>
          ) : (
            <ButtonsCollection.ButtonBrill
              onClick={onOpenCreateCategoryModal}
              type="button"
              renderMobileButton
              className="knowledge-base-header__button-invite"
            >
                {t('Create category')}
            </ButtonsCollection.ButtonBrill>
            )}
        </PermissionElement>
      </Header>
      <div className="knowledge-base-container">
        <ScrollWrapper>
          <div className="knowledge-base-container__content">
          {categoriesData?.length ? categoriesData.map((category) => (
            <CategoryItem
              key={category.id}
              category={category}
              onOpenEditCategoryModal={onOpenEditCategoryModal}
              onOpenDeleteCategoryModal={onOpenDeleteCategoryModal}
              onOpenCreateSubcategoryModal={onOpenCreateSubcategoryModal}
              onOpenEditSubcategoryModal={onOpenEditSubcategoryModal}
              onOpenDeleteSubcategoryModal={onOpenDeleteSubcategoryModal}
            />
          )) : (<EmptyResult text="There are no categories yet" />)}
          </div>
        </ScrollWrapper>
      </div>
      {isOpenedCreateCategoryModal && (
        <CreateCategoryModal
          isOpen={isOpenedCreateCategoryModal}
          setIsOpenedModal={setIsOpenedCreateCategoryModal}
        />
      )}
      {isOpenedEditCategoryModal && (
        <EditCategoryModal
          isOpen={isOpenedEditCategoryModal}
          setIsOpenedModal={setIsOpenedEditCategoryModal}
        />
      )}
      {isOpenedDeleteCategoryModal && (
        <DeleteCategoryModal
          isOpen={isOpenedDeleteCategoryModal}
          setIsOpenedDeleteModal={setIsOpenedDeleteCategoryModal}
        />
      )}
      {isOpenedCreateSubcategoryModal && (
        <CreateSubcategoryModal
          isOpen={isOpenedCreateSubcategoryModal}
          setIsOpenedModal={setIsOpenedCreateSubcategoryModal}
        />
      )}
      {isOpenedEditSubcategoryModal && (
        <EditSubcategoryModal
          isOpen={isOpenedEditSubcategoryModal}
          setIsOpenedModal={setIsOpenedEditSubcategoryModal}
        />
      )}
      {isOpenedDeleteSubcategoryModal && (
        <DeleteSubcategoryModal
          isOpen={isOpenedDeleteSubcategoryModal}
          setIsOpenedDeleteModal={setIsOpenedDeleteSubcategoryModal}
        />
      )}
    </>
  )
}
