import React from 'react';
import PropTypes from 'prop-types';
import { pathOr, prop } from 'ramda';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { ButtonsCollection, ModalWindow, FormsCollection } from '../../../../ui-kit';
import DeleteTaskStatusModal from '../deleteTaskStatusModal';
import StatusItem from './statusItem';
import './style.sass';

const StatusesCRUDModal = ({
  selectedTasksStatusId, stateOfStatusesOptions, errors, setEditingStatusId, editingStatusId,
  setIsUIAddMoreStatuses, isUIAddMoreStatuses, values, handleChange, onDragEnd,
  handleSubmit, result, onDeleteTaskStatus, isOpen, onCloseModal,
}) => (
  <>
    <ModalWindow
      isOpen={isOpen}
      onClose={onCloseModal}
      modalName="statusesOfTasks"
      title="Statuses of this project"
      className="statusesCRUDModal modal-window--big"
    >
      <div className="statuses-list">
        <div className="statuses-list__container">
          <div className="statuses-list__row statuses-list__row--header">
            <div className="statuses-list__col">Column</div>
            <div className="statuses-list__col">Status</div>
          </div>
          <DragDropContext
            onDragEnd={onDragEnd}
          >
            <Droppable
              droppableId="drop-statuses"
              index="statuses"
            >
              {
              provided => (
                <div
                  ref={provided.innerRef}
                  className="statuses__draggable-area"
                >
                  {
                    result.map((id, index) => (
                      <StatusItem
                        id={id}
                        index={index}
                        key={`status-${id}`}
                        editingStatusId={editingStatusId}
                        onDeleteTaskStatus={onDeleteTaskStatus}
                        setEditingStatusId={setEditingStatusId}
                      />
                    ))
                  }
                </div>
              )
            }
            </Droppable>
          </DragDropContext>
          {
            isUIAddMoreStatuses && (
              <form
                onSubmit={handleSubmit}
                className="statuses-list__row statuses-list__row--form-add status-item"
              >
                <div className="statuses-list__col">
                  <FormsCollection.Input
                    name="title"
                    value={prop('title', values)}
                    error={pathOr('1', ['title', 'message'], errors)}
                    className="statuses-list__input-add"
                    onChange={handleChange}
                  />
                </div>
                <div className="statuses-list__col">
                  <FormsCollection.Select
                    value={prop('state', values)}
                    name="state"
                    onChange={handleChange}
                    data={stateOfStatusesOptions}
                  />
                </div>
                <div className="statuses-list__col">
                  <ButtonsCollection.ButtonBrill
                    className="button-brill--fill statuses-list__add-button"
                    type="submit"
                  >


                    Save
                  </ButtonsCollection.ButtonBrill>
                  <button
                    type="button"
                    className="button--cancel statuses-list__add-button-cancel"
                    onClick={() => setIsUIAddMoreStatuses(false)}
                  >


                    Cancel
                  </button>
                </div>
              </form>
            )
          }
          {
            !isUIAddMoreStatuses && (
              <div className="statuses-list__row">
                <ButtonsCollection.ButtonPlus
                  onClick={() => setIsUIAddMoreStatuses(true)}
                  className="add-column__button"
                />
                <button
                  type="button"
                  onClick={() => setIsUIAddMoreStatuses(true)}
                  className="add-column__label"
                >
                  {('Add column')}
                </button>
              </div>
            )
          }
        </div>
      </div>
    </ModalWindow>
    <DeleteTaskStatusModal statusId={selectedTasksStatusId} />
  </>
);

StatusesCRUDModal.propTypes = {
  selectedTasksStatusId: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  stateOfStatusesOptions: PropTypes.instanceOf(Array).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  setEditingStatusId: PropTypes.func.isRequired,
  editingStatusId: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
  setIsUIAddMoreStatuses: PropTypes.func.isRequired,
  isUIAddMoreStatuses: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
  values: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  result: PropTypes.instanceOf(Array).isRequired,
  onDeleteTaskStatus: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

StatusesCRUDModal.defaultProps = {
  selectedTasksStatusId: null,
  isUIAddMoreStatuses: null,
  editingStatusId: null,
  handleSubmit: () => {},
};

export default StatusesCRUDModal;
