import { socketActions, socketTypes } from '../../helpers/webSocket/state';
import { messengerActions } from '../../../state/messenger';


const reconnectSocketToServer = ({ dispatch }) => next => (action) => {
  if (action.type === socketTypes.RECONNECT_SOCKET_TO_SERVER) {
    const oldTotalUnreadCount = action.payload.totalUnreadCount;
    dispatch(socketActions.connectSocketToServer({
      isReconnect: true,
      afterOpenCb: () => {
        dispatch(
          messengerActions
            .getLatestUnreadMessageRequest({ thatParamForUniq: true, oldTotalUnreadCount }),
        );
      },
    }));
  } else {
    next(action);
  }
};

export {
  // eslint-disable-next-line import/prefer-default-export
  reconnectSocketToServer,
};
