import React from 'react';

import { ConfirmModal } from '@/ui-kit';
import { useDeleteProcessModal } from './useDeleteProcessModal';

export const DeleteProcessModal = ({ isPending }) => {
  const { isOpen, selectedProcess, onDelete, onClose } = useDeleteProcessModal();
  return (
    <ConfirmModal
      isOpen={isOpen}
      isPending={isPending}
      actionTitle="Delete"
      onAction={onDelete}
      onClose={onClose}
      title="Delete process"
    >
      Are you sure you want to delete
      <b>{selectedProcess?.title}</b>
      process?
    </ConfirmModal>
  );
};
