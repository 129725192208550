import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import './style.sass';

import { isNil } from 'ramda';
import { getMyPermissions, getUserData } from '../../state/user/selectors';

/**
 *
 * @param children{JSX.Element}
 * @param module{string}
 * @param action{string}
 * @param className{string}
 * @param entity{Object}
 * @param onlyOwner{boolean}
 * @returns {JSX.Element}
 * @constructor
 */

export const PermissionElement = ({
  children,
  module,
  action,
  entity = {},
  onlyOwner,
  className,
}) => {
  const myPermissions = useSelector(getMyPermissions);
  const user = useSelector(getUserData);

  const isNotAccess = useMemo(() => {
    if (isNil(myPermissions[module])) {
      return true;
    } return myPermissions[module][action] === 0;
  }, [action, module]);

  const isNotOwner = onlyOwner ? entity.created_by !== user.id : true;

  const childrenComponent = (
    (className && (
      <div className={className}>
        {children}
      </div>
    )) || <React.Fragment>{children}</React.Fragment>);

  return (isNotAccess && isNotOwner && module
    ? ''
    : childrenComponent
  );
};
