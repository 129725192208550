import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { compose } from 'recompose';
import { reduce } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { CoWorkerItem } from './coWorkerItem';
import { TabWrapper, Preloader } from '../../../../ui-kit';
import { usersSelectors, usersActions } from '../../../../state/users';
import { uiSelectors } from '../../../../state/ui';
import { PRELOADER_DIMENSION } from '../../../../constants/ui';
import {
  BRILL_NAME,
  DELIMITER_LEFT,
  DELIMITER_RIGHT,
  OFFSET_LEFT,
  OFFSET_RIGHT,
} from './constants';
import './style.sass';

/**
 * @param setRef {function}
 * @param getRef {function}
 * @returns {JSX.Element}
 * @constructor
 */

const PRELOADER_DURATION = 800;

export const CoWorkers = ({
  setRef,
  getRef,
}) => {
  const [isPreloader, setIsPreloader] = useState(false);
  const [isCoWorkersTabOpen, setIsCoWorkersTabOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const usersList = useSelector(usersSelectors.getCoworkersList);
  const leftSideBarStatus = useSelector(uiSelectors.getLeftSidebar);
  const getCoworkersRequest = useCallback((value) => {
    dispatch(usersActions.getCoworkersRequest(value));
  });
  const onSetRefContainer = useRef('container');
  const prevPropsRef = useRef({
    leftSideBarStatus, usersList,
  });

  useEffect(() => {
    prevPropsRef.current = {
      leftSideBarStatus, usersList,
    };
  }, [leftSideBarStatus, usersList]);
  const prevProps = prevPropsRef.current;

  const getCountBrillInRow = () => {
    const containerElement = onSetRefContainer.current;
    if (!containerElement) return 0;
    const containerWidth = parseInt(containerElement.offsetWidth, 10) - 11;
    const brillWidth = 43;
    return Math.floor(containerWidth / brillWidth);
  };

  const getDelimitersAmount = countBrillInRow => ({
    delimiterLeft: countBrillInRow,
    brillInRow: countBrillInRow,
    delimiterRight: (countBrillInRow * 2) - 2,
  });

  const setOffsetForBrill = ({ id, offsetPosition }) => {
    const brillElement = getRef(`${BRILL_NAME}${id}`);
    if (!brillElement) return offsetPosition;
    brillElement.setAttribute(offsetPosition, 'true');
    return offsetPosition;
  };

  const currentDelimiter = ({ index, delimiterLeft, delimiterRight }) => {
    if (delimiterLeft === index) return { offset: OFFSET_LEFT, delimiter: delimiterLeft };
    if (delimiterRight === index) return { offset: OFFSET_RIGHT, delimiter: delimiterRight };
    return false;
  };

  const deleteDelimiterAttribute = ({ attribute, element }) => {
    if (element.hasAttribute(attribute)) {
      element.removeAttribute(attribute);
    }
    return element;
  };


  const checkDelimiter = ({ acc, brillInRow, id }) => (params) => {
    if (!params) return { ...acc, ...{ currentIndex: acc.currentIndex + 1 } };
    const { delimiter, offset } = params;
    const keyDelimiter = offset === OFFSET_LEFT ? DELIMITER_LEFT : DELIMITER_RIGHT;
    setOffsetForBrill({ id, offsetPosition: offset });

    return ({
      ...acc,
      ...{
        [keyDelimiter]: delimiter + (brillInRow * 2) - 1,
        currentIndex: acc.currentIndex + 1,
      },
    });
  };

  const setGridBrill = ({ brillInRow }) => (acc, key) => {
    const { delimiterLeft, delimiterRight } = acc;
    deleteDelimiterAttribute({ attribute: OFFSET_RIGHT, element: getRef(`${BRILL_NAME}${key}`) });
    deleteDelimiterAttribute({ attribute: OFFSET_LEFT, element: getRef(`${BRILL_NAME}${key}`) });
    return compose(checkDelimiter({
      acc, brillInRow, id: key,
    }), currentDelimiter)({
      index: acc.currentIndex, delimiterLeft, delimiterRight,
    });
  };

  const setDataOffsetAttribute = () => ({ delimiterLeft, brillInRow, delimiterRight }) => {
    reduce(
      setGridBrill(onSetRefContainer.current, { brillInRow }),
      { delimiterLeft, delimiterRight, currentIndex: 0 },
      usersList,
    );
  };

  const makeGrid = () => {
    if (!usersList) return false;
    const countBrillInRow = getCountBrillInRow({ usersList });
    if (countBrillInRow) {
      compose(
        setDataOffsetAttribute({ usersList }),
        getDelimitersAmount,
      )(countBrillInRow);
    }
    return true;
  };

  const setPreloader = () => {
    setIsPreloader(true);
    setTimeout(() => setIsPreloader(false), PRELOADER_DURATION);
  };

  useEffect(() => {
    getCoworkersRequest();
    if (isCoWorkersTabOpen && usersList) {
      makeGrid({ isCoWorkersTabOpen, usersList });
      window.addEventListener('resize', () => makeGrid({ isCoWorkersTabOpen, usersList }));
    }
    return () => window.removeEventListener('resize', () => makeGrid({ isCoWorkersTabOpen, usersList }));
  }, []);

  useEffect(() => {
    if (prevProps.leftSideBarStatus !== leftSideBarStatus) {
      setPreloader();
    }
    if (usersList !== prevProps.usersList) {
      makeGrid({ isCoWorkersTabOpen, usersList });
    }
  }, [usersList, leftSideBarStatus]);


  return (
    <div
      className={classNames('co-workers', { 'co-workers--pending': isPreloader })}
      ref={onSetRefContainer}
    >
      <div className="co-workers__nav">
        <TabWrapper
          onChangeStatusTab={setIsCoWorkersTabOpen}
          defaultStatusTab={isCoWorkersTabOpen}
          title={t('Co-Workers')}
        >
          {
            (isPreloader && isCoWorkersTabOpen) && (
              <Preloader
                duration={PRELOADER_DURATION}
                dimension={PRELOADER_DIMENSION.EXTRA_SMALL}
              />
            )
          }
          {
            <ul className="co-workers__list">
              {
                (usersList.length ? usersList.map(id => (
                  <CoWorkerItem
                    customRef={setRef}
                    key={`coWorkerItem${id}`}
                    id={id}
                  />
                )) : <span className="co-workers__empty">{t('There are no co-workers yet')}</span>)
              }
            </ul>
          }
        </TabWrapper>
      </div>
    </div>
  );
};
