import { schema } from 'normalizr';

const clientsListSchema = [new schema.Entity('clients')];

const pinnedClientsListSchema = [new schema.Entity('pinnedClients')];

const clientTasksListSchema = [new schema.Entity('clientTasks')];


export {
  clientsListSchema,
  pinnedClientsListSchema,
  clientTasksListSchema,
};
