import { handleActions } from 'redux-actions';
import { filter, pathOr, prop } from 'ramda';
import { hrTypes } from './index';
import { mergeDeep, mergeIn } from '../../../../../utils/helpers/ramdaStateHelpers';

const initialProps = {};

const reducer = handleActions({
  [hrTypes.SET_HR_CANDIDATES]: mergeDeep(({
    payload: { data: { entities, result }, count, hasMore },
  }) => ({
    entities: { candidates: entities.candidates },
    result: { candidates: result },
    summary: { candidates: { hasMore, count } },
  })),
  [hrTypes.SET_HR_CANDIDATE]: mergeDeep(({ payload: { candidate } }) => ({
    entities: { candidates: { [candidate.id]: candidate } },
    result: { candidates: [candidate.id] },
    summary: { candidates: { hasMore: false, count: 1 } },
  })),
  [hrTypes.SET_HR_COMMENTS]: mergeIn(({
    payload: { data: { entities, result }, count, hasMore },
  }, state) => ({
    entities: {
      comments: entities.comments,
      candidates: pathOr({}, ['entities', 'candidates'], state),
      files: pathOr({}, ['entities', 'files'], state),
    },
    result: {
      comments: result,
      candidates: pathOr({}, ['result', 'candidates'], state),
      files: pathOr({}, ['result', 'files'], state),
    },
    summary: {
      comments: { count, hasMore },
      candidates: pathOr({}, ['summary', 'candidates'], state),
      files: pathOr({}, ['summary', 'files'], state),
    },
  })),
  [hrTypes.SET_HR_FILES]: mergeDeep(({
    payload: { data: { entities, result }, count, hasMore },
  }) => ({
    entities: { files: entities.files },
    result: { files: result },
    summary: { files: { count, hasMore } },
  })),
  [hrTypes.DELETE_HR_FILE_SUCCESS]: mergeIn(({ payload: { id } }, state) => ({
    result: {
      files: filter(fileId => fileId !== id, prop('files', state.result)),
      comments: pathOr({}, ['result', 'comments'], state),
      candidates: pathOr({}, ['result', 'candidates'], state),
    },
  })),
}, initialProps);

export default reducer;
