import * as yup from 'yup';
import { helpers } from '../../../utils';

const { translateHelpers } = helpers;

const rules = yup.object({
  contact: yup.object().shape({
    first_name: yup
      .string()
      .required({
        field: 'first_name',
        message: 'required',
        params: {
          key: translateHelpers.t('common', 'First name'),
        },
      }),
    last_name: yup
      .string()
      .required({
        field: 'last_name',
        message: 'required',
        params: {
          key: translateHelpers.t('common', 'Last name'),
        },
      }),
    email: yup
      .string()
      .email({
        field: 'email',
        message: 'Email is invalid',
        params: {
          key: translateHelpers.t('common', 'Email'),
        },
      }),
    phone: yup
      .string(),
    company_name: yup
      .string(),
    avatar: yup
      .string(),
    company_url: yup
      .string(),
    position: yup
      .string(),
    service: yup
      .string(),
  }),
});

export default rules;
