import { CKEditor } from '@ckeditor/ckeditor5-react';

import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ButtonsCollection, DefaultAutoComplete, FormsCollection } from '@/ui-kit';
import { useKnowledgeBaseContext } from '@/views/knowledgeBase/context/useKnowledgeBaseContext';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import './style.sass';

export const ArticleForm = ({ onAction, submitButtonText }) => {
  const { t } = useTranslation();

  const { subcategoryId } = useParams();

  const { refreshCategories, categoriesData } = useKnowledgeBaseContext();

  useEffect(() => {
    refreshCategories()
  }, []);

  const categories = useMemo(() => {
    if (categoriesData.length) {
      return categoriesData.map((category) =>
        category.subCategories.map((subcategory) => ({
          label: `${subcategory.description} (${category.description})`,
          value: subcategory.id,
          isFixed: subcategory.id === Number(subcategoryId),
        }))).flat();
    }
      return [];
  }, [categoriesData])

  const { control, handleSubmit, setValue } = useFormContext();

  const onChangeAutocomplete = useCallback((items) => {
    const result = items.map(item => item.value);
    setValue('categoryIds', result);
  }, [setValue]);

  const getAutocompleteValue = (value) =>
    value.map(item => categories.find(option =>
      item === option.value));

  return (
    <FormsCollection.FormWrapper
      handleSubmit={handleSubmit(onAction)}
      classNameForm="article-form"
    >
      <div className="article-form__row">
        <div className="article-form__col">
          <label className="article-form__label" htmlFor="article-form_title">{t('Title')}</label>
          <Controller
            name="title"
            control={control}
            render={({
              touched = true,
              field: {
               onChange,
                value,
                onBlur
              },
              fieldState: {
               error
              },
            }) => (
              <FormsCollection.Input
                name="title"
                value={value}
                error={error?.message.message}
                onChange={onChange}
                onBlur={onBlur}
                touched={touched}
                id="article-form_title"
                placeholder="Title"
              />
            )}
          />
        </div>
        <div className="article-form__col">
          <label className="article-form__label" htmlFor="article-form_categoryIds">{t('Categories')}</label>
          <Controller
            name="categoryIds"
            control={control}
            render={({
              field: { value, onBlur }, touched,
            }) => (
              <DefaultAutoComplete
                isMulti
                placeholder={t('Choose categories')}
                options={categories}
                onChange={onChangeAutocomplete}
                onBlur={onBlur}
                touched={touched}
                value={getAutocompleteValue(value)}
                name="categoryIds"
                id="article-form_categoryIds"
              />
            )}
          />
        </div>
      </div>
      <div className="article-form__row">
        <div className="article-form__editor-wrapper">
          <label className="article-form__label" htmlFor="article-form_content">{t('Content')}</label>
          <Controller
            name="content"
            control={control}
            render={({
              field: { value, onBlur },
            }) => (
              <CKEditor
                id="article-form_content"
                editor={Editor}
                onBlur={onBlur}
                config={{
                  placeholder: 'Click here to start typing',
                  mediaEmbed: { previewsInData: true }
                }}
                data={value}
                onChange={(e, editor) => {
                  const data = editor.getData();
                  setValue('content', data);
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="article-form__row article-form__row--buttons">
        <ButtonsCollection.ButtonBrill
          type="submit"
          className="article-form__submit-button button-brill--fill"
        >
          {submitButtonText}
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  )
}
