import React from 'react';
import PropTypes from 'prop-types';

import { ScrollWrapper } from '../../../../ui-kit';
import { Board } from './components';
import './style.sass';

const PipeView = ({ setRef, pipelineStatuses }) => (
  <div
    className="pipe-view"
    ref={e => setRef('agendaRow', e)}
  >
    <ScrollWrapper autoHide isEasyScroll className="pipe-view__scroll-wrapper">
      <div
        className="pipe-view__column-wrapper"
      >
        <Board pipelineStatuses={pipelineStatuses} />
      </div>
    </ScrollWrapper>
  </div>
);

PipeView.propTypes = {
  setRef: PropTypes.func.isRequired,
  pipelineStatuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PipeView;
