import React, { memo } from 'react';
import { Controller } from 'react-hook-form';
import { FormsCollection } from '../../../ui-kit';

/**
 *
 * @param defaultValue {string}
 * @param control {object}
 * @returns {JSX.Element}
 * @constructor
 */

const ControlledTitleField = ({
  control, defaultValue,
}) => (
  <Controller
    name="title"
    control={control}
    defaultValue={defaultValue}
    render={({
      field: {
        onBlur, onChange, value, name,
      },
      fieldState: { error },
    }) => (
      <FormsCollection.Input
        className="custom-fields__input-add"
        error={error?.message}
        touched
        onBlur={onBlur}
        onChange={onChange}
        value={value || ''}
        name={name}
      />
    )}
  />
);

ControlledTitleField.defaultProps = {
  control: {},
};

export default memo(ControlledTitleField);
