import React from 'react';
import { WINDOW_WIDTH } from '@/constants/ui';

import { ButtonsCollection, Header, ScrollWrapper } from '@/ui-kit';
import { ProcessesListContainer } from '@bp/containers';

import { useBusinessProcesses } from './useBusinessProcesses';

export const BusinessProcesses = () => {
  const { windowWidth, onCreateProcessModal } = useBusinessProcesses();
  return (
    <>
      <Header className="role-header" hideToggleRightSideBar>
        <>
          <h1 className="weight--medium title--xl title-page">Business Processes</h1>
        </>
        {windowWidth > WINDOW_WIDTH.MEDIUM ? (
          <ButtonsCollection.ButtonBrill
            onClick={onCreateProcessModal}
            className="button--sm header__control-button--project"
          >
            Create a new Business Process
          </ButtonsCollection.ButtonBrill>
        ) : (
          <ButtonsCollection.ButtonPlus
            onClick={onCreateProcessModal}
            className="header__control-button--project"
          />
        )}
      </Header>
      <ScrollWrapper calcContentHeight>
        <div className="main-container__content main-container__content--roles">
          <ProcessesListContainer />
        </div>
      </ScrollWrapper>
    </>
  );
};
