import React, { memo } from 'react';

/**
 *
 * @returns {JSX.Element}
 *
 */

const BurgerDnd = () => (
  <div className="field-item burger-dnd">
    <span />
    <span />
    <span />
  </div>
);

export default memo(BurgerDnd);
