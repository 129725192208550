import {
  compose, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';

import rules from '../rules';
import { getModal } from '../../../../../../../state/ui/selectors';
import { leadsActions, leadsSelectors } from '../../../../../../../state/leads';
import { uiActions } from '../../../../../../../state/ui';
import CreateNote from '../../../../../../projectsNotes/components/notesCRUD/createNote/createNote';

const mapStateToProps = state => ({
  isOpen: getModal(state)('createNoteModal'),
  isPending: leadsSelectors.getLeadActionPending(state)('addLeadNoteRequest'),
});

const mapDispatchToProps = ({
  closeModal: () => uiActions.closeModal('createNoteModal'),
  addLeadNoteRequest: leadsActions.addLeadNoteRequest,
  getLeadNotesRequest: leadsActions.getLeadNotesRequest,
});

const formSubmit = (formValues, {
  resetForm,
  props: {
    closeModal, addLeadNoteRequest, getLeadNotesRequest, leadId: id, order, sortBy,
  },
}) => {
  const data = { ...formValues, id };
  addLeadNoteRequest(data, {
    callbacks: {
      success: () => {
        getLeadNotesRequest({ id, sortBy, order });
        closeModal();
        resetForm({});
      },
    },
  });
};

const onCloseModalHandler = ({ closeModal, resetForm }) => () => {
  resetForm({});
  closeModal();
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common']),
  withFormik({
    mapPropsToValues: () => ({
      title: '',
      content: '',
    }),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: formSubmit,
  }),
  withHandlers({
    onCloseModal: onCloseModalHandler,
  }),
);

export default enhance(CreateNote);
