import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SummaryCard } from '../summaryCard';
import './style.sass';
import { APRICOT_PEACH, ATHENS_GRAY, CARNATION } from '../../constants/colors';

/**
 *
 * @type {React.NamedExoticComponent<{readonly request?: function}>}
 */

export const SummaryCards = memo(({ request }) => {
  const [summary, setSummary] = useState({});
  const { allCount, inProgressCount, doneCount } = summary;

  const getSummaryRequest = async () => {
    const data = await request();
    if (data) {
      setSummary(data);
    }
    return data;
  };
  useEffect(() => {
    getSummaryRequest();
  }, []);

  const { t } = useTranslation('common');

  return (
    <div className="summary-card__cards">
      <SummaryCard
        className="summary-card__item--all"
        colorBorder={APRICOT_PEACH}
        count={allCount}
        title={t('All')}
      />
      <SummaryCard
        className="summary-card__item--progress"
        colorBorder={CARNATION}
        count={inProgressCount}
        title={t('In progress')}
      />
      <SummaryCard
        className="summary-card__item--done"
        colorBorder={ATHENS_GRAY}
        count={doneCount}
        title={t('Done')}
      />
    </div>
  );
});
