import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router';
import HrFilesList from './hrFilesList';
import { hrActions } from '../../store/candidate';


const deleteFileHandler = ({
  deleteHrFileRequest, match: { params },
}) => id => deleteHrFileRequest({ id, candidateId: +params.id });

const mapDispatchToProps = {
  deleteHrFileRequest: hrActions.deleteHrFileRequest,
};

const enhance = compose(
  withRouter,
  connect(null, mapDispatchToProps),
  withHandlers({ deleteFile: deleteFileHandler }),
);
export default enhance(HrFilesList);
