import React from 'react';
import PropTypes from 'prop-types';
import { ButtonsCollection, DropDown } from '../../../../ui-kit';
import { PipelineStatusItem } from '..';

const PipeLineItem = ({
  pipeline: { id, title },
  onEditPipeline,
  onDeletePipeline,
  isOpen,
  pipelineStatuses,
  onToggleStatuses,
}) => (
  <div className={`pipelines__row ${isOpen ? 'pipelines__row--active' : ''}`}>
    <div className="pipelines__row-wrap">
      <div className="pipelines__col">{id}</div>
      <div className="pipelines__col">
        {title}
      </div>
      <div className="pipelines__col pipelines__col--show-more">
        <ButtonsCollection.ButtonIcons title="Toggle pipeline" onClick={onToggleStatuses}>
          <i className="icon-down" />
        </ButtonsCollection.ButtonIcons>
      </div>
      <div className="pipelines__col">
        <DropDown
          className="controls-group-task"
          name="controls-group-task"
          label={(
            <ButtonsCollection.ButtonIcons
              className="project-task__controls-button-task"
              title="Controls for pipeline"
            >
              <span className="project-task__control-icon icon-settings-vertical">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
              </span>
            </ButtonsCollection.ButtonIcons>
          )}
          list={
            [
              {
                id: 0,
                onClick: onEditPipeline,
                label: (
                  <>
                    <span className="controls-group-task__icon icon-pencil-2" />
                    <span className="controls-group-task__text">Edit</span>
                  </>
                ),
              },
              {
                id: 1,
                onClick: onDeletePipeline,
                label: (
                  <>
                    <span className="controls-group-task__icon icon-delete-icon" />
                    <span className="controls-group-task__text">Delete</span>
                  </>
                ),
              },
            ]
          }
        />
      </div>
    </div>
    <div className="pipelines__status-wrap">
      {
        isOpen && pipelineStatuses.map(item => (
          <PipelineStatusItem
            id={item.id}
            key={item.id}
            title={item.title}
            progress={item.progress}
          />
        ))
       }
    </div>
  </div>
);

PipeLineItem.propTypes = {
  pipeline: PropTypes.shape({}).isRequired,
  pipelineStatuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onEditPipeline: PropTypes.func.isRequired,
  onDeletePipeline: PropTypes.func.isRequired,
  onToggleStatuses: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default PipeLineItem;
