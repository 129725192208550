import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ButtonsCollection } from '../../../../../../../../../ui-kit';
import './style.sass';

export const TotalTimeItem = ({ row, fields, children }) => {
  const { t } = useTranslation();

  const [showMore, setShowMore] = useState(false);

  const handleToggleMore = () => {
    setShowMore(!showMore);
  };
  return (
    <>
      <div
        role="button"
        tabIndex="0"
        className={classNames('total-time-item', children && 'total-time-item--tab', fields?.title_text && 'total-time-summary')}
        onClick={handleToggleMore}
      >
        {fields?.hash
        && (
        <div className="total-time-item__id">
          {row?.task_id ?? '###'}
        </div>
        )}
        {(fields?.title || fields?.title_text)
        && (
          <div
            className="total-time-item__title"
          >
            <div className="total-time-item__title-text cut-long-text" title={row?.title_text}>
              {fields?.title
              && <Link to={row?.link} title={row?.title}>{row?.title}</Link>
              }
              {fields?.title_text
                && t(fields?.title_text)
              }
              {row?.title_text
              && (row?.user_id
                ? row?.title_text
                : <Link to={row.link}>{row.title_text}</Link>)
              }
            </div>
          </div>
        )
        }
        {fields?.user
        && (
          <div
            className="total-time-item__title"
          >
            <div className="total-time-item__title-text cut-long-text" title={`${row?.first_name} ${row?.last_name}`}>
              {`${row?.first_name ?? ''} ${row?.last_name ?? ''}`}
            </div>
          </div>
        )
        }
        <div className="total-time-item__estimate">
          <span className="total-time-item__cursor" title={`Equality - ${row?.time_c_h}`}>
            {row?.time_c}
          </span>
        </div>
        <div className="total-time-item__spent">
          <span className="total-time-item__cursor" title={`Equality - ${row?.estimate_c_h}`}>
            {row?.estimate_c}
          </span>
        </div>
        {fields?.more && children && (
          !showMore ? (
            <div className="total-time-item__open">
              <ButtonsCollection.ButtonIcons
                className="table-tasks__sprint-toggle-button"
              >
                <span className="icon-expand-arrow-dn" />
              </ButtonsCollection.ButtonIcons>
            </div>
          ) : (
            <div className="total-time-item__close">
              <ButtonsCollection.ButtonIcons
                className="table-tasks__sprint-toggle-button"
              >
                <span className="icon-expand-arrow-dn" />
              </ButtonsCollection.ButtonIcons>
            </div>
          )
        )}
      </div>
      { fields?.more && showMore && (
        <div className="total-time_item__tasks">
          {children}
        </div>
      )}
    </>
  );
};
