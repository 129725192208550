import {
  compose, getContext, lifecycle, withHandlers,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import ClientTasksList from './clientTasksList';
import { uiActions } from '../../../../state/ui';
import { clientsSelectors, clientsActions } from '../../../../state/clients';
import { preloaderWhileLoading, withWindowWidth } from '../../../../utils/enchancers';
import { TASKS_WITHOUT_PROJECT, STATUS } from '../../../../constants/tasks';
import { tasksActions } from '../../../../state/tasks';
import { taskActions } from '../../../../state/task';

const mapStateToProps = (state, { clientId }) => ({
  clientTasks: clientsSelectors.getClientTasksSelector(state)(clientId),
  isLoading: clientsSelectors.getClientTasksPendingRequest(state),
});

const mapDispatchToProps = {
  openModal: uiActions.openModal,
  getClientTasks: clientsActions.getClientTasksRequest,
  editTask: tasksActions.updateTaskRequest,
  getClientLogs: clientsActions.getClientLogsRequest,
  connectedPush: push,
  getTask: taskActions.getActiveTaskRequest,
  fullEditClientTaskSuccess: clientsActions.fullEditClientTaskSuccess,
  changeClientTaskStatusSuccess: clientsActions.changeClientTaskStatusSuccess,
};

const onSetActiveTaskIdHandler = ({ setSelectedClientTask }) => (id, cb) => () => {
  setSelectedClientTask(id);
  cb();
};

const onEditFullTaskActionHandler = ({
  getTask, setSelectedClientTask, clientId,
}) => (task, cb) => () => {
  getTask({ taskId: task.id, project_id: task.project_id, clientId });
  setSelectedClientTask(task.id);
  cb();
};

const onClickTaskClientHandler = ({ connectedPush }) => ({ id, project_id: projectId }) => () => {
  connectedPush(`/projects/${projectId || TASKS_WITHOUT_PROJECT}/task/${id}/`);
};

const onChangeTaskStatusHandler = ({
  clientId: id, getClientTasks, editTask,
  getClientLogs, changeClientTaskStatusSuccess,
}) => task => () => {
  const newTaskStatus = task.status_id === STATUS.TO_DO.id ? STATUS.DONE.id : STATUS.TO_DO.id;
  editTask({
    ...task,
    clientId: id,
    projectId: TASKS_WITHOUT_PROJECT,
    status: newTaskStatus,
    status_id: newTaskStatus,
  }, {
    section: 'project',
    callbacks: {
      success: () => {
        getClientLogs({ id });
        getClientTasks({ id });
        changeClientTaskStatusSuccess({ id });
      },
    },
  });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { clientId, getClientTasks } = this.props;
      getClientTasks({ id: clientId }, { id: clientId });
    },
  }),
  getContext({
    setSelectedClientTask: PropTypes.func.isRequired,
  }),
  withWindowWidth(),
  withHandlers({
    onSetActiveTaskId: onSetActiveTaskIdHandler,
    onEditFullTask: onEditFullTaskActionHandler,
    onClickTaskClient: onClickTaskClientHandler,
    onChangeTaskStatus: onChangeTaskStatusHandler,
  }),
  preloaderWhileLoading({
    dimension: 75,
    isLoading: props => !props.isLoading,
  }),
);
export default enhance(ClientTasksList);
