import { lifecycle } from 'recompose';
import { memo } from 'react';

const checkIsUserValid = selector => lifecycle({
  componentDidMount() {
    const action = this.props[selector];
    action();
  },
  memo,
});


export default checkIsUserValid;
