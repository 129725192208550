import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import './style.sass';
import { useTranslation } from 'react-i18next';
import { EmptyText } from './EmptyText';
import { WithPreload } from './WithPreload/WithPreload';

const isEmpty = arr => !arr.length;

/**
 *
 * @param data {array}
 * @param handlerFetch {function}
 * @param className {string}
 * @param pending {boolean}
 * @param limit {number}
 * @param count {number}
 * @param renderItem {function}
 * @param emptyText {string | object}
 * @param hasMore {boolean}
 * @returns {JSX.Element}
 * @constructor
 */


export const ShowMoreLazyLoad = ({
  data = [],
  handlerFetch = () => { },
  className = '',
  pending = false,
  limit = 10,
  count = 0,
  renderItem = () => { },
  emptyText = 'There are no items yet',
  hasMore = false,
}) => {
  const [offset, setOffset] = useState(0);
  const [currentCount, setCurrentCount] = useState(isEmpty(data) ? 0 : limit);
  const [currentVector, setCurrentVector] = useState(true);
  const { t } = useTranslation('common');

  const toggleCurrentVector = useCallback(() => {
    setCurrentVector(prevValue => !prevValue);
  }, []);


  const handleRenderItem = useCallback(idItem => renderItem({ idItem }), [renderItem]);

  const handleVectorChange = useCallback(() => {
    if (currentVector) {
      setCurrentCount(prevValue => prevValue + limit);
      if (currentCount + limit >= count && currentVector && count !== 0) {
        toggleCurrentVector();
      }
    } else {
      setCurrentCount(prevValue => prevValue - limit);
      if (currentCount - limit <= limit && !currentVector) {
        toggleCurrentVector();
      }
    }
  }, [currentCount, currentVector, limit, count]); // eslint-disable-line

  const renderItems = (itemsToRender, numOfItems) => itemsToRender.slice(0, numOfItems)
    .map(idItem => handleRenderItem(idItem));

  const onFetchItems = useCallback(
    () => {
      if (hasMore) {
        setOffset(offset + limit);
        handlerFetch({ limit, offset });
      }
      handleVectorChange();
    }, [hasMore, offset, limit, currentCount], // eslint-disable-line
  );

  // eslint-disable-next-line
  useEffect(() => {
    if (isEmpty(data)) {
      onFetchItems();
    }
  }, []); // eslint-disable-line

  return (
    <div className={`show-more-lazy ${className}`}>
      <div className="show-more-lazy__body">
        {renderItems(data, currentCount)}
      </div>
      <WithPreload pending={pending}>
        {isEmpty(data) ? (<EmptyText emptyText={emptyText} />) : count > limit && (
          <WithPreload pending={pending}>
            <div className="show-more-lazy__row">
              <button type="button" className="show-more-lazy__button" onClick={onFetchItems}>
                <span className="show-more-lazy__button-text">{currentVector ? t('Show more') : t('Show less')}</span>
                <span
                  className={classNames('show-more-lazy__button-icon icon-expand-arrow-dn', { 'icon--up': !currentVector })}
                />
              </button>
            </div>
          </WithPreload>
        )
        }
      </WithPreload>
    </div>
  );
};
