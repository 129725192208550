import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import ChannelsItem from './channelsItem';
import { uiActions } from '../../../../state/ui';
import { usersActions } from '../../../../state/users';

const mapDispatchToProps = {
  setUserProfileId: uiActions.setUserProfileId,
  getUserProfileRequest: usersActions.getUserProfileRequest,
};

const onChannelAuthorClickHandler = ({
  channel: { author = {} } = {},
  setUserProfileId,
  changeRightSidebarStatus,
  getUserProfileRequest,
}) => (e) => {
  e.preventDefault();
  e.stopPropagation();
  if (author.id) {
    getUserProfileRequest({ userId: author.id });
    changeRightSidebarStatus(true);
    setUserProfileId(author.id);
  }
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  withHandlers({
    onChannelAuthorClick: onChannelAuthorClickHandler,
  }),
);

export default enhance(ChannelsItem);
