import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';

import { ConfirmModal } from '../../../../../ui-kit';
import './style.sass';

const UnpinMessageModal = ({
  isOpen, onClose, message, onUnpinMessageHandler, isPending,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Unpin"
    onAction={onUnpinMessageHandler}
    onClose={onClose}
    title="Unpin message"
  >

    Are you sure you want to unpin
    <b className="message-short">{prop('content', message)}</b>

    message?
  </ConfirmModal>
);

UnpinMessageModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onUnpinMessageHandler: PropTypes.func.isRequired,
  message: PropTypes.instanceOf(Object),
  isPending: PropTypes.bool,
};

UnpinMessageModal.defaultProps = {
  isPending: false,
  message: {},
  isOpen: false,
};

export default UnpinMessageModal;
