import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';
import { ButtonsCollection, Avatar, BrillMask } from '../../../../../../ui-kit';
import './styles.sass';

import { getImageUrl } from '../../../../../../utils/helpers/requestHelpers';
import { IMAGES_SIZE } from '../../../../../../constants/ui';

const ProjectTab = ({
  project, isOpen, onToggleProjectTab, children,
}) => (
  <div className="my-tasks__project">
    <div className="my-tasks__project-header">
      <ButtonsCollection.ButtonIcons
        className={`my-tasks__project-toggle-button ${isOpen ? 'my-tasks__project-toggle-button--open' : ''}`}
        title={`${isOpen ? 'Show less' : 'Show more'}`}
        onClick={onToggleProjectTab}
      >
        <span className="icon-expand-arrow-dn" />
      </ButtonsCollection.ButtonIcons>
      <BrillMask>
        <Avatar
          src={getImageUrl(prop('image', project), IMAGES_SIZE.beforeSmall)}
          alt={prop('title', project)}
        />
      </BrillMask>
      <h2 className="my-tasks__project-title">{ project.title }</h2>
    </div>
    <div className={`my-tasks__project-body ${isOpen ? 'my-tasks__project-body--open' : ''}`}>
      {children}
    </div>
  </div>
);

ProjectTab.propTypes = {
  project: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  onToggleProjectTab: PropTypes.func.isRequired,
};

export default ProjectTab;
