import { compose } from 'recompose';

import { MODULES } from '../../constants/pemissions';
import withPermission from '../../utils/enchancers/withPemission';
import { Settings } from './Settings';


const enhancer = compose(
  withPermission(MODULES.SETTINGS),
);

export default enhancer(Settings);
