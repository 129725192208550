import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '@/state/ui/actions';
import { CRUD_NODE_CONFIG_ACTIONS } from '@bp/constants';
import { bpActions, bpSelectors } from '@bp/store/bpModule';

const { selectEventById, setCrudNodesConfig, selectNodeById } = bpActions;

export const useProcessNode = ({ id, event_id: eventId }) => {
  const dispatch = useDispatch();
  const activeEvent = useSelector(bpSelectors.getActiveEventById)(eventId);

  const selectActiveNode = useCallback(() => {
    dispatch(selectNodeById(id));
    dispatch(selectEventById(eventId));
  }, [dispatch, id, eventId]);

  const onEditAction = useCallback(() => {
    const { editAction, editTrigger } = CRUD_NODE_CONFIG_ACTIONS;
    const params = activeEvent.is_action ? editAction : editTrigger;
    selectActiveNode();
    dispatch(setCrudNodesConfig(params));
  }, [dispatch, activeEvent, selectActiveNode]);

  const onAddAction = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(selectEventById(null));
      dispatch(setCrudNodesConfig(CRUD_NODE_CONFIG_ACTIONS.createAction));
    },
    [dispatch],
  );

  const onDeleteAction = useCallback(
    (e) => {
      e.stopPropagation();
      selectActiveNode();
      dispatch(openModal('deleteNodeModal'));
    },
    [dispatch, selectActiveNode],
  );

  return { activeEvent, onAddAction, onEditAction, onDeleteAction };
};
