import { compose } from 'recompose';
import { connect } from 'react-redux';
import { indexOf } from 'ramda';
import { messengerSelectors } from '../../state/messenger';

const mapStateToProps = (state, { id, channelId }) => {
  const lastIdMessageInResult = indexOf(id,
    messengerSelectors.getMessageList(state)(channelId)) - 1 || 0;
  return {
    prevMessageId: messengerSelectors.getMessageList(state)(channelId)[lastIdMessageInResult],
  };
};

const withPrevMessageId = compose(
  connect(mapStateToProps),
);

export default withPrevMessageId;
