import {
  compose, lifecycle, withState,
} from 'recompose';
import { connect } from 'react-redux';
import {
  preloaderWhileLoading,
  withAgendaColumnSwipe,
  withRefs,
} from '../../../../utils/enchancers';
import { pipelinesActions, pipelinesSelectors } from '../../../../state/pipelines';
import PipeView from './pipeView';
import { leadsSelectors } from '../../../../state/leads';

const mapStateToProps = (state, { pipeline }) => ({
  pipelineStatuses: pipelinesSelectors.getPipelineStatusesSelector(state)(pipeline),
  isPending: pipelinesSelectors.getPipelineStatusesPendingRequest(state),
  isPendingLeads: leadsSelectors.getLeadsPendingRequest(state),
});

const mapDispatchToProps = ({
  getPipelineStatusesRequest: pipelinesActions.getPipelineStatusesRequest,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRefs(),
  withState('showLoader', 'setIsShowLoader', true),
  lifecycle({
    componentDidMount() {
      const { pipeline: pipelineId, getPipelineStatusesRequest } = this.props;
      getPipelineStatusesRequest({ pipelineId });
    },
    componentDidUpdate(prevProps) {
      const {
        pipeline: pipelineId, getPipelineStatusesRequest,
        isPending, isPendingLeads, setIsShowLoader, showLoader,
      } = this.props;
      const {
        pipeline: prevPipeline,
      } = prevProps;
      if (pipelineId !== prevPipeline) {
        getPipelineStatusesRequest({ pipelineId });
      }

      // Show loader only when component was mounted
      if (!isPending && !isPendingLeads && showLoader) {
        setIsShowLoader(false);
      }
    },
  }),
  preloaderWhileLoading({
    dimension: 100,
    fullScreen: true,
    isLoading: () => false,
    onAction: ({ showLoader }) => showLoader,
    delay: 600,
  }),
  withAgendaColumnSwipe({ range: 50 }),
);

export default enhance(PipeView);
