import React from 'react';
import { prop, equals } from 'ramda';
import PropTypes from 'prop-types';

import RepositoryItem from './components';
import './style.sass';

const data = [
  {
    id: '222jn2j2j',
    name: 'avanga-api',
    url: 'gitolite@78.47.42.169:avanga-api',
  },
  {
    id: '222ll2k',
    name: 'avanga-spa',
    url: 'gitolite@78.47.42.169:avanga-api',
  },
  {
    id: '2kl22k2k',
    name: 'avanga-2.0',
    url: 'gitolite@78.47.42.169:avanga-api',
  },
];
const RepositoriesList = ({ activeTab, changeActiveTabStateHandler }) => (
  <div className="repositories-list">
    <div className="repositories-list__table">
      <div className="repositories-list__table-head">
        <div className="repositories-list__table-row">
          <div className="repositories-list__table-col"><b>Name</b></div>
          <div className="repositories-list__table-col"><b>URL</b></div>
          <div className="repositories-list__table-col" />
        </div>
      </div>
      <div className="repositories-list__table-body">
        {
          data.map(item => (
            <RepositoryItem
              key={prop('id', item)}
              isOpen={equals(item.id, activeTab)}
              id={prop('id', item)}
              name={prop('name', item)}
              url={prop('url', item)}
              toggleIsOpen={changeActiveTabStateHandler}
            />
          ))
        }
      </div>
    </div>
  </div>
);

RepositoriesList.propTypes = {
  activeTab: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  changeActiveTabStateHandler: PropTypes.func,
};

RepositoriesList.defaultProps = {
  activeTab: '',
  changeActiveTabStateHandler: () => {},
};


export default RepositoriesList;
