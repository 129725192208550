import { createActions } from 'redux-actions';
import { identity } from 'ramda';
import types from './types';

export const {
  checkCurrentUserRequest,
  setUserIsValid,
  setAuthenticated,
  logout,
  setUserIsInvalid,
  setUser,
  setUserIsValidFlag,
} = createActions(
  {
    [types.CHECK_CURRENT_USER_REQUEST]: [
      identity,
      params => ({
        async: true,
        params,
        route: '/auth/current-user',
        selectorName: 'checkCurrentUser',
        method: 'GET',
        actions: {
          success: setUserIsValid,
          error: setUserIsInvalid,
        },
      }),
    ],
  },
  types.SET_USER_IS_VALID,
  types.SET_AUTHENTICATED,
  types.LOGOUT,
  types.SET_USER_IS_INVALID,
  types.SET_USER,
  types.SET_USER_IS_VALID_FLAG,
);
