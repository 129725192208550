import React from 'react';
import PropTypes from 'prop-types';
import { prop, pathOr } from 'ramda';

import './style.sass';
import {
  Pagination,
  Grid,
  StatusBar,
  Favorite,
  Avatar,
  BrillMask,
  ButtonsCollection,
  DropDown,
  PermissionElement,
  EmptyResult,
} from '../../../../ui-kit';
import { DeleteProjectModal, EditProjectModal } from '../modals';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import { IMAGES_SIZE } from '../../../../constants/ui';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';

const ProjectsAsList = ({
  data, count, pagination, projects, onSortBy, gridName, sort,
  order, isLoading, onOpenEditProjectModal, onOpenDeleteProjectModal, push,
  selectedProjectId, onDeleteProject, setProgressProject, onToggleFavoriteStatus, t, P, onPath,
}) => (
  <>
    <Grid.Wrapper
      isLoading={!isLoading}
      isKeepOptions
      name={gridName}
      className="table-projects"
      pagination={(
        <Pagination
          count={count}
          onPath={onPath}
          component={gridName}
          pagination={pagination}
          className="projects-list__pagination"
        />
      )}
    >
      <Grid.Row className="table-projects__row-controls">
        <Grid.Col
          sort={sort}
          order={order}
          filter="title"
          onClick={() => onSortBy({ sort: 'title' })}
          name={t('Project')}
          className="table-projects__col--project"
        />
        <div />
        <Grid.Col
          name={t('Tasks')}
          className="table-projects__col--tasks"
        />
        <Grid.Col
          order={order}
          sort={sort}
          filter="done"
          name={`${t('Done')} %`}
          className="table-projects__col--done"
        />
        <Grid.Col className="table-projects__col--controls" />
      </Grid.Row>
      {data.length !== 0
        ? data.map(item => (
          <Grid.Row
            onClick={() => push(`/projects/${item}/`)}
            key={`project-grid  - ${item}`}
            className="table-projects__row project-row"
          >
            <Grid.ColData className="table-projects__col--project__icon">
              <div className="project-row__icon">
                <BrillMask asLink to={`/projects/${item}/`}>
                  <Avatar
                    src={getImageUrl(prop('image', projects[item]), IMAGES_SIZE.sm)}
                    alt={prop('title', projects[item])}
                  />
                </BrillMask>
              </div>
            </Grid.ColData>
            <Grid.ColData className="table-projects__col--project__title">
              <div
                title="Open project  "
                className="project-row__title"
              >
                {prop('title', projects[item])}
              </div>
            </Grid.ColData>
            <Grid.ColData className="table-projects__col--tasks">
              <div className="project-row__task-count">
                <div className="project-row__task-count-text">
                  {pathOr(0, ['__meta__', 'openTasksCount'], projects[item])}
                </div>
                {prop('countImmediate', projects[item]) && (
                  <div className="project-row__implement-task">
                    <div className="project-row__implement-task-text">
                      {prop('countImmediate', projects[item])}
                    </div>
                  </div>
                )}
              </div>
            </Grid.ColData>
            <Grid.ColData className="table-projects__col--progress">
              <StatusBar percents={Math.round(setProgressProject(projects[item]))} />
            </Grid.ColData>
            <Grid.ColData className="table-projects__col--controls">
              <Favorite
                status={prop('favorite', projects[item])}
                onClick={e => onToggleFavoriteStatus(e,
                  prop('favorite', projects[item]),
                  prop('id', projects[item]))}
              />
              <PermissionElement
                onlyOwner
                entity={projects[item]}
                className="agenda-task-control--empty"
              >
                <DropDown
                  className="agenda-task-control"
                  name="control-options"
                  label={(
                    <ButtonsCollection.ButtonIcons
                      className="project-row__control-burger"
                      title={t('Control buttons')}
                    >
                      <span className="table-tasks__control-icon icon-settings-vertical">
                        <span
                          className="path1"
                        />
                        <span className="path2" />
                        <span
                          className="path3"
                        />
                      </span>
                    </ButtonsCollection.ButtonIcons>
                  )}
                  list={
                    [
                      ...P(MODULES.PROJECTS, ACTIONS.UPDATE, [
                        {
                          id: 0,
                          label: (
                            <>
                              <span className="agenda-task-control__icon icon-pencil-2" />
                              <span className="agenda-task-control__text">{t('Edit')}</span>
                            </>
                          ),
                          onClick: () => onOpenEditProjectModal(prop('id', projects[item])),
                        },
                      ]),
                      ...P(MODULES.TASKS, ACTIONS.DELETE, [
                        {
                          id: 2,
                          label: (
                            <>
                              <span className="agenda-task-control__icon icon-delete-icon" />
                              <span className="agenda-task-control__text">{t('Delete')}</span>
                            </>
                          ),
                          onClick: () => onOpenDeleteProjectModal(prop('id', projects[item])),
                        },
                      ]),
                    ]
                  }
                />
              </PermissionElement>
            </Grid.ColData>
          </Grid.Row>
        )) : <EmptyResult text="No search results found" />}
    </Grid.Wrapper>
    <EditProjectModal selectedProjectId={selectedProjectId} />
    <DeleteProjectModal
      selectedProjectId={selectedProjectId}
      onDeleteProject={onDeleteProject}
    />
  </>
);

ProjectsAsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  projects: PropTypes.objectOf(PropTypes.any).isRequired,
  gridName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sort: PropTypes.string,
  order: PropTypes.string,
  push: PropTypes.func.isRequired,
  setProgressProject: PropTypes.func.isRequired,
  onDeleteProject: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  selectedProjectId: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Object)]),
  onSortBy: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
  }).isRequired,
  onToggleFavoriteStatus: PropTypes.func.isRequired,
  onOpenEditProjectModal: PropTypes.func.isRequired,
  onOpenDeleteProjectModal: PropTypes.func.isRequired,
  P: PropTypes.func.isRequired,
};

ProjectsAsList.defaultProps = {
  order: '',
  selectedProjectId: null,
  sort: '',
};

export default ProjectsAsList;
