import React, { useCallback, useState } from 'react';

import { ModulesItem } from './components';
import { TabWrapper } from '../../../../ui-kit';
import './style.sass';

/**
 * @param data {array}
 * @param title {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const ModulesList = ({
  data,
  title = 'Modules',
}) => {
  const [isModulesTabOpen, setIsModulesTabOpen] = useState(false);
  const changeModulesTabStatus = useCallback(() => setIsModulesTabOpen(!isModulesTabOpen));
  return (
    <div className="modules-list">
      <div className="modules-list__container">
        <TabWrapper
          onChangeStatusTab={changeModulesTabStatus}
          defaultStatusTab={isModulesTabOpen}
          title={title}
        >
          <ul className="modules-list__list">
            {
              data.map(({
                title: titleItem, asLink, href, onClick,
              }) => (
                <ModulesItem
                  key={`module-${titleItem}`}
                  title={titleItem}
                  asLink={asLink}
                  href={href}
                  onClick={onClick}
                />
              ))
            }
          </ul>
        </TabWrapper>
      </div>
    </div>
  );
};
