import {
  branch,
  compose, renderNothing, withHandlers,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isNil } from 'ramda';

import PinLeadModal from './pinLeadModal';
import { getModal } from '../../../../state/ui/selectors';
import { uiActions } from '../../../../state/ui';
import { leadsSelectors, leadsActions } from '../../../../state/leads';

const mapDispatchToProps = {
  onCloseModal: uiActions.closeModal,
  pinLead: leadsActions.pinLeadRequest,
  getPinnedLeads: leadsActions.getPinnedLeadsRequest,
};

const mapStateToProps = (state, { selectedLeadId }) => ({
  isOpen: getModal(state)('pinLeadModal'),
  lead: leadsSelectors.getLeadById(state)(selectedLeadId),
});

const onCloseHandler = ({ onCloseModal }) => () => onCloseModal('pinLeadModal');

const onPinHandler = ({
  pinLead, onClose, lead, getPinnedLeads,
}) => () => pinLead({
  leadId: lead.id,
}, {
  callbacks: {
    success: () => {
      getPinnedLeads();
      onClose();
    },
  },
});


const enhance = compose(
  branch(
    ({ selectedLeadId }) => isNil(selectedLeadId),
    renderNothing,
  ),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withHandlers({
    onClose: onCloseHandler,
  }),
  withHandlers({
    onPin: onPinHandler,
  }),
);


export default enhance(PinLeadModal);
