import React from 'react';
import { NavLink } from 'react-router-dom';
import { ButtonsCollection, Header } from '@/ui-kit';
import { DeleteNodeModal, RenameProcessModal } from '@bp/components';
import { ProcessContainer } from '@bp/containers';
import { useBusinessProcess } from './useBusinessProcess';
import './style.sass';

export const BusinessProcess = () => {
  const { selectedProcess, renameProcess } = useBusinessProcess();
  return (
    <>
      <Header className="role-header">
        <>
          <NavLink className="business-proccess__go-back" to="/business-processes/">
            <span className="icon-expand-arrow-left" title="Back to business processes" />
          </NavLink>
          <h1 className="weight--medium title--l title-page">
            Business Processes - {selectedProcess.title}
          </h1>
        </>
        <div className="btns-container">
          <ButtonsCollection.ButtonBrill
            className="button--sm header__control-button--project"
            onClick={renameProcess}
          >
            Rename
          </ButtonsCollection.ButtonBrill>
          <NavLink to="logs/" className="logs-link">
            Logs
          </NavLink>
        </div>
      </Header>
      <ProcessContainer />
      <DeleteNodeModal />
      <RenameProcessModal />
    </>
  );
};
