import { compose, withStateHandlers } from 'recompose';
import RepositoriesList from './repositoriesList';

const changeActiveTab = ({ activeTab }) => (idTab) => {
  if (idTab === activeTab) {
    return {
      activeTab: 0,
    };
  }
  return {
    activeTab: idTab,
  };
};

const enhance = compose(
  withStateHandlers(
    () => ({ activeTab: 0 }), {
      changeActiveTabStateHandler: changeActiveTab,
    },
  ),
);
export default enhance(RepositoriesList);
