import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const ProgressBar = ({ percent }) => (
  <div className="custom-progress-bar__progress-bar-container">
    <div className="custom-progress-bar__wrapper">
      <div className="custom-progress-bar__percent">{percent}</div>
      <div className="custom-progress-bar__progress-bar">
        <div className="line-percent" style={{ width: percent }} />
      </div>
      <button type="button" className="custom-progress-bar__cancel-button">Cancel</button>
    </div>
  </div>
);

ProgressBar.propTypes = {
  percent: PropTypes.string,
};

ProgressBar.defaultProps = {
  percent: '25%',
};

export default ProgressBar;
