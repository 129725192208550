import { requestReducers, errorReducers, pendingReducers } from './state';
import { apiRequest } from './state/request/actions';
import requestApi from './requestApi';
import apiMethods from './methods';

const state = {
  errors: errorReducers,
  pending: pendingReducers,
  request: requestReducers,
};

export default requestApi;

export {
  state,
  apiMethods,
  apiRequest,
};
