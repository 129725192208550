import React from 'react';
import PropTypes from 'prop-types';
import {
  CreateClientModal, EditClientModal, DeleteClientModal, PinClientModal, UnpinClientModal,
} from './modals';
import { CreateTaskModal, EditTaskModal, DeleteTaskModal } from './tasksModals';
import { Pagination, ScrollWrapper } from '../../ui-kit';
import { Filters, ClientsList } from './components';
import './style.sass';
import { EditTaskModal as EditFullTaskModal } from '../taskCRUD';
import CustomFieldsModal from '../customFields';
import { CUSTOM_FIELDS_MODELS } from '../../constants/customFields';

const ClientsContainer = ({
  order,
  sort,
  setSelectedSort,
  onSortBy,
  onSearch,
  searchName,
  setSearchClientName,
  clients,
  setRef,
  pagination,
  clientsCount,
  onPageChange,
  onSuccessCreateClient,
  selectedClientId,
  onDeleteClient,
  selectedClientTask,
  getActiveTaskSelector,
  onSuccessEditFullTask,
  selectedPinnedClientId,
  refreshPage,
  isOpen,
  setIsOpen,
}) => (<>
  <ScrollWrapper refCustom={e => setRef('scroll-wrapper', e)}>
    <div className="clients">
      <Filters
        order={order}
        sort={sort}
        onSortBy={onSortBy}
        onSearch={onSearch}
        setSelectedSort={setSelectedSort}
        searchName={searchName}
        setSearchClientName={setSearchClientName}
      />
      <ClientsList
        clients={clients}
        selectedClientId={selectedClientId}
        selectedPinnedClientId={selectedPinnedClientId}
      />
      <div className="pagination-container">
        <Pagination
          count={clientsCount}
          component="clients"
          pagination={pagination}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  </ScrollWrapper>
  <CreateClientModal onSuccess={onSuccessCreateClient} />
  <EditClientModal selectedClientid={selectedClientId} />
  <DeleteClientModal onDelete={onDeleteClient} selectedClientId={selectedClientId} />
  <PinClientModal selectedClientId={selectedClientId} />
  <UnpinClientModal selectedClientId={selectedClientId} />
  <CreateTaskModal />
  <EditTaskModal selectedClientTask={selectedClientTask} />
  <EditFullTaskModal
    getTaskSelector={getActiveTaskSelector}
    onSuccess={onSuccessEditFullTask}
    selectedTaskId={selectedClientTask}
    taskClientId={selectedClientId}
  />
  <CustomFieldsModal
    isOpen={isOpen}
    setIsOpen={setIsOpen}
    customFieldModel={CUSTOM_FIELDS_MODELS.LEAD_CLIENT}
    customCallback={refreshPage}
  />
  <DeleteTaskModal selectedClientTask={selectedClientTask} />
</>);

ClientsContainer.propTypes = {
  setSelectedSort: PropTypes.func.isRequired,
  onSortBy: PropTypes.func.isRequired,
  setSearchClientName: PropTypes.func.isRequired,
  selectedPinnedClientId: PropTypes.number,
  onSearch: PropTypes.func.isRequired,
  searchName: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  sort: PropTypes.string.isRequired,
  clients: PropTypes.instanceOf(Array).isRequired,
  setRef: PropTypes.func.isRequired,
  onSuccessCreateClient: PropTypes.func.isRequired,
  selectedClientId: PropTypes.number,
  onDeleteClient: PropTypes.func.isRequired,
  selectedClientTask: PropTypes.number,
  onSuccessEditFullTask: PropTypes.func.isRequired,
  getActiveTaskSelector: PropTypes.func.isRequired,
  refreshPage: PropTypes.func.isRequired,
};

ClientsContainer.defaultProps = {
  selectedClientTask: null,
  selectedClientId: null,
  selectedPinnedClientId: null,
};

export default ClientsContainer;
