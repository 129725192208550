import {
  compose, getContext, lifecycle, withProps, withState,
} from 'recompose';
import { equals, map } from 'ramda';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  preloaderWhileLoading,
  withAgendaColumnSwipe,
  withRefs,
} from '../../../../utils/enchancers';
import PipeView from './pipeView';
import { INVOICES_STATUSES } from '../../../../constants/crm';
import { invoicesActions, invoicesSelectors } from '../../../../state/invoices';
import { pendingSelectors } from '../../../../utils/middlewares/sagaRequestApi/state';

const mapDispatchToProps = {
  getInvoicesListRequest: invoicesActions.getInvoicesListRequest,
};

const mapStateToProps = state => ({
  invoicesList: invoicesSelectors.getInvoicesList(state),
  isPending: pendingSelectors.getPendingRequest(state, 'getInvoicesListRequest'),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRefs(),
  withState('showLoader', 'setIsShowLoader', true),
  getContext({
    queryParams: PropTypes.instanceOf(Object),
  }),
  lifecycle({
    componentDidMount() {
      const { queryParams: { startDate, endDate } } = this.props;
      this.props.getInvoicesListRequest({
        startDate, endDate, page: '', is_lifecycle: 1,
      });
    },
    componentDidUpdate({ queryParams }) {
      const {
        isPending, setIsShowLoader, showLoader,
      } = this.props;

      if (!equals(queryParams, this.props.queryParams)) {
        this.props.getInvoicesListRequest({ ...this.props.queryParams, page: '', is_lifecycle: 1 });
      }

      if (!isPending && showLoader) {
        setIsShowLoader(false);
      }
    },
  }),
  preloaderWhileLoading({
    dimension: 100,
    fullScreen: true,
    isLoading: () => false,
    onAction: ({ showLoader }) => showLoader,
  }),
  withAgendaColumnSwipe({ range: 50 }),
  withProps(() => ({
    invoiceStatuses: compose(
      map(arr => ({ id: arr[0], title: arr[1] })),
      Object.entries,
    )(INVOICES_STATUSES),
  })),
);

export default enhance(PipeView);
