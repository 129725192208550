import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';

import { FormsCollection } from '../../../../../../../../ui-kit';
import SendMessage from '../../../../../../../../components/sendMessage';

const EditableMessage = ({
  content,
  onClickSaveContent,
  onCloseEditMessage,
  membersForMention,
  setRef,
  onKeyDown,
}) => (
  <FormsCollection.FormWrapper customRef={e => setRef('editableMessage', e)} className="message-card_edit-form">
    <SendMessage
      value={ReactDOMServer.renderToString(content)}
      onSubmit={onClickSaveContent}
      members={membersForMention}
      onKeyDown={onKeyDown}
      sendLabel="Save"
      discardButton
      isEditing
      onDiscard={onCloseEditMessage}
      className="message-card__field-edit"
    />
  </FormsCollection.FormWrapper>
);

EditableMessage.propTypes = {
  setRef: PropTypes.func.isRequired,
  onClickSaveContent: PropTypes.func.isRequired,
  onCloseEditMessage: PropTypes.func.isRequired,
  membersForMention: PropTypes.instanceOf(Object).isRequired,
  content: PropTypes.string.isRequired,
  onKeyDown: PropTypes.func.isRequired,
};

export default EditableMessage;
