import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { clientsSelectors } from '../../../../../../state/clients';
import ClientsSummary from './clientsSummary';

const mapStateToProps = state => ({
  clientsCount: clientsSelectors.getClientsCount(state),
});

const enhance = compose(
  connect(mapStateToProps, null),
  withTranslation(['tasks']),
);
export default enhance(ClientsSummary);
