import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { openModal } from '@/state/ui/actions';
import { ButtonsCollection } from '@/ui-kit';
import { bpActions } from '@bp//store/bpModule';

import './style.sass';

export const ProcessCard = (process) => {
  const { id, title } = process;
  const dispatch = useDispatch();

  const onDeleteProcess = () => (e) => {
    e.preventDefault();
    dispatch(bpActions.selectBusinessProcess(process));
    dispatch(openModal('deleteProcessModal'));
  };

  return (
    <Link to={`/business-processes/${id}`} className="processCard">
      <h4 className="processCard__title">{title}</h4>
      <div className="processCard__controls-group">
        <ButtonsCollection.ButtonIcons title="Delete process" onClick={onDeleteProcess(id)}>
          <span className="icon-delete-icon" />
        </ButtonsCollection.ButtonIcons>
      </div>
    </Link>
  );
};
