import {
  take,
  fork,
  put,
} from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { sagasManager } from '../../utils';
import { messengerTypes } from '../messenger';
import { usersSchemas, usersActions, usersTypes } from '.';
import { uiActions } from '../ui';

function* addUsersFromChannelFlow() {
  while (true) {
    const { payload: { channel: { members } } } = yield take(messengerTypes.SET_CHANNEL);
    const normalizedMembers = normalize(members, usersSchemas.usersListSchema);
    yield put(usersActions.setUsersList({ data: normalizedMembers }));
  }
}

function* inviteUsersFlow() {
  while (true) {
    yield take(usersTypes.SET_USERS_INVITE_SUCCESS);
    yield put(uiActions.closeModal('inviteMembersModal'));
  }
}

function* emitUsersStatusFlow() {
  while (true) {
    const { payload } = yield take(usersTypes.EMIT_USERS_STATUS);
    yield put(usersActions.setUsersStatus(payload));
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(addUsersFromChannelFlow);
  yield fork(inviteUsersFlow);
  yield fork(emitUsersStatusFlow);
});
