import React from 'react';
import PropTypes from 'prop-types';

import { MessageElements } from '../../../../../../../../components';
import { ButtonsCollection } from '../../../../../../../../ui-kit';

const NotesItem = ({
  isOpen,
  toggleIsOpen,
  note,
  onEdit,
  onDelete,
}) => (
  <div
    className={`notes-list__table-row ${isOpen ? 'notes-list__table-row--active' : ''}`}
  >
    <div className="notes-list__table-col"><h3 className="text--cut">{note.title}</h3></div>
    <div
      className="notes-list__table-col notes-list__table-col--description"
    >
      <span>
        <MessageElements
          messageId={note.id}
        >
          {note.content}
        </MessageElements>
      </span>
    </div>
    <div className="notes-list__table-col">
      <div className="notes-list__controls-group">
        <ButtonsCollection.ButtonIcons
          className="notes-list__show-more-button"
          title={`${isOpen ? 'Hide information' : 'Show information'}`}
          onClick={() => toggleIsOpen(note.id)}
        >
          <span className="icon-down" />
        </ButtonsCollection.ButtonIcons>
        <ButtonsCollection.ButtonIcons
          className="notes-list__edit-button"
          title="Edit note"
          onClick={onEdit}
        >
          <span className="icon-edit-icon" />
        </ButtonsCollection.ButtonIcons>
        <ButtonsCollection.ButtonIcons
          className="notes-list__delete-button"
          onClick={onDelete}
          title="Delete note"
        >
          <span className="icon-delete-icon" />
        </ButtonsCollection.ButtonIcons>
      </div>
    </div>
  </div>
);
NotesItem.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleIsOpen: PropTypes.func.isRequired,
  note: PropTypes.instanceOf(Object).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
export default NotesItem;
