import { compose, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';
import MyTasksProjects from './myTasksProjects';
import { tasksSelectors } from '../../../../state/tasks';

const mapStateToProps = state => ({
  projects: tasksSelectors.getMyTasksProjects(state),
  tasksByProjectList: tasksSelectors.getTasksByProjectList(state),
});

const onToggleProjectTabHandler = ({ isOpen }) => () => ({ isOpen: !isOpen });

const enhance = compose(
  connect(mapStateToProps),
  withStateHandlers(() => ({
    isOpen: true,
  }), {
    onToggleProjectTab: onToggleProjectTabHandler,
  }),
);
export default enhance(MyTasksProjects);
