import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { contactsSelectors } from '../../../../../../state/contacts';
import ContactSummary from './contactSummary';

const mapStateToProps = state => ({
  contactsCount: contactsSelectors.getContactsCount(state),
});

const enhance = compose(
  connect(mapStateToProps, null),
  withTranslation(['tasks']),
);
export default enhance(ContactSummary);
