import {
  compose, lifecycle, withStateHandlers, withHandlers, getContext, withState,
} from 'recompose';
import {
  includes, without, concat, and, unapply, mergeAll,
} from 'ramda';
import { connect } from 'react-redux';
import { notEqual } from 'ramda-extension';
import PropTypes from 'prop-types';
import PipeLinesContainer from './pipeLinesContainer';
import { pipelinesSelectors, pipelinesActions } from '../../state/pipelines';
import { uiActions } from '../../state/ui';
import { preloaderWhileLoading, withPagination, withUrlParams } from '../../utils/enchancers';
import { calculateOffset } from '../../utils/helpers/uiComponentHelpers/pagination';

const mapStateToProps = state => ({
  pipelines: pipelinesSelectors.getPipelinesIDsSelector(state),
  isLoading: pipelinesSelectors.getPipelinesPendingRequest(state),
  pipelinesCount: pipelinesSelectors.getPipelinesCount(state),
});

const mapDispatchToProps = ({
  getPipelinesRequest: pipelinesActions.getPipelinesRequest,
  deletePipeline: pipelinesActions.deletePipelineRequest,
  closeModal: uiActions.closeModal,
});

const LIMIT = 10;

const getPipelines = ({
  getPipelinesRequest, pagination,
}, meta = {}) => compose(
  getPipelinesRequest,
  unapply(mergeAll),
)(pagination, meta);

const onPageChangeHandler = ({
  getPipelinesRequest, pagination,
}) => meta => getPipelines({
  getPipelinesRequest, pagination,
}, meta);

const onDeletePipelineHandler = ({
  deletePipeline,
  selectedPipelineId,
  closeModal,
  setSelectedPipelineId,
  pagination,
}) => () => {
  deletePipeline({ id: selectedPipelineId }, { ...pagination });
  setSelectedPipelineId(null);
  closeModal('deletePipelineModal');
};

const onPipelineAction = ({
  getPipelinesRequest, pagination,
}) => getPipelines({
  getPipelinesRequest, pagination,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(() => ({ openItemIds: [] }), ({
    onSetOpenId: ({ openItemIds }) => id => ({
      openItemIds: includes(id, openItemIds)
        ? without([id], openItemIds)
        : concat([id], openItemIds),
    }),
  })),
  withState('pagination', 'setPagination', { limit: LIMIT, offset: 0 }),
  withUrlParams({}),
  withPagination({
    limit: () => LIMIT,
    offset: ({ getUrlParam }) => calculateOffset(getUrlParam(['page'], 1), LIMIT),
    page: ({ getUrlParam }) => getUrlParam(['page'], 1),
  }),
  getContext({
    setSelectedPipelineId: PropTypes.func.isRequired,
  }),
  withHandlers({
    onPageChange: onPageChangeHandler,
    onDeletePipeline: onDeletePipelineHandler,
    onPipelineAction,
  }),
  lifecycle({
    componentDidMount() {
      getPipelines(this.props);
    },
    componentDidUpdate(prevProps) {
      const { isNeedRefresh: prevIsNeedRefresh } = prevProps;
      const { isNeedRefresh, setIsNeedRefresh } = this.props;
      if (and(notEqual(prevIsNeedRefresh, isNeedRefresh), isNeedRefresh)) {
        setIsNeedRefresh(false);
      }
    },
  }),
  preloaderWhileLoading({
    dimension: 100,
    fullScreen: true,
    isLoading: props => !props.isLoading,
  }),
);

export default enhance(PipeLinesContainer);
