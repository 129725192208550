import api from '../services/api.service';

const extractData = (res) => {
  const { data: { result } } = res;
  return result;
};

const schema = {
  createSettingUserRequest: async (params = {}) => {
    try {
      const response = await api.post('/users', params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  getSettingUserRequest: async (params = {}) => {
    try {
      const response = await api.get(`/users/${params}`);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  updateSettingUserRequest: async (params = {}) => {
    try {
      const response = await api.put(`/users/${params.id}`, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  getCompanyBillingInfoRequest: async (params = {}) => {
    try {
      const response = await api.get('/billing-info/company', params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  updateCompanyBillingInfoRequest: async (params = {}) => {
    try {
      const response = await api.put('/billing-info/company', params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  changeCompanyLogoRequest: async (params = {}) => {
    try {
      const response = await api.put('/billing-info/company-logo', params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
};

export default schema;
