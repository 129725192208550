import { curry } from 'ramda';

const subscribeOnEvent = curry((element,
  event, callback) => element.addEventListener(event, callback));

const unSubscribeFromEvent = curry((element,
  event, callback) => element.removeEventListener(event, callback));


export {
  subscribeOnEvent,
  unSubscribeFromEvent,
};
