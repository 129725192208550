import React from 'react';

import ModalView from '../modalView';

const CreateLeadModal = props => (
  <>
    <ModalView
      title="Create new lead"
      titleAction="Create"
      {...props}
    />
  </>
);

export default CreateLeadModal;
