import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  ButtonsCollection, MobileFiltersContainer, DropDown, FormsCollection,
} from '../../../../ui-kit';
import { INVOICES_VIEW } from '../../../../constants/crm';
import { DROPDOWN_SORT_INVOICES_LIST } from './constants';
import './style.sass';
import { WINDOW_WIDTH } from '../../../../constants/ui';

const FiltersMobile = ({
  onSetView, isPipeView, t, invoiceStatusesOptions, setFilter, initialDate,
  getStatusById, queryParams, getOrderByValue, onSearch, areMobileFiltersOpen,
  toggleMobileFiltersHandler,
}) => (
  <div className="invoices__filters">
    <div className="invoices__filters-col">
      <FormsCollection.Search
        className="search-field--circle"
        placeholder={t('Search by client\'s name or project')}
        name="search-lead"
        value={queryParams.search}
        onChange={({ target: { value } }) => setFilter(value, 'search')}
        onSearch={onSearch}
        withoutControl
      />
    </div>
    <MobileFiltersContainer
      title="Filter"
      onWidth={WINDOW_WIDTH.SMALL}
      isOpen={areMobileFiltersOpen}
      onToggle={toggleMobileFiltersHandler}
    >
      <div className={`invoices__filters-row ${isPipeView ? 'invoices__filters-row--pipeline-view' : ''}`}>
        <div className="invoices__filters-col mobile-indent">
          <ButtonsCollection.ButtonBrill
            className={`button--fill-grey invoices__filters-btn-tab${!isPipeView ? ' active' : ''}`}
            onClick={onSetView(INVOICES_VIEW.LIST)}
          >
            <span className="invoices__filters-icon-list icon-list-stroke" />
            {t('List view')}
          </ButtonsCollection.ButtonBrill>
          <ButtonsCollection.ButtonBrill
            className={`invoices__filters-btn-tab${isPipeView ? ' active' : ''}`}
            onClick={onSetView(INVOICES_VIEW.PIPE)}
          >
            <span className="invoices__filters-icon-pipe icon-list-fill" />
            {t('Lifecycle view')}
          </ButtonsCollection.ButtonBrill>
        </div>
        <div className="invoices__filters-col mobile-indent">
          {
              !isPipeView ? (
                <>
                  <DropDown
                    label={`${t('Status')} : `}
                    name="status"
                    placeholder={getStatusById(queryParams.status_id).label}
                    currentItem={getStatusById(queryParams.status_id)}
                    onChange={status => setFilter(status.val.value, 'status_id')}
                    list={invoiceStatusesOptions}
                  />
                  <DropDown
                    label={`${t('Sort')} : `}
                    name="sorting-filter"
                    placeholder={getOrderByValue(queryParams.order).label}
                    currentItem={getOrderByValue(queryParams.order)}
                    onChange={order => setFilter(order.val.value, 'order')}
                    list={DROPDOWN_SORT_INVOICES_LIST}
                  />
                </>
              ) : null
            }
        </div>
        <div className="invoices__filters-col mobile-indent">
          <div className="invoices-filter-fields__date-picker">
            <div className="invoices-filter-fields__date-picker-label">

                From
            </div>
            <FormsCollection.DatePicker
              isNotUseTimeZone
              onChange={({ target: { value } }) => {
                setFilter(moment(value).format('YYYY-MM-DD'), 'startDate');
              }}
              value={queryParams.startDate || initialDate.startDate}
            />
          </div>
        </div>
        <div className="invoices__filters-col mobile-indent">
          <div className="invoices-filter-fields__date-picker">
            <div className="invoices-filter-fields__date-picker-label">

                To
            </div>
            <FormsCollection.DatePicker
              isNotUseTimeZone
              onChange={({ target: { value } }) => {
                setFilter(moment(value).format('YYYY-MM-DD'), 'endDate');
              }}
              value={queryParams.endDate || initialDate.endDate}
              minDate={moment(queryParams.startDate).toDate()}
            />
          </div>
        </div>
      </div>
    </MobileFiltersContainer>
  </div>
);

FiltersMobile.propTypes = {
  t: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  getStatusById: PropTypes.func.isRequired,
  queryParams: PropTypes.instanceOf(Object).isRequired,
  onSetView: PropTypes.func.isRequired,
  invoiceStatusesOptions: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  isPipeView: PropTypes.bool.isRequired,
  getOrderByValue: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  initialDate: PropTypes.instanceOf(Object).isRequired,
  areMobileFiltersOpen: PropTypes.bool.isRequired,
  toggleMobileFiltersHandler: PropTypes.func.isRequired,
};

export default FiltersMobile;
