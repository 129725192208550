import React from 'react';
import PropTypes from 'prop-types';
import { propOr, prop } from 'ramda';
import moment from 'moment';

import { ButtonsCollection, ModalWindow, FormsCollection } from '../../../../ui-kit';
import './style.sass';

const ModalView = ({
  isOpen,
  isPending,
  onCloseModal,
  handleChange,
  handleBlur,
  handleSubmit,
  values,
  errors,
  getValidFinishDate,
  onChangeStartDate,
  title,
  titleAction,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    modalName="pinMessage"
    title={title}
    className="new-sprint__modal"
  >
    <FormsCollection.FormWrapper
      handleSubmit={handleSubmit}
    >
      <FormsCollection.Input
        placeholderJump="Sprint title"
        name="title"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.title}
        touched
        error={propOr('', 'message', errors.title)}
        className="new-sprint__title-input"
        id="new-sprint__title"
      />
      <div className="new-sprint__row--textArea">
        <FormsCollection.TextArea
          placeholderJump="Description"
          className="new-sprint__textarea"
          name="description"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.description}
          error={prop('message', errors.description)}
          touched
          autoHeight
          maxHeight={100}
        />
      </div>
      <div className="new-sprint__date-fields-wrapper new-sprint__date-wrapper--start flex-row">
        <div className="date-field-label">
          <label htmlFor="date-picker-start__new-sprint-modal">Start</label>
        </div>
        <div className="new-sprint__inputs-wrapper">
          <FormsCollection.DatePicker
            className="date-picker"
            name="startDate"
            onChange={onChangeStartDate}
            onBlur={handleBlur}
            value={values.startDate}
            touched
            error={prop('message', errors.startDate)}
            id="date-picker-start__new-sprint-modal"
          />
          <FormsCollection.TimePicker
            className="time-picker"
            name="startTime"
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!values.startDate}
            touched
            value={values.startTime}
          />
        </div>
      </div>
      <div className="new-sprint__date-fields-wrapper new-sprint__date-wrapper--finish flex-row">
        <div className="date-field-label">
          <label htmlFor="date-picker-finish__new-sprint-modal">Finish</label>
        </div>
        <div className="new-sprint__inputs-wrapper">
          <FormsCollection.DatePicker
            className="date-picker"
            name="finishDate"
            onChange={handleChange}
            onBlur={handleBlur}
            touched
            error={prop('message', errors.finishDate)}
            value={getValidFinishDate()}
            minDate={moment(values.startDate).add(1, 'day').toDate()}
            id="date-picker-finish__new-sprint-modal"
          />
          <FormsCollection.TimePicker
            className="time-picker"
            name="finishTime"
            disabled={!values.finishDate}
            onChange={handleChange}
            onBlur={handleBlur}
            touched
            value={values.finishTime}
          />
        </div>
      </div>
      <div className="button-group">
        <ButtonsCollection.ButtonBrill
          pending={isPending}
          className="button--md new-sprint__button-save button-brill--fill"
          type="submit"
        >
          {titleAction}
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);

ModalView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  getValidFinishDate: PropTypes.func.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  titleAction: PropTypes.string.isRequired,
};


export default ModalView;
