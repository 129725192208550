import {
  compose, getContext, withHandlers, withState,
} from 'recompose';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { pathOr } from 'ramda';
import { withRouter } from 'react-router';
import TaskAbout from './taskAbout';
import { withTasksCRUDHandlers, withWindowWidth } from '../../../../utils/enchancers';
import { projectSelectors } from '../../../../state/project';
import { withPermissionHandlers } from '../../../../utils/helpers/permissionHelpers';

const onEditHandler = ({
  onEditTaskModal, task: { id }, sort, order,
}) => () => {
  onEditTaskModal(id, { sort, order }, {
    isSetActiveTask: true,
  });
};

const mapStateToProps = (state, { task: { id } }) => ({
  isTaskPin: pathOr(false, [id, 'id'], projectSelectors.getPinnedTasksEntities(state)),
});

const onDeleteHandler = ({
  onDeleteTaskModal, task: { id }, sort, order,
}) => () => {
  onDeleteTaskModal(id, { sort, order }, {
    isRedirectToProject: true,
  });
};

const onPinHandler = ({
  onPinTask, match: { params: { id: projectId } }, task: { id: taskId },
}) => () => {
  onPinTask({ taskId, projectId });
};

const onUnpinHandler = ({
  onUnpinTask, match: { params: { id: projectId } }, task: { id: taskId },
}) => () => {
  onUnpinTask({ taskId, projectId });
};

const enhance = compose(
  connect(mapStateToProps),
  withWindowWidth(),
  withTasksCRUDHandlers,
  withRouter,
  withTranslation(['tasks', 'common']),
  withState('isShowMore', 'setIsShowMore', false),
  getContext({
    selectedTaskId: PropTypes.number,
    onDeleteTaskModal: PropTypes.func,
    onEditTaskModal: PropTypes.func,
    isRedirectToProject: PropTypes.bool,
  }),
  withHandlers({
    onEdit: onEditHandler,
    onDelete: onDeleteHandler,
    onPin: onPinHandler,
    onUnpin: onUnpinHandler,
  }),
  withPermissionHandlers,
);
export default enhance(TaskAbout);
