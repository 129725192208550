import React from 'react';

import { Route, Switch } from 'react-router';
import './style.sass';
import { EditPersonForm, InvitePersonForm } from '../../ui-kit';
import { PEOPLE_PAGES } from '../../api/routes';

const PersonContainer = () => (
  <Switch>
    <Route path={PEOPLE_PAGES.PERSON.INVITE} component={InvitePersonForm} />
    <Route path={PEOPLE_PAGES.PERSON.EDIT} component={EditPersonForm} />
  </Switch>
);

export default PersonContainer;
