import {
  compose,
} from 'recompose';

import ProjectsBrill from './projectsBrill';
import { preloaderWhileLoading } from '../../../../utils/enchancers';
import withBrillMaker from '../../../../utils/enchancers/withBrillsBuilder';

const enhance = compose(
  preloaderWhileLoading({
    dimension: 100,
    fullScreen: true,
    isLoading: props => !props.isLoading,
  }),
  withBrillMaker('refToBrillBuilder'),
);


export default enhance(ProjectsBrill);
