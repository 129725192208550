import React, {
  forwardRef, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';


import { ProgressBar } from '../progressBar';
import { ButtonsCollection } from '../../../index';
import { PRELOADER_DIMENSION, WINDOW_WIDTH } from '../../../../constants/ui';
import preloaderWhileLoading from '../../../../utils/enchancers/preloaderWhileLoading';
import './style.sass';

const DELAY = 600;

const filesFieldContent = (pending, windowWidth, t) => {
  if (windowWidth > WINDOW_WIDTH.X_SMALL) {
    if (pending) {
      return <ProgressBar />;
    }
    return <label htmlFor="files-field__input" className="files-field__label">{t('Drop file here or click to upload')}</label>;
  }
  return (
    <ButtonsCollection.ButtonBrill type="button" className="button--md files-field__upload-more">
      <label htmlFor="files-field__input" className="files-field__label-button">
        {t('Upload more')}
      </label>
    </ButtonsCollection.ButtonBrill>
  );
};

/**
 *
 * @param pending {boolean}
 * @param onChange {function}
 * @param availableFormats {array}
 * @returns {JSX.Element}
 * @constructor
 */

export const FilesField = preloaderWhileLoading({
  dimension: PRELOADER_DIMENSION.MIDDLE,
  alignContainerCenter: true,
  delay: DELAY,
  isLoading: () => false,
  onAction: ({ isPending }) => isPending,
})(forwardRef((props, ref) => {
  const {
    pending = true, onChange, availableFormats,
  } = props;
  const { t } = useTranslation('common');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
    return (
      window.removeEventListener('resize', () => setWindowWidth(window.innerWidth))
    );
  }, []);

  return (
    <div className="files-field">
      <input
        ref={ref}
        type="file"
        className="files-field__input"
        id="files-field__input"
        multiple
        accept={availableFormats.join(',')}
        onChange={onChange}
      />
      {
      filesFieldContent(pending, windowWidth, t)
    }
    </div>
  );
}));
