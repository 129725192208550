const UNITS = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const NO_BYTES = '0 Bytes';
const MAX_MEMORY_ADDRESSES = 1024;

export const normalizeSize = (bytes, decimals = 2) => {
  if (bytes === 0) return NO_BYTES;

  const dm = decimals < 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bytes) / Math.log(MAX_MEMORY_ADDRESSES));
  // eslint-disable-next-line no-restricted-properties
  const normalizedNumber = parseFloat((bytes / Math.pow(MAX_MEMORY_ADDRESSES, i)).toFixed(dm));

  return `${normalizedNumber}${' '}${UNITS[i]}`;
};
