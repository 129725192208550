import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';
import {
  Avatar, BrillMask, ButtonsCollection, DropDown,
} from '../../../../ui-kit';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { ClientTasksList, ClientTab } from '..';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import ClientItemMobile from './clientsItemMobile';

const ClientsItem = ({
  t,
  isOpen,
  onToggleClient,
  setRef,
  client,
  companyUrl,
  getContactField,
  setSelectedClientId,
  onCreateTaskModal,
  onEditTaskModal,
  onEditFullTaskModal,
  onDeleteTaskModal,
  onEditClientModal,
  onDeleteClientModal,
  onPinClientModal,
  onUnpinClientModal,
  isPinned,
  windowWidth,
}) => (
  <>
    <div
      className="clients-item"
      ref={e => setRef('active-client', e)}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div tabIndex="-1" role="tab" className="clients-item__row" id={`clientId-${client.id}`} onClick={onToggleClient}>
        <div className="clients-item__col clients-item__avatar">
          <BrillMask>
            <Avatar src={getImageUrl(client.contact.avatar)} alt={getFullName(client.contact)} />
          </BrillMask>
        </div>
        <div className="clients-item__col clients-item__username text--cut">
          <span className="text--cut">{getFullName(client.contact)}</span>
        </div>
        <div className="clients-item__col clients-item__position text--cut">
          <span className="text--cut">{getContactField('position') || t('N/A')}</span>
        </div>
        <div className="clients-item__col clients-item__company contacts__table-item-related text--cut">
          {
            companyUrl ? (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a href={companyUrl} target="_blank" className="text--cut">{getContactField('company_name')}</a>
            ) : (
              <span className="text--cut">{getContactField('company_name') || t('N/A')}</span>
            )
          }
        </div>
        <div className="clients-item__col clients-item__email text--cut">
          <span className="text--cut">
            {getContactField('email') || t('N/A')}
          </span>
        </div>
        <div className="clients-item__col clients-item__email text--cut">
          <span className="text--cut">
            {getContactField('phone') || t('N/A')}
          </span>
        </div>
        <div className="clients-item__col clients-item__service text--cut">
          <span className="text--cut">
            {getContactField('service') || t('N/A')}
          </span>
        </div>
        <div className="clients-item__col clients-item__control">
          <DropDown
            className="controls-group-task"
            name="controls-group-task"
            label={(
              <ButtonsCollection.ButtonIcons
                className="clients-item__controls-button"
                title="Controls for client"
              >
                <span className="clients-item__control-icon icon-settings-vertical">
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                </span>
              </ButtonsCollection.ButtonIcons>
            )}
            list={
              [
                {
                  id: 0,
                  onClick: () => {
                    onEditClientModal();
                    setSelectedClientId(client.id);
                  },
                  label: (
                    <>
                      <span className="controls-group-task__icon icon-pencil-2" />
                      <span className="controls-group-task__text">{t('Edit')}</span>
                    </>
                  ),
                },
                {
                  id: 1,
                  onClick: () => {
                    // eslint-disable-next-line no-unused-expressions
                    isPinned ? onUnpinClientModal() : onPinClientModal();
                    setSelectedClientId(client.id);
                  },
                  label: (
                    <>
                      <span className="controls-group-task__icon icon-pin" />
                      <span className="controls-group-task__text">
                        {t(isPinned ? 'Unpin' : 'Pin')}
                      </span>
                    </>
                  ),
                },
                {
                  id: 2,
                  onClick: () => {
                    onDeleteClientModal();
                    setSelectedClientId(client.id);
                  },
                  label: (
                    <>
                      <span className="controls-group-task__icon icon-delete-icon" />
                      <span className="controls-group-task__text">{t('Delete')}</span>
                    </>
                  ),
                },
              ]
            }
          />
        </div>
      </div>
      {
        windowWidth > WINDOW_WIDTH.SMALL && (
          isOpen && (
            <div className="clients-item__content" ref={e => setRef('active-client-content', e)}>
              <ClientTasksList
                clientId={client.id}
                t={t}
                onCreateTaskModal={onCreateTaskModal}
                onDeleteTaskModal={onDeleteTaskModal}
                onEditTaskModal={onEditTaskModal}
                onEditFullTaskModal={onEditFullTaskModal}
              />
              <ClientTab client={client} />
            </div>
          )
        )
      }
    </div>
    {
      windowWidth < WINDOW_WIDTH.SMALL && (
        isOpen && (
          <ClientItemMobile
            onEditFullTaskModal={onEditFullTaskModal}
            t={t}
            clientId={client.id}
            onDeleteTaskModal={onDeleteTaskModal}
            setRef={setRef}
            onEditTaskModal={onEditTaskModal}
            onCreateTaskModal={onCreateTaskModal}
            client={client}
          />
        )
      )
     }
  </>
);

ClientsItem.propTypes = {
  onToggleClient: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  companyUrl: PropTypes.string,
  getContactField: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  onEditClientModal: PropTypes.func.isRequired,
  setSelectedClientId: PropTypes.func.isRequired,
  onDeleteClientModal: PropTypes.func.isRequired,
  isPinned: PropTypes.bool.isRequired,
  onPinClientModal: PropTypes.func.isRequired,
  onUnpinClientModal: PropTypes.func.isRequired,
  onCreateTaskModal: PropTypes.func.isRequired,
  onEditTaskModal: PropTypes.func.isRequired,
  onDeleteTaskModal: PropTypes.func.isRequired,
  onEditFullTaskModal: PropTypes.func.isRequired,
};

ClientsItem.defaultProps = {
  companyUrl: null,
};

export default ClientsItem;
