import React from 'react';
import PropTypes from 'prop-types';

import { CommentItem } from '..';
import { ButtonsCollection } from '../../../../../../ui-kit';
import './style.sass';

const CommentsHistory = ({
  isPendingShowMore,
  isTaskHasMoreComments,
  commentsList,
  onSetMoreComments,
}) => (
  <div
    className={`task-comments__comments-items ${
      commentsList.length ? '' : 'task-comments__comments-items_empty'
    }`}
  >
    {isTaskHasMoreComments && (
      <div className="task-comments__load-more">
        <ButtonsCollection.ButtonBrill
          onClick={onSetMoreComments}
          pending={isPendingShowMore}
          type="button"
          className="button__load-more button-brill--fill"
        >

          Show 5 older comments
        </ButtonsCollection.ButtonBrill>
      </div>
    )}
    {commentsList.length ? (
      commentsList.map(commentId => (
        <CommentItem commentId={commentId} key={commentId} />
      ))
    ) : (
      <div className="task-comments__empty">There are no comments yet</div>
    )}
  </div>
);

CommentsHistory.propTypes = {
  commentsList: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSetMoreComments: PropTypes.func.isRequired,
  isTaskHasMoreComments: PropTypes.bool.isRequired,
  isPendingShowMore: PropTypes.bool.isRequired,
};

export default CommentsHistory;
