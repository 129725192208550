import {
  compose, withHandlers, withProps, withState, getContext,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withWindowWidth, withSprintsCRUDHandlers } from '../../../../utils/enchancers';
import BoardHeader from './boardHeader';
import { projectSelectors } from '../../../../state/project';
import { uiActions } from '../../../../state/ui';
import { getTimeStringBySeconds } from '../../../../utils/helpers/dateHelpers';

const mapStateTopProps = (state, { sprintId }) => ({
  sprint: projectSelectors.getProjectSprint(state)(sprintId),
});

const mapDispatchToProps = ({
  setOpenModal: uiActions.openModal,
});

const onEditHandler = ({ sprintId, onSprintModal, setSelectedSprintId }) => () => onSprintModal({ sprintId, setSelectedSprintId, modalName: 'editSprintModal' });

const onMakeDeleteModalHandler = ({
  sprintId, setSelectedSprintId, onSprintModal, sprint, setSprintCRUDMeta,
}) => modalName => onSprintModal({
  setSelectedSprintId,
  setSprintCRUDMeta,
  sprintId,
  modalName,
  isAgenda: true,
  sprint,
});
const onCompleteHandler = ({
  onMakeDeleteModal,
}) => () => onMakeDeleteModal('completeSprintModal');

const onDeleteHandler = ({
  onMakeDeleteModal,
}) => () => onMakeDeleteModal('deleteSprintModal');

const onStartHandler = ({
  onMakeDeleteModal,
}) => () => onMakeDeleteModal('startSprintModal');

const enhance = compose(
  connect(mapStateTopProps, mapDispatchToProps),
  withWindowWidth(),
  withState('isShowMore', 'setIsShowMore', false),
  getContext({
    setSelectedSprintId: PropTypes.func.isRequired,
    setSprintCRUDMeta: PropTypes.func.isRequired,
  }),
  withSprintsCRUDHandlers,
  withHandlers({
    onMakeDeleteModal: onMakeDeleteModalHandler,
  }),
  withHandlers({
    onComplete: onCompleteHandler,
    onEdit: onEditHandler,
    onDelete: onDeleteHandler,
    onStart: onStartHandler,
  }),
  withProps(({ sprint }) => ({
    estimatedTime: getTimeStringBySeconds(sprint.totalSpentTime),
    spentTime: getTimeStringBySeconds(sprint.totalEstimatedTime),
  })),
);
export default enhance(BoardHeader);
