import React from 'react';

import ModalView from '../modalView';

const CreatePipelineModal = ({ onCloseModal, ...props }) => (
  <ModalView
    title="Create new pipeline"
    titleAction="Create"
    onCloseModal={onCloseModal}
    {...props}
  />
);

export default CreatePipelineModal;
