
import { useContext } from 'react';
import { GitlabProjectsContext } from './context';

export const useGitlabProjectsContext = () => {
  const context = useContext(GitlabProjectsContext);
  if (!context) {
    throw new Error('useGitlabProjectsContext must be used within a ProjectNotesProvider.');
  }
  return context;
};
