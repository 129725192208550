export const ERRORS_API = {
  FORBIDDEN: 'Forbidden',
  NOT_FOUND: 'Not found',
  PAYLOAD_TOO_LARGE: 'Payload Too Large',
};

export const ERRORS_CODE = {
  404: ERRORS_API.NOT_FOUND,
  400: ERRORS_API.FORBIDDEN,
  413: ERRORS_API.PAYLOAD_TOO_LARGE,
};

export const ERROR_CODES_API = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
};
