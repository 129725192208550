import {
  compose, getContext,
} from 'recompose';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import ProjectItem from './projectItem';
import { projectsSelectors } from '../../../../../../state/projects';

const mapStateToProps = state => ({
  projects: projectsSelectors.getProjects(state),
});

const enhance = compose(
  connect(mapStateToProps, null),
  getContext({
    onToggleFavoriteStatus: PropTypes.func.isRequired,
  }),
);
export default enhance(ProjectItem);
