import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import {
  ConfirmModal,
} from '../../../../ui-kit';
import { getFullName } from '../../../../utils/helpers/userHelpers';

const DeleteClientModal = ({
  isOpen,
  onClose,
  client,
  onDelete,
  isPending,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Delete"
    onAction={onDelete}
    onClose={onClose}
    title="Delete client"
  >
    Are you sure you want to delete
    <b>{getFullName(propOr({}, 'contact', client))}</b>
    client?
  </ConfirmModal>
);

DeleteClientModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object),
  onDelete: PropTypes.func.isRequired,
};

DeleteClientModal.defaultProps = {
  client: null,
  isPending: null,
};

export default DeleteClientModal;
