import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Header } from '@/ui-kit';
import { LogsContainer } from '@bp/containers';
import { bpSelectors } from '@bp/store/bpModule';

export const ProcessLogs = () => {
  const selectedProcess = useSelector(bpSelectors.getBusinessProcessSelected);

  return (
    <>
      <Header className="role-header" hideToggleRightSideBar>
        <>
          <NavLink className="business-proccess__go-back" to="/business-processes/">
            <span className="icon-expand-arrow-left" title="Back to business processes" />
          </NavLink>
          <h1 className="weight--medium title--l title-page">
            Logs - {selectedProcess?.title ?? ''}
          </h1>
        </>
      </Header>
      <LogsContainer />
    </>
  );
};
