import {
  branch, compose, renderNothing, withProps,
} from 'recompose';
import { prop, map } from 'ramda';
import { connect } from 'react-redux';
import moment from 'moment';

import Summary from './summary';
import { formatDate } from '../../../../utils/helpers/commonHelpers';
import { PRIORITY, STATUS, STATUSES_BY_ID } from '../../../../constants/tasks';
import withUsersAvatars from '../../../../utils/enchancers/withUserAvatars';
import { getArrayFromEntities, makeObjectWithAvatars } from '../../../../utils/helpers/uiHelpers';
import { usersSelectors } from '../../../../state/users';
import { withUserProfile } from '../../../../utils/enchancers';
import { getTaskProgress } from '../../../../utils/helpers/taskHelpers/common';
import { projectSelectors } from '../../../../state/project';

const makeAvatars = compose(
  map(makeObjectWithAvatars),
);

const mapStateToProps = state => ({
  users: usersSelectors.getUsersEntities(state),
  statuses: projectSelectors.getStatusesOfTasksEntities(state),
});

const getUsersEntities = (list, entities) => map(id => entities[id], list);
const enhance = compose(
  branch(({ task }) => !task.title, renderNothing),
  connect(mapStateToProps),
  withUserProfile,
  withProps(({ task, users, statuses }) => {
    const deadline = task.deadline ? formatDate(task.deadline) : '-';
    const taskStatus = task.client_id ? STATUS[STATUSES_BY_ID[task.status_id]] : prop(prop('status_id', task), statuses);

    return {
      deadline,
      started: formatDate(task.created_at),
      isDeadlineToday: moment(task.deadline).startOf('day').valueOf() <= moment().startOf('day').valueOf(),
      watchers: getUsersEntities(task.watchers, users),
      author: users[task.author],
      assigneeUser: users[task.assigneeUser],
      priority: getArrayFromEntities(PRIORITY)[task.priority].label,
      done: getTaskProgress(task),
      status: taskStatus || {},
    };
  }),
  withUsersAvatars({
    countDisplay: 6,
    users: ({ watchers }) => makeAvatars(watchers),
    selectedAvatars: ({ selectedUsers }) => selectedUsers,
    onClick: ({ onSetUserProfile }) => onSetUserProfile,
    updatedByProps: 'watchers',
  }),
);
export default enhance(Summary);
