import React from 'react';
import PropTypes from 'prop-types';
import { renderTaskLog } from './historyLogRenders';
import { BrillMask, Avatar } from '../../../../ui-kit';
import './style.sass';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { formatDate } from '../../../../utils/helpers/commonHelpers';
import { WINDOW_WIDTH } from '../../../../constants/ui';

const History = ({ logs, t, windowWidth }) => (
  <div className="history">
    <ul className="history__list">
      {
        Object.keys(logs).map((logData) => {
          const logItems = logs[logData];
          return (
            <li className="history__item" key={`history-item-${logData}`}>
              <span className="history__time-line">
                {logData}
              </span>
              <ul className="history__list">
                {
                  logItems.map(({
                    user,
                    created_at,
                    logs: fieldLogs,
                    id,
                    // eslint-disable-next-line array-callback-return,consistent-return
                  }) => fieldLogs.map((log) => {
                    if (log) {
                      return user ? (
                        <li className="history__item" key={`history-item-${id}`}>
                          { windowWidth > WINDOW_WIDTH.SMALL ? (
                            <>
                              <div className="history__user">
                                <BrillMask className="history__user-icon" asLink to="/">
                                  <Avatar src={getImageUrl(user.avatar)} alt={getFullName(user)} />
                                </BrillMask>
                                <b className="history__user-name">
                                  {getFullName(user)}
                                </b>
                              </div>
                              {
                                renderTaskLog && renderTaskLog(log, t)
                              }
                              <span className="history__time">{formatDate(created_at, 'h:mm:ss A')}</span>
                            </>
                          ) : (
                            <>
                              <div className="history__mobile-block">
                                <div className="history__user">
                                  <BrillMask className="history__user-icon" asLink to="/">
                                    {/* eslint-disable-next-line max-len */}
                                    <Avatar src={getImageUrl(user.avatar)} alt={getFullName(user)} />
                                  </BrillMask>
                                  <b className="history__user-name">
                                    {getFullName(user)}
                                  </b>
                                </div>
                                <span className="history__time">{formatDate(created_at, 'h:mm:ss A')}</span>
                              </div>
                              <div className="history__title text--cut">
                                {renderTaskLog && renderTaskLog(log, t)}
                              </div>
                            </>
                          )}
                        </li>
                      ) : (
                        <li>
                          {
                            renderTaskLog && renderTaskLog(log, t)
                          }
                          <span className="history__time">{formatDate(created_at, 'h:mm:ss A')}</span>
                        </li>
                      );
                    }
                  }))
                }
              </ul>
            </li>
          );
        })
      }
    </ul>
  </div>
);

History.propTypes = {
  logs: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

export default History;
