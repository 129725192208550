import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useProjectNotesContext } from '../../context/useProjectNotesContext';
import rules from '../rules';

import { ButtonsCollection, ModalWindow, FormsCollection } from '../../..';
import { uiActions, uiSelectors } from '../../../../state/ui';

import api from '../../../../api/projects.service';
import './style.sass';

const MAX_TEXTAREA_HEIGHT = 190;

export const CreateNote = () => {
  const { id: projectId } = useParams();
  const dispatch = useDispatch();
  const { setNotes } = useProjectNotesContext();
  const [isPending, setIsPending] = useState(false);
  const { t } = useTranslation(['common']);
  const {
    control, handleSubmit, reset,
  } = useForm({
    resolver: yupResolver(rules),
    defaultValues: {
      title: '',
      content: '',
    },
  });

  const isOpen = useSelector(state => uiSelectors.getModal(state)('createNoteModal'));
  const closeModal = useCallback(() => {
    dispatch(uiActions.closeModal('createNoteModal'));
  }, []);

  const addNote = useCallback(
    async (params) => {
      try {
        setIsPending(true);
        const newNote = await api.addProjectNotes(projectId, params);
        return newNote;
      } catch (error) {
        return error;
      } finally {
        setIsPending(false);
      }
    },
    [projectId],
  );

  const onCloseModal = () => {
    reset({});
    closeModal();
  };

  const handleCreateNote = async (data) => {
    const { document } = await addNote(data);
    setNotes(prevState => [document, ...prevState]);
    onCloseModal();
  };

  return (
    <ModalWindow
      isOpen={isOpen}
      onClose={onCloseModal}
      className="crud-notes__modal"
      title={t('Create new note')}
    >
      <FormsCollection.FormWrapper
        handleSubmit={handleSubmit(handleCreateNote)}
        className="crud-notes"
      >
        <div className="crud-notes__row">
          <Controller
            name="title"
            control={control}
            render={({
              field: { onChange, value, onBlur }, fieldState: { error },
            }) => (
              <FormsCollection.Input
                placeholderJump="Note title"
                className="crud-notes__title-field"
                id="crud-notes__title"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                touched
                error={error?.message}
              />)}
          />
        </div>
        <div className="crud-notes__row crud-notes__row--description">
          <Controller
            name="content"
            control={control}
            render={({
              field: { onChange, value, onBlur }, fieldState: { error },
            }) => (
              <FormsCollection.TextArea
                name="content"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                touched
                className="crud-notes__textarea"
                placeholderJump="Description"
                autoHeight
                maxHeight={MAX_TEXTAREA_HEIGHT}
                error={error?.message}
              />)}
          />
        </div>
        <div className="button-group">
          <ButtonsCollection.ButtonBrill
            pending={isPending}
            type="submit"
            className="button--md crud-notes__button-save button-brill--fill"
          >
            {t('Create')}
          </ButtonsCollection.ButtonBrill>
        </div>
      </FormsCollection.FormWrapper>
    </ModalWindow>);
};
