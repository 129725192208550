import PropTypes from 'prop-types';

import { compose, pure, withProps } from 'recompose';
import React from 'react';

const WithPagination = ({ limit, offset, page }) => (BaseComponent) => {
  const WrapperComponent = props => <BaseComponent {...props} />;
  const enhancer = compose(
    pure,
    withProps(props => ({
      pagination: ({
        limit: limit(props),
        offset: offset(props),
        page: page(props),
      }),
    })),
  );
  return enhancer(WrapperComponent);
};

WithPagination.propTypes = {
  sortBy: PropTypes.func.isRequired,
};

export default WithPagination;
