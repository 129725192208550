import { schema } from 'normalizr';


const sprintsSchema = [new schema.Entity('sprints')];
const projectsSchema = [new schema.Entity('projects')];
const categoriesSchema = [new schema.Entity('categories')];
const statusesSchema = [new schema.Entity('statuses')];

export {
  sprintsSchema,
  projectsSchema,
  categoriesSchema,
  statusesSchema,
};
