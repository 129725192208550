import {
  add,
  always,
  compose,
  concat,
  cond,
  curry,
  equals,
  filter,
  find,
  inc,
  isNil,
  length,
  not,
  prop,
  propEq,
  slice,
  T,
  tap,
  trim,
} from 'ramda';
import { Either, Maybe } from 'ramda-fantasy';

import {
  setCaretFocusByOffset,
  setFocusAfterNodeForMention,
} from '../uiComponentHelpers/caretHelpers';
import { changedSiblingIfExist, concatTextWithSibling } from '../DOMHelper/node';
import {
  checkIsWordValidAndConcat,
  concatWithPreviousSibling,
  removeNode,
} from '../uiComponentHelpers/DOMhelpers';
import {
  checkIfFilterOrAppend,
  isMentionIncludes,
  isParentNodeMention,
  isTextNotEmpty,
  mentionPosition,
} from './lookup';
import {
  curryRegexMatch,
  curryRegexReplace,
  curryRegexTest,
  regexRules,
  replaceEachNode,
} from '../uiComponentHelpers/common';
import { pasteWordInBrackets } from '../stringHelpers/common';

const { Nothing, Just } = Maybe;

const usernameConcatSeparator = '|';

const createMentionNode = curry((data) => {
  const e = document.createElement('mention');
  e.setAttribute('data-id', prop('id', data));
  e.setAttribute('data-username', prop('username', data));
  e.setAttribute('data-type', 'mention');
  e.setAttribute('class', 'mention-tag');
  e.setAttribute('spellcheck', false);
  e.innerHTML = concat('@', prop('username', data));
  return e;
});

const replaceMentionText = curry((
  data,
  range,
  selection,
  parentElement,
  changedNode,
  focusNode,
) => {
  const node = focusNode[changedNode];
  node.textContent = concat('@', prop('username', data));
  node.setAttribute('data-id', prop('id', data));
  setFocusAfterNodeForMention(selection, range, 1, node.nextSibling);
  return node;
});

const editMention = curry((range, selection, parentElement, node, data) => {
  const replaceText = replaceMentionText(data, range, selection, parentElement);
  return Either.either(replaceText('previousSibling'), replaceText('parentElement'), isParentNodeMention(node));
});

const concatTextWithPreviousSibling = concatTextWithSibling(
  'previousSibling',
  ['previousSibling', 'textContent'],
  concatWithPreviousSibling,
);

const concatTextWithNextSibling = concatTextWithSibling(
  'nextSibling',
  ['nextSibling'],
  checkIsWordValidAndConcat,
);

const replaceOrAppendMention = curry((left, right) => Either.either(left, right));

const getTextForReplaceMention = curry((initialText, node) => {
  const previousSiblingText = concatTextWithPreviousSibling(initialText)(node);
  return concatTextWithNextSibling(previousSiblingText)(node);
});

const setTextContent = curry((textContent, node) => {
  const changedNode = node;
  changedNode.textContent = textContent;
  return changedNode;
});

const changeNodeTextContent = curry(contentText => compose(
  tap(compose(setTextContent(contentText), prop('node'))),
  node => ({ node, lengthNode: length(node.textContent) }),
));

const isHasNode = cond([
  [prop('node'), Just],
  [T, Nothing],
]);

const isText = cond([
  [isNil, Nothing],
  [T, Just],
]);

const replaceMentionToText = curry((range, selection) => (mentionNode) => {
  const { anchorOffset } = selection;
  const { focusNode, typeNode } = mentionNode;
  cond([
    [equals('nextSibling'), () => {
      const { nextSibling } = focusNode;
      const contentText = getTextForReplaceMention(nextSibling.textContent, nextSibling);
      changeNodeTextContent(contentText)(focusNode);
      changedSiblingIfExist(removeNode, ['nextSibling'])(focusNode);
      setCaretFocusByOffset(focusNode, anchorOffset);
    }],
    [equals('parentNode'), () => {
      const contentText = getTextForReplaceMention(focusNode.data, focusNode.parentNode);
      const previousSiblingLengthAndNode = changedSiblingIfExist(
        changeNodeTextContent(contentText),
        ['parentNode', 'previousSibling'],
      )(focusNode);
      Maybe.maybe(null, ({ node, lengthNode }) => {
        changedSiblingIfExist(removeNode, ['parentNode', 'nextSibling'])(focusNode);
        setCaretFocusByOffset(node, anchorOffset + lengthNode);
        focusNode.parentNode.remove();
      })(isHasNode(previousSiblingLengthAndNode));
    }],
  ])(typeNode);
});

const filterMentions = curry(members => compose(
  condition => filter(condition, members),
  isMentionIncludes,
  // trim,
));

const getFirstCharsetMention = curry((start, text) => mentionPosition(
  start,
  0,
)(isTextNotEmpty(text)));

const concatIdWithUsername = (selectors) => {
  const mentionIdSelector = compose(concat('@'), prop(1))(selectors);
  const usernameSelector = concat(usernameConcatSeparator, prop(2)(selectors));
  return concat(mentionIdSelector, usernameSelector);
};

const convertToSelector = curryRegexReplace(
  regexRules.regMentionToSelector,
  replaceEachNode(
    pasteWordInBrackets,
    concatIdWithUsername,
    regexRules.regMentionToSelector,
    regexRules.regLocalMentionToSelector,
  ),
);

const appendMention = curry((members, action) => compose(
  cond([[compose(not, isNil), action], [T, () => null]]),
  Maybe.maybe(always(null), username => find(propEq('username', trim(username)))(members)),
));

const appendOrFilterMention = curry((focusText, anchorOffset, append, filterMention) => {
  const getStartIndex = compose(
    cond([
      [compose(
        curryRegexTest(/((\s\S*\s)|(\s@))/),
        prop('input'),
      ), compose(add(2), prop('index'))],
      [isNil, always(null)],
      [T, compose(inc, prop('index'))],
    ]),
    curryRegexMatch(regexRules.regMention),
    slice(0, anchorOffset),
  );
  const index = Maybe.maybe(null, getStartIndex)(isText(focusText));
  cond([
    [isNil, always(null)],
    [T, checkIfFilterOrAppend(anchorOffset, focusText, append, filterMention)],
  ])(index);
});

const getCoordinatesForReplacedWord = anchorOffset => compose(
  (data) => {
    const start = getFirstCharsetMention(anchorOffset, data) - 1;
    return ({ start, end: anchorOffset });
  },
  prop('data'),
);

export {
  editMention,
  filterMentions,
  convertToSelector,
  appendOrFilterMention,
  replaceOrAppendMention,
  getCoordinatesForReplacedWord,
  appendMention,
  replaceMentionToText,
  createMentionNode,
};
