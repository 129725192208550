import React from 'react';

import ModalView from '../modalView';

const EditLeadModal = props => (
  <ModalView
    title="Edit lead"
    titleAction="Save"
    isEdit
    {...props}
  />
);

export default EditLeadModal;
