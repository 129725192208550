const USERS_LIST = 'USERS_LIST';
const USERS = 'USERS';

export default {
  GET_USERS_LIST_FETCH: `GET_${USERS_LIST}_FETCH`,
  GET_USERS_LIST_AUTOCOMPLETE_REQUEST: `GET_${USERS_LIST}_AUTOCOMPLETE_REQUEST`,
  GET_COWORKERS_REQUEST: 'GET_COWORKERS_REQUEST',
  GET_USER_PROFILE_REQUEST: 'GET_USER_PROFILE_REQUEST',

  SET_USERS_LIST: `SET_${USERS_LIST}`,
  SET_USER_PROFILE: 'SET_USER_PROFILE',
  SET_USERS_LIST_AUTOCOMPLETE: `SET_${USERS_LIST}_AUTOCOMPLETE`,
  SET_USERS_INVITE_REQUEST: `SET_${USERS}_INVITE_REQUEST`,
  SET_USERS_INVITE_SUCCESS: `SET_${USERS}_INVITE_SUCCESS`,
  UPDATE_USERS: 'UPDATE_USERS',
  EMIT_USERS_STATUS: `EMIT_${USERS}_STATUS`,
  SET_USERS_STATUS: `SET_${USERS}_STATUS`,
  SET_USERS: `SET_${USERS}`,
  SET_COWORKERS: 'SET_COWORKERS',
  SET_USER_PROFILE_ID: 'SET_USER_PROFILE_ID',
};

export {
  USERS_LIST,
  USERS,
};
