import {
  compose, getContext, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import {
  always, cond, equals, T, prop, identity, ifElse, isNil,
} from 'ramda';
import { push } from 'connected-react-router';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import TaskItem from './taskItem';
import { tasksSelectors } from '../../../../state/tasks';
import { withTasksCRUDHandlers } from '../../../../utils/enchancers';
import { TASKS_WITHOUT_PROJECT } from '../../../../constants/tasks';

const mapDispatchToProps = ({
  push,
});

const getTaskStatus = compose(
  cond([
    [equals(0), always('my-tasks__row--new')],
    [equals(4), always('my-tasks__row--closed')],
    [T, always('')],
  ]),
  prop('status'),
);

const onClickTaskHandler = ({
  push: connectedPush,
  task: { project_id: projectId, id },
}) => () => {
  const projectIdOrNil = ifElse(isNil, always(TASKS_WITHOUT_PROJECT), identity)(projectId);
  connectedPush(`/projects/${projectIdOrNil}/task/${id}/`);
};

const onEditHandler = ({
  onEditTask,
  setSelectedTask,
  setSelectedTaskClientId,
  task: { project_id: projectId, client_id: clientId, id },
}) => () => {
  setSelectedTask(id);
  if (clientId) {
    setSelectedTaskClientId(clientId);
  }
  onEditTask({ taskId: id, projectId, clientId });
};

const onDeleteHandler = ({
  onDeleteTask,
  task: { project_id: projectId, client_id: clientId, id },
  isTaskHasSprint, setSelectedTask, setProjectId,
  setSelectedTaskClientId,
}) => () => {
  setProjectId(projectId);
  setSelectedTask({ id });
  if (clientId) {
    setSelectedTaskClientId(clientId);
  }
  onDeleteTask({ task: { project_id: projectId, id }, meta: ({ isHasSprint: isTaskHasSprint }) });
};

const mapStateToProps = (state, { taskId }) => ({
  task: tasksSelectors.getMyTask(state)(taskId),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTasksCRUDHandlers,
  withTranslation(['common']),
  getContext({
    setSelectedTask: PropTypes.func,
    setProjectId: PropTypes.func,
    setSelectedTaskClientId: PropTypes.func,
  }),
  withHandlers({
    onClickTask: onClickTaskHandler,
    onEdit: onEditHandler,
    onDelete: onDeleteHandler,
  }),
  withProps(({ task }) => ({
    taskStatus: getTaskStatus(task),
  })),
);
export default enhance(TaskItem);
