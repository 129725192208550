import React from 'react';
import ModalView from '../modalView';

const EditClientModal = props => (
  <ModalView
    title="Edit client"
    titleAction="Save"
    isEdit
    {...props}
  />
);

export default EditClientModal;
