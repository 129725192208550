import {
  compose, getContext, withProps,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { equals, length, propOr } from 'ramda';

import InvoiceDetails from './invoiceDetails';
import { invoicesActions, invoicesSelectors } from '../../../../state/invoices';
import { INVOICES_PAY_TYPES } from '../../../../constants/crm';

const getCurrencyOption = (label, value) => ({ label, value });

const currencyOptions = [
  getCurrencyOption('USD', '$'),
  getCurrencyOption('UAH', '₴'),
  getCurrencyOption('EUR', '€'),
];

// eslint-disable-next-line consistent-return
const getPaymentType = (type, currencySign) => {
  if (equals(type, INVOICES_PAY_TYPES.PERCENT)) {
    return {
      label: 'Percent (%)',
      value: INVOICES_PAY_TYPES.PERCENT,
      id: 1,
    };
  }
  if (equals(type, INVOICES_PAY_TYPES.FLAT)) {
    return {
      label: `Flat (${currencySign})`,
      value: INVOICES_PAY_TYPES.FLAT,
      id: 2,
    };
  }
};

const mapDispatchToProps = {
  addInvoiceItem: () => invoicesActions.addInvoiceItem({}),
  deleteInvoiceItem: invoicesActions.deleteInvoiceItem,
  changeInvoiceItemFieldValue: invoicesActions.changeInvoiceItemFieldValue,
  setCurrencySign: invoicesActions.setCurrencySign,
};

const mapStateToProps = state => ({
  invoiceItemsList: invoicesSelectors.getInvoiceItemsList(state),
  invoiceItemsEntities: invoicesSelectors.getInvoiceItemsEntities(state),
  tax: invoicesSelectors.getTax(state),
  discount: invoicesSelectors.getDiscount(state),
  currentInvoiceTax: JSON.parse(invoicesSelectors.getCurrentInvoiceTax(state)),
  currentInvoiceDiscount: JSON.parse(invoicesSelectors.getCurrentInvoiceDiscount(state)),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    values: PropTypes.instanceOf(Object),
    errors: PropTypes.instanceOf(Object),
    touched: PropTypes.instanceOf(Object),
    setFieldValue: PropTypes.func,
    defaultDueDateValues: PropTypes.instanceOf(Object),
    termsOptions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    currencyOptions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  }),
  withProps(({ invoiceItemsList, currentInvoiceTax, currentInvoiceDiscount }) => ({
    currencyOptions,
    canBeDeleted: length(invoiceItemsList) > 1,
    getPaymentType,
    currentInvoiceTax: propOr(null, 'value', currentInvoiceTax) || 0,
    currentInvoiceDiscount: propOr(null, 'value', currentInvoiceDiscount) || 0,
  })),
);

export default enhance(InvoiceDetails);
