import api from '../services/api.service';
import { API_ROUTES } from '../constants/apiRoutes';
import { extractData } from '../utils/helpers/extractDataHelper';

const schema = {
  getProjectsSummaryRequest: async () => {
    try {
      const response = await api.get(API_ROUTES.PROJECTS_SUMMARY);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  getTasksSummaryRequest: async () => {
    try {
      const response = await api.get(API_ROUTES.MY_TASKS_SUMMARY);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
};

export default schema;
