import {
  branch,
  compose, getContext, renderNothing, withHandlers,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';

import LeaveChannelModal from './leaveChannelModal';
import { getModal } from '../../../../state/ui/selectors';
import { uiActions } from '../../../../state/ui';
import { messengerActions, messengerSelectors } from '../../../../state/messenger';

const mapDispatchToProps = {
  onCloseModal: uiActions.closeModal,
  deleteChannelRequest: messengerActions.leaveOfChannelRequest,
  getChannelRequest: messengerActions.getChannelRequest,
};

const mapStateToProps = (state, { selectedChannelId }) => ({
  isOpen: getModal(state)('leaveChannelModal'),
  channel: propOr({}, selectedChannelId,
    messengerSelectors.getGroupChannels(state)),
});

const onLeaveHandler = ({
  deleteChannelRequest, selectedChannelId, onCloseModal,
}) => () => deleteChannelRequest({
  channelId: selectedChannelId,
}, {
  callbacks: {
    success: () => {
      onCloseModal();
    },
  },
});

const onCloseHandler = ({ onCloseModal }) => () => onCloseModal('leaveChannelModal');

const enhance = compose(
  getContext({
    selectedChannelId: PropTypes.number,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ channel }) => !channel.id, renderNothing),
  withRouter,
  withHandlers({
    onClose: onCloseHandler,
  }),
  withHandlers({
    onLeave: onLeaveHandler,
  }),
);


export default enhance(LeaveChannelModal);
