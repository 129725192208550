import { DragDropContext } from 'react-beautiful-dnd';
import React from 'react';
import PropTypes from 'prop-types';

import { ColumnBoard } from '..';
import { WINDOW_WIDTH } from '../../../../../../constants/ui';


const Board = ({
  pipelineStatuses, onDragEnd, onDragStart, onDragUpdate, isDragging,
  updateDroppable, columnRowHeight, windowWidth,
}) => (
  <div
    className="pipe-view__column-row"
    style={windowWidth < WINDOW_WIDTH.SMALL ? { height: columnRowHeight } : {}}
  >
    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate} onDragStart={onDragStart}>
      {pipelineStatuses.map(pipelineStatus => (
        <ColumnBoard
          isDragging={isDragging}
          updateDroppable={updateDroppable}
          pipelineStatus={pipelineStatus}
          key={`agenda - ${pipelineStatus.id}`}
        />
      ))}
    </DragDropContext>
  </div>
);

Board.propTypes = {
  pipelineStatuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onDragStart: PropTypes.func.isRequired,
  onDragUpdate: PropTypes.func.isRequired,
  updateDroppable: PropTypes.number,
  isDragging: PropTypes.bool.isRequired,
  columnRowHeight: PropTypes.string.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

Board.defaultProps = {
  updateDroppable: null,
};

export default Board;
