import {
  compose, withProps, withHandlers, withContext, lifecycle, withState,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { prop } from 'ramda';
import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';

import Channels from './channels';
import { messengerSelectors, messengerActions } from '../../state/messenger';
import { uiActions } from '../../state/ui';
import rules from './rules';
import { CHANNELS_YOU_BELONG_TO, CHANNELS_YOU_CAN_JOIN } from './constants';
import { debounceFunc } from '../../utils/helpers/commonHelpers';
import { withPagination, withUrlParams } from '../../utils/enchancers';
import { calculateOffset } from '../../utils/helpers/uiComponentHelpers/pagination';

const LIMIT = 10;
const DEBOUNCE_DURATION = 800;
const FUNC_NAME = 'setFieldValueWithSubmit';
const DEFAULT_OFFSET = 0;

const { getGroupChannelsRequest, setYouBelongToChannels, setYouCanJoinChannels } = messengerActions;
const { resetPagination } = uiActions;

const getChannelsAction = ({ type }) => {
  if (type === CHANNELS_YOU_CAN_JOIN) return setYouCanJoinChannels;
  return setYouBelongToChannels;
};

const getObjectForSend = ({
  type,
  search,
  limit = LIMIT,
  offset = 0,
  orderByTitle,
}) => ({
  sortBy: 'name',
  order: prop('value', orderByTitle),
  limit,
  offset,
  search,
  notMember: prop('value', type) === CHANNELS_YOU_BELONG_TO ? null : true,
  success: getChannelsAction({ type: prop('value', type) }),
});

const onChangeSearchHandler = ({
  setFieldValue,
  setFieldValueWithSubmit,

}) => (e) => {
  setFieldValue('search', e.target.value);
  debounceFunc(setFieldValueWithSubmit, DEBOUNCE_DURATION, false, FUNC_NAME);
};

const setFieldValueWithSubmit = ({
  setFieldValue, values, getChannelsRequest, resetPagination: resetPages, setPagination, pagination,
}) => (name, value) => {
  setFieldValue(name, value);
  const newValues = {
    ...values,
    [name]: value,
  };
  setPagination({ ...pagination, offset: DEFAULT_OFFSET });
  getChannelsRequest(
    null,
    getObjectForSend({
      search: newValues.search,
      orderByTitle: newValues.orderByTitle,
      ...pagination,
      offset: DEFAULT_OFFSET,
      type: newValues.type,
    }),
  );
  resetPages();
};

const mapStateToProps = state => ({
  youBelongToChannelsList: messengerSelectors.getYouBelongToChannelsList(state),
  youCanJoinChannelsList: messengerSelectors.getYouCanJoinChannelsList(state),
  youBelongToChannelsCount: messengerSelectors.getYouBelongToChannelsCount(state),
  youCanJoinChannelsCount: messengerSelectors.getYouCanJoinChannelsCount(state),
  entities: messengerSelectors.getGroupChannels(state),
});

const mapDispatchToProps = {
  getChannelsRequest: getGroupChannelsRequest,
  resetPagination,
  changeRightSidebarStatus: uiActions.changeRightSidebarStatus,
};

const onPageChangeHandler = ({
  setPagination,
  pagination, getChannelsRequest, paginationRequestParams,
}) => (meta) => {
  const nextPagination = { ...pagination, ...meta };
  setPagination(nextPagination);
  getChannelsRequest(null, { ...paginationRequestParams, ...nextPagination });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common', 'chat']),
  withState('pagination', 'setPagination', {
    limit: LIMIT,
    offset: DEFAULT_OFFSET,
  }),
  withUrlParams({}),
  withPagination({
    limit: () => LIMIT,
    offset: ({ getUrlParam }) => calculateOffset(getUrlParam(['page'], 1), LIMIT),
    page: ({ getUrlParam }) => getUrlParam(['page'], 1),
  }),
  withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({
      search: '',
      type: {
        id: 1,
        label: 'Channels you can join',
        value: 1,
      },
      sortBy: 0,
    }),
    validationSchema: rules,
    handleSubmit: (
      { search, type, orderByTitle },
      { props: { getChannelsRequest, pagination } },
    ) => {
      getChannelsRequest(
        null,
        getObjectForSend({
          search,
          type,
          orderByTitle,
          ...pagination,
        }),
      );
    },
  }),
  withProps(
    ({
      youCanJoinChannelsCount,
      youBelongToChannelsCount,
      youCanJoinChannelsList,
      youBelongToChannelsList,
      values: { type, search, orderByTitle },
    }) => ({
      countEntities: prop('value', type)
        ? youCanJoinChannelsCount
        : youBelongToChannelsCount,
      result: prop('value', type)
        ? youCanJoinChannelsList
        : youBelongToChannelsList,
      paginationRequestParams: getObjectForSend({
        ...{ type },
        name: search,
        orderByTitle,
      }),
      title: prop('value', type)
        ? 'Channels you can join'
        : 'Channels you belong to',
    }),
  ),
  withHandlers({
    setFieldValueWithSubmit,
  }),
  withHandlers({
    onPageChange: onPageChangeHandler,
    onChangeSearchHandler,
  }),
  withContext(
    {
      values: PropTypes.instanceOf(Object),
      setFieldValue: PropTypes.func,
    },
    ({ values, setFieldValueWithSubmit: setFieldValue }) => ({
      values,
      setFieldValue,
    }),
  ),
  lifecycle({
    componentDidMount() {
      const {
        getChannelsRequest,
        values: { search, type, orderByTitle },
        pagination,
        changeRightSidebarStatus,
      } = this.props;
      getChannelsRequest(
        null,
        getObjectForSend({
          search,
          type,
          orderByTitle,
          ...pagination,
        }),
      );
      changeRightSidebarStatus(false);
    },
  }),
);

export default enhance(Channels);
