import React, { useCallback, useEffect, useMemo } from 'react';
import {
  isNil, keys, pathOr,
} from 'ramda';

import './style.sass';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonsCollection } from '../../../../../../../ui-kit';
import { ModulePermissions } from '../modulePermissions';
import { getPermissionsByRoleId, getModules } from '../../../../../../../state/permissions/selectors';
import MODULES_GROUP_PERMISSIONS from '../../../../../../../constants/permissions';
import { mergeDeep } from '../../../../../../../utils/helpers/stateHelpers';

const OTHER = 'Other';
const ALL = 'all';

/**
 *
 * @param listOfPermissions{Object}
 * @param onSubmit{function}
 * @param setListOfPermissions{function}
 * @returns {void|JSX.Element}
 * @constructor
 */
export const PermissionsList = ({
  listOfPermissions,
  onSubmit,
  setListOfPermissions,
}) => {
  const { t } = useTranslation('common');

  const { roleId } = useParams();

  const permissionsByModulesEntities = useSelector(
    state => getPermissionsByRoleId(state)(roleId),
  );
  const modules = useSelector(getModules);

  const permissionsByModulesList = keys(modules);

  const MODULES_GROUP_NAMES = keys(MODULES_GROUP_PERMISSIONS);

  const getGroupName = (permissionGroups, permissionName) => MODULES_GROUP_NAMES.reduce((accum, groupName) => (permissionGroups[groupName].includes(permissionName) ? groupName : accum), '');

  // eslint-disable-next-line max-len
  const permissionByGroups = useMemo(() => permissionsByModulesList.reduce((accum, permissionName) => {
    const groupName = getGroupName(MODULES_GROUP_PERMISSIONS, permissionName) || OTHER;
    return { ...accum, [groupName]: [...accum[groupName] ?? [], permissionName] };
  }, {}), [modules]);

  const onChangePermission = useCallback(({ target: { name, checked } }) => {
    // eslint-disable-next-line no-unused-vars
    const [moduleGroupName, moduleName, permissionName] = name.split('-');
    const appendPermission = permissionName === ALL
      && isNil(pathOr(null, ['moduleName', 'all'], listOfPermissions)) ? ({
        [moduleName]: {
          create: checked, read: checked, update: checked, delete: checked, all: checked,
        },
      }) : ({
        [moduleName]: {
          [permissionName]: Number(checked),
          all: checked,
        },
      });
    setListOfPermissions(mergeDeep({
      ...listOfPermissions,
      [moduleName]: {
        [permissionName]: Number(checked),
      },
      ...appendPermission,
    }));
  }, [modules]);

  useEffect(() => {
    setListOfPermissions(permissionsByModulesEntities);
  }, []);

  return (
    <>
      {
        keys(permissionByGroups).map(moduleName => (
          <ModulePermissions
            key={moduleName}
            permissionByGroups={permissionByGroups}
            moduleName={moduleName}
            permissions={listOfPermissions}
            onChangePermission={onChangePermission}
          />
        ))
      }
      <div className="modulePermissions-buttons__row">
        <ButtonsCollection.ButtonBrill
          type="submit"
          onClick={onSubmit}
          className="modulePermissions-buttons__button button-brill--fill"
        >
          {t('Save changes')}
        </ButtonsCollection.ButtonBrill>
      </div>
    </>
  );
};
