import {
  compose, withHandlers, getContext, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import withWindowWidth from '../../../../../../utils/enchancers/withWindowWidth';
import { LEADS_VIEW } from '../../../../../../constants/crm';
import { leadsActions, leadsSelectors } from '../../../../../../state/leads';
import LeadCard from './leadCard';
import { uiActions } from '../../../../../../state/ui';

const mapStateToProps = (state, { lead }) => ({
  pinnedLead: leadsSelectors.getPinnedLeadById(state)(lead.id),
});

const mapDispatchToProps = {
  openModal: uiActions.openModal,
  setActiveRelatedLead: leadsActions.setActiveRelatedLead,
};

const onActionHandler = modalName => ({
  openModal, setSelectedLeadId, lead,
}) => () => {
  setSelectedLeadId(lead.id);
  openModal(modalName);
};

const onClickLeadCardHandler = ({ setLeadsView, setActiveRelatedLead, lead }) => () => {
  setLeadsView(LEADS_VIEW.LIST);
  setActiveRelatedLead(lead.id);
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withWindowWidth(),
  withTranslation(['tasks', 'common']),
  getContext({
    setLeadsView: PropTypes.func.isRequired,
    setSelectedLeadId: PropTypes.func.isRequired,
  }),
  withProps(({ pinnedLead }) => ({
    isPinned: !!pinnedLead,
  })),
  withHandlers({
    onEditLeadModal: onActionHandler('editLeadModal'),
    onDeleteLeadModal: onActionHandler('deleteLeadModal'),
    onPinLeadModal: onActionHandler('pinLeadModal'),
    onUnpinLeadModal: onActionHandler('unpinLeadModal'),
    onClickLeadCard: onClickLeadCardHandler,
  }),
);

export default enhance(LeadCard);
