import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { identity } from 'ramda';

import { FormsCollection } from '../../../../../../../ui-kit';
import SendMessage from '../../../../../../../components/sendMessage';

const EditableComment = ({
  onClickSaveContent,
  members,
  setRef,
  onRenderContent,
  onCloseEditMessage,
  content,
}) => (
  <FormsCollection.FormWrapper
    handleSubmit={identity}
    customRef={e => setRef('editableMessage', e)}
    className="comment-card__edit-form"
  >
    <SendMessage
      onSubmit={onClickSaveContent}
      members={members}
      className="message-textArea"
      options={{ isFilesUploadingFunctionality: false }}
      onRenderContent={onRenderContent}
      value={ReactDOMServer.renderToString(content)}
      onKeyDown={identity}
      sendLabel="Save"
      discardButton
      isEditing
      onDiscard={onCloseEditMessage}
    />
  </FormsCollection.FormWrapper>
);

EditableComment.propTypes = {
  setRef: PropTypes.func.isRequired,
  onClickSaveContent: PropTypes.func.isRequired,
  onCloseEditMessage: PropTypes.func.isRequired,
  members: PropTypes.instanceOf(Object).isRequired,
  content: PropTypes.string.isRequired,
  onRenderContent: PropTypes.func.isRequired,
};

export default EditableComment;
