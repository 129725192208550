import React from 'react';
import PropTypes from 'prop-types';
import { ProjectTab, ProjectTasksList } from './components';
import { Pagination } from '../../../../ui-kit';

const MyTasksProjects = ({
  gridName, pagination, onPageChange, tasksByProjectList,
}) => (
  <div className="my-tasks__projects-list">
    {
      tasksByProjectList.map(data => (
        <ProjectTab project={data.project} key={data.project.id}>
          <ProjectTasksList
            data={data}
            projectId={data.project.id}
            gridName={gridName}
          />
          <Pagination
            offsetData={data.offset}
            count={data.allProjectTaskCount}
            component={gridName}
            pagination={{ ...pagination, offset: data.offset }}
            onPageChange={({ offset }) => onPageChange({ ...data, requestOffset: offset })}
          />
        </ProjectTab>
      ))
    }
  </div>
);

MyTasksProjects.propTypes = {
  tasksByProjectList: PropTypes.arrayOf(PropTypes.object).isRequired,
  gridName: PropTypes.string.isRequired,
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
  }).isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default MyTasksProjects;
