import {
  compose, lifecycle, withHandlers, withProps, withState, withContext, getContext,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  assoc, curry, equals, prop, isEmpty, unapply, mergeAll, map, anyPass,
} from 'ramda';
import { notEqual } from 'ramda-extension';
import ContactsContainer from './contactsContainer';
import { contactsSelectors, contactsActions } from '../../state/contacts';
import { leadsSelectors } from '../../state/leads';
import { clientsSelectors } from '../../state/clients';
import {
  preloaderWhileLoading, withFilters,
  withPagination,
  withSorting,
  withUrlParams,
} from '../../utils/enchancers';
import { getOppositeValue } from '../../utils/helpers/commonHelpers';
import { calculateOffset } from '../../utils/helpers/uiComponentHelpers/pagination';
import { setFilterForSingleParam } from '../../utils/helpers/uiComponentHelpers/filters';
import { uiActions } from '../../state/ui';
import { callNotification } from '../../utils/helpers/notifies';
import { START_PAGE_NUMBER } from '../../constants/crm';

const mapStateToProps = (state, { selectedContactId }) => ({
  contacts: contactsSelectors.getContactsIDsSelector(state),
  isLoading: contactsSelectors.getContactsPendingRequest(state),
  contactsCount: contactsSelectors.getContactsCount(state),
  contactsOffset: contactsSelectors.getContactsOffset(state),
  contact: contactsSelectors.getContactById(state)(selectedContactId)
    || leadsSelectors.getLeadContactSelector(state)(selectedContactId)
    || clientsSelectors.getClientContactSelector(state)(selectedContactId),
});

const mapDispatchToProps = ({
  getPinnedContactsRequest: contactsActions.getPinnedContactsRequest,
  getContactsRequest: contactsActions.getContactsRequest,
  deleteContact: contactsActions.deleteContactRequest,
  closeModal: uiActions.closeModal,
});

const LIMIT = 10;

const defaultSorting = {
  order: 'desc',
  sortBy: 'created_at',
};


const makeSortingParams = curry((params, sort, order) => {
  const assocIfTruthy = curry((property, value, obj) => (
    value ? assoc(property, value, obj) : obj));
  return compose(
    assocIfTruthy('order', order),
    assocIfTruthy('sortBy', sort),
  )(params);
});

const getSortingParamsForRequest = (sort, order) => {
  const isSortEqualTo = compose(
    anyPass,
    unapply(map(equals(sort))),
  );
  return isSortEqualTo('created_at', 'name') ? makeSortingParams({}, sort, order) : defaultSorting;
};

const onSortByNameHandler = ({ onSortBy }) => () => onSortBy({ sort: 'name' });

const getContacts = ({
  getContactsRequest, sort, order, pagination, filters,
}, meta = {}) => compose(
  getContactsRequest,
  unapply(mergeAll),
)(getSortingParamsForRequest(sort, order), pagination, filters, meta);

const getContactsHandler = ({
  getContactsRequest, pagination, sort, order, filters,
}) => meta => getContacts({
  getContactsRequest, pagination, sortBy: sort, order, filters,
}, meta);

const onPageChangeHandler = ({
  getContactsRequest, pagination, sort, order, filters,
}) => meta => getContacts({
  getContactsRequest, pagination, sortBy: sort, order, filters,
}, meta);

const setSearchContactNameHandler = ({ setFilters }) => ({ target: { value } }) => setFilters({
  name: value,
});

const onSearchHandler = ({
  onSetUrlParam, onResetUrlParam, searchName, mergeFilters, setFilters, setIsNeedRefresh,
}) => () => {
  const setSearchNameFilterAndUrl = setFilterForSingleParam(mergeFilters, setFilters);
  if (isEmpty(searchName)) {
    setSearchNameFilterAndUrl(onResetUrlParam, 'name', null);
  } else {
    setSearchNameFilterAndUrl(onSetUrlParam, 'name', searchName);
  }
  return setIsNeedRefresh(true);
};

const onSuccessCreateContactHandler = ({
  setIsNeedRefresh,
}) => () => setIsNeedRefresh(true);

const onDeleteContactHandler = ({
  deleteContact,
  getPinnedContactsRequest,
  selectedContactId,
  closeModal,
  setSelectedContactId,
  sort, order, pagination, filters, contact,
}) => () => {
  const { parent } = contact;
  if (parent && !isEmpty(parent) && parent.contact_id === selectedContactId) {
    callNotification.error('This contact is already a client or a lead');
  } else {
    deleteContact({ id: selectedContactId }, {
      ...getSortingParamsForRequest(sort, order),
      ...pagination,
      ...filters,
    });
    setSelectedContactId(null);
    closeModal('deleteContactModal');
    getPinnedContactsRequest();
  }
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('isNeedRefresh', 'setIsNeedRefresh', false),
  withState('selectedSort', 'setSelectedSort', {}),
  withState('pagination', 'setPagination', { limit: LIMIT, offset: 0 }),
  withUrlParams({}),
  getContext({
    setSelectedContactId: PropTypes.func.isRequired,
    setSelectedPinnedContactId: PropTypes.func.isRequired,
    selectedPinnedContactId: PropTypes.any,
  }),
  withFilters({ initial: ({ getUrlParam }) => ({ name: getUrlParam(['name']) }) }),
  withProps(({ getFilter }) => ({
    searchName: getFilter('', 'name'),
  })),
  withPagination({
    limit: () => LIMIT,
    offset: ({ getUrlParam }) => calculateOffset(getUrlParam(['page'], 1), LIMIT),
    page: ({ getUrlParam }) => getUrlParam(['page'], 1),
  }),
  withSorting({
    selector: props => props.getUrlParams({}),
    values: ({
      order: ({ getUrlParam }) => getUrlParam(['order'], 'desc'),
      sort: ({ getUrlParam }) => getUrlParam(['sort'], 'created_at'),
    }),
    onSortBy: ({ order, onSetUrlParam, setIsNeedRefresh }) => (dataSorting) => {
      const sort = prop('sort', dataSorting);
      const newOrder = notEqual(sort, 'created_at') ? getOppositeValue('asc', 'desc', order) : prop('order', dataSorting);
      onSetUrlParam({ sort, order: newOrder });
      setIsNeedRefresh(true);
    },
  }),
  withHandlers({
    onPageChange: onPageChangeHandler,
    onSortByName: onSortByNameHandler,
    setSearchContactName: setSearchContactNameHandler,
    onSearch: onSearchHandler,
    onSuccessCreateContact: onSuccessCreateContactHandler,
    onDeleteContact: onDeleteContactHandler,
    getContacts: getContactsHandler,
  }),
  withContext({
    setSelectedContactId: PropTypes.func.isRequired,
    setIsNeedRefresh: PropTypes.func.isRequired,
  }, ({ setSelectedContactId, setIsNeedRefresh }) => ({
    setSelectedContactId,
    setIsNeedRefresh,
  })),
  lifecycle({
    componentDidMount() {
      getContacts(this.props);
      this.props.getPinnedContactsRequest();
    },
    componentDidUpdate(prevProps) {
      const {
        isNeedRefresh, setIsNeedRefresh, selectedPinnedContactId,
        contacts, setSelectedContactId, contactsOffset, onSetUrlParam,
      } = this.props;
      const {
        isNeedRefresh: prevIsNeedRefresh,
        selectedPinnedContactId: prevSelectedPinnedContactId,
        contactsOffset: prevContactsOffset,
      } = prevProps;

      if (selectedPinnedContactId && prevSelectedPinnedContactId !== selectedPinnedContactId) {
        setSelectedContactId(selectedPinnedContactId);
        setIsNeedRefresh(true);
      }
      if (contactsOffset !== prevContactsOffset) {
        onSetUrlParam({ page: START_PAGE_NUMBER + contactsOffset / LIMIT });
      }
      if (isNeedRefresh && prevIsNeedRefresh !== isNeedRefresh) {
        if (selectedPinnedContactId && !contacts.includes(selectedPinnedContactId)) {
          getContacts(this.props, { selectedPinnedContactId });
          setSelectedContactId(selectedPinnedContactId);
        } else {
          getContacts(this.props);
        }
        setIsNeedRefresh(false);
      }
    },
  }),
  preloaderWhileLoading({
    dimension: 100,
    fullScreen: true,
    isLoading: props => !props.isLoading,
  }),
);

export default enhance(ContactsContainer);
