import React from 'react';
import PropTypes from 'prop-types';
import ModalView from '../modalViewTasks/modalView';

const EditTaskModal = (props) => {
  const { t } = props;
  return (
    <ModalView
      modalTitle={t('Edit task')}
      action={t('Save')}
      name="editPartOfTaskModal"
      {...props}
    />
  );
};

EditTaskModal.propTypes = {
  t: PropTypes.func.isRequired,
};

export default EditTaskModal;
