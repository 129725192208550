import {
  compose, lifecycle, withContext, withHandlers, withState,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { path } from 'ramda';
import { uiActions } from '../../../../state/ui';
import { clientsActions, clientsSelectors } from '../../../../state/clients';
import ContactsInfo from './contactsInfo';
import { preloaderWhileLoading } from '../../../../utils/enchancers';
import { contactsActions } from '../../../../state/contacts';
import { PRELOADER_DIMENSION } from '../../../../constants/ui';
import { OFFSET } from '../../../../constants/crm';
import { customFieldsActions } from '../../../../state/customFields';


const mapStateToProps = (state, { client }) => ({
  clientContacts: clientsSelectors.getClientContactsSelector(state)(client.id),
  clientContactsCount: clientsSelectors.getClientContactsCountSelector(state),
  isLoading: clientsSelectors.getClientActionPending(state)('getClientContactsRequest'),
});

const mapDispatchToProps = ({
  getClientContacts: clientsActions.getClientContactsRequest,
  setCreateContactModalOpen: () => uiActions.openModal('createContactModal'),
  deleteContact: contactsActions.deleteContactRequest,
  closeModal: uiActions.closeModal,
  deleteClientContactSuccess: clientsActions.deleteClientContactSuccess,
  setAddedClientContact: clientsActions.setAddedClientContact,
  editClientContactSuccess: clientsActions.editClientContactSuccess,
  getCustomFields: customFieldsActions.getCustomFieldsRequest,
});

const onGetClientContactsHandler = ({ getClientContacts, client }) => () => {
  getClientContacts({ id: client.id });
};

const getMoreContactsHandler = ({
  getClientContacts, setOffset, offset, client, clientContactsCount, clientContacts,
}) => () => {
  if (clientContacts.length < clientContactsCount) {
    setOffset(offset + OFFSET);
    getClientContacts({ id: client.id, offset });
  }
};

const createClientContactsSuccessHandler = ({
  setAddedClientContact, setOffset, client, getClientContacts,
}) => (formData) => {
  setAddedClientContact({ contact: { ...formData, client_id: path(['client_id', 'value'], formData) } });
  setOffset(OFFSET);
  getClientContacts({ id: client.id });
};

const editClientContactsSuccessHandler = ({
  editClientContactSuccess, setOffset, getClientContacts,
}) => (clientId) => {
  setOffset(OFFSET);
  editClientContactSuccess(clientId);
  getClientContacts({ id: clientId });
};


const onDeleteClientContactsHandler = ({
  deleteClientContactSuccess,
  client,
  selectedContactId,
}) => () => {
  deleteClientContactSuccess({ id: client.id, selectedContactId });
};

const onDeleteContactHandler = ({
  deleteContact,
  selectedContactId,
  closeModal,
  setSelectedContactId,
  getClientContacts,
  client,
  deleteClientContactSuccess,
}) => () => {
  deleteContact({ id: selectedContactId }, {
    callbacks: {
      success: () => {
        onGetClientContactsHandler({ getClientContacts, client })();
        onDeleteClientContactsHandler({ deleteClientContactSuccess, client, selectedContactId })();
      },
    },
  });
  setSelectedContactId(null);
  closeModal('deleteContactModal');
};

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common']),
  withState('selectedContactId', 'setSelectedContactId', null),
  withState('offset', 'setOffset', OFFSET),
  withContext({
    setSelectedContactId: PropTypes.func,
  }, ({ setSelectedContactId }) => ({
    setSelectedContactId,
  })),
  withHandlers({
    onGetClientContacts: onGetClientContactsHandler,
    onDeleteContact: onDeleteContactHandler,
    getMoreContacts: getMoreContactsHandler,
    createClientContactsSuccess: createClientContactsSuccessHandler,
    editClientContactsSuccess: editClientContactsSuccessHandler,
  }),
  lifecycle({
    componentDidMount() {
      const { onGetClientContacts, getCustomFields } = this.props;
      getCustomFields();
      onGetClientContacts();
    },
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.SMALL,
    isLoading: props => !props.isLoading,
  }),
);
export default enhancer(ContactsInfo);
