import { useCallback, useEffect } from 'react';

export const useClickOutside = (ref, callback) => {
  const clickOutsideHandler = useCallback((e) => {
    if (!ref.current?.contains(e.target)) {
      callback();
    }
  });
  useEffect(() => {
    document.addEventListener('click', clickOutsideHandler, true);
    return () => {
      document.removeEventListener('click', clickOutsideHandler, true);
    };
  }, [ref, callback]);
};
