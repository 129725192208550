import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { push as routerPush } from 'connected-react-router';
import { find, propEq, path } from 'ramda';
import HrTable from './hrTable';
import { POSITIONS, STATUSES } from '../../constants';

const mapDispatchToProps = { push: routerPush };

const getStatusHandler = ({ entities }) => (candidateId) => {
  const status = find(propEq('value', entities[candidateId].status), STATUSES);
  if (status && status.label) return status.label;
};

const getPositionHandler = ({ entities }) => (candidateId) => {
  const position = find(propEq('value', +entities[candidateId].position), POSITIONS);
  if (position && position.label) return position.label;
};

const getAgeFromDateHandler = ({ entities }) => (candidateId) => {
  const birthDay = path(['date_of_birthday'], entities[candidateId]);
  if (birthDay) {
    const date = new Date(birthDay);
    const ageDifMs = Date.now() - date.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  withHandlers({
    getStatus: getStatusHandler,
    getPosition: getPositionHandler,
    getAgeFromDate: getAgeFromDateHandler,
  }),
);
export default enhance(HrTable);
