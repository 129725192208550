import React from 'react';

import { ScrollWrapper } from '../../../../../ui-kit';
import { UserEditForm } from './components';
import './style.sass';

/**
 * @param user {object}
 * @returns {JSX.Element}
 * @constructor
 */

export const SettingUserContainer = ({ user }) => (
  <ScrollWrapper>
    <div className="userEdit">
      <UserEditForm
        user={user}
      />
    </div>
  </ScrollWrapper>
);
