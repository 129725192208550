import api from '../services/api.service';
import { API_ROUTES } from '../constants/apiRoutes';
import { extractData } from './helpers';

const schema = {
  getUserTotalTimeRequest: async (params = {}) => {
    try {
      const response = await api.get(API_ROUTES.ACTIVITY.ME, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  getUsersTotalTimeRequest: async (params = {}) => {
    try {
      const response = await api.get(API_ROUTES.ACTIVITY.USERS, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  getProjectTotalTimeRequest: async (params = {}) => {
    try {
      const response = await api.get(API_ROUTES.ACTIVITY.PROJECTS_TOTAL_TIME, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  getStatsReport: async (params = {}) => {
    try {
      const response = await api.get(API_ROUTES.ACTIVITY.USER_REPORT, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  getProjectsTaskCrudRequest: async (params = {}) => {
    try {
      const response = await api.get(API_ROUTES.ACTIVITY.PROJECTS_TASK_CRUD, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  getUserAutocomplete: async (params = {}) => {
    try {
      const response = await api.get(API_ROUTES.ACTIVITY.USER_AUTOCOMPLETE, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
};

export default schema;
