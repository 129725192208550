import React, { useCallback, useState } from 'react';
import { pick, propOr } from 'ramda';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ButtonsCollection, FormsCollection } from '../../../../../../../ui-kit';
import rules from './rules';
import api from '../../../../../../../api/settings.service';

/**
 * @param isDisabled {boolean}
 * @param companyBillingInfo {object}
 * @param getCompanyBillingInfoRequest {function}
 * @returns {JSX.Element}
 * @constructor
 */

const COMPANY_INFO_FIELDS = ['business_name', 'beneficiary_address', 'phone', 'email'];
const BANK_INFO_FIELDS = ['bank_name', 'bank_address', 'bic', 'iban', 'swift_code', 'bank_country'];
const SUBMIT_DELAY = 800;

export const BillingInfoForm = ({
  isDisabled,
  companyBillingInfo,
  getCompanyBillingInfoRequest,
}) => {
  const {
    control, handleSubmit, formState: { errors },
  } = useForm({
    resolver: yupResolver(rules),
  });
  const { t } = useTranslation();
  const [isPending, setIsPending] = useState(false);

  const sendCompanyBillingInfoRequest = useCallback(async (value) => {
    const data = await api.updateCompanyBillingInfoRequest(value);
    setIsPending(true);
    if (data) {
      getCompanyBillingInfoRequest();
      setIsPending(false);
    }
  });

  const onSubmit = useCallback((values) => {
    sendCompanyBillingInfoRequest({
      company_info: pick(COMPANY_INFO_FIELDS, values),
      bank_info: pick(BANK_INFO_FIELDS, values),
    });
  });

  return (
    <FormsCollection.FormWrapper className="billing-info__form" handleSubmit={handleSubmit(onSubmit)}>
      {companyBillingInfo.company_info && companyBillingInfo.bank_details && (
        <>
          <div className="billing-info__form--company-info__wrapper">
            <div className="company-info-label">
              Company info
            </div>
            <div className="billing-info__form--company-info">
              <div className="billing-info__form-row">
                <Controller
                  name="business_name"
                  control={control}
                  defaultValue={companyBillingInfo.company_info?.business_name || ''}
                  render={({
                    onBlur, value, touched, field, onChange,
                  }) => (
                    <FormsCollection.Input
                      name="business_name"
                      value={value}
                      error={propOr('', 'message', errors.business_name?.message)}
                      onBlur={onBlur}
                      touched={touched}
                      disabled={isDisabled}
                      onChange={onChange}
                      id="form-billing-info__field-business_name"
                      placeholderJump="Business name"
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="billing-info__form-row">
                <Controller
                  name="beneficiary_address"
                  control={control}
                  defaultValue={companyBillingInfo.company_info?.beneficiary_address || ''}
                  render={({
                    onBlur, value, touched, field, onChange,
                  }) => (
                    <FormsCollection.Input
                      name="beneficiary_address"
                      value={value}
                      error={propOr('', 'message', errors.beneficiary_address?.message)}
                      onBlur={onBlur}
                      touched={touched}
                      disabled={isDisabled}
                      onChange={onChange}
                      id="form-billing-info__field-beneficiary_address"
                      placeholderJump="Beneficiary address"
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="billing-info__form-row">
                <Controller
                  name="phone"
                  control={control}
                  defaultValue={companyBillingInfo.company_info?.phone || ''}
                  render={({
                    onBlur, value, touched, field, onChange,
                  }) => (
                    <FormsCollection.Input
                      name="phone"
                      value={value}
                      error={propOr('', 'message', errors.phone?.message)}
                      onBlur={onBlur}
                      touched={touched}
                      disabled={isDisabled}
                      onChange={onChange}
                      id="form-billing-info__field-phone"
                      placeholderJump="Phone"
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="billing-info__form-row">
                <Controller
                  name="email"
                  control={control}
                  defaultValue={companyBillingInfo.company_info?.email || ''}
                  render={({
                    onBlur, value, touched, field, onChange,
                  }) => (
                    <FormsCollection.Input
                      name="email"
                      value={value}
                      error={propOr('', 'message', errors.email?.message)}
                      onBlur={onBlur}
                      touched={touched}
                      disabled={isDisabled}
                      onChange={onChange}
                      id="form-billing-info__field-email"
                      placeholderJump="Email"
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
            <div className="billing-info__form--buttons">
              <ButtonsCollection.ButtonBrill
                type="submit"
                pending={isPending}
                delay={SUBMIT_DELAY}
                className="button-brill--fill billing-info__form--buttons--submit-button button--md"
              >
                {t('Save')}
              </ButtonsCollection.ButtonBrill>
            </div>
          </div>
          <div className="billing-info__form--bank-info__wrapper">
            <div className="bank-info-label">
              Bank details
            </div>
            <div className="billing-info__form--bank-info" id="bank-info">
              <div className="billing-info__form-row">
                <Controller
                  name="bank_name"
                  control={control}
                  defaultValue={companyBillingInfo.bank_details?.bank_name}
                  render={({
                    onBlur, value, touched, field, onChange,
                  }) => (
                    <FormsCollection.Input
                      name="bank_name"
                      value={value}
                      error={propOr('', 'message', errors.bank_name?.message)}
                      onBlur={onBlur}
                      touched={touched}
                      disabled={isDisabled}
                      onChange={onChange}
                      id="form-billing-info__field-bank_name"
                      placeholderJump="Bank name"
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="billing-info__form-row">
                <Controller
                  name="bank_address"
                  control={control}
                  defaultValue={companyBillingInfo.bank_details?.bank_address || ''}
                  render={({
                    onBlur, value, touched, field, onChange,
                  }) => (
                    <FormsCollection.Input
                      name="bank_address"
                      value={value}
                      error={propOr('', 'message', errors.bank_address?.message)}
                      onBlur={onBlur}
                      touched={touched}
                      disabled={isDisabled}
                      onChange={onChange}
                      id="form-billing-info__field-bank_address"
                      placeholderJump="Bank address"
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="billing-info__form-row">
                <Controller
                  name="bic"
                  control={control}
                  defaultValue={companyBillingInfo.bank_details?.bic || ''}
                  render={({
                    onBlur, value, touched, field, onChange,
                  }) => (
                    <FormsCollection.Input
                      name="bic"
                      value={value}
                      error={propOr('', 'message', errors.bic?.message)}
                      onBlur={onBlur}
                      touched={touched}
                      disabled={isDisabled}
                      onChange={onChange}
                      id="form-billing-info__field-bic"
                      placeholderJump="BIC"
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="billing-info__form-row">
                <Controller
                  name="iban"
                  control={control}
                  defaultValue={companyBillingInfo.bank_details?.iban || ''}
                  render={({
                    onBlur, value, touched, field, onChange,
                  }) => (
                    <FormsCollection.Input
                      name="iban"
                      value={value}
                      error={propOr('', 'message', errors.iban?.message)}
                      onBlur={onBlur}
                      touched={touched}
                      disabled={isDisabled}
                      onChange={onChange}
                      id="form-billing-info__field-iban"
                      placeholderJump="IBAN"
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="billing-info__form-row">
                <Controller
                  name="swift_code"
                  control={control}
                  defaultValue={companyBillingInfo.bank_details?.swift_code || ''}
                  render={({
                    onBlur, value, touched, field, onChange,
                  }) => (
                    <FormsCollection.Input
                      name="swift_code"
                      value={value}
                      error={propOr('', 'message', errors.swift_code?.message)}
                      onBlur={onBlur}
                      touched={touched}
                      disabled={isDisabled}
                      onChange={onChange}
                      id="form-billing-info__field-swift_code"
                      placeholderJump="SWIFT code"
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="billing-info__form-row">
                <Controller
                  name="bank_country"
                  control={control}
                  defaultValue={companyBillingInfo.bank_details?.bank_country || ''}
                  render={({
                    onBlur, value, touched, field, onChange,
                  }) => (
                    <FormsCollection.Input
                      name="bank_country"
                      value={value}
                      error={propOr('', 'message', errors.bank_country?.message)}
                      onBlur={onBlur}
                      touched={touched}
                      disabled={isDisabled}
                      onChange={onChange}
                      id="form-billing-info__field-bank_country"
                      placeholderJump="Bank country"
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </FormsCollection.FormWrapper>
  );
};
