import {
  compose, withHandlers, getContext, withProps, lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import CategoriesItem from './categoriesItem';
import { projectSelectors } from '../../../../../state/project';
import { uiActions } from '../../../../../state/ui';
import { withUrlParams } from '../../../../../utils/enchancers';

const mapStateToProps = (state, { categoryId }) => ({
  category: projectSelectors.getCategoryTasks(state)(categoryId),
});

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
};

const onEditHandler = ({ setOpenModal, category: { id }, setSelectedCategoryId }) => (e) => {
  e.preventDefault();
  setSelectedCategoryId(id);
  setOpenModal('editCategory');
};

const onClickHandler = ({ setSelectedCategoryId, categoryId }) => () => {
  setSelectedCategoryId(categoryId);
};

const onDeleteHandler = ({ setOpenModal, category: { id }, setSelectedCategoryId }) => (e) => {
  e.preventDefault();
  setSelectedCategoryId(id);
  setOpenModal('deleteCategory');
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withUrlParams({}),
  withTranslation(['common', 'tasks']),
  getContext({
    setSelectedCategoryId: PropTypes.func.isRequired,
  }),
  withProps(({
    category: { id },
    selectedCategoryId,
  }) => ({ isSelected: selectedCategoryId === id })),
  withHandlers({
    onEdit: onEditHandler,
    onDelete: onDeleteHandler,
    onClick: onClickHandler,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { selectedCategoryId: prevSelectedCategoryId } = prevProps;
      const { selectedCategoryId, onSetUrlParam } = this.props;
      if (selectedCategoryId && prevSelectedCategoryId !== selectedCategoryId) {
        onSetUrlParam({ category: selectedCategoryId });
      }
    },
  }),
);

export default enhance(CategoriesItem);
