import { compose } from 'recompose';
import { connect } from 'react-redux';

import ChannelItem from './channelItem';
import { messengerSelectors } from '../../../../../../state/messenger';

const mapStateToProps = state => ({
  groupChannels: messengerSelectors.getGroupChannels(state),
});

const enhance = compose(
  connect(mapStateToProps),
);

export default enhance(ChannelItem);
