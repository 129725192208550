import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import DeleteProductModal from './deleteProductModal';
import { uiActions, uiSelectors } from '../../../../../../state/ui';


const mapStateToProps = state => ({
  isOpen: uiSelectors.getModal(state)('deleteProductModal'),
});

const mapDispatchToProps = {
  closeModal: () => uiActions.closeModal('deleteProductModal'),
};

const onCloseHandler = ({
  closeModal,
}) => () => {
  closeModal();
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onClose: onCloseHandler,
  }),
);

export default enhance(DeleteProductModal);
