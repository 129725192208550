import {
  compose, withHandlers, withState, withContext, lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Contacts from './contacts';
import { uiActions } from '../../../../state/ui';
import { withWindowWidth } from '../../../../utils/enchancers';

const mapDispatchToProps = {
  openModal: uiActions.openModal,
  setAppTitle: uiActions.setAppTitle,
};

const onCreateContactModalHandler = ({
  openModal,
}) => () => {
  openModal('createContactModal');
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  withWindowWidth(),
  withTranslation(['common', 'chat']),
  withState('selectedContactId', 'setSelectedContactId', null),
  withState('selectedPinnedContactId', 'setSelectedPinnedContactId', null),
  withContext({
    setSelectedContactId: PropTypes.func,
    setSelectedPinnedContactId: PropTypes.func,
    selectedPinnedContactId: PropTypes.any,
  }, ({
    setSelectedContactId,
    setSelectedPinnedContactId,
    selectedPinnedContactId,
  }) => ({
    setSelectedContactId,
    setSelectedPinnedContactId,
    selectedPinnedContactId,
  })),
  withHandlers({
    onCreateContactModal: onCreateContactModalHandler,
  }),
  lifecycle({
    componentDidMount() {
      this.props.setAppTitle('Contacts - CRM - Avanga 2.0');
    },
  }),
);
export default enhance(Contacts);
