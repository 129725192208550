import React, { useCallback, useEffect , useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import api from '@/api/knowledgeBase.service';
import { ACTIONS, MODULES } from '@/constants/pemissions';
import { WINDOW_WIDTH } from '@/constants/ui';
import { ButtonsCollection, Header, PermissionElement } from '@/ui-kit';
import { useWindowWidth } from '@/utils/hooks';
import { ArticleItem } from '@/views/knowledgeBase/components/subcategory/components/articleItem';
import { DeleteArticleModal } from '@/views/knowledgeBase/components/subcategory/components/deleteArticle';
import { useKnowledgeBaseContext } from '@/views/knowledgeBase/context/useKnowledgeBaseContext';
import './style.sass';

export const Subcategory = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();

  const { push } = useHistory();
  const { subcategoryId } = useParams();

  const { shouldRefreshArticles, setShouldRefreshArticles } = useKnowledgeBaseContext();

  const [subcategoryData, setSubcategoryData] = useState(null);
  const [articlesData, setArticlesData] = useState([]);
  const [isOpenedDeleteArticleModal, setIsOpenedDeleteArticleModal] = useState(false);

  const fetchArticles = async () => {
    const { data } = await api.getKnowledgeBaseArticles(subcategoryId);

    setArticlesData(data);
  };

  const fetchSubcategory = async () => {
    const { kbCategory } = await api.getKnowledgeBaseCategoryById(subcategoryId);

    setSubcategoryData(kbCategory);
  };

  useEffect(() => {
    fetchSubcategory();
  }, []);

  useEffect(() => {
    if (shouldRefreshArticles) {
      fetchArticles();

      setShouldRefreshArticles(false);
    }
  }, [shouldRefreshArticles]);

  const onClose = useCallback(() => {
    push('/knowledge-base/')
  }, []);

  const onOpenDeleteArticleModal = useCallback(() => {
    setIsOpenedDeleteArticleModal(prevState => !prevState);
  }, [setIsOpenedDeleteArticleModal]);

  const onCreateArticle = useCallback(() => {
    push(`/knowledge-base/subcategory/${subcategoryId}/article/create`);
  }, [subcategoryId]);

  return (
    <>
      <Header hideToggleRightSideBar className="subcategory-header">
          <h1 className="weight--medium title--sm title-page">
            <ButtonsCollection.ButtonIcons
              title={t('Go back to categories')}
              renderMobileButton
              className="subcategory-header__head-back"
              onClick={onClose}
            >
              <span className="icon-expand-arrow-left" />
            </ButtonsCollection.ButtonIcons>
            {`${subcategoryData?.description || ''}: articles`}
          </h1>
        <PermissionElement
          module={MODULES.KNOWLEDGE_BASE}
          action={ACTIONS.CREATE}
        >
          {
            windowWidth > WINDOW_WIDTH.MEDIUM ? (
              <ButtonsCollection.ButtonBrill
                onClick={onCreateArticle}
                type="button"
                className="knowledge-base-header__button-invite"
              >
                {t('Create article')}
              </ButtonsCollection.ButtonBrill>
            ) : (
              <ButtonsCollection.ButtonBrill
                onClick={onCreateArticle}
                type="button"
                renderMobileButton
                className="knowledge-base-header__button-invite"
              />
            )
          }
      </PermissionElement>
      </Header>
      <div className="main-container__content subcategory-container">
        <div className="subcategory-container__articles-list">
          {articlesData.map((article) => (
            <ArticleItem
              key={article.id}
              article={article}
              onOpenDeleteArticleModal={onOpenDeleteArticleModal}
            />
          ))}
        </div>
      </div>
      {isOpenedDeleteArticleModal && (
        <DeleteArticleModal
          isOpen={isOpenedDeleteArticleModal}
          setIsOpenedDeleteModal={setIsOpenedDeleteArticleModal}
        />
      )}
    </>
  )
}
