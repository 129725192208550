/* eslint-disable no-unneeded-ternary */
import { toast } from 'react-toastify';
import React from 'react';
import { is } from 'ramda';

// eslint-disable-next-line react/prop-types
const Noty = ({ children, iconClass }) => (
  <div className="notify__row">
    <div className="notify__icon">
      <span className={`${iconClass ? iconClass : 'icon-success-icon'}`} />
    </div>
    <b className="notify__title">{children}</b>
  </div>
);

const isStringOr = (content, alternative) => (is(String, content) ? content : alternative);

// eslint-disable-next-line import/prefer-default-export
export const callNotification = {
  error: (content, options = {}) => toast.error(<Noty iconClass="icon-cancel-icon">{isStringOr(content, 'Error')}</Noty>, options),
  success: (content, options = {}) => toast.success(<Noty>{isStringOr(content, 'Success')}</Noty>, options),
  warn: (content, options = {}) => toast.warn(<Noty iconClass="exclamation-icon">{isStringOr(content, 'Warning')}</Noty>, options),
};
