import api from '../services/api.service';
import { API_ROUTES } from '../constants/apiRoutes';
import { extractData } from '../utils/helpers/extractDataHelper';

const getDocumentsRoute = projectId => `/projects/${projectId}/documents`;

const getRepositoriesRoute = projectId => `/projects/${projectId}/gitlab_projects`;
const getRepositoriesSyncRoute = (projectId, gitlabProjectId) => `/projects/${projectId}/gitlab_projects/${gitlabProjectId}/synchronization`;
const getRepositoryCommitsRoute = (projectId, gitlabProjectId) => `/projects/${projectId}/gitlab_projects/${gitlabProjectId}/commits`;

const schema = {
  addFavoriteProject: async (params = {}) => {
    try {
      const response = await api.post(`${API_ROUTES.FAVORITE_PROJECTS}${params}`);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  removeFavoriteProject: async (params = {}) => {
    try {
      const response = await api.destroy(`${API_ROUTES.FAVORITE_PROJECTS}${params}`);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  getImportantTasksByProjectRequest: async (projectId, params = {}) => {
    try {
      const response = await api.get(`/projects/${projectId}/tasks`, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  getProjectNotes: async (projectId, params = {}) => {
    try {
      const response = await api.get(`${getDocumentsRoute(projectId)}`, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  addProjectNotes: async (projectId, params = {}) => {
    try {
      const response = await api.post(`${getDocumentsRoute(projectId)}`, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  updateProjectNote: async (projectId, noteId, params = {}) => {
    try {
      const response = await api.put(`${getDocumentsRoute(projectId)}/${noteId}`, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  removeProjectNote: async (projectId, noteId, params = {}) => {
    try {
      const response = await api.destroy(`${getDocumentsRoute(projectId)}/${noteId}`, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },

  addRepository: async (projectId, params = {}) => {
    const response = await api.post(`${getRepositoriesRoute(projectId)}`, params);
    return extractData(response);
  },

  getRepositories: async (projectId, params = {}) => {
    try {
      const response = await api.get(`${getRepositoriesRoute(projectId)}`, params);
      return extractData(response);
    } catch (error) {
      throw error;
    }
  },
  syncRepository: async (projectId, gitlabProjectId, params = {}) => {
    try {
      const response = await api.post(`${getRepositoriesSyncRoute(projectId, gitlabProjectId)}`, params);
      return extractData(response);
    } catch (error) {
      throw error;
    }
  },
  getRepositoryCommits: async (projectId, gitlabProjectId, params = {}) => {
    try {
      const response = await api.get(`${getRepositoryCommitsRoute(projectId, gitlabProjectId)}`, params);
      return extractData(response);
    } catch (error) {
      throw error;
    }
  },
};

export default schema;
