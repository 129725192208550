import React from 'react';
import PropTypes from 'prop-types';
import { RightSideBar } from '../../../../newContainers';
import { ScrollWrapper, ActiveTabTitle, SummaryCards } from '../../../../ui-kit';
import { ImportantTasks } from '../../../../newContainers/rightSideBar/components';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import './style.sass';
import api from '../../../../api/summary.service';
import { useImportantTasks } from '../../../../utils/hooks/useImportantTasks';

const RightSideBarContainer = ({
  t, setActiveTabTitleStateHandler, windowWidth, activeTabTitle,
}) => {
  const { importantTasks } = useImportantTasks();
  return (
    <>
      {
        windowWidth > WINDOW_WIDTH.SMALL ? (
          <RightSideBar className="right-side-bar--projects" title={t('Projects summary')}>
            <ScrollWrapper className="right-side-bar__scroll-wrapper">
              <SummaryCards request={api.getProjectsSummaryRequest} />
              <ImportantTasks
                activeTabTitle={activeTabTitle}
                className="show-more"
                setActiveTitle={setActiveTabTitleStateHandler}
                importantTasks={importantTasks}
              />
            </ScrollWrapper>
          </RightSideBar>
        ) : (
          <RightSideBar
            className="right-side-bar--projects"
            titleComponentRender={activeTabTitle ? (
              () => (
                <ActiveTabTitle
                  onClick={() => setActiveTabTitleStateHandler()}
                  title={activeTabTitle}
                />
              )
            ) : (
              () => <h3>{t('Tasks summary')}</h3>
            )}
          >
            <ScrollWrapper className="right-side-bar__scroll-wrapper">
              <SummaryCards request={api.getProjectsSummaryRequest} />
              <ImportantTasks
                activeTabTitle={activeTabTitle}
                setActiveTitle={setActiveTabTitleStateHandler}
                importantTasks={importantTasks}
              />
            </ScrollWrapper>
          </RightSideBar>
        )
      }
    </>
  );
};

RightSideBarContainer.propTypes = {
  windowWidth: PropTypes.number.isRequired,
  activeTabTitle: PropTypes.string,
  t: PropTypes.func.isRequired,
  setActiveTabTitleStateHandler: PropTypes.func.isRequired,
};
RightSideBarContainer.defaultProps = {
  activeTabTitle: '',
};

export default RightSideBarContainer;
