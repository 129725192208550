import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { AVAILABLE_FILE_FORMATS } from '../../../../../../constants/files';

const FilesField = ({ t }) => (
  <div className="files-field">
    <input type="file" className="files-field__input" id="files-field__input" accept={AVAILABLE_FILE_FORMATS.MESSENGER.join(',')} />
    <label htmlFor="files-field__input" className="files-field__label">{t('Drop file here or click to upload')}</label>
  </div>
);

FilesField.propTypes = {
  t: PropTypes.func.isRequired,
};

export default FilesField;
