import * as yup from 'yup';
import { isEmpty } from 'ramda';
import { helpers } from '../../../utils';

const { translateHelpers } = helpers;

const rules = yup.object().shape({
  first_name: yup
    .string()
    .required({
      field: 'first_name',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'First name'),
      },
    }),
  last_name: yup
    .string()
    .required({
      field: 'last_name',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Last name'),
      },
    }),
  email: yup.string().email({
    field: 'email',
    message: 'Email is invalid',
    params: {
      key: translateHelpers.t('common', 'Email'),
    },
  }).nullable(null),
  phone: yup
    .string(),
  client_id: yup.object()
    .test({
      test: val => val && !isEmpty(val),
      message: {
        field: 'client',
        message: 'required',
        params: {
          key: translateHelpers.t('common', 'Pipeline'),
        },
      },
    }),
  avatar: yup
    .string(),
});

export default rules;
