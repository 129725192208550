import React from 'react';
import './style.sass';
import { Emoji } from 'emoji-mart';
import classNames from 'classnames';

/**
 *
 * @param emojisAutocomplete {array}
 * @param onSetEmojiByAutocomplete {function}
 * @param emojiTyping {string}
 * @param emojiSelected {number}
 * @returns {JSX.Element}
 * @constructor
 */


export const EmojiAutoComplete = ({
  emojisAutocomplete,
  onSetEmojiByAutocomplete,
  emojiTyping,
  emojiSelected,
}) => (
  <div
    onMouseDown={e => e.preventDefault()}
    className={classNames('emoji_auto-complete', (emojisAutocomplete.length: 'emoji_auto-complete--open'))}
    aria-hidden="true"
  >
    <div className="emoji-autoComplete__header">
      <div className="emoji-autoComplete__current">
          Emoji matching{' '}<b>{` '${emojiTyping}' `}</b>
      </div>
      <div className="emoji-autoComplete__help">
        {' '}<i>Enter</i>{' '}- to set,
        {' '}<i>Left</i>{' '}or{' '}<i>Right</i>{' '}- to navigate
        {' '}<i>Esc</i>{' '}- to dismiss
      </div>
    </div>
    {emojisAutocomplete.map((emoji, index) => (
      <div
        key={`emoji_autocomplete-item${emoji.id}`}
        className={classNames('emoji-autoComplete__emoji', (index === emojiSelected: 'emoji-autoComplete__emoji--active'))}
      >
        <Emoji
          emoji={emoji}
          set="messenger"
          onClick={() => onSetEmojiByAutocomplete(emoji)}
          size={21}
        />
      </div>
    ))}
  </div>
);
