import React, { memo } from 'react';
import { ButtonsCollection, DefaultAutoComplete, FormsCollection } from '@/ui-kit';
import { FieldByOption, VariablesModal } from '@bp/components';
import { capitalizeFirstLetter } from '@bp/utils/helpers';
import { useFormView } from './useFormView';

import './style.sass';

export const FormView = memo(({ options, initialValues, onSubmit }) => {
  const {
    fieldsList,
    errors,
    value,
    values,
    handleSubmit,
    handleBlur,
    handleChange,
    onEventChange,
    setFieldValue,
    event,
  } = useFormView({
    onSubmit,
    initialValues,
  });

  return (
    <FormsCollection.FormWrapper
      className="processContainer__form-main editActionForm"
      handleSubmit={handleSubmit}
    >
      <div className="processContainer__field-group">
        <span className="processContainer__label">{capitalizeFirstLetter(event)}</span>
        <DefaultAutoComplete
          options={options}
          onChange={onEventChange}
          value={value}
          name="action"
        />
      </div>
      {fieldsList?.length && (
        <div className="editActionForm__form">
          <h4 className="editActionForm__title title--m">
            {capitalizeFirstLetter(event)} settings
          </h4>
          {fieldsList.map((filed) => (
            <FieldByOption
              isUseVariable
              config={filed}
              error={errors[filed.name]}
              value={values[filed.name]}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              key={filed.id}
            />
          ))}
        </div>
      )}
      <ButtonsCollection.ButtonBrill
        type="submit"
        className="button--md button-brill--fill editActionForm__button-save"
      >
        Save
      </ButtonsCollection.ButtonBrill>
      <VariablesModal />
    </FormsCollection.FormWrapper>
  );
});

export default FormView;
