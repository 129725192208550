import React from 'react';
import PropTypes from 'prop-types';

import { ModalWindow, ButtonsCollection, FormsCollection } from '../../../../../ui-kit';
import './style.sass';

const ModalView = ({
  isOpen,
  onCloseModal,
  handleSubmit,
  handleChange,
  values,
  errors,
  isPending,
  title,
  titleAction,
  t,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    className="edit-timeEntry__modal"
    title={title}
  >
    <FormsCollection.FormWrapper
      handleSubmit={handleSubmit}
      className="edit-timeEntry"
    >
      <div className="edit-timeEntry__row">
        <FormsCollection.DatePicker
          name="date"
          onChange={handleChange}
          value={values.date}
          error={errors.date}
          touched
        />
      </div>
      <div className="edit-timeEntry__row">
        <FormsCollection.Input
          name="time"
          id="create-task__estimated-time"
          placeholder={t('1w 2d 21h 30m')}
          onChange={handleChange}
          value={values.time}
          error={errors.time}
          touched
        />
      </div>
      <div className="button-group">
        <ButtonsCollection.ButtonBrill
          pending={isPending}
          className="button--md edit-timeEntry__button-save button-brill--fill"
          type="submit"
        >
          {titleAction}
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);

ModalView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  isPending: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titleAction: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default ModalView;
