import React from 'react';
import PropTypes from 'prop-types';
import { curry, equals } from 'ramda';
import { ButtonsCollection, ScrollWrapper, DefaultAutoComplete } from '../../../../ui-kit';
import {
  InvoiceItem, DisposableForm, AddTaxForm, AddDiscountForm,
} from './components';

import './style.sass';
import { InvoiceForm } from '../index';

const InvoiceDetails = ({
  values, setFieldValue, deleteInvoiceItem, isEditInvoicePage,
  canBeDeleted, subtotal, total, setCurrencySign, currencySign, getPaymentType,
  currencyOptions, invoiceItemsList, addInvoiceItem, changeInvoiceItemFieldValue,
  currentInvoiceTax, currentInvoiceDiscount, handleChange,
  errors, touched, currentInvoice, noticeModal, checkNoticeModal,
}) => (
  <div className="invoice-details__container">
    <ScrollWrapper>
      <InvoiceForm
        noticeModal={noticeModal}
        checkNoticeModal={checkNoticeModal}
        handleChange={handleChange}
        values={values}
        errors={errors}
        setFieldValue={setFieldValue}
        touched={touched}
        currentInvoice={currentInvoice}
        isEditInvoicePage={isEditInvoicePage}
      />
      <h3>Invoice details</h3>
      <div className="invoice-details__container--currency">
        <DefaultAutoComplete
          placeholder="Currency..."
          value={values.currency}
          name="terms"
          options={currencyOptions}
          onChange={(option) => {
            setFieldValue('currency', option);
            setCurrencySign(option.value);
          }}
        />
      </div>
      {
        invoiceItemsList.map(id => (
          <InvoiceItem
            invoiceItemId={id}
            onDelete={() => deleteInvoiceItem(id)}
            canBeDeleted={canBeDeleted}
            onChangeFieldValue={
              curry(
                (itemId, fieldName, value) => changeInvoiceItemFieldValue({
                  itemId,
                  fieldValue: {
                    [fieldName]: value,
                  },
                }),
              )(id)
            }
            key={id}
          />
        ))
      }
      <div className="invoice-details__container--controls">
        <div className="add-invoice-item__section">
          <div className="invoice-controls__row">
            <ButtonsCollection.ButtonPlus
              onClick={addInvoiceItem}
              className="invoice-controls__button"
            />
            <button
              type="button"
              onClick={addInvoiceItem}
              className="invoice-controls__label"
            >
              Add item
            </button>
          </div>
        </div>
        <div className="total-info__section">
          <div className="total-info__section--data">
            <span>Subtotal: </span>
            <span className="amount">{`${currencySign} ${subtotal}`}</span>
          </div>
          <div className="total-info__section--controls">
            <DisposableForm
              openButtonText="Add tax"
              open={isEditInvoicePage && !equals(0, currentInvoiceTax)}
            >
              <AddTaxForm
                isEditInvoicePage={isEditInvoicePage}
                getPaymentType={getPaymentType}
                currencySign={currencySign}
              />
            </DisposableForm>
            <DisposableForm
              openButtonText="Add discount"
              open={isEditInvoicePage && !equals(0, currentInvoiceDiscount)}
            >
              <AddDiscountForm
                isEditInvoicePage={isEditInvoicePage}
                getPaymentType={getPaymentType}
                currencySign={currencySign}
              />
            </DisposableForm>
          </div>
          <div className="total-info__section--data">
            <span>Total: </span>
            <span className="amount">{`${currencySign} ${total}`}</span>
          </div>
        </div>
      </div>
    </ScrollWrapper>
  </div>
);

InvoiceDetails.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  currencyOptions: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  deleteInvoiceItem: PropTypes.func.isRequired,
  canBeDeleted: PropTypes.bool.isRequired,
  subtotal: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  setCurrencySign: PropTypes.func.isRequired,
  currencySign: PropTypes.string.isRequired,
  invoiceItemsList: PropTypes.arrayOf(PropTypes.string).isRequired,
  addInvoiceItem: PropTypes.func.isRequired,
  changeInvoiceItemFieldValue: PropTypes.func.isRequired,
  isEditInvoicePage: PropTypes.bool.isRequired,
  getPaymentType: PropTypes.func.isRequired,
  currentInvoiceTax: PropTypes.string.isRequired,
  currentInvoiceDiscount: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  touched: PropTypes.instanceOf(Object).isRequired,
  currentInvoice: PropTypes.instanceOf(Object).isRequired,
  checkNoticeModal: PropTypes.func.isRequired,
  noticeModal: PropTypes.bool.isRequired,
};

export default InvoiceDetails;
