import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { path } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { push } from 'connected-react-router';

import { ButtonsCollection, Layouts, FormsCollection } from '../../../ui-kit';
import { ErrorNotification } from '../errorNotification';
import { PageError } from '../..';
import { uiActions, uiSelectors } from '../../../state/ui';
import { rules } from './rules';
import { WINDOW_TITLE } from '../../../constants/window-titles';
import './style.sass';
import api from '../../../api/auth.service';
import { APP_ROUTES } from '../../../constants/appRoutes';
import { ERROR_CODES_API } from '../../../constants/api';


/**
 * @returns {JSX.Element}
 * @constructor
 */

export const ChangePassword = () => {
  const { t } = useTranslation('validation');
  const authNotification = useSelector(uiSelectors.getAuthNotification);
  const { userId, code } = useParams();
  const dispatch = useDispatch();

  const [codeError, setCodeError] = useState(null);
  const {
    control, setValue, handleSubmit,
  } = useForm({
    resolver: yupResolver(rules),
  });

  const resetPasswordRequest = async (params) => {
    const data = await api.resetPasswordRequest(params);
    if (data.success) {
      dispatch(push(APP_ROUTES.MAIN));
    } else {
      dispatch(uiActions.displayNotification({ data }));
    }
    return data;
  };

  const checkCodeRequest = useCallback(async (params) => {
    const data = await api.checkForgotPasswordTokenRequest(params);
    setCodeError(data);
  }, []);

  useEffect(() => {
    checkCodeRequest({ id: userId, code });
    dispatch(uiActions.setAppTitle(WINDOW_TITLE.RESET_PASSWORD));
    dispatch(uiActions.displayNotification(null));
    return () => {
      dispatch(uiActions.displayNotification(null));
    };
  }, []);

  const onChange = (name, value) => {
    setValue(name, value);
  };

  const onSubmit = useCallback((values) => {
    const { password, passwordConfirm: repeat } = values;
    const data = {
      password, repeat, id: userId, code,
    };
    return resetPasswordRequest(data);
  });

  const onError = (errors) => {
    dispatch(uiActions.displayNotification(null));
    if (errors) {
      const performedErrors = Object.values(errors).reverse()[0];
      dispatch(uiActions.displayNotification({
        [performedErrors.message.field]: {
          message: performedErrors.message.message,
          params: performedErrors.message.params,
        },
      }));
    }
  };


  return (
    codeError !== ERROR_CODES_API ? (
      <Layouts.Auth>
        <ErrorNotification />
        <div className="auth-wrapper__wrap">
          <FormsCollection.FormWrapper handleSubmit={handleSubmit(onSubmit, onError)}>
            <div className="auth-wrapper__form-contain">
              <h2 className="title weight--bold title--md text-align--center">{t('Reset password')}</h2>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({
                  onBlur, value, touched, field,
                }) => (
                  <FormsCollection.Input
                    type="text"
                    hiddenEye
                    placeholderJump={t('New password')}
                    required
                    onChange={e => onChange('password', e.target.value)}
                    onBlur={onBlur}
                    error={!!path(['password'], authNotification)}
                    name="password"
                    id="reset-password__password"
                    touched={touched}
                    value={value}
                    {...field}
                  />
                )}
              />
              <Controller
                name="passwordConfirm"
                control={control}
                defaultValue=""
                render={({
                  onBlur, value, touched, field,
                }) => (
                  <FormsCollection.Input
                    type="text"
                    hiddenEye
                    placeholderJump={t('Confirm new password')}
                    required
                    onChange={e => onChange('passwordConfirm', e.target.value)}
                    onBlur={onBlur}
                    error={!!path(['passwordConfirm'], authNotification)}
                    name="passwordConfirm"
                    id="reset-password__confirm-password"
                    touched={touched}
                    value={value}
                    {...field}
                  />
                )}
              />
              <div className="button-group">
                <ButtonsCollection.ButtonBrill
                  type="submit"
                  className="auth-wrapper__button--with-border button--fill"
                  borderColor="#26b5ed"
                  borderWidth="2"
                >
                  {t('Confirm')}
                </ButtonsCollection.ButtonBrill>
              </div>
              <div className="auth-wrapper__nav-group">
                <nav className="auth-wrapper__nav">
                  <ul className="auth-wrapper__list">
                    <li className="auth-wrapper__item">
                      <Link to="/sign-up" className="auth-wrapper__link">
                        {t('Create an Account')}
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </FormsCollection.FormWrapper>
        </div>
      </Layouts.Auth>
    ) : (
      <PageError />
    )
  );
};
