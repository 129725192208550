import React from 'react';
import PropTypes from 'prop-types';
import { ButtonsCollection, DropDown } from '../../../../../../ui-kit';

import './style.sass';

const getInvoiceCardInfo = info => info && (
  <b className="pipe-view-invoice__body-info">
    {info}
  </b>
);

const InvoiceCard = ({
  t,
  isDragging,
  invoice,
  onDeleteInvoiceModal,
  projectTitle,
  openEditPage,
}) => (
  <div
    role="tab"
    tabIndex="-1"
    className={`pipe-view-invoice ${isDragging ? 'pipe-view-invoice--drag' : ''}`}
  >
    <div className="pipe-view-invoice__header">
      <h4 className="pipe-view-invoice__title">{invoice.number}</h4>
      <DropDown
        className="pipe-view-invoice-control"
        label={(
          <ButtonsCollection.ButtonIcons
            className="pipe-view-invoice__controls-button"
            title="Controls for sprint"
          >
            <span className="table-leads__control-icon icon-settings-vertical">
              <span
                className="path1"
              />
              <span className="path2" />
              <span
                className="path3"
              />
            </span>
          </ButtonsCollection.ButtonIcons>
          )}
        list={
          [
            {
              id: 0,
              onClick: openEditPage(invoice.id),
              label: (
                <>
                  <span className="pipe-view-invoice-control__icon icon-pencil-2" />
                  <span className="pipe-view-invoice-control__text">{t('Edit')}</span>
                </>
              ),
            },
            {
              id: 2,
              onClick: onDeleteInvoiceModal,
              label: (
                <>
                  <span className="pipe-view-invoice-control__icon icon-delete-icon" />
                  <span className="pipe-view-invoice-control__text">{t('Delete')}</span>
                </>
              ),
            },
          ]
        }
      />
    </div>
    <div className="pipe-view-invoice__body">
      {getInvoiceCardInfo(projectTitle)}
      {getInvoiceCardInfo(invoice.total)}
    </div>
  </div>
);


InvoiceCard.propTypes = {
  invoice: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onDeleteInvoiceModal: PropTypes.func.isRequired,
  projectTitle: PropTypes.string.isRequired,
  openEditPage: PropTypes.func.isRequired,
};


export default InvoiceCard;
