import React from 'react';

const spreadObjectToProps = Component => function SpreadObjectToProps(props) {
  if (props.hasOwnProperty('pagination')) {
    const { limit, offset = 0, page } = props.pagination;
    const propsWithoutPagination = { ...props };
    delete propsWithoutPagination.pagination;

    return <Component {...propsWithoutPagination} limit={limit} offset={offset} page={page} />;
  }
  return <Component {...props} />;
};

export default spreadObjectToProps;
