import React, { useEffect , useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { permissionsActions } from '@/state/permissions';
import { AsyncAutoComplete, ButtonsCollection, FormsCollection, ModalWindow } from '@/ui-kit';
import { useKnowledgeBaseContext } from '@/views/knowledgeBase/context/useKnowledgeBaseContext';
import api from '../../../../../../../api/knowledgeBase.service';
import './style.sass';

const AUTOCOMPLETE_LIMIT = 10;

export const EditCategoryModal = ({ isOpen, setIsOpenedModal }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const {
    categoryData,
    setCategoryId,
    setCategoryData,
    refreshCategories,
  } = useKnowledgeBaseContext();


  const {
    control, reset, handleSubmit, formState: { errors },
  } = useForm({
    defaultValues: {
      description: '',
      roles: []
    }
  });

  const getRolesAutocompleteAction = useCallback((object, callback) => {
    dispatch(permissionsActions.getRolesAutocompleteRequest(object, callback));
  }, []);


  const getAutocompleteHandler = (fieldValue, callback) => {
    getRolesAutocompleteAction({
      q: fieldValue,
      limit: AUTOCOMPLETE_LIMIT,
    }, {
      callbacks: {
        success: ({ roles: rolesData }) => {
          const rolesResult = rolesData.map((role) => ({label: role.name, value: role.id}));
          callback(rolesResult);
        },
      },
    });
  };


  
  const onCloseModal = () => {
    reset();
    setCategoryId(null);
    setCategoryData(null);
    setIsOpenedModal(false);
  };

  const onSubmit = async (data) => {
    try {
      await api.updateKnowledgeBaseCategory(categoryData?.id, {
        description: data.description,
        roleIds: data.roles.map(role => role.value)
      });

      refreshCategories();

      onCloseModal();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (categoryData) {
      reset({
        description: categoryData?.description,
        roles: categoryData?.roles.map(role => ({label: role.name, value: role.id}))
      })
    }
  }, [categoryData])


  return (
    <ModalWindow
      isOpen={isOpen}
      onClose={onCloseModal}
      modalName="editCategoryModal"
      title="Edit category"
      className="modal-window__edit-category"
    >
      <FormsCollection.FormWrapper handleSubmit={handleSubmit(onSubmit)} className="edit-category">
        <div className="edit-category__row">
          <Controller
            name="description"
            control={control}
            render={({ onBlur, value, field, onChange, error }) => (
              <FormsCollection.Input
                placeholderJump="Category title"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                touched
                error={error}
                {...field}
              />
            )}
          />
        </div>
        <div className="edit-category__row">
          <Controller
            name="roles"
            control={control}
            render={({
              onBlur, value, touched, field,
            }) => (
              <AsyncAutoComplete
              isMulti
              value={value}
              error={errors.role_id?.message}
              touched={touched}
              onBlur={onBlur}
              name="roles"
              placeholder={t('Roles')}
              loadOptions={getAutocompleteHandler}
              {...field}
                />
                )}
                />
        </div>
        <div className="button-group">
          <ButtonsCollection.ButtonBrill
            type="submit"
            className="button--md edit-category__button-save button-brill--fill"
          >
            {t('Save')}
          </ButtonsCollection.ButtonBrill>
        </div>
      </FormsCollection.FormWrapper>
    </ModalWindow>
  )
}
