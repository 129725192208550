import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { callNotification } from '@/utils/helpers/notifies';
import { ButtonsCollection, ModalWindow, FormsCollection } from "../../..";
import api from '../../../../api/projects.service';
import { useProjectNotesContext } from '../../context/useProjectNotesContext';
import rules from '../rules';

import './style.sass';

const MAX_TEXTAREA_HEIGHT = 190;

export const EditNote = () => {
  const {
    selectedNoteId,
    notes,
    setNotes,
    isOpenEditModal,
    setIsOpenEditModal,
  } = useProjectNotesContext();
  const { id: projectId } = useParams();
  const { t } = useTranslation(['common', 'tasks']);
  const [isPending, setIsPending] = useState(false);
  const note = notes.find(({ id }) => id === selectedNoteId);

  const {
    control, handleSubmit, reset, formState: { errors },
  } = useForm({
    resolver: yupResolver(rules),
    defaultValues: {
      title: '',
      content: '',
    },
  });

  const onCloseModal = useCallback(() => {
    reset();
    setIsOpenEditModal(false);
  }, []);

  const updateNote = async (params) => {
    try {
      setIsPending(true);
      const updatedNote = await api.updateProjectNote(projectId, note.id, params);
      return updatedNote;
    } catch (error) {
      callNotification.error('Note cannot be updated');
    } finally {
      setIsPending(false);
    }
  };

  const onFormSubmit = async (data) => {
    const { document } = await updateNote(data);
    setNotes(prev => prev.map(item => (item.id === document.id ? document : item)));
    onCloseModal();
  };

  useEffect(() => {
    reset({
      title: note?.title,
      content: note?.content,
    });
  }, [note]);


  return (
    <>
      {
        note
        && (
        <ModalWindow
          isOpen={isOpenEditModal}
          onClose={onCloseModal}
          className="crud-notes__modal"
          title={t('Edit note')}
        >
          <FormsCollection.FormWrapper
            handleSubmit={handleSubmit(onFormSubmit)}
            className="crud-notes"
          >
            <div className="crud-notes__row">
              <Controller
                name="title"
                control={control}
                render={({
                  onBlur, value, field, onChange,
                }) => (
                  <FormsCollection.Input
                    placeholderJump="Note title"
                    className="crud-notes__title-field"
                    id="crud-notes__title"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    touched
                    error={errors.title?.message}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="crud-notes__row crud-notes__row--description">
              <Controller
                name="content"
                control={control}
                render={({
                  onBlur, value, field, onChange,
                }) => (
                  <FormsCollection.TextArea
                    name="content"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    touched
                    className="crud-notes__textarea"
                    placeholderJump="Description"
                    autoHeight
                    maxHeight={MAX_TEXTAREA_HEIGHT}
                    error={errors.content?.message}
                    isNotValidationPasteText={false}
                    {...field}
                  />)}
              />
            </div>
            <div className="button-group">
              <ButtonsCollection.ButtonBrill
                pending={isPending}
                type="submit"
                className="button--md crud-notes__button-save button-brill--fill"
              >
                {t('Save')}
              </ButtonsCollection.ButtonBrill>
            </div>
          </FormsCollection.FormWrapper>
        </ModalWindow>
        )}
    </>
  );
};
