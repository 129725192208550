import {
  compose, withHandlers, withState, lifecycle, branch, renderNothing,
} from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';

import {
  always,
  concat,
  filter,
  inc,
  last,
  path,
  when,
  propOr,
  map,
  mergeAll,
  addIndex,
  cond,
  equals,
  T,
  endsWith,
  keys,
  omit,
  find,
  propEq,
  isEmpty,
} from 'ramda';
import { isNotEmpty, notEqual } from 'ramda-extension';
import EditPipelineModal from './editPipelineModal';
import { uiActions, uiSelectors } from '../../../../state/ui';
import { PIPELINE_STATUSES, PIPELINE_TYPES } from '../../../../constants/crm';
import { pipelinesActions, pipelinesSelectors } from '../../../../state/pipelines';
import rulesWithFields from '../rules';

const mapIndexed = addIndex(map);

const mapStateToProps = (state, { selectedPipelineId }) => ({
  isOpen: uiSelectors.getModal(state)('editPipelineModal'),
  pipeline: pipelinesSelectors.getPipelineById(state)(selectedPipelineId),
  pipelineStatuses: pipelinesSelectors.getPipelineStatusesSelector(state)(selectedPipelineId),
  isPipelineStatusesPending: pipelinesSelectors.getPipelineStatusesPendingRequest(state),
  isPending: pipelinesSelectors.editPipelinePendingRequest(state),
});

const mapDispatchToProps = {
  closeModal: () => uiActions.closeModal('editPipelineModal'),
  getPipelineStatusesRequest: pipelinesActions.getPipelineStatusesRequest,
  editPipeline: pipelinesActions.editPipelineRequest,
};

const valueInArray = val => [val];

const onAddNewStatusHandler = ({ statusesList, setStatusesList }) => compose(setStatusesList,
  concat(statusesList), valueInArray, inc, last, always(statusesList));

const onDeleteStatusHandler = ({ statusesList, setStatusesList }) => compose(
  when(isNotEmpty, setStatusesList),
  item => filter(notEqual(item), statusesList),
  Number,
  path(['target', 'dataset', 'index']),
);

const onCloseModalHandler = ({ closeModal }) => () => {
  closeModal();
};

const onSetStatusesList = ({ setStatusesList, pipelineStatuses }) => {
  setStatusesList(mapIndexed((item, index) => index, pipelineStatuses));
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('statusesList', 'setStatusesList', [0]),
  branch(({ pipeline }) => !pipeline || isEmpty(pipeline), renderNothing),
  withHandlers({
    onAddNeStatus: onAddNewStatusHandler,
    onDeleteStatus: onDeleteStatusHandler,
  }),
  lifecycle({
    componentDidMount() {
      const {
        getPipelineStatusesRequest,
        setStatusesList,
        pipelineStatuses,
        pipeline: { id: pipelineId },
      } = this.props;
      getPipelineStatusesRequest({ pipelineId });
      if (pipelineStatuses.length) {
        onSetStatusesList({ setStatusesList, pipelineStatuses });
      }
    },
    componentDidUpdate(prevState) {
      const {
        setStatusesList,
        pipelineStatuses,
        getPipelineStatusesRequest,
        pipeline: { id: pipelineId },
      } = this.props;
      if (pipelineStatuses.length !== prevState.pipelineStatuses.length) {
        onSetStatusesList({ setStatusesList, pipelineStatuses });
      }
      if (pipelineId !== prevState.pipeline.id) {
        getPipelineStatusesRequest({ pipelineId });
      }
    },
  }),
  branch(({ isPipelineStatusesPending }) => isPipelineStatusesPending, renderNothing),
  withFormik({
    mapPropsToValues: ({ pipeline, pipelineStatuses }) => {
      const statuses = compose(
        mergeAll,
        mapIndexed((status, index) => {
          const progress = cond([
            [equals(PIPELINE_TYPES.IN_PROGRESS), () => status.progress / 10],
            [equals(PIPELINE_TYPES.WON), () => PIPELINE_STATUSES.WON],
            [equals(PIPELINE_TYPES.LOST), () => PIPELINE_STATUSES.LOST],
          ])(status.type);
          return {
            [index]: status.title,
            [`${index}_progress`]: progress,
            [`${index}_id`]: status.id,
          };
        }),
      )(pipelineStatuses);

      return {
        title: propOr('', 'title', pipeline),
        ...statuses,
      };
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: ({ statusesList }) => rulesWithFields(statusesList),
    handleSubmit: (formData, {
      resetForm,
      props: {
        closeModal,
        editPipeline,
        onSuccess,
        pipelineStatuses,
        pipeline,
        statusesList,
        getPipelineStatusesRequest,
      },
    }) => {
      const statuses = compose(
        map((item) => {
          const progressFormData = formData[`${item}_progress`] || 0;
          const progress = progressFormData > 10 ? 100 : progressFormData * 10;
          const id = formData[`${item}_id`];
          const statusData = id ? find(propEq('id', id), pipelineStatuses) : {};

          const type = cond([
            [equals(PIPELINE_STATUSES.WON), always(PIPELINE_TYPES.WON)],
            [equals(PIPELINE_STATUSES.LOST), always(PIPELINE_TYPES.LOST)],
            [T, always(PIPELINE_TYPES.IN_PROGRESS)],
          ])(progressFormData);
          return {
            ...statusData,
            title: formData[item],
            pipeline_id: pipeline.id,
            progress,
            type,
          };
        }),
        filter(item => statusesList.includes(+item)),
        filter(item => !endsWith('_progress', item) && !endsWith('_id', item)),
        keys,
        omit(['title']),
      )(formData);
      const data = {
        ...pipeline,
        title: formData.title,
        statuses,
      };
      editPipeline({
        id: pipeline.id,
        ...data,
      }, {
        callbacks: {
          success: () => {
            closeModal();
            resetForm({});
            getPipelineStatusesRequest({ pipelineId: pipeline.id });
            if (onSuccess) {
              onSuccess();
            }
          },
        },
      });
    },
  }),
  withHandlers({
    onCloseModal: onCloseModalHandler,
  }),
);
export default enhance(EditPipelineModal);
