import React from 'react';
import PropTypes from 'prop-types';

import { DropDown, FormsCollection } from '../../../../ui-kit';
import './style.sass';

const FiltersRow = ({
  onChangeSorting, sortingByDate,
  searchName, setSearchContactName, onSearch, t,
}) => (
  <div className="contacts-filter">
    <div className="contacts-filter__sort-group">
      <FormsCollection.Search
        className="search-field--circle"
        placeholder={t('Search by contact name')}
        name="search-contact"
        value={searchName}
        onChange={setSearchContactName}
        onSearch={onSearch}
        withoutControl
      />
      <DropDown
        label={`${t('Sort')} : `}
        placeholder="Custom"
        name="filter-row"
        onChange={onChangeSorting}
        currentItem={sortingByDate}
        list={
          [
            {
              id: 0,
              order: 'desc',
              sortBy: 'created_at',
              label: t('Recent'),
            },
            {
              id: 1,
              order: 'asc',
              sortBy: 'created_at',
              label: t('Old'),
            },
          ]
        }
      />
    </div>
  </div>
);

FiltersRow.propTypes = {
  searchName: PropTypes.string.isRequired,
  setSearchContactName: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onChangeSorting: PropTypes.func.isRequired,
  sortingByDate: PropTypes.number,
  t: PropTypes.func.isRequired,
};

FiltersRow.defaultProps = {
  sortingByDate: null,
};

export default FiltersRow;
