const TASK = 'TASK';
const TASKS = 'TASKS';
const MY_TASKS = 'MY_TASKS';

export default {
  GET_TASKS_REQUEST: `GET_${TASKS}_REQUEST`,
  GET_MY_TASKS_REQUEST: `GET_${MY_TASKS}_REQUEST`,
  GET_TASK_REQUEST: `GET_${TASK}_REQUEST`,
  GET_MY_TASKS_SUMMARY_REQUEST: 'GET_MY_TASKS_SUMMARY_REQUEST',
  GET_MY_TASKS_BY_PROJECTS_REQUEST: 'GET_MY_TASKS_BY_PROJECTS_REQUEST',
  SET_MY_TASKS_BY_PROJECTS: 'SET_MY_TASKS_BY_PROJECTS',
  GET_MY_PROJECT_TASKS_REQUEST: 'GET_MY_PROJECT_TASKS_REQUEST',
  SET_MY_PROJECT_TASK: 'SET_MY_PROJECT_TASK',

  ADD_TASK_REQUEST: `ADD_${TASK}_REQUEST`,
  UPDATE_TASK_REQUEST: `UPDATE_${TASK}_REQUEST`,
  DELETE_TASK_REQUEST: `DELETE_${TASK}_REQUEST`,

  SET_TASKS: `SET_${TASKS}`,
  SET_TASK: `SET_${TASK}`,
  SET_MY_TASKS: `SET_${MY_TASKS}`,
  DELETE_TASK: `DELETE_${TASK}`,
  PIN_TASK_REQUEST: 'PIN_TASK_REQUEST',
  SET_MY_TASKS_SUMMARY: 'SET_MY_TASKS_SUMMARY',

  UNPIN_TASK_REQUEST: 'UNPIN_TASK_REQUEST',
};
