import * as yup from 'yup';
import { translateHelpers } from '../../../utils/helpers';

export const rules = yup.object().shape({
  email: yup
    .string()
    .email({
      field: 'email',
      message: 'email',
      params: {
        key: translateHelpers.t('common', 'Email address'),
      },
    })
    .required({
      field: 'email',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Email address'),
      },
    }),
});
