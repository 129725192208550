import { mapObjIndexed } from 'ramda';
import { lifecycle, withHandlers, compose } from 'recompose';
import { withRefs } from './index';

const CLASS_NAME_MAIN_WRAPPER = 'brillMakerMainWrapper';

const gridBrillMaker = ({ getRef }) => {
  const wrapperProjects = getRef(CLASS_NAME_MAIN_WRAPPER);
  const initBrillGrid = () => {
    if (wrapperProjects) {
      wrapperProjects.style.width = '100%';
      const getCountBrillInOneRow = (wrapper, brill) => {
        if (brill) {
          const widthWrapper = wrapper.offsetWidth;
          const widthBrill = brill.offsetWidth + 8; // 16 - is size padding left and right
          wrapperProjects.style.width = `${widthBrill * Math.floor(widthWrapper / widthBrill)}px`;
          return Math.floor(widthWrapper / widthBrill);
        }
        return null;
      };
      const makeRows = (wrapper, countInRow) => {
        const currentRow = true; // true - long, false - short
        let breakIndexLeft = countInRow;
        let breakIndexRight = (countInRow + (countInRow - 2));
        mapObjIndexed((item, index) => {
          const indexToInt = parseInt(index, 10);
          if (currentRow) {
            if (indexToInt && (indexToInt === breakIndexLeft)) {
              item.removeAttribute('data-offset-left');
              item.setAttribute('data-offset-left', true);
              breakIndexLeft += (countInRow + (countInRow - 1));
            } else if (indexToInt && (indexToInt === breakIndexRight)) {
              item.removeAttribute('data-offset-right');
              item.setAttribute('data-offset-right', true);
              breakIndexRight += (countInRow + (countInRow - 1));
            } else {
              item.removeAttribute('data-offset-right');
              item.removeAttribute('data-offset-left');
            }
          }
        }, wrapper.children);
      };
      const countInOneRow = getCountBrillInOneRow(wrapperProjects, wrapperProjects.firstChild);
      makeRows(wrapperProjects, countInOneRow);
    }
  };
  initBrillGrid();
  window.addEventListener('resize', () => {
    initBrillGrid();
  });
};

const refBrillBuilderHandler = ({ setRef }) => e => setRef(CLASS_NAME_MAIN_WRAPPER, e);

const withBrillMaker = setterName => compose(
  withRefs(),
  withHandlers({
    [setterName]: refBrillBuilderHandler,
  }),
  lifecycle({
    componentDidMount() {
      gridBrillMaker(this.props);
    },
    componentDidUpdate() {
      gridBrillMaker(this.props);
    },
  }),
);

export default withBrillMaker;
