import React from 'react';
import classNames from 'classnames';

/**
 *
 * @param item {object}
 * @param onChangeValue {function}
 * @param ItemLabel
 * @param isMulti {boolean}
 * @param wrapperProps {object}
 * @param showCheckbox {boolean}
 * @returns {JSX.Element}
 * @constructor
 */

export const DropDownItem = ({
  item,
  onChangeValue,
  ItemLabel,
  isMulti = false,
  wrapperProps = {},
  showCheckbox = true,
}) => (
  !isMulti ? (
    <li>
      <button
        type="button"
        className="btn"
        onClick={(e) => {
          e.preventDefault();
          onChangeValue(e, item);
        }}
        {...wrapperProps}
      >
        { item.label }
      </button>
    </li>
  ) : (
    <li>
      <button
        type="button"
        className={classNames('btn drop-down__multi-btn', item.select && 'drop-down__multi-btn--selected')}
        onClick={e => onChangeValue(e, item)}
        {...wrapperProps}
      >
        {showCheckbox && (
        <div className="drop-down__multi-checkbox">
          <span className="drop-down__multi-checkbox-icon icon-ok" />
        </div>
        )}
        <ItemLabel alt={item.alt} src={item.src} />
      </button>
    </li>
  )
);
