import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InviteMemberButton } from '../../pages/messenger/components';
import { MemberItem, DeleteMemberModal, InviteMembersModal } from './components';
import { usersSelectors } from '../../state/users';
import { getMembersOnlineCount } from '../../utils/helpers/userHelpers/status';

/**
 *
 * @param membersList {array}
 * @param isPendingDelete {boolean}
 * @param isPendingInvite {boolean}
 * @param onDeleteMember {function}
 * @param onInviteMember {function}
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

export const MembersView = ({
  membersList,
  isPendingDelete,
  isPendingInvite,
  onDeleteMember,
  onInviteMember,
  ...props
}) => {
  const [selectedMemberId, setSelectedMemberId] = useState(0);
  const [isOpenedDeleteModal, setIsOpenedDeleteModal] = useState(false);
  const [isOpenedInviteModal, setIsOpenedInviteModal] = useState(false);
  const users = useSelector(usersSelectors.getUsersEntities);
  const { t } = useTranslation('common');

  const countOnlineMembers = getMembersOnlineCount(membersList, users);
  const countMembers = membersList.length;

  const onSetModalDeleteMember = (memberId) => {
    setSelectedMemberId(memberId);
    setIsOpenedDeleteModal(true);
  };

  const onSetModalInviteMember = () => setIsOpenedInviteModal(true);

  const onDelete = () => {
    onDeleteMember(selectedMemberId, {
      success: setIsOpenedDeleteModal(false),
    });
  };

  const onInvite = (memberIds, callbacks) => {
    onInviteMember(memberIds, callbacks);
  };

  return (
    <div className="project-members">
      <div className="project-members__content">
        <h5 className="project-members__title">
          {t('Online')}
          {` ${countOnlineMembers}/${countMembers}`}
        </h5>
        <ul className="project-members__list">
          {membersList.map(memberId => (
            <MemberItem
              key={memberId}
              memberId={memberId}
              onSetModalDeleteMember={onSetModalDeleteMember}
            />
          ))}
        </ul>
      </div>
      { isOpenedDeleteModal && (
        <DeleteMemberModal
          onDelete={onDelete}
          isPending={isPendingDelete}
          memberId={selectedMemberId}
          isOpen={isOpenedDeleteModal}
          setIsOpenedDeleteModal={setIsOpenedDeleteModal}
          {...props}
        />
      )}
      { isOpenedInviteModal && (
        <InviteMembersModal
          isPending={isPendingInvite}
          onInvite={onInvite}
          membersList={membersList}
          isOpen={isOpenedInviteModal}
          setIsOpenedInviteModal={setIsOpenedInviteModal}
          {...props}
        />
      )}
      <InviteMemberButton
        className="invite-members--right-side-bar"
        onClick={onSetModalInviteMember}
      />
    </div>
  );
};
