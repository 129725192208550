import React from 'react';
import {
  Tabs, TabPanel, TabList, Tab,
} from 'react-tabs';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import {
  TaskAbout,
  AttachedFiles,
  TimeEntry,
  Comments,
  History,
} from './components';
import { Label, PreLoaderWhileLoading, ScrollWrapper } from '../../ui-kit';
import { GitlabCommitsByTask } from '../../ui-kit/Gitlab/GitlabCommits/GitlabCommitsByTask';
import './style.sass';

const PRELOADER_DIMENSION = 100;

const Task = ({
  task, t, onScrollSetRef, scrollElement, isLoading, filesResult,
}) => (
  <PreLoaderWhileLoading
    dimension={PRELOADER_DIMENSION}
    alignContainerCenter
    isLoading={isLoading || !task.title}
  >
    <ScrollWrapper refCustom={onScrollSetRef}>
      <div className="task">
        <TaskAbout task={task} />
        <Tabs selectedTabClassName="task-navigation__link--active">
          <div className="task-navigation">
            <TabList className="task-navigation__list">
              <Tab className="task-navigation__link">{t('Comments')}</Tab>
              <Tab className="task-navigation__link">{t('Time Entry')}</Tab>
              <Tab className={classNames('task-navigation__link', filesResult?.length > 0 && 'task-navigation__unread-attach')}>{t('Attached Files')}
                {
                  filesResult?.length > 0 && (
                    <Label
                      className="label--xs label--fill"
                    >
                      {filesResult.length}
                    </Label>
                  )
                }
              </Tab>
              <Tab className="task-navigation__link">{t('History')}</Tab>
              {/* <Tab className="task-navigation__link">{t('Commits')}</Tab> */}
            </TabList>
          </div>
          <TabPanel>
            <Comments scrollElement={scrollElement} />
          </TabPanel>
          <TabPanel>
            <TimeEntry />
          </TabPanel>
          <TabPanel>
            <div className="task-files">
              <AttachedFiles />
            </div>
          </TabPanel>
          <TabPanel>
            <History task={task} />
          </TabPanel>
          <TabPanel>
            <GitlabCommitsByTask title={task.title} />
          </TabPanel>
        </Tabs>
      </div>
    </ScrollWrapper>
  </PreLoaderWhileLoading>
);

Task.propTypes = {
  task: PropTypes.instanceOf(Object).isRequired,
  onScrollSetRef: PropTypes.func.isRequired,
  scrollElement: PropTypes.instanceOf(Object),
  t: PropTypes.func.isRequired,
};

Task.defaultProps = {
  scrollElement: {},
};

export default Task;
