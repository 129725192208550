import React from 'react';

import ModalView from '../modalView';

const NewSprintModal = props => (
  <ModalView
    title="Create new sprint"
    titleAction="Create"
    {...props}
  />
);

export default NewSprintModal;
