import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import {
  ButtonsCollection, ModalWindow, AsyncAutoComplete, FormsCollection, PermissionElement,
} from '../../../../ui-kit';
import './style.sass';
import { TYPE } from '../../../../constants/projects';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';
import { AVAILABLE_FILE_FORMATS, AVAILABLE_FILE_SIZES } from '../../../../constants/files';

const CreateProjectModal = ({
  isOpen,
  onCloseModal,
  handleSubmit,
  handleChange,
  name,
  setFieldValue,
  handleBlur,
  touched,
  values,
  errors,
  getUsersAutocomplete,
  t,
  modalTitle,
  action,
  getClientsAutocomplete,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={() => onCloseModal(name)}
    className="create-project__modal"
    modalName={modalTitle}
    title={modalTitle}
  >
    <FormsCollection.FormWrapper handleSubmit={handleSubmit} className="create-project">
      <FormsCollection.Input
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.title}
        error={propOr('', 'message', errors.title)}
        touched={touched.title}
        name="title"
        id="create-project-modal__title"
        placeholderJump="Project title"
        className="create-project__title-field"
      />
      <div className="create-project__row create-project__row--description">
        <FormsCollection.TextArea
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.description}
          error={propOr('', 'message', errors.description)}
          touched={touched.description}
          name="description"
          className="create-project__textarea"
          placeholderJump="Description"
          autoHeight
          maxHeight={190}
          tabIndex={0}
        />
      </div>
      <div className="create-project__row create-project__row--logo">
        <div className="create-project__col-label">

          Project logo
        </div>
        <div className="create-project__col-field">
          <FormsCollection.AttachImage
            onChange={handleChange}
            value={values.image}
            name="image"
            formats={AVAILABLE_FILE_FORMATS.LOGO_PROJECT}
            maxSize={AVAILABLE_FILE_SIZES.LOGO_PROJECT}
            id="attached-logo__project"
            labelText="Add logo"
          />
        </div>
      </div>
      <div className="create-project__row create-project__row--privacy">
        <div className="create-project__col-label">
          {t('Privacy')}
        </div>
        <div className="create-project__col-fields">
          <FormsCollection.Radio
            onChange={handleChange}
            onBlur={handleBlur}
            checked={parseInt(values.type, 10) === TYPE.PUBLIC}
            label="Public"
            name="type"
            value={TYPE.PUBLIC}
            id="create-project-modal__privacy--public"
          />
          <FormsCollection.Radio
            onChange={handleChange}
            onBlur={handleBlur}
            checked={parseInt(values.type, 10) === TYPE.PRIVATE}
            label="Private"
            name="type"
            value={TYPE.PRIVATE}
            id="create-project-modal__privacy--private"
          />
        </div>
      </div>
      {
        Number(values.type) === TYPE.PRIVATE && (
          <div className="create-project__row create-project__row--members">
            <div className="create-project__col-label">

              Members
            </div>
            <div className="create-project__col-fields custom-position">
              <AsyncAutoComplete
                isMulti
                name="members"
                loadOptions={getUsersAutocomplete}
                placeholder="Choose members..."
                closeMenuOnSelect={false}
                value={values.members}
                placeholderJump="Members"
                touched={touched.members}
                error={propOr('', 'message', errors.members)}
                onChange={value => setFieldValue('members', value)}
              />
            </div>
          </div>
        )
      }
      <div className="create-project__row create-project__row--status">
        <div className="create-project__col-label">

          Status
        </div>
        <div className="create-project__col-field custom-position">
          <FormsCollection.Select
            onChange={handleChange}
            name="status"
            value={values.status}
            data={[
              {
                value: 0,
                label: 'Inactive',
              },
              {
                value: 1,
                label: 'Active',
              },
            ]}
          />
        </div>
      </div>
      <PermissionElement module={MODULES.CLIENTS} action={ACTIONS.CREATE}>
        <div className="create-project__row create-project__row--status">
          <div className="create-project__col-label">

          Client
          </div>
          <div className="create-project__col-field custom-position">
            <AsyncAutoComplete
              name="client_id"
              placeholder={t('Choose client')}
              error={propOr('', 'message', errors.client_id)}
              closeMenuOnSelect
              touched={touched.client_id}
              value={values.client_id}
              defaultValue={values.client_id}
              loadOptions={getClientsAutocomplete}
              onChange={value => setFieldValue('client_id', value)}
            />
          </div>
        </div>
      </PermissionElement>
      <div className="button-group">
        <ButtonsCollection.ButtonBrill
          className="button--md create-project__button-save button-brill--fill"
          type="submit"
        >
          {t(action)}
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);

CreateProjectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  getUsersAutocomplete: PropTypes.func.isRequired,
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  t: PropTypes.func.isRequired,
  getClientsAutocomplete: PropTypes.func.isRequired,
};

export default CreateProjectModal;
