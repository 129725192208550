import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ButtonsCollection } from '../../../../ui-kit';
import './style.sass';

/**
 * @param children {node}
 * @param active {string}
 * @param title {string}
 * @param setActiveTitle {function}
 * @param onKeyPress {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const TabWrapperMobile = ({
  title,
  children,
  active = '',
  setActiveTitle,
  onKeyPress = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <div
      role="button"
      onKeyPress={onKeyPress}
      tabIndex="0"
      onClick={() => setActiveTitle(title)}
      className={classNames('tab-wrapper-mobile', { 'tab-wrapper-mobile--active': active === title })}
    >
      <div className="tab-wrapper-mobile__header">
        <h3 className="tab-wrapper-mobile__title">{title}</h3>
        <ButtonsCollection.ButtonIcons
          className="tab-wrapper-mobile__toggle-button"
          title={t('Toggle tab')}
        >
          <span className="icon-expand-arrow-dn" />
        </ButtonsCollection.ButtonIcons>
      </div>
      <div className="tab-wrapper-mobile__body">
        {children}
      </div>
    </div>
  );
};
