import { handleActions } from 'redux-actions';
import {
  uniq, ifElse, prop, always, mapObjIndexed, pathOr,
} from 'ramda';

import types, { USERS_LIST } from './types';
import {
  mergeDeep,
  mergeByPath,
  mergeIn,
} from '../../utils/helpers/ramdaStateHelpers';
import { getImageUrl } from '../../utils/helpers/requestHelpers';


const setOnlyAvatarIdWhenUpdateUser = val => ({ ...val, avatar: pathOr(null, ['avatar', 'id'], val) });

const reducer = handleActions({
  [types.SET_USERS_LIST]: mergeDeep(({ payload: { data } }, { entities, result = [] }) => ({
    entities: { ...entities, ...data.entities[USERS_LIST] },
    result: uniq([
      ...result,
      ...data.result,
    ]),
  })),
  [types.SET_USERS]: mergeDeep(({ payload }, { entities = {}, result = [] }) => ({
    entities: { ...entities, ...payload.entities },
    result: uniq([
      ...result,
      ...payload.result,
    ]),
  })),
  [types.UPDATE_USERS]: mergeDeep(({ payload }, { result = [] }) => ({
    entities: mapObjIndexed(setOnlyAvatarIdWhenUpdateUser, getImageUrl),
    result: uniq([
      ...result,
      ...payload.result,
    ]),
  })),
  [types.SET_USERS_STATUS]: mergeByPath(['entities'], (action, state) => ({
    [action.payload.user_id]: {
      ...state.entities[action.payload.user_id],
      ...{ is_online: action.payload.is_online },
    },
  })),
  [types.SET_USERS_LIST_AUTOCOMPLETE]: mergeIn(
    ({ payload: { users: usersAutocomplete, meta } }, { users }) => {
      const labelAllUsers = ifElse(prop('withAllUsersLabel'),
        always({ value: null, label: 'All users' }), always({}))(meta);
      return {
        ...users,
        usersAutocomplete: {
          999: { ...labelAllUsers },
          ...usersAutocomplete,
        },
      };
    },
  ),
  [types.SET_COWORKERS]: mergeDeep(
    ({ payload: { data: { result } } }) => ({
      coworkers: result,
    }
    ),
  ),
  [types.SET_USER_PROFILE_ID]: mergeDeep(({ payload }) => ({ userProfileId: payload })),
}, { entities: {}, count: 0, usersAutocomplete: [] });

export default reducer;
