/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';

const leadsListSchema = [new schema.Entity('leads')];

const pinnedLeadsListSchema = [new schema.Entity('pinnedLeads')];

const leadTasksListSchema = [new schema.Entity('leadTasks')];


export {
  leadsListSchema,
  pinnedLeadsListSchema,
  leadTasksListSchema,
};
