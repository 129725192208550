import React from 'react';
import PropTypes from 'prop-types';
import { pathOr, prop } from 'ramda';

import {
  StatusBar, BrillMask, Avatar, Grid, LabelPriorityTask, ButtonsCollection, DropDown,
} from '../../../../ui-kit';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import { getTimeStringBySeconds } from '../../../../utils/helpers/dateHelpers';
import { getTaskProgress } from '../../../../utils/helpers/taskHelpers/common';
import './style.sass';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { formatDate } from '../../../../utils/helpers/commonHelpers';
import { IMAGES_SIZE } from '../../../../constants/ui';
import { MY_TASKS } from '../../../../constants/tasks';

const TaskItem = ({
  task, t, onClickTask, onEdit, onDelete, taskStatus, projectView, activeFilter,
}) => (
  <Grid.Row
    onClick={onClickTask}
    key={`my-tasks-grid  - ${task.id}`}
    className={`my-tasks__row ${taskStatus} ${!task.project_id ? 'my-tasks__row--without-project' : ''}`}
  >
    {
      !projectView && (
        <Grid.ColData className="my-tasks__col-project my-tasks__col">
          {
            task.project ? (
              <BrillMask asLink to={`/projects/${task.project_id}/${task.client_id ? `?client_id=${task.client_id}` : ''}`}>
                <Avatar
                  src={getImageUrl(prop('image', task.project), IMAGES_SIZE.beforeSmall)}
                  alt={prop('title', task.project)}
                />
              </BrillMask>
            ) : <div>-</div>
          }
        </Grid.ColData>
      )
    }
    <Grid.ColData className="my-tasks__col-id my-tasks__col">
      <div>
        {task.id}
      </div>
    </Grid.ColData>
    <Grid.ColData className="my-tasks__col-title my-tasks__col">
      <div className="text--cut">
        {task.title}
      </div>
    </Grid.ColData>
    <Grid.ColData className="my-tasks__col-priority my-tasks__col">
      <LabelPriorityTask className="project-task__priority-label" status={task.priority} />
    </Grid.ColData>
    <Grid.ColData className="my-tasks__col-category my-tasks__col">
      <span className="text--cut">
        {pathOr(t('None'), ['category', 'title'], task)}
      </span>
    </Grid.ColData>
    {
      activeFilter === MY_TASKS.FILTER.ASSIGNED_TO_ME && !projectView && (
        <Grid.ColData className="my-tasks__col-author my-tasks__col">
          {
            task.author ? (
              <BrillMask asLink>
                <Avatar
                  src={getImageUrl(task.author.avatar, IMAGES_SIZE.beforeSmall)}
                  alt={getFullName(task.author) || null}
                />
              </BrillMask>
            ) : (
              <BrillMask className="my-tasks__col-assignee--unassigned">
                <Avatar>
                  <div className="my-tasks__col-assignee--unassigned-icon-wrapper" title="Without Assignee" />
                </Avatar>
              </BrillMask>
            )
          }
        </Grid.ColData>
      )
    }
    {
      activeFilter === MY_TASKS.FILTER.CREATED_BY_ME && !projectView && (
        <Grid.ColData className="my-tasks__col-assignee my-tasks__col">
          {
            task.assigneeUser ? (
              <BrillMask asLink>
                <Avatar
                  src={getImageUrl(task.assigneeUser.avatar, IMAGES_SIZE.beforeSmall)}
                  alt={getFullName(task.assigneeUser) || null}
                />
              </BrillMask>
            ) : (
              <BrillMask className="my-tasks__col-assignee--unassigned">
                <Avatar>
                  <div className="my-tasks__col-assignee--unassigned-icon-wrapper" title="Without Assignee" />
                </Avatar>
              </BrillMask>
            )
          }
        </Grid.ColData>
      )
    }
    {
      projectView && (
        <>
          <Grid.ColData className="my-tasks__col-author my-tasks__col">
            {
              task.author ? (
                <BrillMask asLink>
                  <Avatar
                    src={getImageUrl(task.author.avatar, IMAGES_SIZE.beforeSmall)}
                    alt={getFullName(task.author) || null}
                  />
                </BrillMask>
              ) : (
                <BrillMask className="my-tasks__col-assignee--unassigned">
                  <Avatar>
                    <div className="my-tasks__col-assignee--unassigned-icon-wrapper" title="Without Assignee" />
                  </Avatar>
                </BrillMask>
              )
            }
          </Grid.ColData>
          <Grid.ColData className="my-tasks__col-assignee my-tasks__col">
            { task.assigneeUser ? (
              <BrillMask asLink>
                <Avatar
                  src={getImageUrl(task.assigneeUser.avatar, IMAGES_SIZE.beforeSmall)}
                  alt={getFullName(task.assigneeUser) || null}
                />
              </BrillMask>
            ) : (
              <BrillMask className="my-tasks__col-assignee--unassigned">
                <Avatar>
                  <div className="my-tasks__col-assignee--unassigned-icon-wrapper" title="Without Assignee" />
                </Avatar>
              </BrillMask>
            )}
          </Grid.ColData>
        </>
      )
    }
    <Grid.ColData className="my-tasks__col-estimation my-tasks__col">
      <div>
        {getTimeStringBySeconds(task.estimated_time)}
      </div>
    </Grid.ColData>
    <Grid.ColData className="my-tasks__col-spent my-tasks__col">
      <div>
        {getTimeStringBySeconds(task.spent_time)}
      </div>
    </Grid.ColData>
    <Grid.ColData className="my-tasks__col-deadline my-tasks__col">
      <div>
        {task.deadline ? formatDate(task.deadline) : '-'}
      </div>
    </Grid.ColData>
    <Grid.ColData className="my-tasks__col-progress my-tasks__col">
      <StatusBar
        percents={getTaskProgress(task)}
      />
    </Grid.ColData>
    <Grid.ColData className="my-tasks__col-task-controls my-tasks__col">
      <DropDown
        className="controls-group-task"
        name="controls-group-task"
        label={(
          <ButtonsCollection.ButtonIcons
            className="my-tasks__controls-button-task"
            title="Controls for task"
          >
            <span className="my-tasks__control-icon icon-settings-vertical">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
            </span>
          </ButtonsCollection.ButtonIcons>
        )}
        list={
          [
            {
              id: 0,
              onClick: onEdit,
              label: (
                <>
                  <span className="controls-group-task__icon icon-pencil-2" />
                  <span className="controls-group-task__text">{t('Edit')}</span>
                </>
              ),
            },
            {
              id: 2,
              onClick: onDelete,
              label: (
                <>
                  <span className="controls-group-task__icon icon-delete-icon" />
                  <span className="controls-group-task__text">{t('Delete')}</span>
                </>
              ),
            },
          ]
        }
      />
    </Grid.ColData>
  </Grid.Row>
);

TaskItem.propTypes = {
  task: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  onClickTask: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  taskStatus: PropTypes.string.isRequired,
  projectView: PropTypes.bool.isRequired,
  activeFilter: PropTypes.string.isRequired,
};

export default TaskItem;
