import { withStateHandlers, lifecycle, compose } from 'recompose';

const setWindowWidth = () => () => ({ windowWidth: window.innerWidth });

const withWindowWidth = () => compose(
  withStateHandlers(
    () => ({ windowWidth: window.innerWidth }), {
      setWindowWidthStateHandler: setWindowWidth,
    },
  ),
  lifecycle({
    componentDidMount() {
      window.addEventListener('resize', this.props.setWindowWidthStateHandler);
    },
    componentWillUnmount() {
      window.removeEventListener('resize', this.props.setWindowWidthStateHandler);
    },
  }),
);
export default withWindowWidth;
