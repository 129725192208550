import {
  compose, getContext, lifecycle, withHandlers, withState,
} from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ClientsList from './clientList';
import { preloaderWhileLoading } from '../../../../utils/enchancers';
import { clientsSelectors } from '../../../../state/clients';
import { uiActions } from '../../../../state/ui';

const mapStateToProps = state => ({
  isPending: clientsSelectors.getClientsPendingRequest(state),
});

const mapDispatchToProps = {
  openModal: uiActions.openModal,
};

const onActionHandler = modalName => ({
  openModal,
}) => () => {
  openModal(modalName);
};


const toggleActiveTabHandler = ({ setActiveTabClientId, activeTabClientId }) => clientId => (
  activeTabClientId === clientId ? setActiveTabClientId(null) : setActiveTabClientId(clientId)
);

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['tasks', 'common']),
  withState('showLoader', 'setIsShowLoader', true),
  withState('activeTabClientId', 'setActiveTabClientId', null),
  getContext({
    setSelectedClientId: PropTypes.func.isRequired,
    selectedPinnedClientId: PropTypes.any,
    setSelectedPinnedClientId: PropTypes.func,
  }),
  withHandlers({
    toggleActiveTab: toggleActiveTabHandler,
    onEditClientModal: onActionHandler('editClientModal'),
    onDeleteClientModal: onActionHandler('deleteClientModal'),
    onPinClientModal: onActionHandler('pinClientModal'),
    onUnpinClientModal: onActionHandler('unpinClientModal'),
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        isPending,
        setIsShowLoader,
        selectedPinnedClientId,
        setActiveTabClientId,
        activeTabClientId,
        setSelectedPinnedClientId,
      } = this.props;
      const { isPending: prevIsPending, showLoader: prevShowLoader } = prevProps;
      if (prevIsPending && !isPending && prevShowLoader) {
        setIsShowLoader(false);
      }
      if (selectedPinnedClientId && activeTabClientId !== selectedPinnedClientId) {
        setActiveTabClientId(selectedPinnedClientId);
      } else if (
        selectedPinnedClientId === prevProps.selectedPinnedClientId
        && selectedPinnedClientId === activeTabClientId
      ) {
        setSelectedPinnedClientId(null);
      }
    },
  }),
  preloaderWhileLoading({
    dimension: 100,
    fullScreen: true,
    isLoading: () => false,
    onAction: ({ showLoader }) => showLoader,
    delay: 600,
  }),
);

export default enhance(ClientsList);
