export const CRM_ROUTES = {
  CRM: '/crm/',
  CONTACTS: '/crm/contacts/',
  LEADS: '/crm/leads/',
  PIPELINES: '/crm/leads/pipelines/',
  CLIENTS: '/crm/clients',
  INVOICES: '/crm/invoices/',
  NEW_INVOICE: '/crm/invoices/new-invoice/',
  EDIT_INVOICE: '/crm/invoices/edit-invoice/:invoiceId',
};

export const CLIENT_TYPES = {
  LEAD: 1,
  CLIENT: 2,
  CLIENT_LEAD: 3,
};

export const PIPELINE_STATUSES = {
  LOST: 11,
  WON: 10,
};

export const PIPELINE_TYPES = {
  ALL: 0,
  IN_PROGRESS: 1,
  WON: 2,
  LOST: 3,
};

export const LEADS_VIEW = {
  LIST: 'LIST',
  PIPE: 'PIPE',
};

export const INVOICES_VIEW = {
  LIST: 'LIST',
  PIPE: 'PIPE',
};

export const INVOICES_STATUSES = {
  1: 'Draft',
  2: 'Sent',
  3: 'Unpaid',
  4: 'Overdue',
  5: 'Partially paid',
  6: 'Paid',
};

export const INVOICE_STATUS_IDS = {
  DRAFT: 1,
  SENT: 2,
  UNPAID: 3,
  OVERDUE: 4,
  PARTIALLY_PAID: 5,
  PAID: 6,
};

export const INVOICES_TERMS = {
  END_OF_MONTH: 'END_OF_MONTH',
  END_OF_NEXT_MONTH: 'END_OF_NEXT_MONTH',
  CUSTOM: 'CUSTOM',
};

export const INVOICES_PAY_TYPES = {
  PERCENT: 'PERCENT',
  FLAT: 'FLAT',
};

export const INVOICES_LIMIT = 10;

export const SORTING_ORDER = {
  asc: 'Old',
  desc: 'Recent',
};

export const FILE_FORMATS = {
  CONTACT_LOGO: [
    'image/jpg',
    'image/png',
    'image/jpeg',
  ],
};

export const FILE_SIZES = {
  CONTACT_LOGO: 6,
  MINIMAL_SIZE_KB: 0.001,
};


export const CLIENT_LOGS = {
  CREATED: 'created',
  CHANGED: 'changed',
  DELETED: 'deleted',
};

export const CLIENT_LOGS_ONLY_ACTION_FIELDS = [
  'avatar',
];

export const CONTACT_LOGS_ONLY_ACTION_FIELDS = [
  'avatar',
];

export const CLIENT_LOGS_TYPE = {
  LEAD: 'Lead',
  TASK: 'Task',
  DOCUMENT: 'Document',
  CONTACT: 'Contact',
  BILLING_INFO: 'BillingInfo',
  PRODUCT: 'Project',
};

export const HISTORY_EVENTS = {
  TASKS: {
    ADDED: 'added a task',
    DELETED: 'deleted task',
    MARKED: 'marked task',
  },
  NOTE: {
    ADDED: 'added a note',
    DELETED: 'delete note',
    UPDATED_TITLE: 'updated the note title',
    UPDATED: 'updated',
  },
  CONTACT: {
    ADDED: 'added a contact',
    DELETED: 'deleted contact',
    CHANGED: 'changed contact',
  },
};

export const START_PAGE_NUMBER = 1;

export const OFFSET = 10;
