import { compose, getContext, pure } from 'recompose';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import LeadTab from './leadTab';

const enhance = compose(
  withTranslation(['tasks', 'common']),
  pure,
  getContext({
    onSelectAssignee: PropTypes.func,
    onSelectAssigner: PropTypes.func,
  }),
);
export default enhance(LeadTab);
