import React from 'react';
import PropTypes from 'prop-types';
import { ButtonsCollection, Avatar, BrillMask } from '../../../../../../ui-kit';
import { getFullName } from '../../../../../../utils/helpers/userHelpers';
import './style.sass';
import { getImageUrl } from '../../../../../../utils/helpers/requestHelpers';

const PinnedClientItem = ({
  pinnedClient, onUnpin, setSelectedPinnedClientId,
}) => (
  // eslint-disable-next-line max-len
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
  <li
    className="pinned-clients__item"
    onClick={() => setSelectedPinnedClientId(pinnedClient.id)}
  >
    <BrillMask className="brill-mask">
      <Avatar
        src={getImageUrl(pinnedClient.contact.avatar)}
        alt={getFullName(pinnedClient.contact)}
      />
    </BrillMask>
    <div className="pinned-clients__item-name">
      <div className="text--cut">
        {getFullName(pinnedClient.contact)}
      </div>
    </div>
    {
      onUnpin && (
        <ButtonsCollection.ButtonIcons
          className="pinned-clients__item-unpin"
          onClick={onUnpin}
          title="Unpin client"
        >
          <span className="icon-close" />
        </ButtonsCollection.ButtonIcons>
      )
    }
  </li>
);

PinnedClientItem.propTypes = {
  pinnedClient: PropTypes.instanceOf(Object).isRequired,
  onUnpin: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  setSelectedPinnedClientId: PropTypes.func.isRequired,
};

export default PinnedClientItem;
