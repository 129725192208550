import { fork, take } from 'redux-saga/effects';
import types from './types';
import { dragDropLeadHelper } from '../../utils/helpers/crmHelpers';
import { sagasManager } from '../../utils';

function* onDragAgendaFlow() {
  while (true) {
    const { payload } = yield take(types.ON_DRAG_LEAD_END);
    const { destination, source, draggableId: itemId } = payload;
    yield dragDropLeadHelper({ destination, source, itemId });
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(onDragAgendaFlow);
});
