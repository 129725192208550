import React, {
  memo, useMemo, useRef, useEffect, useState,
} from 'react';
import classNames from 'classnames';

import './style.sass';
import { useTranslation } from 'react-i18next';

/**
 *
 * @param name {string}
 * @param onChange {function}
 * @param error {string|boolean}
 * @param value {string}
 * @param id {string}
 * @param onBlur {function}
 * @param placeholder {string}
 * @param placeholderJump {string}
 * @param onKeyUp {function}
 * @param onKeyDown {function}
 * @param touched {boolean}
 * @param className {string}
 * @param hiddenEye {boolean}
 * @param children {JSX.Element|string|array}
 * @param disabled {boolean}
 * @returns {JSX.Element}
 * @constructor
 */

export const Input = memo(({
  name,
  onChange = () => {},
  error = '',
  touched = false,
  value = '',
  onBlur = () => {},
  placeholder = '',
  placeholderJump = '',
  className = '',
  hiddenEye = false,
  onKeyUp = () => {},
  onKeyDown = () => {},
  id,
  children = '',
  disabled = false,
  type,
}) => {
  const [inFocus, setInFocus] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation('common');
  const isError = useMemo(() => (typeof error === 'string' && error.length !== 0), [error]);
  const input = useRef(null);

  const handlerInFocus = () => {
    setInFocus(true);
  };

  const setInFocusCheck = () => {
    const el = input.current;
    if (!el || !el.value || !el.value.length) {
      setInFocus(false);
    }
  };

  const handlerVisibleStatus = () => {
    setIsVisible(!isVisible);
  };

  const getTypeHandler = () => {
    if (hiddenEye) {
      if (isVisible) return 'text';
      return 'password';
    }
    return type;
  };

  useEffect(() => {
    if (value) {
      setInFocus(true);
    }
    const inputRef = input.current;
    inputRef.addEventListener('change', () => inFocus);
    inputRef.addEventListener('focus', () => inFocus);
    inputRef.addEventListener('blur', () => setInFocusCheck());
    return (
      inputRef.removeEventListener('change', () => inFocus),
      inputRef.removeEventListener('focus', () => inFocus),
      inputRef.removeEventListener('blur', () => setInFocusCheck())
    );
  }, [inFocus, value]);
  return (
    <div className={classNames('field-group',
      className,
      (error && touched: 'field-group--has-error'),
      inFocus && 'field-group--pseudo-focus',
      disabled && 'field-group--disabled')}
    >
      <div className="field-group__wrap">
        <input
          name={name}
          type={getTypeHandler()}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={handlerInFocus}
          autoComplete="off"
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          className="field-group__place-write"
          value={value}
          id={id}
          placeholder={placeholder}
          ref={input}
          disabled={disabled}
        />
        {hiddenEye && (
          <button
            tabIndex="-1"
            aria-label={!isVisible ? 'Show password' : 'Hide password'}
            title={!isVisible ? 'Show password' : 'Hide password'}
            type="button"
            onClick={() => handlerVisibleStatus()}
            className="field-group__button-hidden"
          >
            {
              isVisible ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g fill="none" fillRule="evenodd" transform="translate(2 6)">
                    <path d="M20 5.862s-4.483 5.172-10 5.172S0 5.862 0 5.862 4.483 0 10 0s10 5.862 10 5.862z" />
                    <circle cx="10" cy="5.517" r="3.448" />
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g fill="none" fillRule="evenodd">
                    <path d="M18.552 8.517C20.69 10.034 22 11.621 22 11.621s-4.483 5.517-10 5.517c-.897 0-1.931-.069-2.759-.345M5.448 14.69C3.31 13.138 3.345 13.31 2 11.62c0 0 4.483-5.861 10-5.861.759 0 1.38.172 2.069.344M8.552 11.62A3.415 3.415 0 0 1 12 8.173M15.448 11.276A3.415 3.415 0 0 1 12 14.724M20.276 3L3.724 19.897" />
                  </g>
                </svg>
              )
            }
          </button>
        )}
        {placeholderJump && (
          <span
            className="field-group__placeholder-jump"
          >
            {placeholderJump}
          </span>
        )}
      </div>
      {isError && (
        <span className="field-group__error field__error-text">
          {t(error, { key: placeholderJump })}
        </span>
      )}
      {
        children
      }
    </div>
  );
});
