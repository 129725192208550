import {
  branch,
  compose, getContext, renderNothing,
} from 'recompose';
import { prop } from 'ramda';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import rules from '../rules';
import { uiActions, uiSelectors } from '../../../../../state/ui';
import { projectActions, projectSelectors } from '../../../../../state/project';
import EditCategory from './editCategory';

const mapStateToProps = (state, { selectedCategoryId }) => ({
  isOpen: uiSelectors.getModal(state)('editCategory'),
  category: projectSelectors.getCategory(state)(selectedCategoryId),
  isPending: projectSelectors.getCategoryUpdateRequestPending(state),
});

const mapDispatchToProps = ({
  onCloseModal: () => uiActions.closeModal('editCategory'),
  updateCategoryTask: projectActions.updateCategoryTaskRequest,
});

const formSubmit = (formValues, { resetForm, onCloseModal, ...props }) => {
  const { updateCategoryTask, projectId, selectedCategoryId } = props.props;
  const data = { ...formValues, projectId, categoryId: selectedCategoryId };
  updateCategoryTask(data, {
    callbacks: {
      success: props.props.onCloseModal,
    },
  });
};

const enhance = compose(
  getContext({
    selectedCategoryId: PropTypes.number.isRequired,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common', 'tasks']),
  branch(({ category }) => !prop('title', category), renderNothing),
  withFormik({
    mapPropsToValues: ({ category }) => ({
      title: category.title,
    }),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: formSubmit,
  }),
);

export default enhance(EditCategory);
