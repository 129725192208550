import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmModal } from '../../../../../../ui-kit';
import { formatDate } from '../../../../../../utils/helpers/commonHelpers';

const DeleteTimeEntry = ({
  isOpen,
  isPending,
  onCloseModal,
  timeEntry,
  userName,
  onDelete,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Delete"
    onAction={onDelete}
    onClose={onCloseModal}
    title="Delete time entry"
  >
    <span className="text-size--sm">

      Are you sure you want to delete a log  made by
      {' '}
      <b className="p-xs">{userName}</b>
      {' '}

at
      {' '}
      <b className="p-xs">{formatDate(timeEntry.created_at, 'h:m:s A')}</b>

?
    </span>
  </ConfirmModal>
);

DeleteTimeEntry.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  timeEntry: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
};

export default DeleteTimeEntry;
