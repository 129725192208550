import React, {
  memo, useCallback, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';
import { DropDownItem } from './components';
import './style.sass';
import { getItem } from '../../utils/helpers/commonHelpers';
import { WINDOW_WIDTH } from '../../constants/ui';
import { useClickOutside } from '../../utils/hooks';

const renderCurrentValue = (label, currentItem, list, placeholder) => {
  const item = getItem('id', currentItem, list);
  if (typeof (label) === 'string') {
    return (
      <>
        <span>
          { label }
          <span className="drop-down__current-value">
            { item?.label || placeholder}
          </span>
        </span>
        <i className="icon-down" />
      </>
    );
  }
  return label;
};

/**
 *
 * @param label {string|object}
 * @param list {array}
 * @param name {string}
 * @param className {string}
 * @param onReset {function}
 * @param placeholder
 * @param ItemLabel {string}
 * @param isMulti {boolean}
 * @param showCheckbox {boolean}
 * @param onChange {function}
 * @param currentItem {string|number}
 * @returns {JSX.Element}
 * @constructor
 */

export const DropDown = memo(({
  label = '',
  list,
  name = '',
  className = '',
  onReset,
  placeholder,
  ItemLabel,
  isMulti = false,
  showCheckbox = true,
  onChange = () => {},
  currentItem = 1,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [positionElement, positionDropDown] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dropDown = useRef(null);
  const dropDownBody = useRef(null);
  const prevIsOpened = useRef(isOpen);

  useEffect(() => {
    prevIsOpened.current = {
      isOpen,
    };
  }, [isOpen]);
  const prevProps = prevIsOpened.current;
  const dropDownBodyRef = dropDownBody.current;

  useClickOutside(dropDown, () => setIsOpen(false));

  const onChangeValue = useCallback((e, item) => {
    e.stopPropagation();
    const { id, onClick = () => {} } = item;
    if (onChange) {
      onChange({ val: getItem('id', id, list) });
    }
    if (isMulti && showCheckbox) {
      onClick();
    }
    if (!isMulti) {
      setIsOpen(false);
      onClick();
    }
  });

  const onResetValue = () => {
    if (onReset) onReset();
    setIsOpen(false);
  };

  const checkCoordinates = dropDownBodyBlockValue => (windowWidth > WINDOW_WIDTH.MEDIUM ? (
    dropDownBodyBlockValue.left > WINDOW_WIDTH.X_SMALL
  ) : (
    dropDownBodyBlockValue.left > WINDOW_WIDTH.X_SMALL / 2
  ));

  const onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  useLayoutEffect(() => {
    const dropDownRef = dropDown.current;

    if (isOpen && prevProps.isOpen !== isOpen) {
      const dropDownBodyBlockValue = dropDownBodyRef.getBoundingClientRect();
      if (checkCoordinates(dropDownBodyBlockValue) && dropDownBodyBlockValue.right
      > dropDownRef.parentNode.getBoundingClientRect().width) {
        positionDropDown(true);
      }
    }

    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
    return () => {
      window.removeEventListener('resize', () => setWindowWidth(window.innerWidth));
    };
  }, [isOpen, positionElement, windowWidth]);
  return (
    <div
      className={classNames('drop-down', isOpen && 'open', className, positionElement && ' drop-down--right', isMulti && 'drop-down__multi')}
      ref={dropDown}
    >
      <div
        className="drop-down__head"
        onClick={e => onClick(e)}
        onKeyPress={() => setIsOpen(!isOpen)}
        tabIndex="0"
        role="button"
      >
        { renderCurrentValue(label, currentItem, list, placeholder) }
      </div>
      <div
        className="drop-down__body"
        ref={dropDownBody}
      >
        <ul>
          { placeholder && onReset && (
            <li>
              <button
                type="button"
                onClick={onResetValue}
                className="btn"
              >
                { placeholder }
              </button>
            </li>
          )}
          {
            list.map(item => (
              <DropDownItem
                showCheckbox={showCheckbox}
                key={`dropdown - ${item.id} ${name}`}
                onChangeValue={onChangeValue}
                name={name}
                item={item}
                wrapperProps={item.wrapperProps}
                isMulti={isMulti}
                ItemLabel={isMulti ? ItemLabel : false}
              />
            ))
          }
        </ul>
      </div>
    </div>
  );
});
