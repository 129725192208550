import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { ButtonsCollection } from '../../../../ui-kit';
import ChannelItem from './components';
import { InviteMemberButton } from '..';
import './style.sass';

const DirectChannels = ({ channelsList, openModal, t }) => (
  <div className="direct-nav">
    <div className="direct-nav__header">
      <NavLink to="/messenger/directs/" className="direct-nav__title">
        {t('Direct Messages')}
      </NavLink>
      <ButtonsCollection.ButtonPlus
        className="direct-nav__plus-button"
        onClick={() => openModal('inviteMembersModal')}
      />
    </div>
    <nav className="direct-nav__nav">
      {channelsList.map(channel => (
        <ChannelItem key={channel} channelId={channel} />
      ))}
    </nav>
    <InviteMemberButton onClick={() => openModal('inviteMembersModal')} />
  </div>
);

DirectChannels.propTypes = {
  channelsList: PropTypes.arrayOf(PropTypes.number).isRequired,
  openModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default DirectChannels;
