import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import DeleteClientModal from './deleteClientModal';
import { uiActions, uiSelectors } from '../../../../state/ui';
import { clientsSelectors } from '../../../../state/clients';

const mapStateToProps = (state, { selectedClientId }) => ({
  isOpen: uiSelectors.getModal(state)('deleteClientModal'),
  client: clientsSelectors.getClientById(state)(selectedClientId),
});

const mapDispatchToProps = {
  closeModal: () => uiActions.closeModal('deleteClientModal'),
};

const onCloseHandler = ({
  closeModal,
}) => () => {
  closeModal();
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onClose: onCloseHandler,
  }),
);

export default enhance(DeleteClientModal);
