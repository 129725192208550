export default {
  GET_STATISTICS_REQUEST: 'GET_STATISTICS_REQUEST',
  GET_STATISTICS_BY_TASKS_REQUEST: 'GET_STATISTICS_BY_TASKS_REQUEST',

  SET_STATISTICS_BY_TASKS: 'SET_STATISTICS_BY_TASKS',
  SET_USER_STATISTIC: 'SET_USER_STATISTIC',

  GET_MY_STATISTICS_REQUEST: 'GET_MY_STATISTICS_REQUEST',
  SET_MY_STATISTICS: 'SET_MY_STATISTICS',

  EXPORT_DATA_REQUEST: 'EXPORT_DATA_REQUEST',
};
