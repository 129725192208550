import React, {
  useState, useMemo,
} from 'react';
import moment from 'moment';
import { ActivityContext } from './context';

const DEFAULT_PROJECT_VALUE = { value: null, label: 'All projects' };
const DEFAULT_USER_VALUE = { value: null, label: 'All users' };

export const Provider = ({ children }) => {
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [projectValue, setProjectValue] = useState(DEFAULT_PROJECT_VALUE);
  const [selectedUser, setSelectedUser] = useState(DEFAULT_USER_VALUE);
  const [selectedUsers, setSelectedUsers] = useState([DEFAULT_USER_VALUE]);

  const value = useMemo(() => ({
    startDate,
    endDate,
    projectValue,
    selectedUser,
    setStartDate,
    setEndDate,
    setProjectValue,
    setSelectedUser,
    selectedUsers,
    setSelectedUsers,
  }), [
    startDate,
    endDate,
    projectValue,
    selectedUser,
    setStartDate,
    setEndDate,
    setProjectValue,
    setSelectedUser,
    selectedUsers,
    setSelectedUsers,
  ]);

  return (
    <ActivityContext.Provider value={value}>
      {children}
    </ActivityContext.Provider>
  );
};
