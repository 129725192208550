import {
  compose, withHandlers, withProps, withStateHandlers, getContext,
} from 'recompose';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { path, pathOr } from 'ramda';
import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { projectsActions, projectsSelectors } from '../../../../state/projects';
import { closeModal, openModal } from '../../../../state/ui/actions';
import { projectActions } from '../../../../state/project';
import ProjectsAsList from './projectsAsList';
import { withPermissionHandlers } from '../../../../utils/helpers/permissionHelpers';

const setSelectedProjectIdStateHandler = () => id => ({ selectedProjectId: id });

const mapStateToProps = state => ({
  count: projectsSelectors.getProjectsListCount(state),
  projects: projectsSelectors.getProjects(state),
});

const mapDispatchToProps = ({
  setOpenModal: openModal,
  push,
  getProjectsRequest: projectsActions.getProjectsListRequest,
  onCloseModal: closeModal,
  deleteProject: projectsActions.deleteProjectRequest,
  getProject: projectActions.getProjectRequest,
});

const onOpenEditProjectModalHandler = ({
  setOpenModal, setSelectedProjectId, getProject,
}) => (id) => {
  getProject({ projectId: id });
  setOpenModal('editProjectModal');
  setSelectedProjectId(id);
};

const onOpenDeleteProjectModalHandler = ({ setOpenModal, setSelectedProjectId }) => (id) => {
  setOpenModal('deleteProjectModal');
  setSelectedProjectId(id);
};

const onDeleteProjectHandler = ({
  sort,
  order,
  pagination: { limit, offset },
  searchName,
  deleteProject,
  selectedProjectId,
  onCloseModal,
}) => () => {
  deleteProject({ id: selectedProjectId }, {
    sortBy: sort,
    order,
    name: searchName,
    offset,
    limit,
  });
  onCloseModal('deleteProjectModal');
};

const setProgressProjectHandler = (project) => {
  const allTasks = path(['__meta__', 'tasksCount'], project);
  const closedTasks = allTasks - pathOr(0, ['__meta__', 'openTasksCount'], project);
  return allTasks ? (closedTasks / allTasks) * 100 : 0;
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['projects', 'common']),
  withProps({
    setProgressProject: setProgressProjectHandler,
  }),
  withStateHandlers(() => ({ selectedProjectId: null }), {
    setSelectedProjectId: setSelectedProjectIdStateHandler,
  }),
  getContext({
    onToggleFavoriteStatus: PropTypes.func.isRequired,
  }),
  withHandlers({
    onOpenEditProjectModal: onOpenEditProjectModalHandler,
    onOpenDeleteProjectModal: onOpenDeleteProjectModalHandler,
    onDeleteProject: onDeleteProjectHandler,
  }),
  withPermissionHandlers,
);

export default enhance(ProjectsAsList);
