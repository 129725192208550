import React from 'react';
import PropTypes from 'prop-types';
import {
  HrConfirmDeleteModal, HrSearch, HrTable, HrTableFilters,
} from '../../components';
import './style.scss';

const HrComponent = ({
  candidates, candidatesEntities,
  setSelectedCandidate, selectedCandidate, deleteCandidate,
  onSortBy, sort, order, pagination, gridName,
  onSearch, searchValue, setSearchValue, isPending, candidatesCount,
  selectPosition, selectStatus, selectedFilterStatus, selectedFilterPosition,
}) => (
  <div className="HrComponent">
    <div className="HrComponent_filters">
      <HrSearch
        value={searchValue}
        onChange={setSearchValue}
        onClick={onSearch}
        onSearchByEnter={onSearch}
      />
      <HrTableFilters
        onSelectPosition={selectPosition}
        onSelectStatus={selectStatus}
        selectedPosition={selectedFilterPosition.id}
        selectedStatus={selectedFilterStatus.id}
      />
    </div>
    <HrTable
      isLoading={!isPending}
      candidates={candidates}
      entities={candidatesEntities}
      onDeleteCandidate={setSelectedCandidate}
      gridName={gridName}
      pagination={pagination}
      count={candidatesCount}
      onSortBy={onSortBy}
      sort={sort}
      order={order}
    />
    <HrConfirmDeleteModal
      candidate={selectedCandidate}
      deleteCandidate={deleteCandidate}
      setSelectedCandidate={setSelectedCandidate}
    />
  </div>
);

HrComponent.propTypes = {
  onSortBy: PropTypes.func.isRequired,
  deleteCandidate: PropTypes.func.isRequired,
  setSelectedCandidate: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
  candidates: PropTypes.instanceOf(Object).isRequired,
  candidatesEntities: PropTypes.instanceOf(Object).isRequired,
  selectedCandidate: PropTypes.instanceOf(Object),
  gridName: PropTypes.string.isRequired,
  candidatesCount: PropTypes.number.isRequired,
  sort: PropTypes.string,
  order: PropTypes.string,
  pagination: PropTypes.instanceOf(Object).isRequired,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  selectPosition: PropTypes.func.isRequired,
  selectStatus: PropTypes.func.isRequired,
  selectedFilterStatus: PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  selectedFilterPosition: PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.number,
    label: PropTypes.string,
  }),
};
HrComponent.defaultProps = {
  selectedCandidate: null,
  selectedFilterStatus: { id: null },
  selectedFilterPosition: { id: null },
  sort: '',
  order: '',
};

export default HrComponent;
