import React from 'react';

import { Route, Switch } from 'react-router';

import { LeftSideBar } from '@/newContainers';
import { Layouts, ModuleWrapper, NoInternetConnection } from '@/ui-kit';

import { BusinessProcesses, BusinessProcess, ProcessLogs } from './pages';

import config from '../config';

const BPModule = () => (
  <Layouts.Main>
    <LeftSideBar />
    <div className="main-container">
      <ModuleWrapper title="Business Processes" config={config} isAvailable>
        <NoInternetConnection>
          <Switch>
            <Route exact path="/business-processes/:processId/logs" component={ProcessLogs} />
            <Route exact path="/business-processes/:processId/" component={BusinessProcess} />
            <Route exact component={BusinessProcesses} />
          </Switch>
        </NoInternetConnection>
      </ModuleWrapper>
    </div>
  </Layouts.Main>
);

export default BPModule;
