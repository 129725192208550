import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ConfirmModal, AsyncAutoComplete } from '../../../../../../../ui-kit';
import { permissionsActions, permissionsSelectors } from '../../../../../../../state/permissions';
import './style.sass';

const EMPTY_ROLE_LABEL = 0;

/**
 * @param isOpenModal {boolean}
 * @param setIsOpenModal {function}
 * @param selectedRole {object}
 * @param getRolesAutocomplete {function}
 * @returns {JSX.Element}
 * @constructor
 */

const DeleteRoleModal = ({
  isOpenModal,
  setIsOpenModal,
  selectedRole,
  getRolesAutocomplete,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [newRole, setNewRole] = useState(EMPTY_ROLE_LABEL);
  const isPending = useSelector(permissionsSelectors.getDeleteRolePending);
  const deleteRoleRequest = useCallback((value, meta) => {
    dispatch(permissionsActions.deleteRoleRequest(value, meta));
  });

  const onClose = useCallback(() => setIsOpenModal(false));

  const onChangeRole = useCallback(value => setNewRole(value));

  const onDelete = useCallback(() => {
    deleteRoleRequest({ roleId: selectedRole.id, new_role_id: newRole.value }, {
      callbacks: { success: onClose },
    });
  });

  return (
    <ConfirmModal
      isOpen={isOpenModal}
      isPending={isPending}
      actionTitle={t('Delete')}
      onAction={onDelete}
      onClose={onClose}
      title={t('Delete role')}
      className="delete-role-modal"
    >
      {t('Are you sure you want delete')}
      <b>{selectedRole.name}</b>
      {' '}{t('role and reassign all')}{' '}
      {' '}{' '}{t('users to')}
      <AsyncAutoComplete
        name="role_id"
        placeholder="Role"
        closeMenuOnSelect
        className="delete-role__new-role-field"
        touched
        value={newRole}
        defaultValue={newRole}
        loadOptions={getRolesAutocomplete}
        onChange={onChangeRole}
      />
      {t('role ?')}
    </ConfirmModal>
  );
};

export default DeleteRoleModal;
