import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { CRM_ROUTES } from '../../constants/crm';
import {
  Contacts, Leads, Pipelines, Clients, Invoices,
} from './components';

const CRM = () => (
  <Switch>
    <Route exact path={CRM_ROUTES.CRM} render={() => <Redirect to={CRM_ROUTES.LEADS} />} />
    <Route exact path={CRM_ROUTES.CONTACTS} component={Contacts} />
    <Route exact path={CRM_ROUTES.LEADS} component={Leads} />
    <Route exact path={CRM_ROUTES.PIPELINES} component={Pipelines} />
    <Route exact path={CRM_ROUTES.CLIENTS} component={Clients} />
    <Route exact path={CRM_ROUTES.INVOICES} component={Invoices} />
    <Route exact path={CRM_ROUTES.NEW_INVOICE} component={Invoices} />
    <Route exact path={CRM_ROUTES.EDIT_INVOICE} component={Invoices} />
  </Switch>
);

export default CRM;
