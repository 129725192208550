import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import { userSelectors } from '../../state/user';

const mapStateToProps = state => ({
  myPermissions: userSelectors.getMyPermissions(state),
  user: userSelectors.getUserData(state),
});

const withPermissionHandlers = compose(
  connect(mapStateToProps),
  withHandlers({
    P: ({ myPermissions }) => (module, action, renderWhenHasAccess) => {
      const isAccess = !!pathOr(0, [module, action], myPermissions);
      return renderWhenHasAccess ? (isAccess ? renderWhenHasAccess : '') : isAccess;
    },
  }),
);

export {
  withPermissionHandlers,
};
