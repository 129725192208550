import {
  compose, getContext, withHandlers, withProps, withStateHandlers,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { prop } from 'ramda';
import ContactsItem from './contactsItem';
import { uiActions } from '../../../../state/ui';
import { customFieldsSelectors } from '../../../../state/customFields';

const mapDispatchToProps = {
  openModal: uiActions.openModal,
};

const mapStateToProps = state => ({
  customFieldEntities: customFieldsSelectors.getCustomFieldsEntities(state),
});

const onClickContactItemStateHandler = ({ isOpen }) => () => ({ isOpen: !isOpen });

const onActionHandler = modalName => ({
  openModal,
  setSelectedContactId,
  contact,
}) => () => {
  setSelectedContactId(prop('id', contact));
  openModal(modalName);
};

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common']),
  getContext({
    setSelectedContactId: PropTypes.func.isRequired,
  }),
  withStateHandlers(() => ({ isOpen: false }), {
    onClickContactItem: onClickContactItemStateHandler,
  }),
  withHandlers({
    onEdit: onActionHandler('editContactModal'),
    onDelete: onActionHandler('deleteContactModal'),
  }),
  withProps(({ contact }) => ({
    customFields: contact.customFields,
  })),
);

export default enhancer(ContactsItem);
