import {
  compose, getContext, lifecycle, withProps, withHandlers,
} from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  pathOr, prop, propEq, findIndex, propOr,
} from 'ramda';
import PropTypes from 'prop-types';
import StatusOfLeadsControl from './statusOfLeadsControl';
import { pipelinesActions, pipelinesSelectors } from '../../../../state/pipelines';
import { leadsActions } from '../../../../state/leads';
import { PIPELINE_TYPES } from '../../../../constants/crm';

const mapStateToProps = (state, { lead }) => ({
  pipelineStatuses: pipelinesSelectors.getPipelineStatusesSelector(state)(lead.pipeline_id),
});

const mapDispatchToProps = {
  getPipelineStatuses: pipelinesActions.getPipelineStatusesRequest,
  editLead: leadsActions.editLeadRequest,
  convertLeadToClient: leadsActions.convertLeadToClientRequest,
  getLeadLogs: leadsActions.getLeadLogsRequest,
};

const onChangeStatusHandler = ({
  lead, editLead, setIsNeedRefresh, currentPipelineStatusId, convertLeadToClient,
  getLeadLogs,
}) => (id, type) => () => {
  if (id && id !== currentPipelineStatusId) {
    editLead({
      ...lead,
      pipeline_status_id: id,
    }, {
      callbacks: {
        success: () => {
          getLeadLogs({ id: lead.id });
          setIsNeedRefresh(true);
          if (type === PIPELINE_TYPES.WON) convertLeadToClient({ ...lead });
        },
      },
    });
  }
};


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['tasks', 'common']),
  withProps(({ lead, pipelineStatuses }) => {
    const currentPipelineStatus = pathOr(null, ['pipelineStatus'], lead);
    const currentPipelineStatusId = propOr(null, 'id', currentPipelineStatus);

    return {
      currentPipelineStatusIndex: findIndex(propEq('id', currentPipelineStatusId), pipelineStatuses),
      currentPipelineStatusType: propOr(null, 'type', currentPipelineStatus),
      currentPipelineStatusId,
    };
  }),
  getContext({
    setIsNeedRefresh: PropTypes.func.isRequired,
  }),
  withHandlers({
    onChangeStatus: onChangeStatusHandler,
  }),
  lifecycle({
    componentDidMount() {
      const { getPipelineStatuses, lead } = this.props;
      const pipelineId = prop('pipeline_id', lead);
      getPipelineStatuses({ pipelineId });
    },
  }),
);
export default enhance(StatusOfLeadsControl);
