import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ButtonsCollection } from '../..';
import MessageElements from '../../../components/messageElements';
import { useProjectNotesContext } from '../context/useProjectNotesContext';
import { renderDescriptionWithLinks } from '../../../utils/helpers/stringHelpers/common';

import './style.sass';

/**
 * @param noteId {number}
 * @param content {string}
 * @param title {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const NoteItem = ({ noteId, content, title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    setSelectedNoteId,
    setIsOpenEditModal,
    setIsOpenDeleteModal,
  } = useProjectNotesContext();
  const { t } = useTranslation(['common']);
  const toggleIsOpen = () => {
    setIsOpen(prev => !prev);
  };

  const onEditNoteHandler = () => {
    setSelectedNoteId(noteId);
    setIsOpenEditModal(true);
  };

  const onDeleteNoteHandler = () => {
    setSelectedNoteId(noteId);
    setIsOpenDeleteModal(true);
  };
  
return (
    <div
      className={
        classNames('notes-list__table-row', { 'notes-list__table-row--active': isOpen })
      }
    >
      <div className="notes-list__table-col"><h3 className="text--cut">{title}</h3></div>
      <div
        className="notes-list__table-col notes-list__table-col--description"
      >
        <span className="selectable-text notes-content" dangerouslySetInnerHTML={{__html: renderDescriptionWithLinks(content)}}/>
      </div>
      <div className="notes-list__table-col">
        <div className="notes-list__controls-group">
          <ButtonsCollection.ButtonIcons
            className="notes-list__show-more-button"
            title={`${isOpen ? t('Hide information') : t('Show information')}`}
            onClick={toggleIsOpen}
          >
            <span className="icon-down" />
          </ButtonsCollection.ButtonIcons>
          <ButtonsCollection.ButtonIcons
            className="notes-list__edit-button"
            title={t('Edit note')}
            onClick={onEditNoteHandler}
          >
            <span className="icon-edit-icon" />
          </ButtonsCollection.ButtonIcons>
          <ButtonsCollection.ButtonIcons
            className="notes-list__delete-button"
            onClick={onDeleteNoteHandler}
            title={t('Delete note')}
          >
            <span className="icon-delete-icon" />
          </ButtonsCollection.ButtonIcons>
        </div>
      </div>
    </div>
  );
};
