import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';
import { ConfirmModal } from '../../../../../../ui-kit';

const DeleteProductModal = ({
  isOpen, onClose, product, onDelete,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    onClose={onClose}
    actionTitle="Delete"
    title="Delete project"
    onAction={onDelete}
  >

      Are you sure you want to delete
    <b>{prop('title', product)}</b>

      product?
  </ConfirmModal>
);

DeleteProductModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  product: PropTypes.instanceOf(Object),
  onDelete: PropTypes.func.isRequired,
};

DeleteProductModal.defaultProps = {
  product: null,
};

export default DeleteProductModal;
