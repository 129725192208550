import { createSelector } from 'reselect/lib/index';
import {
  identity, memoizeWith, path, pathOr, propOr,
} from 'ramda';

const getContactByIdFormList = contacts => memoizeWith(
  identity, contactId => propOr(null, contactId, contacts),
);

const getContactsEntitySelector = createSelector(
  path(['contacts', 'summary', 'entities', 'contacts']),
  identity,
);

const getContactsIDsSelector = createSelector(
  path(['contacts', 'summary', 'result']),
  identity,
);

const getContactById = createSelector(
  getContactsEntitySelector,
  getContactByIdFormList,
);

const getPinnedContactsId = createSelector(
  path(['contacts', 'pinnedContacts', 'result']),
  identity,
);

const getPinnedContactsEntitySelector = createSelector(
  path(['contacts', 'pinnedContacts', 'entities', 'pinnedContacts']),
  identity,
);

const getPinnedContactById = createSelector(
  getPinnedContactsEntitySelector,
  getContactByIdFormList,
);

const getContactsPendingRequest = createSelector(pathOr(false, ['pending', 'getContactsRequest']), identity);
const getPinnedContactsPendingRequest = createSelector(pathOr(false, ['pending', 'getPinnedContactsRequest']), identity);

const getContactsCount = createSelector(pathOr(0, ['contacts', 'summary', 'count']), identity);

const getContactsOffset = createSelector(pathOr(0, ['contacts', 'summary', 'offset']), identity);

export {
  getContactsEntitySelector,
  getContactsIDsSelector,
  getContactById,
  getContactsPendingRequest,
  getContactsCount,
  getPinnedContactsId,
  getPinnedContactsEntitySelector,
  getPinnedContactById,
  getPinnedContactsPendingRequest,
  getContactsOffset,
};
