/* eslint-disable */
import { assoc, curry, equals, insert, remove, propOr } from 'ramda';
import { put, select } from 'redux-saga/effects';

import { invoicesSelectors, invoicesActions } from '../../../state/invoices';
import { move } from '../commonHelpers';
import { INVOICE_STATUS_IDS } from '../../../constants/crm';
import { uiActions } from '../../../state/ui';


const makePayload = curry((key, data, invoices) => assoc(key, data, { invoices }));
const addStatusParam = makePayload('status_id');

const updateBoard = curry(function* (status_id, action) {
  const selectedInvoices = yield select(state => invoicesSelectors.getInvoicesByStatusId(state)(status_id));
  const updatedInvoices = action(selectedInvoices);
  return addStatusParam(status_id, updatedInvoices);
});

const dragInvoiceBetweenBoard = function* (data) {
  const { source, destination, itemId, queryParams } = data;

  if (source.droppableId === destination.droppableId) {
    const itemsInSource = yield updateBoard(
      source.droppableId,
      move(source.index, destination.index),
    );
    yield put(invoicesActions.reorderColumnView(itemsInSource));
  } else {
    const invoice = yield select(state => invoicesSelectors.getInvoiceById(state)(itemId));
    const status_id = destination.droppableId;

    const itemsInSource = yield updateBoard(source.droppableId, remove(source.index, 1));
    const itemsInDestination = yield updateBoard(
      destination.droppableId, insert(destination.index, invoice),
    );

    yield put(invoicesActions.reorderColumnView(itemsInSource));
    yield put(invoicesActions.reorderColumnView(itemsInDestination));
    console.log(queryParams);
    if (!equals(parseInt(status_id, 10), INVOICE_STATUS_IDS.SENT)) {
      yield put(invoicesActions.updateInvoiceRequest(
        { invoiceId: itemId, status_id },
        { queryParams: { ...queryParams, page: '', is_lifecycle: 1 } },
      ));
    } else if (!propOr(null, 'is_sent', invoice)) {
      yield put(invoicesActions.openSendModal(itemId));
      yield put(uiActions.openModal('sendInvoiceModal'));
    } else {
      yield put(invoicesActions.updateInvoiceRequest(
        { invoiceId: itemId, status_id },
        { queryParams: { ...queryParams, page: '', is_lifecycle: 1 } },
      ));
    }
  }
};

export default dragInvoiceBetweenBoard;
