import { compose, lifecycle, withState } from 'recompose';
import {
  cond, prop, curry,
} from 'ramda';
import { notEqual, notEqualToZero } from 'ramda-extension';

const isPrevPropEquals = curry((prevProps, condition, comparedPropName, propName) => compose(
  condition(prop(propName, prevProps)),
  prop(comparedPropName),
));

const isNotEmptyAndNotEqual = value => cond([
  [notEqualToZero, notEqual(value)],
]);

const withUnreadCount = ({ unreadIndex }) => compose(
  withState('unreadIndex', 'setUnreadIndex', unreadIndex),
  lifecycle({
    componentDidUpdate(prevProps) {
      const comparePrevProp = isPrevPropEquals(prevProps);
      const { setUnreadIndex } = this.props;
      cond([
        [comparePrevProp(isNotEmptyAndNotEqual, 'unreadCount', 'unreadCount'), compose(
          setUnreadIndex,
          unreadIndex,
        )],
        [comparePrevProp(notEqual, 'channelId', 'channelId'), () => setUnreadIndex(null)],
      ])(this.props);
    },
  }),
);

export default withUnreadCount;
