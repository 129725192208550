import { createActions } from 'redux-actions';
import types from './types';

export const {
  connectSocketToServer,
  listenEvent,
  subscribeChannel,
  unsubscribeChannel,
  reconnectSocketToServer,
} = createActions(
  types.CONNECT_SOCKET_TO_SERVER,
  types.LISTEN_EVENT,
  types.SUBSCRIBE_CHANNEL,
  types.UNSUBSCRIBE_CHANNEL,
  types.RECONNECT_SOCKET_TO_SERVER,
);
