import {
  compose, hoistStatics, getContext, withHandlers,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import STATUS from '../../../../../constants/sprints';
import CompleteSprintModal from './completeSprintModal';
import { getModal } from '../../../../../state/ui/selectors';
import { closeModal } from '../../../../../state/ui/actions';
import { projectActions, projectSelectors } from '../../../../../state/project';

const mapStateToProps = (state, { selectedSprintId }) => ({
  isOpen: getModal(state)('startSprintModal'),
  isPending: projectSelectors.getUpdateSprintRequestPending(state),
  sprint: projectSelectors.getProjectSprint(state)(selectedSprintId),
});

const mapDispatchToProps = {
  updateSprintRequest: projectActions.updateSprintRequest,
  onCloseModal: () => closeModal('startSprintModal'),
};

const onStartHandler = ({
  updateSprintRequest, selectedSprintId, projectId, onCloseModal, sprint,
}) => () => updateSprintRequest({
  projectId,
  sprintId: selectedSprintId,
  ...sprint,
  status: STATUS.IN_PROGRESS,
}, {
  callbacks: {
    success: onCloseModal,
  },
});

const enhance = compose(
  getContext({
    selectedSprintId: PropTypes.number.isRequired,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withHandlers({
    onStart: onStartHandler,
  }),
  hoistStatics,
);

export default enhance(CompleteSprintModal);
