import {
  compose, hoistStatics, getContext, withHandlers, branch, renderNothing,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import DeleteTaskModal from './deleteTaskModal';
import { getModal } from '../../../../state/ui/selectors';
import { closeModal } from '../../../../state/ui/actions';
import { tasksActions } from '../../../../state/tasks';
import { leadsActions, leadsSelectors } from '../../../../state/leads';

const mapStateToProps = (state, { selectedLeadTask }) => ({
  task: leadsSelectors.getLeadTaskSelector(state)(selectedLeadTask),
  isOpen: getModal(state)('deleteTaskModal'),
});

const mapDispatchToProps = {
  deleteTaskRequest: tasksActions.deleteTaskRequest,
  onCloseModal: () => closeModal('deleteTaskModal'),
  getLeadTasks: leadsActions.getLeadTasksRequest,
  getLeadLogs: leadsActions.getLeadLogsRequest,
  deleteLeadTaskSuccess: leadsActions.deleteLeadTaskSuccess,
};

const onDeleteTaskHandler = ({
  deleteTaskRequest,
  onCloseModal,
  onSuccess,
  selectedLeadTask,
  selectedLeadId,
  getLeadTasks,
  getLeadLogs,
  deleteLeadTaskSuccess,
  task,
}) => () => deleteTaskRequest({
  taskId: selectedLeadTask,
  leadId: selectedLeadId,
  project_id: task.project_id ? task.project_id : null,
}, {
  callbacks: {
    success: () => {
      onCloseModal();
      getLeadTasks({ id: selectedLeadId });
      getLeadLogs({ id: selectedLeadId });
      deleteLeadTaskSuccess({ task, selectedLeadId });
      if (onSuccess) {
        onSuccess();
      }
    },
  },
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    selectedLeadId: PropTypes.any,
  }),
  branch(
    ({ task }) => !task,
    renderNothing,
  ),
  withTranslation(['tasks', 'common']),
  withRouter,
  withHandlers({
    onDeleteTask: onDeleteTaskHandler,
  }),
  hoistStatics,
);

export default enhance(DeleteTaskModal);
