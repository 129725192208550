import React from 'react';
import PropTypes from 'prop-types';
import { identity } from 'ramda';

import MessageTextArea from '../../containers/chat/components/messageInput';

import { MessageButtons, MentionsDropdown, ReplayedMessage } from '../../ui-kit';
import EmojiAutoComplete
  from '../messageTextArea/emojiAutoComplete/emojiAutoComplete';
import EmojiPicker from '../../ui-kit/messageTextArea/emojiPicker';
import { ButtonIcons } from '../../ui-kit/ButtonsCollection';

const TypeMessage = ({
  messageContent,
  onTextareaKeyDown,
  onChangeContent,
  onSetEmoji,
  onOpenEmoji,
  onCloseEmoji,
  isEmojiOpen,
  onClickSaveContent,
  mentions,
  isDropdownMentionsOpen,
  className,
  setRef,
  discardButton,
  sendLabel,
  onDiscard,
  onTextAreaFocus,
  emojisAutocomplete,
  isEmojiAutocompleteOpen,
  onOpenEmojiAutocompleteOpen,
  onOpenEmojiAutocompleteClose,
  emojiSelected,
  emojiTyping,
  onSetFocusField,
  onSetEmojiByAutocomplete,
  onSetRefWrapTextArea,
  isBeginMention,
  activeDropdownMember,
  isMouseOverMention,
  onRenderContent,
  replyMessage,
  onClickMention,
  onCloseReplyMessage,
  isWsError,
  onSetInputRef,
  onOpenUploadFilesModal,
  filesListIds,
  onResetFileById,
  options: { isFilesUploadingFunctionality },
  onCloseMentionDropdown,
  setIsDropdownMentionsOpen,
}) => {
  if (messageContent === '') {
    setIsDropdownMentionsOpen(false);
    onCloseMentionDropdown();
  }
  return (
    <>
      {
      replyMessage && (
        <ReplayedMessage
          allowClose
          onClose={onCloseReplyMessage}
          content={onRenderContent(replyMessage.content, onClickMention, replyMessage.id)}
          authorId={replyMessage.created_by}
        />
      )
    }
      <div className="message-textArea__row" ref={onSetRefWrapTextArea}>
        <ul className="message-textArea__files-list">
          {
          filesListIds.map(file => (
            <li className="message-textArea__files-item" key={`file-for-send-${file.id}`}>
              <span className="icon-attach-file message-card__files-icon" />
              <span className="message-textArea__files-name">{file.original}</span>
              <ButtonIcons title="Remove file" onClick={() => onResetFileById(file.id)}>
                <span className="icon-close" />
              </ButtonIcons>
            </li>
          ))
        }
        </ul>
        <EmojiAutoComplete
          onChange={onChangeContent}
          set="messenger"
          emojisAutocomplete={emojisAutocomplete}
          emojiSelected={emojiSelected}
          onSetEmoji={onSetEmoji}
          emojiTyping={emojiTyping}
          isOpen={isEmojiAutocompleteOpen}
          onSetEmojiByAutocomplete={onSetEmojiByAutocomplete}
          isEmojiPickerOpen={isEmojiOpen}
          title={isWsError ? 'Socket connection was broken' : ''}
          onOpen={onOpenEmojiAutocompleteOpen}
          onClose={onOpenEmojiAutocompleteClose}
        />
        <div className="message-textArea__col message-textArea__col--fill">
          <MessageTextArea
            value={messageContent}
            onKeyDown={onTextareaKeyDown}
            onFocusCallback={onTextAreaFocus}
            childrenRef={(ref) => { onSetInputRef(ref); setRef('typeMessage', ref); }}
            onChange={onChangeContent}
            placeholder="Type a message"
            className={className}
          />
        </div>
        <div className="message-textArea__col">
        <>
          <EmojiPicker
            data-emoji="data-emoji"
            onSetFocusField={onSetFocusField}
            onOpen={onOpenEmoji}
            onClose={onCloseEmoji}
            isOpen={isEmojiOpen}
            onSetEmoji={onSetEmoji}
          />
          {
                isFilesUploadingFunctionality && (
                  <button
                    title="Upload files"
                    type="button"
                    onClick={onOpenUploadFilesModal}
                    className="message-textArea__control-button"
                  >
                    <span className="icon-attach-file" />
                  </button>
                )
              }
        </>
          <MessageButtons
            isDisabled={isWsError}
            title={isWsError ? 'Socket connection was broken' : ''}
            onClickSaveContent={onClickSaveContent}
            discardButton={discardButton}
            sendLabel={sendLabel}
            onClickDiscardButton={onDiscard}
          />
        </div>
      </div>
      <MentionsDropdown
        isOpen={isDropdownMentionsOpen}
        members={mentions}
        isBegin={isBeginMention}
        selectedMemberId={activeDropdownMember}
        className="message-textArea__mentions"
        isMouseOverMention={isMouseOverMention}
      />
    </>
  );
};

TypeMessage.propTypes = {
  onTextareaKeyDown: PropTypes.func.isRequired,
  onChangeContent: PropTypes.func.isRequired,
  onTextAreaFocus: PropTypes.func,
  onSetInputRef: PropTypes.func,
  onClickSaveContent: PropTypes.func.isRequired,
  onSetEmoji: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  onOpenEmoji: PropTypes.func.isRequired,
  onCloseEmoji: PropTypes.func.isRequired,
  isEmojiOpen: PropTypes.bool.isRequired,
  isDropdownMentionsOpen: PropTypes.bool.isRequired,
  mentions: PropTypes.arrayOf(PropTypes.object).isRequired,
  messageContent: PropTypes.string,
  className: PropTypes.string.isRequired,
  discardButton: PropTypes.bool,
  onDiscard: PropTypes.func,
  sendLabel: PropTypes.string,
  emojisAutocomplete: PropTypes.instanceOf(Object).isRequired,
  isEmojiAutocompleteOpen: PropTypes.bool,
  onOpenEmojiAutocompleteOpen: PropTypes.func,
  onOpenEmojiAutocompleteClose: PropTypes.func,
  emojiSelected: PropTypes.number.isRequired,
  emojiTyping: PropTypes.string.isRequired,
  onSetFocusField: PropTypes.func.isRequired,
  onSetEmojiByAutocomplete: PropTypes.func.isRequired,
  onSetRefWrapTextArea: PropTypes.func.isRequired,
  onRenderContent: PropTypes.func.isRequired,
  onClickMention: PropTypes.func.isRequired,
  onCloseReplyMessage: PropTypes.func.isRequired,
  isBeginMention: PropTypes.bool.isRequired,
  isMouseOverMention: PropTypes.bool.isRequired,
  activeDropdownMember: PropTypes.number.isRequired,
  replyMessage: PropTypes.oneOfType([PropTypes.instanceOf(Object), () => null]),
  isWsError: PropTypes.bool.isRequired,
  onOpenUploadFilesModal: PropTypes.func.isRequired,
  filesListIds: PropTypes.instanceOf(Array).isRequired,
  onResetFileById: PropTypes.func.isRequired,
  options: PropTypes.instanceOf(Object),
};

TypeMessage.defaultProps = {
  discardButton: false,
  sendLabel: 'Send',
  options: { isFilesUploadingFunctionality: true },
  messageContent: '',
  isEmojiAutocompleteOpen: false,
  onDiscard: () => {},
  onOpenEmojiAutocompleteOpen: identity,
  onSetInputRef: identity,
  onTextAreaFocus: () => {},
  onOpenEmojiAutocompleteClose: identity,
  replyMessage: null,
};

export default TypeMessage;
