import { createActions } from 'redux-actions';
import { identity, pathOr, prop } from 'ramda';

import { callNotification } from '../../utils/helpers/notifies';
import { getErrorMessage } from '../../utils/helpers/requestHelpers';
import types from './types';
import { projectsListSchema, importantTasksSchema } from './schema';

export const {
  getProjectsListRequest,
  addToFavoriteProjectsFetch,
  removeFromFavoriteProjectsFetch,
  addProjectRequest,
  editProjectRequest,
  getProjectTasksRequest,
  getProjectsListMenuRequest,
  deleteProjectRequest,
  getProjectsSummaryRequest,
  getImportantTasksProjectsRequest,
  setProjectsFavorite,
  setProjectsFavoriteCustom,
  setProjectsListMenu,
  removeProjectsFavorite,
  removeProjectsFavoriteCustom,
  setProjectsList,
  addProjectSuccess,
  setProject,
  setMoreProjects,
  setProjectsSummary,
  setImportantTasksProjects,
  editProjectSuccess,
  getProjectsAutocomplete,
  setProjectsAutocomplete,
} = createActions(
  {
    [types.GET_PROJECTS_LIST_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/projects',
        selectorName: 'getProjectsList',
        method: 'GET',
        params,
        schema: {
          rules: projectsListSchema,
          pathData: ['data'],
        },
        actions: {
          success: prop('more', meta) ? setMoreProjects : setProjectsList,
          meta: prop('metaAction', meta),
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.ADD_TO_FAVORITE_PROJECTS_FETCH]: [
      identity,
      ({ id }, meta) => ({
        async: true,
        route: `/favorite-projects/${id}`,
        selectorName: 'setProjectFavorite',
        method: 'POST',
        actions: {
          success: setProjectsFavorite,
          meta: { id },
        },
        ...meta,
      }),
    ],
    [types.REMOVE_FROM_FAVORITE_PROJECTS_FETCH]: [
      identity,
      ({ id }, meta) => ({
        async: true,
        route: `/favorite-projects/${id}`,
        selectorName: 'removeProjectFavorite',
        method: 'DELETE',
        actions: {
          success: removeProjectsFavorite,
          meta: { id },
        },
        ...meta,
      }),
    ],
    [types.ADD_PROJECT_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/projects',
        selectorName: 'addProject',
        method: 'POST',
        params,
        actions: {
          callbacks: {
            success: pathOr(['callbacks', 'success'], meta),
          },
          error: error => callNotification.error(getErrorMessage(error)),
          meta: {
            goToProject: true,
          },
        },
        ...meta,
      }),
    ],
    [types.EDIT_PROJECT_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/projects/${id}`,
        selectorName: 'editProjectRequest',
        method: 'PUT',
        params,
        actions: {
          success: editProjectSuccess,
        },
        callbacks: {
          success: pathOr(identity, ['callbacks', 'success'], meta),
        },
      }),
    ],
    [types.GET_PROJECTS_LIST_MENU_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/projects',
        selectorName: 'getProjectsListMenuRequest',
        method: 'GET',
        params,
        schema: {
          rules: projectsListSchema,
          pathData: ['data'],
        },
        actions: {
          success: setProjectsListMenu,
          meta: prop('metaAction', meta),
        },
        ...meta,
      }),
    ],
    [types.DELETE_PROJECT_REQUEST]: [
      identity,
      ({ id }, meta) => ({
        async: true,
        route: `/projects/${id}`,
        selectorName: 'deleteProjectRequest',
        method: 'DELETE',
        actions: {
          success: () => getProjectsListRequest({ ...meta }),
        },
      }),
    ],
    [types.GET_PROJECTS_SUMMARY_REQUEST]: [
      identity,
      params => ({
        async: true,
        route: '/projects/summary',
        selectorName: 'getProjectsSummaryRequest',
        method: 'GET',
        params,
        actions: {
          success: setProjectsSummary,
        },
      }),
    ],
    [types.GET_IMPORTANT_TASKS_PROJECTS_REQUEST]: [
      identity,
      params => ({
        async: true,
        route: '/tasks/important',
        selectorName: 'getImportantTasksProjectsRequest',
        method: 'GET',
        params,
        schema: {
          rules: importantTasksSchema,
          pathData: ['data'],
        },
        actions: {
          success: setImportantTasksProjects,
        },
      }),
    ],
    [types.GET_PROJECTS_AUTOCOMPLETE]: [
      identity,
      ({ ...params }, meta) => ({
        async: true,
        route: '/projects/autocomplete',
        selectorName: 'getProjectsAutocomplete',
        method: 'GET',
        params,
        actions: {
          success: action => setProjectsAutocomplete({ ...action, meta }),
        },
        ...meta,
      }),
    ],
  },
  types.SET_PROJECTS_FAVORITE,
  types.SET_PROJECTS_FAVORITE_CUSTOM,
  types.SET_PROJECTS_LIST_MENU,
  types.REMOVE_PROJECTS_FAVORITE,
  types.REMOVE_PROJECTS_FAVORITE_CUSTOM,
  types.SET_PROJECTS_LIST,
  types.ADD_PROJECT_SUCCESS,
  types.SET_PROJECT,
  types.SET_MORE_PROJECTS,
  types.SET_PROJECTS_SUMMARY,
  types.SET_IMPORTANT_TASKS_PROJECTS,
  types.EDIT_PROJECT_SUCCESS,
  types.SET_PROJECTS_AUTOCOMPLETE,
);
