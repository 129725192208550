import { useState, useEffect, useCallback } from 'react';
import { useUrlParams } from '.';
import { getOppositeValue } from '../helpers/commonHelpers';

const SORTING_BY_TIME = 'created_at';
const ORDER = 'order';
const SORT = 'sort';
const ASC = 'asc';
const DESC = 'desc';

export const useSorting = ({
  defalutSort = 'created_at', defalutOrder = 'desc', customOnSortBy = null, onSortByAddon = null,
}) => {
  const [sort, setSort] = useState(defalutSort);
  const [order, setOrder] = useState(defalutOrder);
  const { getUrlParam, setUrlParams } = useUrlParams({});

  useEffect(() => {
    const newSort = getUrlParam(SORT, defalutSort);
    if (newSort && newSort !== sort) setSort(newSort);
  }, [getUrlParam, sort]);

  useEffect(() => {
    const newOrder = getUrlParam(ORDER, defalutOrder);
    if (newOrder && newOrder !== order) setOrder(newOrder);
  }, [getUrlParam, order]);

  const onSortBy = useCallback((newSortParams) => {
    if (customOnSortBy) {
      customOnSortBy(newSortParams);
    } else {
      const newOrder = newSortParams.sort !== SORTING_BY_TIME
        ? getOppositeValue(ASC, DESC, order)
        : newSortParams.order;

      setUrlParams({ sort: newSortParams.sort, order: newOrder });
      if (onSortByAddon) onSortByAddon(newSortParams);
    }
  }, [setUrlParams, sort, order, customOnSortBy, onSortByAddon]);

  return {
    order,
    sort,
    onSortBy,
  };
};
