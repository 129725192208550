import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import { KnowledgeBaseContext } from './context';
import api from '../../../api/knowledgeBase.service';

export const DEFAULT_DATA = {
  categories: [],
  articles: [],
  categoryId: null,
  articleId: null,
  articleTitle: '',
  category: null,
};

export const Provider = ({ children }) => {
  const [categoriesData, setCategoriesData] = useState(DEFAULT_DATA.categories);
  const [categoryId, setCategoryId] = useState(DEFAULT_DATA.categoryId);
  const [articleId, setArticleId] = useState(DEFAULT_DATA.articleId);
  const [articleTitle, setArticleTitle] = useState(DEFAULT_DATA.articleTitle);
  const [categoryData, setCategoryData] = useState(DEFAULT_DATA.category);
  const [parentId, setParentId] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [shouldRefreshArticles, setShouldRefreshArticles] = useState(true);

  const refreshCategories = useCallback(() => setShouldRefresh(prevState => !prevState), [])

  const value = useMemo(() => ({
    categoriesData,
    categoryId,
    categoryData,
    setCategoryData,
    parentId,
    setParentId,
    setCategoryId,
    articleId,
    setArticleId,
    articleTitle,
    setArticleTitle,
    isLoading,
    refreshCategories,
    shouldRefreshArticles,
    setShouldRefreshArticles
  }), [
    categoriesData,
    categoryId,
    categoryData,
    setCategoryData,
    parentId,
    setParentId,
    setCategoryId,
    articleId,
    setArticleId,
    articleTitle,
    setArticleTitle,
    isLoading,
    refreshCategories,
    shouldRefreshArticles,
    setShouldRefreshArticles
  ]);

  useEffect(() => {
    if (shouldRefresh) {
      setIsLoading(true);
    }
  }, [shouldRefresh]);

  const fetchCategories = async () => {
    const { data } = await api.getKnowledgeBaseCategories();

    setCategoriesData(data);
    setIsLoading(false);
  };

  const fetchCategoryById = async () => {
    const { kbCategory } = await api.getKnowledgeBaseCategoryById(categoryId);

    setCategoryData(kbCategory);
    setIsLoading(false);
  };

  useEffect(() => {
    if (shouldRefresh) {
      fetchCategories();
      setShouldRefresh(false);
    }
  }, [shouldRefresh]);

  useEffect(() => {
    if (categoryId) {
      fetchCategoryById();
    }
  }, [categoryId])

  return (
    <KnowledgeBaseContext.Provider value={value}>
      {children}
    </KnowledgeBaseContext.Provider>
  );
};
