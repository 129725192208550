import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { path, pathOr } from 'ramda';
import { isNotEmpty } from 'ramda-extension';
import { ButtonsCollection, ScrollWrapper } from '../../../../../ui-kit';
import { getImageUrl } from '../../../../../utils/helpers/requestHelpers';
import './styles.scss';

const HrCandidateReview = ({
  candidate, getValuesSelect, comments, commentsEntities,
  files, filesEntities,
}) => (

  <div className="main-container__content">
    <ScrollWrapper>
      <div className="HrCreateForm HrCandidateReview">
        <div className="HrCreateForm_col">
          <p className="HrCreateForm_col__title">Main information</p>
          <div className="HrField">
            <p className="HrField_label">First name</p>
            <p className="HrField_val">{candidate.first_name}</p>
          </div>
          <div className="HrField">
            <p className="HrField_label">Last name</p>
            <p className="HrField_val">{candidate.last_name}</p>
          </div>
          <div className="HrField">
            <p className="HrField_label">Position</p>
            <p className="HrField_val">{getValuesSelect(candidate.position, 'position')}</p>
          </div>
          <div className="HrField">
            <p className="HrField_label">Status</p>
            <p className="HrField_val">{getValuesSelect(candidate.status, 'status')}</p>
          </div>
          <div className="HrField">
            <p className="HrField_label">First contact</p>
            <p className="HrField_val">{getValuesSelect(candidate.first_contact, 'date')}</p>
          </div>
          <div className="HrField">
            <p className="HrField_label">Date of birthday</p>
            <p className="HrField_val">{getValuesSelect(candidate.date_of_birthday, 'date')}</p>
          </div>
          <div className="HrField">
            <p className="HrField_label">Salary</p>
            <p className="HrField_val">{candidate.salary}</p>
          </div>
        </div>
        <div className="HrCreateForm_col">
          <p className="HrCreateForm_col__title">Contacts</p>
          <div className="HrField">
            <p className="HrField_label">Phone number</p>
            <p className="HrField_val">{candidate.phone}</p>
          </div>
          <div className="HrField">
            <p className="HrField_label">Email</p>
            <p className="HrField_val">{candidate.email}</p>
          </div>
          <div className="HrField">
            <p className="HrField_label">Skype</p>
            <p className="HrField_val">{candidate.skype}</p>
          </div>
          <div className="HrField">
            <p className="HrField_label">Linkedin</p>
            <p className="HrField_val">
              <a
                className="ellipsis"
                rel="noopener noreferrer"
                target="_blank"
                href={candidate.linkedin_url}
              >
                {candidate.linkedin_url}
              </a>
            </p>
          </div>
          <div className="HrField">
            <p className="HrField_label">Facebook</p>
            <p className="HrField_val">
              <a
                className="ellipsis"
                rel="noopener noreferrer"
                target="_blank"
                href={candidate.facebook_url}
              >
                {candidate.facebook_url}
              </a>
            </p>
          </div>
          <div className="HrField">
            <p className="HrField_label">City</p>
            <p className="HrField_val">{candidate.city}</p>
          </div>
        </div>
        <div className="HrCreateForm_col">
          {
            comments && comments.length > 0 && (
              <>
                <p className="HrCreateForm_col__title">Comments</p>
                <div className="HrField height-auto">
                  {
                    comments.map(commentId => (
                      isNotEmpty(commentsEntities[commentId].comment) && (
                        <p
                          key={`hrComment-${commentId}`}
                          className="HrCreateForm_comment"
                        >
                          <span className="priority-label" />
                          <span>{pathOr('', ['comment'], commentsEntities[commentId])}</span>
                        </p>
                      )
                    ))}
                </div>
              </>
            )
          }
        </div>
        <div className="HrCreateForm_col">
          {(files.length > 0 || candidate.file_link) && (
            <>
              <p className="HrCreateForm_col__title">Files</p>
              {candidate.file_link && (
                <div className="HrField">
                  <p className="HrField_label">File link</p>
                  <p className="HrField_val">
                    <a
                      className="ellipsis"
                      rel="noopener noreferrer"
                      target="_blank"
                      href={candidate.file_link}
                    >
                      {candidate.file_link}
                    </a>
                  </p>
                </div>
              )}
              {files.length > 0 && (
                <div className="HrField height-auto">
                  <ul className="HrFilesList">
                    {files.map(fileId => (
                      <li className="HrFilesList_file" key={`file-for-send-${fileId}`}>
                        <a
                          className="HrFilesList_file__link"
                          rel="noopener noreferrer"
                          target="_blank"
                          href={getImageUrl(path(['id'], filesEntities[fileId]))}
                        >
                          <span className="icon-attach-file" />
                          <span>{filesEntities[fileId].original}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
        </div>
        {
          candidate.conclusion && (
            <div className="HrCreateForm_row">
              <p className="HrCreateForm_col__title">Conclusion</p>
              <div className="HrField height-auto">
                <p
                  className="HrField_conclusion"
                  dangerouslySetInnerHTML={{ __html: candidate.conclusion }}
                />
              </div>
            </div>
          )
        }
        <div className="HrCreateForm_row HrCreateForm_row-end">
          <Link to={`/hr-management/candidate/edit/${candidate.id}`} className="HrField_btn">
            <ButtonsCollection.ButtonBrill
              isPending={false}
              className="userFormInvite__submit-button button-brill--fill"
            >
              edit
            </ButtonsCollection.ButtonBrill>
          </Link>
        </div>
      </div>
    </ScrollWrapper>
  </div>
);

HrCandidateReview.propTypes = {
  candidate: PropTypes.instanceOf(Object).isRequired,
  getValuesSelect: PropTypes.func.isRequired,
  comments: PropTypes.instanceOf(Array),
  files: PropTypes.instanceOf(Array),
  commentsEntities: PropTypes.instanceOf(Object),
  filesEntities: PropTypes.instanceOf(Object),
};
HrCandidateReview.defaultProps = {
  comments: [],
  files: [],
  commentsEntities: {},
  filesEntities: {},
};

export default HrCandidateReview;
