import React from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';

import { Label } from '../..';

const MultiValueContainer = (props) => {
  const { children } = props;
  return (
    <components.MultiValueContainer {...props}>
      <Label className="label--shadow multi-value__label label--xs">
        { children }
      </Label>
    </components.MultiValueContainer>
  );
};

MultiValueContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
};

export default MultiValueContainer;
