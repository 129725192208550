import React, { memo } from 'react';
import { ButtonFilter } from '..';

/**
 *
 * @param onClick {function}
 * @param name {string}
 * @param sort {string}
 * @param filter {string}
 * @param className {string}
 * @param order {string}
 * @returns {JSX.Element}
 * @constructor
 */

const Col = ({
  onClick = () => { }, name, sort, filter, className, order,
}) => (
  <div className={className}>
    <ButtonFilter
      value={filter}
      order={order}
      sort={sort}
      onClick={onClick}
    >
      {name}
    </ButtonFilter>
  </div>
);

export default memo(Col);
