import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowWidth } from '../../../../../utils/hooks';
import { Grid } from '../../../../../ui-kit';
import { WINDOW_WIDTH } from '../../../../../constants/ui';

/**
 *
 * @param order {string}
 * @param sort {string}
 * @param onSortBy {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const PeopleHeaderRow = ({ order, sort, onSortBy }) => {
  const windowWidth = useWindowWidth();
  const { t } = useTranslation('common');
  const isNotSmallScreen = useMemo(() => windowWidth > WINDOW_WIDTH.SMALL, [windowWidth]);
  return (
    <Grid.Row className="table-users__rowUsr table-users__row-controls">
      <Grid.Col
        sort={sort}
        order={order}
        filter="id"
        onClick={() => onSortBy({ sort: 'id' })}
        name="#"
        className="table-users__col"
      />
      <div className="table-users__col--avatar" />
      { isNotSmallScreen && (
      <Grid.Col
        sort={sort}
        order={order}
        filter="name"
        onClick={() => onSortBy({ sort: 'name' })}
        name={t('Name')}
        className="table-users__col"
      />
      )}
      <Grid.Col
        order={order}
        sort={sort}
        filter="username"
        name="Username"
        onClick={() => onSortBy({ sort: 'username' })}
        className="table-users__col"
      />
      <Grid.Col
        order={order}
        sort={sort}
        onClick={() => onSortBy({ sort: 'email' })}
        filter="email"
        name={t('Email')}
        className="table-users__col"
      />
      {isNotSmallScreen && (
      <Grid.Col
        order={order}
        sort={sort}
        filter="vacation"
        onClick={() => onSortBy({ sort: 'vacation' })}
        name={t('Vacation')}
        className="table-users__col"
      />
      )}
      <Grid.Col className="table-users__col" />
    </Grid.Row>
  );
};
