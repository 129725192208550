import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import Grid from './components/grid';
import { Preloader } from '../../../../ui-kit';
import { PRELOADER_DIMENSION } from '../../../../constants/ui';

const ProjectsBrill = ({
  refToBrillBuilder, data, isLoadingMore,
}) => (
  <div
    className="brill-projects"
    ref={refToBrillBuilder}
  >
    <Grid
      isLoadingMore={isLoadingMore}
      preloader={(<Preloader dimension={PRELOADER_DIMENSION.EXTRA_SMALL} />)}
      data={data}
    />
  </div>
);

ProjectsBrill.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  refToBrillBuilder: PropTypes.func.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
};

export default ProjectsBrill;
// TODO: Check Position BrillCards after resize and use Left or Right SideBar
