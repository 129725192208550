import {
  lifecycle, compose, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  prop, propOr, path, isEmpty,
} from 'ramda';
import { withFormik } from 'formik';
import { push } from 'connected-react-router';

import { usersActions, usersSelectors } from '../../state/users';
import { projectsActions, projectsSelectors } from '../../state/projects';
import { clientsSelectors, clientsActions } from '../../state/clients';
import { uiActions, uiSelectors } from '../../state/ui';
import { TYPE } from '../../constants/projects';
import { uiHelpers } from '../helpers';
import withAutocomplete from './withAutocomplete';
import { getFullName } from '../helpers/userHelpers';


const mapStateToProps = modalName => state => ({
  usersList: usersSelectors.getUsersForSelect(state),
  addProjectErrors: projectsSelectors.getAddProjectErrors(state),
  getUsersByResult: usersSelectors.getUsersByResult(state),
  isOpen: uiSelectors.getModal(state)(modalName),
  clients: clientsSelectors.getClientsEntitiesSelector(state),
});

const mapDispatchToProps = ({
  addProject: projectsActions.addProjectRequest,
  setCloseModal: uiActions.closeModal,
  goToProject: push,
  uploadTempFile: uiActions.uploadTempFileRequest,
});

const onCloseModalHandler = ({
  resetForm, setCloseModal, initialValues,
}) => (name) => {
  resetForm(initialValues);
  setCloseModal(name);
};

const setClientLabel = (clients, project) => {
  const value = prop('client_id', project) || null;
  const label = !isEmpty(getFullName(path([value, 'contact'], clients)).trim()) ? getFullName(path([value, 'contact'], clients)) : 'None';
  return {
    label, value,
  };
};

const convertToListIds = members => members.map(member => (member.id ? member.id : member));

const withCRUDProject = ({
  rules, data, onSubmit, modalName,
}) => compose(
  connect(mapStateToProps(modalName), mapDispatchToProps),
  withTranslation(['common']),
  withProps(({ getUsersByResult, clients, ...props }) => {
    const project = data(props);
    return ({
      initialValues: ({
        title: propOr('', 'title', project),
        owner: propOr('', 'owner', project),
        type: propOr(TYPE.PUBLIC, 'type', project),
        image: uiHelpers.getImageFrom(project),
        members: compose(uiHelpers.renameKeysForUsers, getUsersByResult, convertToListIds, propOr([], 'members'))(project),
        status: propOr(1, 'status', project),
        description: propOr('', 'description', project),
        client_id: setClientLabel(clients, project),
      }),
    });
  }),
  withAutocomplete({
    name: 'getUsersAutocomplete',
    action: usersActions.getUsersListAutocompleteRequest,
    dataPath: prop('users'),
    searchField: 'q',
  }),
  withAutocomplete({
    name: 'getClientsAutocomplete',
    action: clientsActions.getClientsAutocompleteRequest,
    dataPath: prop('clients'),
    searchField: 'q',
    appendOption: () => ({ label: 'None', value: null }),
  }),
  withFormik({
    mapPropsToValues: ({ initialValues }) => initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: rules,
    handleSubmit: onSubmit,
  }),
  withHandlers({
    onCloseModal: onCloseModalHandler,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        addProjectErrors, setErrors, setSubmitting,
      } = this.props;
      if (prevProps.addProjectErrors !== addProjectErrors && addProjectErrors) {
        setErrors(this.props.addProjectErrors);
        setSubmitting(false);
      }
    },
    componentWillUnmount() {
      this.props.setErrors(null);
    },
  }),
);

export default withCRUDProject;
