import React from 'react';
import { useTranslation } from 'react-i18next';

import './style.sass';

/**
 * @param currentPage {number}
 * @param onGoPreviousPage {function}
 * @param onGoNextPage {function}
 * @param totalPages {number}
 * @param totalCommits {number}
 * @returns {JSX.Element}
 * @constructor
 */


const LIMIT_OF_COMMITS = 10;

export const GitlabCommitsPagination = ({
  currentPage, onGoPreviousPage, onGoNextPage, totalPages, totalCommits,
}) => {
  const { t } = useTranslation(['common']);
  const firstElementOnPage = (LIMIT_OF_COMMITS * currentPage - LIMIT_OF_COMMITS);
  const lastElementOnPage = (LIMIT_OF_COMMITS * currentPage) > totalCommits ? totalCommits : (LIMIT_OF_COMMITS * currentPage);

  return (
    totalCommits > LIMIT_OF_COMMITS
    && (
    <div className="pagination-commits">
      <div className="pagination-commits__string">
        <div className="pagination-commits__current-posts" />
        {firstElementOnPage + 1}-{lastElementOnPage}
        <div className="pagination-commits__delimiter">
          {t('of')}
        </div>
        <div className="pagination-commits__count-posts">
          {totalCommits}
        </div>
      </div>

      <div className="pagination-commits__controls">
        <button
          type="button"
          onClick={() => onGoPreviousPage()}
          disabled={currentPage === 1}
          className="pagination-commits__button pagination-commits__button-prev"
          title={t('Prev page')}
        >
          <span className="icon-pagination-arrow" />
        </button>
        <button
          type="button"
          onClick={() => onGoNextPage()}
          disabled={currentPage === totalPages}
          className="pagination-commits__button"
          title={t('Next page')}
        >
          <span className="icon-pagination-arrow" />
        </button>
      </div>
    </div>
    )
  );
};
