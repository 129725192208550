/* eslint-disable max-len */
import {
  compose, hoistStatics, withHandlers, withProps,
} from 'recompose';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import ProjectNavigation from './projectNavigation';
import { projectSelectors } from '../../../../state/project';
import { hasActiveSprint } from '../../../../utils/helpers/projectHelpers/sprints';
import { TASKS_WITHOUT_PROJECT } from '../../../../constants/tasks';

const taskUrlReg = /(^\/projects\/\d*\/task\/\d*\/$)|(^\/projects\/\d*\/$)/;

const onSetIsActiveTaskLinkHandler = ({ history }) => () => taskUrlReg.test(history.location.pathname);

const mapStateToProps = state => ({
  sprints: projectSelectors.getProjectSprintsTasks(state),
});

const enhancer = compose(
  connect(mapStateToProps),
  withRouter,
  withTranslation(['common']),
  withHandlers({
    onSetIsActiveTaskLink: onSetIsActiveTaskLinkHandler,
  }),
  withProps(({ sprints, match: { params: { id } } }) => ({
    isProjectIdWithoutTask: id === TASKS_WITHOUT_PROJECT,
    isHasActiveSprints: !hasActiveSprint(sprints),
  })),
);

export default hoistStatics(enhancer)(ProjectNavigation);
