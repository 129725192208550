import { useState } from 'react';


export const useCommitsFilters = () => {
  const [titleValue, setTitleValue] = useState('');
  const [authorValue, setAuthorValue] = useState('');

  const onChangeTitleValue = (event) => {
    setTitleValue(event.currentTarget.value);
  };

  const onChangeAuthorValue = (event) => {
    setAuthorValue(event.currentTarget.value);
  };

  return {
    titleValue, authorValue, onChangeTitleValue, onChangeAuthorValue,
  };
};
