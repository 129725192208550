import {
  compose, lifecycle, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withWindowWidth } from '../../utils/enchancers';

import Projects from './projects';
import { openModal, setAppTitle } from '../../state/ui/actions';
import { uiActions } from '../../state/ui';

const mapDispatchToProps = ({
  openModal,
  setAppTitle,
  changeRightSidebarStatus: uiActions.changeRightSidebarStatus,
  changeLeftSidebarStatus: uiActions.changeLeftSidebarStatus,
});

const setCorrectUrlHandler = ({ history }) => () => {
  if (history.location.pathname === '/') history.push('/projects/');
};

const enhancer = compose(
  connect(null, mapDispatchToProps),
  withWindowWidth(),
  withTranslation(['common']),
  withHandlers({
    setCorrectUrl: setCorrectUrlHandler,
  }),
  lifecycle({
    componentDidMount() {
      const {
        setCorrectUrl,
        setAppTitle: setTitle,
        windowWidth,
        changeRightSidebarStatus,
        changeLeftSidebarStatus,
      } = this.props;
      setCorrectUrl();
      setTitle('Projects - Avanga 2.0');
      if (windowWidth < 768) {
        changeRightSidebarStatus(false);
        changeLeftSidebarStatus(true);
      }
    },
  }),
);
export default enhancer(Projects);
