import { compose, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';
import SettingUsersContainer from './SettingUsersContainer';
import { settingUsersActions } from '../../../../../state/settingUsers';
import withGrid from '../../../../../utils/enchancers/withGrid';

const LIMIT_OF_LIST_USERS = 10;

const setSearchValueStateHandlers = () => ({ target: { value } }) => ({ searchValue: value });
const onSearchHandler = ({ searchValue }) => () => ({ searchByValue: searchValue });

const mapDispatchToProps = ({
  getSettingUsers: settingUsersActions.getSettingUsersRequest,
});

const enhance = compose(
  connect(null, mapDispatchToProps),
  withStateHandlers(() => ({ searchValue: '', searchByValue: '' }), {
    setSearchValue: setSearchValueStateHandlers,
    onSearch: onSearchHandler,
  }),
  withGrid({
    limit: LIMIT_OF_LIST_USERS,
    gridName: 'settingUsersList',
    searchValue: ({ searchByValue }) => searchByValue,
    hasMore: ({ usersHasMore }) => usersHasMore,
    count: ({ usersCount }) => usersCount,
    action: ({ getSettingUsers }) => getSettingUsers,
    sortKey: 'sortBy',
    searchByKey: 'name',
  }),
);
export default enhance(SettingUsersContainer);
