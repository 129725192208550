import { createActions } from 'redux-actions';
import types from './types';

export const {
  apiRequest,
  apiRequestSuccess,
  apiRequestError,
  apiRequestCancel,
} = createActions(
  types.API_REQUEST,
  types.API_REQUEST_SUCCESS,
  types.API_REQUEST_ERROR,
  types.API_REQUEST_CANCEL,
);
