import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection, Grid, DropDown } from '../../../../ui-kit';
import './style.sass';

const InvoiceItem = ({
  invoiceId, invoiceStatus, leadName, projectTitle, invoiceStatusesOptions, openEditPage,
  date, total, onDelete, onChangeInvoiceStatus, getInvoiceDownloadUrl, onLinkClick, currencySign,
}) => (
  <Grid.Row
    key={`project-grid  - ${invoiceId}`}
    className="table-invoices__row project-row invoice-item"
  >
    <Grid.ColData className="table-invoices__col">{invoiceId}</Grid.ColData>
    <Grid.ColData className="table-invoices__col table-invoices__col--client">
      <div className="text--cut">{leadName}</div>
    </Grid.ColData>
    <Grid.ColData className="table-invoices__col table-invoices__col--project">
      <div className="text--cut">{projectTitle}</div>
    </Grid.ColData>
    <Grid.ColData className="table-invoices__col table-invoices__col--amount">
      <div className="text--cut p-xs text--breaker">
        <span className="invoice-item__currency">{currencySign}</span>
        {total}
      </div>
    </Grid.ColData>
    <Grid.ColData className="table-invoices__col table-invoices__col--date">
      <div className="text--cut">
        {date}
      </div>
    </Grid.ColData>
    <Grid.ColData className="table-invoices__col">
      <DropDown
        name="invoice-status"
        className="invoice-status-dropdown"
        label={invoiceStatus}
        list={invoiceStatusesOptions}
        onChange={onChangeInvoiceStatus}
      />
    </Grid.ColData>
    <Grid.ColData className="table-invoices__col table-invoices__col--controls">
      <DropDown
        className="controls-group-invoice"
        name="controls-group-invoice"
        label={(
          <ButtonsCollection.ButtonIcons
            className="contacts__table-item-controls-button-task"
            title="Controls for invoice"
          >
            <span className="contacts__table-item-control-icon icon-settings-vertical">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
            </span>
          </ButtonsCollection.ButtonIcons>
        )}
        list={
          [
            {
              id: 0,
              onClick: openEditPage(invoiceId),
              label: (
                <>
                  <span className="invoice-control__icon icon-pencil-2" />
                  <span className="invoice-control__text">Edit</span>
                </>
              ),
            },
            {
              id: 1,
              onClick: onLinkClick(getInvoiceDownloadUrl(1), true),
              label: (
                <>
                  <span className="invoice-control__icon icon-image-file" />
                  <span className="invoice-control__text">Preview</span>
                </>
              ),
            },
            {
              id: 2,
              onClick: onLinkClick(getInvoiceDownloadUrl(0)),
              label: (
                <>
                  <span className="invoice-control__icon icon-download" />
                  <span className="invoice-control__text">Download</span>
                </>
              ),
            },
            {
              id: 3,
              onClick: onDelete,
              label: (
                <>
                  <span className="invoice-control__icon icon-delete-icon" />
                  <span className="invoice-control__text">Delete</span>
                </>
              ),
            },
          ]
        }
      />
    </Grid.ColData>
  </Grid.Row>
);

InvoiceItem.propTypes = {
  invoiceId: PropTypes.number.isRequired,
  invoiceStatus: PropTypes.string.isRequired,
  leadName: PropTypes.string.isRequired,
  projectTitle: PropTypes.string.isRequired,
  invoiceStatusesOptions: PropTypes.instanceOf(Object).isRequired,
  onLinkClick: PropTypes.func.isRequired,
  openEditPage: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeInvoiceStatus: PropTypes.func.isRequired,
  getInvoiceDownloadUrl: PropTypes.func.isRequired,
  currencySign: PropTypes.string.isRequired,
};

export default InvoiceItem;
