/* eslint-disable import/prefer-default-export */
import { compose } from 'recompose';
import {
  cond, curry, is, equals, filter, length, prop, propOr, values,
} from 'ramda';
import { sortByPredicate } from '../commonHelpers';

const getMembersOnlineCount = (result,
  entities) => compose(length, filter(userId => equals(propOr(0, 'is_online',
  entities[userId]), 1)))(result);

const sortUseByOnline = status => sortByPredicate(compose(
  equals(status),
  prop('is_online'),
));

const sortUserByOnlineStatus = curry(status => cond([
  [Array.isArray, sortUseByOnline(status)],
  [is(Object), compose(
    sortUseByOnline(status),
    values,
  )],
]));

export {
  getMembersOnlineCount,
  sortUserByOnlineStatus,
};
