import {
  compose, getContext, lifecycle, withHandlers,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import {
  find, isEmpty, propEq, propOr,
} from 'ramda';
import { isNotEmpty, notEqual } from 'ramda-extension';
import HrCandidateReview from './hrCandidateReview';
import { hrActions, hrSelectors } from '../../store/candidate';
import { POSITIONS, STATUSES } from '../../constants';
import SERVER_TIMEZONE from '../../../../../constants/config';

const mapStateToProps = (state, { match: { params: { id } } }) => ({
  candidate: propOr({}, id, hrSelectors.getHrCandidatesEntities(state)),
  comments: hrSelectors.getHrCommentsList(state),
  commentsEntities: hrSelectors.getHrCommentsEntities(state),
  files: hrSelectors.getHrFilesList(state),
  filesEntities: hrSelectors.getHrFilesEntities(state),
});

const mapDispatchToProps = {
  getHrCandidateRequest: hrActions.getHrCandidateRequest,
  getHrCommentsRequest: hrActions.getHrCommentsRequest,
  getHrFilesRequest: hrActions.getHrFilesRequest,
};

const getValuesSelectHandler = () => (value, type) => {
  let valueLabel = 'none';
  switch (type) {
    case 'position': {
      const position = find(propEq('value', +value), POSITIONS);
      if (position && position.label) valueLabel = position.label;
      break;
    }
    case 'status': {
      const status = find(propEq('value', +value), STATUSES);
      if (status && status.label) valueLabel = status.label;
      break;
    }
    case 'date': {
      const date = moment(value).tz(SERVER_TIMEZONE).format('DD MMM YYYY');
      if (value && date) valueLabel = date;
      break;
    }
    default: return 'none';
  }

  return valueLabel;
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  getContext({ setHeadTitle: PropTypes.func }),
  withHandlers({ getValuesSelect: getValuesSelectHandler }),
  lifecycle({
    componentDidMount() {
      const {
        candidate,
        getHrCandidateRequest,
        setHeadTitle,
        getHrCommentsRequest,
        getHrFilesRequest,
        match: { params: { id } },
      } = this.props;
      // Write header title
      if (isNotEmpty(candidate)) setHeadTitle(`${candidate.first_name} ${candidate.last_name}`);
      // Check is candidate exist
      if (isEmpty(candidate)) getHrCandidateRequest({ id });

      getHrFilesRequest({ id });
      getHrCommentsRequest({ id });
    },
    componentDidUpdate(prevProps) {
      const { setHeadTitle, candidate } = this.props;
      // Write header title if update
      if (notEqual(candidate, prevProps.candidate) && isNotEmpty(candidate)) {
        setHeadTitle(`${candidate.first_name} ${candidate.last_name}`);
      }
    },
  }),
);
export default enhance(HrCandidateReview);
