import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { clientsActions, clientsSelectors } from '../../../../state/clients';
import { preloaderWhileLoading, withWindowWidth } from '../../../../utils/enchancers';
import History from './history';

const mapStateToProps = (state, { clientId }) => ({
  isPending: clientsSelectors.getClientActionPending(state)('getClientLogsRequest'),
  logs: clientsSelectors.getClientLogsSelector(state)(clientId),
  client: clientsSelectors.getClientById(state)(clientId),
});

const mapDispatchToProps = ({
  getClientLogs: clientsActions.getClientLogsRequest,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common']),
  lifecycle({
    componentDidMount() {
      const { clientId: id, getClientLogs } = this.props;
      getClientLogs({ id });
    },
  }),
  withWindowWidth(),
  preloaderWhileLoading({
    dimension: 75,
    isLoading: props => props.isPending,
  }),
);
export default enhance(History);
