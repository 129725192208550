import { propOr } from 'ramda';
import React from 'react';
import { ButtonsCollection, FormsCollection, ModalWindow } from '@/ui-kit';
import { useCreateProcessModal } from './useCreateProcessModal';
import './style.sass';

export const CreateProcessModal = () => {
  const { t, values, touched, errors, isOpen, handleSubmit, handleBlur, handleChange, onClose } =
    useCreateProcessModal();
  return (
    <ModalWindow
      isOpen={isOpen}
      className="create-process__modal"
      modalName="create-process"
      onClose={onClose}
      title="Create business process"
    >
      <FormsCollection.FormWrapper handleSubmit={handleSubmit} className="create-process">
        <FormsCollection.Input
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.title}
          error={propOr('', 'message', errors.title)}
          touched={touched.title}
          name="title"
          id="create-process-modal__title"
          placeholderJump="Process title"
          className="create-process__title-field"
        />
        <div className="button-group">
          <ButtonsCollection.ButtonBrill
            className="button--md create-process__button-save button-brill--fill"
            type="submit"
          >
            {t('Save')}
          </ButtonsCollection.ButtonBrill>
        </div>
      </FormsCollection.FormWrapper>
    </ModalWindow>
  );
};
