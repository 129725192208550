import { createActions } from 'redux-actions';
import { identity } from 'ramda';
import types from './types';
import { settingUsersSchema } from './schema';

export const {
  getSettingUsersRequest,
  getSettingUserRequest,
  updateSettingUserRequest,
  deleteSettingUserRequest,
  createUserRequest,
  resendUserInviteRequest,
  setSettingUsers,
  setSettingUser,
} = createActions(
  {
    [types.GET_SETTING_USERS_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/users',
        selectorName: 'getSettingUsersRequest',
        method: 'GET',
        params,
        schema: {
          rules: settingUsersSchema,
          pathData: ['data'],
        },
        actions: {
          success: setSettingUsers,
        },
        ...meta,
      }),
    ],
    [types.DELETE_SETTING_USER_REQUEST]: [
      identity,
      ({ userId, ...params }, { limit, offset, ...meta }) => ({
        async: true,
        route: `/users/${userId}`,
        selectorName: 'deletingSettingUserRequest',
        method: 'DELETE',
        params,
        actions: {
          success: () => getSettingUsersRequest({ limit, offset }),
        },
        ...meta,
      }),
    ],
    [types.CREATE_USER_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/users',
        selectorName: 'createUserRequest',
        method: 'POST',
        params,
        ...meta,
      }),
    ],
  },
  types.SET_SETTING_USERS,
  types.SET_SETTING_USER,
);
