import { compose, getContext, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import NotesItem from './notesItem';
import { projectSelectors } from '../../../../../../state/project';

const mapStateToProps = (state, { noteId }) => ({
  note: projectSelectors.getNote(state)(noteId),
});

const onEditHandler = ({ onEditNote, noteId }) => () => onEditNote(noteId);
const onDeleteHandler = ({ onDeleteNote, noteId }) => () => onDeleteNote(noteId);

const enhance = compose(
  connect(mapStateToProps),
  getContext({
    onEditNote: PropTypes.func.isRequired,
    onDeleteNote: PropTypes.func.isRequired,
  }),
  withHandlers({
    onEdit: onEditHandler,
    onDelete: onDeleteHandler,
  }),
);
export default enhance(NotesItem);
