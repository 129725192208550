import {
  compose, withHandlers, getContext, lifecycle,
} from 'recompose';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import PinnedClients from './pinnedClients';
import { clientsActions, clientsSelectors } from '../../../../../../state/clients';
import { uiActions } from '../../../../../../state/ui';


const mapStateToProps = state => ({
  pinnedClients: clientsSelectors.getPinnedClientsList(state),
  pending: clientsSelectors.getPinnedClientsPendingRequest(state),
});

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
  getPinnedClientsRequest: clientsActions.getPinnedClientsRequest,
};

const onUnpinHandler = ({
  setSelectedClientId, setOpenModal,
}) => ({ id }) => {
  setSelectedClientId(id);
  setOpenModal('unpinClientModal');
};
const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    setSelectedClientId: PropTypes.func,
  }),
  withHandlers({
    onUnpin: onUnpinHandler,
  }),
  lifecycle({
    componentDidMount() {
      this.props.getPinnedClientsRequest();
    },
  }),
);
export default enhance(PinnedClients);
