import { compose } from 'recompose';
import { withFormik } from 'formik';
import { connect } from 'react-redux';

import AddDiscountForm from './addDiscountForm';
import { invoicesActions, invoicesSelectors } from '../../../../../../state/invoices';
import { INVOICES_PAY_TYPES } from '../../../../../../constants/crm';

const mapDispatchToProps = {
  setDiscount: invoicesActions.setDiscount,
  setDiscountPaymentType: invoicesActions.setDiscountPaymentType,
};

const mapStateToProps = (state, { isEditInvoicePage }) => ({
  discountPaymentType: invoicesSelectors.getDiscountPaymentType(state),
  discount: isEditInvoicePage
    ? JSON.parse(invoicesSelectors.getCurrentInvoiceDiscount(state))
    : invoicesSelectors.getDiscount(state),
});

const defaultLabel = {
  label: 'Percent (%)',
  value: INVOICES_PAY_TYPES.PERCENT,
  id: 1,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    validateOnBlur: true,
    validateOnChange: true,
    handleSubmit: () => {},
    enableReinitialize: true,
    mapPropsToValues: ({
      discount, isEditInvoicePage, getPaymentType, currencySign,
    }) => ({
      discount: discount.value,
      discount_type: isEditInvoicePage
        ? getPaymentType(discount.paymentType, currencySign)
        : defaultLabel,
    }),
  }),
);

export default enhance(AddDiscountForm);
