import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { equals } from 'ramda';
import { compose } from 'recompose';

import { DeleteMessageModal, PinMessageModal, UnpinMessageModal } from '../modals';
import ScrollToBottomButton from '../scrollToBottomButton';
import { ScrollWrapper } from '../../../../ui-kit';
import { MessageItem } from './components';
import JumpToRecentMessagesButton from '../jumpToRecentMessagesButton';

const MessagesListComponent = ({
  messages,
  allRenderedMessages,
  channelId,
  messageTimestamps,
  editableMessage,
  onUpdateMessage,
  onMountEditable,
  onWillUnMountEditable,
  replyMessage,
  isReplyEdited,
  setReplyMessage,
  isPendingMore,
  idMessageWithUnreadLabel,
  scrollToMessageId,
}) => (
  <div>
    {
      messages.map(messageId => (
        <MessageItem
          key={`mes-${messageId}`}
          id={messageId}
          allRenderedMessages={allRenderedMessages}
          channelId={channelId}
          isLastMessage={messageId === messages[0]}
          isUnread={equals(messageId, idMessageWithUnreadLabel)}
          messageTimestamps={messageTimestamps}
          isPendingMore={isPendingMore}
          editableMessage={editableMessage}
          onUpdateMessage={onUpdateMessage}
          scrollToMessageId={scrollToMessageId}
          onMountEditable={onMountEditable}
          onWillUnMountEditable={onWillUnMountEditable}
          replyMessage={replyMessage}
          isReplyEdited={isReplyEdited}
          setReplyMessage={setReplyMessage}
        />
      ))
    }
  </div>
);
const MessagesList = compose(
  memo,
)(MessagesListComponent);

MessagesListComponent.propTypes = {
  messages: PropTypes.instanceOf(Array).isRequired,
  allRenderedMessages: PropTypes.instanceOf(Array).isRequired,
  channelId: PropTypes.number.isRequired,
  messageTimestamps: PropTypes.instanceOf(Object).isRequired,
  editableMessage: PropTypes.oneOfType([PropTypes.number, () => null]),
  onUpdateMessage: PropTypes.func.isRequired,
  onMountEditable: PropTypes.func.isRequired,
  onWillUnMountEditable: PropTypes.func.isRequired,
  replyMessage: PropTypes.oneOfType([PropTypes.instanceOf(Object), () => null]),
  isReplyEdited: PropTypes.bool.isRequired,
  setReplyMessage: PropTypes.func.isRequired,
  isPendingMore: PropTypes.bool.isRequired,
  idMessageWithUnreadLabel: PropTypes.oneOfType([PropTypes.string, () => null]),
  scrollToMessageId: PropTypes.oneOfType([PropTypes.number, () => null]),
};

MessagesListComponent.defaultProps = {
  scrollToMessageId: null,
  idMessageWithUnreadLabel: null,
  replyMessage: {},
  editableMessage: null,
};


const MessageHistory = ({
  onScroll,
  height,
  onDeleteMessage,
  selectedMessage,
  isShowScrollToBottom,
  onScrollToBottom,
  messages,
  allRenderedMessages,
  channelId,
  messageTimestamps,
  editableMessage,
  onUpdateMessageContent,
  onMountEditable,
  onWillUnMountEditable,
  replyMessage,
  isReplyEdited,
  setReplyMessage,
  isPendingMore,
  onSetScrollRef,
  idMessageWithUnreadLabel,
  isPuck,
  setIsPuck,
  scrollToMessageId,
}) => (
  <>
    <ScrollWrapper
      isEasyScroll
      onUpdate={onScroll}
      heightIs={height}
      refCustom={onSetScrollRef}
      renderThumbHorizontal={() => <span />}
      /* renderThumbHorizontal` for remove horizontal scroll */
    >
      <MessagesList
        messages={messages}
        allRenderedMessages={allRenderedMessages}
        channelId={channelId}
        idMessageWithUnreadLabel={idMessageWithUnreadLabel}
        messageTimestamps={messageTimestamps}
        isPendingMore={isPendingMore}
        editableMessage={editableMessage}
        onUpdateMessage={onUpdateMessageContent}
        onMountEditable={onMountEditable}
        onWillUnMountEditable={onWillUnMountEditable}
        replyMessage={replyMessage}
        isReplyEdited={isReplyEdited}
        setReplyMessage={setReplyMessage}
        scrollToMessageId={scrollToMessageId}
      />
      {isPuck && <JumpToRecentMessagesButton onSetIsPuck={setIsPuck} />}
    </ScrollWrapper>
    <div>
      <PinMessageModal messageId={selectedMessage} />
      <UnpinMessageModal messageId={selectedMessage} />
      <DeleteMessageModal onDeleteMessage={onDeleteMessage} messageId={selectedMessage} />
      <ScrollToBottomButton
        onScrollToBottom={onScrollToBottom}
        isShow={isShowScrollToBottom}
      />
    </div>
  </>
);

MessageHistory.propTypes = {
  height: PropTypes.number.isRequired,
  onDeleteMessage: PropTypes.func.isRequired,
  onScroll: PropTypes.func.isRequired,
  onScrollToBottom: PropTypes.func.isRequired,
  isShowScrollToBottom: PropTypes.bool.isRequired,
  messages: PropTypes.instanceOf(Array).isRequired,
  allRenderedMessages: PropTypes.instanceOf(Array).isRequired,
  channelId: PropTypes.number.isRequired,
  messageTimestamps: PropTypes.instanceOf(Object).isRequired,
  editableMessage: PropTypes.oneOfType([PropTypes.number, () => null]),
  onUpdateMessageContent: PropTypes.func.isRequired,
  onMountEditable: PropTypes.func.isRequired,
  onWillUnMountEditable: PropTypes.func.isRequired,
  replyMessage: PropTypes.oneOfType([PropTypes.instanceOf(Object), () => null]),
  isReplyEdited: PropTypes.bool.isRequired,
  setReplyMessage: PropTypes.func.isRequired,
  onSetScrollRef: PropTypes.func.isRequired,
  selectedMessage: PropTypes.oneOfType([PropTypes.number, () => null]),
  isPendingMore: PropTypes.bool.isRequired,
  idMessageWithUnreadLabel: PropTypes.oneOfType([PropTypes.number, () => null]),
  isPuck: PropTypes.bool.isRequired,
  setIsPuck: PropTypes.func.isRequired,
  scrollToMessageId: PropTypes.oneOfType([PropTypes.number, () => null]),
};

MessageHistory.defaultProps = {
  scrollToMessageId: null,
  idMessageWithUnreadLabel: null,
  editableMessage: null,
  selectedMessage: null,
  replyMessage: {},
};

export default MessageHistory;
