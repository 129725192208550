import React from 'react';
import { Emoji } from 'emoji-mart';
import { EMOJI_SIZE } from '../constants';

/**
 *
 * @param category {object}
 * @param scrollToCategoryByElement {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const CategoryItem = ({ category, scrollToCategoryByElement }) => {
  const selectedCategory = document.querySelector(`#emoji__category-${category.id}`);
  return (
    <li className="emoji__picker-category-item">
      <span
        onClick={() => scrollToCategoryByElement(selectedCategory)}
        className="emoji__picker-category-button"
        title={category.name}
        aria-hidden="true"
      >
        <Emoji
          emoji={category.emojis[0]}
          size={EMOJI_SIZE}
        />
      </span>
    </li>
  );
};
