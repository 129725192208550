import React from 'react';
import PropTypes from 'prop-types';
import { ButtonsCollection, DropDown } from '../../../../../../ui-kit';
import { getFullName } from '../../../../../../utils/helpers/userHelpers';
import './style.sass';

const getLeadCardInfo = info => info && (
  <b className="pipe-view-lead__body-info">
    {info}
  </b>
);

const LeadCard = ({
  t,
  isDragging,
  lead,
  onEditLeadModal,
  onDeleteLeadModal,
  onPinLeadModal,
  onUnpinLeadModal,
  onClickLeadCard,
  isPinned,
}) => (
  <div
    role="tab"
    tabIndex="-1"
    onClick={onClickLeadCard}
    onKeyDown={onClickLeadCard}
    className={`pipe-view-lead ${isDragging ? 'pipe-view-lead--drag' : ''}`}
  >
    <div className="pipe-view-lead__header">
      <h4 className="pipe-view-lead__title">{getFullName(lead.contact)}</h4>
      <DropDown
        className="pipe-view-lead-control"
        label={(
          <ButtonsCollection.ButtonIcons
            className="pipe-view-lead__controls-button"
            title="Controls for sprint"
          >
            <span className="table-leads__control-icon icon-settings-vertical">
              <span
                className="path1"
              />
              <span className="path2" />
              <span
                className="path3"
              />
            </span>
          </ButtonsCollection.ButtonIcons>
          )}
        list={
          [
            {
              id: 0,
              onClick: onEditLeadModal,
              label: (
                <>
                  <span className="pipe-view-lead-control__icon icon-pencil-2" />
                  <span className="pipe-view-lead-control__text">{t('Edit')}</span>
                </>
              ),
            },
            {
              id: 2,
              onClick: onDeleteLeadModal,
              label: (
                <>
                  <span className="pipe-view-lead-control__icon icon-delete-icon" />
                  <span className="pipe-view-lead-control__text">{t('Delete')}</span>
                </>
              ),
            },
            {
              id: 2,
              onClick: isPinned ? onUnpinLeadModal : onPinLeadModal,
              label: (
                <>
                  <span className="pipe-view-lead-control__icon icon-delete-icon" />
                  <span className="pipe-view-lead-control__text">
                    {isPinned ? t('Unpin') : t('Pin')}
                  </span>
                </>
              ),
            },
          ]
        }
      />
    </div>
    <div className="pipe-view-lead__body">
      {getLeadCardInfo(lead.pipeline.title)}
      {getLeadCardInfo(lead.contact.position)}
      {getLeadCardInfo(lead.contact.service)}
    </div>
  </div>
);


LeadCard.propTypes = {
  lead: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onEditLeadModal: PropTypes.func.isRequired,
  onDeleteLeadModal: PropTypes.func.isRequired,
  onPinLeadModal: PropTypes.func.isRequired,
  onUnpinLeadModal: PropTypes.func.isRequired,
  onClickLeadCard: PropTypes.func.isRequired,
  isPinned: PropTypes.bool.isRequired,
};


export default LeadCard;
