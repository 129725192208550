import { compose, withContext, withState } from 'recompose';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import TimeEntry from './timeEntry';

const enhance = compose(
  withTranslation(['common', 'tasks']),
  withState('selectedTimeEntryId', 'setSelectedTimeEntryId', 0),
  withContext(
    {
      setSelectedTimeEntryId: PropTypes.func.isRequired,
      selectedTimeEntryId: PropTypes.number.isRequired,
    }, props => ({
      selectedTimeEntryId: props.selectedTimeEntryId,
      setSelectedTimeEntryId: props.setSelectedTimeEntryId,
    }),
  ),
);
export default enhance(TimeEntry);
