import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import userAvatar from '../../../../assets/testAssets/avatar.jpeg';
import './style.sass';
import { Avatar } from '../../../../ui-kit';

const AnswerForMessage = ({ author, time, children }) => (
  <div className="answer-message">
    <div className="answer-message__header">
      <Avatar><img src={userAvatar} alt="" /></Avatar>
      <Link to="/" className="answer-message__name">
        {author}
      </Link>
    </div>
    <div className="answer-message__body">{children}</div>
    <span className="answer-message__date">{time}</span>
  </div>
);

AnswerForMessage.propTypes = {
  author: PropTypes.string,
  time: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
};

AnswerForMessage.defaultProps = {
  author: '',
  time: '',
  children: '',
};

export default AnswerForMessage;
