const SERVER_TIMEZONE = 'Europe/Berlin';
const SERVER_ERRORS = {
  0: { // when has not code error
    code: 404,
    title: 'Oops, seems like you got lost',
    subTitle: 'maybe you should start over again',
  },
  404: {
    code: 404,
    title: 'Oops, seems like you got lost',
    subTitle: 'maybe you should start over again',
  },
  403: {
    code: 403,
    title: 'Sorry, but you do not have a permission \n to go to this page.',
    subTitle: 'maybe you should start over again',
  },
};

export default SERVER_TIMEZONE;

export {
  SERVER_ERRORS,
};
