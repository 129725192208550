import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const SearchGroup = ({
  placeholder, value, onChange,
}) => (
  <div className="search-project">
    <input type="text" onChange={onChange} className="search-project__place-write" placeholder={placeholder} value={value} />
  </div>
);

SearchGroup.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,

};
SearchGroup.defaultProps = {
  placeholder: '',
  value: '',
  onChange: () => {},
};
export default SearchGroup;
