import { useContext } from 'react';
import { KnowledgeBaseContext } from './context';

export function useKnowledgeBaseContext() {
  const context = useContext(KnowledgeBaseContext);
  if (!context) {
    throw new Error('useKnowledgeBaseContext must be used within a Knowledge Base Provider.');
  }
  return context;
}
