import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ButtonsCollection } from '../../../../ui-kit';
import './style.sass';

const NavigationTasks = ({ t }) => (
  <div className="navigation-tasks">
    <nav className="navigation-tasks__nav">
      <ul className="navigation-tasks__list">
        <li className="navigation-tasks__item navigation-tasks__item--active">
          <Link to="tasks/tasks" className="navigation-tasks__link">{t('Tasks')}</Link>
        </li>
        <li className="navigation-tasks__item">
          <Link to="tasks/calendar" className="navigation-tasks__link">{t('Calendar')}</Link>
        </li>
        <li className="navigation-tasks__item">
          <Link to="tasks/progress" className="navigation-tasks__link">{t('Progress')}</Link>
        </li>
        <li className="navigation-tasks__item">
          <Link to="tasks/files" className="navigation-tasks__link">{t('Files')}</Link>
        </li>
        <li className="navigation-tasks__item">
          <Link to="tasks/employees" className="navigation-tasks__link">
            {t('Employees')}
            <span className="navigation-tasks__count">36</span>
          </Link>
        </li>
      </ul>
    </nav>
    <div className="navigation-tasks__controls">
      <ButtonsCollection.Button className="button-default--style-default controls-button  button--xs bg--turquoise">{t('Create Task')}</ButtonsCollection.Button>
      <ButtonsCollection.Button className="button-default--style-default controls-button  button--xs bg--orange">{t('Create Category')}</ButtonsCollection.Button>
      <ButtonsCollection.Button className="button-default--style-default controls-button  button--xs bg--lime">{t('Upload Excel')}</ButtonsCollection.Button>
      <ButtonsCollection.Button className="button-default--style-default controls-button  button--xs bg--lime">{t('Download Excel')}</ButtonsCollection.Button>
    </div>
  </div>
);

NavigationTasks.propTypes = {
  t: PropTypes.func.isRequired,
};

export default NavigationTasks;
