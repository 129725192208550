import {
  compose, getContext, lifecycle, withHandlers,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import LeadTasks from './leadTasks';
import { uiActions } from '../../../../state/ui';
import { leadsSelectors, leadsActions } from '../../../../state/leads';
import { preloaderWhileLoading, withWindowWidth } from '../../../../utils/enchancers';
import { TASKS_WITHOUT_PROJECT, STATUS } from '../../../../constants/tasks';
import { tasksActions } from '../../../../state/tasks';
import { taskActions } from '../../../../state/task';

const mapStateToProps = (state, { leadId }) => ({
  leadTasks: leadsSelectors.getLeadTasksSelector(state)(leadId),
  isLoading: leadsSelectors.getLeadTasksPendingRequest(state),
});

const mapDispatchToProps = {
  openModal: uiActions.openModal,
  getLeadTasks: leadsActions.getLeadTasksRequest,
  editTask: tasksActions.updateTaskRequest,
  getLeadLogs: leadsActions.getLeadLogsRequest,
  connectedPush: push,
  getTask: taskActions.getActiveTaskRequest,
  fullEditLeadTaskSuccess: leadsActions.fullEditLeadTaskSuccess,
  changeTaskStatusSuccess: leadsActions.changeTaskStatusSuccess,
};

const onSetActiveTaskIdHandler = ({ setSelectedLeadTask }) => (id, cb) => () => {
  setSelectedLeadTask(id);
  cb();
};

const onEditFullTaskActionHandler = ({
  getTask, setSelectedLeadTask, leadId,
}) => (task, cb) => () => {
  getTask({ taskId: task.id, leadId, project_id: task.project_id });
  setSelectedLeadTask(task.id);
  cb();
};

const onClickTaskHandler = ({ connectedPush }) => ({ id, project_id: projectId }) => () => {
  connectedPush(`/projects/${projectId || TASKS_WITHOUT_PROJECT}/task/${id}/`);
};

const onChangeTaskStatusHandler = ({
  leadId: id, getLeadTasks, editTask,
  getLeadLogs, changeTaskStatusSuccess,
}) => task => () => {
  const newTaskStatus = task.status_id === STATUS.TO_DO.id ? STATUS.DONE.id : STATUS.TO_DO.id;
  editTask({
    ...task,
    leadId: id,
    projectId: TASKS_WITHOUT_PROJECT,
    status: newTaskStatus,
    status_id: newTaskStatus,
  }, {
    section: 'project',
    callbacks: {
      success: () => {
        getLeadLogs({ id });
        getLeadTasks({ id });
        changeTaskStatusSuccess({ leadId: id });
      },
    },
  });
};
const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { leadId, getLeadTasks } = this.props;
      getLeadTasks({ id: leadId }, { id: leadId });
    },
  }),
  getContext({
    setSelectedLeadTask: PropTypes.func.isRequired,
  }),
  withWindowWidth(),
  withHandlers({
    onSetActiveTaskId: onSetActiveTaskIdHandler,
    onEditFullTask: onEditFullTaskActionHandler,
    onClickTask: onClickTaskHandler,
    onChangeTaskStatus: onChangeTaskStatusHandler,
  }),
  preloaderWhileLoading({
    dimension: 75,
    isLoading: props => !props.isLoading,
  }),
);
export default enhance(LeadTasks);
