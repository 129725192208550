import classNames from 'classnames';
import { prop } from 'ramda';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { components as reactSelectComponents } from 'react-select';
import AsyncSelect from 'react-select/async';

import * as components from '../components';
import '../style.sass';
import { debounceFunc, toTitle } from '../../../utils/helpers/commonHelpers';


const customStyles = {
  option: () => {},
};

/**
 *
 * @param label {string}
 * @param data {object}
 * @param props {object}
 * @returns {JSX.Element}
 * @constructor
 */

const OptionComponent = ({ label, data, ...props }) => (
  <reactSelectComponents.Option {...props} className="auto-complete__option-custom">
    <div className="auto-complete__option-content">
      <span
        className="text--cut"
        title={label}
      >{label}
      </span>
      {' '}
      {prop('username', data) ? (
        <span className="auto-complete__help-option-text text--cut">
          ({prop('username', data)})
        </span>
      ) : ''
        }
    </div>
  </reactSelectComponents.Option>
);

/**
 *
 * @param error {string | boolean}
 * @param disabled {boolean}
 * @param touched {boolean}
 * @param name {string}
 * @param loadOptions {function}
 * @param props {object}
 * @returns {JSX.Element}
 * @constructor
 */
const TIME = 400;

export const AsyncAutoComplete = ({
  error = '', disabled = false, touched = false, name, loadOptions, ...props
}) => {
  const { t } = useTranslation('validation');

  const [inputValue, setInputValue] = useState('');

  const onInputChange = (value) => {
    setInputValue(value);
  };

  const loadOptionsMethod = (e, result) => debounceFunc(() => {
    loadOptions(e, result);
  }, TIME, true, `asyncCompleteDebounce${name}`);

  const upgradedProps = {
    ...props, inputValue, onInputChange, defaultOptions: true,
  };

  return (
    <div className={classNames('auto-complete', { 'auto-complete--has-error': error && touched })}>
      <AsyncSelect
        cacheOptions
        styles={customStyles}
        components={{
          ...components,
          Option: OptionComponent,
        }}
        className="auto-complete__field"
        classNamePrefix="auto-complete"
        loadOptions={(e, result) => loadOptionsMethod(e, result)}
        {...upgradedProps}
        onInputChange={onInputChange}
        isDisabled={disabled}
      />
      {error.length !== 0 && touched && (
      <span className="auto-complete__error field__error-text">
        {t(error, { key: toTitle(name) })}
      </span>
      )}
    </div>
  );
};
