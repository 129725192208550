import React, { useEffect, useState } from 'react';

import { Preloader } from '..';
import { PRELOADER_DURATION } from '../../constants/ui';

/**
 * @param children {array | object | any}
 * @param className {string}
 * @param onAction {boolean | function}
 * @param alignContainerCenter {boolean}
 * @param dimension {number}
 * @param fullScreen {boolean}
 * @param isLoading {boolean}
 * @param delay {number}
 * @returns {JSX.Element}
 * @constructor
 */

export const PreLoaderWhileLoading = ({
  delay = PRELOADER_DURATION,
  isLoading,
  fullScreen = false,
  dimension,
  alignContainerCenter = false,
  onAction,
  className = '',
  children,
}) => {
  const [displayUI, setDisplayUI] = useState(false);
  const [isInitialPreloader, setIsInitialPreloader] = useState(true);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setDisplayUI(true);
      }, delay);
    }
  }, [isLoading]);

  useEffect(() => {
    if (onAction && displayUI && !isInitialPreloader) {
      setDisplayUI(false);
      setTimer(
        setTimeout(() => {
          setDisplayUI(true);
        }, delay),
      );
    }
    if (!isLoading && isInitialPreloader) {
      setIsInitialPreloader(false);
      setTimer(
        setTimeout(() => {
          setDisplayUI(true);
        }, delay),
      );
    }
  }, [isInitialPreloader, displayUI, onAction]);

  useEffect(() => clearTimeout(timer));

  return (
    displayUI ? (
      children
    ) : (
      <Preloader
        fullScreen={fullScreen}
        className={className}
        alignContainerCenter={alignContainerCenter}
        dimension={dimension}
        duration={delay}
      />
    )
  );
};
