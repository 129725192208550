import { handleActions } from 'redux-actions';

import types from './types';
import { mergeIn } from '../../utils/helpers/ramdaStateHelpers';

const initialState = {
  company: {
    companyInfo: {},
    bankDetails: {},
  },
  client: {},
};

const reducer = handleActions({
  [types.SET_CLIENT_BILLING_INFO]: mergeIn(({ payload }) => ({
    client: {
      ...payload,
    },
  })),
}, initialState);

export default reducer;
