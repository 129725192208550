import React, { memo } from 'react';

import { ButtonsCollection } from '@/ui-kit';
import { useProcessNode } from './useProcessNode';

import './style.sass';

export const ProcessNode = memo((node) => {
  const { activeEvent, onAddAction, onEditAction, onDeleteAction } = useProcessNode(node);
  return (
    <>
      <div className="pieceOfProcess" onClick={onEditAction}>
        <h4 className="pieceOfProcess__title">{activeEvent?.title ?? ''}</h4>
        <div className="pieceOfProcess__add-among">
          <ButtonsCollection.ButtonPlus title="Add action" onClick={onAddAction} />
          <div className="pieceOfProcess__add-among-arrow" />
        </div>
        <div className="pieceOfProcess__controls-group">
          <ButtonsCollection.ButtonIcons title="Delete node" type="button" onClick={onDeleteAction}>
            <span className="icon-delete-icon" />
          </ButtonsCollection.ButtonIcons>
        </div>
      </div>
      <hr />
    </>
  );
});
