export default {
  GET_CONTACTS_REQUEST: 'GET_CONTACTS_REQUEST',
  GET_CONTACT_REQUEST: 'GET_CONTACT_REQUEST',
  ADD_CONTACT_REQUEST: 'ADD_CONTACT_REQUEST',
  EDIT_CONTACT_REQUEST: 'EDIT_CONTACT_REQUEST',
  UPDATE_CONTACT_REQUEST: 'UPDATE_CONTACT_REQUEST',
  DELETE_CONTACT_REQUEST: 'DELETE_CONTACT_REQUEST',

  SET_CONTACTS: 'SET_CONTACTS',
  SET_CONTACT: 'SET_CONTACT',
  DELETE_CONTACT: 'DELETE_CONTACT',

  GET_PINNED_CONTACTS_REQUEST: 'GET_PINNED_CONTACTS_REQUEST',
  PIN_CONTACT_REQUEST: 'PIN_CONTACT_REQUEST',
  UNPIN_CONTACT_REQUEST: 'UNPIN_CONTACT_REQUEST',
  SET_PINNED_CONTACTS: 'SET_PINNED_CONTACTS',
  SET_PINNED_CONTACT: 'SET_PINNED_CONTACT',
};
