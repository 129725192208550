import * as messengerActions from './actions';
import * as messengerSelectors from './selectors';
import * as messengerOperations from './operations';
import * as messengerSchemas from './schema';
import messengerTypes from './types';
import reducer from './reducers';

export {
  messengerActions,
  messengerSelectors,
  messengerOperations,
  messengerSchemas,
  messengerTypes,
};

export default reducer;
