import { createActions } from 'redux-actions';
import { identity } from 'ramda';
import { schema } from 'normalizr';
import types from './types';

import {
  categoriesSchema,
  projectsSchema,
  sprintsSchema, statusesSchema,
} from './schema';

export const {
  getCategoriesTaskCrudRequest,
  getProjectsTaskCrudRequest,
  getSprintsTaskCrudRequest,
  getProjectTaskCrudRequest,
  getStatusesTaskCrudRequest,
  setCategoriesTaskCrud,
  setProjectsTaskCrud,
  setSprintsTaskCrud,
  setSelectedProjectCrud,
  setProjectTaskCrud,
  setStatusesTaskCrud,
  getSprintsTaskCrudAutocomplete,
} = createActions(
  {
    [types.GET_CATEGORIES_TASK_CRUD_REQUEST]: [
      identity,
      ({ projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/task-categories`,
        selectorName: 'getCategoriesTaskCrudRequest',
        method: 'GET',
        params,
        schema: {
          rules: categoriesSchema,
          pathData: ['data'],
        },
        actions: {
          success: setCategoriesTaskCrud,
        },
        ...meta,
      }),
    ],
    [types.GET_PROJECTS_TASK_CRUD_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/projects',
        selectorName: 'getProjectsTaskCrudRequest',
        method: 'GET',
        params,
        schema: {
          rules: projectsSchema,
          pathData: ['data'],
        },
        actions: {
          success: setProjectsTaskCrud,
        },
        ...meta,
      }),
    ],
    [types.GET_SPRINTS_TASK_CRUD_REQUEST]: [
      identity,
      ({ projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/sprints`,
        selectorName: 'getSprintsTaskCrudRequest',
        method: 'GET',
        params,
        schema: {
          rules: { sprints: sprintsSchema },
          pathData: ['data'],
        },
        actions: {
          success: setSprintsTaskCrud,
        },
        ...meta,
      }),
    ],
    [types.GET_PROJECT_TASK_CRUD_REQUEST]: [
      identity,
      ({ projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}`,
        selectorName: 'getProjectTaskCrudRequest',
        method: 'GET',
        params,
        schema: {
          rules: { members: [new schema.Entity('members')] },
          pathData: ['project'],
        },
        actions: {
          success: setProjectTaskCrud,
        },
        ...meta,
      }),
    ],
    [types.GET_STATUSES_TASK_CRUD_REQUEST]: [
      identity,
      ({ projectId }) => ({
        async: true,
        route: `/projects/${projectId}/statuses`,
        selectorName: 'getStatusesTaskCrudRequest',
        method: 'GET',
        schema: {
          rules: statusesSchema,
          pathData: ['data'],
        },
        actions: {
          success: setStatusesTaskCrud,
        },
      }),
    ],
  },
  types.SET_CATEGORIES_TASK_CRUD,
  types.SET_PROJECTS_TASK_CRUD,
  types.SET_SPRINTS_TASK_CRUD,
  types.SET_SELECTED_PROJECT_TASK_CRUD,
  types.SET_PROJECT_TASK_CRUD,
  types.SET_STATUSES_TASK_CRUD,
  types.GET_SPRINTS_TASK_CRUD_AUTOCOMPLETE,
);
