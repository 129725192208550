import { useState } from "react";

export const usePreviousPath = () => {
    const [path, setPath] = useState(null);
    const pathHandler = (pathFrom) => {
        setPath(pathFrom);
    };
    return {
        path,
        pathHandler
    }
}
