import { Line } from 'react-chartjs-2';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CardActivity, TotalTimeList } from './cardsActivity/components';
import api from '../../../../api/activity.service';
import { PieChart } from './PieChart';
import { useActivityContext } from '../context/useActivityContext';
import { LineChart } from './LineChart';

const MyActivity = () => {
  const { startDate, endDate, projectValue } = useActivityContext();
  const { t } = useTranslation();

  const filterData = {
    startDate: `${startDate}`,
    endDate: `${endDate}`,
    project_id: projectValue.value,
  };

  const [userTotalTimeHours, setUserTotalTimeHours] = useState([]);
  const [userTotalTimeDates, setUserTotalTimeDates] = useState([]);
  const [userTotalTimePerProjects, setUserTotalTimePerProjects] = useState([]);
  const [requestResult, setRequestResult] = useState(null);

  const getActivity = async () => {
    try {
      const data = await api.getUserTotalTimeRequest(filterData);
      setRequestResult(data);
      const spent = data?.userTotalTimePerDays?.datasets;
      const dates = data?.userTotalTimePerDays?.labels;
      setUserTotalTimeHours(spent);
      setUserTotalTimeDates(dates);
      setUserTotalTimePerProjects(data.totalTimePerProjects);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getActivity();
  }, [startDate, endDate, projectValue]);

  return (
    <div className="cards-activity-container__row">
      <div className="cards-activity-container__col">
        <CardActivity title={t('User total time')}>
          <LineChart labels={userTotalTimeDates} datasets={userTotalTimeHours} />
        </CardActivity>
        <CardActivity
          title={t('Total time per projects')}
        >
          {
            userTotalTimePerProjects
            && (
              <TotalTimeList
                data={userTotalTimePerProjects}
                showSublist={true}
                fields={{
                  project: true, spent: true, estimate: true, more: true,
                }}
              />
            )
          }
        </CardActivity>
      </div>
      <div className="cards-activity-container__col">
        <PieChart
          dateValue={filterData}
          id={!projectValue.value}
          requestResult={requestResult}
        />
      </div>
    </div>
  );
};

export default MyActivity;
