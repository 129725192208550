import { createSelector } from 'reselect/lib/index';
import {
  pathOr,
  identity,
  path,
  memoizeWith,
  compose,
  filter,
  isNil,
  keys,
  map,
  propOr,
  prop,
  values,
  uniqBy,
  propEq,
  includes,
  flip,
  anyPass,
} from 'ramda';
import { tasks as tasksConstants } from '../../constants';

const getProjectsKeys = compose(
  map(item => Number(item)),
  keys,
  filter(item => isNil(item.sprint_id)),
);

const tasksEntitiesSelector = createSelector(
  state => path(['project', 'entities', 'tasks'], state),
  identity,
);

const getTaskActive = createSelector(
  state => pathOr({}, ['tasks', 'active'], state),
  identity,
);

const getTasks = tasksEntitiesSelector;

const getTasksList = createSelector(
  pathOr([], ['tasks', 'currents', 'result']),
  identity,
);

const getTask = createSelector(tasksEntitiesSelector,
  tasks => memoizeWith(identity, taskId => pathOr({}, [taskId], tasks)));

const getMyTasksEntities = createSelector(
  pathOr({}, ['tasks', 'entities', 'items']),
  identity,
);

const getMyTasksCurrentEntities = createSelector(
  pathOr({}, ['tasks', 'currents', 'entities', 'items']),
  identity,
);

const getMyTaskIDs = createSelector(
  pathOr([], ['tasks', 'currents', 'result']),
  identity,
);

const getMyTask = createSelector(getMyTasksCurrentEntities,
  myTasks => memoizeWith(identity, myTaskId => propOr({}, myTaskId, myTasks)));

const getImportantMyTasks = createSelector(
  getMyTasksEntities,
  filter(
    anyPass([
      propEq('priority', tasksConstants.PRIORITY.HIGH.value),
      propEq('priority', tasksConstants.PRIORITY.VERY_HIGH.value),
    ]),
  ),
);

const getMyTasksProjects = createSelector(
  getMyTasksCurrentEntities,
  compose(uniqBy(prop('id')), filter(identity), map(prop('project')), values),
);

const getMyTasksByProjectId = createSelector(
  getMyTasksCurrentEntities,
  getMyTaskIDs,
  (myTasks, myTasksId) => memoizeWith(identity, projectId => compose(
    flip(filter)(myTasksId),
    flip(includes),
    map(prop('id')),
    filter(propEq('project_id', projectId)),
    values,
  )(myTasks)),
);

const getMyTasksSummary = createSelector(
  pathOr({}, ['tasks', 'summary']),
  identity,
);

const getTasksByProjectList = createSelector(
  pathOr([], ['tasks', 'currents', 'taskByProjectData']),
  identity,
);

const getAddTaskPendingRequest = createSelector(
  pathOr(false, ['pending', 'addTaskRequest']),
  identity,
);
const getMyTaskPendingRequest = createSelector(
  pathOr(false, ['pending', 'getMyTasksRequest']),
  identity,
);
const getMyTaskSummaryRequest = createSelector(
  pathOr(false, ['pending', 'getMyTasksSummaryRequest']),
  identity,
);
const getTasksPendingRequest = createSelector(
  pathOr(false, ['pending', 'getTasksRequest']),
  identity,
);

const getAddTaskErrors = createSelector(
  pathOr([], ['errors', 'addTask']),
  identity,
);

const getBacklogTasks = createSelector(
  tasksEntitiesSelector,
  tasks => tasks && getProjectsKeys(tasks),
);

const getDeleteTaskPendingRequest = createSelector(
  pathOr(false, ['pending', 'deleteTaskRequest']),
  identity,
);

export {
  getTasks,
  getTask,
  getTasksList,
  getMyTasksCurrentEntities,
  getMyTaskIDs,
  getMyTask,
  getAddTaskErrors,
  getBacklogTasks,
  getAddTaskPendingRequest,
  getMyTaskPendingRequest,
  getTasksPendingRequest,
  getDeleteTaskPendingRequest,
  getMyTaskSummaryRequest,
  getTaskActive,
  getMyTasksProjects,
  getMyTasksByProjectId,
  getMyTasksSummary,
  getImportantMyTasks,
  getTasksByProjectList,
};
