import { lifecycle, compose } from 'recompose';
import { connect } from 'react-redux';
import { memo } from 'react';

import {
  cond, flip, forEachObjIndexed, prop,
} from 'ramda';
import { uiActions } from '../../state/ui';
import { isNotNil } from '../helpers/commonHelpers';

const keys = {
  hidden: 'visibilitychange',
  webkitHidden: 'webkitvisibilitychange',
  mozHidden: 'mozvisibilitychange',
  msHidden: 'msvisibilitychange',
};

const mapDispatchToProps = ({
  setIsFocus: uiActions.setIsFocus,
});

const getEventKeyAndName = () => {
  let eventKey = null;
  let eventName = null;
  const setEventKey = (value, key) => {
    cond([
      [compose(isNotNil, flip(prop)(document)), (property) => {
        eventKey = property;
        eventName = keys[property];
        return true;
      }],
    ])(key);
  };
  forEachObjIndexed(setEventKey, keys);
  return { eventKey, eventName };
};

const withDocumentVisibleChange = compose(
  connect(null, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { setIsFocus } = this.props;
      const { eventName, eventKey } = getEventKeyAndName();
      document.addEventListener(eventName, data => setIsFocus(!data.target[eventKey]), false);
      document.addEventListener('focus', () => setIsFocus(true), false);
      // document.addEventListener('blur', () => setIsFocus(false), false);
      // window.addEventListener('blur', () => setIsFocus(false), false);
    },
    componentWillUnmount() {
      const { setIsFocus } = this.props;
      const { eventName, eventKey } = getEventKeyAndName();
      document.removeEventListener(eventName, data => setIsFocus(!data.target[eventKey]), false);
      document.removeEventListener(eventName, data => setIsFocus(!data.target[eventKey]), false);
      // window.removeEventListener(eventName, data => setIsFocus(!data.target[eventKey]), false);
    },
  }),
  memo,
);


export default withDocumentVisibleChange;
