import React from 'react';
import { Avatar, BrillMask } from '../..';

/**
 *
 * @param alt {string}
 * @param src {string}
 * @returns {JSX.Element}
 * @constructor
 */


export const ItemLabel = ({ alt, src }) => <>
  <BrillMask className="agenda-board__user-list-avatar" disableHighlight asLink to="/">
    <Avatar src={src} alt={alt} className="users-list__avatar" />
  </BrillMask>
  <span className="agenda-board__user-list-name">{alt}</span>
</>;
