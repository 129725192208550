import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonsCollection, FormsCollection, ModalWindow } from '@/ui-kit';
import { callNotification } from '@/utils/helpers/notifies';
import { useKnowledgeBaseContext } from '@/views/knowledgeBase/context/useKnowledgeBaseContext';
import api from '../../../../../../../api/knowledgeBase.service';
import './style.sass';

export const CreateSubcategoryModal = ({ isOpen, setIsOpenedModal }) => {
  const { t } = useTranslation();

  const { parentId, setParentId, refreshCategories } = useKnowledgeBaseContext();

  const {
    control, reset, handleSubmit,
  } = useForm({
    defaultValues: {
      description: '',
    }
  });

  const onCloseModal = () => {
    setParentId(null);
    reset();
    setIsOpenedModal(false)
  };

  const onSubmit = async (data) => {
    try {
      await api.createKnowledgeBaseCategory({ parent_id: parentId, ...data });

      callNotification.success('Subcategory has been created.');

      refreshCategories();

      onCloseModal();
    } catch (e) {
      callNotification.error('Error with creating subcategory.');
      console.error(e);
    }
  };

  return (
    <ModalWindow
      isOpen={isOpen}
      onClose={onCloseModal}
      modalName="createSubcategoryModal"
      title="Create subcategory"
    >
      <FormsCollection.FormWrapper handleSubmit={handleSubmit(onSubmit)} className="create-subcategory">
        <div className="create-subcategory__row">
          <Controller
            name="description"
            control={control}
            render={({ onBlur, value, field, onChange, error }) => (
              <FormsCollection.Input
                placeholderJump="Subcategory title"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                touched
                error={error}
                {...field}
              />
            )}
          />
        </div>
        <div className="button-group">
          <ButtonsCollection.ButtonBrill
            type="submit"
            className="button--md create-subcategory__button-save button-brill--fill"
          >
            {t('Save')}
          </ButtonsCollection.ButtonBrill>
        </div>
      </FormsCollection.FormWrapper>
    </ModalWindow>
  )
}
