import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import api from '@/api/knowledgeBase.service';
import { ButtonsCollection, FormsCollection, Header } from '@/ui-kit';
import { callNotification } from '@/utils/helpers/notifies';
import './style.sass';

export const AskQuestion = () => {
  const { t } = useTranslation('common');

  const { goBack } = useHistory();

  const {
    control, handleSubmit, formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      message: ''
    }
  });

  const onClose = useCallback(() => {
    goBack();
  }, [goBack]);

  const onSubmit = async (data) => {
    try {
      await api.askKnowledgeBaseQuestion(data);

      callNotification.success('Question has been sent.');

      onClose();
    } catch (e) {
      callNotification.error('Error with sending question.');
      console.error(e);
    }
  };

  return (
    <>
      <Header hideToggleRightSideBar className="ask-question__header">
        <h1 className="weight--medium title--sm title-page">
          <ButtonsCollection.ButtonIcons
            title={t('Go back')}
            renderMobileButton
            className="ask-question__header__head-back"
            onClick={onClose}
          >
            <span className="icon-expand-arrow-left" />
          </ButtonsCollection.ButtonIcons>
          {t('Request form "improvement for Avanga"')}
        </h1>
      </Header>
      <div className="main-container__content ask-question__container">
        <FormsCollection.FormWrapper
          handleSubmit={handleSubmit(onSubmit)}
          classNameForm="ask-question__form"
        >
          <div className="ask-question__form__row">
            <Controller
              name="name"
              control={control}
              render={({ onBlur, value, field, onChange, error }) => (
                <FormsCollection.Input
                  placeholderJump="Name"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  touched
                  error={error}
                  {...field}
                />
              )}
            />
          </div>
          <div className="ask-question__form__row">
            <Controller
              name="message"
              control={control}
              render={({ onBlur, value, field, onChange, error }) => (
                <FormsCollection.TextArea
                  placeholderJump="Message"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  touched
                  autoHeight
                  error={error}
                  {...field}
                />
              )}
            />
          </div>
          <div className="ask-question__form__row ask-question__form__row--buttons">
            <ButtonsCollection.ButtonBrill
              type="submit"
              className="ask-question__form__submit-button button-brill--fill"
            >
              {t('Send')}
            </ButtonsCollection.ButtonBrill>
          </div>
        </FormsCollection.FormWrapper>
      </div>
    </>
  )
}
