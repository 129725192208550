import React from 'react';
import PropTypes from 'prop-types';

import {
  ConfirmModal,
} from '../../../../ui-kit';
import { getFullName } from '../../../../utils/helpers/userHelpers';

const PinContactModal = ({
  isOpen,
  isPending,
  onClose,
  contact,
  onPin,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Pin"
    onAction={onPin}
    onClose={onClose}
    title="Pin lead"
  >
    Are you sure you want to pin
    <b>{getFullName(contact)}</b>
    contact?
  </ConfirmModal>
);

PinContactModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPin: PropTypes.func.isRequired,
  contact: PropTypes.shape({}).isRequired,
};

export default PinContactModal;
