import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from '@/state/ui';
import { bpSelectors, bpActions } from '@bp/store/bpModule';

export const useBusinessProcess = () => {
  const dispatch = useDispatch();
  const selectedProcess = useSelector(bpSelectors.getBusinessProcessSelected);

  const renameProcess = () => {
    dispatch(uiActions.openModal('renameProcess'));
  };

  useEffect(
    () => () => {
      dispatch(bpActions.clearDataBeforeClosePage());
    },
    [dispatch],
  );
  return { selectedProcess, renameProcess };
};
