import { compose } from 'recompose';
import connect from 'react-redux/es/connect/connect';

import { closeModal } from '../../../state/ui/actions';
import { getModal } from '../../../state/ui/selectors';
import AttachFilesModal from './attachFilesModal';

const mapStateToProps = state => ({
  isOpen: getModal(state)('attachFilesModalMessenger'),
});

const mapDispatchToProps = ({
  closeModal,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(AttachFilesModal);
