import classNames from 'classnames';
import React, { memo, useCallback, useState } from 'react';
import ReactCustomScrollBar from 'react-custom-scrollbars';
import { WINDOW_CONTENT_HEIGHT } from '@/constants/window-screen';
import './style.sass';

const renderViewScrollCustom = (e) => <div {...e} className="scroll-wrapper__view" />;
const renderThumbScrollCustom = (e) => <div {...e} className="scroll-wrapper__thumb-vertical" />;

const renderTrackScrollCustom =
  (autoHideHover) =>
  (style, ...e) =>
    (
      <div
        {...e}
        style={{ ...style }}
        className={classNames('scroll-wrapper__track-vertical', {
          'scroll-wrapper__track-vertical--auto-hide-hover': autoHideHover,
        })}
      />
    );

/**
 *
 * @param children {JSX.Element | array | string}
 * @param refCustom {function}
 * @param autoHideHover {boolean}
 * @param heightIs {string | number}
 * @param className {string}
 * @param onUpdate {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const ScrollWrapper = memo(
  ({
    children,
    refCustom = () => {},
    autoHideHover = false,
    heightIs = '',
    className = '',
    calcContentHeight = false,
    onUpdate = () => {},
    ...props
  }) => {
    const contentsHeight = calcContentHeight ? WINDOW_CONTENT_HEIGHT.LARG_SCREEN : '100%';
    const stylesCustom = { height: heightIs || contentsHeight || '100%', width: '100%' };
    const [isVisible, setIsVisible] = useState(false);
    const customClassName = classNames('scroll-wrapper', className, {
      'scroll-wrapper--hidden': !isVisible,
    });

    const onVisibleStatus = useCallback(
      ({ clientHeight, scrollHeight }) => {
        const newIsVisibleStatus = clientHeight !== scrollHeight;
        if (isVisible !== newIsVisibleStatus) {
          setIsVisible(!isVisible);
        }
      },
      [isVisible],
    );

    const onCustomUpdate = (e) => {
      if (onVisibleStatus) onVisibleStatus(e);
      if (onUpdate) onUpdate(e);
    };

    return (
      <ReactCustomScrollBar
        className={customClassName}
        ref={refCustom}
        renderView={renderViewScrollCustom}
        onUpdate={onCustomUpdate}
        renderTrackVertical={renderTrackScrollCustom(autoHideHover)}
        renderThumbVertical={renderThumbScrollCustom}
        style={stylesCustom}
        {...props}
      >
        {children}
      </ReactCustomScrollBar>
    );
  },
);
