import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { ButtonsCollection, Avatar, BrillMask } from '../../../../../ui-kit';

const TimeEntryItem = ({
  userName,
  onShowUserProfile,
  estimatedTime,
  dateLog, isNewDateFlag, createdAt, userAvatar, onEditTimeEntry, onDeleteTimeEntry,
}) => (
  <>
    {
      isNewDateFlag && (
        <div className="time-entry__history-title">{dateLog}</div>)
    }
    <li className="time-entry__history-item">
      <BrillMask asLink onClick={onShowUserProfile}>
        <Avatar src={userAvatar} alt={userName} />
      </BrillMask>
      <div className="time-entry__history-time">{estimatedTime}</div>
      <div className="time-entry__history-date-log">{createdAt}</div>
      <ButtonsCollection.ButtonIcons
        className="time-entry__item-button"
        onClick={onEditTimeEntry}
        title="Edit log"
      >
        <span className="icon-edit-icon" />
      </ButtonsCollection.ButtonIcons>
      <ButtonsCollection.ButtonIcons
        className="time-entry__item-button time-entry__item-button-delete"
        onClick={onDeleteTimeEntry}
        title="Delete log"
      >
        <span className="icon-trash" />
      </ButtonsCollection.ButtonIcons>
    </li>
  </>
);

TimeEntryItem.propTypes = {
  userName: PropTypes.string.isRequired,
  userAvatar: PropTypes.string.isRequired,
  onShowUserProfile: PropTypes.func.isRequired,
  estimatedTime: PropTypes.func.isRequired,
  onEditTimeEntry: PropTypes.func.isRequired,
  onDeleteTimeEntry: PropTypes.func.isRequired,
  dateLog: PropTypes.string.isRequired,
  isNewDateFlag: PropTypes.bool.isRequired,
  createdAt: PropTypes.string.isRequired,
};
export default TimeEntryItem;
