import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose';
import { connect } from 'react-redux';
import { debounce } from '../../../../../../utils/helpers/commonHelpers';
import {
  preloaderWhileLoading,
  withWindowWidth,
} from '../../../../../../utils/enchancers';
import Board from './board';
import { pipelinesSelectors } from '../../../../../../state/pipelines';
import { leadsActions } from '../../../../../../state/leads';
import { PRELOADER_DIMENSION } from '../../../../../../constants/ui';

const FIX_COLUMN_HEIGHT = 175;

const mapDispatchToProps = ({
  onDragLeadEnd: leadsActions.onDragLeadEnd,
});

const mapStateToProps = state => ({
  isPending: pipelinesSelectors.getPipelineStatusesPendingRequest(state),
});

const isDestinationAndSourceEqual = (destination, source) => (
  destination.droppableId === source.droppableId && destination.index === source.index);

const onDragEndHandler = ({
  onDragLeadEnd,
  setIsDragging,
}) => (data) => {
  const { destination, source } = data;
  if (!destination) {
    setIsDragging(false);
    return false;
  }
  if (isDestinationAndSourceEqual(destination, source)) {
    setIsDragging(false);
    return false;
  }
  onDragLeadEnd(data);
  setIsDragging(false);
  return data;
};

const onDragUpdateHandler = ({
  setUpdateDroppable,
}) => (data) => {
  const { destination, source } = data;
  if (!destination) {
    return false;
  }
  if (isDestinationAndSourceEqual(destination, source)) {
    return false;
  }
  setUpdateDroppable(destination.droppableId);
  return data;
};

const onDragStartHandler = ({
  setIsDragging,
}) => (data) => {
  setIsDragging(true);
  return data;
};

const onResizeWindowHandler = ({ setColumnRowHeight }) => () => {
  debounce(() => setColumnRowHeight(`${window.innerHeight - FIX_COLUMN_HEIGHT}px`), 300);
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withWindowWidth(),
  withState('isDragging', 'setIsDragging', false),
  withState('updateDroppable', 'setUpdateDroppable', null),
  withState('columnRowHeight', 'setColumnRowHeight', `${window.innerHeight - FIX_COLUMN_HEIGHT}px`),
  withHandlers({
    onDragEnd: onDragEndHandler,
    onDragUpdate: onDragUpdateHandler,
    onDragStart: onDragStartHandler,
    onResizeWindow: onResizeWindowHandler,
  }),
  lifecycle({
    componentDidMount() {
      window.addEventListener('resize', this.props.onResizeWindow);
    },
    componentWillUnmount() {
      window.removeEventListener('resize', this.props.onResizeWindow);
    },
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.MIDDLE,
    alignContainerCenter: true,
    isLoading: ({ isPending }) => isPending,
  }),
);

export default enhance(Board);
