// for customization activity cards
export const TYPE_CARD_ACTIVITY = {
  DEFAULT: 0,
  PROJECT: 1,
  USER: 2,
  TASK: 3,
};

export const VIEW_TYPE = {
  USERS: 'users',
  MY_STATISTICS: 'myStatistics',
  PROJECT: 'project',
};

export const KEYS = {
  ACTIVITY: {
    PER_DAY: 'activityPerDay',
    PER_MONTH: 'activityPerMonth',
  },
  PROJECTS: 'projects',
  USERS: 'users',
  TASKS: 'tasks',
};

export const SECONDS_IN_HOUR = 3600;
