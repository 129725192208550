import { yupResolver } from '@hookform/resolvers/yup';
import { push } from 'connected-react-router';
import { propOr, values as ramdaValues } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import rules from './rules';
import api from '../../../../../../../api/settings.service';
import { APP_ROUTES } from '../../../../../../../constants/appRoutes';
import { USER_STATUSES } from '../../../../../../../constants/users';
import {
  permissionsActions,
  permissionsSelectors,
} from '../../../../../../../state/permissions';
import {
  AsyncAutoComplete,
  ButtonsCollection,
  FormsCollection,
} from '../../../../../../../ui-kit';
import { callNotification } from '../../../../../../../utils/helpers/notifies';
import './style.sass';

/**
 * @param getRolesAutocomplete {function}
 * @param user {object}
 * @returns {JSX.Element}
 * @constructor
 */

const SUBMIT_DELAY = 800;

const UserEditForm = ({ getRolesAutocomplete, user }) => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);
  const roles = useSelector(permissionsSelectors.getRolesEntities);
  const role = propOr({}, user?.role_id, roles);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(rules),
  });

  const getRolesRequest = useCallback((value) => {
    dispatch(permissionsActions.getRolesRequest({ ...value, limit: 100 }));
  });
  const updateUserRequest = useCallback(async (value) => {
    const data = await api.updateSettingUserRequest(value);
    setIsPending(true);
    if (data && data.user) {
      setIsPending(false);
      dispatch(push(APP_ROUTES.PREFERENCES_USERS));
    } else {
      return callNotification.error;
    }
    return data;
  });

  const onSend = useCallback((values) => {
    updateUserRequest(
      {
        ...values,
        userId,
        role_id: values.role_id,
        status: values.status,
      },
    );
  });

  const onSubmit = useCallback(values => onSend(values), []);

  const onChangeRole = (name, value) => {
    setValue(name, value);
  };

  useEffect(() => {
    getRolesRequest();
  }, [userId]);

  useEffect(() => {
    reset(user);
  }, [user]);

  return (
    <div className="main-container__content main-container__content--profile">
      <div className="userEdit">
        <FormsCollection.FormWrapper
          classNameForm="userFormInvite"
          handleSubmit={handleSubmit(onSubmit)}
        >
          <div className="userFormInvite__row">
            <div className="userFormInvite__col">
              <label className="userFormInvite__label" htmlFor="userEdit_email">
                {t('Email')}
              </label>
              <Controller
                name="email"
                control={control}
                defaultValue={user?.email}
                render={({
                  onBlur, value, touched, field, onChange,
                }) => (
                  <FormsCollection.Input
                    value={value}
                    id="userEdit_email"
                    error={errors.email?.message}
                    touched={touched}
                    onBlur={onBlur}
                    name="email"
                    onChange={onChange}
                    placeholder="example@gmail.com"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="userFormInvite__col">
              <label
                className="userFormInvite__label"
                htmlFor="userEdit_username"
              >
                {t('Username')}
              </label>
              <Controller
                name="username"
                control={control}
                defaultValue={user?.username}
                render={({
                  onBlur, value, touched, field, onChange,
                }) => (
                  <FormsCollection.Input
                    value={value}
                    id="userEdit_username"
                    error={errors.username?.message}
                    touched={touched}
                    onBlur={onBlur}
                    name="username"
                    onChange={onChange}
                    placeholder={t('Username')}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className="userFormInvite__row">
            <div className="userFormInvite__col">
              <label
                className="userFormInvite__label"
                htmlFor="userEdit_firstName"
              >
                {t('First name')}
              </label>
              <Controller
                name="first_name"
                control={control}
                defaultValue={user?.first_name}
                render={({
                  onBlur, value, touched, field, onChange,
                }) => (
                  <FormsCollection.Input
                    value={value}
                    id="userEdit_firstName"
                    error={errors.first_name?.message}
                    touched={touched}
                    onBlur={onBlur}
                    name="first_name"
                    onChange={onChange}
                    placeholder={t('First name')}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="userFormInvite__col">
              <label
                className="userFormInvite__label"
                htmlFor="userEdit_lastName"
              >
                {t('Last name')}
              </label>
              <Controller
                name="last_name"
                control={control}
                defaultValue={user?.last_name}
                render={({
                  onBlur, value, touched, field, onChange,
                }) => (
                  <FormsCollection.Input
                    value={value}
                    id="userEdit_lastName"
                    error={errors.last_name?.message}
                    touched={touched}
                    onBlur={onBlur}
                    name="last_name"
                    onChange={onChange}
                    placeholder={t('Last name')}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className="userFormInvite__row">
            {role.name && (
            <div className="userFormInvite__col">
              <span className="userFormInvite__label">{t('Role')}</span>
              <Controller
                name="role_id"
                control={control}
                render={({ onBlur, value, touched }) => (
                  <AsyncAutoComplete
                    value={value}
                    error={errors.role_id?.message}
                    closeMenuOnSelect
                    touched={touched}
                    onBlur={onBlur}
                    name="role_id"
                    defaultValue={{ ...role, label: role.name }}
                    onChange={e => onChangeRole('role_id', e.value)}
                    loadOptions={getRolesAutocomplete}
                    placeholder={t('Role')}
                  />
                )}
              />
            </div>
            )}
            {user && (
            <div className="userFormInvite__col">
              <span className="userFormInvite__label">{t('Status')}</span>
              <Controller
                name="status"
                control={control}
                defaultValue={user?.status}
                render={({
                  onBlur, value, field, touched, onChange,
                }) => (
                  <FormsCollection.Select
                    error={errors.status?.message}
                    onBlur={onBlur}
                    touched={touched}
                    value={user?.status || value}
                    defaultValue={user?.status}
                    name="status"
                    onChange={onChange}
                    data={ramdaValues(USER_STATUSES)}
                    {...field}
                  />
                )}
              />
            </div>
            )}
          </div>
          <div className="userForm__row userForm__row--buttons">
            <ButtonsCollection.ButtonBrill
              type="submit"
              delay={SUBMIT_DELAY}
              isPending={isPending}
              className="userForm__submit-button button-brill--fill"
            >
              {t('Save')}
            </ButtonsCollection.ButtonBrill>
          </div>
        </FormsCollection.FormWrapper>
      </div>
    </div>
  );
};

export default UserEditForm;
