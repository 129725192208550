import React, { useCallback, useEffect, useState } from 'react';
import { path } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { push } from 'connected-react-router';

import { ButtonsCollection, Layouts, FormsCollection } from '../../../ui-kit';
import { ErrorNotification } from '../errorNotification';
import { uiActions, uiSelectors } from '../../../state/ui';
import { rules } from './rules';
import { WINDOW_TITLE } from '../../../constants/window-titles';
import './style.sass';
import api from '../../../api/auth.service';
import { APP_ROUTES } from '../../../constants/appRoutes';

/**
 * @returns {JSX.Element}
 * @constructor
 */

export const ForgotPassword = () => {
  const { t } = useTranslation('validation');
  const dispatch = useDispatch();
  const authNotification = useSelector(uiSelectors.getAuthNotification);
  const {
    control, setValue, handleSubmit,
  } = useForm({
    resolver: yupResolver(rules),
  });

  const forgotPasswordRequest = async (params) => {
    const data = await api.forgotPasswordRequest(params);
    if (data.success) {
      dispatch(push(APP_ROUTES.FORGOT_PASSWORD_SUCCESS));
    } else {
      dispatch(uiActions.displayNotification({ data }));
    }
    return data;
  };

  useEffect(() => {
    dispatch(uiActions.setAppTitle(WINDOW_TITLE.FORGOT_PASSWORD));
    dispatch(uiActions.displayNotification(null));
    return () => {
      dispatch(uiActions.displayNotification(null));
    };
  }, []);

  const onChange = (name, value) => {
    setValue(name, value);
  };

  const onSubmit = useCallback(values => forgotPasswordRequest(values));

  const onError = (errors) => {
    dispatch(uiActions.displayNotification(null));
    if (errors) {
      const performedErrors = Object.values(errors).reverse()[0];
      dispatch(uiActions.displayNotification({
        [performedErrors.message.field]: {
          message: performedErrors.message.message,
          params: performedErrors.message.params,
        },
      }));
    }
  };

  return (
    <Layouts.Auth>
      <ErrorNotification />
      <div className="auth-wrapper__wrap">
        <FormsCollection.FormWrapper handleSubmit={handleSubmit(onSubmit, onError)}>
          <div className="auth-wrapper__form-contain">
            <h2 className="title weight--bold title--md text-align--center">{t('Reset password')}</h2>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({
                onBlur, value, touched, field,
              }) => (
                <FormsCollection.Input
                  type="email"
                  name="email"
                  placeholderJump={t('Email address')}
                  onChange={e => onChange('email', e.target.value)}
                  onBlur={onBlur}
                  error={!!path(['email'], authNotification)}
                  touched={touched}
                  value={value}
                  id="forgot-password__email"
                  {...field}
                />
              )}
            />
            <div className="button-group">
              <ButtonsCollection.ButtonBrill
                type="submit"
                className="auth-wrapper__button--with-border button--fill"
                borderColor="#26b5ed"
                borderWidth="3"
              >
                {t('Get reset link')}
              </ButtonsCollection.ButtonBrill>
            </div>
          </div>
        </FormsCollection.FormWrapper>
      </div>
    </Layouts.Auth>
  );
};
