import React from 'react';
import PropTypes from 'prop-types';
import { equals } from 'ramda';
import {
  CreateTaskModal,
} from '../../components';
import {
  ScrollWrapper,
  MobileFiltersContainer,
  Pagination, EmptyResult,
  FormsCollection,
} from '../../ui-kit';

import {
  FiltersRow,
  MyTasksList,
  MyTasksProjects,
} from './components';
import './style.sass';
import { WINDOW_WIDTH } from '../../constants/ui';
import { MY_TASKS } from '../../constants/tasks';
import { DeleteTaskModal, EditTaskModal } from '../taskCRUD';

const MyTasks = ({
  groupBy,
  setGroupBy,
  areMobileFiltersOpen,
  toggleMobileFiltersHandler,
  myTasks,
  setActiveFilter,
  activeFilter,
  onSortBy,
  order,
  sort,
  selectedTask,
  selectedTaskClientId,
  onSuccessTaskCRUD,
  setSelectedSort,
  searchName,
  setSearchTaskName,
  onSearch,
  pagination,
  summary,
  gridName,
  onPageChange,
  path, onPath,
}) => (
  <>
    <ScrollWrapper>
      <div className="my-tasks">
        <div className="my-tasks__tasks-filters">
          <MobileFiltersContainer
            title="Filter"
            onWidth={WINDOW_WIDTH.SMALL}
            isOpen={areMobileFiltersOpen}
            onToggle={toggleMobileFiltersHandler}
          >
            <FiltersRow
              setGroupBy={setGroupBy}
              groupBy={groupBy}
              setActiveFilter={setActiveFilter}
              setSelectedSort={setSelectedSort}
              activeFilter={activeFilter}
              onSortBy={onSortBy}
              order={order}
              sort={sort}
            />
          </MobileFiltersContainer>
          <FormsCollection.Search
            className="search-field--circle"
            name="search"
            placeholder="Search by title"
            value={searchName}
            onChange={setSearchTaskName}
            onSearch={onSearch}
            withoutControl
          />
        </div>
        {
            equals(groupBy, MY_TASKS.GROUP_BY.TASKS) ? (
              <MyTasksList
                path={path}
                tasks={myTasks}
                activeFilter={activeFilter}
                pagination={pagination}
                gridName={gridName}
              />
            ) : (
              <MyTasksProjects
                path={path}
                gridName={gridName}
                pagination={pagination}
                onPageChange={onPageChange}
              />
            )
          }
        {equals(groupBy, MY_TASKS.GROUP_BY.TASKS) && !myTasks.length ? (
          <EmptyResult text="No search results found" />
        ) : (
          <Pagination
            onPath={onPath}
            count={
              summary.allCount
            }
            component={gridName}
            pagination={pagination}
            className="my-tasks__list-pagination"
            onPageChange={onPageChange}
          />
        )}
      </div>
    </ScrollWrapper>
    <CreateTaskModal disableRedirect onSuccess={projectId => onSuccessTaskCRUD({ projectId })} />
    <EditTaskModal
      onSuccess={projectId => onSuccessTaskCRUD(projectId)}
      selectedTaskId={selectedTask}
      taskClientId={selectedTaskClientId}
    />
    <DeleteTaskModal
      isMyTasks
      onSuccess={projectId => onSuccessTaskCRUD({ projectId })}
      selectedTask={selectedTask}
      taskClientId={selectedTaskClientId}
    />
  </>
);


MyTasks.propTypes = {
  myTasks: PropTypes.arrayOf(PropTypes.number).isRequired,
  groupBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  sort: PropTypes.string.isRequired,
  activeFilter: PropTypes.string.isRequired,
  areMobileFiltersOpen: PropTypes.bool.isRequired,
  setGroupBy: PropTypes.func.isRequired,
  toggleMobileFiltersHandler: PropTypes.func.isRequired,
  setActiveFilter: PropTypes.func.isRequired,
  onSortBy: PropTypes.func.isRequired,
  selectedTask: PropTypes.oneOfType(
    [PropTypes.number.isRequired,
      PropTypes.oneOf([null])],
  ).isRequired,
  selectedTaskClientId: PropTypes.oneOfType(
    [PropTypes.number.isRequired,
      PropTypes.oneOf([null])],
  ).isRequired,
  onSuccessTaskCRUD: PropTypes.func.isRequired,
  setSelectedSort: PropTypes.func.isRequired,
  searchName: PropTypes.string.isRequired,
  setSearchTaskName: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default MyTasks;
