import React from 'react';
import PropTypes from 'prop-types';
import { SummaryCard } from '../../../../../../ui-kit';
import './style.sass';

const ClientsSummary = ({ t, clientsCount }) => (
  <div className="clients-summary">
    <SummaryCard
      className="clients-summary__card"
      colorBorder="#fcccc4"
      count={clientsCount}
      title={t('All')}
    />
  </div>
);

ClientsSummary.propTypes = {
  t: PropTypes.func.isRequired,
  clientsCount: PropTypes.number.isRequired,
};

export default ClientsSummary;
