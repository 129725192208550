import {
  compose, getContext, hoistStatics, lifecycle, pure, withHandlers,
} from 'recompose';
import PropTypes from 'prop-types';

import {
  always, cond, equals, T,
} from 'ramda';
import { KEYS } from '../../../../../../../constants/ui';
import { withRefs } from '../../../../../../../utils/enchancers';

import EditableComment from './editableComment';

const onSetTextAreaRefHandler = ({ setRef }) => ref => setRef('textArea', ref);
const onKeyDownEditableMessageHandler = ({
  onCloseEditMessage,
}) => event => cond([
  [equals(KEYS.ESCAPE), onCloseEditMessage],
  [T, always(true)],
])(event.keyCode);

const onClickOutsideHandler = ({ messageRef, onCloseEditMessage }) => (e) => {
  if (!messageRef.contains(e.target)) {
    onCloseEditMessage();
  }
};

const enhance = compose(
  pure,
  withRefs(),
  withHandlers({
    onSetTextAreaRef: onSetTextAreaRefHandler,
    onKeyDownEditableMessage: onKeyDownEditableMessageHandler,
  }),
  getContext({
    members: PropTypes.instanceOf(Object).isRequired,
  }),
  withRefs(),
  withHandlers({
    onClickOutside: onClickOutsideHandler,
  }),
  lifecycle({
    componentDidMount() {
      const {
        onKeyDownEditableMessage, onClickOutside,
      } = this.props;
      document.addEventListener('keyup', onKeyDownEditableMessage);
      document.addEventListener('mousedown', onClickOutside);
      document.addEventListener('touchend', onClickOutside);
    },
    componentWillUnmount() {
      const { onKeyDownEditableMessage, onClickOutside } = this.props;
      document.removeEventListener('keyup', onKeyDownEditableMessage);
      document.removeEventListener('mousedown', onClickOutside);
      document.removeEventListener('touchend', onClickOutside);
    },
  }),
  hoistStatics,
);

export default enhance(EditableComment);
