import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import InviteMember from './inviteMember';

const enhance = compose(
  withTranslation(['common', 'chat']),
);

export default enhance(InviteMember);
