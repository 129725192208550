import { path } from 'ramda';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { push as routerPush } from 'connected-react-router';
import { useTranslation } from 'react-i18next';

import { ButtonsCollection, Grid } from '../../../../../../../ui-kit';
import '../rolesList/style.sass';

/**
 * @param roleId {number}
 * @param rolesEntities {array}
 * @param onDeleteRole {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const RolesItem = ({
  roleId,
  rolesEntities,
  onDeleteRole,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const push = useCallback((value) => {
    dispatch(routerPush(value));
  });
  const onDelete = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    onDeleteRole(rolesEntities[roleId]);
  });
  return (
    <Grid.Row
      key={`project-grid  - ${roleId}`}
      onClick={() => push(`/preferences/roles/${roleId}`)}
      className="table-roles__row project-row"
    >
      <Grid.ColData className="table-roles__col table-roles__col--id">
        {path([roleId, 'id'], rolesEntities)}
      </Grid.ColData>
      <Grid.ColData className="table-roles__col table-roles__col--name">
        {path([roleId, 'name'], rolesEntities)}
      </Grid.ColData>
      <Grid.ColData className="table-roles__col table-users__col table-users__col--controls">
        <ButtonsCollection.ButtonIcons
          onClick={() => push(`/preferences/roles/${roleId}/`)}
          title={t('Edit role')}
        >
          <span className="icon-edit-icon" />
        </ButtonsCollection.ButtonIcons>
      </Grid.ColData>
      <Grid.ColData className="table-users__col table-users__col--controls">
        {
          path([roleId, 'can_deleted'], rolesEntities) ? (
            <ButtonsCollection.ButtonIcons
              onClick={onDelete}
              title={t('Delete role')}
            >
              <span className="icon-trash" />
            </ButtonsCollection.ButtonIcons>
          ) : ''
        }
      </Grid.ColData>
    </Grid.Row>
  );
};
