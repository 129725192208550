import React from 'react';

import './style.sass';

/**
 *
 * @param isVisible {boolean}
 * @param onButtonClick {function}
 * @param title {string}
 * @param actionTitle {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const Banner = ({
  isVisible, onButtonClick, title, actionTitle,
}) => (
  <>
    {isVisible && (
    <div className="banner-request-notification">
      <span className="banner-request-notification__wrapper">
        <span className="banner-request-notification__label">
          {title}
        </span>
        <button
          onClick={onButtonClick}
          type="button"
          className="banner-request-notification__button"
        >
          {actionTitle}
        </button>
      </span>
    </div>
    )}
  </>
);
