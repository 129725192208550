import {
  curry, keys, map, propOr, reduce, sum, trim,
} from 'ramda';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';

const minInHour = 60;
const minInDay = 8 * minInHour;
const minInWeek = 5 * minInDay;

const OPTIONS_SELECT_SIZE = 10;

const timeList = {
  w: 144000,
  d: 28800,
  h: 3600,
  m: 60,
};

const getSecondsFromTimeString = (value) => {
  if (!value) return '';
  if ((/^(\d){1,}$/).test(value)) return value * 60;
  const arrayTimes = value.match(/\d*[A-Z,a-z]/gm) || [];
  return sum(map((item) => {
    const count = item.match(/\d*/);
    const char = item.match(/[A-Z, a-z]{1}/);
    return count * propOr(0, char, timeList);
  }, arrayTimes));
};

const isValidLogTime = (value) => {
  if (!value) return true;
  const checkNumber = /^(\d){1,}$/;
  const checkLogTimeString = /^((^\d{1,}w)|^|$)(((^|\s)\d{1,}d)|^|$)(((^|\s)\d{1,}h)|^|$)(((^|\s)\d{1,}m)|^|$)$/;
  return (checkNumber).test(value) || (checkLogTimeString).test(value);
};

const getTimeStringBySeconds = (seconds) => {
  if (!seconds) {
    return '0m';
  }
  const results = reduce((accum, key) => {
    const { secondsCount, result } = accum;
    if (!secondsCount || secondsCount < 0) return accum;
    let c = Math.floor(secondsCount / timeList[key]) || 0;
    if (secondsCount % timeList[key] > 30 && secondsCount % timeList[key] < 60) { c += 1; }
    return {
      secondsCount: secondsCount - c * timeList[key],
      result: c ? `${result}${c}${key} ` : result,
    };
  }, {
    secondsCount: seconds,
    result: '',
  }, keys(timeList));
  return trim(results.result);
};

const getHoursFromTimeString = (value) => {
  const result = getSecondsFromTimeString(value);
  if (!result) return '0h';
  const hours = ((Number(result) / 60) / 60).toFixed(2);

  return `${hours}h`;
};

const getTimezoneAutocompleteHelper = () => (fieldValue, callback) => {
  const lowerCaseFieldValue = fieldValue.toLowerCase();

  const stringValueCheck = value => value.toLowerCase().includes(lowerCaseFieldValue);

  const filteredTimezone = moment.tz.names()
    .filter(stringValueCheck)
    .slice(0, OPTIONS_SELECT_SIZE)
    .map(timezone => ({ label: timezone, value: timezone }));
  callback(filteredTimezone);
};


const convertToUNIX = date => moment(date).unix();
const convertTsWithTimeZone = curry((tz, format, ts) => momentTimeZone(ts).tz(tz).format(format));
const getMessageTimeInMs = () => Date.now() + window.performance.now().toString();
const getDate = () => moment().format();

const getMinutesFromTimeString = (timeString) => {
  const m = 'm';
  const h = 'h';
  const d = 'd';
  const w = 'w';
  let totalMinutes = 0;
  const timeArr = timeString.split(' ');

  for (const element of timeArr) {
    const quantity = element.slice(0, -1);
    switch (element.slice(-1)) {
      case m:
        totalMinutes += Number(quantity);
        break;
      case h:
        totalMinutes += minInHour * quantity;
        break;
      case d:
        totalMinutes += minInDay * quantity;
        break;
      case w:
        totalMinutes += minInWeek * quantity;
        break;
      default:
        return totalMinutes;
    }
  }
  return totalMinutes;
};

const getTimeStringFromMinutes = (min) => {
  const zeroMin = '0m';
  if (min === 0) {
    return zeroMin;
  }
  let res = '';

  const w = Math.floor(min / minInWeek);
  const wRest = min % minInWeek;

  const d = Math.floor(wRest / minInDay);
  const dRest = wRest % minInDay;

  const h = Math.floor(dRest / minInHour);
  const hRest = dRest % minInHour;

  const m = hRest;

  if (w !== 0) {
    res += `${w}w`;
  }
  if (d !== 0) {
    res += ` ${d}d`;
  }
  if (h !== 0) {
    res += ` ${h}h`;
  }
  if (m !== 0) {
    res += ` ${m}m`;
  }
  return res;
};


export {
  convertToUNIX,
  getDate,
  getMessageTimeInMs,
  getSecondsFromTimeString,
  convertTsWithTimeZone,
  isValidLogTime,
  getTimeStringBySeconds,
  getHoursFromTimeString,
  getTimezoneAutocompleteHelper,
  getMinutesFromTimeString,
  getTimeStringFromMinutes,
};
