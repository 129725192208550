import {
  compose, withProps, branch, renderNothing, lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import { path, prop } from 'ramda';

import AboutProject from './aboutProject';
import { projectSelectors } from '../../../../state/project';
import { clientsActions, clientsSelectors } from '../../../../state/clients';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';

const mapStateToProps = (state, { projectId }) => ({
  project: projectSelectors.default(state)(projectId),
  client: clientsSelectors.getClientSelector(state),
});

const mapDispatchToProps = {
  getClient: clientsActions.getClientRequest,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ project }) => !prop('title', project), renderNothing),
  withProps(({ project }) => ({
    imgUrl: getImageUrl(path(['image', 'id'], project)),
  })),
  lifecycle({
    componentDidMount() {
      const { getClient, project } = this.props;
      if (project.client_id) getClient({ id: project.client_id });
    },
    componentDidUpdate(prevProps) {
      const { getClient, project } = this.props;
      const { project: prevProject } = prevProps;
      if (prevProject !== project) {
        if (project.client_id) getClient({ id: project.client_id });
      }
    },
  }),
);
export default enhance(AboutProject);
