import {
  take,
  put,
  fork,
  select,
} from 'redux-saga/effects';
import {
  inc,
  prop,
  compose,
  curry,
  lensProp,
  identity,
  dec,
  sort,
  values,
  keys,
  reduce, uniq, includes, flip, pickBy,
} from 'ramda';
import { Tuple } from 'ramda-fantasy';
import { notEqual } from 'ramda-extension';

import { projectTypes, projectActions, projectSelectors } from '.';
import { uiActions, uiTypes } from '../ui';
import { sagasManager } from '../../utils';
import dragDropHelper from '../../utils/helpers/projectHelpers/index';
import dragDropAgendaHelper from '../../utils/helpers/projectHelpers/dragDropAgendaHelper';
import {
  isConditionRight, map, setObjLens, either,
} from '../../utils/helpers/commonHelpers';

const summaryStatuses = {
  1: 'allCount',
  2: 'inProgressCount',
  3: 'doneCount',
};

const sortByParamPriority = (a, b) => a.priority - b.priority;

function* onDragProjectSaga() {
  while (true) {
    const { payload } = yield take(uiTypes.ON_DRAG_END);
    const { destination, source, draggableId: itemId } = payload;
    yield dragDropHelper({ destination, source, itemId });
  }
}

const getDroppableId = prop('droppableId');


const isSummaryNotEqualAll = isConditionRight(compose(
  notEqual('allCount'),
  Tuple.snd,
));

const changeSummaryStatusValue = curry((mapper, summary) => {
  const statusKey = Tuple.snd(summary);
  return setObjLens(lensProp(statusKey), data => mapper(data[statusKey]), Tuple.fst(summary));
});

const changeSummary = mapper => compose(
  either(Tuple.fst)(identity),
  map(changeSummaryStatusValue(mapper)),
  isSummaryNotEqualAll,
);

function* getStateOfStatusByStatusId(statusId) {
  const { state } = yield select(data => projectSelectors.getStatusOfTasks(data)(statusId));
  return state;
}

function* onDragAgendaFlow() {
  while (true) {
    const { payload } = yield take(projectTypes.ON_DRAG_AGENDA_END);
    const { destination, source, draggableId: itemId } = payload;
    yield dragDropAgendaHelper({ destination, source, itemId });

    const destinationStatusId = getDroppableId(destination);
    const sourceStatusId = getDroppableId(source);
    const destinationStateOfStatusId = yield getStateOfStatusByStatusId(destinationStatusId);
    const sourceStateOfStatusId = yield getStateOfStatusByStatusId(sourceStatusId);

    const summary = yield select(projectSelectors.getProjectSummary);
    const incStatusName = summaryStatuses[destinationStateOfStatusId];
    const decStatusValue = summaryStatuses[sourceStateOfStatusId];

    const destinationSummary = Tuple(summary, incStatusName);
    const incData = changeSummary(inc)(destinationSummary);
    const decData = changeSummary(dec)(Tuple(incData, decStatusValue));
    yield put(projectActions.setStatusesOfTasks(decData));

    yield put(projectActions.setSummary(payload));
  }
}

function* getProjectErrorFlow() {
  while (true) {
    yield take(projectTypes.GET_PROJECT_ERROR);
    yield put(uiActions.setErrorPage(true));
  }
}

const getPriorityAfterReorder = (idDragItem, idCurrentItem, currentPriority, dragPriorityOld,
  dragPriorityNew) => {
  if (idCurrentItem === idDragItem) return dragPriorityNew;
  if (currentPriority < dragPriorityOld
    && currentPriority >= dragPriorityNew) return inc(currentPriority);
  if (currentPriority > dragPriorityOld
    && currentPriority <= dragPriorityNew) return dec(currentPriority);
  return currentPriority;
};
const updateStatusesByPriority = (idItem, oldPriority, newPriority) => list => reduce((accum,
  { id, priority }) => ([...accum, {
  id,
  priority: getPriorityAfterReorder(idItem, id, priority, oldPriority, newPriority),
}]), [], list);

function* dragTasksStatusFlow() {
  while (true) {
    const { payload } = yield take(projectTypes.ON_DRAG_TASKS_STATUS);
    if (!payload.destination) return false;
    const {
      projectId, draggableId, destination: { index }, source: { index: oldIndex },
    } = payload;
    const statusesList = yield select(projectSelectors.getStatusesOfTasksList);
    const entities = yield select(projectSelectors.getStatusesOfTasksEntities);
    const statusesEntities = yield compose(pickBy(compose(flip(includes)(statusesList), prop('id'))))(entities);
    const statusesByPriority = yield compose(sort(sortByParamPriority), values)(statusesEntities);
    const statusesByPriorityNew = yield updateStatusesByPriority(draggableId,
      inc(oldIndex),
      inc(index))(statusesByPriority);
    const statusesData = {
      data: {
        result: compose(uniq, map(prop('id')), sort(sortByParamPriority), values)(statusesByPriorityNew),
        entities: {
          statuses: reduce((accum, key) => ({
            ...accum,
            [statusesByPriorityNew[key].id]: statusesByPriorityNew[key],
          }), {}, keys(statusesByPriorityNew)),
        },
      },
    };

    yield put(projectActions.updateStatusesPriorityListRequest({
      projectId,
      projectTaskStatuses: statusesByPriorityNew,
    }));
    yield put(projectActions.setStatusesOfTasks(statusesData));
  }
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(onDragProjectSaga);
  yield fork(onDragAgendaFlow);
  yield fork(getProjectErrorFlow);
  yield fork(dragTasksStatusFlow);
});
