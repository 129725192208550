import React from 'react';
import PropTypes from 'prop-types';
import {
  MobileFiltersContainer, ButtonsCollection, DropDown, FormsCollection,
} from '../../../../ui-kit';
import { PIPELINE_TYPES } from '../../../../constants/crm';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import { DROPDOWN_SORT_LEAD_LIST } from './constants';
import './style.sass';

const getFilterItemData = (filter, label) => ({
  id: filter,
  value: filter,
  label,
});

const getPipelineFilterItem = ({ id, title }) => ({
  id,
  value: id,
  label: title,
});

const FiltersMobile = ({
  onChangeSorting, sortingByDate,
  searchName, setSearchLeadName, onSearch, t,
  onChangeFilterStatus, statusValue, onSetLeadViewPipe, onSetLeadViewList, isPipeView,
  pipelines, checkedPipeline, onChangeCurrentPipeline, areMobileFiltersOpen,
  toggleMobileFiltersHandler,
}) => (
  <div className="leads__filters">
    <div className="leads__filters-col">
      <FormsCollection.Search
        className="search-field--circle"
        placeholder={t('Search by lead name, position, service')}
        name="search-lead"
        value={searchName}
        onChange={setSearchLeadName}
        onSearch={onSearch}
        withoutControl
      />
    </div>
    <MobileFiltersContainer
      title="Filter"
      onWidth={WINDOW_WIDTH.SMALL}
      isOpen={areMobileFiltersOpen}
      onToggle={toggleMobileFiltersHandler}
    >
      <div className={`leads__filters-row ${isPipeView ? 'leads__filters-row--pipeline-view' : ''}`}>
        <div className="leads__filters-col mobile-indent">
          <ButtonsCollection.ButtonBrill
            className={`button--fill-grey leads__filters-btn-tab${!isPipeView ? ' active' : ''}`}
            onClick={onSetLeadViewList}
          >
            <span className="leads__filters-icon-list icon-list-stroke" />
            {t('List view')}
          </ButtonsCollection.ButtonBrill>
          <ButtonsCollection.ButtonBrill
            className={`leads__filters-btn-tab${isPipeView ? ' active' : ''}`}
            onClick={onSetLeadViewPipe}
          >
            <span className="leads__filters-icon-pipe icon-list-fill" />
            {t('Pipe view')}
          </ButtonsCollection.ButtonBrill>
        </div>
        <div className="leads__filters-col">
          {
              isPipeView ? (
                <DropDown
                  label={`${t('Pipeline')} : `}
                  name="pipeline"
                  placeholder={t('Check any pipeline')}
                  currentItem={checkedPipeline}
                  onChange={onChangeCurrentPipeline}
                  list={
                    pipelines.map(getPipelineFilterItem)
                  }
                />
              ) : (
                <>
                  <DropDown
                    label={`${t('Status')} : `}
                    name="status"
                    placeholder={t('Current')}
                    currentItem={statusValue}
                    onChange={onChangeFilterStatus}
                    list={
                      [
                        getFilterItemData(PIPELINE_TYPES.ALL, t('All')),
                        getFilterItemData(PIPELINE_TYPES.IN_PROGRESS, t('In progress')),
                        getFilterItemData(PIPELINE_TYPES.WON, t('WON')),
                        getFilterItemData(PIPELINE_TYPES.LOST, t('LOST')),
                      ]
                    }
                  />
                  <DropDown
                    label={`${t('Sort')} : `}
                    name="sorting-filter"
                    placeholder={t('Recent')}
                    currentItem={sortingByDate}
                    onChange={onChangeSorting}
                    list={DROPDOWN_SORT_LEAD_LIST}
                  />
                </>
              )
            }
        </div>
      </div>
    </MobileFiltersContainer>
  </div>
);

FiltersMobile.propTypes = {
  t: PropTypes.func.isRequired,
  onChangeSorting: PropTypes.func.isRequired,
  setSearchLeadName: PropTypes.func.isRequired,
  onChangeFilterStatus: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSetLeadViewPipe: PropTypes.func.isRequired,
  onSetLeadViewList: PropTypes.func.isRequired,
  onChangeCurrentPipeline: PropTypes.func.isRequired,
  searchName: PropTypes.string.isRequired,
  statusValue: PropTypes.number.isRequired,
  checkedPipeline: PropTypes.number.isRequired,
  areMobileFiltersOpen: PropTypes.bool.isRequired,
  toggleMobileFiltersHandler: PropTypes.func.isRequired,
  isPipeView: PropTypes.bool.isRequired,
  sortingByDate: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.number]).isRequired,
  pipelines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default FiltersMobile;
