import React from 'react';
import { useSelector } from 'react-redux';

import UnregisteredRoutes from './unregisteredRoutes';
import AuthenticatedRoutes from './authenticatedRoutes';
import { ROUTE_TYPES } from '../../../constants/ui';
import { uiSelectors } from '../../../state/ui';

/**
 * @returns {JSX.Element}
 * @constructor
 */

export const Routes = () => {
  const routeTypes = useSelector(state => uiSelectors.getRouteTypes(state));
  return (
    <>
      { routeTypes === ROUTE_TYPES.AUTHENTICATED && (<AuthenticatedRoutes />)}
      { routeTypes === ROUTE_TYPES.GUEST && (<UnregisteredRoutes />)}
    </>
  );
};
