import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RightSideBar } from '../../../../newContainers';
import { ScrollWrapper, ActiveTabTitle, SummaryCards } from '../../../../ui-kit';
import { ImportantTasks } from '../../../../newContainers/rightSideBar/components';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import './style.sass';
import api from '../../../../api/summary.service';
import { useImportantTasks } from '../../../../utils/hooks/useImportantTasks';

const RightSideBarContainer = ({ setActiveTabTitleStateHandler, windowWidth, activeTabTitle }) => {
  const { importantTasks } = useImportantTasks();
  return (<>
    {
      windowWidth > WINDOW_WIDTH.SMALL ? (
        <RightSideBar className="page-my-tasks__right-side-bar" title="Tasks summary">
          <ScrollWrapper className="right-side-bar__scroll-wrapper">
            <SummaryCards request={api.getTasksSummaryRequest} />
            <ImportantTasks
              activeTabTitle={activeTabTitle}
              setActiveTitle={setActiveTabTitleStateHandler}
              importantTasks={importantTasks}
            />
          </ScrollWrapper>
        </RightSideBar>
      ) : (
        <RightSideBar
          className="page-my-tasks__right-side-bar"
          titleComponentRender={activeTabTitle ? (
            () => (
              <ActiveTabTitle
                onClick={() => setActiveTabTitleStateHandler()}
                title={activeTabTitle}
              />
            )
          ) : (
            () => <h3>Tasks summary</h3>
          )}
        >
          <ScrollWrapper className="right-side-bar__scroll-wrapper">
            <SummaryCards request={api.getTasksSummaryRequest} />
            <ImportantTasks
              activeTabTitle={activeTabTitle}
              setActiveTitle={setActiveTabTitleStateHandler}
              importantTasks={importantTasks}
            />
          </ScrollWrapper>
        </RightSideBar>
      )
    }
  </>
  );
};

RightSideBarContainer.propTypes = {
  windowWidth: PropTypes.number.isRequired,
  activeTabTitle: PropTypes.string,
  setActiveTabTitleStateHandler: PropTypes.func.isRequired,
};
RightSideBarContainer.defaultProps = {
  activeTabTitle: '',
};

export default RightSideBarContainer;
