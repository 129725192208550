import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';
import {
  ButtonsCollection, Avatar, BrillMask, Grid,
} from '../../../../../../ui-kit';
import { getImageUrl } from '../../../../../../utils/helpers/requestHelpers';
import { IMAGES_SIZE } from '../../../../../../constants/ui';

const ProductsItem = ({
  product, onDeleteProductModal, push, setProductInfo,
}) => (
  <Grid.Row
    key={`product-grid  - ${product.id}`}
    className="products-list__row"
  >
    <Grid.ColData className="products-list__col--product__icon">
      <div className="products-list-row__icon">
        <BrillMask asLink to={`/projects/${product.id}/`}>
          <Avatar
            src={getImageUrl(prop('image', product), IMAGES_SIZE.sm)}
            alt={prop('title', product)}
          />
        </BrillMask>
      </div>
    </Grid.ColData>
    <Grid.ColData className="products-list__col--product__title">
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        title="Open product  "
        className="product-row__title"
        onClick={() => push(`/projects/${product.id}/`)}
      >
        <span className="text--cut">{prop('title', product)}</span>
      </div>
    </Grid.ColData>
    <Grid.ColData className="products-list__col--product__title__flex-end">
      <ButtonsCollection.ButtonIcons
        onClick={() => {
          onDeleteProductModal();
          setProductInfo(product);
        }}
        title="Delete product"
      >
        <span className="icon-trash" />
      </ButtonsCollection.ButtonIcons>
    </Grid.ColData>
  </Grid.Row>
);

ProductsItem.propTypes = {
  product: PropTypes.instanceOf(Object),
  onDeleteProductModal: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  setProductInfo: PropTypes.func.isRequired,
};

ProductsItem.defaultProps = {
  product: null,
};

export default ProductsItem;
