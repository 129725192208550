import {
  always, cond, curry, F, identity, isNil, T,
} from 'ramda';
import { Either } from 'ramda-fantasy';

const { Right, Left } = Either;

const isNodeExist = cond([
  [isNil, Right],
  [T, Left],
]);

const createNodeIfNull = curry((createNode, text) => Either.either(identity, (node) => {
  // eslint-disable-next-line no-param-reassign
  node = createNode(text);
  return node;
}));

const checkIfCurrentNodeReadyToReplace = curry(condition => Either.either(condition, always(F)));

function CustomNode(
  focusNode,
  rootContainer,
  createNode,
  conditionForFocusNode,
  getCoordinates,
  replaceNodeTagByOffset,
  appendNode,
  replaceTagToText,
  replaceTag,
) {
  // eslint-disable-next-line no-underscore-dangle
  let _node = null;
  // eslint-disable-next-line no-underscore-dangle,no-underscore-dangle
  let _start = null;
  // eslint-disable-next-line no-underscore-dangle
  let _stop = null;
  const isRangeSelectionText = () => {
    const selection = window.getSelection();
    return selection.baseOffset !== selection.focusOffset;
  };
  const self = {
    createNode: data => createNodeIfNull(createNode, data)(isNodeExist(_node)),
    replaceNodeByOffset: (node) => {
      if (isRangeSelectionText()) {
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(rootContainer);
        range.setStart(focusNode, selection.baseOffset);
        range.setEnd(focusNode, selection.focusOffset);
        range.deleteContents();
        range.insertNode(node);
        selection.removeAllRanges();
        return node;
      }
      _node = replaceNodeTagByOffset(self.getCurrentWordCoordinates())(node);
      return _node;
    },
    appendNode: (data) => {
      appendNode(self.replaceNodeByOffset(self.createNode(data)));
      return _node;
    },
    getCurrentWordCoordinates: () => {
      const data = getCoordinates(focusNode);
      _start = data.start;
      _stop = data.end;
      return {
        start: _start,
        stop: _stop,
      };
    },
    isReadyToReplace: () => checkIfCurrentNodeReadyToReplace(
      conditionForFocusNode,
    )(isNodeExist(focusNode)),
    replaceTagToText,
    replaceTag: text => replaceTag(text),
  };

  return self;
}

export default CustomNode;
