import { schema } from 'normalizr';
import { USERS_LIST } from './types';

const usersListSchema = [new schema.Entity(USERS_LIST)];
const coworkersListSchema = [new schema.Entity('coworkers')];

export {
  coworkersListSchema,
  usersListSchema,
};
