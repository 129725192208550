import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import { withRouter } from 'react-router';
import {
  JoinYourTeam,
  SignIn,
  ForgotPassword,
  ForgotPasswordSuccess,
  ChangePassword,
  PageError,
} from '../../../views';


const Routes = ({ location }) => (
  <Switch>
    <Route
      exact
      strict
      path="/:url*"
      render={() => location.pathname.slice(-1) !== '/' && <Redirect to={`${location.pathname}/`} />}
    />
    <Route path="/forgot-password/" component={ForgotPassword} />
    <Route path="/forgot-password-success" component={ForgotPasswordSuccess} />
    <Route path="/reset-password/:userId/:code" component={ChangePassword} />
    <Route path="/join-your-team/:userId/:code" component={JoinYourTeam} />
    <Route exact path="/" component={SignIn} />
    <Route component={PageError} />
  </Switch>
);

Routes.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const enhance = compose(
  withRouter,
);

export default enhance(Routes);
