import React from 'react';
import PropTypes from 'prop-types';

import ChannelsItem from '../channelsItem';
import './style.sass';

const ChannelsList = ({ title, entities, result, t }) => (
  <div className="channels-list">
    <h2 className="channels-list__title">{title}</h2>
    <ul className="channels-list__list">
      {result?.length ? (
        result.map(channelId => (
          <ChannelsItem channel={entities[channelId]} key={channelId} />
        ))
      ) : (
        <li className="channels-list__empty">
          {t("There are no channels yet")}
        </li>
      )}
    </ul>
  </div>
);

ChannelsList.propTypes = {
  title: PropTypes.string.isRequired,
  entities: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  result: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ChannelsList;
