import {
  compose, getContext, withHandlers, withProps, withState,
} from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  path, pathOr, prop, propOr,
} from 'ramda';
import PropTypes from 'prop-types';
import ContactItem from './contactItem';
import { uiActions } from '../../../../state/ui';
import { contactsSelectors, contactsActions } from '../../../../state/contacts';
import { leadsActions } from '../../../../state/leads';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import { customFieldsActions, customFieldsSelectors } from '../../../../state/customFields';

const mapStateToProps = (state, { id }) => ({
  contact: contactsSelectors.getContactById(state)(id),
  pinnedContact: contactsSelectors.getPinnedContactById(state)(id),
  customFieldEntities: customFieldsSelectors.getCustomFieldsEntities(state),
});

const mapDispatchToProps = {
  openModal: uiActions.openModal,
  pinContact: contactsActions.pinContactRequest,
  setActiveRelatedLead: leadsActions.setActiveRelatedLead,
  editCustomFieldValue: customFieldsActions.editCustomFieldValueRequest,
  getCustomFields: customFieldsActions.getCustomFieldsRequest,
};

const onActionHandler = modalName => ({
  openModal,
  setSelectedContact,
  contact,
}) => () => {
  setSelectedContact(prop('id', contact));
  openModal(modalName);
};

const onClickRelatedToHandler = ({ setActiveRelatedLead, relatedToLeadId }) => () => {
  setActiveRelatedLead(relatedToLeadId);
};

const onClickContactItemStateHandler = ({
  isOpen,
  setIsOpen,
  setSelectedPinnedContactId,
  selectedPinnedContactId,
}) => () => {
  if (!isOpen && !selectedPinnedContactId) {
    setIsOpen(true);
  } else {
    setSelectedPinnedContactId(null);
    setIsOpen(false);
  }
};

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common']),
  withState('isOpen', 'setIsOpen', false),
  getContext({
    selectedPinnedContactId: PropTypes.number,
    setSelectedPinnedContactId: PropTypes.func,
  }),
  withProps(({ contact, pinnedContact }) => ({
    contactName: getFullName(contact),
    customFields: propOr(null, 'customFields', contact),
    isPinned: !!pinnedContact,
    relatedTo: pathOr(pathOr(null, ['parent', 'contact'], contact), ['client', 'contact'], contact),
    relatedToLeadId: pathOr(pathOr(null, ['parent', 'id'], contact), ['client', 'id'], contact),
    avatar: compose(
      getImageUrl,
      propOr(contact, 'id'),
    )(contact.avatar),
  })),
  withHandlers({
    onEdit: onActionHandler('editContactModal'),
    onDelete: onActionHandler('deleteContactModal'),
    onPin: onActionHandler('pinContactModal'),
    onUnpin: onActionHandler('unpinContactModal'),
    onClickRelatedTo: onClickRelatedToHandler,
    onClickContactItem: onClickContactItemStateHandler,
  }),
);
export default enhancer(ContactItem);
