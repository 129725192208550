import React, { useState } from 'react';
import { ButtonsCollection } from '@/ui-kit';
import { formatSprintTime } from '@/utils/helpers/projectHelpers/utils';
import './index.sass';

export const LogsItem = ({ event, logsList }) => {
  const [isLogOpen, setLogOpen] = useState(false);

  const toggleOpenStatus = () => {
    setLogOpen(!isLogOpen);
  };

  return (
    <div
      onClick={toggleOpenStatus}
      className={`
       log-item
       ${isLogOpen ? 'log-item--open' : ''}
     `}
    >
      <div className="log-item__about-log">
        <ButtonsCollection.ButtonIcons className="log-toggle-button" onClick={toggleOpenStatus}>
          <span className="icon-expand-arrow-dn" />
        </ButtonsCollection.ButtonIcons>
        <div className="log-item__name-log">{event}</div>
      </div>
      <div className="log-item-body">
        {logsList.map(({ updated_at: updatedAt, output, id }) => (
          <div key={id} className="log-item-name">
            <>[ {formatSprintTime(updatedAt)} ]</>
            {' :  '}
            <strong>{output}</strong>
          </div>
        ))}
      </div>
    </div>
  );
};
