import { createActions } from 'redux-actions';
import {
  identity, path, pathOr, compose, mergeLeft,
} from 'ramda';

import { callNotification } from '../../utils/helpers/notifies';
import types from './types';
import { contactsListSchema, pinnedContactsListSchema } from './schema';
import { setErrorPage } from '../ui/actions';
import { getNormalizeErrorObject } from '../../utils/helpers/requestHelpers';

const errorPageDataWhenGetContacts = compose(mergeLeft({ href: '/' }),
  getNormalizeErrorObject);

export const {
  getContactsRequest,
  addContactRequest,
  deleteContactRequest,
  editContactRequest,
  setContacts,
  setContact,
  deleteContact,
  getPinnedContactsRequest,
  pinContactRequest,
  unpinContactRequest,
  setPinnedContacts,
  setPinnedContact,
} = createActions(
  {
    [types.GET_CONTACTS_REQUEST]: [
      identity,
      ({ ...params }, meta) => ({
        async: true,
        route: '/contacts',
        selectorName: 'getContactsRequest',
        method: 'GET',
        params,
        schema: {
          rules: contactsListSchema,
          pathData: ['data'],
        },
        actions: {
          success: setContacts,
          error: compose(setErrorPage, errorPageDataWhenGetContacts),
        },
        ...meta,
      }),
    ],
    [types.ADD_CONTACT_REQUEST]: [
      identity,
      ({ ...params }, meta) => ({
        async: true,
        route: '/contacts',
        selectorName: 'addContactRequest',
        method: 'POST',
        params,
        callbacks: {
          success: path(['callbacks', 'success'], meta),
          error: () => callNotification.error('Contact cannot be added'),
        },
        ...meta,
      }),
    ],
    [types.EDIT_CONTACT_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/contacts/${id}`,
        selectorName: 'editContactRequest',
        method: 'PUT',
        params,
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        actions: {
          success: getPinnedContactsRequest,
          error: () => callNotification.error('Contact cannot be edited'),
        },
        ...meta,
      }),
    ],
    [types.DELETE_CONTACT_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/contacts/${id}`,
        selectorName: 'deleteContactRequest',
        method: 'DELETE',
        params,
        callbacks: {
          success: path(['callbacks', 'success'], meta),
          error: () => callNotification.error('Contact cannot be deleted'),
        },
        actions: {
          success: () => getContactsRequest(meta),
        },
        ...meta,
      }),
    ],
    [types.GET_PINNED_CONTACTS_REQUEST]: [
      identity,
      ({ ...params }, meta) => ({
        async: true,
        route: '/pinned-contacts',
        selectorName: 'getPinnedContactsRequest',
        method: 'GET',
        schema: {
          rules: pinnedContactsListSchema,
          pathData: [],
        },
        actions: {
          success: setPinnedContacts,
        },
        params,
        ...meta,
      }),
    ],
    [types.PIN_CONTACT_REQUEST]: [
      identity,
      ({ contactId, ...params }, meta) => ({
        async: true,
        route: `/pinned-contacts/${contactId}`,
        selectorName: 'pinContactRequest',
        method: 'POST',
        callbacks: path(['callbacks', 'success'], meta),
        params,
        ...meta,
      }),
    ],
    [types.UNPIN_CONTACT_REQUEST]: [
      identity,
      ({ contactId, ...params }, meta) => ({
        async: true,
        route: `/pinned-contacts/${contactId}`,
        selectorName: 'unpinContactRequest',
        method: 'DELETE',
        callbacks: path(['callbacks', 'success'], meta),
        params,
        ...meta,
      }),
    ],
  },
  types.SET_CONTACTS,
  types.SET_CONTACT,
  types.DELETE_CONTACT,
  types.SET_PINNED_CONTACTS,
  types.SET_PINNED_CONTACT,
);
