import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useGitlabProjectsContext } from '../context/useGitlabProjectsContext';

import { PreLoaderWhileLoading } from '../../../preloaderWhileLoading';
import { PRELOADER_DIMENSION } from '../../../../constants/ui';
import { GitlabProjectItem } from '../GitlabProjectItem';

import api from '../../../../api/projects.service';

import './style.sass';
import { callNotification } from '../../../../utils/helpers/notifies';

const LIMIT_OF_REPOSITORIES = 10;
const PRELOADER_DELAY = 600;

export const GitlabProjectsList = () => {
  const { sortBy, repositories, setRepositories } = useGitlabProjectsContext();
  const { id: projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const { t } = useTranslation(['common']);


  const fetchRepositories = useCallback(async (params) => {
    try {
      const { data } = await api.getRepositories(projectId, params);
      setIsLoading(true);
      setIsPending(true);
      setRepositories(data);
    } catch (error) {
      callNotification.error(error.response.data.result.message);
    } finally {
      setIsLoading(false);
      setIsPending(false);
    }
  }, [projectId]);

  useEffect(() => {
    fetchRepositories({ ...sortBy, limit: LIMIT_OF_REPOSITORIES });
  }, []);

  return (
    <PreLoaderWhileLoading
      dimension={PRELOADER_DIMENSION.MIDDLE}
      alignContainerCenter
      delay={PRELOADER_DELAY}
      isLoading={isLoading}
      onAction={isPending}
    >

      <ul className="gitlab__project-list">{
              repositories?.length ? (
                repositories.map(({
                  id, name, web_url: webUrl, gitlab_project_id: repositoryId,
                }) => (
                  <GitlabProjectItem key={id} gitlabProjectId={id} name={name} webUrl={webUrl} repositoryId={repositoryId} />
                ))
              ) : <li className="gitlab__project-list__empty">{t('There are no repositories yet')}</li>
            }
      </ul>

    </PreLoaderWhileLoading>
  );
};
