import { handleActions } from 'redux-actions';
import { uniq } from 'ramda';

import types from './types';
import {
  mergeByPath,
  mergeDeep,
  mergeDeepByPath,
} from '../../utils/helpers/ramdaStateHelpers';

const reducer = handleActions({
  [types.SET_PROJECTS_LIST]: mergeDeep(({
    payload: {
      data: { result, entities },
      hasMore,
      count,
    },
  }) => ({
    entities: {
      ...entities,
      hasMore,
      count,
    },
    result: {
      projects: result,
    },
  })),
  [types.SET_MORE_PROJECTS]: mergeDeep(({
    payload: {
      data: { result, entities },
      hasMore,
      count,
    },
  }, state) => ({
    entities: {
      ...entities,
      hasMore,
      count,
    },
    result: {
      projects: [
        ...state.result,
        ...result,
      ],
    },
  })),
  [types.SET_IMPORTANT_TASKS_PROJECTS]: mergeDeep(({
    payload: {
      data: { result, entities },
      hasMore,
      count,
    },
  }, state) => ({
    entities: {
      ...entities,
      hasMoreImportantTask: hasMore,
      importantTaskCount: count,
    },
    result: {
      importantTasks: [
        ...state.result.importantTasks,
        ...result,
      ],
    },
  })),
  [types.SET_PROJECTS_LIST_MENU]: mergeDeep(({
    payload: {
      data: { entities, result },
      count,
      hasMore,
    },
  }, state) => ({
    entities: {
      ...entities,
      hasMoreMenu: hasMore,
      countMenu: count,
    },
    result: {
      menu: uniq([...state.result.menu, ...result]),
    },
  })),
  [types.SET_PROJECTS_FAVORITE]: mergeDeepByPath(['entities', 'projects'],
    ({ payload: { meta: { id } } }) => ({ [id]: { favorite: 1 } })),
  [types.REMOVE_PROJECTS_FAVORITE]: mergeDeepByPath(['entities', 'projects'],
    ({ payload: { meta: { id } } }) => ({ [id]: { favorite: 0 } })),
  [types.SET_PROJECTS_FAVORITE_CUSTOM]: mergeDeepByPath(['entities', 'projects'],
    ({ payload: id }) => ({ [id]: { favorite: 1 } })),
  [types.REMOVE_PROJECTS_FAVORITE_CUSTOM]: mergeDeepByPath(['entities', 'projects'],
    ({ payload: id }) => ({ [id]: { favorite: 0 } })),
  [types.SET_PROJECTS_SUMMARY]: mergeByPath(['summary'], ({ payload: { data } }) => data),
  [types.SET_PROJECTS_AUTOCOMPLETE]: mergeDeep(({ payload: { projects, count } }) => ({
    projectsAutocomplete: {
      projects,
      count,
    },
  })),
}, {
  entities: {},
  result: {
    menu: [],
    projects: [],
    importantTasks: [],
  },
  viewStyle: '',
});

export default reducer;
