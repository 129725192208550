import {
  compose, getContext, lifecycle, pure, withHandlers, withProps, withState,
} from 'recompose';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import ListOfLeads from './listOfLeads';
import { uiActions } from '../../../../state/ui';
import { leadsSelectors, leadsActions } from '../../../../state/leads';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { preloaderWhileLoading } from '../../../../utils/enchancers';

const mapStateToProps = state => ({
  activeRelatedLead: leadsSelectors.getActiveRelatedLeadSelector(state),
  isPending: leadsSelectors.getLeadsPendingRequest(state),
});

const mapDispatchToProps = {
  openModal: uiActions.openModal,
  setActiveRelatedLead: leadsActions.setActiveRelatedLead,
};

const onActionHandler = modalName => ({
  openModal,
}) => () => {
  openModal(modalName);
};


const toggleActiveTabHandler = ({ setActiveTabLeadId, activeTabLeadId }) => leadId => (
  activeTabLeadId === leadId ? setActiveTabLeadId(null) : setActiveTabLeadId(leadId)
);

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['tasks', 'common']),
  withState('showLoader', 'setIsShowLoader', true),
  withState('activeTabLeadId', 'setActiveTabLeadId', ({ activeRelatedLead = null }) => activeRelatedLead),
  pure,
  getContext({
    onSelectAssignee: PropTypes.func,
    onSelectAssigner: PropTypes.func,
    setSelectedLeadId: PropTypes.func.isRequired,
    selectedPinnedLeadId: PropTypes.any,
    setSelectedPinnedLeadId: PropTypes.func,
  }),
  withProps(({ lead, pinnedLead }) => ({
    leadName: getFullName(lead),
    isPinned: !!pinnedLead,
  })),
  withHandlers({
    onEditLeadModal: onActionHandler('editLeadModal'),
    onDeleteLeadModal: onActionHandler('deleteLeadModal'),
    onPinLeadModal: onActionHandler('pinLeadModal'),
    onUnpinLeadModal: onActionHandler('unpinLeadModal'),
    toggleActiveTab: toggleActiveTabHandler,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      // Show loader only when component was mounted
      const {
        isPending,
        setIsShowLoader,
        activeTabLeadId,
        selectedPinnedLeadId,
        setActiveTabLeadId,
        setSelectedPinnedLeadId,
      } = this.props;
      const { isPending: prevIsPending, showLoader: prevShowLoader } = prevProps;
      if (prevIsPending && !isPending && prevShowLoader) {
        setIsShowLoader(false);
      }
      if (selectedPinnedLeadId && activeTabLeadId !== selectedPinnedLeadId) {
        setActiveTabLeadId(selectedPinnedLeadId);
      } else if (
        selectedPinnedLeadId === prevProps.selectedPinnedLeadId
        && selectedPinnedLeadId === activeTabLeadId
      ) {
        setSelectedPinnedLeadId(null);
      }
    },
    componentWillMount() {
      this.props.setActiveRelatedLead(null);
    },
  }),
  preloaderWhileLoading({
    dimension: 100,
    fullScreen: true,
    isLoading: () => false,
    onAction: ({ showLoader }) => showLoader,
    delay: 600,
  }),
);
export default enhance(ListOfLeads);
