/* eslint-disable import/prefer-default-export */
const STYLE_VIEW = {
  CELL: 'cell',
  LIST: 'list',
};

const TYPE = {
  PUBLIC: 1,
  PRIVATE: 0,
};

const MY_PROJECTS_FILTER = {
  ALL: 'all',
  CREATED_BY_ME: 'createdByMe',
  FAVORITES: 'favorites',
  ACTIVE: 'activeStatus',
  INACTIVE: 'inactiveStatus',
};

export {
  STYLE_VIEW,
  TYPE,
  MY_PROJECTS_FILTER,
};
