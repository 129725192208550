import {
  compose, withState, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  inc, tap, path, last,
} from 'ramda';
import { withFormik } from 'formik';

import StatusesCRUD from './statusesCRUDModal';
import { projectActions, projectSelectors } from '../../../../state/project';
import { uiActions, uiSelectors } from '../../../../state/ui';
import rules from './rules';
import { STATUS } from '../../../../constants/tasks';
import { stateOfTasksStatusesOptions } from './utils';

const mapDispatchToProps = ({
  addStatusOfTasksRequest: projectActions.addStatusOfTasksRequest,
  onDragTasksStatus: projectActions.onDragTasksStatus,
  setOpenModal: uiActions.openModal,
  closeModal: uiActions.closeModal,
});

const mapStateToProps = state => ({
  entities: projectSelectors.getStatusesOfTasksEntities(state),
  result: projectSelectors.getStatusesOfTasksList(state),
  isPending: projectSelectors.getStatusesOfTasksPending(state),
  isOpen: uiSelectors.getModal(state)('statusesOfTasks'),
});

const initialValues = () => ({
  title: '',
  state: STATUS.TO_DO.id,
});

const onDeleteTaskStatusHandler = ({ setOpenModal, setSelectedTasksStatusId }) => (statusId) => {
  setSelectedTasksStatusId(statusId);
  setOpenModal('deleteTasksStatus');
};

const onSubmitForm = ({ state, title },
  {
    resetForm,
    props: {
      match: { params: { id } },
      addStatusOfTasksRequest,
      setIsUIAddMoreStatuses,
      entities,
      result,
    },
  }) => addStatusOfTasksRequest({
  projectId: id,
  state: inc(state),
  type: state,
  title,
  priority: compose(inc, Number, path([last(result), 'priority']))(entities),
}, {
  callbacks: {
    success: () => {
      resetForm({});
      tap(setIsUIAddMoreStatuses, false);
    },
  },
});

const onDragEndHandler = ({
  match: { params: { id } },
  onDragTasksStatus,
}) => (data) => {
  if (data.source && data.destination) {
    onDragTasksStatus({ ...data, projectId: id });
  }
};

const onCloseModalHandler = ({ closeModal, resetForm }) => () => {
  resetForm({});
  closeModal('statusesOfTasks');
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withState('editingStatusId', 'setEditingStatusId', null),
  withState('isUIAddMoreStatuses', 'setIsUIAddMoreStatuses', null),
  withState('selectedTasksStatusId', 'setSelectedTasksStatusId', null),
  withProps(() => ({
    stateOfStatusesOptions: stateOfTasksStatusesOptions,
  })),
  withFormik({
    mapPropsToValues: initialValues,
    validateOnChange: false,
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: onSubmitForm,
  }),
  withHandlers({
    onDeleteTaskStatus: onDeleteTaskStatusHandler,
    onDragEnd: onDragEndHandler,
    onCloseModal: onCloseModalHandler,
  }),
);
export default enhance(StatusesCRUD);
