import {
  compose, lifecycle, withStateHandlers, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import NotesList from './notesList';
import { leadsActions, leadsSelectors } from '../../../../../../state/leads';
import { OFFSET } from '../../../../../../constants/crm';


const mapStateToProps = (state, { leadId }) => ({
  notes: leadsSelectors.getLeadNotesSelector(state)(leadId),
  leadsNotesCount: leadsSelectors.getLeadsNoteCountSelector(state),
});

const mapDispatchToProps = ({
  getLeadNotesRequest: leadsActions.getLeadNotesRequest,
});

const getMoreNotes = ({
  getLeadNotesRequest, setOffset, offset, leadId, leadsNotesCount, notes,
}) => () => {
  if (notes.length < leadsNotesCount) {
    setOffset(offset + OFFSET);
    getLeadNotesRequest({ id: leadId, offset });
  }
};

const changeActiveTab = ({ activeTab }) => (idTab) => {
  if (idTab === activeTab) {
    return {
      activeTab: 0,
    };
  }
  return {
    activeTab: idTab,
  };
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withHandlers({
    getMoreNotesHandler: getMoreNotes,
  }),
  withStateHandlers(
    () => ({ activeTab: 0 }), {
      changeActiveTabStateHandler: changeActiveTab,
    },
  ),
  lifecycle({
    componentDidMount() {
      const { leadId: id, getLeadNotesRequest } = this.props;
      getLeadNotesRequest({ id });
    },
    componentDidUpdate(prevProps) {
      const {
        leadId: id,
        getLeadNotesRequest, sortBy, order,
      } = this.props;
      if ((this.props.sortBy !== prevProps.sortBy)
        || (this.props.order !== prevProps.order)) {
        getLeadNotesRequest({ id, sortBy, order });
      }
    },
  }),
);
export default enhance(NotesList);
