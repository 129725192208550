import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmModal } from '../../../../../ui-kit';

const HrConfirmDeleteModal = ({ candidate, deleteCandidate, setSelectedCandidate }) => (
  <ConfirmModal
    isOpen={!!candidate}
    actionTitle="Delete"
    onAction={deleteCandidate}
    onClose={() => setSelectedCandidate(null)}
    title="Delete Candidate"
  >
    Are you sure you want delete candidate
    {candidate
    && candidate.firstName
    && candidate.lastName
    && (
      <span className="weight--bold">
        {` ${candidate.firstName} ${candidate.lastName}`}
      </span>
    )}
    ?
  </ConfirmModal>
);

HrConfirmDeleteModal.propTypes = {
  candidate: PropTypes.instanceOf(Object),
  deleteCandidate: PropTypes.func.isRequired,
  setSelectedCandidate: PropTypes.func.isRequired,
};
HrConfirmDeleteModal.defaultProps = {
  candidate: null,
};

export default HrConfirmDeleteModal;
