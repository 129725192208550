import React from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import {
  Avatar, BrillMask, Grid, Pagination, ButtonsCollection, EmptyResult,
} from '../../../../../ui-kit';
import './styles.scss';

const HrTable = ({
  push, candidates, onDeleteCandidate, pagination,
  onSortBy, sort, order, gridName, isLoading, entities,
  getStatus, getPosition, count, getAgeFromDate,
}) => (
  <Grid.Wrapper
    isLoading={isLoading}
    isKeepOptions
    name="Human resources"
    className="HrTable"
    pagination={(
      <Pagination
        count={count}
        component={gridName}
        pagination={pagination}
        className="users-list__pagination"
      />
    )}
  >
    <Grid.Row className="HrTable_row">
      <Grid.Col
        className="HrTable_row__col"
        name="#"
        sort={sort}
        order={order}
        filter="id"
        onClick={() => onSortBy({ sort: 'id' })}
      />
      <div className="HrTable_row__col avatar" />
      <Grid.Col
        className="HrTable_row__col"
        name="Name"
        sort={sort}
        order={order}
        filter="first_name"
        onClick={() => onSortBy({ sort: 'first_name' })}
      />
      <Grid.Col
        className="HrTable_row__col"
        name="Email"
        order={order}
        sort={sort}
        filter="email"
        onClick={() => onSortBy({ sort: 'email' })}
      />
      <Grid.Col
        className="HrTable_row__col"
        name="Position"
        order={order}
        sort={sort}
        filter="position"
        onClick={() => onSortBy({ sort: 'position' })}
      />
      <Grid.Col
        className="HrTable_row__col"
        name="Status"
        order={order}
        sort={sort}
        filter="in_progress"
        onClick={() => onSortBy({ sort: 'status' })}
      />
      <Grid.Col
        className="HrTable_row__col"
        name="City"
        order={order}
        sort={sort}
        onClick={() => onSortBy({ sort: 'city' })}
        filter="city"
      />
      <Grid.Col
        className="HrTable_row__col"
        name="Age"
        order={order}
        sort={sort}
        onClick={() => onSortBy({ sort: 'date_of_birthday' })}
        filter="date_of_birthday"
      />
      <Grid.Col className="HrTable_row__col" />
    </Grid.Row>
    {candidates.length > 0 && candidates.map(candidateId => (
      <Grid.Row
        className="HrTable_row"
        onClick={() => push(`candidate/${candidateId}`)}
        key={`candidate-${candidateId}`}
      >
        <Grid.ColData className="HrTable_row__col">{candidateId}</Grid.ColData>
        <Grid.ColData className="HrTable_row__col avatar">
          <BrillMask>
            <Avatar alt={`${entities[candidateId].first_name} ${entities[candidateId].last_name}`} />
          </BrillMask>
        </Grid.ColData>
        <Grid.ColData className="HrTable_row__col text--cut">
          <div className="text--cut p-xs text--breaker">
            {(`${entities[candidateId].first_name} ${entities[candidateId].last_name}`) || 'N/A'}
          </div>
        </Grid.ColData>
        <Grid.ColData className="HrTable_row__col">
          <div className="text--cut p-xs">{(path(['email'], entities[candidateId])) || 'N/A'}</div>
        </Grid.ColData>
        <Grid.ColData className="HrTable_row__col">
          <div className="text--cut p-xs">{getPosition(candidateId) || 'N/A'}</div>
        </Grid.ColData>
        <Grid.ColData className="HrTable_row__col">
          <div className="text--cut p-xs text--breaker">{getStatus(candidateId) || 'N/A'}</div>
        </Grid.ColData>
        <Grid.ColData className="HrTable_row__col text--cut">{(path(['city'], entities[candidateId])) || 'N/A'}</Grid.ColData>
        <Grid.ColData className="HrTable_row__col text--cut">{getAgeFromDate(candidateId) || 'N/A'}</Grid.ColData>
        <Grid.ColData className="HrTable_row__col controls">
          <ButtonsCollection.ButtonIcons
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              push(`candidate/edit/${candidateId}`);
            }}
            title="Edit candidate"
          >
            <span className="icon-edit-icon" />
          </ButtonsCollection.ButtonIcons>
        </Grid.ColData>
        <Grid.ColData className="HrTable_row__col controls">
          <ButtonsCollection.ButtonIcons
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onDeleteCandidate(entities[candidateId]);
            }}
            title="Delete user"
          >
            <span className="icon-trash" />
          </ButtonsCollection.ButtonIcons>
        </Grid.ColData>
      </Grid.Row>
    )) }
  </Grid.Wrapper>
);

HrTable.propTypes = {
  push: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  candidates: PropTypes.instanceOf(Object).isRequired,
  entities: PropTypes.instanceOf(Object).isRequired,
  count: PropTypes.number.isRequired,
  onDeleteCandidate: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
  }).isRequired,
  onSortBy: PropTypes.func.isRequired,
  getStatus: PropTypes.func.isRequired,
  getPosition: PropTypes.func.isRequired,
  getAgeFromDate: PropTypes.func.isRequired,
  sort: PropTypes.string,
  order: PropTypes.string,
  gridName: PropTypes.string.isRequired,
};
HrTable.defaultProps = {
  order: '',
  sort: '',
};

export default HrTable;
