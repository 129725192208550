import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { openModal } from '../../../../state/ui/actions';
import DirectChannels from './directChannels';
import { messengerSelectors } from '../../../../state/messenger';

const mapStateToProps = state => ({
  channels: messengerSelectors.getDirectChannelsList(state),
  activeChannel: messengerSelectors.getActiveChannel(state),
});

const mapDispatchToProps = ({
  openModal,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common', 'chat']),
  withProps(({ channels }) => ({
    channelsList: channels,
  })),
);

export default enhance(DirectChannels);
