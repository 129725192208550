import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';

import '../style.sass';
import { BrillWithIndex } from '../../../../../ui-kit';

const getItemValue = (item, key) => propOr('', key, item);

const SummaryRow = ({ t, summary }) => (
  <div className="project-summary__row">
    {summary.map((item) => (
      <div
        key={`summarry - ${item.statusId}`}
        className={`project-summary__row__card project-summary__row__card--${getItemValue(
          item,
          'title',
        ).toLowerCase()} project-summary__row__card--custom-status`}
      >
        <BrillWithIndex colorBorder="#fcccc4">{getItemValue(item, 'value')}</BrillWithIndex>
        <h4 className="project-summary__row__title">{t(getItemValue(item, 'title'))}</h4>
      </div>
    ))}
  </div>
);

SummaryRow.propTypes = {
  t: PropTypes.func.isRequired,
  summary: PropTypes.instanceOf(Array).isRequired,
};

export default SummaryRow;
