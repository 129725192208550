import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ButtonsCollection } from '../../../../ui-kit';
import { uiActions, uiSelectors } from '../../../../state/ui';
import { useWindowWidth } from '../../../../utils/hooks';
import './style.sass';

/**
 * @returns {JSX.Element}
 * @constructor
 */

const TABLET_SIZE = 768;

export const ToggleButtonLeftSideNav = () => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch();
  const leftSideBarStatus = useSelector(uiSelectors.getLeftSidebar);
  const rightSideBarStatus = useSelector(uiSelectors.getRightSidebar);
  const changeLeftSideBarStatus = useCallback((value) => {
    dispatch(uiActions.changeLeftSidebarStatus(value));
  });
  const changeRightSidebarStatus = useCallback((value) => {
    dispatch(uiActions.changeRightSidebarStatus(value));
  });

  const onToggle = useCallback(() => {
    if (windowWidth < TABLET_SIZE && !leftSideBarStatus && rightSideBarStatus) {
      changeRightSidebarStatus(false);
    }
    changeLeftSideBarStatus(!leftSideBarStatus);
  });

  return (
    <>
      <ButtonsCollection.ButtonIcons
        className="close-left-aside"
        title={t('Close aside')}
        onClick={onToggle}
      >
        <span className="icon-cross" />
      </ButtonsCollection.ButtonIcons>
      <button
        title="Toggle left sidebar"
        type="button"
        className="toggle-left-aside"
        onClick={onToggle}
      >
        <span className="icon-expand-arrow-right" />
      </button>
    </>
  );
};
