import classNames from 'classnames';
import { prop } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components as reactSelectComponents } from 'react-select';
import { toTitle } from '../../../utils/helpers/commonHelpers';
import * as components from '../components';
import '../style.sass';

/**
 *
 * @param label {string}
 * @param data {object}
 * @param props {object}
 * @returns {JSX.Element}
 * @constructor
 */

const OptionComponent = ({ label, data, ...props }) => (
  <reactSelectComponents.Option {...props} className="auto-complete__option-custom">
    <div className="auto-complete__option-content">
      <span
        className="text--cut"
        title={label}
      >{label}
      </span>
      {' '}
      {prop('username', data) ? (
        <span className="auto-complete__help-option-text text--cut">
          ({prop('username', data)})
        </span>
      ) : ''
        }
    </div>
  </reactSelectComponents.Option>
);

/**
 *
 * @param props {object}
 * @returns {JSX.Element}
 * @constructor
 */

const MultiValueRemove = (props) => {
  if (props.data.isFixed) {
    return null;
  }
  return <components.MultiValueRemove {...props} />;
};

/**
 *
 * @param error {string | boolean}
 * @param onBlur {function}
 * @param name {string}
 * @param onFocus {function}
 * @param touched {boolean}
 * @param props {object}
 * @returns {JSX.Element}
 * @constructor
 */


export const DefaultAutoComplete = ({
  error = '',
  onBlur = () => {},
  touched = false,
  onFocus = () => {},
  name,
  ...props
}) => {
  const { t } = useTranslation('validation');

  return (
    <div className={classNames('auto-complete', { 'auto-complete--has-error': error && touched })}>
      <Select
        className="auto-complete__field"
        classNamePrefix="auto-complete"
        {...props}
        onBlur={onBlur}
        onFocus={onFocus}
        components={{
          ...components,
          MultiValueRemove,
          Option: OptionComponent,
        }}
      />
      {error.length !== 0 && touched && (
      <span className="auto-complete__error field__error-text">
        {t(error, { key: toTitle(name) })}
      </span>
      )}
    </div>
  );
};
