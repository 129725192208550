import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';

import { Header } from '../../containers';
import { ButtonsCollection, ScrollWrapper } from '../../ui-kit';
import { setVersionToStorage } from '../../utils/helpers/uiHelpers';
import { uiSelectors } from '../../state/ui';
import ChangeLogMD from '../../CHANGELOG.md';
import './style.sass';

/**
 * @returns {JSX.Element}
 * @constructor
 */

export const Changelog = () => {
  const [changelog, setChangelog] = useState(null);
  const { t } = useTranslation();
  const version = useSelector(uiSelectors.getVersionSelector);
  const setVersion = useCallback(() => setVersionToStorage(version));
  const dispatch = useDispatch();

  const onClickBackHandler = useCallback(() => {
    dispatch(push('/projects/'));
  });

  useEffect(() => {
    setVersion(version);
    fetch(ChangeLogMD).then(response => response.text()).then((text) => {
      setChangelog(text);
    });
  }, [version]);
  return (
    <>
      <Header hideToggleRightSideBar>
        <h1 className="weight--medium title--xl title-page">
          <ButtonsCollection.ButtonIcons
            title={t('Go back to projects')}
            renderMobileButton
            className="user-head-back"
            onClick={onClickBackHandler}
          >
            <span className="icon-expand-arrow-left" />
          </ButtonsCollection.ButtonIcons>
          ChangeLog
        </h1>
      </Header>
      <div className="changelog__container">
        <ScrollWrapper>
          <ReactMarkdown source={changelog} />
        </ScrollWrapper>
      </div>
    </>
  );
};
