/* eslint-disable */
import { handleActions } from 'redux-actions';
import {
  uniq,
  filter,
  propOr,
  omit,
  pathOr,
  compose,
  values,
  objOf,
  reject,
  equals,
  prop, mergeDeepRight,
} from 'ramda';
import { notEqual } from 'ramda-extension';
import {filterObjectByKey} from '../../../src/utils/helpers/projectHelpers/utils'
import {
  mergeDeepByPath,
  mergeDeep,
  mergeByPath,
  mergeIn,
  mergeDeepByPathWithConcat,
} from '../../utils/helpers/ramdaStateHelpers';

import types from './types';
import { STATUS } from '../../constants/tasks';

const reducer = handleActions({
  [types.SET_CATEGORY_TASK]: mergeDeepByPath(['entities', 'categories'], ({ payload: { category } }) => ({
    [category.id]: category,
  })),
  [types.DELETE_CATEGORY_TASK_ENTITY]: mergeByPath(['entities'], ({ payload }) => ({ categories: payload })),
  [types.REORDER_CATEGORY_TASK]: mergeByPath(['result'], ({ payload }) => ({ categories: payload })),
  [types.EDIT_PROJECT]: mergeDeep(({
    payload:
    { data: { entities, result: { summary, ...result } }, project: { id } },
  }) => ({
    entities,
    result: { ...result, members: entities.data[id].members },
    // summary,
  })),
  [types.SET_SPRINTS]: mergeDeep(({ payload: { data } }) => data),
  [types.SET_PROJECT_TASKS]: mergeDeep(({ payload: { data } }) => data),
  [types.SET_SPRINT]: mergeDeepByPath(['entities', 'sprints'], ({ payload: { sprint } }) => ({ [sprint.id]: sprint })),
  [types.REORDER_SPRINT_TASKS]: mergeDeepByPath(['entities', 'sprints'], ({ payload: { sprintId, tasks } }) => ({
    [sprintId]: ({ tasks: [...tasks] }),
  })),
  [types.SET_SPRINT_TASK_TO_LIST]: mergeDeepByPathWithConcat(['entities'],
    ({ payload: { sprintId, itemId } }) => ({
      sprints: {
        [sprintId]: ({ tasks: [itemId] }),
      },
      // ...(isNewTask ? ({
      //   agenda: {
      //     [STATUS.TO_DO.id]: {
      //       tasks: [itemId],
      //     },
      //   },
      // }) : ({})),
    })),
  [types.REORDER_BACKLOG_TASKS]: mergeDeepByPath(['result'], ({ payload: { tasks } }) => ({
    backlog: tasks,
  })),
  [types.SET_BACKLOG_TASK_TO_LIST]: mergeDeepByPathWithConcat(['result'], ({ payload }) => ({
    backlog: [payload.itemId],
  })),
  [types.SET_BACKLOG_TASK]: mergeDeepByPath(['entities', 'backlog'], ({ payload }) => payload),
  [types.SET_SPRINT_TASK]: mergeDeepByPath(['entities', 'tasks'], ({ payload }) => payload),
  [types.SET_USERS_WITH_TASKS]: mergeDeep(({ payload: { data: { result, entities } } }) => ({
    entities,
    result: ({ usersWithTasks: uniq(result) }),
  })),
  [types.SET_CATEGORIES_TASK]: mergeIn((action, state) => ({
    entities: {
      ...state.entities,
      ...action.payload.data.entities,
    },
    result: {
      ...state.result,
      ...{ categories: action.payload.data.result },
    },
  })),
  [types.SORT_BACKLOG_TASKS]: mergeByPath(['result'], ({ payload: { data: { result } } }) => ({
    backlog: [...result],
  })),
  [types.SORT_SPRINT_TASKS]: mergeDeepByPath(['entities', 'sprints'], ({ payload: { data: { result }, meta } }) => ({
    [meta.sprintId]: ({ tasks: [...result] }),
  })),
  [types.REMOVE_SPRINT_TASK]: mergeByPath(['entities'], ({ payload }) => ({ tasks: payload })),
  [types.REMOVE_BACKLOG_TASK]: mergeByPath(['entities'], ({ payload }) => ({ backlog: payload })),
  [types.SET_AGENDA_TASKS]: mergeDeep(({ payload: { data } }) => data),
  [types.REORDER_AGENDA_TASKS]: mergeDeepByPath(['entities', 'agenda'], ({ payload: { status_id, tasks } }) => ({
    [status_id]: ({ tasks: [...tasks] }),
  })),
  [types.DELETE_TASK_OF_AGENDA]: mergeDeepByPath(['entities', 'agenda'], ({ payload: { status_id, taskId } }, { entities }) =>  ({
      [status_id]: ({ tasks: compose(filter(notEqual(taskId)), pathOr([], ['agenda', status_id, 'tasks']))(entities) }),
    })),
  [types.SET_TASK_TO_AGENDA]: mergeDeepByPathWithConcat(['entities', 'agenda'], ({ payload: { status_id, taskId } }) => ({
    [status_id]: ({ tasks: [taskId] }),
  })),
  [types.REMOVE_BACKLOG_TASK]: mergeByPath(['entities'], ({ payload }) => ({ backlog: payload })),
  [types.DELETE_PROJECT_TASK]: mergeByPath(['entities'], ({ payload }, state) => ({
      sprints: ({
        ...state.entities.sprints,
        ...payload.sprints,
      }),
      tasks: ({ ...payload.tasks }),
    })),
  [types.REORDER_SPRINT]: mergeByPath(['result'], ({ payload }) => ({ sprints: payload })),
  [types.SET_PINNED_TASKS]: mergeIn(({ payload: { data } }) => ({
    pinnedTasks:
      { entities: data.entities.pinnedTasks, result: data.result },
  })),
  [types.RESET_PIN_TASK]: mergeIn(({ payload: { task_id } }, { pinnedTasks }) => ({
    pinnedTasks: {
      // eslint-disable-next-line camelcase
      entities: omit([task_id], pinnedTasks.entities),
      result: filter(notEqual(task_id), propOr([], 'result', pinnedTasks)),
    },
  })),
  [types.SET_PIN_TASK]: mergeDeep(({ payload: { task_id, meta } }, { pinnedTasks }) => ({
    pinnedTasks: {
      entities: { [task_id]: meta.task },
      // eslint-disable-next-line camelcase
      result: uniq([task_id, ...propOr([], 'result', pinnedTasks)]),
    },
  })),
  [types.DELETE_SPRINT_ENTITY]: mergeByPath(['entities'], ({ payload }) => ({ sprints: payload })),
  [types.SET_IMPORTANT_TASKS]: mergeDeep(({
    payload: { data: { entities: { tasks }, result } },
  }) => ({
    entities: { importantTasks: tasks },
    result: { importantTasks: result },
  })),
  [types.SET_PROJECT_MEMBERS]: mergeDeep(({
    payload:
    { data: { entities }, project: { id } },
  }) => ({
    result: {
      members: entities.data[id].members,
    },
  })),
  [types.SET_STATUSES_OF_TASKS]: mergeDeep(({
    payload: {
      data: { entities: { statuses }, result },
    },
  }, {entities: { tasks }}) => {
    const summary = []
    for (const status in statuses){
      summary.push({
        statusId: status,
        title:statuses[status].title,
        priority: statuses[status].priority,
        value: values(tasks)
          .filter(task => task.status_id === statuses[status].id).length
      })
    }
    return {
    entities: { agenda: statuses },
    result: objOf('statuses', result),
      summary
  }}),
  [types.SET_STATUS_OF_TASKS]: mergeDeep(({
    payload: { status },
  }, { entities: oldEntities, result: oldResult }) => ({
    entities: compose(mergeDeepRight(oldEntities), objOf('agenda'), objOf(prop('id', status)))(status),
    result: {
      ...oldResult,
      statuses: uniq([...oldResult.statuses, status.id]),
    },
  })),
  [types.DELETE_STATUS_OF_TASKS]: mergeDeep(({
    payload: { status },
  }, { result }) => ({
    result: compose(objOf('statuses'), reject(equals(status.id)), propOr([], ['statuses']))(result),
  })),
  [types.REORDER_PROJECT_MEMBERS]: mergeByPath(['result'], ({ payload }) => ({ members: payload })),
  [types.CLEAR_PROJECT_DATA]: () => [],
  [types.SET_SUMMARY]: mergeByPath(['summary'], ({ payload: {destination, source} }, { summary }) => {
    values(summary).map(i=> {
      if (+i.statusId === destination.droppableId)(i.value+=1)
      if (+i.statusId === source.droppableId)(i.value-=1)
      else return i
    })
    return summary
  }),
}, {});

export default reducer;
