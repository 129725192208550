const GROUP_CHANNEL = 1;
const GENERAL_CHANNEL = 0;
const PRIVATE_CHANNEL = 2;
const DIRECT_CHANNEL = 3;
const DIRECT_CHANNELS = [4, 3];
const GROUP_CHANNELS = [GROUP_CHANNEL, PRIVATE_CHANNEL];
const DATE_MESSAGE_FORMAT = 'MMM D, YYYY ';
const TIME_MESSAGE_FORMAT = 'h:mm:ss A';
const ENTER_KEY = 13;
const CHANNELS_URL = '/messenger/channels/';
const DIRECTS_URL = '/messenger/directs/';
const DIRECT_CHANNEL_STORE = 'directChannels';
const GROUP_CHANNEL_STORE = 'groupChannels';
const GLOBAL_MENTION_ID = 0;

const CHANNEL_TYPES = {
  0: 'GROUP_CHANNELS',
  1: 'GROUP_CHANNELS',
  2: 'GROUP_CHANNELS',
  3: 'DIRECT_CHANNELS',
  4: 'DIRECT_CHANNELS',
};

const LAST_CHANNEL_MESSENGER_STORAGE = 'lastChannelMessengerStorage';

export {
  PRIVATE_CHANNEL,
  DIRECT_CHANNEL,
  GROUP_CHANNEL,
  DATE_MESSAGE_FORMAT,
  TIME_MESSAGE_FORMAT,
  GROUP_CHANNEL_STORE,
  DIRECT_CHANNEL_STORE,
  ENTER_KEY,
  GENERAL_CHANNEL,
  CHANNEL_TYPES,
  CHANNELS_URL,
  GROUP_CHANNELS,
  DIRECTS_URL,
  LAST_CHANNEL_MESSENGER_STORAGE,
  GLOBAL_MENTION_ID,
  DIRECT_CHANNELS,
};
