import React from 'react';
import { BrillMask, DropDown } from '..';
import { ItemLabel } from './ItemLabel';
import { AvatarItem } from './AvatarItem';
import './style.sass';

/**
 *
 * @param onClick {function}
 * @param displayAvatars {array}
 * @param hiddenAvatars {array}
 * @param showCheckbox {boolean}
 * @returns {JSX.Element}
 * @constructor
 */


export const UserListAvatar = React.memo(({
  onClick = () => { },
  displayAvatars = [],
  hiddenAvatars = [],
  showCheckbox = true,
}) => displayAvatars?.length && (<>
  <ul className="users-list__list">
    {
      displayAvatars.map(
        avatar => (
          <AvatarItem
            key={`${avatar.id} - ${avatar.alt}`}
            alt={avatar.alt}
            id={avatar.id}
            select={avatar.select}
            asLink={avatar.asLink}
            src={avatar.src}
            importantBackground={avatar.importantBackground}
            onClick={onClick}
          />
        ),
      )
    }
  </ul>
  {
    !!hiddenAvatars.length && (
      <DropDown
        showCheckbox={showCheckbox}
        className="agenda-board__user-list__drop-down"
        name="users"
        onChange={({ val }) => onClick(val.id)}
        isMulti
        label={(
          <BrillMask asLink className="agenda-board__user-list">
            <div className="user-list-count__wrapper">
              <span className="user-list-count__item">
                {`+ ${hiddenAvatars.length} `}
              </span>
            </div>
          </BrillMask>
        )}
        ItemLabel={ItemLabel}
        list={hiddenAvatars}
      />
    )
  }
</>));
