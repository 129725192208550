export const APP_ROUTES = {
  MAIN: '/',
  PROJECTS: '/projects/',
  FORGOT_PASSWORD: '/forgot-password/',
  FORGOT_PASSWORD_SUCCESS: '/forgot-password-success/',
  PROFILE: '/profile/',
  PEOPLE: '/people',
  PREFERENCES: '/preferences/',
  PREFERENCES_USERS: '/preferences/users/',
  PREFERENCES_ROLES: '/preferences/roles/',
  PREFERENCES_BILLING_INFO: '/preferences/billing-info',
  PREFERENCES_INVITE_USER: '/preferences/users/invite-user',
  TASKS: '/tasks/',
  TASK: '/task',
  MESSENGER: '/messenger/',
  MY_ACTIVITY: '/activity/me/',
  CRM: '/crm/',
  CHANGELOG: '/changelog',
  KNOWLEDGE_BASE: '/knowledge-base/',
};
