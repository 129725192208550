import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { includes } from 'ramda';
import { useTranslation } from 'react-i18next';

import { ButtonsCollection, ModalWindow, FormsCollection } from '..';
import { FilesField, FilesItem } from './components';
import './style.sass';
import { AVAILABLE_FILE_FORMATS } from '../../constants/files';
import { callNotification } from '../../utils/helpers/notifies';
import { getModal, getTempFileUploadPending } from '../../state/ui/selectors';
import { MODALS_UPLOADING_FILES } from '../../utils/enchancers/withFilesUploading';
import { uploadTempFileRequest } from '../../state/ui/actions';

const getMbOfBites = mb => (mb / 1024) / 1024;

/**
 *
 * @param onCloseUploadFilesModal {function}
 * @param onSetFilesContinue {function}
 * @param resetFilesIdList {function}
 * @param filesListIds {array}
 * @param availableFormats {array}
 * @param setFilesIdList {function}
 * @param formats {array}
 * @param maxSize {number}
 * @returns {JSX.Element}
 * @constructor
 */

export const AttachFilesModal = ({
  onCloseUploadFilesModal, onSetFilesContinue, resetFilesIdList,
  filesListIds, availableFormats = AVAILABLE_FILE_FORMATS.DEFAULT,
  setFilesIdList, formats, maxSize,
}) => {
  const { t } = useTranslation('common');

  const isOpen = useSelector(getModal)(MODALS_UPLOADING_FILES.FILES_UPLOADING_MODAL);
  const isPending = useSelector(getTempFileUploadPending);

  const dispatch = useDispatch();

  const uploadTempFile = useCallback(
    (data, callbacks) => { dispatch(uploadTempFileRequest(data, callbacks)); },
    [],
  );

  const [filesList, setFilesList] = useState([]);

  const fieldElementRef = useRef(null);
  const fieldElement = fieldElementRef.current;

  const loadingFiles = useMemo(() => []);

  const onUploadFiles = useCallback(() => {
    filesList.forEach((file, index) => {
      const formData = new FormData();
      if (file) {
        const { name, type, size } = file;
        const fileSize = getMbOfBites(size);
        if (fileSize > maxSize) {
          callNotification.error(`File size of ${name} more than ${size}. \n
         File size should not be large than ${maxSize}MB`);
        } else if (!includes(type, formats)) {
          callNotification.error(`File - ${name}, has incorrect file type`);
        } else {
          formData.append('file', file);
          uploadTempFile(formData, {
            callbacks: {
              success: ({ model }) => {
                setFilesIdList(loadingFiles.concat(model));
              },
              error: () => {
                // todo: find solution for safari (Not allow to using data transfer)
                const dataTransfer = new DataTransfer();

                formData.delete('file');
                Array.from(fieldElement.files)
                  .splice(index + 1, 1)
                  .map(fileItem => dataTransfer
                    .items
                    .add(fileItem));
                fieldElement.files = dataTransfer.files;
              },
            },
          });
        }
      }
    });
  }, [filesList, maxSize, formats, setFilesIdList, loadingFiles, uploadTempFile]);

  const onChange = (e) => {
    const chosenFiles = [...e.currentTarget.files];
    setFilesList(chosenFiles);
  };

  const prevFilesListRef = useRef(filesList);
  useEffect(() => {
    prevFilesListRef.current = filesList;
  }, [filesList]);
  const prevFilesList = prevFilesListRef.current;

  useEffect(() => {
    if (filesList !== prevFilesList) {
      onUploadFiles();
    }
  }, [filesList]);

  return (
    <ModalWindow
      isOpen={isOpen}
      onClose={onCloseUploadFilesModal}
      modalName="attachFiles"
      title={t('Upload file')}
      className="modal-window__attach-files"
    >
      <FormsCollection.FormWrapper
        handleSubmit={onSetFilesContinue}
        className="attach-files"
      >
        <FilesField
          pending={isPending}
          ref={fieldElementRef}
          onChange={onChange}
          availableFormats={availableFormats}
        />
        <div className="attach-files__uploaded-files">
          {
            filesListIds.map(item => (
              <FilesItem item={item} key={`item-file${item.filename}`} onDelete={() => resetFilesIdList([item.id])} />
            ))
          }
        </div>
        <div className="button-group">
          <ButtonsCollection.ButtonBrill
            className="button-brill--fill task-files__add-button  button--sm"
            type="submit"
          >
            {t('Upload')}
          </ButtonsCollection.ButtonBrill>
        </div>
      </FormsCollection.FormWrapper>
    </ModalWindow>
  );
};
