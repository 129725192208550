import React, { useMemo } from 'react';
import { ComingSoon } from './ComingSoon';
import './style.sass';

const MIN_VERSION_FOR_PRODUCTION = 1;
const CHECK_MODE = 'development';
const isDevelopMode = (process.env.NODE_ENV === CHECK_MODE);

/**
 *
 * @param children{string|number|array|JSX.Element}
 * @param title{string}
 * @param isAvailable{boolean}
 * @param config{number}
 * @returns {JSX.Element}
 * @constructor
 */

export const ModuleWrapper = ({
  children = '', title = '', isAvailable, config,
}) => {
  const checkIsAvailable = useMemo(() => {
    if (MIN_VERSION_FOR_PRODUCTION <= config?.version && isDevelopMode) {
      return true;
    } return isAvailable;
  }, [isAvailable, config]);

  return (
    <>
      {
        checkIsAvailable ? (children)
          : (<ComingSoon title={title} />)
      }
    </>
  );
};
