import { compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';

import {
  always, concat, inc, path, filter, last, when, omit, keys, endsWith, map, cond, T, equals,
} from 'ramda';
import { isNotEmpty, notEqual } from 'ramda-extension';
import CreatePipelineModal from './createPipelineModal';
import { uiActions, uiSelectors } from '../../../../state/ui';
import { pipelinesActions, pipelinesSelectors } from '../../../../state/pipelines';
import { PIPELINE_STATUSES, PIPELINE_TYPES } from '../../../../constants/crm';
import rulesWithFields from '../rules';

const mapStateToProps = state => ({
  isOpen: uiSelectors.getModal(state)('createPipelineModal'),
  isPending: pipelinesSelectors.createPipelinePendingRequest(state),
});

const valueInArray = val => [val];

const mapDispatchToProps = {
  closeModal: () => uiActions.closeModal('createPipelineModal'),
  createPipeline: pipelinesActions.addPipelineRequest,
};

const onAddNewStatusHandler = ({ statusesList, setStatusesList }) => compose(setStatusesList,
  concat(statusesList), valueInArray, inc, last, always(statusesList));

const onDeleteStatusHandler = ({ statusesList, setStatusesList }) => compose(
  when(isNotEmpty, setStatusesList),
  item => filter(notEqual(item), statusesList),
  Number,
  path(['target', 'dataset', 'index']),
);

const onCloseModalHandler = ({ setStatusesList, closeModal, resetForm }) => () => {
  closeModal();
  setStatusesList([0]);
  resetForm({});
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('statusesList', 'setStatusesList', [0]),
  withFormik({
    mapPropsToValues: () => ({}),
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: ({ statusesList }) => rulesWithFields(statusesList),
    handleSubmit: (formData, {
      resetForm,
      props: {
        closeModal,
        createPipeline,
        onSuccess,
        setStatusesList,
        statusesList,
      },
    }) => {
      const statuses = compose(
        map((item) => {
          const progressFormData = formData[`${item}_progress`] || 0;
          const progress = progressFormData > 10 ? 100 : progressFormData * 10;
          const type = cond([
            [equals(PIPELINE_STATUSES.WON), always(PIPELINE_TYPES.WON)],
            [equals(PIPELINE_STATUSES.LOST), always(PIPELINE_TYPES.LOST)],
            [T, always(PIPELINE_TYPES.IN_PROGRESS)],
          ])(progressFormData);
          return {
            title: formData[item],
            progress,
            type,
          };
        }),
        filter(item => statusesList.includes(+item)),
        filter(item => !endsWith('_progress', item)),
        keys,
        omit(['title']),
      )(formData);
      const data = {
        title: formData.title,
        statuses,
      };
      createPipeline(data, {
        callbacks: {
          success: () => {
            closeModal();
            resetForm({});
            setStatusesList([0]);
            if (onSuccess) {
              onSuccess();
            }
          },
        },
      });
    },
  }),
  withHandlers({
    onAddNeStatus: onAddNewStatusHandler,
    onDeleteStatus: onDeleteStatusHandler,
    onCloseModal: onCloseModalHandler,
  }),
);
export default enhance(CreatePipelineModal);
