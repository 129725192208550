import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ToggleButton, ButtonsCollection } from '../../../../../../../ui-kit';
import { activity } from '../../../../../../../constants';
import './style.sass';

const CardActivity = ({
  title,
  children,
  onExportButtonClick,
  onToggle,
  status,
  /* onExportButtonClick,
  exportable,
  exportKeys,
  isCurrentUser,
  type,
  isClosedHours,
  onToggleHours, */
}) => {
  const { t } = useTranslation();
  return (
  <div className="card-activity">
    <div className="card-activity__header">
      <div className="card-activity__row">
        <h3 className="card-activity__title">
          {title}
        </h3>
        {
          onToggle && (
            <div className="card-activity__toggle-container">
              <span className="card-activity__toggle-label">{t('Estimated')}</span>
              <ToggleButton name="toggle-hours" onChange={onToggle} status={status} className="card-activity__toggle-btn" />
              <span className="card-activity__toggle-label">{t('Spent')}</span>
            </div>
          )
        }
        {
          /* exportable  && (
            <ButtonsCollection.ButtonIcons title="Download" onClick={onExportButtonClick(isCurrentUser, exportKeys)}>
              <span className="icon-download" />
            </ButtonsCollection.ButtonIcons>
          ) */
        }
      </div>
    </div>
    <div className="card-activity__body">
      {children}
    </div>
  </div>
);
}
CardActivity.propTypes = {
  type: PropTypes.number,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onExportButtonClick: PropTypes.func.isRequired,
  exportable: PropTypes.bool.isRequired,
  exportKeys: PropTypes.arrayOf(PropTypes.string),
  isCurrentUser: PropTypes.bool,
  isClosedHours: PropTypes.bool,
  onToggleHours: PropTypes.func,
};

CardActivity.defaultProps = {
  exportKeys: [],
  isCurrentUser: false,
  isClosedHours: false,
  onToggleHours: () => {},
  type: activity.TYPE_CARD_ACTIVITY.DEFAULT,
};

export default CardActivity;
