import { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import { stringify, parse } from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { saveUrlFilter } from '../../state/ui/actions';
import { getFiltersByName } from '../../state/ui/selectors';


export const useUrlParams = ({
  params = {},
  saveFilterByName,
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [currentParams, setCurrentParams] = useState(params);

  const filterFromStore = useSelector(getFiltersByName)(saveFilterByName);

  const stringifyParams = useCallback(
    paramsToStrinify => `?${stringify(paramsToStrinify, { arrayFormat: 'repeat' })}`, [],
  );
  const parseParams = useCallback(
    paramsToParse => parse(paramsToParse.replace('?', '')), [],
  );

  const setUrlParam = useCallback((newParam = {}) => {
    const newKey = Object.keys(newParam)[0];
    if (newParam[newKey] !== currentParams[newKey]) {
      const refreshedParams = Object.assign({}, currentParams, newParam);
      setCurrentParams(refreshedParams);
    }
  }, [currentParams]);

  const setUrlParams = useCallback((newParams = {}) => {
    const paramsToRefresh = {};
    Object.entries(newParams).forEach(([key, newValue]) => {
      if (currentParams[key] !== newValue) paramsToRefresh[key] = newValue;
    });
    if (Object.keys(paramsToRefresh).length > 0) {
      const paramsToSet = Object.assign({}, currentParams, paramsToRefresh);
      setCurrentParams(paramsToSet);
    }
  }, [currentParams]);

  const resetUrlParams = useCallback((paramsToReset = []) => {
    if (paramsToReset.length === 0) return;
    const refreshedParams = {};
    Object.entries(currentParams).forEach(([key, value]) => {
      if (!paramsToReset.includes(key)) refreshedParams[key] = value;
    });
    setCurrentParams(refreshedParams);
  }, [currentParams]);

  const getUrlParams = useCallback((paramsToGet = []) => {
    const selectedParams = {};
    paramsToGet.forEach((key) => {
      if (currentParams[key]) selectedParams[key] = currentParams[key];
    });
    return selectedParams;
  }, [currentParams]);

  const getUrlParam = useCallback(
    (paramToGet = '', defVal = null) => (currentParams[paramToGet] || defVal),
    [currentParams],
  );

  useEffect(() => {
    if (Object.keys(filterFromStore).length > 0) {
      setCurrentParams(filterFromStore);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(currentParams).length > 0) {
      const pathToGo = stringifyParams(currentParams);
      history.push(pathToGo);
      if (saveFilterByName) {
        dispatch(saveUrlFilter({ [saveFilterByName]: currentParams }));
      }
    }
  }, [currentParams]);

  useEffect(() => {
    const pathToGo = stringifyParams(currentParams);
    if (location.search && location.search !== pathToGo) {
      setCurrentParams(parseParams(location.search));
    }
  }, [location.search]);

  return {
    setUrlParams,
    setUrlParam,
    resetUrlParams,
    getUrlParams,
    getUrlParam,
  };
};
