import React from 'react';
import PropTypes from 'prop-types';

import LabelTimeMessage from '../labelTimeMessage';

const DateLabel = ({ content }) => (
  <>
    <div className="row text-align--center time-label__vertical-break-line">
      <span className="display--inline-block">
        <LabelTimeMessage>
          {content}
        </LabelTimeMessage>
      </span>
    </div>
  </>
);

DateLabel.propTypes = {
  content: PropTypes.string.isRequired,
};

export default DateLabel;
