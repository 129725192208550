import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import DragDropArea from './dragDropArea';
import { preloaderWhileLoading } from '../../../../utils/enchancers';
import { PRELOADER_DIMENSION } from '../../../../constants/ui';
import { openModal } from '../../../../state/ui/actions';

const mapDispatchToProps = {
  openModal,
};


const enhance = compose(
  pure,
  connect(null, mapDispatchToProps),
  withTranslation('tasks'),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.MIDDLE,
    alignContainerCenter: true,
    isLoading: () => false,
    onAction: ({ isLoading }) => isLoading,
  }),
);
export default enhance(DragDropArea);
