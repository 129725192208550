import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConfirmModal } from '../../../../../../../ui-kit';
import { getFullName } from '../../../../../../../utils/helpers/userHelpers';
import { settingUsersActions, settingUsersSelectors } from '../../../../../../../state/settingUsers';

/**
 * @param isOpenModal {boolean}
 * @param setIsOpenModal {function}
 * @param selectedUser {object}
 * @param pagination {object}
 * @returns {JSX.Element}
 * @constructor
 */

export const DeleteUserModal = ({
  isOpenModal,
  setIsOpenModal,
  selectedUser,
  pagination,
}) => {
  const dispatch = useDispatch();
  const isPending = useSelector(settingUsersSelectors.getDeleteUserPending);
  const deleteUserRequest = useCallback((value, meta) => {
    dispatch(settingUsersActions.deleteSettingUserRequest(value, meta));
  });
  const onClose = useCallback(() => {
    setIsOpenModal(false);
  });

  const onDelete = useCallback(() => {
    deleteUserRequest({ userId: selectedUser.id }, {
      callbacks: { success: onClose },
      ...pagination,
    });
  });

  return (
    <ConfirmModal
      isOpen={isOpenModal}
      isPending={isPending}
      actionTitle="Delete"
      onAction={onDelete}
      onClose={onClose}
      title="Delete user"
    >
      Are you sure you want delete
      <b>{getFullName(selectedUser) || selectedUser.username}</b>
      {' '}
      {getFullName(selectedUser) && (
        <small>{' '}({selectedUser.username}){' '}</small>
      )}
      user?
    </ConfirmModal>
  );
};
