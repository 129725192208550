import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CRUD_NODE_CONFIG_ACTIONS } from '@bp/constants';
import { bpActions, bpSelectors } from '@bp/store/bpModule';

export const useFormView = ({ onSubmit, initialValues }) => {
  const dispatch = useDispatch();
  const selectedEvent = useSelector(bpSelectors.getSelectedEvent);
  const variables = useSelector(bpSelectors.getVariables);
  const { event } = useSelector(bpSelectors.getCrudNodesConfig);
  const [formValues, setFormValues] = useState({});
  const crudNodesConfig = useSelector(bpSelectors.getCrudNodesConfig);

  const { fields, options } = selectedEvent;

  const fieldsList = crudNodesConfig.event === 'TRIGGER' ? options : fields;

  const value = {
    value: selectedEvent?.id ?? null,
    label: selectedEvent?.title ?? null,
  };

  const onEventChange = useCallback(
    (params) => {
      dispatch(bpActions.selectEventById(params.value));
    },
    [dispatch],
  );

  const { values, errors, touched, handleSubmit, handleChange, setFieldValue, handleBlur } =
    useFormik({
      initialValues: { ...formValues, ...variables },
      onSubmit: (submitValues, { resetForm }) => {
        onSubmit(submitValues);
        dispatch(bpActions.setCrudNodesConfig(CRUD_NODE_CONFIG_ACTIONS.nothing));
        dispatch(bpActions.cleanFormAfterSubmit());
        resetForm();
      },
      enableReinitialize: true,
    });

  useEffect(() => {
    if (Object.keys(values).length) {
      setFormValues(values);
    } else {
      setFormValues(initialValues);
    }
  }, [variables]);

  return {
    fieldsList,
    value,
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
    onEventChange,
    event,
  };
};
