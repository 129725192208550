import React from 'react';
import classNames from 'classnames';
import './style.sass';

/**
 *
 * @param label {string}
 * @param id {string}
 * @param name {string}
 * @param className {string}
 * @param disabled {boolean}
 * @returns {JSX.Element}
 * @constructor
 */

export const Checkbox = ({
  label = '',
  id,
  name,
  className = '',
  disabled = false,
}) => (
  <div
    className={classNames('checkbox-field', disabled && 'checkbox-field--disabled', className)}
  >
    <input checked className="checkbox-field__input" type="checkbox" disabled={disabled} name={name} id={id} />
    <label htmlFor={id} className="checkbox-field__pseudo-input" />
    <label className="checkbox-field__label text" htmlFor={id}>{label}</label>
  </div>
);
