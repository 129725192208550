import React from 'react';
import PropTypes from 'prop-types';

import { CommentsTextArea, CommentsHistory, DeleteCommentModal } from './components';

import './style.sass';

const Comments = ({ onSendComment, selectedCommentId }) => (
  <div className="task-comments">
    <div className="task-comments__container">
      <CommentsHistory />
      <CommentsTextArea onSendComment={onSendComment} />
      <DeleteCommentModal selectedCommentId={selectedCommentId} />
    </div>
  </div>
);

Comments.propTypes = {
  selectedCommentId: PropTypes.number,
  onSendComment: PropTypes.func.isRequired,
};

Comments.defaultProps = {
  selectedCommentId: 0,
};

export default Comments;
