import React, { memo } from 'react';
import { Controller } from 'react-hook-form';
import { FormsCollection } from '../../../../../ui-kit';
import { DEFALUT_FIELD_VALUE } from '../../constants/people';

/**
 *
 * @param id {string}
 * @param title {string}
 * @param placeholder {string}
 * @param disabled {boolean}
 * @param control {object}
 * @returns {JSX.Element}
 * @constructor
 */

const ControlledField = ({
  id, title, placeholder, disabled = false, control,
}) => (
  <div
    className="userForm__col"
    key={`clients-fields-col-${id}`}
  >
    <label className="userForm__label" htmlFor={`userEdit-${title}`}>{title}</label>
    <Controller
      name={id}
      control={control}
      defaultValue={DEFALUT_FIELD_VALUE.EDIT}
      render={({
        field: {
          onBlur, onChange, value, name,
        },
        fieldState: {
          error,
        },
      }) => (
        <FormsCollection.Input
          placeholder={placeholder || title}
          error={error?.message}
          touched
          id={`userEdit-${title}`}
          disabled={disabled}
          onBlur={onBlur}
          onChange={onChange}
          value={value || ''}
          name={name}
        />
      )}
    />

  </div>
);
export default memo(ControlledField);
