import {
  compose, getContext, lifecycle, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  path, propOr, prop, map, values, equals, isEmpty, mergeLeft,
} from 'ramda';
import PropTypes from 'prop-types';

import moment from 'moment';
import InvoiceForm from './invoiceForm';
import { clientsActions } from '../../../../state/clients';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { INVOICES_TERMS } from '../../../../constants/crm';
import { projectsActions, projectsSelectors } from '../../../../state/projects';
import { uiActions } from '../../../../state/ui';
import { getNormalizeErrorObject } from '../../../../utils/helpers/requestHelpers';

const errorPageDataWhenGetClients = compose(mergeLeft({ href: '/' }),
  getNormalizeErrorObject);


const mapDispatchToProps = {
  getClientsRequest: clientsActions.getClientsRequest,
  getProjectsList: projectsActions.getProjectsListRequest,
  setErrorPage: uiActions.setErrorPage,
};

const mapStateToProps = state => ({
  projectsEntities: projectsSelectors.getProjects(state),
  projectsList: projectsSelectors.getProjectsList(state),
});

const getClientsAutocomplete = ({ getClientsRequest, setErrorPage }) => (fieldValue, callback) => {
  getClientsRequest({ name: fieldValue }, {
    actions: {
      error: compose(setErrorPage, errorPageDataWhenGetClients),
    },
    callbacks: {
      success: payload => callback(
        map(
          client => ({ label: getFullName(propOr({}, 'contact', client)), value: prop('id', client) }),
          values(path(['data', 'entities', 'clients'], payload)),
        ),
      ),
    },
  });
};


const setDueDateHandler = ({
  setFieldValue,
  values: formValues,
}) => (
  { value },
  customDate = null,
) => {
  const { END_OF_MONTH, END_OF_NEXT_MONTH } = INVOICES_TERMS;

  if (equals(value, END_OF_MONTH)) setFieldValue('due_date', moment(customDate || formValues.date).endOf('month').toDate());
  if (equals(value, END_OF_NEXT_MONTH)) setFieldValue('due_date', moment(customDate || formValues.date).add(1, 'month').endOf('month').toDate());
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common']),
  getContext({
    values: PropTypes.instanceOf(Object),
    defaultDueDateValues: PropTypes.instanceOf(Object),
    termsOptions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  }),
  withHandlers({
    getClientsAutocomplete,
    setDueDate: setDueDateHandler,
  }),
  withProps(({ values: formikValues, projectsEntities, projectsList }) => ({
    isCustomTerms: equals(formikValues.terms.value, INVOICES_TERMS.CUSTOM),
    projectsOption: formikValues.client_id && !isEmpty(projectsList) ? map(
      ({ title, id }) => ({ label: title, value: id }),
      map(id => projectsEntities[id], projectsList),
    ) : [],
    hasProjects: !isEmpty(projectsList),
  })),
  lifecycle({
    // componentDidMount() {
    //   const { getProjectsList } = this.props;
    //   getProjectsList();
    // },
    componentDidUpdate({ values: { client_id: prevClientId } }) {
      const {
        values: { client_id }, getProjectsList, setFieldValue, checkNoticeModal,
      } = this.props;
      if (!equals(prevClientId, client_id)) {
        getProjectsList({ client_id: client_id.value });
        checkNoticeModal();
        setFieldValue('project_id', null);
      }
    },
  }),
);

export default enhance(InvoiceForm);
