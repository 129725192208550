import React from 'react';
import PropTypes from 'prop-types';
import {
  ConfirmModal,
} from '../../../../ui-kit';
import { getFullName } from '../../../../utils/helpers/userHelpers';

const DeleteContactModal = ({
  isOpen,
  isPending,
  onClose,
  contact,
  onDelete,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Delete"
    onAction={onDelete}
    onClose={onClose}
    title="Delete contact"
  >
    Are you sure you want to delete
    <b>{getFullName(contact)}</b>
    contact?
  </ConfirmModal>
);

DeleteContactModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  contact: PropTypes.instanceOf(Object),
  onDelete: PropTypes.func.isRequired,
};

DeleteContactModal.defaultProps = {
  contact: null,
  isPending: false,
};

export default DeleteContactModal;
