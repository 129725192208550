/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { Filters } from '..';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import './style.sass';
import { MobileFiltersContainer, FormsCollection } from '../../../../ui-kit';
import { setFullUsernameAsLabel } from '../../../../utils/helpers/uiHelpers';

const FiltersRow = ({
  assigners, assignees, selectedAssigner, selectedAssignee, t,
  areMobileFiltersOpen, toggleMobileFiltersHandler, sprintsStatuses, selectedSprintsStatus,
}) => (
  <div className="tasks-filter">
    <div className="tasks-filter__sort-group">
      <FormsCollection.Search
        className="search-field--circle"
        placeholder={t('Search by task')}
        name="search-project"
        value=""
        onChange={() => {}}
        onKeyPress={() => {}}
        withoutControl
      />
      <MobileFiltersContainer
        onWidth={WINDOW_WIDTH.SMALL}
        title={t('Filter')}
        isOpen={areMobileFiltersOpen}
        onToggle={toggleMobileFiltersHandler}
      >
        <Filters
          selectedAssigner={selectedAssigner}
          selectedAssignee={selectedAssignee}
          selectedSprintsStatus={selectedSprintsStatus}
          sprintsStatuses={sprintsStatuses}
          assigners={setFullUsernameAsLabel(assigners)}
          assignees={setFullUsernameAsLabel(assignees)}
        />
      </MobileFiltersContainer>
    </div>
  </div>
);

FiltersRow.propTypes = {
  assigners: PropTypes.instanceOf(Object),
  assignees: PropTypes.instanceOf(Object),
  areMobileFiltersOpen: PropTypes.bool.isRequired,
  toggleMobileFiltersHandler: PropTypes.func.isRequired,
};

FiltersRow.defaultProps = {
  assigners: {},
  assignees: {},
};


export default FiltersRow;
