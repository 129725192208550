import axios from 'axios';
import { stringify } from 'qs';

const HTTP_GET = 'get';
const HTTP_POST = 'post';
const HTTP_PUT = 'put';
const HTTP_DELETE = 'delete';

function Axios({ baseUrl }) {
  let axsiosInstance;

  function createAxiosInstance(headers) {
    axsiosInstance = axios.create({
      baseUrl,
      headers,
    });
  }

  const call = ({ method }) => ({ route, params }) => {
    if (!axsiosInstance) {
      const token = localStorage.getItem('authToken');
      if (token) {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        createAxiosInstance(headers);
      } else {
        createAxiosInstance();
      }
    }
    return axsiosInstance({
      method,
      url: `${baseUrl}${route}`,
      [method !== HTTP_GET ? 'data' : 'params']: params,
      paramsSerializer: () => stringify(params, { encode: false }),
    });
  };

  return {
    delete: call({ method: HTTP_DELETE }),
    get: call({ method: HTTP_GET }),
    post: call({ method: HTTP_POST }),
    put: call({ method: HTTP_PUT }),
  };
}

export default Axios;
