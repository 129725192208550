const CHANNELS_YOU_BELONG_TO = 0;
const CHANNELS_YOU_CAN_JOIN = 1;

const SORT_BY_LETTERS_DOWN = 'asc';
const SORT_BY_LETTERS_UP = 'desc';

const CHANNELS_STATUS = {
  0: CHANNELS_YOU_BELONG_TO,
  1: CHANNELS_YOU_CAN_JOIN,
};

export default CHANNELS_STATUS;

export {
  CHANNELS_YOU_BELONG_TO,
  CHANNELS_YOU_CAN_JOIN,
  SORT_BY_LETTERS_DOWN,
  SORT_BY_LETTERS_UP,
};
