import { createActions } from 'redux-actions';
import { identity, prop, path } from 'ramda';

import { callNotification } from '../../utils/helpers/notifies';
import types from './types';
import { taskSchema, tasksListSchema } from './schema';
import { setUsersUpdateMiddleware } from '../../utils/helpers/stateHelpers';
import { getTaskActionParams } from '../../utils/helpers/taskHelpers/crudHelper';
import { getErrorMessage } from 'utils/helpers/requestHelpers';
import { projectActions } from '../project';
import { taskActions } from '../task';

 

export const {
  getTasksRequest,
  getMyTasksRequest,
  getMyTasksByProjectsRequest,
  getMyTasksSummaryRequest,
  addTaskRequest,
  deleteTaskRequest,
  updateTaskRequest,
  pinTaskRequest,
  unpinTaskRequest,
  setTasks,
  setTask,
  setMyTasks,
  setMyTasksSummary,
  deleteTask,
  setMyTasksByProjects,
  getMyProjectTasksRequest,
  setMyProjectTask,
} = createActions(
  {
    [types.GET_TASKS_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/projects/${id}/tasks`,
        selectorName: 'getTasksRequest',
        method: 'GET',
        params,
        schema: {
          rules: tasksListSchema,
          pathData: ['data'],
        },
        actions: {
          success: setTasks,
        },
        ...meta,
      }),
    ],
    [types.GET_MY_TASKS_REQUEST]: [
      identity,
      ({ ...params }, meta) => ({
        async: true,
        route: '/tasks',
        selectorName: 'getMyTasksRequest',
        method: 'GET',
        params,
        schema: {
          rules: tasksListSchema,
          pathData: ['data'],
        },
        actions: {
          success: setMyTasks,
        },
        ...meta,
      }),
    ],
    [types.GET_MY_TASKS_BY_PROJECTS_REQUEST]: [
      identity,
      ({ ...params }, meta) => ({
        async: true,
        route: '/tasks',
        selectorName: 'setMyTasksByProjects',
        method: 'GET',
        params,
        actions: {
          success: setMyTasksByProjects,
        },
        ...meta,
      }),
    ],
    [types.GET_MY_PROJECT_TASKS_REQUEST]: [
      identity,
      ({ ...params }, meta) => ({
        async: true,
        route: '/tasks',
        selectorName: 'setMyProjectTask',
        method: 'GET',
        params,
        actions: {
          success: setMyProjectTask,
        },
        ...meta,
      }),
    ],
    [types.GET_MY_TASKS_SUMMARY_REQUEST]: [
      identity,
      ({ ...params }, meta) => ({
        async: true,
        route: '/tasks',
        selectorName: 'getMyTasksSummaryRequest',
        method: 'GET',
        params,
        schema: {
          rules: tasksListSchema,
          pathData: ['data'],
        },
        actions: {
          success: setMyTasksSummary,
        },
        ...meta,
      }),
    ],
    [types.ADD_TASK_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: '/tasks',
        selectorName: 'addTaskRequest',
        method: 'POST',
        params,
        callbacks: {
          success: path(['callbacks', 'success'], meta),
        },
        actions: {
          success: action => setTask({ ...action, meta: { ...meta, isNewTask: true } }),
        },
        ...meta,
      }),
    ],
    [types.DELETE_TASK_REQUEST]: [
      identity,
      ({ taskId, ...params }, meta) => ({
        async: true,
        route: `/tasks/${taskId}`,
        selectorName: 'deleteTaskRequest',
        method: 'DELETE',
        params: getTaskActionParams(params),
        callbacks: path(['callbacks', 'success'], meta),
        actions: {
          success: action => deleteTask({ ...action, meta }),
          error: (error) => callNotification.error(getErrorMessage(error)),
        },
        ...meta,
      }),
    ],
    [types.UPDATE_TASK_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: `/tasks/${prop('id', params)}/`,
        params: { ...getTaskActionParams(params) },
        selectorName: 'updateTaskRequest',
        method: 'PUT',
        schema: {
          rules: taskSchema,
          pathData: ['task'],
        },
        actions: {
          success: prop('isUpdateTasksFiles', meta)
            ? () => taskActions.getTaskFilesRequest({
              taskId: params.id,
              project_id: params.project_id,
              limit: 10,
              offset: 0,
            }, { isNotUpdate: true })
            : setUsersUpdateMiddleware(setTask,
              {
                ...meta,
                multiPathEntities: [
                  ['data', 'entities', 'author'],
                  ['data', 'entities', 'assigneeUser'],
                  ['data', 'entities', 'watchers'],
                ],
              }),
          error: (error) => callNotification.error(getErrorMessage(error)),
        },
        ...meta,
      }),
    ],
    [types.PIN_TASK_REQUEST]: [
      identity,
      ({ projectId, taskId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/pinned-tasks/${taskId}`,
        selectorName: 'pinTaskRequest',
        method: 'POST',
        callbacks: path(['callbacks', 'success'], meta),
        params,
        actions: {
          success: action => projectActions.setPinTask({ ...action, meta }),
        },
        ...meta,
      }),
    ],
    [types.UNPIN_TASK_REQUEST]: [
      identity,
      ({ projectId, taskId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/pinned-tasks/${taskId}`,
        selectorName: 'pinTaskRequest',
        method: 'DELETE',
        callbacks: path(['callbacks', 'success'], meta),
        params,
        actions: {
          success: projectActions.resetPinTask,
        },
        ...meta,
      }),
    ],
  },
  types.SET_TASKS,
  types.SET_TASK,
  types.SET_MY_TASKS,
  types.SET_MY_TASKS_SUMMARY,
  types.DELETE_TASK,
  types.SET_MY_TASKS_BY_PROJECTS,
  types.SET_MY_PROJECT_TASK,
);
