import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  BrillMask,
  Grid,
  ButtonsCollection,
  DropDown,
} from '../../../../ui-kit';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';

const ContactsItem = ({
  isOpen, onClickContactItem, contact, t, onEdit, onDelete, customFieldEntities, customFields = [],
}) => (
  <Grid.Row className="contacts__table-item" onClick={onClickContactItem}>
    <div className="contacts__table-item-header contacts__table-row">
      <Grid.ColData className="contacts__table-item-col contacts__table-item-contact">
        <BrillMask asLink>
          <Avatar src={getImageUrl(contact.avatar)} alt={getFullName(contact)} />
        </BrillMask>
        <div className="contacts__table-item-name">
          {getFullName(contact)}
        </div>
      </Grid.ColData>
      <Grid.ColData className="contacts__table-item-col contacts__table-item-related">
        {
          contact.company_url ? (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a href={contact.company_url} target="_blank" className="text--cut">{contact.company_name}</a>
          ) : (
            <span className="text--cut">{contact.company_name}</span>
          )
        }
      </Grid.ColData>
      <Grid.ColData className="contacts__table-item-col contacts__table-item-controls">
        <DropDown
          className="controls-group-task"
          name="controls-group-task"
          label={(
            <ButtonsCollection.ButtonIcons
              className="contacts__table-item-controls-button-task"
              title="Controls for contact"
            >
              <span className="contacts__table-item-control-icon icon-settings-vertical">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
              </span>
            </ButtonsCollection.ButtonIcons>
          )}
          list={
            [
              {
                id: 0,
                onClick: onEdit,
                label: (
                  <>
                    <span className="agenda-task-control__icon icon-pencil-2" />
                    <span className="agenda-task-control__text">{t('Edit')}</span>
                  </>
                ),
              },
              {
                id: 2,
                onClick: onDelete,
                label: (
                  <>
                    <span className="agenda-task-control__icon icon-delete-icon" />
                    <span className="agenda-task-control__text">{t('Delete')}</span>
                  </>
                ),
              },
            ]
          }
        />
      </Grid.ColData>
    </div>
    {
      isOpen && (
        <div className="contacts__table-item-body">
          <div className="contacts__table-item-fields">
            <div className="contacts__table-item-field">
              <div className="contacts__table-item-field-title">
                {t('Email')}:
              </div>
              <div className="contacts__table-item-field-val">
                {contact.email || t('N/A')}
              </div>
            </div>
            <div className="contacts__table-item-field">
              <div className="contacts__table-item-field-title">
                {t('Phone number')}:
              </div>
              <div className="contacts__table-item-field-val">
                {contact.phone || t('N/A')}
              </div>
            </div>
            {customFieldEntities && customFields.map(({ field_id, value }) => (
              <div className="contacts__table-item-field">
                <div className="contacts__table-item-field-title">
                  {customFieldEntities[field_id].title}:
                </div>
                <div className="contacts__table-item-field-val">
                  {value || t('N/A')}
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    }
  </Grid.Row>
);

ContactsItem.propTypes = {
  onClickContactItem: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  contact: PropTypes.shape({}).isRequired,
  customFieldEntities: PropTypes.shape({}).isRequired,
  customFields: PropTypes.arrayOf({}).isRequired,
};

export default ContactsItem;
