import React from 'react';
import PropTypes from 'prop-types';
import { keys } from 'ramda';

import { RightSideBar } from '../../../../../../newContainers';
import { SummaryCard } from '../../../../../../ui-kit';
import { INVOICES_STATUSES } from '../../../../../../constants/crm';

import './style.sass';

const RightSideBarInvoicesContainer = ({
  t, summary,
}) => (
  <>
    <RightSideBar className="right-side-bar--projects" title={t('Invoices statuses')}>
      <div className="invoices-summary__wrapper">
        {
          keys(summary).map(key => (
            <SummaryCard
              count={summary[key]}
              title={INVOICES_STATUSES[key]}
              className="invoices-summary__item"
            />
          ))
        }
      </div>
    </RightSideBar>
  </>
);

RightSideBarInvoicesContainer.propTypes = {
  windowWidth: PropTypes.number.isRequired,
  activeTabTitle: PropTypes.string,
  t: PropTypes.func.isRequired,
  setActiveTabTitle: PropTypes.func.isRequired,
  summary: PropTypes.instanceOf(Object),
};
RightSideBarInvoicesContainer.defaultProps = {
  activeTabTitle: '',
};

export default RightSideBarInvoicesContainer;
