import React from 'react';

/**
 *
 * @param data {object}
 * @returns {JSX.Element}
 * @constructor
 */

const MultiValueLabel = ({ data }) => {
  const { name, label } = data;
  return (
    <span>
      { name || label }
    </span>
  );
};

export default MultiValueLabel;
