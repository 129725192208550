import { handleActions } from 'redux-actions';
import types from './types';
import { mergeDeep, mergeIn } from '../../utils/helpers/stateHelpers';

const reducer = handleActions({
  [types.LOGOUT]: () => {},
  [types.SET_AUTHENTICATED]: mergeIn(action => ({ authenticated: action.payload })),
  [types.SET_USER_IS_VALID]: mergeDeep(action => action.payload),
  [types.SET_USER]: mergeDeep(({ payload: { user } }) => ({ user })),
  [types.SET_USER_IS_VALID_FLAG]: mergeDeep(() => ({ isValidUser: true })),
}, {
  isValidUser: false,
});

export default reducer;
