import * as yup from 'yup';
import * as translateHelpers from '../../../utils/helpers/translateHelpers';
import { symbolsValidator } from '../../../utils/helpers/stringHelpers/common';


const rules = yup.object().shape({
  first_name: yup
    .string()
    .required({
      field: 'first_name',
      message: 'First name is required',
    })
    .test({
      test: symbolsValidator,
      message: {
        field: 'first_name',
        message: 'Incorrect values',
        params: {
          key: translateHelpers.t('common', 'Incorrect values'),
        },
      },
    }),
  last_name: yup
    .string()
    .required({
      field: 'last_name',
      message: 'Last name is required',
      params: {
        key: translateHelpers.t('common', 'Sure Name'),
      },
    })
    .test({
      test: symbolsValidator,
      message: {
        field: 'last_name',
        message: 'Incorrect values',
        params: {
          key: translateHelpers.t('common', 'Incorrect values'),
        },
      },
    }),
  username: yup
    .string()
    .required({
      field: 'username',
      message: 'Username is required',
      params: {
        key: translateHelpers.t('common', 'Username'),
      },
    })
    .test({
      test: symbolsValidator,
      message: {
        field: 'username',
        message: 'Incorrect values',
        params: {
          key: translateHelpers.t('common', 'Incorrect values'),
        },
      },
    }),
});

export default rules;
