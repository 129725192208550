import React from 'react';
import PropTypes from 'prop-types';
import { equals } from 'ramda';

import NotesItem from './components';
import './style.sass';

const NotesList = ({
  activeTab,
  changeActiveTabStateHandler,
  notes,
}) => (
  <div className="notes-list">
    <div className="notes-list__table">
      <div className="notes-list__table-head">
        <div className="notes-list__table-row">
          <div className="notes-list__table-col"><b>Title</b></div>
          <div className="notes-list__table-col notes-list__table-col--description"><b>Description</b></div>
          <div className="notes-list__table-col" />
        </div>
      </div>
      <div className="notes-list__table-body">
        {
        notes && notes.length ? (
          notes.map(noteId => (
            <NotesItem
              key={noteId}
              isOpen={equals(noteId, activeTab)}
              noteId={noteId}
              toggleIsOpen={changeActiveTabStateHandler}
            />
          ))
        ) : <div className="notes-list__empty">There are no notes yet</div>
      }
      </div>
    </div>
  </div>
);

NotesList.propTypes = {
  activeTab: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  notes: PropTypes.objectOf(PropTypes.number).isRequired,
  changeActiveTabStateHandler: PropTypes.func,
};

NotesList.defaultProps = {
  activeTab: '',
  changeActiveTabStateHandler: () => {},
};

export default NotesList;
