import { handleActions } from 'redux-actions';

import { mergeByProp } from '@/utils/helpers/ramdaStateHelpers';
import { mergeDeep } from '@/utils/helpers/stateHelpers';
import { NODE_EVENTS } from '@bp/constants/common';
import { NODES_ACTIONS_CONST } from '@bp/constants/crud';
import {
  clearDataBeforeClosePageHelper,
  clearFormAfterSubmitHelper,
  deleteBusinessProcessHelper,
  deleteBusinessProcessNodeHelper,
  editBusinessProcessHelper,
  editBusinessProcessNodeHelper,
  selectEventByIdHelper,
  selectNodeByIdHelper,
  selectVariableKeyHelper,
  setBusinessProcessHelper,
  setBusinessProcessLogs,
  setBusinessProcessNodeHHelper,
  setNewVariableHelper,
} from './constants';
import types from './types';

const initialStore = {
  businessProcess: {
    data: [],
    selected: null,
  },
  nodes: {
    data: [],
    selected: null,
    selectedVariableKey: null,
    variables: {},
  },
  events: {
    data: [],
    selected: null,
  },
  crudNodesConfig: {
    mode: NODES_ACTIONS_CONST.NOTHING,
    event: NODE_EVENTS.TRIGGER,
  },
  logs: {
    data: [],
  },
};

const reducer = handleActions(
  {
    [types.SET_BUSINESS_PROCESSES]: mergeByProp('businessProcess', ({ payload: { data } }) => ({
      data,
    })),
    [types.SELECT_BUSINESS_PROCESS]: mergeByProp('businessProcess', ({ payload }) => ({
      selected: payload,
    })),
    [types.SET_CRUD_NODES_CONFIG]: mergeByProp('crudNodesConfig', ({ payload }) => ({
      ...payload,
    })),
    [types.SET_BUSINESS_PROCESS_EVENTS]: mergeByProp('events', ({ payload: data }) => ({
      data: Object.values(data),
    })),
    [types.SET_BUSINESS_PROCESS]: mergeDeep(setBusinessProcessHelper),
    [types.DELETE_BUSINESS_PROCESS]: mergeByProp('businessProcess', deleteBusinessProcessHelper),
    [types.EDIT_BUSINESS_PROCESS]: mergeByProp('businessProcess', editBusinessProcessHelper),
    [types.SELECT_EVENT_BY_ID]: mergeByProp('events', selectEventByIdHelper),
    [types.SELECT_NODE_BY_ID]: mergeByProp('nodes', selectNodeByIdHelper),
    [types.SET_BUSINESS_PROCESS_NODE]: mergeByProp('nodes', setBusinessProcessNodeHHelper),
    [types.DELETE_BUSINESS_PROCESS_NODE]: mergeByProp('nodes', deleteBusinessProcessNodeHelper),
    [types.EDIT_BUSINESS_PROCESS_NODE]: mergeByProp('nodes', editBusinessProcessNodeHelper),
    [types.CLEAR_DATA_BEFORE_CLOSE_PAGE]: mergeDeep(clearDataBeforeClosePageHelper),
    [types.SELECT_VARIABLE_KEY]: mergeByProp('nodes', selectVariableKeyHelper),
    [types.SET_NEW_VARIABLE]: mergeByProp('nodes', setNewVariableHelper),
    [types.CLEAN_FORM_AFTER_SUBMIT]: mergeByProp('nodes', clearFormAfterSubmitHelper),
    [types.SET_BUSINESS_PROCESS_LOGS]: mergeByProp('logs', setBusinessProcessLogs),
  },
  initialStore,
);

export default reducer;
