import React from 'react';
import PropTypes from 'prop-types';
import { DropDown } from '../../../../../ui-kit';
import { POSITIONS, STATUSES } from '../../constants';

const HrTableFilters = ({
  onSelectPosition, onSelectStatus, selectedPosition,
  selectedStatus, positions, statuses,
}) => (
  <div className="HrTableFilters">
    <DropDown
      label="Position: "
      name="positionFilter"
      placeholder="All"
      list={positions}
      currentItem={Number(selectedPosition)}
      onChange={onSelectPosition}
    />

    <DropDown
      label="Status: "
      name="statusFiler"
      placeholder="All"
      list={statuses}
      currentItem={Number(selectedStatus)}
      onChange={onSelectStatus}
    />
  </div>
);

HrTableFilters.propTypes = {
  onSelectPosition: PropTypes.func.isRequired,
  onSelectStatus: PropTypes.func.isRequired,
  selectedPosition: PropTypes.number,
  selectedStatus: PropTypes.number,
  statuses: PropTypes.instanceOf(Array),
  positions: PropTypes.instanceOf(Array),
};
HrTableFilters.defaultProps = {
  statuses: [{ label: 'All' }, ...STATUSES],
  positions: [{ label: 'All' }, ...POSITIONS],
  selectedPosition: null,
  selectedStatus: null,
};

export default HrTableFilters;
