import React from 'react';
import { FieldArray, Field } from 'formik';
import PropTypes from 'prop-types';
import { ButtonsCollection, PermissionElement } from '../../../../../ui-kit';
import './styles.scss';
import { ACTIONS, MODULES } from '../../constants/permissions';

const autosize = ({ target }) => {
  const el = target;
  setTimeout(() => {
    el.style.cssText = 'height:auto;';
    el.style.cssText = `height: ${el.scrollHeight}px`;
  }, 0);
};

const HrCustomTextarea = ({ field, form, ...props }) => (
  <textarea
    rows="1"
    className="HrCreateComment_textarea"
    onKeyDown={autosize}
    onFocus={autosize}
    {...field}
    {...props}
  />
);

const HrCreateComment = ({ comments, name, onToggleFields }) => (
  <FieldArray
    name={name}
    render={arrayHelpers => (
      <div className="HrCreateComment">
        <PermissionElement action={ACTIONS.READ} module={MODULES.WHR_COMMENTS}>
          {comments && comments.length > 0 ? (
            comments.map((comment, index) => (
            // eslint-disable-next-line react/no-array-index-key
              <div key={`hrComment-${index}`}>
                <PermissionElement action={ACTIONS.CREATE} module={MODULES.WHR_COMMENTS}>
                  {index === 0 && (
                  <ButtonsCollection.ButtonBrill
                    className="HrCreateForm_col__field-btn HrCreateComment_addBtn HrCreateComment_addBtn--top"
                    onClick={() => {
                      arrayHelpers.insert(index, { comment: '' });
                      onToggleFields({ action: 'insert', index });
                    }}
                    title="Add new comment"
                    type="button"
                  >
                    <span>Add comment</span>
                  </ButtonsCollection.ButtonBrill>
                  )}
                </PermissionElement>
                <div className="flex-row m-b-10">
                  <Field
                    value={comments[index].comment}
                    component={HrCustomTextarea}
                    name={`${name}.${index}.comment`}
                    placeholder="Type a message"
                  />

                  <PermissionElement action={ACTIONS.DELETE} module={MODULES.WHR_COMMENTS}>
                    <ButtonsCollection.ButtonIcons
                      onClick={() => {
                        arrayHelpers.remove(index);
                        onToggleFields({ action: 'remove', index });
                      }}
                      title="Delete comment"
                    >
                      <span className="icon-trash" />
                    </ButtonsCollection.ButtonIcons>
                  </PermissionElement>
                </div>
              </div>
            ))
          ) : (
            <ButtonsCollection.ButtonBrill
              className="HrCreateForm_col__field-btn HrCreateComment_addBtn"
              onClick={() => {
                arrayHelpers.push({ comment: '' });
                onToggleFields({ action: 'push', index: 0 });
              }}
              title="Add new comment"
              type="button"
            >
              <span>Add comment</span>
            </ButtonsCollection.ButtonBrill>
          )}
        </PermissionElement>
      </div>
    )}
  />
);

HrCreateComment.propTypes = {
  comments: PropTypes.instanceOf(Array),
  onToggleFields: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};
HrCreateComment.defaultProps = {
  comments: [],
  entities: {},
};

export default HrCreateComment;
