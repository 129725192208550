import { createSelector } from 'reselect';
import {
  identity, memoizeWith, path, pathOr, propOr,
} from 'ramda';

const createClientSubDataSelector = key => createSelector(
  pathOr([], ['clients', key, 'result']),
  entities => memoizeWith(identity, clientId => propOr([], clientId, entities)),
);

const createClientSubDataEntitySelector = key => createSelector(
  path(['clients', key, 'entities']),
  entities => memoizeWith(identity, id => propOr(null, id, entities)),
);

const getClientsListSelector = createSelector(
  pathOr([], ['clients', 'summary', 'result']),
  identity,
);

const getClientsEntitiesSelector = createSelector(
  pathOr([], ['clients', 'summary', 'entities']),
  identity,
);

const getClientsCountSelector = createSelector(
  pathOr([], ['clients', 'summary', 'count']),
  identity,
);

const getClientsOffsetSelector = createSelector(
  pathOr([], ['clients', 'summary', 'offset']),
  identity,
);

const getClientNotesCountSelector = createSelector(pathOr(0, ['clients', 'clientsNotes', 'count']), identity);
const getClientContactsCountSelector = createSelector(pathOr(0, ['clients', 'clientsContacts', 'count']), identity);

const getClientsByIdList = clients => memoizeWith(
  identity, clientId => pathOr(null, [clientId], clients),
);

const getClientById = createSelector(
  getClientsEntitiesSelector,
  getClientsByIdList,
);

const getClientsPendingRequest = createSelector(pathOr(false, ['pending', 'getClientsRequest']), identity);

const getPinnedClientsList = createSelector(
  pathOr([], ['clients', 'pinnedClients', 'result']),
  identity,
);

const getPinnedClientsEntitiesSelector = createSelector(
  pathOr({}, ['clients', 'pinnedClients', 'entities']),
  identity,
);

const getPinnedClientById = createSelector(
  getPinnedClientsEntitiesSelector,
  getClientsByIdList,
);

const getClientsCount = createSelector(
  pathOr(0, ['clients', 'summary', 'count']),
  identity,
);

const getPinnedClientsPendingRequest = createSelector(pathOr(false, ['pending', 'getPinnedClientsRequest']), identity);

const getClientTasksSelector = createClientSubDataSelector('clientsTasks');
const getClientContactsSelector = createClientSubDataSelector('clientsContacts');
const getClientContactSelector = createClientSubDataEntitySelector('clientsContacts');
const getClientNotesSelector = createClientSubDataSelector('clientsNotes');
const getClientNoteSelector = createClientSubDataEntitySelector('clientsNotes');

const getClientTaskSelector = createClientSubDataEntitySelector('clientsTasks');
const getClientTasksPendingRequest = createSelector(pathOr(false, ['pending', 'getClientTasksRequest']), identity);

const getClientActionPending = createSelector(
  pathOr(false, ['pending']),
  pendingInfo => memoizeWith(identity, pending => propOr(false, pending, pendingInfo)),
);

const getClientLogsSelector = createSelector(
  path(['clients', 'clientLogs']),
  clientLogs => memoizeWith(identity, clientId => propOr({}, clientId, clientLogs)),
);

const getClientProductsSelector = createClientSubDataSelector('clientsProducts');
const getClientProductSelector = createClientSubDataEntitySelector('clientsProducts');
const getClientSelector = createSelector(
  path(['clients', 'client']),
  identity,
);

export {
  getClientsListSelector,
  getClientsEntitiesSelector,
  getClientsCountSelector,
  getClientById,
  getClientsPendingRequest,
  getPinnedClientsList,
  getPinnedClientsEntitiesSelector,
  getPinnedClientById,
  getClientsCount,
  getPinnedClientsPendingRequest,
  getClientTaskSelector,
  getClientTasksSelector,
  getClientTasksPendingRequest,
  getClientContactsSelector,
  getClientActionPending,
  getClientContactSelector,
  getClientNotesSelector,
  getClientNoteSelector,
  getClientLogsSelector,
  getClientProductsSelector,
  getClientProductSelector,
  getClientSelector,
  getClientNotesCountSelector,
  getClientContactsCountSelector,
  getClientsOffsetSelector,
};
