import React from 'react';
import { RightSideBar } from '@/newContainers';
import { ScrollWrapper, TabWrapper } from '@/ui-kit';
import { CreateNode, EditNode } from '@bp/components';
import { NODES_ACTIONS_CONST } from '@bp/constants/crud';
import { capitalizeFirstLetter } from '@bp/utils';
import { useProcessRightSideBar } from './useProcessRightSideBar';

export const ProcessRightSideBar = () => {
  const { crudNodesConfig, onToggleRightSideBar } = useProcessRightSideBar();
  return (
    <RightSideBar
      onToggleMiddleware={onToggleRightSideBar}
      className="right-side-bar--bp"
      title="Process configuration"
    >
      <ScrollWrapper>
        {crudNodesConfig.mode !== NODES_ACTIONS_CONST.NOTHING && (
          <TabWrapper
            defaultStatusTab
            className="pieceOfProcess__actionTabWrapper"
            title={
              <h2 className="title">
                {capitalizeFirstLetter(crudNodesConfig.mode)}{' '}
                {capitalizeFirstLetter(crudNodesConfig.event)}
              </h2>
            }
          >
            {
              {
                [NODES_ACTIONS_CONST.CREATE]: <CreateNode />,
                [NODES_ACTIONS_CONST.EDIT]: <EditNode />,
              }[crudNodesConfig.mode]
            }
          </TabWrapper>
        )}
      </ScrollWrapper>
    </RightSideBar>
  );
};
