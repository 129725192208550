/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';

const tasksListSchema = [new schema.Entity('items')];

const category = new schema.Entity(
  'category',
);

const assigneeUser = new schema.Entity(
  'assigneeUser',
);

const author = new schema.Entity(
  'author',
);

const watchers = new schema.Entity(
  'watchers',
);

const taskSchema = new schema.Entity('task', {
  category,
  assigneeUser,
  author,
  watchers: [watchers],
});

export {
  category,
  watchers,
  author,
  assigneeUser,
  taskSchema,
  tasksListSchema,
};
