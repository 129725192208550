import React from 'react';
import PropTypes from 'prop-types';

import { ScrollWrapper } from '../../ui-kit';
import { FiltersRow, ProjectsAsList, ProjectsAsBrill } from './components';
import { STYLE_VIEW } from '../../constants/projects';
import { WINDOW_WIDTH } from '../../constants/ui';
import './style.sass';

const AllProjects = ({
  changeCurrentViewStyle,
  viewStyle,
  searchName,
  setSearchProjectsName,
  windowWidth,
  data,
  sort,
  order,
  onSortBy,
  count,
  gridName,
  projectsListPending,
  isLoadingMore,
  pagination,
  onScroll,
  onSearch,
  setProjectsFilter,
  projectsFilter,
  onPath,
}) => (
  <div className={`projects ${viewStyle === STYLE_VIEW.CELL ? 'project--brill' : 'project--list'}`}>
    <ScrollWrapper
      autoHide
    >
      <FiltersRow
        handlerStatusVisible={changeCurrentViewStyle}
        viewStyle={viewStyle}
        setProjectsFilter={setProjectsFilter}
        projectsFilter={projectsFilter}
        onSortBy={onSortBy}
        order={order}
        sort={sort}
        onSearch={onSearch}
        searchName={searchName}
        setSearchProjectsName={setSearchProjectsName}
      />
      {viewStyle === STYLE_VIEW.CELL && windowWidth > WINDOW_WIDTH.SMALL
        ? (
          <ProjectsAsBrill
            onScroll={onScroll}
            searchName={searchName}
            isLoadingMore={isLoadingMore}
            onSortBy={onSortBy}
            isLoading={projectsListPending}
            gridName={gridName}
            data={data}
          />
        )
        : (
          <ProjectsAsList
            onPath={onPath}
            gridName={gridName}
            pagination={pagination}
            onSortBy={onSortBy}
            sort={sort}
            order={order}
            isLoading={projectsListPending}
            count={count}
            data={data}
            searchName={searchName}
          />
        )}
    </ScrollWrapper>
  </div>
);

AllProjects.propTypes = {
  changeCurrentViewStyle: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  sort: PropTypes.string,
  isLoadingMore: PropTypes.bool.isRequired,
  order: PropTypes.string,
  gridName: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  onSearch: PropTypes.func.isRequired,
  onScroll: PropTypes.func.isRequired,
  onSortBy: PropTypes.func.isRequired,
  projectsListPending: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
  }).isRequired,
  searchName: PropTypes.string.isRequired,
  setSearchProjectsName: PropTypes.func.isRequired,
  projectsFilter: PropTypes.oneOfType(
    [PropTypes.oneOf([null]),
      PropTypes.string],
  ).isRequired,
  setProjectsFilter: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  viewStyle: PropTypes.string.isRequired,
};

AllProjects.defaultProps = {
  sort: '',
  order: '',
};

export default AllProjects;
