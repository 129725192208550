import PropTypes from 'prop-types';
import React from 'react';

const PipeLineStatusItem = ({ title, progress }) => (
  <div className="pipeLineStatus">
    <div className="pipeLineStatus__progress">
      {progress}
      %
    </div>
    <div className="pipeLineStatus__name">
      {title}
    </div>
  </div>
);

PipeLineStatusItem.propTypes = {
  progress: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default PipeLineStatusItem;
