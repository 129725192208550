import {
  branch,
  compose, renderNothing, withHandlers,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isNil } from 'ramda';

import UnpinClientModal from './unpinClientModal';
import { uiActions, uiSelectors } from '../../../../state/ui';
import { clientsSelectors, clientsActions } from '../../../../state/clients';

const mapDispatchToProps = {
  onCloseModal: uiActions.closeModal,
  unpinClientRequest: clientsActions.unpinClientRequest,
  getPinnedClients: clientsActions.getPinnedClientsRequest,
};

const mapStateToProps = (state, { selectedClientId }) => ({
  isOpen: uiSelectors.getModal(state)('unpinClientModal'),
  pinnedClient: clientsSelectors.getPinnedClientById(state)(selectedClientId),
});


const onUnpinHandler = ({
  unpinClientRequest, onClose, selectedClientId, getPinnedClients,
}) => () => unpinClientRequest({
  id: selectedClientId,
}, {
  callbacks: {
    success: () => {
      onClose();
      getPinnedClients();
    },
  },
});

const onCloseHandler = ({ onCloseModal }) => () => onCloseModal('unpinClientModal');

const enhance = compose(
  branch(
    ({ selectedClientId }) => isNil(selectedClientId),
    renderNothing,
  ),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withHandlers({
    onClose: onCloseHandler,
  }),
  withHandlers({
    onUnpin: onUnpinHandler,
  }),
);


export default enhance(UnpinClientModal);
