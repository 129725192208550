import { NODES_ACTIONS_CONST } from '@bp/constants';

export const getOutputVariablesToEventHelper = (events, nodes, selectedNode, crudConfig) => {
  const result = [...nodes]
    .filter(({ params }) => Object.keys(params).length)
    .map(({ params, ...node }) => ({
      ...node,
      title: events.find((event) => event.id === node.event_id)?.title,
      variables: Object.entries(params).filter(([, value]) => typeof value === 'string'),
    }));

  return selectedNode && crudConfig.mode === NODES_ACTIONS_CONST.EDIT
    ? result.filter(({ id }) => selectedNode.id !== id)
    : result;
};

export const setBusinessProcessHelper = ({ payload: { bp } }) => ({
  businessProcess: {
    selected: bp,
  },
  nodes: {
    data: bp.nodes.map(({ params, options, ...values }) => ({
      ...values,
      params: JSON.parse(params),
      options: JSON.parse(options),
    })),
  },
});

export const deleteBusinessProcessHelper = (
  { payload: payloadId },
  { businessProcess: { data, nodes } },
) => ({
  data: data.filter(({ id }) => id !== payloadId),
  nodes,
});

export const editBusinessProcessHelper = (
  { payload: { bp } },
  { businessProcess: { selected } },
) => ({
  selected: selected.id === bp.id ? bp : selected,
});

export const selectNodeByIdHelper = ({ payload: id }, state) => {
  const { data } = state.nodes;
  const selected = data.find((node) => node.id === id);
  if (selected.params.length) {
    selected.params = JSON.parse(selected.params);
  }
  return {
    selected,
  };
};

export const setBusinessProcessNodeHHelper = ({ payload }, { nodes: { data } }) => {
  const { node } = payload;
  node.params = JSON.parse(node.params);
  return {
    data: [...data, node],
  };
};

export const deleteBusinessProcessNodeHelper = ({ payload: nodeId }, { nodes: { data } }) => ({
  data: data.filter((node) => node.id !== nodeId),
});

export const editBusinessProcessNodeHelper = ({ payload }, { nodes: { data } }) => {
  const { node } = payload;
  node.params = JSON.parse(node.params);
  return {
    data: data.map((item) => (item.id === node.id ? node : item)),
  };
};

export const clearDataBeforeClosePageHelper = () => ({
  nodes: {
    selected: null,
    data: [],
    variables: {},
    selectedVariableKey: null,
  },
  events: {
    selected: null,
  },
});

export const setNewVariableHelper = (
  { payload },
  { nodes: { variables, selectedVariableKey } },
) => ({
  variables: { ...variables, [selectedVariableKey]: payload },
});

export const clearFormAfterSubmitHelper = () => ({
  selected: null,
  selectedVariableKey: null,
  variables: {},
});

export const selectEventByIdHelper = ({ payload: id }, state) => {
  const { data } = state.events;
  return {
    selected: data.find((event) => event.id === id),
  };
};

export const selectVariableKeyHelper = ({ payload: selectedVariableKey }) => ({
  selectedVariableKey,
});

export const setBusinessProcessLogs = ({ payload: { data } }) => ({
  data: Object.entries(data),
});
