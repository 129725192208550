import React from 'react';
import PropTypes from 'prop-types';
import { SummaryCard } from '../../../../../../ui-kit';
import './style.sass';

const SummaryCards = ({ t, leadsCount }) => (
  <div className="leads-summary">
    <SummaryCard
      className="leads-summary__card"
      colorBorder="#fcccc4"
      count={leadsCount.all}
      title={t('All')}
    />
    <SummaryCard
      className="leads-summary__card"
      colorBorder="#fcccc4"
      count={leadsCount.won}
      title={t('Won')}
    />
    <SummaryCard
      className="leads-summary__card"
      colorBorder="#fcccc4"
      count={leadsCount.lost}
      title={t('Lost')}
    />
  </div>
);

SummaryCards.propTypes = {
  t: PropTypes.func.isRequired,
  leadsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Object)]).isRequired,
};

export default SummaryCards;
