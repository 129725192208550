import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { taskActions, taskSelectors } from '../../../../state/task';
import History from './history';
import { withWindowWidth } from '../../../../utils/enchancers';

const mapDispatchToProps = {
  getTaskLogs: taskActions.getTaskLogsRequest,
};

const mapStateToProps = (state, { task }) => ({
  logs: taskSelectors.getTaskLogsByTaskId(state)(task.id),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withTranslation(['common']),
  withWindowWidth(),
  lifecycle({
    componentDidMount() {
      const { task, getTaskLogs } = this.props;
      getTaskLogs({ taskId: task.id });
    },
  }),
);


export default enhance(History);
