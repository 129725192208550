import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './style.sass';

const DEFAULT_COUNT = 5;

/**
 *
 * @param items {array}
 * @param className {string}
 * @param onShowMoreCustomClick {function}
 * @param count {number}
 * @returns {JSX.Element}
 * @constructor
 */

export const ShowMoreItems = ({
  items = [],
  className = '',
  onShowMoreCustomClick = () => { },
  count = DEFAULT_COUNT,
}) => {
  const body = useRef(null);
  const { t } = useTranslation('common');
  const [currentDisplayCount, setCurrentDisplayCount] = useState(count);
  const [data, setData] = useState(items.slice(0, currentDisplayCount));
  const [isMore, setIsMore] = useState(items.length > currentDisplayCount);
  const [isLess, setIsLess] = useState(false);

  const onShowMore = useCallback(() => {
    const newDisplayCount = currentDisplayCount + count;
    setCurrentDisplayCount(newDisplayCount);
    if (newDisplayCount >= items.length) {
      setIsMore(false);
      setIsLess(true);
    }
  }, [currentDisplayCount, count, items.length]);

  const onShowLess = useCallback(() => {
    setCurrentDisplayCount(count);
    setIsLess(false);
    setIsMore(true);
  }, [count]);

  const onClickHandler = useCallback(
    () => {
      onShowMoreCustomClick();
      onShowMore();
    }, [onShowMore, onShowMoreCustomClick],
  );

  useEffect(() => {
    if (currentDisplayCount) {
      setData(items.slice(0, currentDisplayCount));
      setIsMore(items.filter(item => item != null).length > currentDisplayCount);
    }
  }, [currentDisplayCount, items.length]); // eslint-disable-line

  return (
    <div className={`show-more-items ${className}`}>
      <div
        className={classNames('show-more-items__body', { 'show-more-items__body--show-less': isLess })}
        ref={body}
      >
        {data}
      </div>
      {
        isMore && (
          <div className="show-more-items__row">
            <button
              type="button"
              className="show-more-items__button"
              onClick={onClickHandler}
            >
              <span className="show-more-items__button-text">{t('Show more')}</span>
              <span
                className="show-more-items__button-icon icon-expand-arrow-dn"
              />
            </button>
          </div>
        )
      }
      {
        isLess && (
          <div className="show-more-items__row">
            <button type="button" className="show-more-items__button" onClick={onShowLess}>
              <span className="show-more-items__button-text">{t('Show less')}</span>
              <span
                className="show-more-items__button-icon icon-expand-arrow-dn"
              />
            </button>
          </div>
        )
      }
    </div>
  );
};
