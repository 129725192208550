import { compose } from 'recompose';
import { connect } from 'react-redux';
import SettingRolesContainer from './SettingRolesContainer';
import withGrid from '../../../../../utils/enchancers/withGrid';
import { permissionsActions } from '../../../../../state/permissions';

const LIMIT_OF_LIST_ROLES = 10;

const mapDispatchToProps = ({
  getRoles: permissionsActions.getRolesRequest,
});

const enhance = compose(
  connect(null, mapDispatchToProps),
  withGrid({
    limit: LIMIT_OF_LIST_ROLES,
    gridName: 'rolesList',
    hasMore: ({ rolesHasMore }) => rolesHasMore,
    count: ({ rolesCount }) => rolesCount,
    action: ({ getRoles }) => getRoles,
  }),
);
export default enhance(SettingRolesContainer);
