import {
  compose,
  lifecycle,
  pure,
  withContext,
  withHandlers,
  withState,
  withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import {
  values, path, prop, lte, gte, flip, isNil, equals,
} from 'ramda';
import { memo } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router';
import { notEqual } from 'ramda-extension';
import { messengerActions, messengerSelectors } from '../../state/messenger';
import { setAppTitle } from '../../state/ui/actions';
import { withGroupChannels } from '../../utils/enchancers';
import Messenger from './messenger';
import { CHANNELS_URL, DIRECTS_URL } from '../../constants/messenger';
import { debounceFunc } from '../../utils/helpers/commonHelpers';
import { DOWN_CHANNEL, UPPER_CHANNEL } from './consts';

const mapStateToProps = state => ({
  activeChannel: messengerSelectors.getActiveChannelEntity(state),
  groupChannels: messengerSelectors.getGroupChannels(state),
  getDirectChannelsList: messengerSelectors.getDirectChannelsList(state),
  channelLists: messengerSelectors.getGroupChannelsList(state),
});

const mapDispatchToProps = {
  getChannelRequest: messengerActions.getChannelRequest,
  readMessageRequest: messengerActions.readMessagesRequest,
  setActiveChannel: messengerActions.setActiveChannel,
  getDirectChannelsRequest: messengerActions.getDirectChannelsRequest,
  getGroupChannelsRequest: messengerActions.getGroupChannelsRequest,
  setAppTitle,
};

const topOfElement = compose(prop('top'), el => el.getBoundingClientRect());

const getNearestChannelsElements = () => {
  const unreadChannelsElementsByList = document.querySelectorAll(
    '.channel--has-unread',
  );
  const isNotVisibleInBottom = compose(
    flip(gte)(prop('innerHeight', window)),
    topOfElement,
  );
  const isNotVisibleInTop = compose(flip(lte)(50), topOfElement);
  const nearestElements = { [UPPER_CHANNEL]: null, [DOWN_CHANNEL]: null };

  unreadChannelsElementsByList.forEach((element) => {
    if (isNotVisibleInBottom(element) || isNotVisibleInTop(element)) {
      const vector = !isNotVisibleInBottom(element);
      if (isNil(nearestElements[vector ? UPPER_CHANNEL : DOWN_CHANNEL])) {
        nearestElements[vector ? UPPER_CHANNEL : DOWN_CHANNEL] = element;
      }
    }
  });
  return nearestElements;
};

const onScrollToUnreadChannelHandler = () => (scrollTo) => {
  const toElement = getNearestChannelsElements()[scrollTo];
  if (toElement) toElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
};

const onWatchUnreadChannelsHandler = ({
  setShownButtonScrollToChannel,
}) => () => {
  debounceFunc(
    () => {
      const {
        [UPPER_CHANNEL]: upperElement,
        [DOWN_CHANNEL]: downElement,
      } = getNearestChannelsElements();
      setShownButtonScrollToChannel({
        [UPPER_CHANNEL]: !!upperElement,
        [DOWN_CHANNEL]: !!downElement,
      });
    },
    100,
    false,
    'onWatchUnreadChannels',
  );
};

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withGroupChannels({}),
  withRouter,
  withState('selectedChannelId', 'setSelectedChannelId', 0),
  withState('selectedMessage', 'setSelectedMessageGlobal', {}),
  withState('shownButtonScrollToChannel', 'setShownButtonScrollToChannel', {
    [UPPER_CHANNEL]: false,
    [DOWN_CHANNEL]: false,
  }),
  memo,
  withContext(
    {
      isLoadingChannel: PropTypes.bool,
      selectedChannelId: PropTypes.number,
      setSelectedChannelId: PropTypes.func,
      selectedMessage: PropTypes.shape({}),
      setSelectedMessageGlobal: PropTypes.func,
    },
    ({
      groupChannels,
      match: { url },
      selectedChannelId,
      setSelectedChannelId,
      selectedMessage,
      setSelectedMessageGlobal,
    }) => ({
      isLoadingChannel:
        !values(groupChannels)[0]
        || !url === CHANNELS_URL
        || !url === DIRECTS_URL,
      selectedChannelId,
      setSelectedChannelId,
      selectedMessage,
      setSelectedMessageGlobal,
    }),
  ),
  withStateHandlers(() => ({ userProfileId: null }), {
    setUserProfileId: () => val => ({
      userProfileId: val || null,
    }),
  }),
  withHandlers({
    onScrollToUnreadChannel: onScrollToUnreadChannelHandler,
    onWatchUnreadChannels: onWatchUnreadChannelsHandler,
  }),
  pure,
  lifecycle({
    shouldComponentUpdate({
      shownButtonScrollToChannel: shownButtonScrollToChannelPrev,
      ...prevProps
    }) {
      const { shownButtonScrollToChannel, ...props } = this.props;
      return (
        notEqual(
          this.props.shownButtonScrollToChannel,
          shownButtonScrollToChannelPrev,
        ) && equals(props, prevProps)
      );
    },
    componentDidMount() {
      const {
        getDirectChannelsRequest,
        match,
        readMessageRequest,
        setAppTitle: setTitle,
      } = this.props;
      const channelId = path(['params', 'id'], match);
      if (channelId) {
        setTimeout(() => readMessageRequest({ channelId }), 1000);
      }
      getDirectChannelsRequest(null);
      setTitle('Messenger - Avanga 2.0');
    },
    componentWillUnmount() {
      this.props.readMessageRequest({ channelId: this.props.match.params.id });
      this.props.setActiveChannel(null);
    },
  }),
);

// TODO issue with double request. Request should fire in MessagerContainer

export default enhancer(Messenger);
