import React from 'react';
import PropTypes from 'prop-types';

import { DragDropContext } from 'react-beautiful-dnd';
import { Backlog, Sprint } from '..';
import { ButtonsCollection } from '../../../../ui-kit';
import './style.sass';
import useDropArea from './useDropArea';

const DragDropArea = ({ onDragEnd, openModal, t }) => {
  const { firstSprint, nextSprints, showBacklog } = useDropArea();

  return (
    <div className="table-tasks">
      <DragDropContext onDragEnd={onDragEnd}>
        <ButtonsCollection.ButtonBrill
          onClick={() => openModal('createSprintModal')}
          className="button--sm button-brill table-tasks__button-new-sprint"
        >
          {t('Create a new sprint')}
        </ButtonsCollection.ButtonBrill>
        {!!firstSprint && <Sprint key={`sprint-key-${firstSprint.id}`} {...firstSprint} />}
        {nextSprints.map((sprint) => (
          <Sprint key={`sprint-key-${sprint.id}`} {...sprint} />
        ))}
        {showBacklog && <Backlog />}
      </DragDropContext>
    </div>
  );
};

DragDropArea.propTypes = {
  sprints: PropTypes.arrayOf(PropTypes.number),
  onDragEnd: PropTypes.func.isRequired,
};

DragDropArea.defaultProps = {
  sprints: [],
};

export default DragDropArea;
