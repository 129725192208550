import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '@/ui-kit';
import { callNotification } from '@/utils/helpers/notifies';
import { useKnowledgeBaseContext } from '@/views/knowledgeBase/context/useKnowledgeBaseContext';
import api from '../../../../../../../api/knowledgeBase.service';
import './style.sass'

export const DeleteCategoryModal = ({
  isOpen,
  setIsOpenedDeleteModal
}) => {
  const { t } = useTranslation(['common']);

  const {
    categoryData,
    categoryId,
    setCategoryId,
    setCategoryData,
    refreshCategories,
  } = useKnowledgeBaseContext();

  const onCloseModal = useCallback(() => {
    setCategoryId(null);
    setCategoryData(null);
    setIsOpenedDeleteModal(false);
  }, []);

  const onDelete = useCallback(async () => {
    try {
      await api.deleteKnowledgeBaseCategory(categoryId);

      callNotification.success('Category has been deleted.');

      refreshCategories();
      onCloseModal();
    } catch (e) {
      callNotification.error('Error with deleting category.');
      console.error(e);
    }
  }, [categoryId, refreshCategories])

  return (
    <ConfirmModal
      isOpen={isOpen}
      actionTitle="Delete"
      onAction={onDelete}
      onClose={onCloseModal}
      title="Delete category"
    >
      <span className="delete-category__title title-md">
        {t('Are you sure you want to delete')}
        <b className="delete-category__name">{categoryData?.description}</b>
        ?
      </span>
    </ConfirmModal>
  )
};
