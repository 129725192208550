import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';

import { ButtonsCollection, ModalWindow } from '../../../../../ui-kit';
import './style.sass';

const DeleteNote = ({
  isOpen,
  isPending,
  onCloseModal,
  note,
  onDelete,
  t,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    className="delete-note__modal"
    modalName="Delete note"
    title="Delete note"
  >
    <>
      <div className="row  text-align--left">
        <span className="delete-note__title title-md">
          {t('Are you sure you want to delete')}
          <b className="delete-note__name">{prop('title', note)}</b>
          {`${t('note')}?`}
        </span>
      </div>
      <div className="delete-note__button-row">
        <ButtonsCollection.ButtonBrill
          type="button"
          onClick={onDelete}
          pending={isPending}
          className="button--md delete-note__button-control button-brill--fill"
        >
          {t('Delete')}
        </ButtonsCollection.ButtonBrill>
        <button
          type="button"
          className="button--cancel"
          onClick={onCloseModal}
        >
          {t('Cancel')}
        </button>
      </div>
    </>
  </ModalWindow>
);

DeleteNote.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  note: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default DeleteNote;
