/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';

const pipelinesListSchema = [new schema.Entity('pipelines')];
const pipelinesStatusesListSchema = [new schema.Entity('pipelinesStatuses')];

export {
  pipelinesListSchema,
  pipelinesStatusesListSchema,
};
