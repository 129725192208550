import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '@/state/ui/actions';
import { Preload } from '@/ui-kit';
import { bpSelectors } from '@bp//store/bpModule';
import { CreateProcessModal, DeleteProcessModal, FiltersRow, ProcessCard } from '@bp/components';
import './style.sass';

export const ProcessesListContainer = () => {
  const dispatch = useDispatch();
  const processes = useSelector(bpSelectors.getBusinessProcessData);
  const isPending = useSelector(bpSelectors.getBusinessProcessesPending);

  const createProcessModal = () => {
    dispatch(openModal('createProcessModal'));
  };
  return (
    <div className="processesListContainer">
      <FiltersRow />
      <div className="processesListContainer__wrapper">
        {!isPending ? (
          <>
            <button
              onClick={createProcessModal}
              type="button"
              className="processCard processCard__add"
            >
              <span className="processCard__title">Create a new business process</span>
            </button>
            {processes.map((process) => (
              <ProcessCard {...process} key={process.id} />
            ))}
          </>
        ) : (
          <Preload />
        )}
      </div>

      <CreateProcessModal />
      <DeleteProcessModal />
    </div>
  );
};
