import React from 'react';
import { Switch, Route, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Layouts, NoInternetConnection, ButtonsCollection, PermissionElement,
} from '../../ui-kit';
import {
  Header, MyTasksWindow, Task,
} from '../../containers';
import { LeftSideBar } from '../../newContainers';
import { WINDOW_WIDTH } from '../../constants/ui';
import { RightSideBarContainer } from './components';
import './style.sass';
import { ACTIONS, MODULES } from '../../constants/pemissions';
import { usePreviousPath } from 'utils/hooks';

const MyTasks = ({
  setOpenModal, windowWidth, t, isTaskOpen,
}) => {
  const { path, pathHandler} = usePreviousPath()
  return (
  <Layouts.Main className="page-my-tasks">
    <LeftSideBar />
    <div className="main-container page-my-tasks__main-container">
      <NoInternetConnection>
        <Header>
          <>
            {
              isTaskOpen && (
                <NavLink className="page-my-tasks__go-to-tasks" to="/tasks/">
                  <span className="icon-expand-arrow-left" title="Back to tasks" />
                </NavLink>
              )
            }
            <h1 className="weight--medium title--xl title-page">
              {t('My tasks')}
            </h1>
          </>
          <PermissionElement
            module={MODULES.PROJECTS}
            action={ACTIONS.CREATE}
          >
            {
              windowWidth > WINDOW_WIDTH.MEDIUM ? (
                <ButtonsCollection.ButtonBrill
                  onClick={() => setOpenModal('createTaskModal')}
                  className="button--sm header__control-button--project"
                >
                  {t('New task')}
                </ButtonsCollection.ButtonBrill>
              ) : (
                <ButtonsCollection.ButtonPlus
                  onClick={() => setOpenModal('createTaskModal')}
                  className="header__control-button--task"
                />
              )
            }
          </PermissionElement>
        </Header>
        <div className="main-container__content page-my-tasks__content">
          <Switch>
            <Route exact path="/tasks/project/:id/task/:taskId/" component={Task} />
            <Route exact render={props => <MyTasksWindow {...props} path={path} onPath={ pathHandler} />}/>
          </Switch>
          <RightSideBarContainer windowWidth={windowWidth} />
        </div>
      </NoInternetConnection>
    </div>
  </Layouts.Main>
);
}

MyTasks.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  setOpenModal: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  isTaskOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default MyTasks;
