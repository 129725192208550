import { Pie } from 'react-chartjs-2';
import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CardActivity } from './cardsActivity/components';
import { getTimeStringBySeconds } from 'utils/helpers/dateHelpers';

const avangaColors = [
  '#d32f2f',
  '#ffc107',
  '#ffeb3b',
  '#0288d1',
  '#2196f3',
  '#4caf50',
  '#f16e5e',
  '#d16052',
  '#b25246',
];

const SECONDS_IN_ONE_HOUR = 3600;

/**
 *
 * @param id{number}
 * @param dateValue{Object}
 * @param requestResult{Object}
 * @returns {number|JSX.Element}
 * @constructor
 */
export const PieChart = ({
  id = 0, dateValue = {}, requestResult = {},
}) => {
  const { t } = useTranslation();
  const pieChartRef = useRef(null);
  const [showAlternativePie, setShowAlternativePie] = useState(false);

  const stringToColor = str => avangaColors[str ? str.length % 9 : 0];

  const [closedHoursDataset, setClosedHoursDataset] = useState([]);
  const [estimatedHoursDataset, setEstimatedHoursDataset] = useState([]);
  const [pieLabels, setPieLabels] = useState([]);
  const [pieColors, setPieColors] = useState([]);

  const convertTooltipTime = ({ datasetIndex, index }, { datasets }) => {
    const value = datasets[datasetIndex].data[index] * SECONDS_IN_ONE_HOUR
    return getTimeStringBySeconds(value)
  }

  const defaultOptions = {
    tooltips: {
      enabled: true,
    }
  }

  const handleTogglePieChart = () => {
    setShowAlternativePie(!showAlternativePie);
    
    if (showAlternativePie) {
      pieChartRef.current.chartInstance.data.datasets[0].data = estimatedHoursDataset;
    } else {
      pieChartRef.current.chartInstance.data.datasets[0].data = closedHoursDataset;
    }
    pieChartRef.current.chartInstance.options.tooltips.callbacks.label = convertTooltipTime
    pieChartRef.current.chartInstance.update();
  };

  useEffect(() => {
    if (pieChartRef.current) {
      pieChartRef.current.chartInstance.options.tooltips.callbacks.label = convertTooltipTime
      pieChartRef.current.chartInstance.update();
    }
  }, [pieChartRef.current]);

  const getActivity = useCallback(() => {
    if (requestResult?.pieGraph) {
      const { closed_hours, estimated_hours, labels } = requestResult.pieGraph;
      setClosedHoursDataset(closed_hours);
      setEstimatedHoursDataset(estimated_hours);
      setPieLabels(labels);
      setPieColors(labels.map(label => stringToColor(label)));
    }
  }, [requestResult]);

  useEffect(() => {
    getActivity();
  }, [id, dateValue]);

  useEffect(() => {
    setShowAlternativePie(false);
  }, [id]);
  return id && (
    <CardActivity
      title={t('Total time per projects')}
      onToggle={handleTogglePieChart}
      status={showAlternativePie}
    >
      <Pie
        data={
        {
          datasets: [{
            data: estimatedHoursDataset,
            backgroundColor: pieColors,
          }],
          labels: pieLabels,
        }}
        options={defaultOptions}
        ref={pieChartRef}
      />
    </CardActivity>
  );
};
