import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';

import LeadInfo from '../leadInfo';
import ContactsInfo from '../contactsInfo';
import ProjectsNotes from '../notesInfo';
import History from '../historyInfo';

const LeadTab = ({ lead }) => (
  <Tabs selectedTabClassName="lead-navigation__link--active">
    <div className="lead-navigation">
      <TabList className="lead-navigation__list">
        <Tab className="lead-navigation__link">Lead Info</Tab>
        <Tab className="lead-navigation__link">Contacts</Tab>
        <Tab className="lead-navigation__link">Notes</Tab>
        <Tab className="lead-navigation__link">History</Tab>
      </TabList>
    </div>
    <TabPanel>
      <LeadInfo selectedLeadId={lead.id} />
    </TabPanel>
    <TabPanel>
      <ContactsInfo lead={lead} />
    </TabPanel>
    <TabPanel>
      <ProjectsNotes leadId={lead.id} />
    </TabPanel>
    <TabPanel>
      <History leadId={lead.id} />
    </TabPanel>
  </Tabs>
);

LeadTab.propTypes = {
  lead: PropTypes.shape({}).isRequired,
};

export default LeadTab;
