import { handleActions } from 'redux-actions';
import {
  propOr, includes, values, pathOr, equals, filter, omit,
} from 'ramda';
import { normalize } from 'normalizr';

import types from './types';
import schema from './schema';
import { mergeByPath, mergeDeep, mergeIn } from '../../utils/helpers/ramdaStateHelpers';
import { INVOICES_PAY_TYPES, INVOICES_VIEW } from '../../constants/crm';

const INVOICES_VIEWS = [INVOICES_VIEW.PIPE, INVOICES_VIEW.LIST];

const generateRandomString = () => Math.random().toString(36);

const addInvoiceItem = ({
  details = null, quantity = null, rate = null, amount = 0,
}) => ({
  id: generateRandomString(),
  details,
  quantity,
  rate,
  amount,
});

const defaultState = {
  invoicesList: {
    entities: {},
    result: [],
  },
  invoiceToSend: null,
  summary: {
    count: 0,
    totalCount: 0,
    statuses: {},
  },
  board: [],
  newInvoicePage: {
    invoiceItems: {
      entities: {},
      result: [],
    },
    tax: {
      value: 0,
      paymentType: INVOICES_PAY_TYPES.PERCENT,
    },
    discount: {
      value: 0,
      paymentType: INVOICES_PAY_TYPES.PERCENT,
    },
    currencySign: '$',
  },
  invoiceListView: INVOICES_VIEW.LIST,
  currentInvoice: {},
};

const reducer = handleActions({
  [types.OPEN_SEND_MODAL]: mergeIn(({ payload: invoiceId }) => ({
    invoiceToSend: invoiceId,
  })),
  [types.SET_INVOICES]: mergeIn(({
    payload: {
      data, paginatedData: { total }, totalCount, summary: statuses,
    },
  }) => {
    const { entities, result } = data;
    const invoices = propOr({}, 'invoices', entities);

    const invoicesByStatusId = values(invoices).reduce((acc, invoice) => {
      const statusId = invoice.status_id;
      const accInvoicesByStatus = acc[statusId] || [];
      return {
        ...acc,
        [statusId]: [...accInvoicesByStatus, invoice],
      };
    }, {});

    return {
      invoicesList: {
        entities: invoices,
        result,
      },
      summary: {
        count: total,
        totalCount,
        statuses,
      },
      board: invoicesByStatusId,
    };
  }),
  [types.CHANGE_VIEW]: mergeIn(({ payload }) => ({
    invoiceListView: includes(payload, INVOICES_VIEWS) ? payload : INVOICES_VIEW.LIST,
  })),
  [types.REORDER_COLUMN_VIEW]: mergeDeep(({
    payload: {
      invoices, status_id: statusId,
    },
  }) => ({
    board: {
      [statusId]: invoices,
    },
  })),
  [types.ADD_INVOICE_ITEM]: mergeDeep(({ payload }, state) => {
    const normalizedItem = normalize([addInvoiceItem(payload)], schema.invoiceItemsSchema);
    const entities = pathOr({}, ['entities', 'invoiceItems'], normalizedItem);
    const result = propOr([], 'result', normalizedItem);

    return {
      newInvoicePage: {
        invoiceItems: {
          entities,
          result: [...pathOr([], ['newInvoicePage', 'invoiceItems', 'result'], state), ...result],
        },
      },
    };
  }),
  [types.DELETE_INVOICE_ITEM]: mergeByPath(['newInvoicePage', 'invoiceItems'], ({ payload }, state) => ({
    entities: omit(
      [payload],
      pathOr({}, ['newInvoicePage', 'invoiceItems', 'entities'], state),
    ),
    result: filter(
      itemId => !equals(itemId, payload),
      pathOr([], ['newInvoicePage', 'invoiceItems', 'result'], state),
    ),
  })),
  [types.CHANGE_INVOICE_ITEM_FIELD_VALUE]: mergeDeep(({ payload: { itemId, fieldValue } }) => ({
    newInvoicePage: {
      invoiceItems: {
        entities: {
          [itemId]: fieldValue,
        },
      },
    },
  })),
  [types.SET_TAX]: mergeDeep(({ payload }) => ({
    newInvoicePage: {
      tax: {
        value: parseFloat(payload), /* .toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }), */
      },
    },
  })),
  [types.SET_DISCOUNT]: mergeDeep(({ payload }) => ({
    newInvoicePage: {
      discount: {
        value: parseFloat(payload), /* .toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }), */
      },
    },
  })),
  [types.SET_DISCOUNT_PAYMENT_TYPE]: mergeDeep(({ payload }) => ({
    newInvoicePage: {
      discount: {
        paymentType: payload,
      },
    },
  })),
  [types.SET_TAX_PAYMENT_TYPE]: mergeDeep(({ payload }) => ({
    newInvoicePage: {
      tax: {
        paymentType: payload,
      },
    },
  })),
  [types.SET_CURRENCY_SIGN]: mergeDeep(({ payload }) => ({
    newInvoicePage: {
      currencySign: payload,
    },
  })),
  [types.SET_CURRENT_INVOICE]: mergeDeep(({ payload }) => {
    // console.log(payload.items);
    const normalizedItem = normalize(payload.items, schema.invoiceItemsSchema);
    const entities = pathOr({}, ['entities', 'invoiceItems'], normalizedItem);
    const result = propOr([], 'result', normalizedItem);

    return {
      currentInvoice: payload,
      newInvoicePage: {
        invoiceItems: {
          entities,
          result,
        },
        tax: JSON.parse(payload.tax),
        discount: JSON.parse(payload.discount),
        currencySign: propOr('', 'value', JSON.parse(payload.currency)),
      },
    };
  }),
  [types.CLEAR_STORE]: mergeIn(() => ({
    newInvoicePage: {
      invoiceItems: {
        entities: {},
        result: [],
      },
      tax: {
        value: 0,
        paymentType: INVOICES_PAY_TYPES.PERCENT,
      },
      discount: {
        value: 0,
        paymentType: INVOICES_PAY_TYPES.PERCENT,
      },
      currencySign: '$',
    },
    currentInvoice: {},
  })),
}, defaultState);

export default reducer;
