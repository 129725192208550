import { createSelector } from 'reselect';
import {
  pathOr, identity, memoizeWith,
} from 'ramda';

const getRolesEntities = createSelector(pathOr({}, ['permissions', 'entities', 'roles']), identity);
const getRolesList = createSelector(pathOr([], ['permissions', 'result', 'roles']), identity);
const getRolesCount = createSelector(pathOr(0, ['permissions', 'countRoles']), identity);
const getRolesHasMore = createSelector(pathOr(true, ['permissions', 'hasMoreRoles']), identity);
const getRolesIsPending = createSelector(pathOr(true, ['pending', 'getRolesRequest']), identity);
const getDeleteRolePending = createSelector(pathOr(false, ['pending', 'deleteRoleRequest']), identity);
const getUpdateRolePending = createSelector(pathOr(false, ['pending', 'updateRoleRequest']), identity);


const getRoleById = createSelector(
  createSelector(pathOr({}, ['permissions', 'roles']), identity),
  roles => memoizeWith(identity, roleId => pathOr({}, [roleId], roles)),
);

const getPermissionsByRoleId = createSelector(
  createSelector(pathOr({}, ['permissions', 'roles']), identity),
  roles => memoizeWith(identity, roleId => pathOr({}, [roleId, 'permissions'], roles)),
);

const getModules = createSelector(pathOr({}, ['permissions', 'modules']), identity);

export {
  getRolesEntities,
  getRolesList,
  getRolesCount,
  getRolesHasMore,
  getRolesIsPending,
  getRoleById,
  getPermissionsByRoleId,
  getModules,
  getDeleteRolePending,
  getUpdateRolePending,
};
