import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '@/ui-kit';
import { callNotification } from '@/utils/helpers/notifies';
import { useKnowledgeBaseContext } from '@/views/knowledgeBase/context/useKnowledgeBaseContext';
import api from '../../../../../../api/knowledgeBase.service';
import './style.sass';

export const DeleteArticleModal = ({
  isOpen,
  setIsOpenedDeleteModal
}) => {
  const { t } = useTranslation(['common']);

  const {
    articleId,
    setArticleId,
    articleTitle,
    setShouldRefreshArticles,
  } = useKnowledgeBaseContext();

  const onCloseModal = useCallback(() => {
    setArticleId(null);

    setIsOpenedDeleteModal(false);
  }, []);

  const onDelete = useCallback(async () => {
    try {
      await api.deleteKnowledgeBaseArticle(articleId);

      callNotification.success('Article has been deleted.');

      setShouldRefreshArticles(prevState => !prevState);
      onCloseModal();
    } catch (e) {
      callNotification.error('Error with deleting article.');
      console.error(e);
    }
  }, [articleId, setShouldRefreshArticles])

  return (
    <ConfirmModal
      isOpen={isOpen}
      actionTitle="Delete"
      onAction={onDelete}
      onClose={onCloseModal}
      title="Delete article"
    >
      <span className="delete-article__title title-md">
        {t('Are you sure you want to delete')}
        <b className="delete-article__name">{articleTitle}</b>
        ?
      </span>
    </ConfirmModal>
  )
};
