import {
  branch,
  compose, renderNothing, withHandlers,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  always, ifElse, isEmpty, isNil,
} from 'ramda';

import PinTaskModal from './pinTaskModal';
import { getModal } from '../../../state/ui/selectors';
import { uiActions } from '../../../state/ui';
import { tasksActions } from '../../../state/tasks';
import { projectSelectors } from '../../../state/project';
import { taskSelectors } from '../../../state/task';

const mapDispatchToProps = {
  onCloseModal: uiActions.closeModal,
  pinTaskRequest: tasksActions.pinTaskRequest,
};

const mapStateToProps = (state, { selectedTask }) => {
  const taskActive = taskSelectors.getActiveTask(state);
  const taskById = projectSelectors.getTask(state)(selectedTask).id
    ? projectSelectors.getTask(state)(selectedTask)
    : projectSelectors.getBacklogTask(state)(selectedTask);

  return {
    isOpen: getModal(state)('pinTaskModal'),
    task: ifElse(isEmpty, always(taskActive), always(taskById))(taskById),
  };
};


const onPinHandler = ({
  pinTaskRequest, onClose, task,
}) => () => pinTaskRequest({
  taskId: task.id,
  projectId: task.project_id,
}, {
  task,
  callbacks: {
    success: onClose,
  },
});

const onCloseHandler = ({ onCloseModal }) => () => onCloseModal('pinTaskModal');

const enhance = compose(
  branch(
    ({ selectedTask }) => isNil(selectedTask),
    renderNothing,
  ),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withHandlers({
    onClose: onCloseHandler,
  }),
  withHandlers({
    onPin: onPinHandler,
  }),
);


export default enhance(PinTaskModal);
