import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const JumpToRecentMessagesButton = ({ onSetIsPuck }) => (
  <div className="jumpToRecentButton">
    <b className="jumpToRecentButton__title">View archives</b>
    <button className="jumpToRecentButton__button" type="button" onClick={() => onSetIsPuck(false)}>

Jump to recent messages
      <span className="icon-expand-arrow-dn" />
    </button>
  </div>
);

JumpToRecentMessagesButton.propTypes = {
  onSetIsPuck: PropTypes.func.isRequired,
};

export default JumpToRecentMessagesButton;
