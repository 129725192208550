import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import GroupChannels from './groupChannels';
import { messengerSelectors } from '../../../../state/messenger';
import { openModal } from '../../../../state/ui/actions';

const mapStateToProps = state => ({
  groupChannels: messengerSelectors.getGroupChannelsList(state),
  activeChannel: messengerSelectors.getActiveChannel(state),
});

const mapDispatchToProps = ({
  openModal,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common', 'chat']),
  withProps(({ groupChannels }) => ({
    groupChannelsList: groupChannels,
  })),
);

export default enhance(GroupChannels);
