import React from 'react';
import PropTypes from 'prop-types';
import { ButtonsCollection, DropDown } from '../../../../ui-kit';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import { SPRINT_STATUS } from '../../../../constants/sprints';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';

const SprintControls = ({
  onEdit, onDelete, onComplete, onStart, windowWidth, t, status, P,
}) => (
  <DropDown
    className="table-tasks__sprint-control"
    label={(
      <ButtonsCollection.ButtonIcons
        className="agenda-task__controls-button"
        title="Controls for sprint"
      >
        <span className="table-tasks__control-icon icon-settings-vertical">
          <span
            className="path1"
          />
          <span className="path2" />
          <span
            className="path3"
          />
        </span>
      </ButtonsCollection.ButtonIcons>
      )}
    list={
        (windowWidth < WINDOW_WIDTH.SMALL) ? [

          ...P(MODULES.PROJECTS, ACTIONS.UPDATE, [{
            id: 1,
            onClick: onEdit,
            label: (
              <>
                <span className="table-tasks__sprint-control_icon icon-pencil-2" />
                <span className="table-tasks__sprint-control_text">{t('Edit')}</span>
              </>
            ),
          }]),
          ...P(MODULES.PROJECTS, ACTIONS.DELETE, [{
            id: 2,
            onClick: onDelete,
            label: (
              <>
                <span className="table-tasks__sprint-control_icon icon-delete-icon" />
                <span className="table-tasks__sprint-control_text">{t('Delete')}</span>
              </>
            ),
          }]),
          ...P(MODULES.PROJECTS, ACTIONS.UPDATE, [{
            id: 3,
            onClick: (status === SPRINT_STATUS.COMPLETE) ? onStart : onComplete,
            label: (
              <>
                {
                  (status === SPRINT_STATUS.COMPLETE) ? (
                    <>
                      <span className="table-tasks__sprint-control_icon icon-category-set" />
                      <span className="table-tasks__sprint-control_text">{t('Start')}</span>
                    </>
                  ) : (
                    <>
                      <span className="table-tasks__sprint-control_icon icon-work-done" />
                      <span className="table-tasks__sprint-control_text">{t('Complete')}</span>
                    </>
                  )
                }
              </>
            ),
          }]),
        ] : [
          ...P(MODULES.PROJECTS, ACTIONS.UPDATE, [
            {
              id: 1,
              onClick: onEdit,
              label: (
                <>
                  <span className="table-tasks__sprint-control_icon icon-pencil-2" />
                  <span className="table-tasks__sprint-control_text">{t('Edit')}</span>
                </>
              ),
            },
          ]),
          ...P(MODULES.PROJECTS, ACTIONS.DELETE, [
            {
              id: 2,
              onClick: onDelete,
              label: (
                <>
                  <span className="table-tasks__sprint-control_icon icon-delete-icon" />
                  <span className="table-tasks__sprint-control_text">{t('Delete')}</span>
                </>
              ),
            },
          ]),
        ]
      }
  />
);

SprintControls.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  status: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
  P: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default SprintControls;
