import * as taskActions from './actions';
import * as taskSelectors from './selectors';
import * as taskOperations from './operations';
import * as taskSchemas from './schema';
import taskTypes from './types';
import reducer from './reducers';

export {
  taskActions,
  taskSelectors,
  taskOperations,
  taskSchemas,
  taskTypes,
};

export default reducer;
