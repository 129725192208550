export default {
  GET_LEADS_REQUEST: 'GET_LEADS_REQUEST',
  GET_LEAD_REQUEST: 'GET_LEAD_REQUEST',
  GET_LEAD_CONTACTS_REQUEST: 'GET_LEAD_CONTACTS_REQUEST',
  GET_LEAD_TASKS_REQUEST: 'GET_LEAD_TASKS_REQUEST',
  GET_LEAD_NOTES_REQUEST: 'GET_LEAD_NOTES_REQUEST',
  GET_LEAD_LOGS_REQUEST: 'GET_LEAD_LOGS_REQUEST',
  ADD_LEAD_REQUEST: 'ADD_LEAD_REQUEST',
  ADD_LEAD_NOTE_REQUEST: 'ADD_LEAD_NOTE_REQUEST',
  EDIT_LEAD_REQUEST: 'EDIT_LEAD_REQUEST',
  EDIT_LEAD_NOTE_REQUEST: 'EDIT_LEAD_NOTE_REQUEST',
  CONVERT_LEAD_TO_CLIENT_REQUEST: 'CONVERT_LEAD_TO_CLIENT_REQUEST',
  UPDATE_LEAD_REQUEST: 'UPDATE_LEAD_REQUEST',
  DELETE_LEAD_REQUEST: 'DELETE_LEAD_REQUEST',
  DELETE_LEAD_NOTE_REQUEST: 'DELETE_LEAD_NOTE_REQUEST',

  GET_LEADS_AUTOCOMPLETE_REQUEST: 'GET_LEADS_AUTOCOMPLETE_REQUEST',
  SET_ADDED_LEAD_CONTACT: 'SET_ADDED_LEAD_CONTACT',
  SET_LEADS: 'SET_LEADS',
  SET_LEAD: 'SET_LEAD',
  SET_LEAD_CONTACTS: 'SET_LEAD_CONTACTS',
  SET_LEADS_AUTOCOMPLETE: 'SET_LEADS_AUTOCOMPLETE',
  SET_LEAD_TASKS: 'SET_LEAD_TASKS',
  SET_LEAD_NOTES: 'SET_LEAD_NOTES',
  SET_LEAD_LOGS: 'SET_LEAD_LOGS',
  DELETE_LEAD: 'DELETE_LEAD',

  DELETE_LEAD_NOTE_SUCCESS: 'DELETE_LEAD_NOTE_SUCCESS',
  EDIT_LEAD_NOTE_SUCCESS: 'EDIT_LEAD_NOTE_SUCCESS',
  DELETE_LEAD_TASK_SUCCESS: 'DELETE_LEAD_TASK_SUCCESS',
  EDIT_LEAD_TASK_SUCCESS: 'EDIT_LEAD_TASK_SUCCESS',
  DELETE_LEAD_CONTACT_SUCCESS: 'DELETE_LEAD_CONTACT_SUCCESS',
  EDIT_LEAD_CONTACT_SUCCESS: 'EDIT_LEAD_CONTACT_SUCCESS',
  FULL_EDIT_LEAD_TASK_SUCCESS: 'FULL_EDIT_LEAD_TASK_SUCCESS',
  CHANGE_TASK_STATUS_SUCCESS: 'CHANGE_TASK_STATUS_SUCCESS',

  GET_PINNED_LEADS_REQUEST: 'GET_PINNED_LEADS_REQUEST',
  PIN_LEAD_REQUEST: 'PIN_LEAD_REQUEST',
  UNPIN_LEAD_REQUEST: 'UNPIN_LEAD_REQUEST',
  SET_PINNED_LEADS: 'SET_PINNED_LEADS',
  SET_PINNED_LEAD: 'SET_PINNED_LEAD',

  SET_ACTIVE_RELATED_LEAD: 'SET_ACTIVE_RELATED_LEAD',

  ON_DRAG_LEAD_END: 'ON_DRAG_LEAD_END',
  REORDER_PIPE_VIEW_LEAD: 'REORDER_PIPE_VIEW_LEAD',
  UPDATE_LEAD_SUMMARY: 'UPDATE_LEAD_SUMMARY',
  SET_CHECKED_PIPELINE: 'SET_CHECKED_PIPELINE',
  SET_LEADS_VIEW: 'SET_LEADS_VIEW',
};
