import React, {
  useCallback, useEffect, memo, useRef,
} from 'react';
import { Portal } from 'react-portal';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ScrollWrapper, ButtonsCollection } from '..';
import './style.sass';

/**
 *
 * @param title {string}
 * @param children {array | element}
 * @param position {string}
 * @param isOpen {boolean}
 * @param className {string}
 * @param zIndex {number | string}
 * @param isPreventTouch {boolean}
 * @param onClose {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const ModalWindow = memo(({
  title,
  children,
  position = '',
  isOpen,
  className = '',
  zIndex = 99,
  isDoNotPreventTouch = false,
  onClose,
}) => {
  const { t } = useTranslation('common');
  const bodyModalRef = useRef(null);
  const modalContainerRef = useRef(null);

  const onCloseModal = useCallback(() => {
    const isConfirm = window.confirm(t('Are you sure you want to close this modal window?'));
    if (isConfirm) onClose();
  }, [onClose]);

  const onClickOutside = e => (!bodyModalRef.current.contains(e.target) ? onCloseModal() : null);

  useEffect(() => {
    const container = modalContainerRef.current;
    if (container) {
      container.addEventListener('mousedown', onClickOutside, true);
    }
    return () => {
      if (container) {
        container.removeEventListener('mousedown', onClickOutside, true);
      }
    };
  });

  return isOpen
  && (
  <Portal>
    <div
      onMouseDown={e => isDoNotPreventTouch && e.preventDefault()}
      role="none"
      style={{ zIndex }}
      className={`modal-window ${className}`}
      ref={modalContainerRef}
    >
      <ScrollWrapper className="modal-window__scroll-wrapper">
        <div
          className={classNames('modal-window__container', position && `modal-window__container--align-${position}`)}
          ref={bodyModalRef}
        >
          <div className="modal-window__header">
            <h3 className="modal-window__title text--center title--big">
              {t(title)}
            </h3>
            <ButtonsCollection.ButtonClose onClick={onCloseModal} />
          </div>
          {children && <div className="modal-window__body">{children}</div>}
        </div>
      </ScrollWrapper>
    </div>
  </Portal>
  );
});
