import React from 'react';
import View from '../modalView';

const CreateProjectModal = ({ ...props }) => (
  <View
    modalTitle="Create new project"
    action="Create"
    name="createProjectModal"
    {...props}
  />
);
export default CreateProjectModal;
