import React from 'react';
import PropTypes from 'prop-types';

import {
  WINDOW_WIDTH,
} from '../../../../../../../../constants/ui';

import { ButtonsCollection } from '../../../../../../../../ui-kit';
import './style.sass';

const ControlsMessageCard = ({
  isShowActions,
  onClickShowActions,
  vectorDropDown,
  chooseVector,
  onEditMessage,
  onClickDelete,
  isOwner,
  onClickPin,
  onClickUnpin,
  isPinned,
  onSetReplyMessage,
  windowWidth,
}) => (
  <>
    <div className="controls-message" style={{ zIndex: isShowActions ? 2 : 0 }}>
      {/* <ButtonsCollection.ButtonIcons */}
      {/*  title="Reply" */}
      {/*  className="controls-message__answer-message controls-message__btn" */}
      {/* > */}
      {/*  <span className="icon-reply" /> */}
      {/* </ButtonsCollection.ButtonIcons> */}
      {/* <Pin */}
      {/*  className="controls-message__attached-link controls-message__btn" */}
      {/*  onClick={() => openModal(`pinMessageModal${messageId}`)} */}
      {/* /> */}
      <ButtonsCollection.ButtonIcons
        className="controls-message__attached-link controls-message__btn"
        onClick={onClickShowActions}
        title="Show controls for message"
      >
        <span className="icon-settings-vertical">
          <span className="path1" />
          <span
            className="path2"
          />
          <span className="path3" />
        </span>
      </ButtonsCollection.ButtonIcons>
      {
        isShowActions && (
          <div
            className={`controls-message__drop-down dropDown-control-message
             ${vectorDropDown ? 'controls-message__drop-down--to-top' : ''}`}
            ref={e => chooseVector(e)}
          >
            <button
              type="button"
              onClick={isPinned ? onClickUnpin : onClickPin}
              className="dropDown-control-message__button"
            >
              <div className="dropDown-control-message__icon">
                <span className="icon-pin" />
              </div>
              <span className="dropDown-control-message__text">{isPinned ? 'Unpin' : 'Pin'}</span>
            </button>
            {
              isOwner && (
                <>
                  <button
                    type="button"
                    className="dropDown-control-message__button"
                    onClick={onEditMessage}
                  >
                    <div className="dropDown-control-message__icon">
                      <span className="icon-pencil-2" />
                    </div>
                    <span className="dropDown-control-message__text">Edit</span>
                  </button>
                  <button
                    type="button"
                    className="dropDown-control-message__button"
                    onClick={onClickDelete}
                  >
                    <div className="dropDown-control-message__icon">
                      <span className="icon-delete-icon" />
                    </div>
                    <span className="dropDown-control-message__text">

                      Delete
                    </span>
                  </button>
                </>
              )
            }
            {
              windowWidth < WINDOW_WIDTH.X_SMALL && (
                <button
                  type="button"
                  className="dropDown-control-message__button"
                  onClick={onSetReplyMessage}
                >
                  <div className="dropDown-control-message__icon">
                    <span className="icon-reply" />
                  </div>
                  <span className="dropDown-control-message__text">

                      Reply
                  </span>
                </button>
              )
            }
          </div>
        )
      }
    </div>
  </>
);

ControlsMessageCard.propTypes = {
  chooseVector: PropTypes.func,
  onEditMessage: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickShowActions: PropTypes.func.isRequired,
  vectorDropDown: PropTypes.bool,
  isShowActions: PropTypes.bool.isRequired,
  onClickPin: PropTypes.func.isRequired,
  onClickUnpin: PropTypes.func.isRequired,
  isPinned: PropTypes.bool,
  isOwner: PropTypes.bool.isRequired,
  onSetReplyMessage: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

ControlsMessageCard.defaultProps = {
  vectorDropDown: false,
  isPinned: false,
  chooseVector: () => {},
};

export default ControlsMessageCard;
