import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { EmptyResult, TabWrapper } from '@/ui-kit';
import { useKnowledgeBaseContext } from '@/views/knowledgeBase/context/useKnowledgeBaseContext';

import './style.sass';

export const NavCategories = () => {
  const { push } = useHistory();

  const { categoriesData } = useKnowledgeBaseContext();

  const onSubcategoryClick = useCallback((id) => {
    push(`/knowledge-base/subcategory/${id}`)
  }, []);

  return (
    <div className="nav-categories">
      {categoriesData?.length ? (
        <ul className="nav-categories__list">
          {categoriesData.map((category) => (
            <li
              key={`nav-category-item-${category.id}`}
              className="nav-categories__list__item"
            >
              <TabWrapper
                customTitle={() => (
                  <span
                    className="nav-categories__list__item__title"
                    title={category.description}
                  >
                  {category.description}
                </span>
                )}
              >
                <div className="subcategories-list">
                  {category.subCategories.length ? category.subCategories.map((subcategory) => (
                    <div
                      key={`nav-subcategory-item-${subcategory.id}`}
                      className="subcategories-list__item"
                      onClick={() => onSubcategoryClick(subcategory.id)}
                    >
                      {subcategory.description}
                    </div>
                  )) : (
                    <p className="subcategories-list__empty-list">There are no subcategories yet</p>
                  )}
                </div>
              </TabWrapper>
            </li>
          ))}
        </ul>
      ) : (<EmptyResult text="There are no categories yet" />)}
    </div>
  )
}
