import { createActions } from 'redux-actions';
import { identity, pathOr } from 'ramda';
import types from './types';
import { sprintsListSchema } from './schema';

export const {
  getSprintsRequest,
  setSprintsProject,
} = createActions(
  {
    [types.GET_SPRINTS_REQUEST]: [
      identity,
      ({ projectId, ...params }, meta) => ({
        async: true,
        route: `/projects/${projectId}/sprints`,
        selectorName: 'getSprintsRequest',
        method: 'GET',
        params,
        schema: {
          rules: sprintsListSchema,
          pathData: ['data', 'sprints'],
        },
        actions: {
          success: setSprintsProject,
        },
        callbacks: {
          success: pathOr(() => {}, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
  },
  types.SET_SPRINTS_PROJECT,
);
