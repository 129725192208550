import {
  compose, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';

import rules from '../rules';
import { getModal } from '../../../../../state/ui/selectors';
import { projectActions, projectSelectors } from '../../../../../state/project';
import CreateCategory from './createCategory';
import { uiActions } from '../../../../../state/ui';

const mapStateToProps = state => ({
  isOpen: getModal(state)('createCategory'),
  isPending: projectSelectors.getCategoryAddRequestPending(state),
});

const mapDispatchToProps = ({
  closeModal: () => uiActions.closeModal('createCategory'),
  addCategoryTask: projectActions.addCategoryTaskRequest,
});

const formSubmit = (formValues, { resetForm, closeModal, ...props }) => {
  const { addCategoryTask, projectId } = props.props;
  const data = { ...formValues, ...{ id: projectId } };

  addCategoryTask(data, {
    callbacks: {
      success: () => {
        resetForm({});
        props.props.closeModal();
      },
    },
  });
};

const onCloseModalHandler = ({ closeModal, resetForm }) => () => {
  resetForm({});
  closeModal();
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common', 'tasks']),
  withFormik({
    mapPropsToValues: () => ({
      title: '',
    }),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: formSubmit,
  }),
  withHandlers({
    onCloseModal: onCloseModalHandler,
  }),
);

export default enhance(CreateCategory);
