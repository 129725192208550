import { handleActions } from 'redux-actions';
import {
  compose, values, propOr, uniq, uniqBy, prop,
} from 'ramda';
import { mergeDeep, mergeIn } from '../../utils/helpers/ramdaStateHelpers';
import { LEADS_VIEW } from '../../constants/crm';

import types from './types';

const normalizeDataArrToObj = data => data.reduce((acc, item) => ({
  ...acc,
  [item.id]: item,
}), {});

const leadSubDataReducer = key => mergeDeep(({
  payload: {
    data, count, meta: { leadId },
  },
},
{ [key]: { result } }) => {
  const entities = normalizeDataArrToObj(data);
  const dateStateByLeadId = result[leadId];
  const uniqData = compose(uniqBy(prop('id')));
  return {
    [key]: {
      entities,
      result: {
        [leadId]: dateStateByLeadId ? uniqData([...data, ...dateStateByLeadId])
          : uniqData([...data]),
      },
      count,
    },
  };
});

const leadKeyDataReducer = key => mergeIn(({ payload }) => ({
  [key]: payload,
}));

const reducer = handleActions({
  [types.SET_PINNED_LEADS]: mergeIn(({ payload: { data, count, hasMore } }) => ({
    pinnedLeads: {
      ...data,
      count,
      hasMore,
    },
  })),
  [types.SET_LEADS]: mergeIn(({
    payload: {
      data, count, hasMore, summaryCount, offset,
    },
  }) => {
    const { entities } = data;
    const leads = compose(
      values,
      propOr({}, 'leads'),
    )(entities);
    const leadsByStatusId = leads.reduce((acc, lead) => {
      const statusId = lead.pipeline_status_id;
      const accLeadsByStatus = acc[statusId] || [];
      return {
        ...acc,
        [statusId]: [...accLeadsByStatus, lead],
      };
    }, {});
    return {
      summary: {
        ...data,
        count,
        summaryCount,
        hasMore,
        offset,
      },
      board: leadsByStatusId,
    };
  }),
  [types.SET_LEADS_AUTOCOMPLETE]: mergeDeep(({
    payload: {
      clients, count,
    },
  }) => ({
    leadsAutocomplete: {
      clients,
      count,
    },
  })),
  [types.SET_ACTIVE_RELATED_LEAD]: mergeIn(({ payload }) => ({
    activeRelatedLead: payload,
  })),
  [types.SET_LEAD_LOGS]: mergeIn(({ payload: { data, meta: { leadId } } }) => ({
    leadLogs: {
      [leadId]: data,
    },
  })),
  [types.REORDER_PIPE_VIEW_LEAD]: mergeDeep(({
    payload: {
      leads, pipeline_status_id: statusId,
    },
  }) => ({
    board: {
      [statusId]: leads,
    },
  })),
  [types.UPDATE_LEAD_SUMMARY]: mergeDeep(({
    payload,
  }) => ({
    summary: {
      entities: {
        leads: {
          [payload.id]: payload,
        },
      },
    },
  })),
  [types.DELETE_LEAD_NOTE_SUCCESS]: mergeDeep(({ payload: { id, noteId } }, result) => ({
    leadsNotes:
      {
        result: {
          [id]: result.leadsNotes.result[id].filter(n => n.id !== noteId),
        },
      },
  })),
  [types.EDIT_LEAD_NOTE_SUCCESS]: mergeDeep(({ payload: { id } }, result) => ({
    leadsNotes:
        {
          result: {
            [id]: uniq(...result.leadsNotes.result[id]),
          },
        },
  })),
  [types.EDIT_LEAD_TASK_SUCCESS]: mergeDeep(({ payload: { selectedLeadId } }, result) => ({
    leadsTasks:
        {
          result: {
            [selectedLeadId]: uniq(...result.leadsTasks.result[selectedLeadId]),
          },
        },
  })),
  [types.FULL_EDIT_LEAD_TASK_SUCCESS]: mergeDeep(({ payload: { leadId } }, result) => ({
    leadsTasks:
      {
        result: {
          [leadId]: uniq(...result.leadsTasks.result[leadId]),
        },
      },
  })),
  [types.DELETE_LEAD_TASK_SUCCESS]: mergeDeep(({ payload: { task, selectedLeadId } }, result) => ({
    leadsTasks:
      {
        result: {
          [selectedLeadId]: result.leadsTasks.result[selectedLeadId].filter(t => t.id !== task.id),
        },
      },
  })),
  [types.CHANGE_TASK_STATUS_SUCCESS]: mergeDeep(({ payload: { leadId } }, result) => ({
    leadsTasks:
      {
        result: {
          [leadId]: uniq(...result.leadsTasks.result[leadId]),
        },
      },
  })),
  [types.DELETE_LEAD_CONTACT_SUCCESS]: mergeDeep(({ payload: { leadId, selectedContactId } },
    result) => ({
    leadsContacts:
        {
          result: {
            [leadId]: result.leadsContacts.result[leadId].filter(l => l.id !== selectedContactId),
          },
        },
  })),
  [types.EDIT_LEAD_CONTACT_SUCCESS]: mergeDeep(({ payload: clientId }, result) => ({
    leadsContacts:
        {
          result: {
            [clientId]: uniq(...result.leadsContacts.result[clientId]),
          },
        },
  })),
  [types.SET_ADDED_LEAD_CONTACT]: mergeDeep(({ payload: { contact } }, result) => ({
    leadsContacts:
        {
          result: {
            [contact.client_id]: uniq(...result.leadsContacts.result[contact.client_id], contact),
          },
        },
  })),
  [types.SET_CHECKED_PIPELINE]: leadKeyDataReducer('checkedPipeline'),
  [types.SET_LEADS_VIEW]: leadKeyDataReducer('leadsView'),
  [types.SET_LEAD_TASKS]: leadSubDataReducer('leadsTasks'),
  [types.SET_LEAD_CONTACTS]: leadSubDataReducer('leadsContacts'),
  [types.SET_LEAD_NOTES]: leadSubDataReducer('leadsNotes'),
}, {
  summary: {
    entities: {},
    result: [],
    summaryCount: {
      all: 0,
      won: 0,
      lost: 0,
    },
  },
  board: {},
  pinnedLeads: {
    entities: {},
    result: [],
  },
  leadsAutocomplete: {
    clients: [],
  },
  leadLogs: {},
  leadsTasks: {
    entities: {},
    result: {},
  },
  leadsContacts: {
    entities: {},
    result: {},
  },
  leadsNotes: {
    entities: {},
    result: {},
    count: 0,
  },
  activeRelatedLead: null,
  checkedPipeline: null,
  leadsView: LEADS_VIEW.LIST,
});

export default reducer;
