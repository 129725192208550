import * as yup from 'yup';
import { reduce } from 'ramda';

import { helpers } from '../../../utils';

const { translateHelpers } = helpers;

const rulesWithFields = (fields = []) => {
  const fieldsSchema = reduce((accum, index) => ({
    ...accum,
    [index]: yup
      .string()
      .test({
        test: (val = '') => val && val.trim(),
        message: {
          message: 'Title is required',
        },
      })
      .required({
        message: 'Title is required',
      }),
  }), {}, fields);
  return yup.object().shape({
    title: yup
      .string()
      .test({
        test: (val = '') => val && val.trim(),
        message: {
          field: 'title',
          message: 'required',
          params: {
            key: translateHelpers.t('common', 'Title'),
          },
        },
      })
      .required({
        field: 'title',
        message: 'required',
        params: {
          key: translateHelpers.t('common', 'Title'),
        },
      }),
    ...fieldsSchema,
  });
};

export default rulesWithFields;
