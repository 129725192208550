import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { GitlabCommitsGrid } from '../GitlabCommitsGrid';

import api from '../../../../api/tasks.service';

import { callNotification } from '../../../../utils/helpers/notifies';
import { debounce } from '../../../../utils/helpers/commonHelpers';
import { useCommitsFilters } from '../../hooks';

import './style.sass';


/**
 * @param title {string}
 * @returns {JSX.Element}
 * @constructor
 */
const DEBOUNCE_TIMEOUT = 800;
const FIRST_PAGE = 1;
const INITIAL_COMMITS_COUNT = 0;

export const GitlabCommitsByTask = (
  { title },
) => {
  const { t } = useTranslation(['common']);
  const { taskId } = useParams();
  const { id: projectId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [commits, setCommits] = useState([]);
  const [totalCommits, setTotalCommits] = useState(INITIAL_COMMITS_COUNT);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(FIRST_PAGE);


  const onGoPreviousPage = () => {
    setCurrentPage(prevState => prevState - 1);
  };

  const onGoNextPage = () => {
    if (currentPage < totalPages) { setCurrentPage(prevState => prevState + 1); }
  };

  const {
    titleValue,
    authorValue,
    onChangeTitleValue,
    onChangeAuthorValue,
  } = useCommitsFilters();

  const fetchTaskCommits = useCallback(debounce(
    async (params) => {
      try {
        const { data, total, lastPage } = await api.getTaskCommits(projectId, taskId, params);
        setTotalCommits(total);
        setTotalPages(lastPage);
        setCommits(data);
      } catch (error) {
        callNotification.error(t('Can\'t get commits'));
      }
    }, DEBOUNCE_TIMEOUT,
  ), []);

  useEffect(() => {
    fetchTaskCommits({ page: currentPage, title: titleValue, author: authorValue });
  },
  [currentPage, titleValue, authorValue]);

  useEffect(() => {
    setCurrentPage(FIRST_PAGE);
  }, [titleValue, authorValue]);

  useEffect(() => {
    setIsOpen(true);
    return () => { setIsOpen(false); };
  }, []);

  return (
    <div className="task-commits">
      <p className="task-commits__title"># {taskId} {title }</p>
      <GitlabCommitsGrid
        name={`task-${taskId}`}
        onGoPreviousPage={onGoPreviousPage}
        onGoNextPage={onGoNextPage}
        totalPages={totalPages}
        commits={commits}
        currentPage={currentPage}
        totalCommits={totalCommits}
        isOpen={isOpen}
        titleValue={titleValue}
        authorValue={authorValue}
        onChangeAuthorValue={onChangeAuthorValue}
        onChangeTitleValue={onChangeTitleValue}
      />
    </div>
  );
};
