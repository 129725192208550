export const CUSTOM_API = {
  BASE: '/custom/',
  CREATE: '/custom/create/',
  DELETE: '/custom/delete/',
  GET: '/custom/get/',
  UPDATE_VALUE: '/custom/update-value/',
  UPDATE_NAME: '/custom/update/',
  UPDATE_PRIORITY: '/custom/update/priority/',
  BUSINESS_PROCESSES: '/business-processes/',
};
