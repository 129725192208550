import * as activity from './activity';
import * as colors from './colors';
import * as config from './config';
import * as crm from './crm';
import * as customFields from './customFields';
import * as files from './files';
import * as messenger from './messenger';
import * as projects from './projects';
import * as sprints from './sprints';
import * as tasks from './tasks';
import * as ui from './ui';
import * as users from './users';
import * as windowScreen from './window-screen';

export {
  config,
  messenger,
  projects,
  ui,
  tasks,
  sprints,
  users,
  files,
  customFields,
  activity,
  crm,
  colors,
  windowScreen,
};
