import React from 'react';

import { ScrollWrapper } from '../../index';

/**
 *
 * @param children {element}
 * @returns {JSX.Element}
 * @constructor
 */

const Menu = ({ children }) => (
  <ScrollWrapper
    className="auto-complete__scroll-wrapper"
    autoHeight
    autoHideHover
    renderThumbHorizontal={() => <span />}
    renderTrackVertical={({ style, ...props }) => (
      <div style={{ ...style }} {...props} className="auto-complete__track" />
    )}
    renderThumbVertical={props => <div {...props} className="auto-complete__thumb" />}
  >
    {children}
  </ScrollWrapper>
);

export default Menu;
