import React from 'react';
import PropTypes from 'prop-types';
import { AddProductForm, ProductsList } from './components';
import { DeleteProductModal } from './modals';
import './style.sass';

const Products = ({
  clientId, onDeleteProductHandler,
}) => (
  <>
    <AddProductForm clientId={clientId} />
    <ProductsList clientId={clientId} />
    <DeleteProductModal onDelete={onDeleteProductHandler} clientId={clientId} />
  </>
);

Products.propTypes = {
  clientId: PropTypes.number.isRequired,
  onDeleteProductHandler: PropTypes.func.isRequired,
};

export default Products;
