import React from 'react';
import PropTypes from 'prop-types';

import UnregisteredRoutes from './unregisteredRoutes';
import AuthenticatedRoutes from './authenticatedRoutes';
import { ROUTE_TYPES } from '../../../constants/ui';

const Routes = ({ routeTypes }) => (
  <>
    { routeTypes === ROUTE_TYPES.AUTHENTICATED && (<AuthenticatedRoutes />)}
    { routeTypes === ROUTE_TYPES.GUEST && (<UnregisteredRoutes />)}
  </>
);

Routes.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  routeTypes: PropTypes.string.isRequired,
};

export default Routes;
