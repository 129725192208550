import { useDispatch } from 'react-redux';
import { uiActions } from '@/state/ui';
import { bpActions } from '@bp/store/bpModule';
import { getAutoCompleteAction } from './constants';

export const useFieldByOption = () => {
  const dispatch = useDispatch();

  const onOpenModalAddVariable = (name) => () => {
    dispatch(bpActions.selectVariableKey(name));
    dispatch(uiActions.openModal('businessProcessVariablesModal'));
  };

  const getAutoComplete = (name) => (fieldValue, callback) => {
    const { callback: mapResponse, action } = getAutoCompleteAction(name);
    dispatch(
      action(
        { name: fieldValue, limit: 10 },
        {
          callbacks: {
            success: (payload) => callback(mapResponse(payload)),
          },
        },
      ),
    );
  };

  return { onOpenModalAddVariable, getAutoComplete };
};
