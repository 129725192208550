import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  propOr, prop, path,
} from 'ramda';

import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonsCollection, ModalWindow, DefaultAutoComplete, AsyncAutoComplete, FormsCollection,
} from '../../ui-kit';
import { projectsSelectors } from '../../state/projects';
import { projectSelectors } from '../../state/project';
import { userSelectors } from '../../state/user';
import { getUsersForSelect } from '../../state/users/selectors';
import { getUsersListAutocompleteRequest } from '../../state/users/actions';

const ModalView = ({
  isOpen,
  isPending,
  onCloseModal,
  handleSubmit,
  handleChange,
  setFieldValue,
  handleBlur,
  touched,
  values,
  errors,
  action,
  priorities,
  name,
  modalTitle,
  t,
  getUsersAutocomplete,
  getProject,
  onChangeProject,
  categoriesList,
  isDisabledProjectField,
  membersListOfSelectedProject,
  statuses,
  sprintsList,
  dateFormat,
  task,
}) => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const currentProject = useSelector(projectsSelectors.getProject)(id);
  const isPrivate = currentProject.type === 0;

  const memberListIds = useSelector(projectSelectors.getProjectMembersList);
  const allUsers = useSelector(getUsersForSelect);
  const currentUser = useSelector(userSelectors.getUserData);
  const invitedMembers = Object.values(allUsers).filter(user => memberListIds.includes(user.value));
  const invitedMembersWithNone = [{ label: 'None', value: null, username: '' }, ...invitedMembers];

  const isAssignee = currentUser.id === task?.assignee;
  const valueDescription = () => {
    if (values.description === null) {
      return '';
    }
    return values.description;
  };

  useEffect(() => {
    dispatch(getUsersListAutocompleteRequest());
  }, []);
  return (
    <ModalWindow
      isOpen={isOpen}
      onClose={() => onCloseModal(name)}
      className="create-task__modal"
      title={modalTitle}
    >
      <FormsCollection.FormWrapper
        handleSubmit={handleSubmit}
        className="create-task"
      >
        <div className="create-task__row">
          <FormsCollection.Input
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            error={propOr('', 'message', errors.title)}
            touched={touched.title}
            name="title"
            placeholderJump={t('Task name')}
            className="create-task__title-field"
            id="create-task__title"
          />
        </div>
        <div className="create-task__row create-task__row--description">
          <FormsCollection.TextArea
            onChange={handleChange}
            onBlur={handleBlur}
            value={valueDescription()}
            error={propOr('', 'message', errors.description)}
            touched={touched.description}
            placeholderJump={t('Description')}
            name="description"
            autoHeight
            maxHeight={190}
            tabIndex={0}
          />
        </div>
        <div className="create-task__row">
          <div className="create-task__col-label ">
            {t('Watchers')}
          </div>
          <div className="create-task__col-fields">
            {isPrivate ? (
              <DefaultAutoComplete
                isMulti
                name="watchers"
                placeholder={t('Choose watchers')}
                closeMenuOnSelect={false}
                options={invitedMembers}
                onChange={value => setFieldValue('watchers', value)}
                value={values.watchers}
                touched={touched.watchers}
                defaultValue={values.watchers}
              />
            )
              : (
                <AsyncAutoComplete
                  isMulti
                  name="watchers"
                  key={`watcher-field-${membersListOfSelectedProject}`}
                  placeholder={t('Choose watchers')}
                  error={errors.watchers}
                  closeMenuOnSelect={false}
                  value={values.watchers}
                  clearable
                  autoload={false}
                  defaultValue={values.watchers}
                  loadOptions={getUsersAutocomplete}
                  onChange={value => setFieldValue('watchers', value)}
                  touched={touched.watchers}
                />
              )}
          </div>
        </div>
        <div className="create-task__row">
          <div
            className="create-task__col create-task__col-select create-task__col--offset create-task__col--assignee"
          >
            <div className="create-task__col-label">
              {t('Assignee')}
            </div>
            <div className="create-task__col-fields">
              {isPrivate ? (
                <DefaultAutoComplete
                  name="assignee"
                  placeholder={t('Choose assignee')}
                  closeMenuOnSelect
                  options={invitedMembersWithNone}
                  onChange={value => setFieldValue('assignee', value)}
                  value={values.assignee}
                  touched={touched.assignee}
                  defaultValue={values.assignee}
                  isDisabled={isAssignee}
                />
              ) : (
                <AsyncAutoComplete
                  key={`assignee-field-${membersListOfSelectedProject}`}
                  name="assignee"
                  placeholder={t('Choose assignee')}
                  error={propOr('', 'message', errors.assignee)}
                  closeMenuOnSelect
                  touched={touched.assignee}
                  value={values.assignee}
                  defaultValue={values.assignee}
                  loadOptions={getUsersAutocomplete}
                  onChange={value => setFieldValue('assignee', value)}
                  disabled={isAssignee}
                />
              )}
            </div>
          </div>
          <div className="create-task__col create-task__col-select create-task__col--assignee">
            <div className="create-task__col-label">
              {t('Project')}
            </div>
            <div className="create-task__col-fields">
              <AsyncAutoComplete
                name="project_id"
                placeholder={t('Choose project')}
                error={prop('id', errors.project_id)}
                closeMenuOnSelect
                disabled={isDisabledProjectField}
                value={values.project_id}
                defaultValue={values.project_id}
                loadOptions={getProject}
                onChange={onChangeProject}
                touched
              />
            </div>
          </div>
        </div>
        <div className="create-task__row">
          <div className="create-task__col  create-task__col-select create-task__col--offset">
            <div className="create-task__col-label">
              Status
            </div>
            <div className="create-task__col-fields">
              <DefaultAutoComplete
                name="status_id"
                placeholder="Status"
                error={propOr('', 'message', errors.status_id)}
                closeMenuOnSelect
                isDisabled={!path(['project_id', 'value'], values)}
                touched={touched.status_id}
                value={path(['project_id', 'value'], values) ? values.status_id : null}
                options={statuses}
                defaultValue={values.status_id}
                onChange={value => setFieldValue('status_id', value)}
              />
            </div>
          </div>
          <div className="create-task__col create-task__col-select">
            <div className="create-task__col-label">
              {t('Category')}
            </div>
            <div className="create-task__col-fields">
              <DefaultAutoComplete
                name="category_id"
                placeholder={t('Choose category')}
                error={propOr('', 'message', errors.category_id)}
                closeMenuOnSelect
                isDisabled={!path(['project_id', 'value'], values)}
                touched={touched.category_id}
                defaultValue={values.category_id}
                value={values.category_id}
                options={categoriesList}
                onChange={value => setFieldValue('category_id', value)}
              />
            </div>
          </div>
        </div>
        <div className="create-task__row">
          <div className="create-task__col create-task__estimation-field create-task__col--offset">
            <div className="create-task__col-label">
              {t('Estimation')}
            </div>
            <div className="create-task__col-fields">
              <FormsCollection.Input
                name="estimated_time"
                id="create-task__estimated-time"
                onChange={handleChange}
                placeholder={t('1w 2d 21h 30m')}
                onBlur={handleBlur}
                value={values.estimated_time}
                touched
                error={errors.estimated_time}
              />
            </div>
          </div>
          <div className="create-task__col create-task__col-select create-task__col--assignee">
            <div className="create-task__col-label">
              {t('Deadline')}
            </div>
            <div className="create-task__col-fields">
              <FormsCollection.DatePicker
                placeholder="DD MM YYYY"
                dateFormat={dateFormat}
                name="deadline"
                onChange={handleChange}
                value={values.deadline}
                dateError={t('You have entered incorrect date')}
                error={errors.deadline}
                minDate={new Date()}
                touched={touched.deadline}
              />
            </div>
          </div>
        </div>
        <div className="create-task__row">
          <div
            className="create-task__col create-task__col-select create-task__col--offset create-task__col--assignee"
          >
            <div className="create-task__col-label">
              {t('Priority')}
            </div>
            <div className="create-task__col-fields">
              <FormsCollection.Select
                isRequired
                onChange={handleChange}
                name="priority"
                value={values.priority}
                error={prop('message', errors.priority)}
                data={priorities}
              />
            </div>
          </div>
          <div className="create-task__col create-task__col-select">
            <div className="create-task__col-label">
              {t('Sprint')}
            </div>
            <div className="create-task__col-fields">
              <DefaultAutoComplete
                name="sprint_id"
                placeholder={t('Choose sprint')}
                error={propOr('', 'message', errors.sprint_id)}
                closeMenuOnSelect
                isDisabled={!path(['project_id', 'value'], values)}
                touched={touched.sprint_id}
                value={values.sprint_id}
                options={sprintsList}
                defaultValue={values.sprint_id}
                onChange={value => setFieldValue('sprint_id', value)}
              />
            </div>
          </div>
        </div>
        <div className="button-group">
          <ButtonsCollection.ButtonBrill
            className="button--md create-task__button-save button-brill--fill"
            type="submit"
            pending={isPending && path(['submitButton'], values) === 'create'}
            onClick={() => setFieldValue('submitButton', 'create')}
            disabled={values.deadline && (values.deadline < new Date().setHours(0, 0, 0, 0))}
          >
            {action}
          </ButtonsCollection.ButtonBrill>
          {
            name === 'createTaskModal' && (
              <ButtonsCollection.ButtonBrill
                pending={isPending && path(['submitButton'], values) !== 'create'}
                className="button--md create-task__button-save-create"
                type="submit"
                onClick={() => setFieldValue('submitButton', 'saveAndCreate')}
              >
                {t('Save and create another')}
              </ButtonsCollection.ButtonBrill>
            )
          }
        </div>
      </FormsCollection.FormWrapper>
    </ModalWindow>
  );
};

ModalView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  onChangeProject: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  getUsersAutocomplete: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  priorities: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string.isRequired,
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  categoriesList: PropTypes.arrayOf(Object).isRequired,
  sprintsList: PropTypes.arrayOf(Object).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  getProject: PropTypes.func.isRequired,
  isDisabledProjectField: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  membersListOfSelectedProject: PropTypes.instanceOf(Array).isRequired,
  statuses: PropTypes.instanceOf(Object).isRequired,
  dateFormat: PropTypes.string,
};

ModalView.defaultProps = {
  dateFormat: '',
};

export default ModalView;
