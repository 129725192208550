import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';

import { ButtonsCollection, ModalWindow, FormsCollection } from '../../../../ui-kit';
import './style.sass';

const ModalView = ({
  isOpen,
  onCloseModal,
  handleSubmit,
  handleChange,
  handleBlur,
  touched,
  values,
  errors,
  isPending,
  title,
  titleAction,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    className="create-category__modal"
    title={title}
  >
    <FormsCollection.FormWrapper
      handleSubmit={handleSubmit}
      className="create-category"
    >
      <div className="create-category__row">
        <FormsCollection.Input
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.title}
          error={propOr('', 'message', errors.title)}
          touched={touched.title}
          name="title"
          placeholderJump="Category name"
          className="create-category__title-field"
          id="create-category__title"
        />
      </div>
      <div className="button-group">
        <ButtonsCollection.ButtonBrill
          pending={isPending}
          className="button--md create-category__button-save button-brill--fill"
          type="submit"
        >
          {titleAction}
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);

ModalView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  isPending: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titleAction: PropTypes.string.isRequired,
};

export default ModalView;
