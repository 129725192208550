import {
  compose, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  isEmpty, prop, pathOr, type,
} from 'ramda';
import { memo } from 'react';

import { withTasksCRUDHandlers, withUserProfile } from '../../../../utils/enchancers';
import { projectSelectors } from '../../../../state/project';
import TaskItem from './taskItem';
import { getTimeStringBySeconds } from '../../../../utils/helpers/dateHelpers';
import { getTaskProgress } from '../../../../utils/helpers/taskHelpers/common';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { usersSelectors } from '../../../../state/users';
import { withPermissionHandlers } from '../../../../utils/helpers/permissionHelpers';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import { IMAGES_SIZE } from '../../../../constants/ui';

const mapStateToProps = (state, { isTaskHasSprint, id }) => ({
  categories: projectSelectors.getProjectCategoriesTasks(state),
  getUserById: usersSelectors.getUser(state),
  data: isTaskHasSprint ? projectSelectors.getProjectSprintTask(state)(id)
    : projectSelectors.getBacklogTask(state)(id),
  isTaskPin: pathOr(false, [id, 'id'], projectSelectors.getPinnedTasksEntities(state)),
  agendaColumn: projectSelectors.getAgenda(state)((isTaskHasSprint
    ? projectSelectors.getProjectSprintTask(state)(id)
    : projectSelectors.getBacklogTask(state)(id)).status_id),
});

const onOpenProfileAssigneeHandler = ({
  onSetUserProfile,
  data,
}) => () => onSetUserProfile(prop('assignee', data));

const onOpenProfileAuthorHandler = ({
  onSetUserProfile,
  data,
}) => () => onSetUserProfile(prop('author', data));

const getClassByStatus = (status) => {
  if (status === 0) {
    return 'project-task__row--new';
  }
  if (status === 4) {
    return 'project-task__row--closed';
  }
  return '';
};

const onEditHandler = ({
  onEditTask, projectId, id, data,
}) => () => {
  onEditTask({ taskId: id, projectId, clientId: data.client_id });
};

const onCopyHandler = ({
  data,
}) => () => {
  const url = `${window.location.host}/projects/${data.project_id}/task/${data.id}`;
  navigator.clipboard.writeText(url);
};

const onDeleteHandler = ({
  onDeleteTask, projectId, id, isTaskHasSprint,
}) => () => {
  onDeleteTask({ taskId: id, projectId, meta: ({ isHasSprint: isTaskHasSprint }) });
};

const onPinHandler = ({
  onPinTask, projectId, id,
}) => () => {
  onPinTask({ taskId: id, projectId });
};
const onUnpinHandler = ({
  onUnpinTask, projectId, id,
}) => () => {
  onUnpinTask({ taskId: id, projectId });
};


const enhance = compose(
  connect(mapStateToProps, null),
  withUserProfile,
  withTasksCRUDHandlers,
  withTranslation(['common']),
  withProps(({ data, getUserById }) => {
    const {
      estimated_time, spent_time, status, author, assigneeUser,
    } = data;

    const authorData = getUserById(author);
    const assigneeData = getUserById(assigneeUser);
    const assigneeAvatarData = prop('avatar', assigneeData);
    const assigneeAvatar = type(assigneeAvatarData) === 'Object'
      ? getImageUrl(assigneeAvatarData.id, IMAGES_SIZE.xs)
      : getImageUrl(assigneeAvatarData, IMAGES_SIZE.xs);

    return {
      progress: getTaskProgress(data),
      classNameStatus: getClassByStatus(status),
      authorName: getFullName(authorData) || null,
      author: authorData,
      assignee: isEmpty(assigneeData) ? false : assigneeData,
      assigneeName: getFullName(assigneeData) || null,
      estimatedTime: getTimeStringBySeconds(estimated_time),
      spentTime: getTimeStringBySeconds(spent_time),
      assigneeAvatar: assigneeAvatar || '',
    };
  }),
  withHandlers({
    onEdit: onEditHandler,
    onCopy: onCopyHandler,
    onDelete: onDeleteHandler,
    onPin: onPinHandler,
    onUnpin: onUnpinHandler,
    onOpenProfileAssignee: onOpenProfileAssigneeHandler,
    onOpenProfileAuthor: onOpenProfileAuthorHandler,
  }),
  withPermissionHandlers,
  memo,
);
export default enhance(TaskItem);
