import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './ui-kit';
import * as serviceWorker from './serviceWorker';
import './utils/styles/normallize.css';
import './utils/styles/style.sass';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register();
