import {
  cond, path, prop, propOr, T, compose, subtract, add, and, equals, reject,
} from 'ramda';
import { normalize } from 'normalizr';
import { Either } from 'ramda-fantasy';
import { isNotNil, notEqual } from 'ramda-extension';
import tasksTypes from '../../../state/tasks/types';

import {
  getAgendaTasksList,
  getBacklogTasksList,
  getProjectSprint,
  getProjectSprintTasks,
  getProjectSprintTasksList,
} from '../../../state/project/selectors';
import { tasksSchema } from '../../../state/project/schema';
import * as taskActions from '../../../state/task/actions';
import * as projectActions from '../../../state/project/actions';
import {
  makePayloadDeleteBacklogTask,
  makePayloadTaskWithSprint,
  deleteTask, eitherTaskHasSprint, makePayloadDeleteAgendaTask, deleteTaskWithSprint,
} from '../../helpers/taskHelpers/crudHelper';
import { setTaskToBacklog, setTaskToSprint } from '../../helpers/projectHelpers/crudHelper';
import { curriedNext } from '../../helpers/commonHelpers';
import { projectSelectors } from '../../../state/project';

const updateSprintEstimation = (task, state, next) => {
  const taskInStore = projectSelectors.getTask(state)(task.id);
  const sprint = projectSelectors.getProjectSprint(state)(task.sprint_id);
  const oldSprint = projectSelectors.getProjectSprint(state)(taskInStore.sprint_id);

  const newTaskEstimate = propOr(0, 'estimated_time', task);
  const sprintEstimatedTime = propOr(0, 'totalEstimated', sprint);

  const newTaskSpend = propOr(0, 'spent_time', task);
  const sprintSpendTime = propOr(0, 'totalSpend', sprint);

  const isChangedSprint = and(isNotNil(taskInStore.sprint_id),
    notEqual(task.sprint_id, taskInStore.sprint_id));

  const newEstimateTime = cond([
    [compose(and(equals(sprint.id, taskInStore.sprint_id)), isNotNil), compose(add(newTaskEstimate),
      subtract(sprintEstimatedTime), propOr(0, 'estimated_time'))],
    [T, () => add(sprintEstimatedTime, newTaskEstimate)],
  ])(taskInStore);
  const newSpendTime = cond([
    [compose(and(equals(sprint.id, taskInStore.sprint_id)), isNotNil), compose(add(newTaskSpend),
      subtract(sprintSpendTime), propOr(0, 'spent_time'))],
    [T, () => add(sprintSpendTime, newTaskSpend)],
  ])(taskInStore);

  const updateOldSprintEstimated = oldSprint.totalEstimated - taskInStore.estimated_time;
  const updateOldSprintSpend = oldSprint.totalSpend - taskInStore.spent_time;


  if (isChangedSprint) {
    next(projectActions.setSprint({
      sprint: {
        ...oldSprint,

        totalSpend: updateOldSprintSpend,
        totalEstimated: updateOldSprintEstimated,
      },
    }));
  }
  next(projectActions.setSprint({
    sprint: {
      ...sprint,
      totalSpend: newSpendTime,
      totalEstimated: newEstimateTime,
    },
  }));
};

const setTaskMiddleware = ({ getState }) => next => (action) => {
  if (action.type === tasksTypes.SET_TASK) {
    const { payload: { task, meta = {} } } = action;
    const state = getState();
    const isTaskHasSprint = prop('sprint_id', task);
    const getAuthor = path(['entities', 'author']);
    const getAssigneeUser = path(['entities', 'assigneeUser']);
    const getTask = path(['entities', 'tasks']);
    const data = normalize(task, tasksSchema);
    const taskId = task.id;
    const prevTask = projectSelectors.getTaskFromAnyStore(state, taskId);
    const prevTaskStatus = propOr(null, 'status_id', prevTask);
    const prevSprintId = propOr(null, 'sprint_id', prevTask);
    const tasksListInSprint = prevSprintId
      ? projectSelectors.getProjectSprintTasksList(state)(prevSprintId)
      : projectSelectors.getBacklogTasksList(state);
    const newTasksListForOldSprint = reject(equals(taskId), tasksListInSprint);
    const isChangeAfterDrop = prop('isAfterDrop', meta);
    const isNewTask = prop('isNewTask', meta);

    if (meta.isSetActiveTask) next(taskActions.updateActiveTask(action.payload));
    if (prevTask.id) {
      if (prevSprintId) {
        next(projectActions.reorderSprintTasks({
          sprintId: prevSprintId,
          tasks: newTasksListForOldSprint,
        }));
      } else {
        next(projectActions.reorderBacklogTasks({
          tasks: newTasksListForOldSprint,
        }));
      }
    }
    if (!isTaskHasSprint) {
      setTaskToBacklog(getAuthor(data), getAssigneeUser(data), getTask(data), taskId, next, {});
    } else {
      updateSprintEstimation(task, getState(), next);
      setTaskToSprint(getAuthor(data), getAssigneeUser(data), getTask(data), taskId, next,
        { isNewTask });
      if (!isChangeAfterDrop) {
        if (prevTaskStatus && prevTaskStatus !== task.status_id) {
          next(projectActions.deleteTaskOfAgenda({ status_id: prevTaskStatus, taskId }));
        }
        next(projectActions.setTaskToAgenda({ status_id: task.status_id, taskId }));
      }
    }
  } else {
    next(action);
  }
};

const deleteTaskMiddleware = ({ getState }) => next => (action) => {
  if (action.type === tasksTypes.DELETE_TASK) {
    const { payload: { task, meta } } = action;

    const state = getState();
    const isAgendaBoard = prop('isAgendaBoard', meta);

    const deleteTaskFromSprint = (data) => {
      const sprintId = prop('sprint_id', task);
      const sprint = getProjectSprint(state)(sprintId);
      return deleteTask(
        curriedNext(next, projectActions.deleteProjectTask),
        makePayloadTaskWithSprint(sprint),
        getProjectSprintTasksList(state)(sprintId),
        getProjectSprintTasks(state),
      )(data);
    };

    const deleteTaskFromBacklog = data => deleteTask(
      curriedNext(next, projectActions.sortBacklogTasks),
      makePayloadDeleteBacklogTask,
      getBacklogTasksList(state),
      {},
    )(data);

    const deleteTaskFromAgenda = (data) => {
      const statusId = prop('status_id', data);
      return deleteTask(
        curriedNext(next, projectActions.reorderAgendaTasks),
        makePayloadDeleteAgendaTask(statusId),
        getAgendaTasksList(state)(statusId),
        {},
      )(data);
    };

    const deleteTaskFromAgendaOrSprint = deleteTaskWithSprint(
      deleteTaskFromAgenda,
      deleteTaskFromSprint,
    );

    Either.either(
      deleteTaskFromBacklog,
      deleteTaskFromAgendaOrSprint(isAgendaBoard),
    )(eitherTaskHasSprint(task));
  } else {
    next(action);
  }
};


export {
  setTaskMiddleware,
  deleteTaskMiddleware,
};
