import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { RightSideBar } from '../../../../newContainers';
import { ScrollWrapper, TabWrapper, ActiveTabTitle } from '../../../../ui-kit';
import {
  AboutProject, ProjectSummary, Members, PinnedTasks,
} from '..';
import { ImportantTasks, TabWrapperMobile } from '../../../../newContainers/rightSideBar/components';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import './style.sass';
import api from '../../../../api/projects.service';
import { TASKS_WITHOUT_PROJECT } from '../../../../constants/tasks';

const REQUEST_PARAMS = {
  important: true,
};


const RightSideBarContainer = ({
  setActiveTabTitleStateHandler, windowWidth, activeTabTitle, isNotPublic,
  projectId, t,
}) => {
  const [importantTasks, setImportantTasks] = useState([]);
  const { id } = useParams();

  const getImportantTasks = useCallback(async (value) => {
    const data = await api.getImportantTasksByProjectRequest(projectId, value);
    if (data) {
      setImportantTasks(data);
    }
    return null;
  }, [projectId]);

  useEffect(() => {
    if (id !== TASKS_WITHOUT_PROJECT) {
      getImportantTasks(REQUEST_PARAMS);
    }
  }, []);

  return (
    <>
      {
        windowWidth > WINDOW_WIDTH.SMALL ? (
          <RightSideBar className="right-side-bar--project" title="Project summary">
            <ScrollWrapper className="right-side-bar__scroll-wrapper">
              <AboutProject projectId={projectId} />
              <ProjectSummary />
              <ImportantTasks
                importantTasks={importantTasks}
                activeTabTitle={activeTabTitle}
                setActiveTitle={setActiveTabTitleStateHandler}
              />
              <TabWrapper title={(
                <h2 className="title">
                  Pinned tasks
                </h2>
              )}
              >
                <PinnedTasks
                  className="pinned-tasks--right-side-bar"
                  projectId={projectId}
                  activeTabTitle={activeTabTitle}
                  setActiveTitle={setActiveTabTitleStateHandler}
                />
              </TabWrapper>
              {
                isNotPublic && (
                  <TabWrapper
                    title={(
                      <h3 className="title">
                        {t('Members')}
                      </h3>
                    )}
                  >
                    <Members
                      activeTabTitle={activeTabTitle}
                      projectId={projectId}
                      setActiveTitle={setActiveTabTitleStateHandler}
                    />
                  </TabWrapper>
                )
              }
            </ScrollWrapper>
          </RightSideBar>
        ) : (
          <RightSideBar
            className="right-side-bar--project"
            titleComponentRender={activeTabTitle ? (
              () => (
                <ActiveTabTitle
                  onClick={() => setActiveTabTitleStateHandler()}
                  title={activeTabTitle}
                />
              )
            ) : (
              () => <h3>{t('Project summary')}</h3>
            )}
          >
            <ScrollWrapper className="right-side-bar__scroll-wrapper">
              <AboutProject projectId={projectId} />
              <ProjectSummary />
              <ImportantTasks
                projectId={projectId}
                activeTabTitle={activeTabTitle}
                setActiveTitle={setActiveTabTitleStateHandler}
              />
              <TabWrapperMobile
                setActiveTitle={setActiveTabTitleStateHandler}
                title="Pinned tasks"
                active={activeTabTitle}
              >
                <PinnedTasks
                  projectId={projectId}
                  activeTabTitle={activeTabTitle}
                  setActiveTitle={setActiveTabTitleStateHandler}
                />
              </TabWrapperMobile>
              {
                isNotPublic && (
                  <Members
                    activeTabTitle={activeTabTitle}
                    projectId={projectId}
                    setActiveTitle={setActiveTabTitleStateHandler}
                  />
                )
              }
            </ScrollWrapper>
          </RightSideBar>
        )
      }
    </>
  );
};

RightSideBarContainer.propTypes = {
  windowWidth: PropTypes.number.isRequired,
  activeTabTitle: PropTypes.string,
  projectId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  setActiveTabTitleStateHandler: PropTypes.func.isRequired,
  isNotPublic: PropTypes.bool.isRequired,
};
RightSideBarContainer.defaultProps = {
  activeTabTitle: '',
};

export default RightSideBarContainer;
