import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { callNotification } from '../../../../../utils/helpers/notifies';
import { ConfirmModal } from '../../../../../ui-kit';
import api from '../../api/people.service';
import { usePeopleContext } from '../../context/usePeopleContext';


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const DeletePersonModal = () => {
  const { t } = useTranslation('common');
  const { setRequestParams, selectedUser, setSelectedUser } = usePeopleContext();
  const [name, setName] = useState('');
  const [userName, setUserName] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onClose = useCallback(() => setSelectedUser({}), []);

  const onDeleteUser = useCallback(() => {
    const deleteUser = async () => {
      try {
        await api.deletePerson({ id: selectedUser.id });
        setRequestParams({ shouldRefresh: true });
        callNotification.success('User has been deleted.');
        onClose();
        setIsLoading(false);
      } catch (e) {
        callNotification.error('Network error!');
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    deleteUser();
  }, [selectedUser]);


  useEffect(() => {
    const newIsOpen = selectedUser && Object.keys(selectedUser).length > 0;
    if (newIsOpen) {
      setName(`${selectedUser.first_name} ${selectedUser.last_name}`);
      setUserName(selectedUser.username);
    }
    setIsOpen(newIsOpen);
  }, [selectedUser]);

  return (
    <ConfirmModal
      isOpen={isOpen}
      isPending={isLoading}
      actionTitle={t('Delete')}
      onAction={onDeleteUser}
      onClose={onClose}
      title={t('Delete user')}
    >
      {t('Are you sure you want to delete user')}
      <b>{name}</b>
      <small>({userName})</small>
      ?
    </ConfirmModal>
  );
};
