import React from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import {
  MobileFiltersContainer,
  DropDown,
  FormsCollection,
} from '../../../../ui-kit';
import {
  CHANNELS_YOU_BELONG_TO,
  CHANNELS_YOU_CAN_JOIN,
  SORT_BY_LETTERS_DOWN,
  SORT_BY_LETTERS_UP,
} from '../../constants';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import './style.sass';

const FiltersRow = ({
  setFieldValue,
  areMobileFiltersOpen,
  toggleMobileFiltersHandler,
  values,
  t,
  onChangeSearchHandler,
  setShowFilter,
}) => (
  <div className="channels-filter">
    <div className="channels-filter__row">
      <FormsCollection.Search
        className="search-field--circle channels-filter__search-field"
        name="search"
        withoutControl
        value={values.search}
        onChange={onChangeSearchHandler}
      />
      <MobileFiltersContainer
        title="Filter"
        onWidth={WINDOW_WIDTH.SMALL}
        isOpen={areMobileFiltersOpen}
        onToggle={toggleMobileFiltersHandler}
      >
        <DropDown
          className="channels-filter__drop-down"
          label="Show: "
          currentItem={path(['type', 'id'], values)}
          list={[
            {
              id: 1,
              label: t('Channels you can join'),
              value: CHANNELS_YOU_CAN_JOIN,
            },
            {
              id: 2,
              label: t('Channels you belong to'),
              value: CHANNELS_YOU_BELONG_TO,
            },
          ]}
          onChange={({ val }) => {
            setShowFilter();
            setFieldValue('type', val);
          }}
        />
        <DropDown
          className="channels-filter__drop-down"
          label="Sort: "
          currentItem={path(['orderByTitle', 'id'], values)}
          list={[
            {
              id: 1,
              label: t('All'),
              value: null,
            },
            {
              id: 2,
              label: t('A-Z'),
              value: SORT_BY_LETTERS_DOWN,
            },
            {
              id: 3,
              label: t('Z-A'),
              value: SORT_BY_LETTERS_UP,
            },
          ]}
          onChange={({ val }) => setFieldValue('orderByTitle', val)}
        />
      </MobileFiltersContainer>
    </div>
  </div>
);

FiltersRow.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  onChangeSearchHandler: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  areMobileFiltersOpen: PropTypes.bool.isRequired,
  toggleMobileFiltersHandler: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setShowFilter: PropTypes.func.isRequired,
};

export default FiltersRow;
