import { compose, hoistStatics, pure } from 'recompose';

import DateLabel from './view';

const enhance = compose(
  pure,
  hoistStatics,
);

export default enhance(DateLabel);
