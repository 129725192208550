const PERMISSIONS = {
  USER: 1,
  MEMBER: 2,
  MANAGER: 3,
};

const MODULES = {
  USERS: 'users',
  ROLES: 'roles',
  PROJECTS: 'projects',
  PROJECTS_DOCUMENTS: 'project_documents',
  TASKS: 'tasks',
  CHANNELS: 'channels',
  LEADS: 'leads',
  CONTACTS: 'contacts',
  CLIENTS: 'clients',
  INVOICES: 'invoices',
  SETTINGS: 'settings',
  PROFILE: 'profile',
  GUESTS: 'guests',
  EVENTS: 'events',
  STATS: 'stats',
  MATERIALS: 'materials',
  CUSTOMERS: 'customers',
  PEOPLE: 'people',
  CUSTOM_FIELDS: 'custom_fields',
  BUSINESS_PROCESSES: 'bpa',
  LEAD_DOCUMENTS: 'lead_documents',
  CLIENT_DOCUMENTS: 'client_documents',
  KNOWLEDGE_BASE: 'knowledge_base'
};

const ACTIONS = {
  READ: 'read',
  DELETE: 'delete',
  UPDATE: 'update',
  CREATE: 'create',

};

export default PERMISSIONS;

export {
  ACTIONS,
  MODULES,
};
