import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Pagination } from '../../../../ui-kit';
import './style.sass';
import { InvoiceItem } from '..';

const InvoiceList = ({
  invoicesList, invoicesCount, pagination, onPageChange, totalCount,
}) => (
  <>
    <Grid.Wrapper
      name="Invoices"
      className="table-invoices"
      pagination={(
        <Pagination
          className="invoices-list__pagination"
          count={invoicesCount}
          component="invoices"
          pagination={pagination}
          onPageChange={onPageChange}
        />
      )}
    >
      <Grid.Row className="table-invoices__row table-invoices__row-controls">
        <Grid.Col
          name="#"
          className="table-invoices__col"
        />
        <Grid.Col
          name="Client"
          className="table-invoices__col"
        />
        <Grid.Col
          name="Project"
          className="table-invoices__col"
        />
        <Grid.Col
          name="Total"
          className="table-invoices__col"
        />
        <Grid.Col
          name="Date"
          className="table-invoices__col"
        />
        <Grid.Col
          // order={order}
          // sort={sort}
          // onClick={() => onSortBy({ sort: 'status' })}
          // filter="done"
          name="Status"
          className="table-invoices__col"
        />
        <Grid.Col className="table-invoices__col" />
      </Grid.Row>
      {
        totalCount > 0 && invoicesList.map(invoiceId => (
          <InvoiceItem
            invoiceId={invoiceId}
            key={invoiceId}
          />
        ))
      }
    </Grid.Wrapper>
    {
      !totalCount && (
        <span className="invoices-list__empty">
          Create your first invoice
        </span>
      )
    }
  </>
);

InvoiceList.propTypes = {
  invoicesList: PropTypes.arrayOf(PropTypes.number).isRequired,
  invoicesCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default InvoiceList;
