import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection } from '../../../../../../../ui-kit';
import './style.sass';

const ControlsCommentCard = ({
  isShowActions,
  onClickShowActions,
  vectorDropDown,
  chooseVector,
  onEditMessage,
  onClickDelete,
}) => (
  <>
    <div className="controls-message" style={{ zIndex: isShowActions ? 2 : 0 }}>
      <ButtonsCollection.ButtonIcons
        className="controls-message__attached-link controls-message__btn"
        onClick={() => onClickShowActions(true)}
        title="Show controls for message"
      >
        <span className="icon-settings-vertical">
          <span className="path1" />
          <span
            className="path2"
          />
          <span className="path3" />
        </span>
      </ButtonsCollection.ButtonIcons>
      {
        isShowActions && (
          <div
            className={`controls-message__drop-down dropDown-control-message
             ${vectorDropDown ? 'controls-message__drop-down--to-top' : ''}`}
            ref={e => chooseVector(e)}
          >
            <button
              type="button"
              className="dropDown-control-message__button"
              onClick={onEditMessage}
            >
              <div className="dropDown-control-message__icon">
                <span className="icon-pencil-2" />
              </div>
              <span className="dropDown-control-message__text">Edit</span>
            </button>
            <button
              type="button"
              className="dropDown-control-message__button"
              onClick={onClickDelete}
            >
              <div className="dropDown-control-message__icon">
                <span className="icon-delete-icon" />
              </div>
              <span className="dropDown-control-message__text">

                      Delete
              </span>
            </button>
          </div>
        )
      }
    </div>
  </>
);

ControlsCommentCard.propTypes = {
  chooseVector: PropTypes.func,
  onEditMessage: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickShowActions: PropTypes.func.isRequired,
  vectorDropDown: PropTypes.bool,
  isShowActions: PropTypes.bool.isRequired,
};

ControlsCommentCard.defaultProps = {
  vectorDropDown: false,
  chooseVector: () => {},
};

export default ControlsCommentCard;
