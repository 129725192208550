import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { leadsActions, leadsSelectors } from '../../../../state/leads';
import { preloaderWhileLoading, withWindowWidth } from '../../../../utils/enchancers';
import History from './history';

const mapStateToProps = (state, { leadId }) => ({
  isPending: leadsSelectors.getLeadActionPending(state)('getLeadLogsRequest'),
  logs: leadsSelectors.getLeadLogsSelector(state)(leadId),
});

const mapDispatchToProps = ({
  getLeadLogs: leadsActions.getLeadLogsRequest,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common']),
  lifecycle({
    componentDidMount() {
      const { leadId: id, getLeadLogs } = this.props;
      getLeadLogs({ id });
    },
  }),
  withWindowWidth(),
  preloaderWhileLoading({
    dimension: 75,
    isLoading: props => props.isPending,
  }),
);
export default enhance(History);
