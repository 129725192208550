module.exports = {
  STATUSES: [
    {
      id: 1,
      value: 0,
      label: 'In progress',
    },
    {
      id: 2,
      value: 1,
      label: 'Received offer',
    },
    {
      id: 3,
      value: 2,
      label: 'Denied',
    },
    {
      id: 4,
      value: 3,
      label: 'Reserve',
    },
    {
      id: 5,
      value: 4,
      label: 'Received answer',
    },
  ],
  POSITIONS: [
    {
      id: 1,
      value: 0,
      label: 'HR',
    },
    {
      id: 2,
      value: 1,
      label: 'Front End Developer',
    },
    {
      id: 3,
      value: 2,
      label: 'Back End Developer',
    },
    {
      id: 4,
      value: 3,
      label: 'Full Stack Developer',
    },
    {
      id: 5,
      value: 4,
      label: 'PM',
    },
    {
      id: 6,
      value: 5,
      label: 'QA Engineer',
    },
    {
      id: 7,
      value: 6,
      label: 'Sales Assistant',
    },
    {
      id: 8,
      value: 7,
      label: 'UI/UX Designer',
    },
    {
      id: 9,
      value: 8,
      label: 'DevOps Engineer',
    },
    {
      id: 10,
      value: 9,
      label: 'Marketing Manager',
    },
    {
      id: 11,
      value: 10,
      label: 'Product Manager',
    },
    {
      id: 12,
      value: 11,
      label: 'Web Designer',
    },
    {
      id: 13,
      value: 12,
      label: 'System Administrator',
    },
    {
      id: 14,
      value: 13,
      label: 'TechLead',
    },
  ],
};
