import {
  compose, hoistStatics, getContext, withHandlers, branch, renderNothing, withProps,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { isNil, pathOr } from 'ramda';

import DeleteTaskModal from './deleteTaskModal';
import { getModal } from '../../../state/ui/selectors';
import { closeModal } from '../../../state/ui/actions';
import { tasksActions, tasksSelectors } from '../../../state/tasks';
import { projectActions, projectSelectors } from '../../../state/project';
import { getIsTaskHasSprint, getTaskId } from '../../../utils/helpers/taskHelpers/crudHelper';
import { withUrlParams } from '../../../utils/enchancers';

const mapStateToProps = (state, { selectedTask, isMyTasks }) => {
  const taskId = getTaskId(selectedTask);
  const getTaskSelector = () => {
    if (isMyTasks) return tasksSelectors.getMyTask;
    if (getIsTaskHasSprint(selectedTask)) return projectSelectors.getTask;
    return projectSelectors.getBacklogTask;
  };
  return ({
    isOpen: getModal(state)('deleteTaskModal'),
    task: getTaskSelector()(state)(taskId),
  });
};
const mapDispatchToProps = {
  deleteTaskRequest: tasksActions.deleteTaskRequest,
  onCloseModal: () => closeModal('deleteTaskModal'),
  getProjectStatuses: projectActions.getStatusesOfTasksRequest,
  deleteProjectTaskSuccess: projectActions.deleteProjectTaskSuccess,
};

const onDeleteTaskHandler = ({
  deleteTaskRequest,
  projectId,
  selectedTask,
  selectedSort: {
    sort,
    order,
  },
  onCloseModal,
  history,
  selectedMeta,
  isRedirectToProject = false,
  onSuccess,
  getUrlParam,
  taskClientId,
  setSelectedTaskClientId,
  getProjectStatuses,
  paramsProjectId,
}) => () => deleteTaskRequest({
  clientId: getUrlParam(['client_id']) || taskClientId,
  projectId,
  taskId: getTaskId(selectedTask),
}, {
  callbacks: {
    success: () => {
      if (isRedirectToProject) history.push(`/projects/${projectId}`);
      onCloseModal();
      if (setSelectedTaskClientId) {
        setSelectedTaskClientId(null);
      }
      if (selectedTask && paramsProjectId) {
        getProjectStatuses({ projectId: paramsProjectId });
      }
      if (onSuccess) {
        onSuccess(projectId);
      }
    },
  },
  sortBy: sort,
  order,
  ...selectedMeta,
});

const enhance = compose(
  getContext({
    projectId: PropTypes.number,
    selectedMeta: PropTypes.instanceOf(Object),
    selectedSort: PropTypes.instanceOf(Object),
    isRedirectToProject: PropTypes.bool,
    setSelectedTaskClientId: PropTypes.func,
  }),
  branch(
    ({ selectedTask }) => isNil(selectedTask),
    renderNothing,
  ),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['tasks', 'common']),
  withUrlParams({}),
  withProps(({ match }) => ({
    paramsProjectId: pathOr(null, ['params', 'id'], match),
  })),
  withRouter,
  withHandlers({
    onDeleteTask: onDeleteTaskHandler,
  }),
  hoistStatics,
);

export default enhance(DeleteTaskModal);
