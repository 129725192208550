import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection, ModalWindow } from '../../../../../../ui-kit';
import './style.sass';

const DeleteCommentModal = ({
  isOpen, closeModal, t, onDelete,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={() => closeModal('deleteMessageModal')}
    modalName="deleteMessageModal"
    title="Delete Comment"
    className="modal-window__delete-message"
  >
    <>
      <div className="row  text-align--left">
        <span className="delete-message__title title-md">
          {t('Are you sure you want to delete this comment?')}
        </span>
      </div>
      <div className="delete-message__button-row">
        <ButtonsCollection.ButtonBrill
          type="button"
          onClick={onDelete}
          className="button--md delete-message__submit-button button-brill--fill"
        >
          { t('Delete')}
        </ButtonsCollection.ButtonBrill>
        <button
          type="button"
          onClick={() => closeModal('deleteMessageModal')}
          className="delete-message__cancel-button"
        >
          {' '}
          {t('Cancel')}
        </button>
      </div>
    </>
  </ModalWindow>
);

DeleteCommentModal.propTypes = {
  isOpen: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
export default React.memo(DeleteCommentModal);
