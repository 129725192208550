import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AVAILABLE_FILE_FORMATS } from '../../../../../constants/files';

import { Avatar, BrillMask, ButtonsCollection } from '../../../../../ui-kit';
import './style.sass';

/**
 *
 * @param fileUrl {string}
 * @param value {string}
 * @param name {string}
 * @param alt {string}
 * @param errorMessage {string}
 * @param setErrorMessage {function}
 * @param onUploadFile {function}
 * @param onDeleteFile {function}
 * @param setFileValue {function}
 * @param setFieldValue {function}
 * @param onResetCallback {function}
 * @param userAvatar {string}
 * @param resetAvatar{function}
 * @param setUserAvatar{function}
 * @returns {JSX.Element}
 * @constructor
 */

export const AvatarForm = ({
  fileUrl, value, name, alt, errorMessage, setErrorMessage,
  onUploadFile = () => {}, onDeleteFile = () => {}, setFileValue = () => {},
  setFieldValue = () => {}, onResetCallback = () => {}, userAvatar, resetAvatar, setUserAvatar,
}) => {
  const { t } = useTranslation('common');
  const inputRef = useRef(null);

  const onResetAvatar = useCallback(() => {
    inputRef.current.value = null;
    setFileValue('');
    onDeleteFile();
    onResetCallback();
    resetAvatar();
    setUserAvatar(null);
  }, [name, setFieldValue, setFileValue, onResetCallback]);


  const onChange = (e) => {
    setErrorMessage('');
    onUploadFile(e);
  };

  useEffect(() => {
    setFieldValue('avatar', userAvatar);
    setErrorMessage('');
  }, []);

  return (
    <div className="avatar-form">
      <div className="avatar-form__title">{t('Profile Photo')}</div>
      <div className="avatar-form__label">
        <input
          ref={inputRef}
          id="field-profile__avatar-profile"
          className="avatar-form__input"
          onChange={onChange}
          name={name}
          type="file"
          accept={AVAILABLE_FILE_FORMATS.LOGO_PROFILE.join(',')}
        />
        <BrillMask>
          <Avatar src={fileUrl || value} alt={alt}>
            <label
              className="field-profile__pseudo-overlay brill-mask__ignore"
              htmlFor="field-profile__avatar-profile"
            >
              <span className="field-profile__pseudo-overlay-icon brill-mask__ignore icon-camera" />
              <div className="field-profile__pseudo-overlay-title brill-mask__ignore">{t('Change Photo')}</div>
            </label>
          </Avatar>
        </BrillMask>
      </div>
      {
        (fileUrl || value) && (
          <ButtonsCollection.ButtonBrill
            type="button"
            className="button--xs avatar-profile__button-remove"
            onClick={onResetAvatar}
          >
            {t('Remove Avatar')}
          </ButtonsCollection.ButtonBrill>
        )
      }
      {
        errorMessage && (<small className="avatar-form__error-message">{errorMessage}</small>)
      }
    </div>
  );
};
