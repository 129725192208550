import React, { memo } from 'react';
import { connect } from 'react-redux';
import { useLocation, withRouter } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { KnowledgeBasePage } from '@/views/knowledgeBase'; // Modules routs will be automatic generated when you run modules-build
import { CHANNELS_ROUTES } from '../../../constants/ui';
import ModulesRouts from '../../../modules/routsOfModules';
import {
  Projects,
  Project,
  Messenger,
  MyTasks,
  CRM,
} from '../../../pages';
import { uiActions } from '../../../state/ui';
import { userActions, userSelectors } from '../../../state/user';
import { preloaderWhileLoading } from '../../../utils/enchancers';
import checkIsUserValid from '../../../utils/enchancers/checkIsUserValid';
import withDocumentVisibleChange from '../../../utils/enchancers/withDocumentVisibleChange';
import {
  Changelog, PageError, Profile, ActivityPage,
} from '../../../views';
import Settings from '../../../views/settings';

const PRELOADER_DELAY = 600;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

const Routes = () => {
  const location = useLocation();
  return (
    <Switch>
      <Route
        exact
        strict
        path="/:url*"
        render={() => location.pathname.slice(-1) !== '/' && <Redirect to={`${location.pathname}/`} />}
      />
      <Route exact path="/" component={Projects} />
      <Route exact path="/projects/" component={Projects} />
      <Route path="/tasks/" component={MyTasks} />
      <Route exact path="/activity/:view?/" component={ActivityPage} />
      <Route exact path="/profile/" component={Profile} />
      <Route exact path="/messenger/:id?/" component={Messenger} />
      <Route exact path={CHANNELS_ROUTES.CHANNELS} component={Messenger} />
      <Route exact path={CHANNELS_ROUTES.DIRECTS} component={Messenger} />
      <Route path="/projects/:id/" component={Project} />
      <Route path="/preferences/" component={Settings} />
      <Route path="/crm/" component={CRM} />
      <Route path="/changelog/" component={Changelog} />
      <Route path="/knowledge-base/" component={KnowledgeBasePage} />
      <ModulesRouts>
        <PageError />
      </ModulesRouts>
    </Switch>
  );
};

const mapDispatchToProps = ({
  checkCurrentUserRequest: userActions.checkCurrentUserRequest,
  setIsFocus: uiActions.setIsFocus,
});

const masStateToProps = state => ({
  bootData: userSelectors.getUserData(state),
});

const enhance = compose(
  withRouter,
  connect(masStateToProps, mapDispatchToProps),
  checkIsUserValid('checkCurrentUserRequest'),
  preloaderWhileLoading({
    delay: PRELOADER_DELAY,
    fullScreen: true,
    isLoading: props => !props.bootData,
  }),
  withDocumentVisibleChange,
  memo,
);

export default enhance(Routes);
