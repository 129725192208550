import React from 'react';
import { ScrollWrapper } from '@/ui-kit';
import { ProcessNode, ProcessRightSideBar } from '@bp/components';
import { useProcessContainer } from './useProcessContainer';
import './style.sass';

export const ProcessContainer = () => {
  const { nodes, isPending, createTrigger } = useProcessContainer();
  return (
    <>
      <ScrollWrapper className="processContainer__scroll-wrapper">
        <div className="processContainer">
          <div className="processContainer__area">
            {nodes.map((node) => (
              <ProcessNode {...node} key={node.id} />
            ))}
            {!nodes.length && !isPending && (
              <button
                type="button"
                className="pieceOfProcess pieceOfProcess__add"
                onClick={createTrigger}
              >
                <span className="pieceOfProcess__title">Add new trigger</span>
              </button>
            )}
          </div>
        </div>
      </ScrollWrapper>
      <ProcessRightSideBar />
    </>
  );
};
