import {
  compose, withHandlers, getContext,
} from 'recompose';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import PinnedTasks from './pinnedTasks';
import { projectSelectors } from '../../../../state/project';
import { uiActions } from '../../../../state/ui';

const mapStateToProps = state => ({
  result: projectSelectors.getPinnedTasksList(state),
  entities: projectSelectors.getPinnedTasksEntities(state),
  isPending: projectSelectors.getPinnedTasksPending(state),
});

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
};
const onUnpinHandler = ({
  setSelectedTask, setOpenModal,
}) => ({ id }) => {
  setSelectedTask(id);
  setOpenModal('unpinTaskModal');
};
const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  getContext({
    setSelectedTask: PropTypes.func,
  }),
  withHandlers({
    onUnpin: onUnpinHandler,
  }),
);
export default enhance(PinnedTasks);
