import { branch, compose, renderNothing } from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { getModal } from '../../../../../state/ui/selectors';
import { closeModal } from '../../../../../state/ui/actions';
import DeleteMessageModal from './deleteMessageModal';

const mapStateToProps = state => ({
  isOpen: getModal(state)('deleteMessageModal'),
});

const mapDispatchToProps = ({
  closeModal,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(
    ({ selectedMessage, isOpen }) => !selectedMessage && !isOpen,
    renderNothing,
  ),
  withTranslation(['common', 'chat']),
);

export default enhance(DeleteMessageModal);
