import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar, BrillMask, Label, OnlineStatusLabel,
} from '../../../../ui-kit';

import { getFullName } from '../../../../utils/helpers/userHelpers';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import { IMAGES_SIZE } from '../../../../constants/ui';
import api from '../../../../api/messenger.service';

const DirectsItem = ({ channel }) => {
  const history = useHistory();
  const openChannelByUserId = useCallback(async (value) => {
    const data = await api.openDirectChannelByUserId(value);
    if (data) {
      history.push(`/messenger/${data.channel?.id}`);
    }
  });
  const onGoToSendMessage = () => {
    if (channel?.user) {
      history.push(`/messenger/${channel.id}`);
    } else {
      openChannelByUserId(channel.id);
    }
  };

  return (
    <li key={channel.id} className="directs-list__item">
      <div
        role="presentation"
        onClick={onGoToSendMessage}
        className="directs-list__link"
      >
        <div className="directs-list__title-group">
          <BrillMask
            asLink
            outsideChildren={<OnlineStatusLabel status={channel.is_online} />}
            className="directs-list__avatar"
          >
            <Avatar
              src={getImageUrl(channel.avatar, IMAGES_SIZE.sm)}
              alt={getFullName(channel)}
            />
          </BrillMask>
          <h3 className="directs-list__title-group-title">
            {getFullName(channel)}
          </h3>
        </div>
        <Label
          className="directs-list__link-to  label--xs"

        >
          <span className="directs-list__link-to-icon icon-argon-arrow" />
        </Label>
      </div>
    </li>
  );
};

DirectsItem.propTypes = {
  channel: PropTypes.instanceOf(Object).isRequired,
};

export default DirectsItem;
