import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  compose, getContext, lifecycle, withHandlers,
} from 'recompose';
import HrCandidateCreate from './hrCandidateCreate';
import { hrActions } from '../../store/candidate';

const createCandidateHandler = ({ createHrCandidate }) => (formValues) => {
  createHrCandidate(formValues);
};

const mapDispatchToProps = {
  createHrCandidate: hrActions.createHrCandidateRequest,
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  getContext({ setHeadTitle: PropTypes.func }),
  withHandlers({ createCandidate: createCandidateHandler }),
  lifecycle({
    componentDidMount() {
      const { setHeadTitle } = this.props;
      setHeadTitle('Create candidate');
    },
  }),
);
export default enhance(HrCandidateCreate);
