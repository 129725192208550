/* eslint-disable camelcase */
import {
  fork, put, select, take, takeLatest,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { push } from 'connected-react-router';
import { notEqual } from 'ramda-extension';
import { Either } from 'ramda-fantasy';
import {
  includes, path, identity, compose, prop, pathOr,
} from 'ramda';

import { uiActions } from '../ui';
import { messengerActions, messengerSelectors, messengerTypes } from '.';
import { sagasManager } from '../../utils';
// eslint-disable-next-line no-unused-vars
import { TYPES_NOTIFICATION } from '../../constants/ui';
import * as userSelectors from '../user/selectors';
import { updateLastUserOwnerMessage } from './actions';
import { showNotification } from '../../utils/middlewares/feature/ui';
import { isConditionRight, map } from '../../utils/helpers/commonHelpers';
import { showMessageWhenUserNotAuthor } from '../../utils/helpers/messengerHelpers/notifications';
import { isUserFocusOnChat } from '../../utils/helpers/messengerHelpers/channelHelpers';
import { ERRORS_API } from '../../constants/api';

function* setActiveChannelFlow() {
  while (true) {
    const { payload: { channel, meta: { isSetActive } } } = yield take(messengerTypes.SET_CHANNEL);
    if (isSetActive && channel.id) {
      yield put(messengerActions.setActiveChannel({
        id: channel.id,
        type: channel.type,
      }));
      yield put(messengerActions.getLatestMessagesRequest({
        channelId: channel.id,
        offset: 0,
        limit: 25,
      }));
      yield put(uiActions.setPreloader(false));
    }
  }
}

function* setLoadedChannelFlow() {
  while (true) {
    const { payload: { meta } } = yield take(messengerTypes.SET_LATEST_MESSAGES);
    yield put(messengerActions.setLoadedChannel({ loading: true, channelId: meta.channelId }));
  }
}

// eslint-disable-next-line no-unused-vars
function* updateChannel(channelId) {
  yield put(messengerActions.readMessagesRequest({ channelId }, { isUpdatingCount: true }));
}

function setNewMessageFunc(payload, state) {
  const channel = messengerSelectors.getChannelById(state)(payload.channel.id);
  const muteChannels = messengerSelectors.getMuteChannelsList(state);
  const activeChannel = messengerSelectors.getActiveChannel(state);
  const activeChannelId = path(['id'], activeChannel);
  const isUserInSomeChannel = activeChannelId === payload.channel.id && document.hasFocus();
  const user = userSelectors.getUserData(state);
  return {
    channel,
    muteChannels,
    activeChannel,
    activeChannelId,
    isUserInSomeChannel,
    user,
    isUserMember: payload.isMember,
  };
}

function* emitNewMessageFlow() {
  while (true) {
    const { payload } = yield take(messengerTypes.EMIT_NEW_MESSAGE);

    const state = yield select(identity);
    const {
      isUserMember, isUserInSomeChannel, muteChannels, user, channel,
    } = yield setNewMessageFunc(payload, state);
    if (isUserMember && !includes(payload.channel.id, muteChannels)) {
      if (!isUserInSomeChannel) {
        yield put(uiActions.showNotification(payload, { type: TYPES_NOTIFICATION.MESSAGE }));
      }
    }
    yield put(messengerActions.setNewMessage({
      ...payload,
      isMember: isUserMember,
      isUserInSomeChannel,
    }));
    if (user.id === payload.message.created_by) {
      yield put(updateLastUserOwnerMessage({ ts: payload.message.ts, channelId: channel.id }));
    }
  }
}

function* emitUpdateMessageFlow() {
  while (true) {
    const { payload } = yield take(messengerTypes.EMIT_UPDATE_MESSAGE);
    yield put(messengerActions.updateMessage(payload));
  }
}
// function* emitDeleteMessageFlow() {
//   while (true) {
//     const { payload } = yield take(messengerTypes.EMIT_DELETE_MESSAGE);
//     yield put(messengerActions.deleteMessage(payload));
//   }
// }

function* redirectChannelNotFoundFlow() {
  while (true) {
    const action = yield take(messengerTypes.REDIRECT_CHANNEL_NOT_FOUND);
    const errorMessage = path(['payload', 'message'], action);
    if (errorMessage === ERRORS_API.FORBIDDEN) {
      yield put(push('/messenger/1'));
    } else {
      yield put(uiActions.setErrorPage({
        label: 'Go to General',
        href: '/messenger/1/',
      }));
    }
  }
}

function* handleMessageTextarea({ payload }) {
  const { channelId, hasUnreadMessag } = payload;
  yield delay(500);
  if (hasUnreadMessag) yield put(messengerActions.readMessagesRequest({ channelId }));
  yield put(uiActions.setTextareaMessengerWasChanged(true));
  yield delay(500);
  yield put(uiActions.setTextareaMessengerWasChanged(false));
}

function* watchUnreadCount() {
  yield takeLatest('TEXTAREA_CHANGED', handleMessageTextarea);
}

function* openDirectChannelByUserIdFlow() {
  while (true) {
    const { payload: { userId } } = yield take(messengerTypes.OPEN_DIRECT_CHANNEL_BY_USER_ID);
    yield put(messengerActions.getDirectChannelByUserIdRequest({ userId }));
    // eslint-disable-next-line max-len
    const { payload: { channel } } = yield take(messengerTypes.GET_DIRECT_CHANNEL_BY_USER_ID_SUCCESS);
    yield put(push(`/messenger/${channel.id}`));
  }
}

function* setNotificationAfterSocketReconnectFlow() {
  while (true) {
    const action = yield take(messengerTypes.SET_LATEST_UNREAD_MESSAGE);
    const { id: userId } = yield select(userSelectors.getUserData);

    const newTotalUnreadCount = pathOr(0, ['payload', 'unreadCount'], action);
    const oldTotalUnreadCount = pathOr(0, ['payload', 'meta', 'oldTotalUnreadCount'], action);
    const isTotalUnreadCountIncrease = oldTotalUnreadCount < newTotalUnreadCount;
    const channel = path(['payload', 'channel'], action);

    if (isTotalUnreadCountIncrease) {
      yield put(messengerActions.setTotalUnreadCount({ count: newTotalUnreadCount }));
      const redirectToChannel = (event) => {
        event.preventDefault();
        const isTargetChannelNotActive = isConditionRight(notEqual(channel));
        Either.either(() => window.focus(), (channelId) => {
          sagasManager.addSagaToRoot(function* redirectToChannelWatcher() {
            yield put(push(`/messenger/${channelId}/`));
          });
          window.focus();
        })(isTargetChannelNotActive(path(['payload', 'channel', 'id'], action)));
      };
      yield compose(
        map(compose(
          showMessageWhenUserNotAuthor(userId, showNotification({ onClick: redirectToChannel })),
          prop('payload'),
        )),
        isUserFocusOnChat(false, channel, path(['payload', 'message', 'channel_id'])),
      )(action);
    }
  }
}


function* getChannelError() {
  const action = yield take(messengerTypes.GET_CHANNEL_ERROR);
  const errorMessage = yield pathOr(false,
    ['payload', 'error', 'response', 'data', 'result', 'message'], action);
  if (includes(errorMessage, [ERRORS_API.FORBIDDEN, ERRORS_API.NOT_FOUND])) {
    yield put(messengerActions.redirectChannelNotFound(action));
  }
}


sagasManager.addSagaToRoot(function* watcher() {
  yield fork(setLoadedChannelFlow);
  yield fork(setActiveChannelFlow);
  yield fork(watchUnreadCount);
  yield fork(emitNewMessageFlow);
  yield fork(redirectChannelNotFoundFlow);
  yield fork(emitUpdateMessageFlow);
  yield fork(openDirectChannelByUserIdFlow);
  // yield fork(emitDeleteMessageFlow);
  yield fork(setNotificationAfterSocketReconnectFlow);
  yield fork(getChannelError);
});
