import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';

import {
  ConfirmModal,
} from '../../../../../ui-kit';

const DeleteSprintModal = ({
  isOpen,
  isPending,
  onClose,
  sprint,
  onDelete,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Delete"
    onAction={onDelete}
    onClose={onClose}
    title="Delete Sprint"
  >

    Are you sure you want to delete
    <b>{prop('title', sprint)}</b>

    sprint?
  </ConfirmModal>
);

DeleteSprintModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sprint: PropTypes.instanceOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteSprintModal;
