import * as yup from 'yup';
import * as translateHelpers from '../../../../utils/helpers/translateHelpers';


const rules = yup.object().shape({
  title: yup
    .string()
    .required({
      field: 'title',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Title'),
      },
    }),
  content: yup
    .string()
    .required({
      field: 'content',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Description'),
      },
    }),
});

export default rules;
