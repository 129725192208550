/* eslint-disable import/prefer-default-export */
const PRIORITY = {
  LOW: {
    color: '#2196f3',
    value: 0,
    title: 'Low',
  },
  MEDIUM: {
    color: '#4caf50',
    value: 1,
    title: 'Medium',
  },
  HIGH: {
    color: '#ffc107',
    value: 2,
    title: 'High',
  },
  VERY_HIGH: {
    color: '#f44336',
    value: 3,
    title: 'Urgent',
  },
};

const STATUS = {
  TO_DO: {
    id: 1,
    value: 0,
    title: 'To Do',
  },
  IN_PROGRESS: {
    id: 2,
    value: 1,
    title: 'In Progress',
  },
  DONE: {
    id: 3,
    value: 2,
    title: 'Done',
  },
};

const STATUSES_BY_ID = {
  [STATUS.TO_DO.id]: 'TO_DO',
  [STATUS.IN_PROGRESS.id]: 'IN_PROGRESS',
  [STATUS.DONE.id]: 'DONE',
};


const MY_TASKS = {
  GROUP_BY: {
    TASKS: 'tasks',
    PROJECTS: 'projects',
  },
  FILTER: {
    ASSIGNED_TO_ME: 'assignedToMe',
    CREATED_BY_ME: 'createdByMe',
  },
};

const TASKS_LOGS = {
  CREATED: 'created',
  CHANGED: 'changed',
  DELETED: 'deleted',
  ADDED: 'added',
};

const TASK_LOGS_STATIC = {
  NONE: 'None',
};

const FILTER_STATUS = {
  ACTIVE: 1,
  INACTIVE: 2,
};

const GRID_NAMES = {
  MY_TASKS_LIST: 'my-tasks-list',
  MY_TASKS_PROJECT_LIST: 'my-tasks-project-list',
};

const TASK_LOGS_ACTION_NAMES = {
  SPRINT: 'sprint',
};

const TASKS_WITHOUT_PROJECT = 'without-project';

const TASK_LOGS_FIELDS = {
  DEADLINE: 'deadline',
  ESTIMATED_TIME: 'estimated_time',
};

const TASK_LOGS_ITEM_NAMES = {
  WATCHER: 'TaskWatcher',
  TIME_ENTRY: 'TaskTimeEntry',
  COMMENT: 'TaskComment',
  SPRINT: 'Backlog',
};

export {
  PRIORITY,
  STATUS,
  MY_TASKS,
  TASKS_WITHOUT_PROJECT,
  STATUSES_BY_ID,
  TASKS_LOGS,
  TASK_LOGS_FIELDS,
  TASK_LOGS_STATIC,
  TASK_LOGS_ITEM_NAMES,
  TASK_LOGS_ACTION_NAMES,
  FILTER_STATUS,
  GRID_NAMES,
};
