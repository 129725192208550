import { createActions } from 'redux-actions';

import {
  identity, path, pathOr,
} from 'ramda';
import { callNotification } from '../../utils/helpers/notifies';
import types from './types';
import { clientsListSchema, pinnedClientsListSchema } from './schema';

const getTasksStatuses = status => (status ? `status=${status}` : 'status=1&status=2&status=3');

export const {
  createClientRequest,
  editClientRequest,
  deleteClientRequest,
  getClientsRequest,
  getClientsAutocompleteRequest,
  setClients,
  getPinnedClientsRequest,
  pinClientRequest,
  unpinClientRequest,
  setPinnedClients,
  getClientTasksRequest,
  setClientTasks,
  getClientContactsRequest,
  setClientContacts,
  getClientNotesRequest,
  setClientNotes,
  addClientNoteRequest,
  editClientNoteRequest,
  deleteClientNoteRequest,
  getClientLogsRequest,
  setClientLogs,
  setClientsAutocomplete,
  getClientRequest,
  setClient,
  getClientProductsRequest,
  setClientProducts,
  setAddedClientContact,
  deleteClientNoteSuccess,
  editClientNoteSuccess,
  deleteClientTaskSuccess,
  editClientTaskSuccess,
  deleteClientContactSuccess,
  editClientContactSuccess,
  fullEditClientTaskSuccess,
  changeClientTaskStatusSuccess,
} = createActions({
  [types.CREATE_CLIENT_REQUEST]: [
    identity,
    ({ ...params }, meta) => ({
      async: true,
      route: '/clients',
      selectorName: 'createClientRequest',
      method: 'POST',
      params,
      callbacks: {
        success: path(['callbacks', 'success'], meta),
        error: () => callNotification.error('Client cannot be created'),
      },
      ...meta,
    }),
  ],
  [types.GET_CLIENTS_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/clients',
      selectorName: 'getClientsRequest',
      method: 'GET',
      params,
      schema: {
        rules: clientsListSchema,
        pathData: ['data'],
      },
      actions: {
        success: setClients,
        // error: compose(setErrorPage, errorPageDataWhenGetClients),
      },
      ...meta,
    }),
  ],
  [types.GET_CLIENT_TASKS_REQUEST]: [
    identity,
    ({ id, status, ...params }, meta) => ({
      async: true,
      route: `/tasks/?client_id=${id}&${getTasksStatuses(status)}`,
      selectorName: 'getClientTasksRequest',
      method: 'GET',
      params,
      actions: {
        success: setClientTasks,
        meta: {
          clientId: id,
        },
      },
      ...meta,
    }),
  ],
  [types.EDIT_CLIENT_REQUEST]: [
    identity,
    ({ id, ...params }, meta) => ({
      async: true,
      route: `/clients/${id}`,
      selectorName: 'editClientRequest',
      method: 'PUT',
      params,
      callbacks: {
        success: pathOr(null, ['callbacks', 'success'], meta),
        error: () => callNotification.error('Client cannot be edited'),
      },
      ...meta,
    }),
  ],
  [types.DELETE_CLIENT_REQUEST]: [
    identity,
    ({ id, ...params }, meta) => ({
      async: true,
      route: `/clients/${id}`,
      selectorName: 'deleteClientRequest',
      method: 'DELETE',
      params,
      callbacks: {
        success: pathOr(null, ['callbacks', 'success'], meta),
        error: () => callNotification.error('Client cannot be deleted'),
      },
      actions: {
        success: () => getClientsRequest(meta),
      },
      ...meta,
    }),
  ],
  [types.GET_PINNED_CLIENTS_REQUEST]: [
    identity,
    ({ ...params }, meta) => ({
      async: true,
      route: '/pinned-clients',
      selectorName: 'getPinnedClientsRequest',
      method: 'GET',
      schema: {
        rules: pinnedClientsListSchema,
        pathData: [],
      },
      actions: {
        success: setPinnedClients,
      },
      params,
      ...meta,
    }),
  ],
  [types.PIN_CLIENT_REQUEST]: [
    identity,
    ({ id, ...params }, meta) => ({
      async: true,
      route: `/pinned-clients/${id}`,
      selectorName: 'pinClientRequest',
      method: 'POST',
      callbacks: path(['callbacks', 'success'], meta),
      params,
      ...meta,
    }),
  ],
  [types.UNPIN_CLIENT_REQUEST]: [
    identity,
    ({ id, ...params }, meta) => ({
      async: true,
      route: `/pinned-clients/${id}`,
      selectorName: 'unpinClientRequest',
      method: 'DELETE',
      callbacks: path(['callbacks', 'success'], meta),
      params,
      ...meta,
    }),
  ],
  [types.GET_CLIENT_CONTACTS_REQUEST]: [
    identity,
    ({ id, ...params }, meta) => ({
      async: true,
      route: `/contacts?client_id=${id}`,
      selectorName: 'getClientContactsRequest',
      method: 'GET',
      params,
      actions: {
        success: setClientContacts,
        meta: {
          clientId: id,
        },
      },
      ...meta,
    }),
  ],
  [types.GET_CLIENT_NOTES_REQUEST]: [
    identity,
    ({ id, ...params }, meta) => ({
      async: true,
      route: `/clients/${id}/documents`,
      selectorName: 'getClientNotesRequest',
      method: 'GET',
      params,
      actions: {
        success: setClientNotes,
        meta: {
          clientId: id,
        },
      },
      ...meta,
    }),
  ],
  [types.ADD_CLIENT_NOTE_REQUEST]: [
    identity,
    ({ id, ...params }, meta) => ({
      async: true,
      route: `/clients/${id}/documents`,
      selectorName: 'addClientNoteRequest',
      method: 'POST',
      params,
      callbacks: {
        success: pathOr(null, ['callbacks', 'success'], meta),
      },
      ...meta,
    }),
  ],
  [types.EDIT_CLIENT_NOTE_REQUEST]: [
    identity,
    ({ id, noteId, ...params }, meta) => ({
      async: true,
      route: `/clients/${id}/documents/${noteId}`,
      selectorName: 'editClientNoteRequest',
      method: 'PUT',
      params,
      actions: {
        success: () => editClientNoteSuccess({ id, noteId, params }),
      },
      callbacks: {
        success: pathOr(null, ['callbacks', 'success'], meta),
      },
      ...meta,
    }),
  ],
  [types.DELETE_CLIENT_NOTE_REQUEST]: [
    identity,
    ({ id, noteId, ...params }, meta) => ({
      async: true,
      route: `/clients/${id}/documents/${noteId}`,
      selectorName: 'deleteClientNoteRequest',
      method: 'DELETE',
      params,
      actions: {
        success: () => deleteClientNoteSuccess({ id, noteId }, meta),
      },
      callbacks: {
        success: pathOr(null, ['callbacks', 'success'], meta),
      },
      ...meta,
    }),
  ],
  [types.GET_CLIENT_LOGS_REQUEST]: [
    identity,
    ({ id, ...params }, meta) => ({
      async: true,
      route: `/clients/${id}/logs`,
      selectorName: 'getClientsLogsRequest',
      method: 'GET',
      params,
      actions: {
        success: setClientLogs,
        meta: {
          clientId: id,
        },
      },
      ...meta,
    }),
  ],
  [types.GET_CLIENTS_AUTOCOMPLETE_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      params,
      route: '/clients/autocomplete',
      selectorName: 'getClientsAutocompleteRequest',
      method: 'GET',
      actions: {
        success: action => setClientsAutocomplete({ ...action, meta }),
      },
      ...meta,
    }),
  ],
  [types.GET_CLIENT_REQUEST]: [
    identity,
    ({ id, ...params }, meta) => ({
      async: true,
      route: `/clients/${id}`,
      selectorName: 'getClientsLogsRequest',
      method: 'GET',
      params,
      actions: {
        success: setClient,
      },
      ...meta,
    }),
  ],
  [types.GET_CLIENT_PRODUCTS_REQUEST]: [
    identity,
    ({ id, ...params }, meta) => ({
      async: true,
      route: `/clients/products/${id}`,
      selectorName: 'getClientProductsRequest',
      method: 'GET',
      params,
      actions: {
        success: setClientProducts,
        meta: {
          clientId: id,
        },
      },
      ...meta,
    }),
  ],
},
types.SET_CLIENTS,
types.SET_PINNED_CLIENT,
types.SET_PINNED_CLIENTS,
types.SET_CLIENT_TASKS,
types.SET_CLIENT_CONTACTS,
types.SET_CLIENT_NOTES,
types.SET_CLIENT_LOGS,
types.SET_CLIENTS_AUTOCOMPLETE,
types.SET_CLIENT,
types.SET_CLIENT_PRODUCTS,
types.SET_ADDED_CLIENT_CONTACT,
types.EDIT_CLIENT_NOTE_SUCCESS,
types.DELETE_CLIENT_TASK_SUCCESS,
types.EDIT_CLIENT_TASK_SUCCESS,
types.DELETE_CLIENT_CONTACT_SUCCESS,
types.EDIT_CLIENT_CONTACT_SUCCESS,
types.FULL_EDIT_CLIENT_TASK_SUCCESS,
types.CHANGE_CLIENT_TASK_STATUS_SUCCESS,
types.DELETE_CLIENT_NOTE_SUCCESS);
