import {
  compose, lifecycle,
} from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { MODULES } from '../../../constants/pemissions';
import { setAppTitle } from '../../../state/ui/actions';
import withPermission from '../../../utils/enchancers/withPemission';
import People from './People';

const mapDispatchToProps = {
  setAppTitle,
};

const enhancer = compose(
  connect(null, mapDispatchToProps),
  withPermission(MODULES.PEOPLE),
  withTranslation(['common']),
  lifecycle({
    componentDidMount() {
      this.props.setAppTitle('People - Avanga 2.0');
    },
  }),
);

export default enhancer(People);
