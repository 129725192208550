import {
  compose, pure, withHandlers, withProps, withStateHandlers,
} from 'recompose';
import { equals } from 'ramda';
import { withTranslation } from 'react-i18next';
import Filters from './filters';
import { withWindowWidth } from '../../../../utils/enchancers';

const sortParams = {
  recent: {
    order: 'desc',
    sortBy: 'date_converted_to_client',
  },
  old: {
    order: 'asc',
    sortBy: 'date_converted_to_client',
  },
};

const onChangeSortingHandler = ({ onSortBy }) => ({ val: { order, sortBy } }) => {
  onSortBy({ order, sort: sortBy });
};

const onChangeSortingOnButtonHandler = ({ onSortBy, toggleMobileFiltersHandler }) => (e) => {
  const { target: { name } } = e;
  const { order, sortBy } = sortParams[name];
  onSortBy({ order, sort: sortBy });
  toggleMobileFiltersHandler();
};

const getSortValue = (order, sort) => {
  if (equals('date_converted_to_client', sort)) {
    return equals('desc', order) ? 0 : 1;
  }
  return null;
};


const enhance = compose(
  withTranslation(['common']),
  pure,
  withWindowWidth(),
  withProps(({ order, sort }) => ({
    sortingByDate: getSortValue(order, sort),
  })),
  withStateHandlers({
    areMobileFiltersOpen: false,
  }, {
    toggleMobileFiltersHandler:
      ({ areMobileFiltersOpen }) => () => ({ areMobileFiltersOpen: !areMobileFiltersOpen }),
  }),
  withHandlers({
    onChangeSorting: onChangeSortingHandler,
    onChangeSortingOnButton: onChangeSortingOnButtonHandler,
  }),
);

export default enhance(Filters);
