import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ButtonsCollection, LabelPriorityTask } from '../../../../../../ui-kit';
import './style.sass';

/**
 * @param task {object}
 * @param onDelete {function | boolean}
 * @returns {JSX.Element}
 * @constructor
 */

export const TaskItem = ({
  task,
  onDelete = false,
}) => {
  const { t } = useTranslation();
  const onDeleteHandler = useCallback(() => {
    onDelete(task);
  });
  return (
    task && (
      <li className="tasks-list__item">
        <Link to={`/projects/${task.project_id}/task/${task.id}/`} className="tasks-list__link">
          <LabelPriorityTask className="tasks-list__priority-icon" status={task.priority} />
          <div className="text--cut">
            {task.title}
          </div>
          {
            onDelete && (
              <ButtonsCollection.ButtonIcons
                className="project-members__button-remove"
                onClick={onDeleteHandler}
                title={t('Unpin task')}
              >
                <span className="icon-close" />
              </ButtonsCollection.ButtonIcons>
            )
          }
        </Link>
      </li>
    )
  );
};
