// todo: Must be used withFileUploading

import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection } from '../../../../ui-kit';
import { FileItem } from './components';
import './style.sass';
import DeleteFileModal from '../deleteFileModal';


const AttachedFiles = ({
  isTaskHasMoreFiles, filesResult, filesEntities, onDeleteFileModal,
  selectedFileId, user, onSetMoreFiles, isPendingLoadMore, onOpenUploadFilesModal,
}) => (
  <>
    <div className="form-row">
      <div className="col-lg-12 p-3">
        <ButtonsCollection.ButtonBrill
          className="button-brill--fill task-files__add-button  button--xs"
          type="button"
          onClick={onOpenUploadFilesModal}
        >

          Upload new files
        </ButtonsCollection.ButtonBrill>
      </div>
    </div>
    <div className="task-files__container">
      {
        filesResult && filesResult.length ? (filesResult.map(fileId => (
          <FileItem
            user={user}
            onDeleteFileModal={onDeleteFileModal}
            key={`task-file${fileId}`}
            file={filesEntities[fileId]}
          />
        ))
        ) : <div className="task-attachedFiles__empty">There are no attached files yet</div>
      }
    </div>
    {isTaskHasMoreFiles && (
      <div className="task-files__load-more">
        <ButtonsCollection.ButtonBrill
          onClick={onSetMoreFiles}
          pending={isPendingLoadMore}
          type="button"
          className="button__load-more button-brill--fill"
        >

          Show older files
        </ButtonsCollection.ButtonBrill>
      </div>
    )}
    <DeleteFileModal fileId={selectedFileId} />
  </>
);

AttachedFiles.propTypes = {
  onOpenAttachFilesModal: PropTypes.func.isRequired,
  isTaskHasMoreFiles: PropTypes.bool.isRequired,
  filesResult: PropTypes.instanceOf(Array).isRequired,
  filesEntities: PropTypes.instanceOf(Object).isRequired,
  onDeleteFileModal: PropTypes.func.isRequired,
  selectedFileId: PropTypes.number.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  onSetMoreFiles: PropTypes.func.isRequired,
  isPendingLoadMore: PropTypes.bool.isRequired,
};

export default AttachedFiles;
