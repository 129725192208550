import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ACTIONS, MODULES } from '@/constants/pemissions';
import { ButtonsCollection, PermissionElement } from '@/ui-kit';
import { useKnowledgeBaseContext } from '@/views/knowledgeBase/context/useKnowledgeBaseContext';
import './style.sass';

export const ArticleItem = ({ article, onOpenDeleteArticleModal }) => {
  const { push } = useHistory();
  const { subcategoryId } = useParams();

  const {
    setArticleId,
    setArticleTitle,
  } = useKnowledgeBaseContext();

  const onArticleClick = useCallback(() => {
    push(`/knowledge-base/subcategory/${subcategoryId}/article/${article.id}`);
  }, [subcategoryId]);

  const onEditArticle = useCallback((e) => {
    e.stopPropagation();

    push(`/knowledge-base/subcategory/${subcategoryId}/article/${article.id}/edit`);
  }, [subcategoryId]);

  const onDeleteArticle = useCallback((e) => {
    e.stopPropagation();

    setArticleId(article.id);
    setArticleTitle(article.title);

    onOpenDeleteArticleModal();
  }, [onOpenDeleteArticleModal, article]);

  return (
    <div className="article-item" onClick={onArticleClick}>
      <p className="article-item__title">{article.title}</p>
      <div className='article-item__controls'>
        <PermissionElement
          module={MODULES.KNOWLEDGE_BASE}
          action={ACTIONS.UPDATE}
        >
          <ButtonsCollection.ButtonIcons
            onClick={onEditArticle}
            title='Edit subcategory'
            className='article-item__controls__edit-button'
          >
            <span className='icon-edit-icon' />
          </ButtonsCollection.ButtonIcons>
        </PermissionElement>
        <PermissionElement
          module={MODULES.KNOWLEDGE_BASE}
          action={ACTIONS.DELETE}
        >
          <ButtonsCollection.ButtonIcons
            onClick={onDeleteArticle}
            title='Delete subcategory'
            className='article-item__controls__delete-button'
          >
            <span className='icon-trash' />
          </ButtonsCollection.ButtonIcons>
        </PermissionElement>
      </div>
    </div>
  )
};
