import {
  compose, withState, withHandlers, withProps,
} from 'recompose';
import { isNotificationPermission, requestPermission } from '../helpers/uiComponentHelpers/common';

const onEnableNotificationHandler = ({ setNotificationPermission }) => () => {
  const delayAllAnimation = 1000;
  const cbAfterAcceptPermissions = () => setTimeout(
    () => window.dispatchEvent(new Event('resize')),
    delayAllAnimation,
  );
  requestPermission(setNotificationPermission, cbAfterAcceptPermissions);
};

const withNotification = compose(
  withState('notificationPermission', 'setNotificationPermission', null),
  withProps(() => ({
    isNotificationPermissionDefault: isNotificationPermission('default'),
  })),
  withHandlers({
    onEnableNotification: onEnableNotificationHandler,
  }),
);

export default withNotification;
