import React from 'react';
import ModalView from '../modalView';

const CreateClientModal = props => (
  <>
    <ModalView
      title="Create client"
      titleAction="Create"
      {...props}
    />
  </>
);

export default CreateClientModal;
