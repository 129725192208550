import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { I18nextProvider } from 'react-i18next';
import { PersistGate } from 'redux-persist/integration/react';

import moment from 'moment-timezone';
import { Routes } from './routes/Routes';

import { i18n } from '../../utils';
import store, { history, persistor } from '../../store';

export const App = () => {
  useEffect(() => moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone), []);

  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <Routes />
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </I18nextProvider>
  );
};
