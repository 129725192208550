import * as yup from 'yup';

import { helpers } from '../../../../utils';

const { translateHelpers } = helpers;

const rules = yup.object().shape({
  title: yup
    .string()
    .required({
      field: 'title',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Title'),
      },
    }),
});
export default rules;
