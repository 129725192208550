import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';

import './style.sass';

/**
 *
 * @param fullScreen {boolean}
 * @param alignContainerCenter {boolean}
 * @param dimension {number}
 * @param className {string}
 * @param duration {number}
 * @param colorFill {string | boolean}
 * @returns {JSX.Element}
 * @constructor
 */

const DURATION_STEP = 10;
const MAX_AMOUNT_CIRCLE = 366;
const MAX_AMOUNT_SQUARE = 110;

export const Preloader = ({
  fullScreen = false,
  alignContainerCenter = false,
  dimension = 200,
  className = '',
  duration = 1000,
  colorFill = false,
}) => {
  const preloaderRef = useRef(null);
  const canvasSquareRef = useRef(null);
  const canvasCircleRef = useRef(null);
  const middlePartRef = useRef(null);

  const drawElement = useCallback(() => {
    const activeColor = colorFill || getComputedStyle(preloaderRef.current).fill;

    const canvasSquare = canvasSquareRef.current;
    const canvasCircle = canvasCircleRef.current;
    const middlePart = middlePartRef.current;

    if (!canvasCircle || !canvasSquare) return false;

    const contextSquare = canvasSquare.getContext('2d');
    const contextCircle = canvasCircle.getContext('2d');

    canvasCircle.height = canvasCircle.offsetHeight;
    canvasCircle.width = canvasCircle.offsetWidth;

    const centerXCircle = canvasCircle.width / 2;
    const centerYCircle = canvasCircle.height / 2;
    const radiusCircle = canvasCircle.width / 4;

    let animationInterval;
    let amountCircle = 0;
    let amountSquare = 0;

    const amountToIncreaseCircle = MAX_AMOUNT_CIRCLE / (duration / DURATION_STEP);
    const amountToIncreaseSquare = MAX_AMOUNT_SQUARE / (duration / DURATION_STEP);

    const iteratorDrawSquare = () => {
      amountSquare += amountToIncreaseSquare;
      contextSquare.beginPath();
      contextSquare.rect(50, 130 - amountSquare, 200, amountSquare);
      contextSquare.fillStyle = activeColor;
      contextSquare.fill();
      contextSquare.closePath();
    };

    const iteratorDrawCircle = () => {
      contextCircle.beginPath();
      contextCircle.arc(
        centerXCircle,
        centerYCircle,
        radiusCircle,
        0,
        amountCircle * Math.PI / 180,
        false,
      );
      contextCircle.fillStyle = activeColor;
      contextCircle.fill();
      contextCircle.lineWidth = canvasCircle.width / 2;
      contextCircle.strokeStyle = activeColor;
      contextCircle.stroke();
      amountCircle += amountToIncreaseCircle;
      contextCircle.closePath();
      if (amountCircle >= MAX_AMOUNT_CIRCLE) {
        if (middlePart) middlePart.setAttribute('data-animation-heart-tick', true);
        clearInterval(animationInterval);
      }
    };

    animationInterval = setInterval(() => {
      iteratorDrawCircle();
      iteratorDrawSquare();
    }, DURATION_STEP);

    return true;
  }, [preloaderRef, canvasSquareRef, canvasCircleRef, middlePartRef, colorFill, duration]);

  useEffect(() => { drawElement(); }, []);

  return (
    <div
      style={{ fontSize: dimension }}
      ref={preloaderRef}
      className={classNames('preloader', className, { 'preloader--full-screen': fullScreen, 'preloader--container-center': alignContainerCenter })}
    >
      <div className="preloader__element">
        <div className="preloader__element-pseudo-wrap">
          <canvas className="preloader__canvas" ref={canvasCircleRef} />
        </div>
        <div className="preloader__element-pseudo-wrap-center" ref={middlePartRef}>
          <canvas className="preloader__canvas-center" ref={canvasSquareRef} />
        </div>
      </div>
    </div>
  );
};
