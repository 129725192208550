import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { Header, ClientsContainer } from '../../../../containers';
import CRMModules from '../CRMModules/index';
import { LeftSideBar } from '../../../../newContainers';
import { ButtonsCollection, Layouts, DropDown } from '../../../../ui-kit';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import { RightSideBarContainer } from './components';

const Clients = ({
  t, windowWidth, onCreateClientModal, selectedClientId, selectedPinnedClientId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onCreateCustomFieldModal = useCallback(() => setIsOpen(true), []);
  return (
    <Layouts.Main rightSideBarStatus={false}>
      <LeftSideBar>
        <CRMModules />
      </LeftSideBar>
      <div className="main-container page-contacts__main-container">
        <Header>
          <h1 className="weight--medium title--xl title-page">
            {t('Clients')}
          </h1>
          <>
            {
            windowWidth > WINDOW_WIDTH.MEDIUM ? (
              <ButtonsCollection.ButtonBrill
                onClick={onCreateClientModal}
                className="button--sm header__control-button--project"
              >

                Create client
              </ButtonsCollection.ButtonBrill>
            ) : (
              <ButtonsCollection.ButtonPlus
                onClick={onCreateClientModal}
                className="header__control-button--project"
              />
            )
           }
            <DropDown
              className="crm-module-header-control"
              name="crm-module-header"
              label={(
                <ButtonsCollection.ButtonIcons
                  title={t('Control buttons for CRM')}
                  className="header-col__separate-page header-col__crm-module-burger"
                >
                  <span className="icon-burger-dots-gorizont icon" />
                </ButtonsCollection.ButtonIcons>
                )}
              list={
              [
                {
                  id: 1,
                  label: (
                    <div className="crm-module-header-control__link">
                      <span className="crm-module-header-control__icon icon-category-set" />
                      <span className="crm-module-header-control__text">{t('Custom fields')}</span>
                    </div>
                  ),
                  onClick: () => onCreateCustomFieldModal(),
                },
              ]
            }
            />
           </>
        </Header>
        <div className="main-container__content page-contacts__content">
          <ClientsContainer
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedClientId={selectedClientId}
            selectedPinnedClientId={selectedPinnedClientId}
          />
          <RightSideBarContainer windowWidth={windowWidth} />
        </div>
      </div>
    </Layouts.Main>
  );
};

Clients.propTypes = {
  onCreateClientModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  // eslint-disable-next-line react/require-default-props
  selectedClientId: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  selectedPinnedClientId: PropTypes.number,
};

export default Clients;
