import React from 'react';
import { Route, Switch } from 'react-router';

import { Layouts, NoInternetConnection } from '../../../ui-kit';
import { LeftSideBar } from '../../../newContainers';
import './style.sass';
import { PeopleWrapper, PersonWrapper } from './containers';
import { Provider } from './context/Provider';
import { PEOPLE_PAGES } from './api/routes';


const People = () => (
  <Layouts.Main>
    <LeftSideBar />
    <div className="main-container main-container--settings">
      <NoInternetConnection>
        <Provider>
          <Switch>
            <Route path={PEOPLE_PAGES.PERSON.MAIN} component={PersonWrapper} />
            <Route path={PEOPLE_PAGES.PEOPLE.MAIN} component={PeopleWrapper} />
          </Switch>
        </Provider>
      </NoInternetConnection>
    </div>
  </Layouts.Main>
);

export default People;
