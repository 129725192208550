import React from 'react';
import './style.sass';


export const FieldsName = ({ fields }) => (
  <div className="total-time-list-fields-name">
    <div className="total-time-list-fields-name__row">
      {fields?.hash
          && (
          <div className="total-time-list-fields-name__id">
            #
          </div>
)
        }
      {fields?.project
          && (
          <div className="total-time-list-fields-name__title">
            Project
          </div>
)
        }
      {fields?.task
          && (
          <div className="total-time-list-fields-name__title">
            Task
          </div>
)
        }
      {fields?.title
          && (
          <div className="total-time-list-fields-name__title">
            Title
          </div>
)
        }
      {fields?.user
          && (
          <div className="total-time-list-fields-name__title">
            User
          </div>
)
        }
      {fields?.spent
          && (
          <div className="total-time-list-fields-name__spent">
            Spent
          </div>
)
        }
      {fields?.estimate
          && (
          <div className="total-time-list-fields-name__estimate">
            Estimate
          </div>
)
        }
    </div>
  </div>
);
