import {
  branch,
  compose, getContext, renderNothing, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import {
  pathOr, isEmpty,
} from 'ramda';
import PropTypes from 'prop-types';
import EditClientModal from './editClientModal';
import rules from '../rules';
import { uiActions, uiSelectors } from '../../../../state/ui';
import { clientsActions, clientsSelectors } from '../../../../state/clients';
import { callNotification } from '../../../../utils/helpers/notifies';
import { getTimezoneAutocompleteHelper } from '../../../../utils/helpers/dateHelpers';

const mapStateToProps = (state, { selectedClientId }) => ({
  isOpen: uiSelectors.getModal(state)('editClientModal'),
  client: clientsSelectors.getClientById(state)(selectedClientId),
});

const mapDispatchToProps = {
  closeModal: () => uiActions.closeModal('editClientModal'),
  editClient: clientsActions.editClientRequest,
};

const onCloseModalHandler = ({
  closeModal,
  resetForm,
}) => () => {
  closeModal();
  resetForm();
};


const enhance = compose(
  getContext({
    setSelectedClientId: PropTypes.func.isRequired,
    selectedClientId: PropTypes.any,
    setIsNeedRefresh: PropTypes.func.isRequired,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ client }) => !client, renderNothing),
  withProps(({ client }) => {
    const getPropFromClientContact = field => pathOr('', ['contact', field], client);
    return {
      initialValues: ({
        contact: {
          avatar: getPropFromClientContact('avatar'),
          first_name: getPropFromClientContact('first_name'),
          last_name: getPropFromClientContact('last_name'),
          email: getPropFromClientContact('email'),
          phone: getPropFromClientContact('phone'),
          company_name: getPropFromClientContact('company_name'),
          company_url: getPropFromClientContact('company_url'),
          position: getPropFromClientContact('position'),
          service: getPropFromClientContact('service'),
          timezone: getPropFromClientContact('timezone'),
        },
      }),
    };
  }),
  withFormik({
    mapPropsToValues: ({ initialValues }) => initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: rules,
    handleSubmit: (formData, {
      resetForm,
      props: {
        closeModal,
        editClient,
        onSuccess,
        selectedClientId,
        setIsNeedRefresh,
      },
    }) => {
      // eslint-disable-next-line no-param-reassign
      if (isEmpty(formData.contact.avatar)) formData.contact.avatar = null;
      // eslint-disable-next-line no-param-reassign
      if (isEmpty(formData.contact.email)) formData.contact.email = null;
      editClient({
        id: selectedClientId,
        ...formData,
      }, {
        callbacks: {
          success: () => {
            resetForm({
            });
            closeModal();
            if (setIsNeedRefresh) {
              setIsNeedRefresh(true);
            }
            if (onSuccess) {
              onSuccess();
            }
          },
          error: () => callNotification.error('Email should be unique'),
        },
      });
    },
  }),
  withHandlers({
    onCloseModal: onCloseModalHandler,
    getAutocompleteHandler: getTimezoneAutocompleteHelper,
  }),
);

export default enhance(EditClientModal);
