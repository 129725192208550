import React, { memo } from 'react';


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

const ButtonLable = ({ labelText }) => (
  <>
    <span className="project-header-control__icon icon-category-set" />
    <span className="project-header-control__text">{labelText}</span>
  </>
);

export default memo(ButtonLable);
