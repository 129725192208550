import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { projectSelectors } from '../../../../state/project';
import { openModal } from '../../../../state/ui/actions';
import Categories from './categories';
import withUrlParams from '../../../../utils/enchancers/withUrlParams';

const mapStateToProps = state => ({
  categoriesList: projectSelectors.getProjectCategoriesTasksList(state),
});
const mapDispatchToProps = ({
  openModal,
});
const onResetCategoryHandler = ({ setSelectedCategoryId, onResetUrlParam }) => {
  setSelectedCategoryId(null);
  onResetUrlParam({ category: null });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withUrlParams({}),
  withHandlers({
    onResetCategory: onResetCategoryHandler,
  }),
);
export default enhance(Categories);
