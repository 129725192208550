import React from 'react';
import { formatDate } from '../../../../utils/helpers/commonHelpers';

import {
  TASKS_LOGS,
  TASK_LOGS_FIELDS,
  TASK_LOGS_STATIC,
  TASK_LOGS_ACTION_NAMES,
  TASK_LOGS_ITEM_NAMES,
} from '../../../../constants/tasks';
import { dateHelpers } from '../../../../utils/helpers';

const getLogAction = action => ({
  isCreated: action === TASKS_LOGS.CREATED,
  isChanged: action === TASKS_LOGS.CHANGED,
  isDeleted: action === TASKS_LOGS.DELETED,
  isAdded: action === TASKS_LOGS.ADDED,
});

const cutString = (str, countWords = 15) => {
  const isHtmlTag = /<br>/.test(str);
  let newString = str;
  if (isHtmlTag) {
    const array = str.split('<br>');
    newString = `${array[0]}...`;
  }
  return newString.length > countWords ? `${newString.slice(0, countWords)}...` : newString;
};

export const renderTaskLog = ({
  action, field, prevValue, newValue, title, originalField, item_name: itemName,
}, t) => {
  const {
    isCreated, isChanged, isAdded, isDeleted,
  } = getLogAction(action);
  return (
    <>
      {
        isCreated && (
          <span className="history__event">{t('created the task')}</span>
        )
      }
      {
        isAdded && itemName === TASK_LOGS_ITEM_NAMES.WATCHER && (
          <span className="history__event">{`${action} ${t('watcher')} ${title}`}</span>
        )
      }
      {
        isAdded && itemName === TASK_LOGS_ITEM_NAMES.TIME_ENTRY && (
          <span className="history__event">{`${action} ${t('Time Entry')} ${dateHelpers.getTimeStringBySeconds(newValue)}`}</span>
        )
      }
      {
        isAdded && itemName === TASK_LOGS_ITEM_NAMES.COMMENT && (
          <span className="history__event">{`${action} ${t('comment')} ${cutString(newValue)}`}</span>
        )
      }
      {
        isDeleted && itemName === TASK_LOGS_ITEM_NAMES.WATCHER && (
          <span className="history__event">{`${action} ${t('watcher')} ${title}`}</span>
        )
      }
      {
        isDeleted && itemName === TASK_LOGS_ITEM_NAMES.TIME_ENTRY && (
          <span className="history__event">{`${action} ${t('Time Entry')} ${dateHelpers.getTimeStringBySeconds(prevValue)}`}</span>
        )
      }
      {
        isDeleted && itemName === TASK_LOGS_ITEM_NAMES.COMMENT && (
          <span className="history__event">{`${action} ${t('comment')} ${cutString(prevValue)}`}</span>
        )
      }
      {
        (isChanged && originalField === TASK_LOGS_FIELDS.DEADLINE && (
          <span className="history__event">{
            `${t(action)} ${t(field)} ${(
              `${t('from')} ${prevValue !== TASK_LOGS_STATIC.NONE ? (formatDate(prevValue)) : prevValue} ${t('to')} ${newValue !== TASK_LOGS_STATIC.NONE ? (formatDate(newValue)) : newValue}`
            )}`
          }
          </span>
        )) || (isChanged && originalField === TASK_LOGS_FIELDS.ESTIMATED_TIME && (
          <span className="history__event">{
            `${t(action)} ${t(field)} ${(
              `${t('from')} ${prevValue !== TASK_LOGS_STATIC.NONE ? dateHelpers.getTimeStringBySeconds(prevValue) : prevValue} ${t('to')} ${newValue !== TASK_LOGS_STATIC.NONE ? dateHelpers.getTimeStringBySeconds(newValue) : newValue}`
            )}`
          }
          </span>
        )) || (isChanged && itemName === TASK_LOGS_ITEM_NAMES.TIME_ENTRY && (
          <span className="history__event">{
            `${t(action)} ${t(field)} ${(
              `${t('from')} ${dateHelpers.getTimeStringBySeconds(prevValue)} ${t('to')} ${dateHelpers.getTimeStringBySeconds(newValue)}`
            )}`
          }
          </span>
        )) || (isChanged && itemName === TASK_LOGS_ITEM_NAMES.COMMENT && (
          <span className="history__event">{
            `${t(action)} Comment ${(
              `${t('from')} ${cutString(prevValue)} ${t('to')} ${cutString(newValue)}`
            )}`
          }
          </span>
        ))
        || (isChanged && (
          <span className="history__event">
            {
              field === TASK_LOGS_ACTION_NAMES.SPRINT ? (
                `${t(action)} ${t(field)} ${t('from')} ${cutString(prevValue === TASK_LOGS_STATIC.NONE ? TASK_LOGS_ITEM_NAMES.SPRINT : prevValue)} ${t('to')} ${cutString(newValue === TASK_LOGS_STATIC.NONE ? TASK_LOGS_ITEM_NAMES.SPRINT : newValue)}`
              ) : (
                `${t(action)} ${t(field)} ${t('from')} ${cutString(prevValue)} ${t('to')} ${cutString(newValue)}`
              )
            }
          </span>
        ))
      }
    </>
  );
};
