import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { ButtonsCollection, Header } from '../../../../ui-kit';
import { permissionsActions, permissionsSelectors } from '../../../../state/permissions';
import { callNotification } from '../../../../utils/helpers/notifies';
import './style.sass';
import { PermissionsContainer } from './permissionsContainer';

/**
 * @param action {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const PermissionsWrapper = ({ action }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { roleId } = useParams();
  const role = useSelector(state => permissionsSelectors.getRoleById(state)(roleId));

  const createRoleRequest = useCallback((value) => {
    dispatch(permissionsActions.createRoleRequest(value));
  });
  const updateRoleRequest = useCallback((value) => {
    dispatch(permissionsActions.updateRoleRequest(value));
  });
  const getModulesRequest = useCallback((value) => {
    dispatch(permissionsActions.getModulesRequest(value));
  });

  useEffect(() => {
    getModulesRequest();
  }, []);

  const onSubmit = ({
    listOfPermissions: permissions,
    name,
  }) => {
    const dispatcher = action === 'Edit' ? updateRoleRequest : createRoleRequest;
    const data = action === 'Edit'
      ? ({ name, permissions, roleId })
      : ({ name, permissions, roleId });

    dispatcher(data, {
      callbacks: {
        success: name && dispatch(push('/preferences/roles/')),
        error: callNotification.error,
      },
    });
  };

  return (
    <>
      <Header hideToggleRightSideBar className="role-header">
        <h1 className="weight--medium title--xl title-page text--cut">
          <ButtonsCollection.ButtonIcons
            title={t('Go back to users')}
            className="user-head-back"
            onClick={() => dispatch(push('/preferences/roles/'))}
          >
            <span className="icon-expand-arrow-left" />
          </ButtonsCollection.ButtonIcons>
          Settings - Roles - {' '}
          {role.name ? `${role.name}` : 'Create'}
        </h1>
      </Header>
      <div className="main-container__content main-container__content--settings">
        <PermissionsContainer onSubmit={onSubmit} />
      </div>
    </>
  );
};
