import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import './style.sass';

/**
 *
 * @param title {(JSX.Element|string)}
 * @param children {JSX.Element|string|array}
 * @param customTitle {function}
 * @param visible {boolean}
 * @param className {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const TabWrapper = ({
  title,
  children = <></>,
  customTitle = () => {},
  visible = true,
  className = '',
}) => {
  const [statusTab, setStatusTab] = useState(false);
  const changeStatusTab = () => {
    setStatusTab(!statusTab);
  };
  useEffect(() => {
  }, [statusTab]);
  return (
    visible
      ? (
        <div
          className={classNames('tab-wrapper', statusTab && ' tab-wrapper--open', className)}
        >
          <div
            className="tab-wrapper__header"
            onClick={() => changeStatusTab()}
            tabIndex="0"
            role="button"
            onKeyPress={() => changeStatusTab()}
          >
            {title}
            <>
              {customTitle()}
            </>
            <button
              type="submit"
              title={`${statusTab ? 'Hide' : 'Show'} ${typeof title === 'string' ? title : 'Content'}`}
              className="tab-wrapper__toggle-button"
            >
              <span className="icon-expand-arrow-dn" />
            </button>
          </div>
          <div className="tab-wrapper__body">
            {children}
          </div>
        </div>
      )
      : <></>
  );
};
