
/* eslint-disable */
import React from 'react';
import { Route }  from 'react-router-dom';
import {Switch} from 'react-router';

import PeopleApp from './avanga2-spa-module-people-spa/src/';
import HrModule from './avanga2-spa-module-whr/src/';
import BPModule from './avanga2-spa-module-bpa/src/';


const RoutesOfModules = ({ children }) => (
           <Switch>
           <Route exact={false} path="/people" component={PeopleApp}/> 
<Route exact={false} path="/hr-management" component={HrModule}/> 
<Route exact={false} path="/business-processes" component={BPModule}/> 

           {children}
           </Switch>
);

export default RoutesOfModules;