import {
  always,
  cond,
  curry,
  isNil,
  prop,
  propOr,
  replace,
  T,
  path,
  length,
  F,
  trim,
  when,
  identity, pathOr, test, lt,
} from 'ramda';
import { compose } from 'recompose';
import data from 'emoji-mart/data/messenger';
import { NimbleEmojiIndex } from 'emoji-mart';
import { memoizeWithIdentity } from 'ramda-extension';

import {
  convertSpanEmojiToImg,
  getEmojiHtmlStringById,
} from '../../utils/helpers/uiComponentHelpers/emojiHelpers';
import {
  curryRegexMatch, curryRegexReplace,
  curryRegexTest,
  regexRules,
} from '../../utils/helpers/uiComponentHelpers/common';
import { correctLink } from '../../utils/helpers/uiHelpers';
import { notPureCond } from '../../utils/helpers/commonHelpers';
import { getHTMLElementByString } from '../../utils/helpers/uiComponentHelpers/DOMhelpers';

const removeCharsetFromStr = curry((str, rule) => replace(rule, '', str));


const getLinkByUrlParams = curry((options, children) => {
  const metaDataJson = propOr('{}', 'metadata', options);
  const metaData = JSON.parse(metaDataJson);
  const urls = propOr({}, 'urls', metaData);
  const hasLinks = test(regexRules.regLink, children);
  if (hasLinks) {
    const trimSearchLink = trim(children);
    const item = urls[trimSearchLink];
    // eslint-disable-next-line no-nested-ternary
    const itemImage = path(['openGraph', 'image', 'url'], item);
    const itemDescription = path(['openGraph', 'description'], item);
    return (item && (itemDescription || itemImage))
      ? `<div class="parse-link"><a
      rel="noopener noreferrer"
      target="_blank"
      href="${path(['url'], item)}"
      class="parse-link__main-link"
    >${path(['url'], item)}</a><div class="parse-link__about">${
  path(['general', 'description'], item)
    ? `<a rel="noopener noreferrer" href="${path(['url'], item)}" target="_blank" class="parse-link__pretty-link">${path(['general', 'title'], item)}</a><div class="parse-link__description">${path(['general', 'description'], item)}</div>`
    : ''
}${
  path(['openGraph', 'image', 'url'], item) ? (
    `<div class="parse-link__image-prev"><img src="${itemImage}"} alt="${itemDescription}"} /></div>`
  ) : ''
}</div></div>`
      : `<a rel="noopener noreferrer" class="link" target="_blank" href="${correctLink(trimSearchLink)}">${trimSearchLink}</a>`;
  }
  return children;
});

const shortEmojisHardRules = {
  ':(': 'upset',
  ':-(': 'upset',
  ':*': 'kiss',
  ':-*': 'kiss',
  ':)': 'smile',
  ':-)': 'smile',
  '8)': 'glasses',
  ':-1:': '-1',
  ':+1:': '+1',
};

const getEmojiShortCode = str => propOr(null, str, shortEmojisHardRules);

// eslint-disable-next-line consistent-return
const setShortSmile = memoizeWithIdentity((string) => {
  if (!string) return '';
  if (string.length < 2) return string;
  let str = string;
  let emojiSearchInText;
  if (test(/:[\w\d]*:/, string)) {
    str = replace(/:/g, '', string);
  } else if (test(/(\W{2,4}|([2-9]\W))(?!:\s)(?!:$)/, string) && getEmojiShortCode(str)) {
    const newStr = replace(/\s/g, '', str);
    const emojiIndex = new NimbleEmojiIndex(data);
    emojiSearchInText = emojiIndex.search(getEmojiShortCode(newStr), {
      maxResults: 1,
      // emojisToShowFilter: compose(lt(0), length, filter((includes(newStr))), prop('emoticons')),
    });
  }
  if (test(/:/g, string) || emojiSearchInText) {
    const id = cond([
      [pathOr(false, [0, 'id']), path([0, 'id'])],
      [compose(lt(0), length, () => getEmojiHtmlStringById(str)), always(str)],
      [T, F],
    ])(emojiSearchInText);
    if (id) {
      return prop('outerHTML',
        convertSpanEmojiToImg(getHTMLElementByString(getEmojiHtmlStringById(id)), `:${id}:`));
    }
  }
  return string;
});

const parseTextElements = (content, options = {}) => compose(
  curryRegexReplace(/(:\))|(:-\))|(:\*)|(:-\*)|(8\))|(:\()|(:-\()|(:\+1:)|(:-1:)/g, str => `${setShortSmile(str)}`),
  curryRegexReplace(regexRules.regTextElements, notPureCond([
    [curryRegexTest(regexRules.regEmojiForConvert), code => setShortSmile(code)],
    [curryRegexTest(/[(@[\w.]*?\s*?\w*\|@(\w|\.)+?\s*?\w*]/g),
      compose(item => (`<mention spellcheck="false" class="mention-tag" data-id="${item[1]}">${curryRegexReplace(/]/g, '', item[2])}</mention>`),
        curryRegexMatch(regexRules.regIdAndUsername))],
    [curryRegexTest(regexRules.regLink2),
      options.isEditing ? identity : getLinkByUrlParams(options)],
    [curryRegexReplace(/\n/g), () => '<br>'],
    [T, identity],
  ])),
  when(isNil, always('')),
)(content);

const parseTextStyles = curry(content => compose(
  curryRegexReplace(regexRules.regTextStyles, notPureCond([
    [curryRegexTest(regexRules.regBold), str => `<b>${removeCharsetFromStr(str, /\*/g)}</b>`],
    [curryRegexTest(regexRules.regStrike), str => `<s>${removeCharsetFromStr(str, /~/g)}</s>`],
    [curryRegexTest(regexRules.regItalic), str => `<i>${removeCharsetFromStr(str, /_/g)}</i>`],
    [curryRegexTest(regexRules.regCode), str => `<code>${removeCharsetFromStr(str, regexRules.regCodeDelete)}</code>`],
    [T, identity],
  ])),
  curryRegexReplace(/&nbsp;/g, ' '),
  when(isNil, always('')),
)(content));

// Need double check for correct work
// eslint-disable-next-line no-nested-ternary
const parseStringWrappers = (content, options) => compose(
  str => parseTextElements(str, options),
  parseTextStyles,
  curryRegexReplace(regexRules.regTextWrappers, notPureCond([
    [curryRegexTest(regexRules.regReply), str => `<span class="string--reply">${removeCharsetFromStr(str, /(^|\n)>/g)}</span>`],
    [curryRegexTest(regexRules.regPer), str => `<pre>${removeCharsetFromStr(str, /```/g)}</pre>`],
    [T, identity],
  ])),
  // eslint-disable-next-line no-nested-ternary
  curryRegexReplace(regexRules.regAllHtml, str => (curryRegexTest(regexRules.mentionHtmlElement, str) ? str : curryRegexTest(regexRules.mentionHtmlElement, str) ? str : '')),
  curryRegexReplace(regexRules.breakHtmlElement, '\n'),
  when(isNil, always('')),
)(content);

const parseToPlainText = memoizeWithIdentity(content => compose(
  curryRegexReplace(regexRules.regSpace, ' '),
  curryRegexReplace(regexRules.breakHtmlElement, '\r'),
  replace(/<\/?div>(?!$)/g, '\n'),
  when(isNil, always('')),
)(content));

export {
  removeCharsetFromStr,
  parseStringWrappers, parseTextStyles,
  parseTextElements,
  parseToPlainText,
};
