import React from 'react';
import { propOr, prop } from 'ramda';
import PropTypes from 'prop-types';

import './style.sass';
import { AsyncAutoComplete, DefaultAutoComplete, FormsCollection } from '../../../../ui-kit';

const InvoiceForm = ({
  values, errors, touched, handleChange, setFieldValue, t, projectsOption,
  getClientsAutocomplete, termsOptions, isCustomTerms, setDueDate, hasProjects,
}) => (
  <div className="invoice-form__container">
    <h3>Invoice info</h3>
    <FormsCollection.FormWrapper>
      <div className="invoice-form__container--col">
        <div className="invoice-form__container--form-row">
          <div className="invoice-form__container--form-row__label">
            Client
          </div>
          <AsyncAutoComplete
            name="client_id"
            placeholder={t('Choose client')}
            closeMenuOnSelect
            value={values.client_id}
            defaultValue={values.client_id}
            loadOptions={getClientsAutocomplete}
            onChange={value => setFieldValue('client_id', value)}
            touched={touched.client_id}
            error={errors.client_id}
          />
        </div>
        <div className="invoice-form__container--form-row">
          <div className="invoice-form__container--form-row__label">
            Project
          </div>
          <DefaultAutoComplete
            error={!hasProjects ? 'Client without projects' : prop('message', errors.project_id)}
            name="project_id"
            placeholder={t('Choose project')}
            closeMenuOnSelect
            value={hasProjects ? values.project_id : null}
            options={projectsOption}
            // loadOptions={getProjectsAutocomplete}
            onChange={value => setFieldValue('project_id', value)}
            touched
          />
        </div>
        <div className="invoice-form__container--form-row">
          <div className="invoice-form__container--form-row__label">
            Invoice number
          </div>
          <FormsCollection.Input
            name="invoice_number"
            value={values.invoice_number}
            error={propOr('', 'message', errors.invoice_number)}
            onChange={handleChange}
            touched
            id="invoice-form__field-invoice_number"
            placeholder="Invoice number"
          />
        </div>
      </div>
      <div className="invoice-form__container--col padding-bottom">
        <div className="invoice-form__container--form-row">
          <div className="invoice-form__container--form-row__label">
            Date
          </div>
          <FormsCollection.DatePicker
            minDate={new Date()}
            isNotUseTimeZone
            name="date"
            onChange={({ target: { value } }) => {
              setFieldValue('date', value);
              setDueDate({ value: values.terms.value }, value);
            }}
            value={values.date}
          />
        </div>
        <div className="invoice-form__container--form-row">
          <div className="invoice-form__container--form-row__label">
            Terms
          </div>
          <DefaultAutoComplete
            value={values.terms}
            name="terms"
            options={termsOptions}
            onChange={(option) => {
              setFieldValue('terms', option);
              setDueDate(option);
            }}
          />
        </div>
        <div className="invoice-form__container--form-row">
          <div className="invoice-form__container--form-row__label">
            Due date
          </div>
          <FormsCollection.DatePicker
            minDate={values.date}
            isNotUseTimeZone
            name="due_date"
            onChange={({ target: { value } }) => setFieldValue('due_date', value)}
            value={values.due_date}
            disabled={!isCustomTerms}
            className={!isCustomTerms ? 'due_date-disabled' : ''}
          />
        </div>
      </div>
    </FormsCollection.FormWrapper>
  </div>
);

InvoiceForm.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  touched: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  getClientsAutocomplete: PropTypes.func.isRequired,
  termsOptions: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  setDueDate: PropTypes.func.isRequired,
  isCustomTerms: PropTypes.bool.isRequired,
  projectsOption: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasProjects: PropTypes.bool.isRequired,
};

export default InvoiceForm;
