export const getShortName = (name) => {
  if (!name || (typeof name !== 'string')) {
    return '';
  }
  return name.match(/\b(\w)/g)?.join('').toUpperCase();
};

export const getAnyLangAbbreviation = (name) => {
  const UN = 'UN';
  const ABBREVIATION_LENGTH = 3;
  if (!name || (typeof name !== 'string')) {
    return UN;
  }
  let res = '';
  const nameWords = name.split(' ');
  for (let step = 0; step < ABBREVIATION_LENGTH; step += 1) {
    if (nameWords[step]?.length > 1) {
      res += nameWords[step].slice(0, 1).toUpperCase();
    }
  }
  return res.length > 0 ? res : UN;
};

export const stringToColor = (str) => {
  const avangaColors = [
    '#d32f2f',
    '#ffc107',
    '#ffeb3b',
    '#0288d1',
    '#2196f3',
    '#4caf50',
    '#f16e5e',
    '#d16052',
    '#b25246',
  ];
  return avangaColors[str ? str.length % 9 : 0];
};
