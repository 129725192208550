import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { APP_ROUTES } from '../../../../../../constants/appRoutes';
import { Label, OnlineStatusLabel } from '../../../../../../ui-kit';

const ChannelItem = ({
  channelId,
  unreadCount,
  channelStatusOnline,
  channelName,
  username,
}) => (
  <NavLink
    to={`${APP_ROUTES.MESSENGER}${channelId}/`}
    title={channelName}
    activeClassName="direct-nav__item--active"
    className={`direct-nav__item ${unreadCount ? 'channel--has-unread' : ''}`}
  >
    <OnlineStatusLabel status={channelStatusOnline} />
    <span className="text--cut">
      {username === channelName ? `${channelName} (you)` : channelName}
    </span>
    {unreadCount ? (
      <Label className="label--xs label--fill">{unreadCount}</Label>
    ) : (
      ''
    )}
  </NavLink>
);

ChannelItem.propTypes = {
  channelId: PropTypes.number.isRequired,
  unreadCount: PropTypes.number,
  channelStatusOnline: PropTypes.number.isRequired,
  channelName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

ChannelItem.defaultProps = {
  unreadCount: 0,
};

export default ChannelItem;
