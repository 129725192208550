import { NODE_EVENTS, RIGHT_SIDEBAR_VIEWS } from '@bp/constants/common';

export const NODES_ACTIONS_CONST = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  NOTHING: 'NOTHING',
};

export const CRUD_NODE_CONFIG_ACTIONS = {
  createTrigger: {
    mode: NODES_ACTIONS_CONST.CREATE,
    event: NODE_EVENTS.TRIGGER,
  },
  createAction: {
    mode: NODES_ACTIONS_CONST.CREATE,
    event: NODE_EVENTS.ACTION,
  },
  nothing: { mode: RIGHT_SIDEBAR_VIEWS.NOTHING },
  editAction: {
    mode: NODES_ACTIONS_CONST.EDIT,
    event: NODE_EVENTS.ACTION,
  },
  editTrigger: {
    mode: NODES_ACTIONS_CONST.EDIT,
    event: NODE_EVENTS.TRIGGER,
  },
};
