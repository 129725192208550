import React, { useState, memo } from 'react';
import { trim } from 'ramda';
import classNames from 'classnames';
import { getAnyLangAbbreviation, getShortName, stringToColor } from './utils';
import './style.sass';

/**
 *
 * @param children {string}
 * @param src {string}
 * @param colorText {string}
 * @param alt {string}
 * @param importantBackground {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const Avatar = memo(({
  children = '',
  src = null,
  colorText = '#fff',
  alt = '',
  importantBackground = '',
  id,
  onClick = () => {},
}) => {
  const [isErrorLoading, setIsErrorLoading] = useState(false);
  const isEmptyAvatar = !src && !trim(alt);
  const backgroundHelper = importantBackground || (src && !isErrorLoading ? 'transparent' : stringToColor(alt));

  const onSetErrorLoading = () => setIsErrorLoading(true);

  return (
    <div
      className={classNames('avatar-group', isEmptyAvatar && 'avatar-group--empty')}
      title={alt}
      onClick={() => onClick(id)}
      aria-hidden="true"
      style={!isEmptyAvatar ? { backgroundColor: backgroundHelper } : {}}
    >
      {src && !isErrorLoading ? (
        <div className="avatar-group__pseudo-image" style={{ backgroundImage: `url(${src})` }}>
          <img src={src} onError={onSetErrorLoading} style={{ display: 'none' }} alt={alt} />
        </div>
      ) : (
        <div className="avatar-group__short-name" style={{ color: colorText }}>
          <span data-testid="shortName">{getShortName(alt) || getAnyLangAbbreviation(alt)}</span>
        </div>
      )}
      {children}
    </div>
  );
});
