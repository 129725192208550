import { compose, withStateHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';
import { isString } from 'ramda-extension';
import RightSideBarLeadsContainer from './rightSideBarLeadsContainer';

const setActiveTabTitleStateHandler = () => activeTabTitle => ({
  activeTabTitle: isString(activeTabTitle) ? activeTabTitle : '',
});

const enhancer = compose(
  withTranslation(['common']),
  withStateHandlers(
    () => ({ activeTabTitle: '' }), {
      setActiveTabTitle: setActiveTabTitleStateHandler,
    },
  ),
);

export default enhancer(RightSideBarLeadsContainer);
