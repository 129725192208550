import React from 'react';

import './style.sass';
import classNames from 'classnames';

/**
 *
 * @param children {JSX.Element}
 * @param customRef {function}
 * @param handleSubmit {function}
 * @param className {string}
 * @param name {string}
 * @param classNameForm {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const FormWrapper = ({
  children,
  handleSubmit,
  customRef = () => {},
  name = '',
  className = '',
  classNameForm = '',
}) => (
  <div className={classNames('form-group ', classNameForm)}>
    <form action="" ref={e => customRef(e)} onSubmit={handleSubmit} name={name} className={classNames('form-group__form ', className)}>
      { children }
    </form>
  </div>
);
