/* eslint-disable no-param-reassign */
import {
  compose, lifecycle, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import {
  curry, replace, without, cond, is, T, isNil, clone, pathOr, includes, concat,
  mergeDeepWithKey, forEachObjIndexed, mapObjIndexed, hasIn, identity, ifElse,
  prop, always,
} from 'ramda';
import { isNotEmpty } from 'ramda-extension';
import { push } from 'connected-react-router';
import { stringify, parse } from 'qs';
import { withRouter } from 'react-router';
import { uiActions } from '../../state/ui';
import { getFiltersByName } from '../../state/ui/selectors';

const mapDispatchToProps = ({
  pushParams: push,
  saveUrlFilter: uiActions.saveUrlFilter,
  resetUrlFilter: uiActions.resetUrlFilter,
});

const mapStateToProps = (state, { saveFilterByName }) => ({
  filtersFromStore: getFiltersByName(state)(saveFilterByName),
});

const getParamFromUrl = curry(location => compose(
  parse,
  replace('?', ''),
)(location));

const convertToArray = cond([
  [is(Array), identity],
  [T, data => [data]],
]);

const convertParamsToValidData = curry((strategy, num, key) => {
  if (!isNil(strategy) && includes(key, strategy.concat)) {
    return convertToArray(num);
  }
  return num;
});

const modifyParams = curry((source, key, valuesForReset) => cond([
  [isNil, () => delete source[key]],
  [is(Array), () => {
    const restValues = without(valuesForReset, source[key]);
    source[key] = restValues;
  }],
])(valuesForReset));

const modifyHasInParams = curry((source, key, values) => cond([
  [hasIn(key), () => modifyParams(source, key, values[key])],
  [T, () => source],
])(values));

const resetEachValue = curry((paramsForReset, value, key, obj) => modifyHasInParams(
  obj,
  key,
  paramsForReset,
));

const resetParams = curry((stringifyParams, processFunc, convert) => compose(
  stringifyParams,
  forEachObjIndexed(processFunc),
  convert,
  clone,
));

const addParams = curry(stringifyParams => compose(
  stringifyParams,
  clone,
));

const stringifyParams = curry(params => stringify(params, { arrayFormat: 'repeat' }));

const makeNewArrayParams = compose(stringifyParams, mergeDeepWithKey);

const stringifyArrayParam = curry((params, value, urlParams) => {
  const concatValues = (k, l, r) => {
    if (!isNil(params) && includes(k, params.concat)) {
      return concat(convertToArray(l), r);
    }
    return r;
  };
  return makeNewArrayParams(concatValues, urlParams, value);
});

const onSetUrlParamHandler = curry((params, {
  location, pushParams, saveUrlFilter, saveFilterByName,
}) => curry((value) => {
  const paramFromUrl = getParamFromUrl(location.search);
  const newSearchParamValue = addParams(
    stringifyArrayParam(params, value),
    mapObjIndexed(convertParamsToValidData(params)),
  )(paramFromUrl);
  if (isNotEmpty(saveFilterByName)) {
    saveUrlFilter({ [saveFilterByName]: value });
  }
  pushParams({ search: newSearchParamValue });
}));

const onResetUrlParamHandler = curry((params, {
  location, pushParams, saveFilterByName,
  resetUrlFilter,
}) => curry((value) => {
  const paramsFromUrl = getParamFromUrl(location.search);
  const newSearchParamValue = resetParams(
    stringifyParams,
    resetEachValue(value),
    mapObjIndexed(convertParamsToValidData(params)),
  )(paramsFromUrl);
  if (isNotEmpty(saveFilterByName)) {
    resetUrlFilter({ groupName: saveFilterByName, filterValue: value });
  }
  pushParams({ search: newSearchParamValue });
}));

const onGetUrlParam = params => ({ location }) => (param, or = null) => {
  const urlsParam = getParamFromUrl(location.search);
  const result = pathOr(or, param, urlsParam);
  return includes(prop(0, param), pathOr([], ['arrayFields'], params)) ? ifElse(is(Array), identity, val => [val])(result) : result;
};

const onGetUrlParams = ({ location }) => () => getParamFromUrl(location.search);

const withUrlParams = ({
  params = null,
  saveFilterByName: saveFilterByNameProp = always({}),
}) => compose(
  withProps(props => ({
    saveFilterByName: saveFilterByNameProp(props),
  })),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withHandlers({
    onSetUrlParam: onSetUrlParamHandler(params),
    onResetUrlParam: onResetUrlParamHandler(params),
    getUrlParam: onGetUrlParam(params),
    getUrlParams: onGetUrlParams,
  }),
  lifecycle({
    componentDidMount() {
      const {
        // filtersFromStore, onSetUrlParam,
        saveFilterByName,
      } = this.props;
      if (isNotEmpty(saveFilterByName)) {
        // onSetUrlParam(filtersFromStore);
      }
    },
  }),
);

export default withUrlParams;
