import {
  branch, compose, renderNothing, getContext,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import rules from '../rules';
import EditSprintModal from './editSprintModal';
import { withCRUDSprint } from '../../../../../utils/enchancers';
import { projectActions, projectSelectors } from '../../../../../state/project';

const mapStateToProps = (state, { selectedSprintId }) => ({
  sprint: projectSelectors.getProjectSprint(state)(selectedSprintId),
  isPending: projectSelectors.getUpdateSprintRequestPending(state),
});

const mapDispatchToProps = {
  updateSprint: projectActions.updateSprintRequest,
};

const enhance = compose(
  getContext({
    selectedSprintId: PropTypes.number.isRequired,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ sprint }) => !sprint.title, renderNothing),
  withCRUDSprint({
    rules,
    modalName: 'editSprintModal',
    data: ({ sprint }) => ({ sprint }),
    onSubmit: (formData, props) => {
      const {
        updateSprint, closeModal, selectedSprintId, projectId,
      } = props.props;
      updateSprint({
        ...formData,
        projectId,
        sprintId: selectedSprintId,
      }, {
        callbacks: {
          success: () => closeModal('editSprintModal'),
        },
      });
    },
  }),
);
export default enhance(EditSprintModal);
