import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '../../..';
import { GitlabCommitsFilter } from '../GitlabCommitsFilter';
import { GitlabCommitsList } from '../GitlabCommitsList';
import './style.sass';


/**
 * @param name {string}
 * @param isOpen {boolean}
 * @param onGoPreviousPage {function}
 * @param onGoNextPage {function}
 * @param totalPages {number}
 * @param commits {array}
 * @param currentPage {number}
 * @param totalCommits {number}
 * @param titleValue {string}
 * @param onChangeTitleValue {function}
 * @param authorValue {string}
 * @param onChangeAuthorValue {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const GitlabCommitsGrid = ({
  name, isOpen, onGoPreviousPage, onGoNextPage, totalPages, commits, currentPage, totalCommits,
  titleValue, onChangeTitleValue, authorValue, onChangeAuthorValue,
}) => {
  const { t } = useTranslation(['common']);
  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current && isOpen) { firstRender.current = false; }
  }, [authorValue, titleValue]);

  return (
    <Grid.Wrapper
      name={name}
      className={classNames('table-commits__repository-body', { 'table-commits__repository-body--open': isOpen })}
    >
      { (firstRender.current && !commits.length) ? null : (
        <GitlabCommitsFilter
          titleValue={titleValue}
          onChangeTitleValue={onChangeTitleValue}
          authorValue={authorValue}
          onChangeAuthorValue={onChangeAuthorValue}
          isOpen={isOpen}
        />
      )}

      {commits.length ? (
        <>
          <Grid.Row className="table-commits__controls">
            <Grid.Col
              name="Commit message"
              className="table-commits__col table-commits__col--title"
            />
            <Grid.Col
              name="Date"
              className="table-commits__col table-commits__col--date"
            />
            <Grid.Col
              name="Author"
              className="table-commits__col table-commits__col--author"
            />
          </Grid.Row>
          <GitlabCommitsList
            isOpen={isOpen}
            onGoPreviousPage={onGoPreviousPage}
            onGoNextPage={onGoNextPage}
            totalPages={totalPages}
            commits={commits}
            currentPage={currentPage}
            totalCommits={totalCommits}
          />
        </>) : (
          <div className="table-commits__empty">
            {t('There are no commits')}
          </div>
      )}
    </Grid.Wrapper>);
};
