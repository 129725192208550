import { handleActions } from 'redux-actions';

import { isEmpty } from 'ramda';
import types from './types';
import { mergeIn } from '../../utils/helpers/ramdaStateHelpers';

const reducer = handleActions({
  [types.UPDATE_TASK_REQUEST]: mergeIn(({ payload: data }) => ({
    active: {
      ...data,
    },
  })),
  [types.SET_MY_TASKS]: mergeIn(
    (
      {
        payload: {
          data: { entities, result }, ...summary
        },
      },
    ) => ({
      currents: {
        entities,
        result,
      },
      summary,
    }),
  ),
  [types.SET_MY_TASKS_BY_PROJECTS]: mergeIn(
    (
      { payload: { taskByProjectData } },
    ) => {
      const tasks = Object.values(taskByProjectData.filter(project => !isEmpty(project))
        .map(data => data.tasks)).flat();
      const items = {};
      for (const task of tasks) {
        items[task.id] = task;
      }
      return {
        summary: {},
        currents: {
          entities: {
            items,
          },
          taskByProjectData: Object.values(taskByProjectData),
        },
      };
    },
  ),
  [types.SET_MY_PROJECT_TASK]: mergeIn(({ payload: projectData },
    { currents: { taskByProjectData, entities: { items } } }) => {
    const filteredData = Object.values(items)
      .filter(task => task.project_id !== projectData.projectId);
    const { tasks } = projectData;
    const result = [...filteredData, ...tasks];
    const resultItems = {};
    for (const task of result) {
      resultItems[task.id] = task;
    }

    const newTaskByProjectData = taskByProjectData.map(
      stateProjectData => (stateProjectData.projectId === projectData.projectId
        ? projectData
        : stateProjectData),
    );

    return {
      currents: {
        entities: {
          items: resultItems,
        },
        taskByProjectData: newTaskByProjectData,
      },
    };
  }),
  [types.SET_MY_TASKS_SUMMARY]: mergeIn((
    { payload: { data: { entities }, meta, ...summary } },
  ) => ({
    summary,
    entities,
  })),
}, {
  summary: {},
  currents: {},
  active: {},
});

export default reducer;
