import QueryString from 'qs';
import {
  compose, withStateHandlers, withState, lifecycle, withHandlers, withContext, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import {
  curry, prop, equals, assoc, isEmpty, anyPass, unapply, map,
} from 'ramda';
import { notEqual } from 'ramda-extension';
import PropTypes from 'prop-types';
import MyTasks from './myTasks';
import { tasksActions, tasksSelectors } from '../../state/tasks';
import {
  preloaderWhileLoading, withFilters, withSorting, withUrlParams, withRefs, withPagination,
} from '../../utils/enchancers';
import { MY_TASKS, FILTER_STATUS, GRID_NAMES } from '../../constants/tasks';
import { getOppositeValue } from '../../utils/helpers/commonHelpers';
import { openModal } from '../../state/ui/actions';
import { setFilterForSingleParam } from '../../utils/helpers/uiComponentHelpers/filters';
import { calculateOffset } from '../../utils/helpers/uiComponentHelpers/pagination';

const mapDispatchToProps = {
  getMyTasksRequest: tasksActions.getMyTasksRequest,
  getMyTasksByProjectsRequest: tasksActions.getMyTasksByProjectsRequest,
  getMyTasksSummaryRequest: tasksActions.getMyTasksSummaryRequest,
  getMyProjectTasksRequest: tasksActions.getMyProjectTasksRequest,
  setOpenModal: openModal,
};

const mapStateToProps = state => ({
  myTasksEntities: tasksSelectors.getMyTasksCurrentEntities(state),
  isLoading: tasksSelectors.getMyTaskPendingRequest(state),
  myTasks: tasksSelectors.getMyTaskIDs(state),
  summary: tasksSelectors.getMyTasksSummary(state),
  tasksByProjectList: tasksSelectors.getTasksByProjectList(state),
});

const defaultSorting = {
  order: 'desc',
  sortBy: 'created_at',
  assigneeToMe: 1,
};

const LIMIT = 10;
const DEFAULT_PARAMS = { offset: '0', page: 1 };

const onSearchHandler = props => () => {
  const {
    onSetUrlParam, onResetUrlParam, searchName, mergeFilters, setFilters, setIsNeedRefresh,
  } = props;
  const setSearchNameFilterAndUrl = setFilterForSingleParam(mergeFilters, setFilters);
  if (isEmpty(searchName)) {
    setSearchNameFilterAndUrl(onResetUrlParam, 'title', null);
  } else {
    setSearchNameFilterAndUrl(onSetUrlParam, 'title', searchName);
  }
  return setIsNeedRefresh(true);
};

const setSearchTaskName = ({ setFilters }) => ({ target: { value } }) => setFilters({
  title: value,
});


const onDeleteTaskModalHandler = ({
  setOpenModal,
}) => () => setOpenModal('deleteTaskModal');

const onEditTaskModalHandler = ({
  setOpenModal,
  setSelectedTask,
}) => (id) => {
  setSelectedTask(id);
  setOpenModal('editTaskModal');
};

const getParamsFomFilter = activeFilter => (
  equals(activeFilter, MY_TASKS.FILTER.ASSIGNED_TO_ME)
    ? { assigneeToMe: FILTER_STATUS.ACTIVE }
    : { createdByMe: FILTER_STATUS.ACTIVE }
);

const makeSortingParams = curry((params, sort, order) => {
  const assocIfTruthy = curry((property, value, obj) => (
    value ? assoc(property, value, obj) : obj));
  return compose(
    assocIfTruthy('order', order),
    assocIfTruthy('sort', sort),
  )(params);
});

const getSortingParamsForRequest = (sort, order) => {
  const isSortEqualTo = compose(
    anyPass,
    unapply(map(equals(sort))),
  );
  return isSortEqualTo(sort, 'created_at') ? makeSortingParams({}, sort, order) : defaultSorting;
};

const getMyTasks = ({
  getMyTasksRequest, sort, order, activeFilter, getUrlParam, pagination, groupBy,
}) => {
  const { offset, page } = pagination;
  const paramsForRequest = getSortingParamsForRequest(sort, order);
  const paramsFromFilter = getParamsFomFilter(activeFilter);
  getMyTasksRequest({
    ...paramsForRequest,
    ...paramsFromFilter,
    offset,
    groupBy,
    page,
    title: getUrlParam(['title']),
  });
};
const getMyTasksByProject = ({
  getMyTasksByProjectsRequest, sort, order, activeFilter, pagination, groupBy, title,
}) => {
  const { offset, page } = pagination;
  const paramsForRequest = getSortingParamsForRequest(sort, order);
  const paramsFromFilter = getParamsFomFilter(activeFilter);
  getMyTasksByProjectsRequest({
    ...paramsForRequest,
    ...paramsFromFilter,
    offset,
    groupBy,
    page,
    title,
  });
};

const getMyProjectTask = ({
  getMyProjectTasksRequest, sort, order, activeFilter, getUrlParam,
  pagination, groupBy, projectId, requestOffset, offset,
}) => {
  const { page } = pagination;
  const paramsForRequest = getSortingParamsForRequest(sort, order);
  const paramsFromFilter = getParamsFomFilter(activeFilter);
  getMyProjectTasksRequest({
    ...paramsForRequest,
    ...paramsFromFilter,
    offset: requestOffset || offset,
    groupBy,
    page,
    projectId,
    order,
    title: getUrlParam(['title']),
  });
};

const onPageChangeHandler = ({
  getMyTasksRequest, pagination, sort, order, getUrlParam,
  activeFilter, groupBy, getMyProjectTasksRequest,
}) => ({ projectId, requestOffset, ...meta }) => {
  if (groupBy === MY_TASKS.GROUP_BY.TASKS) {
    getMyTasks({
      getMyTasksRequest,
      pagination,
      sort,
      order,
      groupBy,
      getUrlParam,
      activeFilter,
    }, meta);
  } else {
    getMyProjectTask({
      getMyProjectTasksRequest,
      pagination,
      sort,
      order,
      groupBy,
      getUrlParam,
      activeFilter,
      projectId,
      requestOffset,
    }, meta);
  }
};

const onSuccessTaskCRUDHandler = props => ({ projectId }) => {
  const { groupBy, tasksByProjectList } = props;
  if (groupBy === MY_TASKS.GROUP_BY.TASKS) getMyTasks(props);
  else {
    const activeProject = tasksByProjectList.find(tasksData => tasksData.projectId === projectId);
    const { offset } = activeProject;
    getMyProjectTask({ ...props, projectId, offset });
  }
};


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    () => ({ statusVisible: 'brill', areMobileFiltersOpen: false }), {
      toggleMobileFiltersHandler:
        ({ areMobileFiltersOpen }) => () => ({ areMobileFiltersOpen: !areMobileFiltersOpen }),
    },
  ),
  withRefs(),
  withUrlParams({}),
  withFilters({ initial: ({ getUrlParam }) => ({ title: getUrlParam(['title']) }) }),
  withState('selectedTask', 'setSelectedTask', null),
  withState('selectedTaskClientId', 'setSelectedTaskClientId', null),
  withState('selectedSort', 'setSelectedSort', {}),
  withState('projectId', 'setProjectId', null),
  withState('activeFilter', 'setActiveFilter', ({ getUrlParam }) => getUrlParam(['role'], MY_TASKS.FILTER.ASSIGNED_TO_ME)),
  withState('groupBy', 'setGroupBy', ({ getUrlParam }) => getUrlParam(['groupBy'], MY_TASKS.GROUP_BY.TASKS)),
  withState('isNeedRefresh', 'setIsNeedRefresh', false),
  withProps(({ getFilter, groupBy }) => ({
    searchName: getFilter('', 'title'),
    gridName: equals(groupBy, MY_TASKS.GROUP_BY.TASKS)
      ? GRID_NAMES.MY_TASKS_LIST
      : GRID_NAMES.MY_TASKS_PROJECT_LIST,
  })),
  withSorting({
    selector: props => props.getUrlParams({}),
    values: ({
      order: ({ getUrlParam }) => getUrlParam(['order'], 'desc'),
      sort: ({ getUrlParam }) => getUrlParam(['sort'], 'created_at'),
    }),
    onSortBy: ({ order, onSetUrlParam }) => (dataSorting) => {
      const sort = prop('sort', dataSorting);
      const newOrder = notEqual(sort, 'created_at') ? getOppositeValue('asc', 'desc', order) : prop('order', dataSorting);
      onSetUrlParam({ sort, order: newOrder });
    },
  }),
  withPagination({
    limit: () => LIMIT,
    offset: ({ getUrlParam }) => calculateOffset(getUrlParam(['page'], 1), LIMIT),
    page: ({ getUrlParam }) => getUrlParam(['page'], 1),
    selector: ({ gridName }) => gridName,
  }),
  withHandlers({
    onDeleteTaskModal: onDeleteTaskModalHandler,
    onEditTaskModal: onEditTaskModalHandler,
    onSuccessTaskCRUD: onSuccessTaskCRUDHandler,
    onSearch: onSearchHandler,
    setSearchTaskName,
    onPageChange: onPageChangeHandler,
  }),
  withContext({
    onDeleteTaskModal: PropTypes.func,
    onEditTaskModal: PropTypes.func,
    setSelectedTask: PropTypes.func,
    setProjectId: PropTypes.func,
    setSelectedTaskClientId: PropTypes.func,
    projectId: PropTypes.number,
    selectedSort: PropTypes.instanceOf(Object),
  }, props => ({
    onDeleteTaskModal: props.onDeleteTaskModal,
    onEditTaskModal: props.onEditTaskModal,
    setSelectedTask: props.setSelectedTask,
    setProjectId: props.setProjectId,
    projectId: props.projectId,
    selectedSort: props.selectedSort,
    setSelectedTaskClientId: props.setSelectedTaskClientId,
  })),
  lifecycle({
    componentDidMount() {
      getMyTasks(this.props);
    },
    componentDidUpdate(prevProps) {
      const {
        activeFilter: prevActiveFilter,
        order: prevOrder,
        isNeedRefresh: prevIsNeedRefresh,
        pagination: prevPagination,
        groupBy: prevGroupBy,
        sort: prevSort,
        searchName: prevSearchName,
      } = prevProps;
      const {
        activeFilter, order, isNeedRefresh, setIsNeedRefresh,
        pagination, groupBy, tasksByProjectList, sort, searchName, onSetUrlParam, path, onPath,
      } = this.props;
      if (notEqual(prevSearchName, searchName)) {
        onSetUrlParam({
          ...DEFAULT_PARAMS, title: searchName,
        });
      }
      if (isEmpty(searchName) && notEqual(prevSearchName, searchName)) {
        const prevUrlParams = QueryString.parse(path, { ignoreQueryPrefix: true });
        onSetUrlParam({ ...prevUrlParams, title: null });
      }
      if (notEqual(prevOrder, order)
        || notEqual(prevActiveFilter, activeFilter)
        || notEqual(prevGroupBy, groupBy)
        || notEqual(prevSort, sort)) {
        onSetUrlParam(DEFAULT_PARAMS);
        onPath(null);
        getMyTasks(this.props);
      }
      if (notEqual(prevIsNeedRefresh, isNeedRefresh)
        || notEqual(prevPagination, pagination)) {
        if (groupBy === MY_TASKS.GROUP_BY.TASKS) {
          getMyTasks(this.props);
        } else if (groupBy === MY_TASKS.GROUP_BY.PROJECTS) {
          if (isEmpty(tasksByProjectList)) {
            getMyTasksByProject(this.props);
          } else if (equals(prevPagination, pagination)) {
            getMyTasksByProject({ ...this.props, title: searchName });
          }
        }
        setIsNeedRefresh(false);
      }
    },
  }),
  preloaderWhileLoading({
    dimension: 100,
    fullScreen: true,
    isLoading: props => !props.isLoading,
  }),
);
export default enhance(MyTasks);
