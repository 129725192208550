import { compose, getContext, withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as routerPush } from 'connected-react-router';
import { withRouter } from 'react-router';
import HrHeader from './hrHeader';

const mapDispatchToProps = { push: routerPush };

const goBackHandler = ({ push, setHeadTitle }) => () => {
  push('/hr-management/');
  setHeadTitle(null);
};

const enhance = compose(
  withRouter,
  connect(null, mapDispatchToProps),
  getContext({ setHeadTitle: PropTypes.func }),
  withHandlers({ goBack: goBackHandler }),
);
export default enhance(HrHeader);
