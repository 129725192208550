import { useFormik } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CUSTOM_API } from '@/constants/customRoutes';
import { uiActions } from '@/state/ui';
import { getModalWrapperForHooks } from '@/state/ui/selectors';
import { successActionCallback } from '@/utils/helpers/actionCallbacks';
import { BUSINESS_PROCESS_STATUSES } from '@bp/constants';
import { addBusinessProcessRequest } from '@bp/store/bpModule/actions';

export const useCreateProcessModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const isOpen = useSelector(getModalWrapperForHooks('createProcessModal'));

  const onClose = useCallback(() => {
    dispatch(uiActions.closeModal('createProcessModal'));
  }, [dispatch]);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } = useFormik({
    initialValues: {},
    onSubmit: (formValues, { resetForm }) => {
      dispatch(
        addBusinessProcessRequest(
          { ...formValues, status: BUSINESS_PROCESS_STATUSES.NOT_ACTIVE },
          successActionCallback(({ bp }) => {
            onClose();
            resetForm();
            history.push(`${CUSTOM_API.BUSINESS_PROCESSES}${bp.id}`);
          }),
        ),
      );
    },
  });

  return {
    t,
    isOpen,
    values,
    touched,
    errors,
    handleBlur,
    handleSubmit,
    handleChange,
    onClose,
  };
};
