import React from 'react';

import { Pagination, Grid } from '../../../../../../../ui-kit';
import { RolesItem } from '../rolesItem/RolesItem';
import './style.sass';

/**
 * @param rolesEntities {object}
 * @param rolesList {array}
 * @param rolesCount {number}
 * @param gridName {string}
 * @param pagination {object}
 * @param order {string}
 * @param sort {string}
 * @param onSortBy {function}
 * @param isLoading {boolean}
 * @param onDeleteRole {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const RolesList = ({
  rolesEntities,
  rolesList,
  rolesCount,
  gridName,
  pagination,
  order = '',
  sort = '',
  onSortBy,
  isLoading,
  onDeleteRole,
}) => (
  <Grid.Wrapper
    isLoading={!isLoading}
    isKeepOptions
    name={gridName}
    className="table-roles"
    pagination={(
      <Pagination
        count={rolesCount}
        component={gridName}
        pagination={pagination}
        className="roles-list__pagination"
      />
      )}
  >
    <Grid.Row className="table-roles__row-controls">
      <Grid.Col
        name="ID"
        className="table-roles__col table-roles__col--id"
      />
      <Grid.Col
        sort={sort}
        order={order}
        filter="name"
        onClick={() => onSortBy({ sort: 'name' })}
        name="Name"
        className="table-roles__col table-roles__col--name"
      />
      <Grid.Col className="table-roles__col table-roles__col--controls" />
      <Grid.Col className="table-roles__col table-roles__col--controls" />
    </Grid.Row>
    {
      rolesList.map(roleId => (
        <RolesItem
          roleId={roleId}
          rolesEntities={rolesEntities}
          onDeleteRole={onDeleteRole}
        />
      ))}
  </Grid.Wrapper>
);
