import * as yup from 'yup';
import { symbolsValidator } from '../../../../../utils/helpers/stringHelpers/common';
import * as translateHelpers from '../../../../../utils/helpers/translateHelpers';

const rules = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .required('First name is required')
    .test({
      test: symbolsValidator,
      message: 'Incorrect values',
      params: {
        key: translateHelpers.t('common', 'Incorrect values'),
      },
    }),
  last_name: yup
    .string()
    .trim()
    .required('Last name is required')
    .test({
      test: symbolsValidator,
      message: 'Incorrect values',
      params: {
        key: translateHelpers.t('common', 'Incorrect values'),
      },
    }),
  username: yup
    .string()
    .trim()
    .required('Username is required')
    .test({
      test: symbolsValidator,
      message: 'Incorrect values',
      params: {
        key: translateHelpers.t('common', 'Incorrect values'),
      },
    }),
  email: yup
    .string()
    .trim()
    .email('Email is not valid')
    .required('Email is required'),
});

export default rules;
