import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonsCollection, FormsCollection, ModalWindow } from '@/ui-kit';
import { useKnowledgeBaseContext } from '@/views/knowledgeBase/context/useKnowledgeBaseContext';
import api from '../../../../../../../api/knowledgeBase.service';
import './style.sass';

export const EditSubcategoryModal = ({ isOpen, setIsOpenedModal }) => {
  const { t } = useTranslation();

  const {
    categoryData,
    setCategoryId,
    setCategoryData,
    refreshCategories,
  } = useKnowledgeBaseContext();

  const {
    control, reset, handleSubmit,
  } = useForm({
    defaultValues: {
      description: ' ',
    }
  });

  const onCloseModal = () => {
    reset();
    setCategoryId(null);
    setCategoryData(null);
    setIsOpenedModal(false);
  };

  const onSubmit = async (data) => {
    try {
      await api.updateKnowledgeBaseCategory(categoryData?.id, { ...data });

      refreshCategories();

      onCloseModal();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (categoryData) {
      reset({
        description: categoryData?.description
      })
    }
  }, [categoryData])

  return (
    <ModalWindow
      isOpen={isOpen}
      onClose={onCloseModal}
      modalName="editSubcategoryModal"
      title="Edit subcategory"
    >
      <FormsCollection.FormWrapper handleSubmit={handleSubmit(onSubmit)} className="edit-subcategory">
        <div className="edit-subcategory__row">
          <Controller
            name="description"
            control={control}
            render={({ onBlur, value, field, onChange, error }) => (
              <FormsCollection.Input
                placeholderJump="Subcategory title"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                touched
                error={error}
                {...field}
              />
            )}
          />
        </div>
        <div className="button-group">
          <ButtonsCollection.ButtonBrill
            type="submit"
            className="button--md edit-subcategory__button-save button-brill--fill"
          >
            {t('Save')}
          </ButtonsCollection.ButtonBrill>
        </div>
      </FormsCollection.FormWrapper>
    </ModalWindow>
  )
}
