import { compose, withProps } from 'recompose';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { propOr } from 'ramda';

import InvoiceItem from './invoiceItem';
import { invoicesSelectors } from '../../../../../../state/invoices';

const mapStateToProps = (state, { invoiceItemId }) => ({
  invoiceItem: invoicesSelectors.getInvoiceItemById(state)(invoiceItemId),
  currencySign: invoicesSelectors.getCurrencySign(state),
});

const enhance = compose(
  connect(mapStateToProps, null),
  withFormik({
    mapPropsToValues: ({ invoiceItem }) => ({
      details: propOr(null, 'details', invoiceItem),
      rate: propOr(null, 'rate', invoiceItem),
      quantity: propOr(null, 'quantity', invoiceItem),
    }),
    validateOnChange: true,
    enableReinitialize: true,
  }),
  withProps(({ invoiceItem: { amount } }) => ({
    amount: amount || 0,
  })),
  withProps(({ amount }) => ({
    amount: amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
  })),
);

export default enhance(InvoiceItem);
