import { compose, getContext } from 'recompose';
import { map, values, path } from 'ramda';
import PropTypes from 'prop-types';

import FilterFields from './FilterFields';
import { withAutocomplete } from '../../../../../../../utils/enchancers';
import * as newTaskActions from '../../../../../../../state/newTask/actions';
import { renameKeysTitleIntoValue } from '../../../../../../../utils/helpers/uiHelpers';

const enhance = compose(
  getContext({
    setUserId: PropTypes.func,
    selectedProjectLabel: PropTypes.instanceOf(Object),
    setFilters: PropTypes.func,
    onSetUrlParam: PropTypes.func,
    mergeFilters: PropTypes.func,
    onResetUrlParam: PropTypes.func,
  }),
  withAutocomplete({
    name: 'getProjectAutocomplete',
    action: newTaskActions.getProjectsTaskCrudRequest,
    dataPath: compose(
      map(renameKeysTitleIntoValue),
      values,
      path(['data', 'entities', 'projects']),
    ),
    searchField: 'title',
    appendOption: () => ({ label: 'All projects', value: null }),
    appendOptionWhen: ({ activeView }) => activeView === 'myStatistics' || activeView === 'project',
  }),
  // withAutocomplete({
  //   name: 'getUsersAutocompleteByProjects',
  //   action: usersActions.getUsersListAutocompleteRequest,
  //   dataPath: propOr({}, 'users'),
  //   meta: { withAllUsersLabel: true },
  //   appendOption: () => ({ label: 'All users', value: null }),
  //   searchField: 'q',
  // }),

);
export default enhance(FilterFields);
