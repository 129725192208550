import React from 'react';
import PropTypes from 'prop-types';
import { HrCreateForm } from '../../components';
import { ScrollWrapper } from '../../../../../ui-kit';

const HrCandidateCreate = ({ createCandidate }) => (
  <div className="main-container__content hr-candidate__crud-page">
    <ScrollWrapper>
      <div className="HrCandidateCreate">
        <HrCreateForm
          onSubmitForm={createCandidate}
        />
      </div>
    </ScrollWrapper>
  </div>
);

HrCandidateCreate.propTypes = {
  createCandidate: PropTypes.func.isRequired,
};
HrCandidateCreate.defaultProps = {};

export default HrCandidateCreate;
