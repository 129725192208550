import React from 'react';
import PropTypes from 'prop-types';
import { ButtonsCollection } from '../../../../../ui-kit';
import './style.scss';

const HrHeader = ({ title, goBack }) => (
  <div className="HrHeader">
    <h1 className="weight--medium title--xl title-page">
      {title && (
        <ButtonsCollection.ButtonIcons
          title="Go back to users"
          renderMobileButton
          className="user-head-back"
          onClick={goBack}
        >
          <span className="icon-expand-arrow-left" />
        </ButtonsCollection.ButtonIcons>
      )}
      <span>HR management</span>
      {title && (<span className="p-l-5 title-page_name">{` - ${title}`}</span>)}
    </h1>
    {!title && (
      <ButtonsCollection.ButtonBrill
        asLink
        href="/hr-management/candidate/create"
        type="button"
        renderMobileButton
        className="HrHeader_headerButton"
      >
        Create candidate
      </ButtonsCollection.ButtonBrill>
    )}
  </div>
);

HrHeader.propTypes = {
  title: PropTypes.string,
  goBack: PropTypes.func.isRequired,
};
HrHeader.defaultProps = {
  title: null,
};

export default HrHeader;
