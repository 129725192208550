import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { bpActions, bpSelectors } from '@bp/store/bpModule';

export const useLogsContainer = () => {
  const dispatch = useDispatch();
  const logs = useSelector(bpSelectors.getLogs);

  const isPending = useSelector(bpSelectors.getLogsRequestPending);
  const {
    params: { processId },
  } = useRouteMatch();

  useEffect(() => {
    dispatch(bpActions.getBusinessProcessLogsRequest(processId));
    dispatch(bpActions.getBusinessProcessRequest({ processId }));
  }, [dispatch, processId]);

  return { logs, isPending };
};
