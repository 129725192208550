import React from 'react';
import { Emoji } from 'emoji-mart';
import { EMOJI_SIZE } from '../constants';

/**
 *
 * @param item {string}
 * @param onClick {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const EmojiItem = ({ item, onClick }) => (
  <li className="emoji__picker-item">
    <Emoji
      emoji={item}
      set="messenger"
      onClick={onClick}
      size={EMOJI_SIZE}
    />
  </li>
);
