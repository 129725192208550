import * as contactsActions from './actions';
import * as contactsSelectors from './selectors';
import * as contactsOperations from './operations';
import * as contactsSchemas from './schema';
import contactsTypes from './types';
import reducer from './reducers';

export {
  contactsActions,
  contactsSelectors,
  contactsOperations,
  contactsSchemas,
  contactsTypes,
};

export default reducer;
