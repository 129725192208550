import { compose } from 'recompose';
import { map, values } from 'ramda';
import { renameKeys } from '../../../../utils/helpers/commonHelpers';
import { STATUS } from '../../../../constants/tasks';

// eslint-disable-next-line import/prefer-default-export
export const stateOfTasksStatusesOptions = compose(map(renameKeys({
  title: 'label',
  id: 'value',
})), values)(STATUS);
