import { compose, withStateHandlers } from 'recompose';
import { withTranslation } from 'react-i18next';

import RightSideBarContainer from './rightSideBarContainer';

const setActiveTabTitle = () => activeTabTitle => ({ activeTabTitle });

const enhancer = compose(
  withTranslation(['common', 'tasks', 'chat']),
  withStateHandlers(
    () => ({ activeTabTitle: '' }), {
      setActiveTabTitleStateHandler: setActiveTabTitle,
    },
  ),
);

export default enhancer(RightSideBarContainer);
