import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import './style.sass';
import classNames from 'classnames';

/**
 *
 * @param children {JSX.Element}
 * @param outsideChildren {JSX.Element}
 * @param className {string}
 * @param to {string}
 * @param asLink {boolean}
 * @param disableHighlight {boolean}
 * @param onClick {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const BrillMask = memo(({
  children = '',
  className = '',
  to = '',
  asLink,
  disableHighlight,
  outsideChildren = '',
  onClick,
}) => (
  <div
    className={classNames('brill-mask', className)}
    aria-hidden="true"
    onClick={onClick}
  >
    {asLink && (
    <div className={classNames('brill-mask__link', disableHighlight && 'brill-mask__link--highlight-disabled')}>
      <div className="brill-mask__pseudo-link">
        <Link to={to} className="brill-mask__link-item" />
      </div>
    </div>
    )}
    <div className="brill-mask__mask">
      <div className="brill-mask__pseudo-wrap">
        {children}
      </div>
    </div>
    { outsideChildren }
  </div>
));
