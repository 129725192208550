import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useWindowWidth, useOnlineStatus } from '../../utils/hooks';
import {
  Avatar, BrillMask, OnlineStatusLabel, ButtonsCollection, DropDown,
} from '..';
import { getUserData } from '../../state/user/selectors';
import { WINDOW_WIDTH, IMAGES_SIZE } from '../../constants/ui';
import { getFullName } from '../../utils/helpers/userHelpers';
import { getImageUrl } from '../../utils/helpers/requestHelpers';
import { useRelevantList } from './useRelevantList';


const PATH_TO_PROFILE = '/profile/';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const UserControls = () => {
  const history = useHistory();
  const windowWidth = useWindowWidth();
  const onlineStatus = useOnlineStatus();

  const userBoot = useSelector(getUserData);
  const avatarUserId = typeof userBoot.avatar === 'object' ? userBoot.avatar?.id : userBoot.avatar;

  const userName = useMemo(() => getFullName(userBoot), [userBoot]);
  const userAvatar = avatarUserId ? getImageUrl(avatarUserId, IMAGES_SIZE.sm) : '';

  const handleHistory = () => {
    history.push(PATH_TO_PROFILE);
  };

  const relevantList = useRelevantList();

  return (
    <div className="header__user-controls user-controls">
      {windowWidth > WINDOW_WIDTH.X_SMALL && (
        <BrillMask
          asLink
          to={PATH_TO_PROFILE}
          onClick={handleHistory}
          outsideChildren={<OnlineStatusLabel status={onlineStatus} />
          }
        >
          <Avatar src={userAvatar} alt={userName} />
        </BrillMask>
      )}
      <DropDown
        name="header-out"
        label={(
          <ButtonsCollection.ButtonIcons
            title="Toggle show profile group"
            className="user-controls__burger"
          >
            <span className="icon-settings-vertical">
              <span
                className="path1"
              />
              <span className="path2" />
              <span
                className="path3"
              />
            </span>
          </ButtonsCollection.ButtonIcons>
        )}
        list={relevantList}
      />
    </div>
  );
};
