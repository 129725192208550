import * as statisticsActions from './actions';
import * as statisticsSelectors from './selectors';
import * as statisticsOperations from './operations';
import * as statisticsSchemas from './schema';
import statisticsTypes from './types';
import reducer from './reducers';

export {
  statisticsActions,
  statisticsSelectors,
  statisticsOperations,
  statisticsSchemas,
  statisticsTypes,
};

export default reducer;
