import { compose, lifecycle, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { clientsActions, clientsSelectors } from '../../../../../../state/clients';
import ProductsList from './productsList';
import { uiActions } from '../../../../../../state/ui';

const mapStateToProps = (state, { clientId }) => ({
  productsForClient: clientsSelectors.getClientProductsSelector(state)(clientId),
});

const mapDispatchToProps = {
  getProductsRequest: clientsActions.getClientProductsRequest,
  openModal: uiActions.openModal,
};

const onActionHandler = modalName => ({
  openModal,
}) => () => {
  openModal(modalName);
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onDeleteProductModal: onActionHandler('deleteProductModal'),
  }),
  lifecycle({
    componentDidMount() {
      const { getProductsRequest, clientId } = this.props;
      getProductsRequest({ id: clientId });
    },
  }),
);

export default enhance(ProductsList);
