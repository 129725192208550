import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';

import { ConfirmModal } from '../../../../../ui-kit';
import './style.sass';

const PinMessageModal = ({
  isOpen, onClose, message, onPinMessageHandler, isPending,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Pin"
    onAction={onPinMessageHandler}
    onClose={onClose}
    title="Pin message"
  >

    Are you sure you want to pin
    <b className="message-short">{prop('content', message)}</b>

    message?
  </ConfirmModal>
);

PinMessageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPinMessageHandler: PropTypes.func.isRequired,
  message: PropTypes.instanceOf(Object).isRequired,
  isPending: PropTypes.bool,
};

PinMessageModal.defaultProps = {
  isPending: false,
};

export default PinMessageModal;
