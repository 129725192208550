import {
  lifecycle, compose, withState, withProps,
} from 'recompose';
import { mergeRight, propOr } from 'ramda';

const withFilters = ({ initial }) => compose(
  withState('filters', 'setFilters', {}),
  withProps(({ filters }) => ({
    getFilter: (or, prop) => propOr(or, prop, filters),
    mergeFilters: mergeRight(filters),
  })),
  lifecycle({
    componentDidMount() {
      const { setFilters } = this.props;
      const filtersData = initial(this.props);
      setFilters(filtersData);
    },
    componentDidUpdate(prevProps) {
      const { location, setFilters } = this.props;
      if (location.search !== prevProps.location.search) {
        const filtersData = initial(this.props);
        setFilters(filtersData);
      }
    },
  }),
);
export default withFilters;
