import React from 'react';
import { pathOr, path } from 'ramda';
import PropTypes from 'prop-types';
import { FILE_FORMATS, FILE_SIZES } from '../../../../constants/crm';
import { AsyncAutoComplete, ButtonsCollection, FormsCollection } from '../../../../ui-kit';

const ClientForm = ({
  values,
  errors,
  touched,
  isEdit,
  handleSubmit,
  handleChange,
  handleBlur,
  isPending,
  titleAction,
  client,
  getAutocompleteHandler,
  setFieldValue,
  customFieldValues,
}) => {
  const getFieldProps = name => ({
    value: pathOr('', ['contact', name], values),
    touched: isEdit ? true : pathOr(false, ['contact', name], touched),
    error: path(['contact', name, 'message'], errors),
  });

  const getAutocompleteFieldProps = name => ({
    error: path(['contact', name, 'message'], errors),
    touched: isEdit ? true : pathOr(false, ['contact', name], touched),
    defaultValue: { label: client?.contact[name] },
    closeMenuOnSelect: true,
  });

  return (
    <FormsCollection.FormWrapper
      handleSubmit={handleSubmit}
    >
      <div className="clients-modal__row">
        <div className="clients-modal__col">
          <FormsCollection.Input
            placeholderJump="First name"
            name="contact.first_name"
            onChange={handleChange}
            onBlur={handleBlur}
            className="clients-modal__first-name-input"
            id="clients-modal__first-name"
            {...getFieldProps('first_name')}
          />
        </div>
        <div className="clients-modal__col">
          <FormsCollection.Input
            placeholderJump="Last name"
            name="contact.last_name"
            onChange={handleChange}
            onBlur={handleBlur}
            className="clients-modal__last-name-input"
            id="clients-modal__last-name"
            {...getFieldProps('last_name')}
          />
        </div>
      </div>
      <div className="clients-modal__row">
        <div className="clients-modal__col">
          <FormsCollection.Input
            placeholderJump="Company"
            name="contact.company_name"
            onChange={handleChange}
            onBlur={handleBlur}
            className="clients-modal__company-input"
            id="clients-modal__company"
            {...getFieldProps('company_name')}
          />
        </div>
        <div className="clients-modal__col">
          <FormsCollection.Input
            placeholderJump="Company URL"
            name="contact.company_url"
            onChange={handleChange}
            onBlur={handleBlur}
            className="lead__company-url-input"
            id="lead__company-url"
            {...getFieldProps('company_url')}
          />
        </div>
      </div>
      <div className="clients-modal__row">
        <div className="clients-modal__col">
          <FormsCollection.Input
            placeholderJump="Position"
            name="contact.position"
            onChange={handleChange}
            onBlur={handleBlur}
            className="clients-modal__position-input"
            id="clients-modal__position"
            {...getFieldProps('position')}
          />
        </div>
        <div className="clients-modal__col">
          <FormsCollection.Input
            placeholderJump="Phone number"
            name="contact.phone"
            onChange={handleChange}
            onBlur={handleBlur}
            className="clients-modal__phone-input"
            id="clients-modal__phone"
            {...getFieldProps('phone')}
          />
        </div>
      </div>
      <div className="clients-modal__row">
        <div className="clients-modal__col">
          <FormsCollection.Input
            placeholderJump="Service"
            name="contact.service"
            onChange={handleChange}
            onBlur={handleBlur}
            className="clients-modal__service-input"
            id="clients-modal__service"
            {...getFieldProps('service')}
          />
        </div>
        <div className="clients-modal__col">
          <FormsCollection.Input
            placeholderJump="Email"
            name="contact.email"
            onChange={handleChange}
            onBlur={handleBlur}
            className="clients-modal__email-input"
            id="clients-modal__email"
            {...getFieldProps('email')}
          />
        </div>
      </div>
      <div className="clients-modal__row">
        <div className="clients-modal__col clients-modal__logo">
          <div className="clients-modal__col-field">
            <FormsCollection.AttachImage
              onChange={handleChange}
              value={values.avatar}
              name="contact.avatar"
              id="clients-modal__avatar"
              labelText="Add image"
              formats={FILE_FORMATS.CONTACT_LOGO}
              maxSize={FILE_SIZES.CONTACT_LOGO}
              {...getFieldProps('avatar')}
            />
          </div>
        </div>
        <div className="clients-modal__col">
          <span className="auto-complete-label">Timezone</span>
          <AsyncAutoComplete
            placeholderJump="Position"
            name="contact.timezone"
            placeholder="Choose timezone..."
            closeMenuOnSelect
            onChange={({ label }) => setFieldValue('contact.timezone', label)}
            loadOptions={getAutocompleteHandler}
            {...getAutocompleteFieldProps('timezone')}
          />
          {customFieldValues?.map(customField => (
            <FormsCollection.Input
              placeholderJump={customField.name}
              name={customField.name}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[customField.name]}
            />
          ))}
        </div>
      </div>
      <div className="button-group">
        <ButtonsCollection.ButtonBrill
          pending={isPending}
          className="clients-modal__button-save button-brill--fill"
          type="submit"
        >
          {titleAction}
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  );
};

ClientForm.propTypes = {
  customFieldValues: PropTypes.shape({}),
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  touched: PropTypes.shape({}).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  titleAction: PropTypes.string.isRequired,
  getAutocompleteHandler: PropTypes.func,
};

ClientForm.defaultProps = {
  customFieldValues: null,
  getAutocompleteHandler: () => {},
};

export default ClientForm;
