import api from '../services/api.service';
import { CUSTOM_API } from '../constants/customRoutes';

const extractData = (res) => {
  const { data: { result } } = res;
  return result;
};

const schema = {
  createField: async (params = {}) => {
    try {
      const response = await api.post(CUSTOM_API.CREATE, params);
      return extractData(response);
    } catch (e) {
      throw e;
    }
  },
  deleteField: async ({ id }) => {
    try {
      const response = await api.destroy(CUSTOM_API.DELETE, { id });
      return extractData(response);
    } catch (e) {
      throw e;
    }
  },
  getFields: async (props = {}) => {
    try {
      const response = await api.get(CUSTOM_API.GET, props);
      return extractData(response);
    } catch (e) {
      throw e;
    }
  },
  updatePriority: async (props = {}) => {
    try {
      const response = await api.put(CUSTOM_API.UPDATE_PRIORITY, props);
      return extractData(response);
    } catch (e) {
      throw e;
    }
  },
  updateName: async (props = {}) => {
    try {
      const response = await api.put(CUSTOM_API.UPDATE_NAME, props);
      return extractData(response);
    } catch (e) {
      throw e;
    }
  },
};

export default schema;
