import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import classNames from 'classnames';
import { Label } from '../../../../../../ui-kit';
import { messenger } from '../../../../../../constants';

const ChannelItem = ({ channelId, groupChannels }) => {
  const channel = groupChannels[channelId];
  return (
    <NavLink
      to={`/messenger/${channelId}/`}
      title={channel.name}
      activeClassName="chanel-nav-group__item--active"
      className={classNames('chanel-nav-group__item text--cut', channel.unread_count && 'channel--has-unread chanel-nav-group__item--new')}
    >
      {
      channel.type === messenger.PRIVATE_CHANNEL ? (
        <span className="chanel-nav-group__icon icon-chanel-lock-icon" />
      ) : (
        <span className="chanel-nav-group__icon icon-chanel-icon" />
      )
    }

      <span className={classNames('text--cut', channel.unread_count && 'unread')}>{channel.name}</span>
      {channel.unread_count ? (
        <Label
          className="label--xs label--fill"
        >
          {channel.unread_count}
        </Label>
      ) : null}
    </NavLink>
  );
};

ChannelItem.propTypes = {
  channelId: PropTypes.number.isRequired,
  groupChannels: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default ChannelItem;
