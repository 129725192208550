import React from 'react';

/**
 *
 * @param innerProps {object}
 * @returns {JSX.Element}
 * @constructor
 */

const MultiValueRemove = ({ innerProps }) => (
  <button className="close-button" type="button" onClick={innerProps.onClick} onTouchStart={innerProps.onClick}>
    <span className="icon-close" />
  </button>
);

export default MultiValueRemove;
