import * as yup from 'yup';
import * as translateHelpers from '../../../../utils/helpers/translateHelpers';

const rules = yup.object().shape({
  title: yup
    .string()
    .min(1, {
      message: 'Title must be at least 1 characters long',
      params: {
        key: translateHelpers.t('common', 'Title must be at least 1 characters long'),
      },
    })
    .max(254, {
      message: 'Title must be at most 254 characters',
      params: {
        key: translateHelpers.t('common', 'Title must be at most 254 characters'),
      },
    })
    .required({
      field: 'title',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Title'),
      },
    }),
  type: yup
    .number()
    .required({
      field: 'type',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Privacy'),
      },
    }),
  members: yup
    .array(),
  description: yup
    .string()
    .max(500, {
      message: 'Description must be at most 500 characters',
      params: {
        key: translateHelpers.t('common', 'Description must be at most 500 characters'),
      },
    }),
  status: yup
    .number(),
  image: yup
    .string(),
});

export default rules;
