import * as yup from 'yup';
import * as translateHelpers from '../../../../../../../utils/helpers/translateHelpers';

const MAX_CHAR_LENGTH = 120;
const MAX_ADDRESS_LENGTH = 250;
const MAX_EMAIL_SYMBOLS = 62;
const MAX_BANK_NAME_SYMBOLS = 60;
const MAX_BANK_ADDRESS_SYMBOLS = 95;
const MAX_BIC_SYMBOLS = 11;
const MAX_SWIFT_SYMBOLS = 11;
const MAX_IBAN_SYMBOLS = 34;
const MAX_BANK_COUNTRY_SYMBOLS = 30;
const MAX_PHONE_LENGTH = 17;

const symbolsValidator = value => (/^[0-9\-\\+]{0,16}$/).test(value);

const checkTrimValue = (val = '') => val && val.trim();

const rules = yup.object().shape({
  business_name: yup
    .string()
    .test({
      test: checkTrimValue,
      message: {
        field: 'business_name',
        message: 'Space in the fields is not valid',
        params: {
          key: translateHelpers.t('common', 'Business name'),
        },
      },
    })
    .max(MAX_CHAR_LENGTH, {
      field: 'business_name',
      message: 'Maximum number of characters 120',
      params: {
        key: translateHelpers.t('common', 'Maximum number of characters 120'),
      },
    })
    .required({
      field: 'business_name',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Business name'),
      },
    }),
  beneficiary_address: yup
    .string()
    .test({
      test: checkTrimValue,
      message: {
        field: 'beneficiary_address',
        message: 'Space in the fields is not valid',
        params: {
          key: translateHelpers.t('common', 'Space in the fields is not valid'),
        },
      },
    })
    .max(MAX_ADDRESS_LENGTH, {
      field: 'beneficiary_address',
      message: 'Maximum number of characters 250',
      params: {
        key: translateHelpers.t('common', 'Maximum number of characters 250'),
      },
    })
    .required({
      field: 'beneficiary_address',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Beneficiary address'),
      },
    }),
  phone: yup
    .string()
    .test({
      test: symbolsValidator,
      message: {
        field: 'phone',
        message: 'Phone number cannot contain letters',
        params: {
          key: translateHelpers.t('common', 'Phone number cannot contain letters'),
        },
      },
    })
    .max(MAX_PHONE_LENGTH, {
      field: 'phone',
      message: 'Phone number cannot be too long',
      params: {
        key: translateHelpers.t('common', 'Phone number cannot be too long'),
      },
    }),
  email: yup
    .string()
    .test({
      test: checkTrimValue,
      message: {
        field: 'email',
        message: 'Space in the fields is not valid',
        params: {
          key: translateHelpers.t('common', 'Email'),
        },
      },
    })
    .max(MAX_EMAIL_SYMBOLS, {
      field: 'email',
      message: 'Maximum number of characters 62',
      params: {
        key: translateHelpers.t('common', 'Maximum number of characters 62'),
      },
    })
    .email({
      field: 'email',
      message: 'Email address entered incorrectly. Example name@mail.com',
      params: {
        key: translateHelpers.t('common', 'Email address entered incorrectly. Example name@mail.com'),
      },
    }),
  bank_name: yup
    .string()
    .test({
      test: checkTrimValue,
      message: {
        field: 'bank_name',
        message: 'Space in the fields is not valid',
        params: {
          key: translateHelpers.t('common', 'Space in the fields is not valid'),
        },
      },
    })
    .max(MAX_BANK_NAME_SYMBOLS, {
      field: 'bank_name',
      message: 'Maximum number of characters 60',
      params: {
        key: translateHelpers.t('common', 'Maximum number of characters 60'),
      },
    }),
  bank_address: yup
    .string()
    .test({
      test: checkTrimValue,
      message: {
        field: 'bank_address',
        message: 'Space in the fields is not valid',
        params: {
          key: translateHelpers.t('common', 'Space in the fields is not valid'),
        },
      },
    })
    .max(MAX_BANK_ADDRESS_SYMBOLS, {
      field: 'bank_address',
      message: 'Maximum number of characters 95',
      params: {
        key: translateHelpers.t('common', 'Maximum number of characters 95'),
      },
    }),
  bic: yup
    .string()
    .test({
      test: checkTrimValue,
      message: {
        field: 'bic',
        message: 'Space in the fields is not valid',
        params: {
          key: translateHelpers.t('common', 'Space in the fields is not valid'),
        },
      },
    })
    .max(MAX_BIC_SYMBOLS, {
      field: 'bic',
      message: 'Maximum number of characters 11',
      params: {
        key: translateHelpers.t('common', 'Maximum number of characters 11'),
      },
    }),
  iban: yup
    .string()
    .test({
      test: checkTrimValue,
      message: {
        field: 'iban',
        message: 'Space in the fields is not valid',
        params: {
          key: translateHelpers.t('common', 'Space in the fields is not valid'),
        },
      },
    })
    .max(MAX_IBAN_SYMBOLS, {
      field: 'iban',
      message: 'Maximum number of characters 34',
      params: {
        key: translateHelpers.t('common', 'Maximum number of characters 34'),
      },
    }),
  swift_code: yup
    .string()
    .test({
      test: checkTrimValue,
      message: {
        field: 'swift_code',
        message: 'Space in the fields is not valid',
        params: {
          key: translateHelpers.t('common', 'Space in the fields is not valid'),
        },
      },
    })
    .max(MAX_SWIFT_SYMBOLS, {
      field: 'swift_code',
      message: 'Maximum number of characters 11',
      params: {
        key: translateHelpers.t('common', 'Maximum number of characters 11'),
      },
    }),
  bank_country: yup
    .string()
    .test({
      test: checkTrimValue,
      message: {
        field: 'bank_country',
        message: 'Space in the fields is not valid',
        params: {
          key: translateHelpers.t('common', 'Space in the fields is not valid'),
        },
      },
    })
    .max(MAX_BANK_COUNTRY_SYMBOLS, {
      field: 'bank_country',
      message: 'Maximum number of characters 30',
      params: {
        key: translateHelpers.t('common', 'Maximum number of characters 30'),
      },
    }),
});

export default rules;
