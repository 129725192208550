import * as yup from 'yup';
import { helpers } from '../../../../utils';

const { translateHelpers } = helpers;

const rules = yup.object().shape({
  title: yup
    .string()
    .max(254, {
      message: 'Title must be at most 254 characters',
    })
    .required({
      field: 'title',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Title1'),
      },
    }),
});

export default rules;
