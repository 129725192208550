import React from 'react';
import PropTypes from 'prop-types';

import TaskItem from '../taskItem';
import './style.sass';
import { Grid } from '../../../../ui-kit';
import { MY_TASKS } from '../../../../constants/tasks';

const MyTasksList = ({
  tasks, t, projectView, activeFilter,
}) => (
  <div className={`my-tasks__table ${projectView ? 'my-tasks__table--project-view' : ''}`}>
    <Grid.Row className="my-tasks__row-controls">
      {
        !projectView && (
          <Grid.Col
            name={t('Project')}
            className="my-tasks__col-project my-tasks__col-controls"
          />
        )
      }
      <Grid.Col
        name="#"
        className="my-tasks__col-id my-tasks__col-controls"
      />
      <Grid.Col
        name={t('Subject')}
        className="my-tasks__col-subject my-tasks__col-controls"
      />
      <Grid.Col
        name={t('Priority')}
        className="my-tasks__col-priority my-tasks__col-controls"
      />
      <Grid.Col
        name={t('Category')}
        className="my-tasks__col-category my-tasks__col-controls"
      />
      {
        activeFilter === MY_TASKS.FILTER.ASSIGNED_TO_ME && !projectView && (
          <Grid.Col
            name={t('Author')}
            className="my-tasks__col-author my-tasks__col-controls"
          />
        )
      }
      {
        activeFilter === MY_TASKS.FILTER.CREATED_BY_ME && !projectView && (
          <Grid.Col
            name={t('Assignee')}
            className="my-tasks__col-author my-tasks__col-controls"
          />
        )
      }
      {
        projectView && (
          <>
            <Grid.Col
              name={t('Author')}
              className="my-tasks__col-author my-tasks__col-controls"
            />
            <Grid.Col
              name={t('Assignee')}
              className="my-tasks__col-assignee my-tasks__col-controls"
            />
          </>
        )
      }
      <Grid.Col
        name={t('Estimated')}
        className="my-tasks__col-estimated my-tasks__col-controls"
      />
      <Grid.Col
        name={t('Spent')}
        className="my-tasks__col-spent my-tasks__col-controls"
      />
      <Grid.Col
        name={t('Deadline')}
        className="my-tasks__col-deadline my-tasks__col-controls"
      />
      <Grid.Col
        name={t('Done %')}
        className="my-tasks__col-progress my-tasks__col-controls"
      />
      <Grid.Col className="my-tasks__col-estimated my-tasks__col-controls" />
    </Grid.Row>
    {
      tasks.map(taskId => (
        <TaskItem
          projectView={projectView}
          key={taskId}
          taskId={taskId}
          activeFilter={activeFilter}
        />
      ))
    }
  </div>
);

MyTasksList.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.number).isRequired,
  t: PropTypes.func.isRequired,
  projectView: PropTypes.bool,
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
  }).isRequired,
  activeFilter: PropTypes.string.isRequired,
};

MyTasksList.defaultProps = {
  projectView: false,
};

export default MyTasksList;
