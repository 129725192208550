import {
  compose, withHandlers, getContext, renderNothing, branch,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import PinnedLeadItem from './pinnedLeadItem';
import { leadsSelectors } from '../../../../../../state/leads';
import { uiActions } from '../../../../../../state/ui';

const mapStateToProps = (state, { leadId }) => ({
  pinnedLead: leadsSelectors.getPinnedLeadById(state)(leadId),
});

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
};
const onUnpinHandler = ({
  setSelectedLeadId, setOpenModal, leadId,
}) => () => {
  setSelectedLeadId(leadId);
  setOpenModal('unpinLeadModal');
};
const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ pinnedLead }) => !pinnedLead || isEmpty(pinnedLead), renderNothing),
  getContext({
    setSelectedLeadId: PropTypes.func,
    setSelectedPinnedLeadId: PropTypes.func,
  }),
  withHandlers({
    onUnpin: onUnpinHandler,
  }),
);
export default enhance(PinnedLeadItem);
