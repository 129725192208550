import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { changeRightSidebarStatus } from '@/state/ui/actions';
import { RIGHT_SIDEBAR_VIEWS, CRUD_NODE_CONFIG_ACTIONS } from '@bp/constants';
import { bpActions, bpSelectors } from '@bp/store/bpModule';

const { getProcessNodeResult, getBusinessProcessRequestPending } = bpSelectors;

export const useProcessContainer = () => {
  const dispatch = useDispatch();
  const {
    params: { processId },
  } = useRouteMatch();
  const nodes = useSelector(getProcessNodeResult);
  const crudNodesConfig = useSelector(bpSelectors.getCrudNodesConfig);
  const isPending = useSelector(getBusinessProcessRequestPending);

  const createTrigger = () => {
    dispatch(bpActions.setCrudNodesConfig(CRUD_NODE_CONFIG_ACTIONS.createTrigger));
  };

  useEffect(() => {
    dispatch(bpActions.getBusinessProcessRequest({ processId }));
    dispatch(bpActions.getBusinessProcessEventsRequest());
  }, [dispatch, processId]);

  useEffect(() => {
    const isOpen = crudNodesConfig.mode !== RIGHT_SIDEBAR_VIEWS.NOTHING;
    dispatch(changeRightSidebarStatus(isOpen));
  }, [crudNodesConfig, dispatch]);

  return { nodes, isPending, createTrigger };
};
