import React from 'react';
import PropTypes from 'prop-types';
import { isNotNil } from 'ramda-extension';

import { ButtonsCollection } from '../../../../ui-kit';
import CategoriesItem from './categoriesItem';
import './style.sass';

const Categories = ({
  categoriesList, openModal, onSelectCategory,
  selectedCategoryId, onResetCategory, setSelectedCategoryId,
}) => (
  <div className="category-nav">
    <div className="category-nav__header">
      <span className="category-nav__title">Categories</span>
      <ButtonsCollection.ButtonPlus
        className="category-nav__plus-button"
        title="Create category"
        onClick={() => openModal('createCategory')}
      />
    </div>

    <nav className="category-nav__nav">
      {categoriesList.length ? categoriesList.map(category => (
        <CategoriesItem
          selectedCategoryId={selectedCategoryId}
          setSelectedCategoryId={setSelectedCategoryId}
          onSelectCategory={onSelectCategory}
          key={category}
          categoryId={category}
        />
      )) : (
        <span className="category-nav__empty">

          There are no categories yet
        </span>
      )}
      {
        selectedCategoryId && isNotNil(categoriesList) ? (
          <button
            type="button"
            onClick={onResetCategory}
            className="category-nav__item"
          >
            <span className="category-nav__item-title">All categories</span>
          </button>
        ) : ''
      }
    </nav>
  </div>
);

Categories.propTypes = {
  selectedCategoryId: PropTypes.oneOfType([PropTypes.number.isRequired,
    PropTypes.oneOf([null])]).isRequired,
  onSelectCategory: PropTypes.func.isRequired,
  onResetCategory: PropTypes.func.isRequired,
  setSelectedCategoryId: PropTypes.func.isRequired,
  categoriesList: PropTypes.arrayOf(PropTypes.number).isRequired,
  openModal: PropTypes.func.isRequired,
};

export default Categories;
