import {
  take,
  fork, put,
} from 'redux-saga/effects';

import { taskTypes } from '.';
import { sagasManager } from '../../utils';
import { uiActions } from '../ui';

function* redirectTaskNotFoundFlow() {
  while (true) {
    yield take(taskTypes.REDIRECT_TASK_NOT_FOUND);
    yield put(uiActions.setPageNotFound(true));
  }
}


sagasManager.addSagaToRoot(function* watcher() {
  yield fork(redirectTaskNotFoundFlow);
});
