/* eslint-disable import/prefer-default-export */
const AVAILABLE_FILE_FORMATS = {
  MESSENGER: [
    // audio
    'audio/mp3',
    'audio/aiff',
    // image
    'image/jpg',
    'image/png',
    'image/jpeg',
    'image/svg+xml',
    'image/gif',
    // document
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    '.doc',
    '.docx',
    'application/pdf',
    'text/plain',
    // presentation
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    '.ppt',
    '.pptx',
    // archive
    'application/zip',
    'application/x-zip-compressed',
  ],
  TASKS: [
    // video
    'video/mp4',
    'video/avchd',
    'video/avi',
    'video/flv',
    'video/mkv',
    'video/mov',
    'video/webm',
    'video/wmv',
    // audio
    'audio/mp3',
    'audio/aiff',
    // image
    'image/jpg',
    'image/png',
    'image/jpeg',
    'image/svg+xml',
    'image/gif',
    // document
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    '.doc',
    '.docx',
    'application/pdf',
    'text/plain',
    // presentation
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    '.ppt',
    '.pptx',
    // archive
    'application/zip',
    'application/x-zip-compressed',
  ],
  LOGO_PROFILE: [
    'image/jpg',
    'image/png',
    'image/jpeg',
  ],
  LOGO_PROJECT: [
    'image/jpg',
    'image/png',
    'image/jpeg',
  ],
  LOGO_COMPANY: [
    'image/jpg',
    'image/png',
    'image/jpeg',
  ],
  DEFAULT: [
    // video
    'video/mp4',
    'video/avchd',
    'video/avi',
    'video/flv',
    'video/mkv',
    'video/mov',
    'video/webm',
    'video/wmv',
    // audio
    'audio/mp3',
    'audio/aiff',
    // image
    'image/jpg',
    'image/png',
    'image/jpeg',
    'image/svg+xml',
    'image/gif',
    // document
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    '.doc',
    '.docx',
    'application/pdf',
    'text/plain',
    // presentation
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    '.ppt',
    '.pptx',
    // archive
    'application/zip',
    'application/x-zip-compressed',
  ],
};

const AVAILABLE_FILE_SIZES = {
  MESSENGER: 20,
  LOGO_PROFILE: 6,
  LOGO_COMPANY: 20,
  TASKS: 16,
  LOGO_PROJECT: 6,
  DEFAULT: 20,
};


export {
  AVAILABLE_FILE_FORMATS,
  AVAILABLE_FILE_SIZES,
};
