import {
  compose,
  lifecycle,
  withContext,
  withHandlers,
  withProps,
  withStateHandlers,
  withState,
} from 'recompose';
import {
  curry, equals, find, isNil, path, pathOr, prop, propEq, filter, dec,
  // always, ifElse, has,
} from 'ramda';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { propNotEq } from 'ramda-extension';
import { projectActions, projectSelectors } from '../../state/project';

import TasksWindow from './tasks';
import { preloaderWhileLoading, withFilters, withUrlParams } from '../../utils/enchancers';
import { PRELOADER_DIMENSION } from '../../constants/ui';
import { uiActions } from '../../state/ui';
import {
  filterAndTransformCondition,
  filterParamKey,
  setFilterForSingleParam,
} from '../../utils/helpers/uiComponentHelpers/filters';
import { withPermissionHandlers } from '../../utils/helpers/permissionHelpers';

const mapDispatchToProps = ({
  getCategoriesTask: projectActions.getCategoriesTaskRequest,
  sortProjectTasks: projectActions.sortProjectTasksRequest,
  getTasks: projectActions.getProjectTasksRequest,
  onDragEnd: uiActions.onDragEnd,
  getProjectStatuses: projectActions.getStatusesOfTasksRequest,
});

const mapStateToProps = state => ({
  projectTasksPendingRequest: projectSelectors.getProjectTasksPendingRequest(state),
});

const setSelectedTaskIdStateHandler = () => value => ({
  selectedTaskId: value,
});

const setSelectedSortStateHandler = () => value => ({ selectedSort: value });

const onDragEndHandler = ({
  onDragEnd, projectId, getProjectStatuses,
}) => (data) => {
  const { destination, source } = data;
  getProjectStatuses({ projectId });
  if (!destination) return;
  if (destination.droppableId === source.droppableId && destination.index === source.index) return;
  onDragEnd(data, { section: 'project' });
};

const changeParamKey = (e) => {
  const [key, value] = e;
  return ({ field: key, value });
};

const onSelectUserHandler = curry((type, {
  setFilters, onSetUrlParam, onResetUrlParam, mergeFilters,
}) => (item) => {
  const id = pathOr(null, ['val', 'id'], item);
  const action = isNil(id) ? onResetUrlParam : onSetUrlParam;
  return setFilterForSingleParam(mergeFilters, setFilters, action, type, id);
});

const onSelectSprintsStatusHandler = curry((type, {
  setFilters, onSetUrlParam, onResetUrlParam, mergeFilters, getProjectStatuses, projectId,
}) => (item) => {
  const id = pathOr(null, ['val', 'id'], item);
  getProjectStatuses({ projectId });
  const action = isNil(id) ? onResetUrlParam : onSetUrlParam;
  return setFilterForSingleParam(mergeFilters, setFilters, action, type, id);
});


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ match }) => ({ projectId: path(['params', 'id'], match) })),
  withState('isFirstFetching', 'setIsFirstFetching', true),
  withStateHandlers(
    () => ({ selectedTaskId: 0, selectedSort: {} }),
    {
      setSelectedTaskId: setSelectedTaskIdStateHandler,
      setSelectedSort: setSelectedSortStateHandler,
    },
  ),
  withUrlParams({
    params: { concat: ['grid'] },
    saveFilterByName: ({ projectId }) => `tasks-of-project${projectId}`,
  }),
  withFilters({
    initial: ({ getUrlParam }) => ({
      sprintsStatus: getUrlParam(['sprintsStatus']),
      assignee: getUrlParam(['assignee']),
      created_by: getUrlParam(['assigner']),
      category_id: getUrlParam(['category']),
    }),
  }),
  withContext({
    filters: PropTypes.instanceOf(Object),
  }, props => ({
    filters: props.filters,
  })),
  withProps(({
    match, getTasks,
  }) => ({
    getTasksWithFilters: ({ filters }) => {
      const projectId = path(['params', 'id'], match);
      const where = filterAndTransformCondition(filterParamKey, changeParamKey, filters);
      const sprintStatusId = compose(prop('value'), find(propEq('field', 'sprintsStatus')))(where);
      const whereForTasks = compose(filter(propNotEq('field', 'sprintsStatus')))(where);
      const status = sprintStatusId ? dec(sprintStatusId) : null;
      getTasks({
        projectId,
        where: whereForTasks,
        whereSprint: { status },
        withRelated: 'tasks',
        limit: 100,
        offset: 0,
      });
    },
  })),
  withHandlers({
    onDragEnd: onDragEndHandler,
    onSelectAssignee: onSelectUserHandler('assignee'),
    onSelectAssigner: onSelectUserHandler('assigner'),
    onSelectSprintsStatus: onSelectSprintsStatusHandler('sprintsStatus'),
  }),
  withContext({
    selectedTaskId: PropTypes.number,
    selectedSort: PropTypes.instanceOf(Object),
    onSelectAssignee: PropTypes.func,
    onSelectAssigner: PropTypes.func,
    onSetUrlParam: PropTypes.func,
    getUrlParam: PropTypes.func,
    onSelectSprintsStatus: PropTypes.func,
  }, props => ({
    selectedTaskId: props.selectedTaskId,
    selectedSort: props.selectedSort,
    onSelectAssignee: props.onSelectAssignee,
    onSelectAssigner: props.onSelectAssigner,
    onSelectSprintsStatus: props.onSelectSprintsStatus,
    onSetUrlParam: props.onSetUrlParam,
    getUrlParam: props.getUrlParam,
  })),
  memo,
  lifecycle({
    componentDidMount() {
      const {
        onSetUrlParam,
        defaultSprintStatus,
      } = this.props;
      onSetUrlParam({ sprintsStatus: defaultSprintStatus });
    },
    componentDidUpdate(prevProps) {
      const { getTasksWithFilters, filters } = this.props;
      if (!equals(filters, prevProps.filters)) getTasksWithFilters({ filters });
    },
  }),
  memo,
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.MIDDLE,
    alignContainerCenter: true,
    isLoading: props => !props.projectTasksPendingRequest,
  }),
  withPermissionHandlers,
);

export default enhance(TasksWindow);
