import { createActions } from 'redux-actions';
import {
  identity, path, pathOr, compose, mergeLeft,
} from 'ramda';
import { callNotification } from '../../utils/helpers/notifies';
import { setErrorPage } from '../ui/actions';

import types from './types';
import { leadsListSchema, pinnedLeadsListSchema } from './schema';
import { getNormalizeErrorObject } from '../../utils/helpers/requestHelpers';

const errorPageDataWhenGetLeads = compose(mergeLeft({ href: '/' }),
  getNormalizeErrorObject);

const getTasksStatuses = status => (status ? `status=${status}` : 'status=1&status=2&status=3');

export const {
  getLeadsRequest,
  getLeadsAutocompleteRequest,
  getLeadTasksRequest,
  getLeadLogsRequest,
  getLeadContactsRequest,
  getLeadNotesRequest,
  deleteLeadNoteSuccess,
  editLeadNoteSuccess,
  deleteLeadTaskSuccess,
  editLeadTaskSuccess,
  deleteLeadContactSuccess,
  editLeadContactSuccess,
  fullEditLeadTaskSuccess,
  changeTaskStatusSuccess,
  convertLeadToClientRequest,
  addLeadNoteRequest,
  addLeadRequest,
  deleteLeadRequest,
  deleteLeadNoteRequest,
  editLeadRequest,
  editLeadNoteRequest,
  setLeads,
  setLeadsAutocomplete,
  setLead,
  setLeadTasks,
  setLeadLogs,
  setLeadNotes,
  setLeadContacts,
  deleteLead,
  getPinnedLeadsRequest,
  pinLeadRequest,
  unpinLeadRequest,
  setPinnedLeads,
  setPinnedLead,
  setActiveRelatedLead,
  onDragLeadEnd,
  reorderPipeViewLead,
  updateLeadSummary,
  setCheckedPipeline,
  setLeadsView,
  setAddedLeadContact,
} = createActions(
  {
    [types.GET_LEADS_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/leads',
        selectorName: 'getLeadsRequest',
        method: 'GET',
        params,
        schema: {
          rules: leadsListSchema,
          pathData: ['data'],
        },
        actions: {
          success: setLeads,
          error: compose(setErrorPage, errorPageDataWhenGetLeads),
        },
        ...meta,
      }),
    ],
    [types.GET_LEADS_AUTOCOMPLETE_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/leads/autocomplete',
        selectorName: 'getLeadsAutocomplete',
        method: 'GET',
        params,
        actions: {
          success: action => setLeadsAutocomplete({ ...action, meta }),
        },
        ...meta,
      }),
    ],
    [types.GET_LEAD_LOGS_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/leads/${id}/logs`,
        selectorName: 'getLeadLogsRequest',
        method: 'GET',
        params,
        actions: {
          success: setLeadLogs,
          meta: {
            leadId: id,
          },
        },
        ...meta,
      }),
    ],
    [types.GET_LEAD_TASKS_REQUEST]: [
      identity,
      ({ id, status, ...params }, meta) => ({
        async: true,
        route: `/tasks/?lead_id=${id}&${getTasksStatuses(status)}`,
        selectorName: 'getLeadTasksRequest',
        method: 'GET',
        params,
        actions: {
          success: setLeadTasks,
          meta: {
            leadId: id,
          },
        },
        ...meta,
      }),
    ],
    [types.GET_LEAD_CONTACTS_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/contacts-for-lead?client_id=${id}`,
        selectorName: 'getLeadContactsRequest',
        method: 'GET',
        params,
        actions: {
          success: setLeadContacts,
          meta: {
            leadId: id,
          },
        },
        ...meta,
      }),
    ],
    [types.GET_LEAD_NOTES_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/leads/${id}/documents`,
        selectorName: 'getLeadNotesRequest',
        method: 'GET',
        params,
        actions: {
          success: setLeadNotes,
          meta: {
            leadId: id,
          },
        },
        ...meta,
      }),
    ],
    [types.ADD_LEAD_NOTE_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/leads/${id}/documents`,
        selectorName: 'addLeadNoteRequest',
        method: 'POST',
        params,
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.EDIT_LEAD_NOTE_REQUEST]: [
      identity,
      ({ id, noteId, ...params }, meta) => ({
        async: true,
        route: `/leads/${id}/documents/${noteId}`,
        selectorName: 'editLeadNotesRequest',
        method: 'PUT',
        params,
        actions: {
          success: () => editLeadNoteSuccess({ id, noteId, params }),
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.DELETE_LEAD_NOTE_REQUEST]: [
      identity,
      ({ id, noteId, ...params }, meta) => ({
        async: true,
        route: `/leads/${id}/documents/${noteId}`,
        selectorName: 'deleteLeadNoteRequest',
        method: 'DELETE',
        params,
        actions: {
          success: () => deleteLeadNoteSuccess({ id, noteId }, meta),
        },
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
        },
        ...meta,
      }),
    ],
    [types.ADD_LEAD_REQUEST]: [
      identity,
      ({ ...params }, meta) => ({
        async: true,
        route: '/leads',
        selectorName: 'addLeadRequest',
        method: 'POST',
        params,
        callbacks: {
          success: path(['callbacks', 'success'], meta),
          error: () => callNotification.error('Lead cannot be added'),
        },
        ...meta,
      }),
    ],
    [types.EDIT_LEAD_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/leads/${id}`,
        selectorName: 'editLeadRequest',
        method: 'PUT',
        params,
        callbacks: {
          success: pathOr(null, ['callbacks', 'success'], meta),
          error: () => callNotification.error('Lead cannot be edited'),
        },
        actions: {
          success: getPinnedLeadsRequest,
        },
        ...meta,
      }),
    ],
    [types.DELETE_LEAD_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/leads/${id}`,
        selectorName: 'deleteLeadRequest',
        method: 'DELETE',
        params,
        callbacks: {
          success: path(['callbacks', 'success'], meta),
          error: () => callNotification.error('Lead cannot be deleted'),
        },
        actions: {
          success: () => getLeadsRequest(meta),
        },
        ...meta,
      }),
    ],
    [types.CONVERT_LEAD_TO_CLIENT_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/leads/${id}/convert-to-client`,
        selectorName: 'convertLeadToClientRequest',
        method: 'POST',
        params,
        ...meta,
      }),
    ],
    [types.GET_PINNED_LEADS_REQUEST]: [
      identity,
      ({ ...params }, meta) => ({
        async: true,
        route: '/pinned-leads',
        selectorName: 'getPinnedLeadsRequest',
        method: 'GET',
        schema: {
          rules: pinnedLeadsListSchema,
          pathData: [],
        },
        actions: {
          success: setPinnedLeads,
        },
        params,
        ...meta,
      }),
    ],
    [types.PIN_LEAD_REQUEST]: [
      identity,
      ({ leadId, ...params }, meta) => ({
        async: true,
        route: `/pinned-leads/${leadId}`,
        selectorName: 'pinLeadRequest',
        method: 'POST',
        callbacks: path(['callbacks', 'success'], meta),
        params,
        ...meta,
      }),
    ],
    [types.UNPIN_LEAD_REQUEST]: [
      identity,
      ({ leadId, ...params }, meta) => ({
        async: true,
        route: `/pinned-leads/${leadId}`,
        selectorName: 'unpinLeadRequest',
        method: 'DELETE',
        callbacks: path(['callbacks', 'success'], meta),
        params,
        ...meta,
      }),
    ],
  },
  types.SET_LEADS,
  types.SET_LEADS_AUTOCOMPLETE,
  types.SET_LEAD,
  types.SET_LEAD_CONTACTS,
  types.SET_LEAD_TASKS,
  types.SET_LEAD_LOGS,
  types.SET_LEAD_NOTES,
  types.DELETE_LEAD,
  types.SET_PINNED_LEADS,
  types.SET_PINNED_LEAD,
  types.SET_ACTIVE_RELATED_LEAD,
  types.ON_DRAG_LEAD_END,
  types.REORDER_PIPE_VIEW_LEAD,
  types.UPDATE_LEAD_SUMMARY,
  types.SET_CHECKED_PIPELINE,
  types.SET_LEADS_VIEW,
  types.DELETE_LEAD_NOTE_SUCCESS,
  types.EDIT_LEAD_NOTE_SUCCESS,
  types.DELETE_LEAD_TASK_SUCCESS,
  types.EDIT_LEAD_TASK_SUCCESS,
  types.DELETE_LEAD_CONTACT_SUCCESS,
  types.EDIT_LEAD_CONTACT_SUCCESS,
  types.FULL_EDIT_LEAD_TASK_SUCCESS,
  types.CHANGE_TASK_STATUS_SUCCESS,
  types.SET_ADDED_LEAD_CONTACT,
);
