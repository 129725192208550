import React from 'react';
import PropTypes from 'prop-types';
import { equals } from 'ramda';
import { ButtonsCollection, DropDown } from '../../../../ui-kit';
import { MY_TASKS } from '../../../../constants/tasks';
import { LABELS } from '../../../../constants/ui';
import './style.sass';

const FiltersRow = ({
  groupBy,
  setAssignedToMeFilter,
  setCreatedByMeFilter,
  activeFilter,
  onChangeSorting,
  onSetGroupBy,
  sortingByDate,
}) => (
  <div className="my-tasks__tasks-filter__row">
    <div className="my-tasks__tasks-filter__sort-group">
      <ButtonsCollection.ButtonBrill
        className={`my-tasks__tasks-filter-button ${equals(activeFilter, MY_TASKS.FILTER.ASSIGNED_TO_ME) ? 'my-tasks__tasks-filter-button--active' : ''}`}
        onClick={setAssignedToMeFilter}
      >

        Assigned to me
      </ButtonsCollection.ButtonBrill>
      <ButtonsCollection.ButtonBrill
        className={`my-tasks__tasks-filter-button ${equals(activeFilter, MY_TASKS.FILTER.CREATED_BY_ME) ? 'my-tasks__tasks-filter-button--active' : ''}`}
        onClick={setCreatedByMeFilter}
      >

        Created by me
      </ButtonsCollection.ButtonBrill>
    </div>
    <div className="my-tasks__tasks-filter__sort-group">
      <DropDown
        label="Sort by : "
        onChange={onChangeSorting}
        currentItem={sortingByDate}
        list={
          [
            {
              id: 0,
              order: 'desc',
              sortBy: 'created_at',
              label: LABELS.RECENT,
            },
            {
              id: 1,
              order: 'asc',
              sortBy: 'created_at',
              label: LABELS.OLD,
            },
          ]
        }
      />
      <DropDown
        label="Group by : "
        onChange={onSetGroupBy}
        currentItem={groupBy}
        list={
          [
            {
              id: MY_TASKS.GROUP_BY.TASKS,
              label: LABELS.TASKS,
            },
            {
              id: MY_TASKS.GROUP_BY.PROJECTS,
              label: LABELS.PROJECT,
            },
          ]
        }
      />
    </div>
  </div>
);

FiltersRow.propTypes = {
  sortingByDate: PropTypes.number.isRequired,
  groupBy: PropTypes.string.isRequired,
  activeFilter: PropTypes.string.isRequired,
  onChangeSorting: PropTypes.func.isRequired,
  onSetGroupBy: PropTypes.func.isRequired,
  setAssignedToMeFilter: PropTypes.func.isRequired,
  setCreatedByMeFilter: PropTypes.func.isRequired,
};
export default FiltersRow;
