import {
  branch,
  compose, getContext, lifecycle, renderNothing, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import {
  isEmpty,
  pathOr,
} from 'ramda';
import PropTypes from 'prop-types';
import ClientInfo from './clientInfo';
import rules from '../../modals/rules';
import { clientsActions, clientsSelectors } from '../../../../state/clients';
import { billingInfoActions } from '../../../../state/billingInfo';
import { preloaderWhileLoading } from '../../../../utils/enchancers';
import { callNotification } from '../../../../utils/helpers/notifies';
import { getTimezoneAutocompleteHelper } from '../../../../utils/helpers/dateHelpers';
import { customFieldsActions, customFieldsSelectors } from '../../../../state/customFields';
import { customFields } from '../../../../constants';
import {
  getCustomFieldValues,
  getCustomValuesList,
} from '../../../../utils/helpers/crmHelpers';

const mapStateToProps = (state, { selectedClientId }) => ({
  client: clientsSelectors.getClientById(state)(selectedClientId),
  isPending: clientsSelectors.getClientActionPending(state)('editClientRequest'),
  customFieldEntities: customFieldsSelectors.getCustomFieldsEntities(state),
  // eslint-disable-next-line max-len
  customFieldsList: customFieldsSelectors.getCustomFieldsByModel(state)(customFieldsSelectors.getCustomFieldsList(state), customFields.CUSTOM_FIELDS_MODELS.LEAD_CLIENT),
  editCustomFieldValue: customFieldsActions.editCustomFieldValueRequest,
});

const mapDispatchToProps = {
  editClient: clientsActions.editClientRequest,
  getBillingInfo: billingInfoActions.getClientBillingInfoRequest,
  editCustomFieldValue: customFieldsActions.editCustomFieldValueRequest,
  getCustomFields: customFieldsActions.getCustomFieldsRequest,
};

const enhance = compose(
  getContext({
    setSelectedClientId: PropTypes.func.isRequired,
    setIsNeedRefresh: PropTypes.func.isRequired,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ client }) => !client, renderNothing),
  withProps(({ client, customFieldEntities }) => {
    const getPropFromClientContact = field => pathOr('', ['contact', field], client);
    const [customFieldValues, initialCustomValues] = getCustomFieldValues(
      client, customFieldEntities,
    );
    return {
      initialValues: ({
        contact: {
          avatar: getPropFromClientContact('avatar'),
          first_name: getPropFromClientContact('first_name'),
          last_name: getPropFromClientContact('last_name'),
          email: getPropFromClientContact('email'),
          phone: getPropFromClientContact('phone'),
          company_name: getPropFromClientContact('company_name'),
          company_url: getPropFromClientContact('company_url'),
          position: getPropFromClientContact('position'),
          service: getPropFromClientContact('service'),
          timezone: getPropFromClientContact('timezone'),
        },
        ...initialCustomValues,
      }),
      customFieldValues,
    };
  }),
  withFormik({
    mapPropsToValues: ({ initialValues }) => initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: rules,
    handleSubmit: (formData, {
      props: {
        editClient,
        onSuccess,
        selectedClientId,
        setIsNeedRefresh,
        customFieldsList,
        customFieldEntities,
        editCustomFieldValue,
      },
    }) => {
      const customValuesList = getCustomValuesList(
        {
          customFieldsList,
          selectedId: selectedClientId,
          customFieldEntities,
          formData,
        },
      );
      if (isEmpty(formData.contact.avatar)) {
        // eslint-disable-next-line no-param-reassign
        formData.contact.avatar = null;
      }
      if (isEmpty(formData.contact.email)) {
        // eslint-disable-next-line no-param-reassign
        formData.contact.email = null;
      }
      editCustomFieldValue({ customFieldsData: customValuesList });
      editClient({
        id: selectedClientId,
        ...formData,
      }, {
        callbacks: {
          success: () => {
            if (setIsNeedRefresh) {
              setIsNeedRefresh(true);
            }
            if (onSuccess) {
              onSuccess();
            }
          },
          error: () => callNotification.error('Email should be unique'),
        },
      });
      preloaderWhileLoading({
        dimension: 75,
        isLoading: props => props.isPending,
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { getBillingInfo, selectedClientId, getCustomFields } = this.props;
      getBillingInfo({ clientId: selectedClientId });
      getCustomFields();
    },
  }),
  withHandlers({
    getAutocompleteHandler: getTimezoneAutocompleteHelper,
  }),
);

export default enhance(ClientInfo);
