import { useContext } from 'react';
import { ActivityContext } from './context';

export function useActivityContext() {
  const context = useContext(ActivityContext);
  if (!context) {
    throw new Error('useActivityContext must be used within a ActivityProvider.');
  }
  return context;
}
