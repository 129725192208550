import { compose } from 'recompose';
import { withFormik } from 'formik';

import AddItemForm from './addItemForm';

const enhance = compose(
  withFormik({
    validateOnBlur: true,
    validateOnChange: true,
    handleSubmit: () => {},
  }),
);

export default enhance(AddItemForm);
