import React from 'react';
import PropTypes from 'prop-types';
import { path, pathOr } from 'ramda';
import { ButtonsCollection, FormsCollection } from '../../../../ui-kit';
import './style.sass';

const BillingInfo = ({
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  touched,
  isEdit,
  errors,
  isPending,
}) => {
  const getFieldProps = name => ({
    value: pathOr('', [name], values),
    touched: isEdit ? true : pathOr(false, [name], touched),
    error: path([name, 'message'], errors),
  });
  return (
    <div className="billing-info">
      <FormsCollection.FormWrapper
        handleSubmit={handleSubmit}
      >
        <div className="billing-info__row">
          <div className="billing-info__col">
            <FormsCollection.Input
              placeholderJump="Bank name"
              name="bank_name"
              onChange={handleChange}
              onBlur={handleBlur}
              className="billing-info__bank-name-input"
              id="billing-info__bank-name"
              {...getFieldProps('bank_name')}
            />
          </div>
          <div className="billing-info__col">
            <FormsCollection.Input
              placeholderJump="Bank address"
              name="bank_address"
              onChange={handleChange}
              onBlur={handleBlur}
              className="billing-info__bank-address-input"
              id="billing-info__bank-address"
              {...getFieldProps('bank_address')}
            />
          </div>
        </div>
        <div className="billing-info__row">
          <div className="billing-info__col">
            <FormsCollection.Input
              placeholderJump="BIC"
              name="bic"
              onChange={handleChange}
              onBlur={handleBlur}
              className="billing-info__bic-input"
              id="billing-info__bic"
              {...getFieldProps('bic')}
            />
          </div>
          <div className="billing-info__col">
            <FormsCollection.Input
              placeholderJump="IBAN"
              name="iban"
              onChange={handleChange}
              onBlur={handleBlur}
              className="billing-info__iban-input"
              id="billing-info__iban"
              {...getFieldProps('iban')}
            />
          </div>
        </div>
        <div className="billing-info__row">
          <div className="billing-info__col">
            <FormsCollection.Input
              placeholderJump="SWIFT code"
              name="swift_code"
              onChange={handleChange}
              onBlur={handleBlur}
              className="billing-info__swift-code-input"
              id="billing-info__swift-code"
              {...getFieldProps('swift_code')}
            />
          </div>
          <div className="billing-info__col">
            <FormsCollection.Input
              placeholderJump="Bank country"
              name="bank_country"
              onChange={handleChange}
              onBlur={handleBlur}
              className="billing-info__Bank country-input"
              id="billing-info__Bank country"
              {...getFieldProps('bank_country')}
            />
          </div>
        </div>
        <div className="button-group">
          <ButtonsCollection.ButtonBrill
            className="billing-info__button-save button-brill--fill"
            type="submit"
            pending={isPending}
          >
            Save
          </ButtonsCollection.ButtonBrill>
        </div>
      </FormsCollection.FormWrapper>
    </div>
  );
};

BillingInfo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  touched: PropTypes.instanceOf(Object).isRequired,
  isEdit: PropTypes.bool.isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
};

export default BillingInfo;
