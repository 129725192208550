import { dec, inc } from 'ramda';

const getCustomValuesList = (customFieldData) => {
  const {
    customFieldsList, selectedId, customFieldEntities, formData,
  } = customFieldData;

  const customValuesList = [];
  customFieldsList.forEach((fieldId) => {
    const customValues = { model_id: selectedId, field_id: fieldId };
    const { title } = customFieldEntities[fieldId];
    const customFieldValue = formData[String(title)];
    customValuesList.push({ ...customValues, value: customFieldValue });
  });
  return customValuesList;
};

const getCustomFieldValues = (item, customFieldEntities) => {
  const customFieldValues = [];
  let initialCustomValues = {};
  if (item.customFields) {
    item.customFields.forEach((customValue) => {
      const { title } = customFieldEntities[customValue.field_id] ?? '';
      customFieldValues.push({ name: title, value: customValue.value });
      initialCustomValues = { ...initialCustomValues, [title]: customValue.value };
    });
  }
  return [customFieldValues, initialCustomValues];
};

const sortByPriority = fields => fields.sort((a, b) => a.priority - b.priority);

const filterOutOtherModels = (fields, modelName) => fields.filter(
  field => field.model_name === modelName,
);

const fixPriorities = fields => fields.map(
  (field, index) => Object.assign({}, field, { priority: index + 1 }),
);

const filterIn = (fields, updField) => fields.map(
  curField => (curField.id === updField.id ? updField : curField),
);

const insertInto = (fields, newField) => sortByPriority([newField, ...fields]);

const filterOut = (fields, id) => {
  const filteredOut = fields.filter(field => field.id !== id);
  return fixPriorities(filteredOut);
};

const getNewPriorities = (
  idDragItem, idCurrentItem, currentPriority, dragPriorityOld, dragPriorityNew,
) => {
  if (idCurrentItem === idDragItem) return dragPriorityNew;
  if (currentPriority < dragPriorityOld
     && currentPriority >= dragPriorityNew) return inc(currentPriority);
  if (currentPriority > dragPriorityOld
     && currentPriority <= dragPriorityNew) return dec(currentPriority);
  return currentPriority;
};


const dragCustomFieldFlow = ({
  draggableId, destination: { index: newIndex }, source: { index: oldIndex }, customFieldsList,
}) => {
  const fieldsWithNewPriority = customFieldsList.map((item) => {
    const priority = getNewPriorities(
      draggableId, item.id, item.priority, inc(oldIndex), inc(newIndex),
    );
    return Object.assign({}, item, { priority });
  });
  return sortByPriority(fieldsWithNewPriority);
};

const organizeFields = (fields, modelName) => {
  const fieldsOfSelectedModel = filterOutOtherModels(fields, modelName);
  return sortByPriority(fieldsOfSelectedModel);
};

export {
  getCustomValuesList, getCustomFieldValues, dragCustomFieldFlow,
  organizeFields, filterIn, filterOut, insertInto,
};
