import React from 'react';
import PropTypes from 'prop-types';
import { ShowMoreItems } from '../../../../../../ui-kit';
import PinnedLeadItem from '../pinnedLeadItem';

import './style.sass';

const PinnedLeads = ({
  pinnedLeads,
}) => (
  <>
    {
      pinnedLeads && pinnedLeads.length ? (
        <div className="pinned-leads">
          <ShowMoreItems
            count={4}
            items={pinnedLeads.map(id => <PinnedLeadItem key={id} leadId={id} />)}
          />
        </div>
      ) : (
        <div className="pinned-leads--empty">
          There are no pinned leads yet
        </div>
      )
    }
  </>

);

PinnedLeads.propTypes = {
  pinnedContacts: PropTypes.instanceOf(Object),
};

PinnedLeads.defaultProps = {
  pinnedContacts: null,
};

export default PinnedLeads;
