import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import ChannelsList from './channelsList';

const enhance = compose(
  withTranslation(['common', 'chat']),
);

export default enhance(ChannelsList);
