import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import LabelTimeMessage from '../labelTimeMessage';

const DateLabel = ({ date }) => (
  <>
    <div className="row text-align--center time-label__vertical-break-line">
      <span className="display--inline-block">
        <LabelTimeMessage>
          {moment(date).format('MMM DD YYYY')}
        </LabelTimeMessage>
      </span>
    </div>
  </>
);

DateLabel.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default DateLabel;
