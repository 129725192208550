import {
  compose, withHandlers, withProps, withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import { messengerActions, messengerSelectors } from '../../../../state/messenger';
import { uiActions } from '../../../../state/ui';
import Members from './members';

const mapStateToProps = state => ({
  membersList: messengerSelectors.getActiveChannelMembers(state),
  activeChannel: messengerSelectors.getActiveChannel(state),
  isPendingDelete: messengerSelectors.deleteMemberFromChannelPending(state),
  isPendingInvite: messengerSelectors.addMembersToChannelPending(state),
});

const mapDispatchToProps = {
  openModal: uiActions.openModal,
  deleteMemberRequest: messengerActions.deleteMemberFromChannelRequest,
  inviteMembersRequest: messengerActions.addMemberToChannelRequest,
};

const onDeleteMemberHandler = ({ deleteMemberRequest, channelId }) => (memberId, callbacks) => {
  deleteMemberRequest({ memberId, channelId }, {
    callbacks,
  });
};

const onInviteMemberHandler = ({ inviteMembersRequest, channelId }) => (memberIds, callbacks) => {
  inviteMembersRequest({ memberIds, channelId }, {
    callbacks,
  });
};

const setSelectedMemberIdStateHandler = () => memberId => ({ selectedMemberId: memberId });

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common']),
  withProps(({ activeChannel: { id } }) => ({
    channelId: id,
  })),
  withStateHandlers(() => ({ selectedMemberId: null }), {
    setSelectedMemberId: setSelectedMemberIdStateHandler,
  }),
  withHandlers({
    onDeleteMember: onDeleteMemberHandler,
    onInviteMember: onInviteMemberHandler,
  }),
);

export default enhance(Members);
