const LIMIT_OF_LIST_PEOPLE = 10;

const SORT_BY = {
  LETTERS: {
    DOWN: 'asc',
    UP: 'desc',
  },
  CREATED_AT: 'created_at',
};

const DEFAULT_REQUEST = {
  order: 'desc',
  sortBy: 'created_at',
  limit: LIMIT_OF_LIST_PEOPLE,
  offset: 0,
  searchValue: '',
};

const DEFALUT_FIELD_VALUE = {
  EDIT: '  ',
  CREATE: '',
};

const EDIT_PERSON_FIELDS = [
  {
    name: 'email',
    title: 'Email address',
    placeholer: 'example@gmail.co',
  },
  {
    name: 'username',
    title: 'Username',
  },
  {
    name: 'first_name',
    title: 'First name',
  },
  {
    name: 'last_name',
    title: 'Last name',
  },
  {
    name: 'birthdate',
    title: 'Birthdate',
    dateError: 'Date should be within date limits.',
    dateLimits: true,
    isDate: true,
  },
  {
    name: 'created_at',
    title: 'Create at',
    disabled: true,
    isDate: true,
  },
  {
    name: 'vacation',
    title: 'Vacation',
  },
];

const CREATE_PERSON_FIELDS = [
  {
    name: 'email',
    title: 'Email address',
    placeholer: 'example@gmail.co',
  },
  {
    name: 'username',
    title: 'Username',
  },
  {
    name: 'first_name',
    title: 'First name',
  },
  {
    name: 'last_name',
    title: 'Last name',
  },
];

export {
  SORT_BY,
  LIMIT_OF_LIST_PEOPLE,
  DEFAULT_REQUEST,
  DEFALUT_FIELD_VALUE,
  EDIT_PERSON_FIELDS,
  CREATE_PERSON_FIELDS,
};
