import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose';
import { connect } from 'react-redux';
import { equals, isEmpty } from 'ramda';

import InvoiceList from './invoiceList';
import { preloaderWhileLoading, withPagination } from '../../../../utils/enchancers';
import { calculateOffset, calculatePage } from '../../../../utils/helpers/uiComponentHelpers/pagination';
import { INVOICES_LIMIT } from '../../../../constants/crm';
import { invoicesActions, invoicesSelectors } from '../../../../state/invoices';
import { pendingSelectors } from '../../../../utils/middlewares/sagaRequestApi/state';

const onPageChangeHandler = ({
  queryParams, getInvoicesListRequest,
}) => ({ offset }) => {
  getInvoicesListRequest({
    ...queryParams,
    page: calculatePage(offset, INVOICES_LIMIT),
    limit: INVOICES_LIMIT,
  });
};

const mapDispatchToProps = {
  getInvoicesListRequest: invoicesActions.getInvoicesListRequest,
};

const mapStateToProps = state => ({
  invoicesList: invoicesSelectors.getInvoicesList(state),
  invoicesCount: invoicesSelectors.getInvoicesCount(state),
  totalCount: invoicesSelectors.getTotalCount(state),
  isPending: pendingSelectors.getPendingRequest(state, 'getInvoicesListRequest'),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('showLoader', 'setIsShowLoader', true),
  withPagination({
    limit: () => INVOICES_LIMIT,
    offset: ({ getUrlParam }) => calculateOffset(getUrlParam(['page'], 1), INVOICES_LIMIT),
    page: ({ getUrlParam }) => getUrlParam(['page'], 1),
  }),
  withState('isInitialSent', 'setInitialSent', false),
  withHandlers({
    onPageChange: onPageChangeHandler,
  }),
  lifecycle({
    componentDidMount() {
      const { queryParams, getInvoicesListRequest } = this.props;
      getInvoicesListRequest(queryParams);
    },
    componentDidUpdate({ queryParams }) {
      const {
        invoicesList, getInvoicesListRequest, isInitialSent,
        setInitialSent, isPending, showLoader, setIsShowLoader,
      } = this.props;

      if (!isInitialSent && isEmpty(invoicesList)) {
        getInvoicesListRequest(this.props.queryParams);
        setInitialSent(true);
      }

      if (!equals(queryParams, this.props.queryParams)) {
        getInvoicesListRequest(this.props.queryParams);
      }

      if (!isPending && showLoader) {
        setIsShowLoader(false);
      }
    },
  }),
  preloaderWhileLoading({
    dimension: 100,
    fullScreen: true,
    isLoading: () => false,
    onAction: ({ showLoader }) => showLoader,
  }),
);

export default enhance(InvoiceList);
