import { path, pathOr } from 'ramda';
import React, { memo } from 'react';

import { AsyncAutoComplete, FormsCollection } from '@/ui-kit';
import { RadioButtons } from '@bp/components';
import { FIELD_TYPES, FIELD_TYPES_KEYS } from './constants';
import { useFieldByOption } from './useFieldByOption';

import './style.sass';

export const FieldByOption = memo(
  ({ error, config, value, isUseVariable, handleChange, handleBlur, setFieldValue }) => {
    const { id, name, label, defaultValue, className, input_type } = config;
    const { onOpenModalAddVariable, getAutoComplete } = useFieldByOption();

    const allowVariables =
      isUseVariable && !pathOr(0, ['input_options', 'isNotAllowVariables'], config);

    return (
      <div
        className={`fieldByOption ${className}
         ${
           path(['input_options', 'only_variables'], config) ? 'fieldByOption--only-variables' : ''
         }`}
      >
        {
          {
            [FIELD_TYPES_KEYS[FIELD_TYPES.TEXT]]: (
              <div className="fieldByOption__group">
                <label htmlFor={id} className="fieldByOption__group-label">
                  {label}
                </label>
                <FormsCollection.TextArea
                  asInput
                  id={id}
                  name={name}
                  type="text"
                  value={value}
                  error={error}
                  onChange={handleChange}
                />
              </div>
            ),
            [FIELD_TYPES_KEYS[FIELD_TYPES.TEXTAREA]]: (
              <div className="fieldByOption__group">
                <label htmlFor={id} className="fieldByOption__group-label">
                  {label}
                </label>
                <FormsCollection.TextArea
                  name={name}
                  type="text"
                  id={id}
                  value={value}
                  error={error}
                  onChange={handleChange}
                />
              </div>
            ),
            [FIELD_TYPES_KEYS[FIELD_TYPES.ESTIMATE]]: (
              <div className="fieldByOption__group">
                <label htmlFor={id} className="fieldByOption__group-label">
                  {label}
                </label>
                <FormsCollection.Input
                  name={name}
                  id="create-task__estimated-time"
                  onChange={handleChange}
                  placeholder="1w 20m 21h 30m"
                  onBlur={handleBlur}
                  value={value}
                  touched
                />
              </div>
            ),
            [FIELD_TYPES_KEYS[FIELD_TYPES.RADIO]]: (
              <div className="fieldByOption__group">
                <label htmlFor={id} className="fieldByOption__group-label">
                  {label}
                </label>
                <RadioButtons
                  defaultValue={defaultValue}
                  config={config.input_options}
                  name={name}
                  values={value}
                  id={id}
                  selectedValue={value}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </div>
            ),
            [FIELD_TYPES_KEYS[FIELD_TYPES.DATE_PICKER]]: (
              <div className="fieldByOption__group">
                <label htmlFor={name} className="fieldByOption__group-label">
                  {label}
                </label>
                <FormsCollection.DatePicker
                  id={id}
                  name={name}
                  value={value}
                  onChange={handleChange}
                />
              </div>
            ),
            [FIELD_TYPES_KEYS[FIELD_TYPES.TIME_PICKER]]: (
              <div className="fieldByOption__group">
                <label htmlFor={name} className="fieldByOption__group-label">
                  {label}
                </label>
                <FormsCollection.TimePicker
                  id={id}
                  name={name}
                  value={value}
                  onChange={handleChange}
                />
              </div>
            ),
            [FIELD_TYPES_KEYS[FIELD_TYPES.SELECT]]: (
              <div className="fieldByOption__group">
                <label htmlFor={name} className="fieldByOption__group-label">
                  {label}
                </label>
                <FormsCollection.Select
                  id={id}
                  name={name}
                  value={value}
                  data={path(['input_options', 'options'], config)}
                  onChange={handleChange}
                />
              </div>
            ),
            [FIELD_TYPES_KEYS[FIELD_TYPES.AUTOCOMPLETE]]: (
              <div className="fieldByOption__group">
                <label htmlFor={name} className="fieldByOption__group-label">
                  {label}
                </label>
                <div className="fieldByOption__row">
                  <AsyncAutoComplete
                    id={id}
                    defaultValue={defaultValue}
                    config={config.input_options}
                    name={name}
                    value={value}
                    loadOptions={getAutoComplete(name)}
                    onChange={(val) => setFieldValue(name, val)}
                  />
                </div>
              </div>
            ),
            [FIELD_TYPES_KEYS[FIELD_TYPES.MULTI_AUTOCOMPLETE]]: (
              <div className="fieldByOption__group">
                <label htmlFor={name} className="fieldByOption__group-label">
                  {label}
                </label>
                <div className="fieldByOption__row">
                  <AsyncAutoComplete
                    isMulti
                    closeMenuOnSelect={false}
                    id={id}
                    defaultValue={defaultValue}
                    config={config.input_options}
                    name={name}
                    value={value}
                    loadOptions={getAutoComplete(name)}
                    onChange={(val) => setFieldValue(name, val)}
                  />
                </div>
              </div>
            ),
          }[input_type]
        }
        <small className="fieldByOption__error">{error}</small>
        {allowVariables && (
          <button
            type="button"
            onMouseDown={(e) => e.preventDefault()}
            onClick={onOpenModalAddVariable(name)}
            className="fieldByOption__add-variable"
          >
            <span className="icon-add-btn" />
            Add variable
          </button>
        )}
      </div>
    );
  },
);
