import { compose } from 'recompose';
import { withFormik } from 'formik';
import { connect } from 'react-redux';

import AddTaxForm from './addTaxForm';
import { invoicesActions, invoicesSelectors } from '../../../../../../state/invoices';
import { INVOICES_PAY_TYPES } from '../../../../../../constants/crm';

const mapStateToProps = (state, { isEditInvoicePage }) => ({
  tax: isEditInvoicePage
    ? JSON.parse(invoicesSelectors.getCurrentInvoiceTax(state))
    : invoicesSelectors.getTax(state),
  taxPaymentType: invoicesSelectors.getTaxPaymentType(state),
});

const mapDispatchToProps = {
  setTax: invoicesActions.setTax,
  setTaxPaymentType: invoicesActions.setTaxPaymentType,
};

const defaultLabel = {
  label: 'Percent (%)',
  value: INVOICES_PAY_TYPES.PERCENT,
  id: 1,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    mapPropsToValues: ({
      tax, getPaymentType, currencySign, isEditInvoicePage,
    }) => ({
      tax: tax.value,
      tax_type: isEditInvoicePage ? getPaymentType(tax.paymentType, currencySign) : defaultLabel,
    }),
  }),
);

export default enhance(AddTaxForm);
