import React from 'react';
import PropTypes from 'prop-types';

import { propOr } from 'ramda';
import {
  ConfirmModal,
} from '../../../../ui-kit';
import { getFullName } from '../../../../utils/helpers/userHelpers';

const UnpinClientModal = ({
  isOpen,
  onClose,
  pinnedClient,
  onUnpin,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    actionTitle="Unpin"
    onAction={onUnpin}
    onClose={onClose}
    title="Unpin client"
  >
    Are you sure you want to unpin
    <b>{getFullName(propOr({}, 'contact', pinnedClient))}</b>
    client?
  </ConfirmModal>
);

UnpinClientModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUnpin: PropTypes.func.isRequired,
  pinnedClient: PropTypes.shape({}),
};

UnpinClientModal.defaultProps = {
  pinnedClient: null,
};

export default UnpinClientModal;
