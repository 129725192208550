import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  LabelPriorityTask,
  BrillMask,
  Avatar,
  ButtonsCollection,
  DropDown,
} from '../../../../ui-kit';

import './style.sass';
import { formatDate, getCategoryTitle } from '../../../../utils/helpers/taskHelpers/crudHelper';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { getTimeStringBySeconds } from '../../../../utils/helpers/dateHelpers';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import { IMAGES_SIZE } from '../../../../constants/ui';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';

const TaskCard = ({
  t,
  categories,
  isDragging,
  assignees,
  task,
  onEdit,
  onDelete,
  windowWidth,
  onSetTaskStatus,
  P,
  statuses,
  statusOfTasksEntities,
}) => {
  const categoryTitle = getCategoryTitle(categories[task.category_id]);
  const assignee = assignees[task.assigneeUser];
  return (
    <Link
      to={`/projects/${task.project_id}/task/${task.id}`}
      className={`agenda-task ${isDragging ? 'agenda-task--drag' : ''}`}
    >
      <div className="agenda-task__header">
        <b className="agenda-task__category">{categoryTitle}</b>
        <h4 className="agenda-task__title">{task.title}</h4>
        <DropDown
          className="agenda-task-control"
          label={(
            <ButtonsCollection.ButtonIcons
              className="agenda-task__controls-button"
              title="Controls for sprint"
            >
              <span className="table-tasks__control-icon icon-settings-vertical">
                <span
                  className="path1"
                />
                <span className="path2" />
                <span
                  className="path3"
                />
              </span>
            </ButtonsCollection.ButtonIcons>
          )}
          list={
          [
            {
              id: 0,
              onClick: onEdit,
              label: (
                <>
                  <span className="agenda-task-control__icon icon-pencil-2" />
                  <span className="agenda-task-control__text">{t('Edit')}</span>
                </>
              ),
            },
            ...P(MODULES.TASKS, ACTIONS.DELETE, [{
              id: 2,
              onClick: onDelete,
              label: (
                <>
                  <span className="agenda-task-control__icon icon-delete-icon" />
                  <span className="agenda-task-control__text">{t('Delete')}</span>
                </>
              ),
            }]),
          ]
        }
        />
      </div>
      <div className="agenda-task__body">
        {
            assignee ? (
              <BrillMask className="agenda-task__avatar-user" disableHighlight asLink to="/">
                <Avatar
                  src={getImageUrl(assignee.avatar, IMAGES_SIZE.sm)}
                  alt={getFullName(assignee)}
                />
              </BrillMask>
            ) : (
              <BrillMask className="agenda-task__avatar-user" disableHighlight asLink to="/">
                <Avatar importantBackground="#a0a0a0" alt="Without assignee" />
              </BrillMask>
            )
          }
        <div className="agenda-task__date">
          {formatDate(task.created_at)}
        </div>
        <div className="agenda-task__time-entry">
          {getTimeStringBySeconds(task.remaining_estimate)}
          {' '}

remaining
        </div>
        {
          windowWidth < 1200 && (
            <DropDown
              className="controls-group-task"
              name="controls-group-task-status"
              label={(
                <div className="agenda-task__label">
                  {statusOfTasksEntities[task.status_id]?.title}
                </div>
              )}
              list={
                statuses.map(({ id, title }) => (
                  {
                    id,
                    onClick: () => onSetTaskStatus(id),
                    label: (
                      <>
                        <span className="controls-group-task__text">{title}</span>
                      </>
                    ),
                  }
                ))
              }
            />
          )
        }
        <div className="agenda-task__info">
          <LabelPriorityTask status={task.priority} />
          <div className="agenda-task__number">{`#${task.id}`}</div>
        </div>
      </div>
    </Link>
  );
};


TaskCard.propTypes = {
  categories: PropTypes.instanceOf(Object).isRequired,
  assignees: PropTypes.instanceOf(Object).isRequired,
  task: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  taskStatuses: PropTypes.shape({}).isRequired,
  onSetTaskStatus: PropTypes.func.isRequired,
  P: PropTypes.func.isRequired,
  statuses: PropTypes.instanceOf(Array).isRequired,
  statusOfTasksEntities: PropTypes.instanceOf(Object).isRequired,
};


export default TaskCard;
