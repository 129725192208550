import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { TaskItem } from './components';
import { ShowMoreItems } from '../../../../ui-kit';
import './style.sass';

/**
 * @param result {array}
 * @param className {string}
 * @param onDelete {boolean}
 * @param entities {object}
 * @returns {JSX.Element}
 * @constructor
 */

const COUNT_ITEMS = 5;

export const TaskList = ({
  className = '',
  result,
  onDelete = false,
  entities,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classNames('tasks-list', className)}>
      <ul className="tasks-list__list">
        {
          result?.length ? (
            <ShowMoreItems
              count={COUNT_ITEMS}
              items={result.map(item => (
                <TaskItem
                  onDelete={onDelete}
                  key={entities ? item : item.id}
                  task={entities ? entities[item] : item}
                />
              ))}
            />
          ) : <div className="files-attached__info-text tasks-list__empty text-align--center">{t('No tasks yet')}</div>
        }
      </ul>
    </div>
  );
};
