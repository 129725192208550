import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import classNames from 'classnames';

import { useWindowWidth } from '../../utils/hooks';
import { UserProfile } from './components';
import { ButtonsCollection } from '../../ui-kit';
import { uiActions, uiSelectors } from '../../state/ui';
import { WINDOW_WIDTH } from '../../constants/ui';
import { APP_ROUTES } from '../../constants/appRoutes';
import './style.sass';

/**
 * @param children {string}
 * @param className {string}
 * @param title {string}
 * @param titleComponentRender {function}
 * @param onToggleMiddleware {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const RightSideBar = ({
  children = '',
  className = '',
  title = '',
  titleComponentRender = null,
  onToggleMiddleware = null,
}) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const location = useLocation();
  const leftSideBarStatus = useSelector(uiSelectors.getLeftSidebar);
  const rightSideBarStatus = useSelector(uiSelectors.getRightSidebar);
  const userProfileId = useSelector(uiSelectors.getUserProfileId);

  const rightSideBarRef = useRef('rightSideBar');
  const prevPropsRef = useRef({
    leftSideBarStatus, location,
  });
  useEffect(() => {
    prevPropsRef.current = {
      leftSideBarStatus, location,
    };
  }, [leftSideBarStatus, location]);
  const prevProps = prevPropsRef.current;

  const changeRightSidebarStatus = useCallback((value) => {
    dispatch(uiActions.changeRightSidebarStatus(value));
  });
  const changeLeftSidebarStatus = useCallback((value) => {
    dispatch(uiActions.changeLeftSidebarStatus(value));
  });
  const setUserProfileId = useCallback((value) => {
    dispatch(uiActions.setUserProfileId(value));
  });

  const onToggle = useCallback(() => {
    const allowAction = onToggleMiddleware
      ? onToggleMiddleware({ status: rightSideBarStatus })
      : true;
    if (allowAction) {
      if (userProfileId) {
        setUserProfileId(null);
      }
      if (windowWidth < WINDOW_WIDTH.SMALL) {
        changeLeftSidebarStatus(false);
        changeRightSidebarStatus(false);
      }
      if (rightSideBarStatus && !userProfileId) {
        changeRightSidebarStatus(false);
      }
    }
  });

  useEffect(() => {
    if (pathname.indexOf(APP_ROUTES.TASK) !== -1 && rightSideBarStatus) {
      changeRightSidebarStatus(false);
    }
  }, [pathname, rightSideBarStatus]);

  useEffect(() => {
    if (prevProps.location.pathname !== location.pathname) {
      onToggle();
    }
  }, [location]);

  return (
    <div
      className={classNames('right-side-bar', className)}
      ref={rightSideBarRef}
    >
      <div className="right-side-bar__header">
        {titleComponentRender ? titleComponentRender()
          : (
            <h2 className="title--xl weight--bold right-side-bar__title">
              { userProfileId ? 'About User' : title }
            </h2>
          )}
        <ButtonsCollection.ButtonClose
          title="Right sidebar"
          className="right-side-bar__close-button"
          onClick={() => onToggle(true)}
        />
      </div>
      <div className="right-side-bar__body">
        {userProfileId ? <UserProfile /> : <>{children}</>}
      </div>
    </div>
  );
};
