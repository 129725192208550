import React, {
  useState, useEffect, memo, useCallback,
} from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { WINDOW_WIDTH } from '../../../constants/ui';
import { ButtonsCollection } from '../..';
import { PseudoLabel } from './PseudoLabel';


import './style.sass';

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return windowWidth;
};

const DEFAULT_BUTTON_NAME = 'Submit';

/**
 * @param children {element | string}
 * @param onClick {function}
 * @param style {object}
 * @param className {string}
 * @param href {string}
 * @param asLink {boolean}
 * @param type {string}
 * @param pending {boolean}
 * @param isDelay {boolean}
 * @param onMouseDown {function}
 * @param disabled {boolean}
 * @param title {string}
 * @returns {JSX.Element}
 * @constructor
 */

const ButtonBrill = ({
  children, onClick, onMouseDown, className,
  href, asLink, pending, type, title, disabled, style,
  delay, onEndDelay, renderMobileButton,
}) => {
  const [isDelay, setIsDelay] = useState(false);
  const [isMobileButton, setIsMobileButton] = useState(false);
  const windowWidth = useWindowWidth();


  const onClickHandler = useCallback(() => {
    if (!isDelay && !pending) {
      onClick();
    }
  }, [isDelay, pending, onClick]);

  useEffect(() => {
    setIsMobileButton(renderMobileButton && windowWidth < WINDOW_WIDTH.SMALL);
  }, [windowWidth, renderMobileButton]);

  useEffect(() => {
    setIsDelay(true);
    const timerId = setTimeout(() => setIsDelay(false), delay);
    return () => {
      clearTimeout(timerId);
    };
  }, [pending, delay]);

  useEffect(() => {
    if (!isDelay) {
      onEndDelay();
    }
  }, [isDelay, onEndDelay]);

  if (isMobileButton) {
    return (
      <ButtonsCollection.ButtonPlus
        onClick={onClick}
        asLink={asLink}
        href={href}
        className={`${className} button-icon--mobile`}
      />
    );
  }

  return asLink ? (
    <Link to={href} className={`button-brill ${className}`}>
      <span className="button-brill__wrapper">
        <span className="button-brill__text">{children}</span>
      </span>
      <PseudoLabel />
    </Link>
  ) : (
    // eslint-disable-next-line react/button-has-type
    <button
      title={title}
      type={type}
      disabled={disabled}
      onMouseDown={onMouseDown}
      onClick={onClickHandler}
      className={classNames(`button-brill ${className}`, { 'button--pending': isDelay || pending })}
      style={style}
    >
      <span className="button-brill__wrapper">
        <span className="button-brill__text">{children}</span>
        <span className="button-brill__pending">
          <span className="button-brill__pending-part" />
          <span className="button-brill__pending-part" />
          <span className="button-brill__pending-part" />
          <span className="button-brill__pending-part" />
        </span>
      </span>
      <PseudoLabel />
    </button>
  );
};

ButtonBrill.defaultProps = {
  children: DEFAULT_BUTTON_NAME,
  onClick: () => { },
  onMouseDown: () => { },
  className: '',
  href: '',
  asLink: false,
  pending: false,
  type: 'button',
  title: '',
  disabled: false,
  style: {},
  delay: 0,
  onEndDelay: () => { },
  renderMobileButton: false,
};

export default memo(ButtonBrill);
