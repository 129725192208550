import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import DeleteLeadModal from './deleteLeadModal';
import { uiSelectors, uiActions } from '../../../../state/ui';
import { leadsSelectors } from '../../../../state/leads';

const mapStateToProps = (state, { selectedLeadId }) => ({
  isOpen: uiSelectors.getModal(state)('deleteLeadModal'),
  lead: leadsSelectors.getLeadById(state)(selectedLeadId),
});

const mapDispatchToProps = {
  closeModal: () => uiActions.closeModal('deleteLeadModal'),
};

const onCloseHandler = ({
  closeModal,
}) => () => {
  closeModal();
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onClose: onCloseHandler,
  }),
);
export default enhance(DeleteLeadModal);
