import {
  compose, withHandlers, withProps,
} from 'recompose';
import {
  values, map, pickAll, prop, pipe, mergeRight,
} from 'ramda';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import Filters from './filters';
import withUsersAvatars from '../../../../utils/enchancers/withUserAvatars';
import { makeObjectWithAvatars } from '../../../../utils/helpers/uiHelpers';
import { projectSelectors } from '../../../../state/project';
import { usersSelectors } from '../../../../state/users';
import { getOnlyActiveSprints } from '../../../../utils/helpers/projectHelpers/sprints';

const mapStateToProps = state => ({
  isAgendaTasksPendingRequestPending: projectSelectors.getAgendaTasksRequestPending(state),
  assigneeUsersList: projectSelectors.getUsersWithTasksList(state),
  usersEntities: usersSelectors.getUsersEntities(state),
  tasksSS: projectSelectors.getProjectSprintTasks(state),
});

const makeAvatars = compose(
  map(makeObjectWithAvatars),
  values,
  mergeRight({
    UNASSIGNED_USER_ID: {
      avatar: null,
      created_at: '2019-01-01 00:00:00',
      email: 'unassigned@un.co',
      first_name: 'Without',
      last_name: 'Assignee',
      id: 'null',
      invited_by: null,
      invited_for: null,
      is_online: 0,
      phone: null,
      role_id: 1,
      type: 2,
      updated_at: '2019-01-01 00:00:00',
      username: 'Unassigned',
      importantBackground: '#a0a0a0',
    },
  }),
);

const onClickAvatarHandler = ({ setAvatarActive, onSelectUser }) => (id) => {
  onSelectUser(id);
  setAvatarActive(id);
};

const enhance = compose(
  connect(mapStateToProps, null),
  withTranslation(['tasks', 'common']),
  withProps(({ assigneesIds, usersEntities }) => ({
    assigneeUsers: pickAll(assigneesIds, usersEntities),
  })),
  withHandlers({
    onClickAvatar: onClickAvatarHandler,
  }),
  withProps(({ sprints }) => ({
    sprintsList: getOnlyActiveSprints(sprints),
  })),
  withUsersAvatars({
    countDisplay: 6,
    users: pipe(
      prop('assigneeUsers'),
      makeAvatars,
    ),
    selectedAvatars: prop('selectedUsers'),
    onClick: ({ onSelectUser }) => pipe(
      String,
      onSelectUser,
    ),
  }),
);

export default enhance(Filters);
