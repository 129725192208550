import {
  compose, lifecycle, withContext, withHandlers, withState,
} from 'recompose';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { uiActions } from '../../../../state/ui';
import { leadsActions, leadsSelectors } from '../../../../state/leads';
import ContactsInfo from './contactsInfo';
import { preloaderWhileLoading } from '../../../../utils/enchancers';
import { contactsActions } from '../../../../state/contacts';
import { PRELOADER_DIMENSION } from '../../../../constants/ui';
import { OFFSET } from '../../../../constants/crm';
import { customFieldsActions } from '../../../../state/customFields';


const mapStateToProps = (state, { lead }) => ({
  leadContacts: leadsSelectors.getLeadContactsSelector(state)(lead.id),
  leadContactsCount: leadsSelectors.getLeadsContactCountSelector(state),
  isLoading: leadsSelectors.getLeadActionPending(state)('getLeadContactsRequest'),
});

const mapDispatchToProps = ({
  getLeadContacts: leadsActions.getLeadContactsRequest,
  setCreateContactModalOpen: () => uiActions.openModal('createContactModal'),
  deleteContact: contactsActions.deleteContactRequest,
  addContact: contactsActions.addContactRequest,
  closeModal: uiActions.closeModal,
  deleteLeadContactSuccess: leadsActions.deleteLeadContactSuccess,
  setAddedLeadContact: leadsActions.setAddedLeadContact,
  editLeadContactSuccess: leadsActions.editLeadContactSuccess,
  getCustomFields: customFieldsActions.getCustomFieldsRequest,
});

const onGetLeadContactsHandler = ({ getLeadContacts, lead }) => () => {
  getLeadContacts({ id: lead.id });
};
const onDeleteLeadContactsHandler = ({
  deleteLeadContactSuccess,
  lead,
  selectedContactId,
}) => () => {
  deleteLeadContactSuccess({ leadId: lead.id, selectedContactId });
};

const createLeadContactsSuccessHandler = ({
  setAddedLeadContact, setOffset, lead, getLeadContacts,
}) => (formData) => {
  setAddedLeadContact({ contact: { ...formData, client_id: path(['client_id', 'value'], formData) } });
  setOffset(OFFSET);
  getLeadContacts({ id: lead.id });
};

const editLeadContactsSuccessHandler = ({
  editLeadContactSuccess, setOffset, getLeadContacts,
}) => (clientId) => {
  setOffset(OFFSET);
  editLeadContactSuccess(clientId);
  getLeadContacts({ id: clientId });
};


const getMoreContactsHandler = ({
  getLeadContacts, setOffset, offset, lead, leadContactsCount, leadContacts,
}) => () => {
  if (leadContacts.length < leadContactsCount) {
    setOffset(offset + OFFSET);
    getLeadContacts({ id: lead.id, offset });
  }
};
const onDeleteContactHandler = ({
  deleteContact,
  selectedContactId,
  closeModal,
  setSelectedContactId,
  getLeadContacts,
  lead,
  deleteLeadContactSuccess,
}) => () => {
  deleteContact({ id: selectedContactId }, {
    callbacks: {
      success: () => {
        onGetLeadContactsHandler({ getLeadContacts, lead })();
        onDeleteLeadContactsHandler({ deleteLeadContactSuccess, lead, selectedContactId })();
      },
    },
  });
  setSelectedContactId(null);
  closeModal('deleteContactModal');
};

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common']),
  withState('selectedContactId', 'setSelectedContactId', null),
  withContext({
    setSelectedContactId: PropTypes.func,
  }, ({ setSelectedContactId }) => ({
    setSelectedContactId,
  })),
  withState('offset', 'setOffset', OFFSET),
  withHandlers({
    onGetLeadContacts: onGetLeadContactsHandler,
    onDeleteContact: onDeleteContactHandler,
    getMoreContacts: getMoreContactsHandler,
    createLeadContactsSuccess: createLeadContactsSuccessHandler,
    editLeadContactsSuccess: editLeadContactsSuccessHandler,
  }),
  lifecycle({
    componentDidMount() {
      const { onGetLeadContacts, getCustomFields } = this.props;
      getCustomFields();
      onGetLeadContacts();
    },
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.SMALL,
    isLoading: props => !props.isLoading,
  }),
);
export default enhancer(ContactsInfo);
