import React, { useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { useWindowWidth } from '../../utils/hooks';
import { WINDOW_WIDTH } from '../../constants/ui';
import { uiActions } from '../../state/ui';
import { LAST_CHANNEL_MESSENGER_STORAGE } from '../../constants/messenger';
import { APP_ROUTES } from '../../constants/appRoutes';

/**
 *
 * @returns {function}
 */

export const useRelevantList = () => {
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const { t } = useTranslation();

  const isSmallScreen = useMemo(() => windowWidth < WINDOW_WIDTH.X_SMALL, [windowWidth]);

  const logoutRequest = useCallback(() => {
    dispatch(uiActions.resetUi());
    dispatch(push(APP_ROUTES.MAIN));
    localStorage.removeItem('authToken');
    localStorage.removeItem(LAST_CHANNEL_MESSENGER_STORAGE);
  });

  return useMemo(() => (isSmallScreen ? (
    [
      {
        id: 1,
        label: (
          <Link to="/profile/" className="user-controls__link">
            <span className="icon-edit-icon" />
            {t('My profile')}
          </Link>
        ),
      },
      {
        id: 0,
        label: (
          <>
            <span className="button-logout icon icon-reply" />
            <span className="button-logout btn">
              {t('Log Out')}
            </span>
          </>
        ),
        onClick: logoutRequest,
      },
    ]
  ) : (
    [
      {
        id: 0,
        label: (
          <>
            <span className="button-logout icon icon-reply" />
            <span className="button-logout btn">
              {t('Log Out')}
            </span>
          </>
        ),
        onClick: logoutRequest,
      },
    ]
  )), [t, isSmallScreen, logoutRequest]);
};
