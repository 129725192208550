import React from 'react';
import classNames from 'classnames';

import './style.sass';

/**
 *
 * @param label {string}
 * @param id {string}
 * @param name {string}
 * @param checked {boolean}
 * @param className {string}
 * @param value {string}
 * @param onChange {function}
 * @param onBlur {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const Radio = ({
  label = '',
  id,
  name,
  checked,
  className = '',
  value,
  onChange,
  onBlur,
}) => (
  <div className={classNames('radio-field', className)}>
    <input
      className="radio-field__input"
      type="radio"
      name={name}
      id={id}
      checked={checked}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
    <label htmlFor={id} className="radio-field__pseudo-input" />
    <label className="radio-field__label" htmlFor={id}>{label}</label>
  </div>
);
