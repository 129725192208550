import React from 'react';
import { FormsCollection } from '../../..';

import './style.sass';

/**
 * @param titleValue {string}
 * @param onChangeTitleValue {function}
 * @param authorValue {string}
 * @param onChangeAuthorValue {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const GitlabCommitsFilter = ({
  titleValue, onChangeTitleValue, authorValue, onChangeAuthorValue,
}) => (
  <div className="commits__filters">
    <FormsCollection.Search value={titleValue} onChange={onChangeTitleValue} placeholder="Search by message" withoutControl className="commits__filters-item search-field--circle" />
    <FormsCollection.Search value={authorValue} onChange={onChangeAuthorValue} placeholder="Search by author" withoutControl className="commits__filters-item search-field--circle" />
  </div>
);
