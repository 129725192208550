import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ButtonsCollection, Avatar,
  BrillMask,
  Grid,
  DropDown,
} from '../../../../ui-kit';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import './style.sass';
import { getFullName } from '../../../../utils/helpers/userHelpers';

const ContactItem = ({
  contact,
  isOpen,
  selectedPinnedContactId,
  onClickContactItem,
  onEdit,
  onDelete,
  t,
  contactName,
  onPin,
  onUnpin,
  isPinned,
  relatedTo,
  onClickRelatedTo,
  customFieldEntities,
  customFields = [],
}) => (
  <Grid.Row
    className="contacts__table-item"
    id={contact.id}
    onClick={onClickContactItem}
  >
    <div className="contacts__table-item-header contacts__table-row">
      <Grid.ColData className="contacts__table-item-col contacts__table-item-contact">
        <BrillMask asLink>
          <Avatar src={getImageUrl(contact.avatar)} alt={contactName} />
        </BrillMask>
        <div className="contacts__table-item-name">
          {contactName}
        </div>
      </Grid.ColData>
      <Grid.ColData className="contacts__table-item-col contacts__table-item-related">
        { relatedTo && (
          <Link to="/crm/leads" onClick={onClickRelatedTo}>
            {getFullName(relatedTo)}
          </Link>
        ) }
      </Grid.ColData>
      <Grid.ColData className="contacts__table-item-col contacts__table-item-controls">
        <DropDown
          className="controls-group-task"
          name="controls-group-task"
          label={(
            <ButtonsCollection.ButtonIcons
              className="contacts__table-item-controls-button-task"
              title="Controls for contact"
            >
              <span className="contacts__table-item-control-icon icon-settings-vertical">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
              </span>
            </ButtonsCollection.ButtonIcons>
          )}
          list={
            [
              {
                id: 0,
                onClick: onEdit,
                label: (
                  <>
                    <span className="agenda-task-control__icon icon-pencil-2" />
                    <span className="agenda-task-control__text">{t('Edit')}</span>
                  </>
                ),
              },
              {
                id: 1,
                onClick: isPinned ? onUnpin : onPin,
                label: (
                  <>
                    <span className="agenda-task-control__icon icon-pin" />
                    <span className="agenda-task-control__text">{t(isPinned ? 'Unpin' : 'Pin')}</span>
                  </>
                ),
              },
              {
                id: 2,
                onClick: onDelete,
                label: (
                  <>
                    <span className="agenda-task-control__icon icon-delete-icon" />
                    <span className="agenda-task-control__text">{t('Delete')}</span>
                  </>
                ),
              },
            ]
          }
        />
      </Grid.ColData>
    </div>
    {
      (selectedPinnedContactId === contact.id || isOpen) && (
        <div className="contacts__table-item-body">
          <div className="contacts__table-item-fields">
            <div className="contacts__table-item-field">
              <div className="contacts__table-item-field-title">
                Email:
              </div>
              <div className="contacts__table-item-field-val">
                {contact.email || t('N/A')}
              </div>
            </div>
            <div className="contacts__table-item-field">
              <div className="contacts__table-item-field-title">
                Phone number:
              </div>
              <div className="contacts__table-item-field-val">
                {contact.phone || t('N/A')}
              </div>
            </div>
            {customFieldEntities && customFields.map(({ field_id, value }) => (
              <div className="contacts__table-item-field">
                <div className="contacts__table-item-field-title">
                  {customFieldEntities[field_id]?.title}:
                </div>
                <div className="contacts__table-item-field-val">
                  {value || t('N/A')}
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    }
  </Grid.Row>
);

ContactItem.propTypes = {
  contact: PropTypes.shape({
    avatar: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    company_name: PropTypes.instanceOf(PropTypes.object),
  }).isRequired,
  relatedTo: PropTypes.shape({}).isRequired,
  contactName: PropTypes.string.isRequired,
  onClickContactItem: PropTypes.func.isRequired,
  onPin: PropTypes.func.isRequired,
  onUnpin: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClickRelatedTo: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  selectedPinnedContactId: PropTypes.number,
  isPinned: PropTypes.bool.isRequired,
  customFieldEntities: PropTypes.shape({}).isRequired,
  customFields: PropTypes.arrayOf({}).isRequired,
};

export default ContactItem;
