import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from '@/state/ui';
import { getModalWrapperForHooks } from '@/state/ui/selectors';
import { bpSelectors } from '@bp/store/bpModule';

export const useVariablesModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getModalWrapperForHooks('businessProcessVariablesModal'));
  const variablesOfNodes = useSelector(bpSelectors.getOutputVariablesToEvent);

  const onCloseModal = useCallback(() => {
    dispatch(uiActions.closeModal('businessProcessVariablesModal'));
  }, [dispatch]);

  return { variablesOfNodes, isOpen, onCloseModal };
};
