import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from '@/state/ui';
import { CRUD_NODE_CONFIG_ACTIONS } from '@bp//constants';
import { RIGHT_SIDEBAR_VIEWS } from '@bp/constants/common';
import { bpSelectors, bpActions } from '@bp/store/bpModule';

export const useProcessRightSideBar = () => {
  const dispatch = useDispatch();
  const crudNodesConfig = useSelector(bpSelectors.getCrudNodesConfig);
  const selectedEvent = useSelector(bpSelectors.getSelectedEvent);

  useEffect(() => {
    if (crudNodesConfig.view !== RIGHT_SIDEBAR_VIEWS.NOTHING) {
      dispatch(uiActions.changeRightSidebarStatus(true));
    }
  }, [crudNodesConfig]);

  const onToggleRightSideBar = (status) => {
    if (crudNodesConfig.mode !== RIGHT_SIDEBAR_VIEWS.NOTHING && status) {
      dispatch(bpActions.setCrudNodesConfig(CRUD_NODE_CONFIG_ACTIONS.nothing));
      return false;
    }
    return true;
  };

  return { crudNodesConfig, onToggleRightSideBar, selectedEvent };
};
