import PropTypes from 'prop-types';
import React from 'react';
import {
  Avatar, BrillMask, ButtonsCollection, DropDown,
} from '../../../../ui-kit';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { LeadTab, StatusOfLeadsControl, LeadTasks } from '..';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import { WINDOW_WIDTH } from '../../../../constants/ui';
import LeadItemMobile from './leadItemMobile';

const LeadItem = ({
  leadStatus,
  leadPipeline,
  t,
  isOpen,
  onToggleLead,
  setRef,
  lead,
  companyUrl,
  getContactField,
  onCreateTaskModal,
  onEditTaskModal,
  onEditFullTaskModal,
  onDeleteTaskModal,
  onEditLeadModal,
  onDeleteLeadModal,
  onPinLeadModal,
  onUnpinLeadModal,
  isPinned,
  windowWidth,
}) => (
  <>
    <div
      className="leadItem"
      ref={e => setRef('active-lead', e)}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div tabIndex="-1" role="tab" className="leadItem__row" id={`leadId-${lead.id}`} onClick={onToggleLead}>
        <div className="leadItem__col leadItem__avatar">
          <BrillMask>
            <Avatar src={getImageUrl(lead.contact.avatar)} alt={getFullName(lead.contact)} />
          </BrillMask>
        </div>
        <div className="leadItem__col leadItem__username text--cut">
          <span className="text--cut">{getFullName(lead.contact)}</span>
        </div>
        <div className="leadItem__col leadItem__position text--cut">
          <span className="text--cut">{getContactField('position') || t('N/A')}</span>
        </div>
        <div className="leadItem__col leadItem__company contacts__table-item-related text--cut">
          {
          companyUrl ? (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a href={companyUrl} target="_blank" className="text--cut">{getContactField('company_name')}</a>
          ) : (
            <span className="text--cut">{getContactField('company_name') || t('N/A')}</span>
          )
        }
        </div>
        <div className="leadItem__col leadItem__email text--cut">
          <span className="text--cut">
            {getContactField('email') || t('N/A')}
          </span>
        </div>
        <div className="leadItem__col leadItem__service text--cut">
          <span className="text--cut">
            {getContactField('service') || t('N/A')}
          </span>
        </div>
        <div className="leadItem__col leadItem__pipeline text--cut">
          <span className="text--cut">
            {leadPipeline}
          </span>
        </div>
        <div className="leadItem__col leadItem__progress">
          <div className="leadItem__service">
            <span className="leadItem__service text--cut">{leadStatus}</span>
          </div>
        </div>
        <div className="leadItem__col leadItem__control">
          <DropDown
            className="controls-group-task"
            name="controls-group-task"
            label={(
              <ButtonsCollection.ButtonIcons
                className="project-task__controls-button-task"
                title="Controls for lead"
              >
                <span className="project-task__control-icon icon-settings-vertical">
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                </span>
              </ButtonsCollection.ButtonIcons>
          )}
            list={
            [
              {
                id: 0,
                onClick: onEditLeadModal,
                label: (
                  <>
                    <span className="controls-group-task__icon icon-pencil-2" />
                    <span className="controls-group-task__text">{t('Edit')}</span>
                  </>
                ),
              },
              {
                id: 1,
                onClick: isPinned ? onUnpinLeadModal : onPinLeadModal,
                label: (
                  <>
                    <span className="controls-group-task__icon icon-pin" />
                    <span className="controls-group-task__text">{t(isPinned ? 'Unpin' : 'Pin')}</span>
                  </>
                ),
              },
              {
                id: 2,
                onClick: onDeleteLeadModal,
                label: (
                  <>
                    <span className="controls-group-task__icon icon-delete-icon" />
                    <span className="controls-group-task__text">{t('Delete')}</span>
                  </>
                ),
              },
            ]
          }
          />
        </div>
      </div>
      {
        windowWidth > WINDOW_WIDTH.SMALL && (
          isOpen && (
            <div className="leadItem__content" ref={e => setRef('active-lead-content', e)}>
              <StatusOfLeadsControl lead={lead} t={t} />
              <LeadTasks
                leadId={lead.id}
                t={t}
                onCreateTaskModal={onCreateTaskModal}
                onDeleteTaskModal={onDeleteTaskModal}
                onEditTaskModal={onEditTaskModal}
                onEditFullTaskModal={onEditFullTaskModal}
              />
              <LeadTab lead={lead} />
            </div>
          )
        )
      }
    </div>
    {
      windowWidth < WINDOW_WIDTH.SMALL && (
        isOpen && (
          <LeadItemMobile
            onEditFullTaskModal={onEditFullTaskModal}
            t={t}
            lead={lead}
            onDeleteTaskModal={onDeleteTaskModal}
            setRef={setRef}
            onEditTaskModal={onEditTaskModal}
            onCreateTaskModal={onCreateTaskModal}
          />
        )
      )
    }
  </>
);

LeadItem.propTypes = {
  leadPipeline: PropTypes.string.isRequired,
  leadStatus: PropTypes.string.isRequired,
  companyUrl: PropTypes.string,
  t: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isPinned: PropTypes.bool.isRequired,
  onToggleLead: PropTypes.func.isRequired,
  lead: PropTypes.shape({}).isRequired,
  onCreateTaskModal: PropTypes.func.isRequired,
  onEditTaskModal: PropTypes.func.isRequired,
  onEditFullTaskModal: PropTypes.func.isRequired,
  onDeleteTaskModal: PropTypes.func.isRequired,
  onEditLeadModal: PropTypes.func.isRequired,
  onDeleteLeadModal: PropTypes.func.isRequired,
  onPinLeadModal: PropTypes.func.isRequired,
  onUnpinLeadModal: PropTypes.func.isRequired,
  getContactField: PropTypes.func.isRequired,
};

LeadItem.defaultProps = {
  companyUrl: null,
};

export default LeadItem;
