import React from 'react';
import PropTypes from 'prop-types';
import { isNotEmpty } from 'ramda-extension';
import { HrCreateForm } from '../../components';
import { ScrollWrapper, Preloader } from '../../../../../ui-kit';
import { PRELOADER_DIMENSION } from '../../../../../constants/ui';

const HrCandidateUpdate = ({
  candidate, commentsEntities, commentsList,
  filesList, filesEntities, updateCandidate,
}) => (
  <div className="main-container__content hr-candidate__crud-page">
    <ScrollWrapper>
      {
        isNotEmpty(candidate) ? (
          <div className="HrCandidateUpdate">
            <HrCreateForm
              candidate={candidate}
              commentsEntities={commentsEntities}
              commentsList={commentsList}
              filesList={filesList}
              filesEntities={filesEntities}
              onSubmitForm={updateCandidate}
            />
          </div>
        ) : (<Preloader fullScreen duration={800} dimension={PRELOADER_DIMENSION.SMALL} />)
      }
    </ScrollWrapper>
  </div>
);

HrCandidateUpdate.propTypes = {
  candidate: PropTypes.instanceOf(Object).isRequired,
  commentsEntities: PropTypes.instanceOf(Object).isRequired,
  filesEntities: PropTypes.instanceOf(Object).isRequired,
  commentsList: PropTypes.instanceOf(Array).isRequired,
  filesList: PropTypes.instanceOf(Array).isRequired,
  updateCandidate: PropTypes.func.isRequired,
};
HrCandidateUpdate.defaultProps = {};

export default HrCandidateUpdate;
