import {
  compose, withProps, withHandlers, withContext, lifecycle, withState,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  always, ifElse, pathOr, prop, equals,
} from 'ramda';
import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';

import Directs from './directs';
import { messengerSelectors, messengerActions } from '../../state/messenger';
import { uiActions } from '../../state/ui';
import rules from './rules';
import { CHANNELS_YOU_CAN_JOIN } from '../channels/constants';
import { debounceFunc } from '../../utils/helpers/commonHelpers';
import { withPagination, withUrlParams } from '../../utils/enchancers';
import { calculateOffset } from '../../utils/helpers/uiComponentHelpers/pagination';

const LIMIT = 10;

const {
  getDirectChannelsRequest,
  setMatchedDirectChannels,
  setYouCanJoinDirectChannels,
} = messengerActions;
const { resetPagination } = uiActions;

const getObjectForSend = ({
  search, limit = 10, offset = 0, orderByTitle, isNotMember,
}) => ({
  sortBy: 'name',
  order: prop('value', orderByTitle),
  limit,
  offset,
  search,
  success: isNotMember ? setYouCanJoinDirectChannels : setMatchedDirectChannels,
  notMember: isNotMember,
});

const onChangeSearchHandler = ({ setFieldValue, setFieldValueWithSubmit }) => (e) => {
  setFieldValue('search', e.target.value);
  debounceFunc(setFieldValueWithSubmit, 800, false, 'setFieldValueWithSubmit');
};

const setFieldValueWithSubmit = ({
  setFieldValue, values, getChannelsRequest, resetPagination: resetPages,
  setPagination, pagination,
}) => (name, value) => {
  setFieldValue(name, value);
  const newValues = {
    ...values,
    [name]: value,
  };
  const isNotMember = equals(pathOr(0, ['type', 'value'], newValues), CHANNELS_YOU_CAN_JOIN);
  setPagination({ ...pagination, offset: 0 });
  getChannelsRequest(null, getObjectForSend({
    search: newValues.search,
    orderByTitle: newValues.orderByTitle,
    ...pagination,
    offset: 0,
    isNotMember,
  }));
  resetPages();
};

const mapStateToProps = state => ({
  directChannelsList: messengerSelectors.getMatchedDirectChannelsList(state),
  // todo: matched must has name BelongTo
  directChannelsCount: messengerSelectors.getMatchedDirectChannelsCount(state),
  channelsYouCanJoinList: messengerSelectors.getYouCanJoinDirectChannelsList(state),
  channelsYouCanJoinCount: messengerSelectors.getYouCanJoinDirectChannelsCount(state),
  entities: messengerSelectors.getDirectChannels(state),
});

const mapDispatchToProps = {
  getChannelsRequest: getDirectChannelsRequest,
  resetPagination,
  changeRightSidebarStatus: uiActions.changeRightSidebarStatus,
};

const onPageChangeHandler = ({
  setPagination,
  pagination, getChannelsRequest, paginationRequestParams, values,
}) => (meta) => {
  const nextPagination = { ...pagination, ...meta };
  setPagination(nextPagination);
  const isNotMember = equals(pathOr(0, ['type', 'value'], values),
    CHANNELS_YOU_CAN_JOIN);
  getChannelsRequest(null, getObjectForSend({
    ...paginationRequestParams,
    ...nextPagination,
    isNotMember,
  }));
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withUrlParams({}),
  withTranslation(['common', 'chat']),
  withState('pagination', 'setPagination', { limit: 10, offset: 0 }),
  withPagination({
    limit: () => LIMIT,
    offset: ({ getUrlParam }) => calculateOffset(getUrlParam(['page'], 1), LIMIT),
    page: ({ getUrlParam }) => getUrlParam(['page'], 1),
  }),
  withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({
      search: '',
      type: {
        value: 1,
      },
      sortBy: 0,
    }),
    validationSchema: rules,
    handleSubmit: (
      { search, orderByTitle },
      { props: { getChannelsRequest, setChannels, pagination } },
    ) => {
      getChannelsRequest(
        null,
        getObjectForSend({
          search,
          orderByTitle,
          setChannels,
          ...pagination,
        }),
      );
    },
  }),
  withProps(
    ({
      directChannelsCount,
      directChannelsList,
      channelsYouCanJoinList,
      channelsYouCanJoinCount,
      values: { search, orderByTitle, type },
    }) => ({
      result: ifElse(
        prop('value'),
        always(channelsYouCanJoinList),
        always(directChannelsList),
      )(type),
      countEntities: ifElse(
        prop('value'),
        always(channelsYouCanJoinCount),
        always(directChannelsCount),
      )(type),
      paginationRequestParams: getObjectForSend({ name: search, orderByTitle }),
      title: 'Direct channels',
    }),
  ),
  withHandlers({
    setFieldValueWithSubmit,
  }),
  withHandlers({
    onPageChange: onPageChangeHandler,
    onChangeSearchHandler,
  }),
  withContext(
    {
      values: PropTypes.instanceOf(Object),
      setFieldValue: PropTypes.func,
    },
    ({ values, setFieldValueWithSubmit: setFieldValue }) => ({
      values,
      setFieldValue,
    }),
  ),
  lifecycle({
    componentDidMount() {
      const {
        getChannelsRequest,
        values: { search, orderByTitle },
        pagination,
        changeRightSidebarStatus,
      } = this.props;
      getChannelsRequest(
        null,
        getObjectForSend({
          search,
          orderByTitle,
          isNotMember: true,
          ...pagination,
        }),
      );
      changeRightSidebarStatus(false);
    },
  }),
);

export default enhance(Directs);
