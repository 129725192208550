import {
  compose, withHandlers, withProps, getContext, branch, renderNothing, lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import {
  __, prop, propOr, pathOr, isEmpty, path,
} from 'ramda';
import EditContactModal from './editContactModal';
import { uiActions, uiSelectors } from '../../../../state/ui';
import { contactsSelectors, contactsActions } from '../../../../state/contacts';
import rules from '../rules';
import withAutocomplete from '../../../../utils/enchancers/withAutocomplete';
import { leadsActions, leadsSelectors } from '../../../../state/leads';
import { clientsActions, clientsSelectors } from '../../../../state/clients';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { callNotification } from '../../../../utils/helpers/notifies';
import { customFieldsActions, customFieldsSelectors } from '../../../../state/customFields';
import { customFields } from '../../../../constants';

const mapStateToProps = (state, { selectedContactId }) => ({
  contact: contactsSelectors.getContactById(state)(selectedContactId)
      || leadsSelectors.getLeadContactSelector(state)(selectedContactId)
      || clientsSelectors.getClientContactSelector(state)(selectedContactId),
  isOpen: uiSelectors.getModal(state)('editContactModal'),
  contactRequestEnded: contactsSelectors.getContactsPendingRequest(state),
  customFieldEntities: customFieldsSelectors.getCustomFieldsEntities(state),
  // eslint-disable-next-line max-len
  customFieldsList: customFieldsSelectors.getCustomFieldsByModel(state)(customFieldsSelectors.getCustomFieldsList(state), customFields.CUSTOM_FIELDS_MODELS.CONTACT),
});

const mapDispatchToProps = {
  closeModal: () => uiActions.closeModal('editContactModal'),
  editContact: contactsActions.editContactRequest,
  editClientContactSuccess: clientsActions.editClientContactSuccess,
  editCustomFieldValue: customFieldsActions.editCustomFieldValueRequest,
  getCustomFields: customFieldsActions.getCustomFieldsRequest,
};

const onCloseModalHandler = ({
  closeModal, setSelectedContactId,
}) => () => {
  closeModal();
  setSelectedContactId(null);
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ contact }) => !contact, renderNothing),
  withProps(({ contact, customFieldEntities }) => {
    const getPropFromContact = propOr('', __, contact);
    let initialCustomValues = {};
    if (contact.customFields) {
      contact.customFields.forEach((customValue) => {
        const customFieldTitle = path([customValue.field_id, 'title'], customFieldEntities);
        initialCustomValues = { ...initialCustomValues, [customFieldTitle]: customValue.value };
      });
    }
    const parentContact = propOr(propOr(null, 'parent', contact), 'client', contact);
    const clientAutocompleteValue = parentContact && !isEmpty(parentContact)
      ? {
        value: parentContact.id,
        label: getFullName(propOr(null, 'contact', parentContact)),
      } : undefined;
    return {
      initialValues: ({
        first_name: getPropFromContact('first_name'),
        last_name: getPropFromContact('last_name'),
        email: getPropFromContact('email'),
        phone: getPropFromContact('phone'),
        client_id: clientAutocompleteValue,
        avatar: getPropFromContact('avatar'),
        ...initialCustomValues,
      }),
      parentContact,
      customFields: propOr(null, 'customFields', contact),
      contact,
    };
  }),
  getContext({
    setSelectedContactId: PropTypes.func.isRequired,
    setIsNeedRefresh: PropTypes.func.isRequired,
  }),
  withHandlers({
    onCloseModal: onCloseModalHandler,
  }),
  withFormik({
    mapPropsToValues: ({ initialValues }) => initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: rules,
    handleSubmit: (formData, {
      resetForm, props: {
        editContact,
        onCloseModal,
        selectedContactId,
        setIsNeedRefresh,
        onSuccess,
        initialValues,
        contact,
        parentContact,
        customFieldsList,
        customFieldEntities,
        editCustomFieldValue,
      },
    }) => {
      const clientIdInitial = pathOr(null, ['client_id', 'value'], initialValues);
      const clientIdFormData = pathOr(null, ['client_id', 'value'], formData);
      const clientId = !contact.client && clientIdFormData === clientIdInitial
        ? null : clientIdFormData;
      const customValuesList = [];
      customFieldsList.forEach((fieldId) => {
        const customValues = { model_id: selectedContactId, field_id: fieldId };
        const customFieldTitle = path([fieldId, 'title'], customFieldEntities);
        const customFieldValue = prop(`${customFieldTitle}`, formData);
        customValuesList.push({ ...customValues, value: customFieldValue });
      });
      if (parentContact.contact_id === selectedContactId) {
        callNotification.error('Selected contact is already a lead or a client');
      } else {
        editCustomFieldValue({ customFieldsData: customValuesList });
        editContact({
          id: selectedContactId,
          ...formData,
          client_id: clientId,
          avatar: formData.avatar || null,
        }, {
          callbacks: {
            success: () => {
              onCloseModal();
              resetForm({});
              if (setIsNeedRefresh) {
                setIsNeedRefresh(true);
              }
              if (onSuccess) {
                onSuccess(clientId);
              }
            },
          },
        });
      }
    },
  }),
  withAutocomplete({
    name: 'getLeadsAutocomplete',
    action:
      (params, meta) => leadsActions.getLeadsAutocompleteRequest({ all: true, ...params }, meta),
    dataPath: prop('clients'),
    searchField: 'q',
  }),
  lifecycle({
    componentDidMount() {
      const { getCustomFields } = this.props;
      getCustomFields();
    },
  }),
);
export default enhance(EditContactModal);
