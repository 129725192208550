import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import {
  ConfirmModal,
} from '../../../../ui-kit';

const SendInvoiceModal = ({
  isOpen,
  isPending,
  onClose,
  invoice,
  onSend,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Send"
    onAction={onSend}
    onClose={onClose}
    title="Send invoice"
  >
    Are you sure you want to send invoice <b>{propOr('', 'number', invoice)}</b>?
  </ConfirmModal>
);

SendInvoiceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  invoice: PropTypes.instanceOf(Object).isRequired,
  onSend: PropTypes.func.isRequired,
};

export default SendInvoiceModal;
