import React from 'react';

import ModalView from '../modalView';

const EditPipelineModal = props => (
  <ModalView
    title="Edit pipeline"
    titleAction="Save"
    {...props}
  />
);

export default EditPipelineModal;
