import {
  fork, put, select, take,
} from 'redux-saga/effects';
import {
  compose, dec, flip, inc, includes, keys, pickBy, prop, reduce, sort, uniq, values, identity,
} from 'ramda';
import { customFieldsActions, customFieldsTypes, customFieldsSelectors } from '.';
import { map } from '../../utils/helpers/commonHelpers';
import { sagasManager } from '../../utils';

const sortByParamPriority = (a, b) => a.priority - b.priority;

const getPriorityAfterReorder = (idDragItem, idCurrentItem, currentPriority, dragPriorityOld,
  dragPriorityNew) => {
  if (idCurrentItem === idDragItem) return dragPriorityNew;
  if (currentPriority < dragPriorityOld
    && currentPriority >= dragPriorityNew) return inc(currentPriority);
  if (currentPriority > dragPriorityOld
    && currentPriority <= dragPriorityNew) return dec(currentPriority);
  return currentPriority;
};
const updateFieldsByPriority = (idItem, oldPriority, newPriority) => list => reduce((accum,
  {
    id, priority, model_name, title, created_at, updated_at,
  }) => ([...accum, {
  id,
  model_name,
  title,
  created_at,
  updated_at,
  priority: getPriorityAfterReorder(idItem, id, priority, oldPriority, newPriority),
}]), [], list);

function getDataForDragCustomFieldFlow(payload, state, modelName) {
  const fieldsList = customFieldsSelectors.getCustomFieldsList(state);
  const entities = customFieldsSelectors.getCustomFieldsEntities(state);
  const fieldsListByModel = customFieldsSelectors.getCustomFieldsByModel(state)(fieldsList,
    modelName);
  const fieldsEntities = compose(pickBy(compose(flip(includes)(fieldsListByModel), prop('id'))))(entities);
  return {
    fieldsList,
    entities,
    fieldsListByModel,
    fieldsEntities,
  };
}

function* dragCustomFieldFlow() {
  while (true) {
    try {
      const { payload } = yield take(customFieldsTypes.ON_DRAG_CUSTOM_FIELD);
      const state = yield select(identity);
      const {
        model_name: modelName, draggableId, destination: { index }, source: { index: oldIndex },
      } = payload;
      const {
        entities, fieldsEntities, fieldsList,
      } = yield getDataForDragCustomFieldFlow(payload, state, modelName);
      const fieldsByPriority = yield compose(
        sort(sortByParamPriority),
        values,
      )(fieldsEntities);
      const fieldsByPriorityNew = yield updateFieldsByPriority(draggableId,
        inc(oldIndex),
        inc(index))(fieldsByPriority);
      const fieldsData = {
        data: {
          result: uniq([...compose(uniq, map(prop('id')), sort(sortByParamPriority), values)(fieldsByPriorityNew), ...fieldsList]),
          entities: {
            customFields: {
              ...entities,
              ...reduce((accum, key) => ({
                ...accum,
                [fieldsByPriorityNew[key].id]: fieldsByPriorityNew[key],
              }), {}, keys(fieldsByPriorityNew)),
            },
          },
        },
      };
      yield put(customFieldsActions.updateCustomFieldsPriorityRequest({
        customFields: fieldsByPriorityNew,
      }));
      yield put(customFieldsActions.getCustomFieldsSuccess(fieldsData));
    } catch (e) {
      yield put(customFieldsActions.updateCustomFieldsPriorityError());
    }
  }
}


sagasManager.addSagaToRoot(function* watcher() {
  yield fork(dragCustomFieldFlow);
});
