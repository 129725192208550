import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import SprintControls from './sprintControls';
import { withWindowWidth } from '../../../../utils/enchancers';
import { withPermissionHandlers } from '../../../../utils/helpers/permissionHelpers';

const enhance = compose(
  withWindowWidth(),
  withTranslation(['common']),
  withPermissionHandlers,
);
export default enhance(SprintControls);
