import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as yup from 'yup';
import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';
import { prop } from 'ramda';

import { helpers } from '../../../../../../utils';
import { closeModal } from '../../../../../../state/ui/actions';
import { getModal } from '../../../../../../state/ui/selectors';
import { messengerActions } from '../../../../../../state/messenger';
import { usersActions, usersSelectors } from '../../../../../../state/users';
import CreateChannel from './createChannel';
import withAutocomplete from '../../../../../../utils/enchancers/withAutocomplete';
import { addChannelPending } from '../../../../../../state/messenger/selectors';

const { translateHelpers } = helpers;
const { addChannel } = messengerActions;
const { getUsersListAutocompleteRequest, setUsersListAutocomplete } = usersActions;
const { getUsersForSelect } = usersSelectors;


const onClose = ({ resetForm, onCloseModal }) => () => {
  resetForm();
  onCloseModal();
};
const submitForm = (formValues, { props: { addChannelRequest, onCloseModal, pushToChannel } }) => {
  const {
    title,
    privacy,
    members,
    description,
  } = formValues;
  const params = {
    name: title,
    is_public: privacy === 'public',
    members: members.map(member => member.value),
    description,
  };
  addChannelRequest({ params }, {
    callbacks: {
      success: ({ channel: { id } }) => {
        pushToChannel(`/messenger/${id}`);
        onCloseModal();
      },
    },
  });
};

const rules = yup.object().shape({
  title: yup
    .string()
    .required({
      field: 'title',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Title'),
      },
    }),
  privacy: yup
    .string()
    .required({
      field: 'privacy',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Privacy'),
      },
    }),
  members: yup
    .array(),
  description: yup
    .string()
    .required({
      field: 'description',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Description'),
      },
    }),
});

const mapStateToProps = state => ({
  usersList: getUsersForSelect(state),
  isOpen: getModal(state)('createChannelModal'),
  isPending: addChannelPending(state),
});

const mapDispatchToProps = ({
  addChannelRequest: addChannel,
  getUsersAutocomplete: getUsersListAutocompleteRequest,
  setUsersAutocomplete: setUsersListAutocomplete,
  pushToChannel: push,
  onCloseModal: () => closeModal('createChannelModal'),
});


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common', 'validation']),
  withFormik({
    mapPropsToValues: () => ({
      title: '',
      privacy: 'public',
      members: [],
      description: '',
    }),
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: submitForm,
  }),
  withAutocomplete({
    name: 'getUsersAutocomplete',
    action: usersActions.getUsersListAutocompleteRequest,
    dataPath: prop('users'),
    searchField: 'q',
  }),
  withHandlers({
    onClose,
  }),
);
export default enhance(CreateChannel);
