import {
  compose, lifecycle, withContext, withHandlers, withState,
} from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Clients from './clients';
import { withWindowWidth } from '../../../../utils/enchancers';
import { uiActions } from '../../../../state/ui';

const mapDispatchToProps = {
  openModal: uiActions.openModal,
  setAppTitle: uiActions.setAppTitle,
};

const onCreateClientModalHandler = ({
  openModal,
}) => () => {
  openModal('createClientModal');
};


const enhance = compose(
  connect(null, mapDispatchToProps),
  withWindowWidth(),
  withTranslation(['common', 'chat']),
  withState('selectedClientId', 'setSelectedClientId', null),
  withState('selectedPinnedClientId', 'setSelectedPinnedClientId', null),
  withContext({
    setSelectedClientId: PropTypes.func,
    selectedClientId: PropTypes.any,
    setSelectedPinnedClientId: PropTypes.func,
    selectedPinnedClientId: PropTypes.any,
  }, ({
    setSelectedClientId,
    selectedClientId,
    setSelectedPinnedClientId,
    selectedPinnedClientId,
  }) => ({
    setSelectedClientId,
    selectedClientId,
    setSelectedPinnedClientId,
    selectedPinnedClientId,
  })),
  withHandlers({
    onCreateClientModal: onCreateClientModalHandler,
  }),
  lifecycle({
    componentDidMount() {
      this.props.setAppTitle('Clients - CRM - Avanga 2.0');
    },
  }),
);

export default enhance(Clients);
