import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ShowMoreLazyLoad, TabWrapper } from '../../../../ui-kit';
import { ProjectNavItem } from './projectNavItem';
import { projectsActions, projectsSelectors } from '../../../../state/projects';
import api from '../../../../api/projects.service';
import './style.sass';

/**
 * @returns {JSX.Element}
 * @constructor
 */

const PROJECTS_LIMIT = 10;
const SORT_BY_FAVORITE = { sortBy: 'favorite', order: 'desc' };

export const ProjectsNav = () => {
  const [isProjectsTabOpen, setIsProjectsTabOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const projectListResult = useSelector(projectsSelectors.getProjectsListMenu);
  const projectListEntities = useSelector(projectsSelectors.getProjects);
  const projectListHasMore = useSelector(projectsSelectors.getProjectsListMenuHasMore);
  const projectCount = useSelector(projectsSelectors.getProjectsListMenuCount);
  const projectListPending = useSelector(projectsSelectors.getProjectsListMenuPending);

  const changeProjectsTabStatus = useCallback(() => setIsProjectsTabOpen(!isProjectsTabOpen));
  const projectListRequest = useCallback(({ ...params }) => {
    dispatch(projectsActions.getProjectsListMenuRequest({ ...SORT_BY_FAVORITE, ...params }));
  });
  const addToFavoriteProjectRequest = useCallback(async (id) => {
    const data = await api.addFavoriteProject(id);
    if (data.success) {
      return dispatch(projectsActions.setProjectsFavoriteCustom(id));
    }
    return null;
  });
  const removeFromFavoriteProjectRequest = useCallback(async (id) => {
    const data = await api.removeFavoriteProject(id);
    if (data.success) {
      return dispatch(projectsActions.removeProjectsFavoriteCustom(id));
    }
    return null;
  });

  const handlerFavorite = useCallback((status, id) => {
    if (status) {
      return removeFromFavoriteProjectRequest(id);
    }
    return addToFavoriteProjectRequest(id);
  });
  return (
    <div className="nav-project">
      <nav className="nav-project__nav">
        <TabWrapper
          title="Projects"
          onChangeStatusTab={changeProjectsTabStatus}
          defaultStatusTab={isProjectsTabOpen}
        >
          <ShowMoreLazyLoad
            data={projectListResult}
            limit={PROJECTS_LIMIT}
            pending={projectListPending}
            handlerFetch={projectListRequest}
            hasMore={projectListHasMore}
            count={projectCount}
            emptyText={t('There are no projects yet')}
            renderItem={props => (
              <ProjectNavItem
                key={`project-nav__item${props.idItem}`}
                handlerFavorite={handlerFavorite}
                item={projectListEntities[props.idItem]}
              />
            )}
          />
        </TabWrapper>
      </nav>
    </div>
  );
};
