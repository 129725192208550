import * as tasksActions from './actions';
import * as tasksSelectors from './selectors';
import * as tasksOperations from './operations';
import * as tasksSchemas from './schema';
import tasksTypes from './types';
import reducer from './reducers';

export {
  tasksActions,
  tasksSelectors,
  tasksOperations,
  tasksSchemas,
  tasksTypes,
};

export default reducer;
