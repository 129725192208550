import {
  compose, pure, withHandlers, withProps, withStateHandlers,
} from 'recompose';
import { withTranslation } from 'react-i18next';

import { equals } from 'ramda';
import { connect } from 'react-redux';
import { PIPELINE_TYPES, LEADS_VIEW } from '../../../../constants/crm';
import { withWindowWidth } from '../../../../utils/enchancers';
import Filters from './filters';
import { leadsActions, leadsSelectors } from '../../../../state/leads';

const mapStateToProps = state => ({
  checkedPipeline: leadsSelectors.getCheckedPipelineSelector(state),
});

const mapDispatchToProps = ({
  setCheckedPipeline: leadsActions.setCheckedPipeline,
});


const sortParams = {
  recent: {
    order: 'desc',
    sortBy: 'created_at',
  },
  old: {
    order: 'asc',
    sortBy: 'created_at',
  },
};

const onChangeSortingHandler = ({ onSortBy }) => ({ val: { order, sortBy } }) => {
  onSortBy({ order, sort: sortBy });
};

const onChangeSortingOnButtonHandler = ({ onSortBy, toggleMobileFiltersHandler }) => (e) => {
  const { target: { name } } = e;
  const { order, sortBy } = sortParams[name];
  onSortBy({ order, sort: sortBy });
  toggleMobileFiltersHandler();
};

const onChangeCurrentPipelineHandler = ({
  setCheckedPipeline,
  checkedPipeline,
}) => ({ val: { id } }) => {
  if (checkedPipeline !== id) {
    setCheckedPipeline(id);
  }
};

const getSortValue = (order, sort) => {
  if (equals('created_at', sort)) {
    return equals('desc', order) ? 0 : 1;
  }
  return null;
};

const getStatusValue = (status) => {
  const statusInt = parseInt(status, 10);
  if (!statusInt && statusInt !== PIPELINE_TYPES.ALL) {
    return PIPELINE_TYPES.IN_PROGRESS;
  }
  return statusInt;
};

const onSetLeadsViewHandler = view => ({ setLeadsView, leadsView }) => () => {
  if (leadsView !== view) {
    setLeadsView(view);
  }
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['tasks', 'common']),
  pure,
  withWindowWidth(),
  withProps(({
    order, sort, status, leadsView,
  }) => ({
    sortingByDate: getSortValue(order, sort),
    statusValue: getStatusValue(status),
    isPipeView: leadsView === LEADS_VIEW.PIPE,
  })),
  withStateHandlers({
    areMobileFiltersOpen: false,
  }, {
    toggleMobileFiltersHandler:
      ({ areMobileFiltersOpen }) => () => ({ areMobileFiltersOpen: !areMobileFiltersOpen }),
  }),
  withHandlers({
    onChangeSorting: onChangeSortingHandler,
    onChangeSortingOnButton: onChangeSortingOnButtonHandler,
    onChangeCurrentPipeline: onChangeCurrentPipelineHandler,
    onSetLeadViewPipe: onSetLeadsViewHandler(LEADS_VIEW.PIPE),
    onSetLeadViewList: onSetLeadsViewHandler(LEADS_VIEW.LIST),
  }),
);
export default enhance(Filters);
