import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { filterIn } from '../../../utils/helpers/crmHelpers/customFieldsHelper';
import { callNotification } from '../../../utils/helpers/notifies';
import { ButtonsCollection, FormsCollection } from '../../../ui-kit';
import SubmitAndCancel from '../SubmitAndCancel';
import BurgerDnd from './BurgerDnd';
import api from '../../../api/custom.service';
import ControlledTitleField from '../ControlledTitleField';

import rules from '../customFieldsForm/rules';


/**
 *
 * @param id {number}
 * @param title {string}
 * @param setIdToDelete {function}
 * @param customFieldsList {array}
 * @param setCustomFieldsList {function}
 * @returns {JSX.Element}
 *
 */

const FieldItem = ({
  id, title, setIdToDelete, customFieldsList, setCustomFieldsList,
}) => {
  const { t } = useTranslation('common');
  const [isPending, setIsPending] = useState(false);

  const [isInEditingMode, setIsInEditingMode] = useState(false);
  const toggleEditingMode = useCallback(() => setIsInEditingMode(prevState => !prevState), []);

  const { control, handleSubmit } = useForm({ resolver: yupResolver(rules) });

  const onDeleteCustomField = useCallback(() => setIdToDelete(id), [id]);

  const onSubmit = useCallback((formValues) => {
    const makeRequest = async () => {
      try {
        if (formValues.title !== title) {
          const updatedField = await api.updateName({ id, title: formValues.title });
          setCustomFieldsList(filterIn(customFieldsList, updatedField));
          callNotification.success(t('Custom field has been updated!'));
        }
        toggleEditingMode();
        setIsPending(false);
      } catch (e) {
        setIsPending(false);
        callNotification.error(t('Custom field has not been updated!'));
      }
    };
    setIsPending(true);
    makeRequest();
  }, [customFieldsList]);

  return (
    <FormsCollection.FormWrapper
      handleSubmit={handleSubmit(onSubmit)}
      className="custom-fields__row custom-fields__row--form-add status-item"
    >
      <div
        key={`field-${id}`}
        className={
          classNames(
            'custom-fields__row', 'field-item',
            { 'field-item--editing': isInEditingMode },
          )
        }
      >
        <div className="custom-fields__col">
          <BurgerDnd />
          {isInEditingMode ? (
            <ControlledTitleField control={control} defaultValue={title} />
          ) : (
            <h4 className="field-item__title">{title}</h4>
          )}
        </div>
        <div className="custom-fields__col">
          {isInEditingMode ? (
            <SubmitAndCancel onCancel={toggleEditingMode} isPending={isPending} />) : (
            <>
              <ButtonsCollection.ButtonIcons
                onClick={toggleEditingMode}
                title={t('Edit')}
              >
                <span className="icon-edit-icon" />
              </ButtonsCollection.ButtonIcons>
              <ButtonsCollection.ButtonIcons
                onClick={onDeleteCustomField}
                title={t('Delete')}
              >
                <span className="icon-trash" />
              </ButtonsCollection.ButtonIcons>
            </>
          )}
        </div>
      </div>
    </FormsCollection.FormWrapper>
  );
};

FieldItem.defaultProps = {
  setIdToDelete: () => {},
  customFieldsList: [],
  setCustomFieldsList: () => {},
};

export default FieldItem;
