import React from 'react';
import PropTypes from 'prop-types';
import { DropDown, FormsCollection } from '../../../../ui-kit';
import './style.sass';

const Filters = ({
  searchName, setSearchClientName, onSearch, onChangeSorting, sortingByDate, t,
}) => (
  <div className="clients-filter">
    <div className="clients-filter__sort-group">
      <FormsCollection.Search
        className="search-field--circle"
        placeholder={t('Search by client name, company')}
        name="search-client"
        value={searchName}
        onChange={setSearchClientName}
        onSearch={onSearch}
        withoutControl
      />
      <DropDown
        label={`${t('Sort')} : `}
        placeholder="Custom"
        name="filter-row"
        onChange={onChangeSorting}
        currentItem={sortingByDate}
        list={
            [
              {
                id: 0,
                order: 'desc',
                sortBy: 'date_converted_to_client',
                label: t('Recent'),
              },
              {
                id: 1,
                order: 'asc',
                sortBy: 'date_converted_to_client',
                label: t('Old'),
              },
            ]
          }
      />
    </div>
  </div>
);

Filters.propTypes = {
  searchName: PropTypes.string.isRequired,
  setSearchClientName: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onChangeSorting: PropTypes.func.isRequired,
  sortingByDate: PropTypes.number,
  t: PropTypes.func.isRequired,
};

Filters.defaultProps = {
  sortingByDate: null,
};

export default Filters;
