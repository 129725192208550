import {
  compose,
  getContext,
  lifecycle,
  withContext,
  withHandlers,
  withProps,
  withState,
  withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import {
  anyPass, assoc, concat, curry, equals, includes, isEmpty, map, mergeAll, prop, unapply, without,
} from 'ramda';
import PropTypes from 'prop-types';
import { notEqual } from 'ramda-extension';
import { clientsActions, clientsSelectors } from '../../state/clients';
import ClientsContainer from './clientsContainer';
import { setFilterForSingleParam } from '../../utils/helpers/uiComponentHelpers/filters';
import {
  withFilters, withPagination, withRefs, withSorting, withUrlParams,
} from '../../utils/enchancers';
import { calculateOffset } from '../../utils/helpers/uiComponentHelpers/pagination';
import { getOppositeValue } from '../../utils/helpers/commonHelpers';
import { uiActions } from '../../state/ui';
import { START_PAGE_NUMBER } from '../../constants/crm';

const LIMIT = 10;

const defaultSorting = {
  order: 'desc',
  sortBy: 'date_converted_to_client',
};

const mapDispatchToProps = ({
  getClientsRequest: clientsActions.getClientsRequest,
  deleteClient: clientsActions.deleteClientRequest,
  closeModal: uiActions.closeModal,
  getPinnedClientsRequest: clientsActions.getPinnedClientsRequest,
  getClientTasks: clientsActions.getClientTasksRequest,
  getPinnedClients: clientsActions.getPinnedClientsRequest,
  setErrorPage: uiActions.setErrorPage,
});

const mapStateToProps = state => ({
  clients: clientsSelectors.getClientsListSelector(state),
  clientsCount: clientsSelectors.getClientsCountSelector(state),
  clientOffset: clientsSelectors.getClientsOffsetSelector(state),
});

const makeSortingParams = curry((params, sort, order) => {
  const assocIfTruthy = curry((property, value, obj) => (
    value ? assoc(property, value, obj) : obj));
  return compose(
    assocIfTruthy('order', order),
    assocIfTruthy('sort', sort),
  )(params);
});

const getSortingParamsForRequest = (sort, order) => {
  const isSortEqualTo = compose(
    anyPass,
    unapply(map(equals(sort))),
  );
  return isSortEqualTo('date_converted_to_client', 'name') ? makeSortingParams({}, sort, order) : defaultSorting;
};


const getClients = ({
  getClientsRequest, sort, order, pagination, filters,
}, meta = {}) => compose(
  getClientsRequest,
  unapply(mergeAll),
)(getSortingParamsForRequest(sort, order), pagination, filters, meta);

const onPageChangeHandler = ({
  getClientsRequest, pagination, sort, order, filters,
}) => meta => getClients({
  getClientsRequest, pagination, sort, order, filters,
}, meta);

const setSearchClientNameHandler = ({ setFilters }) => ({ target: { value } }) => setFilters({
  name: value,
});

const onSearchHandler = ({
  onSetUrlParam, onResetUrlParam, searchName, mergeFilters, setFilters, setIsNeedRefresh,
}) => () => {
  const setSearchNameFilterAndUrl = setFilterForSingleParam(mergeFilters, setFilters);
  if (isEmpty(searchName)) {
    setSearchNameFilterAndUrl(onResetUrlParam, 'name', null);
  } else {
    setSearchNameFilterAndUrl(onSetUrlParam, 'name', searchName);
  }
  return setIsNeedRefresh(true);
};

const onChangeFilterStatusHandler = ({
  setFilters,
  mergeFilters,
  setIsNeedRefresh,
  onSetUrlParam,
}) => ({ val: { value } }) => {
  setFilterForSingleParam(mergeFilters, setFilters, onSetUrlParam, 'status', value);
  return setIsNeedRefresh(true);
};


const onSuccessCreateClientHandler = ({
  setIsNeedRefresh,
}) => () => setIsNeedRefresh(true);

const onDeleteClientHandler = ({
  deleteClient,
  selectedClientId,
  closeModal,
  setSelectedClientId,
  sort, order, pagination, filters,
  getPinnedClients,
}) => () => {
  deleteClient({ id: selectedClientId }, {
    ...getSortingParamsForRequest(sort, order),
    ...pagination,
    ...filters,
    callbacks: {
      success: () => {
        getPinnedClients();
      },
    },
  });
  setSelectedClientId(null);
  closeModal('deleteClientModal');
};

const getActiveTaskSelector = ({ selectedClientTask }) => state => (
  clientsSelectors.getClientTaskSelector(state)(selectedClientTask)
);

const refreshPageHandler = ({ setIsNeedRefresh }) => () => setIsNeedRefresh(true);

const onSuccessEditFullTaskHandler = ({ selectedClientId: id, getClientTasks }) => () => {
  getClientTasks({ id });
};

const enhance = compose(
  getContext({
    setSelectedClientId: PropTypes.func.isRequired,
    selectedClientId: PropTypes.any,
    setSelectedPinnedClientId: PropTypes.func.isRequired,
    selectedPinnedClientId: PropTypes.any,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withState('isNeedRefresh', 'setIsNeedRefresh', false),
  withState('selectedSort', 'setSelectedSort', {}),
  withState('selectedClientTask', 'setSelectedClientTask', null),
  withUrlParams({}),
  withFilters({
    initial: ({ getUrlParam }) => ({
      name: getUrlParam(['name']),
    }),
  }),
  withProps(({ getFilter }) => ({
    searchName: getFilter('', 'name'),
  })),
  withPagination({
    limit: () => LIMIT,
    offset: ({ getUrlParam }) => calculateOffset(getUrlParam(['page'], 1), LIMIT),
    page: ({ getUrlParam }) => getUrlParam(['page'], 1),
  }),
  withSorting({
    selector: props => props.getUrlParams({}),
    values: ({
      order: ({ getUrlParam }) => getUrlParam(['order'], 'desc'),
      sort: ({ getUrlParam }) => getUrlParam(['sort'], 'date_converted_to_client'),
    }),
    onSortBy: ({ order, onSetUrlParam, setIsNeedRefresh }) => (dataSorting) => {
      const sort = prop('sort', dataSorting);
      const newOrder = notEqual(sort, 'date_converted_to_client') ? getOppositeValue('asc', 'desc', order) : prop('order', dataSorting);
      onSetUrlParam({ sort, order: newOrder });
      setIsNeedRefresh(true);
    },
  }),
  withStateHandlers(() => ({ openItemIds: [] }), ({
    onSetOpenId: ({ openItemIds }) => id => ({
      openItemIds: includes(id, openItemIds)
        ? without([id], openItemIds)
        : concat([id], openItemIds),
    }),
  })),
  withRefs(),
  withHandlers({
    onPageChange: onPageChangeHandler,
    setSearchClientName: setSearchClientNameHandler,
    onSearch: onSearchHandler,
    onSuccessCreateClient: onSuccessCreateClientHandler,
    onDeleteClient: onDeleteClientHandler,
    getActiveTaskSelector,
    onSuccessEditFullTask: onSuccessEditFullTaskHandler,
    onChangeFilterStatus: onChangeFilterStatusHandler,
    refreshPage: refreshPageHandler,
  }),
  withProps(({ getRef }) => ({
    scrollWrapperRef: getRef('scroll-wrapper'),
  })),
  withContext({
    setIsNeedRefresh: PropTypes.func.isRequired,
    setSelectedClientTask: PropTypes.func.isRequired,
    onSetActiveClientRef: PropTypes.func,
    setClientsView: PropTypes.func,
    selectedClientTask: PropTypes.any,
    scrollWrapperRef: PropTypes.any,
  }, ({
    setIsNeedRefresh, setSelectedClientTask, selectedClientTask, scrollWrapperRef,
    setClientsView,
  }) => ({
    setIsNeedRefresh,
    setSelectedClientTask,
    selectedClientTask,
    scrollWrapperRef,
    setClientsView,
  })),
  lifecycle({
    componentDidMount() {
      getClients(this.props);
      this.props.getPinnedClientsRequest();
    },
    componentDidUpdate(prevProps) {
      const { isNeedRefresh: prevIsNeedRefresh, clientOffset: prevClientOffset } = prevProps;
      const {
        isNeedRefresh, setIsNeedRefresh, clients, selectedPinnedClientId, setSelectedClientId,
        clientOffset, onSetUrlParam,
      } = this.props;

      if (clientOffset !== prevClientOffset) {
        onSetUrlParam({ page: START_PAGE_NUMBER + clientOffset / LIMIT });
      }
      if (selectedPinnedClientId && !clients.includes(selectedPinnedClientId)) {
        getClients(this.props, { selectedPinnedClientId });
        setSelectedClientId(selectedPinnedClientId);
      }
      if (isNeedRefresh && prevIsNeedRefresh !== isNeedRefresh) {
        setIsNeedRefresh(false);
        getClients(this.props);
      }
    },
  }),
);

export default enhance(ClientsContainer);
