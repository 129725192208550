import {
  compose, withProps, withStateHandlers,
} from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ifElse, includes, equals } from 'ramda';

import NotificationPreferences from './notificationPreferences';
import { messengerActions, messengerSelectors } from '../../../../state/messenger';

const mapStateToProps = state => ({
  muteChannelsList: messengerSelectors.getMuteChannelsList(state),
});

const mapDispatchToProps = {
  onSetMuteChannel: messengerActions.setMuteChannel,
  onDeleteMuteChannel: messengerActions.deleteMuteChannel,
};

const onToggleMuteStateHandler = (state, {
  channel, onSetMuteChannel, onDeleteMuteChannel,
}) => ({ target: { checked } }) => {
  ifElse(equals(true),
    () => onSetMuteChannel({ channelId: channel.id }),
    () => onDeleteMuteChannel({ channelId: channel.id }))(checked);
  return { status: checked };
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common', 'chat']),
  withProps(({ channel, muteChannelsList }) => ({
    isMute: includes(channel.id, muteChannelsList),
  })),
  withStateHandlers(({ isMute }) => ({ status: isMute }), {
    onToggleMute: onToggleMuteStateHandler,
  }),
);
export default enhance(NotificationPreferences);
