import {
  compose, lifecycle, withContext, withHandlers, withProps, withState,
} from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import {
  equals, find, isEmpty, map, multiply, props,
  reduce, sum, values as Rvalues, propOr,
} from 'ramda';
import moment from 'moment';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  INVOICES_PAY_TYPES, INVOICE_STATUS_IDS, INVOICES_TERMS, CRM_ROUTES,
} from '../../constants/crm';
import NewInvoicePage from './newInvoicePage';
import { invoicesActions, invoicesSelectors } from '../../state/invoices';
import { getApiUrl } from '../../utils/helpers/requestHelpers';
import { pendingSelectors } from '../../utils/middlewares/sagaRequestApi/state';
import { projectsActions, projectsSelectors } from '../../state/projects';
import { getFullName } from '../../utils/helpers/userHelpers';
import preloaderWhileLoading from '../../utils/enchancers/preloaderWhileLoading';
import { PRELOADER_DIMENSION } from '../../constants/ui';
import { randomNumberHelper } from '../../utils/helpers/commonHelpers';

const MIN_VALUE = 100000;
const MAX_VALUE = 999999;

const defaultDueDateValues = {
  [INVOICES_TERMS.END_OF_MONTH]: moment().endOf('month').toDate(),
  [INVOICES_TERMS.END_OF_NEXT_MONTH]: moment().add(1, 'month').endOf('month').toDate(),
};

const rules = yup.object().shape({
  client_id: yup.string().required({
    field: 'client_id',
    message: 'required',
    params: {
      key: 'Client',
    },
  }),
  project_id: yup
    .string()
    .required({
      field: 'project_id',
      message: 'required',
      params: {
        key: 'Project',
      },
    }),
  invoice_number: yup
    .string()
    .required({
      field: 'invoice_number',
      message: 'required',
      params: {
        key: 'Invoice number',
      },
    }),
  date: yup
    .date()
    .required({
      field: 'date',
      message: 'required',
      params: {
        key: 'Invoice date',
      },
    }),
  due_date: yup
    .date()
    .required({
      field: 'due_date',
      message: 'required',
      params: {
        key: 'Due date',
      },
    }),
  terms: yup
    .string()
    .required({
      field: 'terms',
      message: 'required',
      params: {
        key: 'Terms',
      },
    }),
});

const termsOptions = [
  {
    label: 'By end of the month',
    value: INVOICES_TERMS.END_OF_MONTH,
  },
  {
    label: 'By end of the next month',
    value: INVOICES_TERMS.END_OF_NEXT_MONTH,
  },
  {
    label: 'Custom',
    value: INVOICES_TERMS.CUSTOM,
  },
];

const formatClient = contact => (
  contact
    ? ({ value: contact.client_id, label: getFullName(contact) })
    : null
);
const formatProject = project => (project ? ({ value: project.id, label: project.title }) : null);

const getInitialValuesEdit = ({
  currentInvoice: {
    client_id, project_id, date, terms, due_date, number,
    currency, items, discount, tax, total, subtotal,
  },
  client_contact, project,
}) => ({
  // eslint-disable-next-line camelcase
  client_id: client_id ? formatClient(client_contact) : null,
  // eslint-disable-next-line camelcase
  project_id: project_id ? formatProject(project) : null,
  date: date ? new Date(date) : new Date(),
  terms: terms ? JSON.parse(terms) : find(termOption => equals(
    termOption.value, INVOICES_TERMS.END_OF_MONTH,
  ), termsOptions),
  // eslint-disable-next-line camelcase
  due_date: due_date ? new Date(due_date) : defaultDueDateValues[INVOICES_TERMS.END_OF_MONTH],
  invoice_number: number || '',
  currency: currency ? JSON.parse(currency) : { label: 'USD', value: '$' },
  items: items || [],
  discount: discount ? JSON.parse(discount) : { value: null, paymentType: 'PERCENT' },
  tax: tax ? JSON.parse(tax) : { value: null, paymentType: 'PERCENT' },
  total: total || '0.00',
  subtotal: subtotal || '0.00',
});

const getInitialValuesCreate = () => ({
  date: new Date(),
  terms: find(termOption => equals(termOption.value, INVOICES_TERMS.END_OF_MONTH), termsOptions),
  due_date: defaultDueDateValues[INVOICES_TERMS.END_OF_MONTH],
  invoice_number: '',
  currency: { label: 'USD', value: '$' },
});

// eslint-disable-next-line consistent-return
const calculateTax = (subtotal, tax, type) => {
  const { PERCENT, FLAT } = INVOICES_PAY_TYPES;
  if (equals(type, PERCENT)) return subtotal + (subtotal * (tax / 100));
  if (equals(type, FLAT)) return subtotal + parseFloat(tax);
};

// eslint-disable-next-line consistent-return
const calculateTotal = (withTax, discount, type) => {
  const { PERCENT, FLAT } = INVOICES_PAY_TYPES;
  if (equals(type, PERCENT)) return withTax - (withTax * (discount / 100));
  if (equals(type, FLAT)) return withTax - parseFloat(discount);
};

const mapDispatchToProps = {
  addInvoiceItem: invoicesActions.addInvoiceItem,
  addInvoiceRequest: invoicesActions.addInvoiceRequest,
  updateInvoiceRequest: invoicesActions.updateInvoiceRequest,
  getCurrentInvoice: invoicesActions.getCurrentInvoice,
  clearStore: invoicesActions.clearStore,
  getProjectsList: projectsActions.getProjectsListRequest,
  connectedPush: push,
};

const mapStateToProps = state => ({
  tax: invoicesSelectors.getTax(state),
  discount: invoicesSelectors.getDiscount(state),
  invoiceItemsEntities: invoicesSelectors.getInvoiceItemsEntities(state),
  taxPaymentType: invoicesSelectors.getTaxPaymentType(state),
  discountPaymentType: invoicesSelectors.getDiscountPaymentType(state),
  currencySign: invoicesSelectors.getCurrencySign(state),
  taxState: invoicesSelectors.getTaxState(state),
  discountState: invoicesSelectors.getDiscountState(state),
  isPendingCreate: pendingSelectors.getPendingRequest(state, 'addInvoiceRequest'),
  isPendingUpdate: pendingSelectors.getPendingRequest(state, 'updateInvoiceRequest'),
  currentInvoice: invoicesSelectors.getCurrentInvoice(state),
  isLoadingCurrentInvoice: pendingSelectors.getPendingRequest(state, 'getCurrentInvoice'),
  projects: projectsSelectors.getProjects(state),
});

const saveAsDraftHandler = ({
  invoice, addInvoiceRequest, validateForm, connectedPush,
}) => async () => {
  const errors = await validateForm();
  if (isEmpty(errors)) {
    addInvoiceRequest(invoice, {
      callbacks: {
        success: () => connectedPush(CRM_ROUTES.INVOICES),
      },
    });
  }
};

const saveAndSendHandler = ({
  invoice, addInvoiceRequest, validateForm, connectedPush,
}) => async () => {
  const errors = await validateForm();

  if (isEmpty(errors)) {
    addInvoiceRequest({ ...invoice, is_email: true }, {
      callbacks: {
        success: () => connectedPush(CRM_ROUTES.INVOICES),
      },
    });
  }
};

const updateAndSendHandler = ({
  invoice, updateInvoiceRequest, validateForm, match: { params: { invoiceId } }, connectedPush,
}) => async () => {
  const errors = await validateForm();

  if (isEmpty(errors)) {
    updateInvoiceRequest({ invoiceId, ...invoice, is_email: true }, {
      callbacks: {
        success: () => connectedPush(CRM_ROUTES.INVOICES),
      },
    });
  }
};

const generateInvoiceNumberHandler = ({ setGeneratedInvoiceNumber }) => async () => {
  await setGeneratedInvoiceNumber(randomNumberHelper(MIN_VALUE, MAX_VALUE, false));
  setGeneratedInvoiceNumber('');
};

const onCloseHandler = ({ setNoticeModal, validateForm }) => () => {
  setNoticeModal(false);
  validateForm();
};

const checkNoticeModalHandler = ({
  projects, invoice, setNoticeModal,
}) => () => {
  if (!Rvalues(projects).find(i => i.client_id === invoice.client_id)) {
    setNoticeModal(true);
  }
};

const updateInvoiceHandler = ({
  invoice, updateInvoiceRequest, validateForm, match: { params: { invoiceId } }, connectedPush,
}) => async () => {
  const errors = await validateForm();

  if (isEmpty(errors)) {
    updateInvoiceRequest({ invoiceId, ...invoice }, {
      callbacks: {
        success: () => connectedPush(CRM_ROUTES.INVOICES),
      },
    });
  }
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ currentInvoice }) => ({
    client_contact: propOr(null, 'client_contact', currentInvoice),
    project: propOr(null, 'project', currentInvoice),
  })),
  withRouter,
  withState('generatedInvoiceNumber', 'setGeneratedInvoiceNumber', null),
  withState('noticeModal', 'setNoticeModal', false),
  withTranslation(['common']),
  lifecycle({
    componentDidMount() {
      const {
        addInvoiceItem, getCurrentInvoice, isEditInvoicePage, match: { params }, getProjectsList,
      } = this.props;
      getProjectsList();

      if (!isEditInvoicePage) {
        addInvoiceItem({});
      }

      if (isEditInvoicePage) {
        getCurrentInvoice({ invoiceId: params.invoiceId });
      }
    },
    // componentDidUpdate({ values: { client_id: prevClientId } }) {
    //   const { values: { client_id }, getProjectsList } = this.props;
    //   if (!equals(prevClientId, client_id)) {
    //     getProjectsList({ client_id: client_id.value });
    //   }
    // },
    componentWillUnmount() {
      const { clearStore } = this.props;
      clearStore();
    },
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.MIDDLE,
    alignContainerCenter: true,
    delay: 100,
    isLoading: ({
      currentInvoice,
      isEditInvoicePage,
      projects,
    }) => isEditInvoicePage && (isEmpty(currentInvoice) || isEmpty(projects)),
  }),
  withFormik({
    // eslint-disable-next-line no-shadow
    mapPropsToValues: ({ isEditInvoicePage, ...props }) => (isEditInvoicePage
      ? getInitialValuesEdit(props)
      : getInitialValuesCreate(props)
    ),
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: rules,
  }),
  withContext({
    values: PropTypes.instanceOf(Object),
    errors: PropTypes.instanceOf(Object),
    touched: PropTypes.instanceOf(Object),
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func,
    defaultDueDateValues: PropTypes.instanceOf(Object),
    termsOptions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    currencyOptions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    addInvoiceItem: PropTypes.func,
  }, ({
    errors,
    touched,
    setFieldValue,
    values,
  }) => ({
    errors,
    values,
    touched,
    setFieldValue,
    termsOptions,
    defaultDueDateValues,
  })),
  withProps(({
    invoiceItemsEntities, tax, discount, taxPaymentType, discountPaymentType,
  }) => {
    const subtotal = sum(
      map(
        item => reduce(multiply, 1, map(parseFloat, props(['rate', 'quantity'], item))),
        Rvalues(invoiceItemsEntities),
      ),
    );
    const withTax = calculateTax(parseFloat(subtotal), tax ? parseFloat(tax) : tax, taxPaymentType);
    const total = calculateTotal(parseFloat(withTax), discount, discountPaymentType);
    return {
      subtotal: (subtotal || 0),
      total: (total || 0),
    };
  }),
  withProps(({
    values: {
      invoice_number, client_id, project_id, date, due_date, ...rest
    }, invoiceItemsEntities,
    total, subtotal, taxState, discountState,
  }) => ({
    invoice: {
      ...rest,
      date: moment(date).format('YYYY-MM-DD HH:mm:ss'),
      due_date: moment(due_date).format('YYYY-MM-DD HH:mm:ss'),
      number: invoice_number,
      client_id: propOr(null, 'value', client_id),
      project_id: propOr(null, 'value', project_id),
      status_id: INVOICE_STATUS_IDS.DRAFT,
      items: compose(
        map(({ rate, quantity, ...item }) => {
          const floatRate = parseFloat(rate) || '';
          const floatQuantity = parseFloat(quantity) || '';

          return {
            ...item,
            rate: floatRate,
            quantity: floatQuantity,
            amount: floatRate * floatQuantity,
          };
        }),
        Rvalues,
      )(invoiceItemsEntities),
      total,
      subtotal,
      tax: taxState,
      discount: discountState,
    },
  })),
  withHandlers({
    saveAsDraft: saveAsDraftHandler,
    saveAndSend: saveAndSendHandler,
    updateAndSend: updateAndSendHandler,
    updateInvoice: updateInvoiceHandler,
    generateInvoiceNumber: generateInvoiceNumberHandler,
    onClose: onCloseHandler,
    checkNoticeModal: checkNoticeModalHandler,
  }),
  withProps(({
    invoice, subtotal, total, values, generatedInvoiceNumber, isEditInvoicePage,
  }) => {
    if (!isEditInvoicePage) {
      if (generatedInvoiceNumber) {
        // eslint-disable-next-line no-param-reassign
        values.invoice_number = generatedInvoiceNumber;
      }
    }
    return {
      previewInvoiceUrl: `${getApiUrl(window.location)}/invoices/preview?data=${JSON.stringify(invoice)}`,
      downloadInvoiceUrl: `${getApiUrl(window.location)}/invoices/preview?is_download=1&data=${JSON.stringify(invoice)}`,
      subtotal: (subtotal || 0).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      total: (total || 0).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      values,
    };
  }),
);

export default enhance(NewInvoicePage);
