import React, { memo } from 'react';

import './style.sass';

/**
 *
 * @param children {string | element}
 * @param disabled {boolean}
 * @param className {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const ButtonText = memo(({
  children = 'Submit',
  disabled = false,
  className = '',
}) => (
  <button disabled={disabled} type="submit" className={`button-text ${className}`}>
    {children}
  </button>
));
