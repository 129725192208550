import {
  compose, withState, withContext, lifecycle,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { withWindowWidth } from '../../utils/enchancers';
import { openModal, setAppTitle } from '../../state/ui/actions';
import MyTasks from './myTasks';

const mapDispatchToProps = {
  setOpenModal: openModal,
  setAppTitle,
};

const enhancer = compose(
  connect(null, mapDispatchToProps),
  withWindowWidth(),
  withTranslation(['common', 'tasks', 'projects']),
  withState('isTaskOpen', 'setIsTaskOpen', false),
  withContext({
    setIsTaskOpen: PropTypes.func.isRequired,
  }, props => ({
    setIsTaskOpen: props.setIsTaskOpen,
  })),
  lifecycle({
    componentDidMount() {
      this.props.setAppTitle('My tasks - Avanga 2.0');
    },
  }),
);

export default enhancer(MyTasks);
