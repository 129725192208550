/* eslint-disable import/prefer-default-export */
import { deleteTimeEntry } from '../../helpers/taskHelpers/crudHelper';
import { taskActions, taskTypes } from '../../../state/task';


const reorderSpentTimeTask = ({
  totalSpentTime,
  remainingEstimate,
}, next) => {
  next(taskActions.reorderSpentTimeTask({
    totalSpentTime,
    remainingEstimate,
  }));
};

const deleteTimeEntryMiddleWare = ({ getState }) => next => (action) => {
  if (action.type === taskTypes.DELETE_TIME_ENTRY) {
    const { payload: { timeEntry } } = action;
    deleteTimeEntry(getState(), timeEntry.id, next);
    reorderSpentTimeTask({
      totalSpentTime: timeEntry.total_spent_time,
      remainingEstimate: timeEntry.remaining_estimate,
    }, next);
  } else {
    next(action);
  }
};
const editTimeEntryMiddleware = () => next => (action) => {
  if (action.type === taskTypes.SET_TIME_ENTRY) {
    const { payload: { timeEntry, meta: { projectId, taskId, clientId } } } = action;
    next(taskActions.getTimeEntriesRequest({ projectId, taskId, clientId },
      { isNotPending: true }));
    reorderSpentTimeTask({
      totalSpentTime: timeEntry.total_spent_time,
      remainingEstimate: timeEntry.remaining_estimate,
    }, next);
  } else {
    next(action);
  }
};

export {
  deleteTimeEntryMiddleWare,
  editTimeEntryMiddleware,
};
