import React, { memo } from 'react';

import './style.sass';

/**
 *
 * @param children {string}
 * @param onClick {function}
 * @param className {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const ButtonClose = memo(({
  title = '',
  onClick = () => { },
  className = '',
}) => (
  <button
    className={`close-button ${className}`}
    aria-label={`Close ${title}`}
    title={`Close ${title}`}
    type="button"
    onClick={onClick}
  >
    <span className="icon-close" />
  </button>
));
