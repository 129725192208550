const SPRINTS = 'SPRINTS';

export default {
  GET_SPRINTS_REQUEST: `GET_${SPRINTS}_REQUEST`,
  SET_SPRINTS_PROJECT: 'SET_SPRINTS_PROJECT',
};

export {
  SPRINTS,
};
