import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useProjectNotesContext } from '../../context/useProjectNotesContext';

import { ButtonsCollection, ModalWindow } from '../../../../ui-kit';
import { callNotification } from '../../../../utils/helpers/notifies';
import api from '../../../../api/projects.service';

import './style.sass';

export const DeleteNote = () => {
  const {
    selectedNoteId,
    notes,
    setNotes,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
  } = useProjectNotesContext();
  const { id: projectId } = useParams();
  const { t } = useTranslation(['common']);

  const [isPending, setIsPending] = useState(false);
  const note = notes.find(({ id }) => id === selectedNoteId);

  const onCloseModal = useCallback(() => {
    setIsOpenDeleteModal(false);
  }, []);


  const deleteNote = useCallback(
    async () => {
      try {
        setIsPending(true);
        const deletedNote = await api.removeProjectNote(projectId, note.id);
        return deletedNote;
      } catch (error) {
        callNotification.error(t('Note cannot be updated'));
      } finally {
        setIsPending(false);
      }
    }, [projectId, note],
  );

  const onDelete = async () => {
    const { document } = await deleteNote();
    setNotes(prev => prev.filter(item => item.id !== document.id));
    onCloseModal();
  };


  return (
    <ModalWindow
      isOpen={isOpenDeleteModal}
      onClose={onCloseModal}
      className="delete-note__modal"
      title={t('Delete note')}
    >
      <>
        <div className="row  text-align--left">
          <span className="delete-note__title title-md">
            {t('Are you sure you want to delete')}
            <b className="delete-note__name">{note?.title}</b>
            {`${t('note')}?`}
          </span>
        </div>
        <div className="delete-note__button-row">
          <ButtonsCollection.ButtonBrill
            type="button"
            onClick={onDelete}
            pending={isPending}
            className="button--md delete-note__button-control button-brill--fill"
          >
            {t('Delete')}
          </ButtonsCollection.ButtonBrill>
          <button
            type="button"
            className="button--cancel"
            onClick={onCloseModal}
          >
            {t('Cancel')}
          </button>
        </div>
      </>
    </ModalWindow>
  );
};
