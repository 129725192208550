export default {
  GET_PIPELINES_REQUEST: 'GET_PIPELINES_REQUEST',
  GET_PIPELINES_AUTOCOMPLETE_REQUEST: 'GET_PIPELINES_AUTOCOMPLETE_REQUEST',
  GET_PIPELINE_STATUSES_AUTOCOMPLETE_REQUEST: 'GET_PIPELINE_STATUSES_AUTOCOMPLETE_REQUEST',
  GET_PIPELINE_REQUEST: 'GET_PIPELINE_REQUEST',
  ADD_PIPELINE_REQUEST: 'ADD_PIPELINE_REQUEST',
  EDIT_PIPELINE_REQUEST: 'EDIT_PIPELINE_REQUEST',
  UPDATE_PIPELINE_REQUEST: 'UPDATE_PIPELINE_REQUEST',
  DELETE_PIPELINE_REQUEST: 'DELETE_PIPELINE_REQUEST',

  SET_PIPELINES: 'SET_PIPELINES',
  SET_PIPELINES_AUTOCOMPLETE: 'SET_PIPELINES_AUTOCOMPLETE',
  SET_PIPELINE_STATUSES_AUTOCOMPLETE: 'SET_PIPELINE_STATUSES_AUTOCOMPLETE',
  SET_PIPELINE: 'SET_PIPELINE',
  DELETE_PIPELINE: 'DELETE_PIPELINE',

  GET_PIPELINE_STATUSES_REQUEST: 'GET_PIPELINE_STATUSES_REQUEST',
  SET_PIPELINE_STATUSES: 'SET_PIPELINE_STATUSES',
};
