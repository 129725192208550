import classNames from 'classnames';
import moment from 'moment-timezone';
import React, {
  useEffect, useRef, useState,
} from 'react';
import DatePickerComponent from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { DD_MM_YYYY, DD_MMM_YYYY } from '../../../../constants/masks';

import { ButtonsCollection } from '../../..';

import './style.sass';
import SERVER_TIMEZONE from '../../../../constants/config';

const HOURS = 0;
const MIN = 0;
const SEC = 0;
const MS = 0;

/**
 *
 * @param inFocus {boolean}
 * @param id {string}
 * @param minDate {Date}
 * @param maxDate {Date}
 * @param value {string}
 * @param name {string}
 * @param className {string}
 * @param onChange {function}
 * @param onBlur {function}
 * @param isNotUseTimeZone {boolean}
 * @param touched {boolean}
 * @param error {string}
 * @param disabled {boolean}
 * @param placeholder {boolean}
 * @returns {JSX.Element}
 * @constructor
 */

export const DatePicker = ({
  id = '',
  className = '',
  onChange,
  name = '',
  onBlur = () => { },
  touched = false,
  error = '',
  dateError,
  minDate = null,
  maxDate = null,
  value = '',
  isNotUseTimeZone = false,
  disabled = false,
  placeholder = false,
  dateFormat,
}) => {
  const [inFocus, setInFocus] = useState(false);
  const [isPicker, setIsPicker] = useState(false);

  const inputRef = useRef(null);
  const fieldRef = useRef(null);

  const setOpenDateHandler = () => {
    const inputCurrentRef = inputRef.current;
    if (inputCurrentRef) {
      inputCurrentRef.setOpen(true);
    }
    setIsPicker(prevProps => !prevProps);
  };

  const onChangeFocus = () => {
    setInFocus(prevValue => !prevValue);
  };

  const onChangeDate = (e) => {
    if (e >= minDate) {
      onChange({
        target: {
          value: e,
          name,
        },
      });
    }
  };

  const values = () => {
    if (value) {
      return (isNotUseTimeZone ? value : moment(value).tz(SERVER_TIMEZONE).toDate());
    } return null;
  };

  const errorCheck = typeof error === 'string' && error.length !== 0 && touched;
  const dateErrorCheck = value && minDate && value < minDate.setHours(HOURS, MIN, SEC, MS);

  const privateRef = useRef({ value });

  useEffect(() => {
    privateRef.current = { value };
  }, [value]);

  const prevProps = privateRef.current;

  useEffect(() => {
    if (value !== prevProps.value) {
      setIsPicker(false);
    }
  }, [value]);

  return (
    <div
      className={classNames('date-picker-field',
        isPicker && !inFocus && 'date-picker-field--is-picker',
        inFocus && 'date-picker-field--focus',
        error && touched && 'date-picker-field--has-error',
        className)}
      ref={fieldRef}
    >
      <DatePickerComponent
        {...{
          selected: values(),
        }}
        onChange={e => onChangeDate(e)}
        name={name}
        minDate={minDate}
        maxDate={maxDate}
        className={classNames('datePicker-field', className)}
        dateFormat={dateFormat || 'dd MMM yyyy'}
        placeholderText={placeholder || 'DD Mon YYYY'}
        id={id}
        customInput={(
          <MaskedInput
            mask={dateFormat ? DD_MM_YYYY : DD_MMM_YYYY}
          />
        )}
        onClickOutside={() => setIsPicker(false)}
        onFocus={() => onChangeFocus()}
        onBlur={() => { onBlur({ target: { name } }); onChangeFocus(); }}
        ref={inputRef}
        disabled={disabled}
      />

      {errorCheck && (
        <span className="field-group__error field__error-text">
          {error}
        </span>
      )}
      {dateErrorCheck && (
        <span className="field-group__error field__error-text">
          {dateError}
        </span>
      )}
      <ButtonsCollection.ButtonIcons onClick={setOpenDateHandler} title="Show datepicker">
        <span className="icon-datepick" />
      </ButtonsCollection.ButtonIcons>
    </div>
  );
};


export default DatePickerComponent;
