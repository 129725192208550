import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import {
  compose, withContext, withState, lifecycle, branch, renderComponent,
} from 'recompose';
import { connect } from 'react-redux';
import { Header } from '../../../ui-kit';
import { LeftSideBar } from '../../../newContainers';
import {
  Layouts, ModuleWrapper,
  NoInternetConnection,
  ScrollWrapper,
} from '../../../ui-kit';
import {
  HrCandidateCreate,
  HrCandidateUpdate,
  HrCandidateReview,
  HrComponent,
} from './containers';
import { HrHeader } from './components';
import config from '../config';
import './style.scss';
import { withPermission } from '../../../utils/enchancers';
import { MODULES } from './constants/permissions';
import { setAppTitle } from '../../../state/ui/actions';
import { PageError } from '../../../views';
import { uiActions, uiSelectors } from '../../../state/ui';

const HrModule = ({ headTitle }) => (
  <Layouts.Main>
    <LeftSideBar />
    <div className="main-container main-container--settings HrModule">
      <ModuleWrapper title="Human Resources" config={config}>
        <ScrollWrapper>
          <NoInternetConnection>
            <Header hideToggleRightSideBar>
              <HrHeader title={headTitle} />
            </Header>
            <Switch>
              <Route path="/hr-management/candidate/create/" component={HrCandidateCreate} />
              <Route path="/hr-management/candidate/edit/:id" component={HrCandidateUpdate} />
              <Route path="/hr-management/candidate/:id/" component={HrCandidateReview} />
              <Route exact component={HrComponent} />
            </Switch>
          </NoInternetConnection>
        </ScrollWrapper>
      </ModuleWrapper>
    </div>
  </Layouts.Main>
);

HrModule.propTypes = {
  headTitle: PropTypes.string,
};
HrModule.defaultProps = {
  headTitle: null,
};

const mapDispatchToProps = {
  setErrorPage: uiActions.setErrorPage,
  setAppTitle,
};

const mapStateToProps = state => ({
  errorPage: uiSelectors.getIsPageNotFoundStatus(state),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPermission(MODULES.WHR_CANDIDATES),
  withState('headTitle', 'setHeadTitle', null),
  withContext({ setHeadTitle: PropTypes.func }, ({ setHeadTitle }) => ({ setHeadTitle })),
  lifecycle({
    componentDidMount() {
      this.props.setAppTitle('HR Management - Avanga 2.0');
    },
  }),
);
export default enhance(HrModule);
