import {
  compose, cond, curry, dissoc, eqBy, equals, insert, lensProp, prop, remove, T, set,
} from 'ramda';
import { put, select } from 'redux-saga/effects';

import { move } from '../commonHelpers';
import {
  getBacklogTasksList,
  getProjectBacklogTasks,
  getProjectSprintTasks,
  getProjectSprintTasksList,
  getTask,
  getBacklogTask,
} from '../../../state/project/selectors';
import {
  removeBacklogTask,
  removeSprintTask,
  reorderBacklogTasks,
  reorderSprintTasks,
  setBacklogTask,
  setSprintTask,
} from '../../../state/project/actions';
import { updateTaskRequest } from '../../../state/tasks/actions';
import { makePayload } from './utils';

const BACKLOG_DROPPABLE_ID = 'backlog-grid';

const getDroppableId = prop('droppableId');
const getDraggableIndex = prop('index');
const makePayloadWithSprintId = makePayload('sprintId');

const isSourceBacklog = curry((source, destination) => (
  source === BACKLOG_DROPPABLE_ID && destination !== BACKLOG_DROPPABLE_ID));
const isDestinationBacklog = equals(BACKLOG_DROPPABLE_ID);
const isDroppableIdEquals = curry((first, second) => eqBy(getDroppableId, first, second));

const getSprintId = cond([
  [equals(BACKLOG_DROPPABLE_ID), () => null],
  [T, sprint => sprint],
]);

const makeNewEqualsDroppableOrdering = curry((
  itemIndex, newItemIndex, droppableId,
) => compose(
  makePayloadWithSprintId(droppableId),
  move(itemIndex, newItemIndex),
));

const getDataInStoreWithParams = selector => params => () => select(
  state => selector(state)(params),
);

const getDataInStore = selector => () => select(state => selector(state));

const getSprintTasksListSelector = getDataInStoreWithParams(getProjectSprintTasksList);
const getSprintTaskSelector = getDataInStoreWithParams(getTask);
const getBacklogTaskSelector = getDataInStoreWithParams(getBacklogTask);
const getBacklogTasksListSelector = getDataInStore(getBacklogTasksList);
const getBacklogTasksSelector = getDataInStore(getProjectBacklogTasks);
const getProjectSprintTasksSelector = getDataInStore(getProjectSprintTasks);

const removeItemInList = curry((index, sprintId) => compose(
  makePayloadWithSprintId(sprintId),
  remove(index, 1),
));

const setItemInList = curry((itemIndex, item, sprintId) => compose(
  makePayloadWithSprintId(sprintId),
  insert(itemIndex, item),
));

const updateTaskSprint = curry(function* (sprintId, data) {
  const sprintIdLens = lensProp('sprint_id');
  const updatedData = set(sprintIdLens, sprintId, data);
  yield put(updateTaskRequest(updatedData));
});

const removeDataInSourceSprintList = curry(function* (selector, reorderTasksListAction, source) {
  const dataList = yield selector();
  const sourceDraggableIndex = getDraggableIndex(source);
  const sourceDroppableIndex = getDroppableId(source);
  const newSourceList = removeItemInList(
    sourceDraggableIndex,
    sourceDroppableIndex,
  )(dataList);
  yield put(reorderTasksListAction(newSourceList));
});

const updateDataInSourceEntities = curry(function* (
  selector,
  itemId,
  removeTaskAction,
) {
  const dataList = yield selector();
  const newSourceEntities = dissoc(itemId, dataList);
  yield put(removeTaskAction(newSourceEntities));
});

const setNewItemInDestinationList = curry(function* (
  reorderTasksAction,
  destination,
  item,
  selector,
) {
  const dataList = yield selector();
  const newDestinationList = setItemInList(
    getDraggableIndex(destination),
    item,
    getDroppableId(destination),
  )(dataList);
  yield put(reorderTasksAction(newDestinationList));
});

const setNewTasksOrdering = curry(function* (
  getSourceTasksList,
  reorderSourceList,
  getSourceTasks,
  removeSourceTask,
  getDestinationTasksList,
  reorderDestinationList,
  setDestinationTask,
  updateTaskAction,
  selectItem,
  dragData,
) {
  const { destination, source, itemId } = dragData;
  const task = yield selectItem();
  yield removeDataInSourceSprintList(getSourceTasksList, reorderSourceList, source);
  yield updateDataInSourceEntities(getSourceTasks, itemId, removeSourceTask);
  yield put(setDestinationTask({ [itemId]: task }));
  yield setNewItemInDestinationList(
    reorderDestinationList,
    destination,
    itemId,
    getDestinationTasksList,
  );
  yield updateTaskSprint(getSprintId(getDroppableId(destination)), task);
});

const setNewEqualsDroppableOrdering = curry(function* (
  selector,
  orderAction,
  destination,
  source,
) {
  const dataList = yield selector();
  const newListOrdering = makeNewEqualsDroppableOrdering(
    getDraggableIndex(source),
    getDraggableIndex(destination),
    getDroppableId(destination),
  )(dataList);
  yield put(orderAction(newListOrdering));
});

const setOrderingSprintToBacklog = (sprintId, taskId) => setNewTasksOrdering(
  getSprintTasksListSelector(sprintId),
  reorderSprintTasks,
  getProjectSprintTasksSelector,
  removeSprintTask,
  getBacklogTasksListSelector,
  reorderBacklogTasks,
  setBacklogTask,
  updateTaskRequest,
  getSprintTaskSelector(taskId),
);

const setOrderingBacklogToSprint = (sprintId, taskId) => setNewTasksOrdering(
  getBacklogTasksListSelector,
  reorderBacklogTasks,
  getBacklogTasksSelector,
  removeBacklogTask,
  getSprintTasksListSelector(sprintId),
  reorderSprintTasks,
  setSprintTask,
  updateTaskRequest,
  getBacklogTaskSelector(taskId),
);

const setOrderingSprintToSprint = (sourceId, destinationId, taskId) => setNewTasksOrdering(
  getSprintTasksListSelector(sourceId),
  reorderSprintTasks,
  getProjectSprintTasksSelector,
  removeSprintTask,
  getSprintTasksListSelector(destinationId),
  reorderSprintTasks,
  setSprintTask,
  updateTaskRequest,
  getSprintTaskSelector(taskId),
);

const setBacklogOrdering = setNewEqualsDroppableOrdering(
  getBacklogTasksListSelector,
  reorderBacklogTasks,
);
const setSprintOrdering = sprintId => setNewEqualsDroppableOrdering(
  getSprintTasksListSelector(sprintId),
  reorderSprintTasks,
);

const dragEqualsDroppable = cond([
  [({ destination }) => isDestinationBacklog(getDroppableId(destination)),
    ({ destination, source }) => setBacklogOrdering(destination, source),
  ],
  [T, ({ destination, source }) => setSprintOrdering(
    getDroppableId(destination),
  )(destination, source)],
]);

const dragDifferentDroppable = cond([
  [({ destination, source }) => isSourceBacklog(
    getDroppableId(source),
    getDroppableId(destination),
  ),
  ({ destination, source, itemId }) => setOrderingBacklogToSprint(
    getDroppableId(destination),
    itemId,
  )({ destination, source, itemId }),
  ],
  [({ destination }) => isDestinationBacklog(getDroppableId(destination)),
    ({ destination, source, itemId }) => setOrderingSprintToBacklog(
      getDroppableId(source),
      itemId,
    )({ destination, source, itemId }),
  ],
  [T, ({ destination, source, itemId }) => setOrderingSprintToSprint(
    getDroppableId(source),
    getDroppableId(destination),
    itemId,
  )({ destination, source, itemId })],
]);

const makeDragDrop = cond([
  [({ destination, source }) => isDroppableIdEquals(destination, source),
    function* ({ destination, source, itemId }) {
      yield dragEqualsDroppable({ destination, source, itemId });
    },
  ],
  [T, function* ({ destination, source, itemId }) {
    yield dragDifferentDroppable({ destination, source, itemId });
  }],
]);

export {
  dragDifferentDroppable,
  dragEqualsDroppable,
};

export default makeDragDrop;
