import { compose, getContext } from 'recompose';
import PropTypes from 'prop-types';

import DroppableArea from './droppableArea';
import { preloaderWhileLoading } from '../../../../../../utils/enchancers';
import { PRELOADER_DIMENSION } from '../../../../../../constants/ui';

const enhance = compose(
  getContext({
    isAgendaRequestPending: PropTypes.bool,
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.MIDDLE,
    alignContainerCenter: true,
    isLoading: () => false,
    onAction: ({ isAgendaRequestPending }) => isAgendaRequestPending,
  }),
);

export default enhance(DroppableArea);
