import * as yup from 'yup';
import { translateHelpers } from '../../../utils/helpers';

export const rules = yup.object().shape({
  password: yup
    .string()
    .required({
      field: 'password',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Password'),
      },
    }),
  username: yup
    .string()
    .required({
      field: 'username',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Email address'),
      },
    }),
});
