import React from 'react';

import './style.sass';
import classNames from 'classnames';

/**
 *
 * @param className {string}
 * @param children {JSX.Element}
 * @returns {JSX.Element}
 * @constructor
 */

export const BrillWithIndex = ({ className, children }) => (
  <div className={classNames('brill-count', className)}>
    <span className="brill-count__count">{children}</span>
    <span className="icon-hexagon-stroke brill-count__brill" />
  </div>
);
