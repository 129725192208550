import classNames from 'classnames';
import { equals, path, isNil } from 'ramda';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from './Option';
import { ScrollWrapper } from '../../..';
import './style.sass';

/**
 *
 * @param isOpen {boolean}
 * @param name {string}
 * @param data {array}
 * @param disabled {boolean}
 * @param value {string|number}
 * @param placeholder {string}
 * @param title {string}
 * @param touched {boolean}
 * @param error {string|boolean}
 * @param isRequired {boolean}
 * @param className {string}
 * @param onChange {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const Select = ({
  data,
  name,
  placeholder = '',
  value = '',
  error = '',
  touched = false,
  title = '',
  isRequired = false,
  disabled = false,
  className = '',
  onChange = () => {},
}) => {
  const [isToTop, setIsToTop] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [internalValue, setInternalValue] = useState(value);
  const { t } = useTranslation('common');


  useEffect(() => {
    if (!internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  const setToTopStatusStateHandler = val => setIsToTop(val);

  const body = useRef(null);
  const changeVectorForOpenHandler = useCallback(() => {
    const bodyRef = body.current;
    if (!bodyRef) return;
    const { y, height } = bodyRef.getBoundingClientRect();
    if (window.innerHeight < y + height) setToTopStatusStateHandler(true);
  });

  const onSelectOptionHandler = (newValue) => {
    onChange({ target: { name, value: newValue } });
    setInternalValue(newValue);
    setIsOpened(false);
  };

  const onToggleHandler = () => {
    setIsOpened(prevValue => !prevValue);
  };

  const fieldRef = useRef(null);
  const onClickOutsideHandler = (e) => {
    if (!fieldRef?.current?.contains(e.target)) {
      setIsOpened(false);
    }
  };

  useEffect(() => {
    changeVectorForOpenHandler();
    document.body.addEventListener('click', onClickOutsideHandler, true);
    return document.removeEventListener('click', onClickOutsideHandler, true);
  }, [onToggleHandler]);

  return (
    <div
      className={classNames('select-field',
        value && 'select-field--is-fill',
        isOpened && 'select-field--open',
        isToTop && 'select-field--top',
        disabled && 'select-field--disabled',
        className)}
      ref={fieldRef}
    >
      <div
        className="select-field__current-value"
        onClick={onToggleHandler}
        onKeyPress={onToggleHandler}
        tabIndex="0"
        role="button"
      >
        <div className="row text--cut">
          { !isNil(value) && path([internalValue, 'label'], data) }
          <span className="select-field__placeholder">{placeholder}</span>
        </div>
        <span className="select-field__current-value-icon icon-expand-arrow-right" />
      </div>
      {error.length !== 0 && touched && (
        <span className="field-group__error field__error-text">
          {t(error, { key: title })}
        </span>
      )}
      {
        (isOpened && !disabled) && (
          <div
            className="select-field__drop-down dropDown-select"
            ref={body}
          >
            <ScrollWrapper
              className="dropDown-select__scroll-wrapper"
              autoHeight
              renderTrackVertical={({ style, ...props }) => (
                <div style={{ ...style }} {...props} className="dropDown-select__track" />
              )}
              renderThumbVertical={props => <div {...props} className="dropDown-select__thumb" />}
            >
              {
                (!isRequired && placeholder) && (
                  <Option
                    key={`name-${name}-placeholder`}
                    isActive={equals(value, null)}
                    onSelectOption={onSelectOptionHandler}
                    innerText={placeholder}
                    value={null}
                  />
                )
              }
              {

                data ? data.map(option => (
                  <Option
                    key={`name ${option.value}`}
                    isActive={equals(internalValue, option.value)}
                    onSelectOption={onSelectOptionHandler}
                    innerText={option.label}
                    value={option.value}
                  />
                )) : (
                  <div className="row text-align--center">
                    <i className="select-field__empty">{t('Empty list')}</i>
                  </div>
                )
              }
            </ScrollWrapper>
          </div>
        )
      }
    </div>
  );
};
