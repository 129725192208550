import React, { memo } from 'react';

import './style.sass';

/**
 *
 * @param children {element | string}
 * @param className {string}
 * @param title {string}
 * @param disabled {boolean}
 * @param onClick {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const ButtonIcons = memo(({
  className = '',
  onClick = () => { },
  children = '',
  disabled = false,
  title = '',
}) => (
  <button
    type="button"
    className={`button-icons ${className}`}
    title={title}
    aria-label={title}
    onClick={e => !disabled && onClick(e)}
  >
    {children}
  </button>
));
