import {
  compose, getContext, lifecycle, withHandlers, withProps,
} from 'recompose';
import { equals } from 'ramda';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import Filters from './filters';
import { INVOICES_VIEW, INVOICES_STATUSES, SORTING_ORDER } from '../../../../constants/crm';
import { withFilters, withUrlParams, withWindowWidth } from '../../../../utils/enchancers';
import { invoicesActions } from '../../../../state/invoices';

const mapDispatchToProps = {
  getInvoicesListRequest: invoicesActions.getInvoicesListRequest,
};

const defaultStatusFilter = {
  id: null,
  value: null,
  label: 'All',
};

const getStatusById = statusId => (
  statusId
    ? ({ id: statusId, label: INVOICES_STATUSES[statusId], value: statusId })
    : defaultStatusFilter
);

const getOrderByValue = orderValue => (
  orderValue
    ? ({ label: SORTING_ORDER[orderValue], value: orderValue })
    : defaultStatusFilter
);

const onSetViewHandler = ({ setInvoicesView }) => view => () => setInvoicesView(view);

const onChangeFilterHandler = ({
  onSetUrlParam, queryParams, setFilters, mergeFilters, onResetUrlParam,
}) => (value, param, callback) => {
  if (value) {
    onSetUrlParam({ [param]: value });
    setFilters(mergeFilters({ [param]: value }));
  } else {
    onResetUrlParam({ [param]: null });
    setFilters(mergeFilters({ [param]: null }));
  }

  if (callback) callback(queryParams);
};

const onSearchHandler = ({
  getInvoicesListRequest, queryParams,
}) => () => getInvoicesListRequest(queryParams);

const enhance = compose(
  connect(null, mapDispatchToProps),
  withTranslation(['common']),
  withWindowWidth(),
  withUrlParams({}),
  withFilters({
    initial: ({ getUrlParam }) => ({
      status_id: getUrlParam(['status_id']),
      order: getUrlParam(['order'], 'desc'),
      search: getUrlParam(['search']),
      startDate: getUrlParam(['startDate']),
      endDate: getUrlParam(['endDate']),
    }),
  }),
  withProps(({ getFilter }) => ({
    queryParams: {
      status_id: getFilter('', 'status_id'),
      order: getFilter('desc', 'order'),
      search: getFilter('', 'search'),
      startDate: getFilter('', 'startDate'),
      endDate: getFilter('', 'endDate'),
    },
  })),
  withProps(({ queryParams }) => ({
    initialDate: {
      startDate: moment().startOf('month').toDate(),
      endDate: queryParams.startDate
        ? moment(queryParams.startDate).endOf('month').toDate()
        : moment().endOf('month').toDate(),
    },
  })),
  withHandlers({
    onSetView: onSetViewHandler,
    onSearch: onSearchHandler,
    setFilter: onChangeFilterHandler,
  }),
  getContext({
    invoiceStatusesOptions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  }),
  withProps(({ invoicesView, invoiceStatusesOptions }) => ({
    isPipeView: equals(invoicesView, INVOICES_VIEW.PIPE),
    invoiceStatusesOptions: [defaultStatusFilter, ...invoiceStatusesOptions],
    getStatusById,
    getOrderByValue,
  })),
  lifecycle({
    componentDidMount() {
      // this.props.getInvoicesListRequest(this.props.queryParams);
    },
    componentDidUpdate({ queryParams }) {
      if (!equals(queryParams, this.props.queryParams)) {
        this.props.getInvoicesListRequest(this.props.queryParams);
      }
    },
  }),
);
export default enhance(Filters);
