import { compose, defaultProps, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import FiltersRow from './filtersRow';
import {
  getProjectTasksAssignees, getProjectTasksAuthors,
} from '../../../../state/project/selectors';
import { OPTIONS_OF_STATUSES_SPRINTS } from '../../../../constants/sprints';

const mapStateToProps = state => ({
  assignees: getProjectTasksAssignees(state),
  assigners: getProjectTasksAuthors(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withTranslation(['common', 'projects']),
  defaultProps({
    sprintsStatuses: OPTIONS_OF_STATUSES_SPRINTS,
  }),
  withStateHandlers({
    areMobileFiltersOpen: false,
  }, {
    toggleMobileFiltersHandler:
      ({ areMobileFiltersOpen }) => () => ({ areMobileFiltersOpen: !areMobileFiltersOpen }),
  }),
);
export default enhance(FiltersRow);
