import React from 'react';
import PropTypes from 'prop-types';

import { EditNote, DeleteNote, CreateNote } from './components/notesCRUD';
import { ScrollWrapper, PermissionElement } from '../../ui-kit';
import { FilterRow, NotesList } from './components';
import './style.sass';
import { ACTIONS, MODULES } from '../../constants/pemissions';

const ProjectsNotes = ({ match: { params: { id } }, onChangeSorting, sortBy }) => (
  <ScrollWrapper>
    <PermissionElement
      module={MODULES.PROJECTS}
      action={ACTIONS.READ}
    >
      <FilterRow onChangeSorting={onChangeSorting} sortBy={sortBy} />
      <NotesList sortBy={sortBy} />
      <EditNote projectId={id} />
      <DeleteNote projectId={id} />
      <CreateNote projectId={id} />
    </PermissionElement>
  </ScrollWrapper>
);

ProjectsNotes.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  onChangeSorting: PropTypes.func.isRequired,
  sortBy: PropTypes.instanceOf(Object).isRequired,
};

export default ProjectsNotes;
