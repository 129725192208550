import {
  compose, defaultProps, lifecycle, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import {
  pathOr, pipe, pick, mergeRight, propOr,
} from 'ramda';
import { withTranslation } from 'react-i18next';

import { withRouter } from 'react-router';
import { notEqual } from 'ramda-extension';
import CreateTaskModal from './createTaskModal';
import rules from '../rules';
import withCRUDTask from '../../../utils/enchancers/withCRUDTask';
import { setTaskData } from '../../../utils/helpers/taskHelpers/crudHelper';
import { tasksActions } from '../../../state/tasks';
import { newTaskActions } from '../../../state/newTask';
import { TASKS_WITHOUT_PROJECT } from '../../../constants/tasks';
import { projectActions } from '../../../state/project';

const mapDispatchToProps = ({
  addTask: tasksActions.addTaskRequest,
  setSelectedProjectIdCrud: newTaskActions.setSelectedProjectCrud,
  getCategoriesTaskCrud: newTaskActions.getCategoriesTaskCrudRequest,
  getSprintsTaskCrud: newTaskActions.getSprintsTaskCrudRequest,
  getStatusesTasksCrud: newTaskActions.getStatusesTaskCrudRequest,
  getSprintsTaskCrudAutocomplete: newTaskActions.getSprintsTaskCrudAutocomplete,
  getProjectStatuses: projectActions.getStatusesOfTasksRequest,
});


const fetchDataToProjectHandler = ({
  getSprintsTaskCrud,
  getCategoriesTaskCrud,
  getStatusesTasksCrud,
}) => (projectId) => {
  if (projectId) {
    getStatusesTasksCrud({ projectId });
    getCategoriesTaskCrud({ projectId });
    getSprintsTaskCrud({ projectId, isTaskCRUD: true });
  }
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  withRouter,
  withTranslation(['common', 'tasks']),
  defaultProps({
    isDisabledProjectField: false,
  }),
  withCRUDTask({
    rules,
    data: () => null,
    modalName: 'createTaskModal',
    onSubmit: (formValues, {
      setFieldValue,
      resetForm,
      props: {
        addTask, onCloseModal, initialValues, match, history, onSuccess, getProjectStatuses,
      },
    }) => {
      const {
        submitButton,
      } = formValues;
      const projectIdFromUrl = pathOr(null, ['params', 'id'], match);
      const projectIdFromValue = pathOr(null, ['project_id', 'value'], formValues);

      const projectId = projectIdFromValue || projectIdFromUrl || TASKS_WITHOUT_PROJECT;

      addTask(setTaskData({ ...formValues, projectId }), {
        section: 'project',
        callbacks: {
          success: ({ task: { id } }) => {
            if (projectId !== TASKS_WITHOUT_PROJECT) {
              getProjectStatuses({ projectId });
            }
            resetForm(initialValues);
            if (submitButton !== 'saveAndCreate') {
              onCloseModal('createTaskModal');
              history.push(`/projects/${projectId}/task/${id}`);
            } else {
              const leftoverData = pipe(
                pick(['assignee', 'project_id', 'category_id', 'priority', 'sprint_id', 'watchers']),
                mergeRight(initialValues),
              )(formValues);
              resetForm(leftoverData);
              setFieldValue('assignee', formValues.assignee);
              setFieldValue('sprint_id', formValues.sprint_id);
              setFieldValue('project_id', formValues.project_id);
            }
            if (onSuccess) {
              onSuccess(projectId);
            }
          },
        },
      });
    },
  }),
  withHandlers({
    fetchDataToProject: fetchDataToProjectHandler,
    // loadSprintsList: loadSprintsListHandler,
  }),
  lifecycle({
    componentDidMount() {
      const {
        values: { project_id }, projectId, fetchDataToProject, setSelectedProjectIdCrud,
      } = this.props;
      setSelectedProjectIdCrud(projectId);
      if (projectId) {
        fetchDataToProject(propOr(projectId, 'value', project_id));
      }
    },
    componentDidUpdate(prevProps) {
      const {
        isOpen, resetForm, values: { project_id }, fetchDataToProject, projectId,
      } = this.props;
      if (notEqual(project_id, prevProps.values.project_id)) {
        fetchDataToProject(propOr(projectId, 'value', project_id));
      }
      if (isOpen !== prevProps.isOpen && isOpen) {
        resetForm({});
        fetchDataToProject(propOr(projectId, 'value', project_id));
      }
    },
  }),
);

export default enhance(CreateTaskModal);
