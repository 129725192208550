import React from 'react';
import PropTypes from 'prop-types';
import { pathOr, propOr } from 'ramda';

import {
  ButtonsCollection, ModalWindow, AsyncAutoComplete, FormsCollection,
} from '../../../ui-kit';
import { FILE_FORMATS, FILE_SIZES } from '../../../constants/crm';
import './style.sass';

const ModalView = ({
  isOpen,
  isPending,
  onCloseModal,
  handleChange,
  handleBlur,
  handleSubmit,
  values,
  errors,
  title,
  titleAction,
  setFieldValue,
  getLeadsAutocomplete,
  touched,
  isEdit,
  customFields,
  customFieldEntities,
}) => {
  const getFieldProps = name => ({
    value: propOr('', name, values),
    error: pathOr('', [name, 'message'], errors),
    touched: isEdit ? true : propOr(false, name, touched),
  });

  return (
    <ModalWindow
      isOpen={isOpen}
      onClose={onCloseModal}
      modalName="Contact"
      title={title}
      className="contacts-modal__modal"
    >
      <FormsCollection.FormWrapper
        handleSubmit={handleSubmit}
      >
        <FormsCollection.Input
          placeholderJump="First name"
          name="first_name"
          onChange={handleChange}
          onBlur={handleBlur}
          className="contacts-modal__first-name-input"
          id="contacts-modal__first-name"
          {...getFieldProps('first_name')}
        />
        <FormsCollection.Input
          placeholderJump="Last name"
          name="last_name"
          onChange={handleChange}
          onBlur={handleBlur}
          className="contacts-modal__last-name-input"
          id="contacts-modal__last-name"
          {...getFieldProps('last_name')}
        />
        <FormsCollection.Input
          placeholderJump="Email"
          name="email"
          onChange={handleChange}
          onBlur={handleBlur}
          className="contacts-modal__email-input"
          id="contacts-modal__email"
          {...getFieldProps('email')}
        />
        <FormsCollection.Input
          placeholderJump="Phone number"
          name="phone"
          onChange={handleChange}
          onBlur={handleBlur}
          className="contacts-modal__phone-input"
          id="contacts-modal__phone"
          {...getFieldProps('phone')}
        />
        <div className="contacts-modal__row contacts-modal__logo">
          <div className="contacts-modal__col-field">
            <FormsCollection.AttachImage
              onChange={handleChange}
              value={values.avatar}
              name="avatar"
              id="contacts-modal__avatar"
              labelText="Add image"
              formats={FILE_FORMATS.CONTACT_LOGO}
              maxSize={FILE_SIZES.CONTACT_LOGO}
            />
          </div>
        </div>
        {customFields && customFields.map(({ field_id: fieldId }) => (
          <FormsCollection.Input
            className="contacts-modal__last-name-input"
            placeholder={pathOr('N/A', [fieldId, 'title'], customFieldEntities)}
            id={`${fieldId}`}
            onChange={handleChange}
            name={pathOr('N/A', [fieldId, 'title'], customFieldEntities)}
            value={values[pathOr('N/A', [fieldId, 'title'], customFieldEntities)] || ''}
            touched
          />
        ))
        }
        <AsyncAutoComplete
          name="client"
          placeholder="Related to"
          closeMenuOnSelect
          defaultValue={values.client_id}
          loadOptions={getLeadsAutocomplete}
          onChange={(value) => {
            setFieldValue('client_id', value);
          }}
          {...getFieldProps('client_id')}
        />
        <div className="button-group">
          <ButtonsCollection.ButtonBrill
            pending={isPending}
            className="contacts-modal__button-save button-brill--fill"
            type="submit"
          >
            {titleAction}
          </ButtonsCollection.ButtonBrill>
        </div>
      </FormsCollection.FormWrapper>
    </ModalWindow>
  );
};

ModalView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool,
  isEdit: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  getLeadsAutocomplete: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  titleAction: PropTypes.string.isRequired,
};

ModalView.defaultProps = {
  isEdit: false,
  isPending: false,
};

export default ModalView;
