import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useWindowWidth } from '../../../../utils/hooks';
import { TabWrapper } from '../../../../ui-kit';
import { TaskList } from '../taskList';
import { TabWrapperMobile } from '../tabWrapperMobile';
import { WINDOW_WIDTH } from '../../../../constants/ui';

/**
 * @param activeTabTitle {string}
 * @param setActiveTitle {function}
 * @param importantTasks {array}
 * @returns {JSX.Element}
 * @constructor
 */

export const ImportantTasks = ({
  setActiveTitle = () => {},
  activeTabTitle = '',
  importantTasks,
}) => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();
  const [isImportantTasksTabOpen, setIsImportantTasksTabOpen] = useState(false);

  return (
    windowWidth > WINDOW_WIDTH.SMALL ? (
      <TabWrapper
        onChangeStatusTab={setIsImportantTasksTabOpen}
        defaultStatusTab={isImportantTasksTabOpen}
        title={<h2 className="title">{t('Important tasks')}</h2>}
      >
        <TaskList
          result={importantTasks?.data}
          className="important-tasks--right-side-bar"
        />
      </TabWrapper>
    ) : (
      <TabWrapperMobile
        setActiveTitle={setActiveTitle}
        title={t('Important tasks')}
        active={activeTabTitle}
      >
        <TaskList
          result={importantTasks?.data}
          className="important-tasks--right-side-bar"
        />
      </TabWrapperMobile>
    )
  );
};
