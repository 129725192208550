import {
  compose, getContext, lifecycle, pure, withHandlers, withProps, withState,
} from 'recompose';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { pathOr, propOr, startsWith } from 'ramda';
import LeadItem from './leadItem';
import { uiActions, uiSelectors } from '../../../../state/ui';
import { leadsSelectors } from '../../../../state/leads';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { withRefs, withWindowWidth } from '../../../../utils/enchancers';
import { taskActions } from '../../../../state/task';

const fixUrl = str => (startsWith('http', str) ? str : `http://${str}`);

const mapStateToProps = (state, { leadId }) => ({
  lead: leadsSelectors.getLeadById(state)(leadId),
  pinnedLead: leadsSelectors.getPinnedLeadById(state)(leadId),
  isEditModalOpen: uiSelectors.getModal(state)('editLeadModal'),
});

const mapDispatchToProps = {
  getTask: taskActions.getActiveTaskRequest,
  openModal: uiActions.openModal,
};

const onActionHandler = modalName => ({
  openModal, setSelectedLeadId, lead,
}) => () => {
  setSelectedLeadId(lead.id);
  openModal(modalName);
};

const onToggleLeadHandler = ({ toggleActiveTab, lead }) => () => toggleActiveTab(lead.id);

const onSetRefHandler = ({ setRef, isInitialOpen }) => refName => (e) => {
  if (isInitialOpen) {
    setRef(refName, e);
  }
};


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['tasks', 'common']),
  pure,
  withRefs(),
  withWindowWidth(),
  getContext({
    setSelectedLeadId: PropTypes.func.isRequired,
    scrollWrapperRef: PropTypes.any,
  }),
  withState('isInitialOpen', 'setIsInitialOpen', ({ isOpen }) => isOpen),
  withProps(({ lead, pinnedLead }) => {
    const getContactField = property => pathOr('', ['contact', property], lead);
    const companyUrl = getContactField('company_url');
    const fixedUrl = companyUrl ? fixUrl(companyUrl) : null;
    return {
      leadName: getFullName(lead),
      isPinned: !!pinnedLead,
      getContactField,
      leadPipeline: pathOr('', ['pipeline', 'title'], lead),
      leadStatus: pathOr('', ['pipelineStatus', 'title'], lead),
      companyUrl: fixedUrl,
    };
  }),
  withHandlers({
    onCreateTaskModal: onActionHandler('createTaskModal'),
    onEditTaskModal: onActionHandler('editPartOfTaskModal'),
    onEditFullTaskModal: onActionHandler('editTaskModal'),
    onDeleteTaskModal: onActionHandler('deleteTaskModal'),
    onEditLeadModal: onActionHandler('editLeadModal'),
    onDeleteLeadModal: onActionHandler('deleteLeadModal'),
    onPinLeadModal: onActionHandler('pinLeadModal'),
    onUnpinLeadModal: onActionHandler('unpinLeadModal'),
    onToggleLead: onToggleLeadHandler,
    onSetLeadRowRef: onSetRefHandler('active-lead'),
    onSetLeadContentRef: onSetRefHandler('active-lead-content'),
  }),
  lifecycle({
    componentDidUpdate() {
      const {
        scrollWrapperRef, getRef, toggleActiveTab, isInitialOpen, isEditModalOpen, lead,
      } = this.props;
      const activeLeadRef = getRef('active-lead');
      const activeLeadContentRef = getRef('active-lead-content');
      if (isInitialOpen && scrollWrapperRef && activeLeadRef && activeLeadContentRef) {
        requestAnimationFrame(() => {
          scrollWrapperRef.scrollTop(propOr(0, 'offsetTop', activeLeadRef), propOr(0, 'offsetTop', activeLeadRef));
        });
      }
      if (isEditModalOpen && lead) {
        toggleActiveTab(null);
      }
    },
  }),
);
export default enhance(LeadItem);
