import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { ButtonsCollection } from '../../../../../../ui-kit';

const DisposableForm = ({
  isFormOpen, setIsFormOpen, children, className, openButtonText, cancelButton,
}) => (
  <div className={`disposable-form ${className}`}>
    {
      !isFormOpen && (
        <div className="invoice-controls__row">
          <ButtonsCollection.ButtonPlus
            onClick={() => setIsFormOpen(true)}
            className="invoice-controls__button"
          />
          <button
            type="button"
            onClick={() => setIsFormOpen(true)}
            className="invoice-controls__label"
          >
            {openButtonText}
          </button>
        </div>
      )
    }
    {
      isFormOpen && (
        <>
          <div className="disposable-form__children">
            {
              Array.isArray(children)
                ? children.map(child => React.cloneElement(child, { isFormOpen, setIsFormOpen }))
                : React.cloneElement(children, { isFormOpen, setIsFormOpen })
            }
            {
              cancelButton && (
                <button
                  type="button"
                  className="button--cancel disposable-form__cancel-button"
                  onClick={() => setIsFormOpen(false)}
                >
                  Cancel
                </button>
              )
            }
          </div>
        </>
      )
    }
  </div>
);

DisposableForm.propTypes = {
  isFormOpen: PropTypes.bool.isRequired,
  setIsFormOpen: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  className: PropTypes.string,
  openButtonText: PropTypes.string,
  cancelButton: PropTypes.bool,
};

DisposableForm.defaultProps = {
  className: '',
  openButtonText: 'Open form',
  cancelButton: false,
};

export default DisposableForm;
