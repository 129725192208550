import {
  compose, getContext, withState, withContext, withHandlers, withStateHandlers,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LeadNotes from './leadNotes';
import { uiActions } from '../../../../state/ui';
import preloaderWhileLoading from '../../../../utils/enchancers/preloaderWhileLoading';
import { PRELOADER_DIMENSION } from '../../../../constants/ui';
import { leadsSelectors } from '../../../../state/leads';
import { OFFSET } from '../../../../constants/crm';

const mapStateToProps = (state, { leadId }) => ({
  isPending: leadsSelectors.getLeadActionPending(state)('getLeadNotesRequest'),
  notes: leadsSelectors.getLeadNotesSelector(state)(leadId),
});

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
};

const onCreateNoteHandler = ({ setOpenModal }) => () => {
  setOpenModal('createNoteModal');
};

const onActionHandler = modalName => ({ setSelectedNoteId, setOpenModal }) => (noteId) => {
  setSelectedNoteId(noteId);
  setOpenModal(modalName);
};

const onChangeSortingStateHandler = () => val => ({
  sortBy: val,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('selectedNoteId', 'setSelectedNoteId', 0),
  getContext({
    setActiveTab: PropTypes.func,
  }),
  withState('offset', 'setOffset', OFFSET),
  withHandlers({
    onCreateNote: onCreateNoteHandler,
    onEditNote: onActionHandler('editNoteModal'),
    onDeleteNote: onActionHandler('deleteNoteModal'),
  }),
  withStateHandlers(() => ({ sortBy: { val: { id: 0 } } }), {
    onChangeSorting: onChangeSortingStateHandler,
  }),
  withContext({
    selectedNoteId: PropTypes.func.isRequired,
    onEditNote: PropTypes.func.isRequired,
    onDeleteNote: PropTypes.func.isRequired,
  }, ({ selectedNoteId, onEditNote, onDeleteNote }) => ({
    selectedNoteId,
    onEditNote,
    onDeleteNote,
  })),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.SMALL,
    delay: 600,
    isLoading: props => props.isPending,
  }),
);
export default enhance(LeadNotes);
