import {
  compose, withContext, withHandlers, withState,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Products from './products';
import { getModal } from '../../../../state/ui/selectors';
import { clientsActions } from '../../../../state/clients';
import { projectsActions } from '../../../../state/projects';
import { uiActions } from '../../../../state/ui';

const mapStateToProps = state => ({
  isOpen: getModal(state)('deleteProductModal'),
});

const mapDispatchToProps = {
  closeModal: uiActions.closeModal,
  getProductsForClient: clientsActions.getClientProductsRequest,
  editProject: projectsActions.editProjectRequest,
};

const onDeleteProductHandler = ({
  editProject,
  closeModal: onCloseModal,
  getProductsForClient, productInfo, clientId,
}) => () => {
  editProject({
    id: productInfo.id,
    client_id: null,
    title: productInfo.title,
    type: productInfo.type,
    forClient: true,
  }, {
    callbacks: {
      success: () => getProductsForClient({ id: clientId }),
    },
  });
  onCloseModal('deleteProductModal');
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('productInfo', 'setProductInfo', null),
  withContext({
    productInfo: PropTypes.instanceOf(Object),
    setProductInfo: PropTypes.func,
  }, ({ productInfo, setProductInfo }) => ({
    productInfo, setProductInfo,
  })),
  withHandlers({
    onDeleteProductHandler,
  }),
);

export default enhance(Products);
