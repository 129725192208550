import {
  compose, getContext, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { taskActions } from '../../state/task';

const mapDispatchToProps = ({
  getTask: taskActions.getActiveTaskRequest,
});

const onEditTaskHandler = ({
  onEditTaskModal, sort, order, getTask,
}) => ({ taskId, projectId, clientId }) => {
  getTask({ projectId, taskId, clientId });
  onEditTaskModal(taskId, { sort, order });
};

const onDeleteTaskHandler = ({
  onDeleteTaskModal, sort, order,
}) => ({ taskId, meta }) => {
  onDeleteTaskModal(taskId, { sort, order }, meta);
};

const onUnpinTaskHandler = ({ onUnpinTaskModal, sort, order }) => ({ taskId, meta }) => {
  onUnpinTaskModal(taskId, { sort, order }, meta);
};

const onPinTaskHandler = ({ onPinTaskModal, sort, order }) => ({ taskId, meta }) => {
  onPinTaskModal(taskId, { sort, order }, meta);
};

const withTasksCRUDHandlers = compose(
  connect(null, mapDispatchToProps),
  getContext({
    selectedTaskId: PropTypes.number,
    onDeleteTaskModal: PropTypes.func,
    onEditTaskModal: PropTypes.func,
    onPinTaskModal: PropTypes.func,
    onUnpinTaskModal: PropTypes.func,
  }),
  withHandlers({
    onEditTask: onEditTaskHandler,
    onDeleteTask: onDeleteTaskHandler,
    onPinTask: onPinTaskHandler,
    onUnpinTask: onUnpinTaskHandler,
  }),
);
export default withTasksCRUDHandlers;
