import React from 'react';
import { Switch, Route } from 'react-router';
import { ACTIVITY_ROUTES } from './constants';

import './style.sass';
import { MyActivity, UsersActivity, ProjectActivity } from '../index';

export const CardsActivity = () => (
  <div className="cards-activity-container">
    <Switch>
      <Route exact path={`${ACTIVITY_ROUTES.ACTIVITY}/me`} component={MyActivity} />
      <Route exact path={`${ACTIVITY_ROUTES.ACTIVITY}/users`} component={UsersActivity} />
      <Route exact path={`${ACTIVITY_ROUTES.ACTIVITY}/projects`} component={ProjectActivity} />
    </Switch>
  </div>
);
