import {
  compose, withHandlers, withProps, lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { equals, includes, replace } from 'ramda';

import Invoices from './invoices';
import { withWindowWidth } from '../../../../utils/enchancers';
import { CRM_ROUTES } from '../../../../constants/crm';
import { clientsActions } from '../../../../state/clients';
import { uiActions } from '../../../../state/ui';

const onCreateInvoiceButtonClickHandler = ({ history }) => () => {
  history.push(CRM_ROUTES.NEW_INVOICE);
};

const mapDispatchToProps = {
  getClientsRequest: clientsActions.getClientsRequest,
  setAppTitle: uiActions.setAppTitle,
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  withWindowWidth(),
  withTranslation(['common']),
  withRouter,
  withHandlers({
    onCreateInvoiceButtonClick: onCreateInvoiceButtonClickHandler,
  }),
  withProps(({ history }) => ({
    isNewInvoicePage: equals(history.location.pathname, CRM_ROUTES.NEW_INVOICE),
    isEditInvoicePage: includes(replace(':invoiceId', '', CRM_ROUTES.EDIT_INVOICE), history.location.pathname),
  })),
  lifecycle({
    componentDidMount() {
      const { setAppTitle } = this.props;
      setAppTitle('Invoices - CRM - Avanga 2.0');
    },
  }),
);

export default enhance(Invoices);
