import { compose, withHandlers, withProps } from 'recompose';
import { equals } from 'ramda';
import FiltersRow from './filtersRow';
import { MY_TASKS } from '../../../../constants/tasks';
import { withUrlParams } from '../../../../utils/enchancers';

const setAssignedToMeFilter = ({ setActiveFilter, onSetUrlParam, onResetUrlParam }) => () => {
  onSetUrlParam({ role: MY_TASKS.FILTER.ASSIGNED_TO_ME });
  onResetUrlParam({ page: null });
  setActiveFilter(MY_TASKS.FILTER.ASSIGNED_TO_ME);
};
const setCreatedByMeFilter = ({
  setActiveFilter,
  onSetUrlParam,
  onResetUrlParam,
  getUrlParam,
}) => () => {
  onSetUrlParam({ role: MY_TASKS.FILTER.CREATED_BY_ME });
  const isPage = getUrlParam(['page']);
  if (isPage) {
    onResetUrlParam({ page: null });
  }
  setActiveFilter(MY_TASKS.FILTER.CREATED_BY_ME);
};

const onChangeSortingHandler = ({ onSortBy, setSelectedSort }) => ({ val: { order, sortBy } }) => {
  setSelectedSort({ order, sort: sortBy });
  onSortBy({ order, sort: sortBy });
};

const onSetGroupByHandler = ({ setGroupBy, onSetUrlParam }) => ({ val: { id } }) => {
  onSetUrlParam({ groupBy: id });
  setGroupBy(id);
};

const getSortValue = (order, sort) => {
  if (equals('created_at', sort)) {
    return equals('desc', order) ? 0 : 1;
  }
  return null;
};

const enhance = compose(
  withUrlParams({}),
  withHandlers({
    setAssignedToMeFilter,
    setCreatedByMeFilter,
    onChangeSorting: onChangeSortingHandler,
    onSetGroupBy: onSetGroupByHandler,
  }),
  withProps(({ order, sort }) => ({
    sortingByDate: getSortValue(order, sort),
  })),
);
export default enhance(FiltersRow);
