import {
  branch,
  compose,
  renderNothing,
  withProps,
  pure,
  withHandlers,
  withStateHandlers,
  getContext,
  withState, withContext,
} from 'recompose';
import {
  prop, path, pathOr, map, isEmpty, ifElse, gt, identity, propEq,
} from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import { isNotNil } from 'ramda-extension';
import CommentItem from './commentItem';
import { addBreakerToContentEditableValue } from '../../../../../../utils/helpers/commonHelpers';
import { usersSelectors } from '../../../../../../state/users';
import { taskActions, taskSelectors } from '../../../../../../state/task';
import { getImageUrl } from '../../../../../../utils/helpers/requestHelpers';
import { IMAGES_SIZE } from '../../../../../../constants/ui';
import { projectSelectors } from '../../../../../../state/project';
import {
  convertMessageBeforeSubmit,
  convertToHtml,
} from '../../../../../../utils/helpers/messengerHelpers/messages';
import { userSelectors } from '../../../../../../state/user';
import { uiActions } from '../../../../../../state/ui';
import withRefs from '../../../../../../utils/enchancers/withRefs';

const mapStateToProps = (state, { commentId }) => ({
  users: usersSelectors.getUsersEntities(state),
  comment: taskSelectors.getComment(state)(commentId),
  members: map(usersSelectors.getUser(state), ifElse(gt(0), identity,
    () => usersSelectors.getUsersResult(state))(projectSelectors.getProjectMembersList(state))),
  bootData: userSelectors.getUserData(state),
  user: userSelectors.getUserData(state),
});

const mapDispatchToProps = ({
  editTaskCommentRequest: taskActions.editTaskCommentRequest,
  setOpenModal: uiActions.openModal,
});

const onRenderContentHandler = ({ members, bootData }) => (content,
  onHandler, messageId, options) => convertToHtml(
  bootData.id,
  members,
  onHandler,
  messageId,
  options,
)(content);

const onDeleteCommentHandler = ({ setOpenModal, comment, setSelectedCommentId }) => () => {
  setSelectedCommentId(comment.id);
  setOpenModal('deleteCommentModal');
};

const onSaveCommentHandler = ({
  editTaskCommentRequest, comment, match: {
    params: {
      id: projectId, taskId,
    },
  }, setIsEditing,
}) => (value) => {
  editTaskCommentRequest({
    projectId, taskId, commentId: comment.id, content: convertMessageBeforeSubmit(value),
  });
  setIsEditing(false);
};

const onEditingThisCommentHandler = ({ setIsEditing, onHideActions }) => () => {
  onHideActions(false);
  setIsEditing(true);
};

const onCloseEditingThisCommentHandler = ({ setIsEditing }) => () => setIsEditing(false);

const setRefCommentHandler = ({ setRef }) => e => setRef('commentElement', e);

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  branch(({ comment, members }) => !prop('content', comment) && !isEmpty(members), renderNothing),
  withRefs(),
  withProps(({
    comment: {
      content, author, created_at, created_by,
    }, user, users,
  }) => {
    const authorAvatarId = path(['avatar', 'id'], users[author]) || pathOr(null, ['avatar'], users[author]);

    return {
      content: addBreakerToContentEditableValue(content),
      createdAt: created_at,
      author: users[created_by],
      isCommentOwner: propEq('id', created_by, user),
      authorAvatarSrc: getImageUrl(authorAvatarId, IMAGES_SIZE.sm) || '',
    };
  }),
  withState('isEditing', 'setIsEditing', false),
  withStateHandlers(() => ({ isShowActions: false }), {
    // eslint-disable-next-line max-len
    onToggleShowActions: ({ isShowActions }) => val => ({ isShowActions: isNotNil(val) ? val : isShowActions }),
    onHideActions: () => () => ({ isShowActions: false }),
  }),
  getContext({
    setSelectedCommentId: PropTypes.func,
  }),
  withContext({
    members: PropTypes.instanceOf(Array),
  }, ({ members }) => ({ members })),
  withHandlers({
    onRenderContent: onRenderContentHandler,
    setRefComment: setRefCommentHandler,
    onEditingThisComment: onEditingThisCommentHandler,
    onCloseEditingThisComment: onCloseEditingThisCommentHandler,
    onDeleteComment: onDeleteCommentHandler,
    onSaveComment: onSaveCommentHandler,
  }),
  pure,
);

export default enhancer(CommentItem);
