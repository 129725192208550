import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.sass';

/**
 *
 * @param text{string}
 * @returns {JSX.Element}
 * @constructor
 */

export const EmptyResult = ({ text = '' }) => {
  const { t } = useTranslation('common');
  return (
    <span className="no-results">
      {t(text)}
    </span>
  );
};
