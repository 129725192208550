import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Avatar, OnlineStatusLabel, BrillMask, ButtonsCollection,
} from '../../../index';
import { setUserProfileId } from '../../../../state/ui/actions';
import { getUser } from '../../../../state/users/selectors';
import { getUserData } from '../../../../state/user/selectors';
import { getChannelById } from '../../../../state/messenger/selectors';
import { getProject } from '../../../../state/projects/selectors';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import { IMAGES_SIZE } from '../../../../constants/ui';

/**
 *
 * @param memberId {number}
 * @param onSetModalDeleteMember {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const MemberItem = ({
  memberId,
  onSetModalDeleteMember,
}) => {
  const { id } = useParams();
  const user = useSelector(state => getUser(state)(memberId));
  const userData = useSelector(getUserData);
  const channel = useSelector(state => getChannelById(state)(id));
  const project = useSelector(state => getProject(state)(id));
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'chat']);

  const userName = getFullName(user);
  const userAvatar = getImageUrl(user.avatar, IMAGES_SIZE.xs);
  const isCanDeleteMember = channel.created_by === userData.id
    || project.created_by === userData.id;

  const onClick = () => dispatch(setUserProfileId(memberId));

  const onDelete = (e) => {
    e.stopPropagation();
    onSetModalDeleteMember(memberId);
  };

  return (
    <li role="presentation" onClick={onClick} onKeyPress={onClick} className="project-members__item">
      <BrillMask outsideChildren={<OnlineStatusLabel status={user.is_online} />}>
        <Avatar src={userAvatar} alt={userName} />
      </BrillMask>
      <button type="button" className="project-members__link">
        {`${user.first_name} ${user.last_name}`}
      </button>
      {
        isCanDeleteMember && (
          <ButtonsCollection.ButtonIcons
            className="project-members__button-remove"
            onClick={onDelete}
            title={t('Remove member')}
          >
            <span className="icon-close" />
          </ButtonsCollection.ButtonIcons>
        )
      }
    </li>
  );
};
