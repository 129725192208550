import React from 'react';
import PropTypes from 'prop-types';

import ProjectItem from '../projectItem';

const Grid = ({
  data, setRef, openModal, preloader, isLoadingMore,
}) => (
  <>
    <button
      onClick={() => openModal('createProjectModal')}
      type="button"
      className="brill-projects__card project-brill project-brill__add-project"
    >
      <span className="project-brill__title">Add new project</span>
    </button>
    {
      data.map(project => (
        <ProjectItem
          id={project}
          customRef={e => setRef(`project-brill${project}`, e)}
          key={project}
        />
      ))
    }
    { isLoadingMore && preloader }
  </>
);

Grid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  setRef: PropTypes.func.isRequired,
  preloader: PropTypes.element.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default Grid;
