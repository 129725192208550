const DROPDOWN_SORT_INVOICES_LIST = [
  {
    id: 0,
    value: 'desc',
    label: 'Recent',
  },
  {
    id: 1,
    value: 'asc',
    label: 'Old',
  },
];

export {
  DROPDOWN_SORT_INVOICES_LIST,
};
