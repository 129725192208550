import {
  compose, getContext,
} from 'recompose';
import PropTypes from 'prop-types';
import MentionItem from './MentionItem';


const enhance = compose(
  getContext({
    onClickMention: PropTypes.func.isRequired,
  }),
);

export default enhance(MentionItem);
