import { createSelector } from 'reselect';
import { identity, pathOr } from 'ramda';

const getHrCandidatesEntities = createSelector(pathOr({}, ['candidate', 'entities', 'candidates']), identity);
const getHrCandidatesList = createSelector(pathOr([], ['candidate', 'result', 'candidates']), identity);
const getHrCandidatesCount = createSelector(pathOr(0, ['candidate', 'summary', 'candidates', 'count']), identity);
const getHrCandidatesPending = createSelector(pathOr(false, ['pending', 'getHrCandidatesRequest']), identity);
const getHrCandidatesHasMore = createSelector(pathOr(false, ['candidate', 'summary', 'candidates', 'hasMore']), identity);
const getDeleteCandidatePending = createSelector(pathOr(false, ['pending', 'deletingSettingUserRequest']), identity);
const getCreateCandidatePending = createSelector(pathOr(false, ['pending', 'createUserRequest']), identity);

const getHrCommentsEntities = createSelector(pathOr({}, ['candidate', 'entities', 'comments']), identity);
const getHrCommentsList = createSelector(pathOr([], ['candidate', 'result', 'comments']), identity);

const getHrFilesEntities = createSelector(pathOr({}, ['candidate', 'entities', 'files']), identity);
const getHrFilesList = createSelector(pathOr([], ['candidate', 'result', 'files']), identity);

export {
  getHrCandidatesEntities,
  getHrCandidatesList,
  getHrCandidatesCount,
  getHrCandidatesPending,
  getHrCandidatesHasMore,
  getDeleteCandidatePending,
  getCreateCandidatePending,

  getHrCommentsEntities,
  getHrCommentsList,

  getHrFilesEntities,
  getHrFilesList,
};
