import React from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import { STATUS } from '../../../../constants/tasks';
import {
  ButtonsCollection, Avatar, BrillMask, DropDown,
} from '../../../../ui-kit';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import { IMAGES_SIZE } from '../../../../constants/ui';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { formatDate } from '../../../../utils/helpers/commonHelpers';

const ClientsTasksItem = ({
  task,
  onChangeTaskStatus,
  onClickTaskClient,
  onSetActiveTaskId,
  onEditTaskModal,
  onEditFullTask,
  onDeleteTaskModal,
  onEditFullTaskModal,
  t,
}) => (
  <li
    className="client-tasks__item"
  >
    <div
      role="button"
      tabIndex="-1"
      className={
          `client-tasks__complete-status ${task.status_id === STATUS.DONE.id ? 'client-tasks__complete-status--done' : ''}`
        }
      onKeyDown={onChangeTaskStatus(task)}
      onClick={onChangeTaskStatus(task)}
      title={task.status_id === STATUS.DONE.id ? t('Mark as To do') : t('Mark as Done')}
    />
    <div
      className="client-tasks__title"
      role="button"
      tabIndex="-1"
      onKeyDown={onClickTaskClient(task)}
      onClick={onClickTaskClient(task)}
    >{task.title}
    </div>
    {
        task.assigneeUser && (
          <div className="client-tasks__author">
            <BrillMask>
              <Avatar src={getImageUrl(pathOr(null, ['assigneeUser', 'avatar'], task), IMAGES_SIZE.xs)} alt={getFullName(task.assigneeUser)} />
            </BrillMask>
            <span className="client-tasks__name">{getFullName(task.assigneeUser)}</span>
          </div>
        )
      }
    {
        task.deadline && (
          <div className="client-tasks__deadline">
            {formatDate(task.deadline)}
          </div>
        )
      }
    <DropDown
      className="controls-group-task"
      name="controls-group-task-client"
      label={(
        <ButtonsCollection.ButtonIcons
          className="project-task__controls-button-task"
          title="Controls for client"
        >
          <span className="project-task__control-icon icon-settings-vertical">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
          </span>
        </ButtonsCollection.ButtonIcons>
        )}
      list={
          [
            {
              id: 0,
              onClick: onSetActiveTaskId(task.id, onEditTaskModal),
              label: (
                <>
                  <span className="controls-group-task__icon icon-pencil-2" />
                  <span className="controls-group-task__text">{t('Edit')}</span>
                </>
              ),
            },
            {
              id: 0,
              onClick: onEditFullTask(task, onEditFullTaskModal),
              label: (
                <>
                  <span className="controls-group-task__icon icon-pencil-2" />
                  <span className="controls-group-task__text">{t('Edit full task')}</span>
                </>
              ),
            },
            {
              id: 1,
              onClick: onSetActiveTaskId(task.id, onDeleteTaskModal),
              label: (
                <>
                  <span className="controls-group-task__icon icon-delete-icon" />
                  <span className="controls-group-task__text">{t('Delete')}</span>
                </>
              ),
            },
          ]
        }
    />
  </li>
);

ClientsTasksItem.propTypes = {
  task: PropTypes.instanceOf(Object).isRequired,
  onChangeTaskStatus: PropTypes.func.isRequired,
  onClickTaskClient: PropTypes.func.isRequired,
  onSetActiveTaskId: PropTypes.func.isRequired,
  onEditTaskModal: PropTypes.func.isRequired,
  onEditFullTask: PropTypes.func.isRequired,
  onDeleteTaskModal: PropTypes.func.isRequired,
  onEditFullTaskModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default ClientsTasksItem;
