import React from 'react';
import PropTypes from 'prop-types';
import { SummaryCard } from '../../../../../../ui-kit';
import './style.sass';

const SummaryCards = ({ t, contactsCount }) => (
  <div className="contacts-summary">
    <SummaryCard
      className="contacts-summary__card"
      colorBorder="#fcccc4"
      count={contactsCount}
      title={t('All')}
    />
  </div>
);

SummaryCards.propTypes = {
  t: PropTypes.func.isRequired,
  contactsCount: PropTypes.number.isRequired,
};

export default SummaryCards;
