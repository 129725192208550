const DROPDOWN_SORT_LEAD_LIST = [
  {
    id: 0,
    order: 'desc',
    sortBy: 'created_at',
    label: 'Recent',
  },
  {
    id: 1,
    order: 'asc',
    sortBy: 'created_at',
    label: 'Old',
  },
];

export {
  DROPDOWN_SORT_LEAD_LIST,
};
