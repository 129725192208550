import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';

import { ModalWindow, ButtonsCollection, FormsCollection } from '../../../ui-kit';
import { PIPELINE_STATUSES } from '../../../constants/crm';
import './style.sass';

const ModalView = ({
  isOpen,
  isPending,
  onCloseModal,
  handleChange,
  handleBlur,
  handleSubmit,
  values,
  errors,
  title,
  titleAction,
  onDeleteStatus,
  onAddNeStatus,
  statusesList,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    modalName="Pipeline"
    title={title}
    className="pipeline-modal__modal"
  >
    <FormsCollection.FormWrapper
      handleSubmit={handleSubmit}
    >
      <FormsCollection.Input
        placeholderJump="Pipeline title"
        name="title"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.title}
        touched
        error={propOr('', 'message', errors.title)}
        className="pipeline-modal__title-input"
        id="pipeline-modal__title"
      />
      <h3>Status</h3>
      {
        statusesList.map(status => (
          <div key={status} className="pipeline-modal__row-status">
            <div className="pipeline-modal__col-status">
              <FormsCollection.Input
                placeholderJump="Title"
                name={status}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[status]}
                touched
                error={propOr('', 'message', errors[status])}
                className="pipeline-modal__title-status"
                id="pipeline-modal__title"
              />
            </div>
            <div className="pipeline-modal__col-status">
              <div className="field-group">
                <div className="field-group__wrap">
                  <FormsCollection.Select
                    isRequired
                    onChange={handleChange}
                    name={`${status}_progress`}
                    value={values[`${status}_progress`] || 0}
                    data={[
                      {
                        value: 0,
                        label: '0%',
                      },
                      {
                        value: 1,
                        label: '10%',
                      },
                      {
                        value: 2,
                        label: '20%',
                      },
                      {
                        value: 3,
                        label: '30%',
                      },
                      {
                        value: 4,
                        label: '40%',
                      },
                      {
                        value: 5,
                        label: '50%',
                      },

                      {
                        value: 6,
                        label: '60%',
                      },

                      {
                        value: 7,
                        label: '70%',
                      },

                      {
                        value: 8,
                        label: '80%',
                      },

                      {
                        value: 9,
                        label: '90%',
                      },
                      {
                        value: PIPELINE_STATUSES.WON,
                        label: 'WON',
                      },
                      {
                        value: PIPELINE_STATUSES.LOST,
                        label: 'LOST',
                      },
                    ]}
                  />
                </div>
              </div>
              <button type="button" className="pipeline-modal__delete-button" data-index={status} onClick={onDeleteStatus}>
                <span data-index={status} className="icon-close" />
              </button>
            </div>
          </div>
        ))
      }
      <div className="row">
        <button
          type="button"
          className="pipeline-modal__add-status"
          onClick={onAddNeStatus}
        >
Add status
        </button>
      </div>
      <div className="button-group">
        <ButtonsCollection.ButtonBrill
          pending={isPending}
          className="button--md pipeline-modal__button-save button-brill--fill"
          type="submit"
        >
          {titleAction}
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);

ModalView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onDeleteStatus: PropTypes.func.isRequired,
  onAddNeStatus: PropTypes.func.isRequired,
  statusesList: PropTypes.instanceOf(Array).isRequired,
  title: PropTypes.string.isRequired,
  titleAction: PropTypes.string.isRequired,
};


export default ModalView;
