const PROJECTS_LIST = 'PROJECTS_LIST';

const PROJECTS_LIST_MENU = 'PROJECTS_LIST_MENU';
const PROJECTS_FAVORITE = 'PROJECTS_FAVORITE';
const FAVORITE_PROJECTS = 'FAVORITE_PROJECTS';

export default {
  GET_PROJECTS_LIST_REQUEST: `GET_${PROJECTS_LIST}_REQUEST`,
  GET_PROJECTS_LIST_MENU_REQUEST: `GET_${PROJECTS_LIST_MENU}_REQUEST`,
  GET_PROJECT_REQUEST: 'GET_PROJECT_REQUEST',
  GET_PROJECT_TASKS_REQUEST: 'GET_PROJECT_TASKS_REQUEST',
  GET_PROJECTS_SUMMARY_REQUEST: 'GET_PROJECTS_SUMMARY_REQUEST',
  GET_IMPORTANT_TASKS_PROJECTS_REQUEST: 'GET_IMPORTANT_TASKS_PROJECTS_REQUEST',
  GET_PROJECTS_AUTOCOMPLETE: 'GET_PROJECTS_AUTOCOMPLETE',

  SET_PROJECTS_LIST_MENU: `SET_${PROJECTS_LIST_MENU}`,
  SET_PROJECTS_LIST: `SET_${PROJECTS_LIST}`,
  SET_PROJECTS_FAVORITE: `SET_${PROJECTS_FAVORITE}`,
  SET_PROJECTS_FAVORITE_CUSTOM: 'SET_PROJECTS_FAVORITE_CUSTOM',
  SET_MORE_PROJECTS: 'SET_MORE_PROJECTS',
  SET_PROJECT_TASKS: 'SET_PROJECT_TASKS',
  SET_PROJECTS_SUMMARY: 'SET_PROJECTS_SUMMARY',
  SET_IMPORTANT_TASKS_PROJECTS: 'SET_IMPORTANT_TASKS_PROJECTS',
  SET_PROJECTS_AUTOCOMPLETE: 'SET_PROJECTS_AUTOCOMPLETE',

  ADD_TO_FAVORITE_PROJECTS_FETCH: `ADD_TO_${FAVORITE_PROJECTS}_FETCH`,
  ADD_PROJECT_REQUEST: 'ADD_PROJECT_REQUEST',
  ADD_PROJECT_SUCCESS: 'ADD_PROJECT_SUCCESS',

  EDIT_PROJECT_REQUEST: 'EDIT_PROJECT_REQUEST',

  EDIT_PROJECT_SUCCESS: 'EDIT_PROJECT_SUCCESS',
  SET_PROJECT: 'SET_PROJECT',

  DELETE_PROJECT_REQUEST: 'DELETE_PROJECT_REQUEST',

  REMOVE_FROM_FAVORITE_PROJECTS_FETCH: `REMOVE_FROM_${FAVORITE_PROJECTS}_FETCH`,
  REMOVE_PROJECTS_FAVORITE: `REMOVE_${PROJECTS_FAVORITE}`,
  REMOVE_PROJECTS_FAVORITE_CUSTOM: 'REMOVE_PROJECTS_FAVORITE_CUSTOM',
};

export {
  PROJECTS_LIST,
  PROJECTS_LIST_MENU,
  PROJECTS_FAVORITE,
};
