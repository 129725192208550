import React, { useState, useMemo } from 'react';
import { GitlabProjectsContext } from './context';

export const Provider = ({ children }) => {
  const [repositories, setRepositories] = useState([]);
  const [sortBy, setSortBy] = useState({ id: 0 });
  const onSortBy = ({ val }) => {
    setSortBy(val);
  };

  const value = useMemo(() => ({
    repositories,
    setRepositories,
    sortBy,
    setSortBy,
    onSortBy,
  }), [
    repositories,
    setRepositories,
    sortBy,
    setSortBy,
    onSortBy,
  ]);


  return (
    <GitlabProjectsContext.Provider value={value}>
      {children}
    </GitlabProjectsContext.Provider>
  );
};
