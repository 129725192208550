import React from 'react';
import { Link } from 'react-router-dom';

import { logoBlack } from '../../constants/assets';
import ToggleRightSideBarButton from './ToggleRightSideBarButton';
import { UserControls } from './UserControls';

import './style.sass';

/**
 * @param children {string | element}
 * @param empty {boolean}
 * @param className {string}
 * @param hideToggleRightSideBar {boolean}
 * @param withoutControls {boolean}
 * @returns {JSX.Element}
 * @constructor
 */

export const Header = ({
  children = '', empty = false, className, hideToggleRightSideBar = false, withoutControls = false,
}) => (empty ? (
  <header className={`header header--empty ${className}`}>
    <div className="header__row">
      <Link to="/" className="header__logo">
        <img src={logoBlack} alt="" />
      </Link>
    </div>
  </header>
) : (
  <header className={`header ${className}`}>
    <div className="header__col--left header__col">
      {children[0] || children}
    </div>
    {
      (children[1] || !hideToggleRightSideBar) && (
        <div className="header__col--central header__col">
          {children[1]}
          {
            !hideToggleRightSideBar && <ToggleRightSideBarButton />
          }
        </div>
      )
    }
    {!withoutControls && (
      <div className="header__col--right header__col">
        <UserControls />
      </div>
    )}
  </header>
));
