/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';

const projectsListSchema = [new schema.Entity('projects')];
const importantTasksSchema = [new schema.Entity('importantTasks')];

export {
  projectsListSchema,
  importantTasksSchema,
};
