import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { filterOut } from '../../../utils/helpers/crmHelpers/customFieldsHelper';
import { ConfirmModal } from '../../../ui-kit';
import { callNotification } from '../../../utils/helpers/notifies';
import api from '../../../api/custom.service';

import './style.sass';

const Z_INDEX = '100';

/**
 *
 * @param idToDelete {number}
 * @param setIdToDelete {function}
 * @param customFieldsList {array}
 * @param setCustomFieldsList {function}
 * @returns {JSX.Element}
 *
 */

const DeleteCustomFieldModal = ({
  idToDelete, setIdToDelete,
  customFieldsList, setCustomFieldsList,
}) => {
  const { t } = useTranslation('common');
  const [isPending, setIsPending] = useState(false);

  const onClose = useCallback(() => setIdToDelete(null), []);

  const onDelete = useCallback(() => {
    const makeRequest = async () => {
      try {
        await api.deleteField({ id: idToDelete });
        setIsPending(false);
        setCustomFieldsList(filterOut(customFieldsList, idToDelete));
        callNotification.success(t('Custom field has been deleted!'));
        onClose();
      } catch (e) {
        setIsPending(false);
        callNotification.error(t('Custom field has not been deleted!'));
      }
    };
    setIsPending(true);
    makeRequest();
  }, [idToDelete, customFieldsList]);

  return (
    <ConfirmModal
      isOpen={!!idToDelete}
      isPending={isPending}
      actionTitle={t('Delete')}
      zIndex={Z_INDEX}
      onAction={onDelete}
      onClose={onClose}
      title={t('Delete custom field')}
    >
      {t('Are you sure you want to delete this custom field?')}
    </ConfirmModal>
  );
};

DeleteCustomFieldModal.defaultProps = {
  setIdToDelete: () => {},
  customCallback: () => {},
  customFieldsList: [],
  setCustomFieldsList: () => {},
};

export default DeleteCustomFieldModal;
