import {
  compose,
} from 'recompose';
import { propNotEq } from 'ramda-extension';
import {
  map, values, path, filter,
} from 'ramda';

import DeleteRoleModal from './DeleteRoleModal';
import { permissionsActions } from '../../../../../../../state/permissions';
import { renameKeys } from '../../../../../../../utils/helpers/commonHelpers';
import { withAutocomplete } from '../../../../../../../utils/enchancers';

const enhance = compose(
  withAutocomplete({
    name: 'getRolesAutocomplete',
    action: (params, meta) => permissionsActions.getRolesRequest(params,
      { ...meta, isAutocomplete: true }),
    dataPath: compose(
      map(renameKeys({ name: 'label', id: 'value' })),
      values,
      path(['data', 'entities', 'roles']),
    ),
    searchField: 'name',
    filterFunc: ({ selectedRole }) => filter(propNotEq('value', selectedRole.id)),
  }),
);


export default enhance(DeleteRoleModal);
