/* eslint-disable import/prefer-default-export */
import { schema } from 'normalizr';

const myStatisticsSchema = [new schema.Entity('statistics', {
  activityPerDay: [new schema.Entity('activityPerDay')],
})];
const activityPerDaySchema = [new schema.Entity('activityPerDay')];
const activityPerMonthSchema = [new schema.Entity('activityPerMonth')];
const activityPerTasksSchema = [new schema.Entity('tasks')];
const activityPerUsers = [new schema.Entity('users')];

export {
  myStatisticsSchema,
  activityPerDaySchema,
  activityPerMonthSchema,
  activityPerTasksSchema,
  activityPerUsers,
};
