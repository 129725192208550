import {
  compose, withHandlers, getContext,
} from 'recompose';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import PinnedLeads from './pinnedLeads';
import { leadsSelectors } from '../../../../../../state/leads';
import { uiActions } from '../../../../../../state/ui';

const mapStateToProps = state => ({
  pinnedLeads: leadsSelectors.getPinnedLeadsId(state),
  pending: leadsSelectors.getPinnedLeadsPendingRequest(state),
});

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
};
const onUnpinHandler = ({
  setSelectedLeadId, setOpenModal,
}) => ({ id }) => {
  setSelectedLeadId(id);
  setOpenModal('pinLeadModal');
};
const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    setSelectedLeadId: PropTypes.func,
  }),
  withHandlers({
    onUnpin: onUnpinHandler,
  }),
);
export default enhance(PinnedLeads);
