import React, { useCallback } from 'react';
import MaskedInput from 'react-text-mask';

import './style.sass';

const ARROW_UP_KEY_CODE = 38;
const ARROW_DOWN_KEY_CODE = 40;
const MASK_REG_EXP = [/\d/, /\d/];

/**
 *
 * @param placeholder {string}
 * @param middlewareMask {function}
 * @param setRef {function}
 * @param getRef {function}
 * @param onChange {function}
 * @param min {string}
 * @param customRef {function}
 * @param onFocus {function}
 * @param onBlur {function}
 * @param max {string}
 * @returns {JSX.Element}
 * @constructor
 */

const Number = ({
  placeholder = '',
  setRef = () => {},
  onChange = () => {},
  customRef = () => {},
  getRef = () => {},
  onFocus = () => {},
  onBlur = () => {},
  min = '',
  max = '',
}) => {
  const filterValue = (value) => {
    const newValue = parseInt(value, 10) || 0;
    if (newValue > max) {
      newValue = max;
    } else if (newValue < min) {
      newValue = min;
    }
    onChange({
      value: newValue,
    });
    return newValue.toString();
  };
  const handleValue = useCallback(() => (updateValue) => {
    const input = getRef('input').inputElement;
    input.value = filterValue(parseInt(input.value, 10) + updateValue);
  });
  const middlewareMask = () => (newState) => {
    let { newValue } = newState;
    if (parseInt(newState.value, 10) > max) {
      newValue = max;
    } else if (parseInt(newState.value, 10) < min) {
      newValue = min;
    }
    return {
      selection: newState.selection,
      newValue,
    };
  };

  const specialKeysHandle = () => (e) => {
    const input = getRef('input').inputElement;
    const inputValue = parseInt(input.value || 0, 10);
    switch (e.keyCode) {
      case ARROW_UP_KEY_CODE:
        input.value = filterValue(inputValue + 1);
        break;
      case ARROW_DOWN_KEY_CODE:
        input.value = filterValue(inputValue - 1);
        break;
      default:
    }
  };

  return (
    <div className="field-number">
      <MaskedInput
        mask={MASK_REG_EXP}
        placeholder={placeholder}
        onChange={() => onChange({
          value: getRef('input').value,
        })}
        ref={(e) => { setRef('input', e); customRef(e); }}
        maskChar=""
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={specialKeysHandle}
        beforeMaskedValueChange={e => middlewareMask(e)}
        className="field-number__place-write"
      />
      <div className="field-number__controls">
        <div role="button" className="field-number__plus" onClick={() => handleValue(1)} onKeyPress={() => handleValue(1)} tabIndex="0">⯅</div>
        <div role="button" className="field-number__minus" onClick={() => handleValue(-1)} onKeyPress={() => handleValue(-1)} tabIndex="0">⯆</div>
      </div>
    </div>
  );
};

export default Number;
