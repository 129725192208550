import { compose, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import {
  isEmpty,
  pathOr,
} from 'ramda';
import CreateLeadModal from './createLeadModal';
import { uiActions } from '../../../../state/ui';
import { leadsActions } from '../../../../state/leads';
import rules from '../rules';
import { withLeadsCRUD } from '../../../../utils/enchancers';
import { PIPELINE_TYPES } from '../../../../constants/crm';
import { callNotification } from '../../../../utils/helpers/notifies';
import { getTimezoneAutocompleteHelper } from '../../../../utils/helpers/dateHelpers';
import { helpers } from '../../../../utils';


const { translateHelpers } = helpers;

const INPUT_ERROR_MESSAGE = 'Email should be unique';


const mapDispatchToProps = {
  closeCreateModal: () => uiActions.closeModal('createLeadModal'),
  addLead: leadsActions.addLeadRequest,
  convertLeadToClient: leadsActions.convertLeadToClientRequest,
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  withProps(() => ({
    initialValues: ({
      contact: {
        avatar: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        company_name: '',
        company_url: '',
        position: '',
        service: '',
        timezone: '',
      },
      pipeline_id: undefined,
      pipeline_status_id: undefined,
    }),
  })),
  withLeadsCRUD({
    pendingSelector: 'addLeadRequest',
    modalName: 'createLeadModal',
    rules,
    onSubmit: (formData, {
      resetForm,
      props: {
        closeCreateModal,
        addLead,
        onSuccess,
        convertLeadToClient,
      },
    }) => {
      const getFormField = path => pathOr(null, path, formData);
      if (isEmpty(formData.contact.avatar)) {
        // eslint-disable-next-line no-param-reassign
        formData.contact.avatar = null;
      }
      if (isEmpty(formData.contact.email)) {
        // eslint-disable-next-line no-param-reassign
        formData.contact.email = null;
      }
      addLead({
        contact: {
          ...formData.contact,
          timezone: formData.contact.timezone?.value,
        },
        pipeline_id: getFormField(['pipeline_id', 'value']),
        pipeline_status_id: getFormField(['pipeline_status_id', 'value']),
      }, {
        callbacks: {
          success: ({ client }) => {
            if (getFormField(['pipeline_status_id', 'type']) === PIPELINE_TYPES.WON) convertLeadToClient({ ...client });
            resetForm({});
            closeCreateModal();
            if (onSuccess) {
              onSuccess();
            }
          },
          error: () => callNotification.error(translateHelpers.t('common', INPUT_ERROR_MESSAGE)),
        },
      });
    },
  }),
  withHandlers({
    getAutocompleteHandler: getTimezoneAutocompleteHelper,
  }),
);
export default enhance(CreateLeadModal);
