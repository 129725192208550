import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection, ModalWindow, FormsCollection } from '../../../../ui-kit';
import FieldItem from './fieldItem';
import FIELD_NAME from './constants';
import './style.sass';


const getFieldName = idField => `${FIELD_NAME}${idField}`;

const InviteMembersModal = ({
  isOpen,
  isPending,
  handleChange,
  handleBlur,
  handleSubmit,
  values,
  closeModalHandler,
  fieldsArray,
  deleteFieldItemHandler,
  addFieldItemHandler,
  getError,
  t,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={closeModalHandler}
    modalName="createChannel"
    title={t('Invite members')}
    className="invite-members__modal"
  >
    <FormsCollection.FormWrapper className="invite-members" handleSubmit={handleSubmit}>
      {
        fieldsArray.map(id => (
          <FieldItem
            key={id}
            id={id}
            t={t}
            value={values[getFieldName(id)]}
            name={getFieldName(id)}
            error={getError(id)}
            touched
            onChange={handleChange}
            onBlur={handleBlur}
            onDelete={fieldsArray.length > 1 ? () => deleteFieldItemHandler(id) : null}
          />
        ))
      }
      <div className="invite-members__add-fields-row">
        <span className="invite-members__icon--in-link icon-add-member" />
        <button
          className="invite-members__button-as-link link"
          onClick={() => addFieldItemHandler({ many: false })}
          type="button"
        >
          {t('Add another')}
        </button>
        {t('or add')}
        <button
          onClick={() => addFieldItemHandler({ many: true })}
          className="invite-members__button-as-link link"
          type="button"
        >
          {t('many at once')}
        </button>
      </div>
      <div className="button-group">
        <ButtonsCollection.ButtonBrill
          pending={isPending}
          type="submit"
          className="button--md invite-members__button-save button-brill--fill"
        >
          {t('Invite')}
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);

InviteMembersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.shape().isRequired,
  closeModalHandler: PropTypes.func.isRequired,
  fieldsArray: PropTypes.arrayOf(PropTypes.number).isRequired,
  deleteFieldItemHandler: PropTypes.func.isRequired,
  addFieldItemHandler: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

InviteMembersModal.defaultProps = {
  isPending: false,
};

export default InviteMembersModal;
