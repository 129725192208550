import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';

import {
  UsersWrapper,
  UserWrapper,
  PermissionsWrapper,
  RolesWrapper,
  BillingInfoWrapper,
  UserInvite,
} from './components';
import { APP_ROUTES } from '../../constants/appRoutes';
import { LeftSideBar } from '../../newContainers';
import { uiActions } from '../../state/ui';
import {
  Layouts,
  NoInternetConnection,
} from '../../ui-kit';
import './style.sass';

const PermissionsWrapperCreate = () => (<PermissionsWrapper action="Create" />);
const PermissionsWrapperEdit = () => (<PermissionsWrapper action="Edit" />);

export const Settings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const setAppTitle = useCallback((value) => {
    dispatch(uiActions.setAppTitle(value));
  });

  useEffect(() => {
    setAppTitle('Settings - Avanga 2.0');
  }, []);

  return (
    <Layouts.Main>
      <LeftSideBar>
        <nav className="sub-nav">
          <h3 className="sub-nav__title">{t('General')}</h3>
          <NavLink
            className="sub-nav__link"
            activeClassName="sub-nav__link--active"
            to={APP_ROUTES.PREFERENCES}
          >
            {t('Users/Roles')}
          </NavLink>
        </nav>
      </LeftSideBar>
      <div className="main-container main-container--settings">
        <NoInternetConnection>
          <Switch>
            <Route exact path="/preferences/users/invite-user/" component={UserInvite} />
            <Route exact path="/preferences/users/" component={UsersWrapper} />
            <Route exact path="/preferences/users/:userId/" component={UserWrapper} />
            <Route exact path="/preferences/roles/create/" component={PermissionsWrapperCreate} />
            <Route exact path="/preferences/roles/:roleId/" component={PermissionsWrapperEdit} />
            <Route exact path="/preferences/roles/" component={RolesWrapper} />
            <Route exact path="/preferences/billing-info/" component={BillingInfoWrapper} />
            <Route exact path="/preferences/" component={UsersWrapper} />
            <Route component={UsersWrapper} />
          </Switch>
        </NoInternetConnection>
      </div>
    </Layouts.Main>
  );
};
