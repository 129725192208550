import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from 'react-portal';
import { ButtonsCollection } from '..';
import './style.sass';


/**
 *
 * @param title {string}
 * @param className {string}
 * @param onWidth {number}
 * @param onToggle {function}
 * @param isOpenDefault {boolean}
 * @param children {JSX.Element}
 * @returns {JSX.Element}
 * @constructor
 */

export const MobileFiltersContainer = ({
  title = '',
  className = '',
  children,
  onWidth = 0,
  isOpenDefault,
  onToggle,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { t } = useTranslation('common');

  const onToggleTab = useCallback(() => {
    setIsOpen((prevState => !prevState));
    onToggle((prevState => !prevState));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
    return (
      window.removeEventListener('resize', () => setWindowWidth(window.innerWidth))
    );
  }, []);
  return (
    windowWidth > onWidth ? (
      children
    ) : (
      <>
        <ButtonsCollection.ButtonIcons
          title={t('Filters')}
          type="button"
          className="filters-container-mobile__open-container"
          onClick={onToggleTab}
        >
          <span className="icon-filter" />
        </ButtonsCollection.ButtonIcons>
        <Portal>
          {(isOpenDefault ?? isOpen)
              && (
                <div className={`filters-container-mobile${className}`}>
                  <div
                    className="filters-container-mobile__container"
                  >
                    <div className="filters-container-mobile__header">
                      <ButtonsCollection.ButtonIcons
                        title={t('Close')}
                        onClick={onToggleTab}
                      >
                        <span className="icon-expand-arrow-left" />
                      </ButtonsCollection.ButtonIcons>
                      <h3 className="filters-container-mobile__title text--center title--big">
                        {title}
                      </h3>
                    </div>
                    <div className="filters-container-mobile__body">{children}</div>
                  </div>
                </div>
              )
              }
        </Portal>
      </>
    )
  );
};
