import React from 'react';
import PropTypes from 'prop-types';

import { propOr } from 'ramda';
import {
  ConfirmModal,
} from '../../../../ui-kit';
import { getFullName } from '../../../../utils/helpers/userHelpers';

const UnpinLeadModal = ({
  isOpen,
  onClose,
  pinnedLead,
  onUnpin,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    actionTitle="Unpin"
    onAction={onUnpin}
    onClose={onClose}
    title="Unpin lead"
  >
    Are you sure you want to unpin
    <b>{getFullName(propOr({}, 'contact', pinnedLead))}</b>
    lead?
  </ConfirmModal>
);

UnpinLeadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUnpin: PropTypes.func.isRequired,
  pinnedLead: PropTypes.shape({}),
};

UnpinLeadModal.defaultProps = {
  pinnedLead: null,
};

export default UnpinLeadModal;
