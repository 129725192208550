import {
  compose, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';

import rules from '../rules';
import { getModal } from '../../../../../../../state/ui/selectors';
import { clientsActions, clientsSelectors } from '../../../../../../../state/clients';
import { uiActions } from '../../../../../../../state/ui';
import CreateNote from '../../../../../../projectsNotes/components/notesCRUD/createNote/createNote';

const mapStateToProps = state => ({
  isOpen: getModal(state)('createNoteModal'),
  isPending: clientsSelectors.getClientActionPending(state)('addLeadNoteRequest'),
});

const mapDispatchToProps = ({
  closeModal: () => uiActions.closeModal('createNoteModal'),
  addClientNoteRequest: clientsActions.addClientNoteRequest,
  getClientNotesRequest: clientsActions.getClientNotesRequest,
});

const formSubmit = (formValues, {
  resetForm,
  props: {
    closeModal, addClientNoteRequest, getClientNotesRequest, clientId: id, order, sortBy,
  },
}) => {
  const data = { ...formValues, id };
  addClientNoteRequest(data, {
    callbacks: {
      success: () => {
        getClientNotesRequest({ id, sortBy, order });
        closeModal();
        resetForm({});
      },
    },
  });
};

const onCloseModalHandler = ({ closeModal, resetForm }) => () => {
  resetForm({});
  closeModal();
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common']),
  withFormik({
    mapPropsToValues: () => ({
      title: '',
      content: '',
    }),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: formSubmit,
  }),
  withHandlers({
    onCloseModal: onCloseModalHandler,
  }),
);

export default enhance(CreateNote);
