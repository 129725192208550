import { handleActions } from 'redux-actions';
import types from './types';
import {
  mergeByPath,
  mergeIn,
} from '../../utils/helpers/ramdaStateHelpers';

const reducer = handleActions({
  [types.SET_USER_STATISTIC]: mergeIn(({ payload: { data, meta } }, { users }) => ({
    [meta.userId]: {
      total: data.total,
      entities: data.entities,
      result: data.result,
    },
    users: {
      entities: { ...users?.entities, [meta.userId]: Object.values(data.entities.users || {}) },
      result: data.result.total.users,
    },
  })),
  [types.SET_MY_STATISTICS]: mergeByPath(['myStatistics'], ({
    payload: { data: { result: { total, ...result }, ...data } },
  }) => ({
    entities: data.entities,
    result,
    total,
  })),
  [types.SET_STATISTICS_BY_TASKS]: mergeIn(({ payload: { data, meta } }) => ({
    tasks: {
      [meta.projectId]: {
        entities: data.entities.tasks,
        result: data.result,
      },
    },
  })),
}, {
});

export default reducer;
