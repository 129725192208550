import {
  compose, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { pathOr, prop } from 'ramda';

import ChannelItem from './channelItem';
import { messengerSelectors } from '../../../../../../state/messenger';
import { usersSelectors } from '../../../../../../state/users';
import { userSelectors } from '../../../../../../state/user';

const mapStateToProps = (state, { channelId }) => ({
  channel: messengerSelectors.getDirectChannels(state)[channelId],
  usersList: usersSelectors.getUsersEntities(state),
  currentUser: userSelectors.getUserData(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withProps(({ usersList, channel, currentUser }) => ({
    channelStatusOnline: pathOr(pathOr(0, ['user', 'is_online'], channel),
      [(prop('user_id', channel)), 'is_online'], usersList),
    channelName: channel.name,
    unreadCount: channel.unread_count,
    username: currentUser.username,
  })),
);

export default enhance(ChannelItem);
