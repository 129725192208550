import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ScrollWrapper, DropDown, FormsCollection } from '../../../../../ui-kit';
import './style.sass';
import { PeopleList, DeletePersonModal } from '../../ui-kit';

import { customFieldsActions } from '../../../../../state/customFields';
import { usePeopleContext } from '../../context/usePeopleContext';
import { usePagination, useSorting } from '../../../../../utils/hooks';
import { LIMIT_OF_LIST_PEOPLE } from '../../constants/people';

const CREATED_AT = 'created_at';
const DESC = 'desc';

/**
 * @returns {JSX.Element}
 * @constructor
 */

const PeopleContainer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { setRequestParams } = usePeopleContext();
  const [sortingByDate, setSortingByDate] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [searchValueToDisplay, setSearchValueToDisplay] = useState('');
  const { limit, offset, page } = usePagination(LIMIT_OF_LIST_PEOPLE);
  const { sort, order, onSortBy } = useSorting({});

  const onSearchChange = useCallback(({ target: { value } }) => setSearchValueToDisplay(value), []);
  const onSearch = useCallback(() => setSearchValue(searchValueToDisplay), [searchValueToDisplay]);

  const onChangeSorting = ({ val: { order: newOrder, sortBy } }) => {
    onSortBy({ order: newOrder, sort: sortBy });
  };

  useEffect(() => {
    setRequestParams({
      sortBy: sort, limit, offset, order, searchValue,
    });
    dispatch(customFieldsActions.getCustomFieldsRequest());
  }, [sort, order, limit, offset, searchValue, searchValueToDisplay]);

  useEffect(() => {
    if (sort === CREATED_AT) setSortingByDate(order === DESC ? 0 : 1);
  }, [order, sort]);

  return (
    <ScrollWrapper>
      <div className="settingUsers">
        <div className="row settingUsers__search-row">
          <FormsCollection.Search
            className="search-field--fill"
            placeholder={t('Search by name, username, e-mail')}
            name="search-users"
            value={searchValueToDisplay}
            onChange={onSearchChange}
            onClick={onSearch}
            onSearchByEnter={onSearch}
          />
          <DropDown
            className="sort-select"
            label={`${t('Sort')} : `}
            placeholder={t('Recent')}
            onChange={onChangeSorting}
            currentItem={sortingByDate}
            list={
              [
                {
                  id: 0,
                  order: 'desc',
                  sortBy: 'created_at',
                  label: t('Recent'),
                },
                {
                  id: 1,
                  order: 'asc',
                  sortBy: 'created_at',
                  label: t('Old'),
                },
              ]
            }
          />
        </div>
        <PeopleList
          onSortBy={onSortBy}
          sort={sort}
          order={order}
          limit={limit}
          offset={offset}
          page={page}
        />
      </div>
      <DeletePersonModal />
    </ScrollWrapper>
  );
};

PeopleContainer.defaultProps = {
  usersHasMore: false,
};

export default PeopleContainer;
