import {
  compose, withHandlers, getContext,
} from 'recompose';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import PinnedContacts from './pinnedContacts';
import { contactsSelectors } from '../../../../../../state/contacts';
import { uiActions } from '../../../../../../state/ui';

const mapStateToProps = state => ({
  pinnedContacts: contactsSelectors.getPinnedContactsId(state),
  pending: contactsSelectors.getPinnedContactsPendingRequest(state),
});

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
};

const onUnpinHandler = ({
  setSelectedContactId, setOpenModal,
}) => ({ id }) => {
  setSelectedContactId(id);
  setOpenModal('pinContactModal');
};
const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    setSelectedContactId: PropTypes.func,
  }),
  withHandlers({
    onUnpin: onUnpinHandler,
  }),
);
export default enhance(PinnedContacts);
