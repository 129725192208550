import React from 'react';
import { ClientForm } from '..';
import './style.sass';

const ClientInfo = props => (
  <div className="client-info">
    <ClientForm titleAction="Save" isEdit {...props} />
  </div>
);

export default ClientInfo;
