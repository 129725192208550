import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { uiActions, uiSelectors } from '../../../../../state/ui';
import { ButtonsCollection, ModalWindow, FormsCollection } from '../../../..';
import { useGitlabProjectsContext } from '../../context/useGitlabProjectsContext';

import { callNotification } from '../../../../../utils/helpers/notifies';
import { rules } from './rules';
import api from '../../../../../api/projects.service';

export const AddGitlabProject = () => {
  const { id: projectId } = useParams();
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);
  const { t } = useTranslation(['common']);
  const {
    control, handleSubmit, reset,
  } = useForm({
    resolver: yupResolver(rules),
    defaultValues: {
      gitlabProjectId: null,
    },
  });
  const { setRepositories } = useGitlabProjectsContext();

  const isOpenRepositoryModal = useSelector(state => uiSelectors.getModal(state)('createRepositoryModal'));

  const closeModal = useCallback(() => {
    dispatch(uiActions.closeModal('createRepositoryModal'));
  }, []);

  const onCloseModal = () => {
    reset({});
    closeModal();
  };

  const addRepository = useCallback(async (params) => {
    try {
      setIsPending(true);
      const data = await api.addRepository(projectId, params);
      setRepositories(prevState => [data.gitlabProject, ...prevState]);
      onCloseModal();
    } catch (error) {
      callNotification.error(error.response.data.result.message);
    } finally {
      setIsPending(false);
    }
  }, [projectId]);

  const handleCreateRepository = async (data) => {
    await addRepository(data);
  };
  return (
    <ModalWindow
      isOpen={isOpenRepositoryModal}
      onClose={onCloseModal}
      className=""
      title={t('Add new repository')}
    >
      <FormsCollection.FormWrapper
        handleSubmit={handleSubmit(handleCreateRepository)}
        className=""
      >
        <div className="">
          <Controller
            name="gitlabProjectId"
            control={control}
            render={({
              field: { onChange, value, onBlur }, fieldState: { error },
            }) => (
              <FormsCollection.Input
                placeholderJump="Repository id"
                className=""
                id=""
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                touched
                error={error?.message}
              />)}
          />
        </div>
        <div className="button-group">
          <ButtonsCollection.ButtonBrill
            pending={isPending}
            type="submit"
            className="button--md crud-notes__button-save button-brill--fill"
          >
            {t('Add repository')}
          </ButtonsCollection.ButtonBrill>
        </div>
      </FormsCollection.FormWrapper>
    </ModalWindow>
  );
};
