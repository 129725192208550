/* eslint-disable no-nested-ternary */
import {
  compose,
  cond, curry, identity, isNil, path, T,
} from 'ramda';
import { Maybe } from 'ramda-fantasy';
import { isNodeHasSibling } from '../uiComponentHelpers/DOMhelpers';

const fireActionIfSiblingNotNil = curry((action, node) => cond([
  [isNil, identity],
  [T, sibling => action(sibling)],
])(node));

const concatWithSiblingTextOrReturnInitial = curry((
  concatMethod,
  getSiblingTextContent,
  text,
) => Maybe.maybe(
  text,
  compose(concatMethod(text), getSiblingTextContent),
));

const changedSiblingIfExist = curry((action, siblingPath) => compose(
  fireActionIfSiblingNotNil(action),
  path(siblingPath),
));

const concatTextWithSibling = curry((nodeType, pathToText, condition, initialText) => compose(
  concatWithSiblingTextOrReturnInitial(
    condition,
    path(pathToText),
    initialText,
  ),
  isNodeHasSibling(nodeType),
));


const scrollToCustom = (element, params) => {
  const makeScrollTick = (el, {
    x, y, left, top,
  }, delay) => {
    const getToValue = (difference, step = 20) => (Math.abs(difference) >= step
      ? (difference < 0 ? step : -step) : 0);
    window.intervalScrollingCustom = setInterval(() => {
      const { scrollLeft, scrollTop } = el;
      const differenceScrollX = x || left ? scrollLeft - (x || left) : 0;
      const differenceScrollY = y || top ? scrollTop - (y || top) : 0;

      const xTo = getToValue(differenceScrollX);
      const yTo = getToValue(differenceScrollY);
      if (xTo || yTo) {
        element.scrollBy(xTo, yTo);
      } else {
        clearInterval(window.intervalScrollingCustom);
        window.intervalScrollingCustom = null;
      }
    }, delay);
  };

  const {
    x = 0, y = 0, left, top, behavior,
  } = params;
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (window.intervalScrollingCustom) clearInterval(window.intervalScrollingCustom);
  if (!isSafari) {
    // eslint-disable-next-line no-unused-expressions
    (x || y) ? element.scrollTo(x, y) : element.scrollTo({ top, left, behavior });
  } else {
    makeScrollTick(element, params, 1);
  }
};

export {
  concatWithSiblingTextOrReturnInitial,
  changedSiblingIfExist,
  concatTextWithSibling,
  fireActionIfSiblingNotNil,
  scrollToCustom,
};
