import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';

import './style.sass';
import { Grid, DropDown, FormsCollection } from '../../../../../../ui-kit';
import { INVOICES_PAY_TYPES } from '../../../../../../constants/crm';


const AddItemForm = ({
  values, errors, handleChange, setIsFormOpen, setDiscount,
  setDiscountPaymentType, setFieldValue, currencySign, getPaymentType,
}) => (
  <div className="add-invoice-discount__form">
    <Grid.Row
      key="invoice-item-grid-addDiscountForm"
      className="add-invoice-discount__row invoice-discount"
    >
      <Grid.ColData className="add-invoice-discount__col add-invoice-discount__col--details">
        <FormsCollection.Input
          name="discount"
          value={values.discount}
          error={propOr('', 'message', errors.discount)}
          touched
          onChange={(e) => {
            handleChange(e);
            setDiscount(e.target.value || 0);
          }}
          id="add-invoice-discount__field add-invoice-discount__field--discount"
          placeholderJump="Discount"
        />
      </Grid.ColData>
      <Grid.ColData className="add-invoice-discount__col add-invoice-discount__col--type-dropdown">
        <DropDown
          className="controls-group-task"
          name="discount_type"
          label={propOr('', 'label', getPaymentType(values.discount_type.value, currencySign))}
          currentItem={values.discount_type}
          onChange={({ val }) => {
            setFieldValue('discount_type', getPaymentType(val.value, currencySign));
            setDiscountPaymentType(val.value);
          }}
          list={[
            {
              id: 1,
              label: 'Percent (%)',
              value: INVOICES_PAY_TYPES.PERCENT,
            },
            {
              id: 2,
              label: `Flat (${currencySign})`,
              value: INVOICES_PAY_TYPES.FLAT,
            },
          ]}
        />
      </Grid.ColData>
      <Grid.ColData className="add-invoice-discount__col add-invoice-discount__col--cancel-button">
        <button
          type="button"
          className="button--cancel disposable-form__cancel-button"
          onClick={() => {
            setIsFormOpen(false);
            setDiscount(0);
          }}
        >
          Cancel
        </button>
      </Grid.ColData>
    </Grid.Row>
  </div>
);

AddItemForm.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  setIsFormOpen: PropTypes.func.isRequired,
  setDiscount: PropTypes.func.isRequired,
  setDiscountPaymentType: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  currencySign: PropTypes.string,
  getPaymentType: PropTypes.func.isRequired,
};

AddItemForm.defaultProps = {
  currencySign: '$',
};

export default AddItemForm;
