import React from 'react';

import TimeEntryForm from './timeEntryForm';
import TimeEntryHistory from './timeEntryHistory';
import './style.sass';

const TimeEntry = () => (
  <div className="history time-entry">
    <TimeEntryForm />
    <TimeEntryHistory />
  </div>
);
export default TimeEntry;
