import React from 'react';
import PropTypes from 'prop-types';

import { ScrollWrapper, PermissionElement } from '../../ui-kit';
import {
  FiltersRow,
  DragDropArea,
} from './components';
import './style.sass';
import { ACTIONS, MODULES } from '../../constants/pemissions';

const Tasks = ({
  getTasks, onDragEnd, getFilter, projectTasksPendingRequest,
}) => (
  <div className="tasks">
    <PermissionElement
      module={MODULES.PROJECTS}
      action={ACTIONS.READ}
    >
      <ScrollWrapper className="tasks__scroll-wrapper">
        <FiltersRow
          selectedAssignee={getFilter(null, 'assignee')}
          selectedAssigner={getFilter(null, 'created_by')}
          selectedSprintsStatus={getFilter(null, 'sprintsStatus')}
        />
        <DragDropArea
          getTasks={getTasks}
          onDragEnd={onDragEnd}
          isLoading={projectTasksPendingRequest}
        />
      </ScrollWrapper>
    </PermissionElement>
  </div>
);

Tasks.propTypes = {
  match: PropTypes.shape({}),
  sprints: PropTypes.arrayOf(PropTypes.number),
  getTasks: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  getFilter: PropTypes.func.isRequired,
  projectTasksPendingRequest: PropTypes.bool.isRequired,
};

Tasks.defaultProps = {
  match: {},
  sprints: [],
};

export default Tasks;
