import React from 'react';

import './style.sass';
import { useTranslation } from 'react-i18next';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

const currentYear = new Date().getFullYear().toString();

export const Copyright = () => {
  const { t } = useTranslation('common');

  return (
    <div className="copyright">
      <div className="row text-align--center">
        {t(`All right reserved. Avanga © ${currentYear}.`)}
      </div>
    </div>
  );
};
