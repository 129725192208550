import {
  compose, getContext, lifecycle, withHandlers, withProps, withState,
} from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import AttachedFiles from './attachedFiles';
import { uiActions } from '../../../../state/ui';
import { userSelectors } from '../../../../state/user';
import { taskActions, taskSelectors } from '../../../../state/task';
import { tasksActions } from '../../../../state/tasks';
import { preloaderWhileLoading, withFilesUploading } from '../../../../utils/enchancers';
import { PRELOADER_DIMENSION } from '../../../../constants/ui';
import { AVAILABLE_FILE_FORMATS, AVAILABLE_FILE_SIZES } from '../../../../constants/files';

const mapDispatchToProps = ({
  setOpenModal: uiActions.openModal,
  getTaskFilesRequest: taskActions.getTaskFilesRequest,
  updateTask: tasksActions.updateTaskRequest,
});

const mapStateToProps = state => ({
  task: taskSelectors.getActiveTask(state),
  user: userSelectors.getUserData(state),
  filesResult: taskSelectors.getTaskFilesList(state),
  filesEntities: taskSelectors.getTaskFilesEntities(state),
  isTaskHasMoreFiles: taskSelectors.getTaskFilesHasMore(state),
  isPendingFilesGet: taskSelectors.getGetTaskFilesRequestPending(state),
});

const onDeleteFileModalHandler = ({ setSelectedTaskFileId, setOpenModal }) => (fileId) => {
  setOpenModal('deleteTaskFileModal');
  setSelectedTaskFileId(fileId);
};

const setFilesListHandler = ({
  getTaskFilesRequest,
  clientId,
  match: { params: { id, taskId } },
  filesPagination: { limit },
}) => ({ offset, meta = {} }) => {
  getTaskFilesRequest({
    limit,
    offset,
    clientId,
    order: 'desc',
    sortBy: 'created_at',
    project_id: id,
    taskId,
  }, meta);
};


const onSetMoreFilesHandler = ({
  setFilesList, setFilesPagination, filesPagination: { offset },
}) => () => {
  const newOffset = offset + 10;

  setFilesPagination({
    offset: newOffset,
  });
  setFilesList({ offset: newOffset, meta: { isShowMoreRequest: true } });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withFilesUploading({
    availableSize: AVAILABLE_FILE_SIZES.TASKS,
    availableFormats: AVAILABLE_FILE_FORMATS.TASKS,
    onSubmitFiles: ({
      task, updateTask,
      resetFilesIdList,
    }) => files => updateTask({ ...task, taskId: task.id, files },
      {
        isUpdateTasksFiles: true,
        callbacks: { success: resetFilesIdList },
      }),
  }),
  withState('selectedFileId', 'setSelectedTaskFileId', 0),
  withState('filesPagination', 'setFilesPagination', { limit: 10, offset: 0 }),
  withTranslation(['common']),
  getContext({
    clientId: PropTypes.string,
  }),
  withHandlers({
    setFilesList: setFilesListHandler,
  }),
  withHandlers({
    onDeleteFileModal: onDeleteFileModalHandler,
    onSetMoreFiles: onSetMoreFilesHandler,
  }),
  withProps(({ isPendingFilesGet, filesPagination: { offset } }) => ({
    isPendingLoadMore: offset === 0 && isPendingFilesGet,
  })),
  lifecycle({
    componentDidMount() {
      const { id, project_id, clientId } = this.props.task;
      this.props.getTaskFilesRequest({
        taskId: id, project_id, clientId, limit: 10, offset: 0,
      }, { isNotUpdate: true });
    },
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.EXTRA_SMALL,
    alignContainerCenter: true,
    delay: 600,
    isLoading: () => false,
    onAction: ({
      isPendingFilesGet,
      filesPagination: { offset },
    }) => offset === 0 && isPendingFilesGet,
  }),
);
export default enhance(AttachedFiles);
