import classNames from 'classnames';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonsCollection } from '../../../ui-kit';

/**
 *
 * @param onCancel {function}
 * @param isPending {boolean}
 * @returns {JSX.Element}
 *
 */

const SubmitAndCancel = ({ onCancel, isPending, isColm }) => {
  const { t } = useTranslation('common');
  return (
    <div className={classNames({ 'custom-fields__col': isColm }, { 'custom-fields__flat': !isColm })}>
      <ButtonsCollection.ButtonBrill
        className="button-brill--fill custom-fields__add-button"
        type="submit"
        pending={isPending}
      >
        {t('Save')}
      </ButtonsCollection.ButtonBrill>
      <button
        type="button"
        className="button--cancel custom-fields__add-button-cancel"
        onClick={onCancel}
      >
        {t('Cancel')}
      </button>
    </div>);
};

SubmitAndCancel.defaultPropls = {
  onCancel: () => {},
  isPending: false,
  isColm: false,
};

export default memo(SubmitAndCancel);
