import { handleActions } from 'redux-actions';
import { mergeDeep, mergeIn } from '../../utils/helpers/ramdaStateHelpers';

import types from './types';

const reducer = handleActions({
  [types.SET_CONTACTS]: mergeDeep(({
    payload: {
      data, count, hasMore, offset,
    },
  }) => ({
    summary: {
      ...data,
      count,
      hasMore,
      offset,
    },
  })),
  [types.SET_PINNED_CONTACTS]: mergeIn(({ payload: { data } }) => ({
    pinnedContacts: {
      ...data,
    },
  })),
}, {
  summary: {
    entities: {},
    result: [],
  },
  pinnedContacts: {
    entities: {},
    result: [],
  },

});

export default reducer;
