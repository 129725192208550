import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import api from '../api/people.service';
import { PeopleContext } from './context';
import { DEFAULT_REQUEST } from '../constants/people';

export const DEFAULT_DATA = {
  peopleData: [],
  count: 0,
  hasMore: false,
};

export const Provider = ({ children }) => {
  const [peopleData, setPeopleData] = useState(DEFAULT_DATA.peopleData);
  const [count, setCount] = useState(DEFAULT_DATA.count);
  const [hasMore, setHasMore] = useState(DEFAULT_DATA.hasMore);
  const [limit, setLimit] = useState(DEFAULT_REQUEST.limit);
  const [offset, setOffset] = useState(DEFAULT_REQUEST.offset);
  const [searchValue, setSearchValue] = useState(DEFAULT_REQUEST.searchValue);
  const [sortBy, setSortBy] = useState(DEFAULT_REQUEST.sortBy);
  const [order, setOrder] = useState(DEFAULT_REQUEST.order);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const setRequestParams = useCallback((newParams) => {
    if (newParams.limit) {
      setLimit(newParams.limit);
      setShouldRefresh(true);
      setIsLoading(true);
    }
    if (newParams.offset === 0 || newParams.offset) {
      setOffset(newParams.offset);
      setShouldRefresh(true);
      setIsLoading(true);
    }
    if (newParams.searchValue || newParams.searchValue === '') {
      setSearchValue(newParams.searchValue);
      setShouldRefresh(true);
      setIsLoading(true);
    }
    if (newParams.sortBy) {
      setSortBy(newParams.sortBy);
      setShouldRefresh(true);
      setIsLoading(true);
    }
    if (newParams.order) {
      setOrder(newParams.order);
      setShouldRefresh(true);
      setIsLoading(true);
    }
    if (newParams.shouldRefresh) {
      setShouldRefresh(newParams.shouldRefresh);
      setIsLoading(true);
    }
  }, []);


  const value = useMemo(() => ({
    selectedUser,
    setSelectedUser,
    peopleData,
    hasMore,
    count,
    isLoading,
    setRequestParams,
  }), [peopleData, hasMore, count, isLoading, setRequestParams, selectedUser]);

  useEffect(() => {
    if (shouldRefresh) {
      setIsLoading(true);
    }
  }, [shouldRefresh]);

  useEffect(() => {
    const fetchPeopleData = async () => {
      const data = await api.getPeople({
        limit, offset, sortBy, order, name: searchValue,
      });
      setPeopleData(data.data);
      setCount(data.count);
      setHasMore(data.hasMore);
      setIsLoading(false);
    };
    if (shouldRefresh) {
      fetchPeopleData();
      setShouldRefresh(false);
    }
  }, [shouldRefresh]);

  return (
    <PeopleContext.Provider value={value}>
      {children}
    </PeopleContext.Provider>
  );
};
