import { createSelector } from 'reselect';
import {
  identity, memoizeWith, path, pathOr, propOr, filter, equals, compose, prop, values, sort,
} from 'ramda';

const getPipelineByIdFormList = pipelines => memoizeWith(
  identity, pipelineId => propOr(null, pipelineId, pipelines),
);

const getPipelinesEntitySelector = createSelector(
  path(['pipelines', 'summary', 'entities', 'pipelines']),
  identity,
);

const getPipelinesSelector = createSelector(
  getPipelinesEntitySelector,
  values,
);

const getPipelinesIDsSelector = createSelector(
  path(['pipelines', 'summary', 'result']),
  identity,
);

const getPipelineById = createSelector(
  getPipelinesEntitySelector,
  getPipelineByIdFormList,
);

const getPipelineStatusesEntitySelector = createSelector(
  path(['pipelines', 'pipelinesStatuses', 'entities', 'pipelinesStatuses']),
  identity,
);

const getPipelineStatusesAutocompleteSelector = createSelector(
  path(['pipelines', 'pipelineStatusesAutocomplete', 'pipelineStatuses']),
  identity,
);

const getPipelineStatuses = pipelineStatuses => memoizeWith(
  identity, pipelineId => compose(
    sort((a, b) => a.progress - b.progress),
    filter(compose(equals(pipelineId), prop('pipeline_id'))),
    values,
  )(pipelineStatuses),
);

const getPipelineStatusesSelector = createSelector(
  getPipelineStatusesEntitySelector,
  getPipelineStatuses,
);

const getPipelineStatusByIdSelector = createSelector(
  getPipelineStatusesEntitySelector,
  getPipelineByIdFormList,
);


const getPipelinesPendingRequest = createSelector(pathOr(false, ['pending', 'getPipelinesRequest']), identity);
const createPipelinePendingRequest = createSelector(pathOr(false, ['pending', 'addPipelineRequest']), identity);
const editPipelinePendingRequest = createSelector(pathOr(false, ['pending', 'editPipelineRequest']), identity);
const deletePipelinePendingRequest = createSelector(pathOr(false, ['pending', 'deletePipelineRequest']), identity);
const getPipelineStatusesPendingRequest = createSelector(pathOr(false, ['pending', 'getPipelineStatusesRequest']), identity);
const getPipelineStatusesAutocompletePendingRequest = createSelector(pathOr(false, ['pending', 'getPipelineStatusesAutocomplete']), identity);

const getPipelinesCount = createSelector(pathOr(0, ['pipelines', 'summary', 'count']), identity);


export {
  getPipelinesEntitySelector,
  getPipelinesIDsSelector,
  getPipelinesSelector,
  getPipelineById,
  getPipelinesPendingRequest,
  getPipelinesCount,
  getPipelineStatusesSelector,
  getPipelineStatusesPendingRequest,
  createPipelinePendingRequest,
  deletePipelinePendingRequest,
  editPipelinePendingRequest,
  getPipelineStatusesAutocompleteSelector,
  getPipelineStatusesAutocompletePendingRequest,
  getPipelineStatusByIdSelector,
};
