import { compose } from 'recompose';

import withRefs from '../../../../utils/enchancers/withRefs';
import Number from './Number';


const enhance = compose(
  withRefs(),
);
export default enhance(Number);
