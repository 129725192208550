import React from 'react';
import PropTypes from 'prop-types';

import { propOr } from 'ramda';
import {
  ConfirmModal,
} from '../../../../ui-kit';
import { getFullName } from '../../../../utils/helpers/userHelpers';

const PinLeadModal = ({
  isOpen,
  isPending,
  onClose,
  lead,
  onPin,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Pin"
    onAction={onPin}
    onClose={onClose}
    title="Pin lead"
  >
    Are you sure you want to pin
    <b>{getFullName(propOr({}, 'contact', lead))}</b>
    lead?
  </ConfirmModal>
);

PinLeadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onPin: PropTypes.func.isRequired,
  lead: PropTypes.shape({}),
};

PinLeadModal.defaultProps = {
  isPending: null,
  lead: null,
};

export default PinLeadModal;
