import { pathOr, identity, path, memoizeWith, propOr, prop } from 'ramda';
import { createSelector } from 'reselect';

const getAuthNotification = createSelector(pathOr(null, ['ui', 'notification']), identity);
const getRouteTypes = createSelector(pathOr(null, ['ui', 'routeTypes']), identity);
const getPreloader = createSelector(pathOr(false, ['ui', 'preloader']), identity);
const getLeftSidebar = createSelector(pathOr(true, ['ui', 'leftSidebar']), identity);
const getRightSidebar = createSelector(pathOr(true, ['ui', 'rightSidebar']), identity);
const getIsSwipeSideBars = createSelector(pathOr(true, ['ui', 'isSwipeSideBars']), identity);
const getFilterSelector = createSelector(pathOr(true, ['ui', 'filters']), identity);

const getGridsSelector = createSelector((state) => pathOr({}, ['ui', 'grids'], state), identity);

const getPagination = createSelector(
  (state) => pathOr(null, ['ui', 'pagination'], state),
  (pagination) =>
    ({ component, limit, offset }) => {
      const prevPaginationComponentData = pathOr(null, [component], pagination);
      if (prevPaginationComponentData) {
        return prevPaginationComponentData;
      }
      return { limit, offset };
    },
);
const getPaginationParams = createSelector(
  (state) => pathOr(null, ['ui', 'pagination'], state),
  (pagination) =>
    memoizeWith(identity, ({ name, offset, limit }) =>
      pathOr({ offset, limit }, [name], pagination),
    ),
);

const getSort = createSelector(getGridsSelector, (grids) =>
  memoizeWith(identity, (selector, defaultValue = null) =>
    pathOr(defaultValue, [selector, 'sort'], grids),
  ),
);

const getOrder = createSelector(getGridsSelector, (grids) =>
  memoizeWith(identity, (selector, defaultValue = null) =>
    pathOr(defaultValue, [selector, 'order'], grids),
  ),
);

const getFilter = createSelector(getFilterSelector, (filters) =>
  memoizeWith(identity, (filter) => path([filter], filters)),
);

const getUserProfileId = createSelector(pathOr(null, ['ui', 'userProfileId']), identity);

const getTabStatus = createSelector(
  (state) => path(['ui', 'tabs'], state),
  (tabs) => memoizeWith(identity, (tabName) => pathOr(false, [tabName], tabs)),
);

const getModals = createSelector(path(['ui', 'modals']), identity);

const getModal = createSelector(getModals, (modals) =>
  memoizeWith(identity, (modalName) => propOr(false, modalName, modals)),
);

const getModalWrapperForHooks = (name) => (state) => getModal(state)(name);

const getTempFileUploadPending = createSelector(
  pathOr(false, ['pending', 'uploadTempFilesRequest']),
  identity,
);

const getIsPageNotFoundStatus = createSelector(pathOr(false, ['ui', 'pageNotFound']), identity);

const getUserProfileRequestPending = createSelector(
  pathOr(false, ['pending', 'getUserProfileRequest']),
  identity,
);

const getIsWindowFocus = createSelector(pathOr(true, ['ui', 'isFocus']), identity);

const getTextareaMessengerWasChanged = createSelector(
  pathOr(false, ['ui', 'isTextAreaMessengerWasChanged']),
  identity,
);

const getLastUrlBeforeChannels = createSelector(pathOr('/', ['ui', 'urlBeforeChannels']), identity);

const getIsWsError = createSelector(pathOr(false, ['ui', 'isWsError']), identity);

const getFieldValue = createSelector(pathOr({}, ['ui', 'fieldValues']), (state) =>
  memoizeWith(identity, (keyField) => propOr('', keyField, state)),
);

const getFiltersByName = createSelector(path(['ui', 'urlFilters']), (filters) =>
  memoizeWith(identity, (filterName) => pathOr({}, [filterName], filters)),
);

const getAppTitle = createSelector(prop('ui'), prop('title'));

const getAppUnreadMessages = createSelector(prop('ui'), prop('unreadMessages'));

const getVersionSelector = createSelector(path(['ui', 'version']), identity);

const getIsNotificationBannerSelector = createSelector(path(['ui', 'isNotificationBanner']), identity);

export {
  getIsWindowFocus,
  getAuthNotification,
  getPreloader,
  getLeftSidebar,
  getRightSidebar,
  getIsSwipeSideBars,
  getPagination,
  getFilter,
  getModals,
  getModal,
  getSort,
  getOrder,
  getGridsSelector,
  getRouteTypes,
  getTempFileUploadPending,
  getIsPageNotFoundStatus,
  getPaginationParams,
  getTabStatus,
  getUserProfileId,
  getUserProfileRequestPending,
  getTextareaMessengerWasChanged,
  getLastUrlBeforeChannels,
  getIsWsError,
  getFieldValue,
  getFiltersByName,
  getAppTitle,
  getAppUnreadMessages,
  getVersionSelector,
  getModalWrapperForHooks,
  getIsNotificationBannerSelector
};
