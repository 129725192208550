import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonsCollection } from '../../..';
import './style.sass';
import { AVAILABLE_FILE_FORMATS } from '../../../../constants/files';


/**
 *
 * @param isPending {boolean}
 * @param fileUrl {string}
 * @param onUploadFile {function}
 * @param labelText {string}
 * @param name {string}
 * @param id {string}
 * @param touched {boolean}
 * @param className {string}
 * @param value {string}
 * @param onBlur {function}
 * @param onDeleteFile {function}
 * @param error {string}
 * @returns {JSX.Element}
 * @constructor
 */

const AttachImage = ({
  isPending,
  onBlur = () => {},
  name = '',
  labelText = 'Add Image',
  id,
  className = '',
  value = '',
  error = '',
  touched = false,
  onDeleteFile,
  onUploadFile,
  fileUrl,
}) => {
  const isError = useMemo(() => (typeof error === 'string' && error.length !== 0 && touched), [error, touched]);
  const { t } = useTranslation('common');
  return (
    <div className={`attach-image-field ${className}`}>
      <input
        type="file"
        onChange={onUploadFile}
        name={name}
        onBlur={onBlur}
        className="attach-image-field__field"
        id={id}
        accept={AVAILABLE_FILE_FORMATS.LOGO_PROJECT.join(',')}
      />
      {
        value ? (
          <div className="attach-image-field__preview-row">
            <div className="attach-image-field__preview-card">
              <ButtonsCollection.ButtonIcons
                title="Delete image"
                onClick={onDeleteFile}
                className="attach-image-field__preview-delete-button"
              >
                <span className="icon-cross" />
              </ButtonsCollection.ButtonIcons>
              <img alt={t('Logo for project')} className="attach-image-field__preview-image" src={fileUrl || value} />
            </div>
          </div>
        ) : (
          <ButtonsCollection.ButtonBrill
            pending={isPending}
            type="button"
            className="button--sm attach-image-field__button"
          >
            <label className="attach-image-field__button-text" htmlFor={id}>
              {labelText}
            </label>
          </ButtonsCollection.ButtonBrill>
        )
      }
      {isError && (
        <span className="attach-image-field__error field__error-text">
          { error}
        </span>
      )}
    </div>
  );
};

export default AttachImage;
