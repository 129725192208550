import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router';
import { ButtonsCollection, FormsCollection } from '../../../../../ui-kit';
import { AvatarForm } from '..';
import rules from './rules';
import api from '../../api/people.service';
import { callNotification } from '../../../../../utils/helpers/notifies';
import { PEOPLE_PAGES } from '../../api/routes';

import './style.sass';

const FIELD_DEFAULT = '';


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

const InvitePersonForm = () => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const [avatarError, setAvatarError] = useState('');
  const [isPending, setIsPending] = useState(false);

  const {
    control, setValue, handleSubmit,
  } = useForm({
    resolver: yupResolver(rules),
  });

  const onSubmit = useCallback((formValues) => {
    const makeRequest = async (data) => {
      try {
        await api.createPerson({ ...data });
        await api.getPeople({});
        callNotification.success(t('User has been registered!'));
        history.push(PEOPLE_PAGES.PEOPLE.MAIN);
      } catch (e) {
        setIsPending(false);
        callNotification.error(t('User has not been registered!'));
      }
    };
    setIsPending(true);
    makeRequest(formValues);
  }, []);

  const onChange = (name, value) => {
    setValue(name, value);
  };

  const onError = () => {
    callNotification.error(t('User has not been registered!'));
  };

  return (
    <FormsCollection.FormWrapper classNameForm="userFormInvite" handleSubmit={handleSubmit(onSubmit, onError)}>
      <div className="userFormInvite__row userFormInvite__row--avatar">
        <Controller
          name="avatar"
          control={control}
          defaultValue={null}
          render={({
            field,
          }) => (<AvatarForm
            alt="UN"
            name="avatar"
            setFieldValue={onChange}
            errorMessage={avatarError}
            setErrorMessage={setAvatarError}
            {...field}
          />
          )}
        />
      </div>
      <div className="userFormInvite__row">
        <div className="userFormInvite__col">
          <label className="userFormInvite__label" htmlFor="userEdit_email" />
          <Controller
            name="email"
            control={control}
            defaultValue={FIELD_DEFAULT}
            render={({
              field,
              fieldState: {
                error,
              },
            }) => (
              <FormsCollection.Input
                type="email"
                name="email"
                placeholderJump={t('Email address')}
                error={error?.message}
                touched
                id="forgot-password__email"
                {...field}
              />
            )}
          />
        </div>
        <div className="userFormInvite__col">
          <label className="userFormInvite__label" htmlFor="userEdit_username" />
          <Controller
            name="username"
            control={control}
            defaultValue={FIELD_DEFAULT}
            render={({
              field,
              fieldState: {
                error,
              },
            }) => (
              <FormsCollection.Input
                id="userEdit_username"
                touched
                name="username"
                error={error?.message}
                placeholderJump={t('Username')}
                {...field}
              />)}
          />
        </div>
      </div>
      <div className="userFormInvite__row">
        <div className="userFormInvite__col">
          <label className="userFormInvite__label" htmlFor="userEdit_firstName" /><Controller
            name="first_name"
            control={control}
            defaultValue={FIELD_DEFAULT}
            render={({
              field,
              fieldState: {
                error,
              },
            }) => (
              <FormsCollection.Input
                id="userEdit_firstName"
                error={error?.message}
                touched
                name="first_name"
                placeholderJump="First name"
                {...field}
              />)}
          />
        </div>
        <div className="userFormInvite__col">
          <label className="userFormInvite__label" htmlFor="userEdit_lastName" /><Controller
            name="last_name"
            control={control}
            defaultValue={FIELD_DEFAULT}
            render={({
              field,
              fieldState: {
                error,
              },
            }) => (
              <FormsCollection.Input
                id="userEdit_lastName"
                error={error?.message}
                touched
                placeholderJump="Last name"
                {...field}
              />)}
          />
        </div>
      </div>
      <div className="userFormInvite__row">
        <Controller
          name="birthdate"
          control={control}
          defaultValue={FIELD_DEFAULT}
          render={({
            field,
          }) => (
            <FormsCollection.DatePicker
              name="birthdate"
              placeholder={t('Birth date')}
              touched
              dateError={t('You have entered incorrect date')}
              minDate={new Date(1970)}
              maxDate={new Date()}
              {...field}
            />)}
        />
      </div>
      <div className="userFormInvite__row userFormInvite__row--buttons">
        <ButtonsCollection.ButtonBrill
          type="submit"
          pending={isPending}
          className="userFormInvite__submit-button button-brill--fill"
        >
          {t('Create')}
        </ButtonsCollection.ButtonBrill>
        <ButtonsCollection.ButtonBrill
          asLink
          href={PEOPLE_PAGES.PEOPLE.MAIN}
          type="button"
          pending={isPending}
          className="userFormInvite__submit-button modal-button--cancel"
        >
          {t('Cancel')}
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  );
};

export default InvitePersonForm;
