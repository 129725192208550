import QueryString from 'qs';
import React, {
  useEffect, memo, useCallback, useMemo,
} from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import isNil from 'redux-actions/lib/utils/isNil';
import spreadObjectToProps from './spreadObjectToProps';

import './style.sass';
/**
 *
 * @param className {string}
 * @param count {number}
 * @param onResetUrlParam {function}
 * @param onPageChange {function}
 * @param onSetUrlParam {function}
 * @param offsetData {number}
 * @param pagination {object}
 *
 */

const Pagination = memo(({

  className = '', count, onResetUrlParam, onPageChange, onSetUrlParam, offsetData, limit, offset, page, onPath = null,
}) => {
  const history = useHistory();
  const { title } = QueryString.parse(history.location.search, { ignoreQueryPrefix: true });
  const offsetPlusLimit = offset + limit;
  const { t } = useTranslation('common');
  // eslint-disable-next-line max-len
  const maxOpenedPosts = useMemo(() => (offsetPlusLimit > count ? count : offsetPlusLimit), [offset, limit, count]);
  const onGoNextPage = useCallback(() => {
    if (offset + Number(limit) >= count) return;
    const nextPage = Number(page) + 1;
    const nextOffset = limit * (nextPage - 1);
    onSetUrlParam({ page: nextPage });
    if (onPath && !title) {
      onPath(history.location.search);
    }
    if (onPageChange) {
      if (!isNil(offsetData)) {
        const newOffsetNext = limit * (offsetData / limit + 1);
        onPageChange({ offset: newOffsetNext });
      } else onPageChange({ offset: nextOffset });
    }
  }, [count, offset, page, limit, offsetData, onPageChange, onSetUrlParam]);

  const onGoPreviousPage = useCallback(() => {
    if (offset === 0) return;
    const previousPage = Number(page) - 1;
    const previousOffset = limit * (previousPage - 1);
    onSetUrlParam({ page: previousPage });
    if (onPath && !title) {
      onPath(history.location.search);
    }
    if (onPageChange) {
      if (!isNil(offsetData)) {
        const newOffsetPrev = limit * (offsetData / limit - 1);
        onPageChange({ offset: newOffsetPrev });
      } else onPageChange({ offset: previousOffset });
    }
  }, [count, offset, page, limit, offsetData, onPageChange, onSetUrlParam]);

  useEffect(() => onResetUrlParam({ page: null }), []);

  return (
    limit < count && (
    <div className={`pagination ${className}`}>
      <div className="pagination__string">
        <div className="pagination__current-posts">
          {`${offset + 1}-${maxOpenedPosts}`}
        </div>
        <div className="pagination__delimiter">
          {t('of')}
        </div>
        <div className="pagination__count-posts">
          {count}
        </div>
      </div>
      <div className="pagination__controls">
        <button
          type="button"
          onClick={onGoPreviousPage}
          disabled={!offset}
          className="pagination__button pagination__button-prev"
          title={t('Prev page')}
        >
          <span className="icon-pagination-arrow" />
        </button>
        <button
          type="button"
          onClick={onGoNextPage}
          disabled={offsetPlusLimit >= count}
          className="pagination__button"
          title={t('Next page')}
        >
          <span className="icon-pagination-arrow" />
        </button>
      </div>
    </div>
    )
  );
});

export default spreadObjectToProps(Pagination);
