import {
  compose, withHandlers,
} from 'recompose';
import { path } from 'ramda';
import { AvatarForm } from './AvatarForm';
import { withUploadFile } from '../../../../../utils/enchancers';
import { AVAILABLE_FILE_FORMATS, AVAILABLE_FILE_SIZES } from '../../../../../constants/files';


const onCbErrorFileUploadingHandler = ({ setErrorMessage }) => (e) => {
  const errorMessage = path(['response', 'data', 'result', 'error', 'message'], e);
  setErrorMessage(errorMessage || 'Error when uploading file');
};

const enhance = compose(
  withHandlers({
    onCbErrorFileUploading: onCbErrorFileUploadingHandler,
  }),
  withUploadFile({
    availableFormats: AVAILABLE_FILE_FORMATS.LOGO_PROFILE,
    availableSize: AVAILABLE_FILE_SIZES.LOGO_PROFILE,
  }),
);

export default enhance(AvatarForm);
