import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { bpActions } from '@bp/store/bpModule';

export const VariableItem = memo(({ variable, id, onCloseModal }) => {
  const [key, value] = variable;

  const dispatch = useDispatch();

  const onSetVariable = () => {
    dispatch(bpActions.setNewVariable(value));
    onCloseModal();
  };

  return (
    <div className="bpVariables__item">
      <div className="bpVariables__name">
        <button className="bpVariables__variable-button" type="button" onClick={onSetVariable}>
          [{id}] -> {key}
        </button>
      </div>
    </div>
  );
});
