import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';

import {
  ConfirmModal,
} from '../../../../ui-kit';

const DeletePipelineModal = ({
  isOpen,
  isPending,
  onClose,
  onDelete,
  pipeline,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Delete"
    onAction={onDelete}
    onClose={onClose}
    title="Delete Pipeline"
  >
    Are you sure you want to delete
    <b>{prop('title', pipeline)}</b>
    pipeline?
  </ConfirmModal>
);

DeletePipelineModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  pipeline: PropTypes.shape({}).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeletePipelineModal;
