import { createSelector } from 'reselect';
import { identity, pathOr } from 'ramda';

const getCategoriesList = createSelector(pathOr([], ['newTask', 'result', 'categories']), identity);
const getSprintsList = createSelector(pathOr([], ['newTask', 'result', 'sprints']), identity);
const getProjectsList = createSelector(pathOr([], ['newTask', 'result', 'projects']), identity);
const getStatusesList = createSelector(pathOr([], ['newTask', 'result', 'statuses']), identity);


const getCategoriesEntities = createSelector(pathOr({}, ['newTask', 'entities', 'categories']), identity);
const getSprintsEntities = createSelector(pathOr({}, ['newTask', 'entities', 'sprints']), identity);
const getProjectsEntities = createSelector(pathOr({}, ['newTask', 'entities', 'projects']), identity);
const getStatusesEntities = createSelector(pathOr([], ['newTask', 'entities', 'statuses']), identity);

const getSelectedProjectIdCrud = createSelector(pathOr({}, ['newTask', 'selectedProjectId']), identity);
const getSelectedProjectMembersList = createSelector(pathOr([],
  ['newTask', 'selectedProject', 'result', 'members']), identity);
const getSelectedProject = createSelector(state => pathOr({},
  ['newTask', 'selectedProject', 'result'], state), identity);

export {
  getSprintsEntities,
  getCategoriesEntities,
  getProjectsEntities,

  getSprintsList,
  getCategoriesList,
  getProjectsList,
  getSelectedProjectMembersList,
  getSelectedProjectIdCrud,
  getSelectedProject,
  getStatusesEntities,
  getStatusesList,
};
