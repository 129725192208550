import {
  compose, getContext, hoistStatics, withHandlers,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import DeleteFileModal from './deleteFileModal';
import { getModal } from '../../../../state/ui/selectors';
import { closeModal } from '../../../../state/ui/actions';
import { taskActions, taskSelectors } from '../../../../state/task';

const mapStateToProps = state => ({
  isOpen: getModal(state)('deleteTaskFileModal'),
  task: taskSelectors.getActiveTask(state),
});

const mapDispatchToProps = {
  deleteTaskFileRequest: taskActions.deleteTaskFileRequest,
  onCloseModal: () => closeModal('deleteTaskFileModal'),
};

const onDeleteTaskFileHandler = ({
  deleteTaskFileRequest,
  match: { params: { id, taskId } },
  onCloseModal,
  fileId,
  clientId,
}) => () => deleteTaskFileRequest({
  project_id: id,
  taskId,
  fileId,
  clientId,
}, {
  callbacks: {
    success: onCloseModal,
  },
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['tasks', 'common']),
  withRouter,
  getContext({
    clientId: PropTypes.string,
  }),
  withHandlers({
    onDeleteTaskFile: onDeleteTaskFileHandler,
  }),
  hoistStatics,
);

export default enhance(DeleteFileModal);
