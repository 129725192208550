import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { identity } from 'ramda';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router';
import { ButtonsCollection, Header } from '../../../../ui-kit';
import { SettingUserContainer } from './settingUserContainer';
import { callWithPreventEvent } from '../../../../utils/helpers/uiComponentHelpers/DOMhelpers';
import { getFullNameOrUserName } from '../../../../utils/helpers/userHelpers';
import api from '../../../../api/settings.service';
import './style.sass';

/**
 * @returns {JSX.Element}
 * @constructor
 */

export const UserWrapper = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});

  const getSettingUser = useCallback(async (params) => {
    const data = await api.getSettingUserRequest(params);
    if (data && data.user) {
      setUserData(data.user);
    }
  });
  const userName = getFullNameOrUserName(userData);

  useEffect(() => {
    getSettingUser(userId);
  }, []);

  return (
    <>
      <Header hideToggleRightSideBar className="user-header">
        <>
          <h1 className="weight--medium title--xl title-page user-nav__title">
            <ButtonsCollection.ButtonIcons
              title={t('Go back to users')}
              renderMobileButton
              className="user-head-back"
              onClick={() => dispatch(push('/preferences/users/'))}
            >
              <span className="icon-expand-arrow-left" />
            </ButtonsCollection.ButtonIcons>
            Settings - Users - {userName}
          </h1>
          <nav className="header-nav header-nav--user">
            <NavLink
              activeClassName="header-nav__link--active"
              className="header-nav__link"
              to={`/preferences/users/${userId}/`}
            >
              {t('Edit user')}
            </NavLink>
            <NavLink
              disabled
              activeClassName="header-nav__link--active"
              className="header-nav__link header-nav__link--disabled"
              onClick={callWithPreventEvent(identity)}
              to={`/preferences/users/${userId}/personal-permissions/`}
            >
              {t('Personal permissions')}
            </NavLink>
          </nav>
        </>
      </Header>
      <div className="main-container__content main-container__content--profile">
        <SettingUserContainer user={userData} />
      </div>
    </>
  );
};
