import {
  compose, withStateHandlers,
} from 'recompose';

import UserPermissionForm from './userPermissionForm';

const onToggleStateHandler = ({ isOpen }) => () => ({ isOpen: !isOpen });

const enhance = compose(
  withStateHandlers(() => ({ isOpen: false }), {
    onToggle: onToggleStateHandler,
  }),
);

export default enhance(UserPermissionForm);
