import React from 'react';
import PropTypes from 'prop-types';

import { propOr } from 'ramda';
import {
  ConfirmModal,
} from '../../../../ui-kit';
import { getFullName } from '../../../../utils/helpers/userHelpers';

const PinClientModal = ({
  isOpen,
  isPending,
  onClose,
  client,
  onPin,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Pin"
    onAction={onPin}
    onClose={onClose}
    title="Pin client"
  >
    Are you sure you want to pin
    <b>{getFullName(propOr({}, 'contact', client))}</b>
    client?
  </ConfirmModal>
);

PinClientModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onPin: PropTypes.func.isRequired,
  client: PropTypes.shape({}).isRequired,
};

PinClientModal.defaultProps = {
  isPending: false,
};

export default PinClientModal;
