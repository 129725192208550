import React, { useRef, memo, useEffect } from 'react';
import {
  trim, identity, path,
} from 'ramda';

import './style.sass';
import classNames from 'classnames';
import MentionItem from './components/mentionItem';
import { ScrollWrapper } from '..';
import { getWritingWord } from '../../utils/helpers/uiComponentHelpers/caretHelpers';
import { callWithPreventEvent } from '../../utils/helpers/uiComponentHelpers/DOMhelpers';

/**
 * @param className {string}
 * @param members {array}
 * @param isOpen {boolean}
 * @param setRef {function}
 * @param isBegin {boolean}
 * @param selectedMemberId {number}
 * @param isMouseOverMention {boolean}
 * @param activeDropdownMember {number}
 * @param getRef {function}
 * @param onMouseOverMention {function}
 * @returns {JSX.Element}
 * @constructor
 */


const MentionsDropdown = memo(({
  className,
  members = [],
  isOpen,
  setRef,
  isBegin,
  selectedMemberId,
  isMouseOverMention,
  activeDropdownMember,
  getRef,
  onMouseOverMention,
}) => {
  const isSelectedValue = index => (
    (!isBegin && !isMouseOverMention && selectedMemberId === index)
    || (!isMouseOverMention && isBegin && index === 0 && selectedMemberId === 0
      && trim(getWritingWord()).length > 1));

  const onPrevent = () => callWithPreventEvent(identity);
  const onMouseOver = () => onMouseOverMention(true);
  const onMouseLeave = () => onMouseOverMention(false);

  const privateRef = useRef({
    activeDropdownMember, isOpen,
  });

  useEffect(() => {
    privateRef.current = {
      activeDropdownMember, isOpen,
    };
  }, [activeDropdownMember, isOpen]);

  const prevProps = privateRef.current;

  useEffect(() => {
    if (activeDropdownMember !== prevProps.activeDropdownMember) {
      const itemTop = path(['children', activeDropdownMember, 'offsetTop'])(getRef('mentionsContainer'));
      getRef('scrollWrapper').scrollTop(itemTop);
    }
    if (isOpen !== prevProps.isOpen) {
      getRef('scrollWrapper').scrollToTop();
    }
  }, [activeDropdownMember, prevProps, getRef, isOpen]);
  return (
    <div
      role="none"
      onMouseDown={onPrevent}
      onMouseOver={onMouseOver}
      onFocus={onMouseOver}
      onMouseLeave={onMouseLeave}
      className={classNames('drop-down-mentions', className, { 'drop-down-mentions--begin': isBegin, 'drop-down-mentions--allow-hover': isMouseOverMention, 'drop-down-mentions--open': isOpen })}
    >
      <ScrollWrapper
        className="dropDown-select__scroll-wrapper"
        autoHeight
        autoHide
        renderTrackVertical={({ style, ...props }) => (
          <div style={{ ...style }} {...props} className="dropDown-select__track" />
        )}
        refCustom={node => setRef('scrollWrapper', node)}
        renderThumbVertical={props => <div {...props} className="dropDown-select__thumb" />}
        renderTrackHorizontal={() => <span />}
      >
        <ul className="drop-down-mentions__list " ref={node => setRef('mentionsContainer', node)}>
          {members.map((item, index) => (
            <MentionItem
              key={`member-chat-${item.id}`}
              index={index}
              isSelected={isSelectedValue(index)}
              itemId={item.id}
            />
          ))}
        </ul>
      </ScrollWrapper>
    </div>
  );
});

export default MentionsDropdown;
