import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { ButtonsCollection, FormsCollection } from '../../../../../ui-kit';
import { AvatarForm, PeopleCustomFields, PeopleFields } from '..';
import api from '../../api/people.service';
import { callNotification } from '../../../../../utils/helpers/notifies';
import { PEOPLE_PAGES } from '../../api/routes';
import rules from './rules';
import './style.sass';
import { usePeopleContext } from '../../context/usePeopleContext';
import { editCustomFieldValueRequest } from '../../../../../state/customFields/actions';
import { getImageParams } from '../../../../../utils/helpers/requestHelpers';
import { EDIT_PERSON_FIELDS } from '../../constants/people';


const DEFAULT_DELAY = 500;
const CUSTOM_FIELDS = 'customFields';
const REGULAR_FIELDS = ['avatar', 'email', 'first_name', 'last_name', 'username', 'birthdate', 'vacation'];

const extractRegularValues = (formValues = {}) => {
  const regularValues = {};
  if (formValues) {
    REGULAR_FIELDS.forEach((key) => {
      if (formValues[key]) regularValues[key] = formValues[key];
    });
  }
  return regularValues;
};

const extractCustomValues = (formValues = {}, initialUserValues = {}) => {
  const customValues = [];
  if (formValues && initialUserValues && initialUserValues.customFields) {
    initialUserValues.customFields.forEach(({ id, model_id, field_id }) => {
      if (formValues[id]) customValues.push({ model_id, field_id, value: formValues[id] });
    });
  }
  return customValues;
};

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

const EditPersonForm = () => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { selectedUser, setSelectedUser } = usePeopleContext();
  const [avatarError, setAvatarError] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [userAvatar, setUserAvatar] = useState(null);

  const {
    control, setValue, handleSubmit,
  } = useForm({
    resolver: yupResolver(rules),
  });

  const onClose = useCallback(() => {
    history.push(PEOPLE_PAGES.PEOPLE.MAIN);
    setSelectedUser({});
  }, []);

  const onSubmit = useCallback((formValues) => {
    const makeRequest = async (data) => {
      try {
        await api.updatePerson({ id: userId, ...extractRegularValues(data) });
        dispatch(
          editCustomFieldValueRequest(
            { customFieldsData: extractCustomValues(data, selectedUser) },
          ),
        );
        onClose();
        callNotification.success(t('User has been updated!'));
      } catch (e) {
        setIsPending(false);
        callNotification.error(t('User has not been updated!'));
      }
    };
    setIsPending(true);
    makeRequest(formValues);
  }, [selectedUser]);

  const resetAvatar = useCallback(async () => {
    const makeRequest = (data) => {
      api.updatePerson({ id: userId, avatar: null });
      dispatch(
        editCustomFieldValueRequest(
          { customFieldsData: extractCustomValues(data, selectedUser) },
        ),
      );
    };
    setIsPending(true);
    makeRequest();
  }, [selectedUser]);

  const onChangeHandler = useCallback((name, value) => {
    setValue(name, value);
  }, [setValue]);

  const onError = useCallback(() => {
    callNotification.error(t('User has not been updated!'));
  }, []);

  const onChangeAvatarHandler = (e) => {
    setValue('avatar', e.target.value);
  };
  useEffect(() => {
    if (selectedUser && Object.keys(selectedUser).length !== 0) {
      Object.entries(selectedUser).forEach(([key, value]) => {
        if (key !== CUSTOM_FIELDS) setValue(key, value);
      });
    }
    setUserAvatar(selectedUser?.avatar?.id);
  }, [selectedUser]);

  return (
    <FormsCollection.FormWrapper classNameForm="userForm" handleSubmit={handleSubmit(onSubmit, onError)}>
      <div className="userForm__row userForm__row--avatar">
        <AvatarForm
          name="avatar"
          alt={selectedUser.name}
          fileUrl={getImageParams(selectedUser.avatar)}
          setFieldValue={onChangeHandler}
          errorMessage={avatarError}
          setErrorMessage={setAvatarError}
          onChange={onChangeAvatarHandler}
          value={userAvatar}
          setUserAvatar={setUserAvatar}
          userAvatar={selectedUser?.avatar?.id ?? 0}
          resetAvatar={resetAvatar}
        />
      </div>
      <PeopleFields fields={EDIT_PERSON_FIELDS} control={control} />
      <PeopleCustomFields
        fields={selectedUser.customFields}
        setValue={setValue}
        control={control}
      />
      <div className="userForm__row userForm__row--buttons">
        <ButtonsCollection.ButtonBrill
          type="submit"
          delay={DEFAULT_DELAY}
          isPending={isPending}
          className="userForm__submit-button button-brill--fill save-btn"
        >
          {t('Save')}
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  );
};


export default EditPersonForm;
