import {
  compose, getContext, lifecycle, withHandlers, withState,
} from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';
import CommentsHistory from './commentsHistory';
import { taskActions, taskSelectors } from '../../../../../../state/task';
import { preloaderWhileLoading } from '../../../../../../utils/enchancers';
import { PRELOADER_DIMENSION } from '../../../../../../constants/ui';

const LIMIT_COMMENTS = 5;
const OFFSET_COMMENTS_STEP = 5;

const mapDispatchToProps = {
  getTaskCommentsRequest: taskActions.getTaskCommentsRequest,
};

const mapStateToProps = state => ({
  task: taskSelectors.getActiveTask(state),
  commentsList: taskSelectors.getTaskCommentsList(state),
  isPendingShowMore: taskSelectors.getShowMoreTaskCommentsRequestPending(state),
  isPendingGetComments: taskSelectors.getTaskCommentsRequestPending(state),
  isTaskHasMoreComments: taskSelectors.getHasMoreTaskComments(state),
});

const onChangeHandler = ({ setFieldValue }) => ({ target: { value } }) => setFieldValue(value);

const onSendHandler = ({ addCommentRequest, value, task: { project_id, id } }) => (e) => {
  e.preventDefault();

  addCommentRequest({
    projectId: project_id,
    taskId: id,
    content: value,
  });
};

const setCommentsListHandler = ({
  getTaskCommentsRequest,
  clientId,
  match: { params: { id, taskId } },
  commentsPagination: { limit },
}) => ({ offset, meta = {} }) => {
  getTaskCommentsRequest({
    limit,
    offset,
    order: 'desc',
    sortBy: 'created_at',
    projectId: id,
    taskId,
    clientId,
  }, meta);
};

const onSetMoreCommentsHandler = ({
  setCommentsList, setCommentsPagination, commentsPagination: { offset },
}) => () => {
  const newOffset = offset + OFFSET_COMMENTS_STEP;

  setCommentsPagination({
    offset: newOffset,
  });
  setCommentsList({ offset: newOffset, meta: { isShowMoreRequest: true } });
};

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('value', 'setFieldValue', ''),
  withState('commentsPagination', 'setCommentsPagination', { limit: LIMIT_COMMENTS, offset: 0 }),
  withRouter,
  getContext({
    clientId: PropTypes.string,
  }),
  withHandlers({
    setCommentsList: setCommentsListHandler,
  }),
  withHandlers({
    onChange: onChangeHandler,
    onSend: onSendHandler,
    onSetMoreComments: onSetMoreCommentsHandler,
  }),
  lifecycle({
    componentDidMount() {
      this.props.setCommentsList({ offset: 0 });
    },
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.EXTRA_SMALL,
    alignContainerCenter: true,
    delay: 600,
    isLoading: () => false,
    onAction: ({ isPendingGetComments }) => isPendingGetComments,
  }),
);
export default enhancer(CommentsHistory);
