import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import './style.sass';
import ButtonLabel from './ButtonLabel';
import { HeaderTitle } from '../../ui-kit';
import { ButtonsCollection, DropDown, PermissionElement } from '../../../../../ui-kit';
import { ACTIONS, MODULES } from '../../../../../constants/pemissions';
import { Header } from '../../../../../containers';
import PeopleContainer from '../peopleContainer';
import CustomFieldsModal from '../../../../../containers/customFields';
import { CUSTOM_FIELDS_MODELS } from '../../../../../constants/customFields';
import { getCustomFieldsRequest } from '../../../../../state/customFields/actions';
import { PEOPLE_PAGES } from '../../api/routes';
import { usePeopleContext } from '../../context/usePeopleContext';
import { WINDOW_WIDTH } from '../../../../../constants/ui';
import { useWindowWidth } from '../../../../../utils/hooks';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

export const PeopleWrapper = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const windowWidth = useWindowWidth();
  const { setRequestParams } = usePeopleContext();
  const [isOpen, setIsOpen] = useState(false);

  const setOpenModal = useCallback(() => setIsOpen(true), []);

  const onModalClose = useCallback(() => {
    setRequestParams({ shouldRefresh: true });
    dispatch(getCustomFieldsRequest());
  }, [setRequestParams, getCustomFieldsRequest]);

  return (<>
    <Header hideToggleRightSideBar className="user-header">
      <HeaderTitle title={t('People')} />
      <>
        <PermissionElement module={MODULES.PEOPLE} action={ACTIONS.CREATE}>
          {windowWidth > WINDOW_WIDTH.MEDIUM ? (
            <ButtonsCollection.ButtonBrill
              asLink
              href={PEOPLE_PAGES.PERSON.INVITE}
              type="button"
              className="user-header__button-invite"
            >
              {t('Create user')}
            </ButtonsCollection.ButtonBrill>
          ) : (
            <ButtonsCollection.ButtonBrill
              asLink
              href={PEOPLE_PAGES.PERSON.INVITE}
              type="button"
              renderMobileButton
              className="user-header__button-invite"
            >
              {t('Create user')}
            </ButtonsCollection.ButtonBrill>
          )}
        </PermissionElement>
        <PermissionElement module={MODULES.CUSTOM_FIELDS} action={ACTIONS.CREATE}>
          <DropDown
            className="project-header-control"
            name="project-header"
            label={(
              <ButtonsCollection.ButtonIcons
                title={t('Control buttons for people')}
                className="header-col__project-burger"
              >
                <span className="icon-burger-dots-gorizont" />
              </ButtonsCollection.ButtonIcons>
        )}
            list={
          [
            {
              id: 0,
              label: (
                <ButtonLabel labelText={t('Custom fields')} />
              ),
              onClick: setOpenModal,
            },
          ]
        }
          />
        </PermissionElement>
      </>
    </Header>
    <div className="main-container__content">
      <PeopleContainer />
    </div>
    <CustomFieldsModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      customFieldModel={CUSTOM_FIELDS_MODELS.PEOPLE}
      customCallback={onModalClose}
    />
  </>);
};
