import React, { useEffect, useRef, useState } from 'react';
import './style.sass';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Controller } from 'react-hook-form';
import { ButtonsCollection, FormsCollection } from '../../../../../ui-kit';


const DELAY = 800;

/**
 *
 * @param values{Object}
 * @param pending{boolean}
 * @param control{Object}
 * @param renderEmailNotificationBlock(function)
 * @returns {JSX.Element}
 * @constructor
 */

export const FormProfile = ({
  user,
  pending,
  control,
  renderEmailNotificationBlock,
}) => {
  const [isDisabled, setIsDisabled] = useState(null);
  const { t } = useTranslation('common');
  const history = useHistory();
  const goToPreviousPath = () => {
    history.goBack();
  };
  const onRemoveDisabled = () => {
    setIsDisabled(false);
  };

  const privateRef = useRef({ pending });

  useEffect(() => {
    privateRef.current = { pending };
  }, [pending]);

  const prevProps = privateRef.current;

  useEffect(() => {
    if (pending && prevProps.pending !== pending) {
      setIsDisabled(true);
    }
  }, [pending, prevProps]);

  return (
    <div className="form-profile">
      <div className="form-profile__row">
        <Controller
          name="first_name"
          control={control}
          defaultValue={user?.first_name || ''}
          render={({
            touched = true, field: { onChange, value, onBlur }, fieldState: { error },
          }) => (
            <FormsCollection.Input
              name="first_name"
              value={value}
              error={error?.message.message}
              disabled={isDisabled}
              onChange={onChange}
              onBlur={onBlur}
              touched={touched}
              id="form-profile__field-first-name"
              placeholderJump="First name"
            />
          )}
        />
      </div>
      <div className="form-profile__row">
        <Controller
          name="last_name"
          control={control}
          defaultValue={user?.last_name || ''}
          render={({
            touched = true, field: { onChange, value, onBlur }, fieldState: { error },
          }) => (
            <FormsCollection.Input
              name="last_name"
              value={value}
              error={error?.message.message}
              onBlur={onBlur}
              touched={touched}
              disabled={isDisabled}
              onChange={onChange}
              id="form-profile__field-last-name"
              placeholderJump="Last name"
            />
          )}
        />
      </div>
      <div className="form-profile__row">
        <Controller
          name="username"
          control={control}
          defaultValue={user?.username || ''}
          render={({
            touched = true, field: { onChange, value, onBlur }, fieldState: { error },
          }) => (
            <FormsCollection.Input
              name="username"
              disabled={isDisabled}
              value={value}
              error={error?.message.message}
              onBlur={onBlur}
              touched={touched}
              onChange={onChange}
              id="form-profile__field-user-name"
              placeholderJump="Username"
            />
          )}
        />
      </div>
      {renderEmailNotificationBlock()}
      <div className="form-profile__row form-profile__row--buttons">
        <ButtonsCollection.ButtonBrill
          type="submit"
          pending={pending}
          delay={DELAY}
          onEndDelay={onRemoveDisabled}
          className="button-brill--fill form-profile__control-button form-profile__submit-button button--md"
        >
          {t('Save')}
        </ButtonsCollection.ButtonBrill>
        <button
          type="button"
          onClick={goToPreviousPath}
          className="form-profile__cancel-button form-profile__control-button"
        >
          {t('Cancel')}
        </button>
      </div>
    </div>
  );
};
