import * as settingUsersActions from './actions';
import * as settingUsersSelectors from './selectors';
import * as settingUsersOperations from './operations';
import settingUsersTypes from './types';
import reducer from './reducers';

export {
  settingUsersActions,
  settingUsersTypes,
  settingUsersSelectors,
  settingUsersOperations,
};

export default reducer;
