import React from 'react';
import PropTypes from 'prop-types';
import {
  ModalWindow,
} from '../../../ui-kit';
import { ClientForm } from '../components';
import './style.sass';

const ModalView = ({
  isOpen,
  onCloseModal,
  title,
  ...props
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    modalName="Client"
    title={title}
    className="clients-modal"
  >
    <ClientForm {...props} />
  </ModalWindow>
);

ModalView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  touched: PropTypes.shape({}).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  titleAction: PropTypes.string.isRequired,
};

ModalView.defaultProps = {
  isPending: null,
};

export default ModalView;
