import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { uiActions } from '@/state/ui';
import { getModalWrapperForHooks } from '@/state/ui/selectors';
import { successActionCallback } from '@/utils/helpers/actionCallbacks';
import { bpSelectors, bpActions } from '@bp/store/bpModule';

export const useDeleteNodeModal = () => {
  const dispatch = useDispatch();
  const {
    params: { processId },
  } = useRouteMatch();
  const isOpen = useSelector(getModalWrapperForHooks('deleteNodeModal'));
  const selectedEvent = useSelector(bpSelectors.getSelectedEvent);
  const selectedNode = useSelector(bpSelectors.getSelectedNode);

  const onClose = useCallback(() => {
    dispatch(uiActions.closeModal('deleteNodeModal'));
  }, [dispatch]);

  const onDelete = useCallback(() => {
    dispatch(
      bpActions.deleteBusinessProcessNodeRequest(
        {
          nodeId: selectedNode.id,
          processId,
        },
        successActionCallback(() => {
          onClose();
        }),
      ),
    );
  }, [selectedNode, dispatch]);

  return {
    isOpen,
    selectedEvent,
    onDelete,
    onClose,
  };
};
