import { path, pathOr } from 'ramda';
// import PropTypes from 'prop-types';
import { POSITIONS, STATUSES } from './constants';

const candidateFields = candidate => ({
  first_name: pathOr('', ['first_name'], candidate),
  last_name: pathOr('', ['last_name'], candidate),
  email: pathOr('', ['email'], candidate),
  phone: pathOr('', ['phone'], candidate),
  city: pathOr('', ['city'], candidate),
  position: pathOr(POSITIONS[0].value, ['position'], candidate),
  status: pathOr(STATUSES[0].value, ['status'], candidate),
  salary: pathOr('', ['salary'], candidate),
  comments: path(['comments'], candidate),
  date_of_birthday: path(['date_of_birthday'], candidate),
  first_contact: pathOr(new Date(), ['first_contact'], candidate),
  facebook_url: pathOr('', ['facebook_url'], candidate),
  instagram_url: pathOr('', ['instagram_url'], candidate),
  linkedin_url: pathOr('', ['linkedin_url'], candidate),
  skype: pathOr('', ['skype'], candidate),
  cv: path(['cv'], candidate),
  conclusion: pathOr('', ['conclusion'], candidate),
  file_link: pathOr('', ['file_link'], candidate),
});

/* const candidateInterface = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  city: PropTypes.string,
  position: PropTypes.number,
  status: PropTypes.number,
  salary: PropTypes.string,
  comments: PropTypes.string,
  date_of_birthday: PropTypes.string,
  first_contact: PropTypes.string,
  facebook_url: PropTypes.string,
  instagram_url: PropTypes.string,
  linkedin_url: PropTypes.string,
  skype: PropTypes.string,
  cv: PropTypes.number,
  conclusion: PropTypes.string,
  file_link: PropTypes.string,
  hrComments: PropTypes.instanceOf(
    PropTypes.shape({
      comment: PropTypes.string,
    }),
  ),
  files: PropTypes.instanceOf(Array),
}; */

export {
  // eslint-disable-next-line import/prefer-default-export
  candidateFields,
};
