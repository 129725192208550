import { schema } from 'normalizr';

const authorSchema = new schema.Entity('author');

const timeEntriesSchema = [new schema.Entity('time_entries', {
  user: new schema.Entity('user'),
})];

const taskCommentsSchema = [new schema.Entity('comments', {
  author: new schema.Entity('author'),
})];

const taskSchema = [new schema.Entity('task', {
  author: new schema.Entity('author'),
  assigneeUser: new schema.Entity('assigneeUser'),
  watchers: new schema.Array((new schema.Entity('watchers'))),
})];

const timeEntrySchema = [new schema.Entity('timeEntry', {
  user: new schema.Entity('user'),
})];

const taskFilesSchema = [new schema.Entity('files')];

export {
  taskCommentsSchema,
  timeEntriesSchema,
  authorSchema,
  taskSchema,
  timeEntrySchema,
  taskFilesSchema,
};
