import {
  branch,
  compose, renderNothing, withHandlers,
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isNil } from 'ramda';

import PinTaskModal from './unpinContactModal';
import { uiActions, uiSelectors } from '../../../../state/ui';
import { contactsSelectors, contactsActions } from '../../../../state/contacts';

const mapDispatchToProps = {
  onCloseModal: uiActions.closeModal,
  unpinContactRequest: contactsActions.unpinContactRequest,
  getPinnedContacts: contactsActions.getPinnedContactsRequest,
};

const mapStateToProps = (state, { selectedContactId }) => ({
  isOpen: uiSelectors.getModal(state)('unpinContactModal'),
  pinnedContact: contactsSelectors.getPinnedContactById(state)(selectedContactId),
});


const onUnpinHandler = ({
  unpinContactRequest, onClose, selectedContactId, getPinnedContacts,
}) => () => unpinContactRequest({
  contactId: selectedContactId,
}, {
  callbacks: {
    success: () => {
      onClose();
      getPinnedContacts();
    },
  },
});

const onCloseHandler = ({ onCloseModal }) => () => onCloseModal('unpinContactModal');

const enhance = compose(
  branch(
    ({ selectedContactId }) => isNil(selectedContactId),
    renderNothing,
  ),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withHandlers({
    onClose: onCloseHandler,
  }),
  withHandlers({
    onUnpin: onUnpinHandler,
  }),
);


export default enhance(PinTaskModal);
