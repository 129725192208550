import * as yup from 'yup';
import { translateHelpers } from '../../../../utils/helpers';

const rules = yup.object().shape({
  members: yup
    .array()
    .required({
      field: 'members',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Members'),
      },
    }),
});

export default rules;
