import React from 'react';
import PropTypes from 'prop-types';
import ClientsItem from '../clientsItem';
import './style.sass';

const ClientsList = ({
  t,
  clients,
  onEditClientModal,
  onDeleteClientModal,
  onPinClientModal,
  onUnpinClientModal,
  isPinned,
  activeTabClientId,
  toggleActiveTab,
}) => (
  <div
    className={`clients-list ${activeTabClientId && 'clients-list__has-selected-client'}`}
  >
    <div className="clients-item clients-item--header">
      <div className="clients-item__row">
        <div className="clients-item__col" />
        <div className="clients-item__col clients-item__username">
            Username
        </div>
        <div className="clients-item__col clients-item__position">
            Position
        </div>
        <div className="clients-item__col clients-item__company">
            Company
        </div>
        <div className="clients-item__col clients-item__email">
            Email
        </div>
        <div className="clients-item__col clients-item__email">
          Phone
        </div>
        <div className="clients-item__col clients-item__service">
            Service
        </div>
        <div className="clients-item__col clients-item__control" />
      </div>
    </div>
    {
        clients.map(client => (
          <ClientsItem
            key={client}
            t={t}
            onEditClientModal={onEditClientModal}
            onDeleteClientModal={onDeleteClientModal}
            onPinClientModal={onPinClientModal}
            onUnpinClientModal={onUnpinClientModal}
            clientId={client}
            isPinned={isPinned}
            isOpen={activeTabClientId === client}
            toggleActiveTab={toggleActiveTab}
          />
        ))
      }
  </div>
);

ClientsList.propTypes = {
  t: PropTypes.func.isRequired,
  clients: PropTypes.instanceOf(Array).isRequired,
  onEditClientModal: PropTypes.func.isRequired,
  onDeleteClientModal: PropTypes.func.isRequired,
  onPinClientModal: PropTypes.func.isRequired,
  onUnpinClientModal: PropTypes.func.isRequired,
  isPinned: PropTypes.bool,
  activeTabClientId: PropTypes.number,
  toggleActiveTab: PropTypes.func.isRequired,
};

ClientsList.defaultProps = {
  isPinned: false,
  activeTabClientId: null,
};

export default ClientsList;
