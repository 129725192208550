import React from 'react';
import classNames from 'classnames';
import { tasks } from '../../constants';
import './style.sass';

const getPriority = id => Object.values(tasks.PRIORITY)[id];

/**
 *
 * @param className {string}
 * @param status {number}
 * @returns {JSX.Element}
 * @constructor
 */

export const LabelPriorityTask = ({ className = '', status = 0 }) => {
  const { title, color } = getPriority(status);

  return (
    <div
      title={title}
      className={classNames('priority-label', className)}
      style={{ color }}
    />
  );
};
