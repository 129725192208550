import React from 'react';

import './style.sass';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ButtonsCollection } from '..';

/**
 *
 * @param status {boolean | number}
 * @param className {string}
 * @param onClick {function}
 * @param title {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const Favorite = ({
  status = false, onClick = () => { }, title = '', className = '',
}) => {
  const { t } = useTranslation('common');

  return (
    <ButtonsCollection.ButtonIcons
      className={classNames('favorite-button', className, { 'favorite-button--active': status })}
      onClick={onClick}
      title={`${status ? t(`Remove ${title} from favorite`) : t(`Add ${title} to favorite`)}`}
    >
      <span className={status ? 'icon-star-empty-project--active' : 'icon-star-empty-project'} />
    </ButtonsCollection.ButtonIcons>
  );
};
