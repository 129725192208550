import {
  take,
  fork,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';

import { sagasManager } from '../../utils';
import { userActions, userSelectors, userTypes } from '.';
import { userHelpers } from '../../utils/helpers';
import { messengerActions } from '../messenger';
import { usersActions } from '../users';
import { settingUsersActions } from '../settingUsers';
import {
  connectSocketToServer,
  subscribeChannel,
  listenEvent,
} from '../../utils/helpers/webSocket/state/actions';
import { LAST_CHANNEL_MESSENGER_STORAGE } from '../../constants/messenger';
import { setUsersUpdateMiddleware } from '../../utils/helpers/stateHelpers';
import { projectActions } from '../project';

function* logoutFlow() {
  while (true) {
    yield take(userTypes.LOGOUT);
    yield localStorage.removeItem(LAST_CHANNEL_MESSENGER_STORAGE);
    yield userHelpers.userLogout();
  }
}

function subscribeToUserWsChannels() {
  sagasManager.addSagaToRoot(function* subscribeToCommon() {
    const activeChannel = yield select(state => state.messenger.activeChannel);
    const pathname = yield select(state => state.router.location.pathname);

    yield put(subscribeChannel({ topic: 'common' }));
    yield put(listenEvent({
      topic: 'common',
      event: 'newMessage',
      action: messengerActions.emitNewMessage,
    }));
    yield put(listenEvent({
      topic: 'common',
      event: 'readMessages',
      action: messengerActions.readMessagesWs,
    }));
    yield put(listenEvent({
      topic: 'common',
      event: 'deleteMessage',
      action: messengerActions.emitDeleteMessage,
    }));
    yield put(listenEvent({
      topic: 'common',
      event: 'userOnlineStatus',
      action: usersActions.emitUsersStatus,
    }));
    yield put(listenEvent({
      topic: 'common',
      event: 'updateChannel',
      action: setUsersUpdateMiddleware(messengerActions.updateChannelBySocket, { isNotClear: true, pathEntities: ['channel', 'members'] }),
    }));
    yield put(listenEvent({
      topic: 'common',
      event: 'emitSettingsUser',
      action: user => settingUsersActions.setSettingUser({ user }),
    }));
    yield put(listenEvent({
      topic: 'common',
      event: 'updateChannelAfterUserUpdateOrDelete',
      // eslint-disable-next-line consistent-return
      action: () => {
        if (activeChannel && activeChannel.id) {
          return messengerActions.getChannelRequest(activeChannel.id);
        }
      },
    }));
    yield put(listenEvent({
      topic: 'common',
      event: 'updateProjectAfterUserUpdateOrDelete',
      // eslint-disable-next-line consistent-return
      action: () => {
        if (/\/projects\/\d*\//gi.test(pathname)) {
          const projectId = pathname.split('/')[2];
          return projectActions.getProjectRequest({ projectId });
        }
      },
    }));
    yield put(listenEvent({
      topic: 'common',
      event: 'newChannel',
      action: () => messengerActions.getGroupChannelsRequest({}),
    }));
    yield put(listenEvent({
      topic: 'common',
      event: 'deleteChannel',
      action: messengerActions.deleteChannelBySocketWatcher,
    }));
  });
}

function* userIsValidFlow({ payload }) {
  const { salt } = payload;
  yield localStorage.setItem('userSalt', salt);
  yield delay(100);
  const isUser = yield select(userSelectors.getIsValidUser);
  if (!isUser) {
    yield put(connectSocketToServer());
    yield put(userActions.setUserIsValidFlag({}));
  }
}

function* userIsInvalidFlow() {
  while (true) {
    yield take(userTypes.SET_USER_IS_INVALID);
    yield userHelpers.userLogout();
  }
}

function* userIsValidFlowWatcher() {
  yield takeLatest(userTypes.SET_USER_IS_VALID, userIsValidFlow);
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(logoutFlow);
  yield fork(userIsInvalidFlow);
  yield fork(userIsValidFlowWatcher);
});

export {
  // eslint-disable-next-line import/prefer-default-export
  subscribeToUserWsChannels,
};
