import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Label, ButtonsCollection } from '../../..';
import Number from './components';
import './style.sass';

const arraySeconds = [...Array(60).keys()];
const arrayHours = [...Array(24).keys()];
const SLIDERS_CONFIG_VAL = {
  dots: false,
  infinite: true,
  speed: 250,
  vertical: true,
  slidesToShow: 3,
  loop: true,
  initialSlide: 0,
  arrows: true,
};

/**
 *
 * @param convertTimeToString {function}
 * @param slidersConfig {object}
 * @param getRef {function}
 * @param className {string}
 * @param name {string}
 * @param setRef {function}
 * @param error {string}
 * @param touched {boolean}
 * @param onBlur {function}
 * @param disabled {boolean}
 * @returns {JSX.Element}
 * @constructor
 */

const TimePickerComponent = ({
  getRef = () => {},
  className = '',
  name = '',
  setRef = () => {},
  error = '',
  touched = false,
  onBlur = () => {},
  disabled = false,
  onChange = () => {},
  value = {
    hours: 0,
    minutes: 0,
  },
  slidersConfig = SLIDERS_CONFIG_VAL,
}) => {
  const [isPicker, setIsPicker] = useState(false);
  const [currentMinute, setCurrentMinute] = useState(0);
  const [currentHour, setCurrentHour] = useState(0);
  const [inFocus, setInFocus] = useState(false);
  const { t } = useTranslation('common');

  const convertTimeToString = val => (val.toString().length < 2 ? `0${val}` : val);

  const setFocusStateHandler = (val) => {
    setInFocus(val);
  };

  const inputFieldGroup = useRef(null);

  const clickOutsideHandler = (e) => {
    const inputFieldGroupRef = inputFieldGroup.current;
    if (isPicker && !inputFieldGroupRef?.contains(e.target)) {
      setIsPicker(false);
    }
  };

  const changeHourSliderHandler = (oldHour, newHour) => {
    setCurrentHour(newHour, getRef);
    const fieldElement = getRef('fieldHour').inputElement;
    fieldElement.value = convertTimeToString(newHour);
  };

  const changeMinuteSliderHandler = (oldMinute, newMinute) => {
    setCurrentMinute(newMinute, getRef);
    const fieldElement = getRef('fieldMinute').inputElement;
    fieldElement.value = convertTimeToString(newMinute);
  };

  const changeMinuteHandler = (e, updateSlider = true) => {
    if (updateSlider) {
      getRef('minuteSlider').slickGoTo(e.value, true);
    }
    setCurrentMinute(e.value);
  };

  const changeHourHandler = (e, updateSlider = true) => {
    if (updateSlider) {
      getRef('hoursSlider').slickGoTo(e.value, true);
    }
    setCurrentHour(e.value);
  };

  const togglePicker = () => {
    const fieldMinute = getRef('fieldMinute');
    const fieldHour = getRef('fieldHour');
    fieldMinute.inputElement.value = convertTimeToString(currentMinute);
    fieldHour.inputElement.value = convertTimeToString(currentHour);
    getRef('hoursSlider').slickGoTo(currentHour, true);
    getRef('minuteSlider').slickGoTo(currentMinute, true);
    setIsPicker(!isPicker);
  };

  const privateRef = useRef({
    currentHour, currentMinute, disabled,
  });

  useEffect(() => {
    privateRef.current = {
      currentHour, currentMinute, disabled,
    };
  }, [currentHour, currentMinute, disabled]);

  const prevProps = privateRef.current;

  const setInitialValue = (hours, minutes) => {
    const fieldMinute = getRef('fieldMinute');
    const fieldHour = getRef('fieldHour');

    fieldMinute.inputElement.value = disabled ? '' : convertTimeToString(minutes);
    fieldHour.inputElement.value = disabled ? '' : convertTimeToString(hours);
  };

  useEffect(() => {
    const hours = typeof value.hours === 'number' ? value.hours : '';
    const minutes = typeof value.minutes === 'number' ? value.minutes : '';

    setInitialValue(hours, minutes);
    if (disabled !== prevProps.disabled) {
      setInitialValue({ hours: 0 }, { minutes: 0 });
    }
    if (currentHour !== prevProps.currentHour || currentMinute !== prevProps.currentMinute) {
      onChange({
        target: {
          value: {
            hours: currentHour,
            minutes: currentMinute,
          },
          name,
        },
      });
    }
    document.addEventListener('click', e => clickOutsideHandler(e), true);
    return (
      document.removeEventListener('click', e => clickOutsideHandler(e), true)
    );
  }, [isPicker, prevProps, currentHour, currentMinute]);

  const errorCheck = typeof error === 'string' && error.length !== 0 && touched;

  return (
    <div
      className={classNames('time-picker',
        { 'time-picker--is-fill': currentHour || currentMinute },
        error && touched && 'time-picker--has-error',
        className, inFocus && 'time-picker--focus')}
      ref={inputFieldGroup}
    >
      <div className="time-picker__input-container">
        <div className="time-picker__place-write">
          <Number
            min="0"
            max="23"
            charMask="H"
            placeholder="HH"
            disabled={disabled}
            convertTimeToString={convertTimeToString}
            onFocus={() => setFocusStateHandler(true)}
            onBlur={() => { onBlur({ target: { name } }); setFocusStateHandler(false); }}
            onChange={e => changeHourHandler(e, false)}
            customRef={e => setRef('fieldHour', e)}
          />
          <Number
            min="0"
            max="59"
            charMask="M"
            placeholder="MM"
            disabled={disabled}
            convertTimeToString={convertTimeToString}
            onFocus={() => setFocusStateHandler(true)}
            onBlur={() => { onBlur({ target: { name } }); setFocusStateHandler(false); }}
            onChange={e => changeMinuteHandler(e, false)}
            customRef={e => setRef('fieldMinute', e)}
          />
          <ButtonsCollection.ButtonIcons
            title={t('Show time slider')}
            disabled={disabled}
            className="time-picker__toggle-button"
            onClick={() => togglePicker(!inFocus, true, getRef)}
          >
            <span className="icon-timer-2-icon" />
          </ButtonsCollection.ButtonIcons>
        </div>
        <div className={classNames('time-picker__modal', isPicker && 'time-picker__modal--focus')}>
          <div className="time-picker__row">
            <Slider
              {...slidersConfig}
              beforeChange={changeHourSliderHandler}
              ref={e => setRef('hoursSlider', e)}
            >
              {
                arrayHours.map((el, index) => (
                  <div
                    className="time-picker__item"
                    key={`timePickerHour${name}${new Date().getTime() * Math.random()}`}
                  >
                    {currentHour === index ? (
                      <Label className="label--fill time-picker__label-current label--xs">
                        {convertTimeToString(index)}
                      </Label>
                    ) : (
                      convertTimeToString(index)
                    )
                    }
                  </div>
                ))
              }
            </Slider>
          </div>
          <div className="time-picker__row">
            <Slider
              {...slidersConfig}
              beforeChange={changeMinuteSliderHandler}
              ref={e => setRef('minuteSlider', e)}
            >
              {
                arraySeconds.map((el, index) => (
                  <div
                    className="time-picker__item"
                    key={`timePickerMinute${name}${new Date().getTime() * Math.random()}`}
                  >
                    {currentMinute === index ? (
                      <Label className="label--fill time-picker__label-current label--xs">
                        {convertTimeToString(index)}
                      </Label>
                    ) : (
                      convertTimeToString(index)
                    )
                    }
                  </div>
                ))
              }
            </Slider>
          </div>
        </div>
      </div>
      {errorCheck && (
        <span className="field-group__error field__error-text">
          {error}
        </span>
      )}
    </div>
  );
};

export default TimePickerComponent;
