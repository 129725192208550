import {
  compose, getContext, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { closeModal } from '../../../../../state/ui/actions';
import { getModal } from '../../../../../state/ui/selectors';
import { projectActions, projectSelectors } from '../../../../../state/project';
import DeleteNote from './deleteNote';

const mapStateToProps = (state, { selectedNoteId }) => ({
  isOpen: getModal(state)('deleteNoteModal'),
  note: projectSelectors.getNote(state)(selectedNoteId),
  isPending: projectSelectors.getDeleteNoteRequestPending(state),
});

const mapDispatchToProps = ({
  onCloseModal: () => closeModal('deleteNoteModal'),
  deleteNote: projectActions.deleteNoteRequest,
});

const onDeleteHandler = ({
  deleteNote, selectedNoteId, projectId, onCloseModal,
}) => () => {
  deleteNote({ noteId: selectedNoteId, projectId }, {
    callbacks: {
      success: onCloseModal,
    },
  });
};

const enhance = compose(
  getContext({
    selectedNoteId: PropTypes.number.isRequired,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['common']),
  withHandlers({
    onDelete: onDeleteHandler,
  }),
);

export default enhance(DeleteNote);
