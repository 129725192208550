import {
  compose, getContext, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { propOr, replace } from 'ramda';
import PropTypes from 'prop-types';
import withWindowWidth from '../../../../../../utils/enchancers/withWindowWidth';
import InvoiceCard from './invoiceCard';
import { uiActions } from '../../../../../../state/ui';
import { CRM_ROUTES } from '../../../../../../constants/crm';

const mapStateToProps = (state, { invoice }) => ({
  project: propOr(null, 'project', invoice),
});

const mapDispatchToProps = {
  openModal: uiActions.openModal,
};

const onDeleteInvoiceModal = ({
  openModal, invoice, setSelectedInvoiceId,
}) => () => {
  setSelectedInvoiceId(invoice.id);
  openModal('deleteInvoiceModal');
};

const openEditPageHandler = ({ history }) => invoiceId => () => history.push(
  replace(':invoiceId', invoiceId, CRM_ROUTES.EDIT_INVOICE),
);

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    setSelectedInvoiceId: PropTypes.func,
  }),
  withRouter,
  withWindowWidth(),
  withTranslation(['tasks', 'common']),
  withHandlers({
    onDeleteInvoiceModal,
    openEditPage: openEditPageHandler,
  }),
  withProps(({ project }) => ({
    projectTitle: propOr('N/A', 'title', project),
  })),
);

export default enhance(InvoiceCard);
