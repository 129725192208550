import React, { memo } from 'react';

/**
 *
 * @param children {string | element}
 * @param className {string}
 * @param onClick {function}
 * @returns {JSX.Element}
 * @constructor
 */

const Row = ({ children = '', className, onClick = () => { } }) => (
  <div role="presentation" onClick={onClick} className={className}>
    {children}
  </div>
);

export default memo(Row);
