import { compose } from 'recompose';
import { connect } from 'react-redux';

import { closeModal } from '../../../../state/ui/actions';
import { getModal } from '../../../../state/ui/selectors';
import EditRepositoryModal from './editRepositoryModal';

const mapStateToProps = state => ({
  isOpen: getModal(state),
});

const mapDispatchToProps = ({
  closeModal,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
);
export default enhance(EditRepositoryModal);
