import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Avatar, ButtonsCollection, BrillMask } from '../../../../../ui-kit';
import { AVAILABLE_FILE_FORMATS } from '../../../../../constants/files';
import './style.sass';


/**
 *
 * @param fileUrl{string}
 * @param value{string}
 * @param name{string}
 * @param alt{string}
 * @param setFileValue{function}
 * @param onResetCallback{function}
 * @param onUploadFile{function}
 * @returns {JSX.Element}
 * @constructor
 */

const AvatarProfile = ({
  fileUrl,
  value,
  name,
  alt,
  setFileValue,
  onResetCallback,
  onUploadFile,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation('common');

  const onResetAvatar = () => {
    setFileValue(null);
    onResetCallback();
  };
  const onChangeHandler = (e) => {
    setErrorMessage('');
    onUploadFile(e);
  };
  useEffect(() => {
    setErrorMessage('');
  }, []);
  return (
    <div className="avatar-profile">
      <div className="avatar-profile__title">{t('Profile photo')}</div>
      <div
        className="avatar-profile__label"
      >
        <input
          id="field-profile__avatar-profile"
          className="avatar-profile__input"
          onChange={onChangeHandler}
          name={name}
          value={(e) => {
            e.target.value = '';
          }}
          type="file"
          accept={AVAILABLE_FILE_FORMATS.LOGO_PROFILE.join(',')}
        />
        <BrillMask>
          <Avatar src={fileUrl || value} alt={alt}>
            <label
              className="field-profile__pseudo-overlay brill-mask__ignore"
              htmlFor="field-profile__avatar-profile"
            >
              <span className="field-profile__pseudo-overlay-icon brill-mask__ignore icon-camera" />
              <div className="field-profile__pseudo-overlay-title brill-mask__ignore">{t('Change Photo')}</div>
            </label>
          </Avatar>
        </BrillMask>
      </div>
      {
        (fileUrl || value) && (

          <ButtonsCollection.ButtonBrill
            type="button"
            className="button--xs avatar-profile__button-remove"
            onClick={onResetAvatar}
          >
            {t('Remove Avatar')}
          </ButtonsCollection.ButtonBrill>
        )
      }
      {
        errorMessage && (<small className="avatar-profile__error-message">{errorMessage}</small>)
      }
    </div>
  );
};

export default AvatarProfile;
