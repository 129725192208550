/* eslint-disable */
import {
  curry, insert, lensProp, remove, set,
} from 'ramda';
import { select, put } from 'redux-saga/effects';

import { getAgendaTasksList, getTask } from '../../../state/project/selectors';
import { reorderAgendaTasks } from '../../../state/project/actions';
import { updateTaskRequest } from '../../../state/tasks/actions';
import { makePayload } from './utils';
import { move } from '../commonHelpers';
import {projectSelectors} from "../../../state/project";

const getDataInStoreWithParams = curry((selector, params) => select(
  state => selector(state)(params),
));

const addStatusParam = makePayload('status_id');

const getAgendaTasksListSelector = getDataInStoreWithParams(getAgendaTasksList);
const getTaskSelector = getDataInStoreWithParams(getTask);

const updateTask = curry(function* (status_id, data) {
  const statusLens = lensProp('status_id');
  const updatedData = set(statusLens, status_id, data);
  const { state: status} = yield select((state) => projectSelectors.getStatusOfTasks(state)(status_id));
  yield put(updateTaskRequest({...updatedData, status}, { isAfterDrop: true }));
});

const updateBoard = curry(function* (status_id, action) {
  const selectedTasks = yield getAgendaTasksListSelector(status_id);
  const updatedTasks = action(selectedTasks);
  return addStatusParam(status_id, updatedTasks);
});

const dragTaskBetweenBoard = function* (data) {
  const { source, destination, itemId } = data;
  if (source.droppableId === destination.droppableId) {
    const itemsInSource = yield updateBoard(
      source.droppableId,
      move(source.index, destination.index),
    );
    yield put(reorderAgendaTasks(itemsInSource));
  } else {
    const itemsInSource = yield updateBoard(source.droppableId, remove(source.index, 1));
    const itemsInDestination = yield updateBoard(
      destination.droppableId, insert(destination.index, itemId),
    );
    yield put(reorderAgendaTasks(itemsInSource));
    yield put(reorderAgendaTasks(itemsInDestination));
    const task = yield getTaskSelector(itemId);
    yield updateTask(destination.droppableId, task);
  }
};

export default dragTaskBetweenBoard;
