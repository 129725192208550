import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import FieldItem from '../fieldItem';

/**
 *
 * @param index {number}
 * @param id {number}
 * @param title {string}
 * @param setIdToDelete {function}
 * @param customFieldsList {array}
 * @param setCustomFieldsList {function}
 * @returns {JSX.Element}
 *
 */

const DraggableFieldItem = ({
  id, index, title, setIdToDelete, customFieldsList, setCustomFieldsList,
}) => (
  <div className="status-item__droppable">
    <Draggable
      index={index}
      draggableId={id}
    >
      {(providedDrag, draggableSnapshot) => (
        <div
          className="custom-field__drag-wrapper"
          ref={providedDrag.innerRef}
          {...providedDrag.draggableProps}
          {...providedDrag.dragHandleProps}
        >
          <FieldItem
            id={id}
            key={`status-${id}`}
            title={title}
            setIdToDelete={setIdToDelete}
            isDragging={draggableSnapshot.isDragging}
            setCustomFieldsList={setCustomFieldsList}
            customFieldsList={customFieldsList}
          />
        </div>
      )}
    </Draggable>
  </div>
);

DraggableFieldItem.defaultProps = {
  setIdToDelete: () => {},
  customFieldsList: [],
  setCustomFieldsList: () => {},
};

export default DraggableFieldItem;
