import {
  compose, getContext, withProps,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DirectsItem from './directsItem';
import { uiSelectors } from '../../../../state/ui';
import { usersActions, usersSelectors } from '../../../../state/users';
import {
  messengerActions,
} from '../../../../state/messenger';
import { getDirectChannelId } from '../../../../state/messenger/selectors';

const mapStateToProps = (state, { id }) => ({
  userId: uiSelectors.getUserProfileId(state),
  users: usersSelectors.getUsersEntities(state),
  channelId: getDirectChannelId(state)(id),
  isPending: uiSelectors.getUserProfileRequestPending(state),
});

const mapDispatchToProps = {
  getUserProfile: usersActions.getUserProfileRequest,
  getChannelByUserRequest: messengerActions.getChannelByUserRequest,
  readMessagesRequest: messengerActions.readMessagesRequest,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    values: PropTypes.shape({}),
  }),
  withProps(({ channel }) => ({
    channel,
  })),
);

export default enhance(DirectsItem);
