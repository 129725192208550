import React from 'react';

import './style.sass';

/**
 *
 * @param item {object}
 * @param onDelete {function}
 * @returns {JSX.Element}
 * @constructor
 */

export const FilesItem = ({ item, onDelete }) => (
  <div className="files-upload-entities">
    <span className="files-upload-entities__icon">
      <span className="icon-document-file" />
    </span>
    <h6 className="files-upload-entities__name">{item.original}</h6>
    <button type="button" className="files-upload-entities__remove-button" onClick={onDelete}>
      <span className="icon-close" />
    </button>
  </div>
);
