import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  DirectChannels, GroupChannels, ChannelsContainer, MessengerContainer, DirectsContainer,
} from './components';
import { LeftSideBar } from '../../newContainers';
import { Layouts, NoInternetConnection, ButtonsCollection } from '../../ui-kit';
import './style.sass';
import {
  InviteMembersModal, DeleteChannelModal, LeaveChannelModal, EditChannelModal,
} from './modals';
import { CHANNELS_ROUTES } from '../../constants/ui';
import { DOWN_CHANNEL, UPPER_CHANNEL } from './consts';

const Messenger = ({
  onScrollToUnreadChannel, onWatchUnreadChannels,
  shownButtonScrollToChannel,
}) => (
  <Layouts.Main>
    <LeftSideBar onScroll={onWatchUnreadChannels}>
      <GroupChannels />
      <DirectChannels />
      {
        shownButtonScrollToChannel[UPPER_CHANNEL] && (
          <ButtonsCollection.ButtonBrill
            onClick={() => onScrollToUnreadChannel(UPPER_CHANNEL)}
            className="button-brill--fill button--unread-mentions button--unread-mentions--up"
          >
            <span className="icon-down" />

            Unread messages
          </ButtonsCollection.ButtonBrill>
        )
      }
      {
        shownButtonScrollToChannel[DOWN_CHANNEL] && (
          <ButtonsCollection.ButtonBrill
            onClick={() => onScrollToUnreadChannel(DOWN_CHANNEL)}
            className="button-brill--fill button--unread-mentions"
          >
            <span className="icon-down" />

            Unread messages
          </ButtonsCollection.ButtonBrill>
        )
      }
    </LeftSideBar>
    <NoInternetConnection>
      <Switch>
        <Route exact path={CHANNELS_ROUTES.CHANNELS} component={ChannelsContainer} />
        <Route exact path={CHANNELS_ROUTES.DIRECTS} component={DirectsContainer} />
        <Route component={MessengerContainer} />
      </Switch>
      <InviteMembersModal />
      <DeleteChannelModal />
      <EditChannelModal />
      <LeaveChannelModal />
    </NoInternetConnection>
  </Layouts.Main>
);

Messenger.propTypes = {
  onScrollToUnreadChannel: PropTypes.func.isRequired,
  onWatchUnreadChannels: PropTypes.func.isRequired,
  shownButtonScrollToChannel: PropTypes.instanceOf(Object).isRequired,
};

export default Messenger;
