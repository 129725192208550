import React from 'react';
import PropTypes from 'prop-types';

import ModalView from '../modalView';

import '../style.sass';

const CreateNote = (props) => {
  const { t } = props;
  return (
    <ModalView
      title={t('Create new note')}
      titleAction={t('Create')}
      {...props}
    />
  );
};

CreateNote.propTypes = {
  t: PropTypes.func.isRequired,
};

export default CreateNote;
