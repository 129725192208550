import {
  branch,
  compose, getContext, renderNothing, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { closeModal } from '../../../../../state/ui/actions';
import { getModal } from '../../../../../state/ui/selectors';
import { projectActions, projectSelectors } from '../../../../../state/project';
import CreateCategory from './deleteCategory';

const mapStateToProps = (state, { selectedCategoryId }) => ({
  isOpen: getModal(state)('deleteCategory'),
  category: projectSelectors.getCategory(state)(selectedCategoryId),
  isPending: projectSelectors.getCategoryDeleteRequestPending(state),
});

const mapDispatchToProps = ({
  onCloseModal: () => closeModal('deleteCategory'),
  deleteCategoryTask: projectActions.deleteCategoryTaskRequest,
});

const onDeleteHandler = ({
  deleteCategoryTask, selectedCategoryId, projectId, onCloseModal,
}) => () => {
  deleteCategoryTask({ categoryId: selectedCategoryId, projectId }, {
    callbacks: {
      success: onCloseModal,
    },
  });
};

const enhance = compose(
  getContext({
    selectedCategoryId: PropTypes.number.isRequired,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ category }) => !category.title, renderNothing),
  withTranslation(['common', 'tasks']),
  withHandlers({
    onDelete: onDeleteHandler,
  }),
);

export default enhance(CreateCategory);
