import api from '../../../../services/api.service';
import { PEOPLE_API } from './routes';
import { callNotification } from '../../../../utils/helpers/notifies';

const NETWORK_ERROR = 'Network error!';

const extractData = (res) => {
  const { data: { result } } = res;
  return result;
};

const schema = {
  createPerson: async (params = {}) => {
    try {
      const response = await api.post(PEOPLE_API.MAIN, params);
      return extractData(response);
    } catch (e) {
      callNotification.error(NETWORK_ERROR);
      throw e;
    }
  },
  getPeople: async (props = {}) => {
    try {
      const response = await api.get(PEOPLE_API.MAIN, props);
      return extractData(response);
    } catch (e) {
      callNotification.error(NETWORK_ERROR);
      throw e;
    }
  },
  getPerson: async ({ id, ...params }) => {
    try {
      const response = await api.get(PEOPLE_API.MAIN + id, params);
      return extractData(response);
    } catch (e) {
      callNotification.error(NETWORK_ERROR);
      throw e;
    }
  },
  updatePerson: async ({ id, ...params }) => {
    try {
      const response = await api.put(PEOPLE_API.MAIN + id, params);
      return extractData(response);
    } catch (e) {
      callNotification.error(NETWORK_ERROR);
      throw e;
    }
  },
  resetAvatarToDefault: async ({ id, ...params }) => {
    try {
      const response = await api.put(PEOPLE_API.AVATAR + id, params);
      return extractData(response);
    } catch (e) {
      callNotification.error(NETWORK_ERROR);
      throw e;
    }
  },
  deletePerson: async ({ id, ...params }) => {
    try {
      const response = await api.destroy(PEOPLE_API.MAIN + id, params);
      return extractData(response);
    } catch (e) {
      callNotification.error(NETWORK_ERROR);
      throw e;
    }
  },
  resendInvite: async ({ id, ...params }) => {
    try {
      const response = await api.post(
        PEOPLE_API.USERS_MAIN + id + PEOPLE_API.RESEND_INVITE, params,
      );
      return extractData(response);
    } catch (e) {
      callNotification.error(NETWORK_ERROR);
      throw e;
    }
  },
};

export default schema;
