import { API_ROUTES } from '@/constants/apiRoutes';
import api from '@/services/api.service';
import { extractData } from '@/utils/helpers/extractDataHelper';

const schema = {
  getKnowledgeBaseCategories: async (params = {}) => {
    try {
      const response = await api.get(API_ROUTES.KNOWLEDGE_BASE.CATEGORIES, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  getKnowledgeBaseCategoryById: async (id) => {
    try {
      const response = await api.get(`${API_ROUTES.KNOWLEDGE_BASE.CATEGORY}/${id}`);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  createKnowledgeBaseCategory: async (params = {}) => {
    try {
      const response = await api.post(API_ROUTES.KNOWLEDGE_BASE.CATEGORY, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  updateKnowledgeBaseCategory: async (id, params = {}) => {
    try {
      const response = await api.put(`${API_ROUTES.KNOWLEDGE_BASE.CATEGORY}/${id}`, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  deleteKnowledgeBaseCategory: async (id) => {
    try {
      const response = await api.destroy(`${API_ROUTES.KNOWLEDGE_BASE.CATEGORY}/${id}`);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  getKnowledgeBaseArticles: async (id) => {
    try {
      const response = await api.get(`${API_ROUTES.KNOWLEDGE_BASE.ARTICLES}?subCategoryId=${id}`);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  getKnowledgeBaseArticleById: async (id) => {
    try {
      const response = await api.get(`${API_ROUTES.KNOWLEDGE_BASE.ARTICLE}/${id}`);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  createKnowledgeBaseArticle: async (params = {}) => {
    try {
      const response = await api.post(API_ROUTES.KNOWLEDGE_BASE.ARTICLE, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  updateKnowledgeBaseArticle: async (id, params = {}) => {
    try {
      const response = await api.put(`${API_ROUTES.KNOWLEDGE_BASE.ARTICLE}/${id}`, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  deleteKnowledgeBaseArticle: async (id) => {
    try {
      const response = await api.destroy(`${API_ROUTES.KNOWLEDGE_BASE.ARTICLE}/${id}`);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
  askKnowledgeBaseQuestion: async (params = {}) => {
    try {
      const response = await api.post(API_ROUTES.KNOWLEDGE_BASE.ASK_QUESTION, params);
      return extractData(response);
    } catch (error) {
      return error;
    }
  },
};

export default schema;
