import {
  compose, lifecycle, withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import NotesList from './notesList';
import { projectActions, projectSelectors } from '../../../../state/project';
import preloaderWhileLoading from '../../../../utils/enchancers/preloaderWhileLoading';
import { PRELOADER_DIMENSION } from '../../../../constants/ui';

const LIMIT_OF_NOTES = 1000;

const mapStateToProps = state => ({
  notes: projectSelectors.getNotesList(state),
  isPending: projectSelectors.getNotesRequestPending(state),
});

const mapDispatchToProps = ({
  getNotesRequest: projectActions.getNotesRequest,
});

const changeActiveTab = ({ activeTab }) => (idTab) => {
  if (idTab === activeTab) {
    return {
      activeTab: 0,
    };
  }
  return {
    activeTab: idTab,
  };
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStateHandlers(
    () => ({ activeTab: 0 }), {
      changeActiveTabStateHandler: changeActiveTab,
    },
  ),
  lifecycle({
    componentDidMount() {
      const { match: { params: { id } }, getNotesRequest } = this.props;
      getNotesRequest({ projectId: id, limit: LIMIT_OF_NOTES, offset: 0 });
    },
    componentDidUpdate(prevProps) {
      const {
        match: { params: { id } },
        getNotesRequest, sortBy: { val: { order, sortBy } },
      } = this.props;
      if (this.props.sortBy !== prevProps.sortBy && prevProps.sortBy) {
        getNotesRequest({
          projectId: id, sortBy, order, limit: LIMIT_OF_NOTES, offset: 0,
        });
      }
    },
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.MIDDLE,
    alignContainerCenter: true,
    delay: 600,
    isLoading: () => false,
    onAction: ({ isPending }) => isPending,
  }),
);
export default enhance(NotesList);
