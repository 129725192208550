import {
  compose, withHandlers, branch, renderNothing, getContext,
} from 'recompose';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as yup from 'yup';
import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';
import { prop, propOr } from 'ramda';
import PropTypes from 'prop-types';

import { helpers } from '../../../../utils';
import { closeModal } from '../../../../state/ui/actions';
import { getModal } from '../../../../state/ui/selectors';
import { messengerActions, messengerSelectors } from '../../../../state/messenger';
import { usersActions, usersSelectors } from '../../../../state/users';
import EditChannelModal from './editChannelModal';
import { renameKeysForUsers } from '../../../../utils/helpers/uiHelpers';
import withAutocomplete from '../../../../utils/enchancers/withAutocomplete';

const { translateHelpers } = helpers;
const { getUsersListAutocompleteRequest, setUsersListAutocomplete } = usersActions;
const { getUsersForSelect } = usersSelectors;

const onClose = ({ resetForm, onCloseModal }) => () => {
  resetForm();
  onCloseModal();
};
const submitForm = (formValues,
  {
    props: {
      editChannelRequest, onCloseModal, pushToChannel, channel,
    },
  }) => {
  const {
    title,
    privacy,
    members,
    description,
  } = formValues;
  const params = {
    name: title,
    is_public: privacy === 'public',
    members: members.map(member => member.value),
    description,
  };
  editChannelRequest({ params, channelId: channel.id }, {
    callbacks: {
      success: ({ channel: { id } }) => {
        pushToChannel(`/messenger/${id}`);
        onCloseModal();
      },
    },
  });
};

const rules = yup.object().shape({
  title: yup
    .string()
    .required({
      field: 'title',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Title'),
      },
    }),
  privacy: yup
    .string()
    .required({
      field: 'privacy',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Privacy'),
      },
    }),
  members: yup
    .array(),
  description: yup
    .string()
    .required({
      field: 'description',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Description'),
      },
    }),
});

const mapStateToProps = (state, { selectedChannelId }) => ({
  usersList: getUsersForSelect(state),
  isOpen: getModal(state)('editChannelModal'),
  channel: propOr({}, selectedChannelId,
    messengerSelectors.getGroupChannels(state)),
  getUsersByResult: usersSelectors.getUsersByResult(state),
});

const mapDispatchToProps = ({
  editChannelRequest: messengerActions.updateChannelRequest,
  getUsersAutocomplete: getUsersListAutocompleteRequest,
  setUsersAutocomplete: setUsersListAutocomplete,
  pushToChannel: push,
  onCloseModal: () => closeModal('editChannelModal'),
});


const enhance = compose(
  getContext({
    selectedChannelId: PropTypes.number,
  }),
  branch(({ selectedChannelId }) => !selectedChannelId, renderNothing),
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ channel }) => !channel.id, renderNothing),
  withTranslation(['common', 'validation']),
  withAutocomplete({
    name: 'getUsersAutocomplete',
    action: usersActions.getUsersListAutocompleteRequest,
    dataPath: prop('users'),
    searchField: 'q',
  }),
  withFormik({
    mapPropsToValues: ({ channel, getUsersByResult }) => ({
      ...channel,
      title: channel.name,
      members: compose(renameKeysForUsers, getUsersByResult, propOr([], 'members'))(channel),
      privacy: channel.type === 2 ? 'private' : 'public',
    }),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: submitForm,
  }),
  withHandlers({
    onClose,
  }),
);
export default enhance(EditChannelModal);
