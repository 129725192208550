import {
  compose,
  getContext,
  withStateHandlers,
  withHandlers,
} from "recompose";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import FiltersRow from "./filtersRow";

const setShowFilter = ({ getUrlParam, onResetUrlParam }) => () => {
  const isPage = getUrlParam(["page"]);
  if (isPage) {
    onResetUrlParam({ page: null });
  }
};

const enhance = compose(
  getContext({
    values: PropTypes.shape({}),
    setFieldValue: PropTypes.func
  }),
  withHandlers({ setShowFilter }),
  withStateHandlers(
    {
      areMobileFiltersOpen: false
    },
    {
      toggleMobileFiltersHandler: ({ areMobileFiltersOpen }) => () => ({
        areMobileFiltersOpen: !areMobileFiltersOpen
      })
    }
  ),
  withTranslation(["common", "chat"])
);
export default enhance(FiltersRow);
