import {
  branch,
  compose,
  getContext,
  lifecycle,
  renderNothing,
  withHandlers,
  withProps,
  withState,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  always,
  and,
  compose as RCompose,
  filter,
  find,
  flip,
  identity,
  ifElse,
  includes,
  isEmpty,
  keys,
  map,
  path,
  pathOr,
  prop,
  propEq,
  propOr,
  reduce,
  values,
} from 'ramda';
import { withFormik } from 'formik';
import { propNotEq } from 'ramda-extension';

import moment from 'moment';
import { withRouter } from 'react-router';
import { memo } from 'react';
import { usersActions, usersSelectors } from '../../state/users';
import { uiActions, uiSelectors } from '../../state/ui';
import { tasksSelectors } from '../../state/tasks';
import { newTaskActions, newTaskSelectors } from '../../state/newTask';
import withAutocomplete from './withAutocomplete';
import { dateHelpers, uiHelpers } from '../helpers';
import { SPRINT_STATUS } from '../../constants/sprints';
import { PRIORITY } from '../../constants/tasks';
import {
  setAssigneeLabel,
  setCategoryLabel,
  setSprintLabel,
  setStatusLabel,
} from '../helpers/taskHelpers/crudHelper';
import { getSelectedProjectIdCrud } from '../../state/newTask/selectors';
import { projectActions, projectSelectors } from '../../state/project';

const DEFAULT_STATUS_LABEL = 'TODO';

const DEFAULT_PROJECT_LABEL = {
  label: 'None',
  value: null,
};

const { renameKeysTitleIntoValue, renameKeysForUsers, getArrayFromEntities } = uiHelpers;

const mapStateToProps = modalName => (state, { task = {}, match: { params: { id } } }) => ({
  project: projectSelectors.default(state)(task.project_id || id),
  isPending: tasksSelectors.getAddTaskPendingRequest(state),
  categoriesEntities: newTaskSelectors.getCategoriesEntities(state),
  sprintsEntities: newTaskSelectors.getSprintsEntities(state),
  projects: newTaskSelectors.getProjectsEntities(state),
  getUsersByResult: usersSelectors.getUsersByResult(state),
  users: usersSelectors.getUsersEntities(state),
  selectedProjectId: getSelectedProjectIdCrud(state),
  getUserById: usersSelectors.getUser(state),
  isOpen: uiSelectors.getModal(state)(modalName),
  membersListOfSelectedProject: newTaskSelectors.getSelectedProjectMembersList(state),
  selectedProject: newTaskSelectors.getSelectedProject(state),
  statusesOfTasks: newTaskSelectors.getStatusesEntities(state),
});

const mapDispatchToProps = ({
  setUsersAutocomplete: usersActions.setUsersListAutocomplete,
  getCategoriesTaskCrud: newTaskActions.getCategoriesTaskCrudRequest,
  getSprintsTaskCrud: newTaskActions.getSprintsTaskCrudRequest,
  getProjectTaskCrudRequest: newTaskActions.getProjectTaskCrudRequest,
  onCloseModal: uiActions.closeModal,
  getStatusesRequest: projectActions.getStatusesOfTasksRequest,
  getProject: projectActions.getProjectRequest,
});

const BACKLOG_OPTION = {
  id: null,
  title: 'Backlog',
};

const convertEntitiesToOptions = RCompose(
  map(renameKeysTitleIntoValue),
  values,
);

const onChangeProjectHandler = ({
  setSelectedProjectIdCrud,
  setFieldValue, getProjectTaskCrudRequest,
}) => (value) => {
  setSelectedProjectIdCrud(value.value);
  setFieldValue('project_id', value);
  getProjectTaskCrudRequest({ projectId: value.value });
};

const appendBackLogToSpintEntities = entities => ({
  0: BACKLOG_OPTION,
  ...entities,
});

const filterCompletedSprintWhen = is => (
  is ? (sprints => compose(reduce((accum, key) => {
    const sprint = sprints[key];
    return sprint.status === SPRINT_STATUS.COMPLETE ? accum : ({ ...accum, [key]: sprint });
  }, {}), keys)(sprints)) : identity
);

const withProjectIdStorage = data => compose(
  withState('projectLabelByStorage', 'setProjectLabelByStorage', null),
  lifecycle({
    componentDidMount() {
      const {
        selectedProject, setProjectLabelByStorage, getProjectTaskCrudRequest,
        match: { params: { id } }, ...props
      } = this.props;
      const task = data(props);
      if (task && task.project_id) {
        getProjectTaskCrudRequest({ projectId: task.project_id });
        const projectOfThisTaskLabel = { label: selectedProject.title, value: selectedProject.id };
        setProjectLabelByStorage(projectOfThisTaskLabel);
      } else if (id) {
        getProjectTaskCrudRequest({ projectId: id });
      } else if (!task) {
        setProjectLabelByStorage(DEFAULT_PROJECT_LABEL);
      }
    },
    componentDidUpdate(prevProps) {
      const { selectedProject: prevSelectedProject } = prevProps;
      const {
        selectedProject, setProjectLabelByStorage,
      } = this.props;
      if (prevSelectedProject !== selectedProject) {
        const projectOfThisTaskLabel = { label: selectedProject.title || 'None', value: selectedProject.id || null };
        setProjectLabelByStorage(projectOfThisTaskLabel);
      }
    },
  }),
  memo,
);


const withCRUDTask = ({
  rules, data, onSubmit, modalName,
}) => compose(
  withRouter,
  connect(mapStateToProps(modalName), mapDispatchToProps),
  branch(({ isOpen }) => !isOpen, renderNothing),
  withProjectIdStorage(data),
  withState('taskInfo', 'setTaskInfo', {}),
  getContext({
    projectId: PropTypes.number,
  }),
  withProps(({
    getUsersByResult, getUserById, membersListOfSelectedProject, project, statusesOfTasks,
    projectLabelByStorage, taskInfo, ...props
  }) => {
    const task = data(props);
    const deadline = propOr(false, 'deadline', task) ? moment(task.deadline) : null;
    return ({
      initialValues: ({
        title: prop('title', taskInfo) || prop('title', task),
        description: prop('description', taskInfo) || prop('description', task),
        assignee: pathOr(false, ['assignee'], taskInfo) ? taskInfo.assignee : compose(
          setAssigneeLabel,
          (user) => {
            if (user && user.id) {
              return user;
            }
            return getUserById(user);
          },
          propOr(null, 'assigneeUser'),
        )(task),
        watchers: pathOr(false, ['watchers'], taskInfo)
          ? taskInfo.watchers : compose(renameKeysForUsers, getUsersByResult, propOr([], 'watchers'))(task),
        project_id: projectLabelByStorage,
        priority: propOr(PRIORITY.LOW.value, 'priority', task),
        status_id: !isEmpty(setStatusLabel(statusesOfTasks, task))
          ? setStatusLabel(statusesOfTasks, task)
          : find(propEq('label', DEFAULT_STATUS_LABEL))(convertEntitiesToOptions(statusesOfTasks)),
        category_id: setCategoryLabel(props.categoriesEntities, task),
        deadline: deadline || prop('deadline', taskInfo),
        estimated_time: prop('estimated_time', taskInfo) || compose(
          ifElse(
            identity,
            dateHelpers.getTimeStringBySeconds,
            always(''),
          ),
          prop('estimated_time'),
        )(task),
        sprint_id: setSprintLabel(appendBackLogToSpintEntities(props.sprintsEntities), task),
      }),
      categoriesList: [{ label: 'None', value: null }, ...convertEntitiesToOptions(prop('categoriesEntities', props))],
      sprintsList: convertEntitiesToOptions(filterCompletedSprintWhen(!task)(appendBackLogToSpintEntities(prop('sprintsEntities', props)))),
      priorities: getArrayFromEntities(PRIORITY),
      statuses: convertEntitiesToOptions(statusesOfTasks),
    });
  }),
  withTranslation(['common']),
  withAutocomplete({
    name: 'getUsersAutocomplete',
    action: usersActions.getUsersListAutocompleteRequest,
    dataPath: prop('users'),
    filterFunc: ({
      selectedProject,
    }) => (propEq('type', 0, selectedProject)
      ? filter(compose(flip(includes)(), prop('value')))
      : identity),
    searchField: 'q',
    appendOption: () => ({ label: 'None', value: null }),
  }),
  withAutocomplete({
    name: 'getProject',
    action: newTaskActions.getProjectsTaskCrudRequest,
    dataPath: RCompose(
      map(renameKeysTitleIntoValue),
      values,
      path(['data', 'entities', 'projects']),
    ),
    searchField: 'title',
    appendOption: () => ({ label: 'None', value: null }),
  }),
  withFormik({
    mapPropsToValues: ({ initialValues }) => initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: rules,
    handleSubmit: onSubmit,
  }),
  withHandlers({
    onChangeProject: onChangeProjectHandler,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        setFieldValue, selectedProject, setProjectLabelByStorage, values: formValues,
        selectedProjectId, setTaskInfo,
      } = this.props;

      if (compose(and(propEq('type', 0, selectedProject)), propNotEq('selectedProject', selectedProject))(prevProps)) {
        setFieldValue('assignee', null);
        setFieldValue('watchers', []);
      }
      if (path(['project_id', 'value'], formValues)
        !== path(['project_id', 'value'], prevProps.values)) {
        setProjectLabelByStorage(formValues.project_id);
      }
      if (selectedProjectId !== prevProps.selectedProjectId) {
        setFieldValue('category_id', null);
        setFieldValue('sprint_id', null);
        setTaskInfo(prevProps.values);
      }
    },
  }),
);

export default withCRUDTask;
