import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { path, isEmpty } from 'ramda';
import { ShowMore } from '../../../../ui-kit';
import { MessageElements } from '../../../../components';
import { getFullName } from '../../../../utils/helpers/userHelpers';

import './style.sass';

const AboutProject = ({ project, imgUrl, client }) => (
  <div className="about-project">
    <div className="about-project__container">
      {
        imgUrl && (
          <div className="about-project__logo">
            <img className="about-project__logo-image" src={imgUrl} alt="Wetelo" />
          </div>
        )
      }
      <div className="about-project__name">
        <h4 className="about-project__name-title">{project.title}</h4>
      </div>
      <div className="about-project__created-by">

        Created by
        {' '}
        {getFullName(project.author)}
        {' '}

        on
        {' '}
        {moment(project.created_at).format('YYYY-MM-DD')}
      </div>
      { project.client_id && !isEmpty(client) && (
        <div className="about-project__client">
          Client: {getFullName(path(['contact'], client))}
        </div>
      )}
      <ShowMore className="about-project__show-more">
        <div className="about-project__description selectable-text">
          <MessageElements
            options={{ parseToPlainText: true }}
          >
            {
              project.description
            }
          </MessageElements>
        </div>
      </ShowMore>
    </div>
  </div>
);

AboutProject.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  project: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
};

export default AboutProject;
