import { compose, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import {
  prop, path, isEmpty, propOr,
} from 'ramda';

import { withFormik } from 'formik';
import CreateContactModal from './createContactModal';
import { uiActions, uiSelectors } from '../../../../state/ui';
import { contactsActions } from '../../../../state/contacts';
import rules from '../rules';
import withAutocomplete from '../../../../utils/enchancers/withAutocomplete';
import { leadsActions } from '../../../../state/leads';
import { getFullName } from '../../../../utils/helpers/userHelpers';

const mapStateToProps = state => ({
  isOpen: uiSelectors.getModal(state)('createContactModal'),
});

const mapDispatchToProps = {
  closeModal: () => uiActions.closeModal('createContactModal'),
  addContact: contactsActions.addContactRequest,
};

const onCloseModalHandler = ({
  closeModal,
  resetForm,
}) => () => {
  closeModal();
  resetForm();
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ relatedTo }) => {
    const parentContact = propOr(null, 'contact', relatedTo);
    const clientAutocompleteValue = parentContact && !isEmpty(parentContact)
      ? {
        value: relatedTo.id,
        label: getFullName(parentContact),
      } : undefined;

    return {
      relatedToAutocomplete: clientAutocompleteValue,
    };
  }),
  withFormik({
    mapPropsToValues: ({ relatedToAutocomplete }) => ({
      client_id: relatedToAutocomplete,
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      avatar: '',
    }),
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: rules,
    handleSubmit: (formData, {
      resetForm,
      props: {
        closeModal,
        addContact,
        onSuccess,
        relatedToAutocomplete,
      },
    }) => {
      addContact({
        ...formData,
        client_id: path(['client_id', 'value'], formData),
      }, {
        callbacks: {
          success: () => {
            resetForm({
              client_id: relatedToAutocomplete,
            });
            closeModal();
            if (onSuccess) {
              onSuccess(formData);
            }
          },
        },
      });
    },
  }),
  withHandlers({
    onCloseModal: onCloseModalHandler,
  }),
  withAutocomplete({
    name: 'getLeadsAutocomplete',
    action:
      (params, meta) => leadsActions.getLeadsAutocompleteRequest({ all: true, ...params }, meta),
    dataPath: prop('clients'),
    searchField: 'q',
  }),
);
export default enhance(CreateContactModal);
