import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { bpSelectors, bpActions } from '@bp/store/bpModule';

export const useCreateNode = () => {
  const dispatch = useDispatch();
  const {
    params: { processId },
  } = useRouteMatch();
  const options = useSelector(bpSelectors.getEventsOptionsForCreation);
  const selectedEvent = useSelector(bpSelectors.getSelectedEvent);

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        bpActions.addBusinessProcessNodeRequest({
          event_id: selectedEvent.id,
          values: JSON.stringify(values),
          processId,
        }),
      );
    },
    [selectedEvent, processId],
  );

  return { options, onSubmit };
};
