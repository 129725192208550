import React from 'react';
import PropTypes from 'prop-types';

import { Header, Channels } from '../../../../containers';
import './style.sass';

const ChannelsContainer = ({ t, onGoBack }) => (
  <div className="main-container">
    <Header
      hideToggleRightSideBar
    >
      <h3 className="weight--medium title--xl title-page">
        {t('All channels')}
      </h3>
    </Header>
    <div className="main-container__content main-container__content--profile">
      <Channels onGoBack={onGoBack} />
    </div>
  </div>
);

ChannelsContainer.propTypes = {
  t: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
};

export default ChannelsContainer;
