import React from 'react';

import { FilterRow, RepositoriesList } from './components';
import './style.sass';

const ProjectsRepository = () => (
  <>
    <FilterRow />
    <RepositoriesList />
  </>
);
export default ProjectsRepository;
