import { handleActions } from 'redux-actions';
import types from './types';
import { mergeDeep, mergeIn } from '../../utils/helpers/ramdaStateHelpers';

const reducer = handleActions({
  [types.GET_CUSTOM_FIELDS_SUCCESS]: mergeIn(({
    payload: {
      data: { entities, result },
    },
  }) => ({
    entities: entities.customFields,
    result,
  })),
  [types.ADD_CUSTOM_FIELD_SUCCESS]: mergeDeep(({
    payload: customField,
  }, { result = [] }) => ({
    entities: {
      [customField.id]: customField,
    },
    result: [...result, customField.id],
  })),
  [types.EDIT_CUSTOM_FIELD_SUCCESS]: mergeDeep(({
    payload: customField,
  }) => ({
    entities: {
      [customField.id]: customField,
    },
  })),
}, {});

export default reducer;
