import React from 'react';
import PropTypes from 'prop-types';
import ModalView from '../modalViewTasks/modalView';

const CreateTaskModal = (props) => {
  const { t } = props;
  return (
    <ModalView
      modalTitle={t('Create new task')}
      action={t('Create')}
      name="createTaskModal"
      {...props}
    />
  );
};

CreateTaskModal.propTypes = {
  t: PropTypes.func.isRequired,
};

export default CreateTaskModal;
