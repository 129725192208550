import { compose, withStateHandlers } from 'recompose';
import ProjectTab from './projectTab';

const onToggleProjectTabHandler = ({ isOpen }) => () => ({ isOpen: !isOpen });

const enhance = compose(
  withStateHandlers(() => ({
    isOpen: true,
  }), {
    onToggleProjectTab: onToggleProjectTabHandler,
  }),
);
export default enhance(ProjectTab);
