import React, { useEffect } from 'react';
import MaskedInput from 'react-text-mask';

import './style.sass';

const ARROW_UP_KEY_CODE = 38;
const ARROW_DOWN_KEY_CODE = 40;

/**
 *
 * @param onChange {function}
 * @param placeholder {string}
 * @param setRef {function}
 * @param customRef {function}
 * @param onFocus {function}
 * @param disabled {boolean}
 * @param onBlur {function}
 * @param getRef {function}
 * @param convertTimeToString {function}
 * @param min {string}
 * @param max {string}
 * @returns {JSX.Element}
 * @constructor
 */

const Number = ({
  placeholder = '',
  setRef = () => {},
  onChange = () => {},
  customRef = () => {},
  onFocus = () => {},
  onBlur = () => {},
  disabled = false,
  getRef,
  convertTimeToString = () => {},
  min,
  max,
}) => {
  const filterValue = (value) => {
    const newValue = parseInt(value, 10) || 0;
    if (newValue > max) {
      newValue = max;
    } else if (newValue < min) {
      newValue = min;
    }
    onChange({
      value: newValue,
    });

    return convertTimeToString(newValue);
  };

  const convertTime = () => {
    const input = getRef('input').inputElement;
    input.value = filterValue(input.value);
  };

  const handlerValue = (updateValue) => {
    const input = getRef('input').inputElement;
    input.value = filterValue(parseInt(input.value, 10) + updateValue);
  };

  const specialKeysHandle = (e) => {
    const input = getRef('input').inputElement;
    const inputValue = parseInt(input.value || 0, 10);
    switch (e.keyCode) {
      case ARROW_UP_KEY_CODE:
        input.value = filterValue(min, max, inputValue + 1, convertTimeToString, onChange);
        break;
      case ARROW_DOWN_KEY_CODE:
        input.value = filterValue(min, max, inputValue - 1, convertTimeToString, onChange);
        break;
      default:
    }
  };

  useEffect(() => {
    const fieldElement = getRef('input').inputElement;

    fieldElement.addEventListener('blur', convertTime, true);
    return (
      fieldElement.removeEventListener('blur', convertTime, true)
    );
  }, []);

  return (
    <div className="field-number-time-picker">
      <MaskedInput
        mask={[/\d/, /\d/]}
        placeholder={placeholder}
        ref={(e) => { setRef('input', e); customRef(e); }}
        onBlur={onBlur}
        disabled={disabled}
        onChange={e => onChange({
          value: e.currentTarget.value,
        })}
        onFocus={onFocus}
        onKeyDown={specialKeysHandle}
        className="field-number-time-picker__place-write"
      />
      <div className="field-number-time-picker__controls">
        <button
          type="button"
          className="field-number-time-picker__plus"
          onClick={() => !disabled && handlerValue(1)}
          onKeyPress={() => !disabled && handlerValue(1)}
          tabIndex="0"
        />
        <button
          type="button"
          className="field-number-time-picker__minus"
          onClick={() => !disabled && handlerValue(-1)}
          onKeyPress={() => !disabled && handlerValue(-1)}
          tabIndex="0"
        />
      </div>
    </div>
  );
};

export default Number;
