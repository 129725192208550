import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty, propOr } from 'ramda';
import { withRouter } from 'react-router';
import { isNotEmpty, notEqual } from 'ramda-extension';
import {
  compose, getContext, lifecycle, withHandlers,
} from 'recompose';
import { hrActions, hrSelectors } from '../../store/candidate';
import HrCandidateUpdate from './hrCandidateUpdate';

const updateCandidateHandler = ({
  updateHrCandidate,
  match: { params: { id } },
}) => formValues => updateHrCandidate({ ...formValues, id });

const mapStateToProps = (state, { match: { params: { id } } }) => ({
  candidate: propOr({}, id, hrSelectors.getHrCandidatesEntities(state)),
  commentsEntities: hrSelectors.getHrCommentsEntities(state),
  commentsList: hrSelectors.getHrCommentsList(state),
  filesList: id ? hrSelectors.getHrFilesList(state) : [],
  filesEntities: hrSelectors.getHrFilesEntities(state),
});
const mapDispatchToProps = {
  getHrCandidateRequest: hrActions.getHrCandidateRequest,
  getHrCommentsRequest: hrActions.getHrCommentsRequest,
  getHrFilesRequest: hrActions.getHrFilesRequest,
  updateHrCandidate: hrActions.updateHrCandidateRequest,
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  getContext({ setHeadTitle: PropTypes.func }),
  withHandlers({ updateCandidate: updateCandidateHandler }),
  lifecycle({
    componentDidMount() {
      const {
        setHeadTitle, candidate, getHrCandidateRequest,
        getHrCommentsRequest, getHrFilesRequest,
        match: { params: { id } },
      } = this.props;

      // Get candidate if not exist
      if (isEmpty(candidate) && id) getHrCandidateRequest({ id });
      if (id) {
        getHrFilesRequest({ id });
        getHrCommentsRequest({ id });
      }
      // Write header title
      if (isNotEmpty(candidate)) setHeadTitle(`${candidate.first_name} ${candidate.last_name}`);
    },
    componentDidUpdate(prevProps) {
      const { setHeadTitle, candidate } = this.props;
      // Write header title if update
      if (notEqual(candidate, prevProps.candidate) && isNotEmpty(candidate)) {
        setHeadTitle(`${candidate.first_name} ${candidate.last_name}`);
      }
    },
  }),
);
export default enhance(HrCandidateUpdate);
