import React, { useState, useMemo } from 'react';
import { ProjectNotesContext } from './context';

export const Provider = ({ children }) => {
  const [notes, setNotes] = useState([]);
  const [sortBy, setSortBy] = useState({ id: 0 });
  const [selectedNoteId, setSelectedNoteId] = useState(0);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const onChangeSorting = ({ val }) => {
    setSortBy(val);
  };

  const value = useMemo(() => ({
    notes,
    setNotes,
    sortBy,
    setSortBy,
    selectedNoteId,
    setSelectedNoteId,
    isOpenEditModal,
    setIsOpenEditModal,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    onChangeSorting,
  }), [notes,
    setNotes,
    sortBy,
    setSortBy,
    selectedNoteId,
    setSelectedNoteId,
    isOpenEditModal,
    setIsOpenEditModal,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    onChangeSorting]);
  return (
    <ProjectNotesContext.Provider value={value}>
      {children}
    </ProjectNotesContext.Provider>
  );
};
