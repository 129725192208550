import { useState, useEffect } from 'react';

/**
 * @param onOnlineCb {function}
 * @param onOfflineCb {function}
 * @returns {boolean}
 */

export const useOnlineStatus = (onOnlineCb = () => {}, onOfflineCb = () => {}) => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const handleOnOnline = () => {
      setIsOnline(true);
      if (onOnlineCb) onOnlineCb();
    };

    window.addEventListener('online', handleOnOnline);
    return () => {
      window.addEventListener('online', handleOnOnline);
    };
  }, [onOnlineCb]);

  useEffect(() => {
    const handleOnOffline = () => {
      setIsOnline(false);
      if (onOfflineCb) onOfflineCb();
    };

    window.addEventListener('offline', handleOnOffline);
    return () => {
      window.addEventListener('offline', handleOnOffline);
    };
  }, [onOfflineCb]);

  return isOnline;
};
