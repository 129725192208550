import { isEmpty } from 'ramda';
import React, { useCallback } from 'react';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { dragCustomFieldFlow } from '../../../utils/helpers/crmHelpers/customFieldsHelper';
import DraggableFieldItem from '../DraggableFieldItem';
import api from '../../../api/custom.service';

/**
 *
 * @param isUiAddMoreFields {boolean}
 * @param customFieldsList {array}
 * @param setCustomFieldsList {function}
 * @param setIdToDelete {function}
 * @returns {JSX.Element}
 *
 */


const CustomFieldsList = ({
  isUiAddMoreFields, customFieldsList,
  setCustomFieldsList, setIdToDelete,
}) => {
  const { t } = useTranslation('common');

  const onDragEnd = useCallback(({ draggableId, destination, source }) => {
    const fieldsByPriorityNew = dragCustomFieldFlow({
      customFieldsList, draggableId, destination, source,
    });
    setCustomFieldsList(fieldsByPriorityNew);
    api.updatePriority({ customFields: fieldsByPriorityNew });
  }, [customFieldsList]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-fields">
        {provided => (
          <div
            ref={provided.innerRef}
            className="custom-fields__draggable-area"
          >
            {!isEmpty(customFieldsList) || isUiAddMoreFields
              ? customFieldsList.map(({ id, title }, index) => (
                <DraggableFieldItem
                  id={id}
                  key={`project-task-key${id}`}
                  index={index}
                  title={title}
                  setIdToDelete={setIdToDelete}
                  customFieldsList={customFieldsList}
                  setCustomFieldsList={setCustomFieldsList}
                />
              )) : (
                <p className="custom-fields__text">
                  {t('There are no custom fields yet')}
                </p>
              )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

CustomFieldsList.defaultProps = {
  customFieldsList: [],
  setCustomFieldsList: () => {},
  setIdToDelete: () => {},
  isUiAddMoreFields: false,
};

export default CustomFieldsList;
