import React from 'react';
import { Grid } from '../../..';
import './style.sass';
import { formatDate } from '../../../../utils/helpers/commonHelpers';

/**
 * @param title {string}
 * @param date {string}
 * @param url {string}
 * @param author {string}
 * @returns {JSX.Element}
 * @constructor
 */

export const GitlabCommitItem = ({
  title, date, url, author,
}) => (
  <li className="commit">
    <a href={url} className="commit__link">
      <Grid.Row className="commit__row">
        <Grid.ColData className="commit__col commit__col commit__col commit__col--title">
          <div className="commit__data commit__data--title">
            { title }
          </div>
        </Grid.ColData>
        <Grid.ColData className="commit__col commit__col commit__col commit__col--date">
          <div className="commit__data">
            {formatDate(date)}
          </div>
        </Grid.ColData>
        <Grid.ColData className="commit__col commit__col commit__col commit__col--author">
          <div className="commit__data">
            {author}
          </div>
        </Grid.ColData>
      </Grid.Row>
    </a>
  </li>
);
