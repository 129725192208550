import { createActions } from 'redux-actions';
import { push } from 'connected-react-router';
import { identity, compose, mergeLeft } from 'ramda';
import { getNormalizeErrorObject } from '../../../../../utils/helpers/requestHelpers';
import hrTypes from './types';
import { setErrorPage } from '../../../../../state/ui/actions';
import { hrCandidatesSchema, hrCommentsSchema, hrFilesSchema } from './schema';
import { callNotification } from '../../../../../utils/helpers/notifies';

const errorPageDataWhenGetCandidate = compose(mergeLeft({ href: '/' }), getNormalizeErrorObject);

export const {
  setHrCandidate,
  setHrCandidates,
  getHrCandidatesRequest,
  getHrCandidateRequest,
  createHrCandidateRequest,
  deleteHrCandidateRequest,
  updateHrCandidateRequest,
  updateHrCandidateSuccess,
  getHrCommentsRequest,
  setHrComments,
  getHrFilesRequest,
  setHrFiles,
  deleteHrFileRequest,
  deleteHrFileSuccess,
} = createActions(
  {
    [hrTypes.GET_HR_CANDIDATES_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/whr/candidates',
        selectorName: 'getHrCandidatesRequest',
        method: 'GET',
        params,
        schema: {
          rules: hrCandidatesSchema,
          pathData: ['data'],
        },
        actions: {
          success: setHrCandidates,
          error: compose(setErrorPage, errorPageDataWhenGetCandidate),
        },
        callbacks: { error: callNotification.error },
        ...meta,
      }),
    ],
    [hrTypes.GET_HR_CANDIDATE_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/whr/candidates/${id}/`,
        selectorName: 'getHrCandidateRequest',
        method: 'GET',
        params,
        actions: {
          success: setHrCandidate,
          error: identity,
        },
        ...meta,
      }),
    ],
    [hrTypes.CREATE_HR_CANDIDATE_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/whr/candidates',
        selectorName: 'createHrCandidateRequest',
        method: 'POST',
        params,
        actions: {
          success: () => push('/hr-management/'),
        },
        callbacks: { error: callNotification.error, success: callNotification.success },
        ...meta,
      }),
    ],
    [hrTypes.DELETE_HR_CANDIDATE_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: `/whr/candidates/${params.id}`,
        selectorName: 'deleteHrCandidateRequest',
        method: 'DELETE',
        params,
        actions: {
          success: () => getHrCandidatesRequest(meta),
        },
        callbacks: { error: callNotification.error },
        ...meta,
      }),
    ],
    [hrTypes.UPDATE_HR_CANDIDATE_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: `/whr/candidates/${params.id}`,
        selectorName: 'updateHrCandidateRequest',
        method: 'PUT',
        params,
        actions: { success: updateHrCandidateSuccess },
        callbacks: { error: callNotification.error, success: callNotification.success },
        ...meta,
      }),
    ],
    [hrTypes.GET_HR_COMMENTS_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: `/whr/candidates/${params.id}/comments`,
        selectorName: 'getHrCommentsRequest',
        method: 'GET',
        schema: { rules: hrCommentsSchema, pathData: ['data'] },
        actions: { success: setHrComments },
        callbacks: { error: callNotification.error },
        ...meta,
      }),
    ],
    [hrTypes.GET_HR_FILES_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: `/whr/candidates/${params.id}/files`,
        selectorName: 'getHrFilesRequest',
        method: 'GET',
        schema: { rules: hrFilesSchema, pathData: ['data'] },
        actions: { success: setHrFiles },
        ...meta,
      }),
    ],
    [hrTypes.DELETE_HR_FILE_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: `/whr/candidates/${params.candidateId}/files/${params.id}`,
        selectorName: 'deleteHrFileRequest',
        method: 'DELETE',
        actions: { success: deleteHrFileSuccess },
        callbacks: { error: callNotification.error },
        ...meta,
      }),
    ],
  },
  hrTypes.SET_HR_CANDIDATES,
  hrTypes.SET_HR_CANDIDATE,
  hrTypes.SET_HR_COMMENTS,
  hrTypes.SET_HR_FILES,
  hrTypes.DELETE_HR_FILE_SUCCESS,
  hrTypes.UPDATE_HR_CANDIDATE_SUCCESS,
);
