import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import moment from 'moment';
import { getFileUrl, getImageUrl } from '../../../../../../utils/helpers/requestHelpers';
import { getFullName } from '../../../../../../utils/helpers/userHelpers';
import { Avatar, BrillMask } from '../../../../../../ui-kit';
import { IMAGES_SIZE } from '../../../../../../constants/ui';

const FileIcon = ({ type, id }) => (
  <>
    {
      /^image/g.test(type) ? (
        <BrillMask>
          <Avatar
            src={getImageUrl(id, IMAGES_SIZE.sm)}
            alt=""
          />
        </BrillMask>
      ) : (
        <div className="file-preloader">
          <a href={`${getImageUrl(id, IMAGES_SIZE.sm)}`}>
            <i className="icon-filetype-other" />
          </a>
        </div>
      )
    }
  </>
);


FileIcon.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
const FileItem = ({
  className, file, onDeleteFileModal, user, userName,
}) => (
  <div className={`file-row ${className}`}>
    <FileIcon type={file.type} id={file.id} />
    <div className="file-row__wrap">
      <b className="file-row__title file-row__title--post">
        <a
          rel="noopener noreferrer"
          href={`${getFileUrl(file.id)}`}
          target="_blank"
        >
          {file.original}
        </a>
      </b>
      {
        user.id === file.created_by && (
          <button
            title="Delete"
            className="btn-delete"
            type="button"
            onClick={() => onDeleteFileModal(file.id)}
          >
            <i className="icon-trash" />
          </button>
        )
      }
      <div className="row-flex help-text">
        Created by:
        {' '}
        {getFullName(userName)}
        {' '}
        {' '}
        at
        {' '}
        {moment(file.created_at).format('YYYY-MM-DD hh:mm')}
      </div>
    </div>
  </div>
);

FileItem.propTypes = {
  className: PropTypes.string.isRequired,
  file: PropTypes.instanceOf(Object).isRequired,
  onDeleteFileModal: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  userName: PropTypes.string.isRequired,
  // todo: why variable object of User, has name as `userName`
};
export default FileItem;
