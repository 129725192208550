import { compose } from 'recompose';
import { connect } from 'react-redux';
import { leadsSelectors } from '../../../../../../state/leads';
import ColumnBoard from './columnBoard';

const mapStateToProps = (state, { pipelineStatus }) => ({
  leads: leadsSelectors.getLeadsByPipelineStatusId(state)(pipelineStatus.id),
});

const enhance = compose(
  connect(mapStateToProps),
);
export default enhance(ColumnBoard);
