import { compose, getContext, withHandlers } from 'recompose';
import PropTypes from 'prop-types';

import NotesItem from './notesItem';

const onEditHandler = ({ onEditNote, note: { id } }) => () => onEditNote(id);
const onDeleteHandler = ({ onDeleteNote, note: { id } }) => () => onDeleteNote(id);

const enhance = compose(
  getContext({
    onEditNote: PropTypes.func.isRequired,
    onDeleteNote: PropTypes.func.isRequired,
  }),
  withHandlers({
    onEdit: onEditHandler,
    onDelete: onDeleteHandler,
  }),
);
export default enhance(NotesItem);
