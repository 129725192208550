import { handleActions } from 'redux-actions';
import {
  filter, compose, values, head, propOr, pathOr,
} from 'ramda';
import { mergeDeep, mergeIn } from '../../utils/helpers/ramdaStateHelpers';

import types from './types';

const reducer = handleActions({
  [types.SET_PIPELINES]: mergeDeep(({ payload: { data, count, hasMore } }) => ({
    summary: {
      ...data,
      count,
      hasMore,
    },
  })),
  [types.SET_PIPELINES_AUTOCOMPLETE]: mergeDeep(({ payload: { pipelines, count } }) => ({
    pipelinesAutocomplete: {
      pipelines,
      count,
    },
  })),
  [types.SET_PIPELINE_STATUSES_AUTOCOMPLETE]: mergeDeep(({
    payload: {
      pipelineStatuses, count,
    },
  }) => ({
    pipelineStatusesAutocomplete: {
      pipelineStatuses,
      count,
    },
  })),
  [types.SET_PIPELINE_STATUSES]: mergeIn(({ payload: { data } }, state) => ({
    summary: {
      ...state.summary,
    },
    pipelinesStatuses: {
      entities: {
        pipelinesStatuses: {
          ...data.entities.pipelinesStatuses,
          ...filter(item => item.pipeline_id !== compose(
            propOr('', 'pipeline_id'),
            head,
            values,
            pathOr({}, ['entities', 'pipelinesStatuses']),
          )(data), pathOr({}, ['pipelinesStatuses', 'entities', 'pipelinesStatuses'], state)),
        },
      },
    },
  })),
}, {
  summary: {
    entities: {},
    result: [],
  },
  pipelinesAutocomplete: {
    pipelines: [],
  },
  pipelineStatusesAutocomplete: {
    pipelineStatuses: [],
  },
  pipelinesStatuses: {
    entities: {},
  },
});

export default reducer;
