import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import redirectToProjectsInvoiceModal from './redirectModal';


const enhance = compose(
  withTranslation(['common']),
);

export default enhance(redirectToProjectsInvoiceModal);
